import ClientsService from "../../../services/Clients/ClientsService";
import createListStateModule from "../createListStateModule";
import gasTypesStatePlugin from "../plugins/gasTypesStatePlugin";

const clientsUnitsListStateModule = createListStateModule({
    stateNamespace: "clientsLocationUnits",
    itemsPropertyName: "clientsLocationUnits",
    loadItemsMethodName: "loadClientsLocationUnits",
    itemsDataMethod: ClientsService.getUnits,
    loadDetailMethodName: "loadClientsLocationsUnitsById",
    detailDataMethod: async (id) => {
        const clientUnits = await ClientsService.getUnitById(id);
        return clientUnits
    },
    detailsPropertyName: "clientsLocationUnitsData",
    deleteMethodName: "deleteClientLocationUnit",
    deleteDataMethod: ClientsService.deleteClientLocationUnit,
    updateMethodName: "updateClientLocationUnits",
    updateDataMethod: ClientsService.updateClientLocationUnit,
    createDataMethod: "createClientLocationUnitsItem",
    createMethodName: ClientsService.createClientLocationUnit,
    defaultItemsData: {data: []},
    plugins: [gasTypesStatePlugin]
});

export default clientsUnitsListStateModule;
