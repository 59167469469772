import DashboardWrapper from "./DashboardWrapper";
import FormWrapper from "./FormWrapper";
import ListingWrapper from './ListingWrapper';
import Wrapper from "./Wrapper";

const Wrappers = {
    FormWrapper: FormWrapper,
    Wrapper: Wrapper,
    DashboardWrapper: DashboardWrapper,
    ListingWrapper: ListingWrapper
};

export default Wrappers;
