import { useEffect, useState } from "react";

let listeners = {};
let state = {};


const getSharedState = (branch) => state[branch];

const setState = (newState, branch) => {
    state[branch] = { ...state[branch], ...newState };
    if (listeners[branch] === undefined) listeners[branch] = [];
    
    listeners[branch].forEach((listener) => {
        listener.cb(state[branch]);
    });
};

const useSharedState = (branch, initialState={}, name) => {
    if (state[branch] === undefined) state[branch] = initialState;
    if (listeners[branch] === undefined) listeners[branch] = [];

    const newListener = {cb:useState()[1], name};
    useEffect(() => {
        listeners[branch].push(newListener);
        return () => {
            listeners[branch] = listeners[branch].filter(
                (listener) => listener.cb !== newListener.cb
            );
        };
    }, []);

    return [state[branch], (newState) => setState(newState, branch)];
};


export default useSharedState;
export {setState as setSharedState, getSharedState};