import React, { useState, useRef, useEffect } from "react";
import _ from 'lodash'
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import helpers from "../../core/helpers";
import ChartDates from "./ChartDates";
import CustomTooltip from "./CustomTooltip";
import ChartDataSidebar from "./ChartDataSidebar";
import NoData from "./NoData";
import {
    ComposedChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid,
} from 'recharts';

//known bug in recharts that it renders chart 
const ChartWrapper = styled.div`
   margin-left: -40px;
   height: 100%;
`;

const Container = styled.div`
    padding-right: ${p => p.paddingRight || "30px"};
    padding: 25px 30px 30px 0px;
    height: ${p => p.height || "20%"};
    min-height: ${p => p.height || "20%"};
    flex-grow: 1;

    @media all and (max-width: ${props => props.theme.extraLargeDevices}) {
        padding-right: ${p => p.paddingRight || "0px"};
 
    }
`;

const ChartContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

const WEEK = 'week';
const MONTH = 'month';
const DAY = 'day';

const VALUE_COLUMN = 'value';
const COUNT_COLUMN = 'count';
export const FONT_COLOR = '#AAAEB3';

const FilterableLineChart = ({
    filterState,
    title,
    height,
    minHeight,
    data,
    isCompleted,
    isEmpty,
    onChangeFilter,
    column,
    color,
    unit,
    style,
    isInt,
    showDrillDown,
    valueLabel,
    countLabel,

}) => {

    const { t } = useTranslation("screens");

    const [selectedPeriodGrouping, setSelectedPeriodGrouping] = useState();
    const [selectedDataPoint, setSelectedDataPoint] = useState();
    const [selectedDetailTitle, setSelectedDetailTitle] = useState();  
    const [sidebarOpened, setSidebarOpened] = useState(false);

    const closeSideBar = () => {
        setSidebarOpened(false);
        setSelectedDataPoint(null);
    };

    const filterData = useRef({
        from: "",
        to: "",
    });

    //one way flow from the date pickers
    const tempFilterData = useRef({});

    const applyFilters = () => {
        Object.assign(filterData.current, tempFilterData.current);
        onChangeFilter(filterData.current);
    };

    const groupAndTransformSubItems = (data) => {
        return _(data.reduce(
            (arr, currentItem) => {
                if (currentItem.items) {
                    for (const item of currentItem.items) {
                        arr.push(item);
                    }
                }
                return arr;
            }, []
        )).groupBy(x => x.id).map((items, key) =>
        ({
            id: key,
            name: items[0].name,
            value: _.sumBy(items, VALUE_COLUMN),
            count: _.sumBy(items, COUNT_COLUMN),
        })).value();
    }


    const getValuesPerWeek = (rawData) => {

        return _(rawData)
            .groupBy(x => helpers.getWeekName(x.period))
            .map((dates, key) =>
            ({
                period: `${t("screens:salesDashboard.labels.week")} ${key}`,
                value: _.sumBy(dates, VALUE_COLUMN),
                dates: dates,
                items: groupAndTransformSubItems(dates)
            })).value();
    }

    const getValuesPerMonth = (rawData) => {

        return _(rawData)
            .groupBy(x => x.period.substring(0, 7))
            .map((dates, key) =>
            ({
                period: t(`screens:months.${parseInt(key.slice(-2))}`).substring(0, 3),
                value: _.sumBy(dates, VALUE_COLUMN),
                items: groupAndTransformSubItems(dates),
                dates: dates
            })).value();
    }

    const getValuesPerDay = (rawData) => {

        return rawData.map(x => (
            {
                period: helpers.toLocalDateIgnoreTime(x.period),
                value: x.value,
                items: x.items
            }
        ));
    }

    useEffect(() => {

        if (filterState) {
            tempFilterData.current.from = filterState.from;
            tempFilterData.current.to = filterState.to;
        }

    }, [filterState]);

    const handleDateFromFilterChange = (date) => {
        tempFilterData.current.from = date;
        applyFilters();
    }

    const handleDateToFilterChange = (date) => {
        tempFilterData.current.to = date;
        applyFilters();
    }

    const RenderChart = () => {

        let chartData = [];

        if (data) {
            const dataPerDay = getValuesPerDay(data);
            const dataPerMonth = getValuesPerMonth(data);
            const dataPerWeek = getValuesPerWeek(data);

            const threshold = 10;

            if (!selectedPeriodGrouping) {
                if (dataPerDay.length < threshold)
                    setSelectedPeriodGrouping(DAY);
                else if (dataPerDay.length < threshold)
                    setSelectedPeriodGrouping(WEEK);
                else
                    setSelectedPeriodGrouping(MONTH);
            }

            if (selectedPeriodGrouping === DAY)
                chartData = dataPerDay;
            else if (selectedPeriodGrouping === WEEK)
                chartData = dataPerWeek;
            else if (selectedPeriodGrouping === MONTH)
                chartData = dataPerMonth;
        }

        const showSidebar = (e) => {
          
            if (showDrillDown && e.activePayload && e.activePayload.length>0 && e.activePayload[0].payload && e.activePayload[0].payload.items) {
               setSidebarOpened(true);
               setSelectedDetailTitle(e.activeLabel);
               setSelectedDataPoint(e.activePayload[0].payload.items);     
            }   
        }

        return (
            <ChartContainer>
                <NoData.Wrapper
                    className={`${isEmpty || (isCompleted && chartData.length === 0) ? "show" : ""}`}
                >
                    <NoData.Icon />
                    {t("table:headers.noItems")}
                </NoData.Wrapper>
                <ResponsiveContainer width="100%" height="100%" minHeight={minHeight ? minHeight : "200px"}>
                    <ComposedChart
                        data={chartData}
                        onClick={showSidebar}
                        margin={{
                            top: 10, right: 0, left: 0, bottom: 5,
                        }}
                    >
                        <defs>
                            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor={color} stopOpacity={0.4} />
                                <stop offset="95%" stopColor={color} stopOpacity={0} />
                            </linearGradient>

                        </defs>
                        <CartesianGrid strokeWidth={1} strokeOpacity="50%" vertical={false} />
                        <XAxis dataKey="period" axisLine={false} tickLine={false} stroke={FONT_COLOR} style={{
                            fontSize: 10,

                        }} />
                        <YAxis axisLine={false} tickLine={false} stroke={FONT_COLOR} style={{
                            fontSize: 12,

                        }} />

                        <Tooltip label={column} labelFormatter={() => ''} content={<CustomTooltip unit={unit} isInt={isInt} showDrillDown={showDrillDown} />} />
                        <Area type="linear" dataKey="value" stroke={color} fillOpacity={0.4} fill="url(#colorUv)" activeDot={{ r: 8 }} />
                    </ComposedChart>
                </ResponsiveContainer>
            </ChartContainer>
        );
    }

    return (
        <Container height={height} style={style}>
            <ChartDates
                setSelectedPeriodGrouping={setSelectedPeriodGrouping}
                title={title}
                selectedPeriodGrouping={selectedPeriodGrouping}
                filterState={filterState}
                handleDateFromFilterChange={handleDateFromFilterChange}
                handleDateToFilterChange={handleDateToFilterChange}
            >

            </ChartDates>

            <ChartWrapper>
                {RenderChart()}
            </ChartWrapper>

            {showDrillDown  && <ChartDataSidebar
                data={selectedDataPoint}
                valueLabel={valueLabel}
                countLabel={countLabel}
                title={selectedDetailTitle}
                isLoading={false}
                unit={"kg"}
                closeSideBar={closeSideBar}
            >

            </ChartDataSidebar>
            }
           
        </Container>
    )
}

export default FilterableLineChart;