import React, { useCallback } from "react";
import { Redirect, Switch } from "react-router-dom";
import CompanyType from "../../core/enums/CompanyType";
import PrivateRoute from "../../core/PrivateRoute";
import RouteCreator from "../../core/RouteCreator";
import useLeftMenu from "../../core/state/navigation/useLeftMenu";
import globalAdminMenuItems from "../../data/menu/globalAdminMenuItems";
import CompanyListing from "./views/company/CompanyListing";
import CompanysStatisticsDashboard from "./views/company/CompanysStatisticsDashboard";
import CompanyCertificateScreen from "./CompanyCertificatesScreen";

const CompanyAdministrationScreen = () => {
    useLeftMenu(globalAdminMenuItems);

    const installerListingComponent = useCallback(
        (props) => {
            return (
                <CompanyListing
                    companyType={CompanyType.InstallationCompany}
                    {...props}
                ></CompanyListing>
            );
        },
        [CompanyListing]
    );
    const distributorListingComponent = useCallback(
        (props) => {
            return (
                <CompanyListing
                    companyType={CompanyType.Distributor}
                    {...props}
                ></CompanyListing>
            );
        },
        [CompanyListing]
    );

    const companyListingComponent = useCallback(
        (props) => {
            return (
                <CompanyListing
                    {...props}
                ></CompanyListing>
            );
        },
        [CompanyListing]
    );

    return (
        <Switch>
            <PrivateRoute
                path={RouteCreator.admin.companies.list(
                    CompanyType.InstallationCompany
                )}
                component={installerListingComponent}
            />
            <PrivateRoute
                path={RouteCreator.admin.companies.list(
                    CompanyType.Distributor
                )}
                component={distributorListingComponent}
            />
                <PrivateRoute
                path={RouteCreator.admin.companies.list()}
                component={companyListingComponent}
            />
            <PrivateRoute
                path={RouteCreator.admin.companies.statistics()}
                component={CompanysStatisticsDashboard}
            />
            <PrivateRoute
                path={RouteCreator.admin.companies.certificates()}
                component={CompanyCertificateScreen}
            />
            <Redirect to={RouteCreator.admin.companies.statistics()} />
        </Switch>
    );
};

export default CompanyAdministrationScreen;
