import React, { useCallback, useEffect, useMemo, useState, useRef } from "react";
import useBreadcrumbs from "../../../core/state/navigation/useBreadcrumbs";
import { useTranslation } from "react-i18next";
import useImmerReducer from "../../../hooks/_shared/useImmerReducer";
import useDataReducer from "../../../hooks/_shared/useDataReducer";
import PaymentService from "../../../services/Payment/PaymentService";
import MarketplaceService from "../../../services/BuyRefrigerant/MarketplaceService";
import usePageChange from "../../../hooks/_shared/usePageChange";
import HamburgerMenu from "../../../components/menu/HamburgerMenu";
import { ReactComponent as ListSvg } from "../../../assets/list-view.svg";
import { ReactComponent as HierarchySvg } from "../../../assets/hierarchy-view.svg";
import HamburgerMenuAction from "../../../components/menu/HamburgerMenuAction";
import { getApiUrl } from "../../../services/getAxios";
// import { DataListingContent } from "../../../components/data/styles/StyledDataContainer";
import styled from "styled-components";
import Flex from "../../../components/layout/flex/Flex";
import helpers from "../../../core/helpers";
import DataTable from "../../../components/data/DataTable";
import TransactionStatus from "../../../core/enums/TransactionStatus";
import DetailSideBar from "../../../components/modal/DetailSideBar";
import useClickAway from "../../../hooks/_shared/useClickAway";
import { toast } from "react-toastify";
import { getDateFormat, getTimeFormat, GetShortDate, ShortDateCell } from "../../../components/data/DateCell";
import TransactionDetails from "./components/TransactionDetails";
import PlainModal, { usePlainModal } from "../../../components/modal/PlainModal";
import { Helmet } from "react-helmet";
// import TransactionGroupFilter from "./components/TransactionGroupFilter";
import TransactionsFilter from "./components/TransactionsFilter";
import TransactionDetailsSidebar from "./components/TransactionDetailsSidebar";
import MangoPaymentTypeForDisplay from "../../../core/enums/MangoPaymentTypeForDisplay";
import MangoTransferType from "../../../core/enums/MangoTransferType";
// import Pagination from "../../../components/pagination/Pagination";
import LayoutInnerContainer from "../../../components/layout/split/one/LayoutInnerContainer";
// import TransactionRow from "./components/TransactionRow";
import FilterSummary from "./components/FilterSummary";
import Filters from "../../../components/filter/Filters";
import ActionButton from "../../../components/elements/buttons/ActionButton";
import HeaderActionsWrapper from "../../../components/wrappers/HeaderActionsWrapper";
import ModalDialog, { useModal } from "../../../components/modal/ModalDialog";

const HeaderWrapper = styled.div`
    display: flex;
    padding: 0px 60px 5px;
    margin: 0px -60px;
    border-radius: 15px 15px 0px 0px;
    background: linear-gradient(180deg, rgba(245, 245, 245, 1) 0%, rgba(240, 240, 240, 1) 100%);
    box-shadow: -2px 9px 11px -16px rgba(0, 0, 0, 0.75);
    border-bottom: 1px solid #ffffff;

    &.transparent {
        background: transparent;
        box-shadow: none;
        border-bottom: none;
    }

    &.hidden {
        display: none;
    }
`;

export const DataListingContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 20px 10px 20px 0;
`;

const Header = styled.div`
    color: #687191;
    &.escrow {
        color: #e69221;
    }

    & .title {
        font-size: 24px;
        font-weight: 600;
        line-height: 35px;
    }

    & .subtitle {
        font-size: 14px;
    }
`;

const SwitchButton = styled.button`
    cursor: pointer;
    min-width: 70px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    text-align: center;
    position: relative;
    text-align: center;
    border: none;
    background: none;
    font-weight: 500;
    color: ${(p) => p.theme.secondaryColor};
    text-decoration: none;
`;

const TableTitleContainer = styled(Flex.Container)`
    justify-content: space-between;
    margin: 20px 0px 0px 0px;
`;

const Highlight = styled.span`
    font-weight: 600;
    &.green {
        color: #48b05e;
    }
`;

const actionTypes = {
    SET_SORT: "SET_SORT",
    RESET_SORT: "RESET_SORT",
    SET_STATUS: "SET_STATUS",
    RESET_STATUS: "RESET_STATUS",
    SET_PAGING: "SET_PAGING",
    SET_PAYMENT_TYPE: "SET_PAYMENT_TYPE",
    RESET_PAYMENT_TYPE: "RESET_PAYMENT_TYPE",
    SET_CREATION_DATE_FROM: "SET_CREATION_DATE_FROM",
    RESET_CREATION_DATE_FROM: "RESET_CREATION_DATE_FROM",
    SET_CREATION_DATE_TO: "SET_CREATION_DATE_TO",
    RESET_CREATION_DATE_TO: "RESET_CREATION_DATE_TO",
    SET_EXECUTION_DATE_FROM: "SET_EXECUTION_DATE_FROM",
    RESET_EXECUTION_DATE_FROM: "RESET_EXECUTION_DATE_FROM",
    SET_EXECUTION_DATE_TO: "SET_EXECUTION_DATE_TO",
    RESET_EXECUTION_DATE_TO: "RESET_EXECUTION_DATE_TO",
    SET_PARENT_TYPE: "SET_PARENT_TYPE",
    RESET_PARENT_TYPE: "RESET_PARENT_TYPE",
    SET_PARENT_STATUS: "SET_PARENT_STATUS",
    RESET_PARENT_STATUS: "RESET_PARENT_STATUS",
    SET_FLOW_TYPE: "SET_FLOW_TYPE",
    RESET_FLOW_TYPE: "RESET_FLOW_TYPE",
    RESET_GROUP_FILTER: "RESET_GROUP_FILTER",
    SET_AMOUNT_FROM: "SET_AMOUNT_FROM",
    RESET_AMOUNT_FROM: "RESET_AMOUNT_FROM",
    SET_AMOUNT_TO: "SET_AMOUNT_TO",
    RESET_AMOUNT_TO: "RESET_AMOUNT_TO",
    TOGGLE_WALLET: "TOGGLE_WALLET",
};

const transactionsReducer = (state, action) => {
    if (action.type !== actionTypes.SET_PAGING) state.page = 1;
    switch (action.type) {
        case actionTypes.SET_PAGING:
            state.pageSize = action.payload.pageSize;
            state.page = action.payload.pageIndex + 1;
            break;
        case actionTypes.SET_SORT:
            state.orderColumn = action.payload[0].id;
            state.descending = action.payload[0].desc;
            break;
        case actionTypes.RESET_SORT:
            state.orderColumn = "";
            state.descending = true;
            break;
        case actionTypes.SET_AMOUNT_FROM:
            state.filter.amountFrom = action.payload;
            break;
        case actionTypes.RESET_AMOUNT_FROM:
            delete state.filter.amountFrom;
            break;
        case actionTypes.SET_AMOUNT_TO:
            state.filter.amountTo = action.payload;
            break;
        case actionTypes.RESET_AMOUNT_TO:
            delete state.filter.amountTo;
            break;
        case actionTypes.SET_CREATION_DATE_FROM:
            state.filter.creationDateFrom = action.payload;
            break;
        case actionTypes.RESET_CREATION_DATE_FROM:
            delete state.filter.creationDateFrom;
            break;
        case actionTypes.SET_CREATION_DATE_TO:
            state.filter.creationDateTo = action.payload;
            break;
        case actionTypes.RESET_CREATION_DATE_TO:
            delete state.filter.creationDateTo;
            break;

        case actionTypes.SET_EXECUTION_DATE_FROM:
            state.filter.executionDateFrom = action.payload;
            break;
        case actionTypes.RESET_EXECUTION_DATE_FROM:
            delete state.filter.executionDateFrom;
            break;
        case actionTypes.SET_EXECUTION_DATE_TO:
            state.filter.creationDateTo = action.payload;
            break;
        case actionTypes.RESET_EXECUTION_DATE_TO:
            delete state.filter.creationDateTo;
            break;
        case actionTypes.SET_STATUS:
            state.filter.status = action.payload;
            break;
        case actionTypes.RESET_STATUS:
            delete state.filter.status;
            break;
        case actionTypes.SET_PAYMENT_TYPE:
            state.filter.paymentType = action.payload;
            break;
        case actionTypes.RESET_PAYMENT_TYPE:
            delete state.filter.paymentType;
            break;
        case actionTypes.TOGGLE_WALLET:
            state.filter = { mainWallet: !state.filter.mainWallet };
            state.page = 1;
            state.pageSize = 10;
            state.orderColumn = "creationDate";
            state.descending = true;
            break;

        default:
            return state;
    }
};

const walletBreadcrumbs = (t, mainWallet = true) => [
    {
        text: t("navigation:finance"),
    },
    {
        text: t("screens:transactions.myTransactions.walletView"),
    },
    {
        text: mainWallet
            ? t("screens:transactions.myTransactions.mainWallet")
            : t("screens:transactions.myTransactions.escrowWallet"),
    },
];

const TransactionsView = () => {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;
    const currency = "EUR";

    const sideBarRef = useRef(null);

    useClickAway(sideBarRef, () => {
        try {
            closeSideBar();
        } catch (ex) {}
    });

    const [myTransactionsState, myTransactionsDispatch] = useImmerReducer(transactionsReducer, {
        filter: {
            mainWallet: true,
        },
        page: 1,
        pageSize: 10,
        orderColumn: "creationDate",
        descending: true,
    });

    const columns = useMemo(
        () => [
            {
                Header: t("table:headers.transactions.transactionId"),
                accessor: "mangoTransactionId",
                Cell: ({ cell: { value } }) => {
                    return <Highlight>{value}</Highlight>;
                },
            },
            {
                Header: t("table:headers.transactions.paymentType"),
                accessor: "paymentTypeForDisplay",
                Cell: ({ cell: { value } }) => {
                    let paymentTypeTranslation;
                    if (MangoPaymentTypeForDisplay[value]) {
                        paymentTypeTranslation = t("table:headers.transactions.mangoPaymentTypeDisplay." + value + "");
                    }

                    return <Highlight>{paymentTypeTranslation || value}</Highlight>;
                },
            },
            {
                Header: t("table:headers.transactions.creationDate"),
                accessor: "creationDate",
                Cell: ({ cell: { value } }) => {
                    return value ? ShortDateCell({ cell: { value } }) : "-";
                },
            },
            {
                Header: t("table:headers.transactions.executionDate"),
                accessor: "executionDate",
                Cell: ({ cell: { value } }) => {
                    return value ? ShortDateCell({ cell: { value } }) : "-";
                },
            },

            {
                Header: t("table:headers.transactions.status"),
                accessor: "transactionStatus",
                Cell: ({ cell: { value } }) => {
                    let transactionTranslation;
                    if (TransactionStatus[value]) {
                        transactionTranslation = t(
                            "table:headers.transactions.transactionStatus." + TransactionStatus[value] + ""
                        );
                    }

                    return transactionTranslation || value;
                },
            },

            {
                Header: t("table:headers.transactions.amount"),
                accessor: "totalAmount",
                Cell: ({ cell: { value, row } }) => {
                    const val = row.original.direction === "Out" ? -1 * value : value;
                    return <Highlight>{helpers.formatCurrency(val, currentLanguage, currency, true)}</Highlight>;
                },
            },

            {
                Header: t("table:headers.transactions.transferTypeDescription"),
                accessor: "transferType",
                Cell: ({ cell: { value } }) => {
                    let transferTypeTranslation;
                    if (MangoTransferType[value]) {
                        transferTypeTranslation = t(
                            "table:headers.transactions.transferType." + MangoTransferType[value] + ""
                        );
                    }

                    return transferTypeTranslation || value;
                },
            },
            {
                Header: "",
                accessor: "documentId",
                Cell: ({ cell: { value } }) =>
                    value && (
                        <HamburgerMenu>
                            <HamburgerMenuAction
                                text={t("actions:view")}
                                onClick={() => window.open(`${getApiUrl}/Documents/${value}`, "_blank")}
                            />
                        </HamburgerMenu>
                    ),
                disableSortBy: true,
            },
        ],
        [t]
    );

    const { update: updateBreadcrumbs } = useBreadcrumbs(walletBreadcrumbs(t, myTransactionsState.filter.mainWallet));

    const [myTransactions, loadMyTransactions] = useDataReducer(
        () => PaymentService.getMyTransactions(myTransactionsState),
        true
    );

    useEffect(() => {
        loadMyTransactions(myTransactionsState);
    }, [myTransactionsState]);

    const pageChange = usePageChange(myTransactionsDispatch, myTransactionsState);

    const [transactionDetails, setTransactionDetails] = useState({
        offer: "",
        details: "",
        paymentMethod: "0",
        paymentType: 0,
    });

    const { modalProps: transactionDetailsProps, toggleOpen: toggleTransactionDetails } = usePlainModal({
        onClose: () => {
            toggleTransactionDetails();
        },
        closeText: t("actions:close"),
    });

    const [detailViewIsOpened, setDetailViewIsOpened] = useState(false);
    const [transaction, setTransaction] = useState({
        bid: null,
        offer: null,
        info: null,
    });

    const closeSideBar = () => {
        setDetailViewIsOpened(false);
    };

    const onSelect = useCallback((row) => {
        const getDetails = async () => {
            const info = await PaymentService.transactionDetails(row.original.id);
            if (info.data) {
                let offer = null;
                let acceptedBid = null;
                if (row.original.offerId !== "00000000-0000-0000-0000-000000000000") {
                    offer = await MarketplaceService.getBuyingItemById(row.original.offerId);

                    const bids = await MarketplaceService.getBids(row.original.offerId);
                    const acceptedBid = bids.filter((bid) => bid.bidStatus === 1)[0];
                }
                setTransaction({
                    bid: acceptedBid,
                    offer: offer,
                    info: info.data,
                });
                setDetailViewIsOpened(true);
            }
        };
        getDetails();
    }, []);

    const toggleView = () => {
        updateBreadcrumbs(walletBreadcrumbs(t, !myTransactionsState.filter.mainWallet));
        myTransactionsDispatch({ type: actionTypes.TOGGLE_WALLET });
    };

    const getFiltersForExport = () => {
        let filteredItems = [];
        if (myTransactionsState.filter) {
            filteredItems.push({
                label: t("filters:transactions.transactionType"),
                value: myTransactionsState.filter.paymentType
                    ? t(
                          `filters:transactions.mangoPaymentType.${
                              MangoPaymentTypeForDisplay[myTransactionsState.filter.paymentType]
                          }`
                      )
                    : "-",
            });

            filteredItems.push({
                label: t("filters:transactions.status"),
                value: myTransactionsState.filter.status
                    ? t(
                          `filters:transactions.statusType.${TransactionStatus[
                              myTransactionsState.filter.status
                          ].toLowerCase()}`
                      )
                    : "-",
            });

            const creationDateFrom = myTransactionsState.filter.creationDateFrom
                ? GetShortDate(myTransactionsState.filter.creationDateFrom)
                : "";
            const creationDateTo = myTransactionsState.filter.creationDateTo
                ? GetShortDate(myTransactionsState.filter.creationDateTo)
                : "";

            filteredItems.push({
                label: t("filters:transactions.creationDateFromTo"),
                value: `${creationDateFrom} - ${creationDateTo}`,
            });

            const executionDateFrom = myTransactionsState.filter.executionDateFrom
                ? GetShortDate(myTransactionsState.filter.executionDateFrom)
                : "";
            const executionDateTo = myTransactionsState.filter.executionDateTo
                ? GetShortDate(myTransactionsState.filter.executionDateTo)
                : "";

            filteredItems.push({
                label: t("filters:transactions.executionDateFromTo"),
                value: `${executionDateFrom} - ${executionDateTo}`,
            });

            const amountFrom = myTransactionsState.filter.amountFrom
                ? helpers.formatCurrency(myTransactionsState.filter.amountFrom, currentLanguage, currency)
                : "";

            const amountTo = myTransactionsState.filter.amountTo
                ? helpers.formatCurrency(myTransactionsState.filter.amountTo, currentLanguage, currency)
                : "";

            filteredItems.push({
                label: t("filters:transactions.amountFromTo"),
                value: `${amountFrom} - ${amountTo}`,
            });
        }

        return filteredItems;
    };

    const exportModal = useModal({
        title: t("actions:dialogs.exportTransactions.title").toUpperCase(),
        type: "info",
        onConfirm: async () => {
            const result = await PaymentService.downloadTransactionsFromMango(myTransactionsState.filter);
            if (result && result.error) {
                toast.error(t(result.error.message));
            } else {
                const dateValue = new Date();
                const date = getDateFormat(currentLanguage).format(dateValue);
                const time = getTimeFormat(currentLanguage).format(dateValue);

                helpers.downloadFile(result.data, `transactions_${date}/${time}.xlsx`, "application/vnd.ms-excel");
                exportModal.toggleOpen();
            }
        },
        onCancel: () => {
            exportModal.toggleOpen();
        },
        confirmText: t("actions:dialogs.exportTransactions.confirmText"),
    });

    const sortChange = (sortBy) => {
        if (sortBy.length > 0) {
            myTransactionsDispatch({ type: "SET_SORT", payload: sortBy });
        }
    };
    const currentSortBy = useMemo(() => {
        return [
            {
                id: myTransactionsState.orderColumn,
                desc: myTransactionsState.descending,
            },
        ];
    }, [myTransactionsState.orderColumn, myTransactionsState.descending]);

    return (
        <>
            <Helmet>
                <title>{t("seo:transactions.title")}</title>
            </Helmet>
            <LayoutInnerContainer>
                <HeaderWrapper>
                    <div>
                        <Filters.Title>{t("screens:transactions.search")}</Filters.Title>

                        <TransactionsFilter
                            key={myTransactionsState.filter.mainWallet}
                            filterState={myTransactionsState.filter}
                            dispatch={myTransactionsDispatch}
                            actionTypes={actionTypes}
                        />
                    </div>
                    <HeaderActionsWrapper>
                        <Flex.Column justifyContent="flex-end" style={{ marginBottom: 20, display: "flex" }}>
                            <ActionButton
                                className="primary"
                                disabled={myTransactions?.data?.totalRows === 0}
                                minWidth="250px"
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    exportModal.toggleOpen();
                                }}
                            >
                                {t("screens:transactions.myTransactions.exportButtonText")}
                            </ActionButton>
                        </Flex.Column>
                    </HeaderActionsWrapper>
                </HeaderWrapper>
                <TableTitleContainer>
                    <Header className={myTransactionsState.filter.mainWallet ? "main" : "escrow"}>
                        <div className="title">
                            {myTransactionsState.filter.mainWallet
                                ? t("screens:transactions.myTransactions.mainWalletTitle")
                                : t("screens:transactions.myTransactions.escrowWalletTitle")}
                        </div>
                        <div className="subtitle">
                            {myTransactionsState.filter.mainWallet
                                ? t("screens:transactions.myTransactions.mainWalletSubtitle")
                                : t("screens:transactions.myTransactions.escrowWalletSubtitle")}
                        </div>
                    </Header>
                    <SwitchButton onClick={toggleView}>
                        {myTransactionsState.filter.mainWallet
                            ? t("screens:transactions.myTransactions.switchToEscrow")
                            : t("screens:transactions.myTransactions.switchToMain")}
                    </SwitchButton>
                </TableTitleContainer>
                {myTransactions?.data && (
                    <DataTable
                        loading={myTransactions.isLoading}
                        enablePaging
                        enableSorting
                        pageCount={myTransactions.data.totalPages || 1}
                        currentPageIndex={myTransactionsState.page - 1}
                        onSortByChange={sortChange}
                        currentSortBy={currentSortBy}
                        onPageChange={pageChange}
                        totalRows={myTransactions.data.totalRows}
                        pageSize={10}
                        numPageButtons={3}
                        columns={columns}
                        data={myTransactions.data?.data}
                        onSelect={onSelect}
                    />
                )}

                <DetailSideBar
                    detailIsOpened={detailViewIsOpened}
                    closeSideBar={closeSideBar}
                    ref={sideBarRef}
                    title={t("actions:transactions.details")}
                >
                    <TransactionDetailsSidebar
                        key={detailViewIsOpened}
                        selectedOffer={{
                            offer: transaction.offer,
                            bid: transaction.bid,
                        }}
                        offer={transaction.offer}
                        bidDetails={transaction.bid}
                        info={transaction.info}
                        closeSideBar={closeSideBar}
                    />
                </DetailSideBar>
            </LayoutInnerContainer>

            <PlainModal {...transactionDetailsProps}>
                <TransactionDetails
                    transactionDetails={transactionDetails.details}
                    offer={transactionDetails.offer}
                    paymentMethod={transactionDetails.paymentMethod}
                    paymentType={transactionDetails.paymentType}
                />
            </PlainModal>

            <ModalDialog {...exportModal.modalProps}>
                <p>{t("actions:dialogs.exportTransactions.text")}</p>
                <FilterSummary filters={getFiltersForExport()} total={myTransactions?.data?.totalRows}></FilterSummary>
            </ModalDialog>
        </>
    );
};

export default TransactionsView;
