import React from "react";
import styled, { keyframes } from "styled-components";

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const SpinnerImage = styled.div`
    animation: ${rotate360} 1s linear infinite;
    transform: translateZ(0);

    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    border-left: 2px solid #fff;

    background: transparent;

    width: 84px;
    height: 84px;
    border-radius: 50%;
`;

const Spinnerwrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Spinner = () => (
    <Spinnerwrapper>
        <SpinnerImage />
    </Spinnerwrapper>
);
export default Spinner;
