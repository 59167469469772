import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import AccordionWrapper from "../../../../components/toggle-view/AccordionWrapper";
import SidebarField from "../../../../components/elements/sidebar/SidebarField";
import {
    Num,
    NumInfo,
    NumSection,
} from "../../../../components/elements/sidebar/SidebarStyle";
import helpers from "../../../../core/helpers";
import { VSpace } from "../../../pwa/components/StyledElements";
import {
    BidGradeWrapper,
    BidGrade,
} from "../../../../components/bids/BidGrade";
import Slider from "../../../../components/elements/general/Slider";
import { getApiUrl } from "../../../../services/getAxios";
import useAccordionGroup from "../../../../hooks/_shared/useAccordionGroup";

const DownloadWrapper = styled.div`
    display: flex;
    justify-content: space-between;

    &.pad {
        padding: 25px 20px;
    }

    &.big > * {
        font-size: 14px !important;
    }

    & > div {
        font-size: 12px;
        color: #333333;
        font-weight: 600;
    }
`;

export const SidebarLabel = styled.label`
    display: inline-block;
    margin: 20px 20px 0 0;
    padding: 0 0 0 20px;
    font-weight: bold;
    min-width: 130px;

    &.pl-0 {
        padding-left: 0px;
    }
`;

const DownloadLink = styled.a`
    color: #2294d2;
    text-decoration: none;
    font-size: 12px;
    &.big {
        font-size: 14px;
    }
`;

const splitTag = (tag) => {
    if (!tag || tag.length < 8) return tag;
    return tag.substring(0, 4) + " " + tag.substring(4, 8);
};

const getFileName = (url) => {
    const parts = url.split("/");
    return parts[parts.length - 1];
};

const InvoiceDetailsSidebar = ({
    selectedOffer,
    offer,
    isLoading,
    document,
}) => {
    const { t } = useTranslation("sidebar");
    const shouldShow = !isLoading && offer;
    const [openAccordion, setOpenAccordion] = useAccordionGroup();
    const numQualityTests = () => {
        if (!offer.skUs) return 0;
        let cnt = 0;
        offer.skUs.forEach((offer) => {
            if (offer.qualityTestDone) cnt++;
        });
        return cnt;
    };

    let transDesc = "";
    switch (document?.transferType) {
        case 50:
            transDesc = t("description.toMainWallet", {
                amount: document.amount.toFixed(2),
            });
            break;
        case 40:
        case 60:
            transDesc = t("description.toIBAN", {
                amount: document.amount.toFixed(2),
            });
            break;
        default:
            break;
    }

    const showDesc = [40, 50, 60].includes(document?.transferType);

    return (
        <>
            {showDesc && (
                <AccordionWrapper
                    title={t("title.transactionDescription")}
                    id="transactionDescription"
                    isOpen={openAccordion}
                    onToggle={setOpenAccordion}
                >
                    <SidebarLabel>{transDesc}</SidebarLabel>
                </AccordionWrapper>
            )}
            {shouldShow && (
                <>
                    <AccordionWrapper
                        title={t("title.offer.details")}
                        id="offer.details"
                        isOpen={openAccordion}
                        onToggle={setOpenAccordion}
                    >
                        {selectedOffer.offer && (
                            <>
                                <SidebarField
                                    label={t("label.type")}
                                    value={
                                        selectedOffer.offer
                                            .refrigerantGasTypeCode
                                    }
                                />
                                <SidebarField
                                    label={t("label.numberOfCylinders")}
                                    value={
                                        selectedOffer.offer.numberOfCylinders
                                    }
                                />
                                <SidebarField
                                    label={t("label.netWeight")}
                                    value={
                                        selectedOffer.offer.maximumQuantity
                                            ? selectedOffer.offer.maximumQuantity.toFixed(
                                                  2
                                              ) + " kg"
                                            : "-"
                                    }
                                />
                                <SidebarField
                                    label={t("label.totalExtractedQuantity")}
                                    value={
                                        selectedOffer.offer.quantity.toFixed(
                                            2
                                        ) + " kg"
                                    }
                                />
                                <SidebarField
                                    label={t("label.extraction.qualityGrade")}
                                    value={
                                        selectedOffer.offer.pressumedGradeName
                                    }
                                />
                                <SidebarField
                                    label={t(
                                        "label.extraction.qualityEvaluation"
                                    )}
                                    value={
                                        offer.hasAnalysis
                                            ? t("filters:analysis.measurement")
                                            : t(
                                                  "filters:analysis.selfAssessment"
                                              )
                                    }
                                />
                            </>
                        )}
                    </AccordionWrapper>
                    {offer.skUs && (
                        <AccordionWrapper
                            title={t("title.contamination")}
                            id="contamination"
                            isOpen={openAccordion}
                            onToggle={setOpenAccordion}
                        >
                            <Slider
                                style={{ padding: "25px 20px 0px" }}
                                title={t(
                                    "sidebar:title.extraction.extractionCylinder"
                                )}
                                captions={offer.skUs.map((sku) =>
                                    splitTag(sku.tagId)
                                )}
                            >
                                {offer.skUs.map((sku, idx) => {
                                    let cnt = 0;
                                    return (
                                        <div id={`slide-${idx + 1}`} key={idx}>
                                            {!sku.saIsContaminated &&
                                                !sku.saIsMixture &&
                                                !sku.saHasR22 && (
                                                    <NumSection>
                                                        <Num className="tight">
                                                            N/A
                                                        </Num>
                                                        <NumInfo>
                                                            {t(
                                                                "actions:noContamination"
                                                            )}
                                                        </NumInfo>
                                                    </NumSection>
                                                )}

                                            {sku.saIsContaminated && (
                                                <NumSection>
                                                    <Num>{++cnt}</Num>
                                                    <NumInfo>
                                                        {t(
                                                            "screens:stock.aGradeText"
                                                        )}
                                                    </NumInfo>
                                                </NumSection>
                                            )}
                                            {sku.saIsMixture && (
                                                <NumSection>
                                                    <Num>{++cnt}</Num>
                                                    <NumInfo>
                                                        {t(
                                                            "screens:stock.bGradeText"
                                                        )}
                                                    </NumInfo>
                                                </NumSection>
                                            )}
                                            {sku.saHasR22 && (
                                                <NumSection>
                                                    <Num>{++cnt}</Num>
                                                    <NumInfo>
                                                        {t(
                                                            "screens:stock.cGradeText"
                                                        )}
                                                    </NumInfo>
                                                </NumSection>
                                            )}
                                        </div>
                                    );
                                })}
                            </Slider>
                        </AccordionWrapper>
                    )}

                    {/*//Show only if Accepted*/}
                    {selectedOffer.bid && selectedOffer.bid.bidStatus === 1 && (
                        <>
                            <AccordionWrapper
                                title={t("title.bid.details")}
                                id="bid.details"
                                isOpen={openAccordion}
                                onToggle={setOpenAccordion}
                            >
                                <SidebarField
                                    label={t("label.bid.date")}
                                    value={helpers.toLocalDateIgnoreTime(
                                        selectedOffer.bid.bidDateTime
                                    )}
                                />
                                <VSpace size="10px" />

                                <BidGradeWrapper>
                                    <BidGrade
                                        grade="a"
                                        amount={
                                            selectedOffer.bid.offeredPriceGradeA
                                        }
                                        quantity={
                                            selectedOffer.offer.maximumQuantity
                                        }
                                        bidCompanyCountryId={
                                            selectedOffer.bid
                                                .bidCompanyCountryId
                                        }
                                    />
                                    <BidGrade
                                        grade="b"
                                        amount={
                                            selectedOffer.bid.offeredPriceGradeB
                                        }
                                        quantity={
                                            selectedOffer.offer.maximumQuantity
                                        }
                                        bidCompanyCountryId={
                                            selectedOffer.bid
                                                .bidCompanyCountryId
                                        }
                                    />
                                    <BidGrade
                                        grade="c"
                                        amount={
                                            selectedOffer.bid.offeredPriceGradeC
                                        }
                                        quantity={
                                            selectedOffer.offer.maximumQuantity
                                        }
                                        bidCompanyCountryId={
                                            selectedOffer.bid
                                                .bidCompanyCountryId
                                        }
                                    />
                                </BidGradeWrapper>
                            </AccordionWrapper>

                            {offer.skUs && (
                                <AccordionWrapper
                                    title={t(
                                        "title.quality.qualityConfirmation"
                                    )}
                                    id="qualityConfirmation"
                                    isOpen={openAccordion}
                                    onToggle={setOpenAccordion}
                                >
                                    {numQualityTests() > 0 && (
                                        <DownloadWrapper className="pad big">
                                            <div>
                                                {numQualityTests()}{" "}
                                                {numQualityTests() > 1
                                                    ? t(
                                                          "actions:availableTests"
                                                      )
                                                    : t(
                                                          "actions:availableTest"
                                                      )}
                                            </div>
                                            <DownloadLink
                                                href={`${getApiUrl}/Marketplace/getAnalysesZip/${offer.offerId}`}
                                                target="_blank"
                                                // onClick={(ev) =>
                                                //     handleDownloadAllTest(ev)
                                                // }
                                            >
                                                {t("actions:downloadAll")}
                                            </DownloadLink>
                                        </DownloadWrapper>
                                    )}

                                    <Slider
                                        prefix="qInfo-"
                                        style={{ padding: "25px 20px 0px" }}
                                        title={t(
                                            "sidebar:title.extraction.extractionCylinder"
                                        )}
                                        captions={offer.skUs.map((sku) =>
                                            splitTag(sku.tagId)
                                        )}
                                    >
                                        {offer.skUs.map((sku, idx) => {
                                            return (
                                                <DownloadWrapper
                                                    id={`qInfo-${idx + 1}`}
                                                    key={idx}
                                                >
                                                    <div>
                                                        {getFileName(
                                                            sku.qualityTestUrl
                                                        )}
                                                    </div>
                                                    {sku.qualityTestDone ? (
                                                        <DownloadLink
                                                            href={
                                                                sku.qualityTestUrl
                                                            }
                                                            target="_blank"
                                                        >
                                                            {t(
                                                                "actions:download"
                                                            )}
                                                        </DownloadLink>
                                                    ) : (
                                                        <div>-</div>
                                                    )}
                                                </DownloadWrapper>
                                            );
                                        })}
                                    </Slider>
                                </AccordionWrapper>
                            )}

                            <AccordionWrapper
                                title={t("title.buyer")}
                                id="buyer"
                                isOpen={openAccordion}
                                onToggle={setOpenAccordion}
                            >
                                <SidebarField
                                    label={t("title.company")}
                                    value={selectedOffer.bid.bidCompanyName}
                                />
                                <SidebarField
                                    label={t("label.companyVAT")}
                                    value={selectedOffer.bid.bidCompanyVAT}
                                />
                                <SidebarField
                                    label={t("label.companyStreet")}
                                    value={selectedOffer.bid.bidCompanyAddress}
                                />
                                <SidebarField
                                    label={t("label.companyCity")}
                                    value={selectedOffer.bid.bidCompanyCity}
                                />
                                <SidebarField
                                    label={t("label.companyState")}
                                    value={selectedOffer.bid.bidCompanyRegion}
                                />
                                <SidebarField
                                    label={t("label.companyZIP")}
                                    value={
                                        selectedOffer.bid.bidCompanyPostalCode
                                    }
                                />
                                {selectedOffer.bid.bidCompanyCountry && (
                                    <SidebarField
                                        label={t("label.companyCountry")}
                                        value={
                                            selectedOffer.bid.bidCompanyCountry
                                        }
                                    />
                                )}

                                <SidebarField
                                    label={t("label.companyContactPersonName")}
                                    value={
                                        selectedOffer.bid
                                            .bidCompanyContactPerson
                                    }
                                />
                                <SidebarField
                                    label={t("label.companyEmail")}
                                    value={selectedOffer.bid.bidCompanyEmail}
                                />
                                <SidebarField
                                    label={t("label.companyPhone")}
                                    value={selectedOffer.bid.bidCompanyPhone}
                                />
                            </AccordionWrapper>
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default InvoiceDetailsSidebar;
