import SellRefrigerantService from "../../../services/SellRefrigerant/SellRefrigerantService";
import createListStateModule from "../createListStateModule";
import gasTypesStatePlugin from "../plugins/gasTypesStatePlugin";
import unpublishOfferPlugin from "./sellRefrigerantListingPlugins";

const sellRefrigerantListingStateModule = createListStateModule({
    stateNamespace: "sellrefrigerant",
    itemsPropertyName: "sellingItems",
    loadItemsMethodName: "loadSellingItems",
    itemsDataMethod: SellRefrigerantService.getSellingItems,
    loadDetailMethodName: "loadSellingItemById",
    detailDataMethod: SellRefrigerantService.getSellingItemById,
    detailsPropertyName: "sellingItemData",
    updateMethodName: "updateSellingItem",
    updateDataMethod: () => {},
    createMethodName: 'createSellingItem',
    createDataMethod: () => {},
    defaultItemsData: {data:[]},
    plugins: [unpublishOfferPlugin, gasTypesStatePlugin]
});

export default sellRefrigerantListingStateModule;
