import Roles from "./core/enums/Roles";
import RouteCreator from "./core/RouteCreator";
import CompanyAdministrationScreen from "./screens/Administration/CompanyAdministrationScreen";
import MarketplaceStatisticsScreen from "./screens/Administration/MarketplaceStatisticsScreen";
import QRTagsScreen from "./screens/Administration/QRTagsScreen";
import UserAdministrationScreen from "./screens/Administration/UserAdministrationScreen";
import ClientsScreen from "./screens/Clients/ClientsScreen";
import CompanyScreen from "./screens/Company/views/company/CompanyScreen";
import DashboardScreen from "./screens/Dashboard/DashboardScreen";
import MobileDashboard from "./screens/Dashboard/MobileDashboard";
import EquipmentScreen from "./screens/Equipment/EquipmentScreen";
import ForgottenPasswordScreen from "./screens/ForgottenPassword/ForgottenPasswordScreen";
import ResetPasswordScreen from "./screens/ForgottenPassword/ResetPasswordScreen";
import LoginScreen from "./screens/Login/LoginScreen";
import RegisterScreen from "./screens/Register/RegisterScreen";
import RegistrationConfirmedScreen from "./screens/Register/RegistrationConfirmedScreen";
import SalesCenterScreen from "./screens/SalesCenter/SalesCenterScreen";
import StockScreen from "./screens/Stock/StockScreen";
import ActivateUserScreen from "./screens/User/ActivateUserScreen";
import ChangeEmailScreen from "./screens/User/ChangeEmailScreen";
import ProfileScreen from "./screens/Company/views/employees/ProfileScreen";
import FinanceScreen from "./screens/Finance/FinanceScreen";
import ServicesScreen from "./screens/Services/ServicesScreen";
import DisputesScreen from "./screens/Disputes/DisputesScreen";
import SignupScreen from "./screens/Register/SignupScreen";
import ServicesStatisticsScreen from "./screens/Administration/ServicesStatisticsScreen";

export default [
    {
        path: RouteCreator.user.login(),
        text: "Login",
        component: LoginScreen,
        unprotected: true,
        exact: true,
    },
    {
        path: RouteCreator.user.changeEmail(),
        text: "Verify email",
        component: ChangeEmailScreen,
        unprotected: true,
        exact: true,
    },
    {
        path: RouteCreator.user.verifyLogin(),
        text: "Verify Login",
        component: LoginScreen,
        unprotected: true,
        exact: true,
    },
    {
        path: RouteCreator.home(),
        text: "Dashboard",
        exact: true,
        component: DashboardScreen,
    },
    {
        path: RouteCreator.pwa.root(),
        text: "Mobile Dashboard",
        exact: false,
        component: MobileDashboard,
    },
    {
        path: RouteCreator.user.register(),
        text: "Register here",
        component: SignupScreen,
        unprotected: true,
    },
    {
        path: RouteCreator.salesCenter.root(),
        text: "Marketplace",
        component: SalesCenterScreen,
        roles: [Roles.InstallerCompanyAdmin, Roles.InstallerCompanyUser],
    },
    {
        path: RouteCreator.disputes.root(),
        text: "Disputes",
        component: DisputesScreen,
        roles: [Roles.InstallerCompanyAdmin, Roles.InstallerCompanyUser],
    },
    {
        path: RouteCreator.stock.root(),
        text: "Stock",
        component: StockScreen,
        roles: [Roles.InstallerCompanyAdmin, Roles.InstallerCompanyUser,Roles.InstallerCompanyTechnician],
    },
    {
        path: RouteCreator.company.root(),
        text: "Company",
        component: CompanyScreen,
        roles: [Roles.InstallerCompanyAdmin, Roles.InstallerCompanyUser, Roles.InstallerCompanyTechnician],
    },
    {
        path: RouteCreator.equipment.root(),
        text: "Equipment",
        component: EquipmentScreen,
        roles: [Roles.InstallerCompanyAdmin, Roles.InstallerCompanyUser, Roles.InstallerCompanyTechnician],
    },
    {
        path: RouteCreator.user.forgotPassword(),
        text: "Forgotten password",
        component: ForgottenPasswordScreen,
        unprotected: true,
    },
    {
        path: RouteCreator.user.resetPassword(),
        text: "Setup new password",
        component: ResetPasswordScreen,
        unprotected: true,
    },
    {
        path: RouteCreator.user.activate(),
        text: "Activate user",
        component: ActivateUserScreen,
        unprotected: true,
        roles: [Roles.Admin, Roles.MasterAdmin],
    },
    {
        path: RouteCreator.admin.users.root(),
        text: "User Administration",
        component: UserAdministrationScreen,
        roles: [Roles.Admin, Roles.MasterAdmin],
    },
    {
        path: RouteCreator.admin.companies.root(),
        text: "CompanyAdministration",
        component: CompanyAdministrationScreen,
        roles: [Roles.Admin, Roles.MasterAdmin],
    },
    {
        path: RouteCreator.admin.hangfire(),
        component: () => {
            window.location.href =
                "/hangfire?token=" + localStorage.getItem("token");
            return null;
        },
        roles: [Roles.MasterAdmin],
    },
    {
        path: RouteCreator.admin.marketplaceStatistics(),
        text: "Marketplace statistics",
        component: MarketplaceStatisticsScreen,
        roles: [Roles.MasterAdmin, Roles.Admin],
    },
    {
        path: RouteCreator.admin.services.root(),
        text: "Services statistics",
        component: ServicesStatisticsScreen,
        roles: [Roles.MasterAdmin, Roles.Admin],
    },
    // {
    //     path: RouteCreator.admin.marketplace(),
    //     text: "Marketplace",
    //     component: SalesCenterScreen,
    //     roles: [Roles.MasterAdmin, Roles.Admin],
    // },
    // {
    //     path: RouteCreator.admin.reclaimedMarketplace(),
    //     text: "Marketplace",
    //     component: SalesCenterScreen,
    //     roles: [Roles.MasterAdmin, Roles.Admin],
    // },
    {
        path: RouteCreator.user.verifyLogin(),
        text: "Verify login",
        component: () => console.log("Verify login")
    },
    {
        path: RouteCreator.confirm.company(':username'),
        text: "Company confirmed",
        component: RegistrationConfirmedScreen,
        unprotected: true
    },
    {
        path: RouteCreator.clients.root(),
        text: "Clients",
        component: ClientsScreen,
        roles: [Roles.InstallerCompanyAdmin, Roles.InstallerCompanyUser, Roles.InstallerCompanyTechnician]
    },
    {
        path: RouteCreator.admin.qrTags(),
        text: 'QR Tags',
        component: QRTagsScreen,
        roles: [Roles.MasterAdmin, Roles.Admin],
    },
    {
        path: RouteCreator.user.profile(),
        text: "Profile",
        component: ProfileScreen
    },
    {
        path: RouteCreator.finance.root(),
        text: "Finance",
        component: FinanceScreen
    },
    {
        path: RouteCreator.services.root(),
        text: "Services",
        component: ServicesScreen,
        roles: [Roles.InstallerCompanyAdmin, Roles.InstallerCompanyUser, Roles.MasterAdmin],
    },
];
