import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import ListingPage from "../../../../components/data/ListingPage";
import ModalWizard from "../../../../components/modal/ModalWizard";
import AuthorizeComponent from "../../../../core/AuthorizeComponent";
import Roles from "../../../../core/enums/Roles";
import RouteCreator from "../../../../core/RouteCreator";
import clientsListStateModule from "../../../../core/state/clients/clientsListStateModule";
import useBreadcrumbs from "../../../../core/state/navigation/useBreadcrumbs";
import useStateModule from "../../../../core/state/useStateModule";
import useDataReducer from "../../../../hooks/_shared/useDataReducer";
import useImmerReducer from "../../../../hooks/_shared/useImmerReducer";
import usePageChange from "../../../../hooks/_shared/usePageChange";
import ClientsService from "../../../../services/Clients/ClientsService";
import ClientsBreadcrumbs from "../../columns/ClientsBreadcrumbs";
import ClientsColumns from "../../columns/ClientsColumns";
import ClientsFilter from "./ClientsFilter";
import ClientDetailSideBar from "./sidebar/ClientDetailSideBar";
import ClientWizard from "./wizard/ClientWizard";
import ActionButton from "../../../../components/elements/buttons/ActionButton";
import ModalDialog, {
    useModal,
} from "../../../../components/modal/ModalDialog";
import {Helmet} from "react-helmet";

const actionTypes = {
    SET_NAME_FILTER: "SET_NAME_FILTER",
    RESET_NAME_FILTER: "RESET_NAME_FILTER",

    SET_ADDRESS_FILTER: "SET_ADDRESS_FILTER",
    RESET_ADDRESS_FILTER: "RESET_ADDRESS_FILTER",

    SET_CITY_FILTER: "SET_CITY_FILTER",
    RESET_CITY_FILTER: "RESET_CITY_FILTER",

    SET_COUNTRY_FILTER: "SET_COUNTRY_FILTER",
    RESET_COUNTRY_FILTER: "RESET_COUNTRY_FILTER",

    SET_CLIENT_ID: "SET_CLIENT_ID",
    RESET_CLIENT_ID: "RESET_CLIENT_ID",

    SET_SORT: "SET_SORT",
    RESET_SORT: "RESET_SORT",

    SET_PAGING: "SET_PAGING",
};

const clientsReducer = (state, action) => {
    if (
        (action.type !== actionTypes.SET_PAGING) 
    )
        state.page = 1;
    switch (action.type) {
        case actionTypes.SET_PAGING:
            state.pageSize = action.payload.pageSize;
            state.page = action.payload.pageIndex + 1;
            break;
        case actionTypes.SET_SORT:
            state.orderColumn = action.payload[0].id;
            state.descending = action.payload[0].desc;
            break;
        case actionTypes.RESET_SORT:
            state.orderColumn = "";
            state.descending = true;
            break;
        case actionTypes.SET_NAME_FILTER:
            state.filter.name = action.payload;
            break;
        case actionTypes.RESET_NAME_FILTER:
            delete state.filter.name;
            break;
        case actionTypes.SET_ADDRESS_FILTER:
            state.filter.address = action.payload;
            break;
        case actionTypes.RESET_ADDRESS_FILTER:
            delete state.filter.address;
            break;
        case actionTypes.SET_CITY_FILTER:
            state.filter.city = action.payload;
            break;
        case actionTypes.RESET_CITY_FILTER:
            delete state.filter.city;
            break;
        case actionTypes.SET_COUNTRY_FILTER:
            state.filter.country = action.payload;
            break;
        case actionTypes.RESET_COUNTRY_FILTER:
            delete state.filter.country;
            break;
        default:
            return state;
    }
};

const ClientsListing = () => {
    const { t } = useTranslation("screens");
    const history = useHistory();
    const [selectedClient, setSelectedClient] = useState(null);
    useBreadcrumbs(ClientsBreadcrumbs.getBreadcrumbs(t, "1"));

    const {
        clients,
        isLoading,
        error,
        loadClients,
        countryData,
        loadCountries,
        details,
        deleteClient,
    } = useStateModule(clientsListStateModule);

    const [locationsListing, ] = useDataReducer(
        ClientsService.getClientsLocations
    );

    useEffect(() => {
        loadCountries();
    }, [loadCountries]);

    const toggleClientEdit = async (clientId) => {
        const result = await ClientsService.getClientByIdAsync(clientId);
        if (result && result.error) toast.error(t(result.error.message));
        else {
            ModalWizard.show(
                ClientWizard(
                    t,
                    result.data.data[0],
                    countryData.countries,
                    true
                ),
                {
                    onSubmit: async (values) => {
                        const result = await ClientsService.updateClientAsync(
                            values
                        );
                        if (result && result.error) toast.error(t(result.error.message));
                        else {
                            toast.success(
                                t("actions:dialogs.updateClient.success")
                            );
                            ModalWizard.hide();
                            loadClients();
                        }
                    },
                    onCancel: async () => {
                        ModalWizard.hidePrompt();
                    },
                }
            );
        }
    };

    const { modalProps, toggleOpen } = useModal({
        title: t("actions:dialogs.deleteClient.title"),
        onConfirm: async (clientId) => {
            const result = await deleteClient(clientId);

            if (result && result.error) toast.error(t(result.error.message));
            else {
                toast.success(t("actions:dialogs.deleteClient.success"));
                loadClients(clientsState);
            }
            toggleOpen();
        },
        onCancel: () => {
            toggleOpen();
        },
        confirmText: t("actions:dialogs.deleteUnit.confirmText"),
    });

    const toggleClientDelete = async (row, value) => {
        setSelectedClient(row.original);
        toggleOpen(value);
    };

    const clientsColumns = useMemo(() => {
        return ClientsColumns.getColumns(t, "clientColumns", {
            toggleClientEdit,
            toggleClientDelete,
        });
    }, [t]);

    const [clientsState, clientsDispatch] = useImmerReducer(clientsReducer, {
        filter: {},
        page: 1,
        pageSize: 10,
        orderColumn: "clientName",
        descending: false,
    });

    useEffect(() => {
        loadClients(clientsState);
    }, [loadClients, clientsState]);

    const clientsFilterAction = (
        <AuthorizeComponent
            roles={[
                Roles.InstallerCompanyAdmin,
                Roles.InstallerCompanyUser,
                Roles.InstallerCompanyTechnician,
            ]}
        >
            <ActionButton
                className="primary"
                // icon="/images/actions/icon-add.svg"
                iconSize="12px"
                data-test="add-client"
                onClick={(ev) => {
                    ev.preventDefault();
                    ModalWizard.show(
                        ClientWizard(t, null, countryData.countries),
                        {
                            onSubmit: async (values) => {
                                const result = await ClientsService.createClientAsync(
                                    values
                                );
                                if (result && result.error)
                                    toast.error(t(result.error.message));
                                else {
                                    toast.success(
                                        t(
                                            "actions:dialogs.createClient.success"
                                        )
                                    );
                                    ModalWizard.hide();
                                    loadClients();
                                }
                            },
                            onCancel: async () => {
                                ModalWizard.hidePrompt();
                            },
                        }
                    );
                }}
                tooltip={t("screens:clients.tooltip.add")}
            >
                {t("forms:clients.addClient")}
            </ActionButton>
        </AuthorizeComponent>
    );

    const clientsPageChange = usePageChange(clientsDispatch, clientsState);

    const [detailViewIsOpened, setDetailViewIsOpened] = useState(false);

    const onClientSelect = useCallback(
        (row) => {
            const selectRow = (row) => {
                history.push(
                    RouteCreator.clients.locations(row.original.clientId)
                );
            };
            selectRow(row);
        },
        [history]
    );

    const sortClients = (sortBy) => {
        sortBy.length > 0
            ? clientsDispatch({ type: actionTypes.SET_SORT, payload: sortBy })
            : clientsDispatch({ type: actionTypes.RESET_SORT });
    };

    const currentClientsSortBy = useMemo(() => {
        return [
            {
                id: clientsState.orderColumn,
                desc: clientsState.descending,
            },
        ];
    }, [clientsState.orderColumn, clientsState.descending]);

    const closeSideBar = () => {
        setDetailViewIsOpened(false);
    };


    return (
        <>
            <Helmet>
                    <title>{t("seo:clients.title")}</title>
                </Helmet>
            <ListingPage
                columns={clientsColumns}
                tableState={{
                    data: clients.data,
                    loading: isLoading,
                    error: error,
                }}
                paging={{
                    enablePaging: true,
                    pageCount: clients.totalPages,
                    pageSize: clients.pageSize,
                    onPageChange: clientsPageChange,
                    currentPageIndex: clients.currentPage
                        ? clients.currentPage - 1
                        : 0,
                }}
                filterTitle={t("filters:clients.filterTitle")}
                filterComponent={() => {
                    return (
                        <ClientsFilter
                            filterState={clientsState.filter}
                            dispatch={clientsDispatch}
                            actionTypes={actionTypes}
                            screen="1"
                            countryData={countryData}
                            actions={clientsFilterAction}
                        />
                    );
                }}
                onSelect={onClientSelect}
                sorting={{
                    enableSorting: true,
                    onSortByChange: sortClients,
                    currentSortBy: currentClientsSortBy,
                }}
                detailView={
                    <ClientDetailSideBar
                        client={details}
                        locations={locationsListing}
                    />
                }
                detailViewIsOpened={detailViewIsOpened}
                closeSideBar={closeSideBar}
                detailSideBarTitle={t("sidebar:title.client.title")}
            />
            <ModalDialog {...modalProps}>
                <p>{t("actions:dialogs.deleteClient.text")}</p>
                <p style={{ marginLeft: "10px" }}>
                    {selectedClient &&
                        `• ${selectedClient.clientName} | ${selectedClient.vat}`}
                </p>
            </ModalDialog>
        </>
    );
};

export default ClientsListing;
