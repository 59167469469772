import React from "react";
import Flex from "../../../../components/layout/flex/Flex";
import AuthorizeComponent from "../../../../core/AuthorizeComponent";
import Roles from "../../../../core/enums/Roles";

const StockListFooterActions = ({
                                    filteredStockItems,
                                    selectedRows,
                                    gasSelectionIsValid
                                }) => {
    const footerButton = (
        <>
            <AuthorizeComponent roles={[Roles.InstallerCompanyAdmin, Roles.InstallerCompanyUser]}>
                <Flex.Column>
                    <Flex.Row color="red">
                        {!gasSelectionIsValid(selectedRows) && (
                            <div>
                                <p>
                                    Only gases that meet the following prerequisites
                                    can be bundled:
                                </p>
                                <ol>
                                    <li>All gases have the same gas type</li>
                                    <li>All gases have the same grade</li>
                                    <li>
                                        All gases either have or don't have the
                                        quality test done
                                    </li>
                                </ol>
                            </div>
                        )}
                    </Flex.Row>
                </Flex.Column>
            </AuthorizeComponent>
        </>
    );

    if (filteredStockItems.length > 0) {
        return footerButton;
    }
    return null;
};

export default StockListFooterActions;
