import PropTypes from "prop-types";
import React from "react";
import { InputWrapper } from "../Stylings";
import {
    ClearableInput,
    GalleryUploader,
    Input,
    Label,
    SearchInput,
    TextArea,
    Uploader,
    ValidationError,
    SingleFileUploader
} from "./index";

const InputElement = React.forwardRef((props, ref) => {
    let {
        labelText,
        name,
        errors,
        touched,
        values,
        handleChange,
        handleBlur,
        wrapperVariant,
        disabled,
        placeholder,
        style,
        min,
        autoComplete,
        action,
        type,
        accept,
        multiple,
        setFieldValue,
        title,
        searchFn,
        defaultValue,
        pattern,
        className,
        noLabel,
        itemSelected,
        direction,
        autoHeight,
        elementStyle,
        ...rest
    } = props;

    let value = props.value ? props.value : values && values[name];
    if (value === null) {
        value = "";
    }
    let error = props.error ? props.error : errors ? errors[name] : null;
    let isTouched = touched ? touched[name] : false;
    if (autoComplete === false) autoComplete = "new-" + (name || props.id);

    const Element =
        type === "textarea"
            ? TextArea
            : type === "gallery"
            ? GalleryUploader
            : type === "file" || type === "image"
            ? Uploader
            : type === "singlefile"
            ? SingleFileUploader
            : type === "search"
            ? SearchInput
            : type === "filter"
            ? ClearableInput
            : Input;

    return (
        <InputWrapper
            style={style}
            className={`${wrapperVariant ? wrapperVariant : ""} ${
                error ? "error" : ""
            }`}
        >
            {!noLabel && (
                <Label htmlFor={name} className={error && "error"}>
                    {labelText}
                </Label>
            )}
            <Element
                id={props.id}
                type={type}
                name={props.name}
                value={value}
                onChange={handleChange}
                onKeyUp={handleChange}
                onBlur={handleBlur}
                disabled={disabled && "disabled"}
                placeholder={placeholder}
                autoComplete={autoComplete}
                min={min}
                hasAction={action !== undefined}
                ref={ref}
                setFieldValue={setFieldValue}
                values={values}
                accept={accept}
                multiple={multiple}
                title={title}
                searchFn={searchFn}
                defaultValue={defaultValue}
                pattern={pattern}
                className={className}
                data-password={type === "password"}
                itemSelected={itemSelected}
                data-test="main-element-anchor"
                direction={direction}
                autoHeight={autoHeight}
                style={elementStyle}
                {...rest}
            />

            {error && (
                <ValidationError
                    className={rest.pwaBig ? "pwaBig" : ""}
                    touched={isTouched}
                    error={error}
                    isFieldError={true}
                />
            )}
            {action && action}
        </InputWrapper>
    );
});

export default InputElement;

InputElement.propTypes = {
    labelText: PropTypes.any.isRequired,
    name: PropTypes.any.isRequired,
    type: PropTypes.any.isRequired,
};
