import React from "react";
import { useTranslation } from "react-i18next";
import SidebarField from "../../../../../components/elements/sidebar/SidebarField";
import SidebarTitle from "../../../../../components/elements/sidebar/SidebarTitle";
import AccordionWrapper from "../../../../../components/toggle-view/AccordionWrapper";
import useAccordionGroup from "../../../../../hooks/_shared/useAccordionGroup";

const AdminDetailSideBar = ({ adminUser }) => {
    const { adminData } = adminUser;
    let { fullName, username, userStatus, roleNames, email, phoneNumber } =
        adminUser.adminData;
    const { t } = useTranslation("sidebar");
    let sideBarRoles = [];
    const [openAccordion, setOpenAccordion] = useAccordionGroup();
    if (roleNames) {
        sideBarRoles = roleNames.map((role) => {
            return <>{t("common:roles." + role)}</>;
        });
    }

    return (
        <>
            {adminData && (
                <>
                    <SidebarTitle title={username} />
                    <AccordionWrapper
                        title={t("title.userDetails")}
                        id="userDetails"
                        isOpen={openAccordion}
                        onToggle={setOpenAccordion}
                        variant="sidebar"
                    >
                        <SidebarField
                            label={t("label.userName")}
                            value={username}
                        />
                        <SidebarField
                            label={t("label.fullName")}
                            value={fullName}
                        />
                        <SidebarField
                            label={t("label.status")}
                            value={
                                userStatus &&
                                t("common:companyActivation." + userStatus)
                            }
                        />
                    </AccordionWrapper>
                    <AccordionWrapper
                        title={t("title.contactDetails")}
                        id="contactDetails"
                        isOpen={openAccordion}
                        onToggle={setOpenAccordion}
                        variant="sidebar"
                    >
                        <SidebarField label={t("label.email")} value={email} />
                        <SidebarField
                            label={t("label.phoneNumber")}
                            value={phoneNumber}
                        />
                        <SidebarField
                            label={t("label.companyRole")}
                            multipleValues={sideBarRoles}
                        />
                    </AccordionWrapper>
                </>
            )}
        </>
    );
};

export default AdminDetailSideBar;
