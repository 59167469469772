import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AccordionWrapper from "../../../../components/toggle-view/AccordionWrapper";
import BidsListing from "../../../../components/bids/BidsListing";
import SidebarField from "../../../../components/elements/sidebar/SidebarField";
import {
    Num,
    NumInfo,
    NumSection,
} from "../../../../components/elements/sidebar/SidebarStyle";
import DetailSideBar from "../../../../components/modal/DetailSideBar";
import SingleBidDetailSideBar from "./SingleBidDetailSideBar";
import useClickAway from "../../../../hooks/_shared/useClickAway";
import helpers from "../../../../core/helpers";
import { VSpace } from "../../../pwa/components/StyledElements";
import Button from "../../../../components/elements/buttons/Button";
import styled from "styled-components";
import {
    BidGradeWrapper,
    BidGrade,
    BigHandlingCosts,
} from "../../../../components/bids/BidGrade";
import Slider from "../../../../components/elements/general/Slider";
import { getApiUrl } from "../../../../services/getAxios";
import useSharedState from "../../../../hooks/_shared/useSharedState";
import useAccordionGroup from "../../../../hooks/_shared/useAccordionGroup";

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
`;

const DownloadWrapper = styled.div`
    display: flex;
    justify-content: space-between;

    &.pad {
        padding: 25px 15px;
    }

    &.big > * {
        font-size: 14px !important;
    }

    & > div {
        font-size: 12px;
        color: #333333;
        font-weight: 600;
    }
`;

const DownloadLink = styled.a`
    color: #2294d2;
    text-decoration: none;
    font-size: 12px;
    &.big {
        font-size: 14px;
    }
`;

const splitTag = (tag) => {
    if (!tag || tag.length < 8) return tag;
    return tag.substring(0, 4) + " " + tag.substring(4, 8);
};

const getFileName = (url) => {
    const parts = url.split("/");
    return parts[parts.length - 1];
};

const MyOffersDetailSideBar = ({
    selectedOffer,
    offer,
    isLoading,
    sellToggleOpen,
    disputeToggleOpen,
    closeSideBar,
    openAccordionId = null,
}) => {
    const { t } = useTranslation("sidebar");
    const shouldShow = !isLoading && offer;
    const [globalState] = useSharedState("global");
    const [openAccordion, setOpenAccordion] =
        useAccordionGroup(openAccordionId);

    const [singleBidViewIsOpened, setSingleBidviewIsOpened] = useState(false);
    const [singleBidData, setSingleBidData] = useState(null);

    const openSingleBid = (bid) => {
        setSingleBidviewIsOpened(!singleBidViewIsOpened);
        setSingleBidData(bid);
    };

    const closeSingleBidSidebar = () => {
        setSingleBidviewIsOpened(false);
    };

    const bidRef = useRef(null);

    useClickAway(bidRef, (ev) => {
        closeSingleBidSidebar();
    });

    const numQualityTests = () => {
        if (!offer.skUs) return 0;
        let cnt = 0;
        offer.skUs.forEach((offer) => {
            if (offer.qualityTestDone) cnt++;
        });
        return cnt;
    };

    if (selectedOffer.offer && selectedOffer.offer.offerStatus !== undefined) {
        selectedOffer.offer.status = selectedOffer.offer.offerStatus;
    }

    return (
        <>
            {shouldShow && (
                <>
                    <DetailSideBar
                        sidebarId="single-bid"
                        detailIsOpened={singleBidViewIsOpened}
                        closeSideBar={closeSingleBidSidebar}
                        ref={bidRef}
                        variant="nested"
                        title={t("title.bid.details")}
                        shouldRender={
                            selectedOffer.offer &&
                            selectedOffer.offer.status === 0
                        }
                    >
                        <SingleBidDetailSideBar
                            bid={singleBidData}
                            offer={selectedOffer.offer}
                            closeSingleBidSideBar={closeSingleBidSidebar}
                            closeOfferSideBar={closeSideBar}
                        />
                    </DetailSideBar>
                    <AccordionWrapper
                        title={t("title.offer.details")}
                        id="details"
                        isOpen={openAccordion}
                        onToggle={setOpenAccordion}
                    >
                        {selectedOffer.offer && (
                            <>
                                <SidebarField
                                    label={t("label.type")}
                                    value={
                                        selectedOffer.offer
                                            .refrigerantGasTypeCode
                                    }
                                />
                                <SidebarField
                                    label={t("label.numberOfCylinders")}
                                    value={
                                        selectedOffer.offer.numberOfCylinders
                                    }
                                />
                                <SidebarField
                                    label={t("label.netWeight")}
                                    value={`${selectedOffer.offer.maximumQuantity.toFixed(
                                        2
                                    )} kg`}
                                />
                                <SidebarField
                                    label={t("label.totalExtractedQuantity")}
                                    value={`${selectedOffer.offer.quantity.toFixed(
                                        2
                                    )} kg`}
                                />
                                <SidebarField
                                    label={t("label.extraction.qualityGrade")}
                                    value={
                                        selectedOffer.offer.pressumedGradeName
                                    }
                                />
                                <SidebarField
                                    label={t(
                                        "label.extraction.qualityEvaluation"
                                    )}
                                    value={
                                        offer.hasAnalysis
                                            ? t("filters:analysis.measurement")
                                            : t(
                                                  "filters:analysis.selfAssessment"
                                              )
                                    }
                                />
                            </>
                        )}
                    </AccordionWrapper>

                    {offer.skUs && (
                        <AccordionWrapper
                            title={t("title.contamination")}
                            id="contamination"
                            isOpen={openAccordion}
                            onToggle={setOpenAccordion}
                        >
                            <Slider
                                style={{ padding: "25px 20px 0px" }}
                                prefix="slide-"
                                title={t(
                                    "sidebar:title.extraction.extractionCylinder"
                                )}
                                captions={offer.skUs.map((sku) =>
                                    splitTag(sku.tagId)
                                )}
                            >
                                {offer.skUs.map((sku, idx) => {
                                    let cnt = 0;
                                    return (
                                        <div id={`slide-${idx + 1}`} key={idx}>
                                            <div>
                                                <SidebarField
                                                    labelvariant="pl-0"
                                                    wrapperVariant="mt-0"
                                                    label={t(
                                                        "label.extractedQuantity"
                                                    )}
                                                    value={
                                                        sku.quantity.toFixed(
                                                            2
                                                        ) + " kg"
                                                    }
                                                />
                                                {!sku.saIsContaminated &&
                                                    !sku.saIsMixture &&
                                                    !sku.saHasR22 && (
                                                        <NumSection>
                                                            <Num className="tight">
                                                                N/A
                                                            </Num>
                                                            <NumInfo>
                                                                {t(
                                                                    "actions:noContamination"
                                                                )}
                                                            </NumInfo>
                                                        </NumSection>
                                                    )}

                                                {sku.saIsContaminated && (
                                                    <NumSection>
                                                        <Num>{++cnt}</Num>
                                                        <NumInfo>
                                                            {t(
                                                                "screens:stock.aGradeText"
                                                            )}
                                                        </NumInfo>
                                                    </NumSection>
                                                )}
                                                {sku.saIsMixture && (
                                                    <NumSection>
                                                        <Num>{++cnt}</Num>
                                                        <NumInfo>
                                                            {t(
                                                                "screens:stock.bGradeText"
                                                            )}
                                                        </NumInfo>
                                                    </NumSection>
                                                )}
                                                {sku.saHasR22 && (
                                                    <NumSection>
                                                        <Num>{++cnt}</Num>
                                                        <NumInfo>
                                                            {t(
                                                                "screens:stock.cGradeText"
                                                            )}
                                                        </NumInfo>
                                                    </NumSection>
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                            </Slider>
                        </AccordionWrapper>
                    )}

                    {/*// Show only if Offered */}
                    {selectedOffer.offer && selectedOffer.offer.status === 0 && (
                        <AccordionWrapper
                            title={t("title.bid.receivedBids")}
                            id="receivedBids"
                            isOpen={openAccordion}
                            onToggle={setOpenAccordion}
                        >
                            {offer && offer.offerId && (
                                <BidsListing
                                    offer={offer}
                                    toggleBidView={openSingleBid}
                                />
                            )}
                        </AccordionWrapper>
                    )}

                    {/*//Show only if Accepted*/}
                    {selectedOffer.bid && selectedOffer.bid.bidStatus === 1 && (
                        <>
                            <AccordionWrapper
                                title={t("title.bid.details")}
                                id="bid.details"
                                isOpen={openAccordion}
                                onToggle={setOpenAccordion}
                            >
                                <SidebarField
                                    label={t("label.bid.date")}
                                    value={helpers.toLocalDateIgnoreTime(
                                        selectedOffer.bid.bidDateTime
                                    )}
                                />
                                <VSpace size="10px" />
                                {selectedOffer.isCleaning ? (
                                    <>
                                        <BidGradeWrapper>
                                            <BidGrade
                                                title={t(
                                                    "forms:bid.wizard.betterCleaning"
                                                )}
                                                grade="a"
                                                amount={
                                                    selectedOffer.bid
                                                        .offeredPriceGradeA
                                                }
                                                quantity={
                                                    selectedOffer.offer
                                                        .maximumQuantity
                                                }
                                                bidCompanyCountryId={
                                                    selectedOffer.bid
                                                        .bidCompanyCountryId
                                                }
                                            />
                                            <BidGrade
                                                title={t(
                                                    "forms:bid.wizard.standardCleaning"
                                                )}
                                                grade="b"
                                                amount={
                                                    selectedOffer.bid
                                                        .offeredPriceGradeB
                                                }
                                                quantity={
                                                    selectedOffer.offer
                                                        .maximumQuantity
                                                }
                                                bidCompanyCountryId={
                                                    selectedOffer.bid
                                                        .bidCompanyCountryId
                                                }
                                            />
                                        </BidGradeWrapper>
                                        {selectedOffer.bid && (
                                            <BigHandlingCosts
                                                price={
                                                    selectedOffer.bid
                                                        .offeredPriceGradeC
                                                }
                                                quantity={
                                                    selectedOffer.offer
                                                        .maximumQuantity
                                                }
                                                administrativeCosts={
                                                    selectedOffer.bid
                                                        .administrationCosts
                                                }
                                                transportationCosts={
                                                    selectedOffer.bid
                                                        .transportationCosts
                                                }
                                                bidCompanyCountryId={
                                                    selectedOffer.bid
                                                        .bidCompanyCountryId
                                                }
                                                title={t(
                                                    "table:headers.handlingCosts"
                                                )}
                                            />
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <BidGradeWrapper>
                                            <BidGrade
                                                grade="a"
                                                amount={
                                                    selectedOffer.bid
                                                        .offeredPriceGradeA
                                                }
                                                quantity={
                                                    selectedOffer.offer
                                                        .maximumQuantity
                                                }
                                                bidCompanyCountryId={
                                                    selectedOffer.bid
                                                        .bidCompanyCountryId
                                                }
                                            />
                                            <BidGrade
                                                grade="b"
                                                amount={
                                                    selectedOffer.bid
                                                        .offeredPriceGradeB
                                                }
                                                quantity={
                                                    selectedOffer.offer
                                                        .maximumQuantity
                                                }
                                                bidCompanyCountryId={
                                                    selectedOffer.bid
                                                        .bidCompanyCountryId
                                                }
                                            />
                                            <BidGrade
                                                grade="c"
                                                amount={
                                                    selectedOffer.bid
                                                        .offeredPriceGradeC
                                                }
                                                quantity={
                                                    selectedOffer.offer
                                                        .maximumQuantity
                                                }
                                                bidCompanyCountryId={
                                                    selectedOffer.bid
                                                        .bidCompanyCountryId
                                                }
                                            />
                                        </BidGradeWrapper>
                                        {selectedOffer.bid && (
                                            <BigHandlingCosts
                                                price={
                                                    selectedOffer.bid
                                                        .offeredPriceGradeC
                                                }
                                                quantity={
                                                    selectedOffer.offer
                                                        .maximumQuantity
                                                }
                                                administrativeCosts={
                                                    selectedOffer.bid
                                                        .administrationCosts
                                                }
                                                transportationCosts={
                                                    selectedOffer.bid
                                                        .transportationCosts
                                                }
                                                bidCompanyCountryId={
                                                    selectedOffer.bid
                                                        .bidCompanyCountryId
                                                }
                                                title={t(
                                                    "table:headers.handlingCosts"
                                                )}
                                            />
                                        )}
                                    </>
                                )}
                            </AccordionWrapper>

                            {offer.skUs && (
                                <AccordionWrapper
                                    title={t(
                                        "title.quality.qualityConfirmation"
                                    )}
                                    id="qualityConfirmation"
                                    isOpen={openAccordion}
                                    onToggle={setOpenAccordion}
                                >
                                    {numQualityTests() > 0 && (
                                        <DownloadWrapper className="pad big">
                                            <div>
                                                {numQualityTests()}{" "}
                                                {numQualityTests() > 1
                                                    ? t(
                                                          "actions:availableTests"
                                                      )
                                                    : t(
                                                          "actions:availableTest"
                                                      )}
                                            </div>
                                            <DownloadLink
                                                href={`${getApiUrl}/Marketplace/getAnalysesZip/${offer.offerId}`}
                                                target="_blank"
                                            >
                                                {t("actions:downloadAll")}
                                            </DownloadLink>
                                        </DownloadWrapper>
                                    )}

                                    <Slider
                                        style={{
                                            padding: "25px 20px 0px",
                                        }}
                                        prefix="qslide-"
                                        title={t(
                                            "sidebar:title.extraction.extractionCylinder"
                                        )}
                                        captions={offer.skUs.map((sku) =>
                                            splitTag(sku.tagId)
                                        )}
                                    >
                                        {offer.skUs.map((sku, idx) => {
                                            return (
                                                <div
                                                    id={`qslide-${idx + 1}`}
                                                    key={idx}
                                                >
                                                    <DownloadWrapper
                                                        id={`qslide-${idx + 1}`}
                                                        key={idx}
                                                    >
                                                        <div>
                                                            {getFileName(
                                                                sku.qualityTestUrl
                                                            )}
                                                        </div>
                                                        {sku.qualityTestDone ? (
                                                            <DownloadLink
                                                                href={
                                                                    sku.qualityTestUrl
                                                                }
                                                                target="_blank"
                                                            >
                                                                {t(
                                                                    "actions:download"
                                                                )}
                                                            </DownloadLink>
                                                        ) : (
                                                            <div>-</div>
                                                        )}
                                                    </DownloadWrapper>
                                                    {sku.buyerCylindersAnalysis && (
                                                        <div>
                                                            <SidebarField
                                                                labelvariant="pl-0"
                                                                valueVariant={
                                                                    sku
                                                                        .buyerCylindersAnalysis
                                                                        .gasTypeCode !==
                                                                    offer.refrigerantGasTypeCode
                                                                        ? "wrong"
                                                                        : ""
                                                                }
                                                                label={t(
                                                                    "label.analysisGasType"
                                                                )}
                                                                value={
                                                                    sku
                                                                        .buyerCylindersAnalysis
                                                                        .gasTypeCode
                                                                }
                                                            />

                                                            <SidebarField
                                                                labelvariant="pl-0"
                                                                label={t(
                                                                    "label.analysisQuantity"
                                                                )}
                                                                valueVariant={
                                                                    sku
                                                                        .buyerCylindersAnalysis
                                                                        .quantity !==
                                                                    sku.quantity
                                                                        ? "wrong"
                                                                        : ""
                                                                }
                                                                value={
                                                                    sku.buyerCylindersAnalysis.quantity.toFixed(
                                                                        2
                                                                    ) + " kg"
                                                                }
                                                            />
                                                            <SidebarField
                                                                labelvariant="pl-0"
                                                                label={t(
                                                                    "label.analysisQualityGrade"
                                                                )}
                                                                valueVariant={
                                                                    sku
                                                                        .buyerCylindersAnalysis
                                                                        .gradeName !==
                                                                    offer.pressumedGradeName
                                                                        ? "wrong"
                                                                        : ""
                                                                }
                                                                value={
                                                                    sku
                                                                        .buyerCylindersAnalysis
                                                                        .gradeName
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </Slider>
                                </AccordionWrapper>
                            )}

                            <AccordionWrapper
                                title={
                                    selectedOffer.isCleaning
                                        ? t("table:headers.serviceProvider")
                                        : t("title.buyer")
                                }
                                id="serviceProvider"
                                isOpen={openAccordion}
                                onToggle={setOpenAccordion}
                            >
                                <SidebarField
                                    label={t("title.company")}
                                    value={selectedOffer.bid.bidCompanyName}
                                />
                                <SidebarField
                                    label={t("label.companyVAT")}
                                    value={selectedOffer.bid.bidCompanyVAT}
                                />
                                <SidebarField
                                    label={t("label.companyStreet")}
                                    value={selectedOffer.bid.bidCompanyAddress}
                                />
                                <SidebarField
                                    label={t("label.companyCity")}
                                    value={selectedOffer.bid.bidCompanyCity}
                                />
                                <SidebarField
                                    label={t("label.companyState")}
                                    value={selectedOffer.bid.bidCompanyRegion}
                                />
                                <SidebarField
                                    label={t("label.companyZIP")}
                                    value={
                                        selectedOffer.bid.bidCompanyPostalCode
                                    }
                                />
                                {selectedOffer.bid.bidCompanyCountry && (
                                    <SidebarField
                                        label={t("label.companyCountry")}
                                        value={
                                            selectedOffer.bid.bidCompanyCountry
                                        }
                                    />
                                )}

                                <SidebarField
                                    label={t("label.companyContactPersonName")}
                                    value={
                                        selectedOffer.bid
                                            .bidCompanyContactPerson
                                    }
                                />
                                <SidebarField
                                    label={t("label.companyEmail")}
                                    value={selectedOffer.bid.bidCompanyEmail}
                                />
                                <SidebarField
                                    label={t("label.companyPhone")}
                                    value={selectedOffer.bid.bidCompanyPhone}
                                />
                            </AccordionWrapper>
                        </>
                    )}
                    {selectedOffer.offer && selectedOffer.offer.status === 6 && (
                        <ButtonWrapper justifyContent="space-between">
                            <Button
                                disabled={
                                    selectedOffer.offer.dealExpiration >
                                        new Date().toISOString() &&
                                    (!globalState.hasValidCompanyCertificate ||
                                        !globalState.hasCertificateRights)
                                }
                                className="primary--action sidebar"
                                onClick={() => {
                                    sellToggleOpen([selectedOffer.offer]);
                                }}
                            >
                                {t("actions:finishTransaction")}
                            </Button>
                            <Button
                                disabled={
                                    !globalState.hasValidCompanyCertificate
                                }
                                className="tertiary tertiary--negative sidebar"
                                onClick={() =>
                                    disputeToggleOpen(selectedOffer.offer)
                                }
                            >
                                {t("actions:dispute.start")}
                            </Button>
                        </ButtonWrapper>
                    )}
                </>
            )}
        </>
    );
};

export default MyOffersDetailSideBar;
