import React from "react";
import { useTranslation } from "react-i18next";
import ClearableInput from "../../../../../components/elements/form-elements/ClearableInput";
import Label from "../../../../../components/elements/form-elements/Label";
import Select from "../../../../../components/elements/form-elements/Select";
import Filters from "../../../../../components/filter/Filters";
import useDebouncedCallback from "../../../../../hooks/_shared/useDebouncedCallback";
import actionTypes from "./filterActionsTypes";
import globals from "../../../../../globalVars";

const UserFilter = ({
    filterState,
    dispatch,
    roles,
    showFgasFilter = false,
    showStatusFilter = true,
    isMasterAdmin = false,
    isAdminList = false,
}) => {
    const { t } = useTranslation("filters");
    const [emailChanged] = useDebouncedCallback((text) => {
        dispatch({ type: actionTypes.SEARCH_EMAIL, payload: text.trim() });
    });
    const [fullNameChanged] = useDebouncedCallback((text) => {
        dispatch({ type: actionTypes.SEARCH_FULLNAME, payload: text.trim() });
    });
    const [companyNameChanged] = useDebouncedCallback((text) => {
        dispatch({ type: actionTypes.SEARCH_COMPANYNAME, payload: text.trim() });
    });

    /* some filters have additional property "filter" and some don't have */
    const hasFilterObject = filterState.filter !== undefined;
    let email = filterState.email;
    let fullName = filterState.fullName;
    let companyName = filterState.companyName;

    if (hasFilterObject) {
        email = filterState.filter.email;
        fullName = filterState.filter.fullName;
    }

    return (
        <>
            <Filters.Wrapper>
                <Filters.Item>
                    <Label htmlFor="fullName" className="sr-only">
                        {t("fullName")}
                    </Label>
                    <ClearableInput
                        key="fullName"
                        name="fullName"
                        type="text"
                        defaultValue={fullName}
                        onChange={(evt) => {
                            fullNameChanged(evt.currentTarget.value);
                        }}
                        placeholder={t("fullName")}
                        className="filters"
                    />
                </Filters.Item>
                <Filters.Item>
                    <Label htmlFor="email" className="sr-only">
                        {t("email")}
                    </Label>
                    <ClearableInput
                        key="email"
                        name="email"
                        type="text"
                        defaultValue={email}
                        onChange={(evt) => {
                            emailChanged(evt.currentTarget.value);
                        }}
                        placeholder={t("email")}
                        className="filters"
                    />
                </Filters.Item>
                {showFgasFilter && (
                    <Filters.Item>
                        <Select
                            key="hasCertificate"
                            name="hasCertificate"
                            errors={[]}
                            touched={[]}
                            defaultItem={{
                                key: "all",
                                value: -1,
                                label: t("certificate.certificates"),
                            }}
                            defaultValue={filterState.hasCertificate}
                            options={[
                                {
                                    key: 0,
                                    value: 0,
                                    label: t("certificate.withCertificate"),
                                },
                                {
                                    key: 1,
                                    value: 1,
                                    label: t("certificate.withoutCertificate"),
                                },
                            ]}
                            handleChange={(evt) =>
                                dispatch({
                                    type: actionTypes.SEARCH_CERTIFICATE,
                                    payload: evt.currentTarget.value,
                                })
                            }
                            className="filters"
                            labelText={t("status")}
                            labelClassName="sr-only"
                        />
                    </Filters.Item>
                )}
                {!isAdminList && (
                    <Filters.Item>
                        <Label htmlFor="companyName" className="sr-only">
                            {t("companyName")}
                        </Label>
                        <ClearableInput
                            key="companyName"
                            name="companyName"
                            type="text"
                            defaultValue={companyName}
                            onChange={(evt) => {
                                companyNameChanged(evt.currentTarget.value);
                            }}
                            placeholder={t("company.companyName")}
                            className="filters"
                        />
                    </Filters.Item>
                )}
                <Filters.Item>
                    <Select
                        key="role"
                        name="role"
                        errors={[]}
                        touched={[]}
                        defaultItem={{
                            key: "all",
                            value: "",
                            label: t("roles.allRoles"),
                        }}
                        defaultValue={
                            filterState.filter
                                ? filterState.filter.roleNames[0]
                                : ""
                        }
                        options={roles}
                        handleChange={(evt) =>
                            dispatch({
                                type: actionTypes.SEARCH_ROLE,
                                payload: evt.currentTarget.value,
                            })
                        }
                        className="filters"
                        labelText={t("roles.allRoles")}
                        labelClassName="sr-only"
                    />
                </Filters.Item>
                {!isAdminList && (
                    <Filters.Item>
                        <Select
                            key="country"
                            name="countryId"
                            errors={[]}
                            touched={[]}
                            defaultItem={{
                                key: "all",
                                value: "",
                                label: t("filters:clients.country"),
                            }}
                            defaultValue={filterState.userStatus}
                            options={
                                globals.countries
                                    ? globals.countries.map(
                                          (country, index) => ({
                                              key: country.id,
                                              value: country.id,
                                              label: country.localName,
                                          })
                                      )
                                    : []
                            }
                            handleChange={(evt) => {
                                if (evt.currentTarget.value === "") {
                                    dispatch({
                                        type: actionTypes.RESET_COUNTRY,
                                        payload: evt.currentTarget.value,
                                    });
                                } else {
                                    dispatch({
                                        type: actionTypes.SET_COUNTRY,
                                        payload: evt.currentTarget.value,
                                    });
                                }
                            }}
                            className="filters"
                            labelText={t("filters:clients.country")}
                            labelClassName="sr-only"
                        />
                    </Filters.Item>
                )}
                {showStatusFilter && (
                    <Filters.Item>
                        <Select
                            key="userStatus"
                            name="userStatus"
                            errors={[]}
                            touched={[]}
                            defaultItem={{
                                key: "all",
                                value: -1,
                                label: t("user.allUsers"),
                            }}
                            defaultValue={filterState.userStatus}
                            options={[
                                {
                                    key: 0,
                                    value: 0,
                                    label: t("user.activeUsers"),
                                },
                                {
                                    key: 1,
                                    value: 1,
                                    label: t("user.inactiveUsers"),
                                },
                            ]}
                            handleChange={(evt) =>
                                dispatch({
                                    type: actionTypes.SEARCH_STATUS,
                                    payload: evt.currentTarget.value,
                                })
                            }
                            className="filters"
                            labelText={t("status")}
                            labelClassName="sr-only"
                        />
                    </Filters.Item>
                )}
            </Filters.Wrapper>
        </>
    );
};

export default React.memo(UserFilter);
