import qs from "qs";
import { useLocation } from "react-router-dom";

const useQueryString = (name = "", defaultValue = null) => {
    const location = useLocation();
    const parsedQuery = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (!name) {
        return parsedQuery;
    } else {
        return name in parsedQuery ? parsedQuery[name] : defaultValue;
    }
};

export default useQueryString;
