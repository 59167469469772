import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ListingPage from "../../../components/data/ListingPage";
import TransactionStatus from "../../../components/elements/general/TransactionStatus";
import OfferStatus from "../../../core/enums/OfferStatus";
import useBreadcrumbs from "../../../core/state/navigation/useBreadcrumbs";
import sellRefrigerantListingStateModule from "../../../core/state/sell-refrigerant/sellRefrigerantListingStateModule";
import useStateModule from "../../../core/state/useStateModule";
import useImmerReducer from "../../../hooks/_shared/useImmerReducer";
import createDisputeSteps from "../../SalesCenter/views/components/createDisputeSteps";
import BidGradePriceCell from "../../SalesCenter/views/components/BidGradePriceCell";
import MarketplaceFilter from "../../SalesCenter/views/components/MarketplaceFilter";
import DisputesDetailSideBar from "../../SalesCenter/views/components/DisputesDetailSideBar";
import usePageChange from "../../../hooks/_shared/usePageChange";
import AuthorizeComponent from "../../../core/AuthorizeComponent";
import Roles from "../../../core/enums/Roles";
import HamburgerMenu from "../../../components/menu/HamburgerMenu";
import HamburgerMenuAction from "../../../components/menu/HamburgerMenuAction";
import ModalWizard from "../../../components/modal/ModalWizard";
import WizardDisputeSettle from "../../SalesCenter/views/components/wizard/WizardDiputeSettle";
import WizardDisputeConfirm from "../../SalesCenter/views/components/wizard/WizardDisputeConfirm";
import GradeCell from "../../../components/data/GradeCell";
import GreenCell from "../../../components/data/GreenCell";
import { Helmet } from "react-helmet";
import MarketplaceService from "../../../services/BuyRefrigerant/MarketplaceService";
import { toast } from "react-toastify";
import globals from "../../../globalVars";
import HandlingCell from "../../../components/data/HandlingCell";

const actionTypes = {
    SET_HASOFFERS: "SET_HASOFFERS",
    RESET_HASOFFERS: "RESET_HASOFFERS",
    SET_GAS_TYPE: "SET_GAS_TYPE",
    RESET_GAS_TYPE: "RESET_GAS_TYPE",
    SET_GAS_QUANTITY_TO: "SET_GAS_QUANTITY",
    RESET_GAS_QUANTITY_TO: "RESET_GAS_QUANTITY",
    SET_GAS_QUANTITY_FROM: "SET_GAS_QUANTITY_FROM",
    RESET_GAS_QUANTITY_FROM: "RESET_GAS_QUANTITY_FROM",
    SET_PRESUMED_QUALITY: "SET_PRESUMED_QUALITY",
    RESET_PRESUMED_QUALITY: "RESET_PRESUMED_QUALITY",
    SET_QUALITY_ASSURANCE: "SET_QUALITY_ASSURANCE",
    RESET_QUALITY_ASSURANCE: "RESET_QUALITY_ASSURANCE",
    SET_SORT: "SET_SORT",
    RESET_SORT: "RESET_SORT",
    SET_ACTIVETAB: "SET_ACTIVETAB",
    SET_PAGING: "SET_PAGING",
};

const disputesReducer = (state, action) => {
    if (action.type !== actionTypes.SET_PAGING) state.page = 1;
    switch (action.type) {
        case actionTypes.SET_HASOFFERS:
            state.filter.hasOffers = action.payload;
            break;
        case actionTypes.RESET_HASOFFERS:
            delete state.filter.hasOffers;
            break;
        case actionTypes.SET_ACTIVETAB:
            state.filter.offerStatuses = action.payload;
            delete state.filter.hasOffers;
            delete state.filter.gasType;
            delete state.filter.gasQuantityTo;
            delete state.filter.presumedQuality;
            delete state.filter.hasAnalysis;
            // state.orderColumn = "dealExpiration";
            // state.descending = true;
            break;
        case actionTypes.SET_PAGING:
            state.pageSize = action.payload.pageSize;
            state.page = action.payload.pageIndex + 1;
            break;
        case actionTypes.SET_SORT:
            if (action.payload[0].id === "analysisDone")
                action.payload[0].id = "hasAnalysis";
            state.orderColumn = action.payload[0].id;
            state.descending = action.payload[0].desc;
            break;
        case actionTypes.RESET_SORT:
            state.orderColumn = "";
            state.descending = true;
            break;
        case actionTypes.SET_GAS_TYPE:
            state.filter.gasType = action.payload;
            break;
        case actionTypes.RESET_GAS_TYPE:
            delete state.filter.gasType;
            break;
        case actionTypes.SET_GAS_QUANTITY_TO:
            state.filter.gasQuantityTo = Number(action.payload);
            break;
        case actionTypes.RESET_GAS_QUANTITY_TO:
            delete state.filter.gasQuantityTo;
            break;
        case actionTypes.SET_GAS_QUANTITY_FROM:
            state.filter.gasQuantityFrom = Number(action.payload);
            break;
        case actionTypes.RESET_GAS_QUANTITY_FROM:
            delete state.filter.gasQuantityFrom;
            break;
        case actionTypes.SET_PRESUMED_QUALITY:
            state.filter.presumedQuality = Number(action.payload);
            break;
        case actionTypes.RESET_PRESUMED_QUALITY:
            delete state.filter.presumedQuality;
            break;
        case actionTypes.SET_QUALITY_ASSURANCE:
            state.filter.hasAnalysis = action.payload === "true";
            break;
        case actionTypes.RESET_QUALITY_ASSURANCE:
            delete state.filter.hasAnalysis;
            break;
        default:
            return state;
    }
};

const DisputesListing = () => {
    const { t } = useTranslation("screens");
    const [openAccordionId, setOpenAccordionId] = useState();
    const {
        // sellingItems,
        isLoading,
        error,
        loadSellingItems,
        loadSellingItemById,
        loadGasTypes,
        gasTypesData,
        details,
    } = useStateModule(sellRefrigerantListingStateModule);

    useBreadcrumbs([
        {
            text: t("navigation:disputes"),
        },
    ]);

    const [sellingItems, setSellingItems] = useState({ data: [] });

    const [filterState, dispatch] = useImmerReducer(disputesReducer, {
        filter: { offerStatuses: [OfferStatus.disputed] },
        pageSize: 10,
        page: 1,
        orderColumn: "disputeDateTime",
        descending: true,
    });

    const getDisputes = async () => {
        const items = await MarketplaceService.getDisputes(filterState);
        setSellingItems(items.data);
    };

    useEffect(() => {
        getDisputes();
    }, [loadGasTypes, filterState]);

    useEffect(() => {
        loadGasTypes();
    }, [loadGasTypes]);

    const columns = useMemo(() => {
        return [
            {
                Header: t("stockListing.headers.gasType"),
                accessor: "refrigerantGasTypeCode",
                Cell: GreenCell,
            },
            {
                Header: t("stockListing.headers.gasQuantity"),
                accessor: "quantity",
                Cell: ({ cell: { row, value } }) =>
                    GreenCell({
                        cell: {
                            value:
                                value.toFixed(2) +
                                ` kg / ${
                                    row.original.numberOfCylinders || "-"
                                } ${t("screens:technician.cylinders")}`,
                        },
                    }),
            },
            {
                Header: t("table:headers.grade"),
                accessor: "pressumedGradeName",
                Cell: GradeCell,
            },
            {
                Header: `${t("table:headers.bidAmounts")}/${t(
                    "sidebar:label.extraction.qualityGrade"
                )}`,
                accessor: "price",
                Cell: BidGradePriceCell,
            },
            {
                Header: t("table:headers.handlingCosts"),
                accessor: "acceptedBidInfo",
                Cell: ({ cell: { value } }) =>
                    HandlingCell(
                        value?.handlingCosts || 0,
                        setOpenAccordionId,
                        "bid.details"
                    ),
            },
            {
                Header: "Status",
                accessor: "status",
                disableSortBy: true,
                Cell: ({ cell: { row, value } }) => (
                    <TransactionStatus
                        steps={createDisputeSteps(value, t, row.original)}
                    />
                ),
            },
            {
                Header: "",
                accessor: "disputeId",
                Cell: ({ cell: { value }, row: { original } }) => {
                    const myDisputeSettle =
                        original.acceptedBidInfo.bidCompanyId ===
                        globals.companyId;
                    return (
                        <AuthorizeComponent
                            roles={[Roles.InstallerCompanyAdmin]}
                        >
                            {myDisputeSettle === true && original.status === 0 && (
                                <HamburgerMenu>
                                    <HamburgerMenuAction
                                        text={t(
                                            "actions:dialogs.dispute.settlement"
                                        )}
                                        onClick={() => {
                                            handleDisputeSettle(original);
                                        }}
                                    />
                                </HamburgerMenu>
                            )}
                            {myDisputeSettle !== true && original.status === 1 && (
                                <HamburgerMenu>
                                    <HamburgerMenuAction
                                        disabled={
                                            (myDisputeSettle === false) &
                                            (original.status === 0)
                                        }
                                        text={t(
                                            "actions:dialogs.dispute.endSettlement"
                                        )}
                                        onClick={() => {
                                            handleDisputeConfirm(original);
                                        }}
                                    />
                                </HamburgerMenu>
                            )}
                        </AuthorizeComponent>
                    );
                },
            },
        ];
    }, [filterState.filter.offerStatuses, sellingItems, t]);

    const [currentRowData, setCurrentRowData] = useState(null);
    const [detailViewIsOpened, setDetailViewIsOpened] = useState(false);

    const onSelect = useCallback(
        (row) => {
            const selectRow = async (row) => {
                setCurrentRowData(row.original);
                await loadSellingItemById(row.original.offerId);
                setDetailViewIsOpened(!detailViewIsOpened);
            };
            selectRow(row);
        },
        [details, loadSellingItemById]
    );

    const pageChange = usePageChange(dispatch, filterState);

    const currentSortBy = useMemo(() => {
        return [{ id: filterState.orderColumn, desc: filterState.descending }];
    }, [filterState.orderColumn, filterState.descending]);

    const sortChange = (sortBy) => {
        sortBy.length > 0
            ? dispatch({ type: actionTypes.SET_SORT, payload: sortBy })
            : dispatch({ type: actionTypes.RESET_SORT });
    };

    const closeSideBar = () => {
        if (detailViewIsOpened) {
            setOpenAccordionId(null);
            setDetailViewIsOpened(false);
            loadSellingItems(filterState);
        }
    };

    const handleDisputeSettle = async (dispute) => {
        const details = await MarketplaceService.getDisputeDetails(
            dispute.disputeId
        );
        if (details && details.error) {
            toast.error(t(details.error.message));
            return;
        }
        ModalWizard.show(
            WizardDisputeSettle(t, details.data.data),
            {
                onSubmit: async (values) => {
                    const results = ModalWizard.groupValues(values, "_", true);
                    results.forEach((res) => {
                        res.grade =
                            res.grade === "A" ? 0 : res.grade === "B" ? 1 : 2;
                        res.quantity = Number(res.quantity);
                    });
                    const submitted = await MarketplaceService.addDisputeSettle(
                        results
                    );
                    if (submitted && submitted.error) {
                        toast.error(t(submitted.error.message));
                        return;
                    }
                    getDisputes();
                    ModalWizard.hide();
                },
                onCancel: () => {
                    ModalWizard.hidePrompt();
                },
            },
            { style: { minHeight: "654px" } }
        );
    };

    const handleDisputeConfirm = async (dispute) => {
        const details = await MarketplaceService.getDisputeDetails(
            dispute.disputeId
        );
        if (details && details.error) {
            toast.error(t(details.error.message));
            return;
        }
        ModalWizard.show(
            WizardDisputeConfirm(t, details.data.data),
            {
                onSubmit: async (values) => {
                    const results = ModalWizard.groupValues(values, "_", true);
                    results.forEach((res) => {
                        delete res.disputeThisResult;
                    });

                    const submitted = await MarketplaceService.finishDispute({
                        details: results,
                    });
                    if (submitted && submitted.error) {
                        toast.error(t(submitted.error.message));
                        return;
                    }
                    getDisputes();
                    ModalWizard.hide();
                },
                onCancel: () => {
                    ModalWizard.hidePrompt();
                },
            },
            { style: { minHeight: "654px" } }
        );
    };

    return (
        <>
            <Helmet>
                <title>{t("seo:disputes.title")}</title>
            </Helmet>
            <ListingPage
                filterTitle={t("disputes.title")}
                filterComponent={() => {
                    return (
                        <>
                            <MarketplaceFilter
                                filterState={filterState.filter}
                                dispatch={dispatch}
                                actionTypes={actionTypes}
                                gasTypes={gasTypesData.gasTypes}
                                showMeasurement={false}
                                offerStatus={filterState.filter.offerStatuses}
                            />
                        </>
                    );
                }}
                columns={columns}
                tableState={{
                    data: sellingItems.data,
                    loading: isLoading,
                    error: error,
                }}
                noDataText={t("myOffers.noData")}
                paging={{
                    enablePaging: true,
                    pageCount: sellingItems.totalPages,
                    pageSize: sellingItems.pageSize,
                    onPageChange: pageChange,
                    currentPageIndex: sellingItems.currentPage - 1,
                }}
                sorting={{
                    enableSorting: true,
                    onSortByChange: sortChange,
                    currentSortBy: currentSortBy,
                }}
                detailSideBarTitle={`${t("sidebar:title.offerId")}: ${
                    details.sellingItemData?.offerNumber
                }`}
                detailView={
                    <DisputesDetailSideBar
                    key={detailViewIsOpened}    
                    openAccordionId={openAccordionId}
                        selectedDispute={currentRowData}
                        offer={details.sellingItemData}
                        isLoading={isLoading}
                    />
                }
                detailViewIsOpened={detailViewIsOpened}
                onSelect={onSelect}
                closeSideBar={closeSideBar}
            />
        </>
    );
};

export default DisputesListing;
