import React from "react";
import { Redirect, Switch } from "react-router-dom";
import PrivateRoute from "../../core/PrivateRoute";
import RouteCreator from "../../core/RouteCreator";
import useLeftMenu from "../../core/state/navigation/useLeftMenu";
import companyAdminMenuItems from "../../data/menu/companyAdminMenuItems";
import CylindersList from "./CylindersList";
import EquipmentList from "./EquipmentList";
import useSharedState from "../../hooks/_shared/useSharedState";

const EquipmentScreen = () => {
    const [globalState] = useSharedState("global");
    useLeftMenu(companyAdminMenuItems(globalState));

    return (
        <Switch>
            <PrivateRoute
                path={RouteCreator.equipment.analyzers()}
                component={EquipmentList}
            />
            <PrivateRoute
                path={RouteCreator.equipment.cylinders()}
                component={CylindersList}
            />
            <Redirect to={RouteCreator.equipment.cylinders()} />
        </Switch>
    );
};

export default EquipmentScreen;
