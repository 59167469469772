import api, { apiResult } from "../getAxios";
import { config } from "../Config/config.prod";

let jwtDecode = require("jwt-decode");
let _userRoles = [];

const compareArrays = (array1, array2) => {
    return (
        array1.length === array2.length &&
        array1.every(function (value, index) {
            return value === array2[index];
        })
    );
};

const UserService = {
    getUserDetailsFromToken: () => {
        const token = localStorage.getItem("token");
        if (token) {
            return jwtDecode(token);
        }
    },
    activate: (values) => {
        return api.post(config.apiUrl.activateUser, values);
    },
    getUserDetails: async () => {
        let res = await api.get(config.apiUrl.getUserData);
        if (res.data && res.data.data) {
            return res.data.data[0];
        }
        return {};
    },
    setUserDetails: (values) => {
        return api.post(config.apiUrl.setUserData, values);
    },
    setUserDetailsAsync: async (values) => {
        return apiResult(api.post(config.apiUrl.setUserData, values));
    },
    getUserById: async (id) => {
        let res = await api.get(config.apiUrl.getUserById + "?userId=" + id);
        return res.data.data[0];
    },
    getAdministrators: async (filters = {}) => {
        return (await api.post(config.apiUrl.getAdministrators, filters)).data;
    },
    getNonAdministrators: async (filters = {}) => {
        return (await api.post(config.apiUrl.getNonAdministrators, filters))
            .data;
    },
    getIsFullyRegistered() {
        const userDetails = UserService.getUserDetailsFromToken();
        if (userDetails && userDetails.CompanyId) {
            return userDetails.IsFullyRegistered === "True";
        } else if (userDetails && !userDetails.CompanyId) {
            return true;
        }
        return false;
    },
    setUserCertificate: (formData) => {
        return api.post(config.apiUrl.setUserCertificate, formData);
    },
    setUserCertificateAsync: async (formData) => {
        return apiResult(api.post(config.apiUrl.setUserCertificate, formData));
    },
    getUserRoles: () => {
        const userDetails = UserService.getUserDetailsFromToken();
        if (userDetails) {
            let roles = Array.isArray(userDetails)
                ? userDetails[
                      "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
                  ]
                : [
                      userDetails[
                          "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
                      ],
                  ];
            if (!compareArrays(_userRoles, roles)) _userRoles = roles;
        }
        return _userRoles;
    },
    isInRole: (role) => {
        return UserService.getUserRoles().indexOf(role) >= 0;
    },
    createAdministrator: (values) => {
        return api.post(config.apiUrl.createAdministrator, values);
    },
    createAdministratorAsync: async (values) => {
        return apiResult(api.post(config.apiUrl.createAdministrator, values));
    },
    getAdminRoles: async () => {
        return (await api.get(config.apiUrl.getAdminRoles)).data.data;
    },
    getAdminRolesAsync: async () => {
        return apiResult(api.get(config.apiUrl.getAdminRoles));
    },
    setUserPicture: async (formData) => {
        return await api.post(config.apiUrl.setUserPicture, formData);
    },
    deleteUserPicture: async () => {
        return await api.post(config.apiUrl.deleteUserPicture);
    },
    setUserPassword: (password) => {
        return api.post(config.apiUrl.setUserPassword, password);
    },
    deleteUser: async (id) => {
        return (await api.post(config.apiUrl.deleteUser, { userId: id })).data
            .data;
    },
    deleteLicence: async (id) => {
        return (await api.post(config.apiUrl.deleteLicence, { id: id })).data
            .data;
    },
    setUserEnabled: async ({ id, enabled }) => {
        return await api.post(config.apiUrl.enableDisableUser, {
            enable: enabled,
            userId: id,
        });
    },
    setEmployeeEnabled: async ({ id, enabled }) => {
        return await api.post(config.apiUrl.enableDisableEmployee, {
            enable: enabled,
            userId: id,
        });
    },
    changeEmail: async ({ email, newEmail }) => {
        return await api.post(config.apiUrl.changeEmail, { email, newEmail });
    },
    confirmEmail: async ({ username, newEmail, token }) => {
        return await api.post(config.apiUrl.confirmEmail, {
            username,
            newEmail,
            token,
        });
    },
    getCompaniesByUserId: async () => {
        return (await api.get(config.apiUrl.getCompaniesByUserId)).data.data;
    },
    updateCompanyForCurrentUser: async (companyId) => {
        return await api.post(config.apiUrl.updateCompanyForCurrentUser, {
            companyId,
        });
    },

    isFGasLicenceMandatory: async (companyId) => {
        return await api.post(config.apiUrl.isFGasLicenceMandatory, {});
    },
    updateShowWarningsSetting: (value) => {
        return apiResult(
            api.post(config.apiUrl.updateShowWarningsSetting, {
                value: value,
            })
        );
    },
};

export default UserService;
