import React from "react";
import {
    InfoText,
    InfoTitle,
    InfoWrapper,
    RoundInfo,
} from "../../../../../components/modal/components/WizardStyling";
import OfferBids from "../../../../SalesCenter/views/components/OfferBids";
import ValidationRule, {
    buildValidation,
} from "../../../../../core/validations/ValidationRule";
import { Checkbox } from "../../../../../components/elements/form-elements";

const validGrades = (t) => {
    return buildValidation({
        gradeC: ValidationRule.min(
            0.1,
            t("forms:bid.wizard.minimum"),
            t("forms:bid.wizard.minimum")
        ).rule,
    });
};

const WizardPlaceDisposalBid = (
    t,
    initialValues,
    offers,
    bidIsLoading,
    enableHandlingCosts
) => {
    initialValues.chargeSeller = false;
    const handleChargeSellerCheck = (ev, formik, updatePropertyBag) => {
        formik.setFieldValue("chargeSeller", ev.target.checked);
        updatePropertyBag({ chargeSeller: ev.target.checked });
    };
    return {
        title: t("forms:bid.wizard.title"),
        initialValues: initialValues,
        steps: [
            {
                name: t("forms:bid.wizard.step1"),
                fieldNames: ["gradeA", "gradeB", "gradeC"],
                validation: validGrades(t),
                fields: (formik) => {
                    return (
                        <>
                            <InfoWrapper>
                                <RoundInfo
                                    alt=""
                                    src="/images/menu/green-info.svg"
                                />
                                <div>
                                    <InfoTitle>
                                        {t("forms:bid.wizard.infoTitle")}
                                    </InfoTitle>
                                    <InfoText>
                                        {t("forms:bid.wizard.infoText")}
                                        <br />
                                        {t("forms:bid.wizard.infoText2")}
                                    </InfoText>
                                </div>
                            </InfoWrapper>

                            <OfferBids
                                offers={offers}
                                isLoading={bidIsLoading}
                                formik={formik}
                                disabledGrades={["A", "B"]}
                            />
                        </>
                    );
                },
            },
            {
                name: t("forms:bid.wizard.step2"),
                fieldNames: [
                    "chargeSeller",
                    "transportationCosts",
                    "administrationCosts",
                ],
                // validation: validCosts(t),
                onStepRender: (props) => {
                    props.updatePropertyBag({ handling: true });
                    props.formik.setFieldValue(
                        "chargeSeller",
                        props.propertyBag.chargeSeller
                    );
                },
                fields: (
                    formik,
                    propertyBag,
                    updatePropertyBag,
                    updateFields
                ) => {
                    return (
                        <>
                            <InfoWrapper>
                                <RoundInfo
                                    alt=""
                                    src="/images/menu/green-info.svg"
                                />
                                <div>
                                    <InfoTitle>
                                        {t("forms:bid.wizard.infoTitle")}
                                    </InfoTitle>
                                    <InfoText>
                                        {t("forms:bid.wizard.infoText")}
                                        <br />
                                        {t("forms:bid.wizard.infoText2")}
                                    </InfoText>
                                </div>
                            </InfoWrapper>
                            <Checkbox
                                labelText={t("forms:bid.wizard.chargeSeller")}
                                id="chargeSeller"
                                name="chargeSeller"
                                className="flex form ml-10"
                                wrapperVariant="flex-1"
                                disabled={!enableHandlingCosts}
                                onChange={(ev) =>
                                    handleChargeSellerCheck(
                                        ev,
                                        formik,
                                        updatePropertyBag
                                    )
                                }
                                {...formik}
                            />

                            <OfferBids
                                offers={offers}
                                isLoading={bidIsLoading}
                                formik={formik}
                                withVat={true}
                                handlingStep={propertyBag.handling}
                                disableInputs={!formik.values.chargeSeller}
                            />
                        </>
                    );
                },
            },
        ],
    };
};

export default WizardPlaceDisposalBid;
