import React, { useCallback, useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import styled from "styled-components";
import { ReactComponent as BackSvg } from "../../assets/goback.svg";

const afterMixin = () => `
    content: '';
    background: url('/images/actions/hover_bottom-line.svg') no-repeat bottom center;
    background-size: 40px 4px;
    width: 40px;
    height: 4px;
    position: absolute;
    top: calc(100% + 4px);
    left: -4px;
    opacity: 0;
    will-change: opacity;
`;


const Gallery = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 500;
    width: 100%;
    height: 100%;
`;

const Overlay = styled.div`
    background: rgba(0, 0, 0);
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 100%;
`;

const ImageWrapper = styled.div`
    width: 800px;
    height: 450px;
    margin: auto;
    padding: 0 0 60px;
    position: absolute;
    z-index: 600;
    top: 50%;
    left: 50%;
    transform-origin: 0 0;
    transform: translate(-50%,-50%);
    text-align: center;
`;

const Image = styled.img`
    max-width: 100%;
    max-height: 100%;
    transition: 0.3s ease-in;
`;

const CloseButtonWrapper = styled.div`
    position: absolute;
    top: 60px;
    left: 88px;
    z-index: 600;

    &:after {
        ${afterMixin};
    }

    &:hover {
        &:after {
            opacity: 1;
        }
    }
`;

const CloseButton = styled(BackSvg)`
    width: 24px;
    height: auto;
    cursor: pointer;
    transform: translate(0,0);

    line {
        stroke: #FFF;
    }

    &:after {
        ${afterMixin};
    }

    &:hover {
        &:after {
            opacity: 1;
        }
    }
`;

const ControlsWrapper = styled.div`
    -webkit-font-smoothing: auto;
`;

const ArrowControls = styled.a`
    background: none;
    top: 50%;
    transform: translateY(-50%);
    width: 200px;
    height: 200px;
    position: absolute;
    z-index: 600;
    will-change: display;

    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        transition: all 0.2s ease;
    }

    &:after {
        ${afterMixin};
    }

    &:hover:after {
            width: 24px;
            left: 50%;
            top: calc(50% + 20px);
            transform: translate(-50%, -50%);
            background-size: 24px auto;
        }
    }

    &.previous {
        left: 0;

        &:before {
            background: url('/images/actions/arrow_white.svg') no-repeat center;
            transform: rotate(180deg);
            background-size: 12px 24px;
        }

        &:hover:after {
            opacity: 1;
        }
    }

    &.next {
        right: 0;

        &:before {
            background: url('/images/actions/arrow_white.svg') no-repeat center;
            background-size: 12px 24px;
        }

        &:hover:after {
            opacity: 1;
        }
    }

    &.hidden {
        display: none;
    }

`;

const EditControls = styled.div`
    top: 60px;
    right: 188px;
    position: absolute;
`;

const DownloadImage = styled.a`
    width: 30px;
    height: 38px;
    position: absolute;
    z-index: 600;
    left: 0;
    top: 0;

    &:before {
        content: '';
        background: url('/images/actions/icon-download.svg') no-repeat center;
        background-size: auto 36px;
        position: absolute;
        top: 50%;
        left:  50%;
        transform: translate(-50%, -50%);
        z-index: 10;
        width: 100%;
        height: 100%;
    }

    &:after {
        ${afterMixin};
    }

    &:hover {
        &:after {
            opacity: 1;
        }
    }

`;

const DeleteImage = styled.a`
    width: 32px;
    height: 37px;
    position: absolute;
    z-index: 600;
    left: 68px;
    cursor: pointer;

    &:before {
        content: '';
        background: url('/images/actions/icon-delete.svg') no-repeat center;
        background-size: auto 36px;
        position: absolute;
        z-index: 10;
        width: 100%;
        height: 100%;
        top: 50%;
        left:  50%;
        transform: translate(-50%, -50%);
    }

    &:after {
        ${afterMixin};
        top:calc(100% + 6px);
    }

    &:hover {
        &:after {
            opacity: 1;
        }
    }
`;

const ImageDots = styled.div`
    width: 100%;
    text-align: center;
    height: 20px;
    position: absolute;
    bottom: 0;
`;

const Dot = styled.span`
    width: 12px;
    height: 12px;
    box-sizing: border-box;
    border: 2px solid #FFF;
    background: #000;
    display: inline-block;
    border-radius: 50%;
    margin: 0 7px;
    cursor: pointer;

    &.active {
        background: #FFF;
    }
`;

const ImageGalleryPopup = ({imageGallery, handleClose}) => {
    const images = imageGallery.images;
    const currentImage = imageGallery.currentImage;
    const currentImageObject = images.find(img => img.id === currentImage);
    const currentImagePosition = images.length > 0 && images.findIndex((img) => img.id === currentImage);

    const [currentObject, setCurrentObject] = useState({
        image: currentImageObject.imageUrl || '',
        position: currentImagePosition,
        previousVisible: currentImagePosition !== 0,
        nextVisible: currentImagePosition !== images.length - 1
    });

    let position = currentObject["position"];
    const togglePreviousImage = useCallback(() => {
        if (position !== 0) {
            setCurrentObject(
                {
                    image: images[position - 1].imageUrl,
                    position: position - 1,
                    previousVisible: position - 1 !== 0,
                    nextVisible: position - 1 !== images.length - 1
                }
            );
        }
    }, [images, position]);

    const toggleNextImage = useCallback(() => {
        if (images.length - 1 > position) {
            setCurrentObject(
                {
                    image: images[position + 1].imageUrl,
                    position: position + 1,
                    previousVisible: position + 1 !== 0,
                    nextVisible: position + 1 !== images.length - 1
                });
        }
    }, [images, position]);

    const toggleCircle = useCallback((circleIndex) => {
        setCurrentObject({...currentObject, image: images[circleIndex].imageUrl, position: circleIndex});
    }, [currentObject, images]);

    const keyPress = useCallback((event) => {
        // 37 - prev, 39 - next, 27 - escape
        if (event.keyCode === 37) {
            togglePreviousImage();
        } else if (event.keyCode === 39) {
            toggleNextImage();
        } else if (event.keyCode === 27) {
            handleClose();
        }
    }, [handleClose, toggleNextImage, togglePreviousImage]);

    useEffect(() => {
        window.addEventListener("keyup", keyPress, true);
        return () => window.removeEventListener("keyup", keyPress, true);
    }, [keyPress]);

    useEffect(() => {
        if (position === 0) {
            setCurrentObject({...currentObject, previousVisible: false, nextVisible: true})
        } else if (position === images.length - 1) {
            setCurrentObject({...currentObject, previousVisible: true, nextVisible: false})
        } else {
            setCurrentObject({...currentObject, previousVisible: true, nextVisible: true})
        }
        return () => {
            setCurrentObject({
                image: currentImageObject.imageUrl || '',
                position: currentImagePosition,
                previousVisible: currentImagePosition !== 0,
                nextVisible: currentImagePosition !== images.length - 1
            })
        }
    }, [currentObject.position]);

    const deleteImage = (imageId) => {
        //TODO image delete
    }

    return ReactDOM.createPortal(
        <Gallery className="gallery" onKeyPress={keyPress} id="gallery-open">
            <Overlay/>
            <ImageWrapper>
                {
                    currentImage &&
                    <Image className="active" src={currentObject.image}/>
                }

                <ImageDots>
                    {
                        images && images.map((image, index) => (
                            <Dot key={"dot_" + index}
                                 className={index === position ? 'active' : ''}
                                 onClick={() => index !== position ? toggleCircle(index) : ''}
                            />
                        ))
                    }
                </ImageDots>
            </ImageWrapper>

            <ControlsWrapper>
                <CloseButtonWrapper>
                    <CloseButton onClick={handleClose}/>
                </CloseButtonWrapper>

                <EditControls>
                    <DownloadImage href={currentObject.image}/>
                    <DeleteImage onClick={() => deleteImage(currentImageObject.id)}/>
                </EditControls>

                <ArrowControls className={["previous", currentObject.previousVisible ? 'previous' : 'hidden']}
                               href="#"
                               onClick={currentObject.previousVisible ? togglePreviousImage : undefined}
                >
                </ArrowControls>
                <ArrowControls className={["next", currentObject.nextVisible ? 'next' : 'hidden']}
                               href="#"
                               onClick={currentObject.nextVisible ? toggleNextImage : undefined}
                >
                </ArrowControls>
            </ControlsWrapper>

        </Gallery>,
        document.body
    )
};

export default ImageGalleryPopup;
