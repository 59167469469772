import React from "react";
import styled from "styled-components";

const Title = styled.span`
    text-transform: uppercase;
    margin: 0 0 16px 0;
    font-size: 13px;
    display: block;
    font-weight: 600;
    color: #444;
    text-align: left;
`;

const FilterTitle = (props) => {
    return (
        <Title>
            {props.children}
        </Title>
    )
};

export default FilterTitle;
