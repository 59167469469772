import React from "react";
import { useTranslation } from "react-i18next";
import ClearableInput from "../../../../../components/elements/form-elements/ClearableInput";
import Select from "../../../../../components/elements/form-elements/Select";
import Filters from "../../../../../components/filter/Filters";
import AnalysisStatus from "../../../../../core/enums/AnalysisStatus";
import GradeStatus from "../../../../../core/enums/GradeStatus";
import MarketplaceStatus from "../../../../../core/enums/MarketplaceStatus";
import useDebouncedCallback from "../../../../../hooks/_shared/useDebouncedCallback";

const StockFilter = ({ filterState, dispatch, actionTypes, gasTypes }) => {
    const { t } = useTranslation("filters");

    const statuses = Object.keys(MarketplaceStatus).map((key) => ({
        key: key,
        value: key,
        label: t(
            "screens:stockListing.marketplaceStatus." + MarketplaceStatus[key]
        ),
    }));
    // statuses[statuses.findIndex((s) => s.key === "OnStock")].label +="/"+ t(
    //     "screens:stockListing.marketplaceStatus.RecyclePending"
    // );
    statuses.splice(statuses.findIndex((s) => s.key === "RecyclePending"),1);

    const [quantityToChanged] = useDebouncedCallback((text) => {
        text === ""
            ? dispatch({
                  type: actionTypes.RESET_GAS_QUANTITY_TO,
                  payload: text,
              })
            : dispatch({
                  type: actionTypes.SET_GAS_QUANTITY_TO,
                  payload: text,
              });
    });
    const [quantityFromChanged] = useDebouncedCallback((text) => {
        text === ""
            ? dispatch({
                  type: actionTypes.RESET_GAS_QUANTITY_FROM,
                  payload: text,
              })
            : dispatch({
                  type: actionTypes.SET_GAS_QUANTITY_FROM,
                  payload: text,
              });
    });

    return (
        <>
            <Filters.Wrapper>
                <Filters.Item>
                    <Select
                        name="gastype"
                        key="gastype"
                        errors={[]}
                        touched={[]}
                        defaultItem={{
                            key: "all",
                            value: "",
                            label: t("gas.gasType"),
                        }}
                        defaultValue={filterState.refrigerantGasTypeCode}
                        options={
                            gasTypes
                                ? gasTypes.map((gas) => ({
                                      key: gas.code,
                                      value: gas.code,
                                      label: gas.code,
                                  }))
                                : []
                        }
                        handleChange={(evt) => {
                            dispatch({
                                type:
                                    evt.currentTarget.value === ""
                                        ? actionTypes.RESET_GAS_TYPE
                                        : actionTypes.SET_GAS_TYPE,
                                payload: evt.currentTarget.value,
                            });
                        }}
                        className="filters"
                        labelText={t("gas.gasType")}
                        labelClassName="sr-only"
                    />
                </Filters.Item>
                <Filters.Item className="tight">
                    <ClearableInput
                        name="quantityFrom"
                        type="number"
                        defaultValue={
                            filterState.quantityFrom !== undefined
                                ? filterState.quantityFrom
                                : ""
                        }
                        onChange={(evt) =>
                            quantityFromChanged(evt.currentTarget.value)
                        }
                        placeholder={t("gas.quantityFrom")}
                        className="filters left group"
                    />
                </Filters.Item>
                <Filters.Item>
                    <ClearableInput
                        name="quantityTo"
                        type="number"
                        defaultValue={
                            filterState.quantityTo !== undefined
                                ? filterState.quantityTo
                                : ""
                        }
                        onChange={(evt) =>
                            quantityToChanged(evt.currentTarget.value)
                        }
                        placeholder={t("gas.quantityTo")}
                        className="filters right group"
                    />
                </Filters.Item>
                <Filters.Item>
                    <Select
                        name="presumedGrade"
                        key="presumedGrade"
                        errors={[]}
                        touched={[]}
                        defaultItem={{
                            key: "all",
                            value: "",
                            label: t("gas.presumedQuality"),
                        }}
                        defaultValue={filterState.presumedGrade}
                        options={Object.keys(GradeStatus).map((key) => ({
                            key: key,
                            value: key,
                            label: GradeStatus[key],
                        }))}
                        handleChange={(evt) => {
                            dispatch({
                                type:
                                    evt.currentTarget.value === ""
                                        ? actionTypes.RESET_PRESUMED_QUALITY
                                        : actionTypes.SET_PRESUMED_QUALITY,
                                payload: evt.currentTarget.value,
                            });
                        }}
                        className="filters"
                        labelText={t("gas.presumedGrade")}
                        labelClassName="sr-only"
                    />
                </Filters.Item>
                <Filters.Item>
                    <Select
                        name="qualityAssurance"
                        key="qualityAssurance"
                        errors={[]}
                        touched={[]}
                        defaultItem={{
                            key: "all",
                            value: "",
                            label: t("gas.qualityAssurance"),
                        }}
                        defaultValue={filterState.hasAnalysis}
                        options={Object.keys(AnalysisStatus).map((key) => ({
                            key: key,
                            value: key,
                            label: t("analysis." + AnalysisStatus[key]),
                        }))}
                        handleChange={(evt) => {
                            dispatch({
                                type:
                                    evt.currentTarget.value === ""
                                        ? actionTypes.RESET_QUALITY_ASSURANCE
                                        : actionTypes.SET_QUALITY_ASSURANCE,
                                payload: evt.currentTarget.value,
                            });
                        }}
                        className="filters"
                        labelText={t("gas.qualityAssurance")}
                        labelClassName="sr-only"
                    />
                </Filters.Item>
                <Filters.Item>
                    <Select
                        name="marketplaceStatus"
                        key="marketplaceStatus"
                        errors={[]}
                        touched={[]}
                        defaultItem={{
                            key: "all",
                            value: "",
                            label: t("gas.marketplaceStatus"),
                        }}
                        defaultValue={filterState.hasAnalysis}
                        options={statuses}
                        handleChange={(evt) => {
                            dispatch({
                                type:
                                    evt.currentTarget.value === ""
                                        ? actionTypes.RESET_MARKETPLACE_STATUS
                                        : actionTypes.SET_MARKETPLACE_STATUS,
                                payload: evt.currentTarget.value,
                            });
                        }}
                        className="filters"
                        labelText={t("gas.qualityAssurance")}
                        labelClassName="sr-only"
                    />
                </Filters.Item>
            </Filters.Wrapper>
        </>
    );
};

export default StockFilter;
