import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import styled from "styled-components";
import DataTable from "../../../../components/data/DataTable";
import DateCell from "../../../../components/data/DateCell";
import { DateTimeCell } from "../../../../components/data/DateCell";
import Flex from "../../../../components/layout/flex/Flex";
import LayoutInnerContainer from "../../../../components/layout/split/one/LayoutInnerContainer";
import HamburgerMenu from "../../../../components/menu/HamburgerMenu";
import HamburgerMenuAction from "../../../../components/menu/HamburgerMenuAction";
import ModalDialog, {
    useModal,
} from "../../../../components/modal/ModalDialog";
import ModalWizard from "../../../../components/modal/ModalWizard";
import AccordionWrapper from "../../../../components/toggle-view/AccordionWrapper";
import AuthorizeComponent from "../../../../core/AuthorizeComponent";
import Roles from "../../../../core/enums/Roles";
import useBreadcrumbs from "../../../../core/state/navigation/useBreadcrumbs";
import useDataReducer from "../../../../hooks/_shared/useDataReducer";
import PaymentService from "../../../../services/Payment/PaymentService";
import UserService from "../../../../services/User/UserService";
import KYCWizard from "./wizard/KYCWizard";
import { Helmet } from "react-helmet";
import SimpleTable from "../../../../components/elements/general/SimpleTable";
import KYCDocumentType from "../../../../core/enums/KYCDocumentType";
import ModalContainer from "../../../../components/modal/ModalContainer";
import { ReactComponent as CloseSvg } from "../../../../assets/pwa/pwa-close.svg";
import { ReactComponent as MangoSvg } from "../../../../assets/mangopay.svg";
import Button from "../../../../components/elements/buttons/Button";
import UboScreen from "./UboScreen";
import useAccordionGroup from "../../../../hooks/_shared/useAccordionGroup";

const InfoWrapper = styled.div`
    display: flex;
    width: 75%;
`;

const StyledList = styled.ul`
    &.gray {
        color: #888;
        margin-top: 20px;
        font-style: italic;
    }
    margin: 0;
    padding: 0 0 0 56px;

    font-size: 0.875rem;
`;

const Information = styled.div`
    &.primary {
        b {
            color: ${(props) => props.theme.primaryColor};
        }
    }

    p {
        &.micro {
            font-size: 12px;
        }

        &.tight {
            margin-top: -14px;
        }
        &.smaller {
            font-size: 0.875rem;
        }

        &.status {
            font-weight: bold;
            margin: 0 0 36px;
        }

        &.success {
            color: ${(props) => props.theme.textActive};
        }

        &.fail {
            color: ${(props) => props.theme.error};
        }
    }

    a {
        color: ${(props) => props.theme.textActive};
        font-weight: bold;
        text-decoration: none;

        &.file {
            color: ${(props) => props.theme.tertiaryColor};
            font-weight: normal;
        }
    }
`;

const MangoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: calc(100% - 50px);
    margin: 50px 80px 0px 150px;
    background-color: transparent;
    overflow-y: hidden;
    & img {
        width: 100%;
        height: auto;
    }
`;

const ImgWrapper = styled.div`
    display: block;
    width: 100%;
    height: 100%;
    overflow-y: auto;
`;

const SecondInfoWrapper = styled.div`
    margin-top: 20px;
`;

const GreenInfo = styled.img`
    width: 40px;
    height: 40px;
    margin-right: 24px;
`;

const CloseMango = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #000;
    font-size: 16px;
    font-weight: 700;
    padding-right: 15px;

    & > div {
        cursor: pointer;
        display: flex;
        align-items: center;
        & img {
            margin-right: 10px;
        }
    }
`;

const StyledClose = styled(CloseSvg)`
    width: 16px;
    height: 16px;
    margin-right: 12px;

    & line,
    & path {
        stroke: #e94e4e;
    }
`;

const StyledMango = styled(MangoSvg)`
    width: 320px;
    height: auto;
    margin-top: 56px;
`;

const StatusTItle = styled.div`
    margin-top: 56px;
    font-size: 36px;
    & span {
        color: ${(p) => p.theme.primaryColor};
        font-weight: 700;
    }

    &.invalid span {
        color: ${(p) => p.theme.error};
    }
`;

const StatusTitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

const StatusLimits = styled.div`
    margin-top: 13px;
    font-size: 20px;
    color: #8e8e8e;
`;

const Separator = styled.div`
    width: 100%;
    height: 2px;
    background-color: #000;
    margin-top: 24px;
`;
const LightSeparator = styled.div`
    width: 100%;
    height: 1px;
    background-color: #dddddd;
    margin-top: 24px;
`;

const RightContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const GridWrap = styled.div`
    margin: 36px 0px 36px 15px;
    display: flex;
    width: 76.666%;
    &.light .col .line {
        border-bottom: 1px solid #dddddd;
    }

    & .col {
        display: flex;
        flex-direction: column;
        width: 33.333%;
        font-size: 12px;

        & > div {
            min-height: 45px;
            display: flex;
            align-items: center;
            color: #8e8e8e;

            & span {
                color: #000;
                font-weight: 700;
            }
        }

        & .line {
            border-bottom: 1px solid #000;
        }

        & .title {
            font-weight: 700;
            font-size: 14px;
            color: #000;
        }

        & .inset {
            margin-left: 20px;
        }

        & .full {
            width: 100%;
        }

        & .flex-col {
            flex-direction: column;
            justify-content: center;
        }

        & .limited {
            color: ${(p) => p.theme.error};
        }
    }
`;

const ValidationStatus = styled.span`
    text-transform: uppercase;
`;

const SHAREHOLDER_DECLARATION = "SHAREHOLDER_DECLARATION";

const KycScreen = () => {
    const { t } = useTranslation();
    const [mangoOpen, setMangoOpen] = useState(false);
    const [isMasterAdmin] = useState(
        UserService.isInRole(Roles.InstallerCompanyAdmin)
    );
    const [openAccordion, setOpenAccordion] = useAccordionGroup();
    useBreadcrumbs([
        {
            text: t("navigation:company"),
        },
        {
            text: t("navigation:kycSettings"),
        },
    ]);

    const [selectedKycDocument, setSelectedKycDocument] = useState(null);

    const IsKYCValidated =
        UserService.getUserDetailsFromToken().IsKYCValidated !== "False";

    const [myUboDocuments, loadMyUboDocuments] = useDataReducer(async () => {
        return await PaymentService.getMyUbos();
    });

    const uboValidated =
        myUboDocuments?.data && myUboDocuments.data.length > 0
            ? myUboDocuments.data[0].status === "VALIDATED"
            : false;

    const shareHoldersNeeded = () => {
        return myUboDocuments?.data && myUboDocuments.data.length > 0
            ? myUboDocuments.data[0].reason ===
                  "SHAREHOLDERS_DECLARATION_NEEDED"
            : false;
    };

    const [myKYCDocuments, loadMyKYCDocuments] = useDataReducer(async () => {
        const docs = await PaymentService.getMyKYCDocuments();
        if (shareHoldersNeeded()) {
            let showWarning = false;
            let shareholders = docs.filter(
                (e) => e.type === SHAREHOLDER_DECLARATION
            );
            if (shareholders.length === 0) {
                docs.push({
                    type: SHAREHOLDER_DECLARATION,
                    history: [],
                });
                showWarning = true;
            } else showWarning = shareholders[0].history.length === 0;

            if (showWarning) {
                toggleshareholdersKYC();
            }
        }
        return docs;
    });

    useEffect(() => {
        loadMyUboDocuments();
    }, []);
    useEffect(() => {
        loadMyKYCDocuments();
    }, [myUboDocuments]);

    const isUpload = (documentType) =>
        documentType !== KYCDocumentType.UBO_DECLARATION;

    const KYCColumns = useMemo(
        () => [
            {
                Header: t("table:headers.kyc.documentType"),
                accessor: "type",
                Cell: ({ cell: { row, value } }) => {
                    let green = row.original.history[0]?.status === "VALIDATED";
                    let typeTranslation = t(
                        "table:headers.kyc.type." + value + ""
                    );
                    return (
                        <span
                            style={{
                                color: green ? "#48b05e" : "#E94E4E",
                                fontWeight: 700,
                            }}
                        >
                            {typeTranslation || value}
                        </span>
                    );
                },
            },
            {
                Header: t("table:headers.kyc.creationDate"),
                accessor: "creationDate",
                Cell: ({ cell: { row } }) => {
                    if (row.original.history.length > 0) {
                        let value = row.original.history[0].creationDate;
                        return DateCell({ cell: { value } });
                    }
                    return null;
                },
            },
            {
                Header: t("table:headers.kyc.processedDate"),
                accessor: "processedDate",
                Cell: ({ cell: { row } }) => {
                    if (row.original.history.length > 0) {
                        let value = row.original.history[0].processedDate;
                        return DateCell({ cell: { value } });
                    }
                    return null;
                },
            },
            {
                Header: t("table:headers.kyc.status"),
                accessor: "status",

                Cell: ({ cell: { row } }) => {
                    if (row.original.history.length > 0) {
                        let value = row.original.history[0].status;
                        let statusTranslation = t(
                            "table:headers.kyc.statusEnum." + value + ""
                        );
                        return (
                            <ValidationStatus
                                style={{
                                    color:
                                        value === "VALIDATED"
                                            ? "#48b05e"
                                            : value === "REFUSED"
                                            ? "#E94E4E"
                                            : "#000",
                                    fontWeight: 700,
                                }}
                            >
                                {statusTranslation || value}
                            </ValidationStatus>
                        );
                    }

                    return null;
                },
            },
            {
                Header: t("table:headers.kyc.refusedReasonType"),
                accessor: "refusedReasonType",
                Cell: ({ cell: { row } }) => {
                    if (row.original.history.length > 0) {
                        let value = row.original.history[0].refusedReasonType;
                        let translation = row.original.history[0]
                            .refusedReasonType
                            ? t(
                                  "table:headers.kyc.refusedReasonTypeEnum." +
                                      value +
                                      ""
                              )
                            : null;
                        return translation || value;
                    }

                    return null;
                },
            },
            {
                Header: t("table:headers.kyc.justification"),
                accessor: "refusedReasonMessage",
                Cell: ({ cell: { row } }) => {
                    if (row.original.history.length > 0) {
                        let value = row.original.history[0].refusedReasonType;
                        let translation = row.original.history[0]
                            .refusedReasonType
                            ? t(
                                  "table:headers.kyc.refusedReasonTypeEnum." +
                                      value +
                                      ""
                              )
                            : null;
                        return translation || value;
                    }

                    return null;
                },
            },
            {
                Header: "",
                accessor: "id",
                Cell: ({ cell: { row } }) => {
                    const existing = row.original.history.length > 0;
                    const status = row.original.history[0]?.status;
                    const showCreate =
                        !existing ||
                        status === "CREATED" ||
                        status === "REFUSED" ||
                        status === "OUT_OF_DATE";
                    const showMenu = status !== "VALIDATED";
                    const showShDownload =
                        row.original.type === SHAREHOLDER_DECLARATION;
                    return (
                        <AuthorizeComponent
                            roles={[Roles.InstallerCompanyAdmin]}
                        >
                            {showMenu && (
                                <HamburgerMenu
                                    up={
                                        row.original.type ===
                                            "REGISTRATION_PROOF" ||
                                        row.original.type ===
                                            SHAREHOLDER_DECLARATION
                                    }
                                >
                                    {isUpload(row.original.type) && showCreate && (
                                        <HamburgerMenuAction
                                            text={
                                                existing
                                                    ? t(
                                                          "screens:payments.kyc.createNew"
                                                      )
                                                    : t(
                                                          "screens:payments.kyc.create"
                                                      )
                                            }
                                            dataTest="edit-client"
                                            onClick={async () => {
                                                ModalWizard.show(
                                                    KYCWizard(
                                                        t,
                                                        row.original.type,
                                                        (ev) => {
                                                            setMangoOpen(true);
                                                        }
                                                    ),
                                                    {
                                                        onSubmit: async (
                                                            values
                                                        ) => {
                                                            const params = {
                                                                ...values,
                                                                KYCDocumentType:
                                                                    row.original
                                                                        .type,
                                                            };
                                                            const result =
                                                                await PaymentService.addKYCDocument(
                                                                    ModalWizard.asFormData(
                                                                        params
                                                                    )
                                                                );
                                                            if (
                                                                result &&
                                                                result.error
                                                            )
                                                                toast.error(
                                                                    t(
                                                                        result
                                                                            .error
                                                                            .message
                                                                    )
                                                                );
                                                            else {
                                                                toast.success(
                                                                    t(
                                                                        "actions:dialogs.createKYCDocument.success"
                                                                    )
                                                                );
                                                                ModalWizard.hide();
                                                                loadMyKYCDocuments();
                                                            }
                                                        },
                                                        onCancel: async () => {
                                                            ModalWizard.hidePrompt();
                                                        },
                                                    },
                                                    {
                                                        style: {
                                                            minWidth: "740px",
                                                        },
                                                        footerContent: t(
                                                            "forms:kyc.info.disclaimer"
                                                        ),
                                                    }
                                                );
                                            }}
                                        />
                                    )}
                                    {status !== undefined && (
                                        <HamburgerMenuAction
                                            text={t(
                                                "screens:payments.kyc.btnHistory"
                                            )}
                                            onClick={async () => {
                                                onSelect(row);
                                            }}
                                        />
                                    )}
                                    {showShDownload && (
                                        <HamburgerMenuAction>
                                            <a
                                                download
                                                target="_blank"
                                                href="/files/Shareholder_Declaration-EN.pdf"
                                            >
                                                {t(
                                                    "table:headers.kyc.downloadTemplate"
                                                )}
                                            </a>
                                        </HamburgerMenuAction>
                                    )}
                                </HamburgerMenu>
                            )}
                        </AuthorizeComponent>
                    );
                },
            },
        ],
        [t]
    );

    const kycHistoryColumns = useMemo(
        () => [
            {
                header: t("table:headers.kyc.status"),
                accessor: "status",
                cell: (value) => {
                    let translation = t(
                        "table:headers.kyc.statusEnum." + value + ""
                    );
                    return translation || value;
                },
            },
            {
                header: t("table:headers.kyc.refusedReasonType"),
                accessor: "refusedReasonType",
                cell: (value) => {
                    let translation = value
                        ? t(
                              "table:headers.kyc.refusedReasonTypeEnum." +
                                  value +
                                  ""
                          )
                        : null;
                    return translation || value;
                },
            },
            {
                header: t("table:headers.kyc.justification"),
                accessor: "RefusedReasonMessage",
            },
            {
                header: t("table:headers.kyc.creationDate"),
                accessor: "creationDate",
                cell: (value) => {
                    return DateTimeCell({ cell: { value } });
                },
            },
            {
                header: t("table:headers.kyc.processedDate"),
                accessor: "processedDate",
                cell: (value) => {
                    return DateTimeCell({ cell: { value } });
                },
            },
        ],
        [t, myKYCDocuments]
    );

    const documentTitle = selectedKycDocument
        ? KYCDocumentType[selectedKycDocument.type]
        : "";
    const { modalProps, toggleOpen } = useModal({
        title: t("screens:payments.kyc.modalTitle", { documentTitle }),
        style: { maxWidth: "100%", width: "80%" },
        onCancel: () => {
            setSelectedKycDocument(null);
            toggleOpen();
        },
    });

    const { modalProps: submitKYCprops, toggleOpen: toggleSubmitKYC } =
        useModal({
            title: t("forms:kyc.submit.title"),
            type: "info",
            confirmText: t("forms:kyc.submit.action"),
            onConfirm: async () => {
                const docIDs = getSubmitDocList().map((doc) => doc.id);
                const result = await PaymentService.submitKYC(docIDs);
                if (result && result.error) {
                    toast.error(t(result.error.message));
                } else {
                    toggleSubmitKYC();
                    loadMyKYCDocuments();
                }
            },
            onCancel: () => {
                toggleSubmitKYC();
            },
        });

    const {
        modalProps: shareholdersKYCprops,
        toggleOpen: toggleshareholdersKYC,
    } = useModal({
        title: t("forms:kyc.shareholders.title").toUpperCase(),
        type: "danger",
        confirmText: t("forms:kyc.shareholders.action"),
        onConfirm: async () => {
            toggleshareholdersKYC();
            let kycSectionEl = document.getElementById("kycsection");
            kycSectionEl.scrollIntoView({ behavior: "smooth", block: "start" });
        },
    });

    const onSelect = useCallback((row) => {
        const selectRow = async (row) => {
            setSelectedKycDocument(row.original);
            toggleOpen();
        };
        selectRow(row);
    }, []);

    const submitEnabled = () => {
        let result = false;

        if (myKYCDocuments.data && myKYCDocuments.data.length > 0) {
            myKYCDocuments.data.forEach((doc) => {
                let isCreated =
                    doc.history.length > 0 &&
                    doc.history[0].status === "CREATED";
                if (isCreated) result = true;
            });
        }
        return result;
    };

    const getSubmitDocList = () => {
        const result = [];
        if (myKYCDocuments.data && myKYCDocuments.data.length > 0) {
            myKYCDocuments.data.forEach((doc) => {
                const created =
                    doc.history.length > 0 &&
                    doc.history[0].status === "CREATED";
                if (created)
                    result.push({
                        type: t("forms:kyc.docType." + doc.type),
                        id: doc.history[0].id,
                    });
            });
        }
        return result;
    };

    return (
        <LayoutInnerContainer className="wide">
            <Helmet>
                <title>{`${t("screens:payments.kyc.accordionTitle")} (${
                    IsKYCValidated
                        ? t("screens:payments.kyc.validated")
                        : t("screens:payments.kyc.notValidated")
                })`}</title>
            </Helmet>
            <Flex.Column>
                <StatusTitleWrapper>
                    <StatusTItle className={IsKYCValidated ? "" : "invalid"}>
                        {t("screens:payments.kyc.status.title")}
                        {": "}
                        <span>
                            {IsKYCValidated
                                ? t("screens:payments.kyc.status.regular")
                                : t("screens:payments.kyc.status.light")}
                        </span>
                    </StatusTItle>
                    <StyledMango />
                </StatusTitleWrapper>
                <StatusLimits>
                    {t("screens:payments.kyc.status.statusLimits")}
                </StatusLimits>
                <GridWrap className={IsKYCValidated ? "" : "light"}>
                    <div className="col">
                        <div className="title inset">
                            {t("screens:payments.kyc.statusTable.country")}
                        </div>
                        <div className="title line inset">
                            {t("screens:payments.kyc.statusTable.france")}
                        </div>
                        <div className="title line inset">
                            {t("screens:payments.kyc.statusTable.germany")}
                        </div>
                        <div className="title inset">
                            {t("screens:payments.kyc.statusTable.restOfEU")}
                        </div>
                    </div>
                    <div className="col">
                        <div className="title">
                            {t(
                                "screens:payments.kyc.statusTable.paymentLimits"
                            )}
                        </div>
                        <div className="line flex-col">
                            <div className="full">
                                {t(
                                    "screens:payments.kyc.statusTable.payinTransfers"
                                )}
                                :{" "}
                                <span>
                                    {t(
                                        "screens:payments.kyc.statusTable.noLimit"
                                    )}
                                </span>
                            </div>
                            <div className="full">
                                {t("screens:payments.kyc.statusTable.payout")}:{" "}
                                <span
                                    className={IsKYCValidated ? "" : "limited"}
                                >
                                    {IsKYCValidated
                                        ? t(
                                              "screens:payments.kyc.statusTable.noLimit"
                                          )
                                        : t(
                                              "screens:payments.kyc.statusTable.compulsory"
                                          )}
                                </span>
                            </div>
                        </div>
                        <div className="line flex-col">
                            <div className="full">
                                {t(
                                    "screens:payments.kyc.statusTable.payinTransfers"
                                )}
                                :{" "}
                                <span>
                                    {t(
                                        "screens:payments.kyc.statusTable.noLimit"
                                    )}
                                </span>
                            </div>
                            <div className="full">
                                {t("screens:payments.kyc.statusTable.payout")}:{" "}
                                <span
                                    className={IsKYCValidated ? "" : "limited"}
                                >
                                    {IsKYCValidated
                                        ? t(
                                              "screens:payments.kyc.statusTable.noLimit"
                                          )
                                        : t(
                                              "screens:payments.kyc.statusTable.compulsory"
                                          )}
                                </span>
                            </div>
                        </div>
                        <div className="flex-col">
                            <div className="full">
                                {t(
                                    "screens:payments.kyc.statusTable.payinTransfers"
                                )}
                                :{" "}
                                <span>
                                    {t(
                                        "screens:payments.kyc.statusTable.noLimit"
                                    )}
                                </span>
                            </div>
                            <div className="full">
                                {t("screens:payments.kyc.statusTable.payout")}:{" "}
                                <span
                                    className={IsKYCValidated ? "" : "limited"}
                                >
                                    {IsKYCValidated
                                        ? t(
                                              "screens:payments.kyc.statusTable.noLimit"
                                          )
                                        : t(
                                              "screens:payments.kyc.statusTable.compulsory"
                                          )}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="title">
                            {t("screens:payments.kyc.statusTable.moneyLimits")}
                        </div>
                        <div className="line flex-col">
                            <div className="full">
                                {t(
                                    "screens:payments.kyc.statusTable.cumulatedPayout"
                                )}
                                :
                                <span
                                    className={IsKYCValidated ? "" : "limited"}
                                >
                                    {" "}
                                    {IsKYCValidated
                                        ? t(
                                              "screens:payments.kyc.statusTable.noLimit"
                                          )
                                        : "150,00 EUR"}
                                </span>
                            </div>
                            <div className="full">
                                {t(
                                    "screens:payments.kyc.statusTable.userBalance"
                                )}
                                :{" "}
                                <span
                                    className={IsKYCValidated ? "" : "limited"}
                                >
                                    {IsKYCValidated
                                        ? t(
                                              "screens:payments.kyc.statusTable.noLimit"
                                          )
                                        : "150,00 EUR"}
                                </span>
                            </div>
                        </div>
                        <div className="line flex-col">
                            <div className="full">
                                {t(
                                    "screens:payments.kyc.statusTable.cumulatedPayout"
                                )}
                                :
                                <span
                                    className={IsKYCValidated ? "" : "limited"}
                                >
                                    {" "}
                                    {IsKYCValidated
                                        ? t(
                                              "screens:payments.kyc.statusTable.noLimit"
                                          )
                                        : "100,00 EUR"}
                                </span>
                            </div>
                            <div className="full">
                                {t(
                                    "screens:payments.kyc.statusTable.userBalance"
                                )}
                                :{" "}
                                <span
                                    className={IsKYCValidated ? "" : "limited"}
                                >
                                    {IsKYCValidated
                                        ? t(
                                              "screens:payments.kyc.statusTable.noLimit"
                                          )
                                        : "100,00 EUR"}
                                </span>
                            </div>
                        </div>
                        <div className="flex-col">
                            <div className="full">
                                {t(
                                    "screens:payments.kyc.statusTable.cumulatedPayout"
                                )}
                                :
                                <span
                                    className={IsKYCValidated ? "" : "limited"}
                                >
                                    {" "}
                                    {IsKYCValidated
                                        ? t(
                                              "screens:payments.kyc.statusTable.noLimit"
                                          )
                                        : "150,00 EUR"}
                                </span>
                            </div>
                            <div className="full">
                                {t(
                                    "screens:payments.kyc.statusTable.userBalance"
                                )}
                                :{" "}
                                <span
                                    className={IsKYCValidated ? "" : "limited"}
                                >
                                    {IsKYCValidated
                                        ? t(
                                              "screens:payments.kyc.statusTable.noLimit"
                                          )
                                        : "150,00 EUR"}
                                </span>
                            </div>
                        </div>
                    </div>
                </GridWrap>
                <AccordionWrapper
                    id="kycsection"
                    title={`${t("screens:payments.kyc.accordionTitle")} (${
                        IsKYCValidated
                            ? t("screens:payments.kyc.validated")
                            : t("screens:payments.kyc.notValidated")
                    })`}
                    isOpen={openAccordion}
                    onToggle={setOpenAccordion}
                    variant="overflow top-space inset"
                    style={{ marginTop: "30px" }}
                    noBorder
                >
                    <InfoWrapper>
                        <GreenInfo src="/images/menu/green-info.svg" />
                        <div>
                            <Information>
                                <p
                                    style={{ marginTop: "0" }}
                                    className="smaller"
                                    dangerouslySetInnerHTML={{
                                        __html: t("screens:payments.kyc.text"),
                                    }}
                                />
                            </Information>
                            <LightSeparator />
                            <Information>
                                <p
                                    className="micro"
                                    dangerouslySetInnerHTML={{
                                        __html: t(
                                            "screens:payments.kyc.fileFormats"
                                        ),
                                    }}
                                />
                            </Information>

                            <Information>
                                <p
                                    style={{ marginTop: "-12px" }}
                                    className="micro"
                                    dangerouslySetInnerHTML={{
                                        __html: t(
                                            "screens:payments.kyc.fileSizes"
                                        ),
                                    }}
                                />
                            </Information>

                            <Information>
                                <p
                                    className="micro"
                                    dangerouslySetInnerHTML={{
                                        __html: t("forms:kyc.info.kycDocs"),
                                    }}
                                />
                            </Information>

                            <Information>
                                <p
                                    style={{ marginTop: "-12px" }}
                                    className="micro"
                                    dangerouslySetInnerHTML={{
                                        __html: t(
                                            "forms:kyc.info.kycTranslations"
                                        ),
                                    }}
                                />
                            </Information>
                        </div>
                    </InfoWrapper>
                    <Separator />
                    {myKYCDocuments && myKYCDocuments.data && (
                        <>
                            <DataTable
                                columns={KYCColumns}
                                data={myKYCDocuments.data}
                                onSelect={onSelect}
                            />
                            {isMasterAdmin && (
                                <RightContainer>
                                    <Button
                                        disabled={!submitEnabled()}
                                        className="primary--action"
                                        onClick={(ev) => {
                                            toggleSubmitKYC();
                                        }}
                                    >
                                        {t(
                                            "screens:payments.kyc.submitForValidation"
                                        )}
                                    </Button>
                                </RightContainer>
                            )}
                        </>
                    )}
                </AccordionWrapper>
                <AccordionWrapper
                    title={`${t("screens:payments.ubo.accordionTitle")} (${
                        uboValidated
                            ? t("screens:payments.kyc.validated")
                            : t("screens:payments.kyc.notValidated")
                    })`}
                    id="ubosection"
                    isOpen={openAccordion}
                    onToggle={setOpenAccordion}
                    variant="overflow top-space inset"
                    style={{ marginTop: "30px" }}
                    noBorder
                >
                    <InfoWrapper>
                        <GreenInfo src="/images/menu/green-info.svg" />
                        <div style={{ width: "100%" }}>
                            <Information>
                                <p
                                    style={{ marginTop: "0" }}
                                    className="smaller"
                                    dangerouslySetInnerHTML={{
                                        __html: t("screens:payments.ubo.info1"),
                                    }}
                                />

                                <StyledList>
                                    <li>
                                        {t(
                                            "screens:payments.ubo.list.criteria1"
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            "screens:payments.ubo.list.criteria2"
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            "screens:payments.ubo.list.criteria3"
                                        )}
                                    </li>
                                </StyledList>
                            </Information>
                            <LightSeparator />
                            <SecondInfoWrapper>
                                <Information>
                                    <p
                                        style={{ marginTop: "0" }}
                                        className="micro"
                                        dangerouslySetInnerHTML={{
                                            __html: t(
                                                "screens:payments.ubo.info2"
                                            ),
                                        }}
                                    />

                                    <p
                                        style={{ marginTop: "0" }}
                                        className="micro"
                                        dangerouslySetInnerHTML={{
                                            __html: t(
                                                "screens:payments.ubo.info3"
                                            ),
                                        }}
                                    />
                                </Information>
                            </SecondInfoWrapper>
                        </div>
                    </InfoWrapper>
                    <Separator />
                    <UboScreen />
                </AccordionWrapper>
                <ModalDialog {...shareholdersKYCprops}>
                    <div style={{ fontSize: "15px" }}>
                        {t("forms:kyc.shareholders.text")}
                    </div>
                    <div
                        style={{
                            fontSize: "12px",
                            color: "#707070",
                            marginTop: "14px",
                        }}
                    >
                        {t("forms:kyc.shareholders.footer")}
                    </div>
                </ModalDialog>
                <ModalDialog {...modalProps}>
                    {selectedKycDocument && (
                        <SimpleTable
                            columns={kycHistoryColumns}
                            data={selectedKycDocument.history}
                            wrapperVariant="modal"
                            shouldScrollInfoView={true}
                        />
                    )}
                </ModalDialog>
                <ModalDialog {...submitKYCprops}>
                    <div>{t("forms:kyc.submit.text")}</div>

                    <StyledList className="gray">
                        {getSubmitDocList().map((item, idx) => (
                            <li key={idx}>{item.type}</li>
                        ))}
                    </StyledList>
                </ModalDialog>
            </Flex.Column>
            <ModalContainer isOpen={mangoOpen} bgColor="transparent">
                <MangoWrapper>
                    <CloseMango>
                        <div onClick={() => setMangoOpen(false)}>
                            <StyledClose />
                            {t("actions:close")}
                        </div>
                    </CloseMango>
                    <ImgWrapper>
                        <img alt="guide" src="/images/mangopay_guide.jpg" />
                    </ImgWrapper>
                </MangoWrapper>
            </ModalContainer>
        </LayoutInnerContainer>
    );
};

export default KycScreen;
