import FilterItem,{FilterGroup}  from "./FilterItem";
import FilterTitle from "./FilterTitle";
import FilterWrapper from "./FilterWrapper";

const Filters = {
    Wrapper: FilterWrapper,
    Title: FilterTitle,
    Item: FilterItem,
    Group: FilterGroup
};

export default Filters;
