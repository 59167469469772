import RouteCreator from "../../../core/RouteCreator";
import { ReactComponent as MyStockSvg } from "../../../assets/pwa/my-stock.svg";
import { ReactComponent as CompanyStockSvg } from "../../../assets/pwa/company-stock.svg";
import { ReactComponent as CylindersSvg } from "../../../assets/pwa/cylinders.svg";
import { ReactComponent as AnylizersSvg } from "../../../assets/pwa/analyzers.svg";
import { ReactComponent as UnitsSvg } from "../../../assets/pwa/units.svg";
import { ReactComponent as LogoutSvg } from "../../../assets/pwa/logout.svg";
import styled from "styled-components";

const IconLogout = styled(LogoutSvg)`
    width: 30px;
    height: 30px;
    padding-left: 4px;
    &.active path,
    &.active line {
        stroke: #fff;
    }

    &.active path.bottle {
        fill: ${(p) => p.theme.primaryColor};
    }
`;

const IconUnits = styled(UnitsSvg)`
    width: 30px;
    height: 30px;

    & path,
    & line {
        stroke: #000;
    }

    & path.bottle {
        fill: #fff;
    }

    &.active path,
    &.active line {
        stroke: #fff;
    }

    &.active path.bottle {
        fill: ${(p) => p.theme.primaryColor};
    }
`;

const IconAnalyzers = styled(AnylizersSvg)`
    width: 30px;
    height: 30px;

    & path {
        stroke: #000;
    }

    & path.bottle {
        fill: #fff;
    }

    &.active path {
        stroke: #fff;
    }

    &.active path.bottle {
        fill: ${(p) => p.theme.primaryColor};
    }
`;

const IconMyStock = styled(MyStockSvg)`
    width: 30px;
    height: 30px;

    & path {
        stroke: #000;
    }

    & path.bottle {
        fill: #fff;
    }

    &.active path {
        stroke: #fff;
    }

    &.active path.bottle {
        fill: ${(p) => p.theme.primaryColor};
    }
`;

const IconCompanytock = styled(CompanyStockSvg)`
    width: 30px;
    height: 30px;

    & path {
        stroke: #000;
    }

    & path.bottle {
        fill: #fff;
    }

    &.active path {
        stroke: #fff;
    }

    &.active path.bottle {
        fill: ${(p) => p.theme.primaryColor};
    }
`;

const IconCylinders = styled(CylindersSvg)`
    width: 30px;
    height: 30px;

    & path {
        stroke: #000;
    }

    & path.bottle {
        fill: #fff;
    }

    &.active path {
        stroke: #fff;
    }

    &.active path.bottle {
        fill: ${(p) => p.theme.primaryColor};
    }
`;

const PwaMenuItems = (enabled) => {
    return [
        {
            name: "navigation:myStock",
            Icon: IconMyStock,
            url: enabled ? RouteCreator.home() : null,
        },
        {
            name: "navigation:companyStock",
            Icon: IconCompanytock,
            url: enabled ? RouteCreator.pwa.stock() : null,
        },
        {
            name: "navigation:extractionCylinders",
            Icon: IconCylinders,
            url: enabled ? RouteCreator.pwa.cylinders() : null,
        },
        {
            name: "navigation:gasAnalyzers",
            Icon: IconAnalyzers,
            url: RouteCreator.pwa.analyzers(),
        },
        {
            name: "navigation:units",
            Icon: IconUnits,
            url: enabled ? RouteCreator.pwa.equipment() : null,
        },
        {
            name: "navigation:logOut",
            Icon: IconLogout,
            url: "#",
            action: "logout",
        },
    ];
};

export default PwaMenuItems;
