import React from "react";
import InputElement from "../../../../components/elements/form-elements/InputElement";
import {
    InfoText,
    InfoTitle,
    InfoWrapper,
    RoundInfo,
} from "../../../../components/modal/components/WizardStyling";
import ValidationRule, {
    buildValidation,
} from "../../../../core/validations/ValidationRule";
import TagsService from "../../../../services/Tags/TagsService";

const defaultInitialValuesEdit = {
    tagId: "",
    name: "",
    description: "",
    macAddress: "",
    imageFile: "",
};

const defaultInitialValuesAdd = {
    tagId: "",
    name: "",
    description: "",
    macAddress: "",
    imageFile: "",
};

const lookupUnassignedTags = async (tag) => {
    if (tag.length > 2) {
        const tags = await TagsService.lookupUnassignedTags(tag);
        return tags.map((t) => t.id);
    }
    return [];
};

const validTagId = (t) => {
    return buildValidation({
        tagId: ValidationRule.isStringRule()
            .isUUID(t("forms:unit.uuid.invalid"))
            .required(t("forms:unit.unitTagId.required")).rule,
    });
};

const validAnalyzer = (t) => {
    return buildValidation({
        macAddress: ValidationRule.isStringRule()
            .regexMatchRule(
                /^([0-9a-fA-F]{2}[:-]){5}[0-9a-fA-F]{2}$/i,
                t("forms:analyzer.wizard.macInvalid")
            )
            .required(t("forms:analyzer.wizard.macRequired")).rule,
        name: ValidationRule.isStringRule().required(t("forms:analyzer.wizard.nameRequired")).rule,
    });
};

const EquipmentWizard = (t, initialValues = [], isEdit = false, preFill = "") => {
    if (preFill) defaultInitialValuesAdd.name = preFill;
    const steps = [];
    if (!isEdit) {
        steps.push({
            name: t("forms:analyzer.wizard.step1Title"),
            fieldNames: ["tagId"],
            validation: validTagId(t),
            fields: (formik) => (
                <>
                    <InfoWrapper>
                        <RoundInfo alt="" src="/images/menu/green-info.svg" />
                        <div>
                            <InfoTitle>
                                {isEdit
                                    ? t("forms:equipment.editEquipmentTitle")
                                    : t("forms:analyzer.wizard.infoTitle")}
                            </InfoTitle>
                            <InfoText>
                                {t("forms:analyzer.wizard.infoText")}
                                <br />
                                {t("forms:analyzer.wizard.infoText2")}
                            </InfoText>
                        </div>
                    </InfoWrapper>

                    <InputElement
                        labelText={t("forms:analyzer.wizard.step1Title")}
                        id="tagId"
                        name="tagId"
                        type="search"
                        searchFn={lookupUnassignedTags}
                        wrapperVariant="flex-1"
                        {...formik}
                    />
                </>
            ),
        });
    }

    steps.push({
        name: t("forms:analyzer.wizard.step2Title"),
        fieldNames: ["macAddress", "name", "description", "imageFile"],
        validation: validAnalyzer(t),
        fields: (formik) => {
            return (
                <>
                    <InfoWrapper>
                        <RoundInfo alt="" src="/images/menu/green-info.svg" />
                        <div>
                            <InfoTitle>
                                {isEdit
                                    ? t("forms:equipment.editEquipmentTitle")
                                    : t("forms:analyzer.wizard.infoTitle")}
                            </InfoTitle>
                            <InfoText>
                                {t("forms:analyzer.wizard.infoText")}
                                <br />
                                {t("forms:analyzer.wizard.infoText2")}
                            </InfoText>
                        </div>
                    </InfoWrapper>

                    <InputElement
                        labelText={t("forms:analyzer.wizard.macAddress")}
                        mask="**:**:**:**:**:**"
                        alwaysShowMask
                        id="macAddress"
                        autoComplete="new-description"
                        name="macAddress"
                        type="text"
                        wrapperVariant="flex-2"
                        disabled={isEdit}
                        {...formik}
                    />

                    <InputElement
                        labelText={t("forms:analyzer.wizard.name")}
                        id="name"
                        autoComplete="new-description"
                        name="name"
                        type="text"
                        wrapperVariant="flex-2"
                        {...formik}
                    />

                    <InputElement
                        labelText={t("forms:clients.notes")}
                        id="description"
                        name="description"
                        autoComplete="new-description"
                        type="textarea"
                        wrapperVariant="flex-2"
                        {...formik}
                    />

                    <InputElement
                        labelText=""
                        id="imageFile"
                        name="imageFile"
                        type="image"
                        skipUrl="/images/GasAnalyzer_no_image.svg"
                        placeholder={t("forms:analyzer.wizard.browse")}
                        title={t("forms:analyzer.wizard.picture")}
                        wrapperVariant="flex-2"
                        className="doubleHeight"
                        {...formik}
                    />
                </>
            );
        },
    });

    return {
        title: isEdit ? t("forms:analyzer.editAnalyzer") : t("forms:analyzer.addAnalyzer"),
        initialValues:
            initialValues || (isEdit ? defaultInitialValuesEdit : defaultInitialValuesAdd),
        steps,
    };
};

export default EquipmentWizard;
