import React from "react";
import Wrappers from "../../../components/wrappers/Wrappers";
import { useTranslation } from "react-i18next";
import {
    InputSubmit,
    Select,
    Checkbox,
} from "../../../components/elements/form-elements";
import Flex from "../../../components/layout/flex/Flex";

const expires = [
    { key: "0", value: 0, label: "Never" },
    { key: "1", value: 30, label: "1 month" },
    { key: "2", value: 90, label: "3 months" },
];

const User2faForm = ({ formik, isLoading, isCompleted,  }) => {
    const { t } = useTranslation();

    return (
        <Wrappers.FormWrapper variant="nomargin">
            <Flex.Container>
                <Flex.Column style={{ width: "520px",margin: "0 0 16px" }}>
                    <Flex.Container
                        flexWrap="wrap"
                        style={{ marginTop: "25px" }}
                    >
                        <Select
                            labelText={t("forms:passwordExpiration.text")}
                            wrapperVariant="flex-half"
                            name="passwordExpiration"
                            id="passwordExpiration"
                            defaultFirstOption={true}
                            className="filters"
                            labelClassName="spaced"
                            options={expires}
                            {...formik}
                        />
                        <Checkbox
                            header={t("forms:twoFA.text")}
                            labelText={t("forms:twoFA.enable2FA")}
                            id="twoFactorEnabled"
                            name="twoFactorEnabled"
                            className="centered"
                            wrapperVariant="half"
                            {...formik}
                        />
                    </Flex.Container>
                </Flex.Column>
            </Flex.Container>
            <Flex.Row >
                <InputSubmit
                    disabled={isLoading}
                    variant="link"
                    value={t("screens:user2FA.button_text")}
                />
            </Flex.Row>
        </Wrappers.FormWrapper>
    );
};

export default User2faForm;
