import React from "react";
import { Redirect, Switch } from "react-router-dom";
import PrivateRoute from "../../core/PrivateRoute";
import RouteCreator from "../../core/RouteCreator";
import useLeftMenu from "../../core/state/navigation/useLeftMenu";
import globalAdminMenuItems from "../../data/menu/globalAdminMenuItems";
import QRGenerationView from "./views/qr/QRGenerationView";

const QRTagsScreen = () => {
    useLeftMenu(globalAdminMenuItems);
    return (
        <Switch>
            <PrivateRoute
                path={RouteCreator.admin.qrTags()}
                component={QRGenerationView}
            />
            <Redirect to={RouteCreator.admin.qrTags()} />
        </Switch>
    );
};

export default QRTagsScreen;
