import React from "react";
import * as yup from "yup";
import { Checkbox } from "../../../../../components/elements/form-elements";
import InputElement from "../../../../../components/elements/form-elements/InputElement";
import {
    InfoTitle,
    InfoWrapper,
} from "../../../../../components/modal/components/WizardStyling";
import ValidationRule, {
    buildValidation,
    skipValidation,
} from "../../../../../core/validations/ValidationRule";

const defaultInitialValues = {
    tagId: "",
    tagCheck: false
};

const validTagCheck = (t) => {
    yup.lazy((values) => {
        if (!values.tagCheck) return skipValidation();
        return buildValidation({
            tagId: ValidationRule.isStringRule()
                .isUUID(t("forms:unit.uuid.invalid"))
                .required(t("forms:unit.unitTagId.required")).rule
        });
    })
}

const UnitWizardReTag = (t, searchFn = () => {
}) => {

    let validateTagCheck = defaultInitialValues.tagCheck;
    const handleTagCheck = (ev, formik) => {
        validateTagCheck = ev.target.checked;
        formik.setFieldValue("tagCheck", ev.target.checked);
    };

    return {
        title: t('forms:clients.reTagUnit'),
        initialValues: defaultInitialValues,
        steps: [
            {
                name: t('forms:clients.step1ReTag'),
                fieldNames: ["tagId"],
                validation: validTagCheck(t),
                fields: (formik) => (
                    <>
                        <InfoWrapper>
                            <div>
                                <InfoTitle>
                                    <Checkbox
                                        labelText={t("forms:clients.reTagCheckbox")}
                                        id="tagCheck"
                                        name="tagCheck"
                                        onChange={(ev) =>
                                            handleTagCheck(ev, formik)
                                        }
                                        description={[t('forms:clients.reTagInfoText'), t('forms:clients.reTagInfoText2')]}
                                        {...formik}
                                    />
                                </InfoTitle>
                            </div>
                        </InfoWrapper>
                        <InputElement
                            labelText={t("forms:cylinderTagId.text")}
                            id="tagId"
                            name="tagId"
                            type="search"
                            searchFn={searchFn}
                            wrapperVariant="flex-1"
                            disabled={!validateTagCheck}
                            {...formik}
                        />
                    </>
                )
            }
        ]
    }
};

export default UnitWizardReTag;
