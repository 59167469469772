import { useEffect } from "react";
import useStateModule from "../useStateModule";
import leftNavigationStateModule from "./leftNavigationStateModule";
import globals from "../../../globalVars";

const useLeftMenu = (leftMenu) => {
    const { menuItems, setLeftMenu, isVisible, setIsVisible } = useStateModule(
        leftNavigationStateModule
    );

    useEffect(() => {
        if (leftMenu) {
            setIsVisible(true);
            setLeftMenu(leftMenu);
        }
        return () => setLeftMenu(menuItems);
    }, [setLeftMenu, leftMenu, globals.isMobile, setIsVisible]);

    return { menuItems, isVisible, setIsVisible };
};

export default useLeftMenu;
