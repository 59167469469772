import FlexColumn from "./FlexColumn";
import FlexContainer from "./FlexContainer";
import FlexRow from './FlexRow';

const Flex = {
    Column: FlexColumn,
    Container: FlexContainer,
    Row: FlexRow
};

export default Flex;
