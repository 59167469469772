import React from "react";
import DateCellWithExpiration from "../../../../components/data/DateCellWithExpiration";
import TransactionStatus from "../../../../components/elements/general/TransactionStatus";
import HamburgerMenu from "../../../../components/menu/HamburgerMenu";
import HamburgerMenuAction from "../../../../components/menu/HamburgerMenuAction";
import AuthorizeComponent from "../../../../core/AuthorizeComponent";
import BidStatus from "../../../../core/enums/BidStatus";
import OfferStatus from "../../../../core/enums/OfferStatus";
import Roles from "../../../../core/enums/Roles";
import BidGradePriceCell from "../../../SalesCenter/views/components/BidGradePriceCell";
import helpers from "../../../../core/helpers";
import GradeCell from "../../../../components/data/GradeCell";
import GreenCell from "../../../../components/data/GreenCell";
import TooltipCell from "../../../../components/data/TooltipCell";
import HandlingCell from "../../../../components/data/HandlingCell";
import DateCell from "../../../../components/data/DateCell";


export const createSteps = (value, t, bidStatus, original) => {
    const offerer = original?.offerer;
    const cleaningServiceProvider = original?.bidCompanyName;

    if (bidStatus === 2 || bidStatus === 3) {
        return Array.from([
            {
                status: 2,
                title: `${t("common:status.step")}: 1/5 - ${t(
                    "common:status.completed"
                ).toUpperCase()} `,
                tip:
                    bidStatus === 2
                        ? t("common:status.completed_rejected", {
                              company: offerer,
                          })
                        : t("common:status.completed_revoked", {
                              company: cleaningServiceProvider,
                          }),
            },
            {
                status: 0,
                title: `${t("common:status.step")}: 2/5 - ${t(
                    "common:status.canceled"
                ).toUpperCase()} `,
                tip:
                    bidStatus === 2
                        ? t("common:status.completed_rejected", {
                              company: offerer,
                          })
                        : t("common:status.completed_revoked", {
                              company: cleaningServiceProvider,
                          }),
            },
            {
                status: 0,
                title: `${t("common:status.step")}: 3/5 - ${t(
                    "common:status.canceled"
                ).toUpperCase()} `,
                tip:
                    bidStatus === 2
                        ? t("common:status.completed_rejected", {
                              company: offerer,
                          })
                        : t("common:status.completed_revoked", {
                              company: cleaningServiceProvider,
                          }),
            },
            {
                status: 0,
                title: `${t("common:status.step")}: 4/5 - ${t(
                    "common:status.canceled"
                ).toUpperCase()} `,
                tip:
                    bidStatus === 2
                        ? t("common:status.completed_rejected", {
                              company: offerer,
                          })
                        : t("common:status.completed_revoked", {
                              company: cleaningServiceProvider,
                          }),
            },
            {
                status: 0,
                title: `${t("common:status.step")}: 5/5 - ${t(
                    "common:status.canceled"
                ).toUpperCase()} `,
                tip:
                    bidStatus === 2
                        ? t("common:status.completed_rejected", {
                              company: offerer,
                          })
                        : t("common:status.completed_revoked", {
                              company: cleaningServiceProvider,
                          }),
            },
        ]);
    }

    let result = Array.from([
        {
            status: 4,
            title: `${t("common:status.step")}: 1/5 - ${t(
                "common:status.inProgress"
            ).toUpperCase()} `,
            tip: t("common:status.progress_Accept", {
                company: offerer,
            }),
        },
        {
            status: 3,
            title: `${t("common:status.step")}: 2/5 - ${t(
                "common:status.pending"
            ).toUpperCase()} `,
            tip: t("common:status.pending_Adeposit", {
                company: cleaningServiceProvider,
            }),
        },
        {
            status: 3,
            title: `${t("common:status.step")}: 3/5 - ${t(
                "common:status.pending"
            ).toUpperCase()} `,
            tip: t("common:status.pending_collect", {
                company: cleaningServiceProvider,
            }),
        },
        {
            status: 3,
            title: `${t("common:status.step")}: 4/5 - ${t(
                "common:status.pending"
            ).toUpperCase()} `,
            tip: t("common:status.pending_test", {
                company: cleaningServiceProvider,
            }),
        },
        {
            status: 3,
            title: `${t("common:status.step")}: 5/5 - ${t(
                "common:status.pending"
            ).toUpperCase()} `,
            tip: t("common:status.pending_confirmTest", {
                company: offerer,
            }),
        },
    ]);

    switch (value) {
        case 0:
            if (bidStatus === 2) {
                result[0] = {
                    status: 2,
                    tip: t("screens:myOffers.toastNotification.rejectBid"),
                };
            } else if (bidStatus === 3) {
                result[0] = {
                    status: 2,
                    tip: t("screens:myBids.successfullyRevoked"),
                };
            }
            break;

        case 2:
            result[0] = {
                status: 4,
                title: `${t("common:status.step")}: 1/5 - ${t(
                    "common:status.inProgress"
                ).toUpperCase()} `,
                tip: t("common:status.progress_Cdeposit", {
                    company: offerer,
                }),
            };
            break;
        case 3:
            result[0] = {
                status: 1,
                title: `${t("common:status.step")}: 1/5 - ${t(
                    "common:status.completed"
                ).toUpperCase()} `,
                tip: t("common:status.completed_Cdeposit", {
                    company: offerer,
                }),
            };
            result[1] = {
                status: 4,
                title: `${t("common:status.step")}: 2/5 - ${t(
                    "common:status.inProgress"
                ).toUpperCase()} `,
                tip: t("common:status.progress_Adeposit", {
                    company: cleaningServiceProvider,
                }),
            };
            break;
        case 4:
            result[0] = {
                status: 1,
                title: `${t("common:status.step")}: 1/5 - ${t(
                    "common:status.completed"
                ).toUpperCase()} `,
                tip: t("common:status.completed_Cdeposit", {
                    company: offerer,
                }),
            };
            result[1] = {
                status: 1,
                title: `${t("common:status.step")}: 2/5 - ${t(
                    "common:status.completed"
                ).toUpperCase()} `,
                tip: t("common:status.completed_Adeposit", {
                    company: cleaningServiceProvider,
                }),
            };
            result[2] = {
                status: 4,
                title: `${t("common:status.step")}: 3/5 - ${t(
                    "common:status.inProgress"
                ).toUpperCase()} `,
                tip: t("common:status.progress_collect", {
                    company: cleaningServiceProvider,
                }),
            };
            break;
        case 5:
            result[0] = {
                status: 1,
                title: `${t("common:status.step")}: 1/5 - ${t(
                    "common:status.completed"
                ).toUpperCase()} `,
                tip: t("common:status.completed_Cdeposit", {
                    company: offerer,
                }),
            };
            result[1] = {
                status: 1,
                title: `${t("common:status.step")}: 2/5 - ${t(
                    "common:status.completed"
                ).toUpperCase()} `,
                tip: t("common:status.completed_Adeposit", {
                    company: cleaningServiceProvider,
                }),
            };
            result[2] = {
                status: 1,
                title: `${t("common:status.step")}: 3/5 - ${t(
                    "common:status.completed"
                ).toUpperCase()} `,
                tip: t("common:status.completed_collect", {
                    company: cleaningServiceProvider,
                }),
            };
            result[3] = {
                status: 4,
                title: `${t("common:status.step")}: 4/5 - ${t(
                    "common:status.inProgress"
                ).toUpperCase()} `,
                tip: t("common:status.progress_test", {
                    company: cleaningServiceProvider,
                }),
            };
            break;
        case 6:
            result[0] = {
                status: 1,
                title: `${t("common:status.step")}: 1/5 - ${t(
                    "common:status.completed"
                ).toUpperCase()} `,
                tip: t("common:status.completed_Cdeposit", {
                    company: offerer,
                }),
            };
            result[1] = {
                status: 1,
                title: `${t("common:status.step")}: 2/5 - ${t(
                    "common:status.completed"
                ).toUpperCase()} `,
                tip: t("common:status.completed_Adeposit", {
                    company: cleaningServiceProvider,
                }),
            };
            result[2] = {
                status: 1,
                title: `${t("common:status.step")}: 3/5 - ${t(
                    "common:status.completed"
                ).toUpperCase()} `,
                tip: t("common:status.completed_collect", {
                    company: cleaningServiceProvider,
                }),
            };
            result[3] = {
                status: 1,
                title: `${t("common:status.step")}: 4/5 - ${t(
                    "common:status.completed"
                ).toUpperCase()} `,
                tip: t("common:status.completed_test", {
                    company: cleaningServiceProvider,
                }),
            };
            result[4] = {
                status: 4,
                title: `${t("common:status.step")}: 5/5 - ${t(
                    "common:status.inProgress"
                ).toUpperCase()} `,
                tip: t("common:status.progress_confirmTest", {
                    company: offerer,
                }),
            };
            break;
        case 10:
            result[0] = {
                status: 1,
                tip: t("screens:disputes.pendingSettlement"),
            };
            break;
        case 99:
            result[0] = {
                status: 1,
                title: `${t("common:status.step")}: 1/5 - ${t(
                    "common:status.completed"
                ).toUpperCase()} `,
                tip: t("common:status.completed_Cdeposit", {
                    company: offerer,
                }),
            };
            result[1] = {
                status: 1,
                title: `${t("common:status.step")}: 2/5 - ${t(
                    "common:status.completed"
                ).toUpperCase()} `,
                tip: t("common:status.completed_Adeposit", {
                    company: cleaningServiceProvider,
                }),
            };
            result[2] = {
                status: 1,
                title: `${t("common:status.step")}: 3/5 - ${t(
                    "common:status.completed"
                ).toUpperCase()} `,
                tip: t("common:status.completed_collect", {
                    company: cleaningServiceProvider,
                }),
            };
            result[3] = {
                status: 1,
                title: `${t("common:status.step")}: 4/5 - ${t(
                    "common:status.completed"
                ).toUpperCase()} `,
                tip: t("common:status.completed_test", {
                    company: cleaningServiceProvider,
                }),
            };
            result[4] = {
                status: 1,
                title: `${t("common:status.step")}: 5/5 - ${t(
                    "common:status.completed"
                ).toUpperCase()} `,
                tip: t("common:status.completed_confirmTest", {
                    company: offerer,
                }),
            };
            break;
        default:
            break;
    }
    return result;
};

const CleaningBidsColumns = {
    getColumns: (
        bidStatus,
        t,
        actions,
        offerStatus,
        currentLanguage,
        globalState,
        setOpenAccordionId
    ) => {
        const gasGradeNames = [
            t("sidebar:label.bid.aGrade"),
            t("sidebar:label.bid.bGrade"),
            t("sidebar:label.bid.cGrade"),
        ];
        const pendingColumns = [
            {
                Header: t("table:headers.gasType"),
                accessor: "refrigerantGasTypeCode",
                Cell: GreenCell,
            },
            {
                Header: t("table:headers.gasQuantity"),
                accessor: "quantitySum",
                Cell: ({ cell: { value } }) =>
                    GreenCell({ cell: { value: value.toFixed(2) + " kg" } }),
            },
            {
                Header: t("table:headers.grade"),
                accessor: "pressumedGradeName",
                Cell: GradeCell,
            },
            {
                Header: t("table:headers.evaluation"),
                accessor: "analysisDone",
                Cell: ({ cell: { value } }) =>
                    !value
                        ? t("filters:analysis.measurement")
                        : t("filters:analysis.selfAssessment"),
            },
            {
                Header: `${t("table:headers.bidAmounts")}/${t(
                    "sidebar:label.extraction.qualityGrade"
                )}`,
                accessor: "price",
                Cell: BidGradePriceCell,
            },
            {
                Header: t("table:headers.handlingCosts"),
                accessor: "handlingCosts",
                Cell: ({ cell: { value } }) =>
                    HandlingCell(value, setOpenAccordionId, "bid.details"),
            },
            {
                Header: "Status",
                accessor: "offerStatus",
                disableSortBy: true,
                Cell: ({ cell: { value }, row: { original } }) => (
                    <TransactionStatus
                        steps={createSteps(value, t, null, original)}
                    />
                ),
            },
            {
                Header: "",
                accessor: "bidId",
                Cell: ({ cell: { value, row } }) => (
                    <AuthorizeComponent
                        roles={[
                            Roles.InstallerCompanyAdmin,
                            Roles.InstallerCompanyUser,
                        ]}
                    >
                        <HamburgerMenu>
                            <HamburgerMenuAction
                                text={t("actions:bidActions.revokeBid")}
                                onClick={async () => {
                                    actions.setSelectedBid(row.original);
                                    actions.toggleOpenRevoke(value);
                                }}
                            />
                        </HamburgerMenu>
                    </AuthorizeComponent>
                ),
                disableSortBy: true,
            },
        ];
        const acceptedColumns = [
            {
                Header: t("table:headers.gasType"),
                accessor: "refrigerantGasTypeCode",
                Cell: GreenCell,
            },
            {
                Header: t("table:headers.gasQuantity"),
                accessor: "quantitySum",
                Cell: ({ cell: { value } }) =>
                    GreenCell({ cell: { value: value.toFixed(2) + " kg" } }),
            },
            {
                Header: t("table:headers.grade"),
                accessor: "pressumedGradeName",
                Cell: GradeCell,
            },
            {
                Header: t("table:headers.evaluation"),
                accessor: "analysisDone",
                Cell: ({ cell: { value } }) =>
                    !value
                        ? t("filters:analysis.measurement")
                        : t("filters:analysis.selfAssessment"),
            },
            {
                Header: `${t("table:headers.bidAmounts")}/${t(
                    "sidebar:label.extraction.qualityGrade"
                )}`,
                accessor: "price",
                Cell: BidGradePriceCell,
            },
            {
                Header: t("table:headers.handlingCosts"),
                accessor: "handlingCosts",
                Cell: ({ cell: { value } }) =>
                    HandlingCell(value, setOpenAccordionId, "bid.details"),
            },
            {
                Header: t("myBids.headers.bidDealExpiration"),
                accessor: "bidDealExpiration",
                Cell: (value) => {
                    let translation = "";
                    const offerStatus = value.row.original.offerStatus;
                    if (offerStatus === 2) {
                        translation = "screens:myOffers.offerStatus.2";
                    } else if (offerStatus === 3) {
                        translation = "screens:myOffers.offerStatus.3";
                    } else if (offerStatus === 4) {
                        translation = "screens:myOffers.offerStatus.4";
                    }
                    return DateCellWithExpiration(value, translation);
                },
            },
            {
                Header: "Status",
                accessor: "offerStatus",
                disableSortBy: true,
                Cell: ({ cell: { value }, row: { original } }) => (
                    <TransactionStatus
                        steps={createSteps(value, t, null, original)}
                    />
                ),
            },
            {
                Header: "",
                accessor: "offerId",
                Cell: ({
                    cell: {
                        value,
                        row: { original },
                    },
                }) => {
                    if (original.bidStatus !== 1) return null;

                    return (
                        <AuthorizeComponent
                            roles={[
                                Roles.InstallerCompanyAdmin,
                                Roles.InstallerCompanyUser,
                            ]}
                        >
                            <HamburgerMenu>
                                {!original.hasMangoTransaction && (
                                    <HamburgerMenuAction
                                        text={t("actions:bidActions.payForBid")}
                                        onClick={async () => {
                                            var offerInfo = `${
                                                original.refrigerantGasTypeCode
                                            } | ${
                                                gasGradeNames[
                                                    original.pressumedGradeValue
                                                ]
                                            } | ${original.maxQuantitySum} kg`;

                                            await actions.payForAGrade(
                                                original.bidId,
                                                14 * original.maxQuantitySum,
                                                offerInfo,
                                                original
                                            );
                                        }}
                                        disabled={
                                            original.offerStatus !== 3 ||
                                            !globalState.hasCertificateRights
                                        }
                                    />
                                )}

                                {original.hasMangoTransaction && (
                                    <HamburgerMenuAction
                                        text={t(
                                            "actions:dialogs.transactionDetails.title"
                                        )}
                                        onClick={async () => {
                                            await actions.getTransactionDetails(
                                                original,
                                                original.bidId
                                            );
                                        }}
                                        disabled={
                                            original.offerStatus !== 3 ||
                                            !globalState.hasCertificateRights
                                        }
                                    />
                                )}
                            </HamburgerMenu>
                        </AuthorizeComponent>
                    );
                },
                disableSortBy: true,
            },
        ];
        const qualityTestColumns = [
            {
                Header: t("table:headers.gasType"),
                accessor: "refrigerantGasTypeCode",
                Cell: GreenCell,
            },
            {
                Header: t("table:headers.gasQuantity"),
                accessor: "quantitySum",
                Cell: ({ cell: { value } }) =>
                    GreenCell({ cell: { value: value.toFixed(2) + " kg" } }),
            },
            {
                Header: t("table:headers.grade"),
                accessor: "pressumedGradeName",
                Cell: GradeCell,
            },
            {
                Header: t("table:headers.evaluation"),
                accessor: "analysisDone",
                Cell: ({ cell: { value } }) =>
                    !value
                        ? t("filters:analysis.measurement")
                        : t("filters:analysis.selfAssessment"),
            },
            {
                Header: `${t("table:headers.bidAmounts")}/${t(
                    "sidebar:label.extraction.qualityGrade"
                )}`,
                accessor: "price",
                Cell: BidGradePriceCell,
            },
            {
                Header: t("table:headers.handlingCosts"),
                accessor: "handlingCosts",
                Cell: ({ cell: { value } }) =>
                    HandlingCell(value, setOpenAccordionId, "bid.details"),
            },
            {
                Header: t("table:headers.purchasePrice"),
                accessor: "cleaningServicePrice",
                Cell: ({ cell: { row } }) => {
                    const currency = "EUR";

                    return TooltipCell({
                        cell: {
                            value: helpers.formatCurrency(
                                helpers.calculatePriceWithVat(
                                    row.original.cleaningServicePrice
                                ),
                                currentLanguage,
                                currency
                            ),
                        },
                        tip: t("table:tooltips.withVat"),
                    });
                },
            },
            {
                Header: t("stockListing.headers.dealExpiration"),
                accessor: "bidDealExpiration",
                Cell: (value) => {
                    let translation = "";
                    const offerStatus = value.row.original.offerStatus;
                    if (offerStatus === 5) {
                        translation = "screens:myOffers.offerStatus.5";
                    }
                    const onHold =
                        offerStatus === 10 ? t("common:status.onHold") : null;
                    return DateCellWithExpiration(value, translation, onHold);
                },
            },
            {
                Header: "Status",
                accessor: "offerStatus",
                disableSortBy: true,
                Cell: ({ cell: { value }, row: { original } }) => (
                    <TransactionStatus
                        inDispute={
                            original.offerStatus === 10
                                ? t("common:status.inDispute")
                                : null
                        }
                        steps={createSteps(value, t, null, original)}
                    />
                ),
            },
            {
                Header: "",
                accessor: "offerId",
                Cell: ({ cell: { value, row } }) => {
                    if (row.original.bidStatus !== 1) return <></>;
                    return (
                        <AuthorizeComponent
                            roles={[
                                Roles.InstallerCompanyAdmin,
                                Roles.InstallerCompanyUser,
                            ]}
                        >
                            <HamburgerMenu>
                                <HamburgerMenuAction
                                    text={t("actions:bidActions.qualityTest")}
                                    onClick={() => {
                                        actions.showQualityTestForm(
                                            row.original.offerId,
                                            row.original.offerer,
                                            row.original.refrigerantGasTypeCode,
                                            row.original.cylindersAnalysis
                                        );
                                    }}
                                    disabled={
                                        row.original.offerStatus !==
                                            OfferStatus.buyerClaimedBottles ||
                                        !globalState.hasCertificateRights
                                    }
                                />
                                <HamburgerMenuAction
                                    text={t(
                                        "actions:bidActions.sellPresumedQuality"
                                    )}
                                    disabled={
                                        row.original.offerStatus !==
                                            OfferStatus.buyerClaimedBottles ||
                                        !globalState.hasCertificateRights
                                    }
                                    onClick={() => {
                                        actions.setSelectedBid(row.original);
                                        actions.togglePayPresumedQuality([
                                            value,
                                        ]);
                                    }}
                                />
                            </HamburgerMenu>
                        </AuthorizeComponent>
                    );
                },
                disableSortBy: true,
            },
        ];
        const paymentCompletedColumns = [
            {
                Header: t("table:headers.gasType"),
                accessor: "confirmedRefrigerantGasTypeCode",
                Cell: GreenCell,
            },
            {
                Header: t("table:headers.gasQuantity"),
                accessor: "confirmedQuantity",
                Cell: ({ cell: { value } }) =>
                    GreenCell({ cell: { value: value?.toFixed(2) + " kg" } }),
            },
            {
                Header: t("table:headers.grade"),
                accessor: "confirmedGradeName",
                Cell: GradeCell,
            },
            {
                Header: t("table:headers.evaluation"),
                accessor: "analysisDone",
                Cell: ({ cell: { value } }) =>
                    !value
                        ? t("filters:analysis.measurement")
                        : t("filters:analysis.selfAssessment"),
            },
            {
                Header: `${t("table:headers.bidAmounts")}/${t(
                    "sidebar:label.extraction.qualityGrade"
                )}`,
                accessor: "price",
                Cell: BidGradePriceCell,
            },
            {
                Header: t("table:headers.handlingCosts"),
                accessor: "handlingCosts",
                Cell: ({ cell: { value } }) =>
                    HandlingCell(value, setOpenAccordionId, "bid.details"),
            },
            {
                Header: t("table:headers.purchasePrice"),
                accessor: "cleaningServicePrice",
                Cell: ({ cell: { row } }) => {
                    const currency = "EUR";

                    return TooltipCell({
                        cell: {
                            value: helpers.formatCurrency(
                                helpers.calculatePriceWithVat(
                                    row.original.cleaningServicePrice
                                ),
                                currentLanguage,
                                currency
                            ),
                        },
                        tip: t("table:tooltips.withVat"),
                    });
                },
            },
            {
                Header: t("table:headers.cleanedDate"),
                accessor: "lastChangeDateTime",
                Cell: DateCell
            },
            {
                Header: "Status",
                accessor: "offerStatus",
                disableSortBy: true,
                Cell: ({ cell: { value }, row: { original } }) => (
                    <TransactionStatus
                        steps={createSteps(value, t, null, original)}
                    />
                ),
            },
        ];

        const rejectedRevokedColumns = [
            {
                Header: t("table:headers.gasType"),
                accessor: "refrigerantGasTypeCode",
                Cell: GreenCell,
            },
            {
                Header: t("table:headers.gasQuantity"),
                accessor: "quantitySum",
                Cell: ({ cell: { value } }) =>
                    GreenCell({ cell: { value: value.toFixed(2) + " kg" } }),
            },
            {
                Header: t("table:headers.grade"),
                accessor: "pressumedGradeName",
                Cell: GradeCell,
            },
            {
                Header: t("table:headers.evaluation"),
                accessor: "analysisDone",
                Cell: ({ cell: { value } }) =>
                    !value
                        ? t("filters:analysis.measurement")
                        : t("filters:analysis.selfAssessment"),
            },
            {
                Header: `${t("table:headers.bidAmounts")}/${t(
                    "sidebar:label.extraction.qualityGrade"
                )}`,
                accessor: "price",
                Cell: BidGradePriceCell,
            },
            {
                Header: t("table:headers.handlingCosts"),
                accessor: "handlingCosts",
                Cell: ({ cell: { value } }) =>
                    HandlingCell(value, setOpenAccordionId, "bid.details"),
            },
            {
                Header: "Status",
                accessor: "offerStatus",
                disableSortBy: true,
                Cell: ({ cell: { value }, row: { original } }) => (
                    <TransactionStatus
                        steps={createSteps(
                            value,
                            t,
                            original.bidStatus,
                            original
                        )}
                    />
                ),
            },
        ];

        if (
            bidStatus.includes(BidStatus.accepted) &&
            (offerStatus.includes(OfferStatus.buyerEscrowedFunds) ||
                offerStatus.includes(OfferStatus.offererEscrowedFunds))
        ) {
            return acceptedColumns;
        } else if (
            bidStatus.includes(BidStatus.accepted) &&
            (offerStatus.includes(OfferStatus.buyerClaimedBottles) ||
                offerStatus.includes(OfferStatus.qualityTested))
        ) {
            return qualityTestColumns;
        } else if (
            bidStatus.includes(BidStatus.accepted) &&
            offerStatus.includes(OfferStatus.sold)
        ) {
            return paymentCompletedColumns;
        } else if (
            bidStatus.includes(BidStatus.revoked) ||
            bidStatus.includes(BidStatus.rejected)
        ) {
            return rejectedRevokedColumns;
        }
        return pendingColumns;
    },
};

export default CleaningBidsColumns;
