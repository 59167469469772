import React from "react";
import styled from "styled-components";

const ActionsWrapper = styled.div`
    display: flex;

    &.right-action {
        float: right;
        margin-left: 15px;
    }

    .gm-style button, .gm-style a {
        color: black;
        background: none;
        border:0;
        box-shadow: none;
        padding: 0;
        line-height: 1;
        height: initial;
    }
`;

const HeaderActionsWrapper = (props) => {
    return (
        <ActionsWrapper className={props.className}>
            {props.children}
        </ActionsWrapper>
    )
};

export default HeaderActionsWrapper;
