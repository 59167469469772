import Roles from "../../core/enums/Roles";
import RouteCreator from "../../core/RouteCreator";
import UserService from "../../services/User/UserService";

let cache = [];
let lastGlobalState = {};


const companyAdminMenuItems = (globalState) => {
    if (globalState !== lastGlobalState) {
        lastGlobalState = globalState;
        cache = [
            {
                name: "navigation:salesCenter",
                url: RouteCreator.salesCenter.root(),
                showForRoles: [
                    Roles.InstallerCompanyAdmin,
                    Roles.InstallerCompanyUser,
                ],
                icon: {
                    src: "/images/menu/sales_center.svg",
                    srcHover: "/images/menu/sales_center_hover.svg",
                    activeSrc: "/images/menu/sales_center_active.svg",
                    alt: "Sales Center",
                    style: {
                        width: "32px",
                    },
                },
                children: [
                    {
                        name: "navigation:recoveredGas",
                        url: RouteCreator.salesCenter.marketplace(),
                        activePaths: [
                            RouteCreator.salesCenter.myOffers(),
                            RouteCreator.salesCenter.myBids(),
                        ],
                    },
                    {
                        name: "navigation:reclaimedGas",
                        url: RouteCreator.salesCenter.reclaimedMarketplace(),
                        activePaths: [
                            RouteCreator.salesCenter.reclaimedMarketplace(),
                            RouteCreator.salesCenter.reclaimedMarketOffers(),
                            RouteCreator.salesCenter.reclaimedMarketPurchases(),
                        ],
                    },
                ],
            },
            {
                name: "navigation:services",
                url: RouteCreator.services.root(),
                showForRoles: [
                    Roles.InstallerCompanyAdmin,
                    Roles.InstallerCompanyUser,
                ],
                get hide() {
                    return globalState.showServicesSection !== true;
                },
                icon: {
                    src: "/images/menu/services.svg",
                    srcHover: "/images/menu/services_hover.svg",
                    activeSrc: "/images/menu/services_active.svg",
                    alt: "Services",
                    style: {
                        width: "32px",
                    },
                },
                children: [
                    {
                        name: "navigation:disposalService",
                        url: RouteCreator.services.disposal(),
                        activePaths: [RouteCreator.services.disposalBids()],
                    },
                    {
                        name: "navigation:cleaningService",
                        url: RouteCreator.services.cleaning(),
                        activePaths: [RouteCreator.services.cleaningBids()],
                    },
                ],
            },
            {
                name: "navigation:disputes",
                url: RouteCreator.disputes.root(),
                showForRoles: [
                    Roles.InstallerCompanyAdmin,
                    Roles.InstallerCompanyUser,
                ],
                icon: {
                    src: "/images/menu/disputes.svg",
                    srcHover: "/images/menu/disputes_hover.svg",
                    activeSrc: "/images/menu/disputes_active.svg",
                    alt: "Sales Center",
                    style: {
                        width: "32px",
                    },
                },
            },
            {
                name: "navigation:stock",
                get disabled() {
                    return globalState.hasValidCompanyCertificate !== true;
                },
                icon: {
                    src: "/images/menu/stock.svg",
                    srcHover: "/images/menu/stock_hover.svg",
                    activeSrc: "/images/menu/stock_active.svg",
                    alt: "Stock",
                },
                url: RouteCreator.stock.root(),
                children: [
                    {
                        name: "navigation:companyStock",
                        url: RouteCreator.stock.company(),
                    },
                    {
                        name:
                            UserService.isInRole(Roles.InstallerCompanyAdmin) ||
                            UserService.isInRole(Roles.InstallerCompanyUser)
                                ? "navigation:installerStock"
                                : "navigation:myStock",

                        showForRoles: [
                            Roles.InstallerCompanyAdmin,
                            Roles.InstallerCompanyTechnician,
                        ],
                        url: RouteCreator.stock.installer(),
                    },
                ],
            },
            {
                name: "navigation:equipment",
                get disabled() {
                    return globalState.hasValidCompanyCertificate !== true;
                },
                showForRoles: [
                    Roles.InstallerCompanyAdmin,
                    Roles.InstallerCompanyTechnician,
                ],
                icon: {
                    src: "/images/menu/equipment.svg",
                    srcHover: "/images/menu/equipment_hover.svg",
                    activeSrc: "/images/menu/equipment_active.svg",
                    alt: "Equipment",
                },
                url: RouteCreator.equipment.root(),
                children: [
                    {
                        name: "navigation:cylinders",
                        url: RouteCreator.equipment.cylinders(),
                    },
                    {
                        name: "navigation:gasAnalyzers",
                        url: RouteCreator.equipment.analyzers(),
                        disabled: !globalState.showGasAnalyzers,
                    },
                ],
            },
            {
                name: "navigation:clients",
                showForRoles: [
                    Roles.InstallerCompanyAdmin,
                    Roles.InstallerCompanyUser,
                ],
                icon: {
                    src: "/images/menu/clients.svg",
                    srcHover: "/images/menu/clients_hover.svg",
                    activeSrc: "/images/menu/clients_active.svg",
                    alt: "Clients",
                },
                url: RouteCreator.clients.root(),
            },
            {
                name: "navigation:finance",
                showForRoles: [
                    Roles.InstallerCompanyAdmin,
                    Roles.InstallerCompanyUser,
                ],
                icon: {
                    src: "/images/menu/finance.svg",
                    srcHover: "/images/menu/finance_hover.svg",
                    activeSrc: "/images/menu/finance_active.svg",
                    alt: "Finance",
                },
                url: RouteCreator.finance.root(),
                children: [
                    {
                        name: "navigation:walletView",
                        url: RouteCreator.finance.transactions(),
                    },
                    {
                        name: "navigation:hierarchyView",
                        url: RouteCreator.finance.hierarchy(),
                    },
                    {
                        name: "navigation:documents",
                        url: RouteCreator.finance.documents(),
                    },
                ],
            },
        ];
    }
    return cache;
};

export default companyAdminMenuItems;
