import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import AccordionWrapper from "../../../../components/toggle-view/AccordionWrapper";
import SidebarField from "../../../../components/elements/sidebar/SidebarField";
import useAccordionGroup from "../../../../hooks/_shared/useAccordionGroup";
import helpers from "../../../../core/helpers";
import { VSpace } from "../../../pwa/components/StyledElements";
import {
    BidGradeWrapper,
    BidGrade,
} from "../../../../components/bids/BidGrade";

const InfoWrapper = styled.div`
    padding: 20px 0 0 20px;
`;

const TransactionDetailsSidebar = ({
    selectedOffer,
    offer,
    isLoading,
    info,
    closeSideBar,
}) => {
    const { t } = useTranslation("sidebar");
    const shouldShow = !isLoading && info;
    const [openAccordion, setOpenAccordion] = useAccordionGroup();
    return (
        <>
            {shouldShow && (
                <>
                    <AccordionWrapper
                        title={t("screens:equipment.headers.description")}
                        id="description"
                        isOpen={openAccordion}
                        onToggle={setOpenAccordion}
                    >
                        <InfoWrapper>
                            {info.transactionTypeDescription}
                        </InfoWrapper>
                    </AccordionWrapper>
                    {selectedOffer.offer && (
                        <AccordionWrapper
                            title={t("title.offer.details")}
                            id="offer.details"
                            isOpen={openAccordion}
                            onToggle={setOpenAccordion}
                        >
                            <>
                                <SidebarField
                                    label={t("label.type")}
                                    value={
                                        selectedOffer.offer
                                            .refrigerantGasTypeCode
                                    }
                                />
                                <SidebarField
                                    label={t("label.numberOfCylinders")}
                                    value={
                                        selectedOffer.offer.numberOfCylinders
                                    }
                                />
                                <SidebarField
                                    label={t("label.netWeight")}
                                    value={
                                        selectedOffer.offer.maximumQuantity.toFixed(
                                            2
                                        ) + " kg"
                                    }
                                />
                                <SidebarField
                                    label={t("label.totalExtractedQuantity")}
                                    value={
                                        selectedOffer.offer.quantity.toFixed(
                                            2
                                        ) + " kg"
                                    }
                                />
                                <SidebarField
                                    label={t("label.extraction.qualityGrade")}
                                    value={
                                        selectedOffer.offer.pressumedGradeName
                                    }
                                />
                                <SidebarField
                                    label={t(
                                        "label.extraction.qualityEvaluation"
                                    )}
                                    value={
                                        offer.hasAnalysis
                                            ? t("filters:analysis.measurement")
                                            : t(
                                                  "filters:analysis.selfAssessment"
                                              )
                                    }
                                />
                            </>
                        </AccordionWrapper>
                    )}

                    {/*//Show only if Accepted*/}
                    {selectedOffer.bid && selectedOffer.bid.bidStatus === 1 && (
                        <AccordionWrapper
                            title={t("title.bid.details")}
                            id="bid.details"
                            isOpen={openAccordion}
                            onToggle={setOpenAccordion}
                        >
                            <SidebarField
                                label={t("label.bid.date")}
                                value={helpers.toLocalDateIgnoreTime(
                                    selectedOffer.bid.bidDateTime
                                )}
                            />
                            <VSpace size="10px" />

                            <BidGradeWrapper>
                                <BidGrade
                                    grade="a"
                                    amount={
                                        selectedOffer.bid.offeredPriceGradeA
                                    }
                                    quantity={
                                        selectedOffer.offer.maximumQuantity
                                    }
                                    bidCompanyCountryId={
                                        selectedOffer.bid.bidCompanyCountryId
                                    }
                                />
                                <BidGrade
                                    grade="b"
                                    amount={
                                        selectedOffer.bid.offeredPriceGradeB
                                    }
                                    quantity={
                                        selectedOffer.offer.maximumQuantity
                                    }
                                    bidCompanyCountryId={
                                        selectedOffer.bid.bidCompanyCountryId
                                    }
                                />
                                <BidGrade
                                    grade="c"
                                    amount={
                                        selectedOffer.bid.offeredPriceGradeC
                                    }
                                    quantity={
                                        selectedOffer.offer.maximumQuantity
                                    }
                                    bidCompanyCountryId={
                                        selectedOffer.bid.bidCompanyCountryId
                                    }
                                />
                            </BidGradeWrapper>
                        </AccordionWrapper>
                    )}
                    {info && (
                        <AccordionWrapper
                            title={
                                info.showBuyer
                                    ? t("title.buyer")
                                    : t("title.sellerInfo")
                            }
                            id="buyer"
                            isOpen={openAccordion}
                            onToggle={setOpenAccordion}
                        >
                            <SidebarField
                                label={t("title.company")}
                                value={info.buyerSeller.companyName}
                            />
                            <SidebarField
                                label={t("label.companyVAT")}
                                value={info.buyerSeller.vatNumber}
                            />
                            <SidebarField
                                label={t("label.companyStreet")}
                                value={info.buyerSeller.street}
                            />
                            <SidebarField
                                label={t("label.companyCity")}
                                value={info.buyerSeller.city}
                            />
                            <SidebarField
                                label={t("label.companyState")}
                                value={info.buyerSeller.region}
                            />
                            <SidebarField
                                label={t("label.companyZIP")}
                                value={info.buyerSeller.postalCode}
                            />
                            {info.buyerSeller.countryName && (
                                <SidebarField
                                    label={t("label.companyCountry")}
                                    value={info.buyerSeller.countryName}
                                />
                            )}

                            <SidebarField
                                label={t("label.companyContactPersonName")}
                                value={info.buyerSeller.contactPerson}
                            />
                            <SidebarField
                                label={t("label.companyEmail")}
                                value={info.buyerSeller.email}
                            />
                            <SidebarField
                                label={t("label.companyPhone")}
                                value={info.buyerSeller.phone}
                            />
                        </AccordionWrapper>
                    )}
                </>
            )}
        </>
    );
};

export default TransactionDetailsSidebar;
