import UserService from "../../../services/User/UserService";

const adminRolesStatePlugin = {
    types: ["ROLES_LOADING", "ROLES_SUCCESS", "ROLES_ERROR"],
    initialState: {
        rolesData: {
            roles: [],
            isLoading: false,
            error: null
        }
    },
    creators: types => ({
        rolesLoading: () => ({ type: types.ROLES_LOADING }),
        rolesError: err => ({ type: types.ROLES_ERROR, payload: err }),
        rolesSuccess: res => ({
            type: types.ROLES_SUCCESS,
            payload: res
        })
    }),
    actions: creators => ({
        loadRoles: () => {
            return async dispatch => {
                dispatch(creators.rolesLoading());
                try {
                    const response = await UserService.getAdminRoles();
                    dispatch(creators.rolesSuccess(response));
                } catch (e) {
                    dispatch(creators.rolesError(e));
                }
            };
        }
    }),
    createReducer : (types) => {
        return (state, action) => {
            switch(action.type){
                case types.ROLES_LOADING:
                    state.rolesData.roles = [];
                    state.rolesData.isLoading = true;
                    state.rolesData.error = null;
                    break;
                case types.ROLES_ERROR:
                    state.rolesData.roles = [];
                    state.rolesData.isLoading = false;
                    state.rolesData.error = action.payload;
                    break;
                case types.ROLES_SUCCESS:
                    state.rolesData.roles = action.payload;
                    state.rolesData.isLoading = false;
                    state.rolesData.error = null;
                    break;
                default:
                    return state;
            }
        }
    }
};

export default adminRolesStatePlugin;
