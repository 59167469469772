import React, {useState} from "react";
import TagInput from "./TagInput";
import {commonRegex} from "../../../../../core/validations/common";

const CollectableTags = (props) => {
    const [visibleTag, setVisibleTag] = useState(1);
    const [offerTags, setOfferTags] = useState(props.tags);
    const [customError, setCustomError] = useState('');

    const filterAvailableTags = (tag) => {
        let availableTag;
        if (tag.length > 2) {
            availableTag = offerTags.filter(t => t.includes(tag.toLowerCase()));

            if (availableTag.length === 0) {
                setCustomError('error')
            } else {
                setCustomError('')
            }

            return availableTag;
        }

        return [];
    }

    const filterTagFromList = (e) => {
        let t = offerTags.filter((value) => value !== e.currentTarget.value);
        let isValid = commonRegex.UUID.test(e.currentTarget.value);

        if (isValid) {
            setOfferTags(t);
            if (props.tags.length !== visibleTag) {
                setVisibleTag(visibleTag + 1);
            }

            props.handleTags({tag: e.currentTarget.value, currentTagPosition: visibleTag});
        }
    };

    return (
        <>
            {props.tags && props.tags.map((tag, index, arr) => {
                return (
                    <TagInput key={`${'tag_' + index}`}
                              id={`${'tag_' + index}`}
                              index={index}
                              tags={props.tags}
                              visible={visibleTag === index + 1}
                              filterAvailableTags={filterAvailableTags}
                              filterTagFromList={filterTagFromList}
                              error={customError}
                    />
                )
            })}
        </>
    )
};

export default CollectableTags;
