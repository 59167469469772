const createDisputeSteps = (value, t, row) => {
    const seller = row?.offerer ?? "Seller";
    const buyer = row?.acceptedBidInfo?.bidCompanyName ?? "Buyer";
    let result = Array.from([
        {
            status: 4,
            title: `${t("common:status.step")}: 1/2 - ${t(
                "common:status.inProgress"
            ).toUpperCase()} `,
            tip: t("common:status.progress_settlement", {
                company: buyer,
            }),
        },
        {
            status: 3,
            title: `${t("common:status.step")}: 2/2 - ${t(
                "common:status.pending"
            ).toUpperCase()} `,
            tip: t("common:status.pending_settlement", {
                company: seller,
            }),
        },
    ]);
    switch (value) {
        case 1:
            result[0] = {
                status: 1,
                title: `${t("common:status.step")}: 1/2 - ${t(
                    "common:status.completed"
                ).toUpperCase()} `,
                tip: t("common:status.completed_settlement", {
                    company: buyer,
                }),
            };
            result[1] =  {
                status: 4,
                title: `${t("common:status.step")}: 2/2 - ${t(
                    "common:status.inProgress"
                ).toUpperCase()} `,
                tip: t("common:status.progress_confirmSettlement", {
                    company: seller,
                }),
            };
            break;
        case 2:
            result[0] = {
                status: 1,
                title: `${t("common:status.step")}: 1/2 - ${t(
                    "common:status.completed"
                ).toUpperCase()} `,
                tip: t("common:status.completed_settlement", {
                    company: buyer,
                }),
            };
            result[1] =  {
                status: 1,
                title: `${t("common:status.step")}: 2/2 - ${t(
                    "common:status.completed"
                ).toUpperCase()} `,
                tip: t("common:status.completed_confirmSettlement", {
                    company: seller,
                }),
            };
            break;
        default:
            break;
    }
    return result;
};

export default createDisputeSteps;
