import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled.div`
    display: flex;
    justify-content: end;
    padding-right: 50px !important;
    &.shadowed {
        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 1) 0%,
            rgba(220, 220, 220, 1) 100%
        );
        box-shadow: -2px 9px 11px -16px rgb(0 0 0 / 75%);
        border-bottom: 1px solid #ffffff;
    }
`;

const StyledLink = styled(Link)`
    position: relative;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    border-radius: ${(props) => props.theme.buttonsBorderRadius};
    text-align: center;
    margin: 0 20px;
    text-decoration: none;
    color: #000;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 400;
    margin-right: 30px;

    &:after {
        content: "";
        position: absolute;
        left: -15px;
        bottom: -10px;
        width: calc(100% + 30px);
        height: 4px;
        background-color: transparent;
        border-radius: 8px 8px 0px 0px;
    }

    &:hover:after {
        background-color: ${(p) => p.theme.primaryColorHover};
    }

    &.current {
        font-weight: 700;
        &:after {
            content: "";
            position: absolute;
            left: -15px;
            bottom: -10px;
            width: calc(100% + 30px);
            height: 4px;
            background-color: #48b05e;
            border-radius: 8px 8px 0px 0px;
        }
    }
`;

const TabLinks = ({ idx, items, style, variant }) => {
    return (
        <Wrapper style={style} className={variant}>
            {items.map((itm, i) => (
                <StyledLink
                    key={i}
                    className={i === idx ? "current" : ""}
                    to={itm.path}
                    label={itm.name}
                >
                    {itm.name}
                </StyledLink>
            ))}
        </Wrapper>
    );
};

export default TabLinks;
