import React from "react";
import styled from "styled-components";
import ChartHeader from "./ChartHeader";
import ProgressBar from "./ProgressBar";

const ProgressList = styled.ul`
    list-style: none;
    padding: 0;
    flex-grow:1;
    display:flex;
    margin: 0;
    padding-top: 24px;
`;

const ChartWrapper = styled.div`
   padding: 20px 24px 0px 20px;
   color: #AAAEB3;
   height: 100%;
   display: flex;
   flex-direction: column;

   @media all and (max-width: ${props => props.theme.extraLargeDevices}) {
   padding-left: 0px;
   padding-right: 0px;
}
`;

const ListText = styled.div`
  height:38px;
    display:flex;
    align-items:flex-end;
   & span {
       padding-left: 6px;
        font-size:14px;
   }

`;

const ProgressWrapper = styled.div`
   height:38px;
   display: flex;
   align-items:flex-end;
   width: 100%;
   font-size:12px;
`;

const ProgressLabel = styled.span`
    float:right;
`;

const NameLabel = styled.span`
    font-weight: 500;
`;

const ListTextWrapper = styled.div`
    flex-grow:1;
    min-width: 35%;
    height: 100%;
    display:flex;
    flex-direction:column;
    justify-content: space-between;
`;

const ListProgressWrapper = styled.div`
    width:65%;
    height: 100%;
    display:flex;
    flex-direction:column;
    justify-content: space-between;
`;

const ChartProgress = ({
    data,
    title,
    tooltip,
    suffix,
    style,
    unit,
    isInt,
    hideUnit
}) => {
    return (

        <ChartWrapper style={style}>
            <ChartHeader
                title={title}
                tooltip={tooltip}
            >

            </ChartHeader>
            <ProgressList>
                <ListTextWrapper>
                {data? data.map((item, idx) => (
                    <ListText key={idx}>
                        <svg height="12" width="12"><circle cx="6" cy="6" r="5" fill={item.color} ></circle></svg>
                           <NameLabel>
                               {item.name} {suffix}
                           </NameLabel>
                    </ListText>

                )) :null}
                </ListTextWrapper>

                <ListProgressWrapper>
                {data? data.map((item, idx) => (
                    <ProgressWrapper key={idx}>
                            <div style={{width:"100%"}}>
                            <ProgressLabel>
                                 {isInt && isInt== true ? item.value: parseFloat(item.value).toFixed(2)} {hideUnit? '': (unit? unit: 'kg')} {item.suffix}
                             </ProgressLabel>
                             <ProgressBar key={idx} bgcolor={item.color} completed={item.percent} height={8} />
                             </div>
                        </ProgressWrapper>

                )) :null}
                </ListProgressWrapper>
            </ProgressList>
        </ChartWrapper>
    );
}

export default ChartProgress;