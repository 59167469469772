import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import { Link, Redirect, useHistory } from "react-router-dom";
import styled from "styled-components";
import ModuleCards from "../../components/modules/ModuleCards";
import Wrappers from "../../components/wrappers/Wrappers";
import Roles from "../../core/enums/Roles";
import RouteCreator from "../../core/RouteCreator";
import companyStateModule from "../../core/state/company/companyStateModule";
import useStateModule from "../../core/state/useStateModule";
import globals from "../../globalVars";
import UserService from "../../services/User/UserService";
import MobileDashboard from "./MobileDashboard";
import useQueryString from "../../hooks/_shared/useQueryString";
import ModalDialog, { useModal } from "../../components/modal/ModalDialog";
import { KYCInfo, KYCWarn } from "../Login/LoginScreen";
import useLeftMenu from "../../core/state/navigation/useLeftMenu";
import useSharedState from "../../hooks/_shared/useSharedState";

const CompanyLink = styled(Link)`
    color: ${(props) => props.theme.primaryColor};
`;

const DashboardScreen = () => {
    const { t } = useTranslation("moduleCards");
    const { loadCompany } = useStateModule(companyStateModule);
    const history = useHistory();
    const [globalState] = useSharedState("global");

    const userDetails = UserService.getUserDetailsFromToken();
    const userCompanyId = userDetails ? userDetails.CompanyId : null;
    const firstLogin = useQueryString("login");

    const [KYCWarning, setKYCWarning] = useState({
        show: firstLogin === "1" || false,
        redirect: false,
    });
    const { setIsVisible } = useLeftMenu();

    useEffect(() => {
        loadCompany(userCompanyId);
    }, [loadCompany, userCompanyId]);

    useEffect(() => {
        setIsVisible(false);
    }, []);

    const { modalProps, toggleOpen } = useModal({
        title: t("actions:dialogs.kycWarning.title"),
        onConfirm: async () => {
            setKYCWarning((prevState) => ({
                ...prevState,
                show: false,
                redirect: true,
            }));
            toggleOpen();
        },
        onCancel: () => {
            setKYCWarning((prevState) => ({ ...prevState, show: false }));
            history.replace("/");
            toggleOpen();
        },
        confirmText: t("actions:dialogs.kycWarning.submit"),
    });

    if (globals.isMobile) return <MobileDashboard />;

    const role =
        userDetails[
            "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
        ];

    const modules = [
        {
            name: t("stock.salesCenter"),
            image: "/images/dashboard/sales_center.svg",
            link: RouteCreator.salesCenter.root(),
            title: t("salesTitle"),
            desc: t("salesDesc"),
            shouldShowModule:
                userCompanyId !== null &&
                (role === Roles.InstallerCompanyAdmin ||
                    role === Roles.InstallerCompanyUser),
        },
        {
            name: t("navigation:services"),
            link: RouteCreator.services.root(),
            title: t("disposalTitle"),
            desc: t("disposalDesc"),
            image: "/images/dashboard/services.svg",
            shouldShowModule:
                globalState.showServicesSection &&
                userCompanyId !== null &&
                (role === Roles.InstallerCompanyAdmin ||
                    role === Roles.InstallerCompanyUser),
        },
        {
            name: t("navigation:disputes"),
            link: RouteCreator.disputes.root(),
            title: t("disputesTitle"),
            desc: t("disputesDesc"),
            image: "/images/dashboard/disputes.svg",
            shouldShowModule:
                userCompanyId !== null &&
                (role === Roles.InstallerCompanyAdmin ||
                    role === Roles.InstallerCompanyUser),
        },
        {
            name: t("stock.salesCenter"),
            image: "/images/dashboard/sales_center.svg",
            link: RouteCreator.admin.marketplaceStatistics(),
            title: t("salesTitleAdmin"),
            desc: t("salesDescAdmin"),
            shouldShowModule:
                role === Roles.Admin || role === Roles.MasterAdmin,
        },
        {
            name: t("stock.listing"),
            image: "/images/dashboard/stock.svg",
            link:
                role === Roles.InstallerCompanyTechnician
                    ? RouteCreator.stock.installer()
                    : RouteCreator.stock.root(),
            title: t("stockTitle"),
            desc: t("stockDesc"),
            shouldShowModule:
                userCompanyId !== null &&
                (role === Roles.InstallerCompanyAdmin ||
                    role === Roles.InstallerCompanyUser ||
                    role === Roles.InstallerCompanyTechnician),
            disabled:
                !globalState.hasValidCompanyCertificate ||
                (role === Roles.InstallerCompanyTechnician &&
                    !globalState.hasValidUserCertificate),
        },

        {
            name: t("equipment.list"),
            image: "/images/dashboard/equipment.svg",
            link: RouteCreator.equipment.root(),
            title: t("equipTitle"),
            desc: t("equipDesc"),
            shouldShowModule:
                userCompanyId !== null &&
                (role === Roles.InstallerCompanyAdmin ||
                    role === Roles.InstallerCompanyTechnician),
            disabled:
                !globalState.hasValidCompanyCertificate ||
                (role === Roles.InstallerCompanyTechnician &&
                    !globalState.hasValidUserCertificate),
        },
        {
            name: t("clients.listing"),
            link: RouteCreator.clients.root(),
            title: t("clientsTitle"),
            desc: t("clientsDesc"),
            image: "/images/dashboard/clients.svg",
            shouldShowModule:
                userCompanyId !== null &&
                (role === Roles.InstallerCompanyAdmin ||
                    role === Roles.InstallerCompanyUser),
        },
        {
            name: t("navigation:finance"),
            link: RouteCreator.finance.root(),
            title: t("financeTitle"),
            desc: t("financeDesc"),
            image: "/images/dashboard/finance.svg",
            shouldShowModule:
                userCompanyId !== null &&
                (role === Roles.InstallerCompanyAdmin ||
                    role === Roles.InstallerCompanyUser),
        },

        {
            name: t("admin.users"),
            image: "/images/dashboard/users.svg",
            link: RouteCreator.admin.users.root(),
            title: t("usersTitle"),
            desc: t("usersDesc"),
            shouldShowModule:
                role === Roles.Admin || role === Roles.MasterAdmin,
        },
        {
            name: t("admin.companies"),
            image: "/images/dashboard/companies.svg",
            link: RouteCreator.admin.companies.root(),
            title: t("companiesTitle"),
            desc: t("companiesDesc"),
            shouldShowModule:
                role === Roles.Admin || role === Roles.MasterAdmin,
        },
        {
            name: t("admin.qr"),
            image: "/images/dashboard/qr.svg",
            link: RouteCreator.admin.qrTags(),
            title: t("qrTitle"),
            desc: t("qrDesc"),
            shouldShowModule:
                role === Roles.Admin || role === Roles.MasterAdmin,
        },
    ];

    if (
        KYCWarning.show &&
        (UserService.isInRole(Roles.InstallerCompanyAdmin) ||
            UserService.isInRole(Roles.InstallerCompanyUser))
    ) {
        if (!modalProps.isOpen) toggleOpen();
    }

    if (KYCWarning.redirect) {
        return (
            <Redirect
                to={KYCWarning.redirect ? RouteCreator.company.payments() : "/"}
            />
        );
    }

    return (
        <Wrappers.DashboardWrapper data-ctrl="DashboardWrapper">
            <Helmet>
                <title>Dashboard</title>
            </Helmet>
            {modules.length === 0 ? (
                <span>
                    You have to have at least one valid licence to use the
                    application. Please go to{" "}
                    <CompanyLink to="company/certificates">
                        Company Certificates
                    </CompanyLink>
                    .
                </span>
            ) : (
                <ModuleCards modules={modules} userDetails={userDetails} />
            )}

            <ModalDialog {...modalProps}>
                <KYCWarn>{t("actions:dialogs.kycWarning.text")}</KYCWarn>
                <KYCInfo>{t("actions:dialogs.kycWarning.info")}</KYCInfo>
            </ModalDialog>
        </Wrappers.DashboardWrapper>
    );
};

export default DashboardScreen;
