import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../components/elements/buttons/Button";
import SidebarField from "../../../../components/elements/sidebar/SidebarField";
import AccordionWrapper from "../../../../components/toggle-view/AccordionWrapper";
import useSharedState from "../../../../hooks/_shared/useSharedState";
import useAccordionGroup from "../../../../hooks/_shared/useAccordionGroup";

const ReclaimedMarketSidebar = ({
    offer,
    details,
    isDistributor,
    purchaseAction,
    showCancel = false,
    cancelAction,
}) => {
    const { t } = useTranslation(["sidebar"]);
    const shouldShow = details;
    const company = isDistributor ? details?.buyer : details;
    const [globalState] = useSharedState("global");
    const [openAccordion, setOpenAccordion] = useAccordionGroup();
    return (
        <>
            {shouldShow && (
                <>
                    <AccordionWrapper
                        title={t("title.offer.details")}
                        id="details"
                        isOpen={openAccordion}
                        onToggle={setOpenAccordion}
                    >
                        {details && (
                            <>
                                <SidebarField
                                    label={t("label.gasType")}
                                    value={details.refrigerantGasTypeCode}
                                />

                                <SidebarField
                                    label={t("label.cylinderNetWeight")}
                                    value={`${details.cylinderNetWeight.toFixed(
                                        2
                                    )} kg`}
                                />

                                <SidebarField
                                    label={t("label.totalExtractedQuantity")}
                                    value={`${details.quantity.toFixed(2)} kg`}
                                />

                                <SidebarField
                                    label={t("label.extraction.qualityGrade")}
                                    value={details.pressumedGradeName}
                                />
                            </>
                        )}
                    </AccordionWrapper>
                    <AccordionWrapper
                        title={t("title.contamination")}
                        id="contamination"
                        isOpen={openAccordion}
                        onToggle={setOpenAccordion}
                    >
                        -
                    </AccordionWrapper>
                    <AccordionWrapper
                        title={t(
                            isDistributor
                                ? "title.buyerInfo"
                                : "title.sellerInfo"
                        )}
                        id="sellerInfo"
                        isOpen={openAccordion}
                        onToggle={setOpenAccordion}
                    >
                        {company ? (
                            <>
                                <SidebarField
                                    label={t("title.company")}
                                    value={company.companyName}
                                />
                                <SidebarField
                                    label={t("label.companyVAT")}
                                    value={company.vatNumber}
                                />
                                <SidebarField
                                    label={t("label.companyStreet")}
                                    value={company.street}
                                />
                                <SidebarField
                                    label={t("label.companyCity")}
                                    value={company.city}
                                />
                                <SidebarField
                                    label={t("label.companyState")}
                                    value={company.region}
                                />
                                <SidebarField
                                    label={t("label.companyZIP")}
                                    value={company.postalCode}
                                />
                                <SidebarField
                                    label={t("label.companyCountry")}
                                    value={company.countryName}
                                />
                                <SidebarField
                                    label={t("label.companyContactPersonName")}
                                    value={company.contactPerson}
                                />
                                <SidebarField
                                    label={t("label.companyEmail")}
                                    value={company.email}
                                />
                                <SidebarField
                                    label={t("label.companyPhone")}
                                    value={company.phone}
                                />
                            </>
                        ) : (
                            <span>-</span>
                        )}
                    </AccordionWrapper>
                    {!isDistributor && offer && offer.status === 0 && (
                        <div style={{ textAlign: "center" }}>
                            <Button
                                disabled={
                                    !globalState.hasValidCompanyCertificate ||
                                    !globalState.hasCertificateRights
                                }
                                onClick={() =>
                                    purchaseAction && purchaseAction()
                                }
                                className="primary"
                            >
                                {t("actions:purchase")}
                            </Button>
                        </div>
                    )}
                    {isDistributor &&
                        offer &&
                        offer.status === 2 &&
                        showCancel && (
                            <div style={{ textAlign: "center" }}>
                                <Button
                                    onClick={() =>
                                        cancelAction &&
                                        cancelAction({ data: offer })
                                    }
                                    className="primary"
                                >
                                    {t("actions:cancel")}
                                </Button>
                            </div>
                        )}
                </>
            )}
        </>
    );
};

export default ReclaimedMarketSidebar;
