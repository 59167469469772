import React from "react";
import QRCode from "qrcode.react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import helpers from "../../core/helpers";
import AccordionWrapper from "../../components/toggle-view/AccordionWrapper";
import SidebarField from "../../components/elements/sidebar/SidebarField";
import { ReactComponent as InfoSvg } from "../../assets/orange-info.svg";

const StyledInfo = styled(InfoSvg)`
    width: 30px;
    height: 30px;
    margin-right: 16px;
`;

const QRWrapper = styled.div`
    text-align: center;
    margin-top: 5px;
    span {
        display: inline-block;
        margin-top: 5px;
    }
`;

const Link = styled.span`
    font-weight: 500;
    color: #2294d2;
    font-size: 14px;
    cursor: pointer;
`;

const TagTitle = styled.div`
    width: 100%;
    font-size: 24px;
    font-weight: 700;
    margin: 12px 0 0;
`;

const IssueWrapper = styled.div`
    display: flex;
    font-size: 12px;
    padding-left: 20px;
    margin-top: 25px;
`;

const AnalyzerBlock = ({ tagId, name, macAddress, openAccordion, setOpenAccordion }) => {
    const { t } = useTranslation("sidebar");
    const tagParts = tagId ? helpers.splitQRTag(tagId) : [];
    return (
        <AccordionWrapper
            title={t("screens:statistics.fGasAnalyzerTitle")}
            id="cylinderblock.extractionCylinder"
            isOpen={openAccordion}
            onToggle={setOpenAccordion}
        >
            {tagId && (
                <QRWrapper>
                    <QRCode value={tagId} size={140} />
                    <TagTitle>{tagParts[0]}</TagTitle>
                    <span>{tagParts[1]}</span>
                </QRWrapper>
            )}
            <br />

            <SidebarField label={t("label.equipmentName")} value={name} />
            <SidebarField label={t("forms:analyzer.wizard.macAddress")} value={macAddress} />
        </AccordionWrapper>
    );
};

export default AnalyzerBlock;
