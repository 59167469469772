import React, { useEffect, useState } from "react";
import styled from "styled-components";

const StyledLogo = styled.img`
    &.menu {
        width: 113px;
    }

    &.not-auth {
        margin-top: 60px;
        width: 240px;
        height: auto;
    }

    &.left-menu {
        width: auto;
        height: 28px;
    }
    &.left-menu.pwa {
        width: auto;
        height: 20px;
    }

    &.register-screen {
        margin-bottom: 25px;
        width: 240px;
        height: auto;
    }

    &.reset-password {
        margin-top: 60px;
        width: 240px;
        height: auto;
    }

    &.fullscreen-notification {
        position: absolute;
        left: 50%;
        top: 88%;
        width: 200px;
        height: auto;
        margin-left: -100px;
    }
`;

const Logo = ({
    variant = "big",
    width = "280",
    height = "60",
    style,
    ...rest
}) => {
    const [image, setImage] = useState({
        className: rest.className,
        type: variant,
    });

    useEffect(() => {
        if (variant.indexOf("small") > -1) {
            setImage((prevstate) => ({
                ...prevstate,
                type: "small",
                className: rest.className,
            }));
        } else {
            setImage((prevState) => ({
                ...prevState,
                type: "big",
            }));
        }
    }, [variant]);

    return image.type === "big" ? (
        <StyledLogo
            className={rest.className}
            width={width}
            height={height}
            style={style}
            src="/images/logo/logo-big-rtr.svg"
        />
    ) : (
        <StyledLogo
            width={width}
            height={height}
            style={style}
            src="/images/logo/logo-small-rtr.svg"
        />
    );
};

export default Logo;
