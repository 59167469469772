import CompanyService from "../../../services/Company/CompanyService";
import createListStateModule from "../createListStateModule";
import companyRolesStatePlugin from "../plugins/companyRolesStatePlugin";

const employeeListStateModule = createListStateModule({
    stateNamespace: "employees",
    itemsPropertyName: "employees",
    loadItemsMethodName: "loadEmployees",
    itemsDataMethod: CompanyService.getCompanyEmployees,
    loadDetailMethodName: "loadEmployeeById",
    detailDataMethod: async (id) => {
        const employee = await CompanyService.getCompanyEmployeeById(id);
        return employee.data[0];
    },
    detailsPropertyName: "employeeData",
    deleteMethodName: 'deleteEmployee',
    deleteDataMethod: CompanyService.deleteEmployee,
    updateMethodName: 'updateEmployee',
    updateDataMethod: CompanyService.updateCompanyEmployee,
    createMethodName: 'createEmployee',
    createDataMethod: CompanyService.createCompanyEmployee,
    defaultItemsData: {data: []},
    plugins: [companyRolesStatePlugin]
});

export default employeeListStateModule;
