import React from "react";
import styled from "styled-components";

const DataImageWrapper = styled.div`
    background: ${(props) => props.theme.secondaryColor} url("/images/shared/user.svg")
        no-repeat center;
    border-radius: 50%;
    width: 76px;
    height: 76px;
    overflow: hidden;
    margin: 0 auto 10px;

    & span {
        position: absolute;
        top: 52px;
        left: 0;
        width: 100%;
        color: #fff;
        font-size: 34px;
    }

    &.cell-image {
        background-color:transparent;
    }
    &.cell-image.empty {
        background-color:${(props) => props.theme.secondaryColor};
    }
`;

const Image = styled.div`
    background: transparent no-repeat center;
    background-size: cover;
    width: 100%;
    height: 100%;

`;

const getInitials = function (string) {
    if (string !== "") {
        let names = string.split(" "),
            initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    }
};

const DataCellImage = ({ image, fullName, width = "100%" }) => {
    return (
        <DataImageWrapper className={image ? "cell-image": "cell-image empty"}>
            {image ? (
                <Image
                    style={{
                        width: width,
                        backgroundImage: `url(` + image + `)`,
                    }}
                />
            ) : (
                <span>{fullName && getInitials(fullName)}</span>
            )}
        </DataImageWrapper>
    );
};

export default DataCellImage;
