import React from "react";
import {
    InfoTitle, InfoWrapper
} from "../../../../../components/modal/components/WizardStyling";
import * as yup from "yup";
import ValidationRule, {buildValidation} from "../../../../../core/validations/ValidationRule";
import InputElement from "../../../../../components/elements/form-elements/InputElement";
import CollectStep from "./CollectStep";
import CollectableCompaniesList from "./CollectableCompaniesList";
import CollectWizardClientData from "./CollectWizardClientData";
import styled from "styled-components";
import {ReactComponent as CollectGasSvg} from "../../../../../assets/pwa/collect-gas.svg";
import FlexContainer from "../../../../../components/layout/flex/FlexContainer";
import SimpleTable from "../../../../../components/elements/general/SimpleTable";
import VSpace from "../../../../../components/elements/general/vspace";

const SvgToStock = styled(CollectGasSvg)`
    width: auto;
    margin: 0 auto;
`;

const TableWrapper = styled.div`
    margin: 0 2% 0 2%;
    max-height: 300px;
    height: auto;
    overflow: overlay;
    position: relative;
    width: 100%;
`;

const initialValues = {
    client: '',
    tagIds: [],
    currentTagPosition: 0,
    collectedCylinders: [],
    totalTags: 0
}

const validCompany = (t) => {
    return buildValidation({
        client: ValidationRule.isStringRule()
            .required(t("forms:unit.unitTagId.required")).rule,
    });
};

const validTagLength = (t) => (
    yup.lazy((values) => {
        let tagDiff = values.totalTags - values.currentTagPosition;
        return buildValidation({
            currentTagPosition: yup.number().required().moreThan(0).max(values.totalTags, ""),
            totalTags: ValidationRule.isNumber().shouldBeEqual(
                "currentTagPosition",
                values.currentTagPosition < values.totalTags ? t('forms:stock.wizard.collectCylinder.tags.invalid', {tags: tagDiff}) : '',
                "required"
            ).rule
        })
    })
)
const CollectCylinderWizard = (t, companies) => {
        let tags = [];
        const collectTags = (tagObj, formik) => {
            tags = [...tags, tagObj.tag];
            formik.setFieldValue('tagIds', tags);
            formik.setFieldValue('currentTagPosition', tagObj.currentTagPosition);
            formik.setFieldValue('collectedCylinders', tagObj.currentTagPosition - 1);
        }

        const handleCompanySelect = (clientId, formik) => {
            formik.setFieldValue("client", clientId);
        }

        const splitAt = (index) => (x) => [x.slice(0, index), x.slice(index)];
        const tableColumns = [
            {
                header: t("screens:technician.type"),
                accessor: "gasTypeCode",
                cell: (value) => value || "-"
            },
            {
                header: t("screens:technician.Quantity"),
                accessor: "quantity",
                cell: (value) => value.toFixed(2) + " kg",
            },
            {
                header: t("screens:technician.qrTag"),
                accessor: "tagId",
                cell: (value) => splitAt(4)(value.substr(0, 8)).join(" "),
            }
        ];

        return {
            title: t('forms:stock.wizard.collectCylinder.title'),
            initialValues: initialValues,
            steps: [
                {
                    name: t('forms:stock.wizard.collectCylinder.step1'),
                    fieldNames: [
                        "client"
                    ],
                    validation: validCompany(t),
                    fields: (formik) => (
                        <>
                            <InfoWrapper>
                                <div style={{margin: '0 auto'}}>
                                    <FlexContainer justifyContent="center">
                                        <SvgToStock/>

                                    </FlexContainer>
                                    <InfoTitle className="pwa tight">
                                        {t(
                                            "forms:stock.wizard.collectCylinder.step1Info"
                                        )}
                                    </InfoTitle>
                                </div>
                            </InfoWrapper>

                            <CollectableCompaniesList companies={companies.data}
                                                      handleCompanySelect={(cId) => handleCompanySelect(cId, formik)}
                                                      formik={formik}/>
                        </>
                    )
                },
                {
                    name: t('forms:stock.wizard.collectCylinder.step2'),
                    fieldNames: [],
                    onStepRender: async props => {
                        const clientId = props.value["client"];
                        const clientData = companies.data && companies.data.find(c => c.companyDetails.id === clientId);

                        const cylinders = [];
                       
                        if (clientData) {
                            props.updatePropertyBag({
                                    clientData: {
                                        data: clientData.companyDetails
                                    },
                                    cylinders: cylinders
                                }
                            )
                        }
                    },
                    fields: (formik, propertyBag) => (
                        <>
                            <InfoWrapper>
                                <div style={{margin: '0 auto'}}>
                                    <FlexContainer justifyContent="center">
                                        <SvgToStock/>

                                    </FlexContainer>
                                    <InfoTitle className="pwa tight">
                                        {t(
                                            "forms:stock.wizard.collectCylinder.step1Info"
                                        )}
                                    </InfoTitle>
                                </div>
                            </InfoWrapper>
                            {
                                propertyBag && propertyBag.clientData &&
                                <CollectWizardClientData clientData={propertyBag.clientData}/>
                            }
                        </>
                    )
                },
                {
                    name: t('forms:stock.wizard.collectCylinder.step3'),
                    fieldNames: ['tagIds', 'currentTagPosition', 'totalTags'],
                    validation: validTagLength(t),
                    onStepRender: async (props) => {

                        const filterCompany = companies.data.find(c => c.companyDetails.id === props.value["client"]);
                        let clientTags = [];
                        let clientCylinders = [];
                        if (filterCompany && filterCompany.offers && filterCompany.offers.length > 0) {
                            clientCylinders = filterCompany.offers.map(offer => {
                                return offer.cylinders.map(cylinder => cylinder)
                            });
                            clientCylinders = clientCylinders.flat();

                            clientTags = filterCompany.offers.map(offer => {
                                return offer.cylinders.map(cylinder => cylinder.tagId)
                            });
                            clientTags = clientTags.flat();
                        }

                        if (filterCompany) {
                            props.updatePropertyBag({
                                client: props.value.client,
                                clientTags: clientTags,
                                totalTags: clientTags.length,
                                cylinders: clientCylinders
                            });
                            props.formik.setFieldValue("totalTags", clientTags.length);
                        }
                    },
                    fields: (formik, propertyBag) => {
                        return <>
                            {
                                propertyBag.cylinders &&
                                <>
                                    <TableWrapper className="flex-1">
                                        <SimpleTable
                                            columns={tableColumns}
                                            data={propertyBag.cylinders}
                                            selected={[
                                                formik.values["collectedCylinders"]
                                            ]}
                                            wrapperVariant="desktop"
                                            shouldScrollInfoView={true}
                                        />
                                    </TableWrapper>
                                    <VSpace size={20}/>
                                </>
                            }

                            <InputElement labelText="currentTagPosition"
                                          name="currentTagPosition"
                                          type="number"
                                          wrapperVariant="hide"
                                          {...formik} />

                            <InputElement labelText="total"
                                          name="totalTags"
                                          type="number"
                                          wrapperVariant="hide"
                                          {...formik} />

                            <CollectStep clientTags={propertyBag.clientTags}
                                         handleTags={(tag) => collectTags(tag, formik)}/>
                        </>
                    }
                }
            ]
        }
    }
;

export default CollectCylinderWizard;
