import React from "react";
import {useTranslation} from "react-i18next";
import Flex from "../../../../components/layout/flex/Flex";
import styled from "styled-components";
import {VSpace} from "../../../pwa/components/StyledElements";
import Globals from "../../../../globalVars";

const RowWrapper = styled.div`
    padding: 0 50px;

    &.separator {
        border-bottom: 1px solid ${props => props.theme.borderSeparator};
    }

    &.highlight {
        border-bottom: 1px solid #000;
    }
`;

const MainTitle = styled.span`
    padding: 0 0 10px;
    font-size: 1.5rem;
    color: ${props => props.theme.primaryColor};
    text-transform: uppercase;
    display: inline-block;
    width: 100%;
    font-weight:700;
`;

const Title = styled.span`
    font-size: 1.25rem;
    font-weight: bold;
    display: inline-block;
    width: 100%;
    margin: 0 0 16px;
    font-weight:700;
`;

const Content = styled.div`
    margin: 20px 0;
`;

const ContentRow = styled.div`
    margin: 0 0 8px;
    font-weight: 700;

    span {
        color: ${props => props.theme.textNeutral};
        display: inline-block;
        vertical-align: top;
        font-weight: 400;
    }

    div {
        display: inline-block;
        vertical-align: top;
    }
`;

const FooterContent = styled.div`
    font-size: 1.25rem;
    padding: 24px 0 0;
`;

const FooterContentRow = styled.div`
    margin: 0 0 12px;

    &.flex {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    span {
        color: ${props => props.theme.textNeutral};
        font-weight: 400;
    }

    & .bold {
        font-weight:700;
        color:#000;
    }

    & .big {
        font-size: 2.25rem;
    }
    .highlight {
        color: ${props => props.theme.primaryColor};
        font-size: 2.25rem;
        font-weight: bold;
    }
`;

const Pipe = styled.div`
    position: relative;
    display: inline-block;
    margin: 0 12px;

    &:after {
        content: "";
        border-left: 1px solid ${props => props.theme.textNeutral};
        border-right: 1px solid ${props => props.theme.textNeutral};
    }
`;

const TransactionDetails = ({transactionDetails, offer, paymentMethod, paymentType = 0}) => {
    const {t} = useTranslation();

    if (!transactionDetails) {
        return null;
    }
    let vatPercentage = transactionDetails.receivingCompanyCountryId === Globals.userCountryId ? Globals.VATPercentage : 1;
    let vatPercString = transactionDetails.receivingCompanyCountryId === Globals.userCountryId ? Globals.VATPercString : "0";

    return (
        <div style={{textAlign: 'left'}}>

            <RowWrapper className="highlight">
                <Flex.Row>
                    <MainTitle>
                        {t("actions:dialogs.transactionDetails.title")}
                    </MainTitle>
                </Flex.Row>
                <div style={{marginBottom:"20px"}}>{t("actions:dialogs.transactionDetails.paymentType.title")}: <b>{t("actions:dialogs.transactionDetails.paymentType.IBANPayIn")}</b></div>
            </RowWrapper>


            {
                (paymentType !== 2 && paymentType !== 3) &&
                <RowWrapper className="separator">
                    <Flex.Row>
                        <Flex.Container>
                            <Flex.Column className="half">
                                <Content>
                                    <Title>{transactionDetails.payingCompany}&nbsp;{t('actions:dialogs.transactionDetails.beneficiaryInformationTitle')}</Title>
                                    <ContentRow>
                                        <span>{t('actions:dialogs.transactionDetails.bankAccountOwnerName')} :</span> &nbsp;
                                        {transactionDetails.bankAccountOwnerName}
                                    </ContentRow>

                                    <ContentRow>
                                        <span>{t('actions:dialogs.transactionDetails.bankAccountOwnerAddressLine1')}: </span> &nbsp;

                                        <div>
                                            {transactionDetails.bankAccountOwnerAddressLine1}
                                            {
                                                transactionDetails.bankAccountOwnerAddressLine2 && (
                                                    <> <br/>{transactionDetails.bankAccountOwnerAddressLine2}</>
                                                )
                                            }
                                        </div>
                                    </ContentRow>

                                    <ContentRow>
                                        <span>{t('actions:dialogs.transactionDetails.bankAccountOwnerCity')}: </span> &nbsp;
                                        {transactionDetails.bankAccountOwnerCity}
                                    </ContentRow>

                                    <ContentRow>
                                        <span>{t('actions:dialogs.transactionDetails.bankAccountOwnerPostalCode')}: </span> &nbsp;
                                        {transactionDetails.bankAccountOwnerPostalCode}
                                    </ContentRow>

                                    {/* <ContentRow>
                                        <span>{t('forms:account.region')}: </span> &nbsp;
                                        {transactionDetails.bankAccountOwnerRegion || "-"}
                                    </ContentRow> */}

                                    <ContentRow>
                                        <span>{t('forms:account.country')}: </span> &nbsp;
                                        {transactionDetails.bankAccountOwnerCountryCode || "-"}
                                    </ContentRow>

                                </Content>
                            </Flex.Column>

                            <Flex.Column className="half">
                                <Content>
                                    <Title>Bank Details</Title>
                                    <ContentRow>
                                        <span>{t('actions:dialogs.transactionDetails.bankAccountOwner')}: </span> &nbsp;
                                        {transactionDetails.bankAccountOwnerName}<br/>
                                    </ContentRow>

                                    <ContentRow>
                                        <span>{t('actions:dialogs.transactionDetails.ibanCode')}: </span> &nbsp;
                                        {transactionDetails.bankAccountOwnerIBAN}<br/>
                                    </ContentRow>

                                    <ContentRow>
                                        <span>{t('actions:dialogs.transactionDetails.bankAccountOwnerBIC')}: </span> &nbsp;
                                        {transactionDetails.bankAccountOwnerBIC}<br/>
                                    </ContentRow>

                                    <ContentRow>
                                        <span>{t('actions:dialogs.transactionDetails.referenceNumber')}: </span> &nbsp;
                                        {transactionDetails.wireReference}<br/><br/>
                                    </ContentRow>
                                </Content>
                            </Flex.Column>
                        </Flex.Container>
                    </Flex.Row>
                </RowWrapper>
            }

            <RowWrapper>
                <Flex.Row>
                    <FooterContent>
                        {
                            offer && (paymentType === 0 || paymentType === 1) &&
                            <>
                                <FooterContentRow className="flex">
                                    <span >
                                    {offer.refrigerantGasTypeCode}
                                    <Pipe/>

                                    {t('actions:dialogs.transactionDetails.grade', {grade: offer.pressumedGradeName})}
                                    <Pipe/>

                                    {
                                        offer.maximumQuantity &&
                                        offer.maximumQuantity.toFixed(2) + " kg"
                                    }
                                    {
                                        offer.quantitySum &&
                                        offer.quantitySum.toFixed(2) + " kg"
                                    }
                                    </span>
                                    <span className="bold" >{(transactionDetails.amount).toFixed(2)} EUR</span>
                                </FooterContentRow>
                            </>
                        }
                        <FooterContentRow className="flex">
                            <span> {t('filters:company.vat')} ({vatPercString}%):</span> &nbsp;
                            
                            <span className="bold" >{((vatPercentage-1)*transactionDetails.amount).toFixed(2)} EUR</span>
                        </FooterContentRow>

                        <VSpace size="10px"/>

                        <FooterContentRow className="flex">
                            <span className="big"> {t('screens:stockListing.headers.total')}: </span> &nbsp;
                            <span className="highlight"> {(transactionDetails.amount * vatPercentage).toFixed(2)} EUR </span>
                        </FooterContentRow>
                    </FooterContent>
                </Flex.Row>
            </RowWrapper>
        </div>
    )
};

export default TransactionDetails;
