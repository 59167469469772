import React, { useRef, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import ForgottenPasswordService from "../../services/ForgottenPassword/ForgottenPasswordService";
import ValidationRule, {
    buildValidation,
} from "../../core/validations/ValidationRule";
import Flex from "../../components/layout/flex/Flex";
import DataEntry from "../../components/data-entry/DataEntry";
import InputElement from "../../components/elements/form-elements/InputElement";
import Headings from "../../components/elements/headings/Headings";
import { InputSubmit } from "../../components/elements/form-elements";
import Wrappers from "../../components/wrappers/Wrappers";
import LayoutOneTwo from "../../components/layout/split/one-two/LayoutOneTwo";
import NavigationLink from "../../components/elements/links/NavigationLink";
import RouteCreator from "../../core/RouteCreator";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

const Section = styled.div`
    font-size: 20px;
    padding: 0px;
    margin-bottom: 16px;
    &.bold {
        font-weight: 600;
        margin-bottom: 24px;
    }
    & span {
        color: ${(p) => p.theme.primaryColor};
    }

    & div.email {
        margin-top: 16px;
        color: ${(p) => p.theme.primaryColor};
    }

    @media ${(p) => p.theme.device.mobile} {
        padding: 0;
    }
`;

const Circle = styled.div`
    width: 44px;
    height: 44px;
    line-height: 40px;
    font-weight: 700;
    font-size: 26px;
    text-align: center;
    background: #fff;
    color: ${(p) => p.theme.primaryColor};
    border: 3px solid ${(p) => p.theme.primaryColor};
    border-radius: 50%;
    float: left;
    margin-right: 25px;

    & img {
        height: 20px;
        margin-top: 10px;
    }
`;

const OrangeInfo = styled.img`
    width: 44px;
    height: 44px;
    margin-right: 24px;
`;

const BigTitle = styled.div`
    font-size: 20px;
    font-weight: 700;

    &.regular {
        font-weight: 400;
    }

    &.vspace {
        margin-top: 20px;
        margin-bottom: 20px;
    }
`;

const initialValues = {
    username: "",
};

const FormValidation = (t) => {
    return buildValidation({
        username: ValidationRule.isStringRule().required(
            t("forms:username.required")
        ).rule,
    });
};

const ForgottenPasswordForm = ({ formik }) => {
    const { t } = new useTranslation();
    return (
        <>
            <Wrappers.Wrapper
                style={{
                    flexGrow: "1",
                    display: "flex",
                    flexDirection: "column",
                    
                }}
            >
                <Headings.H2
                    className="center title bold"
                    style={{ marginBottom: "40px" }}
                >
                    {t("screens:forgottenPassword.title")}
                </Headings.H2>

                <Flex.Row
                    style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "24px",
                    }}
                >
                    <OrangeInfo src="/images/menu/orange-info.svg" />
                    <BigTitle>{t("screens:forgottenPassword.info")}</BigTitle>
                </Flex.Row>

                <BigTitle className="regular vspace">
                    {t("screens:forgottenPassword.itHappens")}
                </BigTitle>

                <BigTitle className="regular vspace">
                    {t("screens:forgottenPassword.submitAccount")}
                </BigTitle>

                <Flex.Row>
                    <InputElement
                        labelText={t("screens:userList.headers.email")}
                        id="username"
                        name="username"
                        type="text"
                        {...formik}
                    />
                </Flex.Row>
            </Wrappers.Wrapper>

            <Flex.Container
                className="horizontal-center"
                style={{ marginTop: "20px" }}
            >
                <InputSubmit
                    value={t("screens:forgottenPassword.submit")}
                    variant="primary big"
                />
            </Flex.Container>
        </>
    );
};

const ForgottenPasswordScreen = () => {
    const { t } = useTranslation();
    const enteredUsername = useRef("e@mail.com");
    const timeoutFn = useRef(null);
    const [requestSent, setRequestSent] = useState(false);
    const yupValidation = FormValidation(t);

    const requestPassReset = async (values) => {
        enteredUsername.current = values.username;
        const result = await ForgottenPasswordService.sendRequestAsync(
            values.username
        );
        if (result.error) toast.error(t(result.error.message));
        else {
            setRequestSent(true);
        }
    };

    const requestResend = async (ev) => {
        ev.preventDefault();
        if (!enteredUsername.current) return;
        const result = await ForgottenPasswordService.sendRequestAsync(
            enteredUsername.current
        );
        if (result.error) toast.error(t(result.error.message));
        else {
            toast.success(t("screens:forgottenPassword.thankYou"));
        }
    };

    return (
        <Flex.Container className="full-height">
            <Helmet>
                <title>{t("seo:forgottenPassword.title")}</title>
            </Helmet>
            <LayoutOneTwo
                info={`${t("screens:forgottenPassword.storePasswords")} ${t(
                    "screens:forgottenPassword.doNotLeave"
                )}`}
            >
                <Wrappers.FormWrapper className="login">
                    {requestSent && (
                        <Flex.Row>
                            <Wrappers.FormWrapper>
                                <Wrappers.Wrapper>
                                    <Headings.H2
                                        className="center title bold"
                                        style={{ marginBottom: "141px" }}
                                    >
                                        {t("screens:forgottenPassword.title")}
                                    </Headings.H2>
                                    <Flex.Row>
                                        <Section className="bold">
                                            <Circle>
                                                <img
                                                    alt="ok"
                                                    src="/images/actions/icon-ok-primary.svg"
                                                />
                                            </Circle>
                                            <div>
                                                {t(
                                                    "screens:forgottenPassword.requestSubmitted"
                                                )}
                                            </div>
                                        </Section>
                                        <Section>
                                            {t(
                                                "screens:forgottenPassword.emailSent"
                                            )}
                                            :
                                            <div className="email">
                                                {enteredUsername.current}
                                            </div>
                                        </Section>
                                        <Section>
                                            {t(
                                                "screens:forgottenPassword.emailNotReceived"
                                            )}
                                        </Section>
                                    </Flex.Row>
                                </Wrappers.Wrapper>
                                <Wrappers.Wrapper className="form-links-wrapper">
                                    <NavigationLink
                                        to="#"
                                        className="forgotten-password"
                                        onClick={requestResend}
                                    >
                                        {t("screens:forgottenPassword.resend")}
                                    </NavigationLink>
                                    <span style={{ margin: "0px 8px" }}>
                                        &#124;
                                    </span>

                                    <NavigationLink
                                        to={RouteCreator.user.login()}
                                        className="forgotten-password"
                                        onClick={() => {
                                            if (timeoutFn.current)
                                                clearTimeout(timeoutFn.current);
                                        }}
                                    >
                                        {t(
                                            "screens:forgottenPassword.backToLoginLink"
                                        )}
                                    </NavigationLink>
                                </Wrappers.Wrapper>
                            </Wrappers.FormWrapper>
                        </Flex.Row>
                    )}
                    {!requestSent && (
                        <Flex.Row style={{ height: "90%" }}>
                            <DataEntry
                                style={{
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    paddingBottom: "40px",
                                }}
                                isLoading={false}
                                renderForm={(formik) => (
                                    <ForgottenPasswordForm formik={formik} />
                                )}
                                initialValues={initialValues}
                                onSubmit={requestPassReset}
                                validationSchema={yupValidation}
                            />

                            <Wrappers.Wrapper
                                className="form-links-wrapper"
                                style={{ marginTop: "-30px" }}
                            >
                                <NavigationLink
                                    to={RouteCreator.user.login()}
                                    className="forgotten-password"
                                >
                                    {t(
                                        "screens:forgottenPassword.backToLoginLink"
                                    )}
                                </NavigationLink>
                            </Wrappers.Wrapper>
                        </Flex.Row>
                    )}
                </Wrappers.FormWrapper>
            </LayoutOneTwo>
        </Flex.Container>
    );
};

export default ForgottenPasswordScreen;
