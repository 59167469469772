import React from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import QrReader from "react-qr-reader";
import InputElement from "../../../../components/elements/form-elements/InputElement";

import {
    InfoText,
    InfoTitle,
    InfoWrapper,
    RoundInfo,
} from "../../../../components/modal/components/WizardStyling";
import { StyledQrReader } from "../../../../components/elements/qr/QrScanner";
import ValidationRule, {
    buildValidation,
} from "../../../../core/validations/ValidationRule";

import CompanyService from "../../../../services/Company/CompanyService";
import TagsService from "../../../../services/Tags/TagsService";

import { ReactComponent as ToStockSvg } from "../../../../assets/pwa/collect-gas.svg";
import {
    ScanButton,
    KeyVal,
    ListTitle,
    HR,
    Box16A9,
} from "../../components/StyledElements";

import { SvgScan, SvgNoImage } from "../../components/StyledSvg";

const Content = styled.div`
    width: 100%;
    box-sizing: border-box;
    flex-grow: 1;
    position: relative;
    padding: 35px 60px 0px;
    display: flex;
    flex-direction: column;
    padding: 0px 0px 0px 0px;
    margin-top: -40px;
    max-height: 500px;
    overflow: auto;
`;

const FlexCenter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const SvgToStock = styled(ToStockSvg)`
    width: auto;
    margin: 0 auto;
`;

const Section = styled.div`
    padding: 8px 20px;
    &.full-x {
        padding: 8px 0px;
    }

    &.flex {
        display: flex;
    }
`;

const ScanWrapper = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
`;

const Spacer = styled.div`
    height: 10px;
    width: 100%;
`;

const defaultInitialValues = {
    showCylinderScanner: false,
    isValidCylinderTag: false,
    quantity: 0,
    cylinderTagId: "",
    cylinderId: "",
    capacity: "",
    refrigerantId: "",
};

const validCylinderTagId = (t) => {
    return buildValidation({
        cylinderTagId: ValidationRule.isStringRule()
            .isUUID(t("forms:unit.uuid.invalid"))
            .required(t("forms:unit.unitTagId.required")).rule,
    });
};

const CollectCylinderWizard = (t, cylinderSearchFn = () => {}) => {
    const handleCylinderScan = (value, formik) => {
        if (value != null) {
            formik.setValues({
                ...formik.values,
                showCylinderScanner: false,
                cylinderTagId: value.toLowerCase(),
            });
        }
    };

    return {
        title: t("actions:dialogs:collectEmpty.title"),
        initialValues: defaultInitialValues,
        steps: [
            {
                name: t("forms:stock.wizard.moveToCompanyStock.pwaStep1"),
                fieldNames: ["cylinderTagId", "cylinderId"],
                validation: validCylinderTagId(t),
                onStepSubmit: async (props) => {
                    const tagId = props.values["cylinderTagId"];
                    const tagResult = await TagsService.identifyTagAsync(tagId, true);
                    if (tagResult && tagResult.error) {
                        toast.error(t(tagResult.error.message));
                        props.formik.setFieldValue("isValidCylinderTag", false);
                        return;
                    }
                    const tagData = tagResult.data.data;
                    if (tagData.length === 0 || !tagData[0].cylinderId) {
                        toast.error(
                            t("forms:extraction.wizard.notCylinderTag")
                        );
                        props.formik.setFieldValue("isValidCylinderTag", false);
                        return;
                    }

                    const cylinderResult =
                        await CompanyService.getCompanyCylinderByIdAsync(
                            tagData[0].cylinderId, true
                        );

                    if (cylinderResult && cylinderResult.error) {
                        toast.error(t(cylinderResult.error.message));
                        props.formik.setFieldValue("isValidCylinderTag", false);
                        return;
                    }

                    const cyl = cylinderResult.data.data[0];

                    if (
                        cyl.filledQuantity != 0 ||
                        cyl.inUnfinishedOffer 
                    ) {
                        toast.error(t("actions:dialogs.recycle.invalidTag"));
                        return false;
                    }

                    props.updatePropertyBag({
                        cylinder: {
                            tagId: tagId,
                            size: cyl.size,
                            gas: cyl.refrigerantTypeCode,
                            quantity: cyl.filledQuantity,
                            image:
                                cyl.imageUrl &&
                                cyl.imageUrl.indexOf("no_image") < 0
                                    ? cyl.imageUrl
                                    : null,
                        },
                    });
                    props.formik.setFieldValue("cylinderId", cyl.id);
                    return true;
                },
                fields: (formik) => {
                    return (
                        <>
                            <div
                                style={{
                                    display: formik.values.showCylinderScanner
                                        ? "none"
                                        : "block",
                                }}
                            >
                                <FlexCenter>
                                    <SvgToStock />
                                </FlexCenter>

                                <InfoTitle className="pwa">
                                    {t("actions:dialogs.collectEmpty.text")}
                                </InfoTitle>
                                <InfoWrapper className="pwa">
                                    <RoundInfo
                                        alt=""
                                        src="/images/menu/green-info.svg"
                                    />
                                    <div>
                                        <InfoText className="pwa">
                                            {t(
                                                "forms:extraction.wizard.useCamera"
                                            )}
                                            <Spacer />
                                            {t(
                                                "forms:extraction.wizard.typeQrTag"
                                            )}
                                        </InfoText>
                                    </div>
                                </InfoWrapper>
                                <InputElement
                                    pwaBig
                                    noLabel
                                    labelText={t("forms:cylinderTagId.text")}
                                    id="cylinderTagId"
                                    name="cylinderTagId"
                                    type="search"
                                    placeholder={t("screens:technician.qrTag")}
                                    searchFn={cylinderSearchFn}
                                    wrapperVariant="flex-1"
                                    {...formik}
                                />
                            </div>

                            {formik.values.showCylinderScanner && (
                                <StyledQrReader className="wizard">
                                    <QrReader
                                        delay={300}
                                        onError={(error) => console.log(error)}
                                        onScan={(value) =>
                                            handleCylinderScan(value, formik)
                                        }
                                        style={{ width: "100%" }}
                                    />
                                </StyledQrReader>
                            )}

                            <ScanWrapper>
                                <ScanButton
                                    type="button"
                                    className={
                                        formik.values.showCylinderScanner
                                            ? "close"
                                            : ""
                                    }
                                    onClick={() =>
                                        formik.setFieldValue(
                                            "showCylinderScanner",
                                            !formik.values.showCylinderScanner
                                        )
                                    }
                                >
                                    <SvgScan className="white" />
                                </ScanButton>
                            </ScanWrapper>
                        </>
                    );
                },
            },
            {
                name: t("forms:stock.wizard.moveToCompanyStock.pwaStep2"),
                fieldNames: ["cylinderId"],
                fields: (formik, propertyBag) => {
                    return (
                        <>
                            {propertyBag.cylinder && (
                                <Content>
                                    <HR className="content" />
                                    <ListTitle className="wizard">
                                        {t("screens:technician.details")}
                                    </ListTitle>
                                    <Section>
                                        <KeyVal className="single">
                                            {t("forms:cylinderSize.text")}
                                            <span>
                                                {propertyBag.cylinder.size.toFixed(
                                                    2
                                                )}{" "}
                                                kg
                                            </span>
                                        </KeyVal>
                                        <KeyVal className="single">
                                            {t("screens:technician.gas")}
                                            <span>
                                                {propertyBag.cylinder.gas || "N/A"}
                                            </span>
                                        </KeyVal>
                                        <KeyVal className="single">
                                            {t("screens:technician.Quantity")}
                                            <span>
                                                {propertyBag.cylinder.quantity.toFixed(
                                                    2
                                                )}{" "}
                                                kg
                                            </span>
                                        </KeyVal>
                                    </Section>
                                    <HR className="content" />
                                    <ListTitle className="wizard">
                                        {t("forms:extraction.wizard.picture")}
                                    </ListTitle>
                                    <Section>
                                        <Box16A9>
                                            {propertyBag.cylinder.image ? (
                                                <img
                                                    className="thumb"
                                                    alt="unit"
                                                    src={
                                                        propertyBag.cylinder
                                                            .image
                                                    }
                                                />
                                            ) : (
                                                <SvgNoImage />
                                            )}
                                        </Box16A9>
                                    </Section>
                                </Content>
                            )}
                        </>
                    );
                },
            },
        ],
    };
};
export default CollectCylinderWizard;
