import React from "react";
import styled from "styled-components";
import AccordionWrapper from "../../components/toggle-view/AccordionWrapper";
import useAccordionGroup from "../../hooks/_shared/useAccordionGroup";
import { useTranslation } from "react-i18next";
import SidebarField from "../../components/elements/sidebar/SidebarField";

const Section = styled.section`
    width: 100%;
    margin: 10px 0 10px 0;
    padding-bottom: 10px;
    border-bottom: 1px solid #eaeaea;

    &:last-child {
        border-bottom: none;
    }
`;

const Title = styled.div`
    font-size: 16px;
    color: #7f7f7f;
    font-weight: 600;
    margin: 10px 0 0 20px;
`;
const getDateFormat = (currentLanguage) =>
    Intl.DateTimeFormat(currentLanguage, {
        day: "numeric",
        month: "numeric",
        year: "numeric",
    });

const GetShortDate = (dateValue) => {
    if (dateValue && typeof dateValue === "string")
        dateValue = new Date(dateValue);
    const currentLanguage = "sk";
    return getDateFormat(currentLanguage).format(dateValue);
};

const AdminCertSidebar = ({
    approver,
    licenceNumber,
    processedDate,
    certificateHolder,
    type,
}) => {
    const { t } = useTranslation("sidebar");
    const [openAccordion, setOpenAccordion] = useAccordionGroup("approvals");
    return (
        <AccordionWrapper
            title={t("label.approvals")}
            id="approvals"
            isOpen={openAccordion}
            onToggle={setOpenAccordion}
        >
            <Section>
                <Title
                    dangerouslySetInnerHTML={{
                        __html:
                            type === "company"
                                ? t("screens:certificates.companyCert")
                                : `${t(
                                      "screens:certificates.newCertWizard.titlePersonal"
                                  )}<br>(${certificateHolder})`,
                    }}
                />
                <SidebarField
                    label={t("screens:certificates.tableHeaders.certNumber")}
                    value={licenceNumber}
                />
                <SidebarField
                    label={t("label.approvalDate")}
                    value={processedDate ? GetShortDate(processedDate) : "-"}
                />
                <SidebarField
                    label={t("label.approver")}
                    value={approver || "-"}
                />
            </Section>
        </AccordionWrapper>
    );
};

export default AdminCertSidebar;
