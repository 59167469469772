import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import ApiRouteCustomError from "../core/exceptions/ApiRouteCustomError";

export const getApiUrl =
    process.env.NODE_ENV !== "production"
        ? window.location.protocol === "http"
            ? "http://localhost:51872/api"
            : "https://localhost:44394/api"
        : window.location.protocol + "//" + window.location.host + "/api";

const baseUrl = getApiUrl;

let requestsCounter = 0;

const showApiLoader = (visible) => {
    const loaderEl = document.getElementById("apiLoader");
    if (!loaderEl) return;
    visible
        ? loaderEl.classList.add("show")
        : loaderEl.classList.remove("show");
};

const errorHandler = (error) => {
    const INTERNAL_SERVER_ERROR = 500;
    const BAD_REQUEST = 400;
    const NOT_FOUND = 404;
    let handledErrorResponse = error;
    if (
        error &&
        error.response &&
        (error.response.status === INTERNAL_SERVER_ERROR ||
            (error.response.status === NOT_FOUND && error.response.data) ||
            (error.response.status === BAD_REQUEST &&
                error.response.data &&
                error.response.data.localizationKey ===
                    "exception.ValidationException"))
    ) {
        let data = error.response.data;
        handledErrorResponse = new ApiRouteCustomError({
            messages: data.messages || data.error || [],
            localizationKey: data.localizationKey,
            stackTrace: data.stackTrace,
            key: data.key,
            name: data.name,
            type: data.type,
            redirectUrl: data.redirectUrl,
        });
    }
    if (--requestsCounter === 0) {
        showApiLoader(false);
    }
    return Promise.reject(handledErrorResponse);
};

const successHandler = (response) => {
    // Handle responses
    if (--requestsCounter === 0) {
        showApiLoader(false);
    }
    return response;
};

let instance = axios.create({
    baseURL: baseUrl,
});

instance.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");
    if (token && config.url !== "/Authentication/refresh") {
        config.headers["Authorization"] = `Bearer ${localStorage.getItem(
            "token"
        )}`;
    }
    return config;
});

instance.interceptors.response.use(
    (response) => successHandler(response),
    (error) => errorHandler(error)
);

//Progress Bar
instance.interceptors.request.use((config) => {
    if (requestsCounter === 0) {
        showApiLoader(true);
    }
    requestsCounter++;
    return config;
});

instance.interceptors.request.use((config) => {
    const lang = localStorage.getItem("lang");
    if (lang) {
        config.headers["Accept-Language"] = lang;
    }
    return config;
});

// Function that will be called to refresh authorization
const refreshAuthLogic = (failedRequest) => {
    instance.defaults.headers["Authorization"] =
        "Bearer " + localStorage.getItem("refreshToken");
    return instance
        .post("/Authentication/refresh")
        .then((tokenRefreshResponse) => {
            localStorage.setItem("token", tokenRefreshResponse.data.token);
            localStorage.setItem(
                "refreshToken",
                tokenRefreshResponse.data.refreshToken
            );
            instance.defaults.headers["Authorization"] =
                "Bearer " + tokenRefreshResponse.data.token;
            failedRequest.response.config.headers["Authorization"] =
                "Bearer " + tokenRefreshResponse.data.token;
            return Promise.resolve();
        });
};

// Instantiate the interceptor (you can chain it as it returns the axios instance)
instance = createAuthRefreshInterceptor(instance, refreshAuthLogic);

export function apiResult(promise) {
    return promise
        .then((response) => {
            return { status: response.status, data: response.data };
        })
        .catch((error) => {
            if (error.getMessages) {
                const errors = error.getMessages();
                if (errors.length > 0)
                    error.message = errors[0].localizationKey;
            }
            return { data: null, error };
        });
}

export default instance;
