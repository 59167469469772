import React, {  useEffect, useState } from "react";
import useBreadcrumbs from "../../../core/state/navigation/useBreadcrumbs";
import { useTranslation } from "react-i18next";
import useImmerReducer from "../../../hooks/_shared/useImmerReducer";
import useDataReducer from "../../../hooks/_shared/useDataReducer";
import PaymentService from "../../../services/Payment/PaymentService";
import ModalWizard from "../../../components/modal/ModalWizard";
import PayoutWizard from "./components/PayoutWizard";
import { toast } from "react-toastify";
import RouteCreator from "../../../core/RouteCreator";
import MyWallets from "./components/MyWallets";
import styled from "styled-components";
import Flex from "../../../components/layout/flex/Flex";
import Headings from "../../../components/elements/headings/Headings";
import helpers from "../../../core/helpers";
import TransactionRow from "./components/TransactionRow";

import WizardDepositMoney from "./components/wizard/WizardDepositMoney";
import TransactionDetails from "./components/TransactionDetails";
import PlainModal, {
    usePlainModal,
} from "../../../components/modal/PlainModal";
import { Helmet } from "react-helmet";
import { useHistory } from 'react-router-dom';


const Title = styled(Headings.H1)`
    color: #000;
    font-size: 36px;
    text-transform: uppercase;
    margin: 38px 60px;
`;

const WalletsWrapper = styled.div`
    padding: 0px 60px;
`;

export const DataListingContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 20px 0;
    padding: 0 60px;
`;

export const DataListingContent = styled.div`
    display:flex;
    flex-wrap:wrap;
    flex: 1;
    align-items: flex-start;
    align-content: flex-start;
    position: relative;
    overflow: visible;

    &.empty {
       min-height: 190px;
    }
`;

const Activity = styled.div`
    font-size: 20px;
    color: #000;
    padding: 30px 60px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .title {
        text-transform: uppercase;
    }

    & .link {
        color: #2294D2;
        font-size: 16px;
        cursor: pointer;
    }
`;

const WalletSection= styled.div`
    background-color: #f0f0f0;
    border-bottom: 1px solid rgba(0,0,0,0.2);
    padding-bottom: 28px;
`;

const Wallets = styled(Flex.Column)`
    position: relative;
    display: flex;
    padding-left: 40px;
`;

const actionTypes = {
    SET_SORT: "SET_SORT",
    RESET_SORT: "RESET_SORT",
    SET_STATUS: "SET_STATUS",
    RESET_STATUS: "RESET_STATUS",
    SET_PAGING: "SET_PAGING",
    SET_PAYMENT_TYPE: "SET_PAYMENT_TYPE",
    RESET_PAYMENT_TYPE: "RESET_PAYMENT_TYPE",
    SET_DATE_FROM: "SET_DATE_FROM",
    RESET_DATE_FROM: "RESET_DATE_FROM",
    SET_DATE_TO: "SET_DATE_TO",
    RESET_DATE_TO: "RESET_DATE_TO",
};

const groupTransactionsReducer = (state, action) => {
    if (
        (action.type !== actionTypes.SET_PAGING)
    )
        state.page = 1;
    switch (action.type) {
        case actionTypes.SET_PAGING:
            state.pageSize = action.payload.pageSize;
            state.page = action.payload.pageIndex + 1;
            break;
        case actionTypes.SET_FLOW_TYPE:
            state.filter.flowType = action.payload;
            break;
        case actionTypes.RESET_FLOW_TYPE:
            delete state.filter.flowType;
            break;
        case actionTypes.SET_PARENT_TYPE:
            state.filter.parentType = action.payload;
            break;
        case actionTypes.RESET_PARENT_TYPE:
            delete state.filter.parentType;
            break;
        case actionTypes.SET_PARENT_STATUS:
            state.filter.isExecuted = action.payload;
            break;
        case actionTypes.RESET_PARENT_STATUS:
            delete state.filter.isExecuted;
            break;

        case actionTypes.RESET_GROUP_FILTER:
            state.filter = {};
            break;
        default:
            return state;
    }
};

const TransactionsDashboard = () => {
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;
    const currency = "EUR";

    useBreadcrumbs([
        {
            text: t("navigation:finance"),
        },
        {
            text: t("navigation:myWallets"),
        },
    ]);

    // Wallets
    const [myWallets, loadMyWallets] = useDataReducer(
        PaymentService.getMyWallets
    );

    const [myGroupTransactionsState, myGroupTransactionsDispatch] =
        useImmerReducer(groupTransactionsReducer, {
            filter: {
                flowType: null,
                parentType: null,
                status: null

            },
            page: 1,
            pageSize: 6,
        });

    useEffect(() => {
        loadMyWallets();
    }, []);

    const [myBankAccounts, loadMyBankAccounts] = useDataReducer(async () => {
        return await PaymentService.getMyBankAccounts();
    });

    const [myGroupedTransactions, loadMyGroupedTransactions] = useDataReducer(
        () => PaymentService.getMyGroupedTransactions(myGroupTransactionsState),
        true
    );
    useEffect(() => {
        loadMyGroupedTransactions(myGroupTransactionsState);
    }, []);

    useEffect(() => {
        loadMyBankAccounts();
    }, [t]);

    const [payoutTriggered, setPayoutTriggered] = useState(false);
    let initialValues = {
        bankAccountId: "",
    };

    const [transactionDetails, setTransactionDetails] = useState({
        offer: "",
        details: "",
        paymentMethod: "0",
        paymentType: 0,
    });


    const handlePayout = (walletValues) => {
        const payoutInitialValues = Object.assign(
            {},
            initialValues,
            walletValues
        );
        ModalWizard.show(
            PayoutWizard(t, payoutInitialValues, myBankAccounts.data),
            {
                onSubmit: async (values) => {
                    const result = await PaymentService.payout(values);
                    if (result && result.error) {
                        toast.error(t(result.error.message));
                    } else {
                        toast.success(
                            t(
                                "actions:dialogs.wallet.payout.toastNotifications.success"
                            )
                        );
                        ModalWizard.hide();
                        loadMyWallets();
                        loadMyBankAccounts();
                        setPayoutTriggered(true);
                    }
                },
                onCancel: async () => {
                    ModalWizard.hidePrompt();
                },
            }
        );
    };

    const handleDeposit = () => {
        ModalWizard.show(WizardDepositMoney(t), {
            onSubmit: async (values) => {
                const result = await PaymentService.topUp(values);
                if (result && result.error) {
                    toast.error(t(result.error.message));
                } else {
                    setTransactionDetails({
                        offer: null,
                        details: result.data.data[0],
                        paymentType: 4,
                        paymentMethod: "TopUp",
                    });
                    toast.success(
                        t(
                            "actions:dialogs.wallet.deposit.toastNotifications.success"
                        )
                    );
                    ModalWizard.hide();
                    toggleTransactionDetails();
                }
            },
            onCancel: async () => {
                ModalWizard.hidePrompt();
            },
        });
    };

    const [totalWalletAmount, setTotalWalletAmount] = useState({
        amount: 0,
        currency: currency,
    });
    useEffect(() => {
        if (myWallets.data) {
            let amount = 0;
            let currency = "";
            myWallets.data.forEach((wallet, index) => {
                amount += wallet.amount;
                if (index === 0) {
                    currency = wallet.currency;
                }
            });
            setTotalWalletAmount({
                amount: helpers.formatCurrency(
                    amount,
                    currentLanguage,
                    currency
                ),
                currency,
            });
        }
    }, [myWallets, t]);

    const {
        modalProps: transactionDetailsProps,
        toggleOpen: toggleTransactionDetails,
    } = usePlainModal({
        onClose: () => {
            toggleTransactionDetails();
        },
        closeText: t("actions:close"),
    });

    const onRowClick = (item) => {
        history.push(`${RouteCreator.finance.hierarchy()}?id=${item.id}`);
    }

    const hasData = myWallets.data && myWallets.data.length > 0;

    return (
        <>
            <Helmet>
                <title>{t("seo:transactions.myWallets.title")}</title>
            </Helmet>
            <WalletSection>
                <Title>{t("screens:transactions.myWallets.title")}</Title>
                <WalletsWrapper className="walletsWrapper">
                    <Wallets>
                        {hasData &&
                            myWallets.data.map((wallet) => (
                                <MyWallets
                                    key={wallet.id}
                                    data={wallet}
                                    handlePayout={handlePayout}
                                    payoutTriggered={payoutTriggered}
                                    handleDeposit={handleDeposit}
                                />
                            ))}
                        {hasData && totalWalletAmount && (
                            <MyWallets
                                key="TOTAL BALANCE"
                                isTotal
                                data={totalWalletAmount}
                                handlePayout={handlePayout}
                                payoutTriggered={payoutTriggered}
                                handleDeposit={handleDeposit}
                            />
                        )}
                    </Wallets>
                </WalletsWrapper>
            </WalletSection>
            <Activity>
                <div className="title">{t("screens:transactions.recentActivity")}</div>
                <span className="link" onClick={(ev) => {
                    history.push(RouteCreator.finance.hierarchy());
                    ev.preventDefault();
                }}>{t("screens:transactions.viewAll")}</span>
            </Activity>

            {myGroupedTransactions?.data?.data.length && (
                <DataListingContainer>
                    <DataListingContent style={{ marginBottom: 10 }}>
                        {myGroupedTransactions?.data?.data.map(
                            (item, index) => {
                                return (
                                    <TransactionRow
                                        key={`transaction-group}-${index} - ${item.id}`}
                                        item={item}
                                        columns={null}
                                        onRowClick={onRowClick}
                                    ></TransactionRow>
                                );
                            }
                        )}
                    </DataListingContent>
                </DataListingContainer>
            )}

            <PlainModal {...transactionDetailsProps}>
                <TransactionDetails
                    transactionDetails={transactionDetails.details}
                    offer={transactionDetails.offer}
                    paymentMethod={transactionDetails.paymentMethod}
                    paymentType={transactionDetails.paymentType}
                />
            </PlainModal>
        </>
    );
};

export default TransactionsDashboard;
