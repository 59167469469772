import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ListingPage from "../../../components/data/ListingPage";
import RoundTabs from "../../../components/elements/tabs/RoundTabs";
import Flex from "../../../components/layout/flex/Flex";
import ModalDialog, { useModal } from "../../../components/modal/ModalDialog";
import ModalWizard from "../../../components/modal/ModalWizard";
import BidStatus from "../../../core/enums/BidStatus";
import OfferStatus from "../../../core/enums/OfferStatus";
import myBidsListingStateModule from "../../../core/state/buy-refrigerant/myBidsListingStateModule";
import useBreadcrumbs from "../../../core/state/navigation/useBreadcrumbs";
import useStateModule from "../../../core/state/useStateModule";
import useDataReducer from "../../../hooks/_shared/useDataReducer";
import useImmerReducer from "../../../hooks/_shared/useImmerReducer";
import usePageChange from "../../../hooks/_shared/usePageChange";
import MarketplaceService from "../../../services/BuyRefrigerant/MarketplaceService";
import MyBidDetailSideBar from "../../../screens/SalesCenter/views/components/MyBidDetailSideBar";
import QualityTestWizard from "../../../screens/SalesCenter/views/components/QualityTestWizard";
import TransactionDetails from "../../../screens/SalesCenter/views/components/TransactionDetails";
import PlainModal, { usePlainModal } from "../../../components/modal/PlainModal";
import UserService from "../../../services/User/UserService";
import ActionButton from "../../../components/elements/buttons/ActionButton";
import CollectCylinderWizard from "../../Stock/views/stock/components/CollectCylinderWizard";
import WizardPayForOffer from "../../../screens/SalesCenter/views/components/wizard/WizardPayForOffer";
import PaymentService from "../../../services/Payment/PaymentService";
import { Helmet } from "react-helmet";
import RouteCreator from "../../../core/RouteCreator";
import TabLinks from "../../../components/elements/tabs/TabLinks";
import helpers from "../../../core/helpers";
import GradeCell from "../../../components/data/GradeCell";
import GreenCell from "../../../components/data/GreenCell";
import DateCellWithExpiration from "../../../components/data/DateCellWithExpiration";
import TransactionStatus from "../../../components/elements/general/TransactionStatus";
import HamburgerMenu from "../../../components/menu/HamburgerMenu";
import HamburgerMenuAction from "../../../components/menu/HamburgerMenuAction";
import AuthorizeComponent from "../../../core/AuthorizeComponent";
import Roles from "../../../core/enums/Roles";
import TooltipCell from "../../../components/data/TooltipCell";
import DisposalFilterDist from "./components/DisposalFilterDist";
import BidGradePriceCell from "../../SalesCenter/views/components/BidGradePriceCell";
import useSharedState from "../../../hooks/_shared/useSharedState";
import HandlingCell from "../../../components/data/HandlingCell";
import DateCell from "../../../components/data/DateCell";

const actionTypes = {
    SET_GAS_TYPE: "SET_GAS_TYPE",
    RESET_GAS_TYPE: "RESET_GAS_TYPE",
    SET_GAS_QUANTITY_TO: "SET_GAS_QUANTITY",
    RESET_GAS_QUANTITY_TO: "RESET_GAS_QUANTITY",
    SET_GAS_QUANTITY_FROM: "SET_GAS_QUANTITY_FROM",
    RESET_GAS_QUANTITY_FROM: "RESET_GAS_QUANTITY_FROM",
    SET_PRESUMED_QUALITY: "SET_PRESUMED_QUALITY",
    RESET_PRESUMED_QUALITY: "RESET_PRESUMED_QUALITY",
    SET_QUALITY_ASSURANCE: "SET_QUALITY_ASSURANCE",
    RESET_QUALITY_ASSURANCE: "RESET_QUALITY_ASSURANCE",
    SET_EXPIRATION_DATE_FROM: "SET_EXPIRATION_DATE_FROM",
    RESET_EXPIRATION_DATE_FROM: "RESET_EXPIRATION_DATE_FROM",
    SET_EXPIRATION_DATE_TO: "SET_EXPIRATION_DATE_TO",
    RESET_EXPIRATION_DATE_TOM: "RESET_EXPIRATION_DATE_TO",
    SET_OFFER_CREATION__DATE_FROM: "SET_OFFER_CREATION__DATE_FROM",
    RESET_OFFER_CREATION__DATE_FROM: "SET_OFFER_CREATION__DATE_FROM",
    SET_OFFER_CREATION__DATE_TO: "SET_OFFER_CREATION__DATE_TO",
    RESET_OFFER_CREATION__DATE_TO: "SET_OFFER_CREATION__DATE_TO",
    SET_SORT: "SET_SORT",
    RESET_SORT: "RESET_SORT",
    SET_PAGING: "SET_PAGING",
};

const createSteps = (value, bidStatus, t, offerer = "", buyer = "") => {
    if (bidStatus === 2 || bidStatus === 3) {
        return Array.from([
            {
                status: 2,
                title: `${t("common:status.step")}: 1/4 - ${t("common:status.completed").toUpperCase()} `,
                tip:
                    bidStatus === 2
                        ? t("common:status.completed_rejected", {
                              company: offerer,
                          })
                        : t("common:status.completed_revoked", {
                              company: buyer,
                          }),
            },
            {
                status: 0,
                title: `${t("common:status.step")}: 2/4 - ${t("common:status.canceled").toUpperCase()} `,
                tip:
                    bidStatus === 2
                        ? t("common:status.completed_rejected", {
                              company: offerer,
                          })
                        : t("common:status.completed_revoked", {
                              company: buyer,
                          }),
            },
            {
                status: 0,
                title: `${t("common:status.step")}: 3/4 - ${t("common:status.canceled").toUpperCase()} `,
                tip:
                    bidStatus === 2
                        ? t("common:status.completed_rejected", {
                              company: offerer,
                          })
                        : t("common:status.completed_revoked", {
                              company: buyer,
                          }),
            },
            {
                status: 0,
                title: `${t("common:status.step")}: 4/4 - ${t("common:status.canceled").toUpperCase()} `,
                tip:
                    bidStatus === 2
                        ? t("common:status.completed_rejected", {
                              company: offerer,
                          })
                        : t("common:status.completed_revoked", {
                              company: buyer,
                          }),
            },
        ]);
    }

    let result = Array.from([
        {
            status: 4,
            title: `${t("common:status.step")}: 1/4 - ${t("common:status.inProgress").toUpperCase()} `,
            tip: t("common:status.progress_Accept", {
                company: offerer,
            }),
        },
        {
            status: 3,
            title: `${t("common:status.step")}: 2/4 - ${t("common:status.pending").toUpperCase()} `,
            tip: t("common:status.pending_collect", {
                company: buyer,
            }),
        },
        {
            status: 3,
            title: `${t("common:status.step")}: 3/4 - ${t("common:status.pending").toUpperCase()} `,
            tip: t("common:status.pending_test", {
                company: buyer,
            }),
        },
        {
            status: 3,
            title: `${t("common:status.step")}: 4/4 - ${t("common:status.pending").toUpperCase()} `,
            tip: t("common:status.pending_confirmTest", {
                company: offerer,
            }),
        },
    ]);

    if (bidStatus === 2) {
        result[0] = {
            status: 2,
            tip: t("screens:myOffers.toastNotification.rejectBid"),
        };
        return result;
    } else if (bidStatus === 3) {
        result[0] = {
            status: 2,
            tip: t("screens:myBids.successfullyRevoked"),
        };
        return result;
    }

    switch (value) {
        case OfferStatus.waitingForOffererFunds:
            result[0] = {
                status: 4,
                title: `${t("common:status.step")}: 1/4 - ${t("common:status.inProgress").toUpperCase()} `,
                tip: t("common:status.progress_Cdeposit", {
                    company: offerer,
                }),
            };
            break;
        case OfferStatus.disposeAccepted:
            result[0] = {
                status: 1,
                title: `${t("common:status.step")}: 1/4 - ${t("common:status.completed").toUpperCase()} `,
                tip: t("common:status.completed_Cdeposit", {
                    company: offerer,
                }),
            };
            result[1] = {
                status: 4,
                title: `${t("common:status.step")}: 2/4 - ${t("common:status.inProgress").toUpperCase()} `,
                tip: t("common:status.progress_collect", {
                    company: buyer,
                }),
            };
            break;

        case OfferStatus.buyerClaimedBottles:
            result[0] = {
                status: 1,
                title: `${t("common:status.step")}: 1/4 - ${t("common:status.completed").toUpperCase()} `,
                tip: t("common:status.completed_Cdeposit", {
                    company: offerer,
                }),
            };
            result[1] = {
                status: 1,
                title: `${t("common:status.step")}: 2/4 - ${t("common:status.completed").toUpperCase()} `,
                tip: t("common:status.completed_collect", {
                    company: buyer,
                }),
            };
            result[2] = {
                status: 4,
                title: `${t("common:status.step")}: 3/4 - ${t("common:status.inProgress").toUpperCase()} `,
                tip: t("common:status.progress_test", {
                    company: buyer,
                }),
            };
            break;
        case OfferStatus.qualityTested:
            result[0] = {
                status: 1,
                title: `${t("common:status.step")}: 1/4 - ${t("common:status.completed").toUpperCase()} `,
                tip: t("common:status.completed_Cdeposit", {
                    company: offerer,
                }),
            };
            result[1] = {
                status: 1,
                title: `${t("common:status.step")}: 2/4 - ${t("common:status.completed").toUpperCase()} `,
                tip: t("common:status.completed_collect", {
                    company: buyer,
                }),
            };
            result[2] = {
                status: 1,
                title: `${t("common:status.step")}: 3/4 - ${t("common:status.completed").toUpperCase()} `,
                tip: t("common:status.completed_test", {
                    company: buyer,
                }),
            };
            result[3] = {
                status: 4,
                title: `${t("common:status.step")}: 4/4 - ${t("common:status.inProgress").toUpperCase()} `,
                tip: t("common:status.progress_confirmTest", {
                    company: offerer,
                }),
            };
            break;
        case OfferStatus.disposed:
            result[0] = {
                status: 1,
                title: `${t("common:status.step")}: 1/4 - ${t("common:status.completed").toUpperCase()} `,
                tip: t("common:status.completed_Cdeposit", {
                    company: offerer,
                }),
            };
            result[1] = {
                status: 1,
                title: `${t("common:status.step")}: 2/4 - ${t("common:status.completed").toUpperCase()} `,
                tip: t("common:status.completed_collect", {
                    company: buyer,
                }),
            };
            result[2] = {
                status: 1,
                title: `${t("common:status.step")}: 3/4 - ${t("common:status.completed").toUpperCase()} `,
                tip: t("common:status.completed_test", {
                    company: buyer,
                }),
            };
            result[3] = {
                status: 1,
                title: `${t("common:status.step")}: 4/4 - ${t("common:status.completed").toUpperCase()} `,
                tip: t("common:status.completed_confirmTest", {
                    company: offerer,
                }),
            };
            break;
        default:
            break;
    }
    return result;
};

const tabItems = (t) => [
    {
        name: t("navigation:marketplace"),
        path: RouteCreator.services.disposal(),
    },
    {
        name: t("navigation:myBids"),
        path: RouteCreator.services.cleaningBids(),
    },
];

const myBidsReducer = (state, action) => {
    if (action.type !== actionTypes.SET_PAGING) state.page = 1;
    switch (action.type) {
        case "SET_ACTIVETAB":
            state.filter = {};
            state.filter.bidStatuses = action.payload.bidStatuses;
            state.filter.offerStatuses = action.payload.offerStatuses;
            break;
        case "SET_PAGING":
            state.pageSize = action.payload.pageSize;
            state.page = action.payload.pageIndex + 1;
            break;
        case "SET_SORT":
            state.orderColumn = action.payload[0].id;
            state.descending = action.payload[0].desc;
            break;
        case "RESET_SORT":
            state.orderColumn = "";
            state.descending = true;
            break;
        case "SET_GAS_TYPE":
            state.filter.gasType = action.payload;
            break;
        case "RESET_GAS_TYPE":
            delete state.filter.gasType;
            break;
        case actionTypes.SET_GAS_QUANTITY_TO:
            state.filter.gasQuantityTo = Number(action.payload);
            break;
        case actionTypes.RESET_GAS_QUANTITY_TO:
            delete state.filter.gasQuantityTo;
            break;
        case actionTypes.SET_GAS_QUANTITY_FROM:
            state.filter.gasQuantityFrom = Number(action.payload);
            break;
        case actionTypes.RESET_GAS_QUANTITY_FROM:
            delete state.filter.gasQuantityFrom;
            break;
        case "SET_PRESUMED_QUALITY":
            state.filter.presumedQuality = Number(action.payload);
            break;
        case "RESET_PRESUMED_QUALITY":
            delete state.filter.presumedQuality;
            break;
        case "SET_QUALITY_ASSURANCE":
            state.filter.hasAnalysis = action.payload === "true";
            break;
        case "RESET_QUALITY_ASSURANCE":
            delete state.filter.hasAnalysis;
            break;
        case actionTypes.SET_EXPIRATION_DATE_FROM:
            state.filter.bidDealExpirationFrom = action.payload;
            break;
        case actionTypes.RESET_EXPIRATION_DATE_FROM:
            delete state.filter.bidDealExpirationFrom;
            break;
        case actionTypes.SET_EXPIRATION_DATE_TO:
            state.filter.bidDealExpirationTo = action.payload;
            break;
        case actionTypes.RESET_EXPIRATION_DATE_TO:
            delete state.filter.bidDealExpirationTo;
            break;

        case actionTypes.SET_OFFER_CREATION__DATE_FROM:
            state.filter.offerCreationDateTimeFrom = action.payload;
            break;
        case actionTypes.RESET_OFFER_CREATION__DATE_FROM:
            delete state.filter.offerCreationDateTimeFrom;
            break;
        case actionTypes.SET_OFFER_CREATION__DATE_TO:
            state.filter.offerCreationDateTimeTo = action.payload;
            break;
        case actionTypes.RESSET_OFFER_CREATION__DATE_TO:
            delete state.filter.offerCreationDateTimeTo;
            break;
        default:
            return state;
    }
};

const qualityGrades = ["A", "B", "C"];

const DisposalBidsColumns = {
    getColumns: (bidStatus, t, actions, offerStatus, currentLanguage, globalState, setOpenAccordionId) => {
        const pendingColumns = [
            {
                Header: t("table:headers.gasType"),
                accessor: "refrigerantGasTypeCode",
                Cell: GreenCell,
            },
            {
                Header: t("table:headers.gasQuantity"),
                accessor: "quantitySum",
                Cell: ({ cell: { value } }) => GreenCell({ cell: { value: value.toFixed(2) + " kg" } }),
            },
            {
                Header: t("table:headers.grade"),
                accessor: "pressumedGradeName",
                Cell: GradeCell,
            },
            {
                Header: t("table:headers.evaluation"),
                accessor: "analysisDone",
                Cell: ({ cell: { value } }) =>
                    !value ? t("filters:analysis.measurement") : t("filters:analysis.selfAssessment"),
            },
            {
                Header: `${t("table:headers.bidAmounts")}/${t("sidebar:label.extraction.qualityGrade")}`,
                accessor: "price",
                Cell: BidGradePriceCell,
            },
            {
                Header: t("table:headers.handlingCosts"),
                accessor: "handlingCosts",
                Cell: ({ cell: { value } }) => HandlingCell(value, setOpenAccordionId, "bid.details"),
            },
            {
                Header: "Status",
                accessor: "offerStatus",
                disableSortBy: true,
                Cell: ({ cell: { value }, row: { original } }) => {
                    return (
                        <TransactionStatus
                            steps={createSteps(value, original.bidStatus, t, original.offerer, original.bidCompanyName)}
                        />
                    );
                },
            },
            {
                Header: "",
                accessor: "bidId",
                Cell: ({ cell: { value, row } }) => (
                    <AuthorizeComponent roles={[Roles.InstallerCompanyAdmin]}>
                        <HamburgerMenu>
                            <HamburgerMenuAction
                                text={t("actions:bidActions.revokeBid")}
                                onClick={async () => {
                                    actions.setSelectedBid(row.original);
                                    actions.toggleOpenRevoke(value);
                                }}
                            />
                        </HamburgerMenu>
                    </AuthorizeComponent>
                ),
                disableSortBy: true,
            },
        ];
        const acceptedColumns = [
            {
                Header: t("table:headers.gasType"),
                accessor: "refrigerantGasTypeCode",
                Cell: GreenCell,
            },
            {
                Header: t("table:headers.gasQuantity"),
                accessor: "quantitySum",
                Cell: ({ cell: { value } }) => GreenCell({ cell: { value: value.toFixed(2) + " kg" } }),
            },
            {
                Header: t("table:headers.grade"),
                accessor: "pressumedGradeName",
                Cell: GradeCell,
            },
            {
                Header: t("table:headers.evaluation"),
                accessor: "analysisDone",
                Cell: ({ cell: { value } }) =>
                    !value ? t("filters:analysis.measurement") : t("filters:analysis.selfAssessment"),
            },
            {
                Header: `${t("table:headers.bidAmounts")}/${t("sidebar:label.extraction.qualityGrade")}`,
                accessor: "price",
                Cell: BidGradePriceCell,
            },
            {
                Header: t("table:headers.handlingCosts"),
                accessor: "handlingCosts",
                Cell: ({ cell: { value } }) => HandlingCell(value, setOpenAccordionId, "bid.details"),
            },
            {
                Header: t("table:headers.purchasePrice"),
                accessor: "disposalServicePrice",
                Cell: ({ cell: { row } }) => {
                    const currency = "EUR";

                    return TooltipCell({
                        cell: {
                            value: helpers.formatCurrency(
                                helpers.calculatePriceWithVat(
                                    row.original.offeredPriceGradeC * row.original.quantitySum
                                ),
                                currentLanguage,
                                currency
                            ),
                        },
                        tip: t("table:tooltips.withVat"),
                    });
                },
            },
            {
                Header: t("myBids.headers.bidDealExpiration"),
                accessor: "bidDealExpiration",
                Cell: (value) => {
                    let translation = "";
                    const offerStatus = value.row.original.offerStatus;
                    if (offerStatus === 2) {
                        translation = "screens:myOffers.offerStatus.2";
                    } else if (offerStatus === 3) {
                        translation = "screens:myOffers.offerStatus.3";
                    } else if (offerStatus === 4) {
                        translation = "screens:myOffers.offerStatus.4";
                    }
                    return DateCellWithExpiration(value, translation);
                },
            },
            {
                Header: "Status",
                accessor: "offerStatus",
                disableSortBy: true,
                Cell: ({ cell: { value }, row: { original } }) => (
                    <TransactionStatus
                        steps={createSteps(value, original.bidStatus, t, original.offerer, original.bidCompanyName)}
                    />
                ),
            },
        ];
        const qualityTestColumns = [
            {
                Header: t("table:headers.gasType"),
                accessor: "refrigerantGasTypeCode",
                Cell: GreenCell,
            },
            {
                Header: t("table:headers.gasQuantity"),
                accessor: "quantitySum",
                Cell: ({ cell: { value } }) => GreenCell({ cell: { value: value.toFixed(2) + " kg" } }),
            },
            {
                Header: t("table:headers.grade"),
                accessor: "pressumedGradeName",
                Cell: GradeCell,
            },
            {
                Header: t("table:headers.evaluation"),
                accessor: "analysisDone",
                Cell: ({ cell: { value } }) =>
                    !value ? t("filters:analysis.measurement") : t("filters:analysis.selfAssessment"),
            },
            {
                Header: `${t("table:headers.bidAmounts")}/${t("sidebar:label.extraction.qualityGrade")}`,
                accessor: "price",
                Cell: BidGradePriceCell,
            },
            {
                Header: t("table:headers.handlingCosts"),
                accessor: "handlingCosts",
                Cell: ({ cell: { value } }) => HandlingCell(value, setOpenAccordionId, "bid.details"),
            },
            {
                Header: t("table:headers.purchasePrice"),
                accessor: "disposalServicePrice",
                Cell: ({ cell: { row } }) => {
                    const currency = "EUR";

                    return TooltipCell({
                        cell: {
                            value: helpers.formatCurrency(
                                helpers.calculatePriceWithVat(
                                    row.original.offeredPriceGradeC * row.original.quantitySum
                                ),
                                currentLanguage,
                                currency
                            ),
                        },
                        tip: t("table:tooltips.withVat"),
                    });
                },
            },
            {
                Header: t("myBids.headers.bidDealExpiration"),
                accessor: "bidDealExpiration",
                Cell: (value) => {
                    let translation = "";
                    const offerStatus = value.row.original.offerStatus;
                    if (offerStatus === 2) {
                        translation = "screens:myOffers.offerStatus.2";
                    } else if (offerStatus === 3) {
                        translation = "screens:myOffers.offerStatus.3";
                    } else if (offerStatus === 4) {
                        translation = "screens:myOffers.offerStatus.4";
                    }
                    const onHold = offerStatus === 10 ? t("common:status.onHold") : null;
                    return DateCellWithExpiration(value, translation, onHold);
                },
            },
            {
                Header: "Status",
                accessor: "offerStatus",
                disableSortBy: true,
                Cell: ({ cell: { value }, row: { original } }) => (
                    <TransactionStatus
                        inDispute={original.offerStatus === 10 ? t("common:status.inDispute") : null}
                        steps={createSteps(value, original.bidStatus, t, original.offerer, original.bidCompanyName)}
                    />
                ),
            },
            {
                Header: "",
                accessor: "offerId",
                Cell: ({ cell: { value, row } }) => {
                    if (row.original.bidStatus !== 1) return <></>;
                    return (
                        <AuthorizeComponent roles={[Roles.InstallerCompanyAdmin, Roles.InstallerCompanyUser]}>
                            <HamburgerMenu>
                                <HamburgerMenuAction
                                    text={t("actions:bidActions.qualityTest")}
                                    onClick={() => {
                                        actions.showQualityTestForm(
                                            row.original.offerId,
                                            row.original.offerer,
                                            row.original.refrigerantGasTypeCode,
                                            row.original.cylindersAnalysis
                                        );
                                    }}
                                    disabled={
                                        row.original.offerStatus !== OfferStatus.buyerClaimedBottles ||
                                        !globalState.hasCertificateRights
                                    }
                                />
                                <HamburgerMenuAction
                                    text={t("actions:bidActions.sellPresumedQuality")}
                                    disabled={
                                        row.original.offerStatus !== OfferStatus.buyerClaimedBottles ||
                                        !globalState.hasCertificateRights
                                    }
                                    onClick={() => {
                                        actions.setSelectedBid(row.original);
                                        actions.togglePayPresumedQuality([value]);
                                    }}
                                />
                            </HamburgerMenu>
                        </AuthorizeComponent>
                    );
                },
                disableSortBy: true,
            },
        ];
        const paymentCompletedColumns = [
            {
                Header: t("table:headers.gasType"),
                accessor: "confirmedRefrigerantGasTypeCode",
                Cell: GreenCell,
            },
            {
                Header: t("table:headers.gasQuantity"),
                accessor: "confirmedQuantity",
                Cell: ({ cell: { value } }) => GreenCell({ cell: { value: value?.toFixed(2) + " kg" } }),
            },
            {
                Header: t("table:headers.grade"),
                accessor: "confirmedGradeName",
                Cell: GradeCell,
            },

            {
                Header: t("table:headers.evaluation"),
                accessor: "analysisDone",
                Cell: ({ cell: { value } }) =>
                    !value ? t("filters:analysis.measurement") : t("filters:analysis.selfAssessment"),
            },

            {
                Header: `${t("table:headers.bidAmounts")}/${t("sidebar:label.extraction.qualityGrade")}`,
                accessor: "price",
                Cell: BidGradePriceCell,
            },
            {
                Header: t("table:headers.handlingCosts"),
                accessor: "handlingCosts",
                Cell: ({ cell: { value } }) => HandlingCell(value, setOpenAccordionId, "bid.details"),
            },
            {
                Header: t("table:headers.purchasePrice"),
                accessor: "disposalServicePrice",
                Cell: ({ cell: { row } }) => {
                    const currency = "EUR";

                    return TooltipCell({
                        cell: {
                            value: helpers.formatCurrency(
                                helpers.calculatePriceWithVat(
                                    row.original.offeredPriceGradeC * row.original.quantitySum
                                ),
                                currentLanguage,
                                currency
                            ),
                        },
                        tip: t("table:tooltips.withVat"),
                    });
                },
            },
            {
                Header: t("table:headers.disposedDate"),
                accessor: "lastChangeDateTime",
                Cell: DateCell,
            },
            {
                Header: "Status",
                accessor: "offerStatus",
                disableSortBy: true,
                Cell: ({ cell: { value }, row: { original } }) => (
                    <TransactionStatus
                        steps={createSteps(value, original.bidStatus, t, original.offerer, original.bidCompanyName)}
                    />
                ),
            },
        ];

        const rejectedRevokedColumns = [
            {
                Header: t("table:headers.gasType"),
                accessor: "refrigerantGasTypeCode",
                Cell: GreenCell,
            },
            {
                Header: t("table:headers.gasQuantity"),
                accessor: "quantitySum",
                Cell: ({ cell: { value } }) => GreenCell({ cell: { value: value.toFixed(2) + " kg" } }),
            },
            {
                Header: t("table:headers.grade"),
                accessor: "pressumedGradeName",
                Cell: GradeCell,
            },
            {
                Header: t("table:headers.evaluation"),
                accessor: "analysisDone",
                Cell: ({ cell: { value } }) =>
                    !value ? t("filters:analysis.measurement") : t("filters:analysis.selfAssessment"),
            },
            {
                Header: `${t("table:headers.bidAmounts")}/${t("sidebar:label.extraction.qualityGrade")}`,
                accessor: "price",
                Cell: BidGradePriceCell,
            },
            {
                Header: t("table:headers.handlingCosts"),
                accessor: "handlingCosts",
                Cell: ({ cell: { value } }) => HandlingCell(value, setOpenAccordionId, "bid.details"),
            },
            {
                Header: "Status",
                accessor: "offerStatus",
                disableSortBy: true,
                Cell: ({ cell: { value }, row: { original } }) => (
                    <TransactionStatus
                        steps={createSteps(value, original.bidStatus, t, original.offerer, original.bidCompanyName)}
                    />
                ),
            },
        ];

        if (
            bidStatus.includes(BidStatus.accepted) &&
            (offerStatus.includes(OfferStatus.buyerEscrowedFunds) ||
                offerStatus.includes(OfferStatus.offererEscrowedFunds))
        ) {
            return acceptedColumns;
        } else if (bidStatus.includes(BidStatus.accepted) && offerStatus.includes(OfferStatus.disposed)) {
            return paymentCompletedColumns;
        } else if (
            bidStatus.includes(BidStatus.accepted) &&
            (offerStatus.includes(OfferStatus.buyerClaimedBottles) || offerStatus.includes(OfferStatus.qualityTested))
        ) {
            return qualityTestColumns;
        } else if (bidStatus.includes(BidStatus.revoked) || bidStatus.includes(BidStatus.rejected)) {
            return rejectedRevokedColumns;
        }
        return pendingColumns;
    },
};

const DistributorDisposalBids = () => {
    const { myBids, isLoading, error, loadMyBids, loadBidById, details, loadGasTypes, gasTypesData } =
        useStateModule(myBidsListingStateModule);

    const [openAccordionId, setOpenAccordionId] = useState();
    const [selectedBid, setSelectedBid] = useState(null);
    const { userId } = UserService.getUserDetailsFromToken();
    const [globalState] = useSharedState("global");
    const [filterState, dispatch] = useImmerReducer(myBidsReducer, {
        filter: {
            bidStatuses: [BidStatus.active],
            offerStatuses: [],
            userId: userId,
        },
        pageSize: 10,
        page: 1,
        orderColumn: "bidDealExpiration",
        descending: false,
        isDisposal: true,
    });

    useEffect(() => {
        loadMyBids(filterState);
    }, [loadMyBids, filterState]);

    useEffect(() => {
        loadGasTypes();
    }, [loadGasTypes]);

    const [myBidsDataState, getMyBidsForOffer] = useDataReducer(async (params) => {
        const bids = await MarketplaceService.getMyBids(params);
        const currentBid = bids.find((b) => b.bidId === params.bidId);
        return [currentBid];
    });

    const { t, i18n } = useTranslation(["screens", "table"]);
    const currentLanguage = i18n.language;
    const currency = "EUR";

    useBreadcrumbs([
        {
            text: t("navigation:services"),
        },
        {
            text: t("navigation:disposalService"),
        },
    ]);

    const [detailViewIsOpened, setDetailViewIsOpened] = useState(false);

    const closeSideBar = () => {
        setOpenAccordionId(null);
        setDetailViewIsOpened(false);
    };

    const [currentRowData, setCurrentRowData] = useState(null);
    const onSelect = useCallback(
        (row) => {
            const selectRow = async (row) => {
                setCurrentRowData(row.original);
                setSelectedBid(row.original);
                await loadBidById(row.original.offerId);
                await getMyBidsForOffer({
                    filter: { offerId: row.original.offerId },
                    bidId: row.original.bidId,
                });
                setDetailViewIsOpened(true);
            };
            selectRow(row);
        },
        [details.bidDetails, getMyBidsForOffer, loadBidById]
    );
    // revoke bid action
    const { modalProps: revokeModalProps, toggleOpen: toggleOpenRevoke } = useModal({
        title: t("actions:dialogs.revokeBid.title"),
        onConfirm: async (bidId) => {
            const result = await MarketplaceService.revokeBid(bidId);
            if (result.error) {
                toast.error(result.error.message);
            } else {
                setDetailViewIsOpened(false);
                loadMyBids(filterState);
                toast.success(t("screens:myBids.successfullyRevoked"));
            }
            toggleOpenRevoke();
        },
        onCancel: () => {
            toggleOpenRevoke();
        },
        confirmText: t("actions:dialogs.revokeBid.confirmText"),
        type: "warning",
    });

    const [transactionDetails, setTransactionDetails] = useState({
        offer: "",
        details: "",
    });

    const getTransactionDetails = async (offer, bidId) => {
        const result = await PaymentService.getTransactionDetails(0, bidId);
        if (result && result.error) {
            toast.error(t(result.error.message));
        } else {
            setTransactionDetails({
                offer: offer,
                details: result.data.data[0],
            });
            toggleTransactionDetails();
        }
    };

    const payForAGrade = useCallback(
        async (bidId, offeredPriceGradeA, offerInfo) => {
            ModalWizard.show(
                WizardPayForOffer(
                    t,
                    currentLanguage,
                    currency,
                    offeredPriceGradeA,
                    offerInfo,
                    false,
                    t("forms:payForOffer.wizard.securityDeposit"),
                    t("forms:payForOffer.wizard.securityDeposit"),
                    t("forms:payForOffer.wizard.placeSecurityDeposit")
                ),
                {
                    onSubmit: async (values) => {
                        const res = await MarketplaceService.payAClass(bidId, values.paymentMethod, true);
                        if (res && res.error) {
                            toast.error(t(res.error.message));
                        } else {
                            toast.success(t("actions:dialogs.payAClass.toastNotifications.success"));
                            loadMyBids(filterState);
                            ModalWizard.hide();
                        }
                    },
                    onCancel: async () => {
                        ModalWizard.hidePrompt();
                    },
                }
            );
        },
        [filterState]
    );

    const { modalProps: transactionDetailsProps, toggleOpen: toggleTransactionDetails } = usePlainModal({
        onClose: () => {
            toggleTransactionDetails();
        },
        closeText: t("actions:close"),
    });

    //buy presumed quality action
    const { modalProps: buyPresumedQualityProps, toggleOpen: togglePayPresumedQuality } = useModal({
        title: t("actions:bidActions.sellServicePresumedQuality"),
        onConfirm: async (offerIds) => {
            const result = await MarketplaceService.offerSoldByBuyer(offerIds);
            if (result.error) {
                toast.error(result.error.message);
            } else {
                loadMyBids(filterState);
                toast.success(t("actions:dialogs.payPresumedQuality.success"));
            }
            togglePayPresumedQuality();
        },
        onCancel: () => {
            togglePayPresumedQuality();
        },
    });

    const [, setOfferDetails] = useState(null);
    const getOfferDetails = (details) => {
        setOfferDetails(details);
    };

    //Upload Quality Test action
    const asyncEvery = async (arr, predicate) => {
        for (let e of arr) {
            if (!(await predicate(e))) return false;
        }
        return true;
    };

    const showQualityTestForm = useCallback(
        async (offerId, offerer, gasTypeCode, cylinders) => {
            ModalWizard.show(QualityTestWizard(t, offerId, offerer, gasTypeCode, cylinders), {
                onSubmit: async (values) => {
                    const forms = ModalWizard.groupValues(values, "_");
                    const uploadCompleted = await asyncEvery(forms, async (form) => {
                        let gradeIdx = qualityGrades.indexOf(form.values.quality);
                        form.values.grade = gradeIdx;
                        const result = await MarketplaceService.addBuyerAnalysis(ModalWizard.asFormData(form.values));
                        if (result.error) {
                            toast.error(result.error.message);
                            return false;
                        } else {
                            return true;
                        }
                    });

                    if (uploadCompleted) {
                        toast.success(t("screens:myBids.successfullyTested"));
                        ModalWizard.hide();
                        loadMyBids(filterState);
                    }
                },
                onCancel: async () => {
                    ModalWizard.hidePrompt();
                },
            });
        },
        [filterState, t, loadMyBids]
    );

    const columns = useMemo(() => {
        return DisposalBidsColumns.getColumns(
            filterState.filter.bidStatuses,
            t,
            {
                showQualityTestForm: showQualityTestForm,
                toggleOpenRevoke: toggleOpenRevoke,
                getOfferDetails: getOfferDetails,
                togglePayPresumedQuality: togglePayPresumedQuality,
                getTransactionDetails: getTransactionDetails,
                payForAGrade: payForAGrade,
                setSelectedBid: setSelectedBid,
            },
            filterState.filter.offerStatuses,
            currentLanguage,
            globalState,
            setOpenAccordionId
        );
    }, [
        filterState.filter.bidStatuses,
        filterState.filter.offerStatuses,
        t,
        showQualityTestForm,
        toggleOpenRevoke,
        toggleTransactionDetails,
        togglePayPresumedQuality,
        details,
        globalState,
    ]);

    const pageChange = usePageChange(dispatch, filterState);

    const sortChange = (sortBy) => {
        sortBy.length > 0
            ? dispatch({ type: actionTypes.SET_SORT, payload: sortBy })
            : dispatch({ type: actionTypes.RESET_SORT });
    };

    const currentSortBy = useMemo(() => {
        return [{ id: filterState.orderColumn, desc: filterState.descending }];
    }, [filterState.orderColumn, filterState.descending]);

    let tabIndexSuccess = -1;

    const bidStatuses = filterState.filter.bidStatuses;
    const offerStatuses = filterState.filter.offerStatuses;
    let showQRButton = false;

    if (bidStatuses.includes(BidStatus.active)) {
        tabIndexSuccess = 0;
    } else if (
        bidStatuses.includes(BidStatus.accepted) &&
        (offerStatuses.includes(OfferStatus.waitingForOffererFunds) ||
            offerStatuses.includes(OfferStatus.disposeAccepted) ||
            offerStatuses.includes(OfferStatus.offererEscrowedFunds))
    ) {
        tabIndexSuccess = 1;
    } else if (
        bidStatuses.includes(BidStatus.accepted) &&
        (offerStatuses.includes(OfferStatus.buyerClaimedBottles) || offerStatuses.includes(OfferStatus.qualityTested))
    ) {
        tabIndexSuccess = 2;
    } else if (bidStatuses.includes(BidStatus.accepted) && offerStatuses.includes(OfferStatus.disposed)) {
        tabIndexSuccess = 3;
    }

    if (bidStatuses.includes(BidStatus.accepted) && offerStatuses.includes(OfferStatus.buyerEscrowedFunds)) {
        //flip this to true if you want the collect button to show, but only if the QR stickers
        //have a guid printed on them, otherwise this does not make any sense
        showQRButton = false;
    }

    let tabIndexFail = -1;
    switch (filterState.filter.bidStatuses[0]) {
        case BidStatus.revoked:
            tabIndexFail = 0;
            break;
        case BidStatus.rejected:
            tabIndexFail = 1;
            break;
        default:
            tabIndexFail = -1;
    }
    const listingKey = tabIndexSuccess + 10 * tabIndexFail;

    const [collectCompanies, setCollectCompanies] = useDataReducer(MarketplaceService.getOffersForCollection);
    useEffect(() => {
        setCollectCompanies();
    }, []);

    const { modalProps: collectInfoModalProps, toggleOpen: toggleCollectInfoModal } = useModal({
        title: t("forms:stock.wizard.collectCylinder.title"),
        type: "warning",
        confirmText: t("actions:close"),
        onConfirm: () => {
            toggleCollectInfoModal();
        },
    });

    return (
        <>
            <Helmet>
                <title>{t("seo:myBids.title")}</title>
            </Helmet>
            <TabLinks
                variant="shadowed"
                items={tabItems(t)}
                idx={1}
                style={{ marginTop: "0px", padding: "10px 0px 10px 40px" }}
            />
            <ListingPage
                key={listingKey}
                columns={columns}
                tableState={{
                    data: myBids.data,
                    loading: isLoading,
                    error: error,
                }}
                paging={{
                    enablePaging: true,
                    pageCount: myBids.totalPages,
                    pageSize: myBids.pageSize,
                    onPageChange: pageChange,
                    currentPageIndex: myBids.currentPage - 1,
                }}
                sorting={{
                    enableSorting: true,
                    onSortByChange: sortChange,
                    currentSortBy: currentSortBy,
                }}
                actions={{
                    headerActions: () => (
                        <>
                            <RoundTabs
                                tabIndex={tabIndexSuccess}
                                activeFn={(status) => {
                                    setSelectedBid(null);
                                    if (status[0].offerStatuses[0] === 21) {
                                        dispatch({
                                            type: actionTypes.SET_SORT,
                                            payload: [
                                                {
                                                    id: "lastChangeDateTime",
                                                    desc: true,
                                                },
                                            ],
                                        });
                                    } else {
                                        dispatch({
                                            type: actionTypes.SET_SORT,
                                            payload: [
                                                {
                                                    id: "bidDealExpiration",
                                                    desc: false,
                                                },
                                            ],
                                        });
                                    }
                                    dispatch({
                                        type: "SET_ACTIVETAB",
                                        payload: status[0],
                                    });
                                }}
                                tabs={[
                                    {
                                        label: t("screens:myBids.tabs.pending"),
                                        activeValue: [
                                            {
                                                bidStatuses: [BidStatus.active],
                                                offerStatuses: [],
                                            },
                                        ],
                                    },
                                    {
                                        label: t("screens:myBids.tabs.accepted"),
                                        activeValue: [
                                            {
                                                bidStatuses: [BidStatus.accepted],
                                                offerStatuses: [
                                                    OfferStatus.waitingForOffererFunds,
                                                    OfferStatus.offererEscrowedFunds,
                                                    OfferStatus.disposeAccepted,
                                                ],
                                            },
                                        ],
                                    },
                                    {
                                        label: t("screens:myBids.tabs.qualityTest"),
                                        activeValue: [
                                            {
                                                bidStatuses: [BidStatus.accepted],
                                                offerStatuses: [
                                                    OfferStatus.qualityTested,
                                                    OfferStatus.buyerClaimedBottles,
                                                ],
                                            },
                                        ],
                                    },
                                    {
                                        label: t("screens:disposal.tabs.disposed"),
                                        activeValue: [
                                            {
                                                bidStatuses: [BidStatus.accepted],
                                                offerStatuses: [OfferStatus.disposed],
                                            },
                                        ],
                                    },
                                ]}
                            />
                            <RoundTabs
                                style={{ width: "268px" }}
                                tabIndex={tabIndexFail}
                                activeColor="#E94E4E"
                                activeFn={(status) => {
                                    setSelectedBid(null);
                                    dispatch({
                                        type: "SET_ACTIVETAB",
                                        payload: status[0],
                                    });
                                }}
                                tabs={[
                                    {
                                        label: t("screens:myBids.tabs.revoked"),
                                        activeValue: [
                                            {
                                                bidStatuses: [BidStatus.revoked],
                                                offerStatuses: [],
                                            },
                                        ],
                                    },
                                    {
                                        label: t("screens:myBids.tabs.rejected"),
                                        activeValue: [
                                            {
                                                bidStatuses: [BidStatus.rejected],
                                                offerStatuses: [],
                                            },
                                        ],
                                    },
                                ]}
                            />

                            {showQRButton && (
                                <Flex.Column style={{ marginLeft: "auto" }}>
                                    <ActionButton
                                        className="primary"
                                        // icon="/images/actions/icon-move.svg"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            collectCompanies.data.length === 0
                                                ? toggleCollectInfoModal()
                                                : ModalWizard.show(CollectCylinderWizard(t, collectCompanies), {
                                                      onSubmit: async (values) => {
                                                          // alert('uncomment BE action! Selected tags: ' + values.tagIds);
                                                          const result = await MarketplaceService.shipToBuyer(
                                                              values.tagIds
                                                          );
                                                          if (result.error) {
                                                              toast.error(t(result.error.message));
                                                          } else {
                                                              loadMyBids(filterState);
                                                              toast.success(t("actions:dialogs.shipOut.success"));
                                                              ModalWizard.hide();
                                                          }
                                                      },
                                                      onCancel: async () => {
                                                          ModalWizard.hidePrompt();
                                                      },
                                                  });
                                        }}
                                        tooltip={t("screens:stockListing.tooltip.moveToCompanyStock")}
                                    >
                                        {t("actions:collect")}
                                    </ActionButton>
                                </Flex.Column>
                            )}
                        </>
                    ),
                }}
                filterTitle={t("filters:disposalMarketPlace.filterTitle")}
                showTitle={true}
                filterComponent={() => {
                    return (
                        <DisposalFilterDist
                            filterState={filterState.filter}
                            dispatch={dispatch}
                            actionTypes={actionTypes}
                            gasTypes={gasTypesData.gasTypes}
                            tabIndex={tabIndexSuccess}
                            screen="bids"
                        />
                    );
                }}
                noDataText={t("myBids.noData")}
                onSelect={onSelect}
                closeSideBar={closeSideBar}
                detailViewIsOpened={detailViewIsOpened}
                detailView={
                    <MyBidDetailSideBar
                        key={detailViewIsOpened}
                        openAccordionId={openAccordionId}
                        bid={myBidsDataState?.data?.[0]}
                        bidDetails={details.bidDetails}
                        offer={currentRowData}
                        isLoading={isLoading || details.isLoading || myBidsDataState.isLoading}
                        isDisposal={true}
                        toggleOpenRevoke={() => toggleOpenRevoke(myBidsDataState.data[0].bidId)}
                    />
                }
                detailSideBarTitle={`${t("sidebar:title.offerId")}: ${currentRowData?.offerNumber}`}
            />

            <ModalDialog {...revokeModalProps}>
                <p>{t("actions:dialogs.revokeBid.text")}</p>
                <p className="list">
                    {selectedBid &&
                        `• ${selectedBid.offeredPriceGradeA.toFixed(
                            2
                        )} EUR/kg | ${selectedBid.offeredPriceGradeB.toFixed(
                            2
                        )} EUR/kg | ${selectedBid.offeredPriceGradeC.toFixed(2)} EUR/kg`}
                </p>
                <p className="bold">{t("actions:dialogs.revokeBid.offeredItem")}</p>
                <p className="list">
                    {selectedBid &&
                        `• ${selectedBid.refrigerantGasTypeCode} | ${selectedBid.quantitySum.toFixed(2)} kg | ${
                            selectedBid.pressumedGradeName
                        } | ${
                            selectedBid.analysisDone === "False"
                                ? t("filters:analysis.selfAssessment")
                                : t("filters:analysis.measurement")
                        } `}
                </p>
            </ModalDialog>

            <ModalDialog {...buyPresumedQualityProps}>
                <p>{t("actions:dialogs.payPresumedQuality.info")}</p>
                <p className="list">
                    {selectedBid &&
                        `• ${selectedBid.refrigerantGasTypeCode} | ${selectedBid.quantitySum.toFixed(2)} kg | ${
                            selectedBid.pressumedGradeName
                        } | ${
                            selectedBid.analysisDone === "False"
                                ? t("filters:analysis.selfAssessment")
                                : t("filters:analysis.measurement")
                        } `}
                </p>
                <p>{t("actions:dialogs.payPresumedQuality.text")}</p>
            </ModalDialog>

            <PlainModal {...transactionDetailsProps}>
                <TransactionDetails
                    transactionDetails={transactionDetails.details}
                    offer={transactionDetails.offer}
                    paymentMethod="PayIn"
                    paymentType={0}
                />
            </PlainModal>

            <ModalDialog {...collectInfoModalProps}>
                <p>{t("forms:stock.wizard.collectCylinder.warning")}</p>
            </ModalDialog>
        </>
    );
};
export default DistributorDisposalBids;
