import React from "react";
import { useTranslation } from "react-i18next";
import SidebarField from "../../../components/elements/sidebar/SidebarField";
import AccordionWrapper from "../../../components/toggle-view/AccordionWrapper";
import helpers from "../../../core/helpers";
import useAccordionGroup from "../../../hooks/_shared/useAccordionGroup";
import { Box16A9 } from "../../pwa/components/StyledElements";
import { SvgNoImage } from "../../pwa/components/StyledSvg";
import AnalyzerBlock from "../../../components/sidebar/AnalyzerBlock";

const EquipmentDetailSideBar = ({ equipment }) => {
    const { equipmentData } = equipment;
    const { name, tagId, macAddress, imageUrl, description } = equipmentData;
    const { t } = useTranslation("sidebar");
    const [openAccordion, setOpenAccordion] = useAccordionGroup("title_userAccount");
    return (
        <>
            {equipmentData && (
                <>
                    <AnalyzerBlock
                        tagId={tagId}
                        name={name}
                        macAddress={macAddress}
                        openAccordion={openAccordion}
                        setOpenAccordion={setOpenAccordion}
                    />

                    <AccordionWrapper
                        title={t("title.gallery")}
                        id="gallery"
                        isOpen={openAccordion}
                        onToggle={setOpenAccordion}
                    >
                        <Box16A9
                            style={{
                                marginTop: "24px",
                                marginLeft: "20px",
                            }}
                        >
                            {imageUrl !== "/images/cylinder_no_image.svg" ? (
                                <img className="thumb" alt="unit" src={imageUrl} />
                            ) : (
                                <SvgNoImage />
                            )}
                        </Box16A9>
                    </AccordionWrapper>

                    <AccordionWrapper
                        title={t("forms:clients.notes")}
                        id="Notes"
                        isOpen={openAccordion}
                        onToggle={setOpenAccordion}
                    >
                        <p style={{ paddingLeft: "20px" }}>{description ? description : "-"}</p>
                    </AccordionWrapper>
                </>
            )}
        </>
    );
};

export default EquipmentDetailSideBar;
