import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import DataEntry from "../../../../../components/data-entry/DataEntry";
import EffectError from "../../../../../components/errors/EffectError";
import Flex from "../../../../../components/layout/flex/Flex";
import helpers from "../../../../../core/helpers";
import userStateModule from "../../../../../core/state/user/userStateModule";
import useStateModule from "../../../../../core/state/useStateModule";
import ValidationRule, {
    buildValidation,
} from "../../../../../core/validations/ValidationRule";
import UserProfilePictureForm from "../../../../User/components/UserProfilePictureForm";

const profilePicDefaultValues = {
    profilePicture: "",
};

const getProfilePicValidation = (t) => {
    return buildValidation({
        profilePicture: ValidationRule.isStringRule().required(
            t("forms:userPicture.required")
        ).rule,
    });
};

const ChangePictureSection = ({ fullName, otherUserData, style }) => {
    const { t } = useTranslation();

    const {
        userData,
        loadUserData,
        userProfilePictureUpdate,
        updateUserPicture,
        updateUserPictureResetState,
        deleteUserPicture,
    } = useStateModule(userStateModule);

    const isProfilePictureLoading =
        userData.isLoading || userProfilePictureUpdate.isLoading;
    useEffect(() => {
        return updateUserPictureResetState;
    }, [updateUserPictureResetState]);

    useEffect(() => {
        if (userProfilePictureUpdate.isCompleted) {
            toast.success(
                t("screens:userPictureEdit.toastNotification.success")
            );
        } else if (userProfilePictureUpdate.error) {
            //toast.error(t('screens:userPictureEdit.toastNotification.fail'));
        }
    }, [userProfilePictureUpdate.isCompleted, userProfilePictureUpdate.error]);

    // useEffect(() => {
    //     if (userProfilePictureDelete.isCompleted) {
    //         toast.success(t('screens:userPictureEdit.toastNotification.imageDeleted'));
    //     }
    // }, [userProfilePictureDelete.isCompleted]);

    const profilePicValidation = getProfilePicValidation(t);

    const handleProfilePicSubmit = async (values) => {
        let formData = helpers.getFormData(values);
        if (values.profilePictureFile) {
            formData.append(
                "profilePicture",
                values.profilePictureFile,
                values.profilePictureFile.name
            );
        }
        await updateUserPicture(formData);
        loadUserData();
    };

    const handleProfilePicDelete = async () => {
        await deleteUserPicture();
        toast.success(
            t("screens:userPictureEdit.toastNotification.imageDeleted")
        );
        loadUserData();
    };

    return (
        <Flex.Container style={style}>
            <Flex.Column className="half">
                <Flex.Container flexWrap="wrap">
                    <Flex.Column className="half">
                        {userProfilePictureUpdate.error && (
                            <EffectError
                                onlyNotification
                                error={userProfilePictureUpdate.error}
                            />
                        )}

                        <DataEntry
                            isLoading={isProfilePictureLoading}
                            validationSchema={profilePicValidation}
                            onSubmit={handleProfilePicSubmit}
                            initialValues={profilePicDefaultValues}
                            renderForm={(formik) => (
                                <UserProfilePictureForm
                                    formik={formik}
                                    isLoading={isProfilePictureLoading}
                                    userData={userData}
                                    otherUserData={otherUserData}
                                    onDelete={handleProfilePicDelete}
                                    fullName={fullName}
                                />
                            )}
                        />
                    </Flex.Column>
                </Flex.Container>
            </Flex.Column>
        </Flex.Container>
    );
};

export default ChangePictureSection;
