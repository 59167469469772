import React from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import QrReader from "react-qr-reader";
import * as yup from "yup";
import { DateTimePicker } from "../../../../components/elements/form-elements/DatePicker";
import InputElement from "../../../../components/elements/form-elements/InputElement";
import { Checkbox, Radio } from "../../../../components/elements/form-elements";
import Flex from "../../../../components/layout/flex/Flex";
import {
    InfoText,
    InfoTitle,
    InfoWrapper,
    RoundInfo,
} from "../../../../components/modal/components/WizardStyling";
import { StyledQrReader } from "../../../../components/elements/qr/QrScanner";
import ValidationRule, {
    buildValidation,
    skipValidation,
} from "../../../../core/validations/ValidationRule";
import ClientsService from "../../../../services/Clients/ClientsService";
import CompanyService from "../../../../services/Company/CompanyService";
import TagsService from "../../../../services/Tags/TagsService";
import PresumedGradeLabel from "../../../../screens/NewRefrigerant/views/new-refrigerant/components/PresumedGradeLabel";
import { ReactComponent as ExtractSvg } from "../../../../assets/pwa/new-extraction.svg";
import { ReactComponent as AnalyzerSvg } from "../../../../assets/pwa/analyzer-result.svg";
import { ScanButton, KeyVal, ListTitle, HR, Box16A9 } from "../../components/StyledElements";
import MoneyInputElement from "../../../../components/elements/form-elements/MoneyInputElement";

import { SvgScan, SvgNoImage } from "../../components/StyledSvg";

const RadioTitle = styled.div`
    font-size: 14px;
    font-weight: bold;
    margin: 32px 0 20px 10px;
`;

const Content = styled.div`
    width: 100%;
    box-sizing: border-box;
    flex-grow: 1;
    position: relative;
    padding: 35px 60px 0px;
    display: flex;
    flex-direction: column;
    padding: 0px 0px 0px 0px;
    margin-top: -40px;
    max-height: 100%;
    overflow: auto;
    &.nomargin {
        margin-top: 0;
    }
`;

const FlexCenter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    &.spaced {
        width: 100%;
        margin: 30px 0;
    }
`;

const Half = styled.div`
    width: 50%;
`;

const SvgExtract = styled(ExtractSvg)`
    width: auto;
    margin: 0 auto;
`;
const SvgAnalyzer = styled(AnalyzerSvg)`
    width: auto;
    margin: 0 auto;
`;

const Section = styled.div`
    padding: 8px 20px;
    &.full-x {
        padding: 8px 0px;
    }

    &.flex {
        display: flex;
    }
`;

const ScanWrapper = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
`;

const Spacer = styled.div`
    height: 10px;
    width: 100%;
`;

const defaultInitialValues = {
    showUnitScanner: false,
    showAnalyzerScanner: false,
    isValidUnitTag: false,
    showCylinderScanner: false,
    isValidCylinderTag: false,
    quantity: "0.00",
    pressumedGrade: 0,
    extractionDateTime: new Date(),
    countryId: "",
    street: "",
    city: "",
    postalCode: "",
    region: "",
    gradeA: false,
    gradeB: false,
    gradeC: false,
    unitTagId: "",
    cylinderTagId: "",
    capacity: "",
    applicationTypeName: "",
    manufacturerName: "",
    refrigerantName: "",
    cylinderFull: false,
    refrigerantId: "",
    unitGasType: "",
    cylQualityMatch: true,
    assessment: "0",
};

const getRealGrade = (values) => {
    if (values.gradeC) {
        return "C";
    } else if (values.gradeB) {
        return "B";
    } else {
        return "A";
    }
};

const validUnitTag = (t) => {
    return buildValidation({
        isValidUnitTag: yup.boolean().required().oneOf([true], "Wrong Unit Tag"),
    });
};

const validCylinderTag = (t) => {
    return buildValidation({
        isValidCylinderTag: yup.boolean().required().oneOf([true], "Wrong Unit Tag"),
    });
};

const validUnitTagId = (t) => {
    return buildValidation({
        unitTagId: ValidationRule.isStringRule()
            .isUUID(t("forms:unit.uuid.invalid"))
            .required(t("forms:unit.unitTagId.required")).rule,
    });
};

const validCylinderTagId = (t) => {
    return buildValidation({
        cylinderTagId: ValidationRule.isStringRule()
            .isUUID(t("forms:unit.uuid.invalid"))
            .required(t("forms:unit.unitTagId.required")).rule,
    });
};

const validExtraction = (t) => {
    return buildValidation({
        cylinderFull: yup.boolean().required().oneOf([false], "Cylinder full"),
        quantity: ValidationRule.moreThan(
            0,
            t("forms:quantity.invalidNumber"),
            t("forms:quantity.largerThenZero")
        ).rule,
        extractionDateTime: ValidationRule.isStringRule().required(
            t("forms:extractionDate.required")
        ).rule,
    });
};

const validGrades = (t) =>
    yup.lazy((values) => {
        if (values.assessment === "1")
            return buildValidation({
                analyzerTagId: ValidationRule.isStringRule()
                    .isUUID(t("forms:unit.uuid.invalid"))
                    .required(t("forms:unit.unitTagId.required")).rule,
            });

        // if (!values.gradeA || !values.gradeB || !values.gradeC) skipValidation();
        return buildValidation({
            gradeA: ValidationRule.boolean().required("A").rule,
            gradeB: ValidationRule.boolean().required("B").rule,
            gradeC: ValidationRule.boolean().required("C").rule,
        });
    });

const roundToNearestMinute = (date) => {
    var coeff = 1000 * 60 * 1; // <-- Replace {1} with interval
    return new Date(Math.round(date.getTime() / coeff) * coeff);
};

const ExtractionWizard = (
    t,
    searchFn = () => {},
    cylinderSearchFn = () => {},
    analyzerSearchFn = () => {},
    initialValues = {}
) => {
    const handleUnitScan = (value, formik) => {
        if (value != null) {
            formik.setValues({
                ...formik.values,
                showUnitScanner: false,
                unitTagId: value.toLowerCase(),
            });
        }
    };

    const handleAnalyzerScan = (value, formik) => {
        if (value != null) {
            formik.setValues({
                ...formik.values,
                showAnalyzerScanner: false,
                analyzerTagId: value.toLowerCase(),
            });
        }
    };

    const handleCylinderScan = (value, formik) => {
        if (value != null) {
            formik.setValues({
                ...formik.values,
                showCylinderScanner: false,
                cylinderTagId: value.toLowerCase(),
            });
        }
    };

    return {
        title: t("forms:extraction.wizard.title"),
        initialValues: { ...defaultInitialValues, ...initialValues },
        steps: [
            {
                name: t("forms:extraction.wizard.title_step1"),
                fieldNames: ["unitTagId"],
                validation: validUnitTagId(t),
                onStepSubmit: async ({ values }) => {
                    const tagId = values["unitTagId"];

                    const tagResult = await TagsService.identifyTagAsync(tagId);
                    if (tagResult.error) {
                        toast.error(t(tagResult.error.message));
                        return false;
                    }
                    const tagData = tagResult.data.data;
                    if (tagData.length === 0 || !tagData[0].unitId) {
                        toast.error(t("forms:extraction.wizard.notUnitTag"));
                        return false;
                    }

                    return true;
                },
                fields: (formik) => {
                    return (
                        <>
                            <div
                                style={{
                                    display: formik.values.showUnitScanner ? "none" : "block",
                                }}
                            >
                                <FlexCenter>
                                    <SvgExtract />
                                </FlexCenter>

                                <InfoTitle className="pwa">
                                    {t("forms:extraction.wizard.unitInfoTitle")}
                                </InfoTitle>
                                <InfoWrapper className="pwa">
                                    <RoundInfo alt="" src="/images/menu/green-info.svg" />
                                    <div>
                                        <InfoText className="pwa">
                                            {t("forms:extraction.wizard.unitInfoText")}
                                            <Spacer />
                                            {t("forms:extraction.wizard.useCamera")}
                                            <Spacer />
                                            {t("forms:extraction.wizard.typeQrTag")}
                                        </InfoText>
                                    </div>
                                </InfoWrapper>
                                <InputElement
                                    pwaBig
                                    noLabel
                                    labelText={t("forms:unitTagId.text")}
                                    id="unitTagId"
                                    name="unitTagId"
                                    type="search"
                                    searchFn={searchFn}
                                    wrapperVariant="flex-1"
                                    {...formik}
                                />
                            </div>

                            {formik.values.showUnitScanner && (
                                <StyledQrReader className="wizard">
                                    <QrReader
                                        delay={300}
                                        onError={(error) => console.log(error)}
                                        onScan={(value) => handleUnitScan(value, formik)}
                                        style={{ width: "100%" }}
                                    />
                                </StyledQrReader>
                            )}
                            <ScanWrapper>
                                <ScanButton
                                    className={formik.values.showUnitScanner ? "close" : ""}
                                    type="button"
                                    onClick={() =>
                                        formik.setFieldValue(
                                            "showUnitScanner",
                                            !formik.values.showUnitScanner
                                        )
                                    }
                                >
                                    <SvgScan className="white" />
                                </ScanButton>
                            </ScanWrapper>
                        </>
                    );
                },
            },
            {
                name: t("forms:extraction.wizard.title_step2"),
                fieldNames: ["refrigerantId", "unitGasType"],
                onStepRender: async (props) => {
                    const tagId = props.value["unitTagId"];
                    if (!props.propertyBag.unit || tagId !== props.propertyBag.unit.tagId) {
                        const tagResult = await TagsService.identifyTagAsync(
                            props.value["unitTagId"]
                        );
                        if (tagResult.error) {
                            toast.error(t(tagResult.error.message));
                            props.formik.setFieldValue("isValidUnitTag", false);
                            return;
                        }
                        const tagData = tagResult.data.data;
                        if (tagData.length === 0 || !tagData[0].unitId) {
                            toast.error(t("forms:extraction.wizard.notUnitTag"));
                            props.formik.setFieldValue("isValidUnitTag", false);
                            return;
                        }

                        const unitResult = await ClientsService.getUnitByIdAsync(tagData[0].unitId);
                        if (unitResult.error) {
                            toast.error(unitResult.error.message);
                            props.formik.setFieldValue("isValidUnitTag", false);
                            return;
                        }
                        const unitData = unitResult.data.data[0];
                        props.formik.setFieldValue("refrigerantId", unitData.refrigerantId);
                        props.updatePropertyBag({
                            unit: {
                                tagId: tagId,
                                manufacturer: unitData.manufacturerName,
                                application: unitData.applicationTypeName,
                                gas: unitData.refrigerantCode,
                                capacity: unitData.capacity,
                                notes: unitData.description,
                                image: unitData.images[0]?.imageThumbnailUrl,
                                company: unitData.client.clientName,
                                street: unitData.client.street,
                                city: unitData.client.city,
                                zip: unitData.client.postalCode,
                                country: unitData.client.countryName,
                            },
                        });
                        props.formik.setFieldValue("isValidUnitTag", true);
                        props.formik.setFieldValue("unitGasType", unitData.refrigerantCode);
                    } else {
                        props.formik.setFieldValue("isValidUnitTag", true);
                    }
                },
                validation: validUnitTag(t),
                fields: (formik, propertyBag) => {
                    return (
                        <>
                            {propertyBag.unit && (
                                <Content>
                                    <HR className="content" />
                                    <ListTitle className="wizard">
                                        {t("screens:technician.details")}
                                    </ListTitle>
                                    <Section>
                                        <KeyVal className="single">
                                            {t("screens:technician.manufacturer")}
                                            <span>{propertyBag.unit.manufacturer}</span>
                                        </KeyVal>
                                        <KeyVal className="single">
                                            {t("screens:technician.application")}
                                            <span>{propertyBag.unit.application}</span>
                                        </KeyVal>
                                        <KeyVal className="single">
                                            {t("screens:technician.installedGas")}
                                            <span>{propertyBag.unit.gas}</span>
                                        </KeyVal>
                                        <KeyVal className="single">
                                            {t("screens:technician.capacity")}
                                            <span>{propertyBag.unit.capacity} kW</span>
                                        </KeyVal>
                                    </Section>
                                    <HR className="content" />
                                    <div style={{ display: "flex" }}>
                                        <Half>
                                            <ListTitle className="wizard">
                                                {t("screens:technician.notes")}
                                            </ListTitle>
                                        </Half>
                                        <Half>
                                            <ListTitle className="wizard">
                                                {t("screens:technician.gallery")}
                                            </ListTitle>
                                        </Half>
                                    </div>
                                    <Section className="flex">
                                        <Half>
                                            {propertyBag.unit.notes ? propertyBag.unit.notes : "-"}
                                        </Half>
                                        <Half>
                                            <Box16A9>
                                                {propertyBag.unit.image ? (
                                                    <img
                                                        className="thumb"
                                                        alt="unit"
                                                        src={propertyBag.unit.image}
                                                    />
                                                ) : (
                                                    <SvgNoImage />
                                                )}
                                            </Box16A9>
                                        </Half>
                                    </Section>
                                    <HR className="content" />
                                    <ListTitle className="wizard">
                                        {t("screens:technician.installationSite")}
                                    </ListTitle>
                                    <Section>
                                        <KeyVal className="single">
                                            {t("screens:technician.company")}
                                            <span>{propertyBag.unit.company}</span>
                                        </KeyVal>
                                        <KeyVal className="single">
                                            {t("screens:technician.address")}
                                            <span>{propertyBag.unit.street}</span>
                                        </KeyVal>
                                        <KeyVal className="single">
                                            {t("screens:technician.city")}
                                            <span>{propertyBag.unit.city}</span>
                                        </KeyVal>
                                        <KeyVal className="single">
                                            {t("screens:technician.zip")}
                                            <span>{propertyBag.unit.zip}</span>
                                        </KeyVal>
                                        <KeyVal className="single">
                                            {t("screens:technician.country")}
                                            <span>{propertyBag.unit.country}</span>
                                        </KeyVal>
                                    </Section>
                                </Content>
                            )}
                        </>
                    );
                },
            },
            {
                name: t("forms:cylinder.wizard.pwaStep1"),
                fieldNames: ["cylinderTagId"],
                validation: validCylinderTagId(t),
                onStepSubmit: async ({ values }) => {
                    const cylinderId = values["cylinderTagId"];
                    let tagDetails = await TagsService.identifyTag(cylinderId);
                    let currentCylinder;
                    if (tagDetails && tagDetails.length > 0) {
                        currentCylinder = await CompanyService.getCompanyCylinderByIdAsync(
                            tagDetails[0].cylinderId
                        );
                    } else if (tagDetails.length === 0) {
                        toast.error(t("forms:unit.wizard.invalidTag"));
                        return false;
                    }
                    if (currentCylinder.error) {
                        toast.error(t(currentCylinder.error.message));
                        return false;
                    } else {
                        if (
                            currentCylinder.data.data[0].refrigerantTypeCode &&
                            currentCylinder.data.data[0].refrigerantTypeCode !== values.unitGasType
                        ) {
                            toast.error(t("forms:extraction.wizard.actionGasMismatch"));
                            return false;
                        }
                    }
                    return true;
                },
                fields: (formik) => {
                    return (
                        <>
                            <div
                                style={{
                                    display: formik.values.showCylinderScanner ? "none" : "block",
                                }}
                            >
                                <FlexCenter>
                                    <SvgExtract />
                                </FlexCenter>

                                <InfoTitle className="pwa">
                                    {t("forms:extraction.wizard.cylinderInfoTitle")}
                                </InfoTitle>
                                <InfoWrapper className="pwa">
                                    <RoundInfo alt="" src="/images/menu/green-info.svg" />
                                    <div>
                                        <InfoText className="pwa">
                                            {t("forms:extraction.wizard.cylinderInfoText")}
                                            <Spacer />
                                            {t("forms:extraction.wizard.useCamera")}
                                            <Spacer />
                                            {t("forms:extraction.wizard.typeQrTag")}
                                        </InfoText>
                                    </div>
                                </InfoWrapper>
                                <InputElement
                                    pwaBig
                                    noLabel
                                    labelText={t("forms:cylinderTagId.text")}
                                    id="cylinderTagId"
                                    name="cylinderTagId"
                                    type="search"
                                    searchFn={cylinderSearchFn}
                                    wrapperVariant="flex-1"
                                    {...formik}
                                />
                            </div>

                            {formik.values.showCylinderScanner && (
                                <StyledQrReader className="wizard">
                                    <QrReader
                                        delay={300}
                                        onError={(error) => console.log(error)}
                                        onScan={(value) => handleCylinderScan(value, formik)}
                                        style={{ width: "100%" }}
                                    />
                                </StyledQrReader>
                            )}

                            <ScanWrapper>
                                <ScanButton
                                    type="button"
                                    className={formik.values.showCylinderScanner ? "close" : ""}
                                    onClick={() =>
                                        formik.setFieldValue(
                                            "showCylinderScanner",
                                            !formik.values.showCylinderScanner
                                        )
                                    }
                                >
                                    <SvgScan className="white" />
                                </ScanButton>
                            </ScanWrapper>
                        </>
                    );
                },
            },
            {
                name: t("screens:technician.cylinderInfo"),
                fieldNames: ["isValidCylinderTag", "assessment"],
                onStepRender: async (props) => {
                    const tagId = props.value["cylinderTagId"];
                    if (!props.propertyBag.cylinder || tagId !== props.propertyBag.cylinder.tagId) {
                        const tagResult = await TagsService.identifyTagAsync(
                            props.value["cylinderTagId"]
                        );
                        if (tagResult.error) {
                            toast.error(t(tagResult.error.message));
                            props.formik.setFieldValue("isValidCylinderTag", false);
                            return;
                        }
                        const tagData = tagResult.data.data;

                        if (tagData.length === 0 || !tagData[0].cylinderId) {
                            toast.error(t("forms:extraction.wizard.notCylinderTag"));
                            props.formik.setFieldValue("isValidCylinderTag", false);
                            return;
                        }

                        const cylinderResult = await CompanyService.getCompanyCylinderByIdAsync(
                            tagData[0].cylinderId
                        );
                        if (cylinderResult && cylinderResult.error) {
                            toast.error(t(cylinderResult.error.message));
                            props.formik.setFieldValue("isValidCylinderTag", false);
                            return;
                        }

                        const cylinderData = cylinderResult.data.data[0];
                        if (cylinderData.filledQuantity >= cylinderData.size) {
                            props.formik.setFieldValue("isValidCylinderTag", false);
                            toast.error(t("forms:extraction.wizard.cylinderFull"));
                        } else {
                            props.formik.setFieldValue("isValidCylinderTag", true);
                        }
                        const hasAnalysis = cylinderData.hasAnalysis;
                        if (hasAnalysis) {
                            props.formik.setFieldValue("assessment", "1");
                        }
                        props.updatePropertyBag({
                            cylinder: {
                                hasAnalysis,
                                tagId: tagId,
                                size: cylinderData.size,
                                gas: cylinderData.refrigerantTypeCode,
                                quantity: cylinderData.filledQuantity,
                                quality: cylinderData.refrigerantGradeName,
                                image:
                                    cylinderData.imageUrl &&
                                    cylinderData.imageUrl.indexOf("no_image") < 0
                                        ? cylinderData.imageUrl
                                        : null,
                            },
                        });
                    } else {
                        props.formik.setFieldValue("isValidCylinderTag", true);
                    }
                },
                validation: validCylinderTag(t),
                fields: (formik, propertyBag) => {
                    return (
                        <>
                            {propertyBag.cylinder && (
                                <Content>
                                    <HR className="content" />
                                    <ListTitle className="wizard">
                                        {t("screens:technician.details")}
                                    </ListTitle>
                                    <Section>
                                        <KeyVal className="single">
                                            {t("forms:cylinderSize.text")}
                                            <span>{propertyBag.cylinder.size.toFixed(2)} kg</span>
                                        </KeyVal>
                                        <KeyVal className="single">
                                            {t("screens:technician.gas")}
                                            <span>
                                                {propertyBag.cylinder.gas ||
                                                    t("screens:equipment.emptyCylinder")}
                                            </span>
                                        </KeyVal>
                                        <KeyVal className="single">
                                            {t("screens:technician.Quantity")}
                                            <span>
                                                {propertyBag.cylinder.quantity.toFixed(2)} kg
                                            </span>
                                        </KeyVal>
                                    </Section>
                                    <HR className="content" />
                                    <ListTitle className="wizard">
                                        {t("screens:technician.gallery")}
                                    </ListTitle>
                                    <Section>
                                        <Box16A9>
                                            {propertyBag.cylinder.image ? (
                                                <img
                                                    className="thumb"
                                                    alt="unit"
                                                    src={propertyBag.cylinder.image}
                                                />
                                            ) : (
                                                <SvgNoImage />
                                            )}
                                        </Box16A9>
                                    </Section>
                                </Content>
                            )}
                        </>
                    );
                },
            },
            {
                name: t("forms:extraction.wizard.title_step5"),
                validation: validExtraction(t),
                fieldNames: ["quantity", "extractionDateTime", "assessment"],
                onStepRender: async (props) => {
                    props.formik.setFieldValue(
                        "extractionDateTime",
                        roundToNearestMinute(new Date())
                    );
                },
                onStepSubmit: async ({ values, propertyBag }) => {
                    const cylQuantity = propertyBag.cylinder.quantity || 0;
                    const quantity = Number(values.quantity);
                    if (propertyBag.cylinder.size < quantity + cylQuantity) {
                        toast.error(t("exceptions:validation.CylinderCannotHaveMoreGas"));
                        return false;
                    }
                    return true;
                },
                fields: (formik, propertyBag) => {
                    return (
                        <>
                            <InfoWrapper className="pwa">
                                <RoundInfo alt="" src="/images/menu/green-info.svg" />
                                <div>
                                    <InfoText className="pwa">
                                        {t("forms:extraction.wizard.checkMeasuring")}
                                    </InfoText>
                                </div>
                            </InfoWrapper>
                            <HR className="content" />
                            <Flex.Container flexWrap="wrap">
                                <MoneyInputElement
                                    labelText={t("forms:quantity.text")}
                                    currencyLabel="kg"
                                    name="quantity"
                                    id="quantity"
                                    type="number"
                                    step="0.01"
                                    value={formik.values[`quantity`]}
                                    wrapperVariant="flex-1"
                                    formik={formik}
                                />
                                <div style={{ width: "100%", height: "32px" }} />
                                <DateTimePicker
                                    labelText={t("forms:extractionDate.text")}
                                    native
                                    mode="datetime"
                                    id="extractionDateTime"
                                    name="extractionDateTime"
                                    wrapperVariant="flex-1"
                                    disabled={propertyBag.cylinderFull}
                                    {...formik}
                                />
                            </Flex.Container>
                            <Flex.Column>
                                <RadioTitle>
                                    {t("forms:extraction.wizard.qualityAssessment")}
                                </RadioTitle>
                                <Radio
                                    labelText={
                                        t("forms:extraction.wizard.selfAssessment") +
                                        formik.values["assessment"]
                                    }
                                    wrapperVariant="flex-1"
                                    name="assessment"
                                    value="0"
                                    secondary
                                    disabled={propertyBag.cylinder?.hasAnalysis}
                                    onChange={(e) => {
                                        formik.setFieldValue("assessment", "0");
                                    }}
                                    checked={formik.values["assessment"] === "0"}
                                ></Radio>
                                <Radio
                                    labelText={t("forms:extraction.wizard.measurement")}
                                    wrapperVariant="flex-1"
                                    name="assessment"
                                    value="1"
                                    secondary
                                    onChange={(e) => {
                                        formik.setFieldValue("assessment", "1");
                                    }}
                                    checked={formik.values["assessment"] === "1"}
                                ></Radio>
                            </Flex.Column>
                        </>
                    );
                },
            },
            {
                name: (values) => {
                    return values.assessment === "1"
                        ? t("forms:analyzer.wizard.step1Title")
                        : t("forms:extraction.wizard.title_step6");
                },
                fieldNames: ["gradeA", "gradeB", "gradeC", "analyzerTagId"],
                validation: validGrades(t),
                onStepSubmit: async ({ values, propertyBag }) => {
                    if (
                        propertyBag.cylinder.quality &&
                        propertyBag.cylinder.quality !== getRealGrade(values)
                    ) {
                        toast.error(t("forms:extraction.wizard.actionQualityMismatch"));
                        return false;
                    }
                    return true;
                },
                fields: (formik) => {
                    if (formik.values["assessment"] === "1")
                        return (
                            <>
                                <div
                                    style={{
                                        display: formik.values.showAnalyzerScanner
                                            ? "none"
                                            : "block",
                                    }}
                                >
                                    <Content className="nomargin">
                                        <HR className="content" />
                                        <FlexCenter className="spaced">
                                            <SvgAnalyzer />
                                        </FlexCenter>
                                        <InfoTitle>
                                            {t("forms:extraction.wizard.identifyAnalyzerTitle")}
                                        </InfoTitle>
                                        <InfoWrapper className="pwa">
                                            <RoundInfo alt="" src="/images/menu/green-info.svg" />
                                            <div>
                                                <InfoText className="pwa">
                                                    {t("forms:extraction.wizard.unitInfoText")}
                                                    <Spacer />
                                                    {t("forms:extraction.wizard.useCamera")}
                                                    <Spacer />
                                                    {t("forms:extraction.wizard.typeQrTag")}
                                                </InfoText>
                                            </div>
                                        </InfoWrapper>
                                    </Content>

                                    <Flex.Container>
                                        <InputElement
                                            pwaBig
                                            noLabel
                                            labelText={t("forms:analyzer.wizard.step1Title")}
                                            id="analyzerTagId"
                                            name="analyzerTagId"
                                            type="search"
                                            searchFn={analyzerSearchFn}
                                            wrapperVariant="flex-1"
                                            {...formik}
                                        />
                                    </Flex.Container>
                                </div>

                                {formik.values.showAnalyzerScanner && (
                                    <StyledQrReader className="wizard">
                                        <QrReader
                                            delay={300}
                                            onError={(error) => console.log(error)}
                                            onScan={(value) => handleAnalyzerScan(value, formik)}
                                            style={{ width: "100%" }}
                                        />
                                    </StyledQrReader>
                                )}
                                <ScanWrapper>
                                    <ScanButton
                                        className={formik.values.showAnalyzerScanner ? "close" : ""}
                                        type="button"
                                        onClick={() =>
                                            formik.setFieldValue(
                                                "showAnalyzerScanner",
                                                !formik.values.showAnalyzerScanner
                                            )
                                        }
                                    >
                                        <SvgScan className="white" />
                                    </ScanButton>
                                </ScanWrapper>
                            </>
                        );

                    return (
                        <Content>
                            <HR className="content" />
                            <InfoWrapper className="pwa">
                                <RoundInfo alt="" src="/images/menu/green-info.svg" />
                                <div>
                                    <InfoText className="pwa">
                                        {t("forms:extraction.wizard.checkMeasuring")}
                                    </InfoText>
                                </div>
                            </InfoWrapper>

                            <Checkbox
                                key="gradeA"
                                name="gradeA"
                                id="gradeA"
                                labelText={t("screens:stock.aGradeText")}
                                labelClassName="grade"
                                className="mb-20"
                                {...formik}
                            />
                            <Checkbox
                                key="gradeB"
                                name="gradeB"
                                id="gradeB"
                                labelText={t("screens:stock.bGradeText")}
                                labelClassName="grade"
                                className="mb-20"
                                {...formik}
                            />
                            <Checkbox
                                key="gradeC"
                                name="gradeC"
                                id="gradeC"
                                labelText={t("screens:stock.cGradeText")}
                                labelClassName="grade"
                                className="mb-20"
                                {...formik}
                            />
                            <PresumedGradeLabel
                                gradeA={formik.values.gradeA}
                                gradeB={formik.values.gradeB}
                                gradeC={formik.values.gradeC}
                            />
                        </Content>
                    );
                },
            },
        ],
    };
};
export default ExtractionWizard;
