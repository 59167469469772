import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const Link = styled(NavLink)`
    &.big {
        font-size: 20px !important;
    }

    &.signup {
        @media ${(p) => p.theme.device.mobile} {
            margin: 30px 0px 10px;
        }
    }

    &.forgotten-password {
        color: ${(props) => props.theme.secondaryColor};
        text-align: center;
        text-decoration: none;
        display: inline-block;
        margin-top: 20px;
        font-weight: 700;
        font-size: 14px;
    }

    &.primary {
        color: ${(props) => props.theme.primaryColor};
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-weight: normal;
    }

    &.primary:hover {
        text-decoration: underline;
    }

    &.secondary {
        color: ${(props) => props.theme.secondaryColor};
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-weight: 600;
    }

    &.secondary:hover {
        text-decoration: underline;
    }

    &.forgotten-password:hover,
    &.signup:hover {
        color: ${(p) => p.theme.primaryColorHover};
    }

    &.inline-button {
        cursor: pointer;
        color: ${(props) => props.theme.primaryColor};
        box-sizing: border-box;
        text-align: center;
        text-decoration: none;
        min-width: ${(props) => props.theme.widthButtonMin};
    }
`;

const NavigationLink = ({ to, children, className, ...rest }) => {
    return (
        <Link className={className} to={to} {...rest}>
            {children}
        </Link>
    );
};

export default NavigationLink;
