import React, { Fragment, memo } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ApiRouteError from "../../core/exceptions/ApiRouteError";
import HTMLAnchor from "../elements/form-elements/HTMLAnchor";
import ValidationError from "../elements/form-elements/ValidationError";
import Wrappers from "../wrappers/Wrappers";

const getStandardErrorMessages = (errors, t) => {
    let translatedMessages = [];

    const getStandardErrorMessage = (error, t) => {
        let msg = "";

        if (error.localizationKey) {
            let translation = t(error.localizationKey);

            if (!translation) {
                translation = error.localizationKey;
            }

            if (msg) msg += " ";

            msg += translation;
        } else if (error.message) {
            if (msg) msg += " ";

            msg += error.message;
        }

        if (error.redirectUrl) {
            return {
                msg: msg,
                redirectUrl: error.redirectUrl,
                redirectUrlBtnText: t(error.localizationKey + "RedirectUrl"),
            };
        }

        return { msg };
    };

    errors.forEach((error) => {
        translatedMessages.push(getStandardErrorMessage(error, t));
    });

    return translatedMessages;
};

const EffectError = ({ error, children, clearErrors, onlyNotification = false }) => {
    const [t] = useTranslation();
    let errors = [];
    let errorLocalizationMessagePairs = [];

    const isApiRouteError = error instanceof ApiRouteError;

    if (isApiRouteError) {
        errorLocalizationMessagePairs = getStandardErrorMessages(
            error.getMessages(),
            t
        );
    } else {
        errorLocalizationMessagePairs.push(t("exceptions:error.general"));
    }

    if (onlyNotification) {
        errorLocalizationMessagePairs.forEach((err) => {
            if (err.msg) toast.error(t(err.msg));
        });
        clearErrors && setTimeout(() => {
            clearErrors();
        }, 1);
    }

    if (!error || error.length === 0 || onlyNotification) {
        return <> {children} </>;
    }

    return (
        <Wrappers.Wrapper>
            <Wrappers.FormWrapper>
                {errors &&
                    errors.map((err, index) => {
                        return (
                            <ValidationError
                                key={index}
                                error={err}
                                className="block"
                                touched="true"
                            />
                        );
                    })}
                {errorLocalizationMessagePairs &&
                    errorLocalizationMessagePairs.map((err, index) => {
                        return (
                            <Fragment key={"f_" + index}>
                                <ValidationError
                                    key={index}
                                    error={err.msg}
                                    className="block"
                                    touched="true"
                                />
                                {err.redirectUrl && (
                                    <HTMLAnchor
                                        className="inline-button"
                                        href={err.redirectUrl}
                                        key={"link_" + index}
                                    >
                                        {err.redirectUrlBtnText}
                                    </HTMLAnchor>
                                )}
                            </Fragment>
                        );
                    })}
            </Wrappers.FormWrapper>
        </Wrappers.Wrapper>
    );
};

const areEqual = (prevProps, nextProps) =>
    prevProps.error === nextProps.error &&
    prevProps.children === nextProps.children;

export default memo(EffectError, areEqual);
