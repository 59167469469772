import React from "react";
import * as yup from "yup";
import {
    Checkbox,
    Select,
} from "../../../../../../components/elements/form-elements";
import DatePicker from "../../../../../../components/elements/form-elements/DatePicker";
import InputElement from "../../../../../../components/elements/form-elements/InputElement";
import Flex from "../../../../../../components/layout/flex/Flex";
import {
    InfoTitle,
    InfoWrapper,
    RoundInfo,
} from "../../../../../../components/modal/components/WizardStyling";
import commonValidation from "../../../../../../core/validations/common";
import ValidationRule, {
    buildValidation,
    skipValidation,
} from "../../../../../../core/validations/ValidationRule";

const getUserValidation = (t, adminRoleId) => {
    return buildValidation({
        firstName: ValidationRule.isStringRule().required(
            t("forms:firstName.required")
        ).rule,
        lastName: ValidationRule.isStringRule().required(
            t("forms:lastName.required")
        ).rule,
        email: commonValidation.email(t).rule,
        roleId: ValidationRule.isStringRule().isNot(
            "-1",
            t("forms:roleid.pleaseselect")
        ).rule
    });
};

const licenceValidation = (t) => (
    yup.lazy((values) => {
        if (!values.licenceCheck) return skipValidation();
        return buildValidation({
            fGasCertificate: ValidationRule.isStringRule().required(
                t("forms:fGasCertificate.required")
            ).rule,
            licenceNumber: ValidationRule.isStringRule().required(
                t("forms:licenceNumber.required")
            ).rule,
            licenceExpirationDate: ValidationRule.isStringRule().required(
                t("forms:licenceExpirationDate.required")
            ).rule,
        });
    })
);

const defaultInitialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    roleId: "-1",
    licenceCheck: false,
    fGasCertificate: "",
    licenceNumber: "",
    licenceExpirationDate: "",
};

const NewEmployeeWizard = (t, rolesData) => {

    let validateCert = defaultInitialValues.licenceCheck;
    const handleLicenceCheck = (ev, formik) => {
        validateCert = ev.target.checked;
        formik.setFieldValue("licenceCheck", ev.target.checked);
    };

    return {
        title: t("forms:employee.formTitle"),
        initialValues: defaultInitialValues,
        steps: [
            {
                name: t("forms:employee.step1"),
                fieldNames: [
                    "firstName",
                    "lastName",
                    "email",
                    "phoneNumber",
                    "roleId"
                ],
                validation: getUserValidation(t),
                fields: formik => (
                    <>
                        <InfoWrapper>
                            <RoundInfo
                                alt=""
                                src="/images/menu/green-info.svg"
                            />
                            <div>
                                <InfoTitle>
                                    {t('forms:employee.infoTitle')}
                                </InfoTitle>
                            </div>
                        </InfoWrapper>
                        <InputElement
                            labelText={t("forms:firstName.text")}
                            name="firstName"
                            id="firstName"
                            type="text"
                            wrapperVariant="flex-2"
                            {...formik}
                        />
                        <InputElement
                            labelText={t("forms:lastName.text")}
                            name="lastName"
                            id="lastName"
                            type="text"
                            wrapperVariant="flex-2"
                            {...formik}
                        />
                        <InputElement
                            labelText={t("forms:email.text")}
                            name="email"
                            id="email"
                            type="text"
                            wrapperVariant="flex-2"
                            {...formik}
                        />
                        <InputElement
                            labelText={t("forms:phoneNumber.text")}
                            name="phoneNumber"
                            id="phoneNumber"
                            type="text"
                            wrapperVariant="flex-2"
                            {...formik}
                        />
                        <Select
                            labelText={t("forms:role.text")}
                            wrapperVariant="flex-2"
                            name="roleId"
                            id="roleId"
                            defaultItem={{
                                key: "-1",
                                value: "-1",
                                label: "Please select a role",
                            }}
                            options={
                                rolesData.roles &&
                                rolesData.roles.map((role) => {
                                    return {
                                        key: role.id,
                                        value: role.id,
                                        label: t("common:roles." + role.name),
                                    };
                                })
                            }
                            {...formik}
                        />
                    </>
                )
            },
            {
                name: t("forms:employee.step2"),
                fieldNames: [
                    "licenceCheck",
                    "fGasCertificate",
                    "licenceNumber",
                    "licenceExpirationDate"
                ],
                validation: licenceValidation(t),
                fields: formik => (
                    <>
                        <InfoWrapper>
                            <div>
                                <InfoTitle>
                                    <Checkbox
                                        labelText={t("forms:employee.licenceCheckBox")}
                                        id="licenceCheck"
                                        name="licenceCheck"
                                        onChange={(ev) =>
                                            handleLicenceCheck(ev, formik)
                                        }
                                        description={[t('forms:employee.licenceCheckInfoText'), t('forms:employee.licenceCheckInfoText2')]}
                                        {...formik}
                                    />
                                </InfoTitle>
                            </div>
                        </InfoWrapper>
                        <Flex.Container style={{width: "100%", flexWrap:"wrap"}}>
                            <div style={{display:"flex", width: "100%"}}>
                                <InputElement
                                    labelText={t(
                                        "forms:licenceNumber.text"
                                    )}
                                    id="licenceNumber"
                                    name="licenceNumber"
                                    type="text"
                                    wrapperVariant="half"
                                    disabled={!validateCert}
                                    {...formik}
                                />

                                <DatePicker
                                    labelText={t(
                                        "forms:licenceExpirationDate.text"
                                    )}
                                    id="licenceExpirationDate"
                                    name="licenceExpirationDate"
                                    wrapperVariant="half"
                                    disabled={!validateCert}
                                    formik={formik}
                                    filter=""
                                    {...formik}
                                />
                          
                          </div>
                          <InputElement
                                labelText=""
                                placeholder={t("forms:fGasCertificateFileUpload.text")}
                                title={t("forms:fGasCertificateFileUpload.header")}
                                id="fGasCertificate"
                                type="singlefile"
                                name="fGasCertificate"
                                style={{width:"100%", margin:"0 1%"}}
                                accept="application/pdf, image/*"
                                wrapperVariant="flex-full"
                                disabled={!validateCert}
                                {...formik}
                            />

                        </Flex.Container>
                    </>
                )
            }
        ]
    }
};

export default NewEmployeeWizard;
