import { useEffect, useState } from "react";
const isAray = (obj) => !!obj && obj.constructor === Array;

const usePromises = (tasks = [], selectorFn, initialValues = {}, deps = []) => {
    if (!isAray(tasks))
        throw new Error("parameter asyncApiResults is not an array");

    const [state, setState] = useState(initialValues);
    useEffect(() => {
            Promise.all(tasks.map((t) => t())).then((values) => {
                if (selectorFn) values = selectorFn(values);
                setState(values);
            });
    }, deps);
    return state;
};

export default usePromises;
