import styled from "styled-components";


const TooltipContainer = styled.div`
    background: white;
    padding: 6px;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0 0 20px rgb(0 0 0 / 8%);
`;

export default TooltipContainer;