import React from "react";
import styled from "styled-components";

const Content = styled.div`
    --idx: ${(p) => ((p.idx + 1) * 26) -8 }px;
    /* visibility: hidden; */
    position: absolute;
    z-index: 100000;
    top: calc(100% + 12px);
    right: calc(100% - var(--idx));
    width: auto;
    padding: 4px 8px;
    border-radius: 4px;
    text-align: left;
    background: ${(props) => props.theme.bgTooltip};
    color: #fff;
    font-size: 12px;
    white-space: nowrap;
    opacity: 0;

    & span {
        display: block;
        margin-bottom: 4px;
        font-size: 14px;
        font-weight: 600;
    }
    &.visible {
        opacity: 1;
    }

    &.right {
        right: 0;
    }

    &.left {
        right: auto;
        left: 0;
    }

    &.top {
        top: -46px;
    }

    &.top-center {
        top: -46px;
        left: auto;
        right: auto;
        text-align: center;
        margin: 0 auto;
        transform: translate(-50%, 0);
    }

    &.top-left {
        top: -46px;
        right: auto;
        left: 0;
        text-align: center;
    }
`;

const StatusTooltip = ({ tooltip, visible, idx, position }) => {
    return (
        <Content
            idx={idx || 0}
            className={`${visible ? "visible" : ""} ${
                position ? position : ""
            }`}
            dangerouslySetInnerHTML={{ __html: tooltip }}
        />
    );
};

export default StatusTooltip;
