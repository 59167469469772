import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ListingPage from "../../../../components/data/ListingPage";
import NullableCell from "../../../../components/data/NullableCell";
import HamburgerMenu from "../../../../components/menu/HamburgerMenu";
import HamburgerMenuAction from "../../../../components/menu/HamburgerMenuAction";
import AuthorizeComponent from "../../../../core/AuthorizeComponent";
import Roles from "../../../../core/enums/Roles";
import usersListStateModule from "../../../../core/state/admin/users/usersListStateModule";
import useBreadcrumbs from "../../../../core/state/navigation/useBreadcrumbs";
import useStateModule from "../../../../core/state/useStateModule";
import useDataReducer from "../../../../hooks/_shared/useDataReducer";
import useImmerReducer from "../../../../hooks/_shared/useImmerReducer";
import usePageChange from "../../../../hooks/_shared/usePageChange";
import UserService from "../../../../services/User/UserService";
import LoginService from "../../../../services/Login/LoginService";
import UserDetailSideBar from "../../../User/components/UserDetailSideBar";
import CertificateStatusCell from "./components/CertificateStatusCell";
import actionTypes from "./components/filterActionsTypes";
import RoleCell from "./components/RoleCell";
import UserFilter from "./components/UserFilter";
import { Helmet } from "react-helmet";
import useSharedState from "../../../../hooks/_shared/useSharedState";

const filterReducer = (state, action) => {
    if (action.type !== actionTypes.SET_PAGING) {
        state.page = 1;
    }
    switch (action.type) {
        case actionTypes.SEARCH_EMAIL:
            state.filter.email = action.payload;
            break;
        case actionTypes.RESET_SEARCH_EMAIL:
            delete state.filter.email;
            break;
        case actionTypes.SEARCH_FULLNAME:
            state.filter.fullName = action.payload;
            break;
        case actionTypes.RESET_SEARCH_FULLNAME:
            delete state.filter.fullName;
            break;
        case actionTypes.SEARCH_ROLE:
            if (action.payload) {
                state.filter.roleNames = [action.payload];
            } else {
                state.filter.roleNames = [];
            }
            break;
        case actionTypes.SEARCH_STATUS:
            if (action.payload === "-1") {
                delete state.filter.userStatus;
            } else if (action.payload === "0") {
                state.filter.userStatus = true;
            } else {
                state.filter.userStatus = false;
            }
            break;
        case actionTypes.SET_PAGING:
            state.page = action.payload.pageIndex + 1;
            state.pageSize = action.payload.pageSize;
            break;
        case actionTypes.SET_SORT:
            state.orderColumn = action.payload[0].id;
            state.descending = action.payload[0].desc;
            break;
        case actionTypes.SEARCH_COMPANYNAME:
            state.filter.companyName = action.payload;
            break;
        case actionTypes.SEARCH_CERTIFICATE:
            if (action.payload === "-1") {
                delete state.filter.hasCertificate;
            } else if (action.payload === "0") {
                state.filter.hasCertificate = true;
            } else {
                state.filter.hasCertificate = false;
            }
            break;
        case actionTypes.SET_COUNTRY:
            state.filter.countryId = action.payload;
            break;
        case actionTypes.RESET_COUNTRY:
            delete state.filter.countryId;
            break;
        default:
            break;
    }
};

const UserListing = () => {
    const {
        users,
        isLoading,
        error,
        loadUsers,
        loadUserById,
        details,
        deleteUser,
    } = useStateModule(usersListStateModule);

    const { t } = useTranslation("screens");
    const [globalState] = useSharedState("global");
    const [selectedUserId,setSelectedUserId ] = useState({userId:null, companyId:null});

    const [filterState, dispatch] = useImmerReducer(filterReducer, {
        filter: {
            roleNames: [],
        },

        page: 1,
        pageSize: 10,
        orderColumn: "fullName",
        descending: false,
    });

    const [detailViewIsOpened, setDetailViewIsOpened] = useState(false);
    const [detailViewKey, setDetailViewKey] = useState(null);

    useBreadcrumbs([
        {
            text: t("navigation:users"),
        },
        {
            text: t("navigation:f-cycleUsers"),
        },
    ]);

    useEffect(() => {
        loadUsers(filterState);
    }, [loadUsers, filterState]);

    const [, setUserEnabled] = useDataReducer(UserService.setUserEnabled);

    const impersonate = async (email) => {
        const result = await LoginService.impersonate(email);
        if (result) {
            localStorage.setItem("token", result.token);
            localStorage.setItem("refreshToken", result.refreshToken);
            window.location.replace("/");
        }
    };

    const columns = useMemo(
        () => [
            {
                Header: t("userList.headers.fullName"),
                accessor: "fullName",
            },
            {
                Header: t("userList.headers.email"),
                accessor: "email",
            },
            {
                Header: t("userList.headers.fGasCertificate"),
                accessor: "certificationStatus",
                Cell: CertificateStatusCell,
            },
            {
                Header: t("userList.headers.companyName"),
                accessor: "companyName",
                Cell: NullableCell,
            },
            {
                Header: t("userList.headers.roles"),
                accessor: "roleNames",
                Cell: RoleCell,
            },
            {
                Header: t("userList.headers.userStatus"),
                accessor: "userStatus",
            },
            {
                Header: "",
                accessor: "id",
                Cell: ({ cell: { value, row } }) => (
                    <AuthorizeComponent
                        roles={[Roles.MasterAdmin, Roles.Admin]}
                    >
                        <HamburgerMenu>
                            <HamburgerMenuAction
                                text={t("common:impersonate.impersonate")}
                                onClick={async () =>
                                    impersonate(row.original.email)
                                }
                            />
                            <HamburgerMenuAction
                                text={t("actions:delete")}
                                disabled={true}
                                onClick={async () => {
                                    await deleteUser(value);
                                    loadUsers(filterState);
                                }}
                            />
                            {row.original.enabled && (
                                <HamburgerMenuAction
                                    text={t("actions:disable")}
                                    disabled={true}
                                    onClick={async () => {
                                        await setUserEnabled({
                                            id: value,
                                            enabled: false,
                                        });
                                        loadUsers(filterState);
                                    }}
                                />
                            )}
                            {!row.original.enabled && (
                                <HamburgerMenuAction
                                    text={t("actions:enable")}
                                    onClick={async () => {
                                        await setUserEnabled({
                                            id: value,
                                            enabled: true,
                                        });
                                        loadUsers(filterState);
                                    }}
                                />
                            )}
                        </HamburgerMenu>
                    </AuthorizeComponent>
                ),
                disableSortBy: true,
            },
        ],
        [t]
    );

    const onSelect = useCallback((row) => {
        const selectRow = async (row) => {
            setDetailViewKey(row.original.id);
            await loadUserById(row.original.id);
            setSelectedUserId({userId:row.original.id, companyId:row.original.companyId});
            setDetailViewIsOpened(true);
        };
        selectRow(row);
    }, []);

    const closeSideBar = () => {
        setDetailViewKey(null);
        setDetailViewIsOpened(false);
    };

    const pageChange = usePageChange(dispatch, filterState);
    const sortChange = (sortBy) => {
        if (sortBy.length > 0) {
            dispatch({ type: "SET_SORT", payload: sortBy });
        }
    };
    const currentSortBy = useMemo(() => {
        return [{ id: filterState.orderColumn, desc: filterState.descending }];
    }, [filterState.orderColumn, filterState.descending]);

    return (
        <>
            <Helmet>
                <title>{t("seo:userListing.title")}</title>
            </Helmet>
            <ListingPage
                columns={columns}
                tableState={{
                    data: users.data,
                    loading: isLoading,
                    error: error,
                }}
                filterTitle={t("filters:adminUserListing.filterTitle")}
                filterComponent={() => (
                    <UserFilter
                        filterState={filterState}
                        isMasterAdmin={globalState.isAdmin}
                        showFgasFilter={true}
                        roles={[
                            {
                                key: Roles.InstallerCompanyAdmin,
                                value: Roles.InstallerCompanyAdmin,
                                label: t("common:roles.MasterAdmin"),
                            },
                            {
                                key: Roles.InstallerCompanyUser,
                                value: Roles.InstallerCompanyUser,
                                label: t("common:roles.InstallerCompanyUser"),
                            },
                            {
                                key: Roles.InstallerCompanyTechnician,
                                value: Roles.InstallerCompanyTechnician,
                                label: t("common:roles.InstallerCompanyTechnician"),
                            },
                        ]}
                        dispatch={dispatch}
                    />
                )}
                paging={{
                    enablePaging: true,
                    pageCount: users.totalPages,
                    pageSize: users.pageSize,
                    onPageChange: pageChange,
                    totalRows: users.totalRows,
                    currentPageIndex: users.currentPage - 1,
                }}
                sorting={{
                    enableSorting: true,
                    onSortByChange: sortChange,
                    currentSortBy: currentSortBy,
                }}
                onSelect={onSelect}
                detailViewKey={detailViewKey}
                detailView={<UserDetailSideBar user={details} id={selectedUserId} />}
                detailViewIsOpened={detailViewIsOpened}
                closeSideBar={closeSideBar}
            />
        </>
    );
};

export default UserListing;
