import React, { useEffect, useMemo, useCallback } from "react";
import globals from "../../../globalVars";
import styled from "styled-components";
import ListingPage from "../../../components/data/ListingPage";
import useStateModule from "../../../core/state/useStateModule";
import { useTranslation } from "react-i18next";
import useImmerReducer from "../../../hooks/_shared/useImmerReducer";
import usePageChange from "../../../hooks/_shared/usePageChange";
import clientsListStateModule from "../../../core/state/clients/clientsListStateModule";
import InputElement from "../../../components/elements/form-elements/InputElement";
import useDebouncedCallback from "../../../hooks/_shared/useDebouncedCallback";
import { StyledArrow, StyledBack } from "./StyledSvg";

import { ScreenWrapper, ScreenHeader, ListTitle, HR } from "./StyledElements";

const actionTypes = {
    SET_PAGING: "SET_PAGING",
    SET_SORT: "SET_SORT",
    RESET_SORT: "RESET_SORT",
    SET_FILTERS: "SET_FILTERS",
    RESET_FILTERS: "RESET_FILTERS",
};

const SearchWrapper = styled.div`
    width: 85%;
    & * {
        margin: 0;
    }

    & div {
        padding: 0 !important;
    }
`;

const clientsReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.SET_PAGING:
            state.pageSize = action.payload.pageSize;
            state.page = action.payload.pageIndex + 1;
            break;
        case actionTypes.SET_SORT:
            state.orderColumn = action.payload[0].id;
            state.descending = action.payload[0].desc;
            break;
        case actionTypes.RESET_SORT:
            state.orderColumn = "";
            state.descending = true;
            break;
        case actionTypes.SET_NAME_FILTER:
            state.filter.name = action.payload;
            break;
        case actionTypes.RESET_NAME_FILTER:
            delete state.filter.name;
            break;
        default:
            return state;
    }
};

const ClientListing = (props) => {
    const { t } = useTranslation("screens");
    const { clients, isLoading, error, loadClients } = useStateModule(
        clientsListStateModule
    );

    const [clientsState, clientsDispatch] = useImmerReducer(clientsReducer, {
        filter: {},
        page: 1,
        pageSize: globals.maxListingItemsPerPage,
        orderColumn: "clientName",
        descending: false,
    });

    useEffect(() => {
        loadClients(clientsState);
        props.onSearch && props.onSearch();
    }, [loadClients, clientsState]);

    const columns = useMemo(() => {
        const columns = [
            {
                Header: t("screens:clients.name"),
                accessor: "clientName",
            },
            {
                Header: "",
                accessor: "clientId",
                className: "right-arrow",
                Cell: ({ cell: { value } }) => <StyledArrow />,
                disableSortBy: true,
            },
        ];
        return columns;
    }, [clientsState.filter, t]);

    const [nameChanged] = useDebouncedCallback((text) => {
        text
            ? clientsDispatch({
                  type: actionTypes.SET_NAME_FILTER,
                  payload: text,
              })
            : clientsDispatch({ type: actionTypes.RESET_NAME_FILTER });
    });

    const searchClient = (text) => {
        nameChanged(text);
        return [];
    };

    const onSelect = useCallback((row) => {
        props.onSelect && props.onSelect(row.original);
    }, []);

    const clientsPageChange = usePageChange(clientsDispatch, clientsState);

    const sortChange = (sortBy) => {
        sortBy.length > 0
            ? clientsDispatch({ type: actionTypes.SET_SORT, payload: sortBy })
            : clientsDispatch({ type: actionTypes.RESET_SORT });
    };

    const currentClientsSortBy = useMemo(() => {
        return [
            {
                id: clientsState.orderColumn,
                desc: clientsState.descending,
            },
        ];
    }, [clientsState.orderColumn, clientsState.descending]);

    return (
        <ScreenWrapper>
            <ScreenHeader>
                <StyledBack className="disabled" />
                <SearchWrapper>
                    <InputElement
                        pwa
                        name="search"
                        labelText=""
                        noLabel
                        type="search"
                        searchFn={searchClient}
                        wrapperVariant="flex-1"
                        placeholder={t("screens:clients.search")}
                    />
                </SearchWrapper>
            </ScreenHeader>
            <HR />
            <ListTitle>{t("screens:clients.text")}</ListTitle>
            <ListingPage
                pwa
                noHeader
                columns={columns}
                tableState={{
                    data: clients.data,
                    loading: isLoading,
                    error: error,
                }}
                paging={{
                    enablePaging: true,
                    pageCount: clients.totalPages,
                    pageSize: clients.pageSize,
                    onPageChange: clientsPageChange,
                    currentPageIndex: clients.currentPage - 1,
                }}
                sorting={{
                    enableSorting: true,
                    onSortByChange: sortChange,
                    currentSortBy: currentClientsSortBy,
                }}
                onSelect={onSelect}
                noDataText={t("screens:stockListing.notification")}
            />
        </ScreenWrapper>
    );
};

export default ClientListing;
