import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import RouteCreator from "../../../core/RouteCreator";
import { toast } from "react-toastify";
import ListingPage from "../../../components/data/ListingPage";
import GreenCell from "../../../components/data/GreenCell";
import useBreadcrumbs from "../../../core/state/navigation/useBreadcrumbs";
import TabLinks from "../../../components/elements/tabs/TabLinks";
import useSharedState from "../../../hooks/_shared/useSharedState";
import useReclaimedMarketFilterState from "./components/ReclaimedMarketFilterState";
import ReclaimedMarketplaceFilter from "./components/ReclaimedMarketFilter";
import AuthorizeComponent from "../../../core/AuthorizeComponent";
import Roles from "../../../core/enums/Roles";
import ModalWizard from "../../../components/modal/ModalWizard";
import MarketplaceService from "../../../services/BuyRefrigerant/MarketplaceService";
import DateCell from "../../../components/data/DateCell";
import HamburgerMenu from "../../../components/menu/HamburgerMenu";
import HamburgerMenuAction from "../../../components/menu/HamburgerMenuAction";
import ReclaimedMarketSidebar from "./components/ReclaimedMarketSidebar";
import WizardPayForOffer from "./components/wizard/WizardPayForOffer";

const tabItems = (t, isMasterAdmin) => {
    let items = [
        {
            name: t("navigation:marketplace"),
            path: isMasterAdmin ? RouteCreator.admin.reclaimedMarketplace() : RouteCreator.salesCenter.reclaimedMarketplace(),
        },
    ];
    if (!isMasterAdmin) {
        items.push({
            name: t("navigation:myPurchases"),
            path: RouteCreator.salesCenter.reclaimedMarketPurchases(),
        });
    }
    return items;
};

const ReclaimedMarketplace = (props) => {
    const { t, i18n } = useTranslation(["screens", "table"]);
    const currentLanguage = i18n.language;
    const currency = "EUR";
    const [globalState] = useSharedState("global");
    const [tabIndex, setTabIndex] = useState(0);
    const [
        filterState,
        filterDispatch,
        filterActions,
        sortChange,
        currentSortBy,
        pageChange,
        gasTypes,
    ] = useReclaimedMarketFilterState();
    const [detailViewIsOpened, setDetailViewIsOpened] = useState(false);
    const [selected, setSelected] = useState({ offer: null });

    const [tableState, setTableState] = useState({
        data: [],
        loading: false,
        error: null,
    });

    useBreadcrumbs([
        {
            text: t("navigation:salesCenter"),
        },
        {
            text: t("navigation:marketplace"),
        },
    ]);

    const getInstallationCompanyReclaimedOffers = async () => {
        const res =
            await MarketplaceService.getInstallationCompanyReclaimedOffers(
                filterState
            );
        setTableState({ data: res.data.data });
    };

    useEffect(() => {
        getInstallationCompanyReclaimedOffers();
    }, [filterState]);

    //purchase action
    const purchaseOffer = useCallback(
        async (offer) => {
            ModalWizard.show(
                WizardPayForOffer(
                    t,
                    currentLanguage,
                    currency,
                    offer.price,
                    `${t("navigation:reclaimedGas")}/${offer.quantity} KG`,
                    false,
                    t("navigation:reclaimedGas"),
                    t("forms:payForOffer.wizard.reclaimedDeposit"),
                    "",
                    offer.offererCountryId
                ),
                {
                    onSubmit: async (values) => {
                        const res = await MarketplaceService.acceptReclaimOffer(
                            offer.reclaimedOfferId,
                            values.paymentMethod
                        );
                        if (res && res.error) {
                            ModalWizard.submitFailed(t(res.error.message));
                        } else {
                            toast.success(
                                t(
                                    "actions:dialogs.payAClass.toastNotifications.success"
                                )
                            );
                            ModalWizard.hide();
                            getInstallationCompanyReclaimedOffers();
                        }
                    },
                    onCancel: () => {
                        ModalWizard.hidePrompt();
                    },
                }
            );
        },
        [filterState]
    );

    const columns = useMemo(() => {
        const tabColumns = [
            {
                Header: t("table:headers.gasType"),
                accessor: "type",
                Cell: GreenCell,
            },
            {
                Header: t("table:headers.gasQuantity"),
                accessor: "quantity",
                Cell: ({ cell: { row, value } }) =>
                    GreenCell({
                        cell: {
                            value: value.toFixed(2) + ` kg`,
                        },
                    }),
            },
            {
                Header: t("table:headers.grade"),
                accessor: "grade",
                Cell: ({ cell: { value } }) => t("filters:cylinders.reclaimed"),
            },
            {
                Header: t("table:headers.owner"),
                accessor: "seller",
            },
            {
                Header: t("forms:cylinder.price"),
                accessor: "price",
                Cell: ({ cell: { row, value } }) => {
                    const ppk = (value / row.original.quantity).toFixed(2);
                    return (
                        <span
                            title={`${ppk} EUR ${t(
                                "forms:cylinder.perKiloReclaimed"
                            )}`}
                        >
                            {`${value.toFixed(2)} EUR`}
                        </span>
                    );
                },
            },
        ];

        if (tabIndex === 0)
            tabColumns.push({
                Header: t("table:headers.published"),
                accessor: "offerDateTime",
                Cell: (params) => {
                    return DateCell(params);
                },
            });

        tabColumns.push({
            Header: "",
            accessor: "reclaimedOfferId",
            Cell: ({ cell: { value }, row: { original } }) => {
                return (
                    <AuthorizeComponent
                        roles={[
                            Roles.InstallerCompanyAdmin,
                            Roles.InstallerCompanyUser,
                        ]}
                    >
                        <HamburgerMenu>
                            <HamburgerMenuAction
                                disabled={
                                    original.status !== 0 ||
                                    !globalState.hasValidCompanyCertificate ||
                                    !globalState.hasCertificateRights
                                }
                                text={t("actions:purchase")}
                                onClick={async () => {
                                    purchaseOffer(original);
                                }}
                            />
                        </HamburgerMenu>
                    </AuthorizeComponent>
                );
            },
        });
        return tabColumns;
    }, [t, tabIndex]);

    const onSelect = useCallback(
        (row) => {
            const selectRow = async (offerId) => {
                const selectedOffer = tableState.data.filter(
                    (of) => of.reclaimedOfferId === offerId
                )[0];
                const res = await MarketplaceService.getReclaimedOfferById({
                    id: offerId,
                });
                setSelected({
                    details: res.data.data[0],
                    offer: selectedOffer,
                });
                setDetailViewIsOpened(true);
            };
            selectRow(row.original.reclaimedOfferId);
        },
        [tableState]
    );

    return (
        <>
            <Helmet>
                <title>{t("seo:marketplace.title")}</title>
            </Helmet>
            <TabLinks
                variant="shadowed"
                items={tabItems(t, globalState.isAdmin)}
                idx={0}
                style={{ marginTop: "0px", padding: "10px 0px 10px 40px" }}
            />
            <ListingPage
                columns={columns}
                tableState={tableState}
                sorting={{
                    enableSorting: true,
                    onSortByChange: sortChange,
                    currentSortBy: currentSortBy,
                }}
                paging={{
                    enablePaging: true,
                    pageCount: filterState.totalPages,
                    pageSize: filterState.pageSize,
                    onPageChange: pageChange,
                    currentPageIndex: filterState.page - 1,
                }}
                detailSideBarTitle={`${t("sidebar:title.offerId")}: ${
                    selected.offer?.offerNumber
                }`}
                detailView={
                    <ReclaimedMarketSidebar
                        details={selected.details}
                        offer={selected.offer}
                        isDistributor={globalState.isDistributor}
                        offerStatus={filterState.reclaimedOfferStatus}
                        purchaseAction={() => purchaseOffer(selected.offer)}
                    />
                }
                onSelect={onSelect}
                closeSideBar={() => setDetailViewIsOpened(false)}
                detailViewIsOpened={detailViewIsOpened}
                filterComponent={() => {
                    return (
                        <ReclaimedMarketplaceFilter
                            filterState={filterState.filter}
                            dispatch={filterDispatch}
                            actionTypes={filterActions}
                            gasTypes={gasTypes}
                        />
                    );
                }}
                showTitle={true}
                filterTitle={t("filters:reclaimedMarketPlace.filterTitle")}
                variants={{
                    headerViewVariant: "right-action",
                }}
            />
        </>
    );
};

export default ReclaimedMarketplace;
