import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import LocationMarker from "../../../../../components/maps/components/LocationMarker";
import MapBounds from "../../../../../components/maps/components/MapBounds";
import DaikinMap from "../../../../../components/maps/DaikinMap";
import {
    Details,
    DetailSection,
    DetailsWrapper,
    SectionContent,
    SectionTitle,
    Title,
} from "./DetailsStyling";

const ClientDetails = ({ details, clientsLocations, currentLocationData }) => {
    const { t } = useTranslation("screens");

    const usedMapDetails = useMemo(() => {
        if (currentLocationData) {
            return currentLocationData;
        }
        return details;
    }, [details, currentLocationData]);

    const boundsMarkers = useMemo(() => {
        let markers = [];
        if (clientsLocations) {
            markers = clientsLocations.map((loc) => {
                if (!loc.latitude) {
                    return new window.google.maps.Marker({
                        position: {
                            lat: 0,
                            lng: 0,
                        },
                    });
                }
                return new window.google.maps.Marker({
                    position: {
                        lat: loc.latitude,
                        lng: loc.longitude,
                    },
                });
            });
        }
        if (!clientsLocations && usedMapDetails) {
            if (!usedMapDetails.latitude) {
                markers.push(
                    new window.google.maps.Marker({
                        position: {
                            lat: 0,
                            lng: 0,
                        },
                    })
                );
            } else {
                markers.push(
                    new window.google.maps.Marker({
                        position: {
                            lat: usedMapDetails.latitude,
                            lng: usedMapDetails.longitude,
                        },
                    })
                );
            }
        }
        return markers;
    }, [clientsLocations, usedMapDetails]);

    if (details === null) {
        return (
            <>
                <DetailsWrapper style={{ paddingTop: "30px" }}>
                    <Details className="clients" />
                </DetailsWrapper>
            </>
        );
    }

    return (
        <>
            <Title>{details.clientName}</Title>
            <DetailsWrapper>
                <Details className="clients">
                    <DetailSection>
                        <SectionTitle>
                            {t("clients.details.address")}
                        </SectionTitle>

                        <SectionContent>
                            {details.street ? (
                                <>
                                    {details.street}
                                    <br />
                                </>
                            ) : (
                                    <>
                                        -
                                    <br />
                                    </>
                                )}
                            {details.postalCode || details.city ? (
                                <>
                                    {details.postalCode} {details.city}
                                    <br />
                                </>
                            ) : (
                                    <>
                                        -
                                    <br />
                                    </>
                                )}
                            {details.countryName ? details.countryName : "-"}
                        </SectionContent>
                    </DetailSection>

                    <DetailSection>
                        <SectionTitle>
                            {t("clients.details.contact")}
                        </SectionTitle>

                        <SectionContent>
                            {details.contactPersonFirstName ||
                                details.contactPersonLastName ? (
                                    <>
                                        {details.contactPersonFirstName}{" "}
                                        {details.contactPersonLastName}
                                        <br />
                                    </>
                                ) : (
                                    <>
                                        -
                                    <br />
                                    </>
                                )}

                            {details.email ? (
                                <>
                                    {details.email}
                                    <br />
                                </>
                            ) : (
                                    <>
                                        -
                                    <br />
                                    </>
                                )}

                            {details.phoneNumber ? (
                                <>
                                    {details.phoneNumber}
                                    <br />
                                </>
                            ) : (
                                    <>
                                        -
                                    <br />
                                    </>
                                )}
                        </SectionContent>
                    </DetailSection>

                </Details>

                {currentLocationData && (
                    <Details className="locations">
                        <DetailSection>
                            <SectionTitle>
                                {t("clients.details.locationAddress", {
                                    loc: currentLocationData.locationName
                                        ? currentLocationData.locationName
                                        : currentLocationData.street,
                                })}
                            </SectionTitle>
                            <SectionContent>
                                {currentLocationData.street ? (
                                    <>
                                        {currentLocationData.street}
                                        <br />
                                    </>
                                ) : (
                                        <>
                                            -
                                        <br />
                                        </>
                                    )}

                                {currentLocationData.postalCode ||
                                    currentLocationData.city ? (
                                        <>
                                            {currentLocationData.postalCode}{" "}
                                            {currentLocationData.city}
                                            <br />
                                        </>
                                    ) : (
                                        <>
                                            -
                                        <br />
                                        </>
                                    )}

                                {currentLocationData.countryName &&
                                    currentLocationData.countryName}
                            </SectionContent>
                        </DetailSection>
                        <DetailSection>
                            <SectionTitle>
                                {t("clients.details.locationContact")}
                            </SectionTitle>
                            <SectionContent>
                                {currentLocationData.contactPersonFirstName ||
                                    currentLocationData.contactPersonLastName ? (
                                        <>
                                            {
                                                currentLocationData.contactPersonFirstName
                                            }{" "}
                                            {
                                                currentLocationData.contactPersonLastName
                                            }
                                            <br />
                                        </>
                                    ) : (
                                        <>
                                            -
                                        <br />
                                        </>
                                    )}

                                {currentLocationData.email ? (
                                    <>
                                        {currentLocationData.email}
                                        <br />
                                    </>
                                ) : (
                                        <>
                                            -
                                        <br />
                                        </>
                                    )}

                                {currentLocationData.phoneNumber
                                    ? currentLocationData.phoneNumber
                                    : "-"}
                            </SectionContent>
                        </DetailSection>
                    </Details>
                )}

                <Details className="map">
                    <DaikinMap>
                        {clientsLocations && (
                            <>
                                {clientsLocations.map((loc) => {
                                    return (
                                        <LocationMarker
                                            key={loc.locationId}
                                            location={loc}
                                        />
                                    );
                                })}
                            </>
                        )}
                        {!clientsLocations && usedMapDetails && (
                            <LocationMarker location={usedMapDetails} />
                        )}
                        <MapBounds markers={boundsMarkers} />
                    </DaikinMap>
                </Details>
            </DetailsWrapper>
        </>
    );
};

export default ClientDetails;
