import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import SidebarField from "../../../../../components/elements/sidebar/SidebarField";
import SidebarLicence from "../../../../../components/elements/sidebar/SidebarLicence";
import SidebarUsers from "../../../../../components/elements/sidebar/SidebarUsers";
import AccordionWrapper from "../../../../../components/toggle-view/AccordionWrapper";
import useAccordionGroup from "../../../../../hooks/_shared/useAccordionGroup";

const Section = styled.section`
    width: 100%;
    margin: 10px 0 10px 0;
    padding-bottom: 20px;
    border-bottom: 1px solid #eaeaea;

    &:last-child {
        border-bottom: none;
    }
`;

const Title = styled.div`
    font-size: 16px;
    color: #7f7f7f;
    font-weight: 600;
    margin: 10px 0 0 20px;
`;
const getDateFormat = (currentLanguage) =>
    Intl.DateTimeFormat(currentLanguage, {
        day: "numeric",
        month: "numeric",
        year: "numeric",
    });

const GetShortDate = (dateValue) => {
    if (dateValue && typeof dateValue === "string")
        dateValue = new Date(dateValue);
    const currentLanguage = "sk";
    return getDateFormat(currentLanguage).format(dateValue);
};

const CompanyDetailSideBar = ({ company }) => {
    const { companyData } = company;
    const {
        companyName,
        vatNumber,
        iban,
        userStatus,
        address,
        city,
        postalCode,
        region,
        countryName,
        email,
        phoneNumber,
        contactPersonFirstName,
        contactPersonLastName,
        licences = [],
        companyUsers = [],
        activatedByUser,
        activationDate,
        id,
        registrationCompletedDate,
    } = company.companyData;
    const { t } = useTranslation("sidebar");
    const [openAccordion, setOpenAccordion] = useAccordionGroup();
    if (company.isLoading || !companyData) return null;

    const statusString = {
        PendingConfirmation: t("common:companyActivation.PendingConfirmation"),
        FullyRegistered: t("common:companyActivation.FullyRegistered"),
        ConfirmationSent: t("screens:statistics.approvedCompanies"),
    };

    const compLicences = licences
        .map((lic) => ({
            number: lic.licenceNumber,
            appDate: lic.approvalDate
                ? new Date(lic.approvalDate)
                : new Date("1900/1/1"),
            approver: lic.approver || "-",
        }))
        .sort((a, b) => b.appDate - a.appDate);
    const minValidDate = new Date("2000/1/1");

    const userLicences = [];
    companyUsers.forEach((user) => {
        if (user.licences) {
            const uLicences = user.licences.sort((a, b) => {
                const aDate = a.approvalDate
                    ? new Date(a.approvalDate)
                    : new Date("1900/1/1");
                const bDate = b.approvalDate
                    ? new Date(b.approvalDate)
                    : new Date("1900/1/1");
                return bDate - aDate;
            });
            uLicences.forEach((lic) =>
                userLicences.push({
                    name: user.fullName,
                    licNum: lic.licenceNumber,
                    approver: lic.approver,
                    approvalDate: new Date(lic.approvalDate),
                })
            );
        }
    });

    const userApprovals = () => {
        return userLicences.map((lic, idx) => (
            <Section key={idx}>
                <Title
                    dangerouslySetInnerHTML={{
                        __html: `${t(
                            "screens:certificates.newCertWizard.titlePersonal"
                        )}<br>(${lic.name})`,
                    }}
                />
                <SidebarField
                    label={t("screens:certificates.tableHeaders.certNumber")}
                    value={lic.licNum}
                />
                <SidebarField
                    label={t("label.approvalDate")}
                    value={
                        lic.approvalDate > minValidDate
                            ? GetShortDate(lic.approvalDate)
                            : "-"
                    }
                />
                <SidebarField
                    label={t("label.approver")}
                    value={lic.approver}
                />
            </Section>
        ));
    };

    const compLicenceApprovals = () => {
        return compLicences.map((lic, idx) => (
            <Section key={idx}>
                <Title>{t("screens:certificates.companyCert")}</Title>
                <SidebarField
                    label={t("screens:certificates.tableHeaders.certNumber")}
                    value={lic.number}
                />
                <SidebarField
                    label={t("label.approvalDate")}
                    value={
                        lic.appDate > minValidDate
                            ? GetShortDate(lic.appDate)
                            : "-"
                    }
                />
                <SidebarField
                    label={t("label.approver")}
                    value={lic.approver}
                />
            </Section>
        ));
    };

    return (
        <>
            {!company.isLoading && companyData && (
                <>
                    <AccordionWrapper
                        title={t("title.company")}
                        id="company"
                        isOpen={openAccordion}
                        onToggle={setOpenAccordion}
                    >
                        <SidebarField
                            label={t("label.companyName")}
                            value={companyName}
                        />
                        <SidebarField
                            label={t("label.companyVAT")}
                            value={vatNumber}
                        />
                        <SidebarField
                            label={t("label.companyIBAN")}
                            value={iban}
                        />
                        <SidebarField
                            label={t("label.registrationStatus")}
                            value={statusString[userStatus] || "N/A"}
                        />
                        <SidebarField
                            label={t("label.registrationDate")}
                            value={
                                registrationCompletedDate
                                    ? GetShortDate(registrationCompletedDate)
                                    : "N/A"
                            }
                        />
                    </AccordionWrapper>

                    <AccordionWrapper
                        title={t("title.companyAddress")}
                        id="companyAddress"
                        isOpen={openAccordion}
                        onToggle={setOpenAccordion}
                    >
                        <SidebarField
                            label={t("label.companyStreet")}
                            value={address}
                        />
                        <SidebarField
                            label={t("label.companyCity")}
                            value={city}
                        />
                        <SidebarField
                            label={t("label.companyZIP")}
                            value={postalCode}
                        />
                        <SidebarField
                            label={t("label.companyState")}
                            value={region}
                        />
                        <SidebarField
                            label={t("label.companyCountry")}
                            value={countryName}
                        />
                    </AccordionWrapper>

                    <AccordionWrapper
                        title={t("title.companyContact")}
                        id="companyContact"
                        isOpen={openAccordion}
                        onToggle={setOpenAccordion}
                    >
                        <SidebarField
                            label={t("label.companyEmail")}
                            value={email}
                        />
                        <SidebarField
                            label={t("label.phoneNumber")}
                            value={phoneNumber}
                        />
                        <SidebarField
                            label={t("label.companyContactPersonName")}
                            value={
                                contactPersonFirstName +
                                " " +
                                contactPersonLastName
                            }
                        />
                    </AccordionWrapper>

                    <AccordionWrapper
                        title={t("title.licences")}
                        id="licences"
                        isOpen={openAccordion}
                        onToggle={setOpenAccordion}
                    >
                        <SidebarLicence licence={licences} companyId={id} />
                    </AccordionWrapper>

                    <AccordionWrapper
                        title={t("label.approvals")}
                        id="approvals"
                        isOpen={openAccordion}
                        onToggle={setOpenAccordion}
                    >
                        <Section>
                            <Title>{t("label.registrationApproval")}</Title>
                            <SidebarField
                                label={t("label.approvalDate")}
                                value={GetShortDate(activationDate)}
                            />
                            <SidebarField
                                label={t("label.approver")}
                                value={activatedByUser}
                            />
                        </Section>

                        {compLicenceApprovals()}
                        {userApprovals()}
                    </AccordionWrapper>

                    <AccordionWrapper
                        title={t("title.companyEmployees")}
                        id="companyEmployees"
                        isOpen={openAccordion}
                        onToggle={setOpenAccordion}
                    >
                        <SidebarUsers users={companyUsers} />
                    </AccordionWrapper>
                </>
            )}
        </>
    );
};

export default CompanyDetailSideBar;
