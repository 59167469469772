import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useStateModule from "../useStateModule";
import breadcrumbsStateModule from "./breadcrumbsStateModule";

const useBreadcrumbs = (breadcrumbItems) => {
    const { i18n } = useTranslation();
    const {items, setBreadcrumbs} = useStateModule(breadcrumbsStateModule);
    const [newItems, setNewItems] = useState(breadcrumbItems);

    const memoItems = useMemo(() => {
        return newItems;
    }, [i18n.language, newItems]);

    useEffect(() => {
        if (memoItems) {
            setBreadcrumbs(memoItems);
        }
        return () => setBreadcrumbs([]);
    }, [setBreadcrumbs, memoItems]);

    return {items, update:setNewItems};
}

export default useBreadcrumbs;
