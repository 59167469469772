import ApiRouteError from './ApiRouteError';

export default class ApiRouteCustomError extends ApiRouteError {
    constructor(data) {
        data.messages = data.messages || [];
        super(data.messages.join(','));
        this.name = 'ApiRouteCustomError';

        this.messages = data.messages;
        this.localizationKey = data.localizationKey;
        this.stackTrace = data.stackTrace;
        this.key = data.key;
        this.name = data.name;
        this.type = data.type;
        this.redirectUrl = data.redirectUrl;
    }

    getMessages = () => {
        let ret = [];

        if (this.localizationKey === 'exception.ValidationException') {
            this.messages.forEach(message => {
                let fieldError = message.split(':')[0];
                let exceptions = message.split(':')[1];
                let multipleExceptions = exceptions.split('|');

                multipleExceptions.forEach(ex => {

                    ret.push(
                        {
                            fieldError,
                            localizationKey: 'exceptions:' + ex.trim(),
                        }
                    );
                });
            });

        } else {
            ret = [
                {
                    fieldError: null,
                    localizationKey: 'exceptions:' + this.localizationKey,
                    message: this.messages[0],
                    redirectUrl: this.redirectUrl
                }
            ];
        }


        return ret;
    }

}
