import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useLoadScript } from "@react-google-maps/api";
import { useTranslation } from "react-i18next";
import ClientsService from "../../../services/Clients/ClientsService";
import StockService from "../../../services/Stock/StockService";
import QRCode from "qrcode.react";
import LocationMarker from "../../../components/maps/components/LocationMarker";
import DaikinMap from "../../../components/maps/DaikinMap";
import ModalDialog, { useModal } from "../../../components/modal/ModalDialog";
import ModalWizard from "../../../components/modal/ModalWizard";
import EditUnitWizard from "../actions/components/EditUnitWizard";
import usePromises from "../../../hooks/_shared/usePromises";
import { StyledBack, SvgNoImage, SvgEdit, SvgDelete } from "./StyledSvg";
import {
    ScreenWrapper,
    ScreenHeader,
    ListTitle,
    HR,
    Box16A9,
    VSpace,
} from "./StyledElements";
import { StyledQR } from "../../Equipment/components/StyledQR";
import { TagTitle } from "../../Equipment/components/TagTitle";

const Content = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    flex-grow: 1;
    position: relative;
    padding: 35px 60px 0px;
    display: flex;
    flex-direction: column;
    padding: 10px 24px 0px 24px;
`;

const Section = styled.div`
    padding: 12px 20px;
`;

const KeyVal = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    color: #000;
    font-weight: 700;
    margin-bottom: 6px;
    & span {
        font-weight: 500;
        color: ${(p) => p.theme.primaryColor};
    }
`;

const HeaderActions = styled.div`
    & svg + svg {
        margin-left: 18px;
    }
`;

const splitTag = (x) => [`${x.slice(0, 4)} ${x.slice(4, 8)}`, x.slice(9)];

const UnitDetails = ({ location, unitId, ...restProps }) => {
    const { t } = useTranslation("screens");
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyBQSju7sxgnuD4jrzA2cJLP2dVU3RDwHZY", // ,
        // ...otherOptions
    });

    const [unit, setUnit] = useState();
    const [reload, setReload] = useState(1);

    useEffect(() => {
        async function getdata() {
            if (unitId) {
                const result = await ClientsService.getUnitByIdAsync(unitId);
                if (result && result.error) {
                    toast.error(t(result.error.message));
                } else {
                    setUnit(result.data.data[0]);
                }
            } else setUnit(null);
        }
        getdata();
    }, [unitId, reload]);

    const {
        modalProps: modalPropsDelete,
        toggleOpen: toggleOpenDelete,
    } = useModal({
        title: t("actions:dialogs.deleteUnit.title"),
        onConfirm: async (unitId) => {
            let res = await ClientsService.deleteClientLocationUnitAsync(
                unitId
            );

            if (res && res.error) {
                toast.error(t(res.error.message));
            } else {
                toast.success(t("actions:dialogs.deleteUnit.success"));
                toggleOpenDelete();
                restProps.onGotoStart();
            }
        },
        onCancel: () => {
            toggleOpenDelete();
        },
        confirmText: t("actions:dialogs.deleteUnit.confirmText"),
        cancelFirst: true,
    });
    const wizardData = usePromises(
        [
            () => ClientsService.getUnitManufacturersAsync(),
            () => StockService.getRefrigerantTypesAsync(),
        ],
        (res) => ({
            manufacturers: res[0].data.data,
            refrigerants: res[1].data.data,
        })
    );

    if (!location || !unit) return <ScreenWrapper />;
    const tagParts = unit.tagId ? splitTag(unit.tagId) : [];

    const showUnitEdit = () => {
        const initialValues = {
            manufacturerId: unit.manufacturerId,
            applicationType: unit.applicationType,
            refrigerantId: unit.refrigerantId,
            capacity: unit.capacity,
            images: unit.images,
            description: unit.description,
            tagId: unit.tagId
        };
        ModalWizard.show(
            EditUnitWizard(
                t,
                initialValues,
                wizardData.manufacturers,
                wizardData.refrigerants
            ),
            {
                onSubmit: async (values) => {
                    const newImages = values.images.filter(
                        (img) => img instanceof File
                    );

                    const oldImages = values.images.filter(
                        (img) => !(img instanceof File)
                    );

                    const toDelete = unit.images
                        .filter(
                            (img) =>
                                !oldImages.some(
                                    (oldImg) => img.id === oldImg.id
                                )
                        )
                        .map((img) => img.id);

                    values.images = newImages;
                    values.deleteImages = toDelete;
                    values.unitId = unitId;

                    const result = await ClientsService.updateUnitAsync(
                        ModalWizard.asFormData(values)
                    );
                    if (result && result.error)
                        ModalWizard.submitFailed(t(result.error.message));
                    else {
                        toast.success(t("actions:dialogs.editUnit.success"));
                        ModalWizard.hide();
                        setReload(s=>s+1);
                    }
                },
                onCancel: async () => {
                    ModalWizard.hidePrompt();
                },
            },
            { pwa: true }
        );
    };

    return (
        isLoaded && (
            <ScreenWrapper>
                <ScreenHeader>
                    <StyledBack
                        onClick={(ev) => {
                            restProps.onGoBack(ev);
                        }}
                    />
                    <HeaderActions>
                        <SvgDelete
                            onClick={() => toggleOpenDelete(unit.unitId)}
                        />
                        <SvgEdit onClick={()=>showUnitEdit()} />
                    </HeaderActions>
                </ScreenHeader>
                <HR />
                <ListTitle>{t("screens:technician.qrTag")}</ListTitle>
                <Content>
                    <StyledQR>
                        <QRCode value={unit.tagId} size={106} />
                        <TagTitle>{tagParts[0]}</TagTitle>
                        <span>{tagParts[1]}</span>
                    </StyledQR>

                    <Section>
                        <KeyVal>
                            {t("screens:technician.manufacturer")}
                            <span>{unit.manufacturerName}</span>
                        </KeyVal>
                        <KeyVal>
                            {t("screens:technician.application")}
                            <span>{unit.applicationTypeName}</span>
                        </KeyVal>
                        <KeyVal>
                            {t("screens:technician.installedGas")}
                            <span>{unit.refrigerantTypeCode}</span>
                        </KeyVal>
                        <KeyVal>
                            {t("screens:technician.capacity")}
                            <span>{unit.capacity} kW</span>
                        </KeyVal>
                        <HR className="content" />
                    <ListTitle className="content">
                        {t("screens:technician.gallery")}
                    </ListTitle>
                        <VSpace size="20px" />
                        <Box16A9>
                            {unit.images.length ? (
                                <img
                                    className="thumb"
                                    alt="unit"
                                    src={unit.images[0].imageThumbnailUrl}
                                />
                            ) : (
                                <SvgNoImage />
                            )}
                        </Box16A9>
                    </Section>
                    <HR className="content" />
                    <ListTitle className="content">
                        {t("screens:technician.notes")}
                    </ListTitle>
                    <Section>
                        <span>{unit.description ? unit.description:"-"}</span>
                    </Section>
                    <HR className="content" />
                    <ListTitle className="content">
                        {t("screens:technician.installationSite")}
                    </ListTitle>
                    <Section>
                        <KeyVal>
                            {t("screens:technician.company")}
                            <span>{unit.client.clientName}</span>
                        </KeyVal>
                        <KeyVal>
                            {t("screens:technician.street")}
                            <span>{location.address}</span>
                        </KeyVal>
                        <KeyVal>
                            {t("screens:technician.city")}
                            <span>{location.city}</span>
                        </KeyVal>
                        <KeyVal>
                            {t("screens:technician.zip")}
                            <span>{location.postalCode}</span>
                        </KeyVal>
                        <KeyVal>
                            {t("screens:technician.country")}
                            <span>{location.country}</span>
                        </KeyVal>
                        <VSpace size="20px" />
                        <DaikinMap className="pwa mt-20">
                            <LocationMarker location={location} withBounds />
                        </DaikinMap>
                        <VSpace size="20px" />
                    </Section>
                </Content>
                <ModalDialog {...modalPropsDelete}>
                    <p>{t("actions:dialogs.deleteUnit.text")}</p>
                    <StyledQR className="modal">
                        <TagTitle>{tagParts[0]}</TagTitle>
                        <span>{tagParts[1]}</span>
                    </StyledQR>
                </ModalDialog>
            </ScreenWrapper>
        )
    );
};

export default UnitDetails;
