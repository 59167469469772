import React from "react";
import TooltipContainer from "./TooltipContainer";
import TooltipItemList from "./TooltipItemList";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const ValueList = styled.ul`
    list-style: none;
    padding: 0;
`;

const ValueElement = styled.li`
    width: 100%;
    padding: 2px; 
`;

const LabelContainer = styled.div`
   font-weight: bold;
   padding: 6px;
`;

const Total = styled.div`
    padding-top: 12px;
    font-weight: 600;
`;

const ViewDetails = styled.div`
    color:${p => p.theme.secondaryColor};
    font-weight: 100;
    font-size: 12px;
    font-style: italic;
    text-align: right;
`;

const ListItems = ({ items, unit, isInt, value, top }) => {
    const { t } = useTranslation();
    return (
        <>

            <TooltipItemList
                items={top ? items.slice(0, top - 1) : items}
                isInt={isInt}
                unit={unit}
            >

            </TooltipItemList>
            <Total>

                {
                    `${t("screens:dashboard.tooltip.total")}: ${isInt ? value : value.toFixed(2)} ${unit ? unit : ""}`
                }
            </Total>
        </>
    )
}


const SummaryLine = ({ item, unit, isInt, payload }) => {
    return (
        <>

            <div>
                {
                    item.name && payload.length > 1 ? `${item.name}: ` : null
                }

                {`${isInt ? item.value : item.value.toFixed(2)} ${unit ? unit : ""}`}
            </div>
        </>
    )
}


const CustomTooltip = ({ active, payload, label, unit, colors, isInt, showDrillDown }) => {
    const { t } = useTranslation();
    if (active && payload && payload.length) {
        return (
            <>
                <TooltipContainer >
                    {
                        label && (<LabelContainer>
                            {`${label}: `}
                        </LabelContainer>)
                    }
                    <ValueList>
                        {payload ? payload.map((item, index) => (

                            <ValueElement key={index} style={{ color: colors ? colors[index] : null }}>

                                {
                                    item.payload && item.payload.items && item.payload.items.length > 0 && showDrillDown ?

                                        <ListItems
                                            unit={unit}
                                            isInt={isInt}
                                            items={item.payload.items}
                                            value={item.value}
                                            top={null}
                                        />
                                        :
                                        <SummaryLine
                                            unit={unit}
                                            isInt={isInt}
                                            item={item}
                                            payload={payload}
                                        />
                                }
                            </ValueElement>

                        )) : null}
                    </ValueList>
                    {
                        showDrillDown &&
                        <ViewDetails>

                            <span>{t("screens:dashboard.tooltip.clickMoreDetails")}
                            </span>
                        </ViewDetails>
                    }

                </TooltipContainer>

            </>
        );
    }

    return null;
};

export default CustomTooltip;