import React from "react";
import { useTranslation } from "react-i18next";
import DataTable from "../../../../../components/data/DataTable";
import Anchor from "../../../../../components/elements/form-elements/Link";
import SidebarField from "../../../../../components/elements/sidebar/SidebarField";
import AccordionWrapper from "../../../../../components/toggle-view/AccordionWrapper";
import RouteCreator from "../../../../../core/RouteCreator";
import useAccordionGroup from "../../../../../hooks/_shared/useAccordionGroup";

const ClientDetailSideBar = ({ client, locations }) => {
    const { t } = useTranslation("sidebar");
    const [openAccordion, setOpenAccordion] = useAccordionGroup();
    const locationColumns = [
        {
            Header: t("table:headers.client.name"),
            accessor: "locationName",
        },
        {
            Header: t("table:headers.client.address"),
            accessor: "address",
        },
    ];

    const topTenLocations = locations.isCompleted
        ? locations.data.data.slice(0, 10)
        : [];

    return (
        <>
            <AccordionWrapper
                title={t("title.client.information")}
                id="client.information"
                isOpen={openAccordion}
                onToggle={setOpenAccordion}
            >
                <SidebarField
                    label={t("label.client.name")}
                    value={client.clientsData.clientName}
                />
            </AccordionWrapper>

            {locations && locations.isCompleted && (
                <AccordionWrapper
                    title={t("title.client.topLocations", { N: "10" })}
                    id="topLocations"
                    isOpen={openAccordion}
                    onToggle={setOpenAccordion}
                >
                    <DataTable
                        data={topTenLocations}
                        columns={locationColumns}
                    />

                    <Anchor
                        className="primary"
                        to={RouteCreator.clients.locations(
                            client.clientsData.clientId
                        )}
                    >
                        {t("button.clients.locations")}
                    </Anchor>
                </AccordionWrapper>
            )}
        </>
    );
};

export default ClientDetailSideBar;
