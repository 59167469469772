import styled from "styled-components";
import DashboardTheme from "./DashboardTheme";

const ListWrapper = styled.ul`
    list-style: none;
    padding: 0px;
    border-top:2px solid ${DashboardTheme.borderColor};
    margin: 0px;
  
`;

const TitleContainer = styled.div`
    display: inline-block;
    font-size: 20px;
    padding: 24px;
    width:100%;
`;

const Title = styled.div`
    font-size: 20px;
    font-weight: 500;
    display: inline;
    float: left;
`;

const CountWrapper = styled.div`
    float: right;
    display: inline-block;
    position: relative;
    color: ${props => props.theme.primaryColor};
`;

const ColumnContainer = styled.div`
    border-radius: 16px;
    background-color: white;
    box-shadow: 0 0 20px rgb(0 0 0 / 8%);
`;

const Item = styled.li`
    border-bottom: solid 1px #ECEDEF;
    font-size:12px; 


    &.selected {
        background-color: #E9F2ED;
        font-size: 16px;
        font-weight: bold;
    }

    .parent {
        padding: 14px 24px;
        cursor: pointer;
        
        span {
            color: black;
            font-weight: 500;
            
        }
    } 

    .content {
        padding: 10px 40px;

         a, span {
            color:${p => p.theme.secondaryColor};
            text-decoration: none;
            
            &:hover {
                color: ${(p) => p.theme.primaryColorHover};
            }
        }
    } 
`;

const Content = styled.div`
    padding: 14px 40px;
`;

const Detail = styled.div`
    display: inline;
    vertical-align: middle;
    
    a {
        padding-left: 12px;
        text-decoration: none;  
    }

     span {
        color: black;
        font-weight: 500;
        
    }
`;


const Metric = styled.span`
    float: right;

`;

const Menu = {
    ListWrapper: ListWrapper,
    TitleContainer: TitleContainer,
    ColumnContainer: ColumnContainer,
    Item: Item,
    Content: Content,
    Detail: Detail,
    Metric: Metric,
    Title: Title,
    CountWrapper: CountWrapper
};

export default Menu;