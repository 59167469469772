import { config } from "../Config/config.prod";
import api, { apiResult } from "../getAxios";

const CompanyService = {
    getCompanyData: async (companyID) => {
        let res = await api.get(config.apiUrl.getCompanyData + "/" + companyID);
        return res.data.data[0];
    },
    getCompanyDataAsync: async (companyID) => {
        return apiResult(api.get(config.apiUrl.getCompanyData + "/" + companyID));
    },
    setCompanyDataAsync: (formData) => {
        return apiResult(api.post(config.apiUrl.companyUpdate, formData));
    },
    setCompanyData: (formData) => {
        return api.post(config.apiUrl.companyUpdate, formData);
    },
    updateCompanyProfile: (formData) => {
        return api.post(config.apiUrl.updateCompanyProfile, formData);
    },
    getCompanyByUserID: async (values) => {
        let res = await api.get(config.apiUrl.getCompanyDataByUserID + "/" + values);
        return res.data;
    },
    setCompanyCertificate: async (formData) => {
        let res = await api.post(config.apiUrl.setCompanyCertificate, formData);
        return res;
    },
    uploadCompanyCertificate: async (formData) => {
        return apiResult(api.post(config.apiUrl.setCompanyCertificate, formData));
    },
    getCompaniesWithAdmin: async (filters = {}) => {
        return (await api.post(config.apiUrl.getCompaniesWithAdmin, filters)).data;
    },
    getCompanyEquipment: async () => {
        return (await api.get(config.apiUrl.getCompanyEquipment)).data;
    },
    getCompanyCylinders: async (req = {}) => {
        return (await api.post(config.apiUrl.getCompanyCylinders, req)).data;
    },
    getCompanyCylinderById: async (cylinderId) => {
        let res = await api.get(config.apiUrl.getCompanyCylinderById + "/" + cylinderId);
        return res.data;
    },
    getCompanyCylinderByIdAsync: async (cylinderId, skipCompanyCheck = false) => {
        return apiResult(
            api.get(
                config.apiUrl.getCompanyCylinderById +
                    "/" +
                    cylinderId +
                    `?skipCompanyCheck=${skipCompanyCheck}`
            )
        );
    },
    getCompanyAnalyzers: async (req = {}) => {
        return (await api.post(config.apiUrl.getCompanyAnalyzers, req)).data;
    },
    getCompanyEquipmentById: async (equipmentId) => {
        let res = await api.get(config.apiUrl.getCompanyEquipmentById + "/" + equipmentId);
        return res.data;
    },
    getCompanyEquipmentByIdAsync: async (equipmentId) => {
        return api.get(config.apiUrl.getCompanyEquipmentById + "/" + equipmentId);
    },
    createCompanyEquipment: (formData) => {
        return api.post(config.apiUrl.createCompanyEquipment, formData);
    },
    createCompanyCylinder: (formData) => {
        return api.post(config.apiUrl.createCompanyCylinder, formData);
    },
    createCompanyCylinderAsync: async (formData) => {
        return apiResult(api.post(config.apiUrl.createCompanyCylinder, formData));
    },
    createGasAnalyzerAsync: async (formData) => {
        return apiResult(api.post(config.apiUrl.createGasAnalyzer, formData));
    },
    updateGasAnalyzerAsync: async (formData) => {
        return apiResult(api.post(config.apiUrl.updateGasAnalyzer, formData));
    },
    deleteGasAnalyzerAsync: async ({ gasAnalyzerId, shouldDisconnectTag = false }) => {
        return apiResult(
            api.post(config.apiUrl.deleteGasAnalyzer, { gasAnalyzerId, shouldDisconnectTag })
        );
    },
    getMyGasAnalyzerByIdAsync: async (equipmentId) => {
        return api.get(config.apiUrl.getMyGasAnalyzerById + "/" + equipmentId);
    },
    getMyGasAnalyzerByTagAsync: async (equipmentId) => {
        return api.get(config.apiUrl.getMyGasAnalyzerByTag + "/" + equipmentId);
    },
    createCompanyEquipmentAsync: (formData) => {
        return apiResult(api.post(config.apiUrl.createCompanyEquipment, formData));
    },
    updateEquipment: (formData) => {
        return api.post(config.apiUrl.updateCompanyEquipment, formData);
    },
    updateEquipmentAsync: (formData) => {
        return apiResult(api.post(config.apiUrl.updateCompanyEquipment, formData));
    },
    getCompanyEmployees: async (filters) => {
        return (await api.post(config.apiUrl.getCompanyEmployees, filters)).data;
    },
    getCompanyEmployeeById: async (employeeId) => {
        let res = await api.get(config.apiUrl.getCompanyEmployeeById + "?userId=" + employeeId);
        return res.data;
    },
    createCompanyEmployee: (formData) => {
        return api.post(config.apiUrl.createCompanyEmployee, formData);
    },
    createCompanyEmployeeAsync: async (formData) => {
        return apiResult(api.post(config.apiUrl.createCompanyEmployee, formData));
    },
    updateCompanyEmployee: (values) => {
        return api.post(config.apiUrl.updateCompanyEmployee, values);
    },
    updateCompanyEmployeeCertificate: (formData) => {
        return api.post(config.apiUrl.setCompanyEmployeeCertificate, formData);
    },
    getCompanyRoles: async () => {
        return (await api.get(config.apiUrl.getCompanyRoles)).data.data;
    },
    deleteEquipment: async (id) => {
        return (await api.post(config.apiUrl.deleteEquipment, { equipmentId: id })).data.data;
    },
    deleteEmployee: async (id) => {
        return (await api.post(config.apiUrl.deleteUser, { userId: id })).data.data;
    },
    deleteCompany: async (id) => {
        return (await api.post(config.apiUrl.deleteCompany, { id: id })).data.data;
    },
    deleteCylinder: async (id) => {
        return (await api.post(config.apiUrl.deleteCylinder, { cylinderId: id })).data.data;
    },
    deleteCylinderAsync: async (id) => {
        return apiResult(api.post(config.apiUrl.deleteCylinder, { cylinderId: id }));
    },
    updateCylinderAsync: async (values) => {
        return apiResult(api.post(config.apiUrl.updateCylinder, values));
    },
    getNationalities: async (filter) => {
        if (!filter || filter.length < 3) return [];
        return apiResult(api.post(config.apiUrl.getNationalities, { filter }));
    },
    getDistributors: async () => {
        return apiResult(api.post(config.apiUrl.getDistributors, {}));
    },
    getMyEmployeesCertificates: async (userId = "") => {
        return apiResult(
            api.post(config.apiUrl.myEmployeesCertificates, {
                filter: { userId: userId },
                page: 0,
                pageSize: 100,
            })
        );
    },
    getMyCompanyCertificates: async (filter = {}) => {
        return apiResult(
            api.post(config.apiUrl.myCompanyCertificates, {
                filter: filter,
                page: 0,
                pageSize: 100,
            })
        );
    },
    addCompanyCertificate: async (formData) => {
        return apiResult(api.post(config.apiUrl.addCompanyCertificate, formData));
    },
    addUserCertificate: async (formData) => {
        return apiResult(api.post(config.apiUrl.addUserCertificate, formData));
    },
    getEmployeesCertificates: async (userId, companyId = "") => {
        return apiResult(
            api.post(config.apiUrl.employeesCertificates, {
                filter: { userId: userId, companyId: companyId },
                page: 0,
                pageSize: 100,
            })
        );
    },
    getCompanyCertificates: async (companyId) => {
        return apiResult(
            api.post(config.apiUrl.companyCertificates, {
                filter: { companyId: companyId },
                page: 0,
                pageSize: 100,
            })
        );
    },
    updateCertificateStatus: async (certId, status) => {
        return apiResult(
            api.post(config.apiUrl.updateCertificateStatus, {
                certificateId: certId,
                certificateStatus: status,
            })
        );
    },
};

export default CompanyService;
