import React from "react";
import styled from "styled-components";
import GasTypeItems from "./components/GasTypeItems";
import CompanyLookup from "./CompanyLookup";
import MenuColumnContainer from "./components/MenuColumnContainer";
import DashboardStyles from "../../components/dashboard/DashboardStyles";
import { useTranslation } from "react-i18next";

const TitleContainer = styled.div`
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
    padding: 24px 0px 24px 24px;
`;

const GasMenu = ({ items, colors, handleClick, selectedGasType, title, searchFn, companySelect }) => {

    const { t } = useTranslation("screens");
    return (

        <MenuColumnContainer style={{ height: "100%", position: "relative" }} >
            <DashboardStyles.NoDataWrapper
            className = {
                    `${items &&  items.length === 0
                                    ? "show"
                                    : ""
                                    }`
                } >
                < DashboardStyles.SvgNoData /> {
                    t("table:headers.noItems")
                } 
                </DashboardStyles.NoDataWrapper>
            {
                searchFn ?
                    <CompanyLookup
                        title={title}
                        searchFn={searchFn}
                        companySelect={companySelect}
                    >

                    </CompanyLookup>
                    :
                    <TitleContainer>
                        {title}
                    </TitleContainer>
            }

            <GasTypeItems data={items} colors={colors} handleClick={handleClick} selectedGasType={selectedGasType} />
        </MenuColumnContainer>

    )
};

export default GasMenu;
