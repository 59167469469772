import React, { useEffect, useRef, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import DataEntry from "../../../../components/data-entry/DataEntry";
import InputElement from "../../../../components/elements/form-elements/InputElement";
import Headings from "../../../../components/elements/headings/Headings";
import RoundTabs from "../../../../components/elements/tabs/RoundTabs";
import EffectError from "../../../../components/errors/EffectError";
import LayoutInnerContainer from "../../../../components/layout/split/one/LayoutInnerContainer";
import ModalDialog, { useModal } from "../../../../components/modal/ModalDialog";
import AccordionWrapper from "../../../../components/toggle-view/AccordionWrapper";
import AuthorizeComponent from "../../../../core/AuthorizeComponent";
import AuthorizeComponentByUserId from "../../../../core/AuthorizeComponentByUserId";
import Roles from "../../../../core/enums/Roles";
import helpers from "../../../../core/helpers";
import RouteCreator from "../../../../core/RouteCreator";
import employeeListStateModule from "../../../../core/state/company/employeeListStateModule";
import useActiveNavigationPath from "../../../../core/state/navigation/useActiveNavigationPath";
import useBreadcrumbs from "../../../../core/state/navigation/useBreadcrumbs";
import userStateModule from "../../../../core/state/user/userStateModule.js";
import useStateModule from "../../../../core/state/useStateModule";
import useAuthorization from "../../../../core/useAuthorization";
import commonValidation from "../../../../core/validations/common";
import ValidationRule, { buildValidation } from "../../../../core/validations/ValidationRule";
import globals from "../../../../globalVars";
import CompanyService from "../../../../services/Company/CompanyService";
import LoginService from "../../../../services/Login/LoginService";
import UserService from "../../../../services/User/UserService";
import Change2faSection from "./edit-employee/Change2faSection";
import ChangePasswordSection from "./edit-employee/ChangePasswordSection";
import ChangePictureSection from "./edit-employee/ChangePictureSection";
import EmployeeEditForm from "./EmployeeEditForm";
import { Helmet } from "react-helmet";
import DateCell from "../../../../components/data/DateCell";
import HamburgerMenu from "../../../../components/menu/HamburgerMenu";
import HamburgerMenuAction from "../../../../components/menu/HamburgerMenuAction";
import Button from "../../../../components/elements/buttons/Button";
import DataTable from "../../../../components/data/DataTable";
import ModalWizard from "../../../../components/modal/ModalWizard";
import AddCertificateWizard from "../company/wizard/AddCertificateWizard";
import useQueryString from "../../../../hooks/_shared/useQueryString";
import useAccordionGroup from "../../../../hooks/_shared/useAccordionGroup";

const ECertificateStatus = {
    Submitted: 0,
    Valid: 1,
    Rejected: 2,
    Expired: 99,
    NoAttestation: -1, //display only
};

const EmptyGuid = "00000000-0000-0000-0000-000000000000";

const TabContent = styled.div`
    display: ${(props) => (props.show ? "block" : "none")};
`;

const TabsWrapper = styled.div`
    position: fixed;
    top: 85px;
    width: 100%;
    z-index: 5;

    margin: 0px 0px 0px -65px;
    padding: 0px 60px 0px 65px;
    border-radius: 15px 15px 0px 0px;
    background: linear-gradient(180deg, rgba(245, 245, 245, 1) 0%, rgba(240, 240, 240, 1) 100%);
    box-shadow: -2px 9px 11px -16px rgba(0, 0, 0, 0.75);
    border-bottom: 1px solid #ffffff;
`;

const ContentWrapper = styled.div`
    display: block;
    @media ${(props) => props.theme.device.mobile} {
        padding-left: 16px;
        padding-right: 16px;
    }
`;

const InfoWrapper = styled.div`
    display: flex;
    width: 100%;
    color: #000;

    & .topSpace {
        margin-top: 14px;
    }

    & .info {
        font-size: 14px;
    }

    & li {
        font-size: 12px;
        line-height: 18px;
    }
`;

const GreenInfo = styled.img`
    width: 40px;
    height: 40px;
    margin-right: 24px;
`;

const ColoredText = styled.div`
    color: ${(p) => p.theme.primaryColor};

    &.error {
        color: ${(p) => p.theme.error};
    }
`;

const Separator = styled.div`
    width: 100%;
    height: 2px;
    background-color: #000;
    margin-top: 24px;
`;

const RightContainer = styled.div`
    display: flex;
    justify-content: flex-end;

    margin-top: 20px;
`;

const HolderCell = ({ cell: { row } }) => {
    const holder = row.original.certificateHolder;
    const status = row.original.licenceStatus;

    return (
        <ColoredText className={status !== ECertificateStatus.Valid ? "error" : ""}>
            {holder}
        </ColoredText>
    );
};

const StatusCell = ({ cell: { row } }) => {
    const text = row.original.licenceStatusName;
    const status = row.original.licenceStatus;

    return (
        <ColoredText className={status !== ECertificateStatus.Valid ? "error" : ""}>
            {text}
        </ColoredText>
    );
};

const defaultEditEmployeeValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    roleId: "-1",
    fGasCertificate: "",
    licenceNumber: "",
    licenceExpirationDate: "",
    username: "",
};

const tabIndices = {
    Profile: 0,
    Information: 1,
};

const validationEditEmployee = (t) => {
    return buildValidation({
        firstName: ValidationRule.isStringRule().required(t("forms:firstName.required")).rule,
        lastName: ValidationRule.isStringRule().required(t("forms:lastName.required")).rule,
        email: commonValidation.email(t).rule,
        roleId: ValidationRule.isStringRule().isNot("-1", t("forms:roleid.pleaseselect")).rule,
    });
};

const EmployeeEditScreen = () => {
    let initialTab = Number(useQueryString("tab", tabIndices.Profile));
    const { i18n, t } = useTranslation();
    const history = useHistory();
    useActiveNavigationPath(RouteCreator.employee.list());

    const { loadUserData } = useStateModule(userStateModule);
    const [tabIndex, setTabIndexState] = useState(initialTab);
    const [userCerts, setUserCerts] = useState([]);
    const [openAccordion, setOpenAccordion] = useAccordionGroup(["picture", "security"]);

    /* Edit */
    let { employeeId } = useParams();
    if (!employeeId) {
        history.push(RouteCreator.employee.list());
    }

    const ownProfile = useAuthorization({
        allowedUserId: employeeId,
    });

    const {
        loadEmployeeById,
        details,
        updateEmployee,
        updateData,
        resetState,
        rolesData,
        loadRoles,
    } = useStateModule(employeeListStateModule);

    useEffect(() => {
        loadEmployeeById(employeeId);
    }, [employeeId, loadEmployeeById]);

    useEffect(() => {
        return () => {
            resetState();
        };
    }, [resetState]);

    useEffect(() => {
        loadRoles();
    }, [loadRoles]);

    const getCertsAsync = async () => {
        const resUsers = await CompanyService.getMyEmployeesCertificates(employeeId);
        if (!resUsers.error) {
            const myCerts = resUsers.data.data.filter((cert) => cert.userId === employeeId);
            setUserCerts(myCerts);
        }
    };

    const addNewCertificate = (ev, id) => {
        ev.preventDefault();
        ModalWizard.show(
            AddCertificateWizard(t, id),
            {
                onSubmit: async (values) => {
                    if (values.licenceNeverExpires)
                        values.licenceExpirationDate = new Date("1/1/3000");
                    delete values.licenceNeverExpires;
                    values.id = id;

                    let formData = helpers.getFormData(values);
                    let result;
                    result = await CompanyService.addUserCertificate(formData);
                    if (result && result.error) {
                        toast.error(t(result.error.message));
                        ModalWizard.submitFailed();
                    } else {
                        toast.success(t("actions:certificate.toastNotification.success"));
                        ModalWizard.hide();
                        getCertsAsync();
                    }
                },
                onCancel: async () => {
                    ModalWizard.hidePrompt();
                },
            },
            {
                style: {
                    minWidth: "700px",
                },
            }
        );
    };

    const certColumns = useMemo(
        () => [
            {
                Header: t("screens:certificates.tableHeaders.certHolder"),
                accessor: "certificateHolder",
                Cell: HolderCell,
            },
            {
                Header: t("screens:certificates.tableHeaders.certNumber"),
                accessor: "licenceNumber",
            },
            {
                Header: t("screens:certificates.tableHeaders.expiration"),
                accessor: "licenceExpiryDate",
                Cell: DateCell,
            },
            {
                Header: t("screens:certificates.tableHeaders.submitDate"),
                accessor: "licenceUploadDate",
                Cell: DateCell,
            },
            {
                Header: t("screens:certificates.tableHeaders.procDate"),
                accessor: "processedDate",
                Cell: DateCell,
            },
            {
                Header: t("screens:certificates.tableHeaders.status"),
                accessor: "licenceStatusName",
                Cell: StatusCell,
            },
            {
                Header: "",
                accessor: "id",
                Cell: ({ cell: { row } }) => {
                    return (
                        <AuthorizeComponent roles={[Roles.InstallerCompanyAdmin]}>
                            {row.original.licenceId !== EmptyGuid && (
                                <HamburgerMenu up={true}>
                                    <HamburgerMenuAction
                                        text={t("actions:view")}
                                        onClick={() =>
                                            window.open(
                                                row.original.licenceCertificateFile,
                                                "_blank"
                                            )
                                        }
                                    ></HamburgerMenuAction>
                                </HamburgerMenu>
                            )}
                        </AuthorizeComponent>
                    );
                },
            },
        ],
        [t]
    );

    useEffect(() => {
        getCertsAsync();
    }, []);

    let [submittedValues, setSubmittedValues] = React.useState({
        values: null,
        fullName: null,
    });

    const handleSubmit = async (values, { setSubmitting, setErrors, setStatus, resetForm }) => {
        const updateValues = {
            ...values,
            id: employeeId,
            roleNames: getRoleNamesFromRoleId(values.roleId),
        };
        if (ownProfile) {
            await UserService.setUserDetails(updateValues);
            loadUserData();
        } else await updateEmployee(updateValues);
        toast.success(t("screens:employeeEdit.completed.title"));
        setSubmittedValues({
            values: values,
            fullName: values.firstName + " " + values.lastName,
        });
        loadEmployeeById(employeeId);
    };

    const handle2FASubmit = async (values) => {
        const updateValues = {
            ...details.employeeData,
            ...values,
            id: employeeId,
            roleId: getRoleIdFromRoleName(),
        };
        if (ownProfile) {
            await UserService.setUserDetails(updateValues);
            loadUserData();
        } else await updateEmployee(updateValues);
        toast.success(t("screens:employeeEdit.completed.title"));
        loadEmployeeById(employeeId);
    };

    function getRoleIdFromRoleName() {
        if (initializedValues.roleNames && rolesData.roles.length > 0) {
            return (initializedValues.roleId = rolesData.roles.find(
                (role) => role.name === initializedValues.roleNames[0]
            ).id);
        }
    }

    function getRoleNamesFromRoleId(roleId) {
        return [rolesData.roles.find((role) => role.id === roleId).name];
    }

    const twoFAValues = {
        passwordExpiration: details.employeeData.passwordExpiration || 0,
        twoFactorEnabled: details.employeeData.twoFactorEnabled || false,
    };

    let initializedValues = helpers.getInitialFormValues(
        defaultEditEmployeeValues,
        details.employeeData
    );
    initializedValues.roleId = getRoleIdFromRoleName();

    if (employeeId) {
        initializedValues["id"] = employeeId;
    }

    const employeeBreadcrumbs = [
        {
            text: "Company",
        },
        {
            text: "Employees",
            url: RouteCreator.employee.list(),
        },
        {
            text: "Edit",
        },
    ];

    const { update: updateBreadcrumbs } = useBreadcrumbs(employeeBreadcrumbs);

    useEffect(() => {
        employeeBreadcrumbs[2].text = details.employeeData.fullName;
        updateBreadcrumbs(employeeBreadcrumbs);
    }, [details.employeeData.username]);

    // change Username
    const [usernameError, setUsernameError] = useState({});
    const [usernameRequested, setUsernameRequested] = useState(false);
    const newEmailRef = useRef();
    const { modalProps: changeUsernameModalProps, toggleOpen: toggleUsernameModal } = useModal({
        onConfirm: async () => {
            const schema = buildValidation({
                email: commonValidation.email(t).rule,
            });
            try {
                await schema.validate({ email: newEmailRef.current.value });
                setUsernameError({});
                await UserService.changeEmail({
                    email: initializedValues.email,
                    newEmail: newEmailRef.current.value,
                });
                toggleUsernameModal();
                setUsernameRequested(true);
            } catch (err) {
                setUsernameError({ newEmail: err.message });
                toast.error(err.message);
            }
        },
        onCancel: () => toggleUsernameModal(),
        isNegativeAction: false,
    });

    const handleUsernameChange = (ev) => {
        if (ev.detail === 0) return;
        ev.preventDefault();
        setUsernameError({});
        toggleUsernameModal();
    };

    if (usernameRequested) {
        LoginService.logout();
        window.location.replace(RouteCreator.user.login());
    }

    return (
        <ContentWrapper>
            <Helmet>
                <title>{t("seo:employeeEdit.title")}</title>
            </Helmet>
            <LayoutInnerContainer className="wide">
                {!globals.isMobile && (
                    <TabsWrapper className="TabsWrapper">
                        <RoundTabs
                            language={i18n.language}
                            className="minimal"
                            tabIndex={tabIndex}
                            activeFn={setTabIndexState}
                            tabs={[
                                {
                                    label: t("screens:licenceEdit.profile").toUpperCase(),
                                    activeValue: tabIndices.Profile,
                                },
                                {
                                    label: t("screens:licenceEdit.certificates").toUpperCase(),
                                    activeValue: tabIndices.Information,
                                },
                            ]}
                        />
                    </TabsWrapper>
                )}
                <TabContent show={tabIndex === tabIndices.Profile}>
                    <AccordionWrapper
                        id="picture"
                        isOpen={openAccordion}
                        title={`${t("forms:extraction.wizard.picture")}`}
                        onToggle={setOpenAccordion}
                        variant="overflow top-space"
                        style={{ marginTop: "40px" }}
                        noBorder
                    >
                        <ChangePictureSection
                            style={{ paddingLeft: "20px" }}
                            fullName={submittedValues.fullName}
                            otherUserData={details.employeeData}
                        />
                    </AccordionWrapper>
                    <EffectError error={details.error}>
                        <DataEntry
                            style={{ marginBottom: "20px" }}
                            initialValues={submittedValues.values || initializedValues}
                            validationSchema={validationEditEmployee(t)}
                            renderForm={(formik) => {
                                return (
                                    <EmployeeEditForm
                                        formik={formik}
                                        details={details}
                                        isLoading={details.isLoading || updateData.isLoading}
                                        roles={rolesData.roles}
                                        handleUsernameChange={handleUsernameChange}
                                        ownProfile={ownProfile}
                                    />
                                );
                            }}
                            onSubmit={handleSubmit}
                            isLoading={details.isLoading}
                        />
                    </EffectError>
                    <AccordionWrapper
                        id="security"
                        isOpen={openAccordion}
                        title={`${t("screens:employeeEdit.title_securitySettings")}`}
                        onToggle={setOpenAccordion}
                        variant="overflow top-space inset"
                        style={{ marginTop: "40px" }}
                        noBorder
                    >
                        <AuthorizeComponentByUserId userId={employeeId}>
                            <ChangePasswordSection></ChangePasswordSection>
                        </AuthorizeComponentByUserId>
                        <AuthorizeComponent roles={[Roles.InstallerCompanyAdmin]}>
                            <Change2faSection
                                onSubmit={handle2FASubmit}
                                defaultValues={twoFAValues}
                            ></Change2faSection>
                        </AuthorizeComponent>
                    </AccordionWrapper>
                </TabContent>

                <TabContent show={tabIndex === tabIndices.Information}>
                    <AccordionWrapper
                        title={`${t("screens:certificates.personalCert")}`}
                        variant="overflow top-space inset"
                        isOpen={true}
                        style={{ marginTop: "56px" }}
                        noBorder
                    >
                        <InfoWrapper>
                            <GreenInfo src="/images/menu/green-info.svg" />
                            <div>
                                <div className="info">
                                    {t("screens:certificates.personalCertInfo.allTechnicians")}
                                </div>
                                <div className="info">
                                    {t("screens:certificates.personalCertInfo.requirement")}
                                </div>
                                <div className="info topSpace">
                                    {t("screens:certificates.personalCertInfo.qualification")}
                                </div>
                                <div className="info topSpace">
                                    {t("screens:certificates.personalCertInfo.regulation")}
                                </div>
                                <ul>
                                    <li>{t("screens:certificates.personalList.regulations")}</li>
                                    <li>{t("screens:certificates.personalList.emission")}</li>
                                    <li>{t("screens:certificates.personalList.recovery")}</li>
                                    <li>{t("screens:certificates.personalList.handling")}</li>
                                    <li>{t("screens:certificates.personalList.information")}</li>
                                </ul>
                            </div>
                        </InfoWrapper>

                        <Separator />
                        <DataTable columns={certColumns} data={userCerts} />
                        <RightContainer>
                            <Button
                                className="primary--action"
                                onClick={(ev) => {
                                    addNewCertificate(ev, userCerts[0].userId);
                                }}
                            >
                                {t("screens:certificates.add")}
                            </Button>
                        </RightContainer>
                    </AccordionWrapper>
                </TabContent>
            </LayoutInnerContainer>
            <ModalDialog {...changeUsernameModalProps}>
                <Headings.H3>{t("actions:dialogs.changeUsername.title")}</Headings.H3>
                <p style={{ marginBottom: "50px" }}>{t("actions:dialogs.changeUsername.text")}</p>
                <InputElement
                    labelText={t("forms:userAccount.text")}
                    name="newEmail"
                    id="newEmail"
                    type="newEmail"
                    errors={usernameError}
                    ref={newEmailRef}
                />
            </ModalDialog>
        </ContentWrapper>
    );
};

export default EmployeeEditScreen;
