import companiesListStateModule from "./admin/companies/companiesListStateModule";
import adminUsersListStateModule from './admin/users/adminUsersListStateModule';
import usersListStateModule from "./admin/users/usersListStateModule";
import marketplaceListingStateModule from './buy-refrigerant/marketplaceListingStateModule';
import myBidsListingStateModule from "./buy-refrigerant/myBidsListingStateModule";
import clientsListStateModule from "./clients/clientsListStateModule";
import clientsLocationListStateModule from "./clients/clientsLocationsListStateModule";
import clientsUnitsListStateModule from "./clients/clientsUnitsListStateModule";
import companyStateModule from "./company/companyStateModule";
import cylinderListStateModule from "./company/cylinderListStateModule";
import employeeListStateModule from "./company/employeeListStateModule";
import equipmentListStateModule from "./company/equipmentListStateModule";
import breadcrumbsStateModule from "./navigation/breadcrumbsStateModule";
import leftNavigationStateModule from "./navigation/leftNavigationStateModule";
import sellRefrigerantListingStateModule from "./sell-refrigerant/sellRefrigerantListingStateModule";
import installerStockListingStateModule from "./stock/installerStockListingStateModule";
import stockListingStateModule from "./stock/stockListingStateModule";
import userStateModule from "./user/userStateModule";

const registeredModules = [
    companiesListStateModule,
    userStateModule,
    adminUsersListStateModule,
    usersListStateModule,
    equipmentListStateModule,
    employeeListStateModule,
    breadcrumbsStateModule,
    stockListingStateModule,
    companyStateModule,
    sellRefrigerantListingStateModule,
    marketplaceListingStateModule,
    myBidsListingStateModule,
    leftNavigationStateModule,
    installerStockListingStateModule,
    clientsListStateModule,
    clientsLocationListStateModule,
    clientsUnitsListStateModule,
    cylinderListStateModule
];

const createRootInitialState = (modules) => {
    let initialState = {};
    for (let i = 0; i < modules.length; i++) {
        const currentModule = modules[i];
        initialState[currentModule.stateNamespace] = currentModule.reducer.initialState;
    }

    return initialState;
};

const createRootReducer = (modules) => {
    const initialState = createRootInitialState(modules);

    const rootReducer = (state, action) => {
        for (let i = 0; i < modules.length; i++) {
            const currentModule = modules[i];
            currentModule.reducer(state[currentModule.stateNamespace], action);
        }
    }
    rootReducer.initialState = initialState;

    return rootReducer;
};

const rootReducer = createRootReducer(registeredModules);
export default rootReducer;
