import QRCode from "qrcode.react";
import React, { useMemo } from "react";
import ImageGallery from "../../../../../components/gallery/ImageGallery";
import AccordionWrapper from "../../../../../components/toggle-view/AccordionWrapper";
import { QRWrapper } from "../../../../Equipment/components/QRWrapper";
import { TagTitle } from "../../../../Equipment/components/TagTitle";
import helpers from "../../../../../core/helpers";
import { useTranslation } from "react-i18next";
import SidebarField from "../../../../../components/elements/sidebar/SidebarField";
import DaikinMap from "../../../../../components/maps/DaikinMap";
import LocationMarker from "../../../../../components/maps/components/LocationMarker";
import MapBounds from "../../../../../components/maps/components/MapBounds";
import useAccordionGroup from "../../../../../hooks/_shared/useAccordionGroup";

const UnitsDetailSideBar = ({ unit, location }) => {
    const unitData = unit.clientsLocationUnitsData
        ? unit.clientsLocationUnitsData
        : {};

    const { t } = useTranslation("sidebar");
    const [openAccordion, setOpenAccordion] = useAccordionGroup();

    const tagParts =
        unitData && unitData.tagId ? helpers.splitQRTag(unitData.tagId) : [];

    const boundsMarkers = useMemo(() => {
        let markers = [];
        if (location) {
            if (location.latitude) {
                markers.push(
                    new window.google.maps.Marker({
                        position: {
                            lat: location.latitude,
                            lng: location.longitude,
                        },
                    })
                );
            } else {
                markers.push(
                    new window.google.maps.Marker({
                        position: {
                            lat: 0,
                            lng: 0,
                        },
                    })
                );
            }
        }
        return markers;
    }, [location]);

    return (
        <>
            {unitData && (
                <>
                    <AccordionWrapper
                        title={t("title.unit.title")}
                        id="unit.title"
                        isOpen={openAccordion}
                        onToggle={setOpenAccordion}
                    >
                        {unitData && unitData.tagId && (
                            <QRWrapper>
                                <QRCode value={unitData.tagId} size={140} />{" "}
                                <TagTitle>{tagParts[0]}</TagTitle>
                                <span>{tagParts[1]}</span>
                            </QRWrapper>
                        )}
                        <br />
                        <SidebarField
                            label={t("label.unit.manufacturer")}
                            value={unitData.manufacturerName}
                        />

                        <SidebarField
                            label={t("label.unit.applicationName")}
                            value={unitData.applicationTypeName}
                        />

                        <SidebarField
                            label={t("label.unit.fGas")}
                            value={unitData.refrigerantCode}
                        />

                        <SidebarField
                            label={t("label.unit.capacity")}
                            value={unitData.capacity}
                        />
                    </AccordionWrapper>

                    <AccordionWrapper
                        title={t("title.gallery")}
                        id="gallery"
                        isOpen={openAccordion}
                        onToggle={setOpenAccordion}
                    >
                        <ImageGallery images={unitData.images} showRows="2" />
                    </AccordionWrapper>

                    <AccordionWrapper
                        title={t("forms:clients.notes")}
                        id="Notes"
                        isOpen={openAccordion}
                        onToggle={setOpenAccordion}
                    >
                        <p style={{ paddingLeft: "20px" }}>
                            {unitData.description ? unitData.description : "-"}
                        </p>
                    </AccordionWrapper>

                    {unitData.client && (
                        <AccordionWrapper
                            title={t("title.installationSite")}
                            id="installationSite"
                            isOpen={openAccordion}
                            onToggle={setOpenAccordion}
                        >
                            <SidebarField
                                label={t("label.companyName")}
                                value={
                                    unitData.client &&
                                    unitData.client.clientName
                                }
                            />

                            <>
                                <SidebarField
                                    label={t("label.companyStreet")}
                                    value={unitData.client.street}
                                />

                                <SidebarField
                                    label={t("label.companyCity")}
                                    value={unitData.client.city}
                                />

                                <SidebarField
                                    label={t("label.companyZIP")}
                                    value={unitData.client.postalCode}
                                />

                                <SidebarField
                                    label={t("label.companyCountry")}
                                    value={location.countryName}
                                />

                                <DaikinMap
                                    className="no-border"
                                    style={{
                                        marginTop: "24px",
                                        marginLeft: "20px",
                                    }}
                                >
                                    <LocationMarker location={location} />
                                    <MapBounds markers={boundsMarkers} />
                                </DaikinMap>
                            </>
                        </AccordionWrapper>
                    )}
                </>
            )}
        </>
    );
};

export default UnitsDetailSideBar;
