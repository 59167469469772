import React from "react";
import { Switch } from "react-router-dom";
import PrivateRoute from "../../core/PrivateRoute";
import RouteCreator from "../../core/RouteCreator";
import useLeftMenu from "../../core/state/navigation/useLeftMenu";
import companyAdminMenuItems from "../../data/menu/companyAdminMenuItems";
import DisputesListing from "./views/DisputesListing";
import useSharedState from "../../hooks/_shared/useSharedState";

const DisputesScreen = () => {
    const [globalState] = useSharedState("global");
    useLeftMenu(companyAdminMenuItems(globalState));

    return (
        <Switch>
            <PrivateRoute
                path={RouteCreator.disputes.root()}
                component={DisputesListing}
            />
        </Switch>
    );
};

export default DisputesScreen;
