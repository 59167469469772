const TransactionFlowType = {
    0: 'RecoveredFGasSale',
    1: 'ReclaimedFGasSale',
    2: 'FGasDisposal',
    3: 'FGasCleaning',
    4: 'PayIn',
    5: 'PayOut'
    // 2: 'ReclaimedOffer',
};

export default TransactionFlowType;
