import { useFormik } from "formik";
import PropTypes from "prop-types";
import React, { useRef } from "react";

const DataEntry = props => {
    let {
        onSubmit,
        renderForm,
        initialValues,
        validationSchema,
        isLoading,
        validateOnChange = true,
        validateOnBlur = false,
        ...rest
    } = props;

    const onKeyDown = (keyEvent) => {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
          keyEvent.preventDefault();
        }
      }

    const formik = useFormik({
        initialValues: initialValues || {},
        onSubmit: values => {
            onSubmit(values, formik);
        },
        validateOnBlur,
        validateOnChange,
        validationSchema: validationSchema,
        enableReinitialize: true
    });
    const fieldRef = useRef();
    if (!validateOnBlur) {
        const oldHandleChenge = formik.handleChange;
        const handleFormikChange = data => {
            fieldRef.current = data.target.name;
            oldHandleChenge(data);
        };
        const oldHandleSubmit = formik.handleSubmit;
        const handleFormikSubmit = data => {
            fieldRef.current = null;
            oldHandleSubmit(data);
        };
        formik.handleChange = handleFormikChange;
        formik.handleSubmit = handleFormikSubmit;

        if (fieldRef.current) {
            const fieldError = {};
            if (formik.errors[fieldRef.current]) {
                fieldError[fieldRef.current] = formik.errors[fieldRef.current];
            }
            formik.errors = fieldError;
        }
    }
    let formRendered = renderForm(formik, isLoading);

    return (
        <form onKeyDown={onKeyDown}
            {...rest}
            onSubmit={formik.handleSubmit}
            className={isLoading ? "loading" : ""}
        >
            {formRendered}
        </form>
    );
};

export default DataEntry;

DataEntry.propTypes = {
    initialValues: PropTypes.any.isRequired,
    validationSchema: PropTypes.any.isRequired,
    renderForm: PropTypes.any.isRequired,
    onSubmit: PropTypes.any.isRequired,
    isLoading: PropTypes.any.isRequired
};
