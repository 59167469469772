import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../components/elements/buttons/Button";
import SidebarField from "../../../../components/elements/sidebar/SidebarField";
import AccordionWrapper, {
    AccordionGroup,
} from "../../../../components/toggle-view/AccordionWrapper";
import {
    Num,
    NumInfo,
    NumSection,
} from "../../../../components/elements/sidebar/SidebarStyle";

import Slider from "../../../../components/elements/general/Slider";
import useSharedState from "../../../../hooks/_shared/useSharedState";
import useAccordionGroup from "../../../../hooks/_shared/useAccordionGroup";

const splitTag = (tag) => {
    if (!tag || tag.length < 8) return tag;
    return tag.substring(0, 4) + " " + tag.substring(4, 8);
};

const CleaningDetailSideBar = ({
    offer,
    details,
    companyId,
    toggleBidModal,
    bidAlreadyPlaced,
    openAccordionId,
}) => {
    const { t } = useTranslation(["sidebar"]);
    const shouldShow = details !== null;
    const [globalState] = useSharedState("global");
    const [openAccordion, setOpenAccordion] =
        useAccordionGroup(openAccordionId);
    return (
        <>
            {shouldShow && (
                <>
                        <AccordionWrapper
                            title={t("title.offer.details")}
                            id="offer.details"
                            isOpen={openAccordion}
                            onToggle={setOpenAccordion}
                        >
                            {offer && (
                                <>
                                    <SidebarField
                                        label={t("label.gasType")}
                                        value={offer.refrigerantGasTypeCode}
                                    />

                                    <SidebarField
                                        label={t("label.numberOfCylinders")}
                                        value={
                                            details.skUs
                                                ? details.skUs.length
                                                : "-"
                                        }
                                    />

                                    <SidebarField
                                        label={t("label.netWeight")}
                                        value={`${offer.maximumQuantity.toFixed(
                                            2
                                        )} kg`}
                                    />

                                    <SidebarField
                                        label={t(
                                            "label.totalExtractedQuantity"
                                        )}
                                        value={`${offer.quantity.toFixed(
                                            2
                                        )} kg`}
                                    />

                                    <SidebarField
                                        label={t(
                                            "label.extraction.qualityEvaluation"
                                        )}
                                        value={
                                            details.hasAnalysis
                                                ? t(
                                                      "filters:analysis.measurement"
                                                  )
                                                : t(
                                                      "filters:analysis.selfAssessment"
                                                  )
                                        }
                                    />
                                </>
                            )}
                        </AccordionWrapper>
                        {details.skUs && (
                            <AccordionWrapper
                                title={t("title.contamination")}
                                id="contamination"
                                isOpen={openAccordion}
                                onToggle={setOpenAccordion}
                            >
                                <Slider
                                    style={{ padding: "25px 20px 0px" }}
                                    title={t(
                                        "sidebar:title.extraction.extractionCylinder"
                                    )}
                                    captions={details.skUs.map((sku) =>
                                        splitTag(sku.tagId)
                                    )}
                                >
                                    {details.skUs.map((sku, idx) => {
                                        let cnt = 0;
                                        return (
                                            <div
                                                id={`slide-${idx + 1}`}
                                                key={idx}
                                            >
                                                {!sku.saIsContaminated &&
                                                    !sku.saIsMixture &&
                                                    !sku.saHasR22 && (
                                                        <NumSection>
                                                            <Num className="tight">
                                                                N/A
                                                            </Num>
                                                            <NumInfo>
                                                                {t(
                                                                    "actions:noContamination"
                                                                )}
                                                            </NumInfo>
                                                        </NumSection>
                                                    )}

                                                {sku.saIsContaminated && (
                                                    <NumSection>
                                                        <Num>{++cnt}</Num>
                                                        <NumInfo>
                                                            {t(
                                                                "screens:stock.aGradeText"
                                                            )}
                                                        </NumInfo>
                                                    </NumSection>
                                                )}
                                                {sku.saIsMixture && (
                                                    <NumSection>
                                                        <Num>{++cnt}</Num>
                                                        <NumInfo>
                                                            {t(
                                                                "screens:stock.bGradeText"
                                                            )}
                                                        </NumInfo>
                                                    </NumSection>
                                                )}
                                                {sku.saHasR22 && (
                                                    <NumSection>
                                                        <Num>{++cnt}</Num>
                                                        <NumInfo>
                                                            {t(
                                                                "screens:stock.cGradeText"
                                                            )}
                                                        </NumInfo>
                                                    </NumSection>
                                                )}
                                            </div>
                                        );
                                    })}
                                </Slider>
                            </AccordionWrapper>
                        )}

                        <AccordionWrapper
                            title={t("screens:stockListing.headers.offerer")}
                            id="offerer"
                            isOpen={openAccordion}
                            onToggle={setOpenAccordion}
                        >
                            <SidebarField
                                label={t("title.company")}
                                value={details.companyName}
                            />
                            <SidebarField
                                label={t("label.companyVAT")}
                                value={details.vatNumber}
                            />
                            <SidebarField
                                label={t("label.companyStreet")}
                                value={details.street}
                            />
                            <SidebarField
                                label={t("label.companyCity")}
                                value={details.city}
                            />
                            <SidebarField
                                label={t("label.companyState")}
                                value={details.region}
                            />
                            <SidebarField
                                label={t("label.companyZIP")}
                                value={details.postalCode}
                            />
                            <SidebarField
                                label={t("label.companyCountry")}
                                value={details.countryName}
                            />
                            <SidebarField
                                label={t("label.companyContactPersonName")}
                                value={details.contactPerson}
                            />
                            <SidebarField
                                label={t("label.companyEmail")}
                                value={details.email}
                            />
                            <SidebarField
                                label={t("label.companyPhone")}
                                value={details.phone}
                            />
                        </AccordionWrapper>
                    {companyId &&
                        companyId !== details.companyId &&
                        !bidAlreadyPlaced && (
                            <div style={{ textAlign: "center" }}>
                                <Button
                                    disabled={
                                        !globalState.hasValidCompanyCertificate ||
                                        !globalState.hasCertificateRights
                                    }
                                    onClick={toggleBidModal}
                                    className="primary"
                                >
                                    {t("label.placeBid")}
                                </Button>
                            </div>
                        )}
                </>
            )}
        </>
    );
};

export default CleaningDetailSideBar;
