import Color from "color";
import styled from "styled-components";

const Button = styled.button`
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    border-radius: ${(props) => props.theme.buttonsBorderRadius};
    min-width: ${(props) => props.theme.widthButtonMin};
    padding: ${(props) => props.theme.paddingInlineButton};
    box-shadow: ${(props) => props.theme.buttonShadow};
    height: ${(props) => props.theme.heightButtonSmall};
    line-height: ${(props) => props.theme.heightButtonSmall};
    vertical-align: middle;
    font-size: 0.875rem;

    &.primary {
        background: ${(props) => props.theme.primaryColor};
        border: 1px solid ${(props) => props.theme.primaryColorBorder};
        color: ${(props) => props.theme.primaryColorText};
        min-width: ${(props) => props.theme.widthButtonMin};

        &:hover:enabled {
            background: ${(props) =>
                Color(props.theme.primaryColor).lighten(0.15).string()};
        }
    }

    &.primary--action {
        color: #fff;
        background: ${(props) => props.theme.primaryActionColor};
        border: 1px solid ${(props) => props.theme.primaryActionColorBorder};

        &:hover:enabled {
            background: ${(props) =>
                Color(props.theme.primaryActionColor).lighten(0.15).string()};
        }
    }

    &.primary--action:disabled {
        color: #fff;
        background: ${(props) => props.theme.primaryActionColor};
        border: 1px solid ${(props) => props.theme.primaryActionColorBorder};
        opacity: 0.25;
    }

    &.secondary {
        background: ${(props) => props.theme.bgWhite};
        border: 1px solid ${(props) => props.theme.primaryColorBorder};
        color: ${(props) => props.theme.primaryColorBorder};
        min-width: ${(props) => props.theme.widthButtonMin};

        &:disabled {
            border-color: ${(props) => props.theme.borderColorDisabled};
        }
    }

    &.secondary--action {
        background: ${(props) => props.theme.secondaryColor};
        border: 1px solid ${(props) => props.theme.primaryColorBorder};
        color: #fff;

        &:disabled {
            border-color: ${(props) => props.theme.borderColorDisabled};
        }
    }

    &.blue--border {
        background: transparent;
        border: 1px solid ${(props) => props.theme.secondaryColorBorder};
        color: ${(props) => props.theme.secondaryColor};
        box-shadow: none;

        &:disabled {
            border-color: ${(props) => props.theme.borderColorDisabled};
        }
    }

    &.secondary--negative {
        border: 1px solid ${(props) => props.theme.borderColorError};
        background: ${(props) => props.theme.secondaryNegativeColor};
        color: #fff;

        &:hover {
            box-shadow: ${(props) => props.theme.buttonShadowNegative};
        }
    }

    &.decline {
        color: ${(props) => props.theme.secondaryNegativeColor};
        border: 1px solid transparent;
        background: transparent;
        box-shadow: none;
        font-weight: 700;

        &:hover {
            box-shadow: ${(props) => props.theme.buttonShadowNegative};
        }
    }

    &.tertiary {
        border: none;
        background: transparent;
        box-shadow: none;
        color: ${(props) => props.theme.primaryColor};
        padding: 0;
        min-width: initial;
        font-size: 1.125rem;
        font-weight: bold;

        &:disabled {
        cursor: default;
        background: transparent;
        }
    }

    &.tertiary--negative {
        color: ${(props) => props.theme.tertiaryNegativeColor};
        //margin-left: auto;
    }

    &.inline {
        min-width: auto;
        padding: ${(props) => props.theme.paddingInlineButton};
        margin: 0 0 4px 0;
        height: 24px;
        line-height: 24px;
    }

    &.horizontal-space {
        margin: 0 10px;
    }

    &.flat {
        box-shadow: none;
    }

    &.filter {
        outline: none;
        background: ${(p) => p.theme.primaryColor};
        border: 1px solid ${(p) => p.theme.primaryColor};
        height: 32px;
        line-height: 32px;
        color: #fff;
        box-shadow: none;
        font-size: 0.875rem;
        text-align: left;
        padding: 0 20px 0 37px;
        position: relative;
        border-radius: 16px;
        d &:before {
            content: "+";
            background: #fff;
            color: #675b34;
            border: 1px solid #fff;
            width: 23px;
            height: 23px;
            line-height: 23px;
            position: absolute;
            left: 5px;
            top: 3px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.25rem;
        }
    }

    &.filter.back:before {
        content: "\u2190";
    }

    &.filter.open {
        background: #e94e4e;
        border: 1px solid #e94e4e;
        &:before {
            content: "-";
        }
    }

    &:disabled {
        cursor: default;
        background: ${(props) => props.theme.bgDisabled};
        border-color: ${(props) => props.theme.borderColorDisabled};
        color: ${(props) => props.theme.textDisabled};
    }

    & + button {
        margin: 0 0 0 60px;
        & + button {
            margin: 0 0 0 60px;
        }
    }

    &.sidebar {
        min-width: 148px;
        width: 148px;
    }

    &.big {
        height: 40px;
        width: auto;
    }

    &.centered {
        display: table;
        margin: 0 auto;
    }
`;

export default Button;
