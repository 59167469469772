import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import helpers from "../../../../core/helpers";
import RouteCreator from "../../../../core/RouteCreator";
import UserService from "../../../../services/User/UserService";
import Flex from "../../../../components/layout/flex/Flex";
import Button from "../../../../components/elements/buttons/Button";
import { useHistory } from 'react-router-dom';

const Wallet = styled.div`
    display: flex;
    padding: 12px 0px;
    margin: 0px;

    .main {
        color: #687191;
    }

    &.main-wallet {
        border-bottom: 1px solid #d5d5d5;
    }

    .transaction {
        color: ${(props) => props.theme.walletTransaction};
    }
`;

const Total = styled(Flex.Container)`
    position: relative;
    padding: 12px 0px;
    &:before {
        content:"";
        position: absolute;
        left:   -40px;
        top:    0px;
        width: calc(100% + 40px);
        height: 2px;
        background-color: #000;
    } 
`;

const TotalTitle = styled.div`
    font-weight: 700;
    font-size: 28px;

`;

const FirstColumn = styled(Flex.Column)`
    min-width: 500px;

`;

const SecondColumn = styled(Flex.Column)`
    min-width: 200px;
    font-weight: 700;
    font-size: 24px;
`;

const ActionColumn = styled(Flex.Column)`
    flex: 1;
`;

const WalletTitle = styled.div`
        font-size: 24px;
    
`;

const WalletInfo = styled.div`
    padding: 12px 0px;
    font-size: 12px;
`;

const ActionWrapper = styled.div`
    text-align: right;
`;

const KycStatus = styled.span`
    font-weight: 600;
`;


const Warning = styled.span`
    display: block;
    font-size: 12px;
    color: ${(props) => props.theme.textNeutral};

`;

const PayButton = styled(Button)`
    margin: 0 !important;
    font-size: 14px;
    font-weight: 500 !important;
    outline: none !important;
    color: #2294d2;
    padding: 0px;
    text-align: right;

    background: transparent !important;
    box-shadow: none;
    border: none !important;
    font-size: 16px;
    font-weight: 700;
    :hover {
        text-decoration: underline;
    }
    height: auto;
`;


const MyWallets = (props) => {
    const { data, handlePayout, isTotal, handleDeposit } =
        props;
    const { t, i18n } = useTranslation();
    const isMain = !isTotal && data.tag && data.tag === "MAIN";
    const [walletValues] = useState({
        amount: data.amount,
    });
    const currency = data.currency;
    const currentLanguage = i18n.language;

    const { IsKYCValidated } = UserService.getUserDetailsFromToken();

    const history = useHistory();

    if (data === null) {
        return <></>;
    }

    return isTotal ? (
        <Total>
            <FirstColumn>
                <TotalTitle>
                    {t("screens:transactions.myWallets.totalBalance")}:
                </TotalTitle>
            </FirstColumn>
            <SecondColumn>{data.amount}</SecondColumn>
        </Total>
    ) : (
        <Wallet className={isMain ? "main-wallet" : null}>
            <FirstColumn className={isMain ? "main" : "transaction"}>
                <WalletTitle>
                    {data.tag &&
                        t(
                            `screens:transactions.myWallets.walletType.${data.tag.toLowerCase()}`
                        )}
                </WalletTitle>

                <WalletInfo
                    dangerouslySetInnerHTML={{
                        __html: t(
                            `screens:transactions.myWallets.walletTypeInfo.${data.tag.toLowerCase()}`
                        ),
                    }}
                />
            </FirstColumn>
            <SecondColumn className={isMain ? "main" : "transaction"}>
                {helpers.formatCurrency(data.amount, currentLanguage, currency)}
            </SecondColumn>
            <ActionColumn className="ActionColumn">
                {isMain && (
                    <>
                        <ActionWrapper className="actionWrapper">
                            {
                                <PayButton
                                    to="#"
                                    title={t(
                                        "screens:transactions.myWallets.payIn"
                                    )}
                                    onClick={(ev) => {
                                        ev.preventDefault();
                                        handleDeposit();
                                    }}
                                >
                                    {t("screens:transactions.myWallets.payIn")}
                                </PayButton>
                            }
                        </ActionWrapper>

                        {IsKYCValidated === "True" ? (
                            <ActionWrapper>
                                <PayButton
                                    to="#"
                                    title={t(
                                        "screens:transactions.myWallets.payOut"
                                    )}
                                    onClick={(ev) => {
                                        ev.preventDefault();
                                        handlePayout(walletValues);
                                    }}
                                >
                                    {t("screens:transactions.myWallets.payOut")}
                                </PayButton>
                            </ActionWrapper>
                        ) : (
                            <ActionWrapper>
                                <Warning>
                                    {t("screens:transactions.myWallets.payoutNotAllowed")}
                                    <KycStatus> Light </KycStatus>
                                </Warning>
                                <PayButton
                                    to="#"
                                    onClick={(ev) => {
                                        history.push(RouteCreator.company.kyc());
                                        ev.preventDefault();
                                    }}
                                    title={t("screens:transactions.myWallets.upgradeKycStatus")}
                                >
                                    {t(
                                        "screens:transactions.myWallets.upgradeKycStatus"
                                    )}
                                </PayButton>
                            </ActionWrapper>
                        )}
                    </>
                )}
            </ActionColumn>
        </Wallet>
    );
};

export default MyWallets;
