import PropTypes from "prop-types";
import React, { useRef, useEffect } from "react";
import { MoneyContent, InputWrapper } from "../Stylings";
import { Input, Label, ValidationError } from "./index";

const allowedKeys = ["-", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

const MoneyInputElement = (props) => {
    let {
        labelText,
        name,
        id,
        values,
        handleChange,
        handleBlur,
        wrapperVariant,
        disabled,
        style,
        positive,
        formik,
        grade,
        type,
        currencyLabel = "EUR\\kg",
        ...rest
    } = props;

    let value = props.value
        ? props.value
        : formik.values && formik.values[name];
    let error = formik.errors[name];
    let isTouched = formik.touched[name];

    let inputVal = value || "0.00";
    if (typeof inputVal === "number" && isFinite(inputVal))
        inputVal = inputVal.toFixed(2);

    const inputRef = useRef();

    const handleKeyDown = (ev) => {
        if (ev.keyCode === 9) return true;
        ev.preventDefault();

        if (ev.keyCode === 8 || ev.keyCode === 46) {
            if (Number(inputRef.current.value) < 0.1) {
                inputRef.current.value = "0.00";
            } else
                inputRef.current.value = (
                    Number(inputRef.current.value) / 10
                ).toFixed(2);
        } else if (allowedKeys.indexOf(ev.key) >= 0) {
            if (ev.key === "-") {
                if (!positive)
                    inputRef.current.value = (-Number(
                        inputRef.current.value
                    )).toFixed(2);
            } else
                inputRef.current.value = (
                    Number(inputRef.current.value) * 10 +
                    Number(ev.key) / 100
                ).toFixed(2);
        }
    };
    return (
        <InputWrapper
            style={style}
            className={`${wrapperVariant ? wrapperVariant : ""} ${
                error ? "error" : ""
            }`}
        >
            <Label htmlFor={name} className={error && "error"}>
                {labelText}
            </Label>

            <MoneyContent
                disabled={disabled}
                className={`${error ? "error" : ""} ${
                    wrapperVariant ? wrapperVariant : ""
                } money`}
            >
                <Input
                    type={type}
                    ref={inputRef}
                    id={props.id}
                    name={props.name}
                    onKeyDown={handleKeyDown}
                    value={inputVal}
                    onChange={formik.handleChange}
                    onKeyUp={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={disabled && "disabled"}
                    className={`${error ? "error" : ""} money`}
                    data-grade={grade}
                    {...formik}
                    {...rest}
                />
                <span>{currencyLabel}</span>
            </MoneyContent>

            {error && (
                <ValidationError
                    touched={isTouched}
                    error={error}
                    isFieldError={true}
                    className="small"
                />
            )}
        </InputWrapper>
    );
};

export default MoneyInputElement;

MoneyInputElement.propTypes = {
    labelText: PropTypes.any.isRequired,
    name: PropTypes.any.isRequired,
    type: PropTypes.any.isRequired,
};
