import React from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import QrReader from "react-qr-reader";
import InputElement from "../../../../components/elements/form-elements/InputElement";
import RadioList from "../../../../components/elements/form-elements/RadioList";
import SimpleTable from "../../../../components/elements/general/SimpleTable";

import { InfoTitle } from "../../../../components/modal/components/WizardStyling";
import { StyledQrReader } from "../../../../components/elements/qr/QrScanner";
import ValidationRule, {
    buildValidation,
} from "../../../../core/validations/ValidationRule";

import { ReactComponent as CollectGasSvg } from "../../../../assets/pwa/collect-gas.svg";
import {
    ScanButton,
    KeyVal,
    ListTitle,
    HR,
} from "../../components/StyledElements";

import { SvgScan } from "../../components/StyledSvg";

const FlexCenter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const SvgToStock = styled(CollectGasSvg)`
    width: auto;
    margin: 0 auto;
`;

const Section = styled.div`
    padding: 8px 20px;
    &.full-x {
        padding: 8px 0px;
    }

    &.flex {
        display: flex;
    }
`;

const ScanWrapper = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;

    &.button {
        bottom: 60px;

        & input {
            margin-bottom: 0;
        }
    }
`;

const Spacer = styled.div`
    height: 10px;
    width: 100%;
`;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: calc(100% - 70px);
    overflow-y: auto;
`;

const defaultInitialValues = {
    client: "",
    cylinders: [],
    collectedCylinders: [],
    showCylinderScanner: false,
    numCylinders: "",
    minCylinders: "",
};

const splitAt = (index) => (x) => [x.slice(0, index), x.slice(index)];

const validClient = (t) => {
    return buildValidation({
        client: ValidationRule.isStringRule().required(
            t("forms:unit.unitTagId.required")
        ).rule,
    });
};

const CollectGasWizard = (t, offerCollection = []) => {
    const handleCylinderScan = (value, formik) => {
        if (value !== null) {
            const idx = availableCylinders.findIndex(
                (el) => el.tagId === value.toLowerCase()
            );
            if (idx >= 0) {
                if (!formik.values["collectedCylinders"].includes(idx)) {
                    const col = formik.values["collectedCylinders"];
                    col.push(idx);
                    formik.setFieldValue("collectedCylinders", col);
                    formik.setFieldValue("numCylinders", col.length);
                    formik.setFieldValue("showCylinderScanner", false);
                }
            }
        }
    };

    const tableColumns = [
        {
            header: t("screens:technician.type"),
            accessor: "type",
            cell: (value) => value || "-",
        },
        {
            header: t("screens:technician.Quantity"),
            accessor: "quantity",
            cell: (value) => value.toFixed(2) + " kg",
        },
        {
            header: t("screens:technician.qrTag"),
            accessor: "tagId",
            cell: (value) => splitAt(4)(value.substr(0, 8)).join(" "),
        },
    ];

    const companies =
        offerCollection.length === 0
            ? []
            : offerCollection.map((offer) => {
                  return {
                      text: offer.companyDetails.companyName,
                      value: offer.companyDetails.id,
                  };
              });

    const getCompanyDetails = (id) => {
        return offerCollection.filter(
            (offer) => offer.companyDetails.id === id
        )[0].companyDetails;
    };

    const getCollectableCylinders = (id) => {
        const offers = offerCollection.filter(
            (offer) => offer.companyDetails.id === id
        )[0].offers;

        const cylinders = [];

        offers.map((offer) => {
            offer.cylinders.map((cyl) => {
                cylinders.push({
                    type: cyl.gasTypeCode,
                    quantity: cyl.quantity,
                    tagId: cyl.tagId,
                });
            });
        });

        return cylinders;
    };

    let availableCylinders = [];
    const cylinderSearchFn = (tag) => {
        if (tag.length < 3) return [];
        return availableCylinders
            .filter((cyl) => cyl.tagId.startsWith(tag))
            .map((cyl) => cyl.tagId);
    };

    const handleCylinderChange = (ev, formik) => {
        const idx = availableCylinders.findIndex(
            (el) => el.tagId === ev.currentTarget.value
        );
        if (idx >= 0) {
            if (!formik.values["collectedCylinders"].includes(idx)) {
                const col = formik.values["collectedCylinders"];
                col.push(idx);
                formik.setFieldValue("collectedCylinders", col);
                formik.setFieldValue("numCylinders", col.length);
            }
            // ev.currentTarget.value = "";
        }
    };

    return {
        title: t("forms:stock.wizard.collectCylinder.title"),
        initialValues: defaultInitialValues,
        steps: [
            {
                name: t("forms:stock.wizard.collectCylinder.step1"),
                fieldNames: [
                    "client",
                    "cylinders",
                    "minCylinders",
                    "numCylinders",
                    "collectedCylinders",
                ],
                validation: validClient(t),
                fields: (formik, propertyBag, updatePropertyBag) => {
                    return (
                        <div>
                            <FlexCenter>
                                <SvgToStock />
                            </FlexCenter>

                            <InfoTitle className="pwa tight">
                                {t(
                                    "forms:stock.wizard.collectCylinder.step1Info"
                                )}
                            </InfoTitle>
                            <Spacer size={10} />
                            <HR className="content" />
                            <ListTitle className="wizard">
                                {t("screens:technician.company")}
                            </ListTitle>
                            <RadioList
                                name="client"
                                items={companies}
                                style={{ maxHeight: "304px" }}
                                onSelect={(value) => {
                                    availableCylinders =
                                        getCollectableCylinders(value);
                                    formik.setFieldValue("client", value);
                                    formik.setFieldValue(
                                        "minCylinders",
                                        availableCylinders.length
                                    );

                                    formik.setFieldValue("numCylinders", 0);
                                    updatePropertyBag({
                                        details: getCompanyDetails(value),
                                        cylinders: availableCylinders,
                                    });
                                    formik.setFieldValue(
                                        "cylinders",
                                        availableCylinders.map(
                                            (cyl) => cyl.tagId
                                        )
                                    );

                                    formik.setFieldValue(
                                        "collectedCylinders",
                                        []
                                    );
                                }}
                            />
                        </div>
                    );
                },
            },
            {
                name: t("screens:registration.companyDetails"),
                fields: (formik, propertyBag) => {
                    return (
                        <div>
                            <FlexCenter>
                                <SvgToStock />
                            </FlexCenter>

                            <InfoTitle className="pwa tight">
                                {t(
                                    "forms:stock.wizard.collectCylinder.step1Info"
                                )}
                            </InfoTitle>
                            <Spacer size={10} />
                            <HR className="content" />
                            {propertyBag.details && (
                                <>
                                    <ListTitle className="wizard">
                                        {t("screens:technician.companyDetails")}
                                    </ListTitle>
                                    <Section>
                                        <KeyVal className="single">
                                            {t("screens:technician.address")}
                                            <span>
                                                {propertyBag.details.address}
                                            </span>
                                        </KeyVal>
                                        <KeyVal className="single">
                                            {t("screens:technician.city")}
                                            <span>
                                                {propertyBag.details.city}
                                            </span>
                                        </KeyVal>
                                        <KeyVal className="single">
                                            {t("screens:technician.postal")}
                                            <span>
                                                {propertyBag.details.postalCode}
                                            </span>
                                        </KeyVal>
                                        <KeyVal className="single">
                                            {t("screens:technician.country")}
                                            <span>
                                                {
                                                    propertyBag.details
                                                        .countryName
                                                }
                                            </span>
                                        </KeyVal>
                                        <KeyVal className="single">
                                            {t(
                                                "sidebar:label.companyContactPersonName"
                                            )}
                                            <span>
                                                {`${
                                                    propertyBag.details
                                                        .contactPersonFirstName
                                                        ? propertyBag.details
                                                              .contactPersonFirstName
                                                        : ""
                                                } ${
                                                    propertyBag.details
                                                        .contactPersonLastName
                                                        ? propertyBag.details
                                                              .contactPersonLastName
                                                        : ""
                                                } `}
                                            </span>
                                        </KeyVal>
                                        <KeyVal className="single">
                                            {t(
                                                "screens:administratorListing.email"
                                            )}
                                            <span>
                                                {propertyBag.details.email}
                                            </span>
                                        </KeyVal>
                                        <KeyVal className="single">
                                            {t(
                                                "table:headers.client.contact.phone"
                                            )}
                                            <span>
                                                {propertyBag.details.phoneNumber
                                                    ? propertyBag.details
                                                          .phoneNumber
                                                    : ""}
                                            </span>
                                        </KeyVal>
                                    </Section>
                                </>
                            )}
                        </div>
                    );
                },
            },
            {
                name: t("screens:technician.gasInfo"),
                fieldNames: ["numCylinders"],
                onStepSubmit: ({ values }) => {
                    if (values.numCylinders < values.minCylinders) {
                        toast.error(
                            `${t("screens:technician.cylindersToScan")} ${
                                values.minCylinders - values.numCylinders
                            }`
                        );
                        return false;
                    }
                    return true;
                },
                fields: (formik, propertyBag) => {
                    return (
                        <>
                            <div style={{ width: "100%" }}>
                                <Spacer size={15} />
                                <HR className="content" />
                            </div>
                            <Wrapper>
                                <div
                                    style={{
                                        display: formik.values
                                            .showCylinderScanner
                                            ? "none"
                                            : "block",
                                    }}
                                >
                                    <SimpleTable
                                        radioSelect
                                        columns={tableColumns}
                                        data={propertyBag.cylinders}
                                        selected={
                                            formik.values["collectedCylinders"]
                                        }
                                    />
                                    <ScanWrapper className="button">
                                        <InputElement
                                            pwaBig
                                            noLabel
                                            labelText={t(
                                                "forms:cylinderTagId.text"
                                            )}
                                            id="cylinderTagId"
                                            name="cylinderTagId"
                                            type="search"
                                            searchFn={cylinderSearchFn}
                                            wrapperVariant="flex-1"
                                            onChange={(ev) =>
                                                handleCylinderChange(ev, formik)
                                            }
                                        />
                                    </ScanWrapper>
                                </div>
                                {formik.values.showCylinderScanner && (
                                    <StyledQrReader className="wizard">
                                        <QrReader
                                            delay={300}
                                            onError={(error) =>
                                                console.log(error)
                                            }
                                            onScan={(value) =>
                                                handleCylinderScan(
                                                    value,
                                                    formik
                                                )
                                            }
                                            style={{ width: "100%" }}
                                        />
                                    </StyledQrReader>
                                )}

                                <ScanWrapper>
                                    <ScanButton
                                        type="button"
                                        className={
                                            formik.values.showCylinderScanner
                                                ? "close"
                                                : ""
                                        }
                                        onClick={() => {
                                            if (
                                                !formik.values
                                                    .showCylinderScanner
                                            ) {
                                                document.getElementById(
                                                    "cylinderTagId"
                                                ).value="";
                                            }
                                            formik.setFieldValue(
                                                "showCylinderScanner",
                                                !formik.values
                                                    .showCylinderScanner
                                            );
                                        }}
                                    >
                                        <SvgScan className="white" />
                                    </ScanButton>
                                </ScanWrapper>
                            </Wrapper>
                        </>
                    );
                },
            },
        ],
    };
};
export default CollectGasWizard;
