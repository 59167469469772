import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import Wrappers from "../../../components/wrappers/Wrappers";
import helpers from "../../../core/helpers";
import { InputSubmit } from "../../../components/elements/form-elements";
import Flex from "../../../components/layout/flex/Flex";
import ProfilePictureUploader from "../../../components/elements/form-elements/ProfilePictureUploader";
import globals from "../../../globalVars";


const UserProfilePictureForm = ({ formik, isLoading, userData, otherUserData, onDelete, fullName }) => {
    const { t } = useTranslation();
    const fileChangeFormikObject = helpers.getFileUploadFormik(
        formik,
        "profilePictureFile"
    );
    const submitEl = useRef();

    const handleUpload = ()=>{
        if(!submitEl.current) return;
        submitEl.current.click();
    }

    return (
        <Wrappers.FormWrapper variant="nomargin" style={{ width: globals.isMobile ? "100%" : "496px" }}>
            <Wrappers.Wrapper className="md-10">
                <Flex.Container flexWrap="wrap">
                    <ProfilePictureUploader
                        labelText={t("forms:userPicture.text")}
                        name="profilePicture"
                        type="file"
                        id="profilePicture"
                        wrapperVariant="flex-half"
                        accept="image/*"
                        userData={userData}
                        otherUserData={otherUserData}
                        fullName={fullName}
                        autoSubmit={handleUpload}
                        onDelete={onDelete}
                        {...fileChangeFormikObject}
                    />
                </Flex.Container>
            </Wrappers.Wrapper>

            <Wrappers.Wrapper style={{margin:0}}>
                <InputSubmit
                    style={{display:"none"}}
                    ref={submitEl}
                    disable={isLoading}
                    variant="primary inline"
                    value={t("screens:userPictureEdit.button_text")}
                />
            </Wrappers.Wrapper>
        </Wrappers.FormWrapper>
    );
};

export default UserProfilePictureForm;
