import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { ReactComponent as DotSvg } from "../../../assets/dot.svg";

const StyledDot = styled(DotSvg)`
    &.inactive circle {
        fill: #e0e0e0;
    }
`;

const DotWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 12px;

    & > * + * {
        margin-left: 12px;
    }
`;

const SliderWrapper = styled.div`
    width: 100%;
    min-height: 40px;
    height: max-content;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
`;

const SLiderTitle = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    font-size: 14px;

    & .title {
        font-weight: 700;
    }

    & img {
        transform: rotateZ(90deg);
        margin-left: 0px;
        margin-right: 20px;
        cursor: pointer;
    }

    & img.next {
        transform: rotateZ(-90deg);
        margin-left: 20px;
        margin-right: 0px;
    }

    & img.disabled {
        cursor: default;
        filter: grayscale();
    }
`;

const SlideContent = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    overflow-x: auto;

    &.column {
        flex-direction: column;
    }

    &::-webkit-scrollbar {
        display: none;
    }

    & > * {
        flex-grow: 1;
        flex-shrink: 0;
        width: 100%;
    }
`;

const Slide = styled.div`
    width: 100%;
    height: 100%;
`;

const Slider = ({
    title,
    captions = [],
    prefix = "slide-",
    children,
    column = false,
    ...rest
}) => {
    const [state, setState] = useState({
        idx: 0,
        prevEnabled: false,
        nextEnabled: captions.length > 1,
    });

    const firstRender = useRef(true);

    useEffect(() => {
        if(firstRender.current){
            firstRender.current = false;
            return;
        }
        const selector = `#${prefix}${state.idx + 1}`;
        const sliedEl = document.querySelector(selector);
        sliedEl &&
            sliedEl.scrollIntoView({
                behavior: "smooth",
            });
    }, [state.idx]);

    const handleNext = (ev) => {
        if (state.idx < captions.length - 1) {
            setState((state) => ({
                ...state,
                idx: state.idx + 1,
                nextEnabled: state.idx + 1 < captions.length - 1,
                prevEnabled: true,
            }));
        }
    };

    const handlePrev = (ev) => {
        if (state.idx > 0) {
            setState((state) => ({
                ...state,
                idx: state.idx - 1,
                nextEnabled: state.idx - 1 < captions.length - 1,
                prevEnabled: state.idx - 1 > 0,
            }));
        }
    };

    return (
        <SliderWrapper {...rest}>
            <SLiderTitle>
                <div className="title">{title}</div>
                <div>
                    <img
                        className={state.prevEnabled ? "" : "disabled"}
                        src="/images/menu/expand_thin.svg"
                        alt="Previous page"
                        onClick={(ev) => handlePrev(ev)}
                    />
                    <span>{captions[state.idx] || ""}</span>
                    <img
                        className={state.nextEnabled ? "next" : "next disabled"}
                        src="/images/menu/expand_thin.svg"
                        alt="Next page"
                        onClick={(ev) => handleNext(ev)}
                    />
                </div>
            </SLiderTitle>
            <SlideContent className={column ? "column" : ""}  >{children}</SlideContent>
            <DotWrapper>
                {captions.map((capt, idx) => {
                    return <StyledDot key={idx} className={idx===state.idx ? "": "inactive"} />;
                })}
            </DotWrapper>
        </SliderWrapper>
    );
};

export default Slider;
export { Slide };
