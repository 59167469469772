import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Prompt, useHistory, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import { InputSubmit, Select } from "../../../../components/elements/form-elements";
import DatePicker from "../../../../components/elements/form-elements/DatePicker";
import InputElement from "../../../../components/elements/form-elements/InputElement";
import Flex from "../../../../components/layout/flex/Flex";
import ModalDialog, { useModal } from "../../../../components/modal/ModalDialog";
import AccordionWrapper from "../../../../components/toggle-view/AccordionWrapper";
import Wrappers from "../../../../components/wrappers/Wrappers";
import AuthorizeComponent from "../../../../core/AuthorizeComponent";
import Roles from "../../../../core/enums/Roles";
import CompanyService from "../../../../services/Company/CompanyService";
import { toast } from "react-toastify";
import CountryService from "../../../../services/User/CountryService";
import Globals from "../../../../globalVars.js";
import UserService from "../../../../services/User/UserService";
import useAccordionGroup from "../../../../hooks/_shared/useAccordionGroup";

const ScreenWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 56px;

    &.firstTime {
        padding-left: 145px;
    }
`;

let nationalityLookupResult = [];

const nationalityLookup = async (filter) => {
    if (!filter || filter.length < 3) return [];
    const res = await CompanyService.getNationalities(filter);
    if (res.error) {
        toast.error(res.error.message);
        return [];
    }
    nationalityLookupResult = res.data.data;
    return res.data.data.map((r) => r.nationality);
};

const getNationalId = (nationality) => {
    const match = nationalityLookupResult.filter((itm) => itm.nationality === nationality);
    return match.length > 0 ? match[0].id : "";
};

let countryLookupResult = [];
const countryLookup = async (getAllCountries) => {
    if (!getAllCountries || getAllCountries.length < 3) return [];
    const res = await CountryService.getCountryNames(getAllCountries);
    if (res.error) {
        toast.error(res.error.message);
        return [];
    }
    countryLookupResult = res.data.data;
    return res.data.data.map((r) => r.countryName);
};

const getCountryId = (country) => {
    const match = countryLookupResult.filter((c) => c.countryName === country);
    return match.length > 0 ? match[0].id : "";
};

export const CompanyEditForm = ({
    formik,
    isLoading,
    countryData,
    isCompleted,
    fullyRegister,
    resetState,
}) => {
    const { t } = new useTranslation();
    const [isMasterAdmin] = useState(UserService.isInRole(Roles.InstallerCompanyAdmin));
    const history = useHistory();
    let unregisterListener = useRef(() => {});
    const lastPrompt = useRef({
        date: new Date(),
        nextLocation: null,
        shouldLeave: false,
    });

    const isFirstTime = useRouteMatch({
        path: "/company/edit",
        strict: false,
    });
    const [openAccordion, setOpenAccordion] = useAccordionGroup([
        "title",
        "title_companyAddressInformation",
        "title_legalInformation",
        "title_contactInformation",
    ]);
    useEffect(() => {
        if (isCompleted) {
            unregisterListener.current = history.listen((location) => {
                if (location.pathname !== "/company/edit") {
                    fullyRegister();
                }
            });
        }

        return unregisterListener.current;
    }, [isCompleted, history, fullyRegister]);
    useEffect(() => {
        return resetState;
    }, [resetState]);

    const handleBlockedNavigation = (nextLocation, dirty) => {
        if (!dirty || lastPrompt.current.shouldLeave) return true;
        const promptDate = new Date();
        if (
            nextLocation === window.location.pathname ||
            promptDate.getTime() - lastPrompt.current.date.getTime() < 600
        ) {
            return false;
        }
        lastPrompt.current = {
            date: promptDate,
            nextLocation,
            shouldLeave: false,
        };
        toggleOpen();
        return false;
    };

    const { modalProps, toggleOpen } = useModal({
        isWarning: true,
        title: t("actions:dialogs.signOut.title"),
        onConfirm: async (equipment) => {
            lastPrompt.current.shouldLeave = true;
            toggleOpen();
            history.push(lastPrompt.current.nextLocation);
        },
        onCancel: () => {
            lastPrompt.current = {
                date: new Date(),
                nextLocation: "",
                shouldLeave: false,
            };
            toggleOpen();
        },
        confirmText: t("actions:dialogs.signOut.yes"),
    });

    return (
        <ScreenWrapper className={isFirstTime ? "firstTime" : ""}>
            <Prompt
                when={formik.dirty}
                message={(nextLocation) => handleBlockedNavigation(nextLocation, formik.dirty)}
            />
            <Wrappers.FormWrapper style={{ maxWidth: "640px" }}>
                <Flex.Container flexWrap="wrap">
                    <AccordionWrapper
                        title={t("screens:companyEdit.title")}
                        id="title"
                        isOpen={openAccordion}
                        onToggle={setOpenAccordion}
                        variant="overflow top-space inset"
                        style={{ marginTop: "0px" }}
                        noBorder
                    >
                        <Flex.Column className="full">
                            <Flex.Container flexWrap="wrap">
                                <InputElement
                                    labelText={t("forms:companyName.text")}
                                    id="companyName"
                                    autoComplete="new-companyName"
                                    name="companyName"
                                    type="text"
                                    disabled
                                    wrapperVariant="flex-half"
                                    {...formik}
                                />

                                <InputElement
                                    labelText={t("forms:account.companyType")}
                                    id="companyType"
                                    autoComplete="new-companyName"
                                    name="companyType"
                                    type="text"
                                    disabled
                                    wrapperVariant="flex-half"
                                    value={
                                        Globals.isDistributor
                                            ? t("forms:companyType.Distributor")
                                            : t("forms:companyType.InstallationCompany")
                                    }
                                />

                                <InputElement
                                    labelText={t("forms:companyNumber.text")}
                                    id="companyNumber"
                                    autoComplete="new-companyNumber"
                                    name="companyNumber"
                                    type="text"
                                    wrapperVariant="flex-half"
                                    disabled
                                    {...formik}
                                />

                                <InputElement
                                    labelText={t("forms:VAT.text")}
                                    id="vatNumber"
                                    autoComplete="new-vatNumber"
                                    name="vatNumber"
                                    type="text"
                                    disabled
                                    wrapperVariant="flex-half"
                                    {...formik}
                                />
                            </Flex.Container>
                        </Flex.Column>
                    </AccordionWrapper>
                    <AccordionWrapper
                        title={t("screens:companyEdit.title_companyAddressInformation")}
                        id="title_companyAddressInformation"
                        isOpen={openAccordion}
                        onToggle={setOpenAccordion}
                        variant="overflow top-space inset"
                        noBorder
                    >
                        <Flex.Column className="full">
                            <Flex.Container flexWrap="wrap">
                                <InputElement
                                    labelText={t("forms:address.text")}
                                    id="address"
                                    autoComplete="new-address"
                                    name="address"
                                    type="text"
                                    wrapperVariant="flex-half"
                                    {...formik}
                                />

                                <InputElement
                                    labelText={t("forms:postalCode.text")}
                                    id="postalCode"
                                    autoComplete="new-postalCode"
                                    name="postalCode"
                                    type="text"
                                    wrapperVariant="flex-half"
                                    {...formik}
                                />

                                <InputElement
                                    labelText={t("forms:city.text")}
                                    id="city"
                                    autoComplete="new-city"
                                    name="city"
                                    type="text"
                                    wrapperVariant="flex-half"
                                    {...formik}
                                />

                                <Select
                                    id="countryId"
                                    name="countryId"
                                    labelText={t("forms:country.text")}
                                    wrapperVariant="flex-half"
                                    disabled
                                    options={
                                        countryData.countries && countryData.countries.length > 0
                                            ? countryData.countries.map((country) => {
                                                  return {
                                                      key: country.id,
                                                      value: country.id,
                                                      label: country.countryName,
                                                  };
                                              })
                                            : []
                                    }
                                    {...formik}
                                />

                                <InputElement
                                    labelText={t("forms:region.text")}
                                    id="region"
                                    autoComplete="new-region"
                                    name="region"
                                    wrapperVariant="flex-half"
                                    type="text"
                                    {...formik}
                                />
                            </Flex.Container>
                        </Flex.Column>
                    </AccordionWrapper>
                    <AccordionWrapper
                        title={t("screens:companyEdit.title_legalInformation")}
                        id="title_legalInformation"
                        isOpen={openAccordion}
                        onToggle={setOpenAccordion}
                        variant="overflow top-space inset"
                        noBorder
                    >
                        <Flex.Column className="full">
                            <Flex.Container flexWrap="wrap">
                                <InputElement
                                    disabled={!isMasterAdmin}
                                    labelText={t("forms:firstName.text")}
                                    id="legalRepresentativeFirstName"
                                    autoComplete="new-legalRepresentativeFirstName"
                                    name="legalRepresentativeFirstName"
                                    type="text"
                                    wrapperVariant="flex-half"
                                    {...formik}
                                />

                                <InputElement
                                    disabled={!isMasterAdmin}
                                    labelText={t("forms:lastName.text")}
                                    id="legalRepresentativeLastName"
                                    autoComplete="new-legalRepresentativeLastName"
                                    name="legalRepresentativeLastName"
                                    type="text"
                                    wrapperVariant="flex-half"
                                    {...formik}
                                />

                                <DatePicker
                                    disabled={!isMasterAdmin}
                                    labelText={t("forms:account.birthday")}
                                    id="legalRepresentativeBirthDate"
                                    name="legalRepresentativeBirthDate"
                                    filter="past"
                                    wrapperVariant="flex-half"
                                    {...formik}
                                />

                                <InputElement
                                    disabled={!isMasterAdmin}
                                    labelText={t("forms:email.text")}
                                    id="legalRepresentativeEmail"
                                    autoComplete="new-legalRepresentativeEmail"
                                    name="legalRepresentativeEmail"
                                    type="text"
                                    wrapperVariant="flex-half"
                                    {...formik}
                                />

                                <InputElement
                                    disabled={!isMasterAdmin}
                                    labelText={t("forms:address.text")}
                                    id="legalRepresentativeAddress"
                                    name="legalRepresentativeAddress"
                                    type="text"
                                    wrapperVariant="flex-half"
                                    {...formik}
                                />

                                <InputElement
                                    disabled={!isMasterAdmin}
                                    labelText={t("forms:city.text")}
                                    id="legalRepresentativeCity "
                                    name="legalRepresentativeCity"
                                    type="text"
                                    wrapperVariant="flex-half"
                                    {...formik}
                                />

                                <InputElement
                                    disabled={!isMasterAdmin}
                                    labelText={t("forms:postalCode.text")}
                                    id="legalRepresentativePostalCode"
                                    name="legalRepresentativePostalCode"
                                    type="text"
                                    wrapperVariant="flex-half"
                                    {...formik}
                                />

                                <InputElement
                                    disabled={!isMasterAdmin}
                                    labelText={t("forms:region.text")}
                                    id="legalRepresentativeRegion"
                                    name="legalRepresentativeRegion"
                                    type="text"
                                    wrapperVariant="flex-half"
                                    {...formik}
                                />

                                <InputElement
                                    disabled={!isMasterAdmin}
                                    labelText={t("forms:account.nationality")}
                                    id="legalRepresentativeNationalityd"
                                    name="legalRepresentativeNationality"
                                    type="search"
                                    searchFn={nationalityLookup}
                                    itemSelected={(sel) => {
                                        setTimeout(() => {
                                            formik.setFieldValue(
                                                "legalRepresentativeNationalitySelected",
                                                sel
                                            );
                                            formik.setFieldValue(
                                                "legalRepresentativeNationalityId",
                                                getNationalId(sel)
                                            );
                                        }, 250);
                                    }}
                                    wrapperVariant="flex-half"
                                    {...formik}
                                />

                                <InputElement
                                    disabled={!isMasterAdmin}
                                    labelText={t("forms:account.countryOfResidence")}
                                    id="legalRepresentativeCountryOfResidenceId"
                                    name="legalRepresentativeCountryOfResidence"
                                    type="search"
                                    searchFn={countryLookup}
                                    itemSelected={(sel) => {
                                        setTimeout(() => {
                                            formik.setFieldValue(
                                                "legalRepresentativeCountryOfResidenceSelected",
                                                sel
                                            );
                                            formik.setFieldValue(
                                                "legalRepresentativeCountryOfResidenceId",
                                                getCountryId(sel)
                                            );
                                        }, 250);
                                    }}
                                    wrapperVariant="flex-half"
                                    {...formik}
                                />
                            </Flex.Container>
                        </Flex.Column>
                    </AccordionWrapper>
                    <AccordionWrapper
                        title={t("screens:companyEdit.title_contactInformation")}
                        id="title_contactInformation"
                        isOpen={openAccordion}
                        onToggle={setOpenAccordion}
                        variant="overflow top-space inset"
                        noBorder
                    >
                        <Flex.Column className="full">
                            <Flex.Container flexWrap="wrap">
                                <InputElement
                                    labelText={t("forms:email.text")}
                                    id="email"
                                    autoComplete="new-email"
                                    name="email"
                                    type="text"
                                    wrapperVariant="flex-half"
                                    {...formik}
                                />

                                <InputElement
                                    labelText={t("forms:phoneNumber.text")}
                                    id="phoneNumber"
                                    autoComplete="new-phoneNumber"
                                    name="phoneNumber"
                                    type="text"
                                    wrapperVariant="flex-half"
                                    {...formik}
                                />

                                <InputElement
                                    labelText={t("forms:contactFirstName.text")}
                                    name="contactPersonFirstName"
                                    autoComplete="new-contactPersonFirstName"
                                    type="text"
                                    id="contactPersonFirstName"
                                    wrapperVariant="flex-half"
                                    {...formik}
                                />

                                <InputElement
                                    labelText={t("forms:contactLastName.text")}
                                    name="contactPersonLastName"
                                    type="text"
                                    id="contactPersonLastName"
                                    autoComplete="new-contactPersonLastName"
                                    wrapperVariant="flex-half"
                                    {...formik}
                                />
                            </Flex.Container>
                        </Flex.Column>
                    </AccordionWrapper>
                </Flex.Container>
                <AuthorizeComponent
                    roles={[Roles.InstallerCompanyAdmin, Roles.InstallerCompanyUser]}
                >
                    <InputSubmit
                        disabled={isLoading || !formik.dirty}
                        variant="primary"
                        value={t("screens:companyEdit.button_text")}
                    />
                </AuthorizeComponent>
            </Wrappers.FormWrapper>
            <ModalDialog {...modalProps}>{t("actions:dialogs.unsaved.text")}</ModalDialog>
        </ScreenWrapper>
    );
};
