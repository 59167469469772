import styled from "styled-components";

export const Main = styled.main`
    flex: 1;
    display: flex;
    position: relative;
    flex-direction: column;
    height: 100%;
    background: ${props => props.theme.bgIvory};
    overflow: overlay;

    &.authenticated {
    }
`;
