import React from "react";
import InputElement from "../../../../../components/elements/form-elements/InputElement";
import ValidationRule, {buildValidation} from "../../../../../core/validations/ValidationRule";
import {InfoText, InfoTitle, InfoWrapper, RoundInfo} from "../../../../../components/modal/components/WizardStyling";
import TagsService from "../../../../../services/Tags/TagsService";
import ClientService from "../../../../../services/Company/CompanyService";
import FileDisplayWithAction from "../../../../../components/file-processing/FileDisplayWithAction";
import Flex from "../../../../../components/layout/flex/Flex";
import MoneyInputElement from "../../../../../components/elements/form-elements/MoneyInputElement";
const validQR = (t) => {
    return buildValidation({
        tagId: ValidationRule.isStringRule()
            .isUUID(t("forms:unit.uuid.invalid"))
            .required(t("forms:unit.unitTagId.required")).rule
    });
}

const defaultInitialValues = {
    tagId: "",
    StockListHeaderActions:"",
    refrigerantTypeCode:"",
    filledQuantity:"",
    size:"",
    image:""
}

const MoveToStockWizard = (
    t,
    searchFn = () => {
    },
    isInCompanyStock = true) => {
    return {
        title: isInCompanyStock ? t('forms:stock.wizard.moveToInstallerStock.title') : t('forms:stock.wizard.moveToCompanyStock.title'),
        initialValues: defaultInitialValues,
        steps: [
            {
                name: t('forms:stock.wizard.moveToCompanyStock.step1'),
                fieldNames: [
                    "tagId"
                ],
                validation: validQR(t),
                fields: (formik) => (
                    <>
                        <InfoWrapper>
                            <RoundInfo
                                alt=""
                                src="/images/menu/green-info.svg"
                            />
                            <div>
                                <InfoTitle>
                                    {
                                        isInCompanyStock ?
                                            t("forms:stock.wizard.moveToInstallerStock.infoTitle")
                                            :
                                            t("forms:stock.wizard.moveToCompanyStock.infoTitle")
                                    }

                                </InfoTitle>
                                <InfoText>
                                    {
                                        isInCompanyStock ?
                                            t("forms:stock.wizard.moveToInstallerStock.infoText")
                                            :
                                            t("forms:stock.wizard.moveToCompanyStock.infoText")
                                    }
                                </InfoText>
                            </div>
                        </InfoWrapper>
                        <InputElement
                            labelText={t("forms:cylinderTagId.text")}
                            id="tagId"
                            name="tagId"
                            type="search"
                            searchFn={searchFn}
                            wrapperVariant="flex-1"
                            {...formik}
                        />
                    </>
                )
            },
            {
                name: t('forms:stock.wizard.moveToCompanyStock.step2'),
                fieldNames: [
                    "size",
                    "refrigerantTypeCode",
                    "filledQuantity",
                    "image"
                ],
                onStepRender: async (props) => {
                    const tagId = props.value["tagId"];
                    let tagDetails = await TagsService.identifyTag(tagId);
                    let currentSkuId;
                    let currentCylinder;
                    let cylinderData;
                    if (tagDetails && tagDetails.length > 0) {
                        currentSkuId = tagDetails[0].skuId;
                        currentCylinder = tagDetails[0].cylinderId;
                    }
                    if (currentSkuId) {
                        props.formik.setFieldValue("skuId", currentSkuId);
                        props.updatePropertyBag({
                            skuId: currentSkuId
                        })
                    }

                    if (currentCylinder) {
                        cylinderData = await ClientService.getCompanyCylinderById(currentCylinder)
                        props.updatePropertyBag({
                            cylinderImage: cylinderData.data[0].imageUrl,
                            filledQuantity: cylinderData.data[0].filledQuantity.toFixed(2) + " kg",
                            size: cylinderData.data[0].size,
                            refrigerantTypeCode: cylinderData.data[0].refrigerantTypeCode
                        });
                        props.formik.setFieldValue("cylinderImage", cylinderData.data[0].imageUrl);
                        props.formik.setFieldValue("filledQuantity", cylinderData.data[0].filledQuantity);
                        props.formik.setFieldValue("size", cylinderData.data[0].size);
                        props.formik.setFieldValue("refrigerantTypeCode", cylinderData.data[0].refrigerantTypeCode);
                    }
                },
                fields: (formik, propertyBag) => {
                    return (
                        <>
                            <InfoWrapper>
                                <RoundInfo
                                    alt=""
                                    src="/images/menu/green-info.svg"
                                />
                                <div>
                                    <InfoTitle>
                                        {isInCompanyStock
                                            ? t(
                                                  "forms:stock.wizard.moveToInstallerStock.infoTitle"
                                              )
                                            : t(
                                                  "forms:stock.wizard.moveToCompanyStock.infoTitle"
                                              )}
                                    </InfoTitle>
                                    <InfoText>
                                        {isInCompanyStock
                                            ? t(
                                                  "forms:stock.wizard.moveToInstallerStock.infoText"
                                              )
                                            : t(
                                                  "forms:stock.wizard.moveToCompanyStock.infoText"
                                              )}
                                    </InfoText>
                                </div>
                            </InfoWrapper>

                            <Flex.Container justifyContent="space-between">
                                <Flex.Column style={{ maxWidth: "50%" }}>
                                    <MoneyInputElement
                                        type="number"
                                        name="size"
                                        id="size"
                                        step="0.01"
                                        disabled={true}
                                        labelText={t("forms:cylinderSize.text")}
                                        wrapperVariant={"flex-1 normal"}
                                        value={propertyBag.size?.toFixed(2)}
                                        currencyLabel="kg"
                                        formik={formik}
                                    />
                                    <Flex.Container style={{ paddingTop: 8 }}>
                                        <InputElement
                                            labelText={t(
                                                "forms:cylinder.cylinderGas"
                                            )}
                                            id="refrigerantTypeCode"
                                            name="refrigerantTypeCode"
                                            placeholder={
                                                propertyBag.refrigerantTypeCode
                                                    ? ""
                                                    : t(
                                                          "exceptions:validation.NotAssigned"
                                                      )
                                            }
                                            type="text"
                                            wrapperVariant="flex-2"
                                            disabled
                                            {...formik}
                                        />
                                        <InputElement
                                            labelText={t(
                                                "forms:cylinder.quantity"
                                            )}
                                            id="filledQuantity"
                                            name="filledQuantity"
                                            type="text"
                                            disabled
                                            wrapperVariant="flex-2"
                                            value={propertyBag.filledQuantity}
                                            {...formik}
                                        />
                                    </Flex.Container>
                                </Flex.Column>

                                {propertyBag.cylinderImage && (
                                    <FileDisplayWithAction
                                        fileType="image"
                                        filePath={propertyBag.cylinderImage}
                                        title={t(
                                            "forms:cylinderFileUpload.title"
                                        )}
                                        labelText=""
                                        wrapperVariant="double-height flex-2"
                                    />
                                )}
                            </Flex.Container>
                        </>
                    );
                }
            }
        ]
    }
};

export default MoveToStockWizard
