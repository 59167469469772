import React from "react";
import { useTranslation } from "react-i18next";
import ClearableInput from "../../../../components/elements/form-elements/ClearableInput";
import Label from "../../../../components/elements/form-elements/Label";
import Select from "../../../../components/elements/form-elements/Select";
import Filters from "../../../../components/filter/Filters";
import useDebouncedCallback from "../../../../hooks/_shared/useDebouncedCallback";
import { DateTimePicker } from "../../../../components/elements/form-elements/DatePicker";
import helpers from "../../../../core/helpers";

const ReclaimMarketFilter = ({
    filterState={},
    dispatch,
    actionTypes,
    gasTypes = [],
}) => {
    const { t } = useTranslation("filters");
    const [quantityToChanged] = useDebouncedCallback((text) => {
        text
            ? dispatch({ type: actionTypes.SET_GAS_QUANTITY_TO, payload: text.trim() })
            : dispatch({
                  type: actionTypes.RESET_GAS_QUANTITY_TO,
                  payload: text,
              });
    });

    const [quantityFromChanged] = useDebouncedCallback((text) => {
        text
            ? dispatch({
                  type: actionTypes.SET_GAS_QUANTITY_FROM,
                  payload: text.trim(),
              })
            : dispatch({
                  type: actionTypes.RESET_GAS_QUANTITY_FROM,
                  payload: text,
              });
    });


    return (
        <Filters.Wrapper className="filters-wrapper">
            <Filters.Item>
                <Select
                    key="gasType"
                    name="gasType"
                    errors={[]}
                    touched={[]}
                    defaultItem={{
                        key: "all",
                        value: "",
                        label: t("gas.gasType"),
                    }}
                    defaultValue={
                        filterState.gasType !== undefined
                            ? filterState.gasType
                            : ""
                    }
                    options={
                        gasTypes
                            ? gasTypes.map((gas) => ({
                                  key: gas.code,
                                  value: gas.code,
                                  label: gas.code,
                              }))
                            : []
                    }
                    handleChange={(evt) =>
                        evt.currentTarget.value
                            ? dispatch({
                                  type: actionTypes.SET_GAS_TYPE,
                                  payload: evt.currentTarget.value,
                              })
                            : dispatch({
                                  type: actionTypes.RESET_GAS_TYPE,
                                  payload: evt.currentTarget.value,
                              })
                    }
                    className="filters"
                    labelText={t("gas.gasType")}
                    labelClassName="sr-only"
                />
            </Filters.Item>
            <Filters.Group>
                <Filters.Item>
                    <Label htmlFor="quantityFrom" className="sr-only">
                        {t("gas.quantityFrom")}
                    </Label>

                    <ClearableInput
                        key="quantityFrom"
                        name="quantityFrom"
                        type="number"
                        defaultValue={
                            filterState.gasQuantityFrom
                        }
                        onChange={(evt) => {
                            quantityFromChanged(evt.currentTarget.value);
                        }}
                        placeholder={t("gas.quantityFrom")}
                        title={t("gas.quantityFrom")}
                        className="filters left group"
                    />
                </Filters.Item>

                <Filters.Item>
                    <Label htmlFor="quantityTo" className="sr-only">
                        {t("gas.quantityTo")}
                    </Label>
                    <ClearableInput
                        key="quantityTo"
                        name="quantityTo"
                        type="number"
                        defaultValue={
                            filterState.gasQuantityTo
                        }
                        onChange={(evt) => {
                            quantityToChanged(evt.currentTarget.value);
                        }}
                        placeholder={t("gas.quantityTo")}
                        title={t("gas.quantityTo")}
                        className="filters right group"
                    />
                </Filters.Item>
            </Filters.Group>
            <Filters.Group>
                <Filters.Item>
                    <DateTimePicker
                        labelText={t("forms:extractionDate.from")}
                        noLabel
                        placeholder={t("forms:extractionDate.from")}
                        id="offerCreationDateTimeFrom"
                        name="offerCreationDateTimeFrom"
                        className="filters big left group"
                        value={filterState.dateFrom}
                        maxDate={filterState?.dateTo}
                        handleChange={(date) => {
                            dispatch({
                                type:
                                    date === ""
                                        ? actionTypes.RESET_OFFER_CREATION__DATE_FROM
                                        : actionTypes.SET_OFFER_CREATION__DATE_FROM,
                                payload:
                                    date === ""
                                        ? date
                                        : helpers.dateToIsoDateString(
                                              new Date(date)
                                          ),
                            });
                        }}
                    />
                </Filters.Item>
                <Filters.Item>
                    <DateTimePicker
                        labelText={t("forms:extractionDate.to")}
                        noLabel
                        placeholder={t("forms:extractionDate.to")}
                        id="offerCreationDateTimeTo"
                        name="offerCreationDateTimeTo"
                        className="filters big right group"
                        value={filterState.dateTo}
                        minDate={filterState?.dateFrom}
                        handleChange={(date) =>
                            dispatch({
                                type:
                                    date === ""
                                        ? actionTypes.RESET_OFFER_CREATION__DATE_TO
                                        : actionTypes.SET_OFFER_CREATION__DATE_TO,
                                payload:
                                    date === ""
                                        ? date
                                        : helpers.dateToIsoDateString(date),
                            })
                        }
                    />
                </Filters.Item>
            </Filters.Group>
        </Filters.Wrapper>
    );
};

export default React.memo(ReclaimMarketFilter);
