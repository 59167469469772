import { useEffect, useState } from "react";
import useStateModule from "../useStateModule";
import leftNavigationStateModule from "./leftNavigationStateModule";

const useActiveNavigationPath = (path) => {
    const {activePath, setActivePath} = useStateModule(leftNavigationStateModule);
    const [newPath] = useState(path);

    useEffect(() => {
        if(newPath) {
            setActivePath(newPath);
        }
        return () => setActivePath(null)
    }, [setActivePath, newPath]);

    return activePath;
}

export default useActiveNavigationPath;
