import React from "react";
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import globals from "../../globalVars";

const StyledToastContainer = styled(ToastContainer)`
    .Toastify__toast-container {
        text-align: center;
        position: relative;
    }
    .Toastify__toast {
        border-radius: 18px;
        padding: 10px 40px 10px 10px;
        width: auto;
        min-width: 460px;
        min-height: 36px;
        box-shadow: ${(props) => props.theme.notifierShadow};

        @media ${(p) => p.theme.device.mobile} {
            width: 100%;
            min-width: 100%;
            border-radius: 0px;
        }
    }
    .Toastify__toast--error {
    }
    .Toastify__toast--warning {
    }
    .Toastify__toast--success {
        background: ${(props) => props.theme.bgActive};
    }
    .Toastify__toast-body {
        font-size: 14px;
    }
    .Toastify__progress-bar {
    }

    .Toastify__close-button {
        opacity: 1;
        position: absolute;
        top: 10px;
        right: 10px;
    }
`;

const Notifier = () => {
    return (
        <StyledToastContainer
            position="top-center"
            transition={Slide}
            hideProgressBar={true}
            style={{ width: globals.isMobile ? "100%" : "auto" }}
        />
    );
};

export default Notifier;
