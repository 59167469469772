import React from "react";
import styled from "styled-components";
import Radio from "./Radio";

const ListWrapper = styled.div`
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    list-style: none;
    padding: 0;

`;

const RadioList = ({ items, className, style, name, onSelect, ...rest }) => {
    const handleClick = (ev)=> {
        const value = ev.currentTarget.value;
        onSelect && onSelect(value);
    }
    
    return (
        <ListWrapper className={className} style={style}>
            {items.map((item, idx) => (
                <Radio onClick={handleClick} name={name} list abs key={idx} labelText={item.text} value={item.value} {...rest} />
            ))}
        </ListWrapper>
    );
};

export default RadioList;
