import React, { useEffect, useMemo, useCallback, useRef } from "react";
import globals from "../../../globalVars";
import ListingPage from "../../../components/data/ListingPage";
import useStateModule from "../../../core/state/useStateModule";
import { useTranslation } from "react-i18next";
import { ShortDateCell } from "../../../components/data/DateCell";
import useImmerReducer from "../../../hooks/_shared/useImmerReducer";
import usePageChange from "../../../hooks/_shared/usePageChange";
import installerStockListingStateModule from "../../../core/state/stock/installerStockListingStateModule";
import FilterMenu from "../FilterMenu";
import { Select } from "../../../components/elements/form-elements";
import InputElement from "../../../components/elements/form-elements/InputElement";
import { DateTimePicker } from "../../../components/elements/form-elements/DatePicker";
import useSharedState from "../../../hooks/_shared/useSharedState";
import {
    StyledFilter,
    StyledArrow,
    StyledBack,
    StyledClose,
} from "./StyledSvg";

import {
    ScreenWrapper,
    ScreenHeader,
    ListTitle,
    FlexForm,
    FilterClear,
    FilterWrapper, HR
} from "./StyledElements";

const actionTypes = {
    SET_PAGING: "SET_PAGING",
    SET_SORT: "SET_SORT",
    RESET_SORT: "RESET_SORT",
    SET_FILTERS: "SET_FILTERS",
    RESET_FILTERS: "RESET_FILTERS",
};

const filterReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.SET_PAGING:
            state.pageSize = action.payload.pageSize;
            state.page = action.payload.pageIndex + 1;
            break;
        case actionTypes.SET_SORT:
            state.orderColumn = action.payload[0].id;
            state.descending = action.payload[0].desc;
            break;
        case actionTypes.RESET_SORT:
            state.orderColumn = "extractionDateTime";
            state.descending = true;
            break;
        case actionTypes.SET_FILTERS:
            state.filter.refrigerantGasTypeCode = action.payload.gasType;
            state.filter.quantityTo = action.payload.quantityTo;
            state.filter.quantityFrom = action.payload.quantityFrom;
            state.filter.extractFrom = action.payload.extractFrom;
            state.filter.extractTo = action.payload.extractTo;
            break;
        case actionTypes.RESET_FILTERS:
            delete state.filter.refrigerantGasTypeCode;
            delete state.filter.quantityTo;
            delete state.filter.quantityFrom;
            delete state.filter.extractFrom;
            delete state.filter.extractTo;
            break;
        default:
            return state;
    }
};

const TechnicianStockListing = (props) => {
    const [globalState] = useSharedState("global");
    const {
        stockItems,
        isLoading,
        error,
        loadStockItems,
        loadGasTypes,
        gasTypesData,
    } = useStateModule(installerStockListingStateModule);

    const [filterState, dispatch] = useImmerReducer(filterReducer, {
        filter: {
            locationStatusName: "InstallerStock",
            marketplaceStatusName: "OnStock",
        },
        page: 1,
        pageSize: globals.maxListingItemsPerPage,
        orderColumn: "extractionDateTime",
        descending: true,
    });

    const { t } = useTranslation("screens");

    const filterMenuControl = {};

    useEffect(() => {
        loadStockItems(filterState);
    }, [loadStockItems, filterState]);

    useEffect(() => {
        loadGasTypes();
    }, [loadGasTypes]);

    const columns = useMemo(() => {
        const columns = [
            {
                Header: t("table:headers.gasType"),
                accessor: "refrigerantGasTypeCode",
            },
            {
                Header: t("table:headers.gasQuantity"),
                accessor: "quantity",
                className:"right",
                Cell: ({ cell: { value } }) => value.toFixed(2) + " kg",
            },
            {
                Header: t("stockListing.headers.lastExtraction"),
                accessor: "extractionDateTime",
                className:"right",
                Cell: ShortDateCell,
            },
            {
                Header: "",
                accessor: "skuId",
                className: "right-arrow",
                Cell: ({ cell: { value } }) => <StyledArrow />,
                disableSortBy: true,
            },
        ];
        return columns;
    }, [filterState.filter, t]);

    const filterData = useRef({
        gasType: "",
        quantityFrom: "",
        quantityTo: "",
        extractFrom: "",
        extractTo: "",
    });

    const tempFilterData = useRef({});

    const hasFilters = () =>
        filterData.current.gasType ||
        filterData.current.quantityFrom ||
        filterData.current.quantityTo ||
        filterData.current.extractFrom ||
        filterData.current.extractTo;

    const filterItems = () => {
        tempFilterData.current = {};
        return (
            <FlexForm autoComplete="off">
                <Select
                    className="filters big"
                    labelText={t("forms:gasType.text")}
                    name="refrigerantId"
                    id="refrigerantId"
                    wrapperVariant="flex-1"
                    defaultItem={{
                        key: "",
                        value: "",
                        label: "Please select a gas type",
                    }}
                    options={
                        gasTypesData.gasTypes &&
                        gasTypesData.gasTypes.map((gasType) => {
                            return {
                                key: gasType.code,
                                value: gasType.code,
                                label: gasType.code,
                            };
                        })
                    }
                    defaultValue={filterData.current.gasType}
                    handleChange={(evt) =>
                        (tempFilterData.current.gasType =
                            evt.currentTarget.value)
                    }
                />

                <InputElement
                    labelText={t("forms:quantity.from")}
                    id="quantityFrom"
                    name="quantityFrom"
                    wrapperVariant="flex-2"
                    type="number"
                    className="filters big left"
                    defaultValue={filterData.current.quantityFrom}
                    handleChange={(evt) => {
                        tempFilterData.current.quantityFrom =
                            evt.currentTarget.value;
                    }}
                />

                <InputElement
                    labelText={t("forms:quantity.to")}
                    id="quantityTo"
                    name="quantityTo"
                    wrapperVariant="flex-2"
                    type="number"
                    className="filters big right"
                    defaultValue={filterData.current.quantityTo}
                    handleChange={(evt) => {
                        tempFilterData.current.quantityTo =
                            evt.currentTarget.value;
                    }}
                />

                <DateTimePicker
                    labelText={t("forms:extractionDate.from")}
                    native
                    id="extractFrom"
                    name="extractFrom"
                    wrapperVariant={"flex-2"}
                    className="filters big left"
                    maxPickerId="extractTo"
                    value={
                        filterData.current ? filterData.current.extractFrom : ""
                    }
                    maxDate={
                        filterData.current ? filterData.current.extractTo : null
                    }
                    handleChange={(ev) =>
                        (tempFilterData.current.extractFrom = ev.target.value)
                    }
                />
                <DateTimePicker
                    labelText={t("forms:extractionDate.to")}
                    native
                    id="extractTo"
                    name="extractTo"
                    wrapperVariant={"flex-2"}
                    className="filters big right"
                    minPickerId="extractFrom"
                    value={
                        filterData.current ? filterData.current.extractTo : ""
                    }
                    minDate={
                        filterData.current ? filterData.current.extractFrom : null
                    }
                    handleChange={(ev) =>
                        (tempFilterData.current.extractTo = ev.target.value)
                    }
                />
            </FlexForm>
        );
    };

    const applyFilters = () => {
        Object.assign(filterData.current, tempFilterData.current);
        dispatch({
            type: actionTypes.SET_FILTERS,
            payload: filterData.current,
        });
    };

    const clearFilters = () => {
        filterData.current = {
            gasType: "",
            quantityFrom: "",
            quantityTo: "",
            extractFrom: "",
            extractTo: "",
        };
        dispatch({
            type: actionTypes.RESET_FILTERS,
        });
    };

    const clearFilter = (name) => {
        switch (name) {
            case "type":
                filterData.current.gasType = "";
                break;
            case "quantity":
                filterData.current.quantityFrom = "";
                filterData.current.quantityTo = "";
                break;
            case "date":
                filterData.current.extractFrom = "";
                filterData.current.extractTo = "";
                break;
            default:
                break;
        }
        dispatch({
            type: actionTypes.SET_FILTERS,
            payload: filterData.current,
        });
    };

    const showFilter = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        if(!globalState.hasValidUserCertificate) return;
        filterMenuControl.setState({
            isOpen: true,
            hasFilters: hasFilters(),
            filterItems: filterItems(),
            applyFn: applyFilters,
            clearFn: clearFilters,
            text: hasFilters()
                ? t("screens:stockListing.filters.clearFilters")
                : t("screens:stockListing.filters.filterMyStock"),
        });
    };

    const onSelect = useCallback((row) => {
        props.onSelect && props.onSelect(row.original);
    }, []);

    const pageChange = usePageChange(dispatch, filterState);

    const sortChange = (sortBy) => {
        sortBy.length > 0
            ? dispatch({ type: actionTypes.SET_SORT, payload: sortBy })
            : dispatch({ type: actionTypes.RESET_SORT });
    };

    const currentSortBy = useMemo(() => {
        return [{ id: filterState.orderColumn, desc: filterState.descending }];
    }, [filterState.orderColumn, filterState.descending]);

    const curFilters = filterData.current;

    return (
        <ScreenWrapper>
            <FilterMenu control={filterMenuControl} />
            <ScreenHeader>
                <StyledBack className="disabled" />
                <FilterWrapper>
                    {curFilters.gasType && (
                        <FilterClear onClick={() => clearFilter("type")}>
                            {t("screens:stockListing.filters.type")}{" "}
                            <StyledClose />
                        </FilterClear>
                    )}

                    {(curFilters.quantityFrom || curFilters.quantityTo) && (
                        <FilterClear onClick={() => clearFilter("quantity")}>
                            {t("screens:stockListing.filters.quantity")}{" "}
                            <StyledClose />
                        </FilterClear>
                    )}

                    {(curFilters.extractFrom || curFilters.extractTo) && (
                        <FilterClear onClick={() => clearFilter("date")}>
                            {t("screens:stockListing.filters.date")}{" "}
                            <StyledClose />
                        </FilterClear>
                    )}

                    <StyledFilter className={globalState.hasValidUserCertificate ? "":"disabled"} onClick={showFilter} />
                </FilterWrapper>
            </ScreenHeader>
            <HR />
            <ListTitle>{t("screens:technician.cylinders")}</ListTitle>
            <ListingPage
                pwa
                noHeader
                columns={columns}
                tableState={{
                    data: stockItems.data,
                    loading: isLoading,
                    error: error,
                }}
                paging={{
                    enablePaging: true,
                    pageCount: stockItems.totalPages,
                    pageSize: stockItems.pageSize,
                    onPageChange: pageChange,
                    currentPageIndex: stockItems.currentPage - 1,
                }}
                sorting={{
                    enableSorting: true,
                    onSortByChange: sortChange,
                    currentSortBy: currentSortBy,
                }}
                onSelect={onSelect}
                noDataText={t("screens:stockListing.notification")}
            />
        </ScreenWrapper>
    );
};

export default TechnicianStockListing;
