import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import SidebarField from "./SidebarField";
import helpers from "../../../core/helpers";

const Link = styled(NavLink)`
    color: ${(p) => p.theme.secondaryColor};
    width: 100%;
    display: flex;
    justify-content: end;
    margin-top: 32px;
    cursor: pointer;
`;

const SidebarLicence = (props) => {
    const { licence, userId, companyId } = props;
    const hasLicence = licence && licence.length > 0;
    const lastLicence = hasLicence ? licence[0] : {};
    const { t } = useTranslation("sidebar");
    let expDateString = t("label.neverExpires");
    const expDate = hasLicence ? new Date(licence[0].licenceExpiryDate) : null;
    if (expDate && expDate < new Date("1/1/3000"))
        expDateString = helpers.toLocalDateIgnoreTime(
            lastLicence.licenceExpiryDate
        );

    return (
        <>
            {hasLicence ? (
                <Fragment>
                    <SidebarField
                        label={t("label.licenceNumber")}
                        value={lastLicence.licenceNumber}
                    />
                    <SidebarField
                        label={t("label.licenceExpiryDate")}
                        value={expDateString}
                    />
                    <SidebarField
                        label={t("label.status")}
                        value={t(
                            `certificateStatus.${lastLicence.licenceStatus}`
                        )}
                        dangerousHtml={true}
                    />
                    {userId && (
                        <Link
                            to={`/admin/users/info/${userId.userId}?companyId=${userId.companyId}`}
                        >
                            {t("label.gotoCertDetails")}
                        </Link>
                    )}
                    {companyId && (
                        <Link to={`/admin/companies/certificates/${companyId}`}>
                            {t("label.gotoCertDetails")}
                        </Link>
                    )}
                </Fragment>
            ) : (
                <p style={{paddingLeft:"20px"}}>{t("title.noInformation")}</p>
            )}
        </>
    );
};

export default SidebarLicence;
