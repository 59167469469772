import React from "react";
import styled from "styled-components";
import InputElement from "../../../../../components/elements/form-elements/InputElement";
import {
    InfoTitle,
    InfoWrapper,
    RoundInfo,
} from "../../../../../components/modal/components/WizardStyling";
import ValidationRule, {
    buildValidation,
} from "../../../../../core/validations/ValidationRule";

const initialValues = {
    files: "",
};

const KYCValidation = (t) => {
    return buildValidation({
        files: ValidationRule.isStringRule().required(
            t("forms:kyc.documentFiles.required")
        ).rule,
    });
};

const StyledHtml = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-weight: 600;

    & a {
        color: #2294d2;
        cursor: pointer;
    }
`;

const StyledDiv = styled.div`
    color: #8e8e8e;
    line-height: 18px;
    & b {
        color: ${(p) => p.theme.primaryColor};
    }
`;

const StyledList = styled.ul`
    color: ${(p) => p.theme.primaryColor};
    margin: 0;
    padding: 0 0 0 56px;
`;

const Spacer = styled.div`
    width: 100%;
    height: ${(p) => p.size || 1}px;
`;

function markup(markup) {
    return { __html: markup };
}

const Bullet = styled.span`
    display: list-item;
    &:after {
        content: ":";
    }
    min-width: 100px;
`;

const RequirementContainer = styled.div`
    padding: 10px 0px 20px 30px;
`;

const RequirementRow = styled.div`
    display: flex;
`;

const RequirementLabel = styled.div`
    padding: 0px 10px;
`;
const RequirementDescription = styled.div`
    color: ${(props) => props.theme.primaryColor};
`;

const KYCWizard = (t, documentType, showGuide) => {
    const docName = t(`forms:kyc.docType.${documentType}`);
    return {
        title: docName,
        initialValues: initialValues,
        steps: [
            {
                name: t(
                    `${t("forms:kyc.create")} ${docName} ${t(
                        "forms:kyc.documentFiles.uploadTitle"
                    )}`
                ),
                validation: KYCValidation(t),
                fieldNames: ["files"],
                fields: (formik) => (
                    <>
                        <InfoWrapper className="tight">
                            <RoundInfo
                                alt=""
                                src="/images/menu/green-info.svg"
                            />
                            <div>
                                <InfoTitle>
                                    {t(`forms:kyc.createDoc.${documentType}`)}
                                </InfoTitle>
                                {documentType === "IDENTITY_PROOF" && (
                                    <StyledHtml>
                                        <StyledDiv
                                            dangerouslySetInnerHTML={markup(
                                                t("forms:kyc.info.idProof")
                                            )}
                                        />

                                        <Spacer size={45} />
                                        <div>
                                            {t("forms:kyc.info.pleaseRead")}{" "}
                                            <a onClick={() => showGuide()}>
                                                {t("forms:kyc.info.guidelines")}
                                            </a>
                                        </div>
                                    </StyledHtml>
                                )}
                                {documentType === "ARTICLES_OF_ASSOCIATION" && (
                                    <>
                                        <StyledHtml>
                                            <StyledDiv
                                                dangerouslySetInnerHTML={markup(
                                                    t(
                                                        "forms:kyc.info.certified"
                                                    )
                                                )}
                                            />

                                            <Spacer size={20} />
                                            <StyledDiv
                                                dangerouslySetInnerHTML={markup(
                                                    t(
                                                        "forms:kyc.info.acceptedDocumentsByCountry"
                                                    )
                                                )}
                                            />
                                            <RequirementContainer>
                                                <RequirementRow>
                                                    <RequirementLabel>
                                                        {" "}
                                                        <Bullet>
                                                            {t(
                                                                "forms:kyc.info.countries.czechRepublic"
                                                            )}
                                                        </Bullet>
                                                    </RequirementLabel>
                                                    <RequirementDescription>
                                                        {t(
                                                            "forms:kyc.info.articleOfAssociationCountry.czechRepublic"
                                                        )}
                                                    </RequirementDescription>
                                                </RequirementRow>
                                                <RequirementRow>
                                                    <RequirementLabel>
                                                        {" "}
                                                        <Bullet>
                                                            {t(
                                                                "forms:kyc.info.countries.hungary"
                                                            )}
                                                        </Bullet>
                                                    </RequirementLabel>
                                                    <RequirementDescription>
                                                        {t(
                                                            "forms:kyc.info.articleOfAssociationCountry.hungary"
                                                        )}
                                                    </RequirementDescription>
                                                </RequirementRow>
                                                <RequirementRow>
                                                    <RequirementLabel>
                                                        {" "}
                                                        <Bullet>
                                                            {t(
                                                                "forms:kyc.info.countries.slovakia"
                                                            )}
                                                        </Bullet>
                                                    </RequirementLabel>
                                                    <RequirementDescription>
                                                        {t(
                                                            "forms:kyc.info.articleOfAssociationCountry.slovakia"
                                                        )}
                                                    </RequirementDescription>
                                                </RequirementRow>
                                            </RequirementContainer>
                                        </StyledHtml>
                                        <Spacer size={20} />
                                    </>
                                )}
                                {documentType === "REGISTRATION_PROOF" && (
                                    <>
                                        <StyledHtml>
                                            <StyledDiv
                                                dangerouslySetInnerHTML={markup(
                                                    t("forms:kyc.info.regProof")
                                                )}
                                            />

                                            <Spacer size={20} />
                                            <StyledDiv
                                                dangerouslySetInnerHTML={markup(
                                                    t(
                                                        "forms:kyc.info.acceptedDocumentsByCountry"
                                                    )
                                                )}
                                            />

                                            <RequirementContainer>
                                                <RequirementRow>
                                                    <RequirementLabel>
                                                        {" "}
                                                        <Bullet>
                                                            {t(
                                                                "forms:kyc.info.countries.czechRepublic"
                                                            )}
                                                        </Bullet>
                                                    </RequirementLabel>
                                                    <RequirementDescription>
                                                        {t(
                                                            "forms:kyc.info.regProofCountry.czechRepublic"
                                                        )}
                                                    </RequirementDescription>
                                                </RequirementRow>
                                                <RequirementRow>
                                                    <RequirementLabel>
                                                        {" "}
                                                        <Bullet>
                                                            {t(
                                                                "forms:kyc.info.countries.hungary"
                                                            )}
                                                        </Bullet>
                                                    </RequirementLabel>
                                                    <RequirementDescription>
                                                        {t(
                                                            "forms:kyc.info.regProofCountry.hungary"
                                                        )}
                                                    </RequirementDescription>
                                                </RequirementRow>
                                                <RequirementRow>
                                                    <RequirementLabel>
                                                        {" "}
                                                        <Bullet>
                                                            {t(
                                                                "forms:kyc.info.countries.slovakia"
                                                            )}
                                                        </Bullet>
                                                    </RequirementLabel>
                                                    <RequirementDescription>
                                                        {t(
                                                            "forms:kyc.info.regProofCountry.slovakia"
                                                        )}
                                                    </RequirementDescription>
                                                </RequirementRow>
                                            </RequirementContainer>
                                        </StyledHtml>
                                    </>
                                )}
                                {documentType ===
                                    "SHAREHOLDER_DECLARATION" && (
                                    <StyledHtml>
                                        <StyledDiv
                                            dangerouslySetInnerHTML={markup(
                                                t("forms:kyc.info.shareholder")
                                            )}
                                        />
                                        <Spacer size={10} />
                                        <StyledDiv
                                            dangerouslySetInnerHTML={markup(
                                                t("forms:kyc.info.shareholder2")
                                            )}
                                        />
                                        <Spacer size={10} />
                                        <StyledDiv
                                            dangerouslySetInnerHTML={markup(
                                                t("forms:kyc.info.shareholder3")
                                            )}
                                        />
                                        <Spacer size={25} />
                                    </StyledHtml>
                                )}
                            </div>
                        </InfoWrapper>
                        <InputElement
                            labelText=""
                            name="files"
                            id="files"
                            type="singlefile"
                            title={docName}
                            wrapperVariant="flex-1"
                            accept="image/*, application/pdf"
                            {...formik}
                        />
                    </>
                ),
            },
        ],
    };
};

export default KYCWizard;
