import { useState } from "react";

const useAccordionGroup = (ids = null) => {
    const [state, setState] = useState(ids);

    const setOpen = (id, isOpen) => {
        if (Array.isArray(state)) {
            if (isOpen) {
                if (!state.includes(id)) setState((s) => [...s, id]);
            } else setState((s) => s.filter((e) => e !== id));
        } else setState(isOpen ? id : null);
    };
    return [state, setOpen];
};

export default useAccordionGroup;
