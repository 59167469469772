import { useMemo } from "react";
import UserService from "../services/User/UserService";

const useAuthorization = ({
    allowedRoles,
    allowedUserId = undefined,
    overrideUserIdRoles = undefined
}) => {

    const userRoles = UserService.getUserRoles();
    const currentUserId = useMemo(
        () => UserService.getUserDetailsFromToken().UserId,
        []
    );

    if (overrideUserIdRoles) {
        const canAccessRoleOverride =
            overrideUserIdRoles &&
            userRoles.some(role => overrideUserIdRoles.includes(role));
        if (canAccessRoleOverride) {
            return true;
        }
    }

    if (allowedUserId) {

        return currentUserId === allowedUserId;
    }

    const canAccess =
        allowedRoles && allowedRoles.some(r => userRoles.includes(r));
    return canAccess;
};

export default useAuthorization;
