import React from "react";
import styled from "styled-components";

const Action = styled.div`
    display: flex;
    align-items: center;
    padding: 0 0 12px;
    position: relative;
    text-align: left;

    & * {
        color:#000;
        text-decoration:none;
    }

    &:hover {
        cursor: pointer;
        color: ${props => props.theme.secondaryColor};
    }

    &:hover * {
        color: ${props => props.theme.secondaryColor};
    }

    img {
        margin: 0 12px 0 0;
        max-height: 20px;
        position: relative;
        top: initial;
        left: initial;
        width: auto;
        height: auto;
    }

    &:nth-child(n + 2) {
        border-top: 1px solid ${props => props.theme.primaryInputBorderColor};
        padding: 12px 0;
    }

    ${props => props.disabled && "opacity: 0.5;"}

`;

const HamburgerMenuAction = ({icon, text, onClick, disabled, dataTest, children}) => {
    const clickHandler = (evt) => {
        if (!onClick) return;
        evt.preventDefault();
        evt.stopPropagation();
        onClick(evt);
    };

    return (
        <Action disabled={disabled} onClick={disabled ? () => {} : clickHandler} data-test={ dataTest ? dataTest : "" }>
            {icon && <img src={icon} alt={text}/>}
            {text || children}
        </Action>
    )
};

export default HamburgerMenuAction;
