import React from "react";
import { Redirect, Switch } from "react-router-dom";
import PrivateRoute from "../../core/PrivateRoute";
import useLeftMenu from "../../core/state/navigation/useLeftMenu";
import globalAdminMenuItems from "../../data/menu/globalAdminMenuItems";
import MarketplaceStatisticsDashboard from "./views/marketplace/MarketplaceStatisticsDashboard";
import MarketplaceAdminDashboard from "./views/marketplace/MarketplaceAdminDashboard";
import MarketplaceListing from "../SalesCenter/views/MarketplaceListing";
import RouteCreator from "../../core/RouteCreator";
import ReclaimedMarketplace from "../SalesCenter/views/ReclaimedMarketplace";

const MarketplaceStatisticsScreen = () => {
    useLeftMenu(globalAdminMenuItems);
    return (
        <Switch>
            <PrivateRoute
                path="/admin/marketplace-statistics/dashboardOld"
                component={MarketplaceStatisticsDashboard}
            />
            <PrivateRoute
                path={RouteCreator.admin.marketplaceStatistics()}
                exact={true}
                component={MarketplaceAdminDashboard}
            />

            <PrivateRoute
                path={RouteCreator.admin.marketplace()}
                component={MarketplaceListing}
            />
            <PrivateRoute
                path={RouteCreator.admin.reclaimedMarketplace()}
                component={ReclaimedMarketplace}
            />
            <Redirect to="/admin/sales-center" />
        </Switch>
    );
};

export default MarketplaceStatisticsScreen;
