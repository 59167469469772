import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Filters from "../../components/filter/Filters";
import Flex from "../../components/layout/flex/Flex";
import ChartDatePicker from "./ChartDatePicker";

const WEEK = 'week';
const MONTH = 'month';
const DAY = 'day';


export const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const TitleWrapper = styled.div`
    font-weight: 500;
    color: #333;
    font-size: 18px;
`;

const Button = styled.button`
    cursor: pointer;
    min-width: 70px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    text-align: center;
    padding: 0 20px;
    position: relative;
    text-align: center;
    border: none;
    background: none;

    &.primary {
        color: #2294D2;
        font-weight: 600;
    }

    &.secondary {
        color: #AAAEB3;
    }

`;

const Item = styled.div`
    margin: 0 12px 10px 0;

    & > * {
        margin-top: 0px;
    }

    & * {
        font-size: 12px;
    }

`;

export const FilterGroup = styled.div`
    display: flex;
    & ${Item}:first-child {
        margin-right: 0px;
    }

    flex-wrap: wrap;
`;

const FilterWrapper = styled(Filters.Wrapper)`
    margin-left: auto;
    order: 2;
    flex-wrap: wrap;

    & input {
        margin: 0px 0 2px !important;
    }
`;



const ChartDates = ({
    selectedPeriodGrouping,
    setSelectedPeriodGrouping,
    filterState,
    title,
    handleDateFromFilterChange,
    handleDateToFilterChange
}) => {

    const GetDateGroupingButtonClass = (group) => {
        return selectedPeriodGrouping === group ? "primary" : "secondary";
    }

    const { t } = useTranslation("screens");

    return (
        <Container>
            <TitleWrapper>
                {title}
            </TitleWrapper>
            <FilterWrapper className="filters-wrapper">
                <Flex.Container justifyContent="flex-end">
                    <FilterGroup>
                        <Item>
                            <Button
                                className={GetDateGroupingButtonClass(DAY)}
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    setSelectedPeriodGrouping(DAY);

                                }}>
                                {t("charts.day")}
                            </Button>
                        </Item>
                        <Item>
                            <Button
                                className={GetDateGroupingButtonClass(WEEK)}
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    setSelectedPeriodGrouping(WEEK);
                                }}>
                                {t("charts.week")}
                            </Button>
                        </Item>
                        <Item>
                            <Button
                                className={GetDateGroupingButtonClass(MONTH)}
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    setSelectedPeriodGrouping(MONTH);
                                }}>
                                {t("charts.month")}
                            </Button>
                        </Item>
                    </FilterGroup>
                    <FilterGroup style={{ paddingTop: 2 }}>
                        <Item>
                            <ChartDatePicker
                                labelText={t("charts.from")}
                                id="chartDatesDateFrom"
                                name="chartDatesDateFrom"
                                value={
                                    filterState ? filterState.from : ""
                                }
                                maxDate={filterState && filterState.to ? filterState.to : null}
                                selected={
                                    filterState ? filterState.from : ""
                                }
                                handleChange={(date) => {
                                    handleDateFromFilterChange(date)
                                }
                                } />

                        </Item>
                        <Item>
                            <ChartDatePicker
                                labelText={t("charts.to")}
                                id="chartDatesDateTo"
                                name="chartDatesDateTo"
                                minDate={filterState && filterState.from ? filterState.from : null}
                                selected={
                                    filterState ? filterState.to : ""
                                }
                                value={
                                    filterState ? filterState.to : ""
                                }
                                handleChange={(date) => {
                                    handleDateToFilterChange(date)
                                }
                            }
                            />
                        </Item>
                    </FilterGroup>         
                </Flex.Container>

            </FilterWrapper>

        </Container>

    );
}

export default ChartDates;