import i18next from "i18next";
import React, { useRef, useState, useEffect } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled, { css } from "styled-components";
import { ReactComponent as ResetSvg } from "../../../assets/close.svg";
import { Label, ValidationError } from "../form-elements";
import { InputWrapper } from "../Stylings";
import Input from "./Input";
import { ReactComponent as CalendarSvg } from "../../../assets/calendar.svg";

const SvgCalendar = styled(CalendarSvg)`
    position: absolute;
    top: 50%;
    right: 4px;
    width: auto;
    height: 22px;
    transform: translateY(calc(-50% - 8px));
    z-index: 2;
    pointer-events: none;
`;

const StyledReset = styled(ResetSvg)`
    position: absolute;
    left: 12px;
    top: 10px;
    width: 12px;
    height: 12px;
    transition: all 300ms ease;
    z-index: 2;

    &.clearable {
        top: 17px;
        width: 12px;
        height: 12px;
    }

    &:hover .lines {
        stroke: #e94e4e;
    }
`;

const StyledDatePickerWrapper = styled.div`
    position: relative;

    & .react-datepicker-wrapper {
        width: 100%;
    }

    & .react-datepicker-popper {
        z-index: 10000;
    }

    & input[value=""],
    & input:not([value]) {
        font-style: italic;
        padding-left: 8px !important;
    }
`;

function dateToInputString(date) {
    if (Object.prototype.toString.call(date) !== "[object Date]") return date;
    try {
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        let hour = date.getHours();
        let min = date.getMinutes();

        if (month < 10) month = "0" + month;
        if (day < 10) day = "0" + day;
        if (hour < 10) hour = "0" + hour;
        if (min < 10) min = "0" + min;

        return year + "-" + month + "-" + day + "T" + hour + ":" + min;
    } catch (ex) {
        return "";
    }
}

const StyledDatePicker = styled(ReactDatePicker)`
    box-sizing: border-box;
    width: 100%;
    background: ${(props) => props.theme.inputBgColor};
    padding: 8px;
    border: 1px solid ${(props) => props.theme.primaryInputBorderColor || "#d4d4d4"};
    border-radius: ${(props) => props.theme.inputBorderRadius || "5px"};
    margin: 0 0 15px;
    height: 46px;
    ${(props) =>
        props.disabled &&
        css`
            background: #f5f5f5;
        `};

    &.filters.group {
        width: 158px !important;
    }

    &.filters {
        border-radius: 23px;
        height: 32px;
        font-size: 14px;
    }

    &.filters::placeholder {
        padding-left: 0 !important;
    }

    &.filters.left {
        border-radius: 32px 0px 0px 32px;
    }

    &.filters.right {
        border-radius: 0px 32px 32px 0px;
    }

    &.filters.big {
        border-radius: 46px;
        padding-left: 25px;
    }

    &.filters.big::placeholder {
        padding-left: 25px !important;
        font-style: normal;
    }

    &.filters.big.left {
        border-radius: 46px 0px 0px 46px;
    }

    &.filters.big.right {
        border-radius: 0px 46px 46px 0px;
    }
`;

const DatePicker = (props) => {
    let {
        setFieldValue,
        labelText,
        name,
        values = {},
        errors,
        touched,
        wrapperVariant,
        style,
        selected,
        disabled,
        filter = "future",
        minDate,
        maxDate,
    } = props;
    let error = errors ? errors[name] : "";
    let isTouched = touched ? touched[name] : "";

    const date = new Date();
    let today = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    const isFutureDate = (date) => date >= today;
    const isPasteDate = (date) => date <= today;
    let filterDate = () => true;
    if (filter === "future") filterDate = isFutureDate;
    else if (filter === "past") filterDate = isPasteDate;

    return (
        <InputWrapper style={style} className={`${wrapperVariant} ${error ? "error" : ""}`}>
            <Label htmlFor={name} className={error && "error"}>
                {labelText}
            </Label>
            <StyledDatePickerWrapper>
                <StyledDatePicker
                    locale={i18next.language}
                    id={name}
                    selected={Date.parse(values[name]) || selected}
                    name={name}
                    onChange={(val) => {
                        setFieldValue && setFieldValue(name, val);
                    }}
                    mode="date"
                    dateFormat="dd.MM.yyyy"
                    showTimeSelect={false}
                    filterDate={filterDate}
                    disabled={disabled}
                    minDate={minDate ?? new Date("1/1/1930")}
                    maxDate={maxDate ?? new Date("1/1/2100")}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                />
                <SvgCalendar />
            </StyledDatePickerWrapper>
            <ValidationError error={error} touched={isTouched} isFieldError={true} />
        </InputWrapper>
    );
};

const DateTimePicker = (props) => {
    let {
        labelText,
        name,
        errors,
        touched,
        wrapperVariant,
        style,
        selected,
        disabled,
        filter = "",
        className,
        mode = "date",
        native,
        value,
        values,
        noLabel,
        placeholder,
        minDate,
        maxDate,
        minPickerId,
        maxPickerId,
    } = props;

    let error = errors ? errors[name] : "";
    let isTouched = touched ? touched[name] : false;

    const inputEl = useRef();
    const iniValue = value || (values ? values[name] : "");
    const [currentDate, setCurrentDate] = useState(selected || iniValue);
    const date = new Date();
    let today = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    const isFutureDate = (date) => date >= today;
    const isPasteDate = (date) => date <= today;
    let filterDate = () => true;
    if (filter === "future") filterDate = isFutureDate;
    else if (filter === "past") filterDate = isPasteDate;
    useEffect(() => {
        setCurrentDate(
            iniValue ? (native ? dateToInputString(iniValue) : new Date(iniValue)) : null
        );
    }, [iniValue]);

    const handleNativeChange = (ev) => {
        const minPickerEl = minPickerId ? document.getElementById(minPickerId) : null;
        if (minPickerEl) {
            minPickerEl.max = ev.target.value;
        }
        const maxPickerEl = maxPickerId ? document.getElementById(maxPickerId) : null;
        if (maxPickerEl) {
            maxPickerEl.min = ev.target.value;
        }
        setCurrentDate(ev.target.value);
    };

    return (
        <InputWrapper style={style} className={`${wrapperVariant} ${error ? "error" : ""}`}>
            {!noLabel && (
                <Label htmlFor={name} className={error && "error"}>
                    {labelText}
                </Label>
            )}
            <StyledDatePickerWrapper>
                {currentDate && !native && (
                    <StyledReset
                        className={!props.className ? "clearable" : props.className}
                        onClick={() => {
                            props.handleChange && props.handleChange("");
                            setCurrentDate("");
                        }}
                    />
                )}
                {native ? (
                    <Input
                        type={mode === "date" ? "date" : "datetime-local"}
                        ref={inputEl}
                        id={name}
                        name={name}
                        className={className}
                        dateFormat="dd.MM.yyyy"
                        min={minDate}
                        max={maxDate}
                        placeholder={placeholder || "DD.MM.YYYY"}
                        value={currentDate ? currentDate : ""}
                        onChange={(ev) => {
                            props.handleChange && props.handleChange(ev);
                            handleNativeChange(ev);
                        }}
                    />
                ) : (
                    <StyledDatePicker
                        dateFormat={mode === "date" ? "dd.MM.yyyy" : "dd.MM.yyyy hh:mm"}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        minDate={minDate ? new Date(minDate) : new Date("1/1/1930")}
                        maxDate={maxDate ? new Date(maxDate) : new Date("1/1/2100")}
                        locale={i18next.language}
                        ref={inputEl}
                        id={name}
                        selected={currentDate}
                        name={name}
                        mode={mode ? mode : "date"}
                        className={className}
                        placeholderText={placeholder || "DD/MM/YYYY"}
                        showTimeSelect={mode === "date" ? false : true}
                        filterDate={filterDate}
                        disabled={disabled}
                        onChange={(date) => {
                            props.handleChange && props.handleChange(date);
                            setCurrentDate(date);
                        }}
                        popperPlacement="bottom-start"
                        popperModifiers={{
                            offset: {
                                enabled: true,
                                offset: "5px, 0px",
                            },
                            preventOverflow: {
                                enabled: true,
                                escapeWithReference: false,
                                boundariesElement: "viewport",
                            },
                        }}
                    />
                )}
                <ValidationError error={error} touched={isTouched} isFieldError={true} />
            </StyledDatePickerWrapper>
        </InputWrapper>
    );
};

export default DatePicker;
export { DateTimePicker };
