import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Tooltip from "../../../components/tooltip/Tooltip";

const Star = styled.span`
    position: relative;
    margin-left: 4px;
    &:before {
        content: "\\2605";
        font-size: 20px;
        color: ${(props) => props.theme.primaryColor};
    }
`;

const StarCell = ({ cell: { value, row } }) => {
    const { t } = useTranslation();
    const [tooltip, setTooltip] = useState({
        tip: t("table:headers.client.primaryAddress"),
        visible: false,
    });

    const handleHover = (tooltipIsVisible) => {
        setTooltip((tooltip) => ({ ...tooltip, visible: tooltipIsVisible }));
    };

    if (row.original.locationTypeName === "Primary") {
        return (
            <span>
                {value}
                <Star
                    onMouseEnter={() => handleHover(true)}
                    onMouseLeave={() => handleHover(false)}
                >
                    <Tooltip visible={tooltip.visible} tooltip={tooltip.tip} />
                </Star>
            </span>
        );
    }
    return value;
};

export default StarCell;
