import React from "react";
import { useTranslation } from "react-i18next";
import ClearableInput from "../../../../components/elements/form-elements/ClearableInput";
import Select from "../../../../components/elements/form-elements/Select";
import Filters from "../../../../components/filter/Filters";
import useDebouncedCallback from "../../../../hooks/_shared/useDebouncedCallback";
import { DateTimePicker } from "../../../../components/elements/form-elements/DatePicker";
import helpers from "../../../../core/helpers";
import Label from "../../../../components/elements/form-elements/Label";
import AnalysisStatus from "../../../../core/enums/AnalysisStatus";
import useSharedState from "../../../../hooks/_shared/useSharedState";

const CleaningFilter = ({ filterState, dispatch, actionTypes, gasTypes }) => {
    const { t } = useTranslation("filters");
    const [globalState] = useSharedState("global");
    const isDistributor = globalState.isDistributor;

    const [quantityToChanged] = useDebouncedCallback((text) => {
        text
            ? dispatch({ type: actionTypes.SET_GAS_QUANTITY_TO, payload: text })
            : dispatch({
                  type: actionTypes.RESET_GAS_QUANTITY_TO,
                  payload: text,
              });
    });

    const [quantityFromChanged] = useDebouncedCallback((text) => {
        text
            ? dispatch({
                  type: actionTypes.SET_GAS_QUANTITY_FROM,
                  payload: text,
              })
            : dispatch({
                  type: actionTypes.RESET_GAS_QUANTITY_FROM,
                  payload: text,
              });
    });

    const hideWithBids = [
        { key: t("offers.hideItemsWithBids"), value: true },
        { key: t("offers.showItemsWithBids"), value: false },
    ];

    const showWithBids = [
        { key: t("offers.allOffers"), value: false },
        { key: t("offers.offersWithBids"), value: true },
    ];

    return (
        <>
            <Filters.Wrapper>
                {isDistributor && (
                    <>
                        <Filters.Item>
                            <Select
                                key="hideWithBids"
                                name="hideWithBids"
                                errors={[]}
                                touched={[]}
                                options={hideWithBids.map((item) => ({
                                    key: item.key,
                                    value: item.value,
                                    label: item.key,
                                }))}
                                defaultValue={
                                    filterState.hideOffersWithMyBid !==
                                    undefined
                                        ? filterState.hideOffersWithMyBid
                                        : false
                                }
                                handleChange={(evt) =>
                                    evt.currentTarget.value === "true"
                                        ? dispatch({
                                              type: actionTypes.SET_HIDEWITHBIDS,
                                              payload: true,
                                          })
                                        : dispatch({
                                              type: actionTypes.RESET_HIDEWITHBIDS,
                                          })
                                }
                                className="filters"
                                labelText={t("offers.hideItemsWithBids")}
                                labelClassName="sr-only"
                            />
                        </Filters.Item>
                        <Filters.Group>
                            <Filters.Item>
                                <DateTimePicker
                                    labelText={t("forms:extractionDate.from")}
                                    noLabel
                                    placeholder={t("forms:extractionDate.from")}
                                    id="offerCreationDateTimeFrom"
                                    name="offerCreationDateTimeFrom"
                                    className="filters big left group"
                                    value={
                                        filterState
                                            ? filterState.extractFrom
                                            : ""
                                    }
                                    handleChange={(date) =>
                                        dispatch({
                                            type:
                                                date === ""
                                                    ? actionTypes.RESET_OFFER_CREATION__DATE_FROM
                                                    : actionTypes.SET_OFFER_CREATION__DATE_FROM,
                                            payload:
                                                date === ""
                                                    ? date
                                                    : helpers.dateToIsoDateString(
                                                          new Date(date)
                                                      ),
                                        })
                                    }
                                />
                            </Filters.Item>
                            <Filters.Item>
                                <DateTimePicker
                                    labelText={t("forms:extractionDate.to")}
                                    noLabel
                                    placeholder={t("forms:extractionDate.to")}
                                    id="offerCreationDateTimeTo"
                                    name="offerCreationDateTimeTo"
                                    className="filters big right group"
                                    value={
                                        filterState ? filterState.extractTo : ""
                                    }
                                    handleChange={(date) =>
                                        dispatch({
                                            type:
                                                date === ""
                                                    ? actionTypes.RESET_OFFER_CREATION__DATE_TO
                                                    : actionTypes.SET_OFFER_CREATION__DATE_TO,
                                            payload:
                                                date === ""
                                                    ? date
                                                    : helpers.dateToIsoDateString(
                                                          date
                                                      ),
                                        })
                                    }
                                />
                            </Filters.Item>
                        </Filters.Group>
                    </>
                )}

                {!isDistributor && (
                    <>
                        <Filters.Item>
                            <Select
                                key="showWithBids"
                                name="showWithBids"
                                errors={[]}
                                touched={[]}
                                options={showWithBids.map((item) => ({
                                    key: item.key,
                                    value: item.value,
                                    label: item.key,
                                }))}
                                defaultValue={
                                    filterState.hasOffers !== undefined
                                        ? filterState.hasOffers
                                        : false
                                }
                                handleChange={(evt) =>
                                    evt.currentTarget.value === "true"
                                        ? dispatch({
                                              type: actionTypes.SET_HASOFFERS,
                                              payload: true,
                                          })
                                        : dispatch({
                                              type: actionTypes.RESET_HASOFFERS,
                                          })
                                }
                                className="filters"
                                labelText={t("offers.showItemsWithBids")}
                                labelClassName="sr-only"
                            />
                        </Filters.Item>
                    </>
                )}

                <Filters.Item>
                    <Select
                        name="gastype"
                        key="gastype"
                        errors={[]}
                        touched={[]}
                        defaultItem={{
                            key: "all",
                            value: "",
                            label: t("gas.gasType"),
                        }}
                        defaultValue={filterState.refrigerantGasTypeCode}
                        options={
                            gasTypes
                                ? gasTypes.map((gas) => ({
                                      key: gas.code,
                                      value: gas.code,
                                      label: gas.code,
                                  }))
                                : []
                        }
                        handleChange={(evt) => {
                            dispatch({
                                type:
                                    evt.currentTarget.value === ""
                                        ? actionTypes.RESET_GAS_TYPE
                                        : actionTypes.SET_GAS_TYPE,
                                payload: evt.currentTarget.value,
                            });
                        }}
                        className="filters"
                        labelText={t("gas.gasType")}
                        labelClassName="sr-only"
                    />
                </Filters.Item>

                <Filters.Group>
                    <Filters.Item>
                        <Label htmlFor="quantityFrom" className="sr-only">
                            {t("gas.quantityFrom")}
                        </Label>

                        <ClearableInput
                            key="quantityFrom"
                            name="quantityFrom"
                            type="number"
                            defaultValue={
                                filterState.gasQuantityFrom !== undefined
                                    ? filterState.gasQuantityFrom
                                    : ""
                            }
                            onChange={(evt) => {
                                quantityFromChanged(evt.currentTarget.value);
                            }}
                            placeholder={t("gas.quantityFrom")}
                            title={t("gas.quantityFrom")}
                            className="filters left group"
                        />
                    </Filters.Item>

                    <Filters.Item>
                        <Label htmlFor="quantityTo" className="sr-only">
                            {t("gas.quantityTo")}
                        </Label>
                        <ClearableInput
                            key="quantityTo"
                            name="quantityTo"
                            type="number"
                            defaultValue={
                                filterState.gasQuantityTo !== undefined
                                    ? filterState.gasQuantityTo
                                    : ""
                            }
                            onChange={(evt) => {
                                quantityToChanged(evt.currentTarget.value);
                            }}
                            placeholder={t("gas.quantityTo")}
                            title={t("gas.quantityTo")}
                            className="filters right group"
                        />
                    </Filters.Item>
                </Filters.Group>

                <Filters.Item>
                    <Select
                        key="qualityAssurance"
                        name="qualityAssurance"
                        errors={[]}
                        touched={[]}
                        defaultItem={{
                            key: "all",
                            value: "",
                            label: t("gas.qualityAssurance"),
                        }}
                        defaultValue={
                            filterState.hasAnalysis !== undefined
                                ? filterState.hasAnalysis
                                : ""
                        }
                        options={Object.keys(AnalysisStatus).map((key) => ({
                            key: key,
                            value: key,
                            label: t("analysis." + AnalysisStatus[key]),
                        }))}
                        handleChange={(evt) => {
                            dispatch({
                                type:
                                    evt.currentTarget.value === ""
                                        ? actionTypes.RESET_QUALITY_ASSURANCE
                                        : actionTypes.SET_QUALITY_ASSURANCE,
                                payload: evt.currentTarget.value,
                            });
                        }}
                        className="filters"
                        labelText={t("gas.qualityAssurance")}
                        labelClassName="sr-only"
                    />
                </Filters.Item>
            </Filters.Wrapper>
        </>
    );
};

export default CleaningFilter;
