import React from "react";
import { Switch } from "react-router-dom";
import PrivateRoute from "../../core/PrivateRoute";
import companyAdminMenuItems from "../../data/menu/companyAdminMenuItems";
import MarketplaceListing from "./views/MarketplaceListing";
import MyOffersListing from "./views/MyOffersListing";
import MyBidsListing from "./views/MyBidsListing";
import useLeftMenu from "../../core/state/navigation/useLeftMenu";
import RouteCreator from "../../core/RouteCreator";
import SalesDashboard from "./views/SalesDashboard";
import ReclaimedMarketOffers from "./views/ReclaimedMarketOffers";
import ReclaimetMarketplace from "./views/ReclaimedMarketplace";
import REclaimedMarketPurchases from "./views/ReclaimedMarketPurchases";
import useSharedState from "../../hooks/_shared/useSharedState";

const SalesCenterScreen = () => {
    const [globalState] = useSharedState("global");
    useLeftMenu(companyAdminMenuItems(globalState));
    
    return (
        <Switch>
            <PrivateRoute
                path={RouteCreator.salesCenter.myOffers()}
                component={MyOffersListing}
            />
            <PrivateRoute
                path={RouteCreator.salesCenter.myBids()}
                component={MyBidsListing}
            />
            <PrivateRoute
                path={RouteCreator.salesCenter.marketplace()}
                component={MarketplaceListing}
            />
            <PrivateRoute
                path={RouteCreator.salesCenter.reclaimedMarketOffers()}
                component={ReclaimedMarketOffers}
            />
            <PrivateRoute
                path={RouteCreator.salesCenter.reclaimedMarketPurchases()}
                component={REclaimedMarketPurchases}
            />
            <PrivateRoute
                path={RouteCreator.salesCenter.reclaimedMarketplace()}
                component={globalState.isDistributor ? ReclaimedMarketOffers:ReclaimetMarketplace}
            />
            <PrivateRoute
                path={RouteCreator.salesCenter.root()}
                component={SalesDashboard}
            />
        </Switch>
    );
};

export default SalesCenterScreen;
