import React, { useCallback, useMemo, useRef, useState } from "react";
import AccordionWrapper from "../../../../../components/toggle-view/AccordionWrapper";
import { useTranslation } from "react-i18next";
import ExtractionDetailSideBar from "./ExtractionDetailSideBar";
import DetailSideBar from "../../../../../components/modal/DetailSideBar";
import useClickAway from "../../../../../hooks/_shared/useClickAway";
import styled from "styled-components";
import DataTable from "../../../../../components/data/DataTable";
import { Num, NumInfo, NumSection } from "../../../../../components/elements/sidebar/SidebarStyle";
import globals from "../../../../../globalVars";
import Slider from "../../../../../components/elements/general/Slider";
import CylinderBlock from "../../../../../components/sidebar/CylinderBlock";
import useAccordionGroup from "../../../../../hooks/_shared/useAccordionGroup";
import { ReactComponent as InfoSvg } from "../../../../../assets/orange-info.svg";
import SidebarField from "../../../../../components/elements/sidebar/SidebarField";

const StyledInfo = styled(InfoSvg)`
    width: 30px;
    height: 30px;
    margin-right: 16px;
`;

const FlexDiv = styled.div`
    display: flex;
    font-size: 12px;
`;

const ClientName = styled.span`
    color: ${(props) => props.theme.primaryColor};
    font-weight: 600;
`;

const KeyValue = styled.div`
    padding: 20px 20px 0px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    & .value {
        color: ${(p) => p.theme.primaryColor};
    }
`;

const KeyValueCell = (key, value) => {
    return (
        <KeyValue>
            <span>{key}</span>
            <span className="value">{value.toFixed(2)} kg</span>
        </KeyValue>
    );
};

const splitTag = (tag) => {
    if (!tag || tag.length < 8) return tag;
    return tag.substring(0, 4) + " " + tag.substring(4, 8);
};

const gradeNames = ["A", "B", "C"];

const formatDateStr = (str) => {
    const fmt = Intl.DateTimeFormat("sk", {
        day: "numeric",
        month: "numeric",
        year: "numeric",
    });
    return fmt.format(new Date(str));
};

const StockDetailSideBar = ({
    selectedItem,
    stockItem,
    isLoading,
    reportCylinderIssue,
    isCompanyStock = false,
}) => {
    const { t } = useTranslation("sidebar");
    const shouldShow = !isLoading && stockItem;
    const [extractionViewIsOpened, setExtractionViewIsOpened] = useState(false);
    const [extraction, setExtraction] = useState(null);
    const [openAccordion, setOpenAccordion] = useAccordionGroup();
    let skuDetails = {};
    if (selectedItem) {
        skuDetails = {
            refrigerantGasTypeCode: selectedItem.refrigerantGasTypeCode,
            quantity: selectedItem.quantity,
            grade: selectedItem.pressumedGradeName,
        };
    }

    const handleExtractionClick = (row) => {
        setExtraction(getExtraction(row.original.id));
        setExtractionViewIsOpened(!extractionViewIsOpened);
    };

    const handleCloseExtraction = useCallback(() => {
        setExtraction(null);
        setExtractionViewIsOpened(false);
    }, []);

    const extractionRef = useRef(null);

    useClickAway(extractionRef, () => {
        handleCloseExtraction();
    });

    const getExtraction = (extractionId) => {
        return (
            stockItem.extractions && stockItem.extractions.filter((ext) => ext.id === extractionId)
        );
    };

    const extractionColumns = useMemo(
        () => [
            {
                Header: t("table:headers.extractions.clientName"),
                accessor: "clientName",
                Cell: ({ row }) => {
                    return <ClientName>{row.original.clientName}</ClientName>;
                },
            },
            {
                Header: t("table:headers.extractions.quantity"),
                accessor: "extractedQuantity",
                Cell: ({ cell: { value } }) => value.toFixed(2) + " kg",
            },
            {
                Header: t("table:headers.extractions.userFullName"),
                accessor: "extractedByUserFullName",
            },
        ],
        [stockItem, t]
    );

    let cnt = 0;
    let exCnt = 0;

    const computeGrade = () => {
        let grades = ["A", "B", "C"];
        if (stockItem?.extractions?.length) {
            let result = 0;
            stockItem.extractions.forEach((element) => {
                if (element.grade > result) result = element.grade;
            });
            return grades[result];
        }
        return "N/A";
    };
   return (
        <>
            {shouldShow && (
                <>
                    <CylinderBlock
                        cylinderId={stockItem.cylinderId}
                        tagId={stockItem.skuCylinderTagId}
                        refrigerantTypeCode={stockItem.refrigerantTypeCode ?? "-"}
                        filledQuantity={stockItem.filledQuantity}
                        size={stockItem.cylinderSize}
                        owner={stockItem.ownerCompanyName || "-"}
                        openAccordion={openAccordion}
                        setOpenAccordion={setOpenAccordion}
                        reportIssue={reportCylinderIssue}
                    />
                    {stockItem && (
                        <AccordionWrapper
                            title={t(
                                `title.contaminated.${
                                    stockItem.hasAnalysis ? "measured" : "selfAssessed"
                                }`
                            )}
                            id="contamination"
                            isOpen={openAccordion}
                            onToggle={setOpenAccordion}
                        >
                            <Slider
                                column
                                style={{ padding: "25px 20px 0px" }}
                                title={t("sidebar:title.extraction.extractionCylinder")}
                                captions={[splitTag(stockItem?.skuCylinderTagId)]}
                            >
                                {stockItem.hasAnalysis ? (
                                    stockItem.extractions.map((ex, idx) =>
                                        ex.isAnalysisReceived ? (
                                            <NumSection>
                                                <Num>{++exCnt}</Num>
                                                <div style={{ flex: "1" }}>
                                                    <SidebarField
                                                        label={t("label.analysisDate")}
                                                        value={formatDateStr(
                                                            ex.dataCollectionDateTime
                                                        )}
                                                    />

                                                    <SidebarField
                                                        dangerousHtml
                                                        label={t("label.analysisResults")}
                                                        value={`<a
                                                    href=${ex.dataFile}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    ${t("actions:download")}
                                                </a>`}
                                                    />

                                                    <SidebarField
                                                        label={t("label.gasQuantity")}
                                                        value={ex.extractedQuantity + " kg"}
                                                    />

                                                    <SidebarField
                                                        wrapperVariant = "num-section"
                                                        label={t("label.gasGrade")}
                                                        value={gradeNames[ex.grade]}
                                                    />
                                                </div>
                                            </NumSection>
                                        ) : (
                                            <NumSection>
                                                <Num>{++exCnt}</Num>
                                                <FlexDiv style={{padding:"12px 0"}}>
                                                    <StyledInfo />
                                                    <span>{t("label.waitingForResult")}</span>
                                                </FlexDiv>
                                            </NumSection>
                                        )
                                    )
                                ) : (
                                    <div>
                                        {!stockItem.isContaminated &&
                                            !stockItem.isMixture &&
                                            !stockItem.hasR22 && (
                                                <NumSection>
                                                    <Num className="tight">N/A</Num>
                                                    <NumInfo>
                                                        {t("actions:noContamination")}
                                                    </NumInfo>
                                                </NumSection>
                                            )}

                                        {stockItem.isContaminated && (
                                            <NumSection>
                                                <Num>{++cnt}</Num>
                                                <NumInfo>{t("screens:stock.aGradeText")}</NumInfo>
                                            </NumSection>
                                        )}
                                        {stockItem.isMixture && (
                                            <NumSection>
                                                <Num>{++cnt}</Num>
                                                <NumInfo>{t("screens:stock.bGradeText")}</NumInfo>
                                            </NumSection>
                                        )}
                                        {stockItem.hasR22 && (
                                            <NumSection>
                                                <Num>{++cnt}</Num>
                                                <NumInfo>{t("screens:stock.cGradeText")}</NumInfo>
                                            </NumSection>
                                        )}
                                    </div>
                                )}
                            </Slider>
                        </AccordionWrapper>
                    )}

                    <AccordionWrapper
                        title={t("title.extraction.extractions")}
                        id="extractions"
                        isOpen={openAccordion}
                        onToggle={setOpenAccordion}
                    >
                        {KeyValueCell(
                            `${t("sidebar:shared.initialQuantity")}:`,
                            stockItem.confirmedAmount ?? 0
                        )}
                        {stockItem.extractions && stockItem.extractions.length > 0 && (
                            <DataTable
                                columns={extractionColumns}
                                onSelect={handleExtractionClick}
                                data={stockItem.extractions.filter(
                                    (ext) => ext.extractedByCompanyId === globals.companyId
                                )}
                            />
                        )}
                    </AccordionWrapper>

                    <DetailSideBar
                        detailIsOpened={extractionViewIsOpened}
                        closeSideBar={handleCloseExtraction}
                        ref={extractionRef}
                        variant="nested"
                        title={t("title.extraction.details")}
                    >
                        <ExtractionDetailSideBar
                            skuDetails={skuDetails}
                            stockItem={stockItem}
                            extraction={extraction}
                        ></ExtractionDetailSideBar>
                    </DetailSideBar>
                </>
            )}
        </>
    );
};

export default StockDetailSideBar;
