import {
    GoogleMap
} from "@react-google-maps/api";
import React, { useMemo } from "react";
import styled from "styled-components";

const containerStyle = {
    width: "350px",
    height: "100%",
};

const center = {
    lat: 45.81,
    lng: 16,
};

const MapWrapper = styled.div`
    margin: 5px 0 0;
    border: 2px solid ${p => p.theme.primaryColor};
    border-radius: 6px;
    height: calc(100% - 7px);
    min-height: 200px;
    box-sizing: border-box;
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.25);
    overflow: hidden;

    > div {
        min-height: 200px; // google map display fix
    }

    &.pwa {
        height: 200px;
    }

    &.mt-10 {
        margin-top:10px;
    }

    &.mt-20 {
        margin-top:20px;
    }

    &.no-border {
        border: none;
        box-shadow: none;
    }
`;

const DaikinMap = ({ children, onMapLoad, className, ...rest }) => {
    const [, setMap] = React.useState(null);

    const onLoad = React.useCallback(function callback(map) {
        onMapLoad && onMapLoad(map);
        setMap(map);
    }, [onMapLoad]);

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null);
    }, []);

    const options = useMemo(() => {
        return {
            disableDefaultUI: true,
        };
    }, []);

    return (
        <MapWrapper className={className} {...rest}>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={10}
                onLoad={onLoad}
                onUnmount={onUnmount}
                options={options}
            >
                {children}
            </GoogleMap>
        </MapWrapper>
    );
};

export default React.memo(DaikinMap);
