import styled from "styled-components";

const Wrapper = styled.div`
    float: right;
    width: 137px;
    font-size: 12px;
`;

const Label = styled.span`
    float: right;
`;

const List = styled.ul`
    list-style: none;
    padding: 0;
    margin-top: 30px;
`;

const Item = styled.li`
    height: 40px;
    color: #aaaeb3;
`;

const Text = styled.div`
    float: left;
    padding-top:10px;
    margin: auto;

    & span {
        padding-left: 6px;
        font-size: 14px;
    }
`;

const ProgressStyles = {
    Wrapper: Wrapper,
    Label: Label,
    List: List,
    Item: Item,
    Text: Text
};

export default ProgressStyles;