import CountryService from "../../../services/User/CountryService";

const countriesStatePlugin = {
    types: ["COUNTRY_LOADING", "COUNTRY_SUCCESS", "COUNTRY_ERROR"],
    initialState: {
        countryData: {
            countries: [],
            isLoading: false,
            error: null
        }
    },
    createReducer: types => {
        return (state, action) => {
            switch (action.type) {
                case types.COUNTRY_LOADING:
                    state.countryData.countries = [];
                    state.countryData.isLoading = true;
                    state.countryData.error = null;
                    break;
                case types.COUNTRY_ERROR:
                    state.countryData.countries = [];
                    state.countryData.isLoading = false;
                    state.countryData.error = action.payload;
                    break;
                case types.COUNTRY_SUCCESS:
                    state.countryData.countries = action.payload;
                    state.countryData.isLoading = false;
                    state.countryData.error = null;
                    break;
                default:
                    return state;
            }
        };
    },
    creators: (types) => ({
        countryLoading: () => ({ type: types.COUNTRY_LOADING }),
        countryError: err => ({ type: types.COUNTRY_ERROR, payload: err }),
        countrySuccess: res => ({
            type: types.COUNTRY_SUCCESS,
            payload: res
        })
    }),
    actions: (creators)=> ({
        loadCountries: () => {
            return async dispatch => {
                dispatch(creators.countryLoading());
                try {
                    const response = await CountryService.getCountries();
                    dispatch(creators.countrySuccess(response));
                } catch (e) {
                    dispatch(creators.countryError(e));
                }
            }
        }
    })
};

export default countriesStatePlugin;
