import Color from "color";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import leftNavigationStateModule from "../../../../core/state/navigation/leftNavigationStateModule";
import userStateModule from "../../../../core/state/user/userStateModule";
import useStateModule from "../../../../core/state/useStateModule";
import { ReactComponent as LogoBig } from "../../../../assets/logo-big-rtr.svg";
import { ReactComponent as LogoSmall } from "../../../../assets/logo-small-rtr.svg";
import { useLocation } from "react-router-dom";

const LogoWrapper = styled.div`
    height: ${(props) => props.theme.heightHeader};
    box-sizing: border-box;
    /* transition: padding 0.3s ease-in; */
    padding: 0 16px 0 10px;
    display: flex;
    align-items: center;
    
    position: relative;

    &.isOpened {
    }

    &.menu-visible {
        border-bottom: 1px solid
            ${(props) =>
                Color(props.theme.headerBorderColor).lighten(0.1).string()};
    }
`;


const BigLogo = styled(LogoBig)`
   width: 165px;
    height: 24px;
    position: absolute;
    left: 15px;
    top: 16px;
    opacity: 0;
    pointer-events: none;
    
    transform: scaleX(0);
    transform-origin: top left;
    transition: all 0.2s ease-out, opacity 0.5s ease-out;
    &.active {
        opacity:1;
        
        transform: scaleX(1);
        pointer-events: all;
    }
`;

const SmallLogo = styled(LogoSmall)`
      width: 50px;
    height: 45px;
    position: absolute;
    left: 3px;
    top: 8px;
    opacity: 0;
    pointer-events: none;
    transition: all 0.1s ease-out;
    &.active {
        opacity: 1;
        pointer-events: all;
    }
`;

const HeaderLogo = () => {
    const location = useLocation();
    const { t } = useTranslation("header");
    const { menuState } = useStateModule(userStateModule);
    const { menuItems, isVisible } = useStateModule(leftNavigationStateModule);
    const forceBigLogo = location.pathname.startsWith("/company/");

    return (
        <LogoWrapper
            className={[
                menuState.isOpened ? "isOpened" : "",
                menuItems.length === 0 || isVisible ===false ? "" : "menu-visible",
            ]}
        >
            <Link to="/" title={t("header.logo")}>
                <BigLogo className={(menuState.isOpened || forceBigLogo) ? "active" : ""} />
                <SmallLogo className={(menuState.isOpened || forceBigLogo) ? "" : "active"} />
            </Link>
        </LogoWrapper>
    );
};

export default HeaderLogo;
