import styled from "styled-components";

const ScanButton = styled.button`
    width: 52px;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: none;
    outline: none;
    background-color: ${(p) => p.theme.secondaryColor};
    /* box-shadow: ${(props) => props.theme.buttonShadow}; */

    &.close {
        background-color:#ff0000;
    }
`;

const ScreenWrapper = styled.div`
    width: 100%;
    height: 100%;
`;

const ScreenHeader = styled.div`
    padding: 0px 16px;
    min-height: 60px;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.right {
        justify-content: flex-end;
    }

    &.big {
        height: 100px;
    }

    &.gray {
        background-color: #ebebeb;
        padding: 0px 20px;
        margin: 0px -20px;
    }
`;

const ListTitle = styled.div`
    font-size: 16px;
    padding: 20px 0px 0px 30px;
    color: ${p => p.theme.primaryColor};
    margin: 0;
    color: ${(p) => p.theme.primaryColor};

    &.wizard {
        padding:0;
    }

    &.content {
        padding-left: 8px;
    }

    &.scanner {
        padding-left: 0px;
        padding-top: 10px;
    }

    &.content.outset {
        margin-left: -22px;
    }
`;

const FlexForm = styled.form`
    display: flex;
    flex-wrap: wrap;
`;

const FilterClear = styled.div`
    height: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    font-size: 12px;
    font-weight: 700;
    box-shadow: 0px 12px 20px 0px rgba(0, 0, 0, 0.16);
    border-color: ${(props) => props.theme.primaryColorBorder};
    padding: 2px 6px;
    border-radius: 6px;
    margin-left: 12px;
`;

const FilterWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const HR = styled.div`
    border-bottom: 1px solid #d5d5d5;
    height: 1px;
    margin: 0px 24px;

    &.content {
        margin: 10px 0px;
    }

    &.section {
        margin: 20px 0px;
    }
`;

const Box16A9 = styled.div`
    position: relative;
    padding-bottom: 56.2%;
    border: 1px solid #d5d5d5;
    border-radius: 10px;
    & > * {
        position: absolute;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    & > .thumb {
        padding: 10px;
    }
`;

const VSpace = styled.div`
    width: 100%;
    height: ${(p) => p.size || "0px"};
    min-height: ${(p) => p.size || "0px"};
`;
const KeyVal = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    color: #000;
    font-weight: 700;
    margin-bottom: 10px;

    &.tight {
        margin-bottom: 5px;
    }

    &.single {
        margin-bottom: 0;
    }
    & span {
        font-weight: 500;
        color: ${(p) => p.theme.primaryColor};
    }

    & b + span {
        font-size: 12px;
        margin-left: 10px;
    }

    &.swap {
        color: ${(p) => p.theme.primaryColor};
        span {
            color: #000;
        }
    }
`;

const LevelWrapper = styled.div`
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    max-height: 100%;
    overflow-y: auto;
    
    & > div{
        display: flex;
        flex-direction: column;
    }
`;

export {
    ScreenWrapper,
    ScreenHeader,
    ListTitle,
    FlexForm,
    FilterClear,
    FilterWrapper,
    HR,
    Box16A9,
    VSpace,
    ScanButton,
    KeyVal,
    LevelWrapper
};
