import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ListingPage from "../../../components/data/ListingPage";
import RoundTabs from "../../../components/elements/tabs/RoundTabs";
import ModalDialog, { useModal } from "../../../components/modal/ModalDialog";
import OfferStatus from "../../../core/enums/OfferStatus";
import useBreadcrumbs from "../../../core/state/navigation/useBreadcrumbs";
import cleanRefrigerantListingStateModule from "../../../core/state/clean-refrigerant/cleanRefrigerantListingStateModule";
import useStateModule from "../../../core/state/useStateModule";
import useImmerReducer from "../../../hooks/_shared/useImmerReducer";
import usePageChange from "../../../hooks/_shared/usePageChange";
import MarketplaceService from "../../../services/BuyRefrigerant/MarketplaceService";
import StockService from "../../../services/Stock/StockService";
import CleaningOffersColumns from "./columns/CleaningOffersColumns";
import MarketplaceFilter from "../../SalesCenter/views/components/MarketplaceFilter";
import MyOffersDetailSideBar from "../../SalesCenter/views/components/MyOffersDetailSideBar";
import TransactionDetails from "./components/TransactionDetails";
import PlainModal, {
    usePlainModal,
} from "../../../components/modal/PlainModal";
import ModalWizard from "../../../components/modal/ModalWizard";
import PaymentService from "../../../services/Payment/PaymentService";
import WizardDispute from "../../SalesCenter/views/components/wizard/WizardDispute";
import { Helmet } from "react-helmet";
import useSharedState from "../../../hooks/_shared/useSharedState";

const actionTypes = {
    SET_HASOFFERS: "SET_HASOFFERS",
    RESET_HASOFFERS: "RESET_HASOFFERS",
    SET_GAS_TYPE: "SET_GAS_TYPE",
    RESET_GAS_TYPE: "RESET_GAS_TYPE",
    SET_GAS_QUANTITY_TO: "SET_GAS_QUANTITY",
    RESET_GAS_QUANTITY_TO: "RESET_GAS_QUANTITY",
    SET_GAS_QUANTITY_FROM: "SET_GAS_QUANTITY_FROM",
    RESET_GAS_QUANTITY_FROM: "RESET_GAS_QUANTITY_FROM",
    SET_PRESUMED_QUALITY: "SET_PRESUMED_QUALITY",
    RESET_PRESUMED_QUALITY: "RESET_PRESUMED_QUALITY",
    SET_CONFIRMED_GAS_QUANTITY_TO: "SET_CONFIRMED_GAS_QUANTITY_TO",
    RESET_CONFIRMED_GAS_QUANTITY_TO: "RESET_CONFIRMED_GAS_QUANTITY",
    SET_CONFIRMED_GAS_QUANTITY_FROM: "SETCONFIRMED__GAS_QUANTITY_FROM",
    RESET_CONFIRMED_GAS_QUANTITY_FROM: "RESET_CONFIRMED_GAS_QUANTITY_FROM",
    SET_CONFIRMED_QUALITY: "SET_CONFIRMED_QUALITY",
    RESET_CONFIRMED_QUALITY: "RESET_CONFIRMED_QUALITY",
    SET_CONFIRMED_GAS_TYPE: "SET_CONFIRMED_GAS_TYPE",
    RESET_CONFIRMED_GAS_TYPE: "RESET_CONFIRMED_GAS_TYPE",
    SET_QUALITY_ASSURANCE: "SET_QUALITY_ASSURANCE",
    RESET_QUALITY_ASSURANCE: "RESET_QUALITY_ASSURANCE",
    SET_EXPIRATION_DATE_FROM: "SET_EXPIRATION_DATE_FROM",
    RESET_EXPIRATION_DATE_FROM: "RESET_EXPIRATION_DATE_FROM",
    SET_EXPIRATION_DATE_TO: "SET_EXPIRATION_DATE_TO",
    RESET_EXPIRATION_DATE_TOM: "RESET_EXPIRATION_DATE_TO",
    SET_SORT: "SET_SORT",
    RESET_SORT: "RESET_SORT",
    SET_PAGING: "SET_PAGING",
};

const myOffersReducer = (state, action) => {
    if (action.type !== actionTypes.SET_PAGING) state.page = 1;
    switch (action.type) {
        case "SET_HASOFFERS":
            state.filter.hasOffers = action.payload;
            break;
        case "SET_ACTIVETAB":
            state.filter.offerStatuses = action.payload;
            delete state.filter.hasOffers;
            delete state.filter.gasType;
            delete state.filter.gasQuantityFrom;
            delete state.filter.gasQuantityTo;
            delete state.filter.presumedQuality;
            delete state.filter.hasAnalysis;
            delete state.filter.bidDealExpirationFrom;
            delete state.filter.bidDealExpirationTo;
            break;
        case "RESET_HASOFFERS":
            delete state.filter.hasOffers;
            break;
        case "SET_PAGING":
            state.pageSize = action.payload.pageSize;
            state.page = action.payload.pageIndex + 1;
            break;
        case "SET_SORT":
            state.orderColumn = action.payload[0].id;
            state.descending = action.payload[0].desc;
            break;
        case "RESET_SORT":
            state.orderColumn = "";
            state.descending = true;
            break;
        case "SET_GAS_TYPE":
            state.filter.gasType = action.payload;
            break;
        case "RESET_GAS_TYPE":
            delete state.filter.gasType;
            break;
        case actionTypes.SET_GAS_QUANTITY_TO:
            state.filter.gasQuantityTo = Number(action.payload);
            break;
        case actionTypes.RESET_GAS_QUANTITY_TO:
            delete state.filter.gasQuantityTo;
            break;
        case actionTypes.SET_GAS_QUANTITY_FROM:
            state.filter.gasQuantityFrom = Number(action.payload);
            break;
        case actionTypes.RESET_GAS_QUANTITY_FROM:
            delete state.filter.gasQuantityFrom;
            break;
        case actionTypes.SET_CONFIRMED_GAS_TYPE:
            state.filter.confirmedGasType = action.payload;
            break;
        case actionTypes.RESET_CONFIRMED_GAS_TYPE:
            delete state.filter.confirmedGasType;
            break;
        case actionTypes.SET_CONFIRMED_GAS_QUANTITY_TO:
            state.filter.confirmedGasQuantityTo = Number(action.payload);
            break;
        case actionTypes.RESET_CONFIRMED_GAS_QUANTITY_TO:
            delete state.filter.confirmedGasQuantityTo;
            break;
        case actionTypes.SET_CONFIRMED_GAS_QUANTITY_FROM:
            state.filter.confirmedGasQuantityFrom = Number(action.payload);
            break;
        case actionTypes.RESET_CONFIRMED_GAS_QUANTITY_FROM:
            delete state.filter.confirmedGasQuantityFrom;
            break;
        case "SET_CONFIRMED_QUALITY":
            state.filter.confirmedQuality = Number(action.payload);
            break;
        case "RESET_CONFIRMED_QUALITY":
            delete state.filter.confirmedQuality;
            break;
        case "SET_PRESUMED_QUALITY":
            state.filter.presumedQuality = Number(action.payload);
            break;
        case "RESET_PRESUMED_QUALITY":
            delete state.filter.presumedQuality;
            break;
        case "SET_QUALITY_ASSURANCE":
            state.filter.hasAnalysis = action.payload === "1";
            break;
        case "RESET_QUALITY_ASSURANCE":
            delete state.filter.hasAnalysis;
            break;

        case actionTypes.SET_EXPIRATION_DATE_FROM:
            state.filter.bidDealExpirationFrom = action.payload;
            break;
        case actionTypes.RESET_EXPIRATION_DATE_FROM:
            delete state.filter.bidDealExpirationFrom;
            break;
        case actionTypes.SET_EXPIRATION_DATE_TO:
            state.filter.bidDealExpirationTo = action.payload;
            break;
        case actionTypes.RESET_EXPIRATION_DATE_TO:
            delete state.filter.bidDealExpirationTo;
            break;
        default:
            return state;
    }
};

const CleaningOffers = () => {
    const {
        sellingItems,
        isLoading,
        error,
        loadSellingItems,
        loadSellingItemById,
        details,
        unpublishData,
        loadGasTypes,
        gasTypesData,
    } = useStateModule(cleanRefrigerantListingStateModule);

    const { t, i18n } = useTranslation("screens");
    const currentLanguage = i18n.language;
    const [globalState] = useSharedState("global");
    const [filterState, dispatch] = useImmerReducer(myOffersReducer, {
        filter: { offerStatuses: [OfferStatus.offered] },
        pageSize: 10,
        page: 1,
        orderColumn: "dealExpiration",
        descending: false,
    });
    const [openAccordionId, setOpenAccordionId] = useState();
    const setActiveTab = (tab) => {
        dispatch({ type: "SET_ACTIVETAB", payload: tab });
        if (tab[0] === 99) {
            dispatch({
                type: actionTypes.SET_SORT,
                payload: [{ id: "lastChangeDateTime", desc: true }],
            });
        } else {
            dispatch({
                type: actionTypes.SET_SORT,
                payload: [{ id: "dealExpiration", desc: false }],
            });
        }
    };

    const fetchGridData = () => {
        loadSellingItems({
            isInstaller: true,
            options: filterState,
        });
    };

    useEffect(() => {
        fetchGridData();
    }, [loadSellingItems, filterState]);

    useEffect(() => {
        loadGasTypes();
    }, [loadGasTypes]);

    const [currentRowData, setCurrentRowData] = useState({
        offer: null,
        bid: null,
    });
    const onSelect = useCallback(
        (row) => {
            const selectRow = async (row) => {
                let currentBid = null;
                let bidId = row.original.acceptedBid
                    ? row.original.acceptedBid.id
                    : row.original.acceptedBidInfo
                    ? row.original.acceptedBidInfo.bidId
                    : null;
                if (bidId) {
                    const bids = await MarketplaceService.getBids(
                        row.original.offerId
                    );
                    currentBid = bids
                        ? bids.find((b) => {
                              if (b.bidStatus === 1) {
                                  return b.bidId === bidId;
                              }
                              return null;
                          })
                        : null;
                }

                setCurrentRowData({
                    offer: row.original,
                    bid: currentBid,
                    isCleaning: true,
                });

                await loadSellingItemById(row.original.offerId);
                setDetailViewIsOpened(true);
            };
            selectRow(row);
        },
        [details, loadSellingItemById]
    );

    const closeSideBar = () => {
        if (detailViewIsOpened) {
            setOpenAccordionId(null);
            setDetailViewIsOpened(false);
            if (CleaningOffers.refreshNeeded) {
                CleaningOffers.refreshNeeded = false;
                fetchGridData();
            }
        }
    };

    useBreadcrumbs([
        {
            text: t("navigation:services"),
        },
        {
            text: t("navigation:cleaningService"),
        },
    ]);

    //Unpublish action
    const { modalProps, toggleOpen } = useModal({
        title: t("actions:dialogs.unPublishOffer.title"),
        type: "warning",
        onConfirm: async (model) => {
            const result = await StockService.unpublishFromMarketplace({
                offerIds: model.offerIds,
            });
            if (result && result.error) {
                toast.error(t(result.error.message));
            } else {
                fetchGridData();
                toast.success(t("myOffers.toastNotification.offerUnpublished"));
            }
            toggleOpen();
        },
        onCancel: () => {
            toggleOpen();
        },
        confirmText: t("actions:dialogs.unPublishOffer.confirmText"),
    });
    //Finish action
    const { modalProps: sellModalProps, toggleOpen: sellToggleOpen } = useModal(
        {
            title: t("actions:dialogs.sellOffer.title"),
            isWarning: false,
            onConfirm: async (row) => {
                const result = await MarketplaceService.offerSold([
                    row[0].offerId,
                ]);
                if (result && result.error) {
                    toast.error(t(result.error.message));
                } else {
                    setDetailViewIsOpened(false);
                    fetchGridData();
                    toast.success(t("myOffers.toastNotification.sellOffer"));
                }
                sellToggleOpen();
            },
            onCancel: () => {
                sellToggleOpen();
            },
            confirmText: t("actions:dialogs.sellOffer.confirmText"),
            type: "info",
        }
    );

    //Reject expired bid action
    const { modalProps: rejectModalProps, toggleOpen: rejectToggleOpen } =
        useModal({
            title: t("actions:dialogs.rejectBid.title"),
            onConfirm: async (row) => {
                const result =
                    await MarketplaceService.rejectBidDueToExpiration({
                        bidId: row[0].acceptedBid.id,
                    });
                if (result && result.error) {
                    toast.error(t(result.error.message));
                } else {
                    fetchGridData();
                    toast.success(t("myOffers.toastNotification.rejectBid"));
                }
                rejectToggleOpen();
            },
            onCancel: () => {
                rejectToggleOpen();
            },
            confirmText: t("actions:dialogs.rejectBid.confirmText"),
        });

    const openDisputeWizard = (offer) => {
        return ModalWizard.show(
            WizardDispute(t, offer),
            {
                onSubmit: async (values) => {
                    const data = ModalWizard.groupValues(
                        values,
                        "_",
                        true
                    ).filter((itm) => itm.disputeThisResult === true);
                    if (data.length > 0) {
                        const result = await MarketplaceService.dispute(data);
                        if (result && result.error) {
                            ModalWizard.submitFailed(t(result.error.message));
                        } else {
                            fetchGridData();
                            toast.success(
                                t("myOffers.toastNotification.disputed")
                            );
                            ModalWizard.hide();
                        }
                    } else ModalWizard.hide(0);
                },
                onCancel: async () => {
                    ModalWizard.hidePrompt();
                },
            },
            { style: { width: "800px", height: "730px" } }
        );
    };

    const [transactionDetails, setTransactionDetails] = useState({
        offer: "",
        details: "",
    });
    const getTransactionDetails = async (offer, bidId) => {
        const result = await PaymentService.getTransactionDetails(1, bidId);
        if (result && result.error) {
            toast.error(t(result.error.message));
        } else {
            setTransactionDetails({
                offer: offer,
                details: result.data.data[0],
            });
            toggleTransactionDetails();
        }
    };
    const {
        modalProps: transactionDetailsProps,
        toggleOpen: toggleTransactionDetails,
    } = usePlainModal({
        onClose: async () => {
            toggleTransactionDetails();
        },
        closeText: t("actions:close"),
    });

    const [detailViewIsOpened, setDetailViewIsOpened] = useState(false);

    const columns = useMemo(() => {
        return CleaningOffersColumns.getColumns(
            filterState.filter.offerStatuses,
            t,
            {
                toggleOpen: toggleOpen,
                sellToggleOpen: sellToggleOpen,
                rejectToggleOpen: rejectToggleOpen,
                openDisputeWizard: openDisputeWizard,
                getTransactionDetails: getTransactionDetails,
            },
            currentLanguage,
            globalState,
            setOpenAccordionId
        );
    }, [filterState.filter.offerStatuses, t, globalState]);

    const pageChange = usePageChange(dispatch, filterState);

    const sortChange = (sortBy) => {
        sortBy.length > 0
            ? dispatch({ type: actionTypes.SET_SORT, payload: sortBy })
            : dispatch({ type: actionTypes.RESET_SORT });
    };

    const currentSortBy = useMemo(() => {
        return [{ id: filterState.orderColumn, desc: filterState.descending }];
    }, [filterState.orderColumn, filterState.descending]);

    let tabIndex = 0;
    const statuses = filterState.filter.offerStatuses;

    if (statuses.includes(OfferStatus.offered)) {
        tabIndex = 0;
    } else if (
        statuses.includes(OfferStatus.waitingForOffererFunds) ||
        statuses.includes(OfferStatus.offererEscrowedFunds) ||
        statuses.includes(OfferStatus.buyerEscrowedFunds)
    ) {
        tabIndex = 1;
    } else if (
        statuses.includes(OfferStatus.buyerClaimedBottles) ||
        statuses.includes(OfferStatus.qualityTested)
    ) {
        tabIndex = 2;
    } else if (statuses.includes(OfferStatus.sold)) {
        tabIndex = 3;
    }

    return (
        <>
            <Helmet>
                <title>{t("seo:myOffers.title")}</title>
            </Helmet>
            <ListingPage
                key={tabIndex}
                tableState={{
                    data: sellingItems.data,
                    loading: isLoading || unpublishData.isLoading,
                    error: error,
                }}
                paging={{
                    enablePaging: true,
                    pageCount: sellingItems.totalPages,
                    pageSize: sellingItems.pageSize,
                    onPageChange: pageChange,
                    currentPageIndex: sellingItems.currentPage - 1,
                }}
                sorting={{
                    enableSorting: true,
                    onSortByChange: sortChange,
                    currentSortBy: currentSortBy,
                }}
                actions={{
                    headerActions: () => (
                        <RoundTabs
                            numTabs={4}
                            tabIndex={tabIndex}
                            activeFn={setActiveTab}
                            tabs={[
                                {
                                    label: t("screens:myOffers.tabs.pending"),
                                    activeValue: [OfferStatus.offered],
                                },
                                {
                                    label: t("screens:myOffers.tabs.accepted"),
                                    activeValue: [
                                        OfferStatus.waitingForOffererFunds,
                                        OfferStatus.offererEscrowedFunds,
                                        OfferStatus.buyerEscrowedFunds,
                                    ],
                                },
                                {
                                    label: t(
                                        "screens:myOffers.tabs.qualityTest"
                                    ),
                                    activeValue: [
                                        OfferStatus.buyerClaimedBottles,
                                        OfferStatus.qualityTested,
                                    ],
                                },
                                {
                                    label: t("screens:myOffers.tabs.cleaned"),
                                    activeValue: [OfferStatus.sold],
                                },
                            ]}
                        />
                    ),
                }}
                filterTitle={t("filters:services.filterTitle")}
                showTitle={true}
                filterComponent={() => {
                    return (
                        <>
                            <MarketplaceFilter
                                filterState={filterState.filter}
                                dispatch={dispatch}
                                actionTypes={actionTypes}
                                gasTypes={gasTypesData.gasTypes}
                                screen="offers"
                                offerStatus={filterState.filter.offerStatuses}
                                tabIndex={tabIndex}
                            />
                        </>
                    );
                }}
                noDataText={t("myOffers.noData")}
                columns={columns}
                onSelect={onSelect}
                closeSideBar={closeSideBar}
                detailView={
                    <MyOffersDetailSideBar
                    key={details.sellingItemData?.offerId}    
                    openAccordionId={openAccordionId}
                        selectedOffer={currentRowData}
                        offer={details.sellingItemData}
                        isLoading={isLoading}
                        sellToggleOpen={sellToggleOpen}
                        disputeToggleOpen={openDisputeWizard}
                        closeSideBar={closeSideBar}
                    />
                }
                detailViewId="my-offers"
                detailViewIsOpened={detailViewIsOpened}
                detailSideBarTitle={`${t("sidebar:title.offerId")}: ${
                    currentRowData?.offer?.offerNumber
                }`}
            />
            <ModalDialog {...modalProps}>
                <p>{t("actions:dialogs.unPublishOffer.text")}</p>
                <p className="list">
                    {modalProps.modal &&
                        modalProps.modal.original &&
                        `${
                            modalProps.modal.original.refrigerantGasTypeCode
                        } | ${modalProps.modal.original.quantity?.toFixed(
                            2
                        )} kg | ${
                            modalProps.modal.original.pressumedGradeName
                        } | ${
                            modalProps.modal.original.analysisDone === "False"
                                ? t("filters:analysis.selfAssessment")
                                : t("filters:analysis.measurement")
                        }`}
                </p>
            </ModalDialog>
            <ModalDialog {...sellModalProps}>
                <p> {t("actions:dialogs.sellOffer.text")}</p>
                <p className="list">
                    {sellModalProps.modal &&
                        sellModalProps.modal.length > 0 &&
                        `• ${
                            sellModalProps.modal[0].cylindersAnalysis
                                ? sellModalProps.modal[0].acceptedBidInfo[
                                      `offeredPriceGrade${sellModalProps.modal[0].cylindersAnalysis[0].gradeName}`
                                  ].toFixed(2)
                                : sellModalProps.modal[0].acceptedBidInfo[
                                      `offeredPriceGrade${sellModalProps.modal[0].pressumedGradeName}`
                                  ].toFixed(2)
                        } EUR/kg (${t(
                            "actions:bidActions.establishedQuality"
                        )})`}
                </p>
                <p className="bold">
                    {t("actions:dialogs.revokeBid.offeredItem")}
                </p>
                <p className="list">
                    {sellModalProps.modal &&
                        sellModalProps.modal.length > 0 &&
                        `• ${
                            sellModalProps.modal[0].refrigerantGasTypeCode
                        } | ${sellModalProps.modal[0].quantity.toFixed(
                            2
                        )} kg | ${
                            sellModalProps.modal[0].pressumedGradeName
                        } | ${
                            sellModalProps.modal[0].analysisDone === "False"
                                ? t("filters:analysis.selfAssessment")
                                : t("filters:analysis.measurement")
                        }`}
                </p>
            </ModalDialog>
            <ModalDialog {...rejectModalProps}>
                <p>{t("actions:dialogs.rejectBid.text")}</p>
                <p className="list">
                    {rejectModalProps.modal &&
                        rejectModalProps.modal.length > 0 &&
                        `• ${rejectModalProps.modal[0].acceptedBidInfo.offeredPriceGradeA.toFixed(
                            2
                        )} EUR/kg | ${rejectModalProps.modal[0].acceptedBidInfo.offeredPriceGradeB.toFixed(
                            2
                        )} EUR/kg | ${rejectModalProps.modal[0].acceptedBidInfo.offeredPriceGradeC.toFixed(
                            2
                        )} EUR/kg`}
                </p>
                <p className="bold">
                    {t("actions:dialogs.revokeBid.offeredItem")}
                </p>
                <p className="list">
                    {rejectModalProps.modal &&
                        rejectModalProps.modal.length > 0 &&
                        `• ${
                            rejectModalProps.modal[0].refrigerantGasTypeCode
                        } | ${rejectModalProps.modal[0].quantity.toFixed(
                            2
                        )} kg | ${
                            rejectModalProps.modal[0].pressumedGradeName
                        } | ${
                            rejectModalProps.modal[0].analysisDone === "False"
                                ? t("filters:analysis.selfAssessment")
                                : t("filters:analysis.measurement")
                        }`}
                </p>
            </ModalDialog>

            <PlainModal {...transactionDetailsProps}>
                <TransactionDetails
                    transactionDetails={transactionDetails.details}
                    offer={transactionDetails.offer}
                    paymentMethod="PayIn"
                    paymentType={1}
                />
            </PlainModal>
        </>
    );
};

CleaningOffers.refreshNeeded = false;

export default CleaningOffers;
