import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../components/elements/buttons/Button";
import SidebarField from "../../../../components/elements/sidebar/SidebarField";
import AccordionWrapper from "../../../../components/toggle-view/AccordionWrapper";
import {
    Num,
    NumInfo,
    NumSection,
} from "../../../../components/elements/sidebar/SidebarStyle";

import Slider from "../../../../components/elements/general/Slider";
import helpers from "../../../../core/helpers";
import { VSpace } from "../../../pwa/components/StyledElements";
import {
    BidGradeWrapper,
    BidGrade,
} from "../../../../components/bids/BidGrade";
import useAccordionGroup from "../../../../hooks/_shared/useAccordionGroup";

const splitTag = (tag) => {
    if (!tag || tag.length < 8) return tag;
    return tag.substring(0, 4) + " " + tag.substring(4, 8);
};

const DistDisposalDetailSideBar = ({
    offer,
    details,
    item,
    bid,
    isLoading,
    companyId,
    toggleBidModal,
    bidAlreadyPlaced,
    toggleRevokeBid,
    getActiveBidStatus,
}) => {
    const { t } = useTranslation(["sidebar"]);
    const shouldShow = item && !isLoading;
    const [openAccordion, setOpenAccordion] = useAccordionGroup();

    let currentBid = null;
    if (bid) currentBid = bid;
    if (offer && offer.acceptedBidInfo) currentBid = offer.acceptedBidInfo;
    const activeBidStatus = getActiveBidStatus();
    return (
        <>
            {shouldShow && (
                <>
                    <AccordionWrapper
                        title={t("title.details")}
                        id="details"
                        isOpen={openAccordion}
                        onToggle={setOpenAccordion}
                    >
                        {offer && (
                            <>
                                <SidebarField
                                    label={t("label.gasType")}
                                    value={offer.refrigerantGasTypeCode}
                                />

                                <SidebarField
                                    label={t("label.numberOfCylinders")}
                                    value={offer.numberOfCylinders}
                                />

                                <SidebarField
                                    label={t("label.netWeight")}
                                    value={`${offer.maximumQuantity.toFixed(
                                        2
                                    )} kg`}
                                />

                                <SidebarField
                                    label={t("label.totalExtractedQuantity")}
                                    value={`${offer.quantity.toFixed(2)} kg`}
                                />

                                <SidebarField
                                    label={t(
                                        "label.extraction.qualityEvaluation"
                                    )}
                                    value={
                                        item.hasAnalysis
                                            ? t("filters:analysis.measurement")
                                            : t(
                                                  "filters:analysis.selfAssessment"
                                              )
                                    }
                                />
                            </>
                        )}
                    </AccordionWrapper>
                    {details.buyingItemData.skUs && (
                        <AccordionWrapper
                            title={t("title.contamination")}
                            id="contamination"
                            isOpen={openAccordion}
                            onToggle={setOpenAccordion}
                        >
                            <Slider
                                style={{ padding: "25px 20px 0px" }}
                                title={t(
                                    "sidebar:title.extraction.extractionCylinder"
                                )}
                                captions={details.buyingItemData.skUs.map(
                                    (sku) => splitTag(sku.tagId)
                                )}
                            >
                                {details.buyingItemData.skUs.map((sku, idx) => {
                                    let cnt = 0;
                                    return (
                                        <div id={`slide-${idx + 1}`} key={idx}>
                                            {!sku.saIsContaminated &&
                                                !sku.saIsMixture &&
                                                !sku.saHasR22 && (
                                                    <NumSection>
                                                        <Num className="tight">
                                                            N/A
                                                        </Num>
                                                        <NumInfo>
                                                            {t(
                                                                "actions:noContamination"
                                                            )}
                                                        </NumInfo>
                                                    </NumSection>
                                                )}

                                            {sku.saIsContaminated && (
                                                <NumSection>
                                                    <Num>{++cnt}</Num>
                                                    <NumInfo>
                                                        {t(
                                                            "screens:stock.aGradeText"
                                                        )}
                                                    </NumInfo>
                                                </NumSection>
                                            )}
                                            {sku.saIsMixture && (
                                                <NumSection>
                                                    <Num>{++cnt}</Num>
                                                    <NumInfo>
                                                        {t(
                                                            "screens:stock.bGradeText"
                                                        )}
                                                    </NumInfo>
                                                </NumSection>
                                            )}
                                            {sku.saHasR22 && (
                                                <NumSection>
                                                    <Num>{++cnt}</Num>
                                                    <NumInfo>
                                                        {t(
                                                            "screens:stock.cGradeText"
                                                        )}
                                                    </NumInfo>
                                                </NumSection>
                                            )}
                                        </div>
                                    );
                                })}
                            </Slider>
                        </AccordionWrapper>
                    )}
                    {currentBid && currentBid.bidStatus === activeBidStatus && (
                        <AccordionWrapper
                            title={t("title.bid.details")}
                            id="bid.details"
                            isOpen={openAccordion}
                            onToggle={setOpenAccordion}
                        >
                            <SidebarField
                                label={t("label.bid.date")}
                                value={helpers.toLocalDateIgnoreTime(
                                    currentBid.bidDateTime
                                )}
                            />
                            <VSpace size="10px" />

                            <BidGradeWrapper>
                                {/* <BidGrade
                                    grade="a"
                                    amount={currentBid.offeredPriceGradeA}
                                    quantity={offer.maximumQuantity}
                                />
                                <BidGrade
                                    grade="b"
                                    amount={currentBid.offeredPriceGradeB}
                                    quantity={offer.maximumQuantity}
                                /> */}
                                <BidGrade
                                    grade="c"
                                    amount={currentBid.offeredPriceGradeC}
                                    quantity={offer.maximumQuantity}
                                />
                            </BidGradeWrapper>
                        </AccordionWrapper>
                    )}
                    <AccordionWrapper
                        title={t("title.buyer")}
                        id="buyer"
                        isOpen={openAccordion}
                        onToggle={setOpenAccordion}
                    >
                        <SidebarField
                            label={t("title.company")}
                            value={item.companyName}
                        />
                        <SidebarField
                            label={t("label.companyVAT")}
                            value={item.vatNumber}
                        />
                        <SidebarField
                            label={t("label.companyStreet")}
                            value={item.street}
                        />
                        <SidebarField
                            label={t("label.companyCity")}
                            value={item.city}
                        />
                        <SidebarField
                            label={t("label.companyState")}
                            value={item.region}
                        />
                        <SidebarField
                            label={t("label.companyZIP")}
                            value={item.postalCode}
                        />
                        <SidebarField
                            label={t("label.companyCountry")}
                            value={item.countryName}
                        />
                        <SidebarField
                            label={t("label.companyContactPersonName")}
                            value={item.contactPerson}
                        />
                        <SidebarField
                            label={t("label.companyEmail")}
                            value={item.email}
                        />
                        <SidebarField
                            label={t("label.companyPhone")}
                            value={item.phone}
                        />
                    </AccordionWrapper>
                    {companyId &&
                        companyId !== item.companyId &&
                        !bidAlreadyPlaced &&
                        activeBidStatus === 0 && (
                            <div style={{ textAlign: "center" }}>
                                <Button
                                    onClick={toggleBidModal}
                                    className="primary"
                                >
                                    {t("label.placeBid")}
                                </Button>
                            </div>
                        )}
                    {bidAlreadyPlaced && bid && bid.bidStatus === 0 && (
                        <div style={{ textAlign: "center" }}>
                            <Button
                                onClick={toggleRevokeBid}
                                className="primary"
                            >
                                {t("actions:bidActions.revokeBid")}
                            </Button>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default DistDisposalDetailSideBar;
