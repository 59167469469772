import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { InputSubmit, Select } from "../../../../components/elements/form-elements";
import InputElement from "../../../../components/elements/form-elements/InputElement";
import Flex from "../../../../components/layout/flex/Flex";
import AccordionWrapper from "../../../../components/toggle-view/AccordionWrapper";
import Wrappers from "../../../../components/wrappers/Wrappers";
import useAccordionGroup from "../../../../hooks/_shared/useAccordionGroup";

const ButtonLink = styled.button`
    background: transparent;
    height: 25px;
    box-shadow: none;
    border: none;
    line-height: 25px;
    font-size: 16px;
    padding: 0;
    text-align: left;
    color: ${(p) => p.theme.primaryColor};
    font-weight: 500;
    margin: 15px 0px 20px 0px;
    outline: none;
    &:hover {
        font-weight: 600;
        cursor: pointer;
    }
`;
const EmployeeEditForm = ({
    formik,
    details,
    isLoading,
    roles,
    handleUsernameChange,
    ownProfile,
}) => {
    const { t } = useTranslation();
    const canEdit = !ownProfile;
    const [openAccordion, setOpenAccordion] = useAccordionGroup(["userAccount", "contact"]);
    return (
        <>
            <AccordionWrapper
                title={`${t("screens:employeeEdit.title_userAccount")}`}
                id="userAccount"
                isOpen={openAccordion}
                onToggle={setOpenAccordion}
                variant="overflow top-space inset"
                style={{ marginTop: "30px" }}
                noBorder
            >
                <Wrappers.FormWrapper variant="nomargin">
                    <Flex.Container>
                        <Flex.Column style={{ width: "min(520px, 100%)" }}>
                            <Flex.Container flexWrap="wrap">
                                <InputElement
                                    labelText={t("forms:userAccount.text")}
                                    name="username"
                                    id="username"
                                    disabled
                                    type="text"
                                    wrapperVariant="flex-half"
                                    action={
                                        ownProfile && (
                                            <ButtonLink onClick={handleUsernameChange}>
                                                {t("forms:username.changeUsername")}
                                            </ButtonLink>
                                        )
                                    }
                                    {...formik}
                                />
                                <Select
                                    labelText={t("common:roles.labelText")}
                                    name="roleId"
                                    id="roleId"
                                    options={
                                        roles.length > 0
                                            ? roles.map((role) => ({
                                                  key: role.id,
                                                  value: role.id,
                                                  label: t("common:roles." + role.name),
                                              }))
                                            : []
                                    }
                                    wrapperVariant="flex-half"
                                    disabled={!canEdit}
                                    {...formik}
                                />
                            </Flex.Container>
                        </Flex.Column>
                    </Flex.Container>
                </Wrappers.FormWrapper>
            </AccordionWrapper>
            <AccordionWrapper
                title={`${t("screens:employeeEdit.title_contact")}`}
                id="contact"
                onToggle={setOpenAccordion}
                isOpen={openAccordion}
                variant="overflow top-space inset"
                noBorder
            >
                <Wrappers.FormWrapper variant="nomargin">
                    <Flex.Container>
                        <Flex.Column style={{ width: "520px" }}>
                            <Flex.Container flexWrap="wrap">
                                <InputElement
                                    disabled={!ownProfile}
                                    labelText={t("forms:firstName.text")}
                                    name="firstName"
                                    id="firstName"
                                    type="text"
                                    wrapperVariant="flex-half"
                                    {...formik}
                                />
                                <InputElement
                                    disabled={!ownProfile}
                                    labelText={t("forms:lastName.text")}
                                    name="lastName"
                                    id="lastName"
                                    type="text"
                                    wrapperVariant="flex-half"
                                    {...formik}
                                />
                                <InputElement
                                    disabled={!ownProfile}
                                    labelText={t("forms:phoneNumber.text")}
                                    name="phoneNumber"
                                    id="phoneNumber"
                                    type="text"
                                    wrapperVariant="flex-half"
                                    {...formik}
                                />
                                <InputElement
                                    labelText={t("forms:email.text")}
                                    name="email"
                                    id="email"
                                    disabled
                                    type="text"
                                    wrapperVariant="flex-half"
                                    {...formik}
                                />
                            </Flex.Container>
                        </Flex.Column>
                    </Flex.Container>

                    <Wrappers.Wrapper className="buttons-wrapper mt-0">
                        <Flex.Row>
                            <InputSubmit
                                disabled={isLoading}
                                variant="link"
                                value={t("screens:employeeEdit.button-edit_text")}
                            />
                        </Flex.Row>
                    </Wrappers.Wrapper>
                </Wrappers.FormWrapper>
            </AccordionWrapper>
        </>
    );
};
export default EmployeeEditForm;
