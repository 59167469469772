import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Tooltip from "../../components/tooltip/Tooltip";

const GradeCell = ({ cell: { value } }) => {
    const { t } = useTranslation();
    const [tooltip, setTooltip] = useState({
        tips:{
            A:t("screens:stock.aGradeText"),
            B:t("screens:stock.bGradeText"),
            C:t("screens:stock.cGradeText"),
        },
        visible: false,
    });

    const handleHover = (tooltipIsVisible) => {
        setTooltip((tooltip) => ({ ...tooltip, visible: tooltipIsVisible }));
    };

    return (
        <span style={{position:"relative"}}
            onMouseEnter={() => handleHover(true)}
            onMouseLeave={() => handleHover(false)}
        >
            {value}
            <Tooltip position="top-left" visible={tooltip.visible} tooltip={tooltip.tips[value]} />
        </span>
    );
};

export default GradeCell;
