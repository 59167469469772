import React from "react";
import { useTranslation } from "react-i18next";
import ClearableInput from "../../../../../components/elements/form-elements/ClearableInput";
import Label from "../../../../../components/elements/form-elements/Label";
import Select from "../../../../../components/elements/form-elements/Select";
import Filters from "../../../../../components/filter/Filters";
import useDebouncedCallback from "../../../../../hooks/_shared/useDebouncedCallback";
import actionTypes from "./filterCompanyActionTypes";
import globals from "../../../../../globalVars";

const CompanyFilter = ({ filterState, dispatch }) => {
    const { t } = useTranslation("filters");
    const [emailChanged] = useDebouncedCallback((text) => {
        dispatch({ type: actionTypes.SEARCH_EMAIL, payload: text.trim() });
    });    
    const [companyNameChanged] = useDebouncedCallback((text) => {
        dispatch({ type: actionTypes.SEARCH_COMPANYNAME, payload: text.trim() });
    });
    const [vatChanged] = useDebouncedCallback((text) => {
        dispatch({ type: actionTypes.SEARCH_VAT, payload: text.trim() });
    });
    const [licenceChanged] = useDebouncedCallback((text) => {
        dispatch({ type: actionTypes.SEARCH_LICENCENUMBER, payload: text.trim() });
    });

    // FullyRegistered = 0,
    // PendingConfirmation = 1,
    // ConfirmationSent = 2

    const activationStatus = {
        [t("common:companyActivation.PendingConfirmation")]:1,
        [t("screens:statistics.approvedCompanies")]:2,
        [t("common:companyActivation.FullyRegistered")]:0,
    };

    const partnerStatus = {
        [t("common:partnerStatus.0")]:0,
        [t("common:partnerStatus.1")]:1,
        [t("common:partnerStatus.2")]:2,
    };


    return (
        <>
            <Filters.Wrapper>
                <Filters.Item>
                    <Label htmlFor="companyName" className="sr-only">
                        {t("company.companyName")}
                    </Label>
                    <ClearableInput
                        key="companyName"
                        name="companyName"
                        type="text"
                        defaultValue={filterState.filter.companyName}
                        onChange={(evt) => {
                            companyNameChanged(evt.currentTarget.value);
                        }}
                        placeholder={t("company.companyName")}
                        className="filters"
                    />
                </Filters.Item>               
                <Filters.Item>
                    <Label htmlFor="email" className="sr-only">
                        {t("email")}
                    </Label>
                    <ClearableInput
                        key="email"
                        name="email"
                        type="text"
                        defaultValue={filterState.filter.email}
                        onChange={(evt) => {
                            emailChanged(evt.currentTarget.value);
                        }}
                        placeholder={t("email")}
                        className="filters"
                    />
                </Filters.Item>
                <Filters.Item>
                    <Label htmlFor="vat" className="sr-only">
                        {t("company.vat")}
                    </Label>
                    <ClearableInput
                        key="vat"
                        name="vat"
                        type="text"
                        defaultValue={filterState.filter.vat}
                        onChange={(evt) => {
                            vatChanged(evt.currentTarget.value);
                        }}
                        placeholder={t("company.vat")}
                        className="filters"
                    />
                </Filters.Item>
                <Filters.Item>
                    <Label htmlFor="licenceNumber" className="sr-only">
                        {t("certificate.licenceNumber")}
                    </Label>
                    <ClearableInput
                        key="licenceNumber"
                        name="licenceNumber"
                        type="text"
                        defaultValue={filterState.filter.licenceNumber}
                        onChange={(evt) => {
                            licenceChanged(evt.currentTarget.value);
                        }}
                        placeholder={t("certificate.licenceNumber")}
                        className="filters"
                    />
                </Filters.Item>
                <Filters.Item>
                    <Select
                        key="country"
                        name="countryId"
                        errors={[]}
                        touched={[]}
                        defaultItem={{
                            key: "all",
                            value: "",
                            label: t("filters:clients.country"),
                        }}
                        defaultValue={filterState.userStatus}
                        options={
                            globals.countries
                                ? globals.countries.map((country, index) => ({
                                      key: country.id,
                                      value: country.id,
                                      label: country.localName,
                                  }))
                                : []
                        }
                        handleChange={(evt) => {
                            if (evt.currentTarget.value === "") {
                                dispatch({
                                    type: actionTypes.RESET_COUNTRY,
                                    payload: evt.currentTarget.value,
                                });
                            } else {
                                dispatch({
                                    type: actionTypes.SET_COUNTRY,
                                    payload: evt.currentTarget.value,
                                });
                            }
                        }}
                        className="filters"
                        labelText={t("filters:clients.country")}
                        labelClassName="sr-only"
                    />
                </Filters.Item>
                <Filters.Item>
                    <Select
                        id="status"
                        name="status"
                        key="status"
                        errors={[]}
                        touched={[]}
                        defaultItem={{
                            key: "status",
                            value: "",
                            label: t("status"),
                        }}
                        defaultValue={filterState.status}
                        options={Object.entries(activationStatus).map(
                            ([key, value], index) => ({
                                key: key,
                                value: value,
                                label: key,
                            })
                        )}
                        handleChange={(evt) => {
                            dispatch({
                                type:
                                    evt.currentTarget.value === ""
                                        ? actionTypes.SEARCH_STATUS_RESET
                                        : actionTypes.SEARCH_STATUS,
                                payload: evt.currentTarget.value,
                            });
                        }}
                        className="filters"
                        labelText={t("clients.applicationType")}
                        labelClassName="sr-only"
                    />
                </Filters.Item>
                <Filters.Item>
                    <Select
                        id="partnerStatus"
                        name="partnerStatus"
                        key="partnerStatus"
                        errors={[]}
                        touched={[]}
                        defaultItem={{
                            key: "partnerStatus",
                            value: "",
                            label: t("partnerStatus"),
                        }}
                        defaultValue={filterState.partnerStatus}
                        options={Object.entries(partnerStatus).map(
                            ([key, value], index) => ({
                                key: key,
                                value: value,
                                label: key,
                            })
                        )}
                        handleChange={(evt) => {
                            dispatch({
                                type:
                                    evt.currentTarget.value === ""
                                        ? actionTypes.SEARCH_PARTNER_STATUS_RESET
                                        : actionTypes.SEARCH_PARTNER_STATUS,
                                payload: evt.currentTarget.value,
                            });
                        }}
                        className="filters"
                        labelText={t("clients.applicationType")}
                        labelClassName="sr-only"
                    />
                </Filters.Item>
            </Filters.Wrapper>
        </>
    );
};

export default CompanyFilter;
