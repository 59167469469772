import CompanyService from "../../../../services/Company/CompanyService";
import createListStateModule from "../../createListStateModule";

const companiesListStateModule = createListStateModule({
    stateNamespace: "companies",
    itemsPropertyName: "companies",
    detailsPropertyName: "companyData",
    loadItemsMethodName: "loadCompanies",
    loadDetailMethodName: "loadCompanyById",
    itemsDataMethod: CompanyService.getCompaniesWithAdmin,
    detailDataMethod: async id => {
        return await CompanyService.getCompanyData(id);
    },
    deleteMethodName: "deleteCompany",
    deleteDataMethod: CompanyService.deleteCompany,
    defaultItemsData: {data:[]}
});

export default companiesListStateModule;
