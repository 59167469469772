import React from "react";
import { useTranslation } from "react-i18next";
import { Select } from "../../../../components/elements/form-elements";
import Filters from "../../../../components/filter/Filters";
import TransactionParentType from "../../../../core/enums/TransactionParentType";
import TransactionFlowType from "../../../../core/enums/TransactionFlowType";
import TransactionParentStatus from "../../../../core/enums/TransactionParentStatus";


const TransactionGroupFilter = ({ filterState, dispatch, actionTypes }) => {
    const { t } = useTranslation("filters");



    return (
        <>
            <Filters.Wrapper className="filter-wrapper">
                 <Filters.Item>
                    <Select
                        name="parentType"
                        key="parentType"
                        errors={[]}
                        touched={[]}
                        defaultItem={{
                            key: "all",
                            value: "",
                            label: t("transactions.parentLabel"),
                        }}
                        defaultValue={filterState.size}
                        options={Object.keys(TransactionParentType).map(
                            (key, i) => {
                                return {
                                    key: key,
                                    value: key,
                                    label: t(
                                        `transactions.parentType.${TransactionParentType[key]}`
                                    ),
                                };
                            }
                        )}
                        handleChange={(evt) => {
                            dispatch({
                                type:
                                    evt.currentTarget.value === ""
                                        ? actionTypes.RESET_PARENT_TYPE
                                        : actionTypes.SET_PARENT_TYPE,
                                payload: evt.currentTarget.value,
                            });
                        }}
                        className="filters"
                        labelText={t("transactions.parentLabel")}
                        labelClassName="sr-only"
                    />
                </Filters.Item>
                <Filters.Item>
                    <Select
                        name="flowType"
                        key="flowType"
                        errors={[]}
                        touched={[]}
                        defaultItem={{
                            key: "all",
                            value: "",
                            label: t("transactions.parentTypeLabel"),
                        }}
                        defaultValue={filterState.size}
                        options={Object.keys(TransactionFlowType).map(
                            (key, i) => {
                                return {
                                    key: key,
                                    value: key,
                                    label: t(
                                        `transactions.flowType.${TransactionFlowType[key]}`
                                    ),
                                };
                            }
                        )}
                        handleChange={(evt) => {
                            dispatch({
                                type:
                                    evt.currentTarget.value === ""
                                        ? actionTypes.RESET_FLOW_TYPE
                                        : actionTypes.SET_FLOW_TYPE,
                                payload: evt.currentTarget.value,
                            });
                        }}
                        className="filters"
                        labelText={t("transactions.parentTypeLabel")}
                        labelClassName="sr-only"
                    />
                </Filters.Item>
               
                <Filters.Item>
                    <Select
                        name="status"
                        key="status"
                        errors={[]}
                        touched={[]}
                        defaultItem={{
                            key: "all",
                            value: "",
                            label: t("transactions.parentStatusLabel"),
                        }}
                        defaultValue={filterState.size}
                        options={Object.keys(TransactionParentStatus).map(
                            (key, i) => {
                                return {
                                    key: key,
                                    value: key,
                                    label: t(
                                        `transactions.parentStatus.${key}`
                                    ),
                                };
                            }
                        )}
                        handleChange={(evt) => {
                            dispatch({
                                type:
                                    evt.currentTarget.value === ""
                                        ? actionTypes.RESET_PARENT_STATUS
                                        : actionTypes.SET_PARENT_STATUS,
                                payload: evt.currentTarget.value,
                            });
                        }}
                        className="filters"
                        labelText={t("transactions.parentStatusLabel")}
                        labelClassName="sr-only"
                    />
                </Filters.Item> 
            </Filters.Wrapper>
        </>
    );
};

export default TransactionGroupFilter;
