import React, { useRef, useState } from "react";
import styled, { css } from "styled-components";
import { ReactComponent as SearchSvg } from "../../../assets/pwa/search.svg";
import { Input } from "./index";


const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
    window.HTMLInputElement.prototype,
    "value"
).set;

const SvgSearch = styled(SearchSvg)``;

const SearchButton = styled.button`
    position: absolute;
    top: 1px;
    right: 1px;
    width: 44px;
    height: 44px;
    background: ${p => p.theme.primaryColor};
    border-radius: 6px;
    outline: 0;
    border: none;
    color: white;
    padding: 12px 0px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
`;

const StylizedDrop = styled.div`
    box-sizing: border-box;
    width: 100%;
    min-width: 190px;
    background: ${(props) => props.theme.inputBgColor};
    padding: 0 16px;
    border: 1px solid
        ${(props) => props.theme.primaryInputBorderColor || "#d4d4d4"};
    border-radius: ${(props) => props.theme.inputBorderRadius || "5px"};
    position: absolute;
    top: ${p=>p.direction==="up" ? "auto": "42px"};
    bottom: ${p=>p.direction==="up" ? "110%": "auto"};
    z-index: 300;
    background: #fff;
    text-align: left;
    cursor: pointer;
    box-shadow: 4px 4px 16px 0 rgba(0, 0, 0, 0.16);
    transition: all 0.3s ease-in;
    opacity: 0;
    visibility: hidden;
    font-size: ${(props) => props.theme.inputFontSize};

    & ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    & ul li {
        list-style: none;
        padding: 10px 5px;
    }

    &.thin ul li {
        list-style: none;
        padding: 5px 5px;
    }

    & ul li:hover {
        color: ${(props) => props.theme.primaryColor} !important;
    }

    & ul li:not(:last-child) {
        border-bottom: 1px solid #e2e2e2;
    }

    ${(props) =>
        props.hasDefault &&
        css`
        & ul li:first-child {
        font-weight:600;
    `};
`;

const Wrapper = styled.div`
    position: relative;
    
    & input {
        padding: 0px 36px 0px 16px;
    }

    &.pwa ${SearchButton} {
        height: 46px;
        background-color: transparent;
        padding: 6px 0px;
        & g,
        & line {
            stroke: ${(p) => p.theme.primaryColor};
        }
    }

    &.pwa input {
        height: 44px;
    }
    &.pwaBig ${SearchButton} {
        height: 44px;
        background-color: transparent;
        padding: 6px 0px;
        & g,
        & line {
            stroke: ${(p) => p.theme.primaryColor};
        }
    }

    &.pwaBig input {
        height: 44px;
        font-size: 13px;
    }

    &.open ${StylizedDrop} {
        opacity: 1;
        visibility: visible;
    }
`;

const SearchInput = (props) => {
    let { pwa, pwaBig, value, searchFn, formik, direction, ...rest } = props;
    const inputEl = useRef();
    const isSelected = useRef(false);
    const [, setInputVal] = useState(value);

    const [results, setResults] = useState([]);

    const handleSearch = async (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        if(rest.disabled) return;
        props.onChange && props.onChange(ev);
        if (isSelected.current === true) {
            isSelected.current = false;
            return;
        }
        if (!searchFn) return;
        const results = await searchFn(inputEl.current.value);
        setResults(results);
    };

    const handleSelect = (ev) => {
        const selected = ev.target.dataset.value;
        isSelected.current = true;
        setResults([]);
        props.itemSelected && props.itemSelected(selected);
        setInputVal(selected);
        nativeInputValueSetter.call(inputEl.current, selected);
        let inpEvt = new Event("input", { bubbles: true });
        inputEl.current.dispatchEvent(inpEvt);
       
    };

    let wrapperClass = results && results.length ? "open" : "";
    if (pwa) wrapperClass += " pwa";
    else if (pwaBig) wrapperClass += " pwaBig";

    return (
        <Wrapper className={wrapperClass}>
            <Input
                ref={inputEl}
                value={value}
                {...rest}
                onChange={handleSearch}
                type="generic"
            />
            <SearchButton onClick={handleSearch}>
                <SvgSearch />
            </SearchButton>
            <StylizedDrop direction={direction} onClick={handleSelect} hasDefault={false} data-test="dropdown-options">
                <ul>
                    {results &&
                        results.length &&
                        results.map((option, idx) => (
                            <li key={idx} data-value={option} data-idx={idx}>
                                {option}
                            </li>
                        ))}
                </ul>
            </StylizedDrop>
        </Wrapper>
    );
};

export default SearchInput;
