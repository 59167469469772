import styled from "styled-components";

import { ReactComponent as FilterSvg } from "../../../assets/pwa/filters.svg";
import { ReactComponent as ArrowSvg } from "../../../assets/pwa-right.svg";
import { ReactComponent as CloseSvg } from "../../../assets/pwa/pwa-close.svg";
import { ReactComponent as NoImageSvg } from "../../../assets/pwa/no-image.svg";
import { ReactComponent as ScanSvg } from "../../../assets/pwa/scan.svg";
import { ReactComponent as NoDataSvg } from "../../../assets/pwa/no-data.svg";
import { ReactComponent as EditSvg } from "../../../assets/pwa/edit.svg";
import { ReactComponent as DeleteSvg } from "../../../assets/pwa/delete.svg";

const SvgScan = styled(ScanSvg)`
&.white {
    & path {
        stroke: #fff;
    }
}
`;

const SvgEdit = styled(EditSvg)`
`;

const SvgDelete = styled(DeleteSvg)`
&.disabled * {
        opacity: 0.3;
    }
`;


const SvgNoData = styled(NoDataSvg)`
`;

const SvgNoImage = styled(NoImageSvg)`
    padding: 9% 16%;
    &.hidden {
        display:none;
    }
`;

const StyledFilter = styled(FilterSvg)`
    width: 32px;
    height: 32px;
    transition: all 0.3s ease;
    margin-left: 24px;
    padding: 4px;

    & g,
    & line {
        stroke: ${(p) => p.theme.primaryColor};
    }

    &.menu {
        margin-left: 0px;
        margin-right: 12px;
        & g,
        & line {
            stroke: #000;
        }
    }

    &.disabled * {
        stroke: #d5d0d0;
        pointer-events: none;
    }
    &:not(.disabled):active {
        background-color: #ddd;
    }
`;

const StyledBack = styled(ArrowSvg)`
    width: 20px;
    height: 20px;
    margin-left: 8px;
    transform: rotate(180deg);

    &.disabled line {
        stroke: #d5d0d0;
    }
`;

const StyledClose = styled(CloseSvg)`
    width: 12px;
    height: 12px;
    margin-left: 8px;
    & line,
    & path {
        stroke: #e94e4e;
    }

    &.active line {
        stroke: #fff;
    }
`;

const StyledArrow = styled(ArrowSvg)`
    width: 12px;
    height: 12px;

    & .arrow {
        stroke: #d5d0d0;
    }

    &.active line {
        stroke: #fff;
    }

    &.filter {
        width: 10px;
        height: 10px;
    }

    &.filter line {
        stroke: #fff;
    }

    &.back {
        width: 18px;
        height: 18px;
        transform: rotate(180deg);

        & line {
            stroke: ${(p) => p.theme.primarColor};
        }
    }
`;

export { StyledFilter, StyledBack, StyledClose, StyledArrow, SvgNoImage, SvgNoData, SvgScan, SvgDelete, SvgEdit };
