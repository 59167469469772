import styled from "styled-components";

export const DataListingContainer = styled.div`
    display: flex;
    flex-grow:1;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 20px 10px 20px 0;
 
    &.tight {
        margin:0;
    }

    &.sub-table {
        margin-left: 40px;
    }
`;

export const DataListingContent= styled.div`
    display:flex;
    flex-wrap:wrap;
    flex: 1;
    align-items: flex-start;
    align-content: flex-start;
    position: relative;
    overflow: visible;

    &.empty {
       min-height: 190px;
    }
`;
