import Color from "color";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import styled from "styled-components";
import { Select } from "../../../../components/elements/form-elements";
import HTMLAnchor from "../../../../components/elements/form-elements/HTMLAnchor";
import FlexContainer from "../../../../components/layout/flex/FlexContainer";
import useBreadcrumbs from "../../../../core/state/navigation/useBreadcrumbs";
import { config } from "../../../../services/Config/config.prod";
import { getApiUrl } from "../../../../services/getAxios";

const QRViewWrapper = styled.div`
    padding: 15px;
`;

const ExportSection = styled.div`
    margin-top: 15px;
`;

const ExportButton = styled(HTMLAnchor)`
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    border-radius: ${(props) => props.theme.buttonsBorderRadius};
    min-width: ${(props) => props.theme.widthButtonMin};
    padding: ${(props) => props.theme.paddingButton};
    box-shadow: ${(props) => props.theme.buttonShadow};
    height: ${(props) => props.theme.heightButtonBig};
    line-height: ${(props) => props.theme.heightButtonBig};
    vertical-align: middle;
    font-size: 0.875rem;

    &.primary {
        background: ${(props) => props.theme.primaryColor};
        border: 1px solid ${(props) => props.theme.primaryColorBorder};
        color: ${(props) => props.theme.primaryColorText};
        min-width: ${(props) => props.theme.widthButtonMin};

        &:hover:enabled {
            background: ${(props) =>
                Color(props.theme.primaryColor).lighten(0.15).string()};
        }
    }
`;

const QRGenerationView = () => {
    const { t } = useTranslation();

    useBreadcrumbs([
        {
            text: t("navigation:qrTags"),
        },
    ]);

    const [selectedValues, setSelectedValues] = useState({ amount: 500 });
    const [selectedType, setSelectedType] = useState({ type: 0 });

    const selectableAmounts = useMemo(() => {
        return [
            {
                key: 1,
                value: 1,
                label: 1,
            },
            {
                key: 2,
                value: 100,
                label: 100,
            },
            {
                key: 3,
                value: 500,
                label: 500,
            },
            {
                key: 4,
                value: 1000,
                label: 1000,
            },
        ];
    }, []);

    const selectableTypes = useMemo(() => {
        return [
            {
                key: 0,
                value: 0,
                label: "CSV",
            },
            {
                key: 1,
                value: 1,
                label: "Excel",
            },
        ];
    }, []);

    const handleChange = (evt) => {
        setSelectedValues({ amount: evt.target.value });
    };

    const handleTypeChange = (evt) => {
        setSelectedType({ type: evt.target.value });
    };

    const downloadUrl = useMemo(() => {
        const url = getApiUrl + config.apiUrl.generateQr + "/" + selectedValues.amount+ "/" + selectedType.type;
        return url;
    }, [selectedValues, selectedType]);


    const onDownload = useCallback(() => {
        toast.success("Succesfully downloaded GUID list for tag printing");
    }, []);

    return (
        <FlexContainer>
            <QRViewWrapper>
                <ExportSection>
                    <Select
                        name="amount"
                        labelText={t("forms:wallet.wizard.payout.amount.text")}
                        handleChange={handleChange}
                        values={selectedValues}
                        options={selectableAmounts}
                    ></Select>
                </ExportSection>
                <ExportSection>
                    <Select
                        name="type"
                        labelText={t("sidebar:label.type")}
                        handleChange={handleTypeChange}
                        values={selectedType}
                        options={selectableTypes}
                    ></Select>
                </ExportSection>
                <ExportSection>
                    <ExportButton
                        className="primary"
                        onClick={onDownload}
                        download={selectedType.type === 0 ? "qrtags.csv" :  "qrtags.xlsx"}
                        href={downloadUrl}
                    >
                        {t("screens:adminQr.generate")}
                    </ExportButton>
                </ExportSection>
            </QRViewWrapper>
        </FlexContainer>
    );
};

export default QRGenerationView;
