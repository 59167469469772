const actionTypes = {
    SEARCH_EMAIL: "SEARCH_USERNAME",
    RESET_SEARCH_EMAIL: "RESET_SEARCH_EMAIL",
    SEARCH_FULLNAME: "SEARCH_FULLNAME",
    RESET_SEARCH_FULLNAME: "RESET_SEARCH_FULLNAME",
    SEARCH_ROLE: "SEARCH_ROLE",
    RESET_SEARCH_ROLE: "RESET_SEARCH_ROLE",
    SET_PAGING: "SET_PAGING",
    SET_SORT: "SET_SORT",
    SEARCH_STATUS: "SEARCH_STATUS",
    SEARCH_COMPANYNAME: "SEARCH_COMPANYNAME",
    SET_COUNTRY:"SET_COUNTRY",
    RESET_COUNTRY:"RESET_COUNTRY",
};

export default actionTypes;
