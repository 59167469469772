import React from 'react';
import { DataCard } from "./DataCard";
import { DataCardsContainer } from "./DataCardsContainer";



const LoadingCards = () => {
    return <DataCardsContainer style={{opacity: '0.5'}}>
        <DataCard key="1">
            <div>...Loading</div>
        </DataCard >
        <DataCard key="2">
            <div>...Loading</div>
        </DataCard>
        <DataCard key="3">
            <div>...Loading</div>
        </DataCard>
    </DataCardsContainer>
}

export default LoadingCards;
