import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import RouteCreator from "../../../core/RouteCreator";
import { toast } from "react-toastify";
import ListingPage from "../../../components/data/ListingPage";
import GreenCell from "../../../components/data/GreenCell";
import useBreadcrumbs from "../../../core/state/navigation/useBreadcrumbs";
import TabLinks from "../../../components/elements/tabs/TabLinks";
import useSharedState from "../../../hooks/_shared/useSharedState";
import useReclaimedMarketFilterState from "./components/ReclaimedMarketFilterState";
import ReclaimedMarketplaceFilter from "./components/ReclaimedMarketFilter";
import AuthorizeComponent from "../../../core/AuthorizeComponent";
import Roles from "../../../core/enums/Roles";
import MarketplaceService from "../../../services/BuyRefrigerant/MarketplaceService";
import DateCell from "../../../components/data/DateCell";
import RoundTabs from "../../../components/elements/tabs/RoundTabs";
import HamburgerMenu from "../../../components/menu/HamburgerMenu";
import HamburgerMenuAction from "../../../components/menu/HamburgerMenuAction";
import { CustomDateCellWithExpiration } from "../../../components/data/DateCellWithExpiration";
import ReclaimedOfferStatus from "../../../core/enums/ReclaimedOfferStatus";
import ReclaimedMarketSidebar from "./components/ReclaimedMarketSidebar";
import PaymentService from "../../../services/Payment/PaymentService";
import { usePlainModal } from "../../../components/modal/PlainModal";
import PlainModal from "../../../components/modal/PlainModal";
import ReclaimedTransactionDetails from "./components/ReclaimedTransactionDetails";

const dealExpirationDays = 7;

const tabItems = (t) => [
    {
        name: t("navigation:marketplace"),
        path: RouteCreator.salesCenter.reclaimedMarketplace(),
    },
    {
        name: t("navigation:myPurchases"),
        path: RouteCreator.salesCenter.reclaimedMarketPurchases(),
    },
];

const ReclaimedMarketPurchases = (props) => {
    const { t, i18n } = useTranslation(["screens", "table"]);
    const [globalState] = useSharedState("global");
    const [tabIndex, setTabIndex] = useState(0);
    const [
        filterState,
        filterDispatch,
        filterActions,
        sortChange,
        currentSortBy,
        pageChange,
        gasTypes,
    ] = useReclaimedMarketFilterState(2);
    const [detailViewIsOpened, setDetailViewIsOpened] = useState(false);
    const [selected, setSelected] = useState({
        offer: null,
        details: null,
        showCancel: false,
    });

    const [tableState, setTableState] = useState({
        data: [],
        loading: false,
        error: null,
    });

    useBreadcrumbs([
        {
            text: t("navigation:salesCenter"),
        },
        {
            text: t("navigation:myPurchases"),
        },
    ]);

    const getInstallationCompanyReclaimedOffers = async () => {
        const res =
            await MarketplaceService.getInstallationCompanyReclaimedOffers(
                filterState
            );
        setTableState({ data: res.data.data });
    };

    useEffect(() => {
        getInstallationCompanyReclaimedOffers();
    }, [filterState]);

    const setActiveTab = (tab) => {
        let offStatus = 2;
        switch (tab) {
            case 0:
                offStatus = ReclaimedOfferStatus.WaitingForBuyerFunds;
                filterDispatch({
                    type: filterActions.SET_SORT,
                    payload: [{ id: "offerDateTime", desc: false }],
                });
                break;
            case 1:
                offStatus = ReclaimedOfferStatus.Sold;
                filterDispatch({
                    type: filterActions.SET_SORT,
                    payload: [{ id: "lastChangeDateTime", desc: true }],
                });
                break;
            default:
                break;
        }

        setTabIndex(tab);
        filterDispatch({
            type: filterActions.RESET_FILTERS,
            payload: null,
        });
        filterDispatch({
            type: filterActions.SET_OFFER_STATUS,
            payload: offStatus,
        });
    };

    const [transactionDetails, setTransactionDetails] = useState({
        offer: "",
        details: "",
    });
    const getTransactionDetails = async (offer) => {
        const result = await PaymentService.getReclaimTransactionDetails(
            offer.reclaimedOfferId
        );
        if (result && result.error) {
            toast.error(t(result.error.message));
        } else {
            setTransactionDetails({
                offer: offer,
                details: result.data.data[0],
            });
            toggleTransactionDetails();
        }
    };

    const {
        modalProps: transactionDetailsProps,
        toggleOpen: toggleTransactionDetails,
    } = usePlainModal({
        onClose: async () => {
            toggleTransactionDetails();
        },
        closeText: t("actions:close"),
    });

    const columns = useMemo(() => {
        const tabColumns = [
            {
                Header: t("table:headers.gasType"),
                accessor: "type",
                Cell: GreenCell,
            },
            {
                Header: t("table:headers.gasQuantity"),
                accessor: "quantity",
                Cell: ({ cell: { row, value } }) =>
                    GreenCell({
                        cell: {
                            value: value.toFixed(2) + ` kg`,
                        },
                    }),
            },
            {
                Header: t("table:headers.grade"),
                accessor: "grade",
                Cell: ({ cell: { value } }) => t("filters:cylinders.reclaimed"),
            },
            {
                Header: t("table:headers.owner"),
                accessor: "seller",
            },
            {
                Header: t("forms:cylinder.price"),
                accessor: "price",
                Cell: ({ cell: { row, value } }) => {
                    const ppk = (value / row.original.quantity).toFixed(2);
                    return (
                        <span
                            title={`${ppk} EUR ${t(
                                "forms:cylinder.perKiloReclaimed"
                            )}`}
                        >
                            {`${value.toFixed(2)} EUR`}
                        </span>
                    );
                },
            },
        ];

        if (tabIndex === 0)
            tabColumns.push({
                Header: t("stockListing.headers.dealExpiration"),
                accessor: "offerDateTime",
                Cell: ({ cell: { value }, row: { original } }) => {
                    let translation = "-";
                    const offerStatus = original.status;
                    let date = new Date(value);
                    date.setDate(date.getDate() + dealExpirationDays);
                    if (offerStatus === 2) {
                        translation = "screens:myOffers.offerStatus.2";
                    } else if (offerStatus === 3) {
                        translation = "screens:myOffers.offerStatus.3";
                    } else if (offerStatus === 4) {
                        translation = "screens:myOffers.offerStatus.4";
                    }
                    return CustomDateCellWithExpiration(date, translation);
                },
            });

        if (tabIndex === 1)
            tabColumns.push({
                Header: t("table:headers.purchasedDate"),
                accessor: "lastChangeDateTime",
                Cell: DateCell,
            });

        tabColumns.push({
            Header: "",
            accessor: "reclaimedOfferId",
            Cell: ({ cell: { value }, row: { original } }) => {
                let disableCancel = true;
                if (original.status === 2) {
                    let expDate = new Date(original.offerDateTime);
                    expDate.setDate(expDate.getDate() + dealExpirationDays);
                    disableCancel = expDate > new Date();
                }
                if (original.status !== 2) return null;
                return (
                    <AuthorizeComponent
                        roles={[
                            Roles.InstallerCompanyAdmin,
                            Roles.InstallerCompanyUser,
                        ]}
                    >
                        <HamburgerMenu>
                            {original.status === 2 && (
                                <HamburgerMenuAction
                                    disabled={
                                        !globalState.hasValidCompanyCertificate ||
                                        !globalState.hasCertificateRights
                                    }
                                    text={t(
                                        "actions:dialogs.transactionDetails.title"
                                    )}
                                    onClick={() =>
                                        getTransactionDetails(original)
                                    }
                                />
                            )}
                        </HamburgerMenu>
                    </AuthorizeComponent>
                );
            },
        });
        return tabColumns;
    }, [t, tabIndex]);

    const onSelect = useCallback(
        (row) => {
            const selectRow = async (offerId) => {
                const selectedOffer = tableState.data.filter(
                    (of) => of.reclaimedOfferId === offerId
                )[0];
                const res = await MarketplaceService.getReclaimedOfferById({
                    id: offerId,
                });
                let showCancel = false;
                if (selectedOffer.status === 2) {
                    let expDate = new Date(selectedOffer.offerDateTime);
                    expDate.setDate(expDate.getDate() + dealExpirationDays);
                    showCancel = expDate < new Date();
                }
                setSelected({
                    details: res.data.data[0],
                    offer: selectedOffer,
                    showCancel: showCancel,
                });
                setDetailViewIsOpened(true);
            };
            selectRow(row.original.reclaimedOfferId);
        },
        [tableState]
    );

    return (
        <>
            <Helmet>
                <title>{t("seo:marketplace.title")}</title>
            </Helmet>
            <TabLinks
                variant="shadowed"
                items={tabItems(t, globalState.isDistributor)}
                idx={1}
                style={{ marginTop: "0px", padding: "10px 0px 10px 40px" }}
            />
            <ListingPage
                key={tabIndex}
                columns={columns}
                tableState={tableState}
                sorting={{
                    enableSorting: true,
                    onSortByChange: sortChange,
                    currentSortBy: currentSortBy,
                }}
                paging={{
                    enablePaging: true,
                    pageCount: filterState.totalPages,
                    pageSize: filterState.pageSize,
                    onPageChange: pageChange,
                    currentPageIndex: filterState.page - 1,
                }}
                detailView={
                    <ReclaimedMarketSidebar
                        details={selected.details}
                        offer={selected.offer}
                        isDistributor={globalState.isDistributor}
                        offerStatus={filterState.reclaimedOfferStatus}
                        showCancel={selected.showCancel}
                    />
                }
                detailSideBarTitle={`${t("sidebar:title.offerId")}: ${
                    selected.offer?.offerNumber
                }`}
                onSelect={onSelect}
                closeSideBar={() => setDetailViewIsOpened(false)}
                detailViewIsOpened={detailViewIsOpened}
                filterComponent={() => {
                    return (
                        <ReclaimedMarketplaceFilter
                            filterState={filterState.filter}
                            dispatch={filterDispatch}
                            actionTypes={filterActions}
                            gasTypes={gasTypes}
                        />
                    );
                }}
                showTitle={true}
                filterTitle={t("filters:reclaimedMarketPlace.filterTitle")}
                variants={{
                    headerViewVariant: "right-action",
                }}
                actions={{
                    headerTabs: () => {
                        return (
                            <RoundTabs
                                numTabs={2}
                                tabIndex={tabIndex}
                                activeFn={setActiveTab}
                                tabs={[
                                    {
                                        label: t(
                                            "screens:myOffers.tabs.reserved"
                                        ),
                                        activeValue: 0,
                                    },
                                    {
                                        label: t(
                                            "screens:myBids.tabs.purchased"
                                        ),
                                        activeValue: 1,
                                    },
                                ]}
                            />
                        );
                    },
                }}
            />
            <PlainModal {...transactionDetailsProps}>
                <ReclaimedTransactionDetails
                    transactionDetails={transactionDetails.details}
                    offer={transactionDetails.offer}
                    paymentMethod="PayIn"
                    paymentType={1}
                />
            </PlainModal>
        </>
    );
};

export default ReclaimedMarketPurchases;
