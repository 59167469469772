import React from "react";
import styled from "styled-components";
import {
    buildValidation,
    skipValidation,
} from "../../../../../core/validations/ValidationRule";

import { Checkbox } from "../../../../../components/elements/form-elements";
import { VSpace } from "../../../../pwa/components/StyledElements";
import * as yup from "yup";

const HR = styled.div`
    width: 100%;
    height: 1px;
    border-top: 1px solid #eee;
`;
const InfoWrapper = styled.div`
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;

    & > section {
        width: 33.3333%;
        display: flex;
        flex-direction: column;
        font-size: 12px;
        font-weight: 700;

        & > div.wrong {
            color: #f0c40d !important;
        }
    }

    & > div.third {
        width: 33.3333%;
        font-size: 12px;
        font-weight: 600;
    }

    & > div.small {
        width: 16.6666%;
        font-size: 12px;
        font-weight: 600;
        color: ${(p) => p.theme.primaryColor};
    }

    & > div.half {
        font-size: 12px;
        font-weight: 600;
        width: 50%;
    }

    & > section.double {
        width: 66.666%;
        color: #f0c40d !important;
    }

    & > section.bold {
        font-size: 14px;
        font-weight: 700;
    }

    & > section.green {
        color: ${(p) => p.theme.primaryColor};
    }
`;

const SectionTitle = styled.div`
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    color: ${(p) => p.theme.primaryColor};
`;

const validDispute = (t, index) => {
    return yup.lazy((values) => {
        if (!values[`disputeThisResult_${index}`]) return skipValidation();
        return buildValidation({
            [`gasConfirmed_${index}`]: yup.boolean().required().oneOf([true]),
            [`quantityConfirmed_${index}`]: yup
                .boolean()
                .required()
                .oneOf([true]),
            [`gradeConfirmed_${index}`]: yup.boolean().required().oneOf([true]),
        });
    });
};

const WizardDisputeConfirm = (t, disputes) => {
    const initialValues = {};
    const steps = [];

    disputes.forEach((cyl) => {
        const index = cyl.disputeDetailId;

        initialValues[`gasConfirmed_${index}`] =
            cyl.sellerCylinderDetails.gasCode ===
            cyl.buyerCylinderDetails.gasCode;
        initialValues[`gradeConfirmed_${index}`] =
            cyl.sellerCylinderDetails.gradeName ===
            cyl.disputeDetailView.gradeName;
        initialValues[`quantityConfirmed_${index}`] =
            cyl.sellerCylinderDetails.extractedQuantity ===
            cyl.disputeDetailView.quantity;
        initialValues[`id_${index}`] = cyl.disputeDetailId;
        initialValues[`disputeThisResult_${index}`] =
            (cyl.sellerCylinderDetails.extractedQuantity !==
                cyl.disputeDetailView.quantity) |
            (cyl.sellerCylinderDetails.gradeName !==
                cyl.disputeDetailView.gradeName);

        steps.push({
            name: `${t("forms:qualityTest.cylinder")}: ${cyl.cylinderTagId}`,
            fieldNames: [
                `gradeConfirmed_${index}`,
                `quantityConfirmed_${index}`,
                `id_${index}`,
            ],
            validation: validDispute(t, index),
            fields: (formik) => (
                <>
                    <InfoWrapper>
                        <section> </section>
                        <section className="bold">
                            <div>
                                {t("forms:dispute.wizard.qualityAssessment")}
                            </div>
                            <div>{cyl.sellerCylinderDetails.companyName}</div>
                        </section>
                        <section className="bold">
                            <div>
                                {t("sidebar:title.quality.qualityConfirmation")}
                            </div>
                            <div>{cyl.buyerCylinderDetails.companyName}</div>
                        </section>
                    </InfoWrapper>
                    <InfoWrapper>
                        <section>
                            <div>{t("forms:cylinder.cylinderGas")}:</div>
                            <div>{t("screens:technician.netQuantity")}:</div>
                            <div>
                                {t("sidebar:label.totalExtractedQuantity")}:
                            </div>
                            <div>
                                {t("sidebar:label.extraction.qualityGrade")}:
                            </div>
                        </section>
                        <section className="green">
                            <div>{cyl.sellerCylinderDetails.gasCode}</div>
                            <div>
                                {cyl.sellerCylinderDetails.netQuantity.toFixed(
                                    2
                                )}
                                kg
                            </div>
                            <div>
                                {cyl.sellerCylinderDetails.extractedQuantity.toFixed(
                                    2
                                )}
                                kg
                            </div>
                            <div>{cyl.sellerCylinderDetails.gradeName}</div>
                        </section>

                        <section className="green">
                            <div>{cyl.buyerCylinderDetails.gasCode}</div>
                            <div>
                                {cyl.buyerCylinderDetails.netQuantity.toFixed(
                                    2
                                )}
                                kg
                            </div>
                            <div
                                className={
                                    cyl.sellerCylinderDetails
                                        .extractedQuantity !==
                                    cyl.buyerCylinderDetails.extractedQuantity
                                        ? "wrong"
                                        : ""
                                }
                            >
                                {cyl.buyerCylinderDetails.extractedQuantity.toFixed(2)}
                                kg
                            </div>
                            <div
                                className={
                                    cyl.sellerCylinderDetails.gradeName !==
                                    cyl.disputeDetailView.gradeName
                                        ? "wrong"
                                        : ""
                                }
                            >
                                {cyl.disputeDetailView.gradeName}
                            </div>
                        </section>
                    </InfoWrapper>
                    <InfoWrapper>
                        <section>{t("actions:dialogs.dispute.claim")}:</section>
                        <section className="double">
                            {cyl.disputeDetailView.disputeReason}
                        </section>
                    </InfoWrapper>
                    <VSpace size="16px" />
                    <HR />

                    {initialValues[`disputeThisResult_${index}`] && (
                        <>
                            <VSpace size="16px" />
                            <SectionTitle>
                                {t("actions:dialogs.dispute.settlementConsent")}
                                :
                            </SectionTitle>
                            <InfoWrapper>
                                <div className="third">
                                    {t("forms:cylinder.cylinderGas")}:
                                </div>
                                <div className="small">
                                    {cyl.sellerCylinderDetails.gasCode}
                                </div>
                                <Checkbox
                                    className="half"
                                    labelText={t(
                                        "actions:dialogs.dispute.giveConsent"
                                    )}
                                    id={`gasConfirmed_${index}`}
                                    name={`gasConfirmed_${index}`}
                                    {...formik}
                                />
                            </InfoWrapper>
                            <InfoWrapper>
                                <div className="third">
                                    {t("sidebar:label.totalExtractedQuantity")}:
                                </div>
                                <div className="small"                              
                                >
                                    {cyl.disputeDetailView.quantity.toFixed(
                                        2
                                    )}
                                    kg
                                </div>
                                <Checkbox
                                    className="half"
                                    labelText={t(
                                        "actions:dialogs.dispute.giveConsent"
                                    )}
                                    id={`quantityConfirmed_${index}`}
                                    name={`quantityConfirmed_${index}`}
                                    {...formik}
                                />
                            </InfoWrapper>
                            <InfoWrapper>
                                <div className="third">
                                    {t("sidebar:label.extraction.qualityGrade")}
                                    :
                                </div>
                                <div className="small">
                                    {cyl.disputeDetailView.gradeName}
                                </div>
                                <Checkbox
                                    className="half"
                                    labelText={t(
                                        "actions:dialogs.dispute.giveConsent"
                                    )}
                                    id={`gradeConfirmed_${index}`}
                                    name={`gradeConfirmed_${index}`}
                                    {...formik}
                                />
                            </InfoWrapper>
                        </>
                    )}
                </>
            ),
        });
    });

    return {
        title: t("actions:dialogs.dispute.finalize"),
        initialValues: initialValues,
        steps,
    };
};

export default WizardDisputeConfirm;
