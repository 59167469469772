import { config } from "../Config/config.prod";
import api, { apiResult } from "../getAxios";

const ResetPasswordService = {
    sendRequest: ({username, password, token}) => {
        return api.post(config.apiUrl.resetPassword, {username, password, token})
    },
    sendRequestAsync: ({username, password, token}) => {
        return apiResult(api.post(config.apiUrl.resetPassword, {username, password, token}));
    }
};
export default ResetPasswordService;
