import React from "react";
import StyledTable from "./styles/StyledTable";

const LoadingTable = () => {
  return (
    <StyledTable style={{opacity: 0.5}}>
      <thead>
        <tr>
          <th>Loading..</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Loading..</td>
        </tr>
        <tr>
          <td>Loading..</td>
        </tr>
      </tbody>
    </StyledTable>
  );
};

export default LoadingTable;
