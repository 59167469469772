import React from "react";
import { Switch } from "react-router-dom";
import PrivateRoute from "../../core/PrivateRoute";
import companyAdminMenuItems from "../../data/menu/companyAdminMenuItems";
import useLeftMenu from "../../core/state/navigation/useLeftMenu";
import RouteCreator from "../../core/RouteCreator";
import TransactionsDashboard from "./views/TransactionsDashboard";
import DocumentListing from "./views/DocumentListing";
import TransactionsView from "./views/TransactionsView";
import HierarchyView from "./views/HierarchyView";
import useSharedState from "../../hooks/_shared/useSharedState";

const FinanceScreen = () => {
    const [globalState] = useSharedState("global");
    useLeftMenu(companyAdminMenuItems(globalState));
    return (
        <Switch>
            <PrivateRoute
                path={RouteCreator.finance.transactions()}
                component={TransactionsView}
            />
            <PrivateRoute
                path={RouteCreator.finance.hierarchy()}
                component={HierarchyView}
            />
            <PrivateRoute
                path={RouteCreator.finance.documents()}
                component={DocumentListing}
            />
            <PrivateRoute
                path={RouteCreator.finance.root()}
                component={TransactionsDashboard}
            />
            
        </Switch>
    );
};

export default FinanceScreen;
