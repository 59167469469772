import { useCallback } from "react";

const usePageChange = (dispatch, filterState) => {
    const callback = useCallback(({pageIndex, pageSize}) => {
        if(filterState.page !== pageIndex + 1){
             dispatch({type: "SET_PAGING", payload: {pageIndex, pageSize}});
        }
     }, [dispatch, filterState]);

     return callback;
};

export default usePageChange;
