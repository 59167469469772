import React from "react";
import styled from "styled-components";
import ValidationRule, {
    buildValidation,
    skipValidation,
} from "../../../../../core/validations/ValidationRule";

import InputElement from "../../../../../components/elements/form-elements/InputElement";
import { Checkbox } from "../../../../../components/elements/form-elements";
import { VSpace } from "../../../../pwa/components/StyledElements";
import * as yup from "yup";

const validDispute = (t, index) => {
    return yup.lazy((values) => {
        if (!values[`disputeThisResult_${index}`]) return skipValidation();
        return buildValidation({
            [`disputeReason_${index}`]: ValidationRule.isStringRule().required(
                t("forms:dispute.wizard.disputeReason.required")
            ).rule,
        });
    });
};

const InfoWrapper = styled.div`
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;

    & > section {
        width: 32%;
        display: flex;
        flex-direction: column;
        font-size: 12px;
        font-weight: 700;

        & > div.wrong {
            color: #f0c40d !important;
        }
    }

    & > section.bold {
        font-size: 14px;
        font-weight: 700;
    }

    & > section.green {
        color: ${(p) => p.theme.primaryColor};
    }
`;

const getBuyerAnalysisForCylinder = (cyl, buyerAnalysis) => {
    const analysis = buyerAnalysis.filter(
        (analysis) => analysis.skuId === cyl.skuId
    );
    return analysis[0];
};

const WizardDispute = (t, offer) => {
    const initialValues = {};
    const steps = [];
    const cylinders = offer.cylindersAnalysis;

    cylinders.forEach((cyl) => {
        const index = cyl.skuId;
        const buyerAnalysis = getBuyerAnalysisForCylinder(
            cyl,
            offer.buyerCylindersAnalysis
        );

        initialValues[`disputeThisResult_${index}`] = false;
        initialValues[`disputeReason_${index}`] = "";
        initialValues[`analysisId_${index}`] = buyerAnalysis.analysisId;
        initialValues[`offerId_${index}`] = buyerAnalysis.offerId;

        steps.push({
            name: `${t("forms:qualityTest.cylinder")}: ${cyl.tagId}`,
            fieldNames: [
                `disputeThisResult_${index}`,
                `disputeReason_${index}`,
                `analysisId_${index}`,
                `offerId_${index}`,
            ],
            validation: validDispute(t, index),
            fields: (formik) => (
                <>
                    <InfoWrapper>
                        <section> </section>
                        <section className="bold">
                            <div>
                                {t("forms:dispute.wizard.qualityAssessment")}
                            </div>
                            <div>{offer.offerer}</div>
                        </section>
                        <section className="bold">
                            <div>
                                {t("sidebar:title.quality.qualityConfirmation")}
                            </div>
                            <div>{offer.acceptedBidInfo.bidCompanyName}</div>
                        </section>
                    </InfoWrapper>
                    <InfoWrapper>
                        <section>
                            <div>{t("forms:cylinder.cylinderGas")}:</div>
                            <div>{t("screens:technician.netQuantity")}:</div>
                            <div>
                                {t("sidebar:label.totalExtractedQuantity")}:
                            </div>
                            <div>
                                {t("sidebar:label.extraction.qualityGrade")}:
                            </div>
                        </section>
                        <section className="green">
                            <div>{cyl.gasTypeCode}</div>
                            <div>{cyl.maximumQuantity.toFixed(2)} kg</div>
                            <div>{cyl.quantity.toFixed(2)} kg</div>
                            <div>{cyl.gradeName}</div>
                        </section>

                        <section className="green">
                            <div>{cyl.gasTypeCode}</div>
                            <div>{cyl.maximumQuantity.toFixed(2)} kg</div>
                            <div
                                className={
                                    cyl.quantity !== buyerAnalysis?.quantity
                                        ? "wrong"
                                        : ""
                                }
                            >
                                {buyerAnalysis?.quantity.toFixed(2)} kg
                            </div>
                            <div
                                className={
                                    cyl.gradeName !== buyerAnalysis.gradeName
                                        ? "wrong"
                                        : ""
                                }
                            >
                                {buyerAnalysis?.gradeName}
                            </div>
                        </section>
                    </InfoWrapper>
                    <VSpace size="16px" />
                    {(cyl.quantity !== buyerAnalysis.quantity ||
                        cyl.gradeName !== buyerAnalysis.gradeName) && (
                        <Checkbox
                            labelText={t(
                                "forms:dispute.wizard.disputeTestResult"
                            )}
                            id={`disputeThisResult_${index}`}
                            name={`disputeThisResult_${index}`}
                            {...formik}
                        />
                    )}
                    <VSpace size="16px" />
                    {formik.values[`disputeThisResult_${index}`] && (
                        <InputElement
                            labelText={t(
                                "forms:dispute.wizard.disputeReason.text"
                            )}
                            id={`disputeReason_${index}`}
                            name={`disputeReason_${index}`}
                            type="textarea"
                            wrapperVariant="full-width"
                            {...formik}
                        />
                    )}
                </>
            ),
        });
    });

    return {
        title: t("actions:dispute.start"),
        initialValues: initialValues,
        steps,
    };
};

export default WizardDispute;
