import React from "react";
import styled from "styled-components";
import { Pie, PieChart, Cell, Label } from 'recharts';
import ChartHeader from "./ChartHeader";

export const COLORS = ['#2B736B', '#94C21C', '#F0C40D', '#FF8042'];

const ChartWrapper = styled.div`
   padding:24px;
`;

export const FONT_COLOR = '#AAAEB3';

const ChartPieGasQuality = (props) => {
    const { width, height, data, title, style, unit, isInt } = props;

    const total = data? parseFloat(data.reduce((sum, current) => sum + current.value, 0)) : 0;
    const totalFormatted = isInt === true ? total.toString(): total.toFixed(2);
    const label = `${totalFormatted} ${unit? unit: 'kg'}`;
    return (
        <ChartWrapper style={style}>
             <ChartHeader
                title={title}
            >

            </ChartHeader>
          <PieChart width={width} height={height} style={{ margin: "auto", padding: "10px", fontWeight: "bold" }}>
                        <Pie
                            data={data}
                            cx="50%"
                            cy="50%"
                            innerRadius="90%"
                            outerRadius="100%"
                            fill="#8884d8"
                            paddingAngle={1}
                            dataKey="value"
                        >
                            <Label fill="#AAAEB3"
                                value={label} position="center" style={{ fontSize: 20, fontWeight: 600 }} />
                            {
                            data? data.map((item, index) => <Cell key={`cell-${index}`} fill={item.color} />) :null
                            }
                        </Pie>
                    </PieChart>
        </ChartWrapper>

    );
};

export default ChartPieGasQuality;