const ClientsBreadcrumbs = {
    getBreadcrumbs: (t, level = "1", client = "", location = "") => {
        const clients = [
            {
                text: "Clients",
            },
        ];

        const locations = [
            {
                text: "Clients",
            },
            {
                text: client,
            },
            {
                text: "Locations",
            },
        ];

        const units = [
            {
                text: "Clients",
            },
            {
                text: client,
            },
            {
                text: location,
            },
            {
                text: "Units",
            },
        ];

        if (level === "1") {
            return clients;
        } else if (level === "2") {
            return locations;
        } else if (level === "3") {
            return units;
        }
    },
};

export default ClientsBreadcrumbs;
