import styled, { css } from "styled-components";

const FlexColumn = styled.div`
    display:${props => props.display ? `${props.display}` : "block"};
    flex-direction: column;
    ${props => props.flex && css`flex: ${props.flex};`}
    ${props => props.flexGrow && css`flex-grow: ${props.flexGrow};`}
    ${props => props.flexBasis && css`flex-basis: ${props.flexBasis}`}
    ${props => props.justifyContent && css`justify-content: ${props.justifyContent};`}
    ${props => props.alignItems && css`align-items: ${props.alignItems};`}
    box-sizing: border-box;

    &.half {
        flex-basis: 50%;

        @media all and (max-width: ${props => props.theme.extraLargeDevices}) {
            flex-basis: 100%;
        }
    }

    &.full {
        flex-basis: 100%;
    }

    &.wizard-column {
        flex-basis: 45%;
        margin: 0 2%;
    }
`;

export default FlexColumn;
