import React from "react";
import HamburgerMenu from "../../../components/menu/HamburgerMenu";
import HamburgerMenuAction from "../../../components/menu/HamburgerMenuAction";
import AuthorizeComponent from "../../../core/AuthorizeComponent";
import Roles from "../../../core/enums/Roles";
import env from "../../../core/env";
import StarCell from "./StarCell";

const ClientsColumns = {
    getColumns: (t, columns = "clientColumns", actions = {}) => {
        const clientsColumns = [
            {
                Header: t("table:headers.client.name"),
                accessor: "clientName",
            },
            {
                Header: t("table:headers.client.contact.email"),
                accessor: "contactEmail",
            },
            {
                Header: t("table:headers.client.contact.phone"),
                accessor: "contactPhone",
            },
            {
                Header: t("table:headers.client.contact.person"),
                accessor: "contactPerson",
            },
            {
                Header: "",
                accessor: "clientId",
                Cell: ({ cell: { value, row } }) => (
                    <AuthorizeComponent
                        roles={[
                            Roles.InstallerCompanyAdmin,
                            Roles.InstallerCompanyTechnician,
                        ]}
                    >
                        <HamburgerMenu>
                            <HamburgerMenuAction
                                text={t("actions:edit")}
                                dataTest="edit-client"
                                onClick={async () => {
                                    actions.toggleClientEdit(value);
                                }}
                            />
                            <HamburgerMenuAction
                                text={t("actions:delete")}
                                dataTest="delete-client"
                                onClick={async () => {
                                    actions.toggleClientDelete(row, value);
                                }}
                            />
                        </HamburgerMenu>
                    </AuthorizeComponent>
                ),
                disableSortBy: true,
            },
        ];

        const locationColumns = [
            {
                Header: t("table:headers.client.location.name"),
                accessor: "locationName",
            },
            {
                Header: t("table:headers.client.address"),
                accessor: "address",
                Cell: StarCell,
            },
            {
                Header: t("table:headers.client.city"),
                accessor: "city",
            },
            {
                Header: t("table:headers.client.postalCode"),
                accessor: "postalCode",
            },
            {
                Header: t("table:headers.client.region"),
                accessor: "region",
            },
            {
                Header: t("table:headers.client.country"),
                accessor: "country",
            },
            {
                Header: t("table:headers.client.location.units"),
                accessor: "units",
            },
            {
                Header: "",
                accessor: "locationId",
                Cell: ({ cell: { value, row } }) => (
                    <AuthorizeComponent
                        roles={[
                            Roles.InstallerCompanyAdmin,
                            Roles.InstallerCompanyTechnician,
                        ]}
                    >
                        <HamburgerMenu>
                            <HamburgerMenuAction
                                text={t("actions:edit")}
                                onClick={() => {
                                    actions.toggleClientLocationEdit(value);
                                }}
                            />
                            <HamburgerMenuAction
                                text={t("actions:delete")}
                                onClick={() => {
                                    actions.onLocationDelete(row, value);
                                }}
                            />
                            {!env.isProduction && <HamburgerMenuAction
                                text={t("actions:dialogs.createUnit.text")}
                                onClick={() => {
                                    actions.createNewUnit(value);
                                }}
                            />}
                        </HamburgerMenu>
                    </AuthorizeComponent>
                ),
                disableSortBy: true,
            },
        ];

        const unitsColumns = [
            {
                Header: t("table:headers.client.units.manufacturerName"),
                accessor: "manufacturerName",
            },
            {
                Header: t("table:headers.client.units.applicationTypeName"),
                accessor: "applicationTypeName",
            },
            {
                Header: t("table:headers.client.units.refrigerantName"),
                accessor: "refrigerantName",
            },
            {
                Header: t("table:headers.client.units.capacity"),
                accessor: "capacity",
            },
            {
                Header: "",
                accessor: "unitId",
                Cell: ({ cell: { value, row } }) => (
                    <AuthorizeComponent
                        roles={[
                            Roles.InstallerCompanyAdmin,
                            Roles.InstallerCompanyTechnician,
                        ]}
                    >
                        <HamburgerMenu>
                            <HamburgerMenuAction
                                text={t("actions:edit")}
                                onClick={() => {
                                    actions.editUnit(row.original.unitId);
                                }}
                            />
                            <HamburgerMenuAction
                                text={t("actions:delete")}
                                onClick={() => {
                                    actions.onUnitDelete(row, value);
                                }}
                            />
                            {/* <HamburgerMenuAction
                                text={t("actions:reTag")}
                                onClick={() => {
                                    actions.reTagUnit(row.original.unitId);
                                }}
                            /> */}
                        </HamburgerMenu>
                    </AuthorizeComponent>
                ),
                disableSortBy: true,
            },
        ];

        if (columns === "clientColumns") {
            return clientsColumns;
        } else if (columns === "locationColumns") {
            return locationColumns;
        } else if (columns === "unitsColumns") {
            return unitsColumns;
        }
    },
};

export default ClientsColumns;
