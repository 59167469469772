import styled from "styled-components";

export const StyledQR = styled.div`
    width: 100%;
    text-align: center;
    span {
        display: inline-block;
        margin-top: 5px;
        font-size: 10px;
    }

    &.modal {
        margin-top: 20px;
    }
`;
