import api, { apiResult } from "../getAxios";
import { config } from "../Config/config.prod";

const RegistrationService = {
    register: (formData) => {
        return api.post(config.apiUrl.registration, formData);
    },
    registerAsync: (formData) => {
        return apiResult(api.post(config.apiUrl.registration, formData));
    },
    resendConfirmation: async (email) => {
        return (await api.post(config.apiUrl.resendConfirmation, { email: email })).data;
    },
    resendConfirmationAsync: async (email) => {
        return apiResult(api.post(config.apiUrl.resendConfirmation, { email: email }));
    },
    verifyEmail: async (username, token) => {
        return (await api.post(config.apiUrl.confirmRegistration, { username, token })).data;
    },
    verifyEmailAsync: async (username, token) => {
        return apiResult(api.post(config.apiUrl.confirmRegistration, { username, token }));
    },
    setPartnerStatus: async (data) => {
        return apiResult(api.post(config.apiUrl.setPartnerStatus, data));
    },
    getPartnerStatus: (companyId) => {
        return apiResult(api.get(config.apiUrl.getPartnerStatus+"/"+companyId));
    },
};

export default RegistrationService;
