import React from "react";
import {
    CheckMark,
    RadioGroup,
    RadioLabel,
    RadioWrapper,
} from "../../../../../components/elements/form-elements/Radio";
import PaymentService from "../../../../../services/Payment/PaymentService";
import {
    InfoText,
    InfoTitle,
    InfoWrapper,
    RoundInfo,
    Separator,
    KeyText,
    ValueText,
    VSpace,
} from "../../../../../components/modal/components/WizardStyling";
import PaymentType from "../../../../../core/enums/PaymentType";
import Globals from "../../../../../globalVars";
import styled from "styled-components";
import ModalWizard from "../../../../../components/modal/ModalWizard";
import NavigationLink from "../../../../../components/elements/links/NavigationLink";

const SpaceBetween = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 48px;
`;

const InsetDiv = styled.div`
    padding: 0 48px;
    font-size: 14px;
    max-width: 75%;
`;

const ServicePrice = styled.div`
    font-size: 14px;
    font-weight: bold;
`;

const defaultInitialValues = {
    paymentMethod: PaymentType.MainWalletToTransaction,
    serviceType: "B",
};

const WizardPayForCleaning = (
    t,
    currentLanguage,
    currency,
    priceA,
    priceB,
    offerInfo = "",
    offerer = true,
    bidCompanyCountryId,
    maximumQuantity
) => {
    priceA = Number(priceA);
    priceB = Number(priceB);

    let vatPercentage = Globals.VATPercentage;
    let vatPercString = Globals.VATPercString;
    if (
        bidCompanyCountryId !== undefined &&
        Globals.userCountryId !== bidCompanyCountryId
    ) {
        vatPercentage = 1;
        vatPercString = "0";
    }

    const withVatA = (vatPercentage * priceA).toFixed(2);

    const withVatB = (vatPercentage * priceB).toFixed(2);

    const handleServiceTypeChange = (
        ev,
        formik,
        propertyBag,
        updatePropertyBag
    ) => {
        const selectedService = ev.target.value;
        const price =
            selectedService === "A"
                ? priceA * maximumQuantity
                : priceB * maximumQuantity;
        const balanceExceeded =
            propertyBag.mainWalletBalance < vatPercentage * price;
        const remainingBalance =
            propertyBag.mainWalletBalance - price * vatPercentage;

        updatePropertyBag({
            serviceType: selectedService,
            walletBalanceExceeded: balanceExceeded,
            price: price,
            priceWithVAT: vatPercentage * price,
            priceVATonly: (vatPercentage - 1) * price,
            remainingBalance: remainingBalance,
        });
        formik.handleChange(ev);
    };

    return {
        title: t("forms:payForOffer.wizard.payIn"),
        initialValues: defaultInitialValues,
        steps: [
            {
                name: t("forms:payForOffer.wizard.chooseLevel"),
                fieldNames: ["paymentMethod", "serviceType"],
                onStepRender: async (props) => {
                    // get wallet
                    let wallets = await PaymentService.getMyWallets();
                    let walletBalance = 0;
                    if (wallets && wallets.length > 0) {
                        let mainWallet = wallets.filter(
                            (w) => w.tag === "MAIN"
                        );
                        if (mainWallet) {
                            walletBalance = mainWallet[0].amount;
                        }
                    }
                    const price = priceB * maximumQuantity;
                    props.updatePropertyBag({
                        init: true,
                        serviceType: "B",
                        mainWalletBalance: walletBalance,
                        price: price,
                        priceWithVAT: vatPercentage * price,
                        priceVATonly: (vatPercentage - 1) * price,
                        walletBalanceExceeded:
                            walletBalance < vatPercentage * price,
                        remainingBalance: walletBalance - vatPercentage * price,
                    });
                },
                fields: (formik, propertyBag, updatePropertyBag) => {
                    if (!propertyBag.init) return null;
                    return (
                        <>
                            <InfoWrapper className="mv16">
                                <RoundInfo
                                    alt=""
                                    src="/images/menu/green-info.svg"
                                />
                                <div>
                                    <InfoTitle>
                                        {t(
                                            "forms:payForOffer.wizard.newPayment"
                                        )}
                                    </InfoTitle>
                                    <InfoText>
                                        {t(
                                            "forms:payForOffer.wizard.chooseLevelDesc"
                                        )}
                                    </InfoText>
                                </div>
                            </InfoWrapper>
                            <RadioGroup className="flex-1 col">
                                <SpaceBetween>
                                    <RadioWrapper>
                                        <input
                                            type="radio"
                                            name="serviceType"
                                            id="standard"
                                            value="B"
                                            checked={
                                                formik.values["serviceType"] ===
                                                "B"
                                            }
                                            onChange={(ev) =>
                                                handleServiceTypeChange(
                                                    ev,
                                                    formik,
                                                    propertyBag,
                                                    updatePropertyBag
                                                )
                                            }
                                        />
                                        <CheckMark className="secondary checkmark abs" />
                                        <RadioLabel htmlFor="standard">
                                            {t(
                                                "forms:payForOffer.wizard.cleanService"
                                            )}
                                        </RadioLabel>
                                    </RadioWrapper>
                                    <ServicePrice>
                                        {withVatB} EUR/kg
                                    </ServicePrice>
                                </SpaceBetween>
                                <InsetDiv>
                                    {t(
                                        "forms:payForOffer.wizard.cleanServiceDesc"
                                    )}
                                </InsetDiv>
                                <VSpace size="20" />
                                <Separator />
                                <SpaceBetween>
                                    <RadioWrapper>
                                        <input
                                            type="radio"
                                            name="serviceType"
                                            id="better"
                                            value="A"
                                            checked={
                                                formik.values["serviceType"] ===
                                                "A"
                                            }
                                            //  onChange={formik.handleChange}
                                            onChange={(ev) =>
                                                handleServiceTypeChange(
                                                    ev,
                                                    formik,
                                                    propertyBag,
                                                    updatePropertyBag
                                                )
                                            }
                                        />
                                        <CheckMark className="secondary checkmark abs" />
                                        <RadioLabel htmlFor="better">
                                            {t(
                                                "forms:payForOffer.wizard.restoreService"
                                            )}
                                        </RadioLabel>
                                    </RadioWrapper>
                                    <ServicePrice>
                                        {withVatA} EUR/kg
                                    </ServicePrice>
                                </SpaceBetween>
                                <InsetDiv>
                                    {t(
                                        "forms:payForOffer.wizard.restoreServiceDesc"
                                    )}
                                </InsetDiv>
                            </RadioGroup>
                        </>
                    );
                },
            },
            {
                name: t("forms:payForOffer.wizard.cleaningPayIn"),
                fieldNames: [],
                onStepRender: async ({ propertyBag, updatePropertyBag }) => {
                    return propertyBag.walletBalanceExceeded;
                },
                onStepSubmit: ({ propertyBag }) => {
                    return !propertyBag.walletBalanceExceeded;
                },
                fields: (formik, propertyBag) => {
                    return (
                        <>
                            {propertyBag.walletBalanceExceeded ? (
                                <InfoWrapper className="mv16">
                                    <RoundInfo
                                        alt=""
                                        src="/images/menu/green-info.svg"
                                    />
                                    <div>
                                        <InfoTitle>
                                            {t(
                                                "forms:payForOffer.wizard.noFunds"
                                            )}
                                        </InfoTitle>
                                        <InfoText className="justify">
                                            {t(
                                                "forms:payForOffer.wizard.noFundsInfo"
                                            )}
                                        </InfoText>
                                        <NavigationLink
                                            className="secondaty"
                                            style={{ float: "right" }}
                                            to="/finance"
                                            onClick={() => {
                                                ModalWizard.hide();
                                            }}
                                        >
                                            {t(
                                                "forms:payForOffer.wizard.gotoMyWallets"
                                            )}
                                        </NavigationLink>
                                    </div>
                                </InfoWrapper>
                            ) : (
                                <InfoWrapper className="mv16 tight-border">
                                    <RoundInfo
                                        alt=""
                                        src="/images/menu/green-info.svg"
                                    />
                                    <div>
                                        <InfoTitle>
                                            {t(
                                                "forms:payForOffer.wizard.newPayment"
                                            )}
                                        </InfoTitle>
                                        <InfoText>
                                            {t(
                                                "forms:payForOffer.wizard.deductedAmount"
                                            )}
                                        </InfoText>
                                    </div>
                                </InfoWrapper>
                            )}
                            <InfoWrapper className="column mv16 no-border">
                                <div className="content">
                                    <ValueText className="big">
                                        {propertyBag.serviceType === "B"
                                            ? t(
                                                  "forms:payForOffer.wizard.cleanService"
                                              )
                                            : t(
                                                  "forms:payForOffer.wizard.restoreService"
                                              )}
                                    </ValueText>
                                </div>
                                <Separator className="black" />
                                <div className="content keyval">
                                    <ValueText>
                                        {t(
                                            "forms:payForOffer.wizard.mainBallance"
                                        )}
                                    </ValueText>
                                    <ValueText>
                                        {propertyBag.mainWalletBalance?.toFixed(
                                            2
                                        )}{" "}
                                        EUR
                                    </ValueText>
                                </div>
                                <div className="content keyval">
                                    <KeyText className="inset">
                                        {offerInfo}
                                    </KeyText>
                                    <ValueText className="light">
                                        -{propertyBag.price?.toFixed(2)} EUR
                                    </ValueText>
                                </div>
                                <div className="content keyval">
                                    <KeyText className="inset">{`${t(
                                        "filters:company.vat"
                                    )} (${vatPercString}%)`}</KeyText>
                                    <ValueText className="light">
                                        -{propertyBag.priceVATonly?.toFixed(2)}{" "}
                                        EUR
                                    </ValueText>
                                </div>

                                <Separator className="indent" />
                                <div className="content keyval">
                                    <ValueText
                                        className={
                                            propertyBag.walletBalanceExceeded
                                                ? "danger"
                                                : ""
                                        }
                                    >
                                        {t("table:headers.total")}:
                                    </ValueText>
                                    <ValueText
                                        className={
                                            propertyBag.walletBalanceExceeded
                                                ? "danger"
                                                : ""
                                        }
                                    >
                                        -{propertyBag.priceWithVAT?.toFixed(2)}{" "}
                                        EUR
                                    </ValueText>
                                </div>

                                {!propertyBag.walletBalanceExceeded && (
                                    <>
                                        <VSpace size="12" />
                                        <div className="content keyval">
                                            <ValueText className="warning">
                                                {t(
                                                    "forms:payForOffer.wizard.newMainBalance"
                                                )}
                                                :
                                            </ValueText>
                                            <ValueText className="warning">
                                                {propertyBag.remainingBalance?.toFixed(
                                                    2
                                                )}{" "}
                                                EUR
                                            </ValueText>
                                        </div>
                                    </>
                                )}
                            </InfoWrapper>
                        </>
                    );
                },
            },
        ],
    };
};

export default WizardPayForCleaning;
