import React from "react";
import { Switch, Redirect } from "react-router-dom";
import PrivateRoute from "../../core/PrivateRoute";
import companyAdminMenuItems from "../../data/menu/companyAdminMenuItems";
import useLeftMenu from "../../core/state/navigation/useLeftMenu";
import RouteCreator from "../../core/RouteCreator";
import InstallerDisposalListing from "./views/InstallerDisposalListing.js";
import DistributorDisposalBids from "./views/DistributorDisposalBids";
import DistributorDisposalMarket from "./views/DistributorDisposalMarket";
import CleaningMarketplace from "./views/CleaningMarketplace";
import useSharedState from "../../hooks/_shared/useSharedState";
import CleaningOffers from "./views/CleaningOffers";
import CleaningBidsListing from "./views/CleaningBidsListing";


const ServicesScreen = () => {
    const [globalState] = useSharedState("global");
    useLeftMenu(companyAdminMenuItems(globalState));
    
    return (
        <Switch>
            <PrivateRoute
                path={RouteCreator.services.disposal()}
                exact={true}
                component={
                    globalState.isDistributor === undefined
                        ? () => null
                        : globalState.isDistributor
                        ? DistributorDisposalMarket
                        : InstallerDisposalListing
                }
            />
            <PrivateRoute
                path={RouteCreator.services.disposalBids()}
                component={
                    globalState.isDistributor === undefined
                        ? () => null
                        : globalState.isDistributor
                        ? DistributorDisposalBids
                        : null
                }
            />
            <PrivateRoute
                path={RouteCreator.services.cleaning()}
                exact={true}
                component={
                    globalState.isDistributor === undefined
                        ? () => null
                        : globalState.isDistributor
                        ? CleaningMarketplace
                        : CleaningOffers
                }
            />
            <PrivateRoute
                path={RouteCreator.services.cleaningBids()}
                component={
                    globalState.isDistributor === undefined
                        ? () => null
                        : globalState.isDistributor
                        ? CleaningBidsListing
                        : null
                }
            />
            <Redirect to={RouteCreator.services.disposal()} />
        </Switch>
    );
};

export default ServicesScreen;
