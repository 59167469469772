import { useEffect, useRef } from 'react';

const defaultEvents = ['mousedown', 'touchstart'];
const useClickAway = (ref, onClickAway, events = defaultEvents) => {
    const savedCallback = useRef(onClickAway);
    useEffect(() => {
        savedCallback.current = onClickAway;
    }, [onClickAway]);
    useEffect(() => {
        const handler = event => {
            const { current: el } = ref;
            const isModal = document.querySelector("#modal-open");
            const isGallery = document.querySelector("#gallery-open");
            !isModal && !isGallery && el && !el.contains(event.target) && savedCallback.current(event);
        };
        for (const eventName of events) {
            window.document.addEventListener(eventName, handler);
        }
        return () => {
            for (const eventName of events) {
                window.document.removeEventListener(eventName, handler);
            }
        };
    }, [events, ref]);
};
export default useClickAway;
