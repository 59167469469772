import React from "react";
import styled from "styled-components";

const ImagePlaceholder = styled.div`
    width: 84px;
    height: 84px;
    border-radius: 50%;
    margin: 0px 30px;
    box-shadow: 4px 4px 16px 0px rgba(0,0,0,0.16);
    background: center url(${props => props.image}) no-repeat;
    background: ${(props) => (props.image  ? `center url(${props => props.image}) no-repeat` : "#C9C6BA")};
    display:flex;
    align-items:center;
    justify-content:center;

    &.big {
        width:300px;
        height:300px;
    }
`;
const RoundedImage = (props) => {
    const {image, dragging, ...rest} = props;
    return (
        <ImagePlaceholder image={ dragging ? null:image} {...rest} >
        {dragging && <img src="/images/shared/camera.svg" alt="camera" />}
        </ImagePlaceholder>
    )
};

export default RoundedImage;


