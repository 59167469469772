import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ListingPage from "../../../../components/data/ListingPage";
import Anchor from "../../../../components/elements/form-elements/Link";
import Flex from "../../../../components/layout/flex/Flex";
import HamburgerMenu from "../../../../components/menu/HamburgerMenu";
import HamburgerMenuAction from "../../../../components/menu/HamburgerMenuAction";
import ModalWizard from "../../../../components/modal/ModalWizard";
import AuthorizeComponent from "../../../../core/AuthorizeComponent";
import Roles from "../../../../core/enums/Roles";
import adminUsersListStateModule from "../../../../core/state/admin/users/adminUsersListStateModule";
import useBreadcrumbs from "../../../../core/state/navigation/useBreadcrumbs";
import useStateModule from "../../../../core/state/useStateModule";
import useDataReducer from "../../../../hooks/_shared/useDataReducer";
import useImmerReducer from "../../../../hooks/_shared/useImmerReducer";
import usePageChange from "../../../../hooks/_shared/usePageChange";
import usePromises from "../../../../hooks/_shared/usePromises";
import UserService from "../../../../services/User/UserService";
import AdminDetailSideBar from "./components/AdminDetailSideBar";
import actionTypes from "./components/filterActionsTypes";
import RoleCell from "./components/RoleCell";
import UserFilter from "./components/UserFilter";
import CreateAdministratorWizard from "./CreateAdministratorWizard";
import { Helmet } from "react-helmet";
import useSharedState from "../../../../hooks/_shared/useSharedState";
import ActionButton from "../../../../components/elements/buttons/ActionButton";

const filterReducer = (state, action) => {
    if (action.type !== actionTypes.SET_PAGING) state.page = 1;
    switch (action.type) {
        case actionTypes.SEARCH_COMPANYNAME:
            state.filter.companyName = action.payload;
            break;
        case actionTypes.SEARCH_EMAIL:
            state.filter.email = action.payload;
            break;
        case actionTypes.SEARCH_FULLNAME:
            state.filter.fullName = action.payload;
            break;
        case actionTypes.SEARCH_ROLE:
            if (action.payload) {
                state.filter.roleNames = [action.payload];
            } else {
                state.filter.roleNames = [];
            }
            break;
        case actionTypes.SEARCH_STATUS:
            if (action.payload === "-1") {
                delete state.filter.userStatus;
            } else if (action.payload === "0") {
                state.filter.userStatus = true;
            } else {
                state.filter.userStatus = false;
            }
            break;
        case actionTypes.SET_PAGING:
            state.page = action.payload.pageIndex + 1;
            state.pageSize = action.payload.pageSize;
            break;
        case actionTypes.SET_SORT:
            state.orderColumn = action.payload[0].id;
            state.descending = action.payload[0].desc;
            break;
        case actionTypes.SET_COUNTRY:
            state.filter.countryId = action.payload;
            break;
        case actionTypes.RESET_COUNTRY:
            delete state.filter.countryId;
            break;
        default:
            break;
    }
};

const AdministratorListing = () => {
    const [globalState] = useSharedState("global");
    const { t } = useTranslation("screens");
    const {
        adminUsers,
        isLoading,
        error,
        loadAdminUsers,
        loadUserById,
        details,
        deleteUser,
    } = useStateModule(adminUsersListStateModule);

    const adminRoles = usePromises(
        [() => UserService.getAdminRolesAsync()],
        (res) => ({
            adminId: res[0].data.data.find((role) => role.name === Roles.Admin)
                .id,
        })
    );

    const [filterState, dispatch] = useImmerReducer(filterReducer, {
        filter: {
            email: "",
            fullName: "",
            roleNames: [],
        },

        pageSize: 10,
        page: 1,
        orderColumn: "fullName",
        descending: false,
    });

    useBreadcrumbs([
        {
            text: t("navigation:users"),
        },
        {
            text: t("navigation:f-cycleAdmins"),
        },
    ]);

    useEffect(() => {
        loadAdminUsers(filterState);
    }, [loadAdminUsers, filterState]);

    const [detailViewIsOpened, setDetailViewIsOpened] = useState(false);

    const [, setUserEnabled] = useDataReducer(UserService.setUserEnabled);

    const columns = useMemo(
        () => [
            {
                Header: t("administratorListing.fullName"),
                accessor: "fullName",
            },
            {
                Header: t("administratorListing.email"),
                accessor: "email",
            },
            {
                Header: t("administratorListing.roles"),
                accessor: "roleNames",
                Cell: RoleCell,
            },
            {
                Header: t("administratorListing.userStatus"),
                accessor: "userStatus",
            },
            {
                Header: "",
                accessor: "id",
                Cell: ({ cell: { value, row } }) => (
                    <AuthorizeComponent roles={[Roles.MasterAdmin, Roles.Admin]}>
                        <HamburgerMenu>
                            <HamburgerMenuAction
                                text={t("actions:delete")}
                                onClick={async () => {
                                    await deleteUser(value);
                                    loadAdminUsers(filterState);
                                }}
                            />
                            {row.original.enabled &&
                                row.original.roleNames.indexOf(
                                    Roles.MasterAdmin
                                ) < 0 && (
                                    <HamburgerMenuAction
                                        text={t("actions:disable")}
                                        onClick={async () => {
                                            await setUserEnabled({
                                                id: value,
                                                enabled: false,
                                            });
                                            loadAdminUsers(filterState);
                                        }}
                                    />
                                )}
                            {!row.original.enabled && (
                                <HamburgerMenuAction
                                    text={t("actions:enable")}
                                    onClick={async () => {
                                        await setUserEnabled({
                                            id: value,
                                            enabled: true,
                                        });
                                        loadAdminUsers(filterState);
                                    }}
                                />
                            )}
                        </HamburgerMenu>
                    </AuthorizeComponent>
                ),
                disableSortBy: true,
            },
        ],
        // eslint-disable-next-line
        [t]
    );

    const onSelect = useCallback(
        (row) => {
            const selectRow = async (row) => {
                await loadUserById(row.original.id);
                setDetailViewIsOpened(true);
            };
            selectRow(row);
        },
        [loadUserById]
    );

    const closeSideBar = () => {
        setDetailViewIsOpened(false);
    };

    const pageChange = usePageChange(dispatch, filterState);

    const sortChange = (sortBy) => {
        if (sortBy.length > 0) {
            dispatch({ type: "SET_SORT", payload: sortBy });
        }
    };
    const currentSortBy = useMemo(() => {
        return [{ id: filterState.orderColumn, desc: filterState.descending }];
    }, [filterState.orderColumn, filterState.descending]);

    const createNewAdmin = (ev) => {
        ev.preventDefault();
        ModalWizard.show(CreateAdministratorWizard(t), {
            onSubmit: async (values) => {
                values.roleId = adminRoles.adminId;
                const result = await UserService.createAdministratorAsync(
                    values
                );
                if (result && result.error) {
                    ModalWizard.submitFailed(t(result.error.message));
                } else {
                    loadAdminUsers(filterState);
                    toast.success(t("actions:dialogs.createAdmin.success"));
                    ModalWizard.hide();
                }
            },
            onCancel: async () => {
                ModalWizard.hidePrompt();
            },
        });
    };

    return (
        <>
            <Helmet>
                <title>{t("seo:administratorListing.title")}</title>
            </Helmet>
            <ListingPage
                columns={columns}
                tableState={{
                    data: adminUsers.data,
                    loading: isLoading,
                    error: error,
                }}
                actions={{
                    headerActions: () => {
                        return (
                            <AuthorizeComponent
                                roles={[Roles.MasterAdmin, Roles.Admin]}
                            >
                                <ActionButton
                                    className="primary"
                                    onClick={createNewAdmin}
                                >
                                    {t("administratorListing.addAdmin")}
                                </ActionButton>
                            </AuthorizeComponent>
                        );
                    },
                }}
                filterTitle={t("filters:adminAdministratorListing.filterTitle")}
                filterComponent={() => (
                    <UserFilter
                        isAdminList={true}
                        isMasterAdmin={globalState.isMasterAdmin}
                        filterState={filterState}
                        roles={[
                            {
                                key: Roles.Admin,
                                value: Roles.Admin,
                                label: t("common:roles.InstallerCompanyUser"),
                            },
                            {
                                key: Roles.MasterAdmin,
                                value: Roles.MasterAdmin,
                                label: t("common:roles.MasterAdmin"),
                            },
                        ]}
                        dispatch={dispatch}
                    />
                )}
                paging={{
                    enablePaging: true,
                    pageCount: adminUsers.totalPages,
                    pageSize: adminUsers.pageSize,
                    onPageChange: pageChange,
                    totalRows: adminUsers.totalRows,
                    currentPageIndex: adminUsers.currentPage - 1,
                }}
                sorting={{
                    enableSorting: true,
                    onSortByChange: sortChange,
                    currentSortBy: currentSortBy,
                }}
                onSelect={onSelect}
                detailViewIsOpened={detailViewIsOpened}
                detailView={<AdminDetailSideBar adminUser={details} />}
                closeSideBar={closeSideBar}
                variants={{
                    headerViewVariant: "right-action",
                }}
            />
        </>
    );
};

export default AdministratorListing;
