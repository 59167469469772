import React, {useState} from "react";
import styled from "styled-components";
import GasTypeItemsWithGrades from "./components/GasTypeItemsWithGrades";
import MenuColumnContainer from "./components/MenuColumnContainer";
import Switch from "react-switch";
import { useTranslation } from "react-i18next";
import Tooltip from "../../components/tooltip/Tooltip";
import DashboardStyles from "../../components/dashboard/DashboardStyles";

const TitleContainer = styled.div`
    display: inline-block;
    font-size: 20px;
    padding: 24px;
    width:100%;
`;

const Title = styled.div`
    font-size: 20px;
    font-weight: 500;
    display:inline;
    float:left;
`;

const SwitchWrapper = styled.div`
    float: right;
    display: inline-block;
    position: relative;
`;

const GasMenuWithGrades = ({ items, handleClick, selectedItem, isChecked, handleChecked }) => {
    const { t } = useTranslation("screens");
    const [tooltipVisible, setTooltipVisible] = useState(false);
    return (
        <MenuColumnContainer style={{height:"100%", position: "relative"}}>
               <DashboardStyles.NoDataWrapper
                     className = {
                    `${items &&  items.length === 0
                                    ? "show"
                                    : ""
                                    }`
                } >
                < DashboardStyles.SvgNoData /> {
                    t("table:headers.noItems")
                } 
                </DashboardStyles.NoDataWrapper>
            <TitleContainer>
                <Title>
                    {isChecked ? `${t("screens:salesDashboard.labels.sales")}` : `${t("screens:salesDashboard.labels.purchases")}`}
                </Title>
                <SwitchWrapper
                   onMouseEnter={() => setTooltipVisible(true)}
                   onMouseLeave={() => setTooltipVisible(false)}
                >
                <Tooltip position="bottom-left" visible={tooltipVisible} tooltip= {isChecked ? `${t("screens:salesDashboard.labels.showPurchases")}` : `${t("screens:salesDashboard.labels.showSales")}`} />
                <Switch 
                    checked={isChecked} 
                    onChange={handleChecked}                
                    uncheckedIcon={false}
                    checkedIcon={false}
                    height={24}
                    width={40}
                    />
                
                </SwitchWrapper>
               
            </TitleContainer>
            {
                items && (
                    <GasTypeItemsWithGrades
                        items={items}
                        handleClick={handleClick}
                        selectedItem={selectedItem}
                    />
                )
            }

        </MenuColumnContainer>
    )
};

export default GasMenuWithGrades;
