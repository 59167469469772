const MarketplaceStatus = {
    Sold: "Sold",
    Offered: "Offered",
    OnStock : "OnStock",
    Recycling: "Recycling",
    RecyclePending: "RecyclePending"
};


export default MarketplaceStatus;
