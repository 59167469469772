import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Headings from "../../../../components/elements/headings/Headings";
import ModalDialog, {
    useModal,
} from "../../../../components/modal/ModalDialog";
import ModalWizard from "../../../../components/modal/ModalWizard";
import helpers from "../../../../core/helpers";
import CompanyService from "../../../../services/Company/CompanyService";
import LoginService from "../../../../services/Login/LoginService";
import CountryService from "../../../../services/User/CountryService";
import UserService from "../../../../services/User/UserService";
import AccountWizard from "./wizard/AccountWizard";

const SetupAccountScreen = () => {
    const { t, i18n } = useTranslation("screens");
    const userDetails = UserService.getUserDetailsFromToken();
    const companyId = userDetails.CompanyId || null;

    const updateCompanyData = async (values) => {
        const data = {
            phoneNumber: values.phoneNumber,
            iban: values.iban,
            address: values.address,
            postalCode: values.postalCode,
            city: values.city,
            countryId: values.countryId,
            region: values.region,
            legalRepresentativeRegion:values.legalRepresentativeRegion,
            legalRepresentativePostalCode:values.legalRepresentativePostalCode,
            legalRepresentativeCity:values.legalRepresentativeCity,
            legalRepresentativeAddress: values.legalRepresentativeAddress,
            legalRepresentativeFirstName: values.legalRepresentativeFirstName,
            legalRepresentativeLastName: values.legalRepresentativeLastName,
            legalRepresentativeBirthDate: new Date(
                Date.parse(values.legalRepresentativeBirthDate)
            ).toJSON(),
            legalRepresentativeEmail: values.legalRepresentativeEmail,
            legalRepresentativeCountryOfResidenceId:
                values.legalRepresentativeCountryOfResidenceId,
            legalRepresentativeNationalityId:
                values.legalRepresentativeNationalityId,
            companyNumber: values.companyNumber,
        };
        return await CompanyService.setCompanyDataAsync(
            helpers.getFormData(data)
        );
    };

    const updateUserData = async (values) => {
        const data = {
            email: values.userEmail,
            firstName: values.userFirstName,
            lastName: values.userLastName,
            phoneNumber: values.userPhoneNumber,
            twoFactorEnabled: true,
            passwordExpiration: 90,
        };
        return await UserService.setUserDetailsAsync(data);
    };

    const updateUserCert = async (values) => {
        const data = {
            licenceNumber: values.userlicenceNumber,
            licenceExpirationDate: values.userlicenceExpiryDate,
            fGasCertificate: values.userlicenceCertificateFile,
        };
        return await UserService.setUserCertificateAsync(
            helpers.getFormData(data)
        );
    };

    const { modalProps, toggleOpen } = useModal({
        onConfirm: () => {
            toggleOpen();
            LoginService.logout();
            window.location.replace("/?login=1");
        },
        onCancel: () => {
            toggleOpen();
        },
        confirmText: t("actions:dialogs.signOut.confirmText"),
        cancelFirst: true,
    });

    const showSetup = async () => {
        const result = await CompanyService.getCompanyDataAsync(companyId);
        if (result && result.error) {
            toast.error(t(result.error.message));
            return;
        }
        const initialValues = result.data.data[0];
        if (initialValues.licences.length) {
            initialValues.licenceNumber =
                initialValues.licences[0].licenceNumber;
            initialValues.licenceExpiryDate =
                initialValues.licences[0].licenceExpiryDate;
        } else {
            initialValues.licenceNumber = "";
            initialValues.licenceExpiryDate = "";
        }
        delete initialValues.licences;
        initialValues.acceptTerms = false;
        initialValues.acceptData = false;
        initialValues.acceptMangoPay = false;
        initialValues.legalRepresentativeFirstName = "";
        initialValues.legalRepresentativeLastName = "";
        initialValues.legalRepresentativeBirthDate = "";
        initialValues.legalRepresentativeNationality = "";
        initialValues.legalRepresentativeNationalitySelected = "";
        initialValues.legalRepresentativeNationalityId = "";
        initialValues.legalRepresentativeCountryOfResidenceId = "";
        initialValues.legalRepresentativeCountryOfResidence = "";
        initialValues.legalRepresentativeCountryOfResidenceSelected = "";
        initialValues.legalRepresentativeEmail = "";
        initialValues.legalRepresentativeAddress="";
        initialValues.legalRepresentativeCity="";
        initialValues.legalRepresentativePostalCode="";
        initialValues.legalRepresentativeRegion="";
        initialValues.iban = "";
        initialValues.companyNumber = "";
        initialValues.userFirstName = "";
        initialValues.userLastName = "";
        initialValues.userEmail = initialValues.companyUsers[0].email;
        initialValues.userPhoneNumber =
        initialValues.companyUsers[0].phoneNumber;
        initialValues.userlicenceNumber = "";
        initialValues.userlicenceExpiryDate = "";
        initialValues.userlicenceCertificateFile = "";
        initialValues.licenceCheck = false;

        const response = await CountryService.getCountriesAsync();
        if (response && response.error) {
            toast.error(t(response.error.message));
            return;
        }

        Object.keys(initialValues).forEach((key) => {
            if (initialValues[key] === null) initialValues[key] = "";
        });

        const countries = response.data.data;

        ModalWizard.show(
            AccountWizard(t, i18n, initialValues, countries),
            {
                
                onSubmit: async (values) => {

                    const updCompany = await updateCompanyData(values);
                    if (updCompany.error) {
                        ModalWizard.submitFailed(updCompany.error.message);
                        return;
                    }

                    const updUser = await updateUserData(values);
                    if (updUser.error) {
                        ModalWizard.submitFailed(updUser.error.message);
                        return;
                    }

                    if (values.licenceCheck) {
                        const updCert = await updateUserCert(values);
                        if (updCert.error) {
                            ModalWizard.submitFailed(updCert.error.message);
                            return;
                        }
                    }

                    toast.success(
                        t("actions:dialogs.updateAccountInfo.success")
                    );
                    ModalWizard.hide();

                    await LoginService.refresh();
                    window.location.replace("/?login=1");
                },
                onCancel: async () => {
                    ModalWizard.hidePrompt(() => {
                        LoginService.logout();
                        window.location.replace("/");
                    });
                },
            },
            { wide: true, style: { minHeight: "720px" }, logo: true }
        );
    };

    useEffect(() => {
        showSetup();
    }, []);

    return (
        <ModalDialog {...modalProps}>
            <Headings.H3>{t("actions:dialogs.signOut.title")}</Headings.H3>
            <p>{t("actions:dialogs.signOut.text")}</p>
        </ModalDialog>
    );
};

export default SetupAccountScreen;
