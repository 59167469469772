import { useLoadScript } from "@react-google-maps/api";
import QRCode from "qrcode.react";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import LocationMarker from "../../../components/maps/components/LocationMarker";
import DaikinMap from "../../../components/maps/DaikinMap";
import { Box16A9, HR, ListTitle, ScreenHeader, ScreenWrapper, VSpace } from "./StyledElements";
import { StyledBack, StyledFilter, SvgNoImage } from "./StyledSvg";
import { ReactComponent as InfoSvg } from "../../../assets/orange-info.svg";

const NumSection = styled.div`
    padding: 4px 0 4px 0px;
    display: flex;
    &.col {
        flex-direction: column;
    }
`;

const StyledInfo = styled(InfoSvg)`
    width: 40px;
    height: 40px;
    margin-right: 16px;
`;

const StyledQR = styled.div`
    width: 100%;
    text-align: center;
    span {
        display: inline-block;
        margin-top: 5px;
        font-size: 10px;
    }
`;
const TagTitle = styled.div`
    width: 100%;
    font-size: 16px;
    font-weight: 700;
    margin-top: 5px;
`;

const Content = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    flex-grow: 1;
    position: relative;
    padding: 35px 60px 0px;
    display: flex;
    flex-direction: column;
    padding: 10px 24px 0px 24px;
`;

const Section = styled.div`
    padding: 12px 20px;
`;

const KeyVal = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 14px;
    color: #000;
    font-weight: 700;
    margin-bottom: 6px;
    & span {
        text-align: right;
        max-width: 50%;
        font-weight: 500;
        color: ${(p) => p.theme.primaryColor};

        & a {
            color: ${(p) => p.theme.primaryColor};
            font-size: 14px;
        }
    }
`;

const Num = styled.div`
    width: 24px;
    height: 24px;
    line-height: 19px;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    background: #fff;
    color: ${(p) => p.theme.primaryColor};
    border: 3px solid ${(p) => p.theme.primaryColor};
    border-radius: 50%;
    float: left;
`;

const SmallInfo = styled.div`
    float: right;
    font-size: 12px;
    width: 90%;
`;
const splitTag = (x) => [`${x.slice(0, 4)} ${x.slice(4, 8)}`, x.slice(9)];

const formatDateStr = (str) => {
    const fmt = Intl.DateTimeFormat("sk", {
        day: "numeric",
        month: "numeric",
        year: "numeric",
    });
    return fmt.format(new Date(str));
};

const gradeNames = ["A", "B", "C"];

const ExtractionDetails = ({ cylinder, details, extraction, ...restProps }) => {
    const { t } = useTranslation("screens");
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyBQSju7sxgnuD4jrzA2cJLP2dVU3RDwHZY", // ,
        // ...otherOptions
    });
    // const boundsMarkers = useMemo(() => {
    //     let markers = [];
    //     if (!details) return;
    //     const loc = details.locationDetails;

    //     if (loc) {
    //         if (!loc.latitude) {
    //             return [
    //                 new window.google.maps.Marker({
    //                     position: {
    //                         lat: 44.0,
    //                         lng: 16.0,
    //                     },
    //                 }),
    //             ];
    //         }
    //         return new window.google.maps.Marker({
    //             position: {
    //                 lat: loc.latitude,
    //                 lng: loc.longitude,
    //             },
    //         });
    //     }
    //     return markers;
    // }, [details]);

    if (!cylinder || !details) return <ScreenWrapper />;
    const tagParts = cylinder.tagId ? splitTag(cylinder.tagId) : [];
    const tagPartsUnit = details.unitDetails ? splitTag(details.unitDetails.tagId) : [];
    const grade = cylinder.pressumedGradeValue;

    return (
        isLoaded && (
            <ScreenWrapper>
                <ScreenHeader>
                    <StyledBack
                        onClick={(ev) => {
                            restProps.onGoBack(ev);
                        }}
                    />
                    <StyledFilter className="disabled" />
                </ScreenHeader>
                <HR />
                <ListTitle>{t("sidebar:title.extraction.extractionCylinder")}</ListTitle>
                <Content>
                    <StyledQR>
                        <QRCode value={cylinder.tagId} size={106} />
                        <TagTitle>{tagParts[0]}</TagTitle>
                        <span>{tagParts[1]}</span>
                    </StyledQR>
                    <Section>
                        <VSpace size="20px" />
                        <KeyVal>
                            {t("screens:technician.netQuantity")}
                            <span>{details.cylinderSize.toFixed(2)} kg</span>
                        </KeyVal>
                    </Section>
                    <HR className="content" />
                    <ListTitle className="content">
                        {t("screens:technician.extractionDetails")}
                    </ListTitle>
                    <Section>
                        <KeyVal>
                            {t("screens:technician.type")}
                            <span>{cylinder.refrigerantGasTypeCode}</span>
                        </KeyVal>
                        <KeyVal>
                            {t("screens:technician.extractionQuantity")}
                            <span>{details.extractedQuantity.toFixed(2)} kg</span>
                        </KeyVal>
                        <KeyVal>
                            {t("screens:technician.qualityEvaluation")}
                            <span>
                                {!extraction.hasAnalysis
                                    ? t("filters:analysis.selfAssessment")
                                    : extraction.isAnalysisReceived
                                    ? t("filters:analysis.measurement")
                                    : t("filters:analysis.pendingMeasurement")}
                            </span>
                        </KeyVal>
                    </Section>
                    <HR className="content" />
                    <ListTitle className="content">
                        {t(
                            `sidebar:title.contaminated.${
                                extraction.hasAnalysis ? "measured" : "selfAssessed"
                            }`
                        )}
                    </ListTitle>
                    {extraction.hasAnalysis & !extraction.isAnalysisReceived ? (
                        <Section style={{ display: "flex" }}>
                            <StyledInfo />
                            <span style={{ fontSize: "14px" }}>
                                {t("sidebar:label.waitingForResult")}
                            </span>
                        </Section>
                    ) : extraction.isAnalysisReceived ? (
                        <Section>
                            <KeyVal>
                                {t("sidebar:label.analysisDate")}
                                <span>{formatDateStr(extraction.dataCollectionDateTime)}</span>
                            </KeyVal>

                            <KeyVal>
                                {t("sidebar:label.analysisResults")}
                                <span>
                                    <a
                                        href={`${extraction.dataFile}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {t("actions:download")}
                                    </a>
                                </span>
                            </KeyVal>

                            <KeyVal>
                                {t("sidebar:label.gasQuantity")}
                                <span>{extraction.extractedQuantity + " kg"}</span>
                            </KeyVal>
                            <KeyVal>
                                {t("sidebar:label.gasGrade")}
                                <span>{gradeNames[extraction.grade]}</span>
                            </KeyVal>
                        </Section>
                    ) : (
                        <>
                            <Section>
                                <Num>1</Num>
                                <SmallInfo>{t("screens:stock.aGradeText")}</SmallInfo>
                            </Section>
                            {grade > 0 && (
                                <Section>
                                    <Num>2</Num>
                                    <SmallInfo>{t("screens:stock.bGradeText")}</SmallInfo>
                                </Section>
                            )}
                            {grade > 1 && (
                                <Section>
                                    <Num>3</Num>
                                    <SmallInfo>{t("screens:stock.cGradeText")}</SmallInfo>
                                </Section>
                            )}
                        </>
                    )}
                    <HR className="content" />
                    <ListTitle className="content">{t("screens:technician.unit")}</ListTitle>
                    <StyledQR>
                        <QRCode value={details.unitDetails.tagId} size={106} />
                        <TagTitle>{tagPartsUnit[0]}</TagTitle>
                        <span>{tagPartsUnit[1]}</span>
                    </StyledQR>
                    <Section>
                        <KeyVal>
                            {t("screens:technician.manufacturer")}
                            <span>{details.unitDetails.manufacturerName}</span>
                        </KeyVal>
                        <KeyVal>
                            {t("screens:technician.application")}
                            <span>{details.unitDetails.applicationTypeName}</span>
                        </KeyVal>
                        <KeyVal>
                            {t("screens:technician.installedGas")}
                            <span>{details.unitDetails.refrigerantTypeCode}</span>
                        </KeyVal>
                        <KeyVal>
                            {t("screens:technician.capacity")}
                            <span>{details.unitDetails.capacity} kW</span>
                        </KeyVal>
                        <HR className="content" />
                        <ListTitle className="content">{t("screens:technician.gallery")}</ListTitle>
                        <VSpace size="20px" />
                        <Box16A9>
                            {details.unitDetails.images.length ? (
                                <img
                                    className="thumb"
                                    alt="unit"
                                    src={details.unitDetails.images[0].imageThumbnailUrl}
                                />
                            ) : (
                                <SvgNoImage />
                            )}
                        </Box16A9>
                        <VSpace size="20px" />
                    </Section>
                    <HR className="content" />
                    <ListTitle className="content">
                        {t("screens:technician.installationSite")}
                    </ListTitle>
                    <Section>
                        <KeyVal>
                            {t("screens:technician.company")}
                            <span>{details.clientName}</span>
                        </KeyVal>
                        <KeyVal>
                            {t("screens:technician.street")}
                            <span>{details.locationDetails.street}</span>
                        </KeyVal>
                        <KeyVal>
                            {t("screens:technician.city")}
                            <span>{details.locationDetails.city}</span>
                        </KeyVal>
                        <KeyVal>
                            {t("screens:technician.zip")}
                            <span>{details.locationDetails.postalCode}</span>
                        </KeyVal>
                        <KeyVal>
                            {t("screens:technician.country")}
                            <span>{details.locationDetails.countryName}</span>
                        </KeyVal>

                        <DaikinMap className="pwa mt-20">
                            <LocationMarker location={details.locationDetails} withBounds />
                        </DaikinMap>
                    </Section>
                    <HR className="content" />
                </Content>
            </ScreenWrapper>
        )
    );
};

export default ExtractionDetails;
