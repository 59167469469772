import React from "react";
import { useTranslation } from "react-i18next";
import Flex from "../../../../components/layout/flex/Flex";
import ListingWrapper from "../../../../components/wrappers/ListingWrapper";
import useDataReducerImmediate from "../../../../hooks/_shared/useDataReducerImmediate";
import StatisticsService from "../../../../services/Statistics/StatisticsService";

import {Helmet} from "react-helmet";

const MarketplaceStatisticsDashboard = () => {
    const [marketplaceCountsDataState] = useDataReducerImmediate(
        StatisticsService.getMarketplaceCounts
    );
    const {t} = useTranslation();

    return (
        <ListingWrapper>
            <Helmet>
                <title>{t("seo:createAdministrator.title")}</title>
            </Helmet>
            {marketplaceCountsDataState.isCompleted && (
                <Flex.Container>
                    <Flex.Column style={{ padding: "10px" }}>
                        <strong>
                            <em>Extracted gas</em>
                        </strong>
                        <ul>
                            {Object.keys(
                                marketplaceCountsDataState.data
                                    .extractedGasPerType
                            ).map((t) => {
                                return (
                                    <li key={t}>
                                        <strong>{t}:</strong>{" "}
                                        {
                                            marketplaceCountsDataState.data
                                                .extractedGasPerType[t]
                                        }{" "}
                                        kg
                                    </li>
                                );
                            })}
                        </ul>
                    </Flex.Column>
                    <Flex.Column style={{ padding: "10px" }}>
                        <strong>
                            <em>Published gas</em>
                        </strong>
                        <ul>
                            {Object.keys(
                                marketplaceCountsDataState.data
                                    .publishedGasPerType
                            ).map((t) => {
                                return (
                                    <li key={t}>
                                        <strong>{t}:</strong>{" "}
                                        {
                                            marketplaceCountsDataState.data
                                                .publishedGasPerType[t]
                                        }{" "}
                                        kg
                                    </li>
                                );
                            })}
                        </ul>
                        <br></br>
                    </Flex.Column>
                    <Flex.Column style={{ padding: "10px" }}>
                        <strong>
                            <em>Average accepted A grade price</em>
                        </strong>
                        <ul>
                            {Object.keys(
                                marketplaceCountsDataState.data
                                    .averageAcceptedGradeAPricePerGasType
                            ).map((t) => {
                                return (
                                    <li key={t}>
                                        <strong>{t}:</strong>{" "}
                                        {
                                            marketplaceCountsDataState.data
                                                .averageAcceptedGradeAPricePerGasType[
                                                t
                                            ]
                                        }
                                    </li>
                                );
                            })}
                        </ul>
                        <br></br>
                    </Flex.Column>
                    <Flex.Column style={{ padding: "10px" }}>
                        <strong>
                            <em>Average accepted B grade price</em>
                        </strong>
                        <ul>
                            {Object.keys(
                                marketplaceCountsDataState.data
                                    .averageAcceptedGradeBPricePerGasType
                            ).map((t) => {
                                return (
                                    <li key={t}>
                                        <strong>{t}:</strong>{" "}
                                        {
                                            marketplaceCountsDataState.data
                                                .averageAcceptedGradeBPricePerGasType[
                                                t
                                            ]
                                        }
                                    </li>
                                );
                            })}
                        </ul>
                        <br></br>
                    </Flex.Column>
                    <Flex.Column style={{ padding: "10px" }}>
                        <strong>
                            <em>Average accepted C grade price</em>
                        </strong>
                        <ul>
                            {Object.keys(
                                marketplaceCountsDataState.data
                                    .averageAcceptedGradeCPricePerGasType
                            ).map((t) => {
                                return (
                                    <li key={t}>
                                        <strong>{t}:</strong>{" "}
                                        {
                                            marketplaceCountsDataState.data
                                                .averageAcceptedGradeCPricePerGasType[
                                                t
                                            ]
                                        }
                                    </li>
                                );
                            })}
                        </ul>
                        <br></br>
                    </Flex.Column>
                </Flex.Container>
            )}
        </ListingWrapper>
    );
};

export default MarketplaceStatisticsDashboard;
