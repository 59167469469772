import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { getDateFormat, getTimeFormat } from "./DateCell";

const Error = styled.span`
    color: ${props => props.theme.errorColor};
`;

const DateCellWithExpiration = ({cell: {value}}, translation = {}, onHold) => {   
    const {t} = useTranslation("common");
    if(onHold) return <span>{onHold}</span>
    if(!value) return <span />;
    const currentLanguage =  'hr';
    const dateValue = new Date(value);
    let title = `${getDateFormat(currentLanguage).format(dateValue)} ${t(
        "dateTime.at"
    )} ${getTimeFormat(currentLanguage).format(dateValue)}`;
    let hoverTitle = title;

    if (Object.keys(translation).length > 0) {
        hoverTitle = t(translation, {date: title})
    }

    if (dateValue > new Date()) {
        return <span title={hoverTitle}>{title}</span>;
    }
    return (
        <Error title={`${t('dateTime.expired')} ${title}`}>
            {t('expired')}
        </Error>
    )
};

const CustomDateCellWithExpiration = (value, translation = {}) => {   
    const {t} = useTranslation("common");
    if(!value) return <span />;
    const currentLanguage = 'hr';
    const dateValue = new Date(value);
    let title = `${getDateFormat(currentLanguage).format(dateValue)} ${t(
        "dateTime.at"
    )} ${getTimeFormat(currentLanguage).format(dateValue)}`;
    let hoverTitle = title;

    if (Object.keys(translation).length > 0) {
        hoverTitle = t(translation, {date: title})
    }

    if (dateValue > new Date()) {
        return <span title={hoverTitle}>{title}</span>;
    }
    return (
        <Error title={`${t('dateTime.expired')} ${title}`}>
            {t('expired')}
        </Error>
    )
};

export default DateCellWithExpiration;
export {CustomDateCellWithExpiration};
