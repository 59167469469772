import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import ModalWizard from "../../../components/modal/ModalWizard";
import RouteCreator from "../../../core/RouteCreator";
import StockService from "../../../services/Stock/StockService";
import TagsService from "../../../services/Tags/TagsService";
import RecycleWizard from "./components/RecycleWizard";

const ScreeWrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    display: flex;
    flex-wrap: nowrap;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
`;

const RecycleCylinder = () => {
    const { t } = useTranslation("screens");
    const history = useHistory();

    const queryCylinderTags = async (tag) => {
        if (tag.length > 2) {
            const tags = await TagsService.lookupCompanyStockTag(tag, 0);
            return tags.map((t) => t.id);
        }
        return [];
    };

    useEffect(() => {
        ModalWizard.show(
            RecycleWizard(t, queryCylinderTags),
            {
                onSubmit: async (values) => {
                    const result = await StockService.sendToRecycling(values.cylinderTagId);
                    if (result && result.error)
                        ModalWizard.submitFailed(t(result.error.message));
                    else {
                        toast.success(
                            t("actions:dialogs:recycle.success")
                        );
                        ModalWizard.hide();
                        setTimeout(() => {
                            history.push(RouteCreator.pwa.stock());
                        }, 100);
                    }
                },
                onCancel: async () => {
                    ModalWizard.hidePrompt(()=>history.goBack());
                },
            },
            { pwa: true }
        );
    }, []);

    return <ScreeWrapper></ScreeWrapper>;
};

export default RecycleCylinder;
