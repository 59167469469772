import React from "react";
import styled from "styled-components";
import { Radio } from "../form-elements";

const TableWrapper = styled.table`
    width: 100%;
    border: none;
    border-collapse: collapse;
    & th {
        font-weight: 600;
        padding-bottom: 20px;
    }

    & td {
        padding: 12px 0px;
    }
    & tr.selected {
        color: ${(p) => p.theme.primaryColor};
    }

    & tr:not(:last-child) td {
        border-bottom: 1px solid #ddd;
    }

    &.desktop {
        font-size: 0.875rem;
    }

    &.desktop-table {
        font-size: 0.875rem;
        font-weight: 500;
        th {
            text-transform: uppercase;
            text-align: left;
        }
    }

    &.modal {
        font-size: 0.875rem;
        font-weight: 500;

        th {
            text-transform: uppercase;
        }
    }
`;

const SimpleTable = ({
    columns = [],
    data = [],
    selected = [],
    wrapperVariant = "",
    shouldScrollInfoView = false,
    radioSelect = false,
}) => {
    const scrollIntoView = (elemId) => {
        if (elemId && document.getElementById(elemId)) {
            document.getElementById(elemId).scrollIntoView({
                behavior: "smooth",
                block: "nearest",
                inline: "start",
            });
        }
    };

    return (
        <TableWrapper className={wrapperVariant}>
            <thead>
                <tr>
                    {columns.map((col, idx) => (
                        <th key={`th-${idx}`}>{col.header}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {data.map((item, idx) => {
                    if (shouldScrollInfoView && selected.includes(idx)) {
                        scrollIntoView(`tr-${idx + 1}`);
                    }

                    return (
                        <tr
                            key={`tr-${idx}`}
                            className={
                                selected.includes(idx) || idx < selected[0]
                                    ? "selected"
                                    : ""
                            }
                            id={`tr-${idx}`}
                        >
                            {columns.map((col, colIdx) => {
                                const value = col.cell
                                    ? col.cell(item[col.accessor], item)
                                    : item[col.accessor];
                                return <td key={`td-${colIdx}`}>{value}</td>;
                            })}
                            {radioSelect && (
                                <td>
                                    <Radio
                                        containerProps={{
                                            style: { marginBottom: "0" },
                                        }}
                                        checked={selected.includes(idx)}
                                    />
                                </td>
                            )}
                        </tr>
                    );
                })}
            </tbody>
        </TableWrapper>
    );
};

export default SimpleTable;
