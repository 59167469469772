import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Button from "../../../../components/elements/buttons/Button";
import SidebarField from "../../../../components/elements/sidebar/SidebarField";
import {
    Num,
    NumInfo,
    NumSection,
} from "../../../../components/elements/sidebar/SidebarStyle";
import AccordionWrapper from "../../../../components/toggle-view/AccordionWrapper";
import helpers from "../../../../core/helpers";
import { VSpace } from "../../../pwa/components/StyledElements";
import {
    BidGradeWrapper,
    BidGrade,
    BigHandlingCosts,
} from "../../../../components/bids/BidGrade";
import Slider from "../../../../components/elements/general/Slider";
import { getApiUrl } from "../../../../services/getAxios";
import useAccordionGroup from "../../../../hooks/_shared/useAccordionGroup";

const DownloadWrapper = styled.div`
    display: flex;
    justify-content: space-between;

    &.pad {
        padding: 25px 20px 0px;
    }

    &.big > * {
        font-size: 14px !important;
    }

    & > div {
        font-size: 12px;
        color: #333333;
        font-weight: 600;
    }
`;

const DownloadLink = styled.a`
    color: #2294d2;
    text-decoration: none;
    font-size: 12px;
    &.big {
        font-size: 14px;
    }
`;
const RejectReason = styled.p`
    padding: 8px 0px 8px 20px;
    color: #000;
    text-align: justify;
`;

const splitTag = (tag) => {
    if (!tag || tag.length < 8) return tag;
    return tag.substring(0, 4) + " " + tag.substring(4, 8);
};

const getFileName = (url) => {
    const parts = url.split("/");
    return parts[parts.length - 1];
};

const MyBidDetailSideBar = ({
    bid,
    bidDetails,
    isLoading,
    offer,
    toggleOpenRevoke,
    isCleaning,
    isDisposal,
    openAccordionId = null,
}) => {
    const { t } = useTranslation("sidebar");
    const [openAccordion, setOpenAccordion] =
        useAccordionGroup(openAccordionId);
    const shouldShow = bidDetails && !isLoading;

    let maximumQuantity = 0;
    offer &&
        offer.cylindersAnalysis.map(
            (c) => (maximumQuantity += c.maximumQuantity)
        );

    const numQualityTests = () => {
        if (!bidDetails.skUs) return 0;
        let cnt = 0;
        bidDetails.skUs.forEach((offer) => {
            if (offer.qualityTestDone) cnt++;
        });
        return cnt;
    };

    return (
        <>
            {shouldShow && (
                <>
                    <AccordionWrapper
                        title={t("title.offer.details")}
                        id="details"
                        isOpen={openAccordion}
                        onToggle={setOpenAccordion}
                    >
                        {offer && (
                            <>
                                <SidebarField
                                    label={t("label.type")}
                                    value={offer.refrigerantGasTypeCode}
                                />
                                <SidebarField
                                    label={t("label.numberOfCylinders")}
                                    value={offer.numberOfCylinders}
                                />
                                <SidebarField
                                    label={t("label.netWeight")}
                                    value={maximumQuantity.toFixed(2) + " kg"}
                                />
                                <SidebarField
                                    label={t("label.totalExtractedQuantity")}
                                    value={offer.quantitySum.toFixed(2) + " kg"}
                                />
                                <SidebarField
                                    label={t("label.extraction.qualityGrade")}
                                    value={offer.pressumedGradeName}
                                />
                                <SidebarField
                                    label={t(
                                        "label.extraction.qualityEvaluation"
                                    )}
                                    value={
                                        offer.hasAnalysis
                                            ? t("filters:analysis.measurement")
                                            : t(
                                                  "filters:analysis.selfAssessment"
                                              )
                                    }
                                />
                            </>
                        )}
                    </AccordionWrapper>

                    {bidDetails.skUs && (
                        <AccordionWrapper
                            title={t("title.contamination")}
                            id="contamination"
                            isOpen={openAccordion}
                            onToggle={setOpenAccordion}
                        >
                            <Slider
                                style={{ padding: "25px 20px 0px" }}
                                title={t(
                                    "sidebar:title.extraction.extractionCylinder"
                                )}
                                captions={bidDetails.skUs.map((sku) =>
                                    splitTag(sku.tagId)
                                )}
                            >
                                {bidDetails.skUs.map((sku, idx) => {
                                    let cnt = 0;
                                    return (
                                        <div id={`slide-${idx + 1}`} key={idx}>
                                            <div>
                                                <SidebarField
                                                    labelvariant="pl-0"
                                                    wrapperVariant="mt-0"
                                                    label={t(
                                                        "label.extractedQuantity"
                                                    )}
                                                    value={
                                                        sku.quantity.toFixed(
                                                            2
                                                        ) + " kg"
                                                    }
                                                />
                                                {!sku.saIsContaminated &&
                                                    !sku.saIsMixture &&
                                                    !sku.saHasR22 && (
                                                        <NumSection>
                                                            <Num className="tight">
                                                                N/A
                                                            </Num>
                                                            <NumInfo>
                                                                {t(
                                                                    "actions:noContamination"
                                                                )}
                                                            </NumInfo>
                                                        </NumSection>
                                                    )}

                                                {sku.saIsContaminated && (
                                                    <NumSection>
                                                        <Num>{++cnt}</Num>
                                                        <NumInfo>
                                                            {t(
                                                                "screens:stock.aGradeText"
                                                            )}
                                                        </NumInfo>
                                                    </NumSection>
                                                )}
                                                {sku.saIsMixture && (
                                                    <NumSection>
                                                        <Num>{++cnt}</Num>
                                                        <NumInfo>
                                                            {t(
                                                                "screens:stock.bGradeText"
                                                            )}
                                                        </NumInfo>
                                                    </NumSection>
                                                )}
                                                {sku.saHasR22 && (
                                                    <NumSection>
                                                        <Num>{++cnt}</Num>
                                                        <NumInfo>
                                                            {t(
                                                                "screens:stock.cGradeText"
                                                            )}
                                                        </NumInfo>
                                                    </NumSection>
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                            </Slider>
                        </AccordionWrapper>
                    )}

                    <AccordionWrapper
                        title={t("sidebar:title.bid.details")}
                        id="bid.details"
                        isOpen={openAccordion}
                        onToggle={setOpenAccordion}
                    >
                        {offer && (
                            <>
                                <SidebarField
                                    label={t("label.bid.date")}
                                    value={helpers.toLocalDateIgnoreTime(
                                        offer.bidDateTime
                                    )}
                                />
                                <VSpace size="10px" />
                                {isCleaning ? (
                                    <>
                                        <BidGradeWrapper>
                                            <BidGrade
                                                title={t(
                                                    "forms:bid.wizard.betterCleaning"
                                                )}
                                                grade="a"
                                                amount={
                                                    offer.offeredPriceGradeA
                                                }
                                                quantity={offer.maxQuantitySum}
                                                bidCompanyCountryId={
                                                    offer.offererCountryId
                                                }
                                            />
                                            <BidGrade
                                                title={t(
                                                    "forms:bid.wizard.standardCleaning"
                                                )}
                                                grade="b"
                                                amount={
                                                    offer.offeredPriceGradeB
                                                }
                                                quantity={offer.maxQuantitySum}
                                                bidCompanyCountryId={
                                                    offer.offererCountryId
                                                }
                                            />
                                        </BidGradeWrapper>
                                        {bid && (
                                            <BigHandlingCosts
                                                isBuyer={true}
                                                price={offer.offeredPriceGradeC}
                                                quantity={offer.maxQuantitySum}
                                                administrativeCosts={
                                                    bid.administrationCosts
                                                }
                                                transportationCosts={
                                                    bid.transportationCosts
                                                }
                                                bidCompanyCountryId={
                                                    bid.bidCompanyCountryId
                                                }
                                                title={t(
                                                    "table:headers.handlingCosts"
                                                )}
                                            />
                                        )}
                                    </>
                                ) : isDisposal ? (
                                    <>
                                        <BidGradeWrapper>
                                            <BidGrade
                                                grade="c"
                                                amount={
                                                    offer.offeredPriceGradeC
                                                }
                                                quantity={offer.maxQuantitySum}
                                                bidCompanyCountryId={
                                                    offer.offererCountryId
                                                }
                                            />
                                        </BidGradeWrapper>
                                        {bid && (
                                            <BigHandlingCosts
                                                isBuyer={true}
                                                price={offer.offeredPriceGradeC}
                                                quantity={offer.maxQuantitySum}
                                                administrativeCosts={
                                                    bid.administrationCosts
                                                }
                                                transportationCosts={
                                                    bid.transportationCosts
                                                }
                                                bidCompanyCountryId={
                                                    bid.bidCompanyCountryId
                                                }
                                                title={t(
                                                    "table:headers.handlingCosts"
                                                )}
                                            />
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <BidGradeWrapper>
                                            <BidGrade
                                                grade="a"
                                                amount={
                                                    offer.offeredPriceGradeA
                                                }
                                                quantity={offer.maxQuantitySum}
                                                bidCompanyCountryId={
                                                    offer.offererCountryId
                                                }
                                            />
                                            <BidGrade
                                                grade="b"
                                                amount={
                                                    offer.offeredPriceGradeB
                                                }
                                                quantity={offer.maxQuantitySum}
                                                bidCompanyCountryId={
                                                    offer.offererCountryId
                                                }
                                            />
                                            <BidGrade
                                                grade="c"
                                                amount={
                                                    offer.offeredPriceGradeC
                                                }
                                                quantity={offer.maxQuantitySum}
                                                bidCompanyCountryId={
                                                    offer.offererCountryId
                                                }
                                            />
                                        </BidGradeWrapper>
                                        {bid && (
                                            <BigHandlingCosts
                                                isBuyer={true}
                                                price={offer.offeredPriceGradeC}
                                                quantity={offer.maxQuantitySum}
                                                administrativeCosts={
                                                    bid.administrationCosts
                                                }
                                                transportationCosts={
                                                    bid.transportationCosts
                                                }
                                                bidCompanyCountryId={
                                                    bid.bidCompanyCountryId
                                                }
                                                title={t(
                                                    "table:headers.handlingCosts"
                                                )}
                                            />
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </AccordionWrapper>

                    {bidDetails.skUs && (
                        <AccordionWrapper
                            title={t("title.quality.qualityConfirmation")}
                            id="qualityConfirmation"
                            isOpen={openAccordion}
                            onToggle={setOpenAccordion}
                        >
                            {numQualityTests() > 0 && (
                                <DownloadWrapper className="pad big">
                                    <div>
                                        {numQualityTests()}{" "}
                                        {numQualityTests() > 1
                                            ? t("actions:availableTests")
                                            : t("actions:availableTest")}
                                    </div>
                                    <DownloadLink
                                        href={`${getApiUrl}/Marketplace/getAnalysesZip/${offer?.offerId}`}
                                        target="_blank"
                                    >
                                        {t("actions:downloadAll")}
                                    </DownloadLink>
                                </DownloadWrapper>
                            )}
                            <Slider
                                style={{ padding: "25px 20px 0px" }}
                                prefix="qslide-"
                                title={t(
                                    "sidebar:title.extraction.extractionCylinder"
                                )}
                                captions={bidDetails.skUs.map((sku) =>
                                    splitTag(sku.tagId)
                                )}
                            >
                                {bidDetails.skUs.map((sku, idx) => {
                                    return (
                                        <div id={`qslide-${idx + 1}`} key={idx}>
                                            <DownloadWrapper
                                                id={`slide-${idx + 1}`}
                                                key={idx}
                                            >
                                                <div>
                                                    {getFileName(
                                                        sku.qualityTestUrl
                                                    )}
                                                </div>
                                                {sku.qualityTestDone ? (
                                                    <DownloadLink
                                                        href={
                                                            sku.qualityTestUrl
                                                        }
                                                        target="_blank"
                                                    >
                                                        {t("actions:download")}
                                                    </DownloadLink>
                                                ) : (
                                                    <div>-</div>
                                                )}
                                            </DownloadWrapper>
                                            {sku.buyerCylindersAnalysis && (
                                                <div>
                                                    <SidebarField
                                                        labelvariant="pl-0"
                                                        valueVariant={
                                                            sku
                                                                .buyerCylindersAnalysis
                                                                .gasTypeCode !==
                                                            offer?.refrigerantGasTypeCode
                                                                ? "wrong"
                                                                : ""
                                                        }
                                                        label={t(
                                                            "label.analysisGasType"
                                                        )}
                                                        value={
                                                            sku
                                                                .buyerCylindersAnalysis
                                                                .gasTypeCode
                                                        }
                                                    />

                                                    <SidebarField
                                                        labelvariant="pl-0"
                                                        label={t(
                                                            "label.analysisQuantity"
                                                        )}
                                                        valueVariant={
                                                            sku
                                                                .buyerCylindersAnalysis
                                                                .quantity !==
                                                            sku.quantity
                                                                ? "wrong"
                                                                : ""
                                                        }
                                                        value={
                                                            sku.buyerCylindersAnalysis.quantity.toFixed(
                                                                2
                                                            ) + " kg"
                                                        }
                                                    />
                                                    <SidebarField
                                                        labelvariant="pl-0"
                                                        label={t(
                                                            "label.analysisQualityGrade"
                                                        )}
                                                        valueVariant={
                                                            sku
                                                                .buyerCylindersAnalysis
                                                                .gradeName !==
                                                            offer.pressumedGradeName
                                                                ? "wrong"
                                                                : ""
                                                        }
                                                        value={
                                                            sku
                                                                .buyerCylindersAnalysis
                                                                .gradeName
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                            </Slider>
                        </AccordionWrapper>
                    )}

                    <AccordionWrapper
                        title={
                            isCleaning || isDisposal
                                ? t("filters:invoices.buyer")
                                : t("title.sellerInfo")
                        }
                        id="sellerInfo"
                        isOpen={openAccordion}
                        onToggle={setOpenAccordion}
                    >
                        <SidebarField
                            label={t("title.company")}
                            value={bidDetails.companyName}
                        />
                        <SidebarField
                            label={t("label.companyVAT")}
                            value={bidDetails.vatNumber}
                        />
                        <SidebarField
                            label={t("label.companyStreet")}
                            value={bidDetails.street}
                        />
                        <SidebarField
                            label={t("label.companyCity")}
                            value={bidDetails.city}
                        />
                        <SidebarField
                            label={t("label.companyState")}
                            value={bidDetails.region}
                        />
                        <SidebarField
                            label={t("label.companyZIP")}
                            value={bidDetails.postalCode}
                        />
                        <SidebarField
                            label={t("label.companyCountry")}
                            value={bidDetails.countryName}
                        />
                        <SidebarField
                            label={t("label.companyContactPersonName")}
                            value={bidDetails.contactPerson}
                        />
                        <SidebarField
                            label={t("label.companyEmail")}
                            value={bidDetails.email}
                        />
                        <SidebarField
                            label={t("label.companyPhone")}
                            value={bidDetails.phone}
                        />
                    </AccordionWrapper>
                    {bid?.rejectionReason && (
                        <AccordionWrapper
                            title={t("actions:bidActions.rejectReason")}
                            id="rejectReason"
                            isOpen={openAccordion}
                            onToggle={setOpenAccordion}
                        >
                            <RejectReason>{bid?.rejectionReason}</RejectReason>
                        </AccordionWrapper>
                    )}
                    {offer && offer.bidStatus === 0 && (
                        <div style={{ textAlign: "center" }}>
                            <Button
                                className="primary--action sidebar"
                                onClick={() => {
                                    return offer && offer.bidStatus === 0
                                        ? toggleOpenRevoke(offer.offerId)
                                        : undefined;
                                }}
                            >
                                {t("actions:bidActions.revokeBid")}
                            </Button>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default MyBidDetailSideBar;
