import React from "react";
import styled from "styled-components";
import AccordionWrapper from "../../../components/toggle-view/AccordionWrapper";
import { useTranslation } from "react-i18next";
import SidebarField from "../../../components/elements/sidebar/SidebarField";
import SidebarLicence from "../../../components/elements/sidebar/SidebarLicence";
import { SvgNoImage } from "../../pwa/components/StyledSvg";
import { Box16A9, VSpace } from "../../pwa/components/StyledElements";
import useAccordionGroup from "../../../hooks/_shared/useAccordionGroup";

const Section = styled.section`
    width: 100%;
    margin: 10px 0 10px 0;
    padding-bottom: 20px;
    border-bottom: 1px solid #eaeaea;

    &:last-child {
        border-bottom: none;
    }
`;

const Title = styled.div`
    font-size: 16px;
    color: #7f7f7f;
    font-weight: 600;
    margin: 10px 0 0 20px;
`;

const getDateFormat = (currentLanguage) =>
    Intl.DateTimeFormat(currentLanguage, {
        day: "numeric",
        month: "numeric",
        year: "numeric",
    });

const GetShortDate = (dateValue) => {
    if (dateValue && typeof dateValue === "string")
        dateValue = new Date(dateValue);
    const currentLanguage = "sk";
    return getDateFormat(currentLanguage).format(dateValue);
};

const UserDetailSideBar = ({ user, id, userData = "userData" }) => {
    let {
        fullName,
        username,
        roleNames,
        email,
        phoneNumber,
        licences = [],
        profilePictureUrl,
    } = user[userData];
    let hasUser = user[userData] !== undefined;
    const { t } = useTranslation("sidebar");
    const [openAccordion, setOpenAccordion] = useAccordionGroup();

    let sideBarRoles = [];
    if (roleNames) {
        sideBarRoles = roleNames.map((role) => {
            return <>{t("common:roles." + role)}</>;
        });
    }

    if (!hasUser) return null;

    const userLicences = [];
    const uLicences = licences.sort((a, b) => {
        const aDate = a.approvalDate
            ? new Date(a.approvalDate)
            : new Date("1900/1/1");
        const bDate = b.approvalDate
            ? new Date(b.approvalDate)
            : new Date("1900/1/1");
        return bDate - aDate;
    });

    const minValidDate = new Date("2000/1/1");
    uLicences.forEach((lic) =>
        userLicences.push({
            name: fullName,
            licNum: lic.licenceNumber,
            approver: lic.approver,
            approvalDate: new Date(lic.approvalDate || new Date("1900/1/1")),
        })
    );

    const userApprovals = () => {
        if (userLicences.length === 0)
            return (
                <p style={{ paddingLeft: "20px" }}>
                    {t("title.noInformation")}
                </p>
            );

        return userLicences.map((lic) => (
            <Section>
                <Title
                    dangerouslySetInnerHTML={{
                        __html: `${t(
                            "screens:certificates.newCertWizard.titlePersonal"
                        )}<br>(${lic.name})`,
                    }}
                />
                <SidebarField
                    label={t("screens:certificates.tableHeaders.certNumber")}
                    value={lic.licNum}
                />
                <SidebarField
                    label={t("label.approvalDate")}
                    value={
                        lic.approvalDate > minValidDate
                            ? GetShortDate(lic.approvalDate)
                            : "-"
                    }
                />
                <SidebarField
                    label={t("label.approver")}
                    value={lic.approver}
                />
            </Section>
        ));
    };

    return (
        <>
            <AccordionWrapper
                title={t("title.userDetails")}
                id="userDetails"
                isOpen={openAccordion}
                onToggle={setOpenAccordion}
                variant="sidebar"
            >
                <SidebarField label={t("label.fullName")} value={fullName} />
                <SidebarField label={t("label.userName")} value={username} />
                <SidebarField
                    label={t("label.companyRole")}
                    multipleValues={sideBarRoles}
                />
            </AccordionWrapper>

            <AccordionWrapper
                title={t("title.contactDetails")}
                id="contactDetails"
                isOpen={openAccordion}
                onToggle={setOpenAccordion}
                variant="sidebar"
            >
                <SidebarField label={t("label.email")} value={email} />
                <SidebarField
                    label={t("label.phoneNumber")}
                    value={phoneNumber}
                />
            </AccordionWrapper>

            <AccordionWrapper
                title={t("title.licence")}
                variant="sidebar"
                id="licence"
                isOpen={openAccordion}
                onToggle={setOpenAccordion}
            >
                <SidebarLicence licence={licences} userId={id} />
            </AccordionWrapper>

            <AccordionWrapper
                title={t("label.approvals")}
                id="approvals"
                isOpen={openAccordion}
                onToggle={setOpenAccordion}
            >
                {userApprovals()}
            </AccordionWrapper>

            <AccordionWrapper
                title={t("title.gallery")}
                id="gallery"
                isOpen={openAccordion}
                onToggle={setOpenAccordion}
            >
                <VSpace size="20px" />
                <Box16A9>
                    {profilePictureUrl ? (
                        <img
                            className="thumb"
                            alt="unit"
                            src={profilePictureUrl}
                        />
                    ) : (
                        <SvgNoImage />
                    )}
                </Box16A9>
            </AccordionWrapper>
        </>
    );
};

export default UserDetailSideBar;
