import React, { useState } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
    margin: 0 0 30px;
    margin: ${(p) => (p.inner === true ? "30px 0 !important" : "0 0 30px")};
    padding: ${(p) => (p.inner ? "0 0 0 25px !important" : "0")};

    &.isOpened {
        border-bottom: ${(p) =>
            p.inner || p.noBorder
                ? "0px solid"
                : `1px solid ${p.theme.sidebarWrapperBorderColor}`};
    }
`;

const AccordionElement = styled.div`
    transition: all 0.5s ease-in-out;
    overflow: hidden;
    max-height: 1000px;
    opacity: 1;
    padding: 0;

    &.top-space {
        padding-top: 25px;
    }

    &.closed {
        transition: all 0s;
        max-height: 0;
        opacity: 0;
    }

    &.sidebar {
        font-size: 0.875rem;
    }

    &.inset {
        padding-left: 20px;
    }

    &.overflow:not(.closed) {
        overflow: visible;
    }
`;

const AccordionTitle = styled.p`
    margin: 0 0 4px 0;
    padding: 0 0 0 20px;
    position: relative;
    cursor: pointer;
    color: ${(p) => p.theme.secondaryColor};
    font-size: ${(p) => (p.titleVariant === "small" ? "13px" : "1.25rem")};

    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: ${(p) => (p.titleVariant === "small" ? "0px" : "7px")};
        width: 8px;
        height: 12px;
        background: url("/images/actions/arrow-blue.svg") no-repeat top left;
        background-size: 8px 12px;
        transform: rotate(-180deg);
    }

    &.isOpened {
        &:before {
            transform: rotate(-90deg);
        }
    }
`;

const AccordionWrapper = (props) => {
    const {
        id,
        onToggle,
        title,
        isOpen = null,
        variant,
        inner,
        noBorder,
        titleVariant,
        ...rest
    } = props;
    const expanded = id
        ? Array.isArray(isOpen)
            ? isOpen.includes(id)
            : isOpen === id
        : true;
        
    const toggleAccordion = () => {
        onToggle && onToggle(id, !expanded);
    };

    return (
        <Wrapper
            {...rest}
            inner={inner}
            noBorder={noBorder}
            className={expanded ? "isOpened accordion" : "accordion"}
        >
            {title && (
                <AccordionTitle
                    titleVariant={titleVariant}
                    onClick={toggleAccordion}
                    className={expanded ? "isOpened" : ""}
                >
                    {title}
                </AccordionTitle>
            )}

            <AccordionElement className={[expanded ? "" : "closed", variant]}>
                {props.children}
            </AccordionElement>
        </Wrapper>
    );
};

const AccordionGroup = ({ children, activeIdx }) => {
    const [open, setOpen] = useState(activeIdx || -1);
    const onToggle = (idx, state) => {
        setOpen(state ? idx : -1);
    };
    return (
        <>
            {React.Children.map(children, (child, idx) => {
                if (child?.type?.name === "AccordionWrapper")
                    return React.cloneElement(child, {
                        isOpen: open === idx,
                        idx,
                        onToggle,
                    });
                return child;
            })}
        </>
    );
};

export default AccordionWrapper;
export { AccordionGroup };
