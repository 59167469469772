import React from "react";
import ValidationRule, {buildValidation} from "../../../../../core/validations/ValidationRule";
import MoneyInputElement from "../../../../../components/elements/form-elements/MoneyInputElement";
import {InfoText, InfoTitle, InfoWrapper, RoundInfo} from "../../../../../components/modal/components/WizardStyling";

const defaultInitialValues = {
    amount: "0.00"
}

const validateAmount = (t) => {
    return buildValidation({
        amount: ValidationRule.moreThan(0,
            t("forms:wallet.wizard.deposit.amount.invalid"),
            t("forms:wallet.wizard.deposit.amount.largerThenZero")).rule
    })
}

const WizardDepositMoney = (t) => {
    return {
        title: t('forms:wallet.wizard.deposit.title'),
        initialValues: defaultInitialValues,
        steps: [
            {
                name: t('forms:wallet.wizard.deposit.step1'),
                fieldNames: [
                    "amount"
                ],
                validation: validateAmount(t),
                fields: formik => {
                    return (
                        <>
                            <InfoWrapper>
                                <RoundInfo
                                    alt=""
                                    src="/images/menu/green-info.svg"
                                />
                                <div>
                                    <InfoTitle>
                                        {t(
                                            "forms:wallet.wizard.deposit.infoTitle"
                                        )}
                                    </InfoTitle>
                                    <InfoText>
                                        {t(
                                            "forms:wallet.wizard.deposit.infoText"
                                        )}
                                    </InfoText>
                                </div>
                            </InfoWrapper>
                            <MoneyInputElement
                                labelText={t(
                                    "forms:wallet.wizard.deposit.amount.text"
                                )}
                                currencyLabel="EUR"
                                name="amount"
                                id="amount"
                                step="0.01"
                                type="number"
                                value={formik.values["amount"]}
                                wrapperVariant="flex-2 wizard"
                                autoComplete="new-amount"
                                formik={formik}
                            />
                        </>
                    );
                }
            }
        ]
    }
};

export default WizardDepositMoney;
