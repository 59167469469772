import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import ModalWizard from "../../../components/modal/ModalWizard";
import RouteCreator from "../../../core/RouteCreator";
import CollectGasWizard from "./components/CollectGasWizard";
import usePromises from "../../../hooks/_shared/usePromises";
import MarketplaceService from "../../../services/BuyRefrigerant/MarketplaceService";

const ScreeWrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    display: flex;
    flex-wrap: nowrap;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
`;

const Collect = () => {
    const { t } = useTranslation("screens");
    const history = useHistory();

    const wizardData = usePromises(
        [
            () => MarketplaceService.getOffersForCollectionAsync(),
    
        ],
        (res) => ({
            offerCollection: res[0].data.data,
        })
    );

    useEffect(() => {
        ModalWizard.show(
            CollectGasWizard(t, wizardData.offerCollection),
            {
                onSubmit: async (values) => {
                    const result = await MarketplaceService.shipToBuyer(values.cylinders);
                    // const result = await StockService.moveToInstallerStockAsync(values.cylinderTagId, UserId);
                    if (result && result.error)
                        ModalWizard.submitFailed(t(result.error.message));
                    else {
                        toast.success(
                            t("stockListing.toastNotification.moveToStockSuccess")
                        );
                        ModalWizard.hide();
                        setTimeout(() => {
                            history.push(RouteCreator.pwa.root());
                        }, 100);
                    }
                },
                onCancel: async () => {
                    ModalWizard.hidePrompt(()=>history.goBack());
                },
            },
            { pwa: true }
        );
    }, [wizardData]);

    return <ScreeWrapper></ScreeWrapper>;
};

export default Collect;
