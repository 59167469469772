import React from "react";
import UserDetailSideBar from "../../../../User/components/UserDetailSideBar";

const EmployeeDetailSideBar = ({ employee }) => {
    const hasEmployee = employee.employeeData.firstName !== undefined;

    return (
        <>
            {hasEmployee && (
                <UserDetailSideBar user={employee} userData="employeeData" />
            )}
        </>
    );
};

export default EmployeeDetailSideBar;
