import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import useDataReducerImmediate from "../../../../hooks/_shared/useDataReducerImmediate";
import StatisticsService from "../../../../services/Statistics/StatisticsService";
import ChartHeader from "../../../../components/dashboard/ChartHeader";
import DASHBOARD_CONSTANTS from "../../../../components/dashboard/DashboardConstants";
import useImmerReducer from "../../../../hooks/_shared/useImmerReducer";
import DashboardStyles from "../../../../components/dashboard/DashboardStyles";
import DashboardTheme from "../../../../components/dashboard/DashboardTheme";
import ProgressStyles from "../../../../components/dashboard/ProgressStyles";
import CustomLegend from "../../../../components/dashboard/CustomLegend";
import ChartPieGasQuality from "../../../../components/dashboard/ChartPieGasQuality";
import Flex from "../../../../components/layout/flex/Flex";
import ProgressBar from "../../../../components/dashboard/ProgressBar";
import AdminDashboardMenu from "../user/components/AdminDashboardMenu";
import dataFunctions from "../../../../components/dashboard/DataFunctions";
import RouteCreator from "../../../../core/RouteCreator";
import useDataReducer from "../../../../hooks/_shared/useDataReducer";
import { Helmet } from "react-helmet";
import FilterableLineChart from "../../../../components/dashboard/FilterableLineChart";
import ChartProgress from "../../../../components/dashboard/ChartProgress";
import useBreadcrumbs from "../../../../core/state/navigation/useBreadcrumbs";
import CustomTooltip from "../../../../components/dashboard/CustomTooltip";
import CompanyType from "../../../../core/enums/CompanyType";
import CompanyStatus from "../../../../core/enums/CompanyStatus";

import {
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    Cell,
    BarChart,
    Bar,
    Legend,
    CartesianGrid,
} from "recharts";

const DashboardWrapper = styled.div`
    padding: 25px 60px 25px;
    height: calc(100% - 60px)
`;

const MainContainer = styled(Flex.Container)`
   
    @media all and (max-width: ${props => props.theme.extraLargeDevices}) {
        flex-direction: column;
    }
`;

const LeftContainer = styled(Flex.Container)`
    width: 80%;

    @media all and (max-width: ${props => props.theme.extraLargeDevices}) {
        order: 2;
        width: 100%;
        margin: 40px 0px 0px 0px;
      }
`;

const RightContainer = styled(Flex.Column)`
    min-width: 260px;
    width: 20%;

    .half-first {
        height: 50%;

    }

    .half-second {       
        height: calc(50% - 24px);
    }

    @media all and (max-width: ${props => props.theme.extraLargeDevices}) {
        width: 100%;

        .half-second, .half-first {
            height: auto;
        }

    }
`;

const CompanyContainer = styled(Flex.Column)`
    padding-top: 25px;
    min-width: 200px;
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const CertificateContainer = styled(Flex.Column)`
    width: 320px;
    display: flex;
    flex-direction: column;
`;


const CompanyOnboardingContainer = styled(Flex.Row)`
    min-height: 420px;
    height: 50%;

    @media all and (max-width: ${props => props.theme.extraLargeDevices}) {
        height: 412px;
    }

    padding-right: 30px;
    padding-left: 25px;
`;

const CompanysStatisticsDashboard = () => {

    const { t } = useTranslation();

    useBreadcrumbs([
        {
            text: t("navigation:companies"),
        },
    ]);

    const companyUnit = t(
        "screens:statistics.companiesTitle"
    );

    const [companyCountsDataState] = useDataReducerImmediate(
        StatisticsService.getCompaniesCounts
    );

    const [onboardedState, loadOnboarded] = useDataReducer(async (filter) => {
        return await StatisticsService.getCompanyOnboarding(filter);
    });

    const filterReducer = (state, action) => {
        switch (action.type) {
            case DASHBOARD_CONSTANTS.actionTypes.SET_FILTERS:
                state.from = action.payload.from;
                state.to = action.payload.to;
                break;
            case DASHBOARD_CONSTANTS.actionTypes.RESET_FILTERS:
                delete state.from
                delete state.to
                break;
            default:
                return state;
        }
    };

    const onChangeFilter = ((filters) => {
        dispatch({
            type: DASHBOARD_CONSTANTS.actionTypes.SET_FILTERS,
            payload: filters,
        });
    });


    const [filterState, dispatch] = useImmerReducer(filterReducer, {

        from: "",
        to: "",

    });

    useEffect(() => {
        loadOnboarded({ filter: filterState });

    }, [filterState]);

    const ActiveCompanies = 'ActiveCompanies';
    const ActiveCompanies_Distributor = 'ActiveCompaniesDistributor';
    const ActiveCompanies_Installer = 'ActiveCompaniesInstaller';
    const DisabledCompanies = 'DisabledCompanies';
    const DisabledCompanies_Distributor = 'DisabledCompaniesistributor';
    const DisabledCompanies_Installer = 'DisabledCompaniesInstaller';
    const DeletedCompanies = 'DeletedCompanies';

    const PendingCompanies = 'PendingCompanies';
    const ApprovedCompanies = 'ApprovedCompanies';

    const buildUrlWithActiveStatus = (link, activeStatus ) => {

       const url =   link + "?activeStatus=" + activeStatus;

       return url;
    }

    const buildUrlWithStatus = (link, status ) => {

        const url =   link + "?status=" + status;
 
        return url;
     }

    const baseUrl = RouteCreator.admin.companies.list(CompanyType.All);
    const baseUrlInstaller = RouteCreator.admin.companies.list(CompanyType.InstallationCompany);
    const baseUrlDistributor = RouteCreator.admin.companies.list(CompanyType.Distributor);
    const getCompaniesMenu = () => {
        const rawData =
            companyCountsDataState && companyCountsDataState.data
                ? companyCountsDataState.data
                : null;
        let items = [];
        if (rawData !== null) {
            items.push({
                id: ActiveCompanies,
                name: t(
                    "screens:statistics.activeCompanies"
                ),
                count: rawData.numberOfActiveCompanies,
                items: [
                    {
                        id: ActiveCompanies_Installer,
                        name: t(
                            "screens:statistics:companyTypes.installationPartners"
                        ),
                        url: buildUrlWithActiveStatus(baseUrlInstaller, "active"),
                        count: rawData.numberOfActiveInstallerCompanies,
                    },
                    {
                        id: ActiveCompanies_Distributor,
                        name: t(
                            "screens:statistics:companyTypes.distributors"
                        ),
                        url: buildUrlWithActiveStatus(baseUrlDistributor, "active"),
                        count: rawData.numberOfActiveDistributorCompanies,
                    },
                ]
            });

            items.push({
                id: DisabledCompanies,
                name: t(
                    "screens:statistics.disabledCompanies"
                ),
                items: [
                    {
                        id: DisabledCompanies_Installer,
                        name: t(
                            "screens:statistics:companyTypes.installationPartners"
                        ),
                        url: buildUrlWithActiveStatus(baseUrlInstaller, "disabled"),
                        count: rawData.numberOfDisabledInstallerCompanies,
                    },
                    {
                        id: DisabledCompanies_Distributor,
                        name: t(
                            "screens:statistics:companyTypes.distributors"
                        ),
                        url: buildUrlWithActiveStatus(baseUrlDistributor, "disabled"),
                        count: rawData.numberOfDisabledDistributorCompanies,
                    },
                ],

                count: rawData.numberOfDisabledCompanies
            });

            items.push({
                id: DeletedCompanies,
                name: t(
                    "screens:statistics.deletedCompanies"
                ),
                count: rawData.numberOfDeletedCompanies
            });
        }

        return items;

    };

    const getOnboardingMenu = () => {
        const rawData =
            companyCountsDataState && companyCountsDataState.data
                ? companyCountsDataState.data
                : null;
        let items = [];
        if (rawData !== null) {
            items.push({
                id: PendingCompanies,
                name: t(
                    "screens:statistics.pendingCompanies"
                ),
                url: buildUrlWithStatus(baseUrl, CompanyStatus.PendingConfirmation),
                count: rawData.numberOfCompaniesInPendingStatus
            });

            items.push({
                id: ApprovedCompanies,
                name: t(
                    "screens:statistics.approvedCompanies"
                ),
                url: buildUrlWithStatus(baseUrl, CompanyStatus.ConfirmationSent),
                count: rawData.numberOfCompaniesInConfirmationSentStatus
            });

        }

        return items;

    };

    const renderFGasCertificates = () => {

        const rawData =
            companyCountsDataState && companyCountsDataState.data
                ? companyCountsDataState.data
                : null;
        let items = [];
        if (rawData !== null) {
            items.push({
                name: t("common:Yes"),
                value: rawData.numberOfCompaniesWithFGasCertificate,
                percent: rawData.numberOfCompaniesWithFGasCertificate / rawData.numberOfCompanies * 100,
                color: DashboardTheme.SecondaryBarColor
            });

            items.push({
                name: t("common:No"),
                percent: rawData.numberOfCompaniesWithoutFGasCertificate / rawData.numberOfCompanies * 100,
                value: rawData.numberOfCompaniesWithoutFGasCertificate,
                color: DashboardTheme.ThirdBarColor
            });

        }

        return (
            <>
                <ChartPieGasQuality width={200} height={200} style={{ paddingBottom: "0px" }}
                    data={items}
                    isInt={true}
                    unit={t(
                        "screens:statistics.companiesTitle"
                    )}
                >
                </ChartPieGasQuality>

                <ProgressStyles.List>
                    {items.map((item, idx) => (
                        <ProgressStyles.Item key={idx}>
                            <ProgressStyles.Text>
                                <svg height="12" width="12">
                                    <circle
                                        cx="6"
                                        cy="6"
                                        r="5"
                                        fill={item.color}
                                    ></circle>
                                </svg>
                                <span>
                                    {item.name}
                                </span>
                            </ProgressStyles.Text>
                            <ProgressStyles.Wrapper>
                                <ProgressStyles.Label>
                                    {item.value}
                                </ProgressStyles.Label>
                                <ProgressBar
                                    key={idx}
                                    bgcolor={item.color}
                                    completed={item.percent}
                                />
                            </ProgressStyles.Wrapper>
                        </ProgressStyles.Item>
                    ))}
                </ProgressStyles.List>

            </>
        );
    };

    const CompanyTypes = () => {
        const rawData =
            companyCountsDataState && companyCountsDataState.data
                ? companyCountsDataState.data
                : null;
        let items = [];
        let colors = [];
        if (rawData !== null) {
            items.push({
                name: t(
                    "screens:statistics:companyTypes.installationPartners"
                ),
                value: rawData.numberOfInstallerServices,
                color: DashboardTheme.PrimaryBarColor
            });

            items.push({
                name: t(
                    "screens:statistics:companyTypes.distributors"
                ),
                value: rawData.numberOfFGasDistributors,
                color: DashboardTheme.SecondaryBarColor
            });

            items.map(item => {
                colors.push(item.color);
            });
        }

        return (
            <DashboardStyles.RelativeDiv>
                <DashboardStyles.NoDataWrapper
                    className={`${items.length === 0
                        ? "show bar"
                        : ""
                        }`}
                >
                    <DashboardStyles.SvgNoData />
                    {t("table:headers.noItems")}
                </DashboardStyles.NoDataWrapper>

                <ResponsiveContainer width="100%" minHeight="20px">
                    <BarChart data={items} margin={DashboardStyles.BarChart.Margin}>
                        <Bar
                            dataKey="value"
                            legendType="circle"
                            barSize={10}
                            barGap={2}
                            radius={[10, 10, 10, 10]}
                        >
                            {items.map((item, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={item.color}
                                />
                            ))}
                        </Bar>
                        <YAxis
                            axisLine={false}
                            tickLine={false}
                            stroke={DashboardTheme.FontColor}
                            style={{
                                fontSize: 12,
                            }}
                        />

                        <XAxis
                            axisLine={false}
                            tickLine={false}
                            tick={false}
                            dataKey="name"
                            stroke={DashboardTheme.FontColor}
                        />
                        <Tooltip
                            labelFormatter={() => ""}
                            cursor={false}
                            content={<CustomTooltip isInt={true} colors={colors} unit={companyUnit} />}
                        />
                        <CartesianGrid
                            vertical={false}
                            strokeWidth={1}
                            strokeOpacity="50%"
                        />
                        <Legend
                            iconType="circle"
                            verticalAlign="top"
                            align="left"
                            content={<CustomLegend data={items} />}
                            wrapperStyle={DashboardStyles.Legend.Wrapper}
                        />
                    </BarChart>
                </ResponsiveContainer>
            </DashboardStyles.RelativeDiv>
        );
    };


    const LeftColumn = () => {
        return (
            <CertificateContainer>
                <DashboardStyles.ChartHeaderContainer style={{ paddingLeft: "0px", height: 420 }} >
                    <ChartHeader
                        title={t(
                            "screens:statistics.fGasCertificate"
                        )}

                    />
                    {renderFGasCertificates()}
                </DashboardStyles.ChartHeaderContainer>

                <DashboardStyles.ChartHeaderContainer
                    style={{ minHeight: "350px", paddingLeft: "0px", paddingTop: "22px"}}
                    className="grow"
                >

                    <ChartHeader
                        title={t(
                            "screens:statistics.companiesTypesTitle"
                        )}
                    />
                    <div
                        style={{
                            paddingTop: 20,
                            minHeight: "10px",
                            flexGrow: 1,
                        }}
                    >
                        <CompanyTypes />
                    </div>
                </DashboardStyles.ChartHeaderContainer>
            </CertificateContainer>
        );
    };

    const DisputesSection = () => {

        const rawData =
            companyCountsDataState && companyCountsDataState.data
                ? companyCountsDataState.data
                : null;
        let items = [];
        let itemsProgress = [];

        if (rawData !== null) {
            const total = rawData.numberOfActiveDisputes + rawData.numberOfInactiveDisputes;
            const activeItem = {
                name: t(
                    "screens:statistics.active"
                ),
                value: rawData.numberOfActiveDisputes,
                color: DashboardTheme.ThirdBarColor,
                percent: rawData.numberOfActiveDisputes / total * 100,
            };

            items.push(activeItem);

            itemsProgress.push(activeItem);

            const resolved = {
                name: t(
                    "screens:statistics.inactive"
                ),
                value: rawData.numberOfInactiveDisputes,
                color: DashboardTheme.SecondaryBarColor,
                percent: rawData.numberOfInactiveDisputes / total * 100,
            }

            items.push(resolved);
            itemsProgress.push(resolved);

        }

        return (

            <Flex.Column style={{ width: "50%" }}>
                <Flex.Row>
                    <ChartHeader
                        title={t(
                            "screens:statistics.disputesTitle"
                        )}
                        tooltip={<DashboardStyles.Link
                            to={RouteCreator.admin.users.nonAdmins()}
                        >
                            {t(
                                "screens:statistics.viewAll"
                            )}
                        </DashboardStyles.Link>}

                    />
                    <ChartPieGasQuality width={200} height={200} style={{ paddingBottom: "0px" }}
                        data={items}
                        unit={t(
                            "screens:statistics.disputesTitle"
                        )}
                        isInt={true}

                    >
                    </ChartPieGasQuality>
                </Flex.Row>
                <Flex.Row style={{ flex: 1 }}>
                    <ChartProgress
                        data={itemsProgress}
                        isInt={true}
                        hideUnit={true}
                        style={{ paddingLeft: "0px" }}
                    >
                    </ChartProgress>
                </Flex.Row>
            </Flex.Column>

        );
    };


    const FGasAnalyzerSection = () => {

        const rawData =
            companyCountsDataState && companyCountsDataState.data
                ? companyCountsDataState.data
                : null;
        let items = [];
        if (rawData !== null) {
            items.push({
                name: t("common:Yes"),
                value: rawData.numberOfCompaniesWithFGasAnalyzer,
                color: DashboardTheme.SecondaryBarColor
            });

            items.push({
                name: t("common:No"),
                value: rawData.numberOfCompaniesWithoutFGasAnalyzer,
                color: DashboardTheme.ThirdBarColor
            });

        }

        return (
            <Flex.Column style={{ width: "50%", marginLeft: 20 }}>
                <Flex.Row>
                    <ChartHeader
                        title={t(
                            "screens:statistics.fGasAnalyzerTitle"
                        )}
                        tooltip={<DashboardStyles.Link
                            to={RouteCreator.admin.users.nonAdmins()}
                        >
                            {t(
                                "screens:statistics.viewAll"
                            )}
                        </DashboardStyles.Link>}

                    />
                    <ChartPieGasQuality width={200} height={200} style={{ paddingBottom: "0px" }}
                        data={items}
                        unit={t(
                            "screens:statistics.companiesTitle"
                        )}
                        isInt={true}
                    >
                    </ChartPieGasQuality>
                </Flex.Row>

                <Flex.Row style={{ flex: 1 }}>
                    <ChartProgress
                        data={items}
                        isInt={true}
                        hideUnit={true}
                        style={{ paddingLeft: "0px" }}
                    >
                    </ChartProgress>
                </Flex.Row>
            </Flex.Column>
        );
    };


    const MiddleBottomSection = () => {

        const rawData =
            companyCountsDataState && companyCountsDataState.data
                ? companyCountsDataState.data
                : null;
        let items = [];
        if (rawData !== null) {
            items.push({
                name: t(
                    "screens:statistics:companyTypes.installationPartners"
                ),
                value: rawData.numberOfActiveDisputes,
                color: DashboardTheme.ThirdBarColor
            });

            items.push({
                name: t(
                    "screens:statistics:companyTypes.distributors"
                ),
                value: rawData.numberOfInactiveDisputes,
                color: DashboardTheme.borderColor
            });

        }

        return (
            <Flex.Container style={{ position: "relative", height:"calc(50%-24px)" }}>
                <DashboardStyles.NoDataWrapper
                    className={`${items && items.length === 0 ? "show" : ""}`}
                >
                    <DashboardStyles.SvgNoData />
                    {t("table:headers.noItems")}
                </DashboardStyles.NoDataWrapper>
                <DisputesSection />
                <FGasAnalyzerSection />

            </Flex.Container>
        );
    };

    return (
        <DashboardWrapper>
            <Helmet>
                <title>{t("seo:companyStatistics.title")}</title>
            </Helmet>
            {companyCountsDataState.isCompleted && (
                <MainContainer>
                    <LeftContainer>
                        <LeftColumn />
                        <CompanyContainer>
                            <CompanyOnboardingContainer>
                                <FilterableLineChart
                                    filterState={filterState}
                                    title={t(
                                        "screens:statistics.companyOnboarding"
                                    )}
                                    minHeight="320px"                              
                                    color={DASHBOARD_CONSTANTS.lineChartColor}
                                    data={onboardedState?.data}
                                    isCompleted={onboardedState?.isCompleted}
                                    onChangeFilter={onChangeFilter}
                                    column="value"
                                    unit={companyUnit}
                                    isInt={true}
                                    height={'90%'}
                                    style={{ marginTop: "-25px" }}
                                    showDrillDown={false}
                                >

                                </FilterableLineChart>
                            </CompanyOnboardingContainer>
                            <MiddleBottomSection />
                        </CompanyContainer>
                    </LeftContainer>

                    <RightContainer>
                        <AdminDashboardMenu
                            items={getCompaniesMenu()}
                            style={{minHeight: 350}}
                            className={"half-first"} 
                            hasChildren={true}             
                            title={t(
                                "screens:statistics.companiesTitle"
                            )}

                        >
                        </AdminDashboardMenu>
                        <AdminDashboardMenu
                            items={getOnboardingMenu()}
                            className={"half-second"} 
                            style={{marginTop:"24px"}}
                            title={t(
                                "screens:statistics.onboardingTitle"
                            )}

                        ></AdminDashboardMenu>
                    </RightContainer>
                </MainContainer>
            )}
        </DashboardWrapper>
    );
};

export default CompanysStatisticsDashboard;
