import React from "react";
import { Redirect, Switch } from "react-router-dom";
import PrivateRoute from "../../core/PrivateRoute";
import RouteCreator from "../../core/RouteCreator";
import useLeftMenu from "../../core/state/navigation/useLeftMenu";
import globalAdminMenuItems from "../../data/menu/globalAdminMenuItems";
import QRGenerationView from "./views/qr/QRGenerationView";
import { useEffect, useRef, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import AuthorizeComponent from "../../core/AuthorizeComponent";
import HamburgerMenu from "../../components/menu/HamburgerMenu";
import HamburgerMenuAction from "../../components/menu/HamburgerMenuAction";
import DateCell from "../../components/data/DateCell";
import Roles from "../../core/enums/Roles";
import useBreadcrumbs from "../../core/state/navigation/useBreadcrumbs";
import { Helmet } from "react-helmet";
import LayoutInnerContainer from "../../components/layout/split/one/LayoutInnerContainer";
import AccordionWrapper from "../../components/toggle-view/AccordionWrapper";
import CompanyService from "../../services/Company/CompanyService";
import UserService from "../../services/User/UserService";
import ListingPage from "../../components/data/ListingPage";
import AdminCertSidebar from "./AdminCertSidebar";

const ECertificateStatus = {
    Submitted: 0,
    Valid: 1,
    Rejected: 2,
    Expired: 99,
    NoAttestation: -1, //display only
};

const EmptyGuid = "00000000-0000-0000-0000-000000000000";

const ContentWrapper = styled.div`
    display: block;
    width: 100%;
    padding-right: 20px;
    @media ${(props) => props.theme.device.mobile} {
        padding-left: 16px;
        padding-right: 16px;
    }
`;

const InfoWrapper = styled.div`
    display: flex;
    width: 100%;
    color: #000;

    & .topSpace {
        margin-top: 14px;
    }

    & .info {
        font-size: 14px;
    }

    & li {
        font-size: 12px;
        line-height: 18px;
    }
`;

const GreenInfo = styled.img`
    width: 40px;
    height: 40px;
    margin-right: 24px;
`;

const ColoredText = styled.div`
    color: ${(p) => p.theme.primaryColor};

    &.error {
        color: ${(p) => p.theme.error};
    }
`;

const Separator = styled.div`
    width: 100%;
    height: 2px;
    background-color: #000;
    margin-top: 24px;
`;

const RightContainer = styled.div`
    display: flex;
    justify-content: flex-end;

    margin-top: 20px;
`;

const HolderCell = ({ cell: { row } }) => {
    const holder = row.original.certificateHolder;
    const status = row.original.licenceStatus;

    return (
        <ColoredText
            className={status !== ECertificateStatus.Valid ? "error" : ""}
        >
            {holder}
        </ColoredText>
    );
};

const StatusCell = ({ cell: { row } }) => {
    const text = row.original.licenceStatusName;
    const status = row.original.licenceStatus;

    return (
        <ColoredText
            className={status !== ECertificateStatus.Valid ? "error" : ""}
        >
            {text}
        </ColoredText>
    );
};

const employeeBreadcrumbs = (t) => [
    {
        text: t("navigation:users"),
    },
    {
        text: "",
    },
    {
        text: t("filters:certificate.certificates"),
    },
];

const UserCertificateScreen = () => {
    useLeftMenu(globalAdminMenuItems);
    const { t } = useTranslation();
    const history = useHistory();

    const [userCerts, setUserCerts] = useState([]);
    const [userName, setUserName] = useState("");
    const { userId } = useParams();
    const { search } = useLocation();
    const companyId = new URLSearchParams(search).get("companyId");
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const { update: updateBreadcrumbs } = useBreadcrumbs(
        employeeBreadcrumbs(t)
    );

    const getCertsAsync = async () => {
        const resUsers = await CompanyService.getEmployeesCertificates(
            userId,
            companyId
        );
        if (!resUsers.error) {
            const myCerts = resUsers.data.data.filter(
                (cert) => cert.userId === userId
            );
            setUserCerts(myCerts);
        }
    };

    const updateCertificateStatus = async (certId, status) => {
        const res = await CompanyService.updateCertificateStatus(
            certId,
            status
        );
        if (res && res.error) {
            toast.error(t(res.error.message));
        } else {
            toast.success(t("actions:certificate.toastNotification.updated"));
            getCertsAsync();
        }
    };

    useEffect(() => {
        const getUserName = async () => {
            const res = await UserService.getUserById(userId);
            setUserName(res.fullName);
        };

        getCertsAsync();
        getUserName();
    }, []);

    useEffect(() => {
        if (userName !== "") {
            const bc = employeeBreadcrumbs(t);
            bc[1].text = userName;
            updateBreadcrumbs(bc);
        }
    }, [userName]);

    const onSelect = (row) => {
        const selectRow = async (row) => {
            setSelectedRow(row.original);
            setSidebarOpen(true);
        };
        selectRow(row);
    };

    const onCloseSidebar = () => {
        setSidebarOpen(false);
    };

    const certColumns = useMemo(
        () => [
            {
                Header: t("screens:certificates.tableHeaders.certHolder"),
                accessor: "certificateHolder",
                Cell: HolderCell,
            },
            {
                Header: t("screens:certificates.tableHeaders.certNumber"),
                accessor: "licenceNumber",
            },
            {
                Header: t("screens:certificates.tableHeaders.expiration"),
                accessor: "licenceExpiryDate",
                Cell: DateCell,
            },
            {
                Header: t("screens:certificates.tableHeaders.submitDate"),
                accessor: "licenceUploadDate",
                Cell: DateCell,
            },
            {
                Header: t("screens:certificates.tableHeaders.procDate"),
                accessor: "processedDate",
                Cell: DateCell,
            },
            {
                Header: t("screens:certificates.tableHeaders.status"),
                accessor: "licenceStatusName",
                Cell: StatusCell,
            },
            {
                Header: "",
                accessor: "id",
                Cell: ({ cell: { row } }) => {
                    return (
                        <AuthorizeComponent
                            roles={[Roles.Admin, Roles.MasterAdmin]}
                        >
                            {row.original.licenceId !== EmptyGuid && (
                                <HamburgerMenu up={true}>
                                    <HamburgerMenuAction
                                        text={t("actions:view")}
                                        onClick={() =>
                                            window.open(
                                                row.original
                                                    .licenceCertificateFile,
                                                "_blank"
                                            )
                                        }
                                    ></HamburgerMenuAction>
                                    {row.original.licenceStatus === 0 && (
                                        <>
                                            <HamburgerMenuAction
                                                text={t("actions:validate")}
                                                onClick={() =>
                                                    updateCertificateStatus(
                                                        row.original.licenceId,
                                                        1
                                                    )
                                                }
                                            />
                                            <HamburgerMenuAction
                                                text={t(
                                                    "actions:bidActions.reject"
                                                )}
                                                onClick={() =>
                                                    updateCertificateStatus(
                                                        row.original.licenceId,
                                                        2
                                                    )
                                                }
                                            />
                                        </>
                                    )}
                                </HamburgerMenu>
                            )}
                        </AuthorizeComponent>
                    );
                },
            },
        ],
        [t]
    );

    return (
        <ContentWrapper>
            <Helmet>
                <title>{t("screens:certificates.personalCert")}</title>
            </Helmet>
            <LayoutInnerContainer>
                <AccordionWrapper
                    title={`${t("screens:certificates.personalCert")}`}
                    variant="overflow top-space inset"
                    isOpen={true}
                    style={{ marginTop: "56px" }}
                    noBorder
                >
                    <InfoWrapper>
                        <GreenInfo src="/images/menu/green-info.svg" />
                        <div>
                            <div className="info">
                                {t(
                                    "screens:certificates.personalCertInfo.allTechnicians"
                                )}
                            </div>
                            <div className="info">
                                {t(
                                    "screens:certificates.personalCertInfo.requirement"
                                )}
                            </div>
                            <div className="info topSpace">
                                {t(
                                    "screens:certificates.personalCertInfo.qualification"
                                )}
                            </div>
                            <div className="info topSpace">
                                {t(
                                    "screens:certificates.personalCertInfo.regulation"
                                )}
                            </div>
                            <ul>
                                <li>
                                    {t(
                                        "screens:certificates.personalList.regulations"
                                    )}
                                </li>
                                <li>
                                    {t(
                                        "screens:certificates.personalList.emission"
                                    )}
                                </li>
                                <li>
                                    {t(
                                        "screens:certificates.personalList.recovery"
                                    )}
                                </li>
                                <li>
                                    {t(
                                        "screens:certificates.personalList.handling"
                                    )}
                                </li>
                                <li>
                                    {t(
                                        "screens:certificates.personalList.information"
                                    )}
                                </li>
                            </ul>
                        </div>
                    </InfoWrapper>

                    <Separator />
                    {/* <DataTable columns={certColumns} data={userCerts} /> */}
                    <ListingPage
                        key="user"
                        columns={certColumns}
                        tableState={{
                            data: userCerts,
                        }}
                        onSelect={onSelect}
                        closeSideBar={onCloseSidebar}
                        detailViewId="company"
                        detailView={
                            <AdminCertSidebar
                                key="user"
                                {...selectedRow}
                                type="user"
                            />
                        }
                        detailViewIsOpened={sidebarOpen}
                    />
                </AccordionWrapper>
            </LayoutInnerContainer>
        </ContentWrapper>
    );
};

export default UserCertificateScreen;
