import React, { cloneElement } from "react";
import useAuthorization from "./useAuthorization";

const AuthorizeComponent = ({children, roles, disable, disableForOtherRoles}) => {
    const canAccess = useAuthorization({allowedRoles: roles});

    if (disable || (disableForOtherRoles && !canAccess)) {
        return cloneElement(children, {'disabled': 'disabled'})
    }

    if (canAccess) {
        return children;
    }

    return <></>
};

export default AuthorizeComponent;
