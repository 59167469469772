import styled, { css } from "styled-components";

const FlexContainer = styled.div`
    display: flex;
    ${props => props.justifyContent && css`justify-content: ${props.justifyContent};`}
    ${props => props.flexWrap && css`flex-wrap: ${props.flexWrap};`}
    width: 100%;

    &.horizontal-center {
    justify-content: center;
    align-items: center;
    flex-direction: column;

        @media all and (min-width: ${props => props.theme.mediumDevices}) {
            flex-direction: row;
        }
    }

    &.full-height {
        min-height: 100%;
        height: 100%;
        width: 100%;
    }

`;

export default FlexContainer;
