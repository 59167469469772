import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import ModalWizard from "../../../components/modal/ModalWizard";
import ExtractionWizard from "./components/ExtractionWizard";
import TagsService from "../../../services/Tags/TagsService";
import StockService from "../../../services/Stock/StockService";
import CompanyService from "../../../services/Company/CompanyService";
import ModalDialog, { useModal } from "../../../components/modal/ModalDialog";
import { toast } from "react-toastify";

const ScreeWrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    display: flex;
    flex-wrap: nowrap;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
`;
const P = styled.div`
    display: block;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 12px;
`;

const NewExtraction = () => {
    const { t } = useTranslation("screens");
    const history = useHistory();
    const queryUnitTags = async (tag) => {
        if (tag.length > 2) {
            const tags = await TagsService.lookupUnitTags(tag);
            return tags.map((t) => t.id);
        }
        return [];
    };

    const queryCylinderTags = async (tag) => {
        if (tag.length > 2) {
            const tags = await TagsService.lookupExistingCylinderTags(tag);
            return tags.map((t) => t.id);
        }
        return [];
    };

    const queryAnalyzerTags = async (tag) => {
        if (tag.length > 2) {
            const tags = await TagsService.lookupTagForAnalyzerAsync(tag);
            return tags.data.data.map((t) => t.id);
        }
        return [];
    };

    const { modalProps: extractionModalProps, toggleOpen: toggleExtractionModal } = useModal({
        title: t("forms:analyzer.wizard.measurementResults"),
        type: "warning",
        confirmText: t("forms:wizard.finish"),
        onConfirm: () => {
            toggleExtractionModal();
            toast.success(t("actions:dialogs.newExtraction.success"));
            ModalWizard.hide();
            setTimeout(() => {
                history.goBack();
            }, 100);
        },
    });

    useEffect(() => {
        ModalWizard.show(
            ExtractionWizard(t, queryUnitTags, queryCylinderTags, queryAnalyzerTags, {
                extractionDateTime: new Date(),
            }),
            {
                onSubmit: async (values) => {
                    if (values.gradeC) {
                        values.pressumedGrade = 2;
                    } else if (values.gradeB) {
                        values.pressumedGrade = 1;
                    } else {
                        values.pressumedGrade = 0;
                    }
                    values.isContaminated = values.gradeA;
                    values.isMixture = values.gradeB;
                    values.hasR22 = values.gradeC;

                    let analyzer = {};
                    if (values.analyzerTagId) {
                        try {
                            const analyzerData = await CompanyService.getMyGasAnalyzerByTagAsync(
                                values.analyzerTagId
                            );
                            analyzer = analyzerData?.data?.data?.[0];
                        } catch (ex) {
                            analyzer.name = t("screens:statistics.fGasAnalyzerTitle");
                        }
                    }

                    const result = await StockService.addToMyStock(ModalWizard.asFormData(values));

                    if (result && result.error) ModalWizard.submitFailed(t(result.error.message));
                    else {
                        toggleExtractionModal({ analyzer });
                    }
                },
                onCancel: async () => {
                    ModalWizard.hidePrompt(() => history.goBack());
                },
            },
            { pwa: true }
        );
    }, []);

    return (
        <ScreeWrapper>
            <ModalDialog {...extractionModalProps}>
                <P>{t("forms:analyzer.wizard.meResultsLine1")}</P>
                <P>{extractionModalProps?.modal?.analyzer?.name}</P>
                <P>{t("forms:analyzer.wizard.meResultsLine2")}</P>
                <P>{t("forms:analyzer.wizard.meResultsLine3")}</P>
                <P>{t("forms:analyzer.wizard.meResultsLine4")}</P>
            </ModalDialog>
        </ScreeWrapper>
    );
};

export default NewExtraction;
