import React, { useState } from "react";
import styled from "styled-components";
import StatusTooltip from "../../tooltip/StatusTooltip";

const StatusItemWrapper = styled.div`
    width: 20px;
    height: 20px;
    padding: 6px 0px;
    margin-right: 6px;
`;

const StatusItem = styled.div`
    background: #cccccc;
    width: 20px;
    height: 8px;
    position: relative;
    border-radius: 4px;

    &.fulfilled {
        background: ${(p) => p.theme.primaryColor};
    }

    &.failed {
        background: #e94e4e;
    }

    &.pending {
        background: #cccccc;
    }

    &.inProgress {
        background: #fff;
        border: 1px solid ${(p) => p.theme.primaryColor};
    }

    &.failed .tooltip {
        border: 1px solid #e94e4e;
        color: #e94e4e;
    }
`;

const StatusWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 16px;
    align-items: center;
    position: relative;
`;

const Dispute = styled.span`
    color: #e94e4e;
    font-weight: 600;
`;

const TransactionItem = ({ status, title, tip, idx }) => {
    const [tooltip, setTooltip] = useState({ tip: null });

    const handleHover = (title, tip) => {
        if (!tip) setTooltip({ tip: null });
        else {
            const html = `${title ? `<span>${title}</span>` : ""}${tip}`;
            setTooltip({ tip: html });
        }
    };

    return (
        <StatusItemWrapper
            onMouseEnter={() => {
                handleHover(title, tip);
            }}
            onMouseLeave={() => {
                handleHover(null, null);
            }}
        >
            <StatusItem
                className={
                    status === 1
                        ? "fulfilled"
                        : status === 2
                        ? "failed"
                        : status === 3
                        ? "pending"
                        : status === 4
                        ? "inProgress"
                        : ""
                }
            ></StatusItem>
            {tooltip.tip && (
                <StatusTooltip
                    position="top"
                    visible={tooltip && tooltip.tip}
                    tooltip={tooltip?.tip}
                    idx={idx}
                />
            )}
        </StatusItemWrapper>
    );
};

const TransactionStatus = ({ steps, inDispute, ...rest }) => {
    const items = steps.map((item, idx) => (
        <TransactionItem
            key={idx}
            status={item.status}
            title={item.title}
            tip={item.tip}
            idx={idx}
        ></TransactionItem>
    ));
    if (inDispute)
        return (
            <StatusWrapper>
                <Dispute>{inDispute}</Dispute>
            </StatusWrapper>
        );
    return <StatusWrapper>{items}</StatusWrapper>;
};

export default TransactionStatus;
