import React from "react";
import { useTranslation } from "react-i18next";
import { ClearableInput, Label } from "../../../../components/elements/form-elements";
import { DateTimePicker } from "../../../../components/elements/form-elements/DatePicker";
import Filters from "../../../../components/filter/Filters";
import helpers from "../../../../core/helpers";
import useDebouncedCallback from "../../../../hooks/_shared/useDebouncedCallback";

const DocumentsFilter = ({ filterState, dispatch, actionTypes }) => {
    const { t } = useTranslation("filters");

    const [buyerChanged] = useDebouncedCallback((text) => {
        text
            ? dispatch({ type: actionTypes.SET_BUYER, payload: text.trim() })
            : dispatch({ type: actionTypes.RESET_BUYER });
    });

    const [transactionIdChanged] = useDebouncedCallback((text) => {
        text
            ? dispatch({ type: actionTypes.SET_MANGOTRANSACTION_ID, payload: text.trim() })
            : dispatch({ type: actionTypes.RESET_MANGOTRANSACTION_ID });
    });

    const [amountFromChanged] = useDebouncedCallback((text) => {
        text
            ? dispatch({
                  type: actionTypes.SET_AMOUNT_FROM,
                  payload: text.trim(),
              })
            : dispatch({
                  type: actionTypes.RESET_AMOUNT_FROM,
                  payload: text,
              });
    });

    const [amountToChanged] = useDebouncedCallback((text) => {
        text
            ? dispatch({
                  type: actionTypes.SET_AMOUNT_TO,
                  payload: text.trim(),
              })
            : dispatch({
                  type: actionTypes.RESET_AMOUNT_TO,
                  payload: text,
              });
    });

    return (
        <>
            <Filters.Wrapper className="filter-wrapper">
                <Filters.Item>
                    <Label htmlFor="mangoTransactionId" className="sr-only">
                        {t("documents.transactionId")}
                    </Label>
                    <ClearableInput
                        key="mangoTransactionId"
                        name="mangoTransactionId"
                        type="text"
                        defaultValue={
                            filterState.mangoTransactionId !== undefined
                                ? filterState.mangoTransactionId
                                : ""
                        }
                        onChange={(evt) => {
                            transactionIdChanged(evt.currentTarget.value);
                        }}
                        placeholder={t("documents.transactionId")}
                        title={t("documents.transactionId")}
                        className="filters"
                    />
                </Filters.Item>
                <Filters.Group>
                    <Filters.Item>
                        <DateTimePicker
                            labelText={t("documents.executedDateFrom")}
                            noLabel
                            placeholder={t("documents.executedDateFrom")}
                            id="dateFrom"
                            name="dateFrom"
                            className="filters big left group"
                            value={
                                filterState ? filterState.dateFrom : ""
                            }
                            maxDate={filterState?.dateTo}
                            handleChange={(date) =>
                                dispatch({
                                    type:
                                        date === ""
                                            ? actionTypes.RESET_DATE_FROM
                                            : actionTypes.SET_DATE_FROM,
                                    payload:
                                        date === ""
                                            ? date
                                            : helpers.dateToIsoDateString(
                                                new Date(date)
                                            ),
                                })
                            }
                        />
                    </Filters.Item>
                    <Filters.Item>
                        <DateTimePicker
                            labelText={t("documents.executedDateTo")}
                            noLabel
                            placeholder={t("documents.executedDateTo")}
                            id="dateTo"
                            name="dateTo"
                            className="filters big right group"
                            value={
                                filterState ? filterState.dateTo : ""
                            }
                            minDate={filterState?.dateFrom}
                            handleChange={(date) =>
                                dispatch({
                                    type:
                                        date === ""
                                            ? actionTypes.RESET_DATE_TO
                                            : actionTypes.SET_DATE_TO,
                                    payload:
                                        date === ""
                                            ? date
                                            : helpers.dateToIsoDateString(date),
                                })
                            }
                        />
                    </Filters.Item>
                </Filters.Group>

                <Filters.Item>
                    <Label htmlFor="buyer" className="sr-only">
                        {t("documents.buyer")}
                    </Label>
                    <ClearableInput
                        key="buyer"
                        name="buyer"
                        type="text"
                        defaultValue={
                            filterState.buyer !== undefined
                                ? filterState.buyer
                                : ""
                        }
                        onChange={(evt) => {
                            buyerChanged(evt.currentTarget.value);
                        }}
                        placeholder={t("documents.buyer")}
                        title={t("documents.buyer")}
                        className="filters"
                    />
                </Filters.Item>
                <Filters.Group>
                    <Filters.Item>
                        <Label htmlFor="amountFrom" className="sr-only">
                            {t("documents.amountFrom")}
                        </Label>

                        <ClearableInput
                            key="amountFrom"
                            name="amountFrom"
                            type="number"
                            defaultValue={
                                filterState.amountFrom
                            }
                            onChange={(evt) => {
                                amountFromChanged(evt.currentTarget.value);
                            }}
                            placeholder={t("documents.amountFrom")}
                            title={t("documents.amountFrom")}
                            className="filters left group"
                        />
                    </Filters.Item>

                    <Filters.Item>
                        <Label htmlFor="amountTo" className="sr-only">
                            {t("documents.amountTo")}
                        </Label>
                        <ClearableInput
                            key="amountTo"
                            name="amountTo"
                            type="number"
                            defaultValue={
                                filterState.amountTo
                            }
                            onChange={(evt) => {
                                amountToChanged(evt.currentTarget.value);
                            }}
                            placeholder={t("documents.amountTo")}
                            title={t("documents.amountTo")}
                            className="filters right group"
                        />
                    </Filters.Item>
                </Filters.Group>
            </Filters.Wrapper>
        </>
    );
};

export default DocumentsFilter;
