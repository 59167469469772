import LoginService from "../../../services/Login/LoginService";
import UserService from "../../../services/User/UserService";
import createStateModule from "../createStateModule";

const userStateConfig = {
    stateNamespace: "user",
    types: [
        "LOGIN_LOADING",
        "LOGIN_SUCCESS",
        "CLEAR_LOGIN_ERROR",
        "LOGIN_ERROR",
        "LOGIN_TWOFACTOR_NEEDED",
        "VERIFY_LOADING",
        "VERIFY_SUCCESS",
        "VERIFY_ERROR",
        "CLEAR_VERIFY_ERROR",
        "LOGOUT",
        "FULLY_REGISTER",
        "USERDATA_LOADING",
        "USERDATA_SUCCESS",
        "USERDATA_ERROR",
        "UPDATEUSERPICTURE_LOADING",
        "UPDATEUSERPICTURE_ERROR",
        "UPDATEUSERPICTURE_SUCCESS",
        "DELETEUSERPICTURE_LOADING",
        "DELETEUSERPICTURE_ERROR",
        "DELETEUSERPICTURE_SUCCESS",
        "UPDATEUSERPICTURE_LEAVE",
        "ACTIVATE_LOADING",
        "ACTIVATE_ERROR",
        "ACTIVATE_SUCCESS",
        "CHANGEPASSWORD_LOADING",
        "CHANGEPASSWORD_ERROR",
        "CHANGEPASSWORD_SUCCESS",
        "CHANGEPASSWORD_LEAVE",
        "SETMENUOPEN",
        "UPDATEUSERDATA_LOADING",
        "UPDATEUSERDATA_SUCCESS",
        "UPDATEUSERDATA_ERROR",
        "UPDATEUSERDATA_RESETSTATE",
    ],
    initialState: {
        isAuthenticated: !!localStorage.getItem("token"),
        isFullyRegistered: UserService.getIsFullyRegistered(),
        roles: UserService.getUserRoles(),
        userCompanies:[],
        twoFactor: {
            isRequired: false,
            isLoading: false,
            error: null,
        },
        twoFactorVerify: {
            isLoading: false,
            error: null,
            data: null,
        },
        loginState: {
            isLoading: false,
            error: null,
        },
        userData: {
            isLoading: false,
            isLoaded: false,
            profilePictureUrl: "",
            firstName: "",
            lastName: "",
        },
        userProfilePictureUpdate: {
            isLoading: false,
            isCompleted: false,
            error: null,
        },
        userProfilePictureDelete: {
            isLoading: false,
            isCompleted: false,
            error: null,
        },
        username:
            UserService.getUserDetailsFromToken() &&
            UserService.getUserDetailsFromToken().Username,
        impersonatedByUsername :UserService.getUserDetailsFromToken() &&
        UserService.getUserDetailsFromToken().ImpersonatedByUsername,
        activation: {
            isLoading: false,
            isCompleted: false,
            error: null,
        },
        password: {
            isLoading: false,
            isCompleted: false,
            error: null,
        },
        menuState: {
            isOpened: true,
        },
        userUpdateData: {
            isLoading: false,
            isCompleted: false,
            error: null,
            response: null,
        },
    },
    createReducer: (types) => {
        return (state, action) => {
            switch (action.type) {
                case types.LOGIN_LOADING:
                    state.loginState.isLoading = true;
                    state.loginState.error = null;
                    break;
                case types.LOGIN_ERROR:
                    state.loginState.isLoading = false;
                    state.loginState.error = action.payload;
                    break;
                case types.CLEAR_LOGIN_ERROR:
                    state.loginState.error = null;
                    break;
                case types.LOGIN_SUCCESS:
                    state.loginState.isLoading = false;
                    state.loginState.error = null;
                    state.isFullyRegistered = UserService.getIsFullyRegistered();
                    state.roles = UserService.getUserRoles();
                    state.username = action.payload.username;
                    state.userCompanies = action.payload.userCompanies;
                    state.isAuthenticated = state.userCompanies.length === 0;
                    state.userData.isLoaded = false;
                    break;
                case types.LOGIN_TWOFACTOR_NEEDED:
                    state.isAuthenticated = false;
                    state.loginState.isLoading = false;
                    state.loginState.error = null;
                    state.twoFactor.isRequired = true;
                    state.twoFactor.isLoading = false;
                    state.twoFactor.error = null;
                    state.username = action.payload;
                    break;
                case types.VERIFY_LOADING:
                    state.twoFactorVerify.isLoading = true;
                    state.twoFactorVerify.error = null;
                    state.twoFactorVerify.data = null;
                    break;
                case types.VERIFY_SUCCESS:
                    state.twoFactorVerify.isLoading = false;
                    state.twoFactorVerify.error = null;
                    state.twoFactorVerify.data = action.payload.data;
                    state.isFullyRegistered = UserService.getIsFullyRegistered();
                    state.roles = UserService.getUserRoles();
                    state.userCompanies = action.payload.userCompanies;
                    state.isAuthenticated = state.userCompanies.length === 0;
                    state.userData.isLoaded = false;
                    break;
                case types.VERIFY_ERROR:
                    state.twoFactorVerify.isLoading = false;
                    state.twoFactorVerify.error = action.payload;
                    state.twoFactorVerify.data = null;
                    break;
                    case types.CLEAR_VERIFY_ERROR:
                        state.twoFactorVerify.error = null;
                        break;
                case types.LOGOUT:
                    LoginService.logout();
                    state.isAuthenticated = false;
                    state.isFullyRegistered = UserService.getIsFullyRegistered();
                    state.roles = [];
                    state.userData = {
                        isLoading: false,
                        isLoaded: false,
                        profilePictureUrl:
                            "/images/shared/default_employee.png",
                        firstName: "",
                        lastName: "",
                    };
                    state.twoFactor.isRequired = false;
                    break;
                case types.FULLY_REGISTER:
                    state.isFullyRegistered = true;
                    state.roles = UserService.getUserRoles();
                    break;
                case types.USERDATA_LOADING:
                    state.userData.isLoading = true;
                    break;
                case types.USERDATA_ERROR:
                    state.userData.isLoading = false;
                    state.userData.error = action.payload;
                    break;
                case types.USERDATA_SUCCESS:
                    state.userData = action.payload;
                    state.userData.error = null;
                    state.userData.isLoading = false;
                    state.userData.isLoaded = true;
                    break;
                case types.UPDATEUSERPICTURE_LOADING:
                    state.userProfilePictureUpdate.isLoading = true;
                    state.userProfilePictureUpdate.isCompleted = false;
                    break;
                case types.UPDATEUSERPICTURE_ERROR:
                    state.userProfilePictureUpdate.isLoading = false;
                    state.userProfilePictureUpdate.error = action.payload;
                    break;
                case types.UPDATEUSERPICTURE_SUCCESS:
                    state.userProfilePictureUpdate.isLoading = false;
                    state.userProfilePictureUpdate.isCompleted = true;
                    state.userProfilePictureUpdate.error = null;
                    state.userData.profilePictureUrl =
                        state.userData.profilePictureUrl + "?refresh=true";
                    break;
                case types.DELETEUSERPICTURE_LOADING:
                    state.userProfilePictureDelete.isLoading = true;
                    break;
                case types.DELETEUSERPICTURE_ERROR:
                    state.userProfilePictureDelete.isLoading = false;
                    state.userProfilePictureDelete.error = action.payload;
                    break;
                case types.DELETEUSERPICTURE_SUCCESS:
                    state.userProfilePictureDelete.isLoading = false;
                    state.userProfilePictureDelete.isCompleted = true;
                    state.userProfilePictureDelete.error = null;
                    state.userData.profilePictureUrl =
                        state.userData.profilePictureUrl + "?refresh=true";
                    break;
                case types.UPDATEUSERPICTURE_LEAVE:
                    state.userProfilePictureUpdate.isLoading = false;
                    state.userProfilePictureUpdate.isCompleted = false;
                    state.userProfilePictureUpdate.error = null;
                    break;
                case types.ACTIVATE_LOADING:
                    state.activation.isLoading = true;
                    state.activation.isCompleted = false;
                    state.activation.error = null;
                    break;
                case types.ACTIVATE_ERROR:
                    state.activation.isLoading = false;
                    state.activation.isCompleted = false;
                    state.activation.error = action.payload;
                    break;
                case types.ACTIVATE_SUCCESS:
                    state.activation.isLoading = false;
                    state.activation.error = null;
                    state.activation.isCompleted = action.payload;
                    break;
                case types.CHANGEPASSWORD_LOADING:
                    state.password.isLoading = true;
                    state.password.isCompleted = false;
                    state.password.error = null;
                    break;
                case types.CHANGEPASSWORD_ERROR:
                    state.password.isLoading = false;
                    state.password.isCompleted = false;
                    state.password.error = action.payload;
                    break;
                case types.CHANGEPASSWORD_SUCCESS:
                    state.password.isLoading = false;
                    state.password.error = null;
                    state.password.isCompleted = action.payload;
                    break;
                case types.CHANGEPASSWORD_LEAVE:
                    state.password.isLoading = false;
                    state.password.error = null;
                    state.password.isCompleted = false;
                    break;
                case types.SETMENUOPEN:
                    state.menuState.isOpened = action.payload;
                    break;
                case types.UPDATEUSERDATA_LOADING:
                    state.userUpdateData.isLoading = true;
                    state.userUpdateData.isCompleted = false;
                    state.userUpdateData.error = null;
                    state.userUpdateData.response = null;
                    break;
                case types.UPDATEUSERDATA_ERROR:
                    state.userUpdateData.isLoading = false;
                    state.userUpdateData.isCompleted = false;
                    state.userUpdateData.error = action.payload;
                    state.userUpdateData.response = null;
                    break;
                case types.UPDATEUSERDATA_SUCCESS:
                    state.userUpdateData.isLoading = false;
                    state.userUpdateData.isCompleted = true;
                    state.userUpdateData.error = null;
                    state.userUpdateData.response = action.payload;
                    break;
                case types.UPDATEUSERDATA_RESETSTATE:
                    state.userUpdateData = {
                        isLoading: false,
                        isCompleted: false,
                        error: null,
                        response: null,
                    };
                    break;
                default:
                    return state;
            }
        };
    },
    creators: (types) => ({
        loginLoading: () => ({ type: types.LOGIN_LOADING }),
        loginError: (error) => ({ type: types.LOGIN_ERROR, payload: error }),
        clearLoginError: (error) => ({ type: types.CLEAR_LOGIN_ERROR }),
        loginSuccess: (username, userCompanies) => ({
            type: types.LOGIN_SUCCESS,
            payload: {username,userCompanies},
        }),
        logout: () => {
            return {
                type: types.LOGOUT,
            };
        },
        userDataLoading: () => ({ type: types.USERDATA_LOADING }),
        userDataError: (error) => ({
            type: types.USERDATA_ERROR,
            payload: error,
        }),
        userDataSuccess: (userData) => ({
            type: types.USERDATA_SUCCESS,
            payload: userData,
        }),
        updateUserPictureLoading: () => ({
            type: types.UPDATEUSERPICTURE_LOADING,
        }),
        updateUserPictureError: (error) => ({
            type: types.UPDATEUSERPICTURE_ERROR,
            payload: error,
        }),
        updateUserPictureSuccess: () => ({
            type: types.UPDATEUSERPICTURE_SUCCESS,
        }),
        deleteUserPictureLoading: () => ({
            type: types.DELETEUSERPICTURE_LOADING,
        }),
        deleteUserPictureError: (error) => ({
            type: types.DELETEUSERPICTURE_ERROR,
            payload: error,
        }),
        deleteUserPictureSuccess: () => ({
            type: types.DELETEUSERPICTURE_SUCCESS,
        }),
        fullyRegister: () => ({ type: types.FULLY_REGISTER }),
        activateLoading: () => ({ type: types.ACTIVATE_LOADING }),
        activateError: (error) => ({
            type: types.ACTIVATE_ERROR,
            payload: error,
        }),
        activateSuccess: (response) => ({
            type: types.ACTIVATE_SUCCESS,
            payload: response,
        }),
        changePasswordLoading: () => ({
            type: types.CHANGEPASSWORD_LOADING,
        }),
        changePasswordError: (error) => ({
            type: types.CHANGEPASSWORD_ERROR,
            payload: error,
        }),
        changePasswordSuccess: (res) => ({
            type: types.CHANGEPASSWORD_SUCCESS,
            payload: res,
        }),
        changePasswordResetState: () => ({
            type: types.CHANGEPASSWORD_LEAVE,
        }),
        updateUserPictureResetState: () => ({
            type: types.UPDATEUSERPICTURE_LEAVE,
        }),
        toggleMenu: (isOpen) => ({
            type: types.SETMENUOPEN,
            payload: isOpen,
        }),
        updateUserLoading: () => ({ type: types.UPDATEUSERDATA_LOADING }),
        updateUserError: (error) => ({
            type: types.UPDATEUSERDATA_ERROR,
            payload: error,
        }),
        updateUserSuccess: (res) => ({
            type: types.UPDATEUSERDATA_SUCCESS,
            payload: res,
        }),
        updateUserResetState: () => ({ type: types.UPDATEUSERDATA_RESETSTATE }),
        loginTwoFactorNeeded: (username) => ({
            type: types.LOGIN_TWOFACTOR_NEEDED,
            payload: username,
        }),
        verifyLoading: () => ({ type: types.VERIFY_LOADING }),
        verifySuccess: (res,companies) => ({ type: types.VERIFY_SUCCESS, payload: {data:res, userCompanies:companies} }),
        verifyError: (err) => ({ type: types.VERIFY_ERROR, payload: err }),
        clearVerifyError: (err) => ({ type: types.CLEAR_VERIFY_ERROR }),
    }),
    actions: (creators) => ({
        login: (username, password) => {
            return async function (dispatch) {
                dispatch(creators.loginLoading());
                try {
                    const loginResult = await LoginService.login(
                        username,
                        password
                    );
                    if (!loginResult.data.twoFactor) {
                        localStorage.setItem("token", loginResult.data.token);
                        localStorage.setItem(
                            "refreshToken",
                            loginResult.data.refreshToken
                        );
                        const userCompanies = await UserService.getCompaniesByUserId();
                        dispatch(creators.loginSuccess(username, userCompanies));
                    } else {
                        dispatch(creators.loginTwoFactorNeeded(username));
                    }
                } catch (e) {
                    dispatch(creators.loginError(e));
                }
            };
        },
        verifyTwoFactor: (username, code) => {
            return async function (dispatch) {
                dispatch(creators.verifyLoading());
                try {
                    const verifyResult = await LoginService.verifyTwoFactor(
                        username,
                        code
                    );
                    localStorage.setItem("token", verifyResult.data.token);
                    localStorage.setItem(
                        "refreshToken",
                        verifyResult.data.refreshToken
                    );
                    const userCompanies = await UserService.getCompaniesByUserId();
                    dispatch(creators.verifySuccess(username,userCompanies));
                } catch (e) {
                    dispatch(creators.verifyError(e));
                }
            };
        },
        loadUserData: () => {
            return async function (dispatch) {
                dispatch(creators.userDataLoading());
                try {
                    const userApiData = await UserService.getUserDetails();
                    dispatch(creators.userDataSuccess(userApiData));
                } catch (e) {
                    dispatch(creators.userDataError(e));
                }
            };
        },
        updateUserPicture: (formData) => {
            return async function (dispatch) {
                dispatch(creators.updateUserPictureLoading());
                try {
                    await UserService.setUserPicture(formData);
                    dispatch(creators.updateUserPictureSuccess());
                } catch (e) {
                    dispatch(creators.updateUserPictureError(e));
                }
            };
        },
        deleteUserPicture: () => {
            return async function (dispatch) {
                dispatch(creators.deleteUserPictureLoading());
                try {
                    await UserService.deleteUserPicture();
                    dispatch(creators.deleteUserPictureSuccess());
                } catch (e) {
                    dispatch(creators.deleteUserPictureError(e));
                }
            };
        },
        activateUser: (data) => {
            return async function (dispatch) {
                dispatch(creators.activateLoading());
                try {
                    const activateResponse = await UserService.activate(data);
                    dispatch(creators.activateSuccess(activateResponse));
                } catch (e) {
                    dispatch(creators.activateError(e));
                }
            };
        },
        changePassword: (newPassword) => {
            return async function (dispatch) {
                dispatch(creators.changePasswordLoading());
                try {
                    const res = await UserService.setUserPassword(newPassword);
                    dispatch(creators.changePasswordSuccess(res));
                } catch (e) {
                    dispatch(creators.changePasswordError(e));
                }
            };
        },
        updateMyData: (userData) => {
            return async function (dispatch) {
                dispatch(creators.updateUserLoading());
                try {
                    const res = await UserService.setUserDetails(userData);
                    dispatch(creators.updateUserSuccess(res));
                } catch (e) {
                    dispatch(creators.updateUserError(e));
                }
            };
        },
        clearLoginErrors: () => {
            return async function (dispatch) {
                dispatch(creators.clearLoginError());
                dispatch(creators.clearVerifyError());
            };
        },
    }),
};

export default createStateModule(userStateConfig);
