import React from "react";
import styled from "styled-components";
import { buildValidation } from "../../../../../core/validations/ValidationRule";

import InputElement from "../../../../../components/elements/form-elements/InputElement";
import { Select } from "../../../../../components/elements/form-elements";
import { VSpace } from "../../../../pwa/components/StyledElements";
import * as yup from "yup";

const validDispute = (t, index) => {
    return yup.lazy((values) => {
        return buildValidation({
            [`quantity_${index}`]: yup.number().typeError("Please Enter a number").required(
                t("forms:dispute.wizard.quantityRequired")
            ),
        });
    });
};

const HR = styled.div`
    width: 100%;
    height: 1px;
    border-top: 1px solid #eee;
`;
const InfoWrapper = styled.div`
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;

    & > section {
        width: 33.3333%;
        display: flex;
        flex-direction: column;
        font-size: 12px;
        font-weight: 700;

        & > div.wrong {
            color: #f0c40d !important;
        }
    }

    & > section.double {
        width: 66.666%;
        color: #f0c40d !important;
    }

    & > section.bold {
        font-size: 14px;
        font-weight: 700;
    }

    & > section.green {
        color: ${(p) => p.theme.primaryColor};
    }
`;

const SectionTitle = styled.div`
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    color: ${(p) => p.theme.primaryColor};
`;

const SettleWrapper = styled.div`
   padding: 0px 0px 0px 25%;
    width: 100%;
    display: flex;
`;

const WizardDisputeSettle = (t, disputes) => {
    const initialValues = {};
    const steps = [];

    disputes.forEach((cyl) => {

        const index = cyl.disputeDetailId;

        initialValues[`grade_${index}`] = cyl.buyerCylinderDetails.gradeName;
        initialValues[`quantity_${index}`] = cyl.buyerCylinderDetails.extractedQuantity;
        initialValues[`id_${index}`] = cyl.disputeDetailId;

        steps.push({
            name: `${t("forms:qualityTest.cylinder")}: ${cyl.cylinderTagId}`,
            fieldNames: [
                `grade_${index}`,
                `quantity_${index}`,
                `id_${index}`,
            ],
            validation: validDispute(t, index),
            fields: (formik) => (
                <>
                    <InfoWrapper>
                        <section> </section>
                        <section className="bold">
                            <div>
                                {t("forms:dispute.wizard.qualityAssessment")}
                            </div>
                            <div>{cyl.sellerCylinderDetails.companyName}</div>
                        </section>
                        <section className="bold">
                            <div>
                                {t("sidebar:title.quality.qualityConfirmation")}
                            </div>
                            <div>{cyl.buyerCylinderDetails.companyName}</div>
                        </section>
                    </InfoWrapper>
                    <InfoWrapper>
                        <section>
                            <div>{t("forms:cylinder.cylinderGas")}:</div>
                            <div>{t("screens:technician.netQuantity")}:</div>
                            <div>
                                {t("sidebar:label.totalExtractedQuantity")}:
                            </div>
                            <div>
                                {t("sidebar:label.extraction.qualityGrade")}:
                            </div>
                        </section>
                        <section className="green">
                            <div>{cyl.sellerCylinderDetails.gasCode}</div>
                            <div>{cyl.sellerCylinderDetails.netQuantity.toFixed(2)} kg</div>
                            <div>{cyl.sellerCylinderDetails.extractedQuantity.toFixed(2)} kg</div>
                            <div>{cyl.sellerCylinderDetails.gradeName}</div>
                        </section>

                        <section className="green">
                            <div>{cyl.buyerCylinderDetails.gasCode}</div>
                            <div>{cyl.buyerCylinderDetails.netQuantity.toFixed(2)} kg</div>
                            <div
                                className={
                                    cyl.sellerCylinderDetails.extractedQuantity !== cyl.buyerCylinderDetails.extractedQuantity
                                        ? "wrong"
                                        : ""
                                }
                            >
                                {cyl.buyerCylinderDetails.extractedQuantity.toFixed(2)} kg
                            </div>
                            <div
                                className={
                                    cyl.sellerCylinderDetails.gradeName !== cyl.buyerCylinderDetails.gradeName
                                        ? "wrong"
                                        : ""
                                }
                            >
                                {cyl.buyerCylinderDetails.gradeName}
                            </div>
                        </section>
                    </InfoWrapper>
                    <InfoWrapper>
                        <section>{t("actions:dialogs.dispute.claim")}:</section>
                        <section className="double">
                            {cyl.disputeDetailView.disputeReason}
                        </section>
                    </InfoWrapper>
                    <VSpace size="16px" />
                    <HR />
                    <VSpace size="16px" />
                    <SectionTitle> {t("actions:dialogs.dispute.settlement")}:</SectionTitle>
                    <SettleWrapper>
                        <InputElement
                            labelText={t("forms:cylinder.quantity")}
                            id={`quantity_${index}`}
                            name={`quantity_${index}`}
                            type="text"
                            wrapperVariant="flex-2"
                            {...formik}
                        />
                        <Select
                            name={`grade_${index}`}
                            key={"cylinders" + 1}
                            errors={[]}
                            touched={[]}
                            defaultValue={cyl.buyerCylinderDetails.gradeName}
                            options={[
                                {
                                    key: "A",
                                    value: "A",
                                    label: "A",
                                },
                                {
                                    key: "B",
                                    value: "B",
                                    label: "B",
                                },
                                {
                                    key: "C",
                                    value: "C",
                                    label: "C",
                                },
                            ]}
                            labelText={t("sidebar:title.quality.quality")}
                            // handleChange={(e) => handleSelectChange(e)}
                            wrapperVariant="flex-2"
                            {...formik}
                        />
                    </SettleWrapper>
                </>
            ),
        });
    });

    return {
        title: t("actions:dialogs.dispute.finalize"),
        initialValues: initialValues,
        steps,
    };
};

export default WizardDisputeSettle;
