import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import Flex from "../../../../components/layout/flex/Flex";
import LayoutInnerContainer from "../../../../components/layout/split/one/LayoutInnerContainer";
import AuthorizeComponent from "../../../../core/AuthorizeComponent";
import Roles from "../../../../core/enums/Roles";
import helpers from "../../../../core/helpers";
import useBreadcrumbs from "../../../../core/state/navigation/useBreadcrumbs";
import userStateModule from "../../../../core/state/user/userStateModule";
import useStateModule from "../../../../core/state/useStateModule";
import ValidationRule, {
    buildValidation,
} from "../../../../core/validations/ValidationRule";
import CompanyService from "../../../../services/Company/CompanyService";
import UserService from "../../../../services/User/UserService";
import { Helmet } from "react-helmet";
import AccordionWrapper from "../../../../components/toggle-view/AccordionWrapper";
import DataTable from "../../../../components/data/DataTable";
import HamburgerMenu from "../../../../components/menu/HamburgerMenu";
import HamburgerMenuAction from "../../../../components/menu/HamburgerMenuAction";
import DateCell from "../../../../components/data/DateCell";
import Button from "../../../../components/elements/buttons/Button";
import ModalWizard from "../../../../components/modal/ModalWizard";
import AddCertificateWizard from "./wizard/AddCertificateWizard";
import useAccordionGroup from "../../../../hooks/_shared/useAccordionGroup";

const InfoWrapper = styled.div`
    display: flex;
    width: 100%;
    color: #000;

    & .topSpace {
        margin-top: 14px;
    }

    & .info {
        font-size: 14px;
    }

    & li {
        font-size: 12px;
        line-height: 18px;
    }
`;

const GreenInfo = styled.img`
    width: 40px;
    height: 40px;
    margin-right: 24px;
`;

const Separator = styled.div`
    width: 100%;
    height: 2px;
    background-color: #000;
    margin-top: 24px;
`;

const Spacer = styled.div`
    width: 100%;
    height: 30px;
    min-height: 30px;
`;

const RightContainer = styled.div`
    display: flex;
    justify-content: flex-end;

    margin-top: 20px;
`;

const ColoredText = styled.div`
    color: ${(p) => p.theme.primaryColor};

    &.error {
        color: ${(p) => p.theme.error};
    }
`;

export const Wrapper = styled.div`
    margin-bottom: 20px;
`;

export const FormWrapper = styled(Flex.Row)`
    width: 800px;
    margin: 30px auto;
`;

const EmptyGuid = "00000000-0000-0000-0000-000000000000";

const ECertificateStatus = {
    Submitted: 0,
    Valid: 1,
    Rejected: 2,
    Expired: 99,
    NoAttestation: -1, //display only
};

export const getCertificateValidation = (t) => {
    return buildValidation({
        fGasCertificate: ValidationRule.isStringRule().required(
            t("forms:fGasCertificate.required")
        ).rule,
        licenceNumber: ValidationRule.isStringRule().required(
            t("forms:licenceNumber.required")
        ).rule,
        licenceExpirationDate: ValidationRule.isStringRule().required(
            t("forms:licenceExpirationDate.required")
        ).rule,
    });
};

function groupBy(arr, prop) {
    const map = new Map(Array.from(arr, (obj) => [obj[prop], []]));
    arr.forEach((obj) => map.get(obj[prop]).push(obj));
    return Array.from(map.values());
}

const HolderCell = ({ cell: { row } }) => {
    const holder = row.original.certificateHolder;
    const status = row.original.licenceStatus;

    return (
        <ColoredText
            className={status !== ECertificateStatus.Valid ? "error" : ""}
        >
            {holder}
        </ColoredText>
    );
};

const StatusCell = ({ cell: { row } }) => {
    const text = row.original.licenceStatusName;
    const status = row.original.licenceStatus;

    return (
        <ColoredText
            className={status !== ECertificateStatus.Valid ? "error" : ""}
        >
            {text}
        </ColoredText>
    );
};

const CompanyCertificateScreen = () => {
    const { t } = useTranslation(["common", "forms"]);
    const history = useHistory();
    const { isFullyRegistered } = useStateModule(userStateModule);
    const userDetails = UserService.getUserDetailsFromToken();
    const userCompanyId = userDetails.CompanyId || null;
    const [companyId] = useState(userCompanyId);
    const [userCerts, setUserCerts] = useState([]);
    const [companyCerts, setCompanyCerts] = useState([]);
    const [openAccordion, setOpenAccordion] = useAccordionGroup("companyCert");
    const getCertsAsync = async () => {
        const resUsers = await CompanyService.getMyEmployeesCertificates();
        const resCompany = await CompanyService.getMyCompanyCertificates();
        if (!resUsers.error && !resCompany.error) {
            const grp = groupBy(resUsers.data.data, "userId");
            setUserCerts(grp);
            setCompanyCerts(resCompany.data.data);
        }
    };

    useEffect(() => {
        getCertsAsync();
    }, []);

    useBreadcrumbs([
        {
            text: t("navigation:company"),
        },
        {
            text: t("navigation:companyCertificates"),
        },
    ]);

    const addNewCertificate = (ev, id) => {
        ev.preventDefault();
        ModalWizard.show(
            AddCertificateWizard(t, id),
            {
                onSubmit: async (values) => {
                    if (values.licenceNeverExpires)
                        values.licenceExpirationDate = new Date("1/1/3000");
                    delete values.licenceNeverExpires;
                    if (id !== null) values.id = id;

                    let formData = helpers.getFormData(values);
                    let result;
                    result = id
                        ? await CompanyService.addUserCertificate(formData)
                        : await CompanyService.addCompanyCertificate(formData);
                    if (result && result.error) {
                        toast.error(t(result.error.message));
                        ModalWizard.submitFailed();
                    } else {
                        toast.success(
                            t("actions:certificate.toastNotification.success")
                        );
                        ModalWizard.hide();
                        getCertsAsync();
                    }
                },
                onCancel: async () => {
                    ModalWizard.hidePrompt();
                },
            },
            {
                style: {
                    minWidth: "700px",
                },
            }
        );
    };

    const certColumns = useMemo(
        () => [
            {
                Header: t("screens:certificates.tableHeaders.certHolder"),
                accessor: "certificateHolder",
                Cell: HolderCell,
            },
            {
                Header: t("screens:certificates.tableHeaders.certNumber"),
                accessor: "licenceNumber",
            },
            {
                Header: t("screens:certificates.tableHeaders.expiration"),
                accessor: "licenceExpiryDate",
                Cell: DateCell,
            },
            {
                Header: t("screens:certificates.tableHeaders.submitDate"),
                accessor: "licenceUploadDate",
                Cell: DateCell,
            },
            {
                Header: t("screens:certificates.tableHeaders.procDate"),
                accessor: "processedDate",
                Cell: DateCell,
            },
            {
                Header: t("screens:certificates.tableHeaders.status"),
                accessor: "licenceStatusName",
                Cell: StatusCell,
            },
            {
                Header: "",
                accessor: "id",
                Cell: ({ cell: { row } }) => {
                    return (
                        <AuthorizeComponent
                            roles={[
                                Roles.InstallerCompanyAdmin,
                                Roles.InstallerCompanyUser,
                            ]}
                        >
                            {row.original.licenceId !== EmptyGuid && (
                                <HamburgerMenu up={true}>
                                    <HamburgerMenuAction
                                        text={t("actions:view")}
                                        onClick={() =>
                                            window.open(
                                                row.original
                                                    .licenceCertificateFile,
                                                "_blank"
                                            )
                                        }
                                    ></HamburgerMenuAction>
                                </HamburgerMenu>
                            )}
                        </AuthorizeComponent>
                    );
                },
            },
        ],
        [t]
    );

    if (!companyId) {
        history.push("/");
        return null;
    }

    return (
        <LayoutInnerContainer className="wide">
            <Helmet>
                <title>{t("screens:certificates.companyCert")}</title>
            </Helmet>
            {isFullyRegistered && (
                <AuthorizeComponent
                    roles={[
                        Roles.InstallerCompanyAdmin,
                        Roles.InstallerCompanyUser,
                    ]}
                >
                    <AccordionWrapper
                        title={`${t("screens:certificates.companyCert")}`}
                        id="companyCert"
                        isOpen={openAccordion}
                        onToggle={setOpenAccordion}
                        variant="overflow top-space inset"
                        style={{ marginTop: "56px" }}
                        noBorder
                    >
                        <InfoWrapper>
                            <GreenInfo src="/images/menu/green-info.svg" />
                            <div>
                                <div className="info">
                                    {t("screens:certificates.companyInfo")}
                                </div>
                                <ul>
                                    <li>
                                        {t(
                                            "screens:certificates.companyList.installation"
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            "screens:certificates.companyList.maintenance"
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            "screens:certificates.companyList.recovery"
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            "screens:certificates.companyList.decommissioning"
                                        )}
                                    </li>
                                </ul>
                                <div className="info">
                                    {t(
                                        "screens:certificates.companyFullCert.info"
                                    )}
                                </div>
                                <ul>
                                    <li>
                                        {t(
                                            "screens:certificates.companyFullCert.personel"
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            "screens:certificates.companyFullCert.tools"
                                        )}
                                    </li>
                                </ul>
                            </div>
                        </InfoWrapper>

                        <Separator />
                        <DataTable columns={certColumns} data={companyCerts} />
                        <RightContainer>
                            <Button
                                className="primary--action"
                                onClick={(ev) => {
                                    addNewCertificate(ev, null);
                                }}
                            >
                                {t("screens:certificates.add")}
                            </Button>
                        </RightContainer>
                    </AccordionWrapper>

                    <AccordionWrapper
                        title={`${t("screens:certificates.personalCert")}`}
                        id="personalCert"
                        isOpen={openAccordion}
                        onToggle={setOpenAccordion}
                        variant="overflow top-space inset"
                        style={{ marginTop: "56px" }}
                        noBorder
                    >
                        <InfoWrapper>
                            <GreenInfo src="/images/menu/green-info.svg" />
                            <div>
                                <div className="info">
                                    {t(
                                        "screens:certificates.personalCertInfo.allTechnicians"
                                    )}
                                </div>
                                <div className="info">
                                    {t(
                                        "screens:certificates.personalCertInfo.requirement"
                                    )}
                                </div>
                                <div className="info topSpace">
                                    {t(
                                        "screens:certificates.personalCertInfo.qualification"
                                    )}
                                </div>
                                <div className="info topSpace">
                                    {t(
                                        "screens:certificates.personalCertInfo.regulation"
                                    )}
                                </div>
                                <ul>
                                    <li>
                                        {t(
                                            "screens:certificates.personalList.regulations"
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            "screens:certificates.personalList.emission"
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            "screens:certificates.personalList.recovery"
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            "screens:certificates.personalList.handling"
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            "screens:certificates.personalList.information"
                                        )}
                                    </li>
                                </ul>
                            </div>
                        </InfoWrapper>

                        {userCerts.map((certs, idx) => {
                            return (
                                <React.Fragment key={idx}>
                                    <Separator />
                                    <DataTable
                                        columns={certColumns}
                                        data={certs}
                                    />
                                    <RightContainer>
                                        <Button
                                            className="primary--action"
                                            onClick={(ev) => {
                                                addNewCertificate(
                                                    ev,
                                                    certs[0].userId
                                                );
                                            }}
                                        >
                                            {t("screens:certificates.add")}
                                        </Button>
                                    </RightContainer>
                                </React.Fragment>
                            );
                        })}
                    </AccordionWrapper>
                    <Spacer className="spacer" />
                </AuthorizeComponent>
            )}
        </LayoutInnerContainer>
    );
};
export default CompanyCertificateScreen;
