import React from "react";
import styled from "styled-components";

const StyledLabel = styled.label`
    margin: 0 0 5px 10px;
    transition: all 0.5s ease-in;
    color: ${(props) => props.theme.textColor};
    display: inline-block;
    font-size: ${(props) => props.theme.labelFontSize};
    font-weight: bold;

    &.tight {
        font-size: 14px;
        font-weight: normal;
    }
    &.sidebar {
        margin: 0 7px 0 5px;
        font-weight: bold;
    }

    &.error {
        color: ${(props) => props.theme.errorColor};
    }

    &.checkbox {
        vertical-align: bottom;
    }

    &.spaced {
        margin-bottom: 10px;
        display: block;
    }
    &.sr-only {
        border: 0 !important;
        clip: rect(1px, 1px, 1px, 1px) !important; /* 1 */
        -webkit-clip-path: inset(50%) !important;
        clip-path: inset(50%) !important; /* 2 */
        height: 1px !important;
        margin: -1px !important;
        overflow: hidden !important;
        padding: 0 !important;
        position: absolute !important;
        width: 1px !important;
        white-space: nowrap !important;
    }

    &.grade {
        vertical-align: top;
        margin: -2px 0px 2px 5px;
    }

    &.center {
        margin: 0 8px;
        align-self: center;
    }
`;

const Label = (props) => {
    return (
        <StyledLabel htmlFor={props.htmlFor} className={props.className}>
            {props.children}
        </StyledLabel>
    );
};

export default Label;
