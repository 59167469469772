import { useLoadScript } from "@react-google-maps/api";
import React from "react";
import { Switch } from "react-router-dom";
import PrivateRoute from "../../../core/PrivateRoute";
import RouteCreator from "../../../core/RouteCreator";
import ClientLocationsListing from "./components/ClientLocationsListing";
import ClientLocationsUnitListing from "./components/ClientLocationsUnitListing";
import ClientsListing from "./components/ClientsListing";

const ClientsSection = () => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyBQSju7sxgnuD4jrzA2cJLP2dVU3RDwHZY", // ,
        // ...otherOptions
    });

    return (
        isLoaded && (
            <Switch>
                <PrivateRoute
                    path={RouteCreator.clients.units(
                        ":clientId",
                        ":locationId"
                    )}
                    component={ClientLocationsUnitListing}
                ></PrivateRoute>
                <PrivateRoute
                    path={RouteCreator.clients.locations(":clientId")}
                    component={ClientLocationsListing}
                ></PrivateRoute>

                <PrivateRoute
                    path={RouteCreator.clients.root()}
                    component={ClientsListing}
                ></PrivateRoute>
            </Switch>
        )
    );
};
export default ClientsSection;
