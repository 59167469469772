import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ListingPage from "../../../../components/data/ListingPage";
import NullableCell from "../../../../components/data/NullableCell";
import companiesListStateModule from "../../../../core/state/admin/companies/companiesListStateModule";
import useBreadcrumbs from "../../../../core/state/navigation/useBreadcrumbs";
import useStateModule from "../../../../core/state/useStateModule";
import useImmerReducer from "../../../../hooks/_shared/useImmerReducer";
import usePageChange from "../../../../hooks/_shared/usePageChange";
import ActionsCell from "./components/ActionsCell";
import CompanyDetailSideBar from "./components/CompanyDetailSideBar";
import CompanyFilter from "./components/CompanyFilter";
import actionTypes from "./components/filterCompanyActionTypes";
import StatusCell from "./components/StatusCell";
import PartnerStatusCell from "./components/PartnerStatusCell";
import { Helmet } from "react-helmet";
import useQueryString from "../../../../hooks/_shared/useQueryString";
import ModalWizard from "../../../../components/modal/ModalWizard";
import PartnerStatusWizard from "./components/PartnerStatusWizard";
import RegistrationService from "../../../../services/Registration/RegistrationService";
import { toast } from "react-toastify";

const filterReducer = (state, action) => {
    if (action.type !== actionTypes.SET_PAGING) state.page = 1;
    switch (action.type) {
        case actionTypes.SEARCH_EMAIL:
            state.filter.email = action.payload;
            break;
        case actionTypes.SEARCH_FULLNAME:
            state.filter.fullname = action.payload;
            break;
        case actionTypes.SEARCH_COMPANYNAME:
            state.filter.companyName = action.payload;
            break;
        case actionTypes.SEARCH_VAT:
            state.filter.vatNumber = action.payload;
            break;
        case actionTypes.SEARCH_LICENCENUMBER:
            state.filter.licenceNumber = action.payload;
            break;
        case actionTypes.SEARCH_STATUS:
            state.filter.status = action.payload;
            break;
        case actionTypes.SEARCH_STATUS_RESET:
            state.filter.status = "";
            break;
        case actionTypes.SEARCH_PARTNER_STATUS:
            state.filter.partnerStatus = action.payload;
            break;
        case actionTypes.SEARCH_PARTNER_STATUS_RESET:
            state.filter.partnerStatus = "";
            break;
        case actionTypes.SET_PAGING:
            state.page = action.payload.pageIndex + 1;
            state.pageSize = action.payload.pageSize;
            break;
        case actionTypes.SET_SORT:
            state.orderColumn = action.payload[0].id;
            state.descending = action.payload[0].desc;
            break;
        case actionTypes.SET_COUNTRY:
            state.filter.countryId = action.payload;
            break;
        case actionTypes.RESET_COUNTRY:
            delete state.filter.countryId;
            break;
        default:
            return state;
    }
};

const CompanyListing = ({ companyType }) => {
    const {
        companies,
        isLoading,
        error,
        loadCompanies,
        loadCompanyById,
        details,
        deleteCompany,
        deleteData,
    } = useStateModule(companiesListStateModule);
    const { t } = useTranslation("screens");

    const status = useQueryString("status");
    const activeStatus = useQueryString("activeStatus");

    useBreadcrumbs([
        {
            text: t("navigation:companies"),
        },
        {
            text:
                companyType === 0
                    ? t("navigation:installationPartners")
                    : t("navigation:distributors"),
        },
    ]);

    const [detailViewIsOpened, setDetailViewIsOpened] = useState(false);
    const [detailViewKey, setDetailViewKey] = useState(null);

    const [companyState, dispatch] = useImmerReducer(filterReducer, {
        filter: {
            email: "",
            fullName: "",
            companyName: "",
            vatNumber: "",
            licenceNumber: "",
            status: status ? status.toString() : "",
            activeStatus: activeStatus ? activeStatus : null,
            companyType: companyType,
        },
        page: 1,
        pageSize: 10,
        orderColumn: "companyName",
        descending: false,
    });

    useEffect(() => {
        loadCompanies(companyState);
    }, [loadCompanies, companyState]);

    const reloadCompanies = () => loadCompanies(companyState);

    const columns = useMemo(
        () => [
            {
                Header: t("companyListing.headers.companyName"),
                accessor: "companyName",
            },
            // {
            //     Header: t("companyListing.headers.companyCreator"),
            //     accessor: "fullName",
            // },
            {
                Header: t("companyListing.headers.email"),
                accessor: "email",
            },
            {
                Header: t("companyListing.headers.vat"),
                accessor: "vatNumber",
            },
            {
                Header: t("companyListing.headers.licenseNumber"),
                accessor: "companyLicenceNumber",
                Cell: NullableCell,
                disableSortBy: true,
            },
            {
                Header: t("companyListing.headers.registrationStatus"),
                accessor: "userStatus",
                Cell: StatusCell,
            },
            {
                Header: t("companyListing.headers.partnerStatus"),
                accessor: "partnerStatus",
                Cell: PartnerStatusCell,
            },
            {
                id: t("companyListing.headers.actions"),
                accessor: (row) => {
                    return {
                        companyId: row.companyId,
                        companyCreatorId: row.companyCreatorId,
                        userStatus: row.userStatus,
                        partnerStatus: row.partnerStatus,
                        companyName: row.companyName,
                    };
                },
                Cell: (cell) => {
                    return ActionsCell(
                        cell.cell.value.companyCreatorId,
                        cell.cell.value.userStatus,
                        reloadCompanies,
                        () => {
                            deleteCompany(cell.cell.value.companyId).then(() => {
                                reloadCompanies();
                            });
                        },
                        deleteData.isLoading,
                        true,
                        cell.cell.value.partnerStatus,
                        changePartnerStatus,
                        cell.cell.value.companyId,
                        cell.cell.value.companyName
                    );
                },
                disableSortBy: true,
            },
        ],
        //eslint-disable-next-line
        [t, companyState.page]
    );

    const changePartnerStatus = async (companyId, companyName, partnerStatus) => {
        const res = await RegistrationService.getPartnerStatus(companyId);
        const status = res.data.data[0];
        const partnerInitialValues = {
            daikinPartnerStatus: status.daikinPartnerStatus === 2 ? "2" : "1",
            sapNumber: status.sapNumber || "",
            existsInSap: status.existsInSap && status.sapNumber,
            hasVip4Set: status.hasVip4Set,
            sendEmailNotification: false,
        };
        ModalWizard.show(PartnerStatusWizard(t, partnerInitialValues, companyName), {
            onSubmit: async (values) => {
                values.daikinPartnerStatus = Number(values.daikinPartnerStatus);
                values.companyId = companyId;
                const result = await RegistrationService.setPartnerStatus(values);
                if (result && result.error) {
                    toast.error(t(result.error.message));
                } else {
                    toast.success(t("forms:partners.wizard.success"));
                    ModalWizard.hide();
                    loadCompanies(companyState);
                }
            },
            onCancel: async () => {
                ModalWizard.hidePrompt();
            },
        });
    };

    const onSelect = useCallback(
        (row) => {
            const selectRow = async (row) => {
                setDetailViewKey(row.original.companyId);
                await loadCompanyById(row.original.companyId);
                setDetailViewIsOpened(true);
            };
            selectRow(row);
        },
        [loadCompanyById]
    );

    const closeSideBar = () => {
        setDetailViewKey(null);
        setDetailViewIsOpened(false);
    };

    const pageChange = usePageChange(dispatch, companyState);

    const sortChange = (sortBy) => {
        if (sortBy.length > 0) {
            dispatch({ type: "SET_SORT", payload: sortBy });
        }
    };
    const currentSortBy = useMemo(() => {
        return [{ id: companyState.orderColumn, desc: companyState.descending }];
    }, [companyState.orderColumn, companyState.descending]);

    return (
        <>
            <Helmet>
                <title>{t("seo:companyListing.title")}</title>
            </Helmet>
            <ListingPage
                columns={columns}
                tableState={{
                    data: companies.data,
                    loading: isLoading,
                    error: error,
                }}
                paging={{
                    enablePaging: true,
                    pageCount: companies.totalPages,
                    pageSize: companies.pageSize,
                    onPageChange: pageChange,
                    totalRows: companies.totalRows,
                    currentPageIndex: companies.currentPage - 1,
                }}
                sorting={{
                    enableSorting: true,
                    onSortByChange: sortChange,
                    currentSortBy: currentSortBy,
                }}
                onSelect={onSelect}
                closeSideBar={closeSideBar}
                detailViewKey={detailViewKey}
                detailView={<CompanyDetailSideBar company={details} />}
                detailViewIsOpened={detailViewIsOpened}
                filterTitle={t("filters:adminCompanyListing.filterTitle")}
                filterComponent={() => (
                    <CompanyFilter filterState={companyState} dispatch={dispatch} />
                )}
            />
        </>
    );
};

export default CompanyListing;
