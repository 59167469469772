import { useEffect, useState, useMemo } from "react";
import useImmerReducer from "../../../../hooks/_shared/useImmerReducer";
import usePageChange from "../../../../hooks/_shared/usePageChange";
import StockService from "../../../../services/Stock/StockService";

const actionTypes = {
    SET_GAS_TYPE: "SET_GAS_TYPE",
    RESET_GAS_TYPE: "RESET_GAS_TYPE",
    SET_GAS_QUANTITY_FROM: "SET_GAS_QUANTITY_FROM",
    RESET_GAS_QUANTITY_FROM: "RESET_GAS_QUANTITY_FROM",
    SET_GAS_QUANTITY_TO: "SET_GAS_QUANTITY_TO",
    RESET_GAS_QUANTITY_TO: "RESET_GAS_QUANTITY_TO",
    SET_OFFER_CREATION__DATE_FROM: "SET_OFFER_CREATION__DATE_FROM",
    RESET_OFFER_CREATION__DATE_FROM: "RESET_OFFER_CREATION__DATE_FROM",
    SET_OFFER_CREATION__DATE_TO: "SET_OFFER_CREATION__DATE_TO",
    RESET_OFFER_CREATION__DATE_TO: "RESET_OFFER_CREATION__DATE_TO",
    SET_OFFER_STATUS: "SET_OFFER_STATUS",

    SET_SORT: "SET_SORT",
    RESET_SORT: "RESET_SORT",
    RESET_FILTERS: "RESET_FILTERS",
    SET_PAGING: "SET_PAGING",
};

const reducer = (state, action) => {
    if (
        (action.type !== actionTypes.SET_PAGING) 
    )
        state.page = 1;
    switch (action.type) {
        case "SET_PAGING":
            state.page = action.payload.pageIndex + 1;
            state.pageSize = action.payload.pageSize;
            break;
        case "SET_SORT":
            state.orderColumn = action.payload[0].id;
            state.descending = action.payload[0].desc;
            break;
        case "RESET_SORT":
            state.orderColumn = "";
            state.descending = true;
            break;
        case actionTypes.RESET_FILTERS:
                state.filter={};
            break;
        case actionTypes.SET_GAS_TYPE:
            state.filter.gasType = action.payload;
            break;
        case actionTypes.RESET_GAS_TYPE:
            delete state.filter.gasType;
            break;
        case actionTypes.SET_GAS_QUANTITY_FROM:
            state.filter.gasQuantityFrom = Number(action.payload);
            break;
        case actionTypes.RESET_GAS_QUANTITY_FROM:
            delete state.filter.gasQuantityFrom;
            break;
        case actionTypes.SET_GAS_QUANTITY_TO:
            state.filter.gasQuantityTo = Number(action.payload);
            break;
        case actionTypes.RESET_GAS_QUANTITY_TO:
            delete state.filter.gasQuantityTo;
            break;
        case actionTypes.SET_OFFER_CREATION__DATE_FROM:
            state.filter.dateFrom = action.payload;
            break;
        case actionTypes.RESET_OFFER_CREATION__DATE_FROM:
            delete state.filter.dateFrom;
            break;
        case actionTypes.SET_OFFER_CREATION__DATE_TO:
            state.filter.dateTo = action.payload;
            break;
        case actionTypes.RESET_OFFER_CREATION__DATE_TO:
            delete state.filter.dateTo;
            break;
        case actionTypes.SET_OFFER_STATUS:
            state.reclaimedOfferStatus = Number(action.payload);
            break;
        default:
            break;
    }
};

const useReclaimedMarketFilterState = (reclaimedOfferStatus=0) => {
    const [filterState, dispatch] = useImmerReducer(reducer, {
        filter: {},
        reclaimedOfferStatus: reclaimedOfferStatus,
        page: 1,
        pageSize: 10,
        orderColumn: "offerDateTime",
        descending: false,
        totalPages: 1,
    });

    const [gasTypes, setGasTypes] = useState([]);

    const sortChange = (sortBy) => {
        sortBy.length > 0
            ? dispatch({ type: actionTypes.SET_SORT, payload: sortBy })
            : dispatch({ type: actionTypes.RESET_SORT });
    };

    const currentSortBy = useMemo(() => {
        return [{ id: filterState.orderColumn, desc: filterState.descending }];
    }, [filterState.orderColumn, filterState.descending]);

    useEffect(() => {
        const getGasTypes = async () => {
            try {
                setGasTypes(await StockService.getRefrigerantTypes());
            } catch (ex) {}
        };
        getGasTypes();
    }, []);

    const pageChange = usePageChange(dispatch, filterState);

    return [
        filterState,
        dispatch,
        actionTypes,
        sortChange,
        currentSortBy,
        pageChange,
        gasTypes,
    ];
};

export default useReclaimedMarketFilterState;
