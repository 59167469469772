import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Value = styled.label`
    font-weight: 700;
`;


const UboOwnerCell = ({ cell: { row } }) => {
    const { t } = useTranslation("table");
    if (row) {
        return <Value>{`${t('headers.ubo.uboOwner')} ${row.index + 1}`}</Value>;
    }
    return null;
};
export default UboOwnerCell;
