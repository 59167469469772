import React from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import * as yup from "yup";
import SingleDatePicker from "../../../../../components/dateTime/SingleDatePicker";
import InputElement from "../../../../../components/elements/form-elements/InputElement";
import Flex from "../../../../../components/layout/flex/Flex";
import {
    ErrorMessage,
    InfoText,
    InfoTitle,
    InfoWrapper,
    RoundInfo,
} from "../../../../../components/modal/components/WizardStyling";
import ValidationRule, { buildValidation } from "../../../../../core/validations/ValidationRule";
import ClientsService from "../../../../../services/Clients/ClientsService";
import CompanyService from "../../../../../services/Company/CompanyService";
import TagsService from "../../../../../services/Tags/TagsService";
import WizardClientDetails, {
    WizardCylinderDetails,
} from "../../../../Clients/views/components/details/WizardClientDetails";
import GradeCheckboxes from "./GradeCheckboxes";
import PresumedGradeLabel from "./PresumedGradeLabel";
import { ValidationError, Radio } from "../../../../../components/elements/form-elements";
import MoneyInputElement from "../../../../../components/elements/form-elements/MoneyInputElement";

const CliCylWrapper = styled.div`
    display: flex;
    width: 100%;
    & > * {
        width: 50%;
    }
`;

const Row = styled.div`
    display: flex;
    width: 100%;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    &.left {
        margin-right: 50px;
        flex: 1;
    }
    &.right {
        width: 150px;
    }
    & img {
        width: 150px;
        height: 95px;
        object-fit: cover;
    }
`;

const Header = styled.div`
    font-size: 16px;
    font-weight: bold;
    color: #48b05e;
    margin-bottom: 12px;
`;

const Props = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    font-weight: bold;
    color: #000;

    & span:last-child {
        text-align: right;
        color: #48b05e;
        font-weight: normal;
    }
`;

const RadioTitle = styled.div`
    font-size: 14px;
    font-weight: bold;
    margin: 32px 0 20px 10px;
`;

const defaultInitialValues = {
    quantity: "0.00",
    pressumedGrade: 0,
    extractionDateTime: new Date(),
    countryId: "",
    street: "",
    city: "",
    postalCode: "",
    region: "",
    gradeA: false,
    gradeB: false,
    gradeC: false,
    unitTagId: "",
    cylinderTagId: "",
    capacity: "",
    applicationTypeName: "",
    manufacturerName: "",
    refrigerantName: "",
    cylinderFull: false,
    cylinderSize: 0,
    cylinderSizeExceeded: false,
    unitError: "",
    unitGasType: "",
    cylQualityMatch: true,
    assessment: "0",
};

const getRealGrade = (values) => {
    if (values.gradeC) {
        return "C";
    } else if (values.gradeB) {
        return "B";
    } else {
        return "A";
    }
};

const validUnitTagId = (t) => {
    return buildValidation({
        unitTagId: ValidationRule.isStringRule()
            .isUUID(t("forms:unit.uuid.invalid"))
            .required(t("forms:unit.unitTagId.required")).rule,
    });
};

const validCylinderTagId = (t) => {
    return buildValidation({
        cylinderTagId: ValidationRule.isStringRule()
            .isUUID(t("forms:unit.uuid.invalid"))
            .required(t("forms:unit.unitTagId.required")).rule,
        extractionDateTime: ValidationRule.isStringRule().required(
            t("forms:extractionDate.required")
        ).rule,
    });
};

const validUnit = (t) => {
    return buildValidation({
        unitError: yup.string().length(0, "error"),
    });
};

const validCylinder = (t) => {
    return buildValidation({
        cylinderFull: yup
            .boolean()
            .required()
            .oneOf([false], t("exceptions:validation.CylinderFull")),
        quantity: ValidationRule.moreThan(
            0,
            t("forms:quantity.invalidNumber"),
            t("forms:quantity.largerThenZero")
        ).rule,
    });
};

const validGrades = (t) =>
    yup.lazy((values) => {
        if (values.assessment === "1")
            return buildValidation({
                analyzerTagId: ValidationRule.isStringRule()
                    .isUUID(t("forms:unit.uuid.invalid"))
                    .required(t("forms:unit.unitTagId.required")).rule,
            });
        return buildValidation({
            gradeA: ValidationRule.boolean().required("A").rule,
            gradeB: ValidationRule.boolean().required("B").rule,
            gradeC: ValidationRule.boolean().required("C").rule,
            cylQualityMatch: yup.boolean().required().oneOf([true]),
            cylinderSizeExceeded: yup
                .boolean()
                .required()
                .oneOf([false], t("exceptions:validation.CylinderCannotHaveMoreGas")),
        });
    });

const roundToNearestMinute = (date) => {
    var coeff = 1000 * 60 * 1; // <-- Replace {1} with interval
    return new Date(Math.round(date.getTime() / coeff) * coeff);
};

const NewExtractionWizard = (
    t,
    manufacturers,
    refrigerants,
    searchFn = () => {},
    cylinderSearchFn = () => {},
    analyzerSearchFn = () => {}
) => {
    return {
        title: t("forms:extraction.wizard.title"),
        initialValues: defaultInitialValues,
        steps: [
            {
                name: t("forms:extraction.wizard.title_step1"),
                fieldNames: ["unitTagId"],
                validation: validUnitTagId(t),
                fields: (formik) => {
                    return (
                        <>
                            <InfoWrapper>
                                <RoundInfo alt="" src="/images/menu/green-info.svg" />
                                <div>
                                    <InfoTitle>
                                        {t("forms:extraction.wizard.unitInfoTitle")}
                                    </InfoTitle>
                                    <InfoText>
                                        {t("forms:extraction.wizard.unitInfoText")}
                                        <br />
                                        {t("forms:extraction.wizard.unitInfoText2")}
                                    </InfoText>
                                </div>
                            </InfoWrapper>
                            <InputElement
                                labelText={t("forms:unitTagId.text")}
                                id="unitTagId"
                                name="unitTagId"
                                type="search"
                                searchFn={searchFn}
                                wrapperVariant="flex-1"
                                {...formik}
                            />
                        </>
                    );
                },
            },
            {
                name: t("forms:extraction.wizard.title_step2"),
                fieldNames: [
                    "manufacturerId",
                    "applicationTypeName",
                    "refrigerantId",
                    "capacity",
                    "countryId",
                    "street",
                    "city",
                    "postalCode",
                    "region",
                    "unitError",
                    "unitGasType",
                ],
                onStepRender: async (props) => {
                    const tagId = props.value["unitTagId"];
                    let tagDetails = await TagsService.identifyTag(tagId);

                    if (tagDetails.length === 0) {
                        props.updatePropertyBag({
                            unitError: t("forms:extraction.wizard.unitNotFound"),
                            clientData: {},
                        });
                        props.formik.setFieldValue("unitError", "error");
                        return;
                    }
                    let currentUnit;
                    if (tagDetails && tagDetails.length > 0) {
                        currentUnit = await ClientsService.getUnitByIdAsync(tagDetails[0].unitId);
                    }
                    if (currentUnit && currentUnit.error) {
                        toast.error(t(currentUnit.error.message));
                    } else {
                        const filteredManufacturer = manufacturers.filter(
                            (m) => m.id === currentUnit.data.data[0].manufacturerId
                        );
                        const manufacturerName = filteredManufacturer
                            ? filteredManufacturer[0].name
                            : "";
                        const filteredRefrigerant = refrigerants.gasTypes.filter(
                            (r) => r.id === currentUnit.data.data[0].refrigerantId
                        );
                        const refrigerantCode = filteredRefrigerant
                            ? filteredRefrigerant[0].code
                            : "";

                        props.updatePropertyBag({
                            unitError: "",
                            manufacturerId: currentUnit.data.data[0].manufacturerId,
                            refrigerantId: currentUnit.data.data[0].refrigerantId,
                            applicationTypeName: currentUnit.data.data[0].applicationTypeName,
                            capacity: currentUnit.data.data[0].capacity,
                            clientData: {
                                data: currentUnit.data.data[0].client,
                            },
                            description: currentUnit.data.data[0].description || "",
                            image: currentUnit.data.data[0]?.images[0]?.imageUrl || "",
                        });
                        props.formik.setFieldValue(
                            "unitGasType",
                            currentUnit.data.data[0].refrigerantCode
                        );
                        props.formik.setFieldValue("manufacturerName", manufacturerName);
                        props.formik.setFieldValue(
                            "manufacturerId",
                            currentUnit.data.data[0].manufacturerId
                        );
                        props.formik.setFieldValue("refrigerantName", refrigerantCode);
                        props.formik.setFieldValue(
                            "refrigerantId",
                            currentUnit.data.data[0].refrigerantId
                        );
                        props.formik.setFieldValue(
                            "applicationTypeName",
                            currentUnit.data.data[0].applicationTypeName
                        );
                        props.formik.setFieldValue("capacity", currentUnit.data.data[0].capacity);

                        if (currentUnit.data.data[0].client) {
                            props.formik.setFieldValue(
                                "countryId",
                                currentUnit.data.data[0].client.countryId
                            );
                            props.formik.setFieldValue(
                                "street",
                                currentUnit.data.data[0].client.street
                            );
                            props.formik.setFieldValue(
                                "city",
                                currentUnit.data.data[0].client.city
                            );
                            props.formik.setFieldValue(
                                "postalCode",
                                currentUnit.data.data[0].client.postalCode
                            );
                            props.formik.setFieldValue(
                                "region",
                                currentUnit.data.data[0].client.region
                            );
                        }
                    }
                },
                validation: validUnit(t),
                fields: (formik, propertyBag) => {
                    return (
                        <>
                            {propertyBag.unitError && (
                                <>
                                    <ValidationError
                                        touched={true}
                                        error={propertyBag.unitError}
                                        isFieldError={false}
                                        className="small"
                                    />

                                    <InputElement
                                        labelText=""
                                        name="unitError"
                                        id="unitError"
                                        type="text"
                                        wrapperVariant="hide"
                                    />
                                </>
                            )}

                            <Row>
                                <Column className="left">
                                    <Header>{t("screens:technician.unit")}</Header>
                                    <Props>
                                        <span>{t("forms:manufacturer.text")}</span>
                                        <span>{formik.values["manufacturerName"]}</span>
                                    </Props>
                                    <Props>
                                        <span>{t("forms:application.text")}</span>
                                        <span>{formik.values["applicationTypeName"]}</span>
                                    </Props>
                                    <Props>
                                        <span>{t("forms:gasType.text")}</span>
                                        <span>{formik.values["refrigerantName"]}</span>
                                    </Props>
                                    <Props>
                                        <span>{t("formdefaultInitialValuess:capacity.text")}</span>
                                        <span>{formik.values["capacity"]} kW</span>
                                    </Props>
                                </Column>
                                <Column className="right">
                                    <Header>{t("screens:technician.gallery")}</Header>
                                    <img alt="gallery" src={propertyBag?.image || ""} />
                                </Column>
                            </Row>

                            <Row>
                                <Column className="left">
                                    <Header>{t("screens:technician.installationSite")}</Header>
                                    <Props>
                                        <span>{t("screens:technician.company")}</span>
                                        <span>
                                            {propertyBag?.clientData?.data?.clientName || ""}
                                        </span>
                                    </Props>
                                    <Props>
                                        <span>{t("screens:technician.address")}</span>
                                        <span>{propertyBag?.clientData?.data?.street || ""}</span>
                                    </Props>
                                    <Props>
                                        <span>{t("screens:technician.city")}</span>
                                        <span>{propertyBag?.clientData?.data?.city || ""}</span>
                                    </Props>
                                    <Props>
                                        <span>{t("screens:technician.postal")}</span>
                                        <span>
                                            {propertyBag?.clientData?.data?.postalCode || ""}
                                        </span>
                                    </Props>
                                    <Props>
                                        <span>{t("screens:technician.country")}</span>
                                        <span>
                                            {propertyBag?.clientData?.data?.countryName || ""}
                                        </span>
                                    </Props>
                                </Column>
                                <Column className="right">
                                    <Header>{t("screens:technician.notes")}</Header>
                                    <span style={{ fontSize: "12px" }}>
                                        {propertyBag?.description || "-"}
                                    </span>
                                </Column>
                            </Row>
                        </>
                    );
                },
            },
            {
                name: t("forms:cylinder.wizard.pwaStep1"),
                fieldNames: ["cylinderTagId"],
                validation: validCylinderTagId(t),
                onStepSubmit: async ({ values, updatePropertyBag }) => {
                    const cylinderId = values["cylinderTagId"];
                    let tagDetails = await TagsService.identifyTag(cylinderId);
                    let currentCylinder;
                    if (tagDetails && tagDetails.length > 0) {
                        currentCylinder = await CompanyService.getCompanyCylinderByIdAsync(
                            tagDetails[0].cylinderId
                        );
                    }
                    if (currentCylinder.error) {
                        toast.error(t(currentCylinder.error.message));
                        return;
                    } else {
                        if (
                            currentCylinder.data.data[0].refrigerantTypeCode &&
                            currentCylinder.data.data[0].refrigerantTypeCode !== values.unitGasType
                        ) {
                            toast.error(t("forms:extraction.wizard.actionGasMismatch"));
                            return false;
                        }
                    }
                    updatePropertyBag({ cylinder: currentCylinder?.data?.data[0] || {} });
                    return true;
                },
                fields: (formik) => (
                    <>
                        <InfoWrapper>
                            <RoundInfo alt="" src="/images/menu/green-info.svg" />
                            <div>
                                <InfoTitle>
                                    {t("forms:extraction.wizard.cylinderInfoTitle")}
                                </InfoTitle>
                                <InfoText>
                                    {t("forms:extraction.wizard.cylinderInfoText")}
                                    <br />
                                    {t("forms:extraction.wizard.cylinderInfoText2")}
                                </InfoText>
                            </div>
                        </InfoWrapper>
                        <InputElement
                            labelText={t("forms:cylinderTagId.text")}
                            id="cylinderTagId"
                            name="cylinderTagId"
                            type="search"
                            searchFn={cylinderSearchFn}
                            wrapperVariant="flex-1"
                            {...formik}
                        />
                    </>
                ),
            },
            {
                name: t("screens:technician.cylinderInfo"),
                fieldNames: [],
                fields: (formik, propertyBag) => {
                    return (
                        <>
                            <Row>
                                <Column className="left">
                                    <Header>
                                        {t("sidebar:title.extraction.extractionCylinder")}
                                    </Header>
                                    <Props>
                                        <span>{t("forms:cylinderSize.text")}</span>
                                        <span>{propertyBag.cylinder?.size} kg</span>
                                    </Props>
                                    <Props>
                                        <span>{t("screens:technician.gas")}</span>
                                        <span>
                                            {propertyBag.cylinder?.refrigerantTypeCode ||
                                                t("screens:equipment.emptyCylinder")}
                                        </span>
                                    </Props>
                                    <Props>
                                        <span>{t("screens:technician.Quantity")}</span>
                                        <span>{propertyBag.cylinder?.filledQuantity} kg</span>
                                    </Props>
                                </Column>
                                <Column className="right">
                                    <Header>{t("screens:technician.gallery")}</Header>
                                    <img
                                        alt="gallery"
                                        src={propertyBag?.cylinder?.imageUrl || ""}
                                    />
                                </Column>
                            </Row>

                            <Row>
                                <Column className="left"></Column>
                                <Column className="right">
                                    <Header>{t("screens:technician.notes")}</Header>
                                    <span style={{ fontSize: "12px" }}>
                                        {propertyBag?.description || "-"}
                                    </span>
                                </Column>
                            </Row>
                        </>
                    );
                },
            },
            {
                name: t("forms:extraction.wizard.title_step5"),
                validation: validCylinder(t),
                fieldNames: ["cylinderFull", "quantity", "extractionDateTime", "assessment"],
                onStepRender: async (props) => {
                    props.formik.setFieldValue(
                        "extractionDateTime",
                        roundToNearestMinute(new Date())
                    );
                    const cylinderId = props.value["cylinderTagId"];
                    let tagDetails = await TagsService.identifyTag(cylinderId);
                    let currentCylinder;
                    if (tagDetails && tagDetails.length > 0) {
                        currentCylinder = await CompanyService.getCompanyCylinderByIdAsync(
                            tagDetails[0].cylinderId
                        );
                    }
                    if (currentCylinder.error) {
                        toast.error(t(currentCylinder.error.message));
                    } else if (
                        currentCylinder &&
                        currentCylinder.data.data[0].filledQuantity >=
                            currentCylinder.data.data[0].size
                    ) {
                        props.updatePropertyBag({ cylinderFull: true });
                        props.formik.setFieldValue("cylinderFull", true);
                    } else {
                        const hasAnalysis = currentCylinder.data.data[0].hasAnalysis;
                        props.updatePropertyBag({
                            cylinderTagId: currentCylinder.data.data[0].id,
                            cylinderFull: false,
                            cylinderSize: currentCylinder.data.data[0].size,
                            filledQuantity: currentCylinder.data.data[0].filledQuantity,
                            hasAnalysis,
                        });
                        props.updatePropertyBag({
                            cylinderGasQuality: currentCylinder.data.data[0].refrigerantGradeName,
                        });
                        props.formik.setFieldValue("cylinderFull", false);
                        props.formik.setFieldValue(
                            "cylinderTagId",
                            currentCylinder.data.data[0].id
                        );
                        if (hasAnalysis) {
                            props.formik.setFieldValue("assessment", "1");
                        }
                    }
                },
                onStepSubmit: async ({ values, propertyBag }) => {
                    if (
                        parseFloat(values.quantity) + parseFloat(propertyBag.filledQuantity) >
                        propertyBag.cylinderSize
                    ) {
                        toast.error(t("exceptions:validation.CylinderCannotHaveMoreGas"));
                        return false;
                    }
                },
                fields: (formik, propertyBag) => {
                    return (
                        <>
                            <InfoWrapper>
                                <RoundInfo alt="" src="/images/menu/green-info.svg" />
                                <div>
                                    <InfoTitle>
                                        {t("forms:extraction.wizard.extractionDetailsTitle")}
                                    </InfoTitle>
                                    <InfoText>
                                        {t("forms:extraction.wizard.extractionDetailsText")}
                                    </InfoText>
                                </div>
                            </InfoWrapper>
                            {propertyBag.cylinderFull && (
                                <ErrorMessage>
                                    {t("exceptions:validation.CylinderFull")}
                                </ErrorMessage>
                            )}

                            <Flex.Container flexWrap="wrap">
                                <InputElement
                                    labelText=""
                                    name="cylinderFull"
                                    id="cylinderFull"
                                    type="text"
                                    wrapperVariant="flex-2 hide"
                                    disabled={propertyBag.cylinderFull}
                                    {...formik}
                                />
                                <MoneyInputElement
                                    labelText={t("forms:quantity.text")}
                                    currencyLabel="kg"
                                    name="quantity"
                                    id="quantity"
                                    type="number"
                                    step="0.01"
                                    value={formik.values[`quantity`]}
                                    wrapperVariant="flex-2 standard"
                                    formik={formik}
                                />
                                <SingleDatePicker
                                    labelText={t("forms:extractionDate.text")}
                                    name="extractionDateTime"
                                    mode="datetime"
                                    selected={formik.values["extractionDateTime"]}
                                    currentDate={formik.values["extractionDateTime"]}
                                    wrapperVariant="flex-2"
                                    formik={formik}
                                    filter="past"
                                    disabled={propertyBag.cylinderFull}
                                    {...formik}
                                />
                            </Flex.Container>

                            <Flex.Column>
                                <RadioTitle>
                                    {t("forms:extraction.wizard.qualityAssessment")}
                                </RadioTitle>
                                <Radio
                                    labelText={t("forms:extraction.wizard.selfAssessment")}
                                    wrapperVariant="flex-1"
                                    name="assessment"
                                    value="0"
                                    secondary
                                    disabled={propertyBag.hasAnalysis}
                                    onChange={(e) => {
                                        formik.setFieldValue("assessment", "0");
                                    }}
                                    checked={formik.values["assessment"] === "0"}
                                ></Radio>
                                <Radio
                                    labelText={t("forms:extraction.wizard.measurement")}
                                    wrapperVariant="flex-1"
                                    name="assessment"
                                    value="1"
                                    secondary
                                    onChange={(e) => {
                                        formik.setFieldValue("assessment", "1");
                                    }}
                                    checked={formik.values["assessment"] === "1"}
                                ></Radio>
                            </Flex.Column>
                        </>
                    );
                },
            },
            {
                name: (values) => {
                    return values.assessment === "1"
                        ? t("forms:analyzer.wizard.step1Title")
                        : t("forms:extraction.wizard.title_step6");
                },
                fieldNames: ["gradeA", "gradeB", "gradeC", "cylinderSizeExceeded", "analyzerTagId"],
                validation: validGrades(t),
                onStepRender: async (props) => {
                    if (
                        parseFloat(props.value.quantity) +
                            parseFloat(props.propertyBag.filledQuantity) >
                        props.propertyBag.cylinderSize
                    ) {
                        props.updatePropertyBag({ cylinderSizeExceeded: true });
                        props.formik.setFieldValue("cylinderSizeExceeded", true);
                    } else {
                        props.updatePropertyBag({
                            cylinderSizeExceeded: false,
                        });
                        props.formik.setFieldValue("cylinderSizeExceeded", false);
                    }
                },
                fields: (formik, propertyBag, updatePropertyBag, updateFields) => {
                    if (
                        propertyBag.cylinderGasQuality &&
                        getRealGrade(formik.values) !== propertyBag.cylinderGasQuality
                    ) {
                        formik.values["cylQualityMatch"] &&
                            updateFields(() => formik.setFieldValue("cylQualityMatch", false));
                    } else {
                        !formik.values["cylQualityMatch"] &&
                            updateFields(() => formik.setFieldValue("cylQualityMatch", true));
                    }

                    if (formik.values["assessment"] === "1")
                        return (
                            <>
                                <InfoWrapper>
                                    <RoundInfo alt="" src="/images/menu/green-info.svg" />
                                    <div>
                                        <InfoTitle>
                                            {t("forms:extraction.wizard.identifyAnalyzerTitle")}
                                        </InfoTitle>
                                        <InfoText>
                                            {t("forms:extraction.wizard.identifyAnalyzerText")}
                                            <br />
                                            {t("forms:extraction.wizard.identifyAnalyzerText2")}
                                        </InfoText>
                                    </div>
                                </InfoWrapper>
                                <Flex.Container>
                                    <InputElement
                                        labelText={t("forms:analyzer.wizard.step1Title")}
                                        id="analyzerTagId"
                                        name="analyzerTagId"
                                        type="search"
                                        searchFn={analyzerSearchFn}
                                        wrapperVariant="flex-1"
                                        {...formik}
                                    />
                                </Flex.Container>
                            </>
                        );
                    return (
                        <>
                            <Flex.Container>
                                <GradeCheckboxes t={t} formik={formik} name="grades" />
                            </Flex.Container>
                            <Flex.Container flexWrap="wrap">
                                <PresumedGradeLabel
                                    gradeA={formik.values.gradeA}
                                    gradeB={formik.values.gradeB}
                                    gradeC={formik.values.gradeC}
                                />
                            </Flex.Container>
                            {propertyBag.cylinderSizeExceeded && (
                                <ErrorMessage>
                                    {t("exceptions:validation.CylinderCannotHaveMoreGas")}
                                </ErrorMessage>
                            )}
                            {!formik.values["cylQualityMatch"] && (
                                <ErrorMessage>
                                    {t("forms:extraction.wizard.actionQualityMismatch")}
                                </ErrorMessage>
                            )}
                        </>
                    );
                },
            },
        ],
    };
};
export default NewExtractionWizard;
