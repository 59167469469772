import React from "react";
import styled from "styled-components";

const ChartHeaderWrapper = styled.div`
    margin-top:${p=>p.marginTop};
    display: inline-block;
    font-size: 18px;
    width:100%;
`;

const TitleWrapper = styled.div`
    float: left;
    font-weight: 500;
    color: #333;
`;

const TooltipWrapper = styled.div`
    float: right;
    font-size:10px;
`;

const ChartHeader = (props) => {
    const { title, subtitle, tooltip, marginTop } = props;

    return (

        <ChartHeaderWrapper marginTop={marginTop || 0}>
            <TitleWrapper>
                {title} {subtitle}
            </TitleWrapper>
            {
                tooltip && (<TooltipWrapper>
                    {tooltip}
                </TooltipWrapper>)
            }

        </ChartHeaderWrapper>
    );
};

export default ChartHeader;