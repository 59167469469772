import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import ModalDialog, { useModal } from "../../components/modal/ModalDialog";
import Roles from "../../core/enums/Roles";
import helpers from "../../core/helpers";
import UserService from "../../services/User/UserService";
import LogoutButton from "../elements/buttons/LogoutButton";
import TechnicianTools from "./TehnicianTools";

const Menu = styled.div`
    cursor: default;
    position: absolute;
    top: calc(100% + 10px);
    right: 0;
    border-radius: ${(props) => props.theme.inputBorderRadius};
    max-height: 0;
    transition: all 0.3s ease-in;
    opacity: 0;
    background: ${(props) => props.theme.bgWhite};
    text-align: left;
    padding: 8px 24px 24px;
    visibility: hidden;
    box-shadow: 4px 4px 16px 0px rgba(0, 0, 0, 0.16);

    &.opened {
        max-height: 1000px;
        opacity: 1;
        visibility: visible;
        z-index: 100;
    }

    a:hover {
        color: ${(props) => props.theme.primaryColorHover};
    }
`;

const UL = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    word-break: keep-all;
    white-space: nowrap;

    li {
        list-style: none;
        padding: 15px 5px;
        border-bottom: 1px solid
            ${(props) => props.theme.primaryInputBorderColor};
        cursor: auto;
        &:last-child {
            border-bottom: none;
            padding-bottom: 0;
            border-top: 1px solid
                ${(props) => props.theme.primaryInputBorderColor};
        }
    }

    li:not(:first-child) *,
    ul li * {
        font-size: 14px !important;
    }

    & li:hover {
        color: ${(props) => props.theme.primaryColorHover};
    }

    & ul.menuItems {
        padding: 5px 0;
    }

    & ul.menuItems li {
        border-bottom: 1px solid transparent;
        padding: 10px 5px;
        border: none;
    }

    & ul.menuItems li:last-child {
        border-bottom: 1px solid
            ${(props) => props.theme.primaryInputBorderColor};
    }

    & .clear {
        clear: both;
    }

    & .full {
        width: 100%;
    }

    a {
        color: ${(props) => props.theme.textColor};
        text-decoration: none;
        width: 100%;
    }
`;

const Avatar = styled.div`
    border-radius: 50%;
    width: 40px;
    height: 40px;
    color: #fff;
    background-color: ${(props) => props.theme.secondaryColor};
    text-align: center;
    margin-right: 12px;

    & span {
        line-height: 40px;
        font-size: 20px;
        text-transform: uppercase;
    }

    .avatar {
        border-radius: 50%;
        background: no-repeat center;
        background-size: cover;
        display: inline-block;
        width: 100%;
        height: 100%;
    }
`;

const UserInfo = styled.div`
    text-align: left;
    & .name {
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        color: ${(props) => props.theme.primaryColorHover};
    }

    & .mail {
        color: ${(props) => props.theme.menuItems};
        font-size: 14px;
        color: #898989;
        text-transform: lowercase;
    }

    &:hover .mail {
        color: ${(props) => props.theme.primaryColorHover};
    }
`;

const UserMenu = (props, menuRef) => {
    const opened = props.isOpened;
    const links = props.links;
    const pathname = window.location.pathname;
    const { t } = new useTranslation();
    const buildUrl = (link) => {
        if (link === pathname) {
            return link;
        }
        return link + "?returnUrl=" + pathname;
    };

    const avatarUrl =
        props.avatar.indexOf("man-user") > 0 ? null : props.avatar;

    const { modalProps, toggleOpen } = useModal({
        onConfirm: async () => {
            toggleOpen();
        },
        confirmText: t("actions:dialogs.qrLink.confirmText"),
        modalVariant: "wide",
        type: "info",
    });

    const companyEditEnabled =
        UserService.isInRole(Roles.InstallerCompanyAdmin) ||
        UserService.isInRole(Roles.InstallerCompanyUser);

    return (
        <Menu ref={menuRef} className={opened ? "opened" : ""}>
            <UL>
                <li key="userInfo">
                    <Link
                        to={buildUrl(props.userLink)}
                        style={{ display: "flex", alignItems: "center" }}
                    >
                        <Avatar>
                            {avatarUrl ? (
                                <div
                                    className="avatar"
                                    style={{
                                        backgroundImage:
                                            `url(` + avatarUrl + `)`,
                                    }}
                                ></div>
                            ) : (
                                <span>
                                    {helpers.getInitials(props.userFullName)}
                                </span>
                            )}
                        </Avatar>
                        <UserInfo>
                            <div className="name">{props.userFullName}</div>
                            <div className="mail">{props.email}</div>
                        </UserInfo>
                        <div className="clear" />
                    </Link>
                </li>

                {props.company && companyEditEnabled && (
                    <li key="companyEdit">
                        <Link to={buildUrl(props.companyLink)}>
                            <div className="full">{props.company}</div>
                        </Link>
                    </li>
                )}
                {props.company && !companyEditEnabled && (
                    <li style={{ color: "#ccc" }} key="companyEdit">
                        <div className="full">{props.company}</div>
                    </li>
                )}
                <UL className="menuItems">
                    {links.map((link, idx) => {
                        return (
                            <li key={idx + link.link} className="menuItem">
                                {link.external ? (
                                    <a href={link.link} rel="noopener noreferrer" target="_blank">{link.name}</a>
                                ) : (
                                    <Link to={buildUrl(link.link)}>
                                        <div className="full">{link.name}</div>
                                    </Link>
                                )}
                            </li>
                        );
                    })}
                </UL>
                <li
                    style={{ fontSize: "14px", cursor: "pointer" }}
                    key="modalTest"
                    onClick={() => {
                        toggleOpen();
                    }}
                >
                    {t("actions:dialogs.qrLink.title")}
                </li>
                <li key="logout">
                    <LogoutButton variant="list" />
                </li>
            </UL>
            <ModalDialog {...modalProps}>
                <TechnicianTools />
            </ModalDialog>
        </Menu>
    );
};

export default React.forwardRef(UserMenu);
