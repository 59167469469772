import React from "react";
import styled from "styled-components";

const Item = styled.div`
    margin: 0 10px 10px 0;

    & > * {
        height: 32px;
        margin-top: 0px;
    }

    & * {
        font-size: 12px !important;
    }

    &.tight {
        margin-right: 0px;
    }
`;

export const FilterGroup = styled.div`
    display: flex;
    & ${Item}:first-child {
        margin-right: 4px;
    }
`;

const FilterItem = ({ children, ...restProps }) => {
    return <Item {...restProps}>{children}</Item>;
};

export default FilterItem;
