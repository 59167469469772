import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { ReactComponent as MenuBulletSvg } from "../../../assets/pwa/menu-bullet.svg";

const SvgBullet = styled(MenuBulletSvg)`
    vertical-align: middle;
 
    &.selected circle {
        fill: #04A9F5;
        stroke: #white;
    }
`;

const GasGradeCode = styled.span`
    padding-left: 12px;
    vertical-align: middle;
`;

const GasItemWrapper = styled.li`
    border-bottom: solid 1px #ECEDEF;
    font-size: 12px; 
    cursor: pointer;
    
    &.selected {
        background-color: #E9F2ED;
        font-size: 16px;
        font-weight: bold;
    } 

`;

const ListWrapper = styled.ul`
    list-style: none;
    padding: 0px;
    margin: 0px 0px 0px 12px;

    transition: all 0.3s ease-out;
    overflow: hidden;
    max-height: 1000px;
    opacity:1;

    &.top-space {
        padding-top: 25px;
    }

    &.closed {
        max-height:0;
        opacity:0;
    }

    &.sidebar {
        font-size: 0.875rem;
    }

    &.overflow:not(.closed) {
        overflow:visible;
    }
  
`;

const GasGradeItem = styled.li`
    font-size:12px; 
    font-weight: normal;
    cursor: pointer;

    &.selected {
        font-size: 14px;
        font-weight: bold;
    } 
`;

const GasTitle = styled.div`
   position: relative;
   padding: 14px 40px 14px 40px;
`;

const GasDetail = styled.div`
    display: inline;
    vertical-align: middle;
    &:after {
        content: "";
        background: url("/images/menu/expand.svg") no-repeat center;
        background-size: 14px 12px;
 
        position: absolute;
        right: 8px;
        top: 15px;
        width: 15px;
        height: 15px;
        text-align: center;
    }

    &.isOpened {
        &:after {
            transform: rotate(-180deg);
        }
    }
`;

const GasQuantity = styled.span`
    float: right;
`;

const GasGradeContent = styled.div`
    padding: 10px 0px 4px 2px;
`;

const GasGradeDetail = styled.div`
    display: inline;
    vertical-align: middle;
`;

const AccordionMenu = ({ item, handleClick, selectedItem, parentIndex, isOpen }) => {

    const [accordionIsActive, setAccordionIsActive] = useState(isOpen);

    const toggleAccordion = () => {
        setAccordionIsActive(!accordionIsActive);
    };

    const { t } = useTranslation("screens");

    const selectedParentClass = (data) => {
        return data.id === selectedItem.refrigerantId ? 'selected' : null;
    }

    const selectedClass = (data) => {
        return data.refrigerantId === selectedItem.refrigerantId && data.grade === selectedItem.grade ? 'selected' : null;
    }

    const handleClickInternal = (ev) => {
        const tgt = ev.currentTarget;
        if (tgt.dataset.value !== undefined) {
            const index = tgt.dataset.value;
            const selectedItem = item.items[index];
            handleClick(selectedItem);
        }
    }

    return (
        <GasItemWrapper
            className={[accordionIsActive ? 'isOpened accordion' : 'accordion'], selectedParentClass(item)}
            key={item.id}
            data-info={item.id}
            data-value={parentIndex}>

            <GasTitle>
                <GasDetail
                    onClick={toggleAccordion}
                    className={accordionIsActive ? 'isOpened' : ''}
                >
                    {item.code}
                  
                    <GasQuantity>{item.quantity.toFixed(2)} kg</GasQuantity>
                </GasDetail>
                <ListWrapper
                    className={[accordionIsActive ? "" : "closed"]}
                >
                    {item.items.map((subItem, subIndex) =>
                    (
                        <GasGradeItem
                            onClick={handleClickInternal}
                            className={selectedClass(subItem)}
                            key={`${subItem.refrigerantId}_${subIndex}`}
                            data-value={subIndex}
                        >
                            <GasGradeContent>
                                <GasGradeDetail>
                                    <SvgBullet className={selectedClass(subItem)}></SvgBullet>
                                    <GasGradeCode>
                                        {subItem.name}  {t("screens:salesDashboard.labels.grade")}
                                    </GasGradeCode>
                                    <GasQuantity style={{marginTop: 2}}>{subItem.quantity.toFixed(2)} kg</GasQuantity>
                                </GasGradeDetail>
                            </GasGradeContent>
                        </GasGradeItem>

                    ))}
                </ListWrapper>

            </GasTitle>
        </GasItemWrapper>
    )
};

export default AccordionMenu;
