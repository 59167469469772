import styled from "styled-components";

export const QRWrapper = styled.div`
    text-align: center;
    margin-top: 5px;
    span {
        display: inline-block;
        margin-top: 5px;
    }
`;
