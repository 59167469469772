import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import Color from "color";

const ModalOverlay = styled.div`
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: ${(props) =>
        props.color
            ? props.color
            : Color(props.theme.bgOverlay).fade(0.17).string()};
    z-index: 700;
`;

const ModalContainer = ({ children, isOpen, bgColor, zIndex = 1000 }) => {
    if (!isOpen) {
        return null;
    }

    return ReactDOM.createPortal(
        <ModalOverlay color={bgColor}>{children}</ModalOverlay>,
        document.body
    );
};

export default ModalContainer;
