import React, { useRef } from "react";
import { Redirect, Switch, useRouteMatch } from "react-router-dom";
import Roles from "../../core/enums/Roles";
import PrivateRoute from "../../core/PrivateRoute";
import RouteCreator from "../../core/RouteCreator";
import useLeftMenu from "../../core/state/navigation/useLeftMenu";
import globalAdminMenuItems from "../../data/menu/globalAdminMenuItems";
import CreateAdministratorScreen from "./CreateAdministratorScreen";
import AdministratorEditScreen from "./views/user/AdministratorEditScreen";
import AdministratorListing from "./views/user/AdministratorListing";
import UserListing from "./views/user/UserListing";
import UserStatisticsDashboard from "./views/user/UserStatisticsDashboard";
import BackButton from "../../components/elements/buttons/BackButton";
import useQueryString from "../../hooks/_shared/useQueryString";
import UserCertificateScreen from "./UserCertificateScreen";

const UserAdministrationScreen = () => {
    const returnUrl = useQueryString("returnUrl", "/");
    const returnTo = useRef("/");
    if (returnUrl !== "/") returnTo.current = returnUrl;

    const isAdminEdit = useRouteMatch({
        path: RouteCreator.admin.edit(""),
        strict: false,
    });

    const isDashboard = useRouteMatch({
        path: RouteCreator.admin.users.statistics(),
        strict: false,
    });

    useLeftMenu(isAdminEdit ? null : globalAdminMenuItems);

    const onBackButtonClick = (pathName) => {
        if (pathName.indexOf("/employees/edit/") >= 0) return true;
    };

    return (
        <div
            style={{
                display: "inline-flex",
                height: isDashboard ? "auto" : "100%",
            }}
        >
            {isAdminEdit && (
                <BackButton
                    variant="company"
                    returnTo={returnTo.current}
                    onGoBack={onBackButtonClick}
                />
            )}
            <Switch>
                <PrivateRoute
                    path={RouteCreator.admin.users.createAdmin()}
                    component={CreateAdministratorScreen}
                    roles={[Roles.MasterAdmin]}
                />
                <PrivateRoute
                    path={RouteCreator.admin.users.admins()}
                    component={AdministratorListing}
                    roles={[Roles.Admin, Roles.MasterAdmin]}
                />
                <PrivateRoute
                    path={RouteCreator.admin.users.statistics()}
                    component={UserStatisticsDashboard}
                    roles={[Roles.Admin, Roles.MasterAdmin]}
                />
                <PrivateRoute
                    path={RouteCreator.admin.users.nonAdmins()}
                    component={UserListing}
                    roles={[Roles.Admin, Roles.MasterAdmin]}
                />
                <PrivateRoute
                    path={RouteCreator.admin.edit(":employeeId")}
                    component={AdministratorEditScreen}
                />
                <PrivateRoute
                    path={RouteCreator.admin.users.certificates(":userId")}
                    component={UserCertificateScreen}
                    roles={[Roles.MasterAdmin, Roles.Admin]}
                ></PrivateRoute>

                <Redirect to={RouteCreator.admin.users.statistics()} />
            </Switch>
        </div>
    );
};

export default UserAdministrationScreen;
