import styled, { css } from "styled-components";

export const InputWrapper = styled.div`
    position: relative;

    &.half {
        width: 48%;
        display: inline-block;
        margin: 0 1%;
    }

    &.flex-half {
        flex-basis: 45%;
        margin: 0 5% 0 0;

        @media all and (max-width: ${(props) => props.theme.extraLargeDevices}) {
            flex-basis: 100%;
            margin: 0;
        }
    }

    &.flex-2 {
        flex-basis: 46%;
        margin: 0 2% 0 2%;
        flex-grow: 0;
        max-width: 46%;
    }

    &.flex-3 {
        flex-basis: 29%;
        margin: 0 2.166% 0 2.166%;
        flex-grow: 0;
        max-width: 29%;
    }

    &.flex-4 {
        flex-basis: 23%;
        margin: 0 1% 0 1%;
        flex-grow: 0;
        max-width: 23%;
    }

    &.double-height > div {
        height: 142px;
    }

    &.flex-1 {
        flex-basis: 96%;
        margin: 0 2% 0 2%;
        flex-grow: 0;
    }

    &.fill > * {
        width: 100% !important;
    }

    &.full {
        flex-basis: 95%;
        margin: 0 5% 0 0;

        @media all and (max-width: ${(props) => props.theme.extraLargeDevices}) {
            flex-basis: 100%;
            margin: 0;
        }
    }

    &.full-width {
        flex-basis: 100%;
        margin: 0;
    }

    &.flex-full-half {
        flex-basis: 95%;
        margin: 0 5% 0 0;
        padding-right: 50%;

        @media all and (max-width: ${(props) => props.theme.extraLargeDevices}) {
            flex-basis: 100%;
            margin: 0;
        }
    }

    &.error {
        input:not(:focus),
        select:not(:focus),
        textarea:not(:focus),
        .inputElement:not(:focus) {
            border-color: ${(props) => props.theme.errorColor} !important;
            box-shadow: none;
        }
    }

    &.bid {
        &-a {
            input {
                color: ${(props) => props.theme.bidA};
            }
        }
        &-b {
            input {
                color: ${(props) => props.theme.bidB};
            }
        }
        &-c {
            input {
                color: ${(props) => props.theme.bidC};
            }
        }
    }

    &.hide {
        display: none;
    }
`;

export const MoneyContent = styled.div`
    display: flex;
    border: 1px solid ${(props) => props.theme.primaryInputBorderColor};
    border-radius: ${(props) => props.theme.inputBorderRadius};
    overflow: hidden;
    font-size: 0.75rem;
    padding: 15px 18px 12px 18px;

    &.standard {
        padding: 9px 12px 10px 12px;
        margin-bottom: 15px;
    }

    &.money {
        font-weight: bold;

        &.normal {
            font-weight: normal;

            > .money {
                font-weight: normal;
            }
        }
    }

    &.wizard {
        padding: 11px 18px 9px 18px;
    }

    &.error {
        border-color: #e94e4e;
    }

    > input {
        margin: 0;
        padding: 0;
        height: auto;
    }

    ${(props) =>
        props.disabled &&
        css`
            background: #f5f5f5;
        `};
`;

export const HR = styled.hr`
    border-top: none;
    border-bottom: 1px solid gray;
    margin: 40px 0;
`;

export const TextRight = styled.div`
    text-align: right;
`;
