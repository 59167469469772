import React from "react";
import styled from "styled-components";
import ValidationRule, {
    buildValidation,
    skipValidation,
} from "../../../../../core/validations/ValidationRule";
import * as yup from "yup";
import {
    InfoText,
    InfoTitle,
    InfoWrapper,
    RoundInfo,
} from "../../../../../components/modal/components/WizardStyling";
import { Checkbox, Radio } from "../../../../../components/elements/form-elements";
import InputElement from "../../../../../components/elements/form-elements/InputElement";

const Separator = styled.div`
    width: 100%;
    height: 1px;
    margin: 24px 0;
    background-color: ${(p) => p.theme.sidebarWrapperBorderColor};
`;

const CheckList = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 25px;
`;

const validateInput = (t) =>
    yup.lazy((values) => {
        if (values.daikinPartnerStatus === "2") return skipValidation();
        return buildValidation({
            sapNumber: ValidationRule.isStringRule().required(
                t("forms:partners.wizard.sapNumberRequired")
            ).rule,
            existsInSap: yup.boolean().required().oneOf([true]),
            hasVip4Set: yup.boolean().required().oneOf([true]),
        });
    });

const PartnerStatusWizard = (t, initialValues, companyName) => {
    return {
        title: t("forms:partners.wizard.title"),
        initialValues: initialValues,
        steps: [
            {
                name: t("forms:partners.wizard.subtitle"),
                fieldNames: [
                    "daikinPartnerStatus",
                    "sapNumber",
                    "existsInSap",
                    "hasVip4Set",
                    "sendEmailNotification",
                ],
                validation: validateInput(t),
                fields: (formik) => (
                    <>
                        <InfoWrapper className="no-border" style={{ marginBottom: "0" }}>
                            <RoundInfo alt="" src="/images/menu/green-info.svg" />
                            <div>
                                <InfoTitle>
                                    {t("forms:partners.wizard.infoTitle", { company: companyName })}
                                </InfoTitle>
                                <InfoText>{t("forms:partners.wizard.infoSubtitle")}</InfoText>
                            </div>
                        </InfoWrapper>

                        <Radio
                            labelText={t("forms:partners.wizard.markAsPartner", {
                                company: companyName,
                            })}
                            name="daikinPartnerStatus"
                            value="1"
                            secondary
                            onChange={(e) => {
                                formik.setFieldValue("daikinPartnerStatus", "1");
                            }}
                            checked={formik.values["daikinPartnerStatus"] === "1"}
                        ></Radio>

                        <Radio
                            labelText={t("forms:partners.wizard.markAsCompetitor", {
                                company: companyName,
                            })}
                            name="daikinPartnerStatus"
                            value="2"
                            secondary
                            onChange={(e) => {
                                formik.setFieldValue("daikinPartnerStatus", "2");
                            }}
                            checked={formik.values["daikinPartnerStatus"] === "2"}
                        ></Radio>
                        <Separator />
                        <InputElement
                            style={{ marginLeft: "25px" }}
                            labelText={t("forms:partners.wizard.sapNumber")}
                            id="sapNumber"
                            name="sapNumber"
                            autocomplete="new-sapNumber"
                            type="text"
                            disabled={formik.values["daikinPartnerStatus"] === "2"}
                            {...formik}
                        />
                        <CheckList>
                            <Checkbox
                                secondary
                                labelText={t("forms:partners.wizard.existsInSap", {
                                    company: companyName,
                                })}
                                id="existsInSap"
                                name="existsInSap"
                                disabled={formik.values["daikinPartnerStatus"] === "2"}
                                {...formik}
                                // onChange={(e) => {
                                //     formik.setFieldValue("existsInSap", e.target.checked);
                                // }}
                            />

                            <Checkbox
                                labelText={t("forms:partners.wizard.haveVip4", {
                                    company: companyName,
                                })}
                                id="hasVip4Set"
                                name="hasVip4Set"
                                disabled={formik.values["daikinPartnerStatus"] === "2"}
                                {...formik}
                            />

                            <Checkbox
                                style={{ marginTop: "24px" }}
                                labelText={t("forms:partners.wizard.sendEmail", {
                                    company: companyName,
                                })}
                                id="sendEmailNotification"
                                name="sendEmailNotification"
                                disabled={
                                    formik.values["daikinPartnerStatus"] === "2" ||
                                    formik.values["existsInSap"] !== true ||
                                    formik.values["hasVip4Set"] !== true ||
                                    !formik.values["sapNumber"]
                                }
                                {...formik}
                            />
                        </CheckList>
                    </>
                ),
            },
        ],
    };
};

export default PartnerStatusWizard;
