import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import SidebarField from "./SidebarField";

const SidebarUsers = (props) => {
    const {users} = props;
    const hasUsers = users !== undefined;
    const {t} = useTranslation('sidebar');

    return (
        <>
            {
                hasUsers &&
                users.map(user => {
                    return (
                        <Fragment key={user.fullName + '_' + user.email}>
                            <SidebarField label={t('label.fullName')} value={user.fullName}/>
                            <SidebarField label={t('label.email')} value={user.email}/>
                            <br/>
                        </Fragment>
                    )
                })
            }
        </>
    )

};

export default SidebarUsers;
