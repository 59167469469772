import i18next from "i18next";
import { getWeek, getWeekYear} from "date-fns"
import { getDateFormat } from "../components/data/DateCell";
import { splitAt } from "../screens/Equipment/components/splitAt";
import Globals from "../globalVars";
import _ from 'lodash';

const timeZoneCompensation = new Date().getTimezoneOffset() * -1;

const monthNames = ["January", "February", "March", "April", "May", "June",
"July", "August", "September", "October", "November", "December"
];

const helpers = {
    dateToIsoDateString(localDate) {
        var date = localDate ? new Date(localDate) : new Date();
        return new Date(
            date.getTime() - date.getTimezoneOffset() * 60000
        ).toISOString();
    },

    getMonthName(yearAndMonth) {
        const month = parseInt(yearAndMonth.slice(-2));
        return monthNames[month - 1];
    },

    getWeekName(isoString) {
        const dateParsed = new Date(isoString);
        return getWeek(dateParsed);
    },

    getWeekYear(isoString) {
        const dateParsed = new Date(isoString);
        return getWeekYear(dateParsed);
    },

    minDate(values) {
        let dates = values.map((x) => new Date(x));
        return _.min(dates);
    },

    maxDate(values) {
        let dates = values.map((x) => new Date(x));
        const maxDate = _.max(dates);
        return maxDate;
    },

    toLocalDateIgnoreTime(isoString) {
        if (isoString) {
            const dateParsed = new Date(isoString);

            let m = getDateFormat("sk").format(dateParsed);

            return m;
        }
        return null;
    },

    getFormData: (object, mappings = {}) => {
        let formData = new FormData();
        for (let key in object) {
            let value = null;
            if (
                object[key] instanceof Array &&
                object[key].length > 0 &&
                object[key][0] instanceof File
            ) {
                for (var file of object[key]) {
                    formData.append(
                        key[0].toUpperCase() + key.substr(1, key.length - 1),
                        file,
                        file.name
                    );
                }
            } else {
                if (object[key] instanceof Array) {
                    for (var item of object[key]) {
                        formData.append(
                            key[0].toUpperCase() +
                                key.substr(1, key.length - 1),
                            item
                        );
                    }
                } else {
                    if (object[key] instanceof Date) {
                        const parsedDate = new Date(object[key]);

                        parsedDate.setMinutes(
                            parsedDate.getMinutes() + timeZoneCompensation
                        );

                        value = parsedDate.toISOString();
                    } else {
                        value = object[key];
                    }

                    if (!mappings[key]) {
                        formData.append(
                            key[0].toUpperCase() +
                                key.substr(1, key.length - 1),
                            value
                        );
                    } else {
                        formData.append(mappings[key], value);
                    }
                }
            }
        }

        return formData;
    },
    getFileUploadFormik: (
        formik,
        file = "fGasFile",
        dependsOnAnotherField = false
    ) => {
        const handleFileUpload = (event) => {
            formik.setFieldValue(file, event.currentTarget.files[0]);
            return formik.handleChange(event);
        };

        const fileChangeFormikObject = Object.assign({}, formik, {
            handleChange: handleFileUpload,
        });

        return fileChangeFormikObject;
    },
    handleFGasCertificate: (
        formik,
        setter,
        checker,
        fileFieldName = "fGasCertificate"
    ) => {
        const handleFGasCertificate = (e) => {
            if (!checker) {
                formik.setFieldValue(fileFieldName, "");
            }
            setter(!checker);

            return formik.handleChange(e);
        };

        const fGasCheckChangeHandler = {
            handleChange: handleFGasCertificate,
        };

        const fGasCheckFormikObject = Object.assign(
            {},
            formik,
            fGasCheckChangeHandler
        );

        return fGasCheckFormikObject;
    },
    getInitialFormValues: (defaultInitialValues, results) => {
        let initialValues;
        /* this is to cover the case if the api call fails for some reason */
        if (!results) {
            return defaultInitialValues;
        } else {
            if (results.data && results.data.length > 0) {
                initialValues = { ...defaultInitialValues, ...results.data[0] };
            } else {
                initialValues = { ...defaultInitialValues, ...results };
            }

            const initializeValues = (obj) =>
                JSON.parse(
                    JSON.stringify(obj, (k, v) => (v === null ? "" : v))
                );
            return initializeValues(initialValues);
        }
    },
    // Returns a function, that, as long as it continues to be invoked, will not
    // be triggered. The function will be called after it stops being called for
    // N milliseconds. If `immediate` is passed, trigger the function on the
    // leading edge, instead of the trailing.
    debounce: (func, wait, immediate) => {
        var timeout;
        return function () {
            var context = this,
                args = arguments;
            var later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    },

    getInitials: (fullName) => {
        if (fullName !== "") {
            let names = fullName.split(" "),
                initials = names[0].substring(0, 1).toUpperCase();

            if (names.length > 1) {
                initials += names[names.length - 1]
                    .substring(0, 1)
                    .toUpperCase();
            }
            return initials;
        }
    },

    splitQRTag: (tag) => {
        if (tag) {
            const splitTag = (start, end) => (tag) => [tag.slice(start, end)];
            const firstPart = splitTag(0, 4)(tag);
            const secondPart = splitTag(4, 8)(tag);
            const rest = splitAt(9)(tag)[1];
            return [firstPart + " " + secondPart, rest.replaceAll("-", " - ")];
        }
        return [];
    },

    formatCurrency: (amount, currentLanguage, currency, sign=false) => {
        if (!currentLanguage) {
            currentLanguage = "sk-SK";
        }
        return Intl.NumberFormat(currentLanguage, {
            style: "currency",
            currency,
            signDisplay: sign ? "exceptZero" : "never",
            currencyDisplay: "code",
        }).format(amount);
    },

    // https://gist.github.com/davalapar/d0a5ba7cce4bc599f54800da22926da2
    downloadFile(data, filename, mime) {
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        const blob = new Blob([data], {
            type: mime || "application/octet-stream",
        });
        if (typeof window.navigator.msSaveBlob !== "undefined") {
            // IE doesn't allow using a blob object directly as link href.
            // Workaround for "HTML7007: One or more blob URLs were
            // revoked by closing the blob for which they were created.
            // These URLs will no longer resolve as the data backing
            // the URL has been freed."
            window.navigator.msSaveBlob(blob, filename);
            return;
        }
        // Other browsers
        // Create a link pointing to the ObjectURL containing the blob
        const blobURL = window.URL.createObjectURL(blob);
        const tempLink = document.createElement("a");
        tempLink.style.display = "none";
        tempLink.href = blobURL;
        tempLink.setAttribute("download", filename);
        // Safari thinks _blank anchor are pop ups. We only want to set _blank
        // target if the browser does not support the HTML5 download attribute.
        // This allows you to download files in desktop safari if pop up blocking
        // is enabled.
        if (typeof tempLink.download === "undefined") {
            tempLink.setAttribute("target", "_blank");
        }
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
        setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(blobURL);
        }, 100);
    },
    downloadFileViaDirectUrl(url, filename) {
        const tempLink = document.createElement("a");
        tempLink.style.display = "none";
        tempLink.href = url;
        tempLink.setAttribute("download", filename);
        // Safari thinks _blank anchor are pop ups. We only want to set _blank
        // target if the browser does not support the HTML5 download attribute.
        // This allows you to download files in desktop safari if pop up blocking
        // is enabled.
        if (typeof tempLink.download === "undefined") {
            tempLink.setAttribute("target", "_blank");
        }
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
    },

    calculatePurchasedPrice(qualityPrice, realQuantity, withVAT = true) {
        const vat = withVAT ? Globals.VATPercentage : 1;

        return qualityPrice * realQuantity * vat;
    },

    calculatePriceWithVat(price) {
        return price? price * Globals.VATPercentage :  0;
    },
    
};

export default helpers;
