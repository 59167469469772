import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import DataEntry from "../../components/data-entry/DataEntry";
import EffectError from "../../components/errors/EffectError";
import LayoutInnerContainer from "../../components/layout/split/one/LayoutInnerContainer";
import Roles from "../../core/enums/Roles";
import RouteCreator from "../../core/RouteCreator";
import adminUsersListStateModule from "../../core/state/admin/users/adminUsersListStateModule";
import useActiveNavigationPath from "../../core/state/navigation/useActiveNavigationPath";
import useBreadcrumbs from "../../core/state/navigation/useBreadcrumbs";
import useStateModule from "../../core/state/useStateModule";
import commonValidation from "../../core/validations/common";
import ValidationRule, {
    buildValidation,
} from "../../core/validations/ValidationRule";
import CreateAdministratorForm from "./CreateAdministratorForm";
import {Helmet} from "react-helmet";

const defaultInitialValues = {
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    roleId: "-1",
};

const getAdminValidation = (t) => {
    const validation = buildValidation({
        email: commonValidation.email(t).rule,
        firstName: ValidationRule.isStringRule().required(
            t("forms:firstName.required")
        ).rule,
        lastName: ValidationRule.isStringRule().required(
            t("forms:lastName.required")
        ).rule,
    });

    return validation;
};

const CreateAdministratorScreen = () => {
    const { t } = useTranslation("screens");
    const {
        createAdministrator,
        createData,
        loadRoles,
        rolesData,
    } = useStateModule(adminUsersListStateModule);

    useActiveNavigationPath(RouteCreator.admin.users.admins());
    useEffect(() => {
        loadRoles();
    }, [loadRoles]);

    useBreadcrumbs([
        {
            text: t('navigation:users'),
        },
        {
            text: t('navigation:administrationListing'),
            url: RouteCreator.admin.users.admins(),
        },
        {
            text: t('navigation:createNewAdministrator'),
        },
    ]);

    const handleSubmit = (values) => {
        values.roleId = rolesData.roles.find(
            (role) => role.name === Roles.Admin
        ).id;
        createAdministrator(values);
    };

    if (!createData.error && createData.isCompleted) {
        return <Redirect to={RouteCreator.admin.users.admins()} />;
    }
    return (
        <>

        <Helmet>
                <title>{t("seo:createAdministrator.title")}</title>
            </Helmet>
            <LayoutInnerContainer>
                {createData.error && (
                    <EffectError error={createData.error} onlyNotification />
                )}
                <DataEntry
                    autoComplete="off"
                    isLoading={createData.isLoading}
                    initialValues={defaultInitialValues}
                    validationSchema={getAdminValidation(t)}
                    renderForm={(formik) => {
                        return (
                            <CreateAdministratorForm
                                formik={formik}
                                isLoading={
                                    createData.isLoading || rolesData.isLoading
                                }
                                rolesData={rolesData}
                            />
                        );
                    }}
                    onSubmit={handleSubmit}
                />
            </LayoutInnerContainer>
        </>
    );
};

export default CreateAdministratorScreen;
