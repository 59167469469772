import React from "react";
import { useTranslation } from "react-i18next";

const StatusCell = ({ cell: { value } }) => {
    const { t } = useTranslation("");
    const status = {
        PendingConfirmation: t("common:companyActivation.PendingConfirmation"),
        FullyRegistered: t("common:companyActivation.FullyRegistered"),
        ConfirmationSent: t("screens:statistics.approvedCompanies")
    }
    
    return <span title={status[value]}>{status[value]}</span>;
};

export default StatusCell;
