import React from "react";
import styled from "styled-components";

const Wrapper = styled.form`
    width: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 10px;

    & input {
        margin: 0px 0 25px !important;
        width: 210px !important;
    }

    &.full {
        width: 100%;
    }

    &.spread {
        justify-content: space-between;
    }
`;

const FilterWrapper = ({children,...rest}) => {
    
    const handleSubmit=(ev)=>{
        ev.preventDefault();
    }
    
    return (
        <Wrapper {...rest} onSubmit={handleSubmit} autoComplete="off">
            {children}
        </Wrapper>
    )
};

export default FilterWrapper
