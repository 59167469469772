import React from "react";
import { Redirect, Route, useRouteMatch } from "react-router-dom";
import userStateModule from "./state/user/userStateModule";
import useStateModule from "./state/useStateModule";
import useSharedState from "../hooks/_shared/useSharedState";
import Spinner from "../components/elements/general/Spinner";
const SecurityStatus = {
    Allowed : 0,
    NotAuthenticated: 1,
    NotAuthorized : 2
};

const canAccess = (isAuthenticated, userRoles, routeRoles) => {
  if(!isAuthenticated)
  {
    return SecurityStatus.NotAuthenticated;
  }

  if(routeRoles && routeRoles.length > 0) {
    for(var role of userRoles){
      if(routeRoles.indexOf(role) > -1){
        return SecurityStatus.Allowed
      }
    }
    return SecurityStatus.NotAuthorized;
  }
  return SecurityStatus.Allowed;
};

const RouteRenderer = props => {
  const [globalState] = useSharedState("global");
  const match = useRouteMatch();
  const canAccessResult = canAccess(props.isAuthenticated, props.userRoles, props.routeRoles);
  if(canAccessResult === SecurityStatus.NotAuthenticated){
    return  <Redirect to={"/login?returnUrl=" + match.path} />;
  }
  else if(canAccessResult === SecurityStatus.NotAuthorized)
  {
    return <Redirect to={"/"} />;
  }
  else {
    return !globalState.userLoaded ? (
        <Spinner />
    ) : (
        <props.component {...props} isDistributor={globalState.isDistributor} />
    );
  }

};

const PrivateRoute = ({ component: Component, roles: routeRoles, ...rest }) => {
  const {isAuthenticated, roles} = useStateModule(userStateModule);
  return (
    <Route
      {...rest}
      render={(props) => <RouteRenderer component={Component} userRoles={roles} routeRoles={routeRoles} isAuthenticated={isAuthenticated} {...props}></RouteRenderer>}
    />
  );
};

export default PrivateRoute;
