import React, { useState } from "react";
import styled, { css } from "styled-components";
import InputMask from "react-input-mask";
import { ReactComponent as ShowPassIcon } from "../../../assets/Eye.svg";
import { ReactComponent as HidePassIcon } from "../../../assets/Invisible.svg";

const StyledInput = styled.input`
    appearance: none;
    box-sizing: border-box;
    position: relative;
    width: 100%;
    background: ${(props) => props.theme.inputBgColor};
    padding: 0 16px;
    border: 1px solid ${(props) => props.theme.primaryInputBorderColor};
    border-radius: ${(props) => props.theme.inputBorderRadius};
    margin: 0 0 20px;
    ${(props) =>
        props.disabled &&
        css`
            background: #f5f5f5;
        `};
    height: ${(props) => props.theme.heightInputBig};
    font-size: ${(props) => props.theme.inputFontSize};

    ::placeholder {
        color: ${(props) => props.theme.inputPlaceholder};
    }

    :focus::-webkit-input-placeholder {
        color: transparent;
    }
    :focus:-moz-placeholder {
        color: transparent;
    }
    :focus::-moz-placeholder {
        color: transparent;
    }
    :focus:-ms-input-placeholder {
        color: transparent;
    }

    ${(props) =>
        props.hasAction &&
        css`
            margin-bottom: 5px;
        `};

        ${(props) =>
        props.mask &&
        css`
            font-family: monospace;
        `};

    &.prefix {
        font-weight: 700;
        font-size: 14px;
        padding-left: 45px;
        font-family: Courier New, Courier, Lucida Sans Typewriter, Lucida Typewriter, monospace;
        letter-spacing: 4px;
    }

    &.file {
        width: auto;
        padding: 0;
        border: none;
        border-radius: initial;
        margin: 0;
        height: 40px;
    }

    &.clearable {
        padding: 0px 0px 0px 22px;
    }

    &.filters {
        border-radius: ${(props) => props.theme.filtersBorderRadius};
        padding: 0px 10px 0px 32px;
        height: ${(props) => props.theme.heightInputSmall};
        width: 210px;

        @media ${(props) => props.theme.device.mobile} {
            padding: 0px 10px 0px 12px;
        }
    }

    &.filters.group {
        width: 158px !important;
        max-width: 158px !important;
        /* width: auto !important; */
    }

    &.filters.group.left {
        margin: 0;
    }

    &.filters::placeholder {
        padding-left: 0px !important;
    }

    &.filters.left {
        border-radius: ${(props) => props.theme.filtersBorderRadius} 0 0
            ${(props) => props.theme.filtersBorderRadius};
    }

    &.filters.right {
        border-radius: 0 ${(props) => props.theme.filtersBorderRadius}
            ${(props) => props.theme.filtersBorderRadius} 0;
    }

    &.filters.big {
        height: 46px;
        padding: 0px 6px 0px 26px;
        width: 100%;
    }

    &.money {
        font-weight: bold;
        border: none;
    }

    &[type="date"] {
        &::-webkit-calendar-picker-indicator {
            margin-left: 0px;
        }
        &::-webkit-datetime-edit {
            padding-top: 2px;
        }
    }

    &[type="password"] {
        font-family: pass;
        font-size: 18px;
        letter-spacing: 2px;
    }

    &[data-password="true"] {
        padding-right: 40px;
    }

    &.error {
        border-color: #e94e4e;
    }

    &[class*=" disable-spin-button"][type="number"]::-webkit-inner-spin-button,
    &[class*=" disable-spin-button"][type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &[class*=" disable-spin-button"][type="number"] {
        -moz-appearance: textfield;
    }
`;

const StyledShowPass = styled(ShowPassIcon)`
    position: absolute;
    right: 8px;
    top: 38px;
    cursor: pointer;
`;

const StyledHidePass = styled(HidePassIcon)`
    position: absolute;
    right: 8px;
    top: 36px;
    cursor: pointer;
`;

const StyledPrefix = styled.div`
    position: absolute;
    font-weight: 700;
    left: 20px;
    bottom: 34px;
    font-size: 14px;
    font-family: Courier New, Courier, Lucida Sans Typewriter, Lucida Typewriter, monospace;
    letter-spacing: 4px;
    pointer-events: none;
`;

const PlaceholderChars = styled.div`
    position: absolute;
    font-weight: 700;
    left: 14px;
    bottom: 36px;
    font-size: 14px;
    padding-left: 32px;
    font-family: Courier New, Courier, Lucida Sans Typewriter, Lucida Typewriter, monospace;
    letter-spacing: 4px;
    pointer-events: none;
`;

const Input = React.forwardRef((props, ref) => {
    const isPassword = props["data-password"];
    const [hiddenPass, setHiddemPass] = useState({
        visible: false,
        type: props.type,
    });
    const toggleHidden = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        setHiddemPass((oldState) => ({
            visible: !oldState.visible,
            type: oldState.visible ? "password" : "text",
        }));
    };

    const pcChars = props.placeholderChars
        ? props.placeholderChars.char.repeat(props.placeholderChars.num)
        : null;

    const onlyNumbersKey = (evt) => {
        if (!props.onlyNumbers) return true;
        // Only ASCII character in that range allowed
        const ASCIICode = evt.which ? evt.which : evt.keyCode;
        if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) {
            evt.preventDefault();
            return false;
        }
        return true;
    };

    if (props.mask)
        return (
            <InputMask {...props}>
                {(inputProps) => <StyledInput {...inputProps} mask="yes" ref={ref} />}
            </InputMask>
        );

    return (
        <>
            <StyledInput
                {...props}
                ref={ref}
                type={hiddenPass.type}
                className={`${props.className} ${
                    props.enableSpinButton ? "" : "disable-spin-button"
                } ${props.prefix ? "prefix" : ""}`}
                maxLength={props.maxLength || pcChars?.length || ""}
                onKeyPress={(evt) => onlyNumbersKey(evt)}
            />
            {isPassword && !hiddenPass.visible && (
                <StyledShowPass onClick={(ev) => toggleHidden(ev)} />
            )}
            {isPassword && hiddenPass.visible && (
                <StyledHidePass onClick={(ev) => toggleHidden(ev)} />
            )}
            {props.prefix && <StyledPrefix>{props.prefix}</StyledPrefix>}
            {props.placeholderChars && <PlaceholderChars>{pcChars}</PlaceholderChars>}
        </>
    );
});

export default Input;
