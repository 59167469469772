import React from "react";
import {  Switch } from "react-router-dom";
import PrivateRoute from "../../core/PrivateRoute";
import RouteCreator from "../../core/RouteCreator";
import useLeftMenu from "../../core/state/navigation/useLeftMenu";
import companyAdminMenuItems from "../../data/menu/companyAdminMenuItems";
import InstallerStockListing from "./views/stock/InstallerStockListing";
import StockListing from "./views/stock/StockListing";
import StockDashboard from './views/stock/StockDashboard';
import useSharedState from "../../hooks/_shared/useSharedState";


const StockScreen = () => {
    const [globalState] = useSharedState("global");
    useLeftMenu(companyAdminMenuItems(globalState));

    return (
        <Switch>
            <PrivateRoute
                path={RouteCreator.stock.installer()}
                component={InstallerStockListing}
            />
            <PrivateRoute
                path={RouteCreator.stock.company()}
                component={StockListing}
            />
            <PrivateRoute
                path={RouteCreator.stock.root()}
                component={StockDashboard}
            />
        </Switch>

    );
};

export default StockScreen;
