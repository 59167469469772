import React from "react";
import styled from "styled-components";
import { Label } from "./index";

const Container = styled.label`
    display: flex;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    cursor: pointer;
    user-select: none;
    text-align: left;
    font-size: 15px;
    line-height: 32px;
    font-weight: normal;
    align-items: center;
    &.top {
        align-items: flex-start;
    }
    &.list {
        border-bottom: 1px solid #eee;
        display: flex;
        align-items: center;
        margin-bottom: 0;
        padding: 10px 10px 10px 0px;
        height: auto;
    }

    & input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    & input:checked ~ .checkmark:after {
        display: block;
    }

    & .checkmark:after {
        top: 2px;
        left: 2px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: ${(p) => p.theme.primaryColor};
    }

    &.wizard-table {
        margin-bottom: 0;
    }

    &.list .checkmark {
        top: calc(50% - 10px);
        left: initial;
        right: 10px;
    }
    &.secondary .checkmark:after {
        background: ${(p) => p.theme.secondaryColor};
    }
    &.secondary .checkmark {
        border-color: ${(p) => p.theme.secondaryColor};
    }
    &.space {
        margin-top: 24px;
        margin-bottom: 24px;
    }
`;

export const CheckMark = styled.span`
    box-sizing: border-box;
    position: relative;
    margin-right: 15px;
    height: 20px;
    width: 20px;
    min-width: 20px;
    background-color: #fff;
    border-radius: 50%;
    border: 2px solid ${(p) => p.theme.primaryColor};

    &.checkbox {
        width: 22px;
        height: 22px;
        border-radius: 4px;
    }

    &.secondary {
        border-color: ${(p) => p.theme.secondaryColor};
    }

    &.abs {
        position: absolute;
        top: 7px;
        left: 0px;
    }

    &:after {
        content: "";
        position: absolute;
        display: none;
    }

    &.disabled {
        border-color: ${(p) => p.theme.errorColor};
    }
`;

export const RadioLabel = styled(Label)`
    padding: 5px 0 0 20px;
`;

export const RadioGroup = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    &.row {
        flex-direction: row;
        justify-content: space-between;
    }

    &.flex-1 {
        flex-basis: 96%;
        margin: 0 2% 0 2%;
        flex-grow: 0;
    }

    &.col {
        flex-direction: column;
    }
`;

export const RadioWrapper = styled.div`
    position: relative;

    & input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        top: 7px;
        left: 0;
        height: 20px;
        width: 20px;
    }
    & label {
        cursor: pointer;
    }

    & input:checked ~ .checkmark:after {
        display: block;
    }

    & .checkmark {
        pointer-events: none;
        margin-right: 25px;
    }

    & .checkmark:after {
        top: 2px;
        left: 2px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: ${(p) => p.theme.primaryColor};
    }

    & .checkmark.checkbox:after {
        top: 3px;
        left: 3px;
        width: 12px;
        height: 12px;
        border-radius: 2px;
    }

    & .secondary.checkmark:after {
        background: ${(p) => p.theme.secondaryColor};
    }
`;

const Radio = ({
    abs,
    children,
    labelText,
    list,
    containerProps,
    secondary,
    top,
    ...restProps
}) => {
    let classes = restProps.wrapperVariant ? restProps.wrapperVariant : "";
    if (list) classes = classes + " list";
    if (secondary) classes = classes + " secondary";
    if (top) classes = classes + " top";
    return (
        <Container className={classes} {...containerProps}>
            <input {...restProps} type="radio" />
            <CheckMark className={abs ? "checkmark abs" : "checkmark"} />
            {labelText || children}
        </Container>
    );
};

export default Radio;
