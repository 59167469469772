import React from "react";
import styled from "styled-components";
import MoneyInputElement from "../elements/form-elements/MoneyInputElement";

const BidWrapper = styled.div`
    margin: 0 32px 0 0;
    width: 33%;
    max-width: 33%;
    flex: 1 1 0;

    &:last-child {
        margin: 0;
    }

    &.size-3 {
        width: 33%;
        max-width: 33%;
    }

    &.size-2 {
        width: 50%;
        max-width: 50%;
    }

    &.size-1 {
        width: 100%;
        max-width: 100%;
    }
`;

const BidItem = ({
    t,
    grade,
    value,
    minValue,
    error,
    positive,
    className,
    formik,
    labelText,
}) => {
    className = error ? `${error} ${className}` : className;

    return (
        <BidWrapper className={className}>
            <MoneyInputElement
                type="number"
                step="0.01"
                name={"grade" + grade}
                id={"grade" + grade}
                labelText={
                    labelText
                        ? labelText
                        : t("forms:bid.wizard.grade", { grade: grade })
                }
                wrapperVariant={className}
                values={{ ["grade" + grade]: value }}
                min={minValue}
                positive={positive}
                formik={formik}
                grade={grade}
            />
        </BidWrapper>
    );
};

export default BidItem;
