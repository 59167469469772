import H1 from "./H1";
import H2 from "./H2";
import H3 from "./H3";

const Headings = {
    H1: H1,
    H2: H2,
    H3: H3
};

export default Headings;
