import produce from "immer";
import { useMemo, useReducer } from "react";

/**
 * Creates wrapped `useReducer`.
 *
 * @param {Any} initialState
 * @returns {[Any, Function]}
 */

const useImmerReducer = (reducer, initialState) => {
    const cachedReducer = useMemo(() => {
      return produce(reducer);
    }, [reducer]);
    return useReducer(cachedReducer, initialState);
};

export default useImmerReducer;
