import React, { useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ListingPage from "../../../../components/data/ListingPage";
import NullableCell from "../../../../components/data/NullableCell";
import useStateModule from "../../../../core/state/useStateModule";
import CertificateStatusCell from "./components/CertificateStatusCell";
import usersListStateModule from "../../../../core/state/admin/users/usersListStateModule";

const RecentUserListing = () => {
    const {
        users,
        isLoading,
        error,
        loadUsers,
    } = useStateModule(usersListStateModule);

    const { t } = useTranslation("screens");

    useEffect(() => {
        loadUsers({
            page: 1,
            pageSize: 6,
            orderColumn: "creationDate",
            descending: true,

        });
    }, [loadUsers]);

    const columns = useMemo(
        () => [
            {
                Header: t("userList.headers.fullName"),
                accessor: "fullName",
            },
            {
                Header: t("userList.headers.email"),
                accessor: "email",
            },
            {
                Header: t("userList.headers.fGasCertificate"),
                accessor: "certificationStatus",
                Cell: CertificateStatusCell,
            },
            {
                Header: t("userList.headers.companyName"),
                accessor: "companyName",
                Cell: NullableCell,
            },
        ],
        [t]
    );

    return (
        <>      
            <ListingPage
                columns={columns}
                wide={true}
                pwa={true}
                noHeader={true}
                tableState={{
                    data: users.data,
                    loading: isLoading,
                    error: error,
                }}
                paging={{
                    enablePaging: false,
                }}
                sorting={{
                    enableSorting: false,
                }}
            />
        </>
    );
};

export default RecentUserListing;
