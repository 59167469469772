import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import ModalWizard from "../../../components/modal/ModalWizard";
import AddCylinderWizard from "./components/AddCylinderWizard";
import TagsService from "../../../services/Tags/TagsService";
import CompanyService from "../../../services/Company/CompanyService";
import ClientsService from "../../../services/Clients/ClientsService";
import UserService from "../../../services/User/UserService";
import useDebouncedCallback from "../../../hooks/_shared/useDebouncedCallback";
import { toast } from "react-toastify";
import RouteCreator from "../../../core/RouteCreator";

const ScreeWrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    display: flex;
    flex-wrap: nowrap;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
`;

const AddCylinder = () => {
    const { t } = useTranslation("screens");
    const history = useHistory();
    const { UserId } = UserService.getUserDetailsFromToken();

    const [queryClientLocations] = useDebouncedCallback(
        async (clientName, updatePropertyBag) => {
            let clientLocations = [];
            const clients = await ClientsService.getClients({
                filter: { name: clientName },
            });
            if (clients.data.length > 0) {
                for (var i = 0; i < clients.data.length; i++) {
                    const locations = await ClientsService.getClientsLocations({
                        filter: { clientId: clients.data[i].clientId },
                    });
                    if (locations.data.length > 0) {
                        for (var loc = 0; loc < locations.data.length; loc++) {
                            locations.data[loc].clientName =
                                clients.data[i].clientName;
                            locations.data[loc].clientId =
                                clients.data[i].clientId;
                        }
                        clientLocations = [
                            ...clientLocations,
                            ...locations.data,
                        ];
                    }
                }
                updatePropertyBag({ locations: clientLocations });
            } else {
                updatePropertyBag({ locations: [] });
            }
            return [];
        }
    );

    const queryUnusedTags = async (tag) => {
        if (tag.length > 2) {
            const tags = await TagsService.lookupUnassignedTags(tag, UserId);
            return tags.map((t) => t.id);
        }
        return [];
    };

    useEffect(() => {
        const showWizard = async () => {
            const res = await CompanyService.getDistributors();
            const cylOwners = res.data?.data || [];
            ModalWizard.show(
                AddCylinderWizard(t,cylOwners, queryUnusedTags, queryClientLocations),
                {
                    onSubmit: async (values) => {
                        if (values.ownerCompanyId === "")
                        delete values.ownerCompanyId;
                        values.tagId = values.unusedTagId;
                        const result =
                            await CompanyService.createCompanyCylinderAsync(
                                ModalWizard.asFormData(values)
                            );
                        if (result && result.error)
                            ModalWizard.submitFailed(t(result.error.message));
                        else {
                            toast.success(
                                t("actions:dialogs.createCylinder.success")
                            );
                            ModalWizard.hide();
                            history.push(RouteCreator.pwa.cylinders());
                        }
                    },
                    onCancel: async () => {
                        ModalWizard.hidePrompt(() => history.goBack());
                    },
                },
                { pwa: true }
            );
        };
        showWizard();
    }, []);

    return <ScreeWrapper></ScreeWrapper>;
};

export default AddCylinder;
