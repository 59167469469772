import { useEffect } from "react";
import useDataReducer from "./useDataReducer";

const useDataReducerImmediate = (dataMethod, params = {}) => {
    const [data, loadData] = useDataReducer(dataMethod);
    useEffect(() => {
        loadData(params);
    }, []);

    return [data, loadData];
};

export default useDataReducerImmediate;
