import React from "react";
import styled from "styled-components";
import {
    BidGradeWrapper,
    BidGrade,
} from "../../../../../components/bids/BidGrade";
import InputElement from "../../../../../components/elements/form-elements/InputElement";

import {
    InfoWrapper,
    Separator,
} from "../../../../../components/modal/components/WizardStyling";
import PaymentType from "../../../../../core/enums/PaymentType";
import ValidationRule, {
    buildValidation,
} from "../../../../../core/validations/ValidationRule";

const defaultInitialValues = {
    paymentMethod: PaymentType.MainWalletToTransaction,
    rejectionReason: "",
};

const GreenInfo = styled.img`
    width: 40px;
    height: 40px;
    margin-right: 24px;
`;

const Title = styled.div`
    font-size: 18px;
    padding-left: 10px;
    margin-top: -10px;
`;

const Spacer = styled.div`
    width: 100%;
    height: ${(p) => p.size || 1}px;
`;

const validReason = (t) => {
    return buildValidation({
        rejectionReason: ValidationRule.isStringRule().maxCharactersRule(1000).required(
            t("actions:bidActions.rejectReasonRequired")
        ).rule,
    });
};

const WizardRejectBid = (t, currentLanguage, bid, offer) => {
    return {
        title: t("actions:rejectBid"),
        initialValues: defaultInitialValues,
        steps: [
            {
                name: t("actions:bidActions.rejectReason"),
                fieldNames: ["rejectionReason"],
                validation: validReason(t),
                fields: (formik, propertyBag) => {
                    return (
                        <>
                            <InfoWrapper>
                                <GreenInfo src="/images/menu/green-info.svg" />
                                <div>
                                    <div className="title">
                                        {t("actions:bidActions.rejectAbout")}
                                    </div>
                                    <div
                                        className="small"
                                        style={{ marginTop: "8px" }}
                                    >
                                        {t(
                                            "actions:bidActions.provideRejectReason"
                                        )}
                                    </div>
                                </div>
                            </InfoWrapper>

                            <Title>{`${t("actions:bidActions.totalBidFor")} ${
                                offer.refrigerantGasTypeCode
                            }:`}</Title>
                            <Separator className="black" />
                            <BidGradeWrapper className="center">
                                <BidGrade
                                    grade="a"
                                    showQuantity={true}
                                    info={t("forms:bid.wizard.buyersDeposit")}
                                    amount={bid.offeredPriceGradeA}
                                    quantity={offer.maximumQuantity}
                                    bidCompanyCountryId={
                                        bid.bidCompanyCountryId
                                    }
                                />
                                <BidGrade
                                    grade="b"
                                    showQuantity={true}
                                    amount={bid.offeredPriceGradeB}
                                    quantity={offer.maximumQuantity}
                                    bidCompanyCountryId={
                                        bid.bidCompanyCountryId
                                    }
                                />
                                <BidGrade
                                    grade="c"
                                    showQuantity={true}
                                    info={t("forms:bid.wizard.sellersDeposit")}
                                    amount={bid.offeredPriceGradeC}
                                    quantity={offer.maximumQuantity}
                                    bidCompanyCountryId={
                                        bid.bidCompanyCountryId
                                    }
                                />
                            </BidGradeWrapper>
                            <Spacer size="24" />
                            <InputElement
                                labelText={t("actions:bidActions.rejectReason")}
                                id="rejectionReason"
                                autoComplete="new-description"
                                name="rejectionReason"
                                type="textarea"
                                wrapperVariant="flex-1"
                                rows="4"
                                elementStyle={{height:"auto"}}
                                maxLength="999"

                                {...formik}
                            />
                        </>
                    );
                },
            },
        ],
    };
};

export default WizardRejectBid;
