import { createBrowserHistory } from "history";
import React, { useCallback } from "react";
import useImmerReducer from "../../hooks/_shared/useImmerReducer";
import rootReducer from "./rootReducer";

const history = createBrowserHistory();
const routerMiddleware = (history, dispatch) => action => {
    if (action.type !== "@@router/CALL_HISTORY_METHOD") {
        return dispatch(action);
    }

    const {
        payload: {method, args}
    } = action;
    history[method](...args);
};

const asyncer = (dispatch, state) => action =>
    typeof action === "function" ? action(dispatch, state) : dispatch(action);

const withDevTools = name => {
    return (
        name &&
        process.env.NODE_ENV === "development" &&
        typeof window !== "undefined" &&
        window.__REDUX_DEVTOOLS_EXTENSION__
    );
};
let devTool = null;
if (withDevTools) {
    if (window.__REDUX_DEVTOOLS_EXTENSION__)
        devTool = window.__REDUX_DEVTOOLS_EXTENSION__.connect();
}

// Reducer to handle devtools actions.
const devToolReducer = reducer => (state, action) => {
    const nextState = reducer(state, action);
    if (withDevTools && devTool) {
        devTool.send(action, state);
    }
    return nextState;
};

export const GlobalStore = React.createContext({});

export const GlobalStateProvider = ({children, _mockState}) => {
    const rootReducerWithDevTools = devToolReducer(rootReducer);

    const [state, dispatchBase] = useImmerReducer(
        rootReducerWithDevTools,
        rootReducer.initialState
    );

    const dispatch = useCallback(
        routerMiddleware(history, asyncer(dispatchBase, state)),
        []
    );

    return (
        <GlobalStore.Provider value={{ state : _mockState || state, dispatch}}>
            {children}
        </GlobalStore.Provider>
    );
};

export const useGlobalStore = () => React.useContext(GlobalStore);
