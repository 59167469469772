import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ClearableInput from "../../../../components/elements/form-elements/ClearableInput";
import Label from "../../../../components/elements/form-elements/Label";
import Filters from "../../../../components/filter/Filters";
import useDebouncedCallback from "../../../../hooks/_shared/useDebouncedCallback";

const Section = styled.div`
    display: flex;
`;

const LocationsFilter = ({ filterState, dispatch, actionTypes, actions }) => {
    const { t } = useTranslation("filters");

    const [locationChanged] = useDebouncedCallback((text) => {
        text
            ? dispatch({ type: actionTypes.SET_LOCATION_FILTER, payload: text })
            : dispatch({ type: actionTypes.RESET_LOCATION_FILTER });
    });

    const [addressChanged] = useDebouncedCallback((text) => {
        text
            ? dispatch({ type: actionTypes.SET_ADDRESS_FILTER, payload: text })
            : dispatch({ type: actionTypes.RESET_ADDRESS_FILTER });
    });

    const [cityChanged] = useDebouncedCallback((text) => {
        text
            ? dispatch({ type: actionTypes.SET_CITY_FILTER, payload: text })
            : dispatch({ type: actionTypes.RESET_CITY_FILTER });
    });

    const [postalCodeChanged] = useDebouncedCallback((text) => {
        text
            ? dispatch({
                  type: actionTypes.SET_POSTAL_CODE_FILTER,
                  payload: text,
              })
            : dispatch({ type: actionTypes.RESET_POSTAL_CODE_FILTER });
    });

    return (
        <Filters.Wrapper className="full spread">
            <Section>
                <Filters.Item>
                    <Label htmlFor="locationName" className="sr-only">
                        {t("clients.locationName")}
                    </Label>
                    <ClearableInput
                        key="locationName"
                        name="locationName"
                        type="text"
                        defaultValue={
                            filterState.locationName !== undefined
                                ? filterState.locationName
                                : ""
                        }
                        onChange={(evt) => {
                            locationChanged(evt.currentTarget.value);
                        }}
                        placeholder={t("clients.locationName")}
                        title={t("clients.locationName")}
                        className="filters"
                    />
                </Filters.Item>

                <Filters.Item>
                    <Label htmlFor="address" className="sr-only">
                        {t("clients.address")}
                    </Label>
                    <ClearableInput
                        key="address"
                        name="address"
                        type="text"
                        defaultValue={
                            filterState.address !== undefined
                                ? filterState.address
                                : ""
                        }
                        onChange={(evt) => {
                            addressChanged(evt.currentTarget.value);
                        }}
                        placeholder={t("clients.address")}
                        title={t("clients.address")}
                        className="filters"
                    />
                </Filters.Item>

                <Filters.Item>
                    <Label htmlFor="city" className="sr-only">
                        {t("clients.city")}
                    </Label>
                    <ClearableInput
                        key="city"
                        name="city"
                        type="text"
                        defaultValue={
                            filterState.city !== undefined
                                ? filterState.city
                                : ""
                        }
                        onChange={(evt) => {
                            cityChanged(evt.currentTarget.value);
                        }}
                        placeholder={t("clients.city")}
                        title={t("clients.city")}
                        className="filters"
                    />
                </Filters.Item>

                <Filters.Item>
                    <Label htmlFor="postalCode" className="sr-only">
                        {t("clients.postalCode")}
                    </Label>
                    <ClearableInput
                        key="postalCode"
                        name="postalCode"
                        type="text"
                        defaultValue={
                            filterState.postalCode !== undefined
                                ? filterState.postalCode
                                : ""
                        }
                        onChange={(evt) => {
                            postalCodeChanged(evt.currentTarget.value);
                        }}
                        placeholder={t("clients.postalCode")}
                        title={t("clients.postalCode")}
                        className="filters"
                    />
                </Filters.Item>
            </Section>
            <Section>
                {actions && actions}
            </Section>
        </Filters.Wrapper>
    );
};

export default LocationsFilter;
