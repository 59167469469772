import React from "react";
import styled from "styled-components";
import { Select } from "../../../../../components/elements/form-elements";
import InputElement from "../../../../../components/elements/form-elements/InputElement";
import { InfoText, InfoTitle, InfoWrapper, RoundInfo } from "../../../../../components/modal/components/WizardStyling";
import ValidationRule, { buildValidation } from "../../../../../core/validations/ValidationRule";
import MoneyInputElement from "../../../../../components/elements/form-elements/MoneyInputElement";
import TagsService from "../../../../../services/Tags/TagsService";

const SiteInfo = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 2%;
`;

const SiteTitle = styled.div`
    font-size: 20px;
    font-weight: 600;
    color: #48b05e;
    margin-bottom: 10px;
`;

const SiteSubTitle = styled.div`
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    margin-left: 10px;
    color: #000;
    &.light {
        color: #8e8e8e;
    }
`;

const SerialInfo = styled.div`
    font-size: 12px;
    font-weight: 600;
    color: #2294d2;
    margin: 0 2%;

    &.fail {
        color: #e94e4e;
    }

    &.info {
        font-weight: 400;
        color: #000;
        margin-bottom: 20px;
    }
`;

const validUnit = (t) => {
    return buildValidation({
        manufacturerId: ValidationRule.isStringRule().required(t("forms:manufacturer.required")).rule,
        applicationType: ValidationRule.isStringRule().required(t("forms:application.required")).rule,
        refrigerantId: ValidationRule.isStringRule().required(t("forms:gasType.required")).rule,
        capacity: ValidationRule.isNumber().required(t("forms:capacity.required")).rule,
    });
};

const validTagId = (t) => {
    return buildValidation({
        tagId: ValidationRule.isStringRule()
            .isUUID(t("forms:unit.uuid.invalid"))
            .required(t("forms:unit.unitTagId.required")).rule,
    });
};

const defaultInitialValues = {
    manufacturerId: "",
    applicationType: "",
    refrigerantId: "",
    capacity: "0.00",
    description: "",
    images: "",
    tagId: "",
    serialNumber: "",
    serialFound: "",
};

const applicationTypes = ["Residential", "Commercial", "Industrial"];

const UnitWizardAdd = (
    t,
    initialValues,
    clientData,
    manufacturers,
    refrigerants,
    searchFn = () => {},
    clientlocationData
) => {
    const locationData = clientlocationData.data;

    return {
        title: t("forms:clients.addUnit"),
        initialValues: initialValues || defaultInitialValues,
        steps: [
            {
                name: t("forms:clients.step3NameUnits"),
                fieldNames: ["tagId"],
                validation: validTagId(t),
                fields: (formik) => (
                    <>
                        <InfoWrapper>
                            <RoundInfo alt="" src="/images/menu/green-info.svg" />
                            <div>
                                <InfoTitle>{t("forms:unit.newUnitInfoTitle")}</InfoTitle>
                                <InfoText>
                                    {t("forms:unit.newUnitInfoText")}
                                    <br />
                                    {t("forms:unit.newUnitInfoText2")}
                                </InfoText>
                            </div>
                        </InfoWrapper>
                        <InputElement
                            labelText={t("forms:unitTagId.text")}
                            id="tagId"
                            name="tagId"
                            type="search"
                            searchFn={searchFn}
                            wrapperVariant="flex-1"
                            {...formik}
                        />
                    </>
                ),
            },
            {
                name: t("forms:clients.daikinSerial"),
                fieldNames: ["serialNumber"],
                fields: (formik) => (
                    <>
                        <SiteInfo>
                            <SiteTitle>{t("sidebar:title.installationSite")}</SiteTitle>
                            <SiteSubTitle>{locationData.locationName}</SiteSubTitle>
                            <SiteSubTitle className="light">
                                {`${locationData.street}, ${locationData.city}, ${locationData.countryName}`}
                            </SiteSubTitle>
                        </SiteInfo>
                        <InfoWrapper>
                            <RoundInfo alt="" src="/images/menu/green-info.svg" />
                            <div>
                                <InfoText className="dark">{t("forms:clients.daikinSerialInfo")}</InfoText>
                            </div>
                        </InfoWrapper>
                        <InputElement
                            labelText={t("forms:clients.daikinSerial")}
                            id="serialNumber"
                            name="serialNumber"
                            type="text"
                            wrapperVariant="flex-1"
                            {...formik}
                        />
                    </>
                ),
            },
            {
                name: t("forms:clients.step1NameUnits"),
                fieldNames: ["manufacturerId", "applicationType", "refrigerantId", "capacity"],
                validation: validUnit(t),
                onStepRender: async ({ formik }) => {
                    const serial = formik.values["serialNumber"];
                    if (!serial) return;
                    const result = await TagsService.getDaikinSerial(serial);
                    if (result.status !== 200) return;
                    const response = result.data.response;
                    if (response.message || !result.data.success) {
                        formik.setFieldValue("serialFound", "N");
                    } else {
                        formik.setFieldValue("serialFound", "Y");
                        const rfg = refrigerants.find((r) => r.code === response.refrigerantType);
                        if (rfg) {
                            formik.setFieldValue("refrigerantId", rfg.id);
                        }
                        const mfc = manufacturers.find((r) => r.name === "Daikin");
                        if (mfc) {
                            formik.setFieldValue("manufacturerId", mfc.id);
                        }
                        if(response.kWh) {
                            formik.setFieldValue("capacity", Number(response.kWh));
                        }
                    }
                },
                fields: (formik) => (
                    <>
                        <InfoWrapper className="full">
                            <SiteInfo>
                                <SiteTitle>{t("sidebar:title.installationSite")}</SiteTitle>
                                <SiteSubTitle>{locationData.locationName}</SiteSubTitle>
                                <SiteSubTitle className="light">
                                    {`${locationData.street}, ${locationData.city}, ${locationData.countryName}`}
                                </SiteSubTitle>
                            </SiteInfo>
                        </InfoWrapper>

                        {formik.values["serialFound"] && (
                            <>
                                <SerialInfo className={formik.values["serialFound"] === "N" ? "fail" : ""}>
                                    {formik.values["serialFound"] === "N"
                                        ? t("forms:clients.serialNotFound")
                                        : t("forms:clients.serialFound")}
                                </SerialInfo>
                                <SerialInfo className="info">{t("forms:clients.manualInput")}</SerialInfo>
                            </>
                        )}

                        <Select
                            id="manufacturerId"
                            name="manufacturerId"
                            labelText={t("forms:manufacturer.text")}
                            wrapperVariant="flex-2"
                            defaultItem={{
                                key: "all",
                                value: "",
                                label: t("forms:manufacturer.text"),
                            }}
                            options={
                                manufacturers && manufacturers.length > 0
                                    ? manufacturers.map((item) => {
                                          return {
                                              key: item.id,
                                              value: item.id,
                                              label: item.name,
                                          };
                                      })
                                    : []
                            }
                            {...formik}
                        />
                        <Select
                            id="applicationType"
                            name="applicationType"
                            labelText={t("forms:application.text")}
                            wrapperVariant="flex-2"
                            defaultItem={{
                                key: "all",
                                value: "",
                                label: t("forms:application.text"),
                            }}
                            options={applicationTypes.map((item, idx) => {
                                return {
                                    key: idx,
                                    value: idx.toString(),
                                    label: `${t("common:applicationType." + item.toLowerCase())}`,
                                };
                            })}
                            {...formik}
                        />

                        <Select
                            id="refrigerantId"
                            name="refrigerantId"
                            labelText={t("forms:gasType.text")}
                            wrapperVariant="flex-2"
                            defaultItem={{
                                key: "all",
                                value: "",
                                label: t("forms:gasType.text"),
                            }}
                            options={
                                refrigerants && refrigerants.length > 0
                                    ? refrigerants.map((item) => {
                                          return {
                                              key: item.id,
                                              value: item.id,
                                              label: item.code,
                                          };
                                      })
                                    : []
                            }
                            {...formik}
                        />

                        <MoneyInputElement
                            type="number"
                            name="capacity"
                            id="capacity"
                            labelText={t("forms:capacity.text")}
                            wrapperVariant={"flex-2 standard"}
                            values={2}
                            min={0}
                            step="0.01"
                            positive={true}
                            formik={formik}
                            currencyLabel="kW"
                        />
                    </>
                ),
            },
            {
                name: t("forms:clients.step4NameNotes"),
                fieldNames: ["description"],
                fields: (formik) => (
                    <>
                        <InfoWrapper className="full">
                            <SiteInfo>
                                <SiteTitle>{t("sidebar:title.installationSite")}</SiteTitle>
                                <SiteSubTitle>{locationData.locationName}</SiteSubTitle>
                                <SiteSubTitle className="light">
                                    {`${locationData.street}, ${locationData.city}, ${locationData.countryName}`}
                                </SiteSubTitle>
                            </SiteInfo>
                        </InfoWrapper>
                        <InputElement
                            labelText={t("forms:clients.notes")}
                            id="description"
                            autoComplete="new-description"
                            name="description"
                            type="textarea"
                            wrapperVariant="flex-1"
                            {...formik}
                        />
                    </>
                ),
            },
            {
                name: t("forms:clients.step2NameUnits"),
                fieldNames: ["images"],
                fields: (formik) => (
                    <>
                        <InputElement
                            labelText=""
                            placeholder={t("forms:userPicture.text")}
                            title={t("forms:clients.unitPictures")}
                            name="images"
                            autoComplete="new-images"
                            type="gallery"
                            id="images"
                            wrapperVariant="flex-1"
                            {...formik}
                        />
                    </>
                ),
            },
        ],
    };
};

export default UnitWizardAdd;
