import React from "react";
import { Redirect } from "react-router-dom";
import { InputSubmit } from "../../components/elements/form-elements";
import CompanyService from "../../services/Company/CompanyService";
import CountryService from "../../services/User/CountryService";
import Wrappers from "../../components/wrappers/Wrappers";
import { useTranslation } from "react-i18next";
import Headings from "../../components/elements/headings/Headings";
import useQueryString from "../../hooks/_shared/useQueryString";
import userStateModule from "../../core/state/user/userStateModule";
import useStateModule from "../../core/state/useStateModule";
import useDataReducerImmediate from "../../hooks/_shared/useDataReducerImmediate";
import RouteCreator from "../../core/RouteCreator";
import styled from "styled-components";
import { Helmet } from "react-helmet";

const ScreenWrapper = styled.div`
    width: 100%;
`;

const FormWrapper = styled.div`
    width: 500px;
    margin: 0 auto;
    border: 1px solid ${p => p.theme.primaryColor};
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
`;

const ActivateUserScreen = () => {
    const { t } = useTranslation();

    /* check if I'm logged in - this will be replaced/upgraded with the Claims and Roles */
    const { isAuthenticated, activation, activateUser } = useStateModule(
        userStateModule
    );

    let userID = useQueryString("userId");

    const [
        { isLoading: companyLoading, data: companyData },
    ] = useDataReducerImmediate(() =>
        CompanyService.getCompanyByUserID(userID)
    );

    const [{ data: countryData }] = useDataReducerImmediate(
        CountryService.getCountries
    );

    let countryName = null;
    if (countryData && companyData) {
        const filterCountry =
            countryData &&
            countryData.filter((c) => c.id === companyData.company.countryId);
        if (filterCountry) countryName = filterCountry[0].countryName;
    }
    const handleCompanyActivation = () => {
        activateUser({ userId: userID, activate: true });
    };

    if (!isAuthenticated) {
        return <Redirect to={RouteCreator.user.login()}></Redirect>;
    }

    if (activation.isCompleted && !activation.error) {
        return (
            <Wrappers.Wrapper>
                <Helmet>
                    <title>{t("seo:activateUser.title")}</title>
                </Helmet>
                <Wrappers.FormWrapper>
                    <p>User has been activated</p>
                </Wrappers.FormWrapper>
            </Wrappers.Wrapper>
        );
    } else {
        return (
            <ScreenWrapper>
                <Helmet>
                    <title>{t("seo:activateUser.title")}</title>
                </Helmet>
                <FormWrapper>
                    {companyLoading && <div>Loading...</div>}
                    {companyData && (
                        <>
                            <Headings.H1>
                                {t("screens:activateUser.title")}
                            </Headings.H1>
                            <div>
                                Company name:{" "}
                                <strong>
                                    {companyData.company.companyName}
                                </strong>
                                <br />
                                <br />
                            </div>
                            <div>
                                VAT:{" "}
                                <strong>{companyData.company.vatNumber}</strong>
                                <br />
                                <br />
                            </div>
                            <div>
                                IBAN:{" "}
                                <strong>{companyData.company.iban}</strong>
                                <br />
                                <br />
                            </div>
                            <div>
                                Licence number:{" "}
                                <strong>
                                    {companyData.company.licenceNumber}
                                </strong>
                                <br />
                                <br />
                            </div>
                            <div>
                                Email:{" "}
                                <strong>{companyData.company.email}</strong>
                                <br />
                                <br />
                            </div>
                            <div>
                                Phone:{" "}
                                <strong>
                                    {companyData.company.phoneNumber}
                                </strong>
                                <br />
                                <br />
                            </div>
                            <div>
                                Address:{" "}
                                <strong>{companyData.company.address}</strong>
                                <br />
                                <br />
                            </div>
                            <div>
                                City:{" "}
                                <strong>{companyData.company.city}</strong>
                                <br />
                                <br />
                            </div>
                            <div>
                                Region:{" "}
                                <strong>{companyData.company.region}</strong>
                                <br />
                                <br />
                            </div>
                            <div>
                                Country: <strong>{countryName}</strong>
                            </div>
                            <br /> <br />
                            {!activation.isLoading && (
                                <InputSubmit
                                    onClick={handleCompanyActivation}
                                    value={t("screens:activateUser.submit")}
                                    variant="primary"
                                />
                            )}
                        </>
                    )}
                </FormWrapper>
            </ScreenWrapper>
        );
    }
};

export default ActivateUserScreen;
