import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import Flex from "../../components/layout/flex/Flex";
import LayoutOneTwo from "../../components/layout/split/one-two/LayoutOneTwo";
import ModalDialog, { useModal } from "../../components/modal/ModalDialog";
import Roles from "../../core/enums/Roles";
import RouteCreator from "../../core/RouteCreator";
import userStateModule from "../../core/state/user/userStateModule";
import useStateModule from "../../core/state/useStateModule";
import globals from "../../globalVars";
import useQueryString from "../../hooks/_shared/useQueryString";
import UserService from "../../services/User/UserService";
import LoginForm from "./components/LoginForm";
import { toast } from "react-toastify";
import SelectCompanyForm from "./components/SelectCompanyForm";
import useLeftMenu from "../../core/state/navigation/useLeftMenu";
import useSharedState from "../../hooks/_shared/useSharedState";
import { useHistory } from "react-router-dom";
import Color from "color";
import { Checkbox } from "../../components/elements/form-elements";

const Desc = styled.div`
    width: 100%;
    min-height: 20px;
    font-size: 14px;
    color: #898989;
    text-align: center;
    @media ${(p) => p.theme.device.mobile} {
        font-size: 10px;
    }
`;

const LoggedInOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${(props) => Color(props.theme.bgOverlay).string()};
    z-index: 11;
`;

export const KYCWarn = styled.div`
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 40px;
`;

export const KYCInfo = styled.div`
    font-size: 12px;
    font-weight: 400;
    text-align: justify;
`;

const LoginState = {
    Pending: -1,
    LoggedIn: 0,
    UserCertWarning: 1,
    KYCWarning: 2,
    Redirect: 3,
};

const LoginScreen = () => {
    const returnUrl = useQueryString("returnUrl", "/");
    const queryUsername = useQueryString("username");
    const verifyToken = useQueryString("token");
    const ssoFailed = useQueryString("ssoFailed");
    const { t } = useTranslation("screens");
    const userPassword = useRef();
    const [globalState] = useSharedState("global");
    const history = useHistory();
    const { setIsVisible } = useLeftMenu();

    const [curState, setCurState] = useState(LoginState.Pending);
    const [chkWarning, setChkWarning] = useState(false);

    const {
        loginState: { error: errorText, isLoading },
        isAuthenticated,
        twoFactor,
        login,
        logout,
        username,
        verifyTwoFactor,
        twoFactorVerify,
        clearLoginErrors,
        userCompanies,
    } = useStateModule(userStateModule);


    useEffect(() => {
        setIsVisible(false);
        if(ssoFailed !== null) toast.error(t("screens:login.ssoFailed"))
    }, []);

    const { modalProps, toggleOpen } = useModal({
        title: t("actions:dialogs.kycWarning.title"),
        onConfirm: async () => {
            toggleOpen();
            history.push(RouteCreator.company.kyc());
        },
        onCancel: () => {
            toggleOpen();
            setCurState(LoginState.Redirect);
        },
        confirmText: t("actions:dialogs.kycWarning.submit"),
    });

    const { modalProps: certModalProps, toggleOpen: certToggleOpen } = useModal(
        {
            title: t("screens:certificates.userCert.missing"),
            onConfirm: async () => {
                const hide = chkWarning === true;
                if (UserService.isInRole(Roles.InstallerCompanyAdmin) && hide) {
                    await UserService.updateShowWarningsSetting(false);
                }
                certToggleOpen();
                const userId = UserService.getUserDetailsFromToken().UserId;
                history.push(`${RouteCreator.employee.edit(userId)}?tab=1`);
            },
            onCancel: async () => {
                const hide = chkWarning === true;
                if (UserService.isInRole(Roles.InstallerCompanyAdmin) && hide) {
                    await UserService.updateShowWarningsSetting(false);
                }
                certToggleOpen();
                setCurState(
                    UserService.getUserDetailsFromToken().IsKYCValidated ===
                        "False" &&
                        (UserService.isInRole(Roles.InstallerCompanyAdmin) ||
                            UserService.isInRole(Roles.InstallerCompanyUser))
                        ? LoginState.KYCWarning
                        : LoginState.Redirect
                );
            },
            confirmText: t("screens:certificates.userCert.submitCert"),
        }
    );

    useEffect(() => {
        if (queryUsername && verifyToken) {
            verifyTwoFactor(queryUsername, verifyToken);
        }
    }, [queryUsername, verifyToken, verifyTwoFactor]);

    useEffect(() => {
        if (isAuthenticated && globalState.userLoaded) {
            setCurState(LoginState.LoggedIn);
        }
    }, [isAuthenticated, globalState.userLoaded]);

    useEffect(() => {
        switch (curState) {
            case LoginState.LoggedIn:
                if (globals.isMobile) {
                    setCurState(LoginState.Redirect);
                    return;
                }
                if (
                    !globalState.hasValidUserCertificate &&
                    !UserService.isInRole(Roles.MasterAdmin) &&
                    !UserService.isInRole(Roles.Admin) &&
                    (!UserService.isInRole(Roles.InstallerCompanyAdmin) ||
                        (UserService.isInRole(Roles.InstallerCompanyAdmin) &&
                            globalState.showCertificateWarning))
                ) {
                    setCurState(LoginState.UserCertWarning);
                    return;
                } else if (
                    UserService.getUserDetailsFromToken().IsKYCValidated ===
                        "False" &&
                    (UserService.isInRole(Roles.InstallerCompanyAdmin) ||
                        UserService.isInRole(Roles.InstallerCompanyUser))
                ) {
                    setCurState(LoginState.KYCWarning);
                    return;
                } else {
                    setCurState(LoginState.Redirect);
                }

                break;
            case LoginState.UserCertWarning:
                certToggleOpen();
                break;
            case LoginState.KYCWarning:
                toggleOpen();
                break;
            case LoginState.Redirect:
                history.push(returnUrl);
                break;
            default:
                break;
        }
    }, [curState]);

    if (queryUsername && verifyToken) {
        twoFactor.isRequired = true;
    }
    if (errorText && errorText.redirectUrl) {
        toast.error(`${t("exceptions:exception.PasswordExpired")}`);
        return (
            <Redirect
                to={errorText.redirectUrl.replace(/https?:\/\/[^\/]+/i, "")}
            ></Redirect>
        );
    }

    const hamdleNoCertWarning = (ev) => {
        setChkWarning(ev.target.checked);
    };

    return (
        <>
            <Flex.Container className="full-height">
                <Helmet>
                    <title>{t("seo:login.title")}</title>
                </Helmet>
                {isAuthenticated && <LoggedInOverlay />}
                <LayoutOneTwo>
                    {userCompanies.length === 0 ? (
                        <>
                            <LoginForm
                                clearErrors={clearLoginErrors}
                                login={login}
                                logout={logout}
                                username={username}
                                userPassword={userPassword.current}
                                twoFactor={twoFactor}
                                disabled={
                                    isLoading || twoFactorVerify.isLoading
                                }
                                onSubmit={(values) => {
                                    userPassword.current = values.password;
                                    login(values.email, values.password);
                                }}
                                verifyOnSubmit={(values) => {
                                    verifyTwoFactor(username, values.code);
                                }}
                                errorText={errorText || twoFactorVerify.error}
                            />
                            <Desc></Desc>
                        </>
                    ) : (
                        <SelectCompanyForm
                            companies={userCompanies}
                        ></SelectCompanyForm>
                    )}
                </LayoutOneTwo>
            </Flex.Container>

            <ModalDialog {...modalProps} opaque>
                <KYCWarn>{t("actions:dialogs.kycWarning.text")}</KYCWarn>
                <KYCInfo>{t("actions:dialogs.kycWarning.info")}</KYCInfo>
            </ModalDialog>

            <ModalDialog {...certModalProps} opaque>
                <KYCWarn>
                    {t("screens:certificates.userCert.verification")}
                </KYCWarn>
                <KYCInfo>{t("screens:certificates.userCert.allTech")}</KYCInfo>
                <div style={{ width: "100%", height: "20px" }} />
                <KYCInfo>
                    {t("screens:certificates.userCert.requirement")}
                </KYCInfo>
                <div style={{ marginTop: "20px" }} />
                {UserService.isInRole(Roles.InstallerCompanyAdmin) && (
                    <Checkbox
                        labelText={t("actions:dontShowAgain")}
                        id="acceptTerms"
                        name="acceptTerms"
                        className="flex form"
                        wrapperVariant="flex-1"
                        onChange={(ev) => hamdleNoCertWarning(ev)}
                        checked={chkWarning}
                    />
                )}
            </ModalDialog>
        </>
    );
};

export default LoginScreen;
