import styled from "styled-components";

export const InfoWrapper = styled.div`
    flex-basis: 100%;
    margin: 20px 2% 0 2%;
    display: flex;
    position: relative;
    margin-bottom: 40px;
    min-height: 80px;

    &.full {
        margin: 0;
        margin-bottom: 20px;
    }

    &.left {
        margin: 20px 2% 0 -15px;
    }

    &.pwa {
        min-height: 0px;
        margin: 40px 2% 40px 2%;
    }

    &.mv16 {
        margin-top: 16px;
        margin-bottom: 16px;
    }

    &:before {
        content: "";
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 100%;
        height: 1px;
        border-top: 1px solid #dddddd;
    }

    &.tight-border:before {
        bottom: 0px;
    }

    &.no-border:before {
        border-top:1px solid transparent;
    }

    &.column {
        flex-direction: column;
    }

    &.right {
        text-align:end;
    }

    & .content {
        padding: 0px 30px;
    }

    & .title {
        font-size: 16px;
        font-weight: 700;
    }

    & .small {
        font-size: 12px;
        font-weight: 400;
        color: #707070;
    }

    & .keyval {
        display: flex;
        justify-content: space-between;
    }

    &.tight {
        margin-bottom: 22px;
    }
`;

export const PrimarySpan = styled.span`
    color:${p=>p.theme.primaryColor};
`;

export const Separator = styled.div`
    width: 96%;
    height: 1px;
    border-top: 1px solid #dddddd;
    margin: 5px 0px 5px 2%;

    &.indent {
        width: 90%;
        margin: 5px auto;
    }

    &.black {
        border-color: #000;
    }
`;

export const KeyText = styled.div`
    color: #898989;
    font-size: 12px;
    &.big {
        font-size:16px;
    }

    &.primary {
        color:${p=>p.theme.primaryColor};
    }

    &.inset {
        padding-left:20px;
    }
`;

export const ValueText = styled.div`
    color: #000;
    font-weight: 700;
    font-size: 12px;

    &.big {
        font-size:16px;
    }

    &.primary {
        color:${p=>p.theme.primaryColor};
    }

    &.left {
        margin-left: -30px
    }

    &.light {
        color: #898989;
    }

    &.warning {
        color:#e69221;
    }
    
    &.danger {
        color:#E94E4E;
    }
`;

export const VSpace = styled.div`
    width: 100%;
    height: ${(p) => p.size || 0}px;
`;

export const RoundInfo = styled.img`
    width: 36px;
    height: 36px;
    min-height: 36px;
    min-width: 36px;
    margin-right: 16px;
`;

export const InfoTitle = styled.div`
    font-size: 1rem;
    color: #000;
    flex-grow: 1;
    font-weight: bold;
    line-height: 16px;
    display: block;
    margin: 8px 0px 15px;

    &.tight {
        padding: 0px 15px;
    }

    &.pwa {
        text-align:center;
        margin-top: 35px;
    }

    &.primary {
        color: ${props => props.theme.primaryColor};
        font-size: 1.25rem;
    }

    label.checkbox {
        vertical-align: super !important;
        font-size: 1rem;
    }
`;

export const InfoText = styled.div`
    font-size: 0.75rem;
    color: #898989;
    flex-grow: 1;
    font-weight: normal;
    line-height: 1rem;
    margin-bottom: 15px;

    &.dark {
        color: #000;
    }

    &.pwa {
        text-align: right;
    }

    > span {
        color: #000;
    }

    &.larger {
        font-size: 0.875rem;
        font-weight: 500;
    }
    &.justify {
        text-align:justify;
    }
`;

export const ErrorMessage = styled.div`
    color: ${props => props.theme.error};
    margin: 0 2% 16px;
`;
