import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import RouteCreator from "../../../../core/RouteCreator";
import useClickAway from "../../../../hooks/_shared/useClickAway";
import UserService from "../../../../services/User/UserService";
import UserMenu from "../../../user-menu/UserMenu";

const UserWrapper = styled.div`
    margin: 0 60px 0 0px;
    @media ${(p) => p.theme.device.mobile} {
        margin: 0 15px 0 auto;
    }
`;

const UserAction = styled.div`
    background: transparent;
    /* box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.14); */
    height: auto;
    padding: 8px 32px 6px 8px;
    border-radius: 16px;
    /* min-width: 160px; */
    margin: 0;
    position: relative;
    cursor: pointer;
    font-size: 14px;

    &:before {
        content: "";
        background: transparent;
        border-radius: 50%;
        position: absolute;
        right: 5px;
        top: 5px;
        width: 20px;
        height: 20px;
    }

    &:after {
        content: "";
        background: url("/images/menu/expand_thin.svg") no-repeat center;
        background-size: 12px 10px;
        /* transition: all 0.5s ease; */
        color: ${(props) => props.theme.textWhite};
        position: absolute;
        right: 5px;
        top: 6px;
        width: 20px;
        height: 20px;
        text-align: center;
    }

    & div.username {
        width: 100%;
        text-align: right;
    }

    &:hover div.username {
        color: ${(p) => p.theme.primaryColorHover};
    }

    &.opened {
        &:after {
            transform: rotate(-180deg);
            right: 5px;
            top: 4px;
        }
    }
`;

const HeaderUserArea = ({ avatarUrl, name, userFullName, email }) => {
    const [menuIsOpened, setMenuIsOpened] = useState(false);
    const menuRef = useRef(null);
    const { t } = useTranslation("header");
    const {
        UserId,
        CompanyName,
        CompanyId,
    } = UserService.getUserDetailsFromToken();


    useClickAway(menuRef, () => {
        setMenuIsOpened(false);
    });

    const handleToggle = () => {
        setMenuIsOpened(!menuIsOpened);
    };

    const userLinks = [
        {
            name: "retradeables.com",
            link: "https://retradeables.com/",
            external:true
        }
    ];

    return (
        <UserWrapper>
            <UserAction
                ref={menuRef}
                onClick={handleToggle}
                className={menuIsOpened ? "opened" : ""}
            >
                <div className="username">
                    {t("header.helloWord")} {name}
                </div>

                <UserMenu
                    userLink={
                        CompanyId
                            ? RouteCreator.employee.edit(UserId)
                            : RouteCreator.admin.edit(UserId)
                    }
                    isOpened={menuIsOpened}
                    links={userLinks}
                    email={email}
                    company={CompanyName}
                    companyLink={RouteCreator.company.details()}
                    avatar={avatarUrl}
                    userFullName={userFullName}
                />
            </UserAction>
        </UserWrapper>
    );
};
export default HeaderUserArea;
