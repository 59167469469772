import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";
import { Label } from "./index";

const checkedStyles = css`
    background: ${(props) => props.theme.checkBoxActiveBgColor};
    border: 1px solid ${(props) => props.theme.primaryColor};

    &:after {
        content: "";
        background: url("/images/actions/checkbox.svg") no-repeat center;
        background-size: 12px 10px;
        position: absolute;
        top: 0;
        left: -1px;
        width: 20px;
        height: 18px;
    }
`;

const uncheckedStyles = css`
    border: 1px solid ${(props) => props.theme.primaryInputBorderColor};
`;

const StyledCheckbox = styled.div`
    display: inline-block;
    width: 20px;
    min-width: 20px;
    height: 20px;
    min-height: 20px;
    position: relative;
    background: ${(props) =>
        props.checked
            ? props.theme.checkBoxActiveBgColor
            : props.theme.checkBoxDefaultBgColor};
    border-radius: 4px;
    transition: all 150ms;
    margin: 0 5px 0 0;
    ${(props) => (props.checked ? checkedStyles : uncheckedStyles)};
    box-shadow: 2px 2px 4px 0 rgba(34, 34, 34, 0.29);
    ${(props) =>
        props.disabled &&
        css`
            opacity: 0.25;
        `}

    & *:hover {
        cursor: pointer;
    }

    &.error {
        border-color: ${(props) => props.theme.error};
    }
`;

const CheckboxContainer = styled.div`
    display: inline-block;
    vertical-align: middle;
    position: relative;

    &.flex {
        flex-basis: 100%;
    }

    &.form {
        margin: 0 0 20px;

        label {
            vertical-align: top;
        }
    }

    &.ml-10 {
        margin-left: 10px;
    }
    &.mt-20 {
        margin-top: 20px;
    }

    &.mb-20 {
        margin-bottom: 20px;
    }

    & *:hover {
        cursor: pointer;
    }
    &.indeterminate {
        ${StyledCheckbox} {
            position: relative;
            background: ${(props) => props.theme.checkBoxActiveBgColor};

            &:after {
                content: "";
                width: calc(100% - 8px);
                height: 2px;
                background-color: ${(props) => props.theme.primaryColor};
                position: absolute;
                top: 50%;
                left: 4px;
                transform: translateY(-50%);
            }
        }
    }

    &.intable {
        position: absolute;
        top: -11px;
    }

    &.centered > div {
        display: flex;
        flex-direction: row;
    }
    &.centered label {
        margin-top: 5px;
    }
`;

const CheckboxHeader = styled.div`
    margin: 0px 0 12px 12px;
    transition: all 0.5s ease-in;
    color: #000000;
    display: inline-block;
    font-size: 0.875rem;
    font-weight: bold;
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    z-index: 10;
    opacity: 0;

    &.centered {
        top: 30px;
    }

    &:hover:enabled {
        + ${StyledCheckbox} {
            border-color: ${(props) => props.theme.primaryColor};
        }
    }
`;

const CheckboxWrapper = styled.div`
    width: 100%;
    display: flex;
`;

const Description = styled.div`
    font-size: 0.75rem;
    color: #898989;
    flex-grow: 1;
    font-weight: normal;
    line-height: 1rem;
    position: relative;
    left: 40px;
`;

const FormikCheckbox = (props, ref) => {
    let {
        className,
        id,
        labelText,
        values,
        name,
        handleChange,
        header,
        labelClassName = "",
        description,
        errors,
        touched,
        style,
        ...rest
    } = props;

    let value = values && values[name];
    let error = errors ? errors[name] : null;
    let checked = rest.checked || value;

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    return (
        <CheckboxContainer className={className} style={style} onClick={stopPropagation}>
            {header && <CheckboxHeader>{header}</CheckboxHeader>}
            <HiddenCheckbox
                className={className}
                value={value}
                name={name}
                defaultChecked={checked}
                id={id}
                onChange={rest.onChange || handleChange}
                ref={ref}
                {...rest}
            />
            <CheckboxWrapper>
                <StyledCheckbox
                    disabled={props.disabled}
                    checked={checked}
                    className={`${error ? "error" : ""}`}
                />
                <Label
                    htmlFor={id}
                    className={`checkbox ${labelClassName}`}
                    for={id}
                >
                    {labelText}
                </Label>
            </CheckboxWrapper>

            {description && (
                <Description>
                    {description.map((desc, i) => {
                        return (
                            <React.Fragment key={`desc${i}`}>
                                {desc} <br />
                            </React.Fragment>
                        );
                    })}
                </Description>
            )}
        </CheckboxContainer>
    );
};

export default React.forwardRef(FormikCheckbox);

FormikCheckbox.propTypes = {
    name: PropTypes.any.isRequired,
    values: PropTypes.any.isRequired,
    handleChange: PropTypes.func.isRequired,
};
