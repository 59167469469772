import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import {
    InfoText,
    InfoTitle,
    InfoWrapper,
} from "../../../../../components/modal/components/WizardStyling";

const WizardClientDetails = (props) => {
    const {t} = useTranslation('forms');
    const {clientData} = props;

    if (!clientData) {
        return null;
    }

    const currentClientData = clientData.data;

    return (
        <InfoWrapper className="column">
            <InfoTitle className="primary">
            {currentClientData && currentClientData.clientName}
            </InfoTitle>
            <InfoText className="larger">
                <span>{t('account.vatNumber')}:</span> &nbsp;
                {currentClientData ?
                    currentClientData.vatNumber : '/'}
            </InfoText>
            <InfoText className="larger">
                <span>{t('location.text')}:</span>&nbsp;
                {currentClientData ?
                    currentClientData.locationName : '/'}
            </InfoText>
        </InfoWrapper>
    )
};

const WizardCylinderDetails = (props) => {
    const {t} = useTranslation('forms');
    const {cylData} = props;

    return (
        <InfoWrapper className="column right">
            <InfoTitle className="primary">
                {t("qualityTest.cylinder")}
            </InfoTitle>
            <InfoText className="larger">
                <span>{t('cylinderSize.text')}:</span> &nbsp;
                {cylData?.size ?
                    cylData.size : '/'}
            </InfoText>
            <InfoText className="larger">
                <span>{t('quantity.text')}:</span>&nbsp;
                {cylData?.filled ?
                    cylData.filled: '/'}
            </InfoText>
        </InfoWrapper>
    )
};

export default WizardClientDetails;
export {WizardCylinderDetails};

WizardClientDetails.propTypes = {
    clientData: PropTypes.any.isRequired
}
