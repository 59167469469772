import React from 'react';
import styled from "styled-components";
import Module from "./Module";

const ModuleWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    max-width: 540px;
    flex-wrap: wrap;
    margin:  0 auto;

    @media (min-width:1134px) {
        max-width: 1080px;
    }

    @media (min-width:1850px) {
        max-width: 1620px;
    }

`;

const ModuleCards = ({modules}) => {

    const modulesToShow =  modules?  modules.filter(m => m.shouldShowModule) :[];
    return (
        <ModuleWrapper>
            {
                modulesToShow.length> 0 &&
                (
                    modulesToShow.map(m => {
                        return <Module variant={modulesToShow.length>5 ? "small": null} popupEnabled={false} key={m.name} name={m.name} image={m.image} link={m.link} title={m.title} desc={m.desc} disabled={m.disabled}/>
                    })
                )
            }
        </ModuleWrapper>
    )
};

export default ModuleCards;
