
import styled from "styled-components";
import React from "react";

const ItemList = styled.ul`
list-style: none;
padding: 0;
`;

const Item = styled.li`
width: 100%;
padding: 2px 0px; 
`;

const TooltipItemList = ({
    items,
    isInt,
    unit
}) => {
    return (
        <ItemList>
            {
               items.map((item) =>
                (
                    <Item
                        key={ `Tooltip_ID_${item.id}: `}
                    >
                        <span>
                            {
                                `${item.name}: `
                            }
                            {
                                `${isInt ? item.value : item.value.toFixed(2)} ${unit ? unit : ""}`
                            }
                        </span>

                    </Item>
                ))
            }
        </ItemList>
    );
};
export default TooltipItemList;