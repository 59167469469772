import React from "react";
import styled from "styled-components";
import { ReactComponent as HandlingSvg } from "../../assets/handling-costs.svg";
import { ReactComponent as NoHandlingSvg } from "../../assets/no-handling-costs.svg";

const Wrapper = styled.div`
    width: 100%;
`;

const StyledHandling = styled(HandlingSvg)`
    width: 16px;
    height: 16px;
    margin: 0 auto;
    transition: all 300ms ease;
    &:hover {
        transform: scale(1.3);
    }
`;

const StyledNoHandling = styled(NoHandlingSvg)`
    width: 16px;
    height: 16px;
    margin: 0 auto;
    transition: all 300ms ease;
    &:hover {
        transform: scale(1.3);
    }
`;

const HandlingCell = (value, setOpenAccordionId = null, accordionId = null) => {
    const handleClick = () => {
        if (!accordionId || !setOpenAccordionId) return;
        setOpenAccordionId(accordionId);
    };

    return (
        <Wrapper>
            {value > 0 ? (
                <StyledHandling onClick={handleClick} />
            ) : (
                <StyledNoHandling onClick={handleClick} />
            )}
        </Wrapper>
    );
};

export default HandlingCell;
