import { InfoWindow, Marker } from "@react-google-maps/api";
import React, { useCallback, useMemo, useState } from "react";
import MapBounds from "./MapBounds";

const LocationMarker = ({ location, withBounds = false }) => {
    const [infoOpen, setInfoOpen] = useState(false);
    const markerClick = useCallback(() => {
        setInfoOpen((open) => !open);
    }, [setInfoOpen]);

    const onCloseClick = useCallback(() => {
        setInfoOpen(false);
    }, [setInfoOpen]);

    const latLng = useMemo(() => {
        return { lat: location.latitude, lng: location.longitude };
    }, [location.latitude, location.longitude]);

    if (!location || !location.latitude) {
        return null;
    }

    const boundsMarkers = [];   
    if (withBounds) {
        boundsMarkers.push( new window.google.maps.Marker({
            position: latLng,
        }));
    }

    return (
        <>
            <Marker onClick={markerClick} position={latLng}></Marker>
            {withBounds && <MapBounds markers={boundsMarkers} />}
            {infoOpen && (
                <InfoWindow onCloseClick={onCloseClick} position={latLng}>
                    <div>
                        Location name: {location.locationName}
                        <br />
                        Address: {location.address || location.street}{" "}
                        {location.postalCode} {location.city}
                        <br />
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`https://www.google.com/maps/dir//${
                                location.address || location.street
                            },${location.postalCode || ""},${
                                location.city || ""
                            }`}
                        >
                            Get Directions
                        </a>
                    </div>
                </InfoWindow>
            )}
        </>
    );
};
export default LocationMarker;
