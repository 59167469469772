import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import ListingPage from "../../../../components/data/ListingPage";
import { StyledBackIcon } from "../../../../components/elements/buttons/BackButton";
import ModalWizard from "../../../../components/modal/ModalWizard";
import AuthorizeComponent from "../../../../core/AuthorizeComponent";
import Roles from "../../../../core/enums/Roles";
import RouteCreator from "../../../../core/RouteCreator";
import clientsLocationListStateModule from "../../../../core/state/clients/clientsLocationsListStateModule";
import useBreadcrumbs from "../../../../core/state/navigation/useBreadcrumbs";
import useStateModule from "../../../../core/state/useStateModule";
import useImmerReducer from "../../../../hooks/_shared/useImmerReducer";
import usePageChange from "../../../../hooks/_shared/usePageChange";
import usePromises from "../../../../hooks/_shared/usePromises";
import ClientsService from "../../../../services/Clients/ClientsService";
import TagsService from "../../../../services/Tags/TagsService";
import ClientsBreadcrumbs from "../../columns/ClientsBreadcrumbs";
import ClientsColumns from "../../columns/ClientsColumns";
import ClientDetails from "./details/ClientDetails";
import LocationsFilter from "./LocationsFilter";
import ClientLocationWizard from "./wizard/ClientLocationWizard";
import UnitWizardAdd from "./wizard/UnitWizardAdd";
import useDataReducerImmediate from "../../../../hooks/_shared/useDataReducerImmediate";
import ActionButton from "../../../../components/elements/buttons/ActionButton";
import ModalDialog, { useModal } from "../../../../components/modal/ModalDialog";
import { Helmet } from "react-helmet";

const actionTypes = {
    SET_ADDRESS_FILTER: "SET_ADDRESS_FILTER",
    RESET_ADDRESS_FILTER: "RESET_ADDRESS_FILTER",

    SET_CITY_FILTER: "SET_CITY_FILTER",
    RESET_CITY_FILTER: "RESET_CITY_FILTER",

    SET_LOCATION_FILTER: "SET_LOCATION_FILTER",
    RESET_LOCATION_FILTER: "RESET_LOCATION_FILTER",

    SET_POSTAL_CODE_FILTER: "SET_POSTAL_CODE_FILTER",
    RESET_POSTAL_CODE_FILTER: "RESET_POSTAL_CODE_FILTER",

    SET_COUNTRY_FILTER: "SET_COUNTRY_FILTER",
    RESET_COUNTRY_FILTER: "RESET_COUNTRY_FILTER",

    SET_CLIENT_ID: "SET_CLIENT_ID",
    RESET_CLIENT_ID: "RESET_CLIENT_ID",

    SET_SORT: "SET_SORT",
    RESET_SORT: "RESET_SORT",

    SET_PAGING: "SET_PAGING",
};

const ListingHeader = styled.div`
    position: relative;
    width: 100%;
`;

const locationsReducer = (state, action) => {
    if ((action.type !== actionTypes.SET_PAGING) & (action.type !== actionTypes.SET_SORT))
        state.page = 1;
    switch (action.type) {
        case actionTypes.SET_PAGING:
            state.pageSize = action.payload.pageSize;
            state.page = action.payload.pageIndex + 1;
            break;
        case actionTypes.SET_SORT:
            state.orderColumn = action.payload[0].id;
            state.descending = action.payload[0].desc;
            break;
        case actionTypes.RESET_SORT:
            state.orderColumn = "";
            state.descending = true;
            break;
        case actionTypes.SET_CLIENT_ID:
            state.filter.clientId = action.payload;
            break;
        case actionTypes.RESET_CLIENT_ID:
            delete state.filter.clientId;
            break;
        case actionTypes.SET_LOCATION_FILTER:
            state.filter.locationName = action.payload;
            break;
        case actionTypes.RESET_LOCATION_FILTER:
            delete state.filter.locationName;
            break;
        case actionTypes.SET_ADDRESS_FILTER:
            state.filter.address = action.payload;
            break;
        case actionTypes.RESET_ADDRESS_FILTER:
            delete state.filter.address;
            break;
        case actionTypes.SET_CITY_FILTER:
            state.filter.city = action.payload;
            break;
        case actionTypes.RESET_CITY_FILTER:
            delete state.filter.city;
            break;
        case actionTypes.SET_POSTAL_CODE_FILTER:
            state.filter.postalCode = action.payload;
            break;
        case actionTypes.RESET_POSTAL_CODE_FILTER:
            delete state.filter.postalCode;
            break;
        case actionTypes.SET_COUNTRY_FILTER:
            state.filter.country = action.payload;
            break;
        case actionTypes.RESET_COUNTRY_FILTER:
            delete state.filter.country;
            break;
        default:
            return state;
    }
};

const ClientLocationsListing = ({ match }) => {
    const [selectedLocation, setSelectedLocation] = useState(null);
    const history = useHistory();
    const { t } = useTranslation(["screens", "sidebar"]);
    const {
        clientsLocations,
        isLoading: locationsIsLoading,
        error: locationsError,
        loadClientsLocations,
        countryData,
        loadCountries,
        deleteClientLocation,
        gasTypesData,
        loadGasTypes,
    } = useStateModule(clientsLocationListStateModule);

    useEffect(() => {
        loadGasTypes();
    }, [loadGasTypes]);

    const wizardData = usePromises([() => ClientsService.getUnitManufacturersAsync()], (res) => ({
        manufacturers: res[0].data.data,
    }));

    const [currentClientData] = useDataReducerImmediate(
        ClientsService.getClientById,
        match.params.clientId
    );

    useEffect(() => {
        loadCountries();
    }, [loadCountries]);

    const [locationsState, locationsDispatch] = useImmerReducer(locationsReducer, {
        filter: {
            clientId: match.params.clientId,
        },
        page: 1,
        pageSize: 10,
        orderColumn: "locationName",
        descending: false,
    });

    const { modalProps, toggleOpen } = useModal({
        title: t("actions:dialogs.deleteLocation.title"),
        onConfirm: async (locationId) => {
            const result = await deleteClientLocation(locationId);
            if (result && result.error) toast.error(t(result.error.message));
            else {
                toast.success(t("actions:dialogs.deleteLocation.success"));
            }
            loadClientsLocations(locationsState);
            toggleOpen();
        },
        onCancel: () => {
            toggleOpen();
        },
        confirmText: t("actions:dialogs.deleteUnit.confirmText"),
    });

    const toggleClientLocationEdit = async (clientId) => {
        const result = await ClientsService.getClientLocationByIdAsync(clientId);
        if (result && result.error) toast.error(t(result.error.message));
        else {
            ModalWizard.show(
                ClientLocationWizard(
                    t,
                    result.data.data[0],
                    countryData.countries,
                    currentClientData,
                    true
                ),
                {
                    onSubmit: async (values) => {
                        values.clientId = clientId;
                        const result = await ClientsService.updateClientLocationAsync(values);
                        if (result && result.error) toast.error(t(result.error.message));
                        else {
                            loadClientsLocations(locationsState);
                            toast.success(t("actions:dialogs.updateClient.success"));
                            ModalWizard.hide();
                        }
                    },
                    onCancel: async () => {
                        ModalWizard.hidePrompt();
                    },
                },
                { style: { minHeight: "720px" } }
            );
        }
    };

    const onLocationDelete = async (row, value) => {
        setSelectedLocation(row.original);
        toggleOpen(value);
    };

    const queryUnitsTags = async (tag) => {
        if (tag.length > 2) {
            const tags = await TagsService.lookupUnitTags(tag, false);
            return tags.map((t) => t.id);
        }
        return [];
    };

    const createNewUnit = async (locationId) => {
        const clientLocationData = { data: await ClientsService.getLocationById(locationId) };
        ModalWizard.show(
            UnitWizardAdd(
                t,
                null,
                currentClientData,
                wizardData.manufacturers,
                gasTypesData.gasTypes,
                queryUnitsTags,
                clientLocationData
            ),
            {
                onSubmit: async (values) => {
                    values.locationId = locationId;
                    const result = await ClientsService.addUnit(ModalWizard.asFormData());
                    if (result && result.error) toast.error(t(result.error.message));
                    else {
                        loadClientsLocations(locationsState);
                        toast.success(t("actions:dialogs.createUnit.success"));
                        ModalWizard.hide();
                    }
                },
                onCancel: async () => {
                    ModalWizard.hidePrompt();
                },
            },
            { style: { minHeight: "630px" } }
        );
    };

    const locationColumns = useMemo(() => {
        return ClientsColumns.getColumns(t, "locationColumns", {
            toggleClientLocationEdit,
            onLocationDelete,
            createNewUnit,
        });
    }, [t, wizardData, currentClientData, countryData]);

    if (!locationsState.filter.clientId) {
        locationsDispatch({
            type: actionTypes.SET_CLIENT_ID,
            payload: match.params.clientId,
        });
    }

    const locationsPageChange = usePageChange(locationsDispatch, locationsState);

    const { update: updateBreadcrumbs } = useBreadcrumbs([]);

    useEffect(() => {
        locationsState.filter.clientId && loadClientsLocations(locationsState);
    }, [loadClientsLocations, locationsState]);

    useEffect(() => {
        updateBreadcrumbs(
            ClientsBreadcrumbs.getBreadcrumbs(
                t,
                "2",
                currentClientData.data ? currentClientData.data.clientName : ""
            )
        );
    }, [currentClientData.data]);

    const currentLocationsSortBy = useMemo(() => {
        return [
            {
                id: locationsState.orderColumn,
                desc: locationsState.descending,
            },
        ];
    }, [locationsState.orderColumn, locationsState.descending]);

    const onLocationSelect = useCallback(
        (row, clientId) => {
            const selectLocationRow = (row) => {
                history.push(RouteCreator.clients.units(clientId, row.original.locationId));
            };
            selectLocationRow(row);
        },
        [history]
    );

    const sortLocations = (sortBy) => {
        sortBy.length > 0
            ? locationsDispatch({ type: actionTypes.SET_SORT, payload: sortBy })
            : locationsDispatch({ type: actionTypes.RESET_SORT });
    };

    const locationsFilterAction = (
        <AuthorizeComponent
            roles={[
                Roles.InstallerCompanyAdmin,
                Roles.InstallerCompanyUser,
                Roles.InstallerCompanyTechnician,
            ]}
        >
            <ActionButton
                className="primary"
                dataTest="add-extraction-site"
                // icon="/images/actions/icon-add.svg"
                // iconSize="12px"
                onClick={(ev) => {
                    ev.preventDefault();
                    ModalWizard.show(
                        ClientLocationWizard(
                            t,
                            null,
                            countryData.countries,
                            currentClientData,
                            false
                        ),
                        {
                            onSubmit: async (values) => {
                                values.clientId = locationsState.filter.clientId;
                                const result = await ClientsService.createClientLocationAsync(
                                    values
                                );
                                if (result && result.error) toast.error(t(result.error.message));
                                else {
                                    toast.success(
                                        t("actions:dialogs.createClientLocation.success")
                                    );
                                    ModalWizard.hide();
                                    locationsState.filter.clientId &&
                                        loadClientsLocations(locationsState);
                                }
                            },
                            onCancel: async () => {
                                ModalWizard.hidePrompt();
                            },
                        },
                        { style: { minHeight: "720px" } }
                    );
                }}
                tooltip={t("screens:locations.tooltip.add")}
            >
                {t("actions:addExtractionSite")}
            </ActionButton>
        </AuthorizeComponent>
    );

    return (
        <>
            <Helmet>
                <title>{t("seo:locations.title")}</title>
            </Helmet>
            <ListingPage
                columns={locationColumns}
                tableState={{
                    data: clientsLocations.data,
                    loading: locationsIsLoading,
                    error: locationsError,
                }}
                paging={{
                    enablePaging: true,
                    pageCount: clientsLocations.totalPages,
                    pageSize: clientsLocations.pageSize,
                    onPageChange: locationsPageChange,
                    currentPageIndex: clientsLocations.currentPage
                        ? clientsLocations.currentPage - 1
                        : 0,
                }}
                sorting={{
                    enableSorting: true,
                    onSortByChange: sortLocations,
                    currentSortBy: currentLocationsSortBy,
                }}
                filterTitle={t("filters:clients.locations.filterTitle")}
                filterTitleOnTop={false}
                filterComponent={() => {
                    return (
                        <LocationsFilter
                            filterState={locationsState.filter}
                            dispatch={locationsDispatch}
                            actionTypes={actionTypes}
                            actions={locationsFilterAction}
                        />
                    );
                }}
                actions={{
                    headerActions: () => {
                        return (
                            <ListingHeader>
                                <StyledBackIcon
                                    className="clients"
                                    onClick={() => {
                                        history.push(RouteCreator.clients.root());
                                    }}
                                />
                                <ClientDetails
                                    details={currentClientData.data}
                                    clientsLocations={clientsLocations.data}
                                    showLocationDetails={false}
                                />
                            </ListingHeader>
                        );
                    },
                }}
                onSelect={(row) => {
                    onLocationSelect(row, match.params.clientId);
                }}
                detailSideBarTitle={t("sidebar:title.location.title")}
            />
            <ModalDialog {...modalProps}>
                <p>{t("actions:dialogs.deleteLocation.text")}</p>
                <p style={{ marginLeft: "10px" }}>
                    {selectedLocation &&
                        `• ${selectedLocation.locationName} | ${selectedLocation.address} | ${selectedLocation.city}`}
                </p>
            </ModalDialog>
        </>
    );
};

export default ClientLocationsListing;
