import React, { useEffect, useMemo, useState, useCallback, useRef } from "react";
import useStateModule from "../../../core/state/useStateModule";
import marketplaceListingStateModule from "../../../core/state/buy-refrigerant/marketplaceListingStateModule";
import { useTranslation } from "react-i18next";
import useBreadcrumbs from "../../../core/state/navigation/useBreadcrumbs";
import DateCell from "../../../components/data/DateCell";
import HamburgerMenu from "../../../components/menu/HamburgerMenu";
import HamburgerMenuAction from "../../../components/menu/HamburgerMenuAction";
import AuthorizeComponent from "../../../core/AuthorizeComponent";
import ListingPage from "../../../components/data/ListingPage";
import UserService from "../../../services/User/UserService";
import MarketplaceDetailSideBar from "./components/MarketplaceDetailSideBar";
import ModalDialog, { useModal } from "../../../components/modal/ModalDialog";
import DetailSideBar from "../../../components/modal/DetailSideBar";
import OfferBids from "./components/OfferBids";
import MarketplaceService from "../../../services/BuyRefrigerant/MarketplaceService";
import Button from "../../../components/elements/buttons/Button";
import useClickAway from "../../../hooks/_shared/useClickAway";
import useImmerReducer from "../../../hooks/_shared/useImmerReducer";
import { toast } from "react-toastify";
import usePageChange from "../../../hooks/_shared/usePageChange";
import MarketplaceFilter from "./components/MarketplaceFilter";
import Roles from "../../../core/enums/Roles";
import ModalWizard from "../../../components/modal/ModalWizard";
import WizardPlaceBid from "./components/WizardPlaceBid";
import GradeCell from "../../../components/data/GradeCell";
import GreenCell from "../../../components/data/GreenCell";
import StockService from "../../../services/Stock/StockService";
import { Helmet } from "react-helmet";
import RouteCreator from "../../../core/RouteCreator";
import TabLinks from "../../../components/elements/tabs/TabLinks";
import useSharedState from "../../../hooks/_shared/useSharedState";

const actionTypes = {
    SET_HIDEMYOFFERS: "SET_HIDEMYOFFERS",
    RESET_HIDEMYOFFERS: "RESET_HIDEMYOFFERS",
    SET_HIDEWITHBIDS: "SET_HIDEWITHBIDS",
    RESET_HIDEWITHBIDS: "RESET_HIDEWITHBIDS",
    SET_GAS_TYPE: "SET_GAS_TYPE",
    RESET_GAS_TYPE: "RESET_GAS_TYPE",
    SET_GAS_QUANTITY_FROM: "SET_GAS_QUANTITY_FROM",
    RESET_GAS_QUANTITY_FROM: "RESET_GAS_QUANTITY_FROM",
    SET_GAS_QUANTITY_TO: "SET_GAS_QUANTITY_TO",
    RESET_GAS_QUANTITY_TO: "RESET_GAS_QUANTITY_TO",
    RESET_GAS_QUANTITY: "RESET_GAS_QUANTITY",
    SET_PRESUMED_QUALITY: "SET_PRESUMED_QUALITY",
    RESET_PRESUMED_QUALITY: "RESET_PRESUMED_QUALITY",
    SET_QUALITY_ASSURANCE: "SET_QUALITY_ASSURANCE",
    RESET_QUALITY_ASSURANCE: "RESET_QUALITY_ASSURANCE",
    SET_OFFER_CREATION__DATE_FROM: "SET_OFFER_CREATION__DATE_FROM",
    RESET_OFFER_CREATION__DATE_FROM: "RESET_OFFER_CREATION__DATE_FROM",
    SET_OFFER_CREATION__DATE_TO: "SET_OFFER_CREATION__DATE_TO",
    RESET_OFFER_CREATION__DATE_TO: "RESET_OFFER_CREATION__DATE_TO",
    SET_DEAL_EXPIRATION_FROM: "SET_DEAL_EXPIRATION_FROM",
    RESET_DEAL_EXPIRATION_FROM: "RESET_DEAL_EXPIRATION_FROM",
    SET_DEAL_EXPIRATION_TO: "SET_DEAL_EXPIRATION_TO",
    RESET_DEAL_EXPIRATION_TO: "RESET_DEAL_EXPIRATION_TO",
    SET_SORT: "SET_SORT",
    RESET_SORT: "RESET_SORT",
    SET_COUNTRY: "SET_COUNTRY",
    RESET_COUNTRY: "RESET_COUNTRY",
    SET_PAGING: "SET_PAGING",
};

const tabItems = (t, isDistributor = false, isMasterAdmin = false) => {
    const tabs = [
        {
            name: t("navigation:marketplace"),
            path: isMasterAdmin ? RouteCreator.admin.marketplace() : RouteCreator.salesCenter.marketplace(),
        },
    ];

    if (!isDistributor && !isMasterAdmin)
        tabs.push({
            name: t("navigation:myOffers"),
            path: RouteCreator.salesCenter.myOffers(),
        });

    if (!isMasterAdmin) {
        tabs.push({
            name: t("navigation:myBids"),
            path: RouteCreator.salesCenter.myBids(),
        });
    }
    return tabs;
};

const marketplaceReducer = (state, action) => {
    if (action.type !== actionTypes.SET_PAGING) state.page = 1;
    switch (action.type) {
        case "SET_HIDEMYOFFERS":
            state.filter.hideMyOffers = action.payload;
            break;
        case "RESET_HIDEMYOFFERS":
            delete state.filter.hideMyOffers;
            break;
        case "SET_HIDEWITHBIDS":
            state.filter.hideOffersWithMyBid = action.payload;
            break;
        case "RESET_HIDEWITHBIDS":
            delete state.filter.hideOffersWithMyBid;
            break;
        case "SET_PAGING":
            state.page = action.payload.pageIndex + 1;
            state.pageSize = action.payload.pageSize;
            break;
        case "SET_SORT":
            state.orderColumn = action.payload[0].id;
            state.descending = action.payload[0].desc;
            break;
        case "RESET_SORT":
            state.orderColumn = "";
            state.descending = true;
            break;
        case "SET_GAS_TYPE":
            state.filter.gasType = action.payload;
            break;
        case "RESET_GAS_TYPE":
            delete state.filter.gasType;
            break;
        case "SET_GAS_QUANTITY_FROM":
            state.filter.gasQuantityFrom = Number(action.payload);
            break;
        case "RESET_GAS_QUANTITY_FROM":
            delete state.filter.gasQuantityFrom;
            break;
        case "SET_GAS_QUANTITY_TO":
            state.filter.gasQuantityTo = Number(action.payload);
            break;
        case "RESET_GAS_QUANTITY_TO":
            delete state.filter.gasQuantityTo;
            break;
        case "SET_PRESUMED_QUALITY":
            state.filter.presumedQuality = Number(action.payload);
            break;
        case "RESET_PRESUMED_QUALITY":
            delete state.filter.presumedQuality;
            break;
        case "SET_QUALITY_ASSURANCE":
            state.filter.hasAnalysis = action.payload === "true" ? true : false;
            break;
        case "RESET_QUALITY_ASSURANCE":
            delete state.filter.hasAnalysis;
            break;

        case actionTypes.SET_OFFER_CREATION__DATE_FROM:
            state.filter.offerCreationDateTimeFrom = action.payload;
            break;
        case actionTypes.RESET_OFFER_CREATION__DATE_FROM:
            delete state.filter.offerCreationDateTimeFrom;
            break;
        case actionTypes.SET_OFFER_CREATION__DATE_TO:
            state.filter.offerCreationDateTimeTo = action.payload;
            break;
        case actionTypes.RESET_OFFER_CREATION__DATE_TO:
            delete state.filter.offerCreationDateTimeTo;
            break;

        case actionTypes.SET_DEAL_EXPIRATION_FROM:
            state.filter.dealExpirationFrom = action.payload;
            break;
        case actionTypes.RESET_DEAL_EXPIRATION_FROM:
            delete state.filter.dealExpirationFrom;
            break;
        case actionTypes.SET_DEAL_EXPIRATION_TO:
            state.filter.dealExpirationTo = action.payload;
            break;
        case actionTypes.RESET_DEAL_EXPIRATION_TO:
            delete state.filter.dealExpirationTo;
            break;
        case actionTypes.SET_COUNTRY:
            state.filter.countryId = action.payload;
            break;
        case actionTypes.RESET_COUNTRY:
            delete state.filter.countryId;
            break;
        default:
            return state;
    }
};

const initialValues = {
    gradeA: "0.00",
    gradeB: "0.00",
    gradeC: "0.00",
    transportationCosts: 0,
    administrationCosts: 0,
    chargeSeller: false,
};

const MarketplaceListing = () => {
    const {
        buyingItems,
        isLoading,
        error,
        loadBuyingItems,
        loadBuyingItemsById,
        details,
        unpublishData,
        loadGasTypes,
        gasTypesData,
    } = useStateModule(marketplaceListingStateModule);
    const { t } = useTranslation("screens");
    const [globalState] = useSharedState("global");

    useBreadcrumbs([
        {
            text: t("navigation:salesCenter"),
        },
        {
            text: t("navigation:marketplace"),
        },
    ]);

    const { CompanyId } = useMemo(() => UserService.getUserDetailsFromToken(), []);

    const [filterState, dispatch] = useImmerReducer(marketplaceReducer, {
        filter: {
            hideMyOffers: true,
            hideOffersWithMyBid: true,
        },
        page: 1,
        pageSize: 10,
        orderColumn: "offerCreationDateTime",
        descending: true,
    });

    useEffect(() => {
        loadBuyingItems(filterState);
    }, [loadBuyingItems, filterState]);

    useEffect(() => {
        loadGasTypes();
    }, [loadGasTypes]);

    const [detailViewIsOpened, setDetailViewIsOpened] = useState(false);
    const [selectedOffer, setSelectedOffer] = useState(null);
    const onSelect = useCallback(
        (row) => {
            const selectRow = async (row) => {
                await loadBuyingItemsById(row.original.offerId);
                setDetailViewIsOpened(true);
                setSelectedOffer(row.original);
            };
            selectRow(row);
        },
        [loadBuyingItemsById]
    );

    const closeSideBar = () => {
        setDetailViewIsOpened(false);
    };

    const { modalProps, toggleOpen } = useModal({
        type: "warning",
        title: t("actions:dialogs.unPublishOffer.title"),
        onConfirm: async (model) => {
            const result = await StockService.unpublishFromMarketplace({
                offerIds: [model.original.offerId],
            });
            if (result && result.error) {
                toast.error(t(result.error.message));
            } else {
                loadBuyingItems(filterState);
                toast.success(t("myOffers.toastNotification.offerUnpublished"));
                toggleOpen();
            }
        },
        onCancel: () => {
            toggleOpen();
        },
        confirmText: t("actions:dialogs.unPublishOffer.confirmText"),
    });

    const [currentOfferTranslations, setCurrentOfferTranslations] = useState("");

    const columns = useMemo(
        () => [
            {
                Header: t("table:headers.gasType"),
                accessor: "refrigerantGasTypeCode",
                Cell: GreenCell,
            },
            {
                Header: t("table:headers.gasQuantity"),
                accessor: "quantity",
                Cell: ({ cell: { row, value } }) =>
                    GreenCell({
                        cell: {
                            value:
                                value.toFixed(2) +
                                ` kg / ${row.original.numberOfCylinders || "-"} ${t("screens:technician.cylinders")}`,
                        },
                    }),
            },
            {
                Header: t("table:headers.grade"),
                accessor: "pressumedGradeName",
                Cell: GradeCell,
            },
            {
                Header: t("table:headers.evaluation"),
                accessor: "analysisDone",
                Cell: ({ cell: { value } }) =>
                    value ==="True" ? t("filters:analysis.measurement") : t("filters:analysis.selfAssessment"),
            },
            {
                Header: t("table:headers.owner"),
                accessor: "offerer",
            },
            {
                Header: t("table:headers.published"),
                accessor: "offerCreationDateTime",
                Cell: (params) => {
                    return DateCell(params);
                },
            },
            {
                Header: "",
                accessor: "offerId",
                Cell: ({
                    cell: {
                        row: { original },
                        value,
                    },
                }) => {
                    return (
                        ((!original.bidAlreadyPlaced && original.offererId !== CompanyId) ||
                            original.offererId === CompanyId) && (
                            <AuthorizeComponent roles={[Roles.InstallerCompanyAdmin, Roles.InstallerCompanyUser]}>
                                <HamburgerMenu>
                                    {original.offererId === CompanyId && (
                                        <HamburgerMenuAction
                                            text={t("actions:unpublishFromMarketplace")}
                                            onClick={async () => {
                                                setCurrentOfferTranslations(
                                                    `${original.refrigerantGasTypeCode} - ${original.quantity}kg`
                                                );
                                                toggleOpen({ original });
                                            }}
                                        />
                                    )}
                                    {!original.bidAlreadyPlaced && original.offererId !== CompanyId && (
                                        <HamburgerMenuAction
                                            disabled={
                                                !globalState.hasValidCompanyCertificate |
                                                !globalState.hasCertificateRights
                                            }
                                            text={t("actions:bidActions.bidForOffer")}
                                            onClick={async () => {
                                                setCurrentOfferTranslations(
                                                    `${original.refrigerantGasTypeCode} - ${original.quantity}kg`
                                                );
                                                openPlaceBid(original);
                                            }}
                                        />
                                    )}
                                </HamburgerMenu>
                            </AuthorizeComponent>
                        )
                    );
                },
                disableSortBy: true,
            },
        ],
        [t, globalState]
    );

    const getRowClassName = useCallback((row) => {
        if (row.original.offererId === CompanyId) {
            return "my-company";
        }
    }, []);

    const [bidViewIsOpened, setBidViewIsOpened] = useState(false);
    const [offers, setOffers] = useState([]);
    const bidSideBarRef = useRef(null);

    const closeBidSidebar = () => {
        setBidViewIsOpened(!bidViewIsOpened);
    };

    const [selectedRows, setSelectedRows] = useState([]);
    const toggleMultipleBids = (rows) => {
        setBidViewIsOpened(!bidViewIsOpened);
        if (rows) {
            const offers = rows.reduce((acc, currentRow) => {
                let offer = currentRow.original;
                acc.push(offer);
                return acc;
            }, []);

            setOffers(offers);
        }
    };

    useClickAway(bidSideBarRef, () => {
        setBidViewIsOpened(false);
    });

    const shouldShowCheckbox = useCallback(
        (row) => {
            if (row.original.bidAlreadyPlaced) {
                return false;
            }
            if (row.original.offererId === CompanyId) {
                return false;
            }
            return true;
        },
        [CompanyId]
    );

    const pageChange = usePageChange(dispatch, filterState);

    const sortChange = (sortBy) => {
        sortBy.length > 0
            ? dispatch({ type: actionTypes.SET_SORT, payload: sortBy })
            : dispatch({ type: actionTypes.RESET_SORT });
    };

    const currentSortBy = useMemo(() => {
        return [{ id: filterState.orderColumn, desc: filterState.descending }];
    }, [filterState.orderColumn, filterState.descending]);

    const [currentOffer, setCurrentOffer] = useState({});
    useEffect(() => {
        setCurrentOffer(buyingItems.data.filter((offer) => offer.offerId === details.buyingItemData.offerId));
    }, [details]);

    const openPlaceBid = (offer) => {
        return ModalWizard.show(
            WizardPlaceBid(t, initialValues, [offer], buyingItems.isLoading, globalState.enableHandlingCosts),
            {
                onSubmit: async (values) => {
                    if (!values.chargeSeller) {
                        values.administrationCosts = 0;
                        values.transportationCosts = 0;
                    }
                    const result = await MarketplaceService.addBid({
                        gradeA: values.gradeA,
                        gradeB: values.gradeB,
                        gradeC: values.gradeC,
                        offerIds: [offer.offerId],
                        administrationCosts: values.administrationCosts,
                        transportationCosts: values.transportationCosts,
                    });

                    if (result && result.error) toast.error(t(result.error.message));
                    else {
                        loadBuyingItems(filterState);
                        toast.success(t("marketplace.toastNotification.bidPlaced"));
                        closeBidSidebar();
                        ModalWizard.hide();
                    }
                },
                onCancel: async () => {
                    ModalWizard.hidePrompt(setDetailViewIsOpened(false));
                },
            },
            { style: { minHeight: "740px" } }
        );
    };

    return (
        <>
            <Helmet>
                <title>{t("seo:marketplace.title")}</title>
            </Helmet>
            <TabLinks
                variant="shadowed"
                items={tabItems(t, globalState.isDistributor, globalState.isAdmin)}
                idx={0}
                style={{ marginTop: "0px", padding: "10px 0px 10px 40px" }}
            />
            <ListingPage
                columns={columns}
                tableState={{
                    data: buyingItems.data,
                    loading: isLoading || unpublishData.isLoading,
                    error: error,
                }}
                filterTitle={t("filters:recoveredFGasMarketPlace.filterTitle")}
                showTitle={true}
                filterComponent={() => {
                    return (
                        <MarketplaceFilter
                            filterState={filterState.filter}
                            dispatch={dispatch}
                            actionTypes={actionTypes}
                            gasTypes={gasTypesData.gasTypes}
                            screen="market"
                        />
                    );
                }}
                //enableSelection
                paging={{
                    enablePaging: true,
                    pageCount: buyingItems.totalPages,
                    pageSize: buyingItems.pageSize,
                    onPageChange: pageChange,
                    currentPageIndex: buyingItems.currentPage - 1,
                }}
                sorting={{
                    enableSorting: true,
                    onSortByChange: sortChange,
                    currentSortBy: currentSortBy,
                }}
                setSelectedRows={setSelectedRows}
                noDataText={t("marketplace.notification")}
                getRowClassName={getRowClassName}
                onSelect={onSelect}
                closeSideBar={closeSideBar}
                shouldShowSelection={shouldShowCheckbox}
                detailViewIsOpened={detailViewIsOpened}
                detailSideBarTitle={`${t("sidebar:title.offerId")}: ${currentOffer[0]?.offerNumber}`}
                detailView={
                    <MarketplaceDetailSideBar
                        key={details.buyingItemData?.offerId}
                        offer={currentOffer[0]}
                        details={details}
                        item={details.buyingItemData}
                        companyId={CompanyId}
                        isLoading={isLoading}
                        bidAlreadyPlaced={selectedOffer && selectedOffer.bidAlreadyPlaced}
                        toggleBidModal={() => openPlaceBid(selectedOffer)}
                    />
                }
            />

            {offers && offers.length > 0 && (
                <DetailSideBar detailIsOpened={bidViewIsOpened} closeSideBar={closeBidSidebar} ref={bidSideBarRef}>
                    <OfferBids offers={offers} showTotalOffers={false} isLoading={buyingItems.isLoading} />
                </DetailSideBar>
            )}
            <ModalDialog {...modalProps}>
                <p>{t("actions:dialogs.unPublishOffer.text")}</p>
                <p className="list">
                    {modalProps.modal &&
                        modalProps.modal.original &&
                        `${
                            modalProps.modal.original.refrigerantGasTypeCode
                        } | ${modalProps.modal.original.quantity?.toFixed(2)} kg | ${
                            modalProps.modal.original.pressumedGradeName
                        } | ${
                            modalProps.modal.original.analysisDone === "False"
                                ? t("filters:analysis.selfAssessment")
                                : t("filters:analysis.measurement")
                        }`}
                </p>
            </ModalDialog>

            <div style={{ padding: "0px 60px" }}>
                <Button
                    style={{
                        display: selectedRows.length > 0 ? "block" : "none",
                    }}
                    onClick={() => toggleMultipleBids(selectedRows)}
                    className="primary"
                >
                    {t("marketplace.button.placeMultipleBids")}
                </Button>
            </div>
        </>
    );
};

export default MarketplaceListing;
