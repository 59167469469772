import React from "react";
import InputElement from "../../../../../components/elements/form-elements/InputElement";
import helpers from "../../../../../core/helpers";
import ValidationRule, {
    buildValidation,
} from "../../../../../core/validations/ValidationRule";
import WizardCompanyDetails from "../details/WizardCompanyDetails";

const defaultInitialValues = {
    IBAN: '',
}

const validForm = (t) => {
    return buildValidation({
        IBAN: ValidationRule.isStringRule().isIBAN(t('forms:iban.invalid'))
            .required(t('forms:iban.required')).rule
    })
};

const IBANWizard = (t, companyData) => {
        let initialValues = null;
        if (companyData) {
            const extendedValues = {
                address: companyData.data.address,
                city: companyData.data.city,
                postalCode: companyData.data.postalCode,
                countryId: companyData.data.countryId,
                region: companyData.data.region
            }

            initialValues = helpers.getInitialFormValues(defaultInitialValues, extendedValues);
        }

        return {
            title: t('forms:iban.step1'),
            initialValues: initialValues,
            steps: [{
                name: 'Owner and Bank account information',
                fieldNames: ['IBAN', 'address', 'city', 'postalCode', 'countryId', 'region'],
                validation: validForm(t),
                fields: formik => (
                    <>
                        <WizardCompanyDetails companyData={companyData}/>
                        <InputElement labelText={t('forms:iban.text')}
                                      name="IBAN"
                                      id="IBAN"
                                      type="text"
                                      wrapperVariant="flex-1"
                                      {...formik}
                        />
                    </>
                )
            }]
        }
    }
;

export default IBANWizard;
