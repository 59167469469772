import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import HamburgerMenu from "../../../../../components/menu/HamburgerMenu";
import HamburgerMenuAction from "../../../../../components/menu/HamburgerMenuAction";
import UserService from "../../../../../services/User/UserService";

const ActionsCell = (
    companyCreatorId,
    userStatus,
    reloadCompanies,
    onDelete,
    deleteIsLoading,
    disableDelete = false,
    partnerStatus,
    partnerAction,
    companyId,
    companyName
) => {
    const [loading, setLoading] = useState(false);

    const isActivated = useMemo(() => {
        return userStatus !== "PendingConfirmation";
    }, [userStatus]);

    const { t } = useTranslation();

    const activateUser = async (event) => {
        event.stopPropagation();
        setLoading(true);
        await UserService.activate({
            userId: companyCreatorId,
            activate: true,
        });
        toast.success(t("screens:registration.userActivated"));
        setLoading(false);
        reloadCompanies();
    };

    const changetPartnerStatus = (event) => {
        event.stopPropagation();
        partnerAction && partnerAction(companyId, companyName, partnerStatus);
    };

    return (
        <HamburgerMenu>
            {!isActivated && (
                <HamburgerMenuAction
                    disabled={loading}
                    onClick={activateUser}
                    text={t("actions:approve")}
                    dataTest="user-activate"
                />
            )}
            <HamburgerMenuAction
                disabled={loading}
                onClick={changetPartnerStatus}
                text={partnerStatus === 0 ? t("actions:setPartnerStatus") : t("actions:editPartnerStatus")}
                dataTest="user-delete"
            />
            <HamburgerMenuAction
                disabled={deleteIsLoading || disableDelete}
                onClick={onDelete}
                text={t("actions:delete")}
                dataTest="user-delete"
            />
            
        </HamburgerMenu>
    );
};

export default ActionsCell;
