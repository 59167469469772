import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Switch } from "react-router-dom";
import styled from "styled-components";
import PrivateRoute from "../../core/PrivateRoute";
import RouteCreator from "../../core/RouteCreator";
import useSharedState from "../../hooks/_shared/useSharedState";
import BottomMenu, { useBottomMenu } from "../../screens/pwa/BottomMenu";
import LeftMenu, { useLeftMenu } from "../../screens/pwa/LeftMenu";
import CompanyStock from "../pwa/CompanyStock";
import pwaMenuItems from "../pwa/components/PwaMenuItems";
import CylinderStock from "../pwa/CylinderStock";
import AnalyzerStock from "../pwa/AnalyzerStock";
import UnitStock from "../pwa/UnitStock";
import TechnicianStock from "../pwa/TechnicianStock";
import QrScanner from "../../components/elements/qr/QrScanner";
import EmployeeEditScreen from "../../screens/Company/views/employees/EmployeeEditScreen";
import NewExtraction from "../pwa/actions/NewExtraction";
import ToCompanyStock from "../pwa/actions/ToCompanyStock";
import ToMyStock from "../pwa/actions/ToMyStock";
import AddUnit from "../pwa/actions/AddUnit";
import AddCylinder from "../pwa/actions/AddCylinder";
import AddAnalyzer from "../pwa/actions/AddAnalyzer";
import Collect from "../pwa/actions/Collect";
import RecycleCylinder from "../pwa/actions/RecycleCylinder";
import FinishRecycling from "../pwa/actions/FinishRecycling";
import CollectCylinder from "../pwa/actions/CollectCylinder";
import UserService from "../../services/User/UserService";

const ImageButton = styled.img`
    &.round {
        border-radius: 50%;
    }
    padding: 0px;
    transition: all 0.3s ease;

    &:active {
        background-color: #ddd;
    }

    &.disabled {
        opacity: 0.25;
        pointer-events:none;
    }
`;

const Header = styled.div`
    box-sizing: border-box;
    padding: 15px 18px;
    background: #ffffff;
    color: #000000;
    position: fixed;
    top: 0;
    width: 100%;
    height: 55px;
    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.08);
    display: flex;
    justify-content: space-between;
    align-content: center;
    z-index: 4;
    user-select: none;

    & + .content {
        padding-top: 60px;
        height: 100%;
    }
`;

const HeaderSection = styled.div`
    display: flex;
    align-items: center;

    & img + * {
        margin-left: 18px;
    }
`;

const Title = styled.div`
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
`;

const PwaScreen = styled.div`
    & * {
        user-select: text;
    }
`;

const MobileDashboard = () => {
    const { UserId } = UserService.getUserDetailsFromToken();
    const { t } = useTranslation("navigation");
    const [scanState, setScanState] = useState({ show: false });
    const [globalState] = useSharedState("global");
    const [leftMenuState, setLeftMenuState] = useLeftMenu();
    const [bottomMenuState, setBottomMenuState] = useBottomMenu();
    const [headerState, setHeaderState] = useSharedState("pwaHeader", {
        title: "",
    });
    const handleLeftMenu = (ev) =>
        setLeftMenuState({ isOpen: !leftMenuState.isOpen });
    const handleBottomMenu = (ev) =>
        setBottomMenuState({ isOpen: !bottomMenuState.isOpen });

    const hasCompanyCert = globalState.hasValidCompanyCertificate;
    const hasUserCert = globalState.hasValidUserCertificate;

    const restricredUsage = !hasCompanyCert | !hasUserCert;

    useEffect(() => {
        pwaMenuItems(hasCompanyCert).map((item, index) => {
            if (
                window.location.pathname === item.url &&
                headerState.menuIndex !== index
            )
                setHeaderState({ title: t(item.name), menuIndex: index });
        });
    });

    const showScanner = () => {
        setScanState({ show: true });
    };
    if(!globalState.userLoaded) return null;
    return (
        <>
            <LeftMenu />
            <Header>
                <HeaderSection>
                    <ImageButton
                        src="/images/menu/pwa-menu.svg"
                        alt="scan"
                        onClick={handleLeftMenu}
                    />
                    <Title>{headerState.title}</Title>
                </HeaderSection>
                <HeaderSection>
                    <ImageButton
                        className={restricredUsage ? "disabled":""}
                        src="/images/menu/pwa-scan.svg"
                        alt="scan"
                        onClick={showScanner}
                    />
                    <ImageButton
                    className={restricredUsage ? "round disabled":"round"}
                        src="/images/menu/pwa-plus.svg"
                        alt="plus"
                        onClick={handleBottomMenu}
                    />
                </HeaderSection>
            </Header>
            <PwaScreen className="content">
                {hasCompanyCert ? (
                    <Switch>
                        <PrivateRoute
                            exact={true}
                            path={RouteCreator.home()}
                            component={TechnicianStock}
                        />
                        <PrivateRoute
                            path={RouteCreator.pwa.stock()}
                            component={CompanyStock}
                        />
                        <PrivateRoute
                            path={RouteCreator.pwa.equipment()}
                            component={UnitStock}
                        />
                        <PrivateRoute
                            path={RouteCreator.pwa.cylinders()}
                            component={CylinderStock}
                        />
                        <PrivateRoute
                            path={RouteCreator.pwa.analyzers()}
                            component={AnalyzerStock}
                        />
                        <PrivateRoute
                            path={RouteCreator.pwa.editUser(":employeeId")}
                            component={EmployeeEditScreen}
                        />
                        <PrivateRoute
                            path={RouteCreator.pwa.extraction()}
                            component={NewExtraction}
                        />
                        <PrivateRoute
                            path={RouteCreator.pwa.toCompanyStock()}
                            component={ToCompanyStock}
                        />
                        <PrivateRoute
                            path={RouteCreator.pwa.toMyStock()}
                            component={ToMyStock}
                        />
                        <PrivateRoute
                            path={RouteCreator.pwa.addUnit()}
                            component={AddUnit}
                        />
                        <PrivateRoute
                            path={RouteCreator.pwa.addCylinder()}
                            component={AddCylinder}
                        />
                        <PrivateRoute
                            path={RouteCreator.pwa.addAnalyzer()}
                            component={AddAnalyzer}
                        />
                        <PrivateRoute
                            path={RouteCreator.pwa.collect()}
                            component={Collect}
                        />
                        <PrivateRoute
                            path={RouteCreator.pwa.recycleCylinder()}
                            component={RecycleCylinder}
                        />
                        <PrivateRoute
                            path={RouteCreator.pwa.finishRecycling()}
                            component={FinishRecycling}
                        />
                        <PrivateRoute
                            path={RouteCreator.pwa.collectEmptyCilinder()}
                            component={CollectCylinder}
                        />

                        <Redirect to={RouteCreator.home()} />
                    </Switch>
                ) : (
                    <Switch>
                        <PrivateRoute
                            path={RouteCreator.pwa.editUser(":employeeId")}
                            component={EmployeeEditScreen}
                        />

                        <Redirect to={RouteCreator.pwa.editUser(UserId)} />
                    </Switch>
                )}
            </PwaScreen>
            <BottomMenu />
            <QrScanner
                show={scanState.show}
                onClose={() => setScanState({ show: false })}
            />
        </>
    );
};

export default MobileDashboard;
