import * as Yup from "yup";
import { commonRegex } from "./common";

class ValidationRule {
    rule = {};

    isStringRule() {
        this.rule = Yup.string();
        return this;
    }

    isMixedRule() {
        this.rule = Yup.mixed();
        return this;
    }

    isNumber(invalidMessage = null) {
        this.rule = Yup.number().typeError(
            invalidMessage || "Please Enter a number"
        );
        return this;
    }

    isObject() {
        this.rule = Yup.object();
        return this;
    }

    boolean() {
        this.rule = Yup.boolean();
        return this;
    }

    emailRule(invalidMailMessage) {
        this.rule = this.rule.email(invalidMailMessage);
        return this;
    }

    oneOf(values, message) {
        this.rule = Yup.string().oneOf(values, message);
        return this;
    }

    moreThan(value, invalidNumberMsg, moreThanMsg) {
        this.rule = Yup.number()
            .typeError(invalidNumberMsg)
            .moreThan(value, moreThanMsg);
        return this;
    }

    min(value, invalidNumberMsg, minValueMsg) {
        this.rule = Yup.number()
            .typeError(invalidNumberMsg)
            .min(value, minValueMsg);
        return this;
    }

    max(value, invalidNumberMsg, maxValueMsg) {
        this.rule = Yup.number()
            .typeError(invalidNumberMsg)
            .max(value, maxValueMsg);
        return this;
    }

    numberRange(minValue, maxValue, invalidMin, invalidMax) {
        this.rule = Yup.number()
            .moreThan(minValue, invalidMin)
            .max(maxValue, invalidMax);
        return this;
    }

    minCharactersRule(numberOfChars, invalidMessage) {
        this.rule = this.rule.min(numberOfChars, invalidMessage);
        return this;
    }

    maxCharactersRule(numberOfChars, invalidMessage) {
        this.rule = this.rule.max(numberOfChars, invalidMessage);
        return this;
    }

    regexMatchRule(regex, invalidMessage) {
        this.rule = this.rule.matches(regex, invalidMessage);
        return this;
    }

    required(invalidMessage) {
        this.rule = this.rule.required(invalidMessage);
        return this;
    }

    nullable() {
        this.rule = this.rule.nullable();
        return this;
    }

    isFile() {
        this.rule = this.rule
            .required("A file is required")
            .test("fileFormat", "Unsupported Format", (value) => {
                if (value) {
                    return true;
                }
                return false;
            });

        return this;
    }

    shouldBeEqual(referenceField, invalidMessage, requiredMessage) {
        this.rule = this.rule
            .oneOf([Yup.ref(referenceField), null], invalidMessage)
            .required(requiredMessage);

        return this;
    }

    conditional(compareWithField, compareRule, invalidMessage, fieldType) {
        this.rule = this.rule.when(compareWithField, {
            is: compareRule,
            then: Yup[fieldType]().required(invalidMessage),
            otherwise: Yup[fieldType]().notRequired(),
        });
        return this;
    }

    fileType(fileType, invalidMessage, requiredMessage) {
        this.rule = this.rule
            .mixed()
            .test(
                "fileFormat",
                "Unsupported Format",
                (value) => value && "application/pdf".includes(value.type)
            );

        return this;
    }

    isNot(value, invalidMessage) {
        this.rule = this.rule.notOneOf([value], invalidMessage);
        return this;
    }

    isUUID(invalidMessage) {
        return this.regexMatchRule(commonRegex.UUID, invalidMessage);
    }

    isIBAN(invalidMessage) {
        return this.regexMatchRule(
            /^[A-z]{2}[a-z0-9A-Z\s]{13,39}$/i,
            invalidMessage
        ); //TODO valid iban regex
    }

    isDecimal(invalidMessage) {
        return this.regexMatchRule(
            /^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/,
            invalidMessage
        );
    }

    transform(callback) {
        this.rule = Yup.string().transform(callback);
        return this;
    }
}

export const buildValidation = (rules) => {
    return Yup.object(rules);
};

export const skipValidation = () => {
    return Yup.object({});
};

export default new ValidationRule();