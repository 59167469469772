import React, { useRef } from "react";
import useClickAway from "../../hooks/_shared/useClickAway";
import DetailSideBar from "../modal/DetailSideBar";
import AccordionWrapper from "../../components/toggle-view/AccordionWrapper";
import SidebarField from "../../components/elements/sidebar/SidebarField";

const ChartDataSidebar = ({
    data,
    title,
    unit,
    valueLabel,
    countLabel,
    isLoading,
    closeSideBar,
}) => {
    const sideBarRef = useRef(null);
    const shouldShow = !isLoading && data;

    useClickAway(sideBarRef, () => {
        try {
            closeSideBar();
        }
        catch (ex) { }
    });

    return (
        <>
            {shouldShow && data && Array.isArray(data) && (
                <DetailSideBar
                    detailIsOpened={shouldShow}
                    closeSideBar={closeSideBar}
                    ref={sideBarRef}
                    variants={{
                        headerViewVariant: "right-action",
                    }}
                    title={title}
                >
                    {
                         data.map((item) => 
                         <AccordionWrapper title={item.name} isOpen={true} variant="sidebar">
                            <SidebarField label={valueLabel} value={item.value.toFixed(2) + " " + unit}/>
                            <SidebarField label={countLabel} value={item.count}/>
                       
                     </AccordionWrapper>)
                    }
                </DetailSideBar>
            )}
        </>
    );
};

export default ChartDataSidebar;
