import React from "react";
import { useTranslation } from "react-i18next";
import { Select } from "../../../../components/elements/form-elements";
import { DateTimePicker } from "../../../../components/elements/form-elements/DatePicker";
import Filters from "../../../../components/filter/Filters";
import MangoPaymentTypeForDisplay from "../../../../core/enums/MangoPaymentTypeForDisplay";
import TransactionStatus from "../../../../core/enums/TransactionStatus";
import helpers from "../../../../core/helpers";
import {
    ClearableInput,
    Label,
} from "../../../../components/elements/form-elements";
import useDebouncedCallback from "../../../../hooks/_shared/useDebouncedCallback";

const TransactionsFilter = ({ filterState, dispatch, actionTypes }) => {
    const { t } = useTranslation("filters");

    const [amountFromChanged] = useDebouncedCallback((text) => {
        text
            ? dispatch({
                  type: actionTypes.SET_AMOUNT_FROM,
                  payload: text,
              })
            : dispatch({
                  type: actionTypes.RESET_AMOUNT_FROM,
                  payload: text,
              });
    });

    const [amountToChanged] = useDebouncedCallback((text) => {
        text
            ? dispatch({
                  type: actionTypes.SET_AMOUNT_TO,
                  payload: text,
              })
            : dispatch({
                  type: actionTypes.RESET_AMOUNT_TO,
                  payload: text,
              });
    });

    return (
        <>
            <Filters.Wrapper className="filter-wrapper">
                <Filters.Item>
                    <Select
                        name="paymentType"
                        key="paymentType"
                        errors={[]}
                        touched={[]}
                        defaultItem={{
                            key: "all",
                            value: "",
                            label: t("transactions.transactionType"),
                        }}
                        defaultValue={filterState.size}
                        options={Object.keys(MangoPaymentTypeForDisplay).map(
                            (key, i) => {
                                return {
                                    key: key,
                                    value: key,
                                    label: t(
                                        `transactions.mangoPaymentType.${MangoPaymentTypeForDisplay[key]}`
                                    ),
                                };
                            }
                        )}
                        handleChange={(evt) => {
                            dispatch({
                                type:
                                    evt.currentTarget.value === ""
                                        ? actionTypes.RESET_PAYMENT_TYPE
                                        : actionTypes.SET_PAYMENT_TYPE,
                                payload: evt.currentTarget.value,
                            });
                        }}
                        className="filters"
                        labelText={t("transactions.transactionType")}
                        labelClassName="sr-only"
                    />
                </Filters.Item>
                 <Filters.Item>
                    <Select
                        name="status"
                        key="status"
                        errors={[]}
                        touched={[]}
                        defaultItem={{
                            key: "all",
                            value: "",
                            label: t("transactions.status"),
                        }}
                        defaultValue={filterState.size}
                        options={Object.keys(TransactionStatus).map(
                            (key, i) => {
                                return {
                                    key: key,
                                    value: key,
                                    label: t(
                                        `transactions.statusType.${TransactionStatus[
                                            key
                                        ].toLowerCase()}`
                                    ),
                                };
                            }
                        )}
                        handleChange={(evt) => {
                            dispatch({
                                type:
                                    evt.currentTarget.value === ""
                                        ? actionTypes.RESET_STATUS
                                        : actionTypes.SET_STATUS,
                                payload: evt.currentTarget.value,
                            });
                        }}
                        className="filters"
                        labelText={t("transactions.status")}
                        labelClassName="sr-only"
                    />
                </Filters.Item>
                <Filters.Group>
                    <Filters.Item>
                        <DateTimePicker
                            labelText={t("transactions.creationDateFrom")}
                            noLabel
                            placeholder={t("transactions.creationDateFrom")}
                            id="creationDateFrom"
                            name="creationDateFrom"
                            className="filters big left group"
                            value={
                                filterState ? filterState.creationDateFrom : ""
                            }
                            maxDate={filterState?.creationDateTo}
                            handleChange={(date) =>
                                dispatch({
                                    type:
                                        date === ""
                                            ? actionTypes.RESET_CREATION_DATE_FROM
                                            : actionTypes.SET_CREATION_DATE_FROM,
                                    payload:
                                        date === ""
                                            ? date
                                            : helpers.dateToIsoDateString(
                                                  new Date(date)
                                              ),
                                })
                            }
                        />
                    </Filters.Item>
                    <Filters.Item>
                        <DateTimePicker
                            labelText={t("transactions.creationDateTo")}
                            noLabel
                            placeholder={t("transactions.creationDateTo")}
                            id="creationDateTo"
                            name="creationDateTo"
                            className="filters big right group"
                            value={
                                filterState ? filterState.creationDateTo : ""
                            }
                            minDate={filterState?.creationDateFrom}
                            handleChange={(date) =>
                                dispatch({
                                    type:
                                        date === ""
                                            ? actionTypes.RESET_CREATION_DATE_TO
                                            : actionTypes.SET_CREATION_DATE_TO,
                                    payload:
                                        date === ""
                                            ? date
                                            : helpers.dateToIsoDateString(date),
                                })
                            }
                        />
                    </Filters.Item>
                </Filters.Group>
                <Filters.Group>
                    <Filters.Item>
                        <DateTimePicker
                            labelText={t("transactions.executionDateFrom")}
                            noLabel
                            placeholder={t("transactions.executionDateFrom")}
                            id="executionDateFrom"
                            name="executionDateFrom"
                            className="filters big left group"
                            value={
                                filterState ? filterState.executionDateFrom : ""
                            }
                            maxDate={filterState?.executionDateTo}
                            handleChange={(date) =>
                                dispatch({
                                    type:
                                        date === ""
                                            ? actionTypes.RESET_EXECUTION_DATE_FROM
                                            : actionTypes.SET_EXECUTION_DATE_FROM,
                                    payload:
                                        date === ""
                                            ? date
                                            : helpers.dateToIsoDateString(
                                                  new Date(date)
                                              ),
                                })
                            }
                        />
                    </Filters.Item>
                    <Filters.Item>
                        <DateTimePicker
                            labelText={t("transactions.executionDateTo")}
                            noLabel
                            placeholder={t("transactions.executionDateTo")}
                            id="executionDateTo"
                            name="executionDateTo"
                            className="filters big right group"
                            value={
                                filterState ? filterState.executionDateTo : ""
                            }
                            minDate={filterState?.executionDateFrom}
                            handleChange={(date) =>
                                dispatch({
                                    type:
                                        date === ""
                                            ? actionTypes.RESET_EXECUTION_DATE_TO
                                            : actionTypes.SET_EXECUTION_DATE_TO,
                                    payload:
                                        date === ""
                                            ? date
                                            : helpers.dateToIsoDateString(date),
                                })
                            }
                        />
                    </Filters.Item>
                </Filters.Group>

               
                <Filters.Group>
                    <Filters.Item>
                        <Label htmlFor="amountFrom" className="sr-only">
                            {t("transactions.amountFrom")}
                        </Label>

                        <ClearableInput
                            key="amountFrom"
                            name="amountFrom"
                            type="number"
                            defaultValue={filterState.amountFrom}
                            onChange={(evt) => {
                                amountFromChanged(evt.currentTarget.value);
                            }}
                            placeholder={t("transactions.amountFrom")}
                            title={t("transactions.amountFrom")}
                            className="filters left group"
                        />
                    </Filters.Item>

                    <Filters.Item>
                        <Label htmlFor="amountTo" className="sr-only">
                            {t("transactions.amountTo")}
                        </Label>
                        <ClearableInput
                            key="amountTo"
                            name="amountTo"
                            type="number"
                            defaultValue={filterState.amountTo}
                            onChange={(evt) => {
                                amountToChanged(evt.currentTarget.value);
                            }}
                            placeholder={t("transactions.amountTo")}
                            title={t("transactions.amountTo")}
                            className="filters right group"
                        />
                    </Filters.Item>
                </Filters.Group>
            </Filters.Wrapper>
        </>
    );
};

export default TransactionsFilter;
