import Color from "color";
import React, { useCallback, useState } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import styled, { keyframes } from "styled-components";
import Button from "../elements/buttons/Button";

const animFade = keyframes`
0% {
    opacity: 0.5;
    transform: translate(-50%, -50%) scale(0.9, 0.9);
  }

  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1, 1);
  }
`;

const StyledModalContainer = styled.div`
    position: absolute;
    min-width: 624px;
    max-width: 624px;
    font-family: ${(props) => props.theme.fontFamily};
    background: white;
    margin: 0 auto;
    padding: 16px 40px 40px;
    z-index: 701;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    transition: all 0.3s ease-in;
    font-family: ${(props) => props.theme.fontFamily};
    box-shadow: 0px 12px 20px 0px rgba(0, 0, 0, 0.16);
    border-radius: 14px;

    animation-duration: 0.3s;
    animation-name: ${animFade};
    animation-fill-mode: backwards;
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform-style: preserve-3d;
    perspective: 1000px;

    &.wide {
        max-width: 900px;
        padding: 64px 52px 40px;
    }

    @media ${(p) => p.theme.device.mobile} {
        &,
        &.wide {
            min-width: 90%;
            width: 90%;
            max-width: 90%;
            padding: 30px 10px;
        }
    }
`;

const StyledModalOverlay = styled.div`
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: ${(props) =>
        Color(props.theme.bgOverlay).fade(0.5).string()};
    z-index: 700;

    &.opaque {
        background-color: ${(props) => Color(props.theme.bgOverlay).string()};
    }
`;

const StyledHeader = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 14px;
    text-align: center;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 700;
    color: #000;
    background-color: #e94e4e;
    border-radius: 14px 14px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.info {
        background-color: ${(p) => p.theme.primaryColor};
    }

    &.warning {
        background-color: #e98c13;
    }
`;

const StyledTitle = styled.div`
    height: 88px;
    font-size: 20px;
    font-weight: 600;
    color: #e94e4e;
    text-align: left;
    padding: 32px 0px;
    text-transform: uppercase;

    &.info {
        color: ${(p) => p.theme.primaryColor};
    }

    &.warning {
        color: #e98c13;
    }
    @media ${(p) => p.theme.device.mobile} {
        height: 68px;
        font-size: 18px;
        text-align: center;
        padding: 20px 0px;
    }
`;

const ModalContent = styled.div`
    margin: 30px 0px 30px;
    text-align: left;
    font-weight: 600;
    max-width: 100%;
    h1,
    h2,
    h3,
    h4,
    h5 {
        color: ${(props) => props.theme.primaryColor};
        text-transform: uppercase;
        margin: 0 0 32px;
        font-size: 1.5rem;
        font-weight: 500;
    }

    p {
        margin: 0;
        font-size: 1.125rem;
        font-weight: 600;
    }

    p + p {
        margin-top: 10px;
        font-weight: 500;
        color: #707070;
    }

    p.list {
        margin-left: 10px !important;
    }

    p.bold {
        font-weight: 600 !important;
        color: #000 !important;
    }

    @media ${(p) => p.theme.device.mobile} {
        p {
            text-align: center;
            font-size: 14px !important;
        }
    }
`;

const HR = styled.div`
    width: 100%;
    height: 1px;
    border-top: 1px solid #eee;
`;

const ModalButtonsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    &.single {
        justify-content: center;
    }

    &.three {
        justify-content: space-between;
    }

    padding-top: 32px;
    button {
        margin: 0;
        & + button,
        & + a {
            margin: 0 60px 0 0;
        }
    }
`;

const DialogButton = styled(Button)`
    min-width: 148px !important;
    margin: 0 !important;
    font-size: 14px;
    font-weight: 500;
    outline: none !important;

    &.cancel {
        background: transparent !important;
        box-shadow: none;
        border: none !important;
        font-size: 16px;
        font-weight: 700;
        :hover {
            text-decoration: underline;
        }
    }

    &.danger {
        border: 0;
        color: #fff;
        background-color: #e94e4e;
    }

    &.warning {
        border: 0;
        color: #fff;
        background-color: #e98c13;
    }

    &.alternative {
        background-color: #e69221 !important;
        border: 0;
        color: #fff;
    }

    &.info {
        border: 0;
        color: #fff;
        background-color: ${(p) => p.theme.primaryColor};
    }

    &.hidden {
        display: none;
    }
`;

export const useModal = ({
    onConfirm,
    onCancel,
    modalButtons,
    showCloseButton = true,
    confirmText,
    cancelText,
    cancelFirst = true,
    isNegativeAction = true,
    modalVariant = "",
    title = "",
    type = "danger",
    style,
    onAlternative,
    onAlternativeDisabled,
    alternativeText,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [modalModel, setModalModel] = useState({});

    const toggleOpen = useCallback(
        (modal) => {
            setModalModel(modal);
            setIsOpen((open) => !open);
        },
        [setIsOpen, setModalModel]
    );

    return {
        toggleOpen: toggleOpen,
        modalProps: {
            isOpen,
            onConfirm,
            onCancel,
            modal: modalModel,
            modalButtons,
            showCloseButton,
            confirmText,
            cancelText,
            onAlternative,
            alternativeText,
            cancelFirst,
            closeModal: toggleOpen,
            isNegativeAction,
            onAlternativeDisabled,
            modalVariant,
            title,
            type,
            style,
        },
    };
};

const ModalDialog = ({
    children,
    onConfirm,
    onCancel,
    onAlternative,
    isOpen,
    modal,
    modalButtons,
    showCloseButton = true,
    closeModal,
    confirmText,
    cancelText,
    alternativeText,
    cancelFirst,
    isNegativeAction,
    onAlternativeDisabled,
    modalVariant,
    title,
    type,
    style,
    opaque,
}) => {
    const { t } = useTranslation("actions");

    if (!isOpen) {
        return null;
    }

    const singleButton = !onCancel || !onConfirm;

    const threeButtons = onCancel && onConfirm && onAlternative;

    const CancelButton = () =>
        onCancel ? (
            <DialogButton
                className="cancel"
                onClick={(ev) => {
                    onCancel(modal);
                }}
            >
                {cancelText ? cancelText : t("cancel")}
            </DialogButton>
        ) : null;

    const ConfirmButton = () =>
        onConfirm ? (
            <DialogButton
                className={type ? type : "primary"}
                onClick={(ev) => {
                    onConfirm(modal);
                }}
            >
                {confirmText ? confirmText : t("confirm")}
            </DialogButton>
        ) : null;

    const AlternativeButton = () =>
        onAlternative ? (
            <DialogButton
                className="alternative"
                disabled={onAlternativeDisabled && onAlternativeDisabled(modal)}
                onClick={(ev) => {
                    onAlternative(modal);
                }}
            >
                {alternativeText ? alternativeText : t("alternative")}
            </DialogButton>
        ) : null;

    return ReactDOM.createPortal(
        <>
            <StyledModalOverlay
                id="modal-open"
                className={opaque ? "opaque" : ""}
            />
            <StyledModalContainer
                style={style}
                className={modalVariant === "wide" ? "wide" : ""}
                onClick={(ev) => {
                    ev.stopPropagation();
                }}
            >
                {title && (
                    <>
                        <StyledHeader className={type} />
                        <StyledTitle className={type}>{title}</StyledTitle>
                        <HR />
                    </>
                )}
                <ModalContent>{children}</ModalContent>
                {title && <HR />}
                <ModalButtonsWrapper
                    className={
                        singleButton ? "single" : threeButtons ? "three" : ""
                    }
                >
                    <>
                        {onCancel && <CancelButton />}
                        {onAlternative && <AlternativeButton />}
                        {onConfirm && <ConfirmButton />}
                    </>
                    {modalButtons && modalButtons()}
                </ModalButtonsWrapper>
            </StyledModalContainer>
        </>,
        document.body
    );
};

export default ModalDialog;
