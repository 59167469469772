import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import SidebarField from "../../../../components/elements/sidebar/SidebarField";
import AccordionWrapper from "../../../../components/toggle-view/AccordionWrapper";
import {
    Num,
    NumInfo,
    NumSection,
} from "../../../../components/elements/sidebar/SidebarStyle";
import helpers from "../../../../core/helpers";
import { VSpace } from "../../../pwa/components/StyledElements";
import {
    BidGradeWrapper,
    BidGrade,
    BigHandlingCosts,
} from "../../../../components/bids/BidGrade";
import Slider from "../../../../components/elements/general/Slider";
import { getApiUrl } from "../../../../services/getAxios";
import useAccordionGroup from "../../../../hooks/_shared/useAccordionGroup";

const DownloadWrapper = styled.div`
    display: flex;
    justify-content: space-between;

    &.pad {
        padding: 25px 20px 0px;
    }

    &.big > * {
        font-size: 14px !important;
    }

    & > div {
        font-size: 12px;
        color: #333333;
        font-weight: 600;
    }
`;

const DownloadLink = styled.a`
    color: #2294d2;
    text-decoration: none;
    font-size: 12px;
    &.big {
        font-size: 14px;
    }
`;

const splitTag = (tag) => {
    if (!tag || tag.length < 8) return tag;
    return tag.substring(0, 4) + " " + tag.substring(4, 8);
};

const getFileName = (url) => {
    const parts = url.split("/");
    return parts[parts.length - 1];
};

const DisputesDetailSideBar = ({
    selectedDispute,
    offer,
    isLoading,
    openAccordionId = null,
}) => {
    const { t } = useTranslation("sidebar");
    const shouldShow = !isLoading && offer && selectedDispute;
    const [openAccordion, setOpenAccordion] =
        useAccordionGroup(openAccordionId);
    // const buyerCylindersAnalysis =
    //     selectedDispute?.buyerCylindersAnalysis &&
    //     selectedDispute.buyerCylindersAnalysis.length > 0
    //         ? selectedDispute.buyerCylindersAnalysis
    //         : null;

    const numQualityTests = () => {
        if (!offer.skUs) return 0;
        let cnt = 0;
        offer.skUs.forEach((offer) => {
            if (offer.qualityTestDone) cnt++;
        });
        return cnt;
    };

    return (
        <>
            {shouldShow && (
                <>
                    <AccordionWrapper
                        title={t("title.offer.details")}
                        id="offer.details"
                        isOpen={openAccordion}
                        onToggle={setOpenAccordion}
                    >
                        {selectedDispute && (
                            <>
                                <SidebarField
                                    label={t("label.type")}
                                    value={
                                        selectedDispute.refrigerantGasTypeCode
                                    }
                                />
                                <SidebarField
                                    label={t("label.numberOfCylinders")}
                                    value={selectedDispute.numberOfCylinders}
                                />
                                <SidebarField
                                    label={t("label.netWeight")}
                                    value={
                                        selectedDispute.maximumQuantity.toFixed(
                                            2
                                        ) + " kg"
                                    }
                                />
                                <SidebarField
                                    label={t("label.totalExtractedQuantity")}
                                    value={
                                        selectedDispute.quantity.toFixed(2) +
                                        " kg"
                                    }
                                />
                                <SidebarField
                                    label={t("label.extraction.qualityGrade")}
                                    value={selectedDispute.pressumedGradeName}
                                />
                                <SidebarField
                                    label={t(
                                        "label.extraction.qualityEvaluation"
                                    )}
                                    value={
                                        offer.hasAnalysis
                                            ? t("filters:analysis.measurement")
                                            : t(
                                                  "filters:analysis.selfAssessment"
                                              )
                                    }
                                />
                            </>
                        )}
                    </AccordionWrapper>

                    {offer.skUs && (
                        <AccordionWrapper
                            title={t("title.contamination")}
                            id="contamination"
                            isOpen={openAccordion}
                            onToggle={setOpenAccordion}
                        >
                            <Slider
                                prefix="slide-"
                                style={{ padding: "25px 20px 0px" }}
                                title={t(
                                    "sidebar:title.extraction.extractionCylinder"
                                )}
                                captions={offer.skUs.map((sku) =>
                                    splitTag(sku.tagId)
                                )}
                            >
                                {offer.skUs.map((sku, idx) => {
                                    let cnt = 0;
                                    return (
                                        <div id={`slide-${idx + 1}`} key={idx}>
                                            <div>
                                                <SidebarField
                                                    labelvariant="pl-0"
                                                    wrapperVariant="mt-0"
                                                    label={t(
                                                        "label.extractedQuantity"
                                                    )}
                                                    value={
                                                        sku.quantity.toFixed(
                                                            2
                                                        ) + " kg"
                                                    }
                                                />
                                                {!sku.saIsContaminated &&
                                                    !sku.saIsMixture &&
                                                    !sku.saHasR22 && (
                                                        <NumSection>
                                                            <Num className="tight">
                                                                N/A
                                                            </Num>
                                                            <NumInfo>
                                                                {t(
                                                                    "actions:noContamination"
                                                                )}
                                                            </NumInfo>
                                                        </NumSection>
                                                    )}

                                                {sku.saIsContaminated && (
                                                    <NumSection>
                                                        <Num>{++cnt}</Num>
                                                        <NumInfo>
                                                            {t(
                                                                "screens:stock.aGradeText"
                                                            )}
                                                        </NumInfo>
                                                    </NumSection>
                                                )}
                                                {sku.saIsMixture && (
                                                    <NumSection>
                                                        <Num>{++cnt}</Num>
                                                        <NumInfo>
                                                            {t(
                                                                "screens:stock.bGradeText"
                                                            )}
                                                        </NumInfo>
                                                    </NumSection>
                                                )}
                                                {sku.saHasR22 && (
                                                    <NumSection>
                                                        <Num>{++cnt}</Num>
                                                        <NumInfo>
                                                            {t(
                                                                "screens:stock.cGradeText"
                                                            )}
                                                        </NumInfo>
                                                    </NumSection>
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                            </Slider>
                        </AccordionWrapper>
                    )}

                    {offer.skUs && (
                        <AccordionWrapper
                            title={t("title.quality.qualityConfirmation")}
                            id="qualityConfirmation"
                            isOpen={openAccordion}
                            onToggle={setOpenAccordion}
                        >
                            {numQualityTests() > 0 && (
                                <DownloadWrapper className="pad big">
                                    <div>
                                        {numQualityTests()}{" "}
                                        {numQualityTests() > 1
                                            ? t("actions:availableTests")
                                            : t("actions:availableTest")}
                                    </div>
                                    <DownloadLink
                                        href={`${getApiUrl}/Marketplace/getAnalysesZip/${offer.offerId}`}
                                        target="_blank"
                                    >
                                        {t("actions:downloadAll")}
                                    </DownloadLink>
                                </DownloadWrapper>
                            )}

                            <Slider
                                prefix="qslide-"
                                style={{ padding: "25px 20px 0px" }}
                                title={t(
                                    "sidebar:title.extraction.extractionCylinder"
                                )}
                                captions={offer.skUs.map((sku) =>
                                    splitTag(sku.tagId)
                                )}
                            >
                                {offer.skUs.map((sku, idx) => {
                                    const buyerAnalysis =
                                        selectedDispute.buyerCylindersAnalysis.filter(
                                            (ba) => ba.skuId === sku.skuId
                                        );
                                    const customQuantityAndPrice =
                                        buyerAnalysis &&
                                        buyerAnalysis.length > 0
                                            ? buyerAnalysis[0]
                                            : null;
                                    return (
                                        <div id={`qslide-${idx + 1}`} key={idx}>
                                            <DownloadWrapper>
                                                <div>
                                                    {getFileName(
                                                        sku.qualityTestUrl
                                                    )}
                                                </div>
                                                {sku.qualityTestDone ? (
                                                    <DownloadLink
                                                        href={
                                                            sku.qualityTestUrl
                                                        }
                                                        target="_blank"
                                                    >
                                                        {t("actions:download")}
                                                    </DownloadLink>
                                                ) : (
                                                    <div>-</div>
                                                )}
                                            </DownloadWrapper>
                                            {customQuantityAndPrice && (
                                                <div>
                                                    <SidebarField
                                                        labelvariant="pl-0"
                                                        valueVariant={
                                                            customQuantityAndPrice.gasTypeCode !==
                                                            offer.refrigerantGasTypeCode
                                                                ? "wrong"
                                                                : ""
                                                        }
                                                        label={t(
                                                            "label.analysisGasType"
                                                        )}
                                                        value={
                                                            customQuantityAndPrice.gasTypeCode
                                                        }
                                                    />

                                                    <SidebarField
                                                        labelvariant="pl-0"
                                                        label={t(
                                                            "label.analysisQuantity"
                                                        )}
                                                        valueVariant={
                                                            customQuantityAndPrice.quantity !==
                                                            sku.quantity
                                                                ? "wrong"
                                                                : ""
                                                        }
                                                        value={
                                                            customQuantityAndPrice.quantity.toFixed(
                                                                2
                                                            ) + " kg"
                                                        }
                                                    />
                                                    <SidebarField
                                                        labelvariant="pl-0"
                                                        label={t(
                                                            "label.analysisQualityGrade"
                                                        )}
                                                        valueVariant={
                                                            customQuantityAndPrice.gradeName !==
                                                            offer.pressumedGradeName
                                                                ? "wrong"
                                                                : ""
                                                        }
                                                        value={
                                                            customQuantityAndPrice.gradeName
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                            </Slider>
                        </AccordionWrapper>
                    )}
                    {selectedDispute && selectedDispute.acceptedBidInfo && (
                        <>
                            <AccordionWrapper
                                title={t("title.bid.details")}
                                id="bid.details"
                                isOpen={openAccordion}
                                onToggle={setOpenAccordion}
                            >
                                <SidebarField
                                    label={t("label.bid.date")}
                                    value={helpers.toLocalDateIgnoreTime(
                                        selectedDispute.acceptedBidInfo
                                            .bidDateTime
                                    )}
                                />
                                <VSpace size="10px" />
                                {selectedDispute.isCleaning ? (
                                    <>
                                        <BidGradeWrapper>
                                            <BidGrade
                                                title={t(
                                                    "forms:bid.wizard.betterCleaning"
                                                )}
                                                grade="a"
                                                amount={
                                                    selectedDispute
                                                        .acceptedBidInfo
                                                        .offeredPriceGradeA
                                                }
                                                quantity={
                                                    selectedDispute
                                                        .acceptedBidInfo
                                                        .maximumQuantity
                                                }
                                                bidCompanyCountryId={
                                                    selectedDispute
                                                        .acceptedBidInfo
                                                        .bidCompanyCountryId
                                                }
                                            />
                                            <BidGrade
                                                title={t(
                                                    "forms:bid.wizard.standardCleaning"
                                                )}
                                                grade="b"
                                                amount={
                                                    selectedDispute
                                                        .acceptedBidInfo
                                                        .offeredPriceGradeB
                                                }
                                                quantity={
                                                    selectedDispute.maximumQuantity
                                                }
                                                bidCompanyCountryId={
                                                    selectedDispute
                                                        .acceptedBidInfo
                                                        .bidCompanyCountryId
                                                }
                                            />
                                            {selectedDispute.acceptedBidInfo && (
                                                <BigHandlingCosts
                                                    price={
                                                        selectedDispute
                                                            .acceptedBidInfo
                                                            .offeredPriceGradeC
                                                    }
                                                    quantity={
                                                        selectedDispute.maximumQuantity
                                                    }
                                                    administrativeCosts={
                                                        selectedDispute
                                                            .acceptedBidInfo
                                                            .administrationCosts
                                                    }
                                                    transportationCosts={
                                                        selectedDispute
                                                            .acceptedBidInfo
                                                            .transportationCosts
                                                    }
                                                    bidCompanyCountryId={
                                                        selectedDispute
                                                            .acceptedBidInfo
                                                            .bidCompanyCountryId
                                                    }
                                                    title={t(
                                                        "table:headers.handlingCosts"
                                                    )}
                                                />
                                            )}
                                        </BidGradeWrapper>
                                    </>
                                ) : (
                                    <>
                                        <BidGradeWrapper>
                                            <BidGrade
                                                grade="a"
                                                amount={
                                                    selectedDispute
                                                        .acceptedBidInfo
                                                        .offeredPriceGradeA
                                                }
                                                quantity={
                                                    selectedDispute.maximumQuantity
                                                }
                                                bidCompanyCountryId={
                                                    selectedDispute
                                                        .acceptedBidInfo
                                                        .bidCompanyCountryId
                                                }
                                            />
                                            <BidGrade
                                                grade="b"
                                                amount={
                                                    selectedDispute
                                                        .acceptedBidInfo
                                                        .offeredPriceGradeB
                                                }
                                                quantity={
                                                    selectedDispute.maximumQuantity
                                                }
                                                bidCompanyCountryId={
                                                    selectedDispute
                                                        .acceptedBidInfo
                                                        .bidCompanyCountryId
                                                }
                                            />
                                            <BidGrade
                                                grade="c"
                                                amount={
                                                    selectedDispute
                                                        .acceptedBidInfo
                                                        .offeredPriceGradeC
                                                }
                                                quantity={
                                                    selectedDispute.maximumQuantity
                                                }
                                                bidCompanyCountryId={
                                                    selectedDispute
                                                        .acceptedBidInfo
                                                        .bidCompanyCountryId
                                                }
                                            />
                                        </BidGradeWrapper>
                                        {selectedDispute.acceptedBidInfo && (
                                            <BigHandlingCosts
                                                price={
                                                    selectedDispute
                                                        .acceptedBidInfo
                                                        .offeredPriceGradeC
                                                }
                                                quantity={
                                                    selectedDispute.maximumQuantity
                                                }
                                                administrativeCosts={
                                                    selectedDispute
                                                        .acceptedBidInfo
                                                        .administrationCosts
                                                }
                                                transportationCosts={
                                                    selectedDispute
                                                        .acceptedBidInfo
                                                        .transportationCosts
                                                }
                                                bidCompanyCountryId={
                                                    selectedDispute
                                                        .acceptedBidInfo
                                                        .bidCompanyCountryId
                                                }
                                                title={t(
                                                    "table:headers.handlingCosts"
                                                )}
                                            />
                                        )}
                                    </>
                                )}
                            </AccordionWrapper>

                            {offer && offer.hasAnalysis && (
                                <AccordionWrapper
                                    title={t(
                                        "title.quality.qualityConfirmation"
                                    )}
                                    id="quality.qualityConfirmation"
                                    isOpen={openAccordion}
                                    onToggle={setOpenAccordion}
                                >
                                    {offer.skUs.map((o, i) => {
                                        return (
                                            <>
                                                <SidebarField
                                                    key={i}
                                                    label={t(
                                                        "label.cylinder.extractionCylinder",
                                                        { index: i + 1 }
                                                    )}
                                                    value={
                                                        "<a href='" +
                                                        o.qualityTestUrl +
                                                        "'>" +
                                                        t("actions:download") +
                                                        "</a>"
                                                    }
                                                    dangerousHtml={true}
                                                />
                                                <SidebarField
                                                    key={i}
                                                    label={t(
                                                        "label.cylinder.extractionCylinder",
                                                        { index: i + 1 }
                                                    )}
                                                    value={
                                                        "<a href='" +
                                                        o.qualityTestUrl +
                                                        "'>" +
                                                        t("actions:download") +
                                                        "</a>"
                                                    }
                                                    dangerousHtml={true}
                                                />
                                            </>
                                        );
                                    })}
                                </AccordionWrapper>
                            )}

                            <AccordionWrapper
                                title={t("title.buyer")}
                                id="buyer"
                                isOpen={openAccordion}
                                onToggle={setOpenAccordion}
                            >
                                <SidebarField
                                    label={t("title.company")}
                                    value={
                                        selectedDispute.acceptedBidInfo
                                            .bidCompanyName
                                    }
                                />
                                <SidebarField
                                    label={t("label.companyVAT")}
                                    value={
                                        selectedDispute.acceptedBidInfo
                                            .bidCompanyVAT
                                    }
                                />
                                <SidebarField
                                    label={t("label.companyStreet")}
                                    value={
                                        selectedDispute.acceptedBidInfo
                                            .bidCompanyAddress
                                    }
                                />
                                <SidebarField
                                    label={t("label.companyCity")}
                                    value={
                                        selectedDispute.acceptedBidInfo
                                            .bidCompanyCity
                                    }
                                />
                                <SidebarField
                                    label={t("label.companyState")}
                                    value={
                                        selectedDispute.acceptedBidInfo
                                            .bidCompanyRegion
                                    }
                                />
                                <SidebarField
                                    label={t("label.companyZIP")}
                                    value={
                                        selectedDispute.acceptedBidInfo
                                            .bidCompanyPostalCode
                                    }
                                />

                                {selectedDispute.acceptedBidInfo
                                    .bidCompanyCountry && (
                                    <SidebarField
                                        label={t("label.companyCountry")}
                                        value={
                                            selectedDispute.acceptedBidInfo
                                                .bidCompanyCountry
                                        }
                                    />
                                )}

                                <SidebarField
                                    label={t("label.companyContactPersonName")}
                                    value={
                                        selectedDispute.acceptedBidInfo
                                            .bidCompanyContactPerson
                                    }
                                />
                                <SidebarField
                                    label={t("label.companyEmail")}
                                    value={
                                        selectedDispute.acceptedBidInfo
                                            .bidCompanyEmail
                                    }
                                />
                                <SidebarField
                                    label={t("label.companyPhone")}
                                    value={
                                        selectedDispute.acceptedBidInfo
                                            .bidCompanyPhone
                                    }
                                />
                            </AccordionWrapper>
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default DisputesDetailSideBar;
