import CompanyService from "../../../services/Company/CompanyService";
import createListStateModule from "../createListStateModule";

const equipmentListStateModule = createListStateModule({
    stateNamespace: "equipment",
    itemsPropertyName: "equipment",
    loadItemsMethodName: "loadEquipment",
    itemsDataMethod: CompanyService.getCompanyAnalyzers,
    loadDetailMethodName: "loadEquipmentById",
    detailDataMethod: async (id) => {
        const equipment = await CompanyService.getMyGasAnalyzerByIdAsync(id);
        return equipment.data.data[0]
    },
    detailsPropertyName: "equipmentData",
    deleteMethodName: 'deleteEquipment',
    deleteDataMethod: CompanyService.deleteGasAnalyzerAsync,
    updateMethodName: 'updateEquipment',
    updateDataMethod: CompanyService.updateGasAnalyzerAsync,
    createMethodName: 'createEquipmentItem',
    defaultItemsData: {data: []},
    createDataMethod: CompanyService.createCompanyEquipment
});

export default equipmentListStateModule;
