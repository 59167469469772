import React from "react";
import DataEntry from "../../../../../components/data-entry/DataEntry";
import { buildValidation } from "../../../../../core/validations/ValidationRule";
import User2faForm from "../../../../User/components/User2faForm";

const defVals = {
    passwordExpiration: 0,
    twoFactorEnabled: false,
};

const Change2faSection = ({ onSubmit, defaultValues = defVals }) => {
    const handleSubmit = async (values) => {
        values.passwordExpiration = Number(values.passwordExpiration);
        onSubmit && onSubmit(values);
    };

    const twoFAValidation = buildValidation({});
    return (
        <DataEntry
            initialValues={defaultValues}
            validationSchema={twoFAValidation}
            renderForm={(formik) => <User2faForm formik={formik} />}
            onSubmit={handleSubmit}
            isLoading={false}
        />
    );
};

export default Change2faSection;
