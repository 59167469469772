import Color from "color";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import useLeftMenu from "../../../core/state/navigation/useLeftMenu";
import userStateModule from "../../../core/state/user/userStateModule";
import useStateModule from "../../../core/state/useStateModule";
import UserService from "../../../services/User/UserService";
import HeaderLogo from "../../elements/header/components/HeaderLogo";
import LeftMenuListItem from "./LeftMenuListItem";

const MenuListWrapper = styled.div``;

export const MenuList = styled.ul`
    list-style: none;
    margin: 0px 0 0;
    padding: 0;

    &.submenu {
        display: none;
    }
`;

const MenuBody = styled.div`
    position: relative;
    z-index: 10;
    flex-grow:1;
`;

const MenuElem = styled.div`
    display:flex;
    flex-direction:column;
    background: ${(props) => Color(props.theme.bgWhite).fade(0.3).string()};
    min-height: 100%;
    width: 60px;
    min-width: 60px;
    box-sizing: border-box;
    box-shadow: 0 6px 40px rgba(0, 0, 0, 0.16);
    transition: all 0.25s ease-out;
    white-space: nowrap;
    position: relative;
    padding: 0;
    z-index: 500;

    @media all and (max-width: ${props => props.theme.mediumDevices}) {
        display: none;
    }

    &.isOpened {
        min-width: ${(props) => props.theme.leftMenuWidth};
        width: ${(props) => props.theme.leftMenuWidth};
        overflow-x: visible;
    }

    &.isHovered {
        ${MenuBody} {
            z-index: 20;
        }
    }
`;

const MenuLogoWrapper = styled.div`
    position: relative;
`;

const MenuToggle = styled.div`
    width: 50px;
    height: 50px;
    background: #fcfcfc;
    &.isOpened * {
        &:before {
            transform: rotate(0deg);
        }
    }
`;

const MenuToggleIcon = styled.span`
    display: block;
    width: 100%;
    height: 100%;
    position: relative;

    &:before {
        content: "";
        position: absolute;
        background: url('/images/actions/menu-open.svg') no-repeat center;
        width: 100%;
        height: 100%;
        top: -6px;
        left: 6px;
        transform: rotate(180deg);
    }

    &:hover {
        cursor: pointer;
    }
`;

const LeftMenu = () => {
    let {menuItems, isVisible} = useLeftMenu();
    const menuRef = useRef();
    const {menuState, toggleMenu} = useStateModule(userStateModule);
    const [hovered, setHovered] = useState(false);

    const handleMenuOpen = () => {
        toggleMenu(!menuState.isOpened);
    };


    const userRoles = UserService.getUserRoles();

    const handleMenuItemHover = () => {
        setHovered(hovered => !hovered);
    };

    if (!menuItems || !isVisible) {
        return <></>;
    }

    return (
        <MenuElem ref={menuRef} className={[menuState.isOpened && "isOpened", hovered ? 'isHovered': '']}
                  onMouseEnter={handleMenuItemHover}
                  onMouseLeave={handleMenuItemHover}
        >
            <MenuBody className="menu-body">
                <MenuLogoWrapper>
                    <HeaderLogo/>
                </MenuLogoWrapper>

                <MenuListWrapper>
                    <MenuList >
                        {menuItems &&
                        menuItems.map((item, index) => {
                            if (item.showForRoles) {
                                const shouldshow = userRoles.filter(
                                    (role) =>
                                        item.showForRoles.includes(role)
                                );
                                if (item.hide !== true && shouldshow && shouldshow.length > 0) {
                                    return (
                                        <LeftMenuListItem
                                            key={item.name + index}
                                            item={item}
                                            children={item.children}
                                            menuIsOpened={
                                                menuState.isOpened
                                            }
                                            disabled={item.disabled}
                                        />
                                    );
                                } else {
                                    return null;
                                }
                            } else {
                                return item.hide === true ? null: (
                                    <LeftMenuListItem
                                        key={item.name + index}
                                        item={item}
                                        children={item.children}
                                        menuIsOpened={menuState.isOpened}
                                        disabled={item.disabled}
                                    />
                                );
                            }
                        })}
                    </MenuList>
                </MenuListWrapper>
            </MenuBody>
            <MenuToggle
                onClick={handleMenuOpen}
                className={menuState.isOpened && "isOpened"}
            >
                <MenuToggleIcon/>
            </MenuToggle>
        </MenuElem>
    );
};

export default LeftMenu;
