import React from "react";
import styled from "styled-components";
import Flex from "../../components/layout/flex/Flex";

const Element = styled(Flex.Row)`
    margin: 0 0 40px;
    max-width: 960px;

    &.nomargin {
        margin: 0;
    }

    &.animated {
        max-height: 600px;
        transition: max-height 0.25s ease-in;
    }

    &.animated.open {
        max-height: 900px;
    }

    &.login {
        flex-grow: 1;
        display: flex;
        align-items: center;
        padding: 0px 30px;
        margin:0;
    }
`;

const FormWrapper = ({ children, variant, ...rest }) => {
    return (
        <Element className={variant} {...rest}>
            {children}
        </Element>
    );
};

export default FormWrapper;
