import { useContext, useMemo } from "react";
import {
    listingActionTypes,
    ListingPageContext,
} from "../../components/data/ListingPageProvider";

const useListingPageContext = () => {
    const {state, dispatch} = useContext(ListingPageContext);

    const actions = useMemo(() => ({
        addSelectedRow: (row) => {
            dispatch({type: listingActionTypes.ADD_SELECTEDROW, payload: row});
        },
        removeSelectedRow: (rowId) => {
            dispatch({type: listingActionTypes.REMOVE_SELECTEDROW, payload: rowId});
        },
        resetState: () => {
            dispatch({type: 'RESET_STATE'})
        },
        setTableInstance: (instance) => {
            dispatch({type: listingActionTypes.SET_TABLEINSTANCE, payload: instance});
        }
    }), [dispatch]);


    return {state, actions}
}
export default useListingPageContext;
