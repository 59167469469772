import { useEffect, useState } from "react";

export default function useMatchMedia(query) {
    const mediaQueryList = window.matchMedia(query)
    const [ match, setMatch ] = useState(mediaQueryList.matches)

    useEffect(() => {
      const handleMatchChange = (ev) => setMatch(ev.matches)

      mediaQueryList.addListener(handleMatchChange)

      return () => {
        mediaQueryList.removeListener(handleMatchChange)
      }
    }, [ query ])

    return match
  }
