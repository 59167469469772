import {useHistory} from 'react-router-dom';
import RouteCreator from "../../../../core/RouteCreator";
import UserService from "../../../../services/User/UserService";

const ProfileScreen = () => {

    const userDetails = UserService.getUserDetailsFromToken();
    const userId = userDetails ? userDetails.UserId : null;
    const history = useHistory();

    if (userId) {
        history.push(RouteCreator.employee.edit(userId));
        return null;
    } else {
        history.push(RouteCreator.user.login())
    }

    return null;
};

export default ProfileScreen;
