import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useQueryString from "../../hooks/_shared/useQueryString";
import RouteCreator from "../../core/RouteCreator";
import RegistrationService from "../../services/Registration/RegistrationService";
import styled from "styled-components";
import Headings from "../../components/elements/headings/Headings";
import Flex from "../../components/layout/flex/Flex";
import LayoutOneTwo from "../../components/layout/split/one-two/LayoutOneTwo";
import Wrappers from "../../components/wrappers/Wrappers";
import NavigationLink from "../../components/elements/links/NavigationLink";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

const Desc = styled.div`
    width: 100%;
    font-size: 14px;
    text-align: center;
    color: #898989;

    @media ${(p) => p.theme.device.mobile} {
        font-size: 10px;
    }
`;
const Section = styled.div`
    font-size: 20px;
    padding: 0px 0px;
    margin-bottom: 35px;
    &.bold {
        font-weight: 600;
    }
    & span {
        color: ${(p) => p.theme.primaryColor};
    }

    @media ${(p) => p.theme.device.mobile} {
        padding: 0;
    }
`;

const Circle = styled.div`
    width: 44px;
    height: 44px;
    color: ${(p) => p.theme.primaryColor};
    border: 3px solid ${(p) => p.theme.primaryColor};
    border-radius: 50%;
    float: left;
    margin-right: 25px;
    margin-right: 25px;
    display: flex;
    justify-content: center;
    align-items: center;

    & img {
        height: 20px;
    }
`;

const RegistrationConfirmedScreen = () => {
    const { t } = useTranslation();
    const params = useQueryString();
    const userName = params.username;
    const userToken = params.token;
    const timeoutFn = useRef(null);
    const history = useHistory();

    const [confirmData, setConfirmData] = useState({
        error: null,
        isLoading: true,
    });

    const verify = async () => {
        const result = await RegistrationService.verifyEmailAsync(
            userName,
            userToken
        );
        const error = result.error ? t(result.error.message) : null;
        setConfirmData({ isLoading: false, error: error });
    };

    useEffect(() => {
        verify();
    }, []);

    useEffect(() => {
        if (confirmData.isLoading === false && confirmData.error === null) {
            timeoutFn.current = setTimeout(
                () => history.push(RouteCreator.user.login()),
                5000
            );
        }
    }, [confirmData.error, history]);

    const requestResend = async (ev) => {
        ev.preventDefault();
        const result = await RegistrationService.resendConfirmationAsync(
            userName
        );
        if (result.error) toast.error(t(result.error.message));
        else {
            toast.success(t("screens:forgottenPassword.thankYou"));
        }
    };

    return (
        <Flex.Container className="full-height">
            <Helmet>
                <title>{t("seo:registrationConfirmed.title")}</title>
            </Helmet>
            <LayoutOneTwo>
                <Wrappers.FormWrapper className="login">
                    <Wrappers.FormWrapper>
                        <Headings.H2
                            className="center title bold"
                            style={{ marginBottom: "150px" }}
                        >
                            {t("screens:registration.title")}
                        </Headings.H2>
                        {confirmData.error ? (
                            <Section className="bold">
                                {confirmData.error}
                            </Section>
                        ) : (
                            <Flex.Row>
                                {confirmData.isLoading === false && (
                                    <>
                                        <Section className="bold">
                                            <Circle>
                                                <img
                                                    alt="ok"
                                                    src="/images/actions/icon-ok-primary.svg"
                                                />
                                            </Circle>
                                            {t(
                                                "screens:registration.registrationEmailConfirmed.title"
                                            )}
                                        </Section>
                                        <Section>
                                            {t(
                                                "screens:registration.registrationEmailConfirmed.text"
                                            )}
                                        </Section>

                                        <Section>
                                            {t(
                                                "screens:registration.registrationEmailConfirmed.text2"
                                            )}
                                            <br /><br />
                                            <span>{userName}</span>
                                        </Section>
                                    </>
                                )}
                            </Flex.Row>
                        )}
                        <Wrappers.Wrapper
                            className="form-links-wrapper"
                            style={{ marginTop: "-30px" }}
                        >
                            {confirmData.error && (
                                <>
                                    <NavigationLink
                                        to="#"
                                        className="forgotten-password"
                                        onClick={requestResend}
                                    >
                                        {t("screens:forgottenPassword.resend")}
                                    </NavigationLink>
                                    <span style={{ margin: "0px 24px" }}>
                                        &#124;
                                    </span>
                                </>
                            )}

                            <NavigationLink
                                to={RouteCreator.user.login()}
                                className="forgotten-password"
                            >
                                {t("screens:forgottenPassword.backToLoginLink")}
                            </NavigationLink>
                        </Wrappers.Wrapper>
                    </Wrappers.FormWrapper>
                </Wrappers.FormWrapper>
                <Desc>{t("screens:login.platform")}</Desc>
            </LayoutOneTwo>
        </Flex.Container>
    );
};

export default RegistrationConfirmedScreen;
