import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import ValidationRule, { buildValidation, skipValidation } from "../../core/validations/ValidationRule";
import commonValidation, { commonRegex } from "../../core/validations/common";
import RouteCreator from "../../core/RouteCreator";
import useStateModule from "../../core/state/useStateModule";
import companyStateModule from "../../core/state/company/companyStateModule";
import * as yup from "yup";
import { Helmet } from "react-helmet";
import LayoutOneTwo from "../../components/layout/split/one-two/LayoutOneTwo";
import styled from "styled-components";
import Flex from "../../components/layout/flex/Flex";
import { toast } from "react-toastify";
import Headings from "../../components/elements/headings/Headings";
import { Select, Radio, Checkbox } from "../../components/elements/form-elements";
import Button from "../../components/elements/buttons/Button";
import NavigationLink from "../../components/elements/links/NavigationLink";
import InputElement from "../../components/elements/form-elements/InputElement";
import CountryService from "../../services/User/CountryService";
import SingleDatePicker from "../../components/dateTime/SingleDatePicker";
import helpers from "../../core/helpers";
import RegistrationService from "../../services/Registration/RegistrationService";

const Wrapper = styled.div`
    height: 100%;
    padding: 50px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const Title = styled.div`
    font-size: 14px;
    color: #000;
    line-height: 14px;
    &.radio {
        font-weight: 700;
        font-size: 20px;
        line-height: 20px;
    }
`;

const Desc = styled.ul`
    font-size: 14px;
    font-weight: normal;
    line-height: 14px;
    padding-inline-start: 30px;
    color: #666;
`;

const StepsWrapper = styled.div`
    position: absolute;
    left: 50%;
    display: flex;
    margin: 32px auto;
    width: max-content;
    transform: translateX(-50%);
`;

const Circle = styled.span`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 1px solid ${(p) => p.theme.secondaryColor};
    background-color: #fff;

    & + & {
        margin-left: 12px;
    }

    &.active {
        background-color: ${(p) => p.theme.secondaryColor};
    }
`;

const NavContainer = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-evenly;
`;

const GreenCircle = styled.div`
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    color: ${(p) => p.theme.primaryColor};
    border: 3px solid ${(p) => p.theme.primaryColor};
    border-radius: 50%;
    float: left;
    margin-right: 25px;

    & img {
        height: 16px;
    }
`;

const VSpace = styled.div`
    width: 100%;
    height: ${(p) => p.size || 0}px;
`;

const Email = styled.div`
    margin-top: 35px;
    color: ${(p) => p.theme.primaryColor};
`;

const Form = styled.form`
    display: flex;
    justify-content: center;
    height: 100%;
    flex-direction: column;
    margin-bottom: 32px;
    & button {
        margin: auto auto 0;
    }

    & section {
        flex-grow: 1;
    }
`;

const OrangeInfo = styled.img`
    width: 44px;
    height: 44px;
    margin-right: 24px;
`;

const BigTitle = styled.div`
    font-size: 20px;
    font-weight: 700;

    &.regular {
        font-weight: 400;
    }

    &.vspace {
        margin-top: 32px;
        margin-bottom: 32px;
    }

    &.vspace-big {
        margin-top: 40px;
        margin-bottom: 48px;
    }

    &.header {
        text-align: center;
        font-size: 24px;
    }
`;

const QuestMark = styled.span`
    position: absolute;
    right: -38px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    width: 20px;
    height: 20px;
    background-color: ${(p) => p.theme.secondaryColor};
    border-radius: 50%;
    color: white;
    cursor: help;
    top: 50%;
    transform: translateY(-50%);
    &:hover span {
        display: block;
    }

    & a {
        color: #fff;
    }

    & span {
        display: none;
        position: absolute;
        right: 0px;
        top: 12px;
        background-color: #2294d2;
        border-radius: 8px;
        min-width: 150px;
        min-height: 48px;
        padding: 8px;
        font-size: 12px;
        box-shadow: -2px 9px 11px -16px rgba(0, 0, 0, 0.75);
        transform: translateY(-50%);
        z-index: 2;
        line-height: 16px;

        &.wide {
            min-width: 250px;
        }

        & ul {
            margin: 0;
            padding-inline-start: 15px;
        }
        & ul.dashed {
            list-style-type: none;
        }
        & ul.dashed > li {
            line-height: 16px;
            text-indent: -5px;
        }
        & ul.dashed > li:before {
            content: "-";
            text-indent: -5px;
        }
    }
`;

const PassInfo = styled.div`
    font-size: 12px;
    font-weight: 400;
    color: #fff;
    line-height: 18px;
    & ul {
        margin: 0;
        padding-inline-start: 15px;
    }
    & ul.dashed {
        list-style-type: none;
    }
    & ul.dashed > li {
        text-indent: -5px;
    }
    & ul.dashed > li:before {
        content: "-";
        text-indent: -5px;
    }
`;

const Section = styled.div`
    font-size: 20px;
    padding: 0px 0px;
    margin-bottom: 35px;
    &.bold {
        font-weight: 600;
    }
    & span {
        color: ${(p) => p.theme.primaryColor};
    }

    @media ${(p) => p.theme.device.mobile} {
        padding: 0;
    }
`;

const FlexNoWrap = styled.div`
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    position: relative;
    &.between {
        justify-content: space-between;
    }
`;

const initialValues = {
    email: "",
    country: "",
    companyName: "",
    vatNumber: "",
    password: "",
    repeatPassword: "",
    fGasCertificateCheck: true,
    fGasCertificate: "",
    licenceNumber: "",
    licenceExpirationDate: "",
    licenceNeverExpires: false,
    companyType: 0,
    createDaikinId: false,
};

const vatMaxLength = {
    CZ: 10,
    HU: 8,
    SK: 10,
};

const signupSteps = (t, formik, countries, resendValidationEmail) => {
    const handleCountryChange = (ev) => {
        formik.setFieldValue("vatNumber", "");
        formik.handleChange(ev);
    };

    return [
        <section>
            <Flex.Row style={{ marginTop: "120px", marginLeft: "38px" }}>
                <Radio
                    name="companyType"
                    value="0"
                    defaultChecked={true}
                    secondary
                    top
                    onChange={(e) => {
                        formik.setFieldValue("companyType", 0);
                    }}
                >
                    <Title className="radio">{t("forms:companyType.InstallationCompany")}</Title>
                </Radio>
                <div style={{ marginTop: "40px" }}></div>
                <Radio
                    name="companyType"
                    value="1"
                    secondary
                    top
                    onChange={(e) => {
                        formik.setFieldValue("companyType", 1);
                    }}
                >
                    <Title className="radio">{t("forms:companyType.Distributor")}</Title>
                </Radio>
                {/* <Radio
                    name="companyType"
                    value="2"
                    disabled
                    secondary
                    top
                    // onChange={() => setCompany(c.companyId)}
                >
                    <div>
                        <Title>We are regulatory body</Title>
                    </div>
                </Radio> */}
            </Flex.Row>
        </section>,
        <section>
            <VSpace size="36" />
            <Flex.Row style={{ display: "flex", alignItems: "center" }}>
                <OrangeInfo src="/images/menu/orange-info.svg" />
                <BigTitle>{t("screens:login.certNeeded")}</BigTitle>
            </Flex.Row>
            <BigTitle className="regular vspace">
                {formik.values.companyType === 0
                    ? t("screens:login.registerProvider")
                    : t("screens:login.registerWholesaler")}
            </BigTitle>
            <div style={{ color: "#898989" }}>{t("screens:login.documentReady")}</div>
        </section>,
        <section>
            <VSpace size="40" />
            <InputElement
                labelText={t("forms:companyName.text")}
                id="companyName"
                name="companyName"
                autocomplete="new-companyName"
                type="text"
                {...formik}
            />
            <Select
                id="country"
                name="country"
                labelText={t("forms:country.text")}
                options={countries.map((c) => ({
                    key: c.name,
                    value: c.code,
                    label: `<img src="/images/flags/${c.code.toLowerCase()}.png" />${c.name}`,
                }))}
                {...formik}
                handleChange={handleCountryChange}
            />
            <FlexNoWrap>
                <InputElement
                    style={{ width: "100%" }}
                    labelText={t("forms:VAT.text")}
                    id="vatNumber"
                    name="vatNumber"
                    autocomplete="new-vatNumber"
                    type="text"
                    prefix={formik.values.country}
                    placeholderChars={{
                        num: vatMaxLength[formik.values.country],
                        char: "_",
                    }}
                    onlyNumbers
                    {...formik}
                />
                <QuestMark>
                    ?<span>{t(`screens:login.${formik.values.country}Vat`)}</span>
                </QuestMark>
            </FlexNoWrap>
        </section>,
        <section>
            <BigTitle className="header vspace-big">{t("screens:certificates.companyCert")}</BigTitle>
            <FlexNoWrap className="between">
                <InputElement
                    style={{ width: "48%" }}
                    labelText={t("forms:fGasCertificate.certNumber")}
                    id="licenceNumber"
                    name="licenceNumber"
                    type="text"
                    {...formik}
                />
                <SingleDatePicker
                    style={{ width: "48%" }}
                    labelText={t("forms:licenceExpirationDate.text")}
                    name="licenceExpirationDate"
                    disabled={formik.values.licenceNeverExpires}
                    // selected={currentDate}
                    // currentDate={currentDate}

                    formik={formik}
                    {...formik}
                />
            </FlexNoWrap>
            <div
                style={{
                    display: "flex",
                    justifyContent: "start",
                    paddingRight: "8px",
                }}
            >
                <Checkbox
                    labelClassName="center"
                    labelText={t("screens:certificates.neverExpires")}
                    id="licenceNeverExpires"
                    name="licenceNeverExpires"
                    checked={formik.values.licenceNeverExpires}
                    {...formik}
                />
            </div>
            <InputElement
                labelText=""
                placeholder={t("forms:fGasCertificateFileUpload.text")}
                title={t("forms:fGasCertificateFileUpload.header")}
                id="fGasCertificate"
                type="singlefile"
                name="fGasCertificate"
                className="wide2"
                style={{ width: "100%", marginTop: "32px" }}
                accept="application/pdf, image/*"
                {...formik}
            />
        </section>,
        <section>
            <InputElement
                labelText={t("forms:email.text")}
                id="email"
                name="email"
                autocomplete="new-email"
                type="text"
                {...formik}
            />
            <VSpace size="20" />
            <div style={{ position: "relative" }}>
                <InputElement
                    labelText={t("screens:login.setPassword")}
                    id="password"
                    name="password"
                    type="password"
                    autocomplete="new-password"
                    wrapperVariant="full-width"
                    {...formik}
                />
                <QuestMark>
                    ?
                    <span className="wide">
                        <PassInfo className="wide">
                            {t("screens:login.passInfo")}
                            <ul className="dashed">
                                <li>{t("screens:login.passLower")}</li>
                                <li>{t("screens:login.passUpper")}</li>
                                <li>{t("screens:login.passDigits")}</li>
                                <li>{t("screens:login.passSymbols")}</li>
                            </ul>
                        </PassInfo>
                    </span>
                </QuestMark>
            </div>

            <InputElement
                labelText={t("screens:login.confirmPassword")}
                name="repeatPassword"
                type="password"
                {...formik}
            />
            <div style={{ position: "relative", width:"max-content" }}>
                <Checkbox
                    labelClassName="center"
                    labelText={t("screens:login.createDaikinId")}
                    id="createDaikinId"
                    name="createDaikinId"
                    checked={formik.values.createDaikinId}
                    {...formik}
                />
                <QuestMark>
                    ?
                    <span className="wide">
                        <PassInfo className="wide">
                            <div style={{ marginBottom: "15px" }}>{t("screens:login.daikinIdPlatform")}</div>
                            <div>{t("screens:login.moreInfo")}:</div>
                            <a
                                rel="noopener noreferrer"
                                target="_blank"
                                href="https://www.daikin.eu/en_us/daikin-id.html"
                            >
                                https://www.daikin.eu/en_us/daikin-id.html
                            </a>
                        </PassInfo>
                    </span>
                </QuestMark>
            </div>
        </section>,
        <section>
            <Flex.Row>
                <VSpace size="23" />
                <Section className="bold">
                    <GreenCircle>
                        <img alt="ok" src="/images/actions/icon-ok-primary.svg" />
                    </GreenCircle>
                    {t("screens:login.receivedRequest")}
                </Section>
                <Section>
                    {t("screens:forgottenPassword.emailSent")}:<Email>{formik.values.email}</Email>
                </Section>
                <Section>{t("screens:registration.validateEmail.verify")}</Section>
                <Section>
                    {t("screens:registration.validateEmail.resend.text")}
                    <NavigationLink
                        to="#"
                        className="forgotten-password big"
                        onClick={() => resendValidationEmail(formik.values.email)}
                    >
                        {t("screens:registration.validateEmail.resend.button")}
                    </NavigationLink>
                </Section>
            </Flex.Row>
        </section>,
    ];
};

const signupValidator = (t, step) =>
    yup.lazy((values) => {
        switch (step) {
            case 3:
                return buildValidation({
                    companyName: ValidationRule.isStringRule().required(t("forms:companyName.required")).rule,
                    country: ValidationRule.isStringRule().required(t("forms:country.required")).rule,
                    vatNumber: ValidationRule.isStringRule()
                        .transform((value, originalValue) => {
                            return values.country + value;
                        })
                        .regexMatchRule(commonRegex.VATMatch, t("forms:VAT.invalid"))
                        .required(t("forms:VAT.required")).rule,
                });
            case 4:
                return buildValidation({
                    licenceExpirationDate: values.licenceNeverExpires
                        ? yup.string()
                        : ValidationRule.isStringRule().required(t("screens:registration.licenceExpirationDateError"))
                              .rule,
                    fGasCertificate: ValidationRule.isStringRule().required(t("screens:registration.uploadFile.error"))
                        .rule,
                    licenceNumber: ValidationRule.isStringRule().required(t("screens:registration.licenceError")).rule,
                });

            case 5:
                return buildValidation({
                    email: commonValidation.email(t).rule,
                    password: commonValidation.password(t).rule,
                    repeatPassword: ValidationRule.shouldBeEqual(
                        "password",
                        t("forms:repeatPassword.invalid"),
                        t("forms:repeatPassword.required")
                    ).rule,
                });

            default:
                return skipValidation();
        }
    });

const SignupScreen = () => {
    const { t } = useTranslation(["common", "forms"]);
    const [currStep, setCurrStep] = useState(1);
    const [countries, setCountries] = useState([]);
    const { resendValidationEmail } = useStateModule(companyStateModule);

    useEffect(() => {
        (async () => {
            const result = await CountryService.getCountriesAsync();
            let c = result?.data?.data || [];
            setCountries(
                c
                    .filter((c) => c.twoLetterCode !== "NL")
                    .map((r) => ({
                        name: r.countryName,
                        code: r.twoLetterCode,
                    }))
            );
        })();
    }, []);

    const onSubmit = async (values) => {
        let advance = true;
        if (currStep === 5) advance = await handleSignup(values);
        if (advance) setCurrStep((s) => s + 1);
    };

    const onBack = async (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        setCurrStep((s) => s - 1);
    };

    const handleSignup = async (values) => {
        if (values.licenceNeverExpires) values.licenceExpirationDate = new Date("1/1/3000");
        delete values.licenceNeverExpires;
        values.vatNumber = values.country + values.vatNumber;
        let formData = helpers.getFormData(values);

        if (values.fGasFile && values.fGasCertificateCheck)
            formData.append("fGasCertificate", values.fGasFile, values.fGasFile.name);
        const result = await RegistrationService.registerAsync(formData);
        if (result && result.error) {
            toast.error(t(result.error.message));
            return false;
        } else {
            return true;
        }
    };

    initialValues.country = countries?.[0]?.code || "";
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: signupValidator(t, currStep),
        onSubmit: onSubmit,
    });

    return (
        <Flex.Container className="full-height">
            <Helmet>
                <title>{t("seo:registration.title")}</title>
            </Helmet>
            <LayoutOneTwo>
                <Wrapper>
                    <section className="info">
                        <Headings.H2 className="center title bold">{t("screens:registration.title")}</Headings.H2>
                        {currStep < 6 && (
                            <div
                                style={{
                                    margin: "48px 0px 40px",
                                    fontSize: "15px",
                                    textAlign: "center",
                                    letterSpacing: "-1px",
                                }}
                                dangerouslySetInnerHTML={{
                                    __html: t("screens:login.signupDesc"),
                                }}
                            />
                        )}
                    </section>
                    <Form onSubmit={formik.handleSubmit} autoComplete="off">
                        {signupSteps(t, formik, countries, resendValidationEmail)[currStep - 1]}
                        {currStep < 6 && (
                            <NavContainer>
                                <Button
                                    style={{
                                        width: "max-content",
                                        marginTop: "0px",
                                        marginLeft: "0px",
                                    }}
                                    className="tertiary"
                                    onClick={(ev) => {
                                        onBack(ev);
                                    }}
                                    disabled={currStep === 1}
                                >
                                    &lt; {t("forms:wizard.previous")}
                                </Button>

                                <StepsWrapper>
                                    {[1, 2, 3, 4, 5].map((step, idx) => (
                                        <Circle key={idx} className={step <= currStep ? "active" : ""} />
                                    ))}
                                </StepsWrapper>

                                <Button
                                    style={{
                                        width: "max-content",
                                        marginTop: "0px",
                                        marginRight: "0px",
                                    }}
                                    className="tertiary"
                                    onClick={() => {}}
                                >
                                    {t("forms:wizard.next")} &gt;
                                </Button>
                            </NavContainer>
                        )}
                    </Form>
                    <section className="navigation">
                        <Flex.Column style={{ display: "flex", alignItems: "center" }}>
                            {currStep < 6 ? (
                                <div style={{ fontSize: "14px" }}>
                                    {t("screens:login.haveAccount")}{" "}
                                    <NavigationLink to={RouteCreator.user.login()} className="secondary">
                                        {t("screens:login.title")}
                                    </NavigationLink>{" "}
                                    {t("screens:login.here")}.
                                </div>
                            ) : (
                                <div style={{ fontSize: "14px" }}>
                                    <NavigationLink to={RouteCreator.user.login()} className="forgotten-password">
                                        {t("screens:forgottenPassword.backToLoginLink")}
                                    </NavigationLink>
                                </div>
                            )}
                        </Flex.Column>
                    </section>
                </Wrapper>
            </LayoutOneTwo>
        </Flex.Container>
    );
};

export default SignupScreen;
