import PropTypes from "prop-types";
import React from "react";
import {SidebarFieldWrapper, SidebarLabel, SidebarValue} from "./SidebarStyle";

const SidebarField = (props) => {
    const {label, value, multipleValues, dangerousHtml, valueVariant, labelvariant, wrapperVariant, style} = props;

    let fieldValue = '';
    if (value) {
        fieldValue = value;
    } else if (multipleValues && multipleValues.length > 0) {
        fieldValue = multipleValues.map(val => {
            return val.props.children
        });
    } else {
        fieldValue = '-';
    }

    return (
        <SidebarFieldWrapper style={style} className={wrapperVariant ? wrapperVariant : ''}>
            <SidebarLabel className={labelvariant ? labelvariant : ''}>
                {label}
            </SidebarLabel>
            <SidebarValue className={valueVariant ? valueVariant : ''}>
                {
                    dangerousHtml ?
                        <span dangerouslySetInnerHTML={{__html: fieldValue}}/>
                        :
                        typeof value==="function" ? value(): fieldValue.toString()
                }
            </SidebarValue>
        </SidebarFieldWrapper>
    )
};

export default SidebarField;

SidebarField.propTypes = {
    label: PropTypes.any.isRequired
};
