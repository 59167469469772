import React from "react";
import { useTranslation } from "react-i18next";

const RoleCell = ({ cell: { value } }) => {
    const { t } = useTranslation("common");
    if (value && value.length > 0) {
        return value.map((role) => {
            return <span key={role}>{t("roles." + role)}</span>;
        });
    }
    return null;
};

export default RoleCell;
