import styled from "styled-components";

const StyledTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    border: none;
    font-size: 0.875rem;
    display: table;

    & .right-arrow {
        text-align: right;
        padding: 15px 0px 15px 15px !important;
    }

    img {
        width: auto;
        height: 17px;
        position: absolute;
        left: -5px;
        top: 0px;
    }

    th {
        font-weight: 600;
        text-transform: uppercase;
        overflow: hidden;
        text-align: left;
        vertical-align: top;
        position: relative;
        cursor: pointer;
        & > div {
            display: flex;
            align-items: center;
        }

        @media ${(p) => p.theme.device.mobile} {
            text-transform: none;
        }

        /* & span {
            position: absolute;
            left: 6px;
            top: 20px;
        } */
    }

    th.right,
    td.right {
        text-align: right;
        & > div {
            justify-content: flex-end;
        }
    }

    th.center,
    td.center {
        text-align: center;
        & > div {
            justify-content:center;
        }
    }

    th,
    td {
        display: table-cell;
        vertical-align: middle;
        text-align: left;
        padding: 15px 0px 15px 20px;
    }

    th:last-child,
    td:last-child {
        padding: 15px 20px;
    }

    td {
        border-bottom: 1px solid ${(props) => props.theme.tableInputBorderColor};
        padding: 15px 0px 15px 20px;
    }

    tbody > tr:hover {
        background: ${(props) => props.theme.bgWhite};
        cursor: pointer;

        td:first-child {
            position: relative;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 5px;
                height: 100%;
                background-color: ${(props) => props.theme.secondaryColor};
            }
        }
    }

    tbody > tr:last-child td {
        border: none;
    }

    tr {
        &.my-company {
            background: ${(props) => props.theme.bgWhite};

            td:first-child {
                position: relative;

                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 5px;
                    height: 100%;
                    background-color: ${(props) =>
                        props.theme.tableActiveBorder};
                }
            }
        }

        &.expanded {
            td {
                border-bottom: none;
                padding: 20px 20px 10px;
            }
        }
    }
`;

export default StyledTable;
