import React from "react";

const ProgressBar = (props) => {
  const { bgcolor, completed, height = 8 } = props;

  const containerStyles = {
    height: height,
    width: '100%',
    backgroundColor: "#e0e0de",
    borderRadius: height/2,
    display: "inline-block"
  }

  const fillerStyles = {
    height: '100%',
    width: `${completed}%`,
    backgroundColor: bgcolor,
    borderRadius: 'inherit',
    textAlign: 'right'
  }

  return (
    <div style={containerStyles}>
    <div style={fillerStyles}>
      
      </div>
    </div>
  );
};

export default ProgressBar;