import React from "react";
import { Redirect, Switch } from "react-router-dom";
import PrivateRoute from "../../core/PrivateRoute";
import useLeftMenu from "../../core/state/navigation/useLeftMenu";
import globalAdminMenuItems from "../../data/menu/globalAdminMenuItems";
import RouteCreator from "../../core/RouteCreator";
import CleaningMarketplace from "../Services/views/CleaningMarketplace";
import DistributorDisposalMarket from "../Services/views/DistributorDisposalMarket";

const ServicesStatisticsScreen = () => {
    useLeftMenu(globalAdminMenuItems);
    return (
        <Switch>
            <PrivateRoute
                path={RouteCreator.admin.services.disposal()}
                component={DistributorDisposalMarket}
            />
            <PrivateRoute
                path={RouteCreator.admin.services.cleaning()}
                exact={true}
                component={CleaningMarketplace}
            />
            <Redirect to={RouteCreator.admin.services.disposal()} />
        </Switch>
    );
};

export default ServicesStatisticsScreen;