import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Flex from "../../../components/layout/flex/Flex";
import useBreadcrumbs from "../../../core/state/navigation/useBreadcrumbs";
import useImmerReducer from "../../../hooks/_shared/useImmerReducer";
import useDataReducerImmediate from "../../../hooks/_shared/useDataReducerImmediate";
import useDataReducer from "../../../hooks/_shared/useDataReducer";
import StatisticsService from "../../../services/Statistics/StatisticsService";
import DashboardWrapper from "../../../components/dashboard/DashboardWrapper";
import FilterableLineChart from "../../../components/dashboard/FilterableLineChart";
import ChartAveragePrice from "../../../components/dashboard/ChartAveragePrice";
import ChartProgress from "../../../components/dashboard/ChartProgress";
import GasMenuWithGrades from "../../pwa/GasMenuWithGrades";
import UserService from "../../../services/User/UserService";
import Roles from "../../../core/enums/Roles";
import styled from "styled-components";

const actionTypes = {
    SET_FILTERS: "SET_FILTERS",
    RESET_FILTERS: "RESET_FILTERS",
};

const LeftColumn = styled(Flex.Column)``;

const RightColumn = styled(Flex.Column)`
    min-width: 320px;
`;

const MainContainer = styled(Flex.Container)`
    height: 100%;

    .container-left {
        width: 80%;
        display: flex;
        height: 100%;
    }

    @media all and (max-width: ${(props) => props.theme.extraLargeDevices}) {
        flex-direction: column;

        .menu {
            width: 100%;
        }

        .container-left {
            order: 2;
            width: 100%;
        }
    }
`;

const SecondContainerRow = styled(Flex.Container)`
    padding-top: 20px;
    flex-grow: 0;
    margin-bottom: 24px;

    @media all and (max-width: ${(props) => props.theme.extraLargeDevices}) {
        flex-direction: column;
    }
`;

const filterReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.SET_FILTERS:
            state.from = action.payload.from;
            state.to = action.payload.to;
            break;
        case actionTypes.RESET_FILTERS:
            delete state.from;
            delete state.to;
            break;
        default:
            return state;
    }
};

const STATUS_COLORS = ["#435764", "#A0B19F", "#D4A579", "#C98380"];
const PRESUMED_GRADE_COLORS = ["#435764", "#A0B19F", "#D4A579"];

const SalesDashboard = () => {
    const { t } = useTranslation("screens");

    useBreadcrumbs([
        {
            text: t("navigation:sales"),
        },
        {
            text: t("navigation:dashboard"),
        },
    ]);

    const [selectedItem, setSelectedItem] = useState({
        refrigerantId: "",
        grade: null,
        name: "",
        from: "",
        to: "",
        color: PRESUMED_GRADE_COLORS[0],
    });

    const [isSales, setIsSales] = useState(true);
    const [averagePrice, setAveragePrice] = useState(0);
    const [menuState, setMenuState] = useState([]);

    const [tradedRefrigerantState] = useDataReducerImmediate(
        StatisticsService.tradedRefrigerants
    );

    const [tradesPerDate, loadTradesPerDate] = useDataReducer(
        async (filter) => {
            return await StatisticsService.tradedQuantityPerDate(filter);
        }
    );

    const [averagesState, loadAverages] = useDataReducer(async (filter) => {
        return await StatisticsService.tradedRefrigerantsAverages(filter);
    });

    //TODO extract to currency helper
    const DEFAULT_SUFIX = "/ € 0, 00*";

    const [distributionData, setDistributionData] = useState([
        {
            name: "Pending",
            value: 0,
            percent: 0,
            color: STATUS_COLORS[0],
            suffix: DEFAULT_SUFIX,
        },
        {
            name: "Accepted",
            value: 0,
            percent: 0,
            color: STATUS_COLORS[1],
            suffix: DEFAULT_SUFIX,
        },
        {
            name: "Quality test",
            value: 0,
            percent: 0,
            color: STATUS_COLORS[2],
            suffix: DEFAULT_SUFIX,
        },
        {
            name: "Sold",
            value: 0,
            percent: 0,
            color: STATUS_COLORS[3],
            suffix: DEFAULT_SUFIX,
        },
    ]);

    const statusTranslationsSales = [
        t("screens:salesDashboard.labels.pending"),
        t("screens:salesDashboard.labels.accepted"),
        t("screens:salesDashboard.labels.qualityTest"),
        t("screens:salesDashboard.labels.sold"),
    ];

    const statusTranslationPurchases = [
        t("screens:salesDashboard.labels.pending"),
        t("screens:salesDashboard.labels.accepted"),
        t("screens:salesDashboard.labels.qualityTest"),
        t("screens:salesDashboard.labels.purchased"),
    ];

    const [bidsData, setBidsData] = useState([]);

    const [filterState, dispatch] = useImmerReducer(filterReducer, {
        from: "",
        to: "",
    });

    const onChangeFilter = (filters) => {
        dispatch({
            type: actionTypes.SET_FILTERS,
            payload: filters,
        });
    };

    useEffect(() => {
        const filter = getSelectedFilter();
        if (filter) {
            loadAverages({ filter: filter });
        }
    }, [filterState, selectedItem]);

    const getSelectedFilter = () => {
        if (filterState && selectedItem && selectedItem.refrigerantId) {
            const refrigerantId = { refrigerantId: selectedItem.refrigerantId };
            const isSalesValue = { isSales: isSales };
            const presumedGrade = { presumedGrade: selectedItem.grade };
            return {
                ...filterState,
                ...refrigerantId,
                ...presumedGrade,
                ...isSalesValue,
            };
        } else return null;
    };

    useEffect(() => {
        if (filterState && selectedItem && selectedItem.refrigerantId) {
            const refrigerantId = { refrigerantId: selectedItem.refrigerantId };
            const presumedGrade = { presumedGrade: selectedItem.grade };
            const isSalesValue = { isSales: isSales };
            const filter = {
                ...filterState,
                ...refrigerantId,
                ...presumedGrade,
                ...isSalesValue,
            };
            loadTradesPerDate({ filter: filter });
        }
    }, [filterState, selectedItem]);

    useEffect(() => {
        if (averagesState && averagesState.data) {
            const gases = averagesState.data.filter(
                (x) => x.id == selectedItem.refrigerantId
            );
            if (gases.length == 1) {
                const gas = gases[0];
                setAveragePrice(gas.averagePrice);
                const distributionData = gas.tradedQuantitiesPerStatus.map(
                    (item, index) => ({
                        name: isSales
                            ? statusTranslationsSales[index]
                            : statusTranslationPurchases[index],
                        value: item.value,
                        percent:
                            gas.totalQuantity > 0
                                ? (item.value / gas.totalQuantity) * 100
                                : 0,
                        color: STATUS_COLORS[item.status],
                        suffix: `/ € ${(item.value * gas.averagePrice).toFixed(
                            2
                        )}*`,
                    })
                );

                setDistributionData(distributionData);
                setBidsData(gas.bidHistory);
            }
        }
    }, [isSales, averagesState, selectedItem]);

    useEffect(() => {
        let data = tradedRefrigerantState?.data;
        if (data) {
            setIsSales(
                UserService.isInRole(Roles.InstallerCompanyAdmin) ||
                    UserService.isInRole(Roles.InstallerCompanyUser)
            );
            let menuData = isSales
                ? tradedRefrigerantState?.data?.sales?.data
                : tradedRefrigerantState?.data?.purchases?.data;
            setMenuAndSelect(menuData);
        }
    }, [tradedRefrigerantState]);

    useEffect(() => {
        let menuData = isSales
            ? tradedRefrigerantState?.data?.sales?.data
            : tradedRefrigerantState?.data?.purchases?.data;
        if (!menuData) menuData = [];

        setMenuAndSelect(menuData);
    }, [isSales]);

    const setMenuAndSelect = (menuData) => {
        if (menuData.length > 0) {
            const firstGas = menuData[0];
            const firstMenu =
                firstGas.items.length > 0 ? firstGas.items[0] : null;
            if (firstMenu) {
                setSelectedItem({
                    refrigerantId: firstGas.id,
                    code: firstGas.code,
                    grade: firstMenu.grade,
                    gradeName: firstMenu.name,
                    from: firstMenu.from,
                    to: firstMenu.to,
                    color: PRESUMED_GRADE_COLORS[firstMenu.grade],
                });

                if (firstMenu.from && firstMenu.to)
                    onChangeFilter({
                        from: new Date(firstMenu.from),
                        to: new Date(firstMenu.to),
                    });
            }
        }

        setMenuState(menuData);
    };

    const handleClick = (item) => {
        if (item && menuState) {
            const parent = menuState.filter(
                (x) => x.id === item.refrigerantId
            )[0];

            const menuItem = {
                refrigerantId: parent.id,
                code: parent.code,
                grade: item.grade,
                gradeName: item.name,
                from: item.from,
                to: item.to,
                color: PRESUMED_GRADE_COLORS[item.grade],
            };

            setSelectedItem(menuItem);
            onChangeFilter({
                from: new Date(item.from),
                to: new Date(item.to),
            });
        }
    };

    const selectedTitle = () => {
        return selectedItem && selectedItem.refrigerantId
            ? `${selectedItem.gradeName} ${t(
                  "screens:salesDashboard.labels.grade"
              )} ${selectedItem.code}`
            : "";
    };

    const selectedSalesTitle = () => {
        const gasTitle = selectedTitle();
        return gasTitle
            ? `${t("screens:salesDashboard.labels.sales")} - ${gasTitle}`
            : "";
    };

    const selectedAverageTitle = () => {
        const gasTitle = selectedTitle();
        return gasTitle
            ? `${t("screens:salesDashboard.labels.averagePrice")} - ${gasTitle}`
            : "";
    };

    const selectedDistributionTitle = () => {
        const gasTitle = selectedTitle();
        return gasTitle
            ? `${t(
                  "screens:salesDashboard.labels.distribution"
              )}  - ${gasTitle}`
            : "";
    };

    return (
        <DashboardWrapper>
            <Helmet>
                <title>{t("seo:salesDashboard.title")}</title>
            </Helmet>
            {tradedRefrigerantState.isCompleted && menuState && (
                <MainContainer>
                    <LeftColumn className="container-left">
                        <FilterableLineChart
                            filterState={filterState}
                            title={selectedSalesTitle()}
                            height={"60%"}
                            data={tradesPerDate?.data}
                            isEmpty={menuState && menuState.length === 0}
                            isCompleted={tradesPerDate?.isCompleted}
                            onChangeFilter={onChangeFilter}
                            column="Quantity"
                            color={selectedItem.color}
                            unit="kg"
                            style={{ marginBottom: "24px" }}
                            showDrillDown={false}
                        ></FilterableLineChart>

                        <SecondContainerRow>
                            <Flex.Column
                                style={{ minWidth: 300, paddingTop: "20px" }}
                            >
                                <ChartAveragePrice
                                    currency="€"
                                    title={selectedAverageTitle()}
                                    amount={averagePrice}
                                    data={bidsData}
                                    color={selectedItem.color}
                                ></ChartAveragePrice>
                            </Flex.Column>
                            <Flex.Column
                                style={{ width: "100%", minWidth: 300 }}
                            >
                                <ChartProgress
                                    data={distributionData}
                                    title={selectedDistributionTitle()}
                                    tooltip={`${t(
                                        "screens:salesDashboard.labels.calculationBasedOnPrice"
                                    )}`}
                                ></ChartProgress>
                            </Flex.Column>
                        </SecondContainerRow>
                    </LeftColumn>
                    <RightColumn className="menu">
                        <GasMenuWithGrades
                            items={menuState}
                            handleClick={handleClick}
                            selectedItem={selectedItem}
                            handleChecked={setIsSales}
                            isChecked={isSales}
                        ></GasMenuWithGrades>
                    </RightColumn>
                </MainContainer>
            )}
        </DashboardWrapper>
    );
};

export default SalesDashboard;
