import React from "react";
import ValidationRule, {
    buildValidation,
} from "../../../../../core/validations/ValidationRule";
import MoneyInputElement from "../../../../../components/elements/form-elements/MoneyInputElement";
import {
    InfoText,
    InfoTitle,
    InfoWrapper,
    RoundInfo,
} from "../../../../../components/modal/components/WizardStyling";
import { Select } from "../../../../../components/elements/form-elements";
import InputElement from "../../../../../components/elements/form-elements/InputElement";
import cylinderListStateModule from "../../../../../core/state/company/cylinderListStateModule";

const validateAmount = (t) => {
    return buildValidation({
        price: ValidationRule.moreThan(
            0,
            t("forms:wallet.wizard.deposit.amount.invalid"),
            t("forms:wallet.wizard.deposit.amount.largerThenZero")
        ).rule,
        quantity: ValidationRule.moreThan(
            0,
            t("forms:wallet.wizard.deposit.amount.invalid"),
            t("forms:wallet.wizard.deposit.amount.largerThenZero")
        ).required(t("forms:wallet.wizard.deposit.amount.invalid")).rule,
        cylinderNetWeight: ValidationRule.moreThan(
            0,
            t("forms:wallet.wizard.deposit.amount.invalid"),
            t("forms:wallet.wizard.deposit.amount.largerThenZero")
        ).required(t("forms:wallet.wizard.deposit.amount.invalid")).rule,
    });
};

const qualities = (t) => {
    return [{ name: t("filters:cylinders.reclaimed"), value: 3 }];
};

const WizardAddReclaimOffer = (t, gasTypes = []) => {
    const defaultInitialValues = {
        price: "0.00",
        quantity: "0.00",
        refrigerantId: gasTypes.length > 0 ? gasTypes[0].id : "",
        grade: 3,
        cylinderNetWeight: "0.00",
    };

    return {
        title: t("screens:reclaimed.actions.sellRefrigerant"),
        initialValues: defaultInitialValues,
        steps: [
            {
                name: t("forms:reclaimed.wizard.title"),
                fieldNames: [
                    "refrigerantId",
                    "price",
                    "quantity",
                    "grade",
                    "cylinderNetWeight",
                ],
                validation: validateAmount(t),
                fields: (formik) => {
                    return (
                        <>
                            <InfoWrapper>
                                <RoundInfo
                                    alt=""
                                    src="/images/menu/green-info.svg"
                                />
                                <div>
                                    <InfoTitle>
                                        {t(
                                            "forms:reclaimed.wizard.infoTextBold"
                                        )}
                                    </InfoTitle>
                                    <InfoText>
                                        {t("forms:reclaimed.wizard.infoText1")}
                                        <br />
                                        {t("forms:reclaimed.wizard.infoText2")}
                                    </InfoText>
                                </div>
                            </InfoWrapper>

                            <Select
                                id="refrigerantId"
                                name="refrigerantId"
                                labelText={t("filters:gas.gasType")}
                                wrapperVariant="flex-1"
                                defaultValue={gasTypes[0].id}
                                options={gasTypes.map((gas) => {
                                    return {
                                        key: gas.id,
                                        value: gas.id,
                                        label: gas.code,
                                    };
                                })}
                                {...formik}
                            />

                            <Select
                                id="grade"
                                name="grade"
                                labelText={t(
                                    "screens:stockListing.filters.quality"
                                )}
                                wrapperVariant="flex-2"
                                options={qualities(t).map((q, idx) => {
                                    return {
                                        key: idx,
                                        value: q.value,
                                        label: q.name,
                                    };
                                })}
                                disabled
                                {...formik}
                            />

                            <MoneyInputElement
                                labelText={t("forms:cylinder.quantity")}
                                currencyLabel="kg"
                                name="quantity"
                                id="quantity"
                                type="number"
                                step="0.01"
                                value={formik.values["quantity"]}
                                wrapperVariant="flex-2 standard"
                                formik={formik}
                            />

                            <MoneyInputElement
                                labelText={t("forms:cylinderSize.fullText")}
                                currencyLabel="kg"
                                name="cylinderNetWeight"
                                id="cylinderNetWeight"
                                type="number"
                                step="0.01"
                                value={formik.values["cylinderNetWeight"]}
                                wrapperVariant="flex-2 standard"
                                formik={formik}
                            />

                            <MoneyInputElement
                                labelText={t("forms:cylinder.priceExVat")}
                                currencyLabel={`EUR/${t(
                                    "forms:qualityTest.cylinder"
                                )}`}
                                name="price"
                                id="price"
                                type="number"
                                step="0.01"
                                value={formik.values["price"]}
                                wrapperVariant="flex-2 standard"
                                formik={formik}
                            />
                        </>
                    );
                },
            },
        ],
    };
};

export default WizardAddReclaimOffer;
