import UserService from "../../../../services/User/UserService";
import createListStateModule from "../../createListStateModule";
import adminRolesStatePlugin from "../../plugins/adminRolesStatePlugin";

const adminUsersListStateModule = createListStateModule({
    stateNamespace: "adminusers",
    itemsPropertyName: "adminUsers",
    detailsPropertyName: "adminData",
    loadItemsMethodName: "loadAdminUsers",
    loadDetailMethodName: "loadUserById",
    itemsDataMethod: UserService.getAdministrators,
    detailDataMethod: UserService.getUserById,
    deleteDataMethod: UserService.deleteUser,
    deleteMethodName: "deleteUser",
    createDataMethod: UserService.createAdministrator,
    createMethodName: "createAdministrator",
    defaultItemsData: {data: []},
    plugins: [
        adminRolesStatePlugin
    ]
});

export default adminUsersListStateModule;
