import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Globals from "../../globalVars";
import SidebarField from "../elements/sidebar/SidebarField";

const Separator = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${(p) => p.theme.sidebarWrapperBorderColor};
`;

const BidGradeWrapper = styled.div`
    padding-left: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    &.center {
        text-align: center;
        padding-right: 20px;
    }
`;

const StyledBidGrade = styled.div`
    flex-grow: 0;
    font-size: 14px;

    & .title {
        font-weight: 700;
        margin-bottom: 8px;
    }

    & .a-grade {
        color: ${(p) => p.theme.bidA};
    }

    & .b-grade {
        color: ${(p) => p.theme.bidB};
    }

    & .c-grade {
        color: ${(p) => p.theme.bidC};
    }

    & .vat {
        width: 100%;
        padding-bottom: 5px;
        border-bottom: 1px solid #d5d5d5;
    }

    & .with-vat {
        padding-top: 5px;
        font-size: 16px;
        font-weight: 500;
    }

    & .info {
        font-size: 12px;
        color: #8e8e8e;
        height: 26px;
    }
`;

const BidGrade = ({
    grade,
    quantity,
    showQuantity,
    amount,
    title,
    bidCompanyCountryId,
    info = " ",
}) => {
    const { t } = useTranslation("sidebar");
    const withVAT = (amount) => {
        const vatPercentage =
            Globals.userCountryId === bidCompanyCountryId
                ? Globals.VATPercentage
                : 1;
        return (vatPercentage * amount).toFixed(2);
    };
    const percString =
        Globals.userCountryId === bidCompanyCountryId
            ? Globals.VATPercString
            : "0";

    return (
        <StyledBidGrade>
            <div className="title">
                {title ? title : t(`label.bid.${grade}Grade`)}
                {showQuantity && ` / ${quantity.toFixed(2)} kg`}
            </div>
            <div className="info">{`${info}`}</div>
            <div className={`${grade}-grade`}>
                {(amount * quantity).toFixed(2)} EUR
            </div>
            <div className="vat">{`+${t(
                "filters:company.vat"
            )} (${percString}%)`}</div>
            <div className={`${grade}-grade with-vat`}>
                {withVAT(amount * quantity)} EUR
            </div>
        </StyledBidGrade>
    );
};

const BigHandlingCosts = ({
    price,
    administrativeCosts,
    transportationCosts,
    quantity,
    title,
    bidCompanyCountryId,
    isBuyer = false,
}) => {
    const { t } = useTranslation("sidebar");
    const handlingCosts = administrativeCosts + transportationCosts;
    const vatPercentage =
        Globals.userCountryId === bidCompanyCountryId
            ? Globals.VATPercentage
            : 1;
    const withVAT = (amount) => {
        return (vatPercentage * amount).toFixed(2);
    };
    const percString =
        Globals.userCountryId === bidCompanyCountryId
            ? Globals.VATPercString
            : "0";

    const totalCdeposit = (amount, quantity) => {
        return (quantity * vatPercentage + amount * vatPercentage).toFixed(2);
    };

    return (
        <>
            {isBuyer ? (
                <>
                    <SidebarField
                        style={{ marginTop: "20px" }}
                        valueVariant="bid-c"
                        label={t("label.transportationCosts")}
                        value={
                            transportationCosts > 0
                                ? `${transportationCosts.toFixed(2)} EUR`
                                : "N/A"
                        }
                    />
                    <SidebarField
                        valueVariant="bid-c"
                        label={t("label.administrativeCosts")}
                        value={
                            administrativeCosts > 0
                                ? `${administrativeCosts.toFixed(2)} EUR`
                                : "N/A"
                        }
                    />
                </>
            ) : (
                <SidebarField
                    style={{ marginTop: "20px" }}
                    valueVariant="bid-c"
                    label={title}
                    value={
                        handlingCosts > 0
                            ? `${handlingCosts.toFixed(2)} EUR`
                            : "N/A"
                    }
                />
            )}
            {handlingCosts > 0 && (
                <>
                    <SidebarField
                        valueVariant="vat"
                        label=""
                        value={`+${t("filters:company.vat")} (${percString}%)`}
                    />
                    <Separator />
                    <SidebarField
                        valueVariant="bid-c"
                        label={t("label.subtotal")}
                        value={`${withVAT(handlingCosts)} EUR`}
                    />
                </>
            )}
            <SidebarField
                wrapperVariant="big"
                valueVariant="bid-c"
                label={t("label.totalCdeposit")}
                value={`${totalCdeposit(handlingCosts, quantity * price)} EUR`}
            />
        </>
    );
};

export { BidGrade, BidGradeWrapper, BigHandlingCosts };
