import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import styled from "styled-components";
import DataCellImage from "../../components/data/DataCellImage";
import ListingPage from "../../components/data/ListingPage";
import NullableCell from "../../components/data/NullableCell";
import Anchor from "../../components/elements/form-elements/Link";
import Flex from "../../components/layout/flex/Flex";
import HamburgerMenu from "../../components/menu/HamburgerMenu";
import HamburgerMenuAction from "../../components/menu/HamburgerMenuAction";
import ModalDialog, { useModal } from "../../components/modal/ModalDialog";
import ModalWizard from "../../components/modal/ModalWizard";
import AuthorizeComponent from "../../core/AuthorizeComponent";
import Roles from "../../core/enums/Roles";
import equipmentListStateModule from "../../core/state/company/equipmentListStateModule";
import useBreadcrumbs from "../../core/state/navigation/useBreadcrumbs";
import useStateModule from "../../core/state/useStateModule";
import useImmerReducer from "../../hooks/_shared/useImmerReducer";
import usePageChange from "../../hooks/_shared/usePageChange";
import CompanyService from "../../services/Company/CompanyService";
import AttestationStatusCell from "./components/AttestationStatusCell";
import EquipmentDetailSideBar from "./components/EquipmentDetailSideBar";
import EquipmentWizard from "./components/wizard/EquipmentWizard";
import { Helmet } from "react-helmet";
import helpers from "../../core/helpers";
import ActionButton from "../../components/elements/buttons/ActionButton";
import UserService from "../../services/User/UserService";
import { eq } from "lodash";

const TagTitle = styled.div`
    width: 100%;
    font-size: 20px;
    font-weight: 700;
    margin-top: 10px;
`;

const Qr = styled.span`
    font-size: 10px;
`;

const KeyValue = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    & .value {
        color: ${(p) => p.theme.primaryColor};
    }
`;

const CylTypeCell = styled.div`
    width: 100%;
    color: ${(p) => p.theme.primaryColor};
    font-size: 14px;
    margin-bottom: 5px;
`;

const KeyValueCell = (key, value) => {
    return (
        <KeyValue>
            <span>{key}</span>
            <span className="value">{value}</span>
        </KeyValue>
    );
};

const pageReducer = (state, action) => {
    if (action.type === "SET_PAGING") {
        state.pageSize = action.payload.pageSize;
        state.page = action.payload.pageIndex + 1;
    } else {
        return state;
    }
};

const EquipmentList = () => {
    const { t } = useTranslation("screens");
    const { CompanyName } = UserService.getUserDetailsFromToken();
    const {
        equipment,
        isLoading,
        error,
        loadEquipment,
        loadEquipmentById,
        details,
        deleteEquipment,
    } = useStateModule(equipmentListStateModule);
    const [detailViewIsOpened, setDetailViewIsOpened] = useState(false);

    const [selectedEquipment, setSelectedEquipment] = useState(null);

    const createNewEquipment = async (ev) => {
        ev.preventDefault();
        const eqNum = equipment.data?.length ? equipment.data.length + 1 : 1;
        const preFill = `${CompanyName} ${t("screens:statistics.fGasAnalyzerTitle")} ${eqNum}`;
        ModalWizard.show(
            EquipmentWizard(t, null, false, preFill),
            {
                onSubmit: async (values) => {
                    const result = await CompanyService.createGasAnalyzerAsync(
                        ModalWizard.asFormData(values)
                    );
                    if (result.error) toast.error(t(result.error.message));
                    else {
                        loadEquipment(equipmentState);
                        toast.success(
                            t("actions:dialogs.createEquipment.toastNotification.success")
                        );
                        ModalWizard.hide();
                    }
                },
                onCancel: async () => {
                    ModalWizard.hidePrompt();
                },
            },
            { style: { minWidth: "760px" } }
        );
    };

    const editEquipment = async (equipmentId) => {
        const result = await CompanyService.getMyGasAnalyzerByIdAsync(equipmentId);
        if (result.error) toast.error(result.error.message);
        else {
            const initialData = result.data.data[0];
            delete initialData.companyId;
            delete initialData.createdDate;
            initialData.imageFile = initialData.imageUrl;
            delete initialData.imageUrl;

            ModalWizard.show(
                EquipmentWizard(t, initialData, true),
                {
                    onSubmit: async (values) => {
                        if (
                            typeof values.imageFile === "string" ||
                            values.imageFile instanceof String
                        ) {
                            values.imageFile = [];
                        } else if (Array.isArray(values.imageFile) && !values.imageFile.length) {
                            values.deleteImage = true;
                        }

                        const result = await CompanyService.updateGasAnalyzerAsync(
                            ModalWizard.asFormData(values)
                        );
                        if (result.error) toast.error(t(result.error.message));
                        else {
                            loadEquipment(equipmentState);
                            toast.success(t("actions:dialogs.editUnit.success"));
                            ModalWizard.hide();
                        }
                    },
                    onCancel: async () => {
                        ModalWizard.hidePrompt();
                    },
                },
                { style: { minWidth: "760px" } }
            );
        }
    };

    const columns = useMemo(
        () => [
            {
                Header: t("equipment.headers.name"),
                accessor: "name",
                Cell: ({ cell: { value } }) => {
                    return <CylTypeCell>{value}</CylTypeCell>;
                },
            },
            {
                Header: () => null,
                accessor: "imageUrl",
                Cell: ({ cell }) => {
                    if (!cell.value) {
                        cell.value = "/images/analyzer.svg";
                    }
                    return <DataCellImage image={cell.value} width="76px" />;
                },
            },
            {
                Header: t("equipment.headers.refrigerantTypeCode"),
                accessor: "macAddress",
                Cell: ({ cell: { row, value } }) => {
                    return KeyValueCell(t("forms:analyzer.wizard.macAddress"), value);
                },
            },
            {
                Header: t("equipment.headers.refrigerantTypeCode"),
                accessor: "tagId",
                Cell: ({ cell: { value } }) => {
                    const tagParts = value ? helpers.splitQRTag(value) : ["", ""];
                    return (
                        <>
                            <TagTitle>{tagParts[0]}</TagTitle>
                            <Qr>{tagParts[1]}</Qr>
                        </>
                    );
                },
            },
            {
                Header: "",
                accessor: "id",
                Cell: ({ cell: { value } }) => (
                    <HamburgerMenu>
                        <AuthorizeComponent
                            roles={[
                                Roles.InstallerCompanyAdmin,
                                Roles.InstallerCompanyUser,
                                Roles.InstallerCompanyTechnician,
                            ]}
                        >
                            <HamburgerMenuAction
                                text={t("actions:delete")}
                                onClick={() => {
                                    setSelectedEquipment(
                                        equipment.data.filter((e) => e.id === value)
                                    );
                                    toggleOpen(value);
                                }}
                            />
                        </AuthorizeComponent>
                        <HamburgerMenuAction
                            text={t("actions:edit")}
                            onClick={() => {
                                editEquipment(value);
                            }}
                        />
                    </HamburgerMenu>
                ),
            },
        ],
        // eslint-disable-next-line
        [t]
    );

    useEffect(() => {
        loadEquipment(equipmentState);
    }, [loadEquipment]);

    const onSelect = useCallback(
        (row) => {
            const selectRow = async (row) => {
                await loadEquipmentById(row.original.id);
                setDetailViewIsOpened(true);
            };
            selectRow(row);
        },
        [loadEquipmentById]
    );

    const closeSideBar = () => {
        setDetailViewIsOpened(false);
    };

    useBreadcrumbs([
        {
            text: t("navigation:company"),
        },
        {
            text: t("navigation:equipment"),
        },
        {
            text: t("navigation:gasAnalyzers"),
        },
    ]);

    const cardColumns = ["name", "imageUrl", "macAddress", "tagId", "id"];

    const [equipmentState, dispatch] = useImmerReducer(pageReducer, {
        page: 1,
        pageSize: 10,
        orderColumn: "",
        descending: true,
        filter: {},
    });

    const pageChange = usePageChange(dispatch, equipmentState);

    const { modalProps, toggleOpen } = useModal({
        title: t("actions:dialogs.deleteEquipment.title"),
        onConfirm: async (equipment) => {
            debugger;
            return;
            await deleteEquipment({ gasAnalyzerId: equipment }).then(() => {
                loadEquipment();
            });
            toggleOpen();
            toast.success(t("equipmentListing.toastNotification.success"));
        },
        onCancel: () => {
            toggleOpen();
        },
        confirmText: t("actions:dialogs.deleteEquipment.confirmText"),
        cancelFirst: true,
    });

    return (
        <>
            <Helmet>
                <title>{t("seo:equipmentList.title")}</title>
            </Helmet>
            <ListingPage
                filterTitle={t("equipment.title")}
                columns={columns}
                mode="cards"
                cardClasses="wide"
                tableState={{
                    data: equipment.data,
                    loading: isLoading,
                    error: error,
                }}
                actions={{
                    headerActions: () => {
                        return (
                            <AuthorizeComponent
                                roles={[
                                    Roles.InstallerCompanyAdmin,
                                    Roles.InstallerCompanyUser,
                                    Roles.InstallerCompanyTechnician,
                                ]}
                            >
                                <Flex.Container justifyContent="flex-end">
                                    <ActionButton
                                        style={{ margin: "30px 0 20px" }}
                                        className="primary"
                                        // icon="/images/actions/icon-add.svg"
                                        iconSize="12px"
                                        onClick={createNewEquipment}
                                        tooltip={t("forms:analyzer.addNewAnalyzer")}
                                    >
                                        {t("forms:analyzer.addAnalyzer")}
                                    </ActionButton>
                                </Flex.Container>
                            </AuthorizeComponent>
                        );
                    },
                }}
                paging={{
                    enablePaging: true,
                    pageCount: equipment.totalPages,
                    pageSize: equipment.pageSize,
                    onPageChange: pageChange,
                    currentPageIndex: equipment.currentPage ? equipment.currentPage - 1 : 0,
                }}
                onSelect={onSelect}
                closeSideBar={closeSideBar}
                detailView={<EquipmentDetailSideBar key={detailViewIsOpened} equipment={details} />}
                detailViewIsOpened={detailViewIsOpened}
                cardColumns={cardColumns}
            />
            <ModalDialog {...modalProps}>
                <p>{t("actions:dialogs.deleteEquipment.text")}</p>
                <p>{selectedEquipment && selectedEquipment[0].name}</p>
            </ModalDialog>
        </>
    );
};

export default EquipmentList;
