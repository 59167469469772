import React from "react";
import { useTranslation } from "react-i18next";
import Select from "../../../components/elements/form-elements/Select";
import { Label } from "../../../components/elements/form-elements";
import { ClearableInput } from "../../../components/elements/form-elements";
import Filters from "../../../components/filter/Filters";
import CylinderType from "../../../core/enums/CylinderType";
import useDebouncedCallback from "../../../hooks/_shared/useDebouncedCallback";

const CylinderFilter = ({ filterState, dispatch, actionTypes, gasTypes }) => {
    const { t } = useTranslation("filters");
    const [sizeToChanged] = useDebouncedCallback((text) => {
        text
            ? dispatch({
                  type: actionTypes.SET_CYLINDER_SIZE_TO,
                  payload: text,
              })
            : dispatch({
                  type: actionTypes.RESET_CYLINDER_SIZE_TO,
                  payload: text,
              });
    });

    const [sizeFromChanged] = useDebouncedCallback((text) => {
        text
            ? dispatch({
                  type: actionTypes.SET_CYLINDER_SIZE_FROM,
                  payload: text,
              })
            : dispatch({
                  type: actionTypes.RESET_CYLINDER_SIZE_FROM,
                  payload: text,
              });
    });
    return (
        <>
            <Filters.Wrapper className="filter-wrapper">
                <Filters.Item>
                    <Select
                        name="gastype"
                        key="gastype"
                        errors={[]}
                        touched={[]}
                        defaultItem={{
                            key: "all",
                            value: "",
                            label: t("gas.gasType"),
                        }}
                        defaultValue={filterState.refrigerantGasTypeCode}
                        options={
                            gasTypes
                                ? [
                                      {
                                          key: "empty",
                                          value: "empty",
                                          label: t("cylinders.empty"),
                                      },
                                      ...gasTypes.map((gas) => ({
                                          key: gas.code,
                                          value: gas.code,
                                          label: gas.code,
                                      })),
                                  ]
                                : []
                        }
                        handleChange={(evt) => {
                            dispatch({
                                type:
                                    evt.currentTarget.value === ""
                                        ? actionTypes.RESET_GAS_TYPE
                                        : actionTypes.SET_GAS_TYPE,
                                payload: evt.currentTarget.value,
                            });
                        }}
                        className="filters"
                        labelText={t("gas.gasType")}
                        labelClassName="sr-only"
                    />
                </Filters.Item>
                <Filters.Group>
                    <Filters.Item>
                        <Label htmlFor="sizeFrom" className="sr-only">
                            {t("cylinders.sizeFrom")}
                        </Label>

                        <ClearableInput
                            key="sizeFrom"
                            name="sizeFrom"
                            type="number"
                            defaultValue={
                                filterState.cylindersSizeFrom !== undefined
                                    ? filterState.cylindersSizeFrom
                                    : ""
                            }
                            onChange={(evt) => {
                                sizeFromChanged(evt.currentTarget.value);
                            }}
                            placeholder={t("cylinders.sizeFrom")}
                            title={t("cylinders.sizeFrom")}
                            className="filters left group"
                        />
                    </Filters.Item>

                    <Filters.Item>
                        <Label htmlFor="sizeTo" className="sr-only">
                            {t("cylinders.sizeTo")}
                        </Label>

                        <ClearableInput
                            key="sizeTo"
                            name="sizeTo"
                            type="number"
                            defaultValue={
                                filterState.cylindersSizeTo !== undefined
                                    ? filterState.cylindersSizeTo
                                    : ""
                            }
                            onChange={(evt) => {
                                sizeToChanged(evt.currentTarget.value);
                            }}
                            placeholder={t("cylinders.sizeTo")}
                            title={t("cylinders.sizeTo")}
                            className="filters right group"
                        />
                    </Filters.Item>
                </Filters.Group>
            </Filters.Wrapper>
        </>
    );
};

export default React.memo(CylinderFilter);
