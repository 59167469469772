import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ClearableInput from "../../../../components/elements/form-elements/ClearableInput";
import Label from "../../../../components/elements/form-elements/Label";
import Select from "../../../../components/elements/form-elements/Select";
import Filters from "../../../../components/filter/Filters";
import useDebouncedCallback from "../../../../hooks/_shared/useDebouncedCallback";

const Section = styled.div`
    display: flex;
`;

const ClientsFilter = ({
    filterState,
    dispatch,
    actionTypes,
    countryData,
    gasTypes,
    screen,
    actions,
}) => {
    const { t } = useTranslation("filters");

    const [nameChanged] = useDebouncedCallback((text) => {
        text
            ? dispatch({ type: actionTypes.SET_NAME_FILTER, payload: text.trim() })
            : dispatch({ type: actionTypes.RESET_NAME_FILTER });
    });

    return (
        <Filters.Wrapper className="full spread">
            <Section>
                {screen === "1" && (
                    <>
                        <Filters.Item>
                            <Label htmlFor="name" className="sr-only">
                                {t("clients.name")}
                            </Label>
                            <ClearableInput
                                key="name"
                                name="name"
                                type="text"
                                defaultValue={
                                    filterState.name !== undefined
                                        ? filterState.name
                                        : ""
                                }
                                onChange={(evt) => {
                                    nameChanged(evt.currentTarget.value);
                                }}
                                placeholder={t("clients.name")}
                                title={t("clients.name")}
                                className="filters"
                            />
                        </Filters.Item>
                    </>
                )}
                {screen === "3" && (
                    <>
                        <Filters.Item>
                            <Label htmlFor="unitName" className="sr-only">
                                {t("forms:unitName.text")}
                            </Label>
                            <ClearableInput
                                key="unitName"
                                name="unitName"
                                type="text"
                                defaultValue={
                                    filterState.unitName !== undefined
                                        ? filterState.unitName
                                        : ""
                                }
                                onChange={(evt) => {
                                    //quantityChanged(evt.currentTarget.value);
                                }}
                                placeholder={t("forms:unitName.text")}
                                title={t("forms:unitName.text")}
                                className="filters"
                            />
                        </Filters.Item>

                        <Filters.Item>
                            <Label htmlFor="model" className="sr-only">
                                {t("forms:model.text")}
                            </Label>
                            <ClearableInput
                                key="model"
                                name="model"
                                type="text"
                                defaultValue={
                                    filterState.vat !== undefined
                                        ? filterState.vat
                                        : ""
                                }
                                onChange={(evt) => {
                                    //quantityChanged(evt.currentTarget.value);
                                }}
                                placeholder={t("forms:model.text")}
                                title={t("forms:model.text")}
                                className="filters"
                            />
                        </Filters.Item>

                        <Filters.Item>
                            <Label htmlFor="serialNumber" className="sr-only">
                                {t("forms:serialNumber.text")}
                            </Label>
                            <ClearableInput
                                key="serialNumber"
                                name="serialNumber"
                                type="text"
                                defaultValue={
                                    filterState.vat !== undefined
                                        ? filterState.vat
                                        : ""
                                }
                                onChange={(evt) => {
                                    //quantityChanged(evt.currentTarget.value);
                                }}
                                placeholder={t("forms:serialNumber.text")}
                                title={t("forms:serialNumber.text")}
                                className="filters"
                            />
                        </Filters.Item>

                        <Filters.Item>
                            <Select
                                key="gasType"
                                name="gasType"
                                errors={[]}
                                touched={[]}
                                defaultItem={{
                                    key: "all",
                                    value: "",
                                    label: t("forms:gasType.text"),
                                }}
                                options={
                                    gasTypes
                                        ? gasTypes.map((gas) => ({
                                              key: gas.code,
                                              value: gas.code,
                                              label: gas.code,
                                          }))
                                        : []
                                }
                                defaultValue={
                                    filterState.gasType !== undefined
                                        ? filterState.gasType
                                        : ""
                                }
                                handleChange={(evt) => {
                                    // dispatch({
                                    //     type:
                                    //         evt.currentTarget.value === ""
                                    //             ? actionTypes.RESET_QUALITY_ASSURANCE
                                    //             : actionTypes.SET_QUALITY_ASSURANCE,
                                    //     payload: evt.currentTarget.value,
                                    // });
                                }}
                                className="filters"
                                labelText={t("forms:gasType.text")}
                                labelClassName="sr-only"
                            />
                        </Filters.Item>
                    </>
                )}
            </Section>
            <Section>
                {actions && actions}
            </Section>
        </Filters.Wrapper>
    );
};

export default ClientsFilter;
