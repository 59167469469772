const OfferStatus = {
    offered: 0,
    withdrawn: 1,
    waitingForOffererFunds: 2,
    offererEscrowedFunds: 3,
    buyerEscrowedFunds: 4,
    buyerClaimedBottles: 5,
    qualityTested: 6,
    disputed: 10,
    sold: 99,
    // statuses for disposal flow (some are reused)
    //     Offered = 0
    //     WaitingForOffererFunds = 2
    disposeAccepted: 20,
    disposed: 21,
};

export default OfferStatus;
