import React, { Fragment, useEffect, useMemo } from "react";
import {
    useExpanded,
    usePagination,
    useRowSelect,
    useSortBy,
    useTable,
} from "react-table";
import styled from "styled-components";
import { ReactComponent as SortIconSvg } from "../../assets/sort_triangle.svg";
import useListingPageContext from "../../core/state/useListingPage";
import Pagination from "../pagination/Pagination";
import IndeterminateCheckbox from "./IndeterminateCheckbox";
import {
    DataListingContainer,
    DataListingContent,
} from "./styles/StyledDataContainer";
import StyledTable from "./styles/StyledTable";
import { ReactComponent as NoDataSvg } from "../../assets/no_data.svg";
import { useTranslation } from "react-i18next";

const NoDataWrapper = styled.div`
    opacity: 0;
    width: 100%;
    height: calc(100% - 60px);
    position: absolute;
    top: 60px;
    left: 0;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #d5d5d5;
    transition: opacity 0s;

    &.show {
        opacity: 1;
        transition: opacity 1s;
    }
`;

const SvgNoData = styled(NoDataSvg)`
    width: 100%;
`;

const StyledSortIcon = styled(SortIconSvg)`
    width: 12px;
    height: 12px;
    margin: 0px 2px 0px -4px;

    & .triangle {
        fill: #d5d0d0;
    }

    &.asc .triangle {
        fill: ${(p) => p.theme.secondaryColor};
    }

    &.asc {
        transform: rotate(180deg);
    }

    &.desc .triangle {
        fill: ${(p) => p.theme.secondaryColor};
    }
`;

const DataTable = ({
    loading,
    pwa,
    columns,
    data,
    wrapperVariant = "",
    onSelect,
    getRowClassName,
    shouldShowSelection = () => true,
    shouldEnableSelectionForRow = () => true,
    enableSelection = false,
    enablePaging = false,
    showHeaders = true,
    renderRowSubComponent,
    showSelectAll = false,
    pageCount: controlledPageCount,
    onPageChange,
    onSortByChange,
    currentSortBy = [],
    enableSorting,
    pageSize,
    currentPageIndex,
    getRowId,
    numPageButtons,
}) => {
    const { state: listingPageState, actions: listingPageActions } =
        useListingPageContext();

    const initialTableState = useMemo(() => {
        return {
            pageIndex: currentPageIndex,
            pageSize: pageSize,
            pageCount: controlledPageCount,
            sortBy: currentSortBy,
            selectedRowIds: listingPageState
                ? listingPageState.selectedRows.map((row) => row.id)
                : [],
        };
    }, [currentPageIndex, pageSize, controlledPageCount]);
    const { t } = useTranslation();

    const dataMissing = data.length === 0 && !loading;
    const tableConfig = {
        columns,
        data,
        manualPagination: true,
        pageCount: controlledPageCount,
        initialState: initialTableState,
        disableMultiSort: true,
        manualSortBy: true,
        disableSortBy: !enableSorting,
        disableSortRemove: true,
        autoResetSelectedRows: false,
    };

    if (getRowId) {
        tableConfig.getRowId = getRowId;
    }
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        toggleAllRowsSelected,
        // Get the state from the instance
        state,
    } = useTable(
        tableConfig,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect,
        (hooks) => {
            enableSelection &&
                hooks.columns.push((columns) => [
                    // Let's make a column for selection
                    {
                        id: "selection",
                        // The header can use the table's getToggleAllRowsSelectedProps method
                        // to render a checkbox
                        Header: ({
                            getToggleAllRowsSelectedProps,
                            selectedFlatRows,
                            rows,
                        }) => {
                            const { actions: cellListingPageActions } =
                                useListingPageContext();
                            return showSelectAll ? (
                                <div>
                                    {rows.some(
                                        (row) =>
                                            shouldShowSelection &&
                                            shouldShowSelection(
                                                row,
                                                selectedFlatRows
                                            )
                                    ) ? (
                                        <IndeterminateCheckbox
                                            {...getToggleAllRowsSelectedProps(
                                                (props) => {
                                                    const onChange = (evt) => {
                                                        props.onChange(evt);
                                                        const checked =
                                                            evt.currentTarget
                                                                .checked;

                                                        setTimeout(() => {
                                                            checked
                                                                ? rows.map(
                                                                      (row) =>
                                                                          cellListingPageActions.addSelectedRow(
                                                                              row
                                                                          )
                                                                  )
                                                                : rows.map(
                                                                      (row) =>
                                                                          cellListingPageActions.removeSelectedRow(
                                                                              row.id
                                                                          )
                                                                  );
                                                        }, 1);
                                                    };
                                                    return {
                                                        ...props,
                                                        onChange,
                                                    };
                                                }
                                            )}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            ) : null;
                        },
                        // The cell can use the individual row's getToggleRowSelectedProps method
                        // to the render a checkbox
                        Cell: ({ row, selectedFlatRows }) => {
                            const {
                                state: cellListingPageState,
                                actions: cellListingPageActions,
                            } = useListingPageContext();

                            return shouldShowSelection &&
                                shouldShowSelection(row, selectedFlatRows) ? (
                                <div
                                    style={{
                                        position: "relative",
                                        width: "12px",
                                    }}
                                >
                                    <IndeterminateCheckbox
                                        inTable
                                        disabled={
                                            shouldEnableSelectionForRow
                                                ? !shouldEnableSelectionForRow(
                                                      row,
                                                      cellListingPageState.selectedRows
                                                  )
                                                : false
                                        }
                                        onClick={(evt) => evt.stopPropagation()}
                                        {...row.getToggleRowSelectedProps(
                                            (props) => {
                                                const disabled =
                                                    shouldEnableSelectionForRow
                                                        ? !shouldEnableSelectionForRow(
                                                              row,
                                                              cellListingPageState.selectedRows
                                                          )
                                                        : false;

                                                if (disabled && props.checked) {
                                                    props.checked = false;
                                                }

                                                const onChange = (evt) => {
                                                    props.onChange(evt);
                                                    const checked =
                                                        evt.currentTarget
                                                            .checked;

                                                    setTimeout(() => {
                                                        checked
                                                            ? cellListingPageActions.addSelectedRow(
                                                                  row
                                                              )
                                                            : cellListingPageActions.removeSelectedRow(
                                                                  row.id
                                                              );
                                                    }, 1);
                                                };
                                                return {
                                                    ...props,
                                                    onChange,
                                                };
                                            }
                                        )}
                                    />
                                </div>
                            ) : (
                                <></>
                            );
                        },
                        disableSortBy: true,
                    },
                    ...columns,
                ]);
        }
    );

    if (pageSize !== state.pageSize) setPageSize(pageSize);

    useEffect(() => {
        listingPageActions.setTableInstance &&
            listingPageActions.setTableInstance({
                toggleAllRowsSelected: toggleAllRowsSelected,
            });
    }, [toggleAllRowsSelected]);

    useEffect(() => {
        if (state.pageIndex !== currentPageIndex) {
            onPageChange &&
                onPageChange({
                    pageIndex: state.pageIndex,
                    pageSize: state.pageSize,
                });
        }
    }, [state.pageIndex]);

    useEffect(() => {
        if (state.pageIndex !== currentPageIndex) {
            gotoPage(currentPageIndex);
        }
    }, [currentPageIndex]);
    useEffect(() => {
        if (enableSorting && onSortByChange) onSortByChange(state.sortBy);
    }, [state.sortBy]);

    if (data) {
        return (
            <>
                <DataListingContainer
                    className={pwa ? "tight" : wrapperVariant}
                >
                    <DataListingContent
                        className={pwa && dataMissing === true ? "empty" : ""}
                    >
                        <NoDataWrapper
                            className={`${dataMissing === true ? "show" : ""}`}
                        >
                            <SvgNoData />
                            {t("table:headers.noItems")}
                        </NoDataWrapper>
                        <StyledTable {...getTableProps()}>
                            {showHeaders && (
                                <thead>
                                    {headerGroups.map((headerGroup) => (
                                        <tr
                                            {...headerGroup.getHeaderGroupProps()}
                                        >
                                            {headerGroup.headers.map(
                                                (column) => (
                                                    <th
                                                        {...column.getHeaderProps(
                                                            {
                                                                ...column.getSortByToggleProps(),
                                                                className:
                                                                    column.className,
                                                                rows: rows,
                                                            }
                                                        )}
                                                        key={column.Header}
                                                    >
                                                        <div className="header-content">
                                                            {enableSorting &&
                                                                column.Header &&
                                                                !column.disableSortBy && (
                                                                    <span
                                                                        style={
                                                                            !column.isSorted
                                                                                ? {
                                                                                      color: "#cecece",
                                                                                  }
                                                                                : {
                                                                                      color: "#2294d2",
                                                                                  }
                                                                        }
                                                                    >
                                                                        {column.isSorted ? (
                                                                            column.isSortedDesc ? (
                                                                                <StyledSortIcon className="asc" />
                                                                            ) : (
                                                                                <StyledSortIcon className="desc" />
                                                                            )
                                                                        ) : (
                                                                            <StyledSortIcon />
                                                                        )}
                                                                    </span>
                                                                )}

                                                            {column.Header &&
                                                                column.render(
                                                                    "Header"
                                                                )}
                                                        </div>
                                                    </th>
                                                )
                                            )}
                                        </tr>
                                    ))}
                                </thead>
                            )}
                            <tbody {...getTableBodyProps()}>
                                {page.map((row) => {
                                    prepareRow(row);
                                    return (
                                        <Fragment key={"fragment" + row.id}>
                                            <tr
                                                key={row.id}
                                                onClick={() => {
                                                    onSelect && onSelect(row);
                                                }}
                                                title=""
                                                {...row.getRowProps({
                                                    className: getRowClassName
                                                        ? getRowClassName(row)
                                                        : "",
                                                })}
                                                indeterminate="false"
                                                className={
                                                    row.isExpanded && "expanded"
                                                }
                                            >
                                                {row.cells.map((cell) => {
                                                    return (
                                                        <td
                                                            key={
                                                                cell.row.id +
                                                                "-" +
                                                                cell.column.id
                                                            }
                                                            {...cell.getCellProps(
                                                                {
                                                                    className:
                                                                        cell
                                                                            .column
                                                                            .className,
                                                                }
                                                            )}
                                                        >
                                                            {cell.render(
                                                                "Cell"
                                                            )}
                                                        </td>
                                                    );
                                                })}
                                            </tr>

                                            {row.isExpanded && (
                                                <tr key={"expanded-" + row.id}>
                                                    <td
                                                        colSpan={columns.length}
                                                        key={"td-" + row.id}
                                                    >
                                                        {renderRowSubComponent &&
                                                            renderRowSubComponent(
                                                                {
                                                                    row,
                                                                }
                                                            )}
                                                    </td>
                                                </tr>
                                            )}
                                        </Fragment>
                                    );
                                })}
                            </tbody>
                        </StyledTable>
                    </DataListingContent>
                    {enablePaging && (
                        <Pagination
                            gotoPage={gotoPage}
                            previousPage={previousPage}
                            nextPage={nextPage}
                            canPreviousPage={canPreviousPage}
                            canNextPage={canNextPage}
                            pageCount={controlledPageCount}
                            currentPageIndex={currentPageIndex}
                            numButtons={numPageButtons}
                        />
                    )}
                </DataListingContainer>
            </>
        );
    }
};

export default DataTable;
