import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { ReactComponent as FlagSvg } from "../../../../../assets/Flag.svg";

const Wrapper = styled.div`
    display: flex;
    align-items: center;
`;

const StyledFlag = styled(FlagSvg)`
    width: auto;
    height: 16px;
    color: #e94e4e;
    margin-left: 12px;
`;

const PartnerStatusCell = ({ cell }) => {
    const { t } = useTranslation("screens");
    const data = cell.row.original;
    return (
        <Wrapper>
            <span>{t(`common:partnerStatus.${data.partnerStatus}`)}</span>
            {data.requestedDaikinId && <StyledFlag title={t("companyListing.requestedId")} />}
        </Wrapper>
    );
};

export default PartnerStatusCell;
