import React from "react";
import styled from "styled-components";

const Heading1 = styled.h1`
    /* font-family: "Open Sans", sans-serif; */
    color: ${(props) => props.theme.primaryColor};
    position: relative;
    font-size: ${(props) => props.theme.h1FontSize};
    margin: 0 0 18px;
    font-weight: 500;

    &.center {
        text-align: center;
    }

    &.title {
        font-size: ${(props) => props.theme.h1TitleFontSize};
        margin: 0 0 25px;

        @media ${(p) => p.theme.device.mobile} {
            font-size: 2rem;
        }
    }

    &.small-title {
        font-size: ${(props) => props.theme.h1TitleFontSizeSmall};
    }

    &.bold {
        font-weight: 700;
    }
`;

const H1 = ({children, ...rest}) => {
    return <Heading1 {...rest}>{children}</Heading1>;
};

export default H1;
