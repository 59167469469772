import React, { useState, useRef } from "react";
import { InputSubmit, Form, FormField } from "../../../components/elements/form-elements";
import styled from "styled-components";
import Headings from "../../../components/elements/headings/Headings";

import Flex from "../../../components/layout/flex/Flex";
import { useFormik } from "formik";
import commonValidation from "../../../core/validations/common";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import InputElement from "../../../components/elements/form-elements/InputElement";
import EffectError from "../../../components/errors/EffectError";
import NavigationLink from "../../../components/elements/links/NavigationLink";
import Wrappers from "../../../components/wrappers/Wrappers";
import RouteCreator from "../../../core/RouteCreator";
import globals from "../../../globalVars";
import { toast } from "react-toastify";
import Button from "../../../components/elements/buttons/Button";

const QuestMark = styled.span`
    position: absolute;
    right: -30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    width: 20px;
    height: 20px;
    background-color: ${(p) => p.theme.secondaryColor};
    border-radius: 50%;
    color: white;
    cursor: help;
    bottom: 10px;
    &:hover span {
        display: block;
    }

    & span {
        display: none;
        position: absolute;
        right: 0px;
        top: 12px;
        background-color: #2294d2;
        border-radius: 8px;
        min-width: 150px;
        min-height: 48px;
        padding: 8px;
        font-size: 12px;
        box-shadow: -2px 9px 11px -16px rgba(0, 0, 0, 0.75);
        transform: translateY(-50%);
        z-index: 2;
        line-height: 16px;

        &.wide {
            min-width: 250px;
        }

        & ul {
            margin: 0;
            padding-inline-start: 15px;
        }
        & ul.dashed {
            list-style-type: none;
        }
        & ul.dashed > li {
            line-height: 16px;
            text-indent: -5px;
        }
        & ul.dashed > li:before {
            content: "-";
            text-indent: -5px;
        }
    }
`;

const TwoFAInfo = styled.div`
    font-size: 20px;
    font-weight: 500;
    color: #000;
    margin-bottom: 40px;

    & .mail {
        color: ${(p) => p.theme.primaryColor};
        margin: 15px 0px;
    }
`;

const VSpace = styled.div`
    width: 100%;
    height: ${(p) => p.size || 0}px;
`;

const Wrapper = styled.div`
    padding: 40px 0px;
    height: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    align-items: center;
`;

const Digit = styled.input`
    width: 46px;
    height: 46px;
    line-height: 46px;
    text-align: center;
    border: 1px solid #898989;
    font-size: 20px;
    font-weight: 700;

    -moz-appearance: textfield;

    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    & + & {
        margin-left: 12px;
    }
`;

const DigitsWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const TwoFaLabel = styled.div`
    margin: 0px 0px -25px;
    padding: 0;
    width: 100%;
    padding: 0px 25px;
    font-size: 14px;
    font-weight: 700;
`;

const LoginForm = ({
    onSubmit,
    disabled,
    errorText,
    twoFactor,
    verifyOnSubmit,
    username,
    logout,
    clearErrors,
    login,
    userPassword,
}) => {
    const { t } = useTranslation("common");
    const loginValidator = Yup.object({
        email: commonValidation.email(t).rule,
        password: commonValidation.passwordRequired(t).rule,
    });

    const twoFaDigits = useRef(["", "", "", "", "", ""]);

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: loginValidator,
        onSubmit: onSubmit,
    });

    const verifyFormik = useFormik({
        initialValues: {
            code: "",
        },
        onSubmit: verifyOnSubmit,
    });

    const handle2FaInput = (ev) => {
        if (ev.target.value.length > 1) ev.target.value = ev.target.value.slice(0, 1);
        const idx = Number(ev.target.dataset.idx);
        const val = ev.target.value;
        twoFaDigits.current[idx] = val;
        if (val !== "") {
            const nextEl = document.querySelector(`[data-idx="${idx + 1}"]`);
            if (nextEl) nextEl.focus();
        }
        let code = twoFaDigits.current.join("");
        verifyFormik.setFieldValue("code", code);
    };

    if (twoFactor.isRequired) {
        return (
            <>
                <Form className="login" onSubmit={verifyFormik.handleSubmit}>
                    <Wrapper>
                        <Headings.H2
                            className={`center bold ${globals.isMobile ? "small-title " : "title "}`}
                            style={{
                                marginBottom: globals.isMobile ? "20px" : "100px",
                            }}
                        >
                            {t("forms:twoFA.title")}
                        </Headings.H2>
                        <TwoFAInfo>
                            {t("forms:twoFA.mailSent")}
                            <div className="mail">{username}</div>
                            {t("forms:twoFA.notReceived")}
                        </TwoFAInfo>
                        <TwoFaLabel>{t("forms:twoFA.enterCodePlaceholder")}</TwoFaLabel>
                        <DigitsWrapper>
                            {twoFaDigits.current.map((d, idx) => (
                                <Digit
                                    kex={idx}
                                    data-idx={idx}
                                    type="number"
                                    min="0"
                                    max="9"
                                    onInput={(ev) => handle2FaInput(ev)}
                                />
                            ))}
                        </DigitsWrapper>
                        <VSpace size={20} />
                        <Flex.Container className="horizontal-center">
                            <InputSubmit
                                disabled={disabled}
                                type="submit"
                                value={t("screens:forgottenPassword.submit")}
                                variant="primary"
                            />
                        </Flex.Container>
                        <Wrappers.Wrapper
                            className="form-links-wrapper"
                            style={globals.isMobile ? { marginTop: "20px" } : {}}
                        >
                            <>
                                <NavigationLink
                                    to="#"
                                    className="forgotten-password"
                                    onClick={(ev) => {
                                        ev.preventDefault();
                                        ev.stopPropagation();
                                        login(username, userPassword);
                                        toast.success(`${t("forms:twoFA.mailSent")} ${username} `);
                                    }}
                                >
                                    {t("forms:twoFA.resend2FA")}
                                </NavigationLink>
                                <span style={{ margin: "0px 10px" }}>&#124;</span>
                            </>

                            <NavigationLink to="#" className="forgotten-password" onClick={() => logout()}>
                                {t("screens:forgottenPassword.backToLoginLink")}
                            </NavigationLink>
                        </Wrappers.Wrapper>
                        {errorText && <EffectError clearErrors={clearErrors} onlyNotification error={errorText} />}
                    </Wrapper>
                </Form>
            </>
        );
    }
    return (
        <Form className="login" onSubmit={formik.handleSubmit}>
            <Wrapper>
                <Headings.H2 className="center title bold" style={{ marginBottom: "60px" }}>
                    {t("screens:login.title")}
                </Headings.H2>
                <div
                    style={{
                        marginBottom: "60px",
                        fontSize: "14px",
                        textAlign: "center",
                    }}
                    dangerouslySetInnerHTML={{
                        __html: t("screens:login.signupDesc"),
                    }}
                />

                <div>
                    <FormField>
                        <InputElement
                            labelText={t("forms:email.text")}
                            name="email"
                            type="text"
                            placeholder={t("forms:email.placeholder")}
                            {...formik}
                        />
                    </FormField>
                    <FormField>
                        <InputElement
                            labelText={t("forms:password.text")}
                            name="password"
                            type="password"
                            {...formik}
                        />
                    </FormField>
                </div>
                <Flex.Container style={{ flexDirection: "column", position: "relative" }} className="horizontal-center">
                    <InputSubmit
                        disabled={disabled}
                        type="submit"
                        value={t("screens:login.title")}
                        style={{ marginTop: "20px", width: "80%" }}
                        variant="primary"
                    />
                    <Button
                        className="blue--border"
                        style={{ marginTop: "20px", width: "80%", height: "44px", position: "relative" }}
                        onClick={(ev) => {
                            ev.preventDefault();
                            window.location.href = "/api/Authentication/sso";
                        }}
                    >
                        {t("screens:login.cdc")}
                        <QuestMark>
                            ?<span>{t("screens:login.cdcHelp")}</span>
                        </QuestMark>
                    </Button>
                </Flex.Container>

                <Wrappers.Wrapper className="form-links-wrapper">
                    {!globals.isMobile && (
                        <>
                            <NavigationLink to={RouteCreator.user.register()} className="forgotten-password">
                                {t("screens:login.signUpLinkText")}
                            </NavigationLink>
                            <span style={{ margin: "0px 24px" }}>&#124;</span>
                        </>
                    )}
                    <NavigationLink to={RouteCreator.user.forgotPassword()} className="forgotten-password">
                        {t("screens:login.forgottenPasswordLinkText")}
                    </NavigationLink>
                </Wrappers.Wrapper>
                {errorText && <EffectError clearErrors={clearErrors} onlyNotification error={errorText} />}
            </Wrapper>
        </Form>
    );
};

export default LoginForm;
