import { config } from "../Config/config.prod";
import api, { apiResult } from "../getAxios";

const StockService = {
    getRefrigerantTypes: async () => {
        return (await api.get(config.apiUrl.getRefrigerantTypes)).data.data;
    },
    getRefrigerantTypesAsync: async () => {
        return apiResult(api.get(config.apiUrl.getRefrigerantTypes));
    },
    getStockItems: async (params) => {
        return (await api.post(config.apiUrl.getMyCompanyStock, params)).data;
    },
    getStockItem: async (id) => {
        return (await api.get("/Stock/" + id)).data.data[0];
    },
    getStockItemAsync: async (id) => {
        return apiResult(api.get("/Stock/" + id));
    },
    addToMyStock: async (values) => {
        return apiResult(api.post(config.apiUrl.addToMyStock, values));
    },
    analyzeImage: async (formData) => {
        return (await api.post(config.apiUrl.analyzeImage, formData)).data;
    },
    moveToCompanyStock: async (tagId) => {
        return (
            await api.post(config.apiUrl.updateLocationStatus, {
                tagId: tagId,
                locationStatus: 1,
            })
        ).data.data;
    },
    moveToCompanyStockAsync: async (tagId) => {
        return apiResult(
            api.post(config.apiUrl.updateLocationStatus, {
                tagId: tagId,
                locationStatus: 1,
            })
        );
    },
    moveToInstallerStock: async (tagId, userId) => {
        let res = (
            await api.post(config.apiUrl.updateLocationStatus, {
                tagId: tagId,
                locationStatus: 0,
                userId: userId,
            })
        ).data.data;
        return res;
    },
    moveToInstallerStockAsync: async (tagId, userId) => {
        let res = apiResult(
            api.post(config.apiUrl.updateLocationStatus, {
                tagId: tagId,
                locationStatus: 0,
                userId: userId,
            })
        );
        return res;
    },
    publishToMarketplace: async (skuIds, description = "", isCleaning=0) => {
        return apiResult(
            api.post(config.apiUrl.publishSkus, {
                skuIds: skuIds,
                marketplaceStatus: 1,
                description: description,
                isCleaning:isCleaning
            })
        );
    },
    unpublishFromMarketplace: async (params) => {
        return apiResult(
            api.post(config.apiUrl.unpublishFromMarketplace, params)
        );
    },
    sendToRecycling: async (tagId) => {
        return apiResult(api.post(config.apiUrl.sendToRecycling, { tagId }));
    },
    emptyCylinder: async (tagId) => {
        return apiResult(api.post(config.apiUrl.emptyCylinder, { tagId }));
    },
    collectEmptyCylinder: async (tagId) => {
        return apiResult(
            api.post(config.apiUrl.collectEmptyCylinder, { tagId })
        );
    },
    getCleaningListing: async ({ isInstaller, options = {} }) => {
        return apiResult(
            api.post(
                isInstaller
                    ? config.apiUrl.cleaningServiceListingInstaller
                    : config.apiUrl.cleaningServiceListing,
                options
            )
        );
    },
    getCleaningListingData: async ({ isInstaller, options = {} }) => {
        const res = await apiResult(
            api.post(
                isInstaller
                    ? config.apiUrl.cleaningServiceListingInstaller
                    : config.apiUrl.cleaningServiceListing,
                options
            )
        );
        return res.data;
    },
};

export default StockService;
