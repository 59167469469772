import React, { useReducer } from 'react';

export const ListingPageContext = React.createContext({});

export const listingActionTypes = {
    ADD_SELECTEDROW: "ADD_SELECTEDROW",
    REMOVE_SELECTEDROW: "REMOVE_SELECTEDROW",
    SET_TABLEINSTANCE: "SET_TABLEINSTANCE"
}
const ListingPageProvider = ({ children }) => {
    const [state, dispatch] = useReducer(
        (state, action) => {
            switch (action.type) {
                case listingActionTypes.ADD_SELECTEDROW:
                    if(!state.selectedRows.find(row => row.id === action.payload.id)) {
                        state.selectedRows.push(action.payload);
                    }
                    return Object.assign({}, state);
                case listingActionTypes.REMOVE_SELECTEDROW:
                    state.selectedRows = state.selectedRows.filter(row => row.id !== action.payload);
                    return Object.assign({}, state);
                case "RESET_STATE":
                    return {
                        selectedRows: [],
                        tableInstance: {}
                    };
                case listingActionTypes.SET_TABLEINSTANCE:
                    state.tableInstance = action.payload;
                    return Object.assign({}, state);
                default:
                    return state;
            }
        },
        {
            selectedRows: [],
            tableInstance: {}
        }
    );

    return <ListingPageContext.Provider value={{ state, dispatch }}>
        {children}
    </ListingPageContext.Provider>;
};

export default ListingPageProvider;
