import React, {  useState, useRef, memo } from "react";
import styled from "styled-components";
import {StyledFilter} from "./components/StyledSvg";
import { ReactComponent as ClearSvg } from "../../assets/pwa/clear-filters.svg";
import { ReactComponent as CloseSvg } from "../../assets/pwa/dark-close.svg";

const StyledClose = styled(CloseSvg)`
    width: 24px;
    height: 24px;

    & line,
    & path {
        stroke: #fff;
    }

    &.active line {
        stroke: #fff;
    }
`;

const StyledClear = styled(ClearSvg)`
    width: 24px;
    height: 24px;
    margin-right: 12px;
    & line,
    & path {
        stroke: #bc3535;
    }
`;


const MenuBack = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    z-index: 1;
    transform: translateY(100%);

    &.open {
        transform: translateY(0);
    }
`;

const Wrapper = styled.div`
    box-sizing: border-box;
    padding: 20px 10px;
    background: #ffffff;
    color: #000000;
    position: fixed;
    bottom: 110%;
    left: 5%;
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: space-between;
    transform: translateY(30px);
    transition: 0.5s ease all;
    z-index: 3;
    border-radius: 15px;
    box-shadow: 0 6px 40px rgba(0, 0, 0, 0.16);

    &.open {
        transform: translateY(calc(100% + 150px));
    }

    & .content {
        width: 100%;
        margin-bottom: 20px;
    }
`;

const ClearButton = styled.div`
    height: 24px;
    display: flex;
    align-items: center;
    color: #000;
    &.hasFilters {
        color: #bc3535;
    }
    & * {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
    }
`;

const Header = styled.div`
    margin: 0px 0px 40px 0px;
    padding: 0px 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-basis: 100%;
`;

export const Icon = styled.img`
    height: 12px;
    width: 12px;
`;

const CloseButton = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #000;
    display: flex;
    align-items: center;
    justify-content: center;

    &:active {
        background: #961c1c;
    }
`;

const ApplyButton = styled.div`
    width: 120px;
    height: 40px;
    border-radius: 40px;
    background: ${(p) => p.theme.primaryColor};
    color: #fff;
    position: absolute;
    z-index: 4;
    left: calc(50% - 60px);
    bottom: -20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 600;

    &:active {
        box-shadow: inset 0 0 10px #000000;
    }
`;

const FilterMenu = ({ control }) => {
    const [menuState, setMenuState] = useState({
        isOpen: false,
        active: 0,
        hasFilters: undefined,
        filterItems: undefined,
        applyFn: undefined,
        clearFn: undefined,
        text: undefined
    });
    const wrapRef = useRef();
    if (control) {
        control.state = menuState;
        control.setState = setMenuState;
    }

    const handleClick = (ev, apply) => {
        if (apply && menuState.applyFn) menuState.applyFn();
        setMenuState({ isOpen: false });
    };

    const handleClear = (ev) => {
        menuState.clearFn && menuState.clearFn();
        setMenuState({ isOpen: false });
    };

    return (
        <>
            <MenuBack className={menuState.isOpen ? "open" : ""} />
            <Wrapper className={menuState.isOpen ? "open" : ""} ref={wrapRef}>
                <Header>
                    {menuState.hasFilters ? (
                        <ClearButton className="hasFilters" onClick={(ev) => handleClear(ev)}>
                            <StyledClear />
                            <span>{menuState.text}</span>
                        </ClearButton>
                    ) : (
                        <ClearButton>
                            <StyledFilter className="menu" />
                            <span>{menuState.text}</span>
                        </ClearButton>
                    )}
                    <CloseButton onClick={(ev) => handleClick(ev)}>
                        <StyledClose />
                    </CloseButton>
                </Header>
                <div className="content">{menuState.filterItems}</div>
                <ApplyButton onClick={(ev) => handleClick(ev, true)}>
                    Apply Filters
                </ApplyButton>
            </Wrapper>
        </>
    );
};

export default memo(FilterMenu);
