import React from "react";
import styled from "styled-components";

const Heading3 = styled.h3`
    /* font-family: "Open Sans", sans-serif; */
    color: ${(props) => props.theme.primaryColor};

    &.notification {
        font-size: 2.25rem;
        margin: 0 0 12px;
    }
`;

const H3 = (props, ref) => {
    const { children, className, ...restProps } = props;
    return (
        <Heading3 ref={ref} className={className} {...restProps}>
            {children}
        </Heading3>
    );
};

export default React.forwardRef(H3);
