import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ClientListing from "./components/ClientListing";
import ClientLocationListing from "./components/ClientLocationListing";
import LocationUnitListing from "./components/LocationUnitListing";
import UnitDetails from "./components/UnitDetails";
import { LevelWrapper } from "./components/StyledElements";

const ScreeWrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    display: flex;
    flex-wrap: nowrap;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
`;

const UnitStock = () => {
    const [state, setState] = useState({
        level: 0,
        client: null,
        location: null,
        unit: null,
    });

    const changeLevel = () => {
        document.querySelector(`#lvl${state.level + 1}`).scrollIntoView({
            behavior: "smooth",
        });
    };

    useEffect(() => {
        if (state.level === 1) {
            setTimeout(() => {
                changeLevel();
            }, 200);
        } else changeLevel();
    }, [state.level]);

    return (
        <ScreeWrapper>
            <LevelWrapper id="lvl1">
                <ClientListing
                    onSelect={(client) => {
                        setState((prevState) => {
                            return {
                                ...prevState,
                                level: 1,
                                client: client,
                                location: null,
                            };
                        });
                    }}
                    onSearch={() => {
                        setState({ level: 0 });
                    }}
                />
            </LevelWrapper>
            <LevelWrapper id="lvl2">
                <ClientLocationListing
                    onSelect={(location) => {
                        setState((prevState) => ({
                            ...prevState,
                            level: 2,
                            location: location,
                        }));
                    }}
                    onGoBack={(ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        setState((prevState) => ({
                            ...prevState,
                            level: 0,
                        }));
                    }}
                    clientId={state.client ? state.client.clientId : undefined}
                />
            </LevelWrapper>
            <LevelWrapper id="lvl3">
                <LocationUnitListing
                    onSelect={(unit) => {
                        setState((prevState) => ({
                            ...prevState,
                            level: 3,
                            unit,
                        }));
                    }}
                    onGoBack={(ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        setState((prevState) => ({
                            ...prevState,
                            level: 1,
                        }));
                    }}
                    locationId={
                        state.location ? state.location.locationId : undefined
                    }
                />
            </LevelWrapper>
            <LevelWrapper id="lvl4">
                <UnitDetails
                    onSelect={(unit) => {
                        setState((prevState) => ({
                            ...prevState,
                            level: 3,
                            unit,
                        }));
                    }}
                    onGoBack={(ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        setState((prevState) => ({
                            ...prevState,
                            level: 2,
                        }));
                    }}
                    onGotoStart={() => {
                        setState((prevState) => ({
                            ...prevState,
                            level: 0,
                            location: null,
                            unit: null,
                            client: null,
                        }));
                    }}
                    unitId={state.unit ? state.unit.unitId : undefined}
                    location={state.location}
                />
            </LevelWrapper>
        </ScreeWrapper>
    );
};

export default UnitStock;
