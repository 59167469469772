import React, { useCallback, useEffect, useState } from "react";
import ModalDialog, {
    useModal,
} from "../../../../components/modal/ModalDialog";
import MarketplaceService from "../../../../services/BuyRefrigerant/MarketplaceService";
import { toast } from "react-toastify";
import MyOffersListing from "../MyOffersListing";
import { useTranslation } from "react-i18next";
import AccordionWrapper from "../../../../components/toggle-view/AccordionWrapper";
import SidebarField from "../../../../components/elements/sidebar/SidebarField";
import helpers from "../../../../core/helpers";
import { VSpace } from "../../../pwa/components/StyledElements";
import Button from "../../../../components/elements/buttons/Button";
import ModalWizard from "../../../../components/modal/ModalWizard";
import WizardPayForOffer from "./wizard/WizardPayForOffer";
import WizardPayForCleaning from "../../../Services/views/components/wizard/WIzardPayForCleaning";
import WizardRejectBid from "./wizard/WizardRejectBid";
import styled from "styled-components";
import {
    BidGradeWrapper,
    BidGrade,
    BigHandlingCosts,
} from "../../../../components/bids/BidGrade";
import useSharedState from "../../../../hooks/_shared/useSharedState";
import useAccordionGroup from "../../../../hooks/_shared/useAccordionGroup";

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
`;

const SingleBidDetailSideBar = ({
    bid,
    offer,
    closeSingleBidSideBar,
    closeOfferSideBar,
}) => {
    const { t, i18n } = useTranslation(["sidebar"]);
    const currentLanguage = i18n.language;
    const currency = "EUR";
    const [globalState] = useSharedState("global");
    const [openAccordion, setOpenAccordion] = useAccordionGroup();

    const acceptBid = useCallback(
        async (
            bidId,
            offeredPriceGradeC,
            offerInfo = "",
            bidCompanyCountryId
        ) => {
            ModalWizard.show(
                WizardPayForOffer(
                    t,
                    currentLanguage,
                    currency,
                    offeredPriceGradeC * offer.maximumQuantity,
                    offerInfo,
                    true,
                    "",
                    "",
                    "",
                    bidCompanyCountryId
                ),
                {
                    onSubmit: async (values) => {
                        const result = await MarketplaceService.acceptBid(
                            bidId,
                            values.paymentMethod
                        );
                        if (result && result.error) {
                            toast.error(t(result.error.message));
                        } else {
                            MyOffersListing.refreshNeeded = true;
                            toast.success(
                                t(
                                    "actions:dialogs.acceptBid.toastNotification.success"
                                )
                            );
                            closeOfferSideBar();
                            closeSingleBidSideBar();
                        }
                        ModalWizard.hide();
                    },
                    onCancel: async () => {
                        ModalWizard.hidePrompt();
                    },
                }
            );
        },
        [t, bid]
    );

    const acceptCleaningBid = useCallback(
        async (
            bidId,
            offeredPriceGradeA,
            offeredPriceGradeB,
            offerInfo = "",
            bidCompanyCountryId
        ) => {
            ModalWizard.show(
                WizardPayForCleaning(
                    t,
                    currentLanguage,
                    currency,
                    offeredPriceGradeA,
                    offeredPriceGradeB,
                    offerInfo,
                    true,
                    bidCompanyCountryId,
                    offer.maximumQuantity
                ),
                {
                    onSubmit: async (values) => {
                        const result = await MarketplaceService.acceptBid(
                            bidId,
                            values.paymentMethod,
                            values.serviceType === "A" ? 0 : 1
                        );
                        if (result && result.error) {
                            toast.error(t(result.error.message));
                        } else {
                            MyOffersListing.refreshNeeded = true;
                            toast.success(
                                t(
                                    "actions:dialogs.acceptBid.toastNotification.success"
                                )
                            );
                            closeOfferSideBar();
                            closeSingleBidSideBar();
                        }
                        ModalWizard.hide();
                    },
                    onCancel: async () => {
                        ModalWizard.hidePrompt();
                    },
                },
                { style: { minWidth: "720px", minHeight: "690px" } }
            );
        },
        [t, bid]
    );

    const rejectBid = useCallback(
        async (bid, offer) => {
            ModalWizard.show(WizardRejectBid(t, currentLanguage, bid, offer), {
                onSubmit: async (values) => {
                    const result = await MarketplaceService.rejectBid(
                        bid.bidId,
                        values.rejectionReason
                    );
                    if (result && result.error) {
                        toast.error(t(result.error.message));
                    } else {
                        MyOffersListing.refreshNeeded = true;
                        toast.success(
                            t(
                                "actions:dialogs.rejectBid.toastNotification.success"
                            )
                        );
                        closeOfferSideBar();
                        closeSingleBidSideBar();
                    }
                    ModalWizard.hide();
                },
                onCancel: async () => {
                    ModalWizard.hidePrompt();
                },
            });
        },
        [t, bid]
    );

    const gasGradeNames = [
        t("sidebar:label.bid.aGrade"),
        t("sidebar:label.bid.bGrade"),
        t("sidebar:label.bid.cGrade"),
    ];

    // Reject Bid action
    // const { modalProps: modalPropsDecline, toggleOpen: toggleOpenDecline } =
    //     useModal({
    //         title: t("actions:dialogs.rejectBid.title"),
    //         onConfirm: async (bidId) => {
    //             const result = await MarketplaceService.rejectBid(bidId);
    //             if (result.error) {
    //                 toast.error(result.error.message);
    //             } else {
    //                 MyOffersListing.refreshNeeded = true;
    //                 // if (offer.offerId) {
    //                 //     loadBids(offer.offerId);
    //                 // }
    //                 toast.success(
    //                     t("actions:dialogs.rejectBid.toastNotification.success")
    //                 );
    //             }
    //             toggleOpenDecline();
    //             closeSingleBidSideBar();
    //         },
    //         onCancel: () => {
    //             toggleOpenDecline();
    //         },
    //         confirmText: t("actions:dialogs.rejectBid.confirmText"),
    //     });

    const [bidStatus, setBidStatus] = useState(null);
    useEffect(() => {
        if (bid) {
            setBidStatus(bid.bidStatusName);
        }
    }, [bid]);

    return (
        <div id="SingleBidSidebar">
            <AccordionWrapper
                title={t("title.offer.details")}
                id="offer.details"
                isOpen={openAccordion}
                onToggle={setOpenAccordion}
            >
                {offer && (
                    <>
                        <SidebarField
                            label={t("label.type")}
                            value={offer.refrigerantGasTypeCode}
                        />
                        <SidebarField
                            label={t("label.numberOfCylinders")}
                            value={offer.numberOfCylinders}
                        />
                        <SidebarField
                            label={t("label.netWeight")}
                            value={`${offer.maximumQuantity.toFixed(2)} kg`}
                        />
                        <SidebarField
                            label={t("label.totalExtractedQuantity")}
                            value={offer.quantity.toFixed(2) + " kg"}
                        />
                        <SidebarField
                            label={t("label.extraction.qualityGrade")}
                            value={offer.pressumedGradeName}
                        />
                        <SidebarField
                            label={t("label.extraction.qualityEvaluation")}
                            value={
                                offer.hasAnalysis
                                    ? t("filters:analysis.measurement")
                                    : t("filters:analysis.selfAssessment")
                            }
                        />
                    </>
                )}
            </AccordionWrapper>

            <AccordionWrapper
                title={
                    offer.isCleaning
                        ? t("table:headers.serviceProvider")
                        : t("title.buyer")
                }
                id="serviceProvider"
                isOpen={openAccordion}
                onToggle={setOpenAccordion}
            >
                {bid && (
                    <>
                        <SidebarField
                            label={t("title.company")}
                            value={bid.bidCompanyName}
                        />
                        <SidebarField
                            label={t("label.companyVAT")}
                            value={bid.bidCompanyVAT}
                        />
                        <SidebarField
                            label={t("label.companyStreet")}
                            value={bid.bidCompanyAddress}
                        />
                        <SidebarField
                            label={t("label.companyCity")}
                            value={bid.bidCompanyCity}
                        />
                        <SidebarField
                            label={t("label.companyState")}
                            value={bid.bidCompanyRegion}
                        />
                        <SidebarField
                            label={t("label.companyZIP")}
                            value={bid.bidCompanyPostalCode}
                        />
                        <SidebarField
                            label={t("label.companyCountry")}
                            value={bid.bidCompanyCountry}
                        />
                        <SidebarField
                            label={t("label.companyContactPersonName")}
                            value={bid.bidCompanyContactPerson}
                        />
                        <SidebarField
                            label={t("label.companyEmail")}
                            value={bid.bidCompanyEmail}
                        />
                        <SidebarField
                            label={t("label.companyPhone")}
                            value={bid.bidCompanyPhone}
                        />
                    </>
                )}
            </AccordionWrapper>

            <AccordionWrapper
                title={t("title.bid.details")}
                id="bid.details"
                isOpen={openAccordion}
                onToggle={setOpenAccordion}
            >
                {offer && bid && (
                    <>
                        <SidebarField
                            label={t("label.bid.date")}
                            value={helpers.toLocalDateIgnoreTime(
                                bid.bidDateTime
                            )}
                        />
                        <VSpace size="10px" />
                        {offer.isCleaning ? (
                            <>
                                <BidGradeWrapper>
                                    <BidGrade
                                        title={t(
                                            "forms:bid.wizard.betterCleaning"
                                        )}
                                        grade="a"
                                        amount={bid.offeredPriceGradeA}
                                        quantity={offer.maximumQuantity}
                                        bidCompanyCountryId={
                                            bid.bidCompanyCountryId
                                        }
                                    />
                                    <BidGrade
                                        title={t(
                                            "forms:bid.wizard.standardCleaning"
                                        )}
                                        grade="b"
                                        amount={bid.offeredPriceGradeB}
                                        quantity={offer.maximumQuantity}
                                        bidCompanyCountryId={
                                            bid.bidCompanyCountryId
                                        }
                                    />
                                </BidGradeWrapper>
                                {bid && (
                                    <BigHandlingCosts
                                        price={bid.offeredPriceGradeC}
                                        quantity={offer.maximumQuantity}
                                        administrativeCosts={
                                            bid.administrationCosts
                                        }
                                        transportationCosts={
                                            bid.transportationCosts
                                        }
                                        bidCompanyCountryId={
                                            bid.bidCompanyCountryId
                                        }
                                        title={t("table:headers.handlingCosts")}
                                    />
                                )}
                            </>
                        ) : (
                            <>
                                <BidGradeWrapper>
                                    <BidGrade
                                        grade="a"
                                        amount={bid.offeredPriceGradeA}
                                        quantity={offer.maximumQuantity}
                                        bidCompanyCountryId={
                                            bid.bidCompanyCountryId
                                        }
                                    />
                                    <BidGrade
                                        grade="b"
                                        amount={bid.offeredPriceGradeB}
                                        quantity={offer.maximumQuantity}
                                        bidCompanyCountryId={
                                            bid.bidCompanyCountryId
                                        }
                                    />
                                    <BidGrade
                                        grade="c"
                                        amount={bid.offeredPriceGradeC}
                                        quantity={offer.maximumQuantity}
                                        bidCompanyCountryId={
                                            bid.bidCompanyCountryId
                                        }
                                    />
                                </BidGradeWrapper>
                                {bid && (
                                    <BigHandlingCosts
                                        price={bid.offeredPriceGradeC}
                                        quantity={offer.maximumQuantity}
                                        administrativeCosts={
                                            bid.administrationCosts
                                        }
                                        transportationCosts={
                                            bid.transportationCosts
                                        }
                                        bidCompanyCountryId={
                                            bid.bidCompanyCountryId
                                        }
                                        title={t("table:headers.handlingCosts")}
                                    />
                                )}
                            </>
                        )}
                    </>
                )}
            </AccordionWrapper>

            {bid && bid.bidStatus === 0 && (
                <ButtonWrapper>
                    <Button
                        disabled={
                            !globalState.hasValidCompanyCertificate |
                            !globalState.hasCertificateRights
                        }
                        className="primary--action sidebar"
                        onClick={() => {
                            var offerInfo = `${
                                offer.refrigerantGasTypeCode
                            } | ${gasGradeNames[offer.pressumedGradeValue]} | ${
                                offer.maximumQuantity
                            } kg`;

                            offer.isCleaning
                                ? acceptCleaningBid(
                                      bid.bidId,
                                      bid.offeredPriceGradeA,
                                      bid.offeredPriceGradeB,
                                      offerInfo,
                                      bid.bidCompanyCountryId
                                  )
                                : acceptBid(
                                      bid.bidId,
                                      bid.offeredPriceGradeC,
                                      offerInfo,
                                      bid.bidCompanyCountryId
                                  );
                        }}
                    >
                        {t("actions:bidActions.accept")}
                    </Button>

                    <Button
                        disabled={
                            !globalState.hasValidCompanyCertificate |
                            !globalState.hasCertificateRights
                        }
                        className="decline sidebar"
                        onClick={() => {
                            rejectBid(bid, offer);
                        }}
                    >
                        {t("actions:bidActions.reject")}
                    </Button>
                </ButtonWrapper>
            )}

            {bid && bid.bidStatus !== 0 && (
                <AccordionWrapper
                    title={t("title.bid.status")}
                    id="bid.status"
                    isOpen={openAccordion}
                    onToggle={setOpenAccordion}
                >
                    <SidebarField label={t("label.status")} value={bidStatus} />
                    {bid.rejectionReason && (
                        <SidebarField
                            label={t("actions:bidActions.reason")}
                            value={bid.rejectionReason}
                        />
                    )}
                </AccordionWrapper>
            )}

            {/* <ModalDialog {...modalPropsDecline}>
                <p>{t("actions:dialogs.rejectBid.text")}</p>
                <p className="list">
                    {bid &&
                        `• ${bid.offeredPriceGradeA.toFixed(
                            2
                        )} EUR/kg | ${bid.offeredPriceGradeB.toFixed(
                            2
                        )} EUR/kg | ${bid.offeredPriceGradeC.toFixed(
                            2
                        )} EUR/kg`}
                </p>
                <p className="bold">
                    {t("actions:dialogs.revokeBid.offeredItem")}
                </p>
                <p className="list">
                    {offer &&
                        `• ${
                            offer.refrigerantGasTypeCode
                        } | ${offer.quantity?.toFixed(2)} kg | ${
                            offer.pressumedGradeName
                        } | ${
                            offer.analysisDone === "False"
                                ? t("filters:analysis.selfAssessment")
                                : t("filters:analysis.measurement")
                        } `}
                </p>
            </ModalDialog> */}
        </div>
    );
};

export default SingleBidDetailSideBar;
