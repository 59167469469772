import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import ModalWizard from "../../../components/modal/ModalWizard";
import AddUnitWizard from "./components/AddUnitWizard";
import TagsService from "../../../services/Tags/TagsService";
import StockService from "../../../services/Stock/StockService";
import ClientsService from "../../../services/Clients/ClientsService";
import UserService from "../../../services/User/UserService";
import useDebouncedCallback from "../../../hooks/_shared/useDebouncedCallback";
import { toast } from "react-toastify";
import RouteCreator from "../../../core/RouteCreator";
import usePromises from "../../../hooks/_shared/usePromises";

const ScreeWrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    display: flex;
    flex-wrap: nowrap;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
`;

const AddUnit = () => {
    const { t } = useTranslation("screens");
    const history = useHistory();
    const { UserId } = UserService.getUserDetailsFromToken();

    const [queryClientLocations] = useDebouncedCallback(
        async (clientName, updatePropertyBag) => {
            let clientLocations = [];
            const clients = await ClientsService.getClients({
                filter: { name: clientName },
            });
            if (clients.data.length > 0) {
                for (var i = 0; i < clients.data.length; i++) {
                    const locations = await ClientsService.getClientsLocations({
                        filter: { clientId: clients.data[i].clientId },
                    });
                    if (locations.data.length > 0) {
                        for (var loc = 0; loc < locations.data.length; loc++) {
                            locations.data[loc].clientName =
                                clients.data[i].clientName;
                            locations.data[loc].clientId =
                                clients.data[i].clientId;
                        }
                        clientLocations = [
                            ...clientLocations,
                            ...locations.data,
                        ];
                    }
                }
                updatePropertyBag({ locations: clientLocations });
            } else {
                updatePropertyBag({ locations: [] });
            }
            return [];
        }
    );

    const queryUnusedTags = async (tag) => {
        if (tag.length > 2) {
            const tags = await TagsService.lookupUnassignedTags(tag, UserId);
            return tags.map((t) => t.id);
        }
        return [];
    };

    const wizardData = usePromises(
        [
            () => ClientsService.getUnitManufacturersAsync(),
            () => StockService.getRefrigerantTypesAsync(),
        ],
        (res) => ({
            manufacturers: res[0].data.data,
            refrigerants: res[1].data.data,
        })
    );

    useEffect(() => {
        ModalWizard.show(
            AddUnitWizard(t, queryUnusedTags, queryClientLocations, wizardData.manufacturers, wizardData.refrigerants),
            {
                onSubmit: async (values) => {
                    values.tagId = values.unusedTagId;
                    const result = await ClientsService.addUnit(
                        ModalWizard.asFormData()
                    );
                    if (result && result.error) ModalWizard.submitFailed(t(result.error.message));
                    else {
                        toast.success(t("actions:dialogs.createUnit.success"));
                        ModalWizard.hide();
                        history.push(RouteCreator.pwa.equipment())
                    }
                },
                onCancel: async () => {
                    ModalWizard.hidePrompt(()=>history.goBack());
                },
            },
            { pwa: true }
        );
    }, [wizardData]);

    return <ScreeWrapper></ScreeWrapper>;
};

export default AddUnit;
