const Color = require('color');

const $inputColor = "#ffffff";
const $primaryColor = "#48b05e";
const $secondaryColor = "#2294d2";
const $tertiaryColor = "#04A9F5";
const $textColor = "#000000";

/* plain colors */
const $white = "#FFF";
const $whiteSmoke = "#F8F8F8";
const $lightGray= "#EFEFEF";
const $silver = "#D5D5D5";
const $ivory = "#F5F5F5";
const $neutral = "#8E8E8E";
const $error = "#E94E4E";
const $attention = "#000000";
const $inputBorderColor = "#E2E2E2";
const $green = "#48b05e";
const $disabled = "#ccc";
const $active = "#1F91C1";
const $tooltip = "#454B60";

export const device = {
    mobile: "(max-width: 768px)",
    tablet: "(max-width: 991.98px)",
    desktop: "(min-width: 769px)"
};

export default {
    fontFamily: "Poppins, sans-serif",

    /* Colors*/
    primaryColor: $primaryColor,
    primaryActionColor: $tertiaryColor,
    secondaryColor: $secondaryColor,
    secondaryNegativeColor: $error,
    tertiaryColor: $tertiaryColor,
    tertiaryNegativeColor: $error,
    checkBoxDefaultBgColor: $inputColor,
    textColor: $textColor,
    textWhite: $white,
    textActive: $green,
    textPagination: "#4D4F5C",
    textDisabled: $disabled,
    menuItems: '#505050',
    error: $error,
    textUploader: '#8E8E8E',
    textNeutral: $neutral,
    bidA: '#2B736B',
    bidB: '#94C21C',
    bidC: '#F0C40D',

    /* Backgrounds */
    bgDisabled: $lightGray,
    bgIvory: $ivory,
    bgWhite: $white,
    bgActive: $green,
    bgInactive: $error,
    bgSideBar: $white,
    bgFileUploader: $tertiaryColor,
    bgHeader: $whiteSmoke,
    bgUserActionElem: "#F4F4F4",
    bgUserActionButton: $green,
    bgModuleCards: $lightGray,
    bgMenuActive: $green,
    bgMenuHover: $lightGray,
    bgTableActive: $lightGray,
    bgDataCard: "#F0F0F0",
    bgTabs: $lightGray,
    bgTabsActive: $green,
    bgPagination: "#EDEDED",
    bgCloseMenuButton: $whiteSmoke,
    bgCloseSlideButton: $primaryColor,
    bgStatusIconDefault: "#EDEDED",
    bgStatusIconFinished: $primaryColor,
    bgTooltip: $tooltip,

    /* Overlay Background */
    bgOverlay: $ivory,

    /* Colors for Inputs */
    inputBgColor: $inputColor,
    inputPlaceholder: "#898989",

    /* Colors for text*/
    primaryColorText: $white,
    secondaryColorText: $tertiaryColor,
    attentionColor: $attention,
    walletMain: $primaryColor,
    walletTransaction: "#E98C13",

    /* Colors for borders*/
    primaryColorBorder: $primaryColor,
    primaryActionColorBorder: $tertiaryColor,
    secondaryColorBorder: $secondaryColor,
    tertiaryColorBorder: $tertiaryColor,
    secondaryNegativeColorBorder: $error,
    primaryInputBorderColor: $inputBorderColor,
    tableInputBorderColor: $inputBorderColor,
    sidebarWrapperBorderColor: $silver,
    sidebarFieldBorderColor: $inputBorderColor,
    tableActiveBorder: $active,
    secondaryInputBorderColor: $secondaryColor,
    headerBorderColor: "#D8D8D8",
    tabsBorderColor: "#DCDCDC",
    borderColorDisabled: $lightGray,
    borderColorError: $error,
    borderModalDialogColor: "#ccc",
    borderSeparator: "#DDDDDD",

    /* Colors for checkboxes */
    checkBoxActiveBgColor: "#EFEFEF",

    /* Link colors */
    primaryLinkColor: $primaryColor,

    /* Colors on hover */
    primaryColorHover: '#41a198',
    secondaryColorHover: '#41a198',

    /* Buttons */
    variantCancelBg: $error,
    variantCancelBgHover: Color($error).lighten(0.5).string(),
    variantCancelColor: "#FFF",

    /* Border Radius */
    inputBorderRadius: "6px",
    buttonsBorderRadius: "27px",
    filtersBorderRadius: "23px",
    closeButtonBorderRadius: "4px",
    cardsBorderRadius: "12px",
    actionButtonBorderRadius: '16px',
    walletBorderRadius: '4px',

    /* Loader colors */

    /* Errors */
    errorColor: $error,
    errorTextColor: $white,

    /* Default width */
    widthButtonMin: "164px",
    widthActionButtonMin: "88px",
    leftMenuWidth: '280px',

    /* Default height */
    heightButtonBig: "44px",
    heightButtonSmall: "32px",
    heightInputBig: "46px",
    heightInputBigPx: 46,
    heightInputSmall: "32px",

    /* Default padding */
    paddingButton: "0 32px",
    paddingInlineButton: "0 24px",

    /* Shadows */
    buttonShadow: "4px 4px 8px 0 rgba(34, 34, 34, 0.29)",
    buttonShadowNegative: "4px 4px 8px 0 rgba(233, 78, 78, 0.29), -2px 4px 8px 0 rgba(233, 78, 78, 0.29)",
    modalShadow: "15px 15px 30px rgba(163, 163, 163, 0.16), -15px 15px 30px rgba(163, 163, 163, 0.16)",
    notifierShadow: "4px 4px 8px 0 rgba(34, 34, 34, 0.29)",
    closeMenuShadow: "0 3px 6px rgba(0, 0, 0, 0.16)",
    closeSideBarShadow: "-3px 0px 6px rgba(0, 0, 0, 0.16)",
    walletShadow: "4px 4px 8px rgba(34, 34, 34, 0.30)",

    /* Sizes */
    baseFontSize: '16px',
    h1FontSize: '1.125rem',
    h2FontSize: '1rem',
    h1TitleFontSize: '2.5rem',
    h1TitleFontSizeSmall: '1.5rem',
    h2TitleFontSize: '2.0rem',
    labelFontSize: '0.875rem',
    inputFontSize: '0.875rem',

    /* Breakpoints */
    smallDevices: '576px',
    mediumDevices: '768px',
    largeDevices: '992px',
    extraLargeDevices: '1200px',
    superExtraLargeDevices: '1400px',
    device,
    /* Heights */
    heightHeader: '60px'
}
