import React from "react";
import { useTranslation } from "react-i18next";
import { InputSubmit } from "../../../../components/elements/form-elements";
import InputElement from "../../../../components/elements/form-elements/InputElement";
import Flex from "../../../../components/layout/flex/Flex";
import Wrappers from "../../../../components/wrappers/Wrappers";

const AdministratorEditForm = ({ formik, isLoading, roles }) => {
    const { t } = useTranslation();

    return (
        <Wrappers.FormWrapper variant="nomargin">
            <Flex.Container>
                <Flex.Column style={{ width: "520px" }}>
                    <Flex.Container flexWrap="wrap">
                        <InputElement
                            labelText={t("forms:firstName.text")}
                            name="firstName"
                            id="firstName"
                            type="text"
                            wrapperVariant="flex-half"
                            {...formik}
                        />
                        <InputElement
                            labelText={t("forms:lastName.text")}
                            name="lastName"
                            id="lastName"
                            type="text"
                            wrapperVariant="flex-half"
                            {...formik}
                        />
                        <InputElement
                            labelText={t("forms:userAccount.text")}
                            name="email"
                            id="email"
                            type="text"
                            wrapperVariant="flex-half"
                            {...formik}
                        />
                        <InputElement
                            labelText={t("forms:phoneNumber.text")}
                            name="phoneNumber"
                            id="phoneNumber"
                            type="text"
                            wrapperVariant="flex-half"
                            {...formik}
                        />
                    </Flex.Container>
                </Flex.Column>
            </Flex.Container>
            <Wrappers.Wrapper className="buttons-wrapper">
                <Flex.Row>
                    <InputSubmit
                        disabled={isLoading}
                        variant="link"
                        value={t("screens:employeeEdit.button-edit_text")}
                    />
                </Flex.Row>
            </Wrappers.Wrapper>
        </Wrappers.FormWrapper>
    );
};
export default AdministratorEditForm;
