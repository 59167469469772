import styled from 'styled-components';

export const DataCard = styled.div`
    box-sizing: border-box;
    box-shadow: 8px 8px 12px -6px rgba(0, 0, 0, 0.08);
    padding: 32px 12px 16px;
    margin: 0 30px 30px 0;
    text-align: center;
    background: ${(props) => props.theme.bgDataCard};
    border: 2px solid ${(props) => props.theme.bgDataCard};
    border-radius: ${(props) => props.theme.cardsBorderRadius};
    position: relative;
    transition: all 0.2s ease-in;
    width: 240px;
    height: 250px;
    flex-basis: 240px;

    &.wide {
        min-width: 260px;
        width: 260px;
    }

    &:hover {
        border-color: ${(props) => props.theme.secondaryColor};
    }
`;

export default DataCard;
