import StockService from "../../../services/Stock/StockService";

const gasTypesStatePlugin = {
    types: ["GASTYPES_LOADING", "GASTYPES_SUCCESS", "GASTYPES_ERROR"],
    initialState: {
        gasTypesData: {
            gasTypes: [],
            isLoading: false,
            error: null
        }
    },
    createReducer: types => {
        return (state, action) => {
            switch (action.type) {
                case types.GASTYPES_LOADING:
                    state.gasTypesData.gasTypes = [];
                    state.gasTypesData.isLoading = true;
                    state.gasTypesData.error = null;
                    break;
                case types.GASTYPES_ERROR:
                    state.gasTypesData.gasTypes = [];
                    state.gasTypesData.isLoading = false;
                    state.gasTypesData.error = action.payload;
                    break;
                case types.GASTYPES_SUCCESS:
                    state.gasTypesData.gasTypes = action.payload;
                    state.gasTypesData.isLoading = false;
                    state.gasTypesData.error = null;
                    break;
                default:
                    return state;
            }
        };
    },
    creators: (types) => ({
        gasTypesLoading: () => ({ type: types.GASTYPES_LOADING }),
        gasTypesError: err => ({ type: types.GASTYPES_ERROR, payload: err }),
        gasTypesSuccess: res => ({
            type: types.GASTYPES_SUCCESS,
            payload: res
        })
    }),
    actions: (creators)=> ({
        loadGasTypes: () => {
            return async dispatch => {
                dispatch(creators.gasTypesLoading());
                try {
                    const response = await StockService.getRefrigerantTypes();
                    dispatch(creators.gasTypesSuccess(response));
                } catch (e) {
                    dispatch(creators.gasTypesError(e));
                }
            }
        }
    })
};

export default gasTypesStatePlugin;
