import createStateModule from "../createStateModule";

const leftNavigationStateModule = {
    stateNamespace: "leftNavigation",
    types: [
        "SET_LEFTMENU",
        "SET_ACTIVE_PATH",
        "SET_IS_VISIBLE"
    ],
    initialState: {
        menuItems: [],
        activePath: null,
        isVisible: true
    },
    createReducer: types => {
        return (state, action) => {
            switch (action.type) {
                case types.SET_LEFTMENU:
                    state.menuItems = action.payload;
                    break;
                case types.SET_ACTIVE_PATH:
                    state.activePath = action.payload;
                    break;
                case types.SET_IS_VISIBLE:
                    state.isVisible = action.payload;
                    break;
                default:
                    return state;
            }
        }
    },
    creators: types => ({
        setLeftMenu: (menuItems) => ({type: types.SET_LEFTMENU, payload: menuItems}),
        setActivePath: (path) => ({type: types.SET_ACTIVE_PATH, payload: path}),
        setIsVisible: (isVisible) => ({type: types.SET_IS_VISIBLE, payload: isVisible})
    })
};

export default createStateModule(leftNavigationStateModule);
