import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import useFileDrop from "./hooks/useFileDrop";
import Input from "./Input";

const GalleryInput = styled(Input)`
    background: #000;
`;

const GalleryInputWrapper = styled.div`
    position: relative;
    width: 100%;
    min-height: 44px;
    border: 2px dashed #e2e2e2;
    font-size: 16px;
    border-radius: 18px;

    &.dragging {
        border: 2px dashed #675b34;
        cursor: pointer;
    }

    &:hover .placeholder {
        text-decoration: underline;
    }

    & > .content {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-content: center;
        align-items: center;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        padding: 10px;
        color: ${(p) => p.theme.primaryColor};
    }

    & > .content .title {
        width: 100%;
        font-size: 20px;
        color: #000;
        font-weight: 700;
        line-height: 20px;
        padding-bottom: 4px;
    }

    & input {
        opacity: 0;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 99;
        height: 100%;
        margin: 0;
        padding: 0;
        display: block;
        cursor: pointer;
        width: 100%;
    }

    & input.hidden {
        display: none;
    }
`;

const StyledThumbWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: ${(p) => (p.autoHeight ? "0" : "20px")};
    justify-content: space-evenly;
    max-height: 250px;
    overflow-y: auto;
    @media ${(props) => props.theme.device.mobile} {
        height: ${(p) => (p.autoHeight ? "auto" : "125px")};
        max-height: 125px;
    }
    &::-webkit-scrollbar {
        width: 10px;
        background-color: #fff;
    }

    &::-webkit-scrollbar-track {
        border: 1px solid #e2e2e2;
        background-color: #fff;
        box-shadow: none;
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${(p) => p.theme.primaryColor};
        outline: none;
    }
`;

const StyledThumb = styled.div`
    position: relative;
    background: url(${(props) => props.img}) no-repeat center center;
    background-size: cover;
    width: 30%;
    margin: 10px 0px;
    height: 100px;
    border: 1px solid #e2e2e2;
    border-radius: 4px;
    transition: all 0.3s ease-in-out;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */

    &.file {
        background-size: contain;
    }

    &.remove div {
        display: flex;
    }

    & img {
        width: 26%;
        height: auto;
    }

    & span {
        display: none;
    }
    @media ${(props) => props.theme.device.desktop} {
        &:hover {
            /* box-shadow: 0 0 14px -5px rgba(8, 8, 8, 0.5); */
            cursor: pointer;
            border-color: ${(p) => p.theme.primaryColor};

            div {
                display: flex;
            }

            img {
                opacity: 0.4;
            }
        }

        &.remove:hover img {
            opacity: 1;
            width: 20%;
        }

        &.remove:hover span {
            display: block;
        }
    }
`;

export const StyledThumbAction = styled.div`
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    color: #000;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Thumbnail = ({ file, onChange }) => {
    const [state, setState] = useState({
        thumb: undefined,
        file: null,
        remove: false,
    });

    const { t } = useTranslation();

    useEffect(() => {
        if (file instanceof File) {
            let reader = new FileReader();
            reader.onloadend = () => {
                setState({ thumb: reader.result, file });
            };
            reader.readAsDataURL(file);
        } else {
            setState({ thumb: file.imageThumbnailUrl, file });
        }
    }, [file]);

    useEffect(() => {
        onChange && onChange(state.file, !state.remove);
    }, [state.remove]);

    const handleCLick = (ev) => {
        setState((prev) => ({ ...prev, remove: !prev.remove }));
    };

    const isEditMode = state.file && state.file.imageUrl ? true : false;

    const isImage = isEditMode
        ? true
        : state.file
        ? state.file.type.indexOf("image") > -1
        : false;

    if (!state.thumb) return null;
    return (
        <StyledThumb
            img={isImage ? state.thumb : "/images/shared/file@2x.png"}
            className={[state.remove ? "remove" : "", isImage ? "" : "file"]}
            onClick={handleCLick}
        >
            <StyledThumbAction>
                <img src="/images/actions/trash.svg" alt="delete" />
                <span>{t("forms:wizard.undo")}</span>
            </StyledThumbAction>
        </StyledThumb>
    );
};

const GalleryUploader = (props) => {
    const [images, setImages] = useState(
        props.value && props.value instanceof Array ? props.value : []
    );
    const inputRef = useRef();

    const onChange = (e) => {
        if (e && e.currentTarget.files.length > 0) {
            const newFiles = Array.from(e.target.files).filter(
                (file) => !images.some((img) => img.name === file.name)
            );
            if (props.single) {
                props.setFieldValue(props.name, [...newFiles]);
                setImages((prev) => [...newFiles]);
            } else {
                props.setFieldValue(props.name, [
                    ...props.values[props.name],
                    ...newFiles,
                ]);
                setImages((prev) => [...prev, ...newFiles]);
            }
        }
    };

    const { isDragging, getUploaderProps } = useFileDrop((files) => {
        inputRef.current.files = files;

        let event = document.createEvent("UIEvents");
        event.initUIEvent("change", true, true);

        inputRef.current.dispatchEvent(event);
    });

    const handleThumbChange = (file, enabled) => {
        if (file === null) return;
        if (!enabled) {
            const filtered = props.values[props.name].filter((f) => f !== file);
            props.setFieldValue(props.name, filtered);
        } else {
            if (!props.values[props.name].some((f) => f === file)) {
                props.setFieldValue(props.name, [
                    ...props.values[props.name],
                    file,
                ]);
            }
        }
    };

    const thumbs = () =>
        images.map((file, i) => (
            <Thumbnail key={i} file={file} onChange={handleThumbChange} />
        ));

    return (
        <>
            <GalleryInputWrapper
                {...getUploaderProps()}
                className={[props.className, isDragging && "dragging"]}
            >
                <div className="content">
                    {props.title && <div className="title">{props.title}</div>}
                    {props.placeholder && (
                        <div className="placeholder">{props.placeholder}</div>
                    )}
                </div>

                <input
                    ref={inputRef}
                    type="file"
                    onChange={onChange}
                    multiple={props.single ? false : true}
                    accept={props.accept || ".png, .jpg, .jpeg"}
                    className="hidden"
                />
                <GalleryInput
                    {...props}
                    accept={props.accept || ".png, .jpg, .jpeg"}
                    onClick={() => inputRef.current.click()}
                />
            </GalleryInputWrapper>
            <StyledThumbWrapper autoHeight={props.autoHeight}>
                {thumbs()}
            </StyledThumbWrapper>
        </>
    );
};

export default GalleryUploader;
