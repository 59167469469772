import React from "react";
import styled from "styled-components";
import ClearableLookup  from "../../components/elements/form-elements/ClearableLookup";

const SearchContainer = styled.div`
    font-size: 20px;
    font-weight: bold;
    padding: 24px;
    width:100%;
    cursor: pointer;
    height: 80px;
`;


const CompanyLookup = ({ title, searchFn, companySelect }) => {
    return (
  
        
            <SearchContainer>
                    <ClearableLookup
                        id="companyId"
                        name="companyId"
                        searchFn={searchFn}
                        title={title}
                        itemSelected={(sel) => {
                            setTimeout(() => {
                                companySelect(sel);

                            }, 250);
                        }}

                    />
                </SearchContainer>  
     
    )
};

export default CompanyLookup;
