import { useEffect, useMemo } from "react";
import bindActions from "./bindActions";
import { useGlobalStore } from "./GlobalStore";

const useStateModule = (partialStateObject) => {
    const { state, dispatch } = useGlobalStore();
    const partialState = useMemo(() => state[partialStateObject.stateNamespace], [state, partialStateObject]);
    const boundActions = useMemo(() => {
        return bindActions({...partialStateObject.creators, ...partialStateObject.actions}, dispatch);
    }, [partialStateObject, dispatch]);

    useEffect(() => {
        return () => {
            if(boundActions.resetState) {
                boundActions.resetState();
            }
        }
    }, [boundActions]);

    return {...partialState, ...boundActions};
}


export default useStateModule;
