import React from "react";
import styled from 'styled-components';
import {useTranslation} from "react-i18next";
import RadioList from "../../../../../components/elements/form-elements/RadioList";

const Wrapper = styled.div`
    width: 100%;
    margin: -10px 2% 0 2%;
    padding: 0 0 30px;
`;

const Title = styled.p`
    color: ${props => props.theme.primaryColor};
    font-size: 1rem !important;
    margin: 0 0 20px !important;
`;

const Subtitle = styled.p`
    color: #000;
    font-size: 0.875rem !important;
    margin: 0 0 5px !important;
    font-weight: bold;
`;


const CollectableCompaniesList = (props) => {
    const {t} = useTranslation();
    const { companies, handleCompanySelect } = props;

    if (!companies) {
        return null;
    }

    const selectableCompanies = companies.length > 0 ?
        companies.map(c => {
        return {
            text: c.companyDetails.companyName,
            value: c.companyDetails.id
        }
    }) : [];

    // MOCK COMPANIES FOR TESTING SCROLLABLE CONTENT
    let mockCompanies = [];
    for (let i = 0; i < 10; i++) {
        mockCompanies.push(companies);
    }
    mockCompanies = mockCompanies.flat();

    return (
        <Wrapper>
            <Title>{t('forms:stock.wizard.collectCylinder.company')}</Title>
            <Subtitle>{t('forms:stock.wizard.collectCylinder.tableCaption')}</Subtitle>
            <RadioList name="company"
                       items={selectableCompanies}
                       style={{maxHeight: "304px"}}
                       onSelect={handleCompanySelect}/>
        </Wrapper>
    )
}

export default CollectableCompaniesList;
