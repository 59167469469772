import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ReactComponent as BackSvg } from "../../../../assets/goback.svg";
import InputElement from "../../form-elements/InputElement";
import { Checkbox } from "../../form-elements";
import Button from "../../buttons/Button";
import TagsService from "../../../../services/Tags/TagsService";

const StyledBg = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: none;
    align-items: center;
    justify-content: center;
    z-index: 999;
    &.open {
        display: flex;
    }
`;

const StyledForm = styled.div`
    background-color: #fff;
    width: 70%;
    max-width: 968px;
    height: auto;
    /* border: 1px solid #707070; */
    box-shadow: 10px 8px 36px -18px rgba(0, 0, 0, 0.48);
    padding: 42px 48px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
`;

export const StyledBackIcon = styled(BackSvg)`
    width: 18px;
`;

const BackLink = styled.div`
    color: #2294d2;
    display: flex;
    gap: 16px;
    margin-bottom: 48px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
`;

const InfoBlock = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
`;

const Title = styled.div`
    color: #2c2f3c;
    font-size: 36px;
    font-weight: 600;
`;

const SpocBlock = styled.div`
    display: flex;
    gap: 32px;
    align-items: center;
`;

const SpocImg = styled.img`
    width: 60px;
    height: 60px;
    border-radius: 9999px;
`;

const SpocIni = styled.div`
    width: 60px;
    height: 60px;
    border-radius: 9999px;
    background-color: #48b05e;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: #fff;
`;

const SpocInfo = styled.div`
    display: flex;
    flex-direction: column;
    color: #000;
    & .name {
        font-size: 14px;
        font-weight: 600;
    }
    & .position {
        font-size: 12px;
    }

    &.email {
        font-size: 10px;
    }
`;

const Sep = styled.div`
    width: 100%;
    height: 1px;
    background-color: #dddddd;
    margin-top: 48px;
    margin-bottom: 60px;
`;

const RequestSupportForm = React.forwardRef(({ closeCb }, ref) => {
    const [spocData, setSpocData] = useState({});
    const [urgent, setUrgent] = useState(false);
    const { t } = useTranslation("actions");
    const inputRef = useRef();

    useEffect(() => {
        const getSpocData = async () => {
            const res = await TagsService.getSpocDetailsAsync();
            setSpocData(res.data.data[0]);
        };
        getSpocData();
    }, []);

    const finish = async (sendData) => {
        let status = 0;
        const submitData = {
            messageText: inputRef.current.value,
            isUrgent: urgent,
        };
        if (sendData) {
            const res = await TagsService.addSupportMessageAsync(submitData);
            status = res.status;
        }
        setUrgent(false);
        inputRef.current.value = "";
        if (closeCb) closeCb(status, `${spocData.firstName} ${spocData.lastName}`);
    };

    let spocInitials = "";
    if (spocData.firstName?.length && spocData.lastName?.length)
        spocInitials = (spocData.firstName[0] + spocData.lastName[0]).toUpperCase();

    return (
        <StyledBg ref={ref}>
            <StyledForm>
                <BackLink onClick={() => finish(false)}>
                    <StyledBackIcon />
                    {t("backToR")}
                </BackLink>
                <InfoBlock>
                    <Title>{t("helpSupport")}</Title>
                    <SpocBlock>
                        {spocData.profilePictureUrl ? (
                            <SpocImg src={spocData.profilePictureUrl} />
                        ) : (
                            <SpocIni>{spocInitials}</SpocIni>
                        )}
                        <SpocInfo>
                            <div className="name">{`${spocData.firstName} ${spocData.lastName}`}</div>
                            <div className="position">{spocData.position}</div>
                            <div className="email">{spocData.email}</div>
                        </SpocInfo>
                    </SpocBlock>
                </InfoBlock>
                <InputElement
                    ref={inputRef}
                    labelText={t("describeRequest")}
                    id="issueDescription"
                    autoComplete="new-description"
                    name="issueDescription"
                    type="textarea"
                    col={5}
                    wrapperVariant="fill"
                    maxLength="999"
                />
                <div style={{ height: "32px" }} />
                <Checkbox
                    secondary
                    labelText={t("urgentRequest")}
                    id="isUrgent"
                    name="isUrgent"
                    checked={urgent}
                    onChange={(e) => {
                        setUrgent(e.target.checked);
                    }}
                />
                <Sep />
                <Button onClick={() => finish(true)} className="primary centered">
                    {t("dialogs.submitUbo.confirmText")}
                </Button>
            </StyledForm>
        </StyledBg>
    );
});

export default RequestSupportForm;
