import React from 'react';
import styled from 'styled-components';
import {useTranslation} from "react-i18next";

const PresumedGradeLabel = ({gradeA, gradeB, gradeC, ...rest}) => {
    const {t} = useTranslation('common');
    if (gradeC) {
        return <div {...rest}>{t('grade')}: C</div>;
    } else if (gradeB) {
        return <div {...rest}>{t('grade')}: B</div>;
    } else {
        return <div {...rest}>{t('grade')}: A</div>;
    }
};

export default styled(PresumedGradeLabel)`
    margin-bottom: 20px;
    color: ${p => p.theme.primaryColor};
    font-weight: bold;
`;
