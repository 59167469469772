
import CompanyService from "../../../services/Company/CompanyService";
import createListStateModule from "../createListStateModule";
import gasTypesStatePlugin from "../plugins/gasTypesStatePlugin";

const cylinderListStateModule = createListStateModule({
    stateNamespace: "cylinder",
    itemsPropertyName: "cylinders",
    loadItemsMethodName: "loadCylinders",
    itemsDataMethod: CompanyService.getCompanyCylinders,
    loadDetailMethodName: "loadCylinderById",
    detailDataMethod: async (id) => {
        const cylinder = await CompanyService.getCompanyCylinderById(id);
        return cylinder.data[0]
    },
    detailsPropertyName: "cylinderData",
    deleteMethodName: 'deleteCylinder',
    deleteDataMethod: CompanyService.deleteCylinder,
    updateMethodName: 'updateCylinder',
    updateDataMethod: CompanyService.updateCylinder,
    createMethodName: 'createCylinderItem',
    defaultItemsData: {data: []},
    createDataMethod: CompanyService.createCompanyCylinder,
    plugins: [gasTypesStatePlugin]
});

export default cylinderListStateModule;
