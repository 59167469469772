import { ReactComponent as NoDataSvg } from "../../assets/no-chart-data.svg";
import styled from "styled-components";

const NoDataWrapper = styled.div`
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #d5d5d5;
    transition: opacity 0s;

    &.show {
        opacity: 1;
        transition: opacity 1s;
    }

    &.bar {
        top:0px;
    }
`;

const SvgNoData = styled(NoDataSvg)`
    width: 100%;
`;

const NoData = {
    Wrapper: NoDataWrapper,
    Icon: SvgNoData,
};

export default NoData;
