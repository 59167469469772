import { useGoogleMap } from "@react-google-maps/api";

const MapBounds = ({ markers }) => {
    const map = useGoogleMap();
    if (window.google) {
        var bounds = new window.google.maps.LatLngBounds();
        for (let i = 0; i < markers.length; i++) {
            bounds.extend(markers[i].getPosition());
        }
        //center the map to the geometric center of all markers
        map.setCenter(bounds.getCenter());
        window.google.maps.event.addListenerOnce(
            map,
            "bounds_changed",
            function (event) {
                if (map.getZoom() > 10) {
                    map.setZoom(10);
                }
                return null;
            }
        );
        map.fitBounds(bounds);
    }
    return null;
};

export default MapBounds;
