import React, { useMemo, useRef } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import useClickAway from "../../hooks/_shared/useClickAway";
import DataTable from "../data/DataTable";
import Filters from "../filter/Filters";
import Flex from "../layout/flex/Flex";
import LayoutInnerContainer from "../layout/split/one/LayoutInnerContainer";
import DetailSideBar from "../modal/DetailSideBar";
import HeaderActionsWrapper from "../wrappers/HeaderActionsWrapper";
import DataCardList from "./DataCardList";
import LoadingCards from "./LoadingCards";
import LoadingTable from "./LoadingTable";

const HeaderTabsWrapper = styled.div`
    display: flex;
    width: 100%;
`;

const HeaderWrapper = styled.div`
    display: inline-block;
    padding: 0px 60px 5px;
    margin: 0px -60px;
    border-radius: 15px 15px 0px 0px;
    background: linear-gradient(180deg, rgba(245, 245, 245, 1) 0%, rgba(240, 240, 240, 1) 100%);
    box-shadow: -2px 9px 11px -16px rgba(0, 0, 0, 0.75);
    border-bottom: 1px solid #ffffff;

    &.transparent {
        background: transparent;
        box-shadow: none;
        border-bottom: none;
    }

    &.hidden {
        display: none;
    }

    &.employees {
        padding: 0px 60px 5px 205px;
        margin: 0px -60px 0 -205px;
    }
`;

const ListingPageWrapper = ({
    employees,
    transparentHeader,
    wide,
    pwa,
    filterComponent,
    children,
    loading,
    data,
    footerActions,
    filterTitle,
    filterTitleOnTop,
    showTitle,
    headerActions,
    headerTabs,
    translation,
    headerVariant,
    noHeader,
}) => {
    let hwClassNames = transparentHeader ? "transparent" : "";
    if (noHeader) hwClassNames += " hidden";
    if (employees) hwClassNames += "employees";
    return (
        <LayoutInnerContainer className={pwa ? "wide-list pwa" : wide ? "wide-list" : ""}>
            <HeaderWrapper className={hwClassNames}>
                {filterTitleOnTop && filterComponent && showTitle && (
                    <Filters.Title>
                        {filterTitle ? filterTitle : translation("common:filter.title")}
                    </Filters.Title>
                )}

                <HeaderTabsWrapper className={headerVariant}>
                    {headerTabs && headerTabs()}
                </HeaderTabsWrapper>
                <HeaderActionsWrapper className={headerVariant}>
                    {headerActions && headerActions()}
                </HeaderActionsWrapper>

                {!filterTitleOnTop && filterComponent && showTitle && (
                    <Filters.Title>
                        {filterTitle ? filterTitle : translation("common:filter.title")}
                    </Filters.Title>
                )}

                {filterComponent && filterComponent()}
            </HeaderWrapper>
            {!loading && (!data || data.length === 0) ? (
                <>
                    {children}
                    <Flex.Container justifyContent="flex-end">
                        {footerActions && footerActions()}
                    </Flex.Container>
                </>
            ) : (
                children
            )}
        </LayoutInnerContainer>
    );
};

const ListingPage = ({
    cardClasses = "",
    employees,
    transparentHeader,
    wide,
    noHeader,
    pwa,
    columns,
    mode = "table",
    tableState: { data, loading, error },
    actions = {
        detailViewVariant: "",
        headerViewVariant: "",
    },
    onSelect,
    detailView,
    detailViewId = null,
    detailViewIsOpened,
    detailViewKey = null,
    closeSideBar,
    filterComponent,
    noDataText = "No data found",
    getRowClassName,
    cardColumns,
    filterTitle,
    showTitle = true,
    filterTitleOnTop = true,
    detailSideBarTitle,
    enableSelection = false,
    variants = {},
    shouldShowSelection,
    shouldEnableSelectionForRow,
    showSelectAll,
    paging = {
        pageCount: 0,
        pageSize: 10,
        enablePaging: false,
        onPageChange: undefined,
        currentPageIndex: 0,
        numPageButtons: 3,
    },
    sorting = {
        enableSorting: false,
        onSortByChange: undefined,
        currentSortBy: [],
    },
    getRowId,
}) => {
    const tableColumns = useMemo(() => columns, [columns]);
    const tableData = useMemo(() => data, [data]);
    const sideBarRef = useRef(null);
    const detailIsOpen = useMemo(() => detailViewIsOpened, [detailViewIsOpened]);

    const { t } = useTranslation();

    useClickAway(sideBarRef, () => {
        try {
            closeSideBar();
        } catch (ex) {}
    });
    if (!error) {
        return (
            <ListingPageWrapper
                employees={employees}
                transparentHeader={transparentHeader}
                wide={wide}
                pwa={pwa}
                noHeader={noHeader}
                loading={loading}
                data={data}
                noDataText={noDataText}
                footerActions={actions.footerActions}
                filterComponent={filterComponent}
                filterTitle={filterTitle}
                filterTitleOnTop={filterTitleOnTop}
                showTitle={showTitle}
                headerActions={actions.headerActions}
                headerTabs={actions.headerTabs}
                translation={t}
                headerVariant={variants.headerViewVariant}
            >
                {mode === "table" && (
                    <DataTable
                        loading={loading}
                        pwa={pwa}
                        data={tableData}
                        columns={tableColumns}
                        onSelect={onSelect}
                        getRowClassName={getRowClassName}
                        enableSelection={enableSelection}
                        shouldShowSelection={shouldShowSelection}
                        shouldEnableSelectionForRow={shouldEnableSelectionForRow}
                        showSelectAll={showSelectAll}
                        enablePaging={paging.enablePaging && data && data.length > 0}
                        pageCount={paging.pageCount || 1}
                        pageSize={paging.pageSize || 10}
                        numPageButtons={paging.numPageButtons || 3}
                        currentPageIndex={paging.currentPageIndex || 0}
                        onPageChange={paging.onPageChange}
                        onSortByChange={sorting.onSortByChange}
                        enableSorting={sorting.enableSorting || false}
                        currentSortBy={sorting.currentSortBy || []}
                        getRowId={getRowId}
                    />
                )}
                {mode === "cards" && (
                    <DataCardList
                        cardClasses={cardClasses}
                        data={tableData}
                        columns={tableColumns}
                        onSelect={onSelect}
                        cardColumns={cardColumns}
                        enablePaging={(paging.enablePaging && data.length > 0) || false}
                        pageCount={paging.pageCount}
                        pageSize={paging.pageSize}
                        numPageButtons={paging.numPageButtons || 3}
                        currentPageIndex={paging.currentPageIndex || 0}
                        onPageChange={paging.onPageChange}
                    />
                )}
                <Flex.Container justifyContent="flex-end">
                    {actions.footerActions && actions.footerActions()}
                </Flex.Container>
                {detailView && (
                    <DetailSideBar
                        key={detailViewKey}
                        detailIsOpened={detailIsOpen}
                        closeSideBar={closeSideBar}
                        ref={sideBarRef}
                        variant={variants.detailViewVariant}
                        title={detailSideBarTitle}
                        sidebarId={detailViewId}
                    >
                        {detailView}
                    </DetailSideBar>
                )}
            </ListingPageWrapper>
        );
    } else if (loading) {
        return (
            <ListingPageWrapper
                loading={loading}
                data={data}
                noDataText={noDataText}
                footerActions={actions.footerActions}
                filterComponent={filterComponent}
                filterTitle={filterTitle}
                showTitle={showTitle}
                headerActions={actions.headerActions}
            >
                {mode === "table" && <LoadingTable />}
                {mode === "cards" && <LoadingCards />}
            </ListingPageWrapper>
        );
    }
    return null;
};

export default ListingPage;
