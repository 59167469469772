import React, { useState, useEffect } from "react";
import Flex from "../../components/layout/flex/Flex";
import DataEntry from "../../components/data-entry/DataEntry";
import { useTranslation } from "react-i18next";
import ValidationRule, {
    buildValidation,
} from "../../core/validations/ValidationRule";
import commonValidation, { commonRegex } from "../../core/validations/common";
import RegistrationService from "../../services/Registration/RegistrationService";
import Wrappers from "../../components/wrappers/Wrappers";
import RegisterForm from "./RegisterForm";
import helpers from "../../core/helpers";
import LayoutOneTwo from "../../components/layout/split/one-two/LayoutOneTwo";
import styled from "styled-components";
import CompanyType from "../../core/enums/CompanyType";
import useStateModule from "../../core/state/useStateModule";
import companyStateModule from "../../core/state/company/companyStateModule";
import NavigationLink from "../../components/elements/links/NavigationLink";
import { toast } from "react-toastify";
import Headings from "../../components/elements/headings/Headings";
import RouteCreator from "../../core/RouteCreator";
import * as yup from "yup";
import { Helmet } from "react-helmet";
import UserService from "../../services/User/UserService";

const Section = styled.div`
    font-size: 20px;
    padding: 0px 0px;
    margin-bottom: 35px;
    &.bold {
        font-weight: 600;
    }
    & span {
        color: ${(p) => p.theme.primaryColor};
    }

    @media ${(p) => p.theme.device.mobile} {
        padding: 0;
    }
`;

const Circle = styled.div`
    width: 44px;
    height: 44px;
    line-height: 46px;
    font-weight: 700;
    font-size: 26px;
    text-align: center;
    background: #fff;
    color: ${(p) => p.theme.primaryColor};
    border: 3px solid ${(p) => p.theme.primaryColor};
    border-radius: 50%;
    float: left;
    margin-right: 25px;

    & img {
        height: 20px;
        margin-top: 10px;
    }
`;

const initialValues = (isCertMandatory) => ({
    email: "",
    companyName: "",
    vatNumber: "",
    password: "",
    repeatPassword: "",
    fGasCertificateCheck: isCertMandatory,
    fGasCertificate: "",
    licenceNumber: "",
    licenceExpirationDate: "",
    licenceNeverExpires: false,
    companyType: CompanyType.InstallationCompany,
});

const registrationValidation = (t, certMandatory) =>
    yup.lazy((values) => {
        let rules = {
            email: commonValidation.email(t).rule,
            companyName: ValidationRule.isStringRule().required(
                t("forms:companyName.required")
            ).rule,
            vatNumber: ValidationRule.isStringRule()
                .regexMatchRule(commonRegex.VATRegExp, t("forms:VAT.invalid"))
                .required(t("forms:VAT.required")).rule,
            password: commonValidation.password(t).rule,
            repeatPassword: ValidationRule.shouldBeEqual(
                "password",
                t("forms:repeatPassword.invalid"),
                t("forms:repeatPassword.required")
            ).rule,
        };
        if (certMandatory || values.fGasCertificateCheck)
            rules = {
                ...rules,
                ...{
                    fGasCertificateCheck: yup
                        .boolean()
                        .required()
                        .oneOf([true], t("forms:fGasCertificate.required")),
                    fGasCertificate: ValidationRule.isStringRule().required(
                        t("screens:registration.uploadFile.error")
                    ).rule,
                    licenceNumber: ValidationRule.isStringRule().required(
                        t("screens:registration.licenceError")
                    ).rule,
                    licenceExpirationDate: values.licenceNeverExpires
                        ? yup.string()
                        : ValidationRule.isStringRule().required(
                              t(
                                  "screens:registration.licenceExpirationDateError"
                              )
                          ).rule,
                },
            };
        return buildValidation(rules);
    });

const RegisterScreen = () => {
    const [newUserEmail, setNewUserEmail] = useState(null);
    const { resendValidationEmail } = useStateModule(companyStateModule);
    const [certMandatory, setCertMandatory] = useState(true);
    const { t } = useTranslation(["common", "forms"]);

    useEffect(() => {
        async function getSetting() {
            const res = await UserService.isFGasLicenceMandatory();
            setCertMandatory(res.data?.settingValue === "True");
        }
        getSetting();
    }, []);

    const yupValidation = registrationValidation(t, certMandatory);

    const handleSignup = async (values) => {
        if (values.licenceNeverExpires)
            values.licenceExpirationDate = new Date("1/1/3000");
        delete values.licenceNeverExpires;
        let formData = helpers.getFormData(values);

        if (values.fGasFile && values.fGasCertificateCheck)
            formData.append(
                "fGasCertificate",
                values.fGasFile,
                values.fGasFile.name
            );

        const result = await RegistrationService.registerAsync(formData);
        if (result && result.error) toast.error(t(result.error.message));
        else {
            setNewUserEmail(values.email);
        }
    };

    return (
        <Flex.Container className="full-height">
            <Helmet>
                <title>{t("seo:registration.title")}</title>
            </Helmet>
            <LayoutOneTwo>
                {!newUserEmail && (
                    <>
                        <DataEntry
                            isLoading={false}
                            onSubmit={handleSignup}
                            renderForm={(formik) => (
                                <RegisterForm
                                    formik={formik}
                                    isLoading={false}
                                    isCertMandatory={certMandatory}
                                />
                            )}
                            initialValues={initialValues(certMandatory)}
                            validationSchema={yupValidation}
                        />
                    </>
                )}
                {newUserEmail && (
                    <>
                        <Wrappers.FormWrapper className="login">
                            <Wrappers.FormWrapper>
                                <Wrappers.Wrapper>
                                    <Headings.H2
                                        className="center title bold"
                                        style={{ marginBottom: "100px" }}
                                    >
                                        {t("screens:registration.title")}
                                    </Headings.H2>
                                    <Flex.Row>
                                        <Section className="bold">
                                            <Circle>
                                                <img
                                                    alt="ok"
                                                    src="/images/actions/icon-ok-primary.svg"
                                                />
                                            </Circle>
                                            {t("screens:login.receivedRequest")}
                                        </Section>
                                        <Section>
                                            {t(
                                                "screens:registration.validateEmail.emailSent"
                                            )}
                                            <span>{newUserEmail}</span>
                                        </Section>
                                        <Section>
                                            {t(
                                                "screens:registration.validateEmail.verify"
                                            )}
                                        </Section>
                                        <Section>
                                            {t(
                                                "screens:registration.validateEmail.resend.text"
                                            )}
                                        </Section>
                                    </Flex.Row>
                                </Wrappers.Wrapper>
                                <Wrappers.Wrapper
                                    className="form-links-wrapper"
                                    style={{ marginTop: "-30px" }}
                                >
                                    <NavigationLink
                                        to="#"
                                        className="forgotten-password"
                                        onClick={() =>
                                            resendValidationEmail(newUserEmail)
                                        }
                                    >
                                        {t(
                                            "screens:registration.validateEmail.resend.button"
                                        )}
                                    </NavigationLink>

                                    <span style={{ margin: "0px 24px" }}>
                                        &#124;
                                    </span>

                                    <NavigationLink
                                        to={RouteCreator.user.login()}
                                        className="forgotten-password"
                                    >
                                        {t(
                                            "screens:forgottenPassword.backToLoginLink"
                                        )}
                                    </NavigationLink>
                                </Wrappers.Wrapper>
                            </Wrappers.FormWrapper>
                        </Wrappers.FormWrapper>
                    </>
                )}
            </LayoutOneTwo>
        </Flex.Container>
    );
};

export default RegisterScreen;
