import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import DataEntry from "../../../../../components/data-entry/DataEntry";
import EffectError from "../../../../../components/errors/EffectError";
import userStateModule from "../../../../../core/state/user/userStateModule";
import useStateModule from "../../../../../core/state/useStateModule";
import commonValidation from "../../../../../core/validations/common";
import ValidationRule, {
    buildValidation,
} from "../../../../../core/validations/ValidationRule";
import UserPasswordForm from "../../../../User/components/UserPasswordForm";

const passwordDefaultValues = {
    password: "",
    repeatPassword: "",
};
const getPasswordValidation = (t) => {
    return buildValidation({
        password: commonValidation.password(t).rule,
        repeatPassword: ValidationRule.shouldBeEqual(
            "password",
            t("forms:repeatPassword.invalid"),
            t("forms:repeatPassword.required")
        ).rule,
    });
};

const ChangePasswordSection = () => {
    const { t } = useTranslation();
    const {
        password,
        changePassword,
        changePasswordResetState,
    } = useStateModule(userStateModule);
    useEffect(() => {
        return changePasswordResetState;
    }, [changePasswordResetState]);

    const handlePasswordSubmit = async (newPassword) => {
        await changePassword(newPassword);

        toast.success(t("screens:employeeEdit.passwordSuccess"));
    };

    const passwordValidation = getPasswordValidation(t);

    return (
        <EffectError error={password.error}>
            <DataEntry
                validateOnBlur={false}
                validateOnChange={false}
                initialValues={passwordDefaultValues}
                validationSchema={passwordValidation}
                renderForm={(formik) => (
                    <UserPasswordForm
                        formik={formik}
                        isLoading={password.isLoading}
                        isCompleted={!password.error && password.isCompleted}
                    />
                )}
                onSubmit={handlePasswordSubmit}
                isLoading={password.isLoading}
            />
        </EffectError>
    );
};

export default ChangePasswordSection;
