import React, { useRef, useState,useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import RoundedImage from "../../../components/elements/images/RoundedImage";
import helpers from "../../../core/helpers";
import useFileDrop from "./hooks/useFileDrop";
import { Input, ValidationError } from "./index";

const UploaderWrapper = styled.div`
    position: relative;
    min-height: 44px;
    width: 100%;
    text-align: center;
    border: 2px dashed #e2e2e2;
    font-size: 16px;
    border-radius: 18px;
    padding: 16px;
    transition: all 300ms ease;

    &.inline-form {
        margin: 24px 0 0;
    }

    &.dragging {
        border: 2px dashed #675b34;

        .infoWrapper *{
            color:#ABABAB !important;
        }
    }

    &.wide {
        width: 72%;
    }
`;
const RealUploader = styled.div`
    display: flex;
    align-items: center;

    input[type="file"] {
        position: absolute;
        height: 40px;
        opacity: 0;
        z-index: 1;
        width: 0;
    }
 
    .infoWrapper {
        text-align: left;
        margin-left: 20px;
        font-size: 20px;
        color: #000;
        font-weight: 700;
        line-height: 20px;
        padding-bottom: 4px;
        div {
            padding: 5px;
        }
    }

    .action {
        cursor: pointer;
        color: ${p => p.theme.primaryColor};
        font-size: 16px;
        font-weight: normal;
    }

    .action-remove {
        cursor: pointer;
        color: #e94e4e;
    }

    .action:hover {
        text-decoration: underline;
    }
`;

const Avatar = styled.div`
    border-radius: 50%;
    min-width: 84px;
    width: 84px;
    height: 84px;
    color: #fff;
    background-color: ${(props) => props.theme.tertiaryColor};
    text-align: center;
    margin: 0px 30px;

    @media ${(p) => p.theme.device.mobile} {
        margin : 0;
    }

    & span {
        line-height: 84px;
        font-size: 38px;
        text-transform: uppercase;
    }

    .avatar {
        border-radius: 50%;
        background: no-repeat center;
        background-size: cover;
        display: inline-block;
        width: 100%;
        height: 100%;
    }
`;

const ProfilePictureUploader = (props) => {
    const { t } = useTranslation();
    const [inputHover, setInputHover] = useState(false);
    const [fileName, setFileName] = useState("");

    let {
        labelText,
        name,
        errors,
        touched,
        values,
        handleChange,
        handleBlur,
        disabled,
        id,
        style,
        className,
        userData,
        otherUserData,
        autoSubmit,
        onDelete,
        fullName,
    } = props;

    let value = props.value ? props.value : values && values[name];
    let error = errors ? errors[name] : null;
    let isTouched = touched ? touched[name] : false;

    const realUserData=(userData.email===otherUserData.email) ? userData:otherUserData;
    const nameLabel = realUserData.firstName ? realUserData.firstName+" "+ realUserData.lastName : "";
    if(userData.email!==otherUserData.email) disabled = true;

    const fileInput = useRef(null);

    const handleHover = () => {
        if(disabled) return;
        setInputHover(!inputHover);
    };

    const onFileUpload = (e) => {
        if(disabled) return;
        if (e && e.currentTarget.files.length > 0) {
            setFileName(e.currentTarget.files[0].name);
        }
    };

    useEffect(() => {

        autoSubmit && autoSubmit();
    }, [fileName]);

    const onChange = (e) => {
        handleChange(e);
        onFileUpload(e);
        props.onChange && props.onChange(e);
    };

    const triggerFileInput = () => {
        fileInput.current.click();
    };
    const { isDragging, getUploaderProps } = useFileDrop((files) => {
        fileInput.current.files = files;

        let event = document.createEvent("UIEvents");
        event.initUIEvent("change", true, true);

        fileInput.current.dispatchEvent(event);
    });


    const Actions = ()=> realUserData.profilePictureUrl === "" ? (
        <div className="action" onClick={triggerFileInput}>
            <span>{labelText}</span>
        </div>
    ) : (
        <div className="action-remove" onClick={onDelete}>
            <span>{t("actions:deletePicture")}</span>
        </div>
    )

    return (
        <UploaderWrapper
            {...getUploaderProps()}
            style={style}
            className={[className, isDragging && "dragging"]}
        >
            <RealUploader onMouseEnter={handleHover} onMouseLeave={handleHover}>
                <Input
                    type="file"
                    id={id}
                    name={name}
                    onChange={(e) => onChange(e)}
                    onBlur={handleBlur}
                    disabled={disabled && "disabled"}
                    defaultValue={value || ""}
                    className="file"
                    ref={fileInput}
                    accept={props.accept ? props.accept : ""}
                />

                {realUserData.profilePictureUrl || isDragging ? (
                    <RoundedImage
                        dragging={isDragging}
                        image={realUserData.profilePictureUrl}
                    />
                ) : (
                    <Avatar>
                    <span>{helpers.getInitials(nameLabel)}</span>
                    </Avatar>
                )}

                <div className="infoWrapper">
                    <div>{fullName || nameLabel}</div>
                    {!disabled && <Actions />}

                </div>
            </RealUploader>

            {error && <ValidationError touched={isTouched} error={error} />}
        </UploaderWrapper>
    );
};

export default ProfilePictureUploader;
