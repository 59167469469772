import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { ShortDateCell } from "../../../components/data/DateCell";
import ListingPage from "../../../components/data/ListingPage";
import { Select } from "../../../components/elements/form-elements";
import { DateTimePicker } from "../../../components/elements/form-elements/DatePicker";
import useImmerReducer from "../../../hooks/_shared/useImmerReducer";
import usePageChange from "../../../hooks/_shared/usePageChange";
import FilterMenu from "../../../screens/pwa/FilterMenu";
import StockService from "../../../services/Stock/StockService";
import globals from "../../../globalVars";
import {
    FilterClear,
    FilterWrapper,
    FlexForm,
    HR,
    ListTitle,
    ScreenHeader,
    ScreenWrapper,
} from "./StyledElements";
import {
    StyledArrow,
    StyledBack,
    StyledClose,
    StyledFilter,
} from "./StyledSvg";

const actionTypes = {
    SET_PAGING: "SET_PAGING",
    SET_SORT: "SET_SORT",
    RESET_SORT: "RESET_SORT",
    SET_FILTERS: "SET_FILTERS",
    RESET_FILTERS: "RESET_FILTERS",
};

const KeyValue = styled.div`
    padding: 20px 20px 0px 30px;
    width: 100%;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    & .value {
        color: ${(p) => p.theme.primaryColor};
    }
`;

const KeyValueCell = (key, value) => {
    return (
        <KeyValue>
            <span>{key}</span>
            <span className="value">{value.toFixed(2)} kg</span>
        </KeyValue>
    );
};

const filterReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.SET_PAGING:
            state.pageSize = action.payload.pageSize;
            state.page = action.payload.pageIndex + 1;
            break;
        case actionTypes.SET_SORT:
            state.orderColumn = action.payload[0].id;
            state.descending = action.payload[0].desc;
            break;
        case actionTypes.RESET_SORT:
            state.orderColumn = "extractionDateTime";
            state.descending = true;
            break;
        case actionTypes.SET_FILTERS:
            state.filter.clientName = action.payload.clientName;
            state.filter.extractFrom = action.payload.extractFrom;
            state.filter.extractTo = action.payload.extractTo;
            state.page = 1;
            break;
        case actionTypes.RESET_FILTERS:
            delete state.filter.clientName;
            delete state.filter.extractFrom;
            delete state.filter.extractTo;
            state.page = 1;
            break;
        default:
            return state;
    }
};

function dynamicSort(property, asc = true) {
    var sortOrder = asc ? 1 : -1;
    return function (a, b) {
        /* next line works with strings and numbers,
         * and you may want to customize it to your needs
         */
        var result =
            a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
        return result * sortOrder;
    };
}

const ExtractionListing = (props) => {

    const [filterState, dispatch] = useImmerReducer(filterReducer, {
        filter: {
            client: "",
        },
        page: 1,
        pageSize: globals.maxListingItemsPerPage,
        orderColumn: "clientName",
        descending: true,
    });


    const { t } = useTranslation("screens");
    const [extState, setExtState] = useState({
        data: [],
        raw: undefined,
        initialQuantity:0
    });
    const filterMenuControl = {};
    const tempFilterData = useRef({});

    const filterData = useRef({
        clientName: "",
        extractFrom: "",
        extractTo: "",
    });
        
    useEffect(() => {

        //apply sorting and filtering
        let filtered = null;
        if (extState.raw && extState.raw.length) {
            extState.raw.sort(
                dynamicSort(filterState.orderColumn, !filterState.descending)
            );
            if (hasFilters()) {
                filtered = extState.raw.filter((itm) => {
                    if (
                        filterState.filter.clientName &&
                        itm.clientName !== filterState.filter.clientName
                    )
                        return false;
                    if (filterState.filter.extractFrom) {
                        const extractDate = new Date(itm.extractionDateTime);
                        extractDate.setHours(0, 0, 0, 0);
                        if (
                            extractDate <
                            new Date(filterState.filter.extractFrom)
                        )
                            return false;
                    }
                    if (filterState.filter.extractTo) {
                        const extractDate = new Date(itm.extractionDateTime);
                        extractDate.setHours(0, 0, 0, 0);
                        if (
                            extractDate > new Date(filterState.filter.extractTo)
                        )
                            return false;
                    }
                    return true;
                });
            }
            const firstItem = (filterState.page - 1) * filterState.pageSize;
            setExtState({
                ...extState,
                data: filtered
                    ? filtered.slice(firstItem, firstItem + 10)
                    : extState.raw.slice(firstItem, firstItem + 10),
            });
        }
    }, [filterState]);

    const clearFilters = () => {
        filterData.current = {
            clientName: "",
            extractFrom: "",
            extractTo: "",
        };
        Object.assign(tempFilterData.current, filterData.current);
        dispatch({
            type: actionTypes.RESET_FILTERS,
        });
    };

    const getInitialQuantity = (extractions) => {
        if (!extractions || extractions.length === 0) return 0;
        let inQuantity = 0;
        extractions.forEach((curr) => {
            if (curr.extractedByCompanyId !== globals.companyId)
                inQuantity += curr.extractedQuantity;
        });
        return inQuantity;
    };

    const getExtractionData = async () => {
        if (!props.cylinder) return;
        const result = await StockService.getStockItemAsync(props.cylinder);

        if (result && result.error) {
            toast.error(t(result.error.message));
        } else {
            const raw = result.data.data[0].extractions;
            const cylSize = result.data.data[0].cylinderSize;
            for (var i = 0; i < raw.length; i++) raw[i].cylinderSize = cylSize;
            raw.sort(
                dynamicSort(filterState.orderColumn, !filterState.descending)
            );
            setExtState({
                raw: raw,
                data: raw.slice(0, extState.pageSize),
                initialQuantity: getInitialQuantity(raw),
            });
        }
    };

    useEffect(() => {
        clearFilters();
        dispatch({
            type: actionTypes.SET_PAGING,
            payload: { pageSize: 10, pageIndex: 0 },
        });
        getExtractionData();
    }, [props.cylinder]);

    const columns = useMemo(() => {
        const columns = [
            {
                Header: t("table:headers.client.name"),
                accessor: "clientName",
            },
            {
                Header: t("table:headers.extractionDate"),
                accessor: "extractionDateTime",
                className: "right",
                Cell: ShortDateCell,
            },
            {
                Header: "",
                accessor: "id",
                className: "right-arrow",
                Cell: ({ cell: { value } }) => <StyledArrow />,
                disableSortBy: true,
            },
        ];
        return columns;
    }, [extState.data, t]);

    const onSelect = useCallback((row) => {
        props.onSelect && props.onSelect(row.original);
    }, []);

    const pageChange = usePageChange(dispatch, filterState);

    const currentSortBy = useMemo(() => {
        return [{ id: filterState.orderColumn, desc: filterState.descending }];
    }, [filterState.orderColumn, filterState.descending]);

    if (!props.cylinder) return <ScreenWrapper />;

    const hasFilters = () =>
        filterData.current.clientName ||
        filterData.current.extractFrom ||
        filterData.current.extractTo;

    const uniqueClientNames = extState.raw
        ? extState.raw.filter(
              (v, i, a) =>
                  a.findIndex((t) => t.clientName === v.clientName) === i
          )
        : [];

    const filterItems = () => (
        <FlexForm autoComplete="off">
            <Select
                key="clientName"
                name="clientName"
                errors={[]}
                touched={[]}
                defaultItem={{
                    key: "all",
                    value: "",
                    label: t("forms:clients.client"),
                }}
                defaultValue={
                    filterState.filter.clientName !== undefined
                        ? filterState.filter.clientName
                        : ""
                }
                options={uniqueClientNames.map((key) => ({
                    key: key.id,
                    value: key.clientName,
                    label: key.clientName,
                }))}
                handleChange={(evt) => {
                    tempFilterData.current.clientName = evt.currentTarget.value;
                }}
                className="filters big"
                labelText={t("forms:clients.client")}
                wrapperVariant="flex-1"
            />
            <DateTimePicker
                labelText={t("forms:extractionDate.from")}
                native
                id="extractFrom"
                name="extractFrom"
                wrapperVariant="flex-2"
                className="filters big left"
                maxPickerId="extractTo"
                maxDate={
                    filterData.current ? filterData.current.extractTo : null
                }
                value={filterData.current ? filterData.current.extractFrom : ""}
                handleChange={(ev) =>
                    (tempFilterData.current.extractFrom = ev.target.value)
                }
              

            />
            <DateTimePicker
                labelText={t("forms:extractionDate.to")}
                native
                id="extractTo"
                name="extractTo"
                minPickerId="extractFrom"
                minDate={
                    filterData.current ? filterData.current.extractFrom : null
                }
                wrapperVariant="flex-2"
                className="filters big right"
                value={filterData.current ? filterData.current.extractTo : ""}
                handleChange={(ev) =>
                    (tempFilterData.current.extractTo = ev.target.value)
                }
            />
        </FlexForm>
    );

    const applyFilters = () => {
        Object.assign(filterData.current, tempFilterData.current);
        dispatch({
            type: actionTypes.SET_FILTERS,
            payload: filterData.current,
        });
    };

    const clearFilter = (name) => {
        switch (name) {
            case "clientName":
                filterData.current.clientName = "";
                break;
            case "date":
                filterData.current.extractFrom = "";
                filterData.current.extractTo = "";
                break;
            default:
                break;
        }
        dispatch({
            type: actionTypes.SET_FILTERS,
            payload: filterData.current,
        });
    };

    const showFilter = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        filterMenuControl.setState({
            isOpen: true,
            hasFilters: hasFilters(),
            filterItems: filterItems(),
            applyFn: applyFilters,
            clearFn: clearFilters,
            text: hasFilters()
                ? t("screens:stockListing.filters.clearFilters")
                : t("screens:stockListing.filters.filterExtractions"),
        });
    };

    const sortChange = (sortBy) => {
        sortBy.length > 0
            ? dispatch({ type: actionTypes.SET_SORT, payload: sortBy })
            : dispatch({ type: actionTypes.RESET_SORT });
    };

    const curFilters = filterData.current;
    return (
        <ScreenWrapper>
            <FilterMenu control={filterMenuControl} />

            <ScreenHeader>
                <StyledBack
                    onClick={(ev) => {
                        props.onGoBack(ev);
                    }}
                />
                <FilterWrapper>
                    {curFilters.clientName && (
                        <FilterClear onClick={() => clearFilter("clientName")}>
                            {t("screens:stockListing.filters.client")}{" "}
                            <StyledClose />
                        </FilterClear>
                    )}

                    {(curFilters.extractFrom || curFilters.extractTo) && (
                        <FilterClear onClick={() => clearFilter("date")}>
                            {t("screens:stockListing.filters.date")}{" "}
                            <StyledClose />
                        </FilterClear>
                    )}

                    <StyledFilter onClick={showFilter} />
                </FilterWrapper>
            </ScreenHeader>
            <HR />
            <ListTitle>{t("screens:technician.extractions")}</ListTitle>
            {KeyValueCell(
                `${t("sidebar:shared.initialQuantity")}:`,
                extState.initialQuantity
            )}
            <ListingPage
                noHeader
                pwa
                columns={columns}
                tableState={{
                    data: extState.data,
                    loading: false,
                    error: "",
                }}
                paging={{
                    enablePaging: true,
                    pageCount: extState.totalPages,
                    pageSize: extState.pageSize,
                    onPageChange: pageChange,
                    currentPageIndex: extState.currentPage - 1,
                }}
                sorting={{
                    enableSorting: true,
                    onSortByChange: sortChange,
                    currentSortBy: currentSortBy,
                }}
                onSelect={onSelect}
                noDataText={t("screens:stockListing.notification")}
            />
        </ScreenWrapper>
    );
};

export default ExtractionListing;
