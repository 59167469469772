import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as ResetSvg } from "../../../assets/trash.svg";
import { ReactComponent as UploadSvg } from "../../../assets/upload.svg";
import useFileDrop from "./hooks/useFileDrop";
import Input from "./Input";

const UploadInput = styled(Input)`
    background: #000;
`;

const StyledUpload = styled(UploadSvg)`
    display: block;
    height: 22px;
`;

const StyledReset = styled(ResetSvg)`
    position: absolute;
    left: 50%;
    top: 50%;
    width: 40px;
    height: 40px;
    transition: all 300ms ease;
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.8);
    padding: 4px;
    border-radius: 6px;

    opacity: 0;
    pointer-events: none;

    &:hover .lines {
        stroke: #e94e4e;
    }

    &.image.has-file {
        opacity: 1;
        pointer-events: initial;
    }
`;

export const UploadInputWrapper = styled.div`
    position: relative;
    min-height: 44px;
    border: 2px dashed #e2e2e2;
    font-size: 16px;
    border-radius: 12px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    &.white {
        background: #ffffff;
    }

    &.doubleHeight {
        height: 138px;
        margin-top: 5px;
    }

    &.disabled {
        background: #f5f5f5;
    }

    &.disabled .placeholder {
        color: #888;
    }

    &:hover:not(.disabled) {
        cursor: pointer;

        & .back-image {
            opacity: 0.5;
        }
    }

    &.dragging {
        border: 2px dashed #675b34;
        cursor: pointer;
    }

    & > .content {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        padding: 32px 16px;
        color: ${(p) => p.theme.primaryColor};
        height: 100%;
        justify-content: space-between;
        box-sizing: border-box;
    }

    & > .content .placeholder {
        color: #2294D2;
        font-size: 14px;
        font-weight: 500;
        margin: 0 auto;
        @media ${(props) => props.theme.device.mobile} {
            font-size: 18px;
        }
    }

    & > .content .placeholder.multi {
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    & > .content .title {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;
        width: 100%;
        font-size: 16px;
        color: #000;
        font-weight: 600;
        line-height: 20px;
        @media ${(props) => props.theme.device.mobile} {
            font-size: 20px;
        }
    }

    &.image.has-file > .content {
        text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
    }

    &.image.has-file .placeholder,
    &.image.has-file .title {
        display: none;
    }

    &.disabled > .content .title {
        color: #888;
        text-shadow: none;
    }

    &:not(.disabled) > .content.empty {
        & .placeholder {
            margin-top: 0;
        }
    }

    &:not(.disabled) > .content.empty:hover {
        & .placeholder {
            text-decoration: underline;
            margin-top: 0;
        }
    }

    &:not(.disabled):hover:after {
        background: #d4d4d4;
    }

    & input {
        opacity: 0;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 99;
        height: 32px;
        margin: 0;
        padding: 0;
        display: block;
        cursor: pointer;
        width: 100%;
    }

    & input.hidden {
        display: none;
        z-index: -1;
        width: 0px;
        height: 0px;
    }

    & .back-image {
        position: absolute;
        left: 5px;
        top: 5px;
        width: calc(100% - 10px);
        height: calc(100% - 10px);
        z-index: -1;
        background-position: center;
        background-size: cover;
        border-radius: 15px;
    }
`;

const Uploader = (props) => {
    const imageRef = useRef();
    let defVal = props.value && props.value instanceof Array ? props.value : [];
    if (
        props.type === "image" &&
        props.value &&
        (typeof props.value === "string" || props.value instanceof String) &&
        props.value !== props.skipUrl
    ) {
        defVal = [{ name: "image", url: props.value, type: "imageUrl" }];
    }

    const [files, setFiles] = useState(defVal);

    useEffect(() => {
        if (files[0]?.type === "imageUrl")
            imageRef.current.style.backgroundImage = "url(" + props.value + ")";
    }, []);

    const inputRef = useRef();
    const onChange = (e) => {
        if (e && e.currentTarget.files.length > 0) {
            let newFiles = Array.from(e.target.files).filter(
                (file) => !files.some((img) => img.name === file.name)
            );
            if (props.multiple) {
                props.setFieldValue(props.name, [...props.values[props.name], ...newFiles]);
                setFiles((prev) => [...prev, ...newFiles]);
            } else {
                if (newFiles.length > 0) {
                    props.setFieldValue(props.name, [newFiles[0]]);
                    setFiles([newFiles[0]]);
                    if (props.type === "image") {
                        const imageUrl = URL.createObjectURL(newFiles[0]);
                        imageRef.current.style.backgroundImage = "url(" + imageUrl + ")";
                    }
                } else {
                    if (props.type === "image") {
                        imageRef.current.style.backgroundImage = "none";
                    }
                }
            }
        }
    };

    const { isDragging, getUploaderProps } = useFileDrop((files) => {
        inputRef.current.files = files;

        let event = document.createEvent("UIEvents");
        event.initUIEvent("change", true, true);
        inputRef.current.dispatchEvent(event);
    });

    const fileNames = files.map((f) => f.name);
    let placeholder =
        files instanceof Array
            ? fileNames
                  .map((f) => {
                      return "▣ " + f;
                  })
                  .join("<br />")
            : files;

    if (!placeholder) placeholder = props.placeholder || "Drop or add files";
    const handleReset = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        if (props.disabled) return;
        props.setFieldValue(props.name, []);
        setFiles([]);
        inputRef.current.value = "";
        if (props.type === "image") {
            imageRef.current.style.backgroundImage = "none";
        }
    };

    return (
        <>
            <UploadInputWrapper
                {...getUploaderProps()}
                // data-placeholder={placeholder}
                data-text={props.text || "Or Select"}
                disabled={props.disabled}
                className={[
                    props.className,
                    "inputElement",
                    isDragging && "dragging",
                    props.disabled && "disabled",
                    props.type === "image" && "image",
                    files.length && "has-file",
                ]}
                onClick={(ev) => {
                    if (props.disabled) return;
                    inputRef.current && inputRef.current.dispatchEvent(new MouseEvent("click"));
                }}
            >
                {fileNames.length > 0 && (
                    <StyledReset
                        className={[props.type === "image" && "image", files.length && "has-file"]}
                        onClick={handleReset}
                    />
                )}
                <input
                    ref={inputRef}
                    type="file"
                    onChange={onChange}
                    multiple={props.multiple}
                    accept={props.type === "image" ? "image/*" : props.accept}
                    className="hidden"
                />
                <UploadInput {...props} type="generic" className="hidden" />
                <div className={`content ${fileNames.length === 0 ? "empty" : ""}`}>
                    {props.title && (
                        <div className="title">
                            <StyledUpload />{props.title}
                        </div>
                    )}
                    <div
                        className={`placeholder ${fileNames.length > 0 ? "multi" : ""}`}
                        dangerouslySetInnerHTML={{ __html: placeholder }}
                    />
                </div>
                <div ref={imageRef} className="back-image" />
            </UploadInputWrapper>
        </>
    );
};

export default Uploader;
