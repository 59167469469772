import React, { useState, useEffect } from "react";

import { toast } from "react-toastify";
import UserService from "../../services/User/UserService";
import { useTranslation } from "react-i18next";
import useQueryString from "../../hooks/_shared/useQueryString";
import styled from "styled-components";
import FullScreenNotification from "../../components/notifications/FullScreenNotification";
import Anchor from "../../components/elements/form-elements/Link";
import ReactDOM from "react-dom";
import RouteCreator from "../../core/RouteCreator";
import useStateModule from "../../core/state/useStateModule";
import userStateModule from "../../core/state/user/userStateModule.js";

const ScreenWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ChangeEmailScreen = () => {
    const { t } = useTranslation();
    let username = useQueryString("username");
    let token = useQueryString("token");
    let newEmail = useQueryString("newEmail");
    const [usernameChanged, setUsernameChanged] = useState(false);
    const { logout } = useStateModule(userStateModule);

    useEffect(() => {
        (async () => {
            try {
                const res = await UserService.confirmEmail({
                    username,
                    newEmail,
                    token,
                });
                if (res.status === 204) {
                    setUsernameChanged(true);
                } else {
                    toast.error(t("screens:changeEmail.error"));
                }
            } catch (err) {
                toast.error(t("screens:changeEmail.error"));
            }
        })();
    }, []);

    if (usernameChanged) {
        return ReactDOM.createPortal(
            <FullScreenNotification
                title={t("screens:changeEmail.completedTitle")}
                text={`${t("screens:changeEmail.completedText")} ${newEmail}`}
                button={
                    <Anchor
                        className="primary"
                        to={RouteCreator.user.login()}
                        onClick={logout}
                    >
                        {t("screens:logout.notifications.button")}
                    </Anchor>
                }
                redirectTo={RouteCreator.user.login()}
                redirect={true}
                redirectTime={5000}
                executeFn={logout}
            />,
            document.getElementById("portal")
        );
    }

    return <ScreenWrapper></ScreenWrapper>;
};

export default ChangeEmailScreen;
