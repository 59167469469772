import createStateModule from "../createStateModule";

const breadcrumbsStateConfig = {
    stateNamespace: "breadcrumbs",
    types: ["SET_BREADCRUMBS"],
    initialState: {
        items: []
    },
    createReducer: types => {
        return (state, action) => {
            switch (action.type) {
                case types.SET_BREADCRUMBS:
                    state.items = action.payload;
                    break;
                default:
                    return state;
            }
        }
    },
    creators: types => ({
        setBreadcrumbs: (items) => ({type: types.SET_BREADCRUMBS, payload: items})
    })
};

export default createStateModule(breadcrumbsStateConfig);
