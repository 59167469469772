import React, { memo } from "react";
import styled from "styled-components";
export const TabContent = styled.div`
    display: ${(props) => (props.show ? "block" : "none")};
`;

const TabsWrapper = styled.div`
    --i: ${(props) => (props.index !== undefined ? props.index : -1)};
    --numTabs: ${(props) => (props.numTabs !== undefined ? props.numTabs : 10)};
    --tabWidth: calc(100% / var(--numTabs));
    --tabHeight: ${(props) =>
        props.tabHeight !== undefined ? props.tabHeight : "32px"};
    --activeColor: ${(props) =>
        props.activeColor !== undefined ? props.activeColor : "#48b05e"};
    user-select: none;
    display: flex;
    margin: 0 10px 15px 0;
    position: relative;
    overflow: hidden;
    z-index: 0;
    width: calc(150px * var(--numTabs));
    min-height: var(--tabHeight);
    background: #fff;
    border: 1px solid #e2e2e2;
    border-radius: 16px;

    
    &.wide {
        width: calc(180px * var(--numTabs));
    }

    &.minimal {
        background: transparent;
        border: none;
        border-radius: 0;
        width: auto;
        height: 40px;
        padding: 0px 20px 0px 78px;
        margin: -25px -120px 0px -80px;
        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 1) 0%,
            rgba(220, 220, 220, 1) 100%
        );
        box-shadow: -2px 9px 11px -16px rgb(0 0 0 / 75%);
        border-bottom: 1px solid #ffffff;

        &.mr-0 {
            margin-right: 0px;
        }
    }

    &:before {
        box-sizing: border-box;
        content: "";
        position: absolute;
        z-index: -1;
        width: var(--tabWidth);
        top: 0;
        left: calc(var(--i) * var(--tabWidth));
        height: 100%;
        background: var(--activeColor);
        transition: all 300ms ease;
        border-radius: 15px;
    }

    &.minimal:before {
        content: none;
    }

    & div {
        width: var(--tabWidth);
        display: inline;
        margin: 0;
        position: relative;
        z-index: 2;
        text-align: center;
        /* text-shadow: none; */
        color: #000;
        font-size: 0.875rem;
        transition: color 300ms linear 100ms;
        height: 100%;
        line-height: var(--tabHeight);
        cursor: pointer;
    }

    &.minimal div {
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        width: auto;
        margin-right: 20px;
        padding: 0px 15px;
        line-height: 36px;
        height: 36px;
    }

    & div.active {
        color: white;
    }

    &.minimal div {
        color: #000;
        text-decoration: none;
        &:after {
            content: "";
            position: absolute;
            left: 0px;
            bottom: -3px;
            width: 100%;
            height: 4px;
            background-color: #898989;
            border-radius: 8px 8px 0px 0px;
            background-color: transparent;
        }
    }

    &.minimal div:hover {
        &:after {
            background-color: ${(p) => p.theme.primaryColorHover};
        }
    }

    &.minimal div.active {
        color: #000;
        /* text-shadow: 1px 0 0 #000; */
        font-weight: 700;
        text-decoration: none;
        &:after {
            content: "";
            position: absolute;
            left: 0px;
            bottom: -3px;
            width: 100%;
            height: 4px;
            background-color: var(--activeColor);
            border-radius: 8px 8px 0px 0px;
        }
    }
`;

const RoundTabs = ({
    tabIndex,
    tabs,
    activeFn,
    activeColor,
    onChange,
    variant,
    language,
    ...rest
}) => {
    let index = tabIndex !== undefined ? tabIndex : -1;

    const handleChange = (ev) => {
        const tgt = ev.target;
        if (tgt.dataset.i !== undefined) {
            index = Number(tgt.dataset.i);
            activeFn && activeFn(tabs[index].activeValue);
            onChange && onChange(index);
        }
    };

    const options = tabs.map((item, idx) => (
        <React.Fragment key={`tab${idx}`}>
            <div data-i={idx} className={index === idx ? "active" : ""}>
                {item.label}
            </div>
        </React.Fragment>
    ));

    return (
        <TabsWrapper
            className={variant}
            index={index}
            onClick={handleChange}
            numTabs={tabs.length}
            activeColor={activeColor}
            {...rest}
        >
            {options}
        </TabsWrapper>
    );
};

const areEqual = (prevProps, nextProps) =>
    prevProps.tabIndex === nextProps.tabIndex && prevProps.language === nextProps.language;

export default memo(RoundTabs, areEqual);
