import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CylinderStockListing from "./components/CylinderStockListing";
import CylinderDetails from "./components/CylinderDetails";
import { LevelWrapper } from "./components/StyledElements";

const ScreeWrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    display: flex;
    flex-wrap: nowrap;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
`;

const CylinderStock = () => {


    const [state, setState] = useState({
        level: 0,
        cylinder: null,
        reset:false
    });

    useEffect(() => {
        document.querySelector(`#lvl${state.level + 1}`).scrollIntoView({
            behavior: "smooth",
        });
    }, [state.level]);

    return (

            <ScreeWrapper>
                <LevelWrapper id="lvl1">
                    <CylinderStockListing
                        onSelect={(cyl) => {
                            setState({
                                ...state,
                                level: 1,
                                cylinder: cyl,
                            });
                        }}
                        reset={state.reset}
                    />
                </LevelWrapper>
                <LevelWrapper id="lvl2">
                    <CylinderDetails
                        onGoBack={(ev) => {
                            ev.preventDefault();
                            ev.stopPropagation();
                            setState((state) => ({ ...state, level: 0 }));
                        }}
                        cylinder={state.cylinder}
                        onGotoStart={() => {
                            setState((prevState) => ({
                                level: 0,
                                cylinder: null,
                                reset:true
                            }));
                        }}
                    />
                </LevelWrapper>
            </ScreeWrapper>

    );
};

export default CylinderStock;
