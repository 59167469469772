import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Label } from "../elements/form-elements";
import { StyledThumbAction } from "../elements/form-elements/GalleryUploader";
import { UploadInputWrapper } from "../elements/form-elements/Uploader";
import { InputWrapper } from "../elements/Stylings";

const Anchor = styled.a`
    color: ${props => props.theme.textUploader};
    text-decoration: none;
`;

const RemoveIcon = styled(StyledThumbAction)`
    border-radius: 16px;

    > span {
        &.visible {
            display: block;
        }
        &.hidden {
            display: none;
        }

`;

const Content = styled.div`
    border-radius: 16px;
    position: relative;

    &:hover, &.should-delete {
        ${RemoveIcon} {
            display: flex;
        }
    }
`;

const Image = styled.div`
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: calc(100% - 10px);
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 16px;
`;

const FileDisplayWithAction = (props) => {
    const {
        fileType = "pdf", fileName, filePath, title, labelText, wrapperVariant, placeholder, handleFileDelete = () => {
        }
    } = props;
    const {t} = useTranslation();

    const [shouldDelete, setShouldDelete] = useState(false);

    const handleRemove = () => {
        setShouldDelete(!shouldDelete);
    }

    useEffect(() => {
        handleFileDelete(shouldDelete)
    }, [shouldDelete]);

    return (
        <InputWrapper className={wrapperVariant}>
            <Label>{labelText}</Label>
            <UploadInputWrapper className={(filePath && fileName) ? 'dragging' : ''}>
                <Content
                    className={`content ${filePath.length === 0 ? "empty" : ""} ${shouldDelete ? 'should-delete' : ''}`}>

                    {
                        fileType === "pdf" ?
                            <>
                                {
                                    title && <div className="title">{title}</div>
                                }
                                {
                                    (placeholder && !filePath) &&
                                    <div className="placeholder">
                                        {placeholder}
                                    </div>
                                }
                                {
                                    (fileName && filePath) &&
                                    <Anchor href={filePath}>
                                        {fileName}
                                    </Anchor>
                                }
                            </>
                            :
                            <Image style={{backgroundImage: `url(${filePath})`}}>

                            </Image>
                    }

                    <div onClick={handleRemove}>
                        <RemoveIcon>
                            <img src="/images/actions/trash.svg" alt="delete"/>
                            <span
                                className={shouldDelete ? 'visible' : 'hidden'}>{t("forms:wizard.undo")}</span>
                        </RemoveIcon>
                    </div>
                </Content>


            </UploadInputWrapper>
        </InputWrapper>
    )
};

export default FileDisplayWithAction;
