import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import DataEntry from "../../../../components/data-entry/DataEntry";
import Anchor from "../../../../components/elements/form-elements/Link";
import EffectError from "../../../../components/errors/EffectError";
import Flex from "../../../../components/layout/flex/Flex";
import LayoutInnerContainer from "../../../../components/layout/split/one/LayoutInnerContainer";
import FullScreenNotification from "../../../../components/notifications/FullScreenNotification";
import helpers from "../../../../core/helpers";
import companyStateModule from "../../../../core/state/company/companyStateModule";
import useBreadcrumbs from "../../../../core/state/navigation/useBreadcrumbs";
import userStateModule from "../../../../core/state/user/userStateModule";
import useStateModule from "../../../../core/state/useStateModule";
import commonValidation from "../../../../core/validations/common";
import ValidationRule, {
    buildValidation,
} from "../../../../core/validations/ValidationRule";
import UserService from "../../../../services/User/UserService";
import { CompanyEditForm } from "./CompanyEditForm";
import { Helmet } from "react-helmet";
import * as yup from "yup";

import ModalDialog, {
    useModal,
} from "../../../../components/modal/ModalDialog";
export const Wrapper = styled.div`
    margin-bottom: 20px;
`;

export const FormWrapper = styled(Flex.Row)`
    width: 800px;
    margin: 30px auto;
`;

const LinerNotesDialog = styled.div`
    margin-top: 10px;
    font-size: 12px;
    font-weight: 300;
    line-height: 1.5;
`;

const getCompanyValidation = (t) => {
    return yup.lazy((values) => {
        return buildValidation({
            email: commonValidation.email(t).rule,
            companyName: ValidationRule.isStringRule().required(
                t("forms:companyName.required")
            ).rule,
            companyNumber: ValidationRule.isStringRule().required(
                "forms:companyNumber.required"
            ).rule,
            vatNumber: commonValidation
                .vatNumber(t)
                .required(t("forms:VAT.required")).rule,
            address: ValidationRule.isStringRule().required(
                t("forms:address.required")
            ).rule,
            contactPersonFirstName: ValidationRule.isStringRule().required(
                t("forms:contactFirstName.required")
            ).rule,
            contactPersonLastName: ValidationRule.isStringRule().required(
                t("forms:contactLastName.required")
            ).rule,
            city: ValidationRule.isStringRule().required(
                t("forms:city.required")
            ).rule,
            postalCode: ValidationRule.isStringRule().required(
                t("forms:postalCode.required")
            ).rule,
            legalRepresentativeRegion: ValidationRule.isStringRule().required(
                t("forms:region.required")
            ).rule,
            legalRepresentativePostalCode:
                ValidationRule.isStringRule().required(
                    t("forms:postalCode.required")
                ).rule,
            legalRepresentativeCity: ValidationRule.isStringRule().required(
                t("forms:city.required")
            ).rule,
            legalRepresentativeAddress: ValidationRule.isStringRule().required(
                t("forms:address.required")
            ).rule,
            legalRepresentativeNationality: ValidationRule.shouldBeEqual(
                "legalRepresentativeNationalitySelected",
                t("forms:nationality.invalid"),
                t("forms:nationality.required")
            ).rule,
            legalRepresentativeCountryOfResidence: ValidationRule.shouldBeEqual(
                "legalRepresentativeCountryOfResidenceSelected",
                t("forms:countryOfResidence.invalid"),
                t("forms:countryOfResidence.required")
            ).rule,
        });
    });
};

const defaultInitialValues = {
    phoneNumber: "",
    address: "",
    postalCode: "",
    city: "",
    countryId: "",
    region: "",
    companyNumber: "",
    contactPersonFirstName: "",
    contactPersonLastName: "",
    email: "",
    companyName: "",
    vatNumber: "",
    legalRepresentativeFirstName: "",
    legalRepresentativeLastName: "",
    legalRepresentativeNationality: "",
    legalRepresentativeNationalitySelected: "",
    legalRepresentativeNationalityId: "",
    legalRepresentativeEmail: "",
    legalRepresentativeCountryOfResidence: "",
    legalRepresentativeCountryOfResidenceId: "",
    legalRepresentativeCountryOfResidenceSelected: "",
    legalRepresentativeBirthDate: "",
    legalRepresentativeAddress: "",
    legalRepresentativeCity: "",
    legalRepresentativePostalCode: "",
    legalRepresentativeRegion: "",
};

const CompanyEditScreen = () => {
    const { t } = useTranslation(["common", "forms"]);
    const yupValidation = getCompanyValidation(t);
    const history = useHistory();
    const { isFullyRegistered, fullyRegister } =
        useStateModule(userStateModule);
    const {
        companyData,
        updateCompany,
        updateCompanyData,
        countryData,
        loadCountries,
        loadCompany,
        resetState,
    } = useStateModule(companyStateModule);

    const userDetails = UserService.getUserDetailsFromToken();
    const userCompanyId = userDetails.CompanyId || null;
    const [companyId] = useState(userCompanyId);

    useEffect(() => {
        if (!companyData.data.id && !companyData.isLoading) {
            loadCompany(companyId);
        }
    }, [companyId, companyData.isLoading, companyData]);

    useEffect(() => {
        loadCountries();
    }, [companyId, loadCountries]);

    let initialValues = helpers.getInitialFormValues(
        defaultInitialValues,
        companyData.data
    );

    initialValues.legalRepresentativeNationalitySelected =
        companyData.data.legalRepresentativeNationality;
    initialValues.legalRepresentativeCountryOfResidenceSelected =
        companyData.data.legalRepresentativeCountryOfResidence;

    const handleCompanySubmit = async (values, formik) => {
        if (IsLegalRepresentativeChanged(values)) {
            toggleOpen({ values, formik });
        } else await finalizeSubmit(values, formik);
    };

    const finalizeSubmit = async (values, formik) => {
        let formData = helpers.getFormData(values, { vatNumber: "VATNumber" });
        formData.delete("Id");
        formData.delete("legalRepresentativeNationalitySelected");
        formData.delete("legalRepresentativeCountryOfResidenceSelected");
        if (IsLegalRepresentativeChanged(values)) {
            toggleOpen({ values, formik });
        }
        await updateCompany(formData);
        formik.setSubmitting(false);
        formik.resetForm({ values: values });
        if (isFullyRegistered) {
            toast.success(t("screens:companyEdit.successfullySaved"));
        }
    };

    const { modalProps, toggleOpen } = useModal({
        title: t("actions:dialogs.changeLegalRepresentative.title"),
        onConfirm: async ({ values, formik }) => {
            await finalizeSubmit(values, formik);
        },
        onCancel: () => {
            toggleOpen();
        },
        confirmText: t("actions:dialogs.changeLegalRepresentative.confirmText"),
    });

    const IsLegalRepresentativeChanged = (newValues) => {
        const { data } = companyData;

        const isChanged =
            newValues.legalRepresentativeBirthDate !==
                data.legalRepresentativeBirthDate ||
            newValues.legalRepresentativeCountryOfResidenceId !==
                data.legalRepresentativeCountryOfResidenceId ||
            newValues.legalRepresentativeEmail !==
                data.legalRepresentativeEmail ||
            newValues.legalRepresentativeFirstName !==
                data.legalRepresentativeFirstName ||
            newValues.legalRepresentativeLastName !==
                data.legalRepresentativeLastName ||
            newValues.legalRepresentativeNationalityId !==
                data.legalRepresentativeNationalityId ||
            newValues.legalRepresentativeAddress !==
                data.legalRepresentativeAddress ||
            newValues.legalRepresentativeCity !==
                data.legalRepresentativeCity ||
            newValues.legalRepresentativePostalCode !==
                data.legalRepresentativePostalCode ||
            newValues.legalRepresentativeRegion !==
                data.legalRepresentativeRegion;
        return isChanged;
    };

    useBreadcrumbs([
        {
            text: t("navigation:company"),
        },
        {
            text: t("navigation:companyDetails"),
        },
    ]);

    if (!companyId) {
        history.push("/");
        return null;
    }

    return (
        <LayoutInnerContainer className="wide">
            <Helmet>
                <title>{t("seo:companyEdit.title")}</title>
            </Helmet>
            <EffectError error={() => {}}>
                <DataEntry
                    autoComplete="off"
                    isLoading={companyData.isLoading}
                    onSubmit={handleCompanySubmit}
                    renderForm={(formik) => (
                        <CompanyEditForm
                            formik={formik}
                            isLoading={
                                companyData.isLoading ||
                                updateCompanyData.isLoading
                            }
                            countryData={countryData}
                            isFullyRegistered={isFullyRegistered}
                            isCompleted={
                                updateCompanyData.isCompleted &&
                                !updateCompanyData.error
                            }
                            fullyRegister={fullyRegister}
                            resetState={resetState}
                        />
                    )}
                    initialValues={initialValues}
                    validationSchema={yupValidation}
                />
            </EffectError>
            {!isFullyRegistered && updateCompanyData.isCompleted && (
                <FullScreenNotification
                    title={t("screens:registration.completedProfile.title")}
                    text={t("screens:registration.completedProfile.text")}
                    button={
                        <Anchor className="primary" to="/">
                            {t("screens:companyEdit.successfullySavedLink")}
                        </Anchor>
                    }
                    refreshToken={true}
                    redirect={true}
                    redirectTo="/"
                />
            )}
            <ModalDialog {...modalProps}>
                <p>{t("actions:dialogs.changeLegalRepresentative.text")}</p>
                <LinerNotesDialog
                    dangerouslySetInnerHTML={{
                        __html: t(
                            "actions:dialogs.changeLegalRepresentative.message"
                        ),
                    }}
                ></LinerNotesDialog>
            </ModalDialog>
        </LayoutInnerContainer>
    );
};
export default CompanyEditScreen;
