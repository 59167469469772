import React from "react";
import DateCellWithExpiration from "../../../../components/data/DateCellWithExpiration";
import TransactionStatus from "../../../../components/elements/general/TransactionStatus";
import HamburgerMenu from "../../../../components/menu/HamburgerMenu";
import HamburgerMenuAction from "../../../../components/menu/HamburgerMenuAction";
import AuthorizeComponent from "../../../../core/AuthorizeComponent";
import OfferStatus from "../../../../core/enums/OfferStatus";
import Roles from "../../../../core/enums/Roles";
import helpers from "../../../../core/helpers";
import GradeCell from "../../../../components/data/GradeCell";
import GreenCell from "../../../../components/data/GreenCell";
import TooltipCell from "../../../../components/data/TooltipCell";
import BidGradePriceCell from "../../../SalesCenter/views/components/BidGradePriceCell";
import HandlingCell from "../../../../components/data/HandlingCell";
import DateCell from "../../../../components/data/DateCell";

const createSteps = (value, t, offerer = "", buyer = "") => {
    if (buyer === "") buyer = t("common:status.theProvider");
    let result = Array.from([
        {
            status: 4,
            title: `${t("common:status.step")}: 1/4 - ${t(
                "common:status.inProgress"
            ).toUpperCase()} `,
            tip: t("common:status.progress_Accept", {
                company: offerer,
            }),
        },
        {
            status: 3,
            title: `${t("common:status.step")}: 2/4 - ${t(
                "common:status.pending"
            ).toUpperCase()} `,
            tip: t("common:status.pending_collect", {
                company: buyer,
            }),
        },
        {
            status: 3,
            title: `${t("common:status.step")}: 3/4 - ${t(
                "common:status.pending"
            ).toUpperCase()} `,
            tip: t("common:status.pending_test", {
                company: buyer,
            }),
        },
        {
            status: 3,
            title: `${t("common:status.step")}: 4/4 - ${t(
                "common:status.pending"
            ).toUpperCase()} `,
            tip: t("common:status.pending_confirmTest", {
                company: offerer,
            }),
        },
    ]);

    switch (value) {
        case OfferStatus.waitingForOffererFunds:
            result[0] = {
                status: 4,
                title: `${t("common:status.step")}: 1/4 - ${t(
                    "common:status.inProgress"
                ).toUpperCase()} `,
                tip: t("common:status.progress_Cdeposit", {
                    company: offerer,
                }),
            };
            break;
        case OfferStatus.disposeAccepted:
            result[0] = {
                status: 1,
                title: `${t("common:status.step")}: 1/4 - ${t(
                    "common:status.completed"
                ).toUpperCase()} `,
                tip: t("common:status.completed_Cdeposit", {
                    company: offerer,
                }),
            };
            result[1] = {
                status: 4,
                title: `${t("common:status.step")}: 2/4 - ${t(
                    "common:status.inProgress"
                ).toUpperCase()} `,
                tip: t("common:status.progress_collect", {
                    company: buyer,
                }),
            };
            break;

        case OfferStatus.buyerClaimedBottles:
            result[0] = {
                status: 1,
                title: `${t("common:status.step")}: 1/4 - ${t(
                    "common:status.completed"
                ).toUpperCase()} `,
                tip: t("common:status.completed_Cdeposit", {
                    company: offerer,
                }),
            };
            result[1] = {
                status: 1,
                title: `${t("common:status.step")}: 2/4 - ${t(
                    "common:status.completed"
                ).toUpperCase()} `,
                tip: t("common:status.completed_collect", {
                    company: buyer,
                }),
            };
            result[2] = {
                status: 4,
                title: `${t("common:status.step")}: 3/4 - ${t(
                    "common:status.inProgress"
                ).toUpperCase()} `,
                tip: t("common:status.progress_test", {
                    company: buyer,
                }),
            };
            break;
        case OfferStatus.qualityTested:
            result[0] = {
                status: 1,
                title: `${t("common:status.step")}: 1/4 - ${t(
                    "common:status.completed"
                ).toUpperCase()} `,
                tip: t("common:status.completed_Cdeposit", {
                    company: offerer,
                }),
            };
            result[1] = {
                status: 1,
                title: `${t("common:status.step")}: 2/4 - ${t(
                    "common:status.completed"
                ).toUpperCase()} `,
                tip: t("common:status.completed_collect", {
                    company: buyer,
                }),
            };
            result[2] = {
                status: 1,
                title: `${t("common:status.step")}: 3/4 - ${t(
                    "common:status.completed"
                ).toUpperCase()} `,
                tip: t("common:status.completed_test", {
                    company: buyer,
                }),
            };
            result[3] = {
                status: 4,
                title: `${t("common:status.step")}: 4/4 - ${t(
                    "common:status.inProgress"
                ).toUpperCase()} `,
                tip: t("common:status.progress_confirmTest", {
                    company: offerer,
                }),
            };
            break;
        case OfferStatus.disposed:
            result[0] = {
                status: 1,
                title: `${t("common:status.step")}: 1/4 - ${t(
                    "common:status.completed"
                ).toUpperCase()} `,
                tip: t("common:status.completed_Cdeposit", {
                    company: offerer,
                }),
            };
            result[1] = {
                status: 1,
                title: `${t("common:status.step")}: 2/4 - ${t(
                    "common:status.completed"
                ).toUpperCase()} `,
                tip: t("common:status.completed_collect", {
                    company: buyer,
                }),
            };
            result[2] = {
                status: 1,
                title: `${t("common:status.step")}: 3/4 - ${t(
                    "common:status.completed"
                ).toUpperCase()} `,
                tip: t("common:status.completed_test", {
                    company: buyer,
                }),
            };
            result[3] = {
                status: 1,
                title: `${t("common:status.step")}: 4/4 - ${t(
                    "common:status.completed"
                ).toUpperCase()} `,
                tip: t("common:status.completed_confirmTest", {
                    company: offerer,
                }),
            };
            break;
        default:
            break;
    }
    return result;
};

const InstaDisposalColumns = {
    getColumns: (
        offerStatus,
        t,
        actions,
        currentLanguage,
        globalState,
        setOpenAccordionId
    ) => {
        const pendingColumns = [
            {
                Header: t("table:headers.gasType"),
                accessor: "refrigerantGasTypeCode",
                Cell: GreenCell,
            },
            {
                Header: t("table:headers.gasQuantity"),
                accessor: "quantity",
                Cell: ({ cell: { value } }) =>
                    GreenCell({ cell: { value: value.toFixed(2) + " kg" } }),
            },
            {
                Header: t("table:headers.grade"),
                accessor: "pressumedGradeName",
                Cell: GradeCell,
            },
            {
                Header: t("table:headers.evaluation"),
                accessor: "analysisDone",
                Cell: ({ cell: { value } }) =>
                    !value
                        ? t("filters:analysis.measurement")
                        : t("filters:analysis.selfAssessment"),
            },
            {
                Header: `${t("table:headers.numBids")}`,
                accessor: "totalOffers",
            },
            {
                Header: t("table:headers.status"),
                accessor: "status",
                disableSortBy: true,
                Cell: ({ cell: { value }, row: { original } }) =>
                    original.totalOffers > 0 && (
                        <TransactionStatus
                            steps={createSteps(
                                value,
                                t,
                                original.offerer,
                                original.acceptedBid?.company.companyName
                            )}
                        />
                    ),
            },
            {
                Header: "",
                accessor: "offerId",
                Cell: ({ cell: { value }, row: { original } }) => (
                    <AuthorizeComponent
                        roles={[
                            Roles.InstallerCompanyAdmin,
                            Roles.InstallerCompanyUser,
                        ]}
                    >
                        <HamburgerMenu>
                            <HamburgerMenuAction
                                text={t("actions:unpublishFromMarketplace")}
                                onClick={async () => {
                                    actions.toggleOpen({
                                        offerIds: [value],
                                        original,
                                    });
                                }}
                            />
                        </HamburgerMenu>
                    </AuthorizeComponent>
                ),
            },
        ];

        const acceptedColumns = [
            {
                Header: t("table:headers.gasType"),
                accessor: "refrigerantGasTypeCode",
                Cell: GreenCell,
            },
            {
                Header: t("table:headers.gasQuantity"),
                accessor: "quantity",
                Cell: ({ cell: { value } }) =>
                    GreenCell({ cell: { value: value.toFixed(2) + " kg" } }),
            },
            {
                Header: t("table:headers.grade"),
                accessor: "pressumedGradeName",
                Cell: GradeCell,
            },
            {
                Header: t("table:headers.evaluation"),
                accessor: "analysisDone",
                Cell: ({ cell: { value } }) =>
                    !value
                        ? t("filters:analysis.measurement")
                        : t("filters:analysis.selfAssessment"),
            },
            {
                Header: `${t("table:headers.bidAmounts")}/${t(
                    "sidebar:label.extraction.qualityGrade"
                )}`,
                accessor: "price",
                Cell: BidGradePriceCell,
            },
            {
                Header: t("table:headers.handlingCosts"),
                accessor: "acceptedBidInfo",
                Cell: ({ cell: { value } }) =>
                    HandlingCell(
                        value?.handlingCosts || 0,
                        setOpenAccordionId,
                        "bid.details"
                    ),
            },
            {
                Header: t("table:headers.purchasePrice"),
                accessor: "confirmedQuality",
                Cell: ({ cell: { row } }) => {
                    if (row.original.acceptedBidInfo) {
                        const currency = "EUR";
                        let totalPrice = helpers.calculatePurchasedPrice(
                            row.original.acceptedBidInfo.offeredPriceGradeC,
                            row.original.quantity,
                            true
                        );

                        return TooltipCell({
                            cell: {
                                value: helpers.formatCurrency(
                                    totalPrice,
                                    currentLanguage,
                                    currency
                                ),
                            },
                            tip: t("table:tooltips.withVat"),
                        });
                    }

                    return "-";
                },
            },
            {
                Header: t("stockListing.headers.dealExpiration"),
                accessor: "dealExpiration",
                Cell: (value) => {
                    let translation = "";
                    const offerStatus = value.row.original.status;
                    if (offerStatus === 2) {
                        translation = "screens:myOffers.offerStatus.2";
                    } else if (offerStatus === 3) {
                        translation = "screens:myOffers.offerStatus.3";
                    } else if (offerStatus === 4) {
                        translation = "screens:myOffers.offerStatus.4";
                    }
                    return DateCellWithExpiration(value, translation);
                },
            },
            {
                Header: t("table:headers.status"),
                accessor: "status",
                disableSortBy: true,
                Cell: ({ cell: { value }, row: { original } }) => (
                    <TransactionStatus
                        steps={createSteps(
                            value,
                            t,
                            original.offerer,
                            original.acceptedBid?.company.companyName
                        )}
                    />
                ),
            },
            {
                Header: "",
                accessor: "offerId",
                Cell: ({ cell: { value }, row: { original } }) => {
                    const dateTimeNow = new Date();
                    return (
                        <AuthorizeComponent
                            roles={[
                                Roles.InstallerCompanyAdmin,
                                Roles.InstallerCompanyUser,
                            ]}
                        >
                            <HamburgerMenu>
                                <HamburgerMenuAction
                                    disabled={
                                        original.dealExpiration >
                                        dateTimeNow.toISOString()
                                    }
                                    text={t("actions:rejectBid")}
                                    onClick={async () => {
                                        actions.rejectToggleOpen([original]);
                                    }}
                                />
                                {original.status === 2 && (
                                    <HamburgerMenuAction
                                        text={t(
                                            "actions:dialogs.transactionDetails.title"
                                        )}
                                        onClick={async () => {
                                            await actions.getTransactionDetails(
                                                original,
                                                original.acceptedBid.id
                                            );
                                        }}
                                    />
                                )}
                            </HamburgerMenu>
                        </AuthorizeComponent>
                    );
                },
            },
        ];

        const qualityTestColumns = [
            {
                Header: t("table:headers.gasType"),
                accessor: "refrigerantGasTypeCode",
                Cell: GreenCell,
            },
            {
                Header: t("table:headers.gasQuantity"),
                accessor: "quantity",
                Cell: ({ cell: { value } }) =>
                    GreenCell({ cell: { value: value.toFixed(2) + " kg" } }),
            },
            {
                Header: t("table:headers.grade"),
                accessor: "pressumedGradeName",
                Cell: GradeCell,
            },
            {
                Header: t("table:headers.evaluation"),
                accessor: "analysisDone",
                Cell: ({ cell: { value } }) =>
                    !value
                        ? t("filters:analysis.measurement")
                        : t("filters:analysis.selfAssessment"),
            },
            {
                Header: t("table:headers.purchasePrice"),
                accessor: "disposalServicePrice",
                Cell: ({ cell: { row } }) => {
                    const currency = "EUR";
                    return TooltipCell({
                        cell: {
                            value: helpers.formatCurrency(
                                helpers.calculatePriceWithVat(
                                    row.original.acceptedBidInfo
                                        ?.offeredPriceGradeC *
                                        row.original.quantity
                                ),
                                currentLanguage,
                                currency
                            ),
                        },
                        tip: t("table:tooltips.withVat"),
                    });
                },
            },
            {
                Header: t("myBids.headers.bidDealExpiration"),
                accessor: "dealExpiration",
                Cell: (value) => {
                    let translation = "";
                    const offerStatus = value.row.original.status;
                    if (offerStatus === 2) {
                        translation = "screens:myOffers.offerStatus.2";
                    } else if (offerStatus === 3) {
                        translation = "screens:myOffers.offerStatus.3";
                    } else if (offerStatus === 4) {
                        translation = "screens:myOffers.offerStatus.4";
                    }
                    const onHold =
                        offerStatus === 10 ? t("common:status.onHold") : null;
                    return DateCellWithExpiration(value, translation, onHold);
                },
            },
            {
                Header: t("table:headers.status"),
                accessor: "status",
                disableSortBy: true,
                Cell: ({ cell: { value }, row: { original } }) => (
                    <TransactionStatus
                        inDispute={
                            original.status === 10
                                ? t("common:status.inDispute")
                                : null
                        }
                        steps={createSteps(
                            value,
                            t,
                            original.offerer,
                            original.acceptedBid?.company.companyName
                        )}
                    />
                ),
            },
            {
                Header: "",
                accessor: "offerId",
                Cell: ({ cell: { value }, row: { original } }) => {
                    const dateTimeNow = new Date();
                    return (
                        <AuthorizeComponent
                            roles={[
                                Roles.InstallerCompanyAdmin,
                                Roles.InstallerCompanyUser,
                            ]}
                        >
                            <HamburgerMenu>
                                <HamburgerMenuAction
                                    disabled={
                                        !(
                                            original.status ===
                                                OfferStatus.qualityTested ||
                                            (original.status ===
                                                OfferStatus.buyerClaimedBottles &&
                                                original.dealExpiration <
                                                    dateTimeNow.toISOString())
                                        ) ||
                                        (original.status ===
                                            OfferStatus.qualityTested &&
                                            !globalState.hasCertificateRights &&
                                            original.dealExpiration >
                                                dateTimeNow.toISOString())
                                    }
                                    text={t("actions:finishTransaction")}
                                    onClick={async () => {
                                        actions.sellToggleOpen([original]);
                                    }}
                                />
                                <HamburgerMenuAction
                                    disabled={
                                        !(
                                            original.status ===
                                                OfferStatus.qualityTested ||
                                            (original.status ===
                                                OfferStatus.buyerClaimedBottles &&
                                                original.dealExpiration <
                                                    dateTimeNow.toISOString())
                                        )
                                    }
                                    text={t("actions:dispute.start")}
                                    onClick={async () => {
                                        actions.openDisputeWizard(original);
                                    }}
                                />
                            </HamburgerMenu>
                        </AuthorizeComponent>
                    );
                },
            },
        ];

        const soldColumns = [
            {
                Header: t("table:headers.gasType"),
                accessor: "confirmedRefrigerantGasTypeCode",
                Cell: GreenCell,
            },
            {
                Header: t("table:headers.gasQuantity"),
                accessor: "confirmedQuantity",
                Cell: ({ cell: { value } }) =>
                    GreenCell({ cell: { value: value?.toFixed(2) + " kg" } }),
            },
            {
                Header: t("table:headers.grade"),
                accessor: "confirmedGradeName",
                Cell: GradeCell,
            },
            {
                Header: t("table:headers.evaluation"),
                accessor: "analysisDone",
                Cell: ({ cell: { value } }) => (value ? "Yes" : "No"),
            },
            {
                Header: `${t("table:headers.bidAmounts")}/${t(
                    "sidebar:label.extraction.qualityGrade"
                )}`,
                accessor: "price",
                Cell: BidGradePriceCell,
            },
            {
                Header: t("table:headers.handlingCosts"),
                accessor: "acceptedBidInfo",
                Cell: ({ cell: { value } }) =>
                    HandlingCell(
                        value?.handlingCosts || 0,
                        setOpenAccordionId,
                        "bid.details"
                    ),
            },
            {
                Header: t("table:headers.purchasePrice"),
                accessor: "confirmedQuality",
                Cell: ({ cell: { row } }) => {
                    if (row.original.acceptedBidInfo) {
                        const currency = "EUR";
                        let totalPrice = helpers.calculatePurchasedPrice(
                            row.original.acceptedBidInfo.offeredPriceGradeC,
                            row.original.quantity,
                            true
                        );

                        return TooltipCell({
                            cell: {
                                value: helpers.formatCurrency(
                                    totalPrice,
                                    currentLanguage,
                                    currency
                                ),
                            },
                            tip: t("table:tooltips.withVat"),
                        });
                    }

                    return "-";
                },
            },
            {
                Header: t("table:headers.disposedDate"),
                accessor: "lastChangeDateTime",
                Cell: DateCell,
            },
            {
                Header: t("table:headers.status"),
                accessor: "status",
                disableSortBy: true,
                Cell: ({ cell: { value }, row: { original } }) => (
                    <TransactionStatus
                        steps={createSteps(
                            value,
                            t,
                            original.offerer,
                            original.acceptedBid?.company.companyName
                        )}
                    />
                ),
            },
        ];
        if (
            offerStatus.includes(OfferStatus.offererEscrowedFunds) ||
            offerStatus.includes(OfferStatus.disposeAccepted)
        ) {
            return acceptedColumns;
        } else if (
            offerStatus.includes(OfferStatus.buyerClaimedBottles) ||
            offerStatus.includes(OfferStatus.qualityTested)
        ) {
            return qualityTestColumns;
        } else if (offerStatus.includes(OfferStatus.disposed)) {
            return soldColumns;
        }

        return pendingColumns;
    },
};

export default InstaDisposalColumns;
