import React, { useCallback, useEffect, useMemo } from "react";
import globals from "../../../globalVars";
import { useTranslation } from "react-i18next";
import ListingPage from "../../../components/data/ListingPage";
import clientsUnitsListStateModule from "../../../core/state/clients/clientsUnitsListStateModule";
import useStateModule from "../../../core/state/useStateModule";
import useImmerReducer from "../../../hooks/_shared/useImmerReducer";
import usePageChange from "../../../hooks/_shared/usePageChange";
import { HR, ListTitle, ScreenHeader, ScreenWrapper } from "./StyledElements";
import { StyledArrow, StyledBack } from "./StyledSvg";

const actionTypes = {
    SET_MANUFACTURER_FILTER: "SET_MANUFACTURER_FILTER",
    RESET_MANUFACTURER_FILTER: "RESET_MANUFACTURER_FILTER",

    SET_APPLICATION_TYPE_FILTER: "SET_APPLICATION_TYPE_FILTER",
    RESET_APPLICATION_TYPE_FILTER: "RESET_APPLICATION_TYPE_FILTER",

    SET_GAS_TYPE: "SET_GAS_TYPE",
    RESET_GAS_TYPE: "RESET_GAS_TYPE",

    SET_CLIENT_ID: "SET_CLIENT_ID",
    RESET_CLIENT_ID: "RESET_CLIENT_ID",

    SET_SORT: "SET_SORT",
    RESET_SORT: "RESET_SORT",

    SET_PAGING: "SET_PAGING",
    SET_LOCATION_ID: "SET_LOCATION_ID",
    RESET_LOCATION_ID: "RESET_LOCATION_ID",
};

const unitsReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.SET_PAGING:
            state.pageSize = action.payload.pageSize;
            state.page = action.payload.pageIndex + 1;
            break;
        case actionTypes.SET_SORT:
            state.orderColumn = action.payload[0].id;
            state.descending = action.payload[0].desc;
            break;
        case actionTypes.RESET_SORT:
            state.orderColumn = "";
            state.descending = true;
            break;
        case actionTypes.SET_LOCATION_ID:
            state.filter.locationId = action.payload;
            break;
        case actionTypes.RESET_LOCATION_ID:
            delete state.filter.clientId;
            break;
        case actionTypes.SET_MANUFACTURER_FILTER:
            state.filter.manufacturer = action.payload;
            break;
        case actionTypes.RESET_MANUFACTURER_FILTER:
            delete state.filter.manufacturer;
            break;
        case actionTypes.SET_APPLICATION_TYPE_FILTER:
            state.filter.applicationType = action.payload;
            break;
        case actionTypes.RESET_APPLICATION_TYPE_FILTER:
            delete state.filter.applicationType;
            break;
        case actionTypes.SET_GAS_TYPE:
            state.filter.refrigerantId = action.payload;
            break;
        case actionTypes.RESET_GAS_TYPE:
            delete state.filter.refrigerantId;
            break;
        default:
            return state;
    }
};

const LocationUnitListing = (props) => {
    const { t } = useTranslation("screens");

    const {
        clientsLocationUnits,
        isLoading,
        error,
        loadClientsLocationUnits,
    } = useStateModule(clientsUnitsListStateModule);

    const [unitsState, unitsDispatch] = useImmerReducer(unitsReducer, {
        filter: { locationId: null },
        page: 1,
        pageSize: globals.maxListingItemsPerPage,
        orderColumn: "manufacturer",
        descending: false,
    });

    useEffect(() => {
        unitsState.filter.locationId = props.locationId;
        unitsState.filter.locationId && loadClientsLocationUnits(unitsState);
    }, [loadClientsLocationUnits, unitsState, props.locationId]);

    const columns = useMemo(() => {
        const columns = [
            {
                Header: t("table:headers.client.units.manufacturer"),
                accessor: "manufacturerName",
            },
            {
                Header: t("table:headers.client.units.application"),
                accessor: "applicationTypeName",
            },
            {
                Header: t("table:headers.client.units.refrigerantName"),
                accessor: "refrigerantName",
            },
            {
                Header: "",
                accessor: "unitId",
                className: "right-arrow",
                Cell: ({ cell: { value } }) => <StyledArrow />,
                disableSortBy: true,
            },
        ];
        return columns;
    }, [unitsState.filter, t]);

    const onSelect = useCallback((row) => {
        props.onSelect && props.onSelect(row.original);
    }, []);

    const unitsPageChange = usePageChange(unitsDispatch, unitsState);

    const sortUnits = (sortBy) => {
        sortBy.length > 0
            ? unitsDispatch({ type: actionTypes.SET_SORT, payload: sortBy })
            : unitsDispatch({ type: actionTypes.RESET_SORT });
    };

    const currentUnitsSortBy = useMemo(() => {
        return [
            {
                id: unitsState.orderColumn,
                desc: unitsState.descending,
            },
        ];
    });

    return (
        <ScreenWrapper>
            <ScreenHeader>
                <StyledBack
                    onClick={(ev) => {
                        props.onGoBack(ev);
                    }}
                />
            </ScreenHeader>
            <HR />
            <ListTitle>{t("screens:clients.units.text")}</ListTitle>
            <ListingPage
                pwa
                noHeader
                columns={columns}
                tableState={{
                    data: clientsLocationUnits.data,
                    loading: isLoading,
                    error: error,
                }}
                paging={{
                    enablePaging: true,
                    pageCount: clientsLocationUnits.totalPages,
                    pageSize: clientsLocationUnits.pageSize,
                    onPageChange: unitsPageChange,
                    currentPageIndex: clientsLocationUnits.currentPage - 1,
                }}
                sorting={{
                    enableSorting: true,
                    onSortByChange: sortUnits,
                    currentSortBy: currentUnitsSortBy,
                }}
                onSelect={onSelect}
                noDataText={t("screens:stockListing.notification")}
            />
        </ScreenWrapper>
    );
};

export default LocationUnitListing;
