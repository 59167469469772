import React, { useState } from "react";
import styled from "styled-components";
import { GetShortDate } from "../../../../components/data/DateCell";
import Flex from "../../../../components/layout/flex/Flex";
import DataTable from "../../../../components/data/DataTable";
import { useTranslation } from "react-i18next";
import TransactionFlowType from "../../../../core/enums/TransactionFlowType";

const Accordion = styled.p`
    margin: 0 0 4px 0;
    padding: 0 0 0 20px;
    position: relative;
    cursor: pointer;
    color: ${(p) => p.theme.secondaryColor};
    font-size: ${(p) => (p.titleVariant === "small" ? "13px" : "1.25rem")};

    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: ${(p) => (p.titleVariant === "small" ? "0px" : "7px")};
        width: 8px;
        height: 12px;
        background: url("/images/actions/arrow-blue.svg") no-repeat top left;
        background-size: 8px 12px;
        transform: rotate(-180deg);
    }

    &.isOpened {
        &:before {
            transform: rotate(-90deg);
        }
    }

    &.disabled:before {
        content:none;
    }
`;

const Container = styled(Flex.Column)`
    width: 100%;
`;

const Cell = styled.div`
    padding: 15px 0px 15px 10px;
    width: 25%;
`;

const HighlightCell = styled(Cell)`
    font-weight: 700;
`;

const RowWrapper = styled(Flex.Row)`
    background-color: rgb(142, 142, 142, 0.1);
    display: flex;
    border-radius: 8px;
    margin-top: 2px;
    cursor: pointer;
`;

const AccordionWrapper = styled.div`
    padding: 0 0 24px 20px;
    margin-top: 10px;
`;

const TransactionRow = ({ item, columns, isOpen, onSelect, onRowClick }) => {
    const { t } = useTranslation();

    const [accordionIsActive, setAccordionIsActive] = useState(isOpen);

    const toggleAccordion = () => {
        if (columns) setAccordionIsActive(!accordionIsActive);
        if (onRowClick) onRowClick(item);
    };

    const GroupParent = () => {
        let parentType = t(
            "table:headers.transactions.parentType." + item.parentType + ""
        );

        return `${parentType}: ${item.id}`;
    };

    return (
        <Container>
            <RowWrapper onClick={toggleAccordion}>
                <AccordionWrapper>
                    <Accordion
                        className={accordionIsActive ? "isOpened" : columns ? "" : "disabled"}
                    ></Accordion>
                </AccordionWrapper>

                <HighlightCell
                    style={{ paddingLeft: 4, textTransform: "uppercase" }}
                >
                    {GroupParent()}
                </HighlightCell>

                <Cell style={{ flex: 1 }}>
                    {t(
                        `filters:transactions.flowType.${TransactionFlowType[item.flowType]
                        }`
                    )}
                </Cell>

                <Cell style={{}}>
                    {`${item.isExecuted
                        ? t(
                            "screens:transactions.myTransactions.executedOn"
                        )
                        : t(
                            "screens:transactions.myTransactions.lastChangeOn"
                        )
                        }: ${GetShortDate(item.date)}`}
                </Cell>
            </RowWrapper>
            {accordionIsActive && columns && (
                <DataTable
                    columns={columns}
                    data={item.transactions}
                    wrapperVariant="sub-table"
                    onSelect={onSelect}
                />
            )}
        </Container>
    );
};

export default TransactionRow;
