const MangoTransferType = {
    "1":"BuyerPaysForAorBClass",
    "2":"BuyerGetsReservationForAClassBack",
    "3":"BuyerGetsLeftoverReservedMoneyBack",
    "4":"SellerGetsBackReservationForCClass",
    "5":"SellerPaysForCClass",
    "6":"SellerGetsLeftoverReservedMoneyBack",
    "7":"BuyerPays30PercentForAorBClass",
    "9": "BuyerPays70PercentForAorBClassBack",
    "10": "HandlingCosts",
    "13":"ReclaimedOfferPayment",
    "20": "FeeTransfer",
    "25": "HandlingCostsFee",
    "30":"DepositForAClass",
    "31":"DepositForCClass",
    "32":"DepositForCleaningService",
    "33":"SecurityDepositForCleaningService",
    "34":"CleaningServicePurchase",
    "35": "ReturnOfSecurityDeposit",
    "36": "DepositForCClassWithHandling",
    "37": "DepositForCleaningServiceWithHandling",
    "40":"TransferToIBAN",
    "50":"DepositToMainWallet",
    "60":"AutomaticPayment"
};


export default MangoTransferType;
