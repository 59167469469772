import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import useClickAway from "../../../../hooks/_shared/useClickAway";

const DaikinInfo = styled.div`
    font-size: 14px;
    color: #898989;
    text-align: center;
    margin-bottom: 10px;

    @media ${(p) => p.theme.device.mobile} {
        font-size: 10px;
    }
`;

const LangWrapper = styled.div`
    margin: 0 0px 0 30px;
    @media ${(p) => p.theme.device.mobile} {
        margin: 0 auto;
    }
    &.centered {
        margin: 0 auto;
    }
`;

const Action = styled.div`
    background: transparent;
    height: auto;
    padding: 8px 32px 6px 30px;
    border-radius: 16px;
    margin: 0;
    position: relative;
    cursor: pointer;
    font-size: 14px;

    &.pwa {
        position: absolute;
        bottom: 24px;
        left: 50%;
        transform: translate(-50%, 0);
    }

    &.bottom {
        padding: 10px 0px;
        margin: 0 auto;
        width: fit-content;
        &:after {
            right: -20px;
            top: 8px;
        }
    }

    &:after {
        content: "";
        background: url("/images/menu/expand_thin.svg") no-repeat center;
        background-size: 12px 10px;
        color: ${(props) => props.theme.textWhite};
        position: absolute;
        right: 5px;
        top: 6px;
        width: 20px;
        height: 20px;
        text-align: center;
    }

    &:hover span.current{
        color: ${(p) => p.theme.primaryColorHover};
    }

    &.opened {
        &:after {
            transform: rotate(-180deg);
        }
    }
`;

const Menu = styled.div`
    position: absolute;
    top: calc(100% + 10px);
    right: 0;
    border-radius: ${(props) => props.theme.inputBorderRadius};
    max-height: 1000px;
    transition: all 0.3s ease-in;
    opacity: 0;
    background: ${(props) => props.theme.bgWhite};
    text-align: left;
    padding: 8px 24px 24px;
    visibility: hidden;
    box-shadow: 4px 4px 16px 0px rgba(0, 0, 0, 0.16);

    &.bottom {
        top: auto;
        bottom: calc(100% + 10px);
        right: -20px;
    }

    &.opened {
        opacity: 1;
        visibility: visible;
        z-index: 100;
    }
    & ul {
        list-style: none;
        margin: 0;
        padding: 0;
        word-break: keep-all;
        white-space: nowrap;
    }
    & li {
        list-style: none;
        padding: 15px 5px;
        border-bottom: 1px solid
            ${(props) => props.theme.primaryInputBorderColor};
        cursor: auto;
        &:last-child {
            border-bottom: none;
            padding-bottom: 0;
            border-top: 1px solid
                ${(props) => props.theme.primaryInputBorderColor};
        }
    }

    & li:hover {
        color: ${(props) => props.theme.primaryColorHover};
        cursor: pointer;
    }
`;

const languages = [
    { name: "english", code: "en" },
    { name: "slovak", code: "sk" },
    { name: "hungarian", code: "hu" },
    { name: "czech", code: "cz" },
];

const LanguageSwitch = ({ isInHeader, className, info, centered }) => {
    const { i18n, t } = useTranslation();
    const [menuIsOpened, setMenuIsOpened] = useState(false);
    const menuRef = useRef(null);
    useClickAway(menuRef, () => {
        setMenuIsOpened(false);
    });

    const getCurrentLangName = () => {
        let res = languages.filter((lng) => lng.code === i18n.language);
        return res[0].name;
    };

    const handleToggle = () => {
        setMenuIsOpened(!menuIsOpened);
    };

    const handleSelect = (code) => {
        i18n.changeLanguage(code);
        setMenuIsOpened(false);
    };

    let actionClass = menuIsOpened ? "opened" : "";
    if (!isInHeader) actionClass += " bottom";
    actionClass +=` ${className}`;
    
    return (
        <LangWrapper className={centered ? "centered": ""}>
            {info && <DaikinInfo>{info}</DaikinInfo>}
            <Action
                ref={menuRef}
                onClick={handleToggle}
                className={actionClass}
            >
                <span className="current">
                    {t(`actions:language.${getCurrentLangName()}`)}
                </span>
                <Menu className={actionClass}>
                    <ul>
                        {languages.map((lng, idx) => (
                            <li
                                key={idx}
                                onClick={() => handleSelect(lng.code)}
                            >
                                {t(`actions:language.${lng.name}`)}
                            </li>
                        ))}
                    </ul>
                </Menu>
            </Action>
        </LangWrapper>
    );
};

export default LanguageSwitch;
