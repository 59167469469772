import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Roles from "../../../../core/enums/Roles";
import RouteCreator from "../../../../core/RouteCreator";
import { NavLink } from "react-router-dom";
import { ReactComponent as CertValid } from "../../../../assets/haveCert.svg";
import { ReactComponent as CertInvalid } from "../../../../assets/noCert.svg";
import Tooltip from "../../../../components/tooltip/Tooltip";
import useSharedState from "../../../../hooks/_shared/useSharedState";
import useAuthorization from "../../../../core/useAuthorization";

const Link = styled(NavLink)`
    color: ${(p) => p.theme.primaryColorHover};
    text-decoration: none;
    &:hover {
        color: ${(p) => p.theme.primaryColorHover};
    }

    font-weight: bold;
    cursor: pointer;
    font-size: 14px;
`;

const CertWrapper = styled.div`
    margin: 6px 24px 0px 0px;
    @media ${(p) => p.theme.device.mobile} {
        margin: 0 15px 0 auto;
    }
    height: 36px;
    &.invalid {
        margin-top:12px;
    }
`;

const StatusAction = styled.div`
    background: transparent;
    margin: 0;
    position: relative;
`;

const CertSemaphore = () => {
    const { t } = useTranslation("header");
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [globalState] = useSharedState("global", null, "CertSemaphore");
    const canAccess = useAuthorization({
        allowedRoles: [Roles.InstallerCompanyAdmin, Roles.InstallerCompanyUser],
    });

    const isValid = globalState.hasValidCompanyCertificate;
    let statusText = `${t("navigation:certStatus")}:`.toUpperCase();
    statusText += isValid
        ? t("navigation:verified")
        : t("navigation:notVerified");
    const toolTipText = statusText;

    const handleClick = (ev) => {
        if (!canAccess) ev.preventDefault();
    };

    return (
        <CertWrapper className={isValid ? "":"invalid"}>
            <StatusAction>
                <Link
                    onClick={handleClick}
                    to={canAccess ? RouteCreator.company.certificates() : ""}
                    onMouseEnter={() => setTooltipVisible(true)}
                    onMouseLeave={() => setTooltipVisible(false)}
                >
                    {isValid ? (
                        <CertValid style={{ width: "auto", height: "30px", marginTop:"2px" }} />
                    ) : (
                        <CertInvalid
                            style={{ width: "auto", height: "34px" }}
                        />
                    )}
                    <Tooltip visible={tooltipVisible} tooltip={toolTipText} />
                </Link>
            </StatusAction>
        </CertWrapper>
    );
};
export default CertSemaphore;
