import React, { useEffect, useMemo, useCallback } from "react";
import globals from "../../../globalVars";
import styled from "styled-components";
import ListingPage from "../../../components/data/ListingPage";
import useStateModule from "../../../core/state/useStateModule";
import { useTranslation } from "react-i18next";
import useImmerReducer from "../../../hooks/_shared/useImmerReducer";
import usePageChange from "../../../hooks/_shared/usePageChange";
import clientsLocationListStateModule from "../../../core/state/clients/clientsLocationsListStateModule";

import {
    StyledArrow,
    StyledBack,
} from "./StyledSvg";

import {
    ScreenWrapper,
    ScreenHeader,
    ListTitle,
    HR,
} from "./StyledElements";

const Address = styled.div`
    & > div {
        font-weight:600;
    }
    & > span {
       margin-right: 4px;
    }
`;

const actionTypes = {
    SET_PAGING: "SET_PAGING",
    SET_SORT: "SET_SORT",
    RESET_SORT: "RESET_SORT",
    SET_FILTERS: "SET_FILTERS",
    RESET_FILTERS: "RESET_FILTERS",
};


const locationsReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.SET_PAGING:
            state.pageSize = action.payload.pageSize;
            state.page = action.payload.pageIndex + 1;
            break;
        case actionTypes.SET_SORT:
            state.orderColumn = action.payload[0].id;
            state.descending = action.payload[0].desc;
            break;
        case actionTypes.RESET_SORT:
            state.orderColumn = "";
            state.descending = true;
            break;
        default:
            return state;
    }
};

const ClientLocationListing = (props) => {
    const { t } = useTranslation("screens");
    const {
        clientsLocations,
        isLoading,
        error,
        loadClientsLocations,

    } = useStateModule(clientsLocationListStateModule);

    const [locationsState, locationsDispatch] = useImmerReducer(
        locationsReducer,
        {
            filter: {
                clientId:  null,
            },
            page: 1,
            pageSize: globals.maxListingItemsPerPage,
            orderColumn: "locationName",
            descending: false,
        }
    );

    useEffect(() => {
        locationsState.filter.clientId = props.clientId;
        locationsState.filter.clientId && loadClientsLocations(locationsState);
    }, [loadClientsLocations, locationsState, props.clientId]);

    const columns = useMemo(() => {
        const columns = [

            {
                Header: t("screens:clients.locations.address"),
                accessor: "locationName",
                Cell: ({row}) => {
                    const data = row.original;
                    return (
                        <Address
                        >
                            <div>{data.locationName}</div>
                            <span>{data.address}</span>
                            <span>{data.postalCode}</span>
                            <span>{data.city}</span>
                        </Address>
                    );
                },
            },
            {
                Header: t("screens:clients.locations.units"),
                accessor: "units",
                disableSortBy: true,
            },
            {
                Header: "",
                accessor: "locationId",
                className: "right-arrow",
                Cell: ({ cell: { value } }) => <StyledArrow />,
                disableSortBy: true,
            },
        ];
        return columns;
    }, [locationsState.filter, t]);


    const onSelect = useCallback((row) => {
        if(row.original.units === 0) return;
        props.onSelect && props.onSelect(row.original);
    }, []);

    const locationsPageChange = usePageChange(
        locationsDispatch,
        locationsState
    );

    const currentLocationsSortBy = useMemo(() => {
        return [
            {
                id: locationsState.orderColumn,
                desc: locationsState.descending,
            },
        ];
    }, [locationsState.orderColumn, locationsState.descending]);

    const sortLocations = (sortBy) => {
        sortBy.length > 0
            ? locationsDispatch({ type: actionTypes.SET_SORT, payload: sortBy })
            : locationsDispatch({ type: actionTypes.RESET_SORT });
    };


    return (
        <ScreenWrapper>
            <ScreenHeader>
                <StyledBack onClick={(ev) => {
                            props.onGoBack(ev);
                        }} />
            </ScreenHeader>
            <HR />
            <ListTitle>{t("screens:clients.locations.text")}</ListTitle>
            <ListingPage
                noHeader
                pwa
                columns={columns}
                tableState={{
                    data: clientsLocations.data,
                    loading: isLoading,
                    error: error,
                }}
                paging={{
                    enablePaging: true,
                    pageCount: clientsLocations.totalPages,
                    pageSize: clientsLocations.pageSize,
                    onPageChange: locationsPageChange,
                    currentPageIndex: clientsLocations.currentPage - 1,
                }}
                sorting={{
                    enableSorting: true,
                    onSortByChange: sortLocations,
                    currentSortBy: currentLocationsSortBy,
                }}
                onSelect={onSelect}
                noDataText={t("screens:stockListing.notification")}
            />
        </ScreenWrapper>
    );
};

export default ClientLocationListing;
