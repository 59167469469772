import React from "react";
import Wrappers from "../../../components/wrappers/Wrappers";
import InputElement from "../../../components/elements/form-elements/InputElement";
import { useTranslation } from "react-i18next";
import { InputSubmit } from "../../../components/elements/form-elements";
import Flex from "../../../components/layout/flex/Flex";

const UserPasswordForm = ({ formik, isLoading, isCompleted }) => {
    const { t } = useTranslation();
    return (
        <Wrappers.FormWrapper variant="nomargin">
            <Flex.Container>
                <Flex.Column style={{ width: "520px" }}>
                    <Flex.Container flexWrap="wrap">
                        <InputElement
                            labelText={t("forms:password.text")}
                            id="password"
                            name="password"
                            type="password"
                            wrapperVariant="flex-half"
                            {...formik}
                        />
                        <InputElement
                            labelText={t("forms:repeatPassword.text")}
                            name="repeatPassword"
                            id="repeatPassword"
                            type="password"
                            wrapperVariant="flex-half"
                            {...formik}
                        />
                    </Flex.Container>
                </Flex.Column>
            </Flex.Container>
            <Wrappers.Wrapper className="buttons-wrapper mt-0">
                <Flex.Row>
                    <InputSubmit
                        disabled={isLoading}
                        variant="link"
                        value={t("screens:userPassword.button_text")}
                    />
                </Flex.Row>
            </Wrappers.Wrapper>
        </Wrappers.FormWrapper>
    );
};

export default UserPasswordForm;
