import React from "react";
import { useTranslation } from "react-i18next";

const CertificateStatusCell = ({ cell: { value } }) => {
    const { t } = useTranslation("common");
    if (value) {
        return <span key={value}>{t("certificateStatus." + value)}</span>;
    }
    return null;
};
export default CertificateStatusCell;
