import ClientsService from "../../../services/Clients/ClientsService";
import createListStateModule from "../createListStateModule";
import countriesStatePlugin from "../plugins/countriesStatePlugin";

const clientsListStateModule = createListStateModule({
    stateNamespace: "clients",
    itemsPropertyName: "clients",
    loadItemsMethodName: "loadClients",
    itemsDataMethod: ClientsService.getClients,
    loadDetailMethodName: "loadClientById",
    detailDataMethod: async (id) => {
        const clients = await ClientsService.getClientById(id);
        return clients
    },
    detailsPropertyName: "clientsData",
    deleteDataMethod: ClientsService.deleteClient,
    deleteMethodName: "deleteClient",
    updateDataMethod: ClientsService.updateClient,
    updateMethodName: "updateClient",
    createDataMethod: ClientsService.createClient,
    createMethodName: "createClientItem",
    defaultItemsData: {data: []},
    plugins: [countriesStatePlugin]
});

export default clientsListStateModule;
