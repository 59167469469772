import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import styled from "styled-components";
import DataTable from "../../../../components/data/DataTable";
import Button from "../../../../components/elements/buttons/Button";
import Flex from "../../../../components/layout/flex/Flex";
import LayoutInnerContainer from "../../../../components/layout/split/one/LayoutInnerContainer";
import HamburgerMenu from "../../../../components/menu/HamburgerMenu";
import HamburgerMenuAction from "../../../../components/menu/HamburgerMenuAction";
import ModalDialog, { useModal } from "../../../../components/modal/ModalDialog";
import ModalWizard from "../../../../components/modal/ModalWizard";
import AccordionWrapper from "../../../../components/toggle-view/AccordionWrapper";
import AuthorizeComponent from "../../../../core/AuthorizeComponent";
import Roles from "../../../../core/enums/Roles";
import helpers from "../../../../core/helpers";
import companyStateModule from "../../../../core/state/company/companyStateModule";
import useBreadcrumbs from "../../../../core/state/navigation/useBreadcrumbs";
import useStateModule from "../../../../core/state/useStateModule";
import useDataReducer from "../../../../hooks/_shared/useDataReducer";
import PaymentService from "../../../../services/Payment/PaymentService";
import UserService from "../../../../services/User/UserService";
import IBANWizard from "./wizard/IBANWizard";
import { Helmet } from "react-helmet";
import { ReactComponent as MangoSvg } from "../../../../assets/mangopay.svg";

const Information = styled.div`
    margin-bottom: 75px;

    img {
        &.mangopay {
            width: 320px;
            margin: 0 0 44px 0;
        }
        &.file-icon {
            width: 32px;
            vertical-align: middle;
            margin: 0 16px 0 0;
        }
    }

    p {
        &.larger {
            font-size: 20px;
        }

        &.status {
            font-weight: bold;
            margin: 0 0 36px;
        }

        &.success {
            color: ${(props) => props.theme.textActive};
        }

        &.fail {
            color: ${(props) => props.theme.error};
        }
        &.mt-0 {
            margin-top: 0;
        }
    }

    a {
        color: ${(props) => props.theme.primaryActionColor};
        font-weight: bold;
        text-decoration: none;

        &.file {
            color: ${(props) => props.theme.tertiaryColor};
            font-weight: normal;
        }
    }
`;

const InfoWrapper = styled.div`
    display: flex;
    margin: 56px 0px 0px 0px;

    & .left {
        width: 60%;
    }

    & .right {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        border-left: 1px solid #fff;

        & .end {
            align-self: flex-end;
            margin-bottom: 155px;
            width: 100%;
        }

        & a {
            text-decoration: none;
            color: ${(p) => p.theme.primaryActionColor};
        }
    }
`;

const LinkWrapper = styled.div`
    background: url("/images/mangopay-bg.svg") no-repeat;
    background-size: 130%;
    margin-left: 100px;
    height: 100%;
    display: flex;
    margin-top: 60px;
    text-align: right;
    padding-right: 60px;
`;

const MangoWrapper = styled.div`
    text-align: right;
`;

const StyledMango = styled(MangoSvg)`
    width: 320px;
    height: auto;
    margin-right: 60px;
`;

const PaymentsScreen = () => {
    const [isMasterAdmin] = useState(UserService.isInRole(Roles.InstallerCompanyAdmin));
    const { t } = useTranslation();
    const { companyData, loadCompany } = useStateModule(companyStateModule);

    useBreadcrumbs([
        {
            text: t("navigation:company"),
        },
        {
            text: t("navigation:paymentSettings"),
        },
    ]);

    const [bankAccount, setBankAccount] = useState(null);

    // BANK ACCOUNT
    const [myBankAccounts, loadMyBankAccounts] = useDataReducer(async () => {
        return await PaymentService.getMyBankAccounts();
    });

    useEffect(() => {
        loadMyBankAccounts();
    }, []);

    useEffect(() => {
        if (companyData.data.id && !companyData.isLoading) {
            const userDetails = UserService.getUserDetailsFromToken();
            const userCompanyId = userDetails.CompanyId || null;
            loadCompany(userCompanyId);
        }
    }, []);

    const ibanColumns = useMemo(
        () => [
            {
                Header: t("forms:iban.text"),
                accessor: "iban",
            },
            {
                Header: t("forms:swift.text"),
                accessor: "bic",
                Cell: ({ cell: { value } }) => (value ? value : "/"),
            },
            {
                Header: t("forms:status.text"),
                accessor: "archived",
                Cell: ({ cell: { value } }) =>
                    !value ? t("filters:ibanStatus.active") : t("filters:ibanStatus.deactivated"),
            },
            {
                Header: "",
                id: "id",
                Cell: ({ cell: { value, row } }) => {
                    return (
                        <AuthorizeComponent roles={[Roles.InstallerCompanyAdmin]}>
                            <HamburgerMenu disabled={row.original.archived}>
                                <HamburgerMenuAction
                                    text={t("actions:deactivate")}
                                    onClick={() => {
                                        deactivateIban(row.original);
                                    }}
                                />
                            </HamburgerMenu>
                        </AuthorizeComponent>
                    );
                },
            },
        ],
        [t, myBankAccounts]
    );

    const { modalProps: modalPropsDeactivateIban, toggleOpen: toggleOpenDeactivateIban } = useModal(
        {
            title: t("actions:dialogs.ibanDeactivate.title"),
            isWarning: true,
            onConfirm: async (bankAccountId) => {
                const result = await PaymentService.deactivateBankAccount(bankAccountId);
                if (result && result.error) {
                    toast.error(t("result.error.message"));
                } else {
                    toast.success("actions:dialogs.ibanDeactivate.toastNotification.success");
                    loadMyBankAccounts();
                }
                toggleOpenDeactivateIban();
            },
            onCancel: () => {
                toggleOpenDeactivateIban();
            },
            confirmText: t("actions:dialogs.ibanDeactivate.confirmText"),
        }
    );

    const {
        modalProps: modalPropsCannotDeactivateIban,
        toggleOpen: toggleOpenCannotDeactivateIban,
    } = useModal({
        title: t("actions:dialogs.ibanDeactivate.titleAlt"),
        type: "warning",
        isWarning: false,
        onConfirm: async () => {
            toggleOpenCannotDeactivateIban();
        },
        confirmText: t("actions:dialogs.ibanDeactivate.ok"),
    });

    const deactivateIban = (original) => {
        if (!myBankAccounts.data) return;
        const activeBankAccounts = myBankAccounts?.data.filter((acc) => !acc.archived);
        if (activeBankAccounts.length < 2) {
            toggleOpenCannotDeactivateIban();
            return;
        }
        const bankAccountId = helpers.getFormData({
            bankAccountId: original.id,
        });
        setBankAccount(original.iban);
        toggleOpenDeactivateIban(bankAccountId);
    };

    return (
        <LayoutInnerContainer className="wide">
            <Helmet>
                <title>{t("seo:paymentSettings.title")}</title>
            </Helmet>

            <Flex.Container style={{ height: "100%" }}>
                <InfoWrapper>
                    <div className="left">
                        <Information className="text">
                            <p
                                className="larger mt-0"
                                dangerouslySetInnerHTML={{
                                    __html: t("screens:payments.mangopay.text"),
                                }}
                            />
                        </Information>
                        <Flex.Container style={{ width: "100%" }}>
                            <AccordionWrapper
                                title={t("screens:payments.iban.accordionTitle")}
                                variant="overflow"
                                isOpen={true}
                                style={{ width: "100%" }}
                                noBorder
                            >
                                {myBankAccounts.data && (
                                    <DataTable columns={ibanColumns} data={myBankAccounts.data} />
                                )}

                                {isMasterAdmin && (
                                    <Button
                                        className="primary primary--action"
                                        onClick={() => {
                                            ModalWizard.show(IBANWizard(t, companyData), {
                                                onSubmit: async (values) => {
                                                    const result =
                                                        await PaymentService.createBankAccount(
                                                            ModalWizard.asFormData(values)
                                                        );
                                                    if (result && result.error)
                                                        toast.error(t(result.error.message));
                                                    else {
                                                        toast.success(
                                                            t(
                                                                "actions:dialogs.createBankAccount.success"
                                                            )
                                                        );
                                                        ModalWizard.hide();
                                                        await loadMyBankAccounts();
                                                    }
                                                },
                                                onCancel: async () => {
                                                    ModalWizard.hidePrompt();
                                                },
                                            });
                                        }}
                                    >
                                        {t("screens:payments.iban.btn")}
                                    </Button>
                                )}
                            </AccordionWrapper>
                        </Flex.Container>
                    </div>
                    <div className="right">
                        <MangoWrapper>
                            <StyledMango />
                        </MangoWrapper>
                        <LinkWrapper>
                            <a
                                href="https://www.mangopay.com/start/download-tcs/"
                                className="end"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                {t("screens:payments.mangopay.frameworkAgreement")}
                            </a>
                        </LinkWrapper>
                    </div>
                </InfoWrapper>
            </Flex.Container>

            <ModalDialog {...modalPropsDeactivateIban}>
                <p
                    dangerouslySetInnerHTML={{
                        __html: t("actions:dialogs.ibanDeactivate.text", {
                            iban: bankAccount,
                        }),
                    }}
                />
            </ModalDialog>
            <ModalDialog {...modalPropsCannotDeactivateIban}>
                <p>{t("actions:dialogs.ibanDeactivate.singularTitle")}</p>
                <p>{t("actions:dialogs.ibanDeactivate.singularMessage")}</p>
            </ModalDialog>
        </LayoutInnerContainer>
    );
};

export default PaymentsScreen;
