import React, { useState } from "react";
import styled from "styled-components";
import ImageGalleryPopup from "./ImageGalleryPopup";

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
`;

const Image = styled.div`
    --showRows: ${(props) => (props.showRows !== undefined ? props.showRows : 2)};
    width: calc((100% / var(--showRows)) - 18px);
    position: relative;
    background: url(${(props) => props.src}) no-repeat center center;
    background-size: contain;
    margin: 8px;
    height: 100px;
    border-radius: 4px;
    border: 1px solid ${(props) => props.theme.primaryInputBorderColor};
    transition: all 0.3s ease-in-out;
    box-sizing: border-box;

    &:hover {
        cursor: pointer;
        border-color: ${(p) => p.theme.primaryColorHover};
    }

    &.remove {
        opacity: 0.6;
    }

    &.remove:before {
        position: absolute;
        right: -1px;
        bottom: -1px;
        content: "✂";
        color: #e94e4e;
        background: #fff;
        font-size: 24px;
    }
`;

const ImageGallery = ({ images, showRows }) => {
    const [imageGallery, setImageGallery] = useState({ images: [], currentImage: null });
    const [isGalleryVisible, setIsGalleryVisible] = useState(false);

    const handleOpenGallery = (imageId) => {
        setImageGallery({
            images: images,
            currentImage: imageId,
        });
        setIsGalleryVisible(true);
    };

    const handleCloseGallery = () => {
        setIsGalleryVisible(false);
    };

    return (
        <Wrapper>
            {images &&
                images.map((image, index) => (
                    <Image
                        key={"image" + index}
                        showRows={showRows}
                        src={image.imageThumbnailUrl}
                        alt={image.id}
                        onClick={() => handleOpenGallery(image.id)}
                    />
                ))}
            {(!images || images.length === 0) && (
                <Image showRows={showRows} src="/images/no-image.svg" alt="no image" />
            )}

            {images && imageGallery.images.length > 0 && isGalleryVisible && (
                <ImageGalleryPopup imageGallery={imageGallery} handleClose={handleCloseGallery} />
            )}
        </Wrapper>
    );
};

export default ImageGallery;
