import React, { useEffect, useMemo } from "react";
import { usePagination, useRowSelect, useTable } from "react-table";
import styled from "styled-components";
import DataCard from "../data/DataCard";
import Pagination from "../pagination/Pagination";
import {
    DataListingContainer,
    DataListingContent,
} from "./styles/StyledDataContainer";
import { useTranslation } from "react-i18next";

const DataColumn = styled.div`
    font-size: 0.875rem;

    &.id {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    &.userStatus {
        position: absolute;
        top: 10px;
        left: 10px;
        border-radius: 50%;
        width: 12px;
        height: 12px;
        font-size: 0;

        &.active {
            background-color: ${(props) => props.theme.bgActive};
        }

        &.inactive {
            background-color: ${(props) => props.theme.bgInactive};
        }
    }

    &.email {
        color: ${(props) => props.theme.activeColor};
    }

    &.fullName,
    &.name {
        margin-bottom: 32px;
    }

    &.roleNames {
        margin: 5px 0 0;
        text-transform: uppercase;
        color: ${(p) => p.theme.primaryColor};
    }
`;

const DataCardList = ({
    cardClasses,
    columns,
    data,
    onSelect,
    cardColumns,
    pageCount: controlledPageCount,
    enablePaging = false,
    onPageChange,
    pageSize,
    currentPageIndex,
    numPageButtons,
}) => {
    const { t } = useTranslation("");
    const indexState = useMemo(() => {
        return { pageIndex: currentPageIndex, pageSize: pageSize };
    }, [currentPageIndex, pageSize]);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        toggleAllRowsSelected,
        page,
        canPreviousPage,
        canNextPage,
        gotoPage,
        nextPage,
        previousPage,
        // Get the state from the instance
        state,
    } = useTable(
        {
            columns,
            data,
            manualPagination: true,
            pageCount: controlledPageCount,
            initialState: indexState,
        },
        usePagination,
        useRowSelect
    );

    useEffect(() => {
        if (state.pageIndex !== currentPageIndex) {
            onPageChange &&
                onPageChange({
                    pageIndex: state.pageIndex,
                    pageSize: state.pageSize,
                });
        }
    }, [state.pageIndex, currentPageIndex, state.pageSize, state.pageIndex]);

    let filterColumns;
    if (cardColumns && cardColumns.length > 0) {
        filterColumns = columns.filter((col) =>
            cardColumns.includes(col.accessor)
        );
    } else {
        filterColumns = columns;
    }
    filterColumns = filterColumns.map((col) => col.accessor);

    if (data) {
        return (
            <>
                <DataListingContainer
                    {...getTableBodyProps()}
                    {...getTableProps()}
                >
                    <DataListingContent>
                        {page.map((row, i) => {
                            prepareRow(row);

                            return (
                                <DataCard
                                    key={"card" + i}
                                    onClick={() => {
                                        toggleAllRowsSelected(false);
                                        row.toggleRowSelected();
                                        onSelect && onSelect(row);
                                    }}
                                    {...row.getToggleRowSelectedProps()}
                                    title=""
                                    {...row.getRowProps()}
                                    className={`card-item ${cardClasses}`}
                                >
                                    {row.cells.map((cell, j) => {
                                        return headerGroups.map(
                                            (headerGroup) => (
                                                <div
                                                    key={"info" + j}
                                                    {...headerGroup.getHeaderGroupProps()}
                                                >
                                                    {headerGroup.headers.map(
                                                        (column, k) => {
                                                            return (
                                                                column.id ===
                                                                    cell.column
                                                                        .id &&
                                                                filterColumns.includes(
                                                                    column.id
                                                                ) && (
                                                                    <DataColumn
                                                                        title={
                                                                            cell
                                                                                .column
                                                                                .id ===
                                                                            "userStatus"
                                                                                ? cell
                                                                                      .row
                                                                                      .original
                                                                                      .enabled ===
                                                                                  false
                                                                                    ? t(
                                                                                          "common:companyActivation.Disabled"
                                                                                      )
                                                                                    : t(
                                                                                          `common:companyActivation.${cell.value}`
                                                                                      )
                                                                                : null
                                                                        }
                                                                        className={[
                                                                            cell
                                                                                .column
                                                                                .id,
                                                                            cell
                                                                                .column
                                                                                .id ===
                                                                            "userStatus"
                                                                                ? cell
                                                                                      .row
                                                                                      .original
                                                                                      .enabled ===
                                                                                  false
                                                                                    ? "inactive"
                                                                                    : cell.value ===
                                                                                      "FullyRegistered"
                                                                                    ? "active"
                                                                                    : "inactive"
                                                                                : "",
                                                                        ]}
                                                                        key={
                                                                            "column" +
                                                                            k
                                                                        }
                                                                        {...column.getHeaderProps()}
                                                                    >
                                                                        <span
                                                                            {...cell.getCellProps()}
                                                                        >
                                                                            {cell.value !==
                                                                            undefined
                                                                                ? cell.render(
                                                                                      "Cell"
                                                                                  )
                                                                                : "-"}
                                                                        </span>
                                                                    </DataColumn>
                                                                )
                                                            );
                                                        }
                                                    )}
                                                </div>
                                            )
                                        );
                                    })}
                                </DataCard>
                            );
                        })}
                    </DataListingContent>
                    {enablePaging && (
                        <Pagination
                            gotoPage={gotoPage}
                            previousPage={previousPage}
                            nextPage={nextPage}
                            canPreviousPage={canPreviousPage}
                            canNextPage={canNextPage}
                            pageCount={controlledPageCount}
                            currentPageIndex={currentPageIndex}
                            numButtons={numPageButtons}
                        />
                    )}
                </DataListingContainer>
            </>
        );
    }
};

export default DataCardList;
