import React from "react";
import styled from "styled-components";

const Anchor = styled.a`
    color: ${props => props.theme.textColor};
    text-align: center;
    text-decoration: none;
    display: inline-block;
    padding: 30px 0 0;
`;

const HTMLAnchor = (props) => {
    return (
        <Anchor {...props}>
            {props.children}
        </Anchor>
    )
};

export default HTMLAnchor;
