import React, { useState, useRef, useEffect } from "react";
import styled, { css } from "styled-components";
import { Input } from ".";
import { ReactComponent as SearchSvg } from "../../../assets/pwa/magnifier.svg";
import { ReactComponent as ResetSvg } from "../../../assets/close.svg";

const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
    window.HTMLInputElement.prototype,
    "value"
).set;

const StyledReset = styled(ResetSvg)`
    position:absolute;
    right: 16px;
    top: 7px;
    width: 14px; 
    height: 14px;
    transition: all 300ms ease;

    &:hover .lines {
        stroke: #e94e4e;
    }
`;


const SvgSearch = styled(SearchSvg)`
    cursor: pointer;
`;

const SearchButton = styled.button`
    position: absolute;
    right: 10px;
    background: transparent;
    outline: 0;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
`;

const StylizedDrop = styled.div`
    box-sizing: border-box;
    width: 100%;
    padding: 8px 16px;
    min-width: 190px;
    background: ${(props) => props.theme.inputBgColor};
    border: 1px solid
        ${(props) => props.theme.primaryInputBorderColor || "#d4d4d4"};
    border-radius: ${(props) => props.theme.inputBorderRadius || "6px"};
    position: absolute;
    top: 48px;
    z-index: 300;
    background: #fff;
    text-align: left;
    cursor: pointer;
    box-shadow: 4px 4px 16px 0 rgba(0, 0, 0, 0.16);
    transition: all 0.3s ease-in;
    opacity: 0;
    visibility: hidden;
    font-size: ${(props) => props.theme.inputFontSize};

    & ul {
        list-style: none;
        margin: 0;
        padding: 0;
        font-size:14px;
    }

    & ul li {
        list-style: none;
        padding: 15px 5px;
        cursor: pointer;
        font-weight: normal;
    }

    & ul li:hover {
        color: ${(props) => props.theme.primaryColor} !important;
    }

    & ul li:not(:last-child) {
        border-bottom: 1px solid #e2e2e2;
    }

    ${(props) =>
        props.hasDefault &&
        css`
        & ul li:first-child {
        font-weight:600;
        }
    `};
`;

const Wrapper = styled.div`
    position: relative;
    cursor: pointer;
    width: 100%;
    height: 100%;
    
    & input {
        border:none;
        font-weight: bold;
        font-size:20px;
        color: black;
        margin: 0px;
        padding: 0px;
        width: 85%;
        height:100%;
    }

    &.open ${StylizedDrop} {
        opacity: 1;
        visibility: visible;
    }
`;

const Title = styled.div`
    font-size:20px;
    float:left;

`;

const ClearableLookup = (props) => {
    let { value, searchFn, title, ...rest } = props;
    const inputEl = useRef(null);
    const isSelected = useRef(false);
    const [inputVal, setInputVal] = useState(value);
    const [inputChanged, setInputChanged] = useState(0);
    const [itemSelected, setItemSelected] = useState(false);
    const [isFilter, setIsFilter] = useState(false);
    const [results, setResults] = useState([]);

    const handleSearch = async (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        props.onChange && props.onChange(ev);

        if (isSelected.current === true) {
            isSelected.current = false;
            return;
        }
        if (!searchFn) return;
        const results = await searchFn(inputEl.current.value);
        setResults(results);
    };

    const handleSelect = (ev) => {
        const selected = ev.target.dataset.value;
        setResults([]);
        isSelected.current = true;
        setItemSelected(true);
        props.itemSelected && props.itemSelected(selected);
        setInputVal(selected);
        nativeInputValueSetter.call(inputEl.current, selected);
        let inpEvt = new Event("input", { bubbles: true });
        inputEl.current.dispatchEvent(inpEvt);

    };

    const startFilter = (ev) => {
        setInputVal(null);
        setIsFilter(true);

    };

    useEffect(() => {  
        if (isFilter && inputEl.current) {
            inputEl.current.focus();
        }
    }, [isFilter]);



    let wrapperClass = results && results.length > 0 ? "open" : "";

    const handleReset = (ev) => {
        nativeInputValueSetter.call(inputEl.current, "");
        setInputVal("");
        props.itemSelected && props.itemSelected(null);
        isSelected.current = false;
        setItemSelected(false);
        setIsFilter(false);
        setInputChanged(inputChanged + 1);
        let inpEvt = new Event("input", { bubbles: true });
        inputEl.current.dispatchEvent(inpEvt);
    };

    const handleInputBlur= (ev)=>{
        if(ev.currentTarget.value==="") handleReset(ev);
    }

    if (isFilter)
        return (
            <Wrapper className={wrapperClass}>
                <Input
                    ref={inputEl}
                    value={value}
                    {...rest}
                    onChange={handleSearch}
                    onBlur={handleInputBlur}
                    type="generic"
                />

                {
                   inputEl.current && inputEl.current!=="" && <StyledReset
                        onClick={handleReset}
                    />
                }

                {results &&
                    results.length > 0 && (<StylizedDrop onClick={handleSelect} hasDefault={false}>
                        <ul>
                            {
                                results.map((option, idx) => (
                                    <li key={idx} data-value={option} data-idx={idx}>
                                        {option}
                                    </li>
                                ))}
                        </ul>
                    </StylizedDrop>)
                }
            </Wrapper>
        );
    else
        return (
            <Wrapper onClick={startFilter}>
                <Title>
                    {itemSelected && inputVal && inputVal!=="" ? inputVal: title}
                </Title>
                {!itemSelected ? <SearchButton >
                    <SvgSearch />
                </SearchButton> :<StyledReset
                        onClick={handleReset}
                    />
                }

            </Wrapper>)
};

export default ClearableLookup;
