import React from "react";
import styled from "styled-components";
import LanguageSwitch from "../../../elements/header/components/LanguageSwitch";
import Flex from "../../flex/Flex";
import { ReactComponent as LogoSvg } from "../../../../assets/rtr-white-logo.svg";
import { ReactComponent as FlagSvg } from "../../../../assets/life.svg";
import { useTranslation } from "react-i18next";

const Container = styled(Flex.Container)`
    display: flex;
    width: 100%;
    height: 100%;
    padding: 40px 80px;
    background: rgb(34, 148, 208);
    background: linear-gradient(
        90deg,
        rgba(34, 148, 208, 1) 0%,
        rgba(67, 171, 110, 1) 100%
    );
`;

const LeftContainer = styled(Flex.Column)`
    background-color: #f5f5f5;
    padding: 10px 30px;
    box-shadow: 0 6px 40px 0 rgba(0, 0, 0, 0.13);
    width: 500px;
    max-height: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    @media ${(p) => p.theme.device.mobile} {
        height: 100%;
        width: 100%;
        padding: 10px;
        position: absolute;
        left: 0;
        top: 0;
    }

    & > * {
        max-width: 100%;
    }

    &.overflow {
        overflow:visible;
    }
`;

const RightContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: transparent;
    align-items: flex-end;
    justify-content: space-between;
    flex-grow: 1;
    max-width: 100%;

    &.child {
        max-width: 100%;
        flex-grow: 0;
        text-align: right;
        font-size: 62px;
        font-weight: 500;
        color: white;
        & .big {
            font-size: 82px;
            font-weight: 600;
        }
    }
`;

const Logo = styled(LogoSvg)`
    min-width: 348px;
    width: 348px;
    height: auto;
`;

const Flag = styled(FlagSvg)`
    min-width: 140px;
    width: 140px;
    height: auto;
    margin-top: 24px;
`;

const LayoutOneTwo = (props) => {
    const { t } = useTranslation("common");
    return (
        <Container>
            <LeftContainer className={`left-container ${props.overflow ? "overflow" :""}`}>
                {props.children}
                <LanguageSwitch centered info={props.info}></LanguageSwitch>
            </LeftContainer>
            <RightContainer>
                {!props.hideInfo && <Logo />}
                <RightContainer className="child">
                    {!props.hideInfo && (
                        <>
                            <div className="big">{t("dashboard.welcome")}</div>
                            {t("dashboard.toFutureOf")}
                            <br />
                            {t("dashboard.circularEconomy")}
                        </>
                    )}
                </RightContainer>
                {!props.hideInfo && <Flag />}
            </RightContainer>
        </Container>
    );
};

export default LayoutOneTwo;
