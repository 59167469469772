import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import ListingPage from "../../../../components/data/ListingPage";
import { StyledBackIcon } from "../../../../components/elements/buttons/BackButton";
import ModalDialog, {
    useModal,
} from "../../../../components/modal/ModalDialog";
import ModalWizard from "../../../../components/modal/ModalWizard";
import AuthorizeComponent from "../../../../core/AuthorizeComponent";
import Roles from "../../../../core/enums/Roles";
import RouteCreator from "../../../../core/RouteCreator";
import clientsUnitsListStateModule from "../../../../core/state/clients/clientsUnitsListStateModule";
import useBreadcrumbs from "../../../../core/state/navigation/useBreadcrumbs";
import useStateModule from "../../../../core/state/useStateModule";
import useDataReducerImmediate from "../../../../hooks/_shared/useDataReducerImmediate";
import useImmerReducer from "../../../../hooks/_shared/useImmerReducer";
import usePageChange from "../../../../hooks/_shared/usePageChange";
import usePromises from "../../../../hooks/_shared/usePromises";
import ClientsService from "../../../../services/Clients/ClientsService";
import TagsService from "../../../../services/Tags/TagsService";
import ClientsBreadcrumbs from "../../columns/ClientsBreadcrumbs";
import ClientsColumns from "../../columns/ClientsColumns";
import ClientDetails from "./details/ClientDetails";
import UnitsDetailSideBar from "./sidebar/UnitsDetailSideBar";
import UnitsFilter from "./UnitsFilter";
import UnitWizardAdd from "./wizard/UnitWizardAdd";
import UnitWizardEdit from "./wizard/UnitWizardEdit";
import UnitWizardReTag from "./wizard/UnitWizardReTag";
import ActionButton from "../../../../components/elements/buttons/ActionButton";
import {Helmet} from "react-helmet";

const ListingHeader = styled.div`
    position: relative;
    width: 100%;
`;

const actionTypes = {
    SET_MANUFACTURER_FILTER: "SET_MANUFACTURER_FILTER",
    RESET_MANUFACTURER_FILTER: "RESET_MANUFACTURER_FILTER",

    SET_APPLICATION_TYPE_FILTER: "SET_APPLICATION_TYPE_FILTER",
    RESET_APPLICATION_TYPE_FILTER: "RESET_APPLICATION_TYPE_FILTER",

    SET_GAS_TYPE: "SET_GAS_TYPE",
    RESET_GAS_TYPE: "RESET_GAS_TYPE",

    SET_CLIENT_ID: "SET_CLIENT_ID",
    RESET_CLIENT_ID: "RESET_CLIENT_ID",

    SET_SORT: "SET_SORT",
    RESET_SORT: "RESET_SORT",

    SET_PAGING: "SET_PAGING",
    SET_LOCATION_ID: "SET_LOCATION_ID",
    RESET_LOCATION_ID: "RESET_LOCATION_ID",
};

const unitsReducer = (state, action) => {
    if (
        (action.type !== actionTypes.SET_PAGING)
    )
        state.page = 1;
    switch (action.type) {
        case actionTypes.SET_PAGING:
            state.pageSize = action.payload.pageSize;
            state.page = action.payload.pageIndex + 1;
            break;
        case actionTypes.SET_SORT:
            state.orderColumn = action.payload[0].id;
            state.descending = action.payload[0].desc;
            break;
        case actionTypes.RESET_SORT:
            state.orderColumn = "";
            state.descending = true;
            break;
        case actionTypes.SET_LOCATION_ID:
            state.filter.locationId = action.payload;
            break;
        case actionTypes.RESET_LOCATION_ID:
            delete state.filter.clientId;
            break;
        case actionTypes.SET_MANUFACTURER_FILTER:
            state.filter.manufacturer = action.payload;
            break;
        case actionTypes.RESET_MANUFACTURER_FILTER:
            delete state.filter.manufacturer;
            break;
        case actionTypes.SET_APPLICATION_TYPE_FILTER:
            state.filter.applicationType = action.payload;
            break;
        case actionTypes.RESET_APPLICATION_TYPE_FILTER:
            delete state.filter.applicationType;
            break;
        case actionTypes.SET_GAS_TYPE:
            state.filter.refrigerantId = action.payload;
            break;
        case actionTypes.RESET_GAS_TYPE:
            delete state.filter.refrigerantId;
            break;
        default:
            return state;
    }
};

const ClientLocationsUnitListing = ({ match }) => {
    const history = useHistory();
    const { t } = useTranslation("screens");
    const [selectedUnit, setSelectedUnit] = useState(null);

    const {
        clientsLocationUnits,
        isLoading,
        error,
        gasTypesData,
        loadGasTypes,
        loadClientsLocationUnits,
        loadClientsLocationsUnitsById,
        details,
        deleteClientLocationUnit,
    } = useStateModule(clientsUnitsListStateModule);

    useEffect(() => {
        loadGasTypes();
    }, [loadGasTypes]);

    const wizardData = usePromises(
        [() => ClientsService.getUnitManufacturersAsync()],
        (res) => ({
            manufacturers: res[0].data.data,
        })
    );

    const [currentClientData] = useDataReducerImmediate(
        ClientsService.getClientById,
        match.params.clientId
    );

    const applicationTypes = [
        {
            0: t('common:applicationType.residential'),
        },
        {
            1: t('common:applicationType.commercial'),
        },
        {
            2: t('common:applicationType.industrial'),
        },
    ];

    const { modalProps, toggleOpen } = useModal({
        title: t("actions:dialogs.deleteUnit.title"),
        onConfirm: async (unitId) => {
            let res = await deleteClientLocationUnit(unitId);

            if (res && res.error) toast.error(t(res.error.message));
            else {
                toast.success(t("actions:dialogs.deleteUnit.success"));
            }
            loadClientsLocationUnits(unitsState);
            toggleOpen();
        },
        onCancel: () => {
            toggleOpen();
        },
        confirmText: t("actions:dialogs.deleteUnit.confirmText"),
    });

    const editUnit = async (unitId) => {
        const unit = await ClientsService.getUnitByIdAsync(unitId);
        if (unit.error) {
            toast(unit.error.message);
            return;
        }
        ModalWizard.show(
            UnitWizardEdit(
                t,
                unit.data.data[0],
                currentClientData,
                wizardData.manufacturers,
                gasTypesData.gasTypes,
                queryUnitsTags
            ),
            {
                onSubmit: async (values) => {
                    const newImages = values.images.filter(
                        (img) => img instanceof File
                    );

                    const oldImages = values.images.filter(
                        (img) => !(img instanceof File)
                    );

                    const toDelete = unit.data.data[0].images
                        .filter(
                            (img) =>
                                !oldImages.some(
                                    (oldImg) => img.id === oldImg.id
                                )
                        )
                        .map((img) => img.id);

                    values.images = newImages;
                    values.deleteImages = toDelete;
                    values.unitId = unitId;

                    const result = await ClientsService.updateUnitAsync(
                        ModalWizard.asFormData(values)
                    );
                    if (result && result.error)
                        toast.error(t(result.error.message));
                    else {
                        toast.success(t("actions:dialogs.editUnit.success"));
                        ModalWizard.hide();
                        loadClientsLocationUnits(unitsState);
                    }
                },
                onCancel: async () => {
                    ModalWizard.hidePrompt();
                },
            },
            { style: { minHeight: "630px" } }
        );
    };

    const reTagUnit = async (unitId) => {
        const unit = await ClientsService.getUnitByIdAsync(unitId);
        if (unit && unit.error) {
            toast(t(unit.error.message));
            return;
        }
        ModalWizard.show(UnitWizardReTag(t, queryUnitsTags), {
            onSubmit: async (values) => {
                const client = unit.data.data[0].client[0];
                const allValues = Object.assign(
                    {},
                    { ...unit.data.data[0] },
                    { ...client },
                    { ...values }
                );
                const result = await ClientsService.updateUnitAsync(
                    ModalWizard.asFormData(allValues)
                );
                if (result && result.error)
                    toast.error(t(result.error.message));
                else {
                    toast.success(t("actions:dialogs.reTagUnit.success"));
                    ModalWizard.hide();
                    loadClientsLocationUnits(unitsState);
                }
            },
            onCancel: async () => {
                ModalWizard.hidePrompt();
            },
        });
    };

    const onUnitDelete = async (row, value) => {
        const unit = await ClientsService.getUnitByIdAsync(row.original.unitId);
        setSelectedUnit(unit.data.data);
        toggleOpen(value);
    };

    const unitsColumns = useMemo(() => {
        return ClientsColumns.getColumns(
            t,
            "unitsColumns",
            {
                toggleOpen,
                editUnit,
                reTagUnit,
                onUnitDelete,
            },
            [t, wizardData]
        );
    });

    const [unitsState, unitsDispatch] = useImmerReducer(unitsReducer, {
        filter: {},
        page: 1,
        pageSize: 10,
        orderColumn: "manufacturer",
        descending: false,
    });

    const [currentLocationData] = useDataReducerImmediate(
        ClientsService.getLocationById,
        match.params.locationId
    );

    const { update: updateBreadcrumbs } = useBreadcrumbs([]);

    if (!unitsState.filter.locationId) {
        unitsDispatch({
            type: actionTypes.SET_LOCATION_ID,
            payload: match.params.locationId,
        });
    }

    useEffect(() => {
        updateBreadcrumbs(
            ClientsBreadcrumbs.getBreadcrumbs(
                t,
                "3",
                currentClientData.data ? currentClientData.data.clientName : "",
                currentLocationData.data
                    ? currentLocationData.data.street +
                          "," +
                          currentLocationData.data.city
                    : ""
            )
        );
    }, [
        currentClientData.data,
        currentLocationData.data,
        t,
        updateBreadcrumbs,
    ]);

    useEffect(() => {
        unitsState.filter.locationId && loadClientsLocationUnits(unitsState);
    }, [loadClientsLocationUnits, unitsState]);

    const queryUnitsTags = async (tag) => {
        if (tag.length > 2) {
            const tags = await TagsService.lookupUnassignedTags(tag);
            return tags.map((t) => t.id);
        }
        return [];
    };

    const unitsPageChange = usePageChange(unitsDispatch, unitsState);
    const unitsFilterAction = (
        <AuthorizeComponent
            roles={[
                Roles.InstallerCompanyAdmin,
                Roles.InstallerCompanyUser,
                Roles.InstallerCompanyTechnician,
            ]}
        >
            <ActionButton
                className="primary"
                // icon="/images/actions/icon-add.svg"
                // iconSize="12px"
                onClick={(ev) => {
                    ev.preventDefault();
                    ModalWizard.show(
                        UnitWizardAdd(
                            t,
                            null,
                            currentClientData,
                            wizardData.manufacturers,
                            gasTypesData.gasTypes,
                            queryUnitsTags,
                            currentLocationData
                        ),
                        {
                            onSubmit: async (values) => {
                                if (values.images) {
                                    const newImages = values.images.filter(
                                        (img) => img instanceof File
                                    );
                                    values.images = newImages;
                                }

                                values.locationId =
                                    currentLocationData.data.locationId;
                                const result = await ClientsService.createClientLocationUnit(
                                    ModalWizard.asFormData(values)
                                );
                                if (result && result.error)
                                    toast.error(t(result.error.message));
                                else {
                                    toast.success(
                                        t("actions:dialogs.createUnit.success")
                                    );
                                    ModalWizard.hide();
                                    loadClientsLocationUnits(unitsState);
                                }
                            },
                            onCancel: async () => {
                                ModalWizard.hidePrompt();
                            },
                        },
                        { style: { minHeight: "630px" } }
                    );
                }}
                tooltip={t("screens:units.tooltip.add")}
            >
                {t("actions:addUnit")}
            </ActionButton>
        </AuthorizeComponent>
    );

    const sortUnits = (sortBy) => {
        sortBy.length > 0
            ? unitsDispatch({ type: actionTypes.SET_SORT, payload: sortBy })
            : unitsDispatch({ type: actionTypes.RESET_SORT });
    };

    const currentUnitsSortBy = useMemo(() => {
        return [
            {
                id: unitsState.orderColumn,
                desc: unitsState.descending,
            },
        ];
    });

    const [detailViewIsOpened, setDetailViewIsOpened] = useState(false);

    const onUnitsSelect = useCallback(
        (row) => {
            const selectUnitsRow = async (row) => {
                loadClientsLocationsUnitsById(row.original.unitId);
                setDetailViewIsOpened(true);
            };
            selectUnitsRow(row);
        },
        [loadClientsLocationsUnitsById]
    );

    const closeSideBar = () => {
        setDetailViewIsOpened(false);
    };

    return (
        <>
            <Helmet>
                <title>{t("seo:units.title")}</title>
            </Helmet>
            <ListingPage
                columns={unitsColumns}
                tableState={{
                    data: clientsLocationUnits.data,
                    loading: isLoading,
                    error: error,
                }}
                paging={{
                    enablePaging: true,
                    pageCount: clientsLocationUnits.totalPages,
                    pageSize: clientsLocationUnits.pageSize,
                    onPageChange: unitsPageChange,
                    currentPageIndex: clientsLocationUnits.currentPage
                        ? clientsLocationUnits.currentPage - 1
                        : 0,
                }}
                filterTitle={t("filters:clients.units.filterTitle")}
                filterTitleOnTop={false}
                filterComponent={() => {
                    return (
                        <UnitsFilter
                            filterState={unitsState.filter}
                            dispatch={unitsDispatch}
                            actionTypes={actionTypes}
                            gasTypes={gasTypesData.gasTypes}
                            applicationTypes={applicationTypes}
                            actions={unitsFilterAction}
                        />
                    );
                }}
                actions={{
                    headerActions: () => {
                        return (
                            <ListingHeader>
                                <StyledBackIcon
                                    className="clients"
                                    onClick={() => {
                                        history.push(
                                            RouteCreator.clients.locations(
                                                match.params.clientId
                                            )
                                        );
                                    }}
                                />
                                <ClientDetails
                                    currentLocationData={
                                        currentLocationData.data
                                    }
                                    details={currentClientData.data}
                                />
                            </ListingHeader>
                        );
                    },
                }}
                onSelect={onUnitsSelect}
                sorting={{
                    enableSorting: true,
                    onSortByChange: sortUnits,
                    currentSortBy: currentUnitsSortBy,
                }}
                detailView={
                    <UnitsDetailSideBar
                    key={detailViewIsOpened}    
                    location={currentLocationData.data}
                        unit={details}
                    />
                }
                detailViewIsOpened={detailViewIsOpened}
                detailSideBarTitle={t("sidebar:title.unit.details")}
                closeSideBar={closeSideBar}
            />
            <ModalDialog {...modalProps}>
                <p>{t("actions:dialogs.deleteUnit.text")}</p>
                <p style={{ marginLeft: "10px" }}>
                    •{" "}
                    {selectedUnit &&
                        `${selectedUnit[0].manufacturerName} | ${
                            selectedUnit[0].applicationTypeName
                        } ${t("application")} | ${
                            selectedUnit[0].capacity
                        } kW |  ${selectedUnit[0].refrigerantCode}`}
                </p>
            </ModalDialog>
        </>
    );
};
export default ClientLocationsUnitListing;
