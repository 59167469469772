import React, { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import styled from "styled-components";
import useStateModule from "../../core/state/useStateModule";
import userStateModule from "../../core/state/user/userStateModule";
import UserService from "../../services/User/UserService";
import helpers from "../../core/helpers";
import { useTranslation } from "react-i18next";
import pwaMenuItems from "./components/PwaMenuItems";
import useSharedState from "../../hooks/_shared/useSharedState";
import { ReactComponent as ArrowSvg } from "../../assets/pwa-right.svg";
import { ReactComponent as CloseSvg } from "../../assets/pwa/close-arrow.svg";
import RouteCreator from "../../core/RouteCreator";
import Logo from "../../components/logo/Logo";
import LanguageSwitch from "../../components/elements/header/components/LanguageSwitch";

const StyledClose = styled(CloseSvg)`
    width: auto;
    height: 18px;
    margin-right: 8px;
`;

const StyledArrow = styled(ArrowSvg)`
    width: 12px;
    height: 12px;

    & .arrow {
        stroke: #d5d0d0;
    }

    &.active line {
        stroke: #fff;
    }
`;

const MenuBack = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    z-index: 5;
    transform: translateX(-100%);

    &.open {
        transform: translateY(0);
    }
`;

const Header = styled.div`
    box-sizing: border-box;
    padding: 15px 20px;
    background: #ffffff;
    color: #000000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 55px;
    display: flex;
    justify-content: space-between;
    align-content: center;

    & + .content {
        padding: 45px 0px 0px 0px;
        width: 100%;
    }
`;

const HeaderSection = styled.div`
    display: flex;
    align-items: center;

    & img + * {
        margin-left: 25px;
    }

    & .close {
        font-size: 12px;
        font-weight: 600;
        color: ${(p) => p.theme.primaryColor};
    }
`;

const Wrapper = styled.div`
    box-sizing: border-box;
    padding: 15px 20px;
    background: #ffffff;
    color: #000000;
    position: fixed;
    top: 0;
    left: 0;
    width: 83%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-content: center;
    transform: translateX(-100%);
    transition: 0.3s ease all;
    z-index: 6;
    background: #f5f5f5;
    box-shadow: 0 6px 40px rgba(0, 0, 0, 0.16);

    &.open {
        transform: translateX(0);
    }
`;

const Avatar = styled.div`
    border-radius: 50%;
    width: 32px;
    height: 32px;
    color: #fff;
    background-color: ${(p) => p.theme.secondaryColor};
    text-align: center;
    margin-right: 12px;

    & span {
        line-height: 34px;
        font-size: 16px;
        text-transform: uppercase;
    }

    .avatar {
        border-radius: 50%;
        background: no-repeat center;
        background-size: cover;
        display: inline-block;
        width: 100%;
        height: 100%;
    }
`;

const UserSection = styled.div`
    font-size: 12px;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
`;

const UserName = styled.div`
    font-size: 14px;
    font-weight: 600;
    color: ${(p) => p.theme.primaryColor};
`;

const MenuList = styled.ul`
    list-style: none;
    margin: 0px -20px;
    padding: 0;
`;

const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    min-width: 30px;
`;

export const Icon = styled.img`
    height: 29px;
    width: 28px;
`;

const ItemName = styled.div`
    color: ${(props) => props.theme.menuItems};
    margin-left: 10px;
    text-transform: uppercase;
    margin-left: 10px;
    font-size: 12px;
    font-weight: 600;
`;

const ListItem = styled.li`
    outline: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    list-style: none;
    width: 100%;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    padding: 0px 20px;

    &.action * {
        color: #bc3535;
        stroke: #bc3535;
        fill: #bc3535;
    }

    &.disabled * {
        color: rgba(0, 0, 0, 0.25);
        stroke: rgba(0, 0, 0, 0.25);
    }

    & .inline {
        display: flex;
        align-items: center;
    }

    &.top-item {
        &:hover:not(.active) {
            box-shadow: 0 0 8px 6px rgba(0, 0, 0, 0.1);
        }
    }

    &.active {
        background: ${(p) => p.theme.primaryColor};
        color: #fff;
        a {
            color: #fff !important;
        }
    }

    & a {
        color: ${(props) => props.theme.menuItems};
        text-decoration: none;
        text-transform: uppercase;
        margin-left: 10px;
        font-size: 12px;
        font-weight: 600;
        &:hover {
            color: ${(props) => props.theme.textActive};
        }
    }

    .listitem-name {
        margin: 0 0 0 20px;
    }
`;

let listeners = [];
let state = { isOpen: false, active: 0 };

const setState = (newState) => {
    state = { ...state, ...newState };
    listeners.forEach((listener) => {
        listener(state);
    });
};

const useLeftMenu = () => {
    const newListener = useState()[1];
    useEffect(() => {
        // Called just after component mount
        listeners.push(newListener);
        return () => {
            // Called just before the component unmount
            listeners = listeners.filter(
                (listener) => listener !== newListener
            );
        };
    }, []);
    return [state, setState];
};

const LeftMenu = (props) => {
    const { UserId } = UserService.getUserDetailsFromToken();
    const history = useHistory();
    const [menuState, setMenuState] = useLeftMenu();
    const { logout, userData } = useStateModule(userStateModule);
    const { t } = useTranslation("screens");
    const avatarUrl = userData.profilePictureUrl;
    const userFullName = userData.firstName + " " + userData.lastName;
    const [headerState, setHeaderState] = useSharedState("pwaHeader");
    const [globalState] = useSharedState("global");

    const handleClick = (ev, idx, name, action) => {
        ev.preventDefault();
        ev.stopPropagation();
        if (action && action === "logout") {
            setMenuState({ isOpen: false });
            logout();
            return;
        }
        var a = ev.currentTarget.getElementsByTagName("a")[0];
        if (!a) return;
        const to = a.href.substring(a.origin.length);

        setMenuState({ active: idx });
        setHeaderState({ title: name, menuIndex: idx });
        setTimeout(() => {
            setMenuState({ isOpen: false });
        }, 100);
        setTimeout(() => {
            history.push(to);
        }, 300);
    };

    const handleUserEdit = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();

        setTimeout(() => {
            setMenuState({ active: -1, isOpen: false });
            setHeaderState({ title: t("navigation:userInfo"), menuIndex: -1 });
        }, 200);
        history.push(RouteCreator.pwa.editUser(UserId));
    };

    return (
        <>
            <MenuBack
                className={menuState.isOpen ? "open" : ""}
                onClick={() => setMenuState({ isOpen: false })}
            />
            <Wrapper className={menuState.isOpen ? "open" : ""}>
                <Header>
                    <HeaderSection>
                        <Logo className="left-menu pwa" width="130" />
                    </HeaderSection>
                    <HeaderSection
                        onClick={() => setMenuState({ isOpen: false })}
                    >
                        <StyledClose />
                        <div className="close">{t("navigation:close")}</div>
                    </HeaderSection>
                </Header>
                <div className="content">
                    <UserSection
                        onClick={(ev) => {
                            handleUserEdit(ev);
                        }}
                    >
                        <Avatar>
                            {avatarUrl ? (
                                <div
                                    className="avatar"
                                    style={{
                                        backgroundImage:
                                            `url(` + avatarUrl + `)`,
                                    }}
                                ></div>
                            ) : (
                                <span>{helpers.getInitials(userFullName)}</span>
                            )}
                        </Avatar>
                        <div>
                            Welcome,
                            <br />
                            <UserName>{userFullName}</UserName>
                        </div>
                    </UserSection>
                    <MenuList>
                        {pwaMenuItems(
                            globalState.hasValidCompanyCertificate &
                                globalState.hasValidUserCertificate
                        ).map((item, index) => {
                            return (
                                <ListItem
                                    className={
                                        index === headerState.menuIndex
                                            ? "active"
                                            : item.url
                                            ? item.action
                                                ? "action"
                                                : ""
                                            : "disabled"
                                    }
                                    onClick={(ev) =>
                                        handleClick(
                                            ev,
                                            index,
                                            t(item.name),
                                            item.action
                                        )
                                    }
                                    key={index}
                                >
                                    <div className="inline">
                                        {item.icon && (
                                            <IconWrapper>
                                                <Icon
                                                    src={
                                                        index ===
                                                        headerState.menuIndex
                                                            ? item.icon
                                                                  .activeSrc
                                                            : item.icon.src
                                                    }
                                                    alt={item.icon.alt}
                                                />
                                            </IconWrapper>
                                        )}
                                        {item.Icon && (
                                            <item.Icon
                                                className={
                                                    index ===
                                                    headerState.menuIndex
                                                        ? "active"
                                                        : ""
                                                }
                                            />
                                        )}
                                        {item.url ? (
                                            <NavLink to={item.url}>
                                                {t(item.name)}
                                            </NavLink>
                                        ) : (
                                            <ItemName>{t(item.name)}</ItemName>
                                        )}
                                    </div>
                                    {!item.action && (
                                        <StyledArrow
                                            className={
                                                index === headerState.menuIndex
                                                    ? "active"
                                                    : ""
                                            }
                                        />
                                    )}
                                </ListItem>
                            );
                        })}
                    </MenuList>
                    <LanguageSwitch className="pwa" />
                </div>
            </Wrapper>
        </>
    );
};

export default LeftMenu;
export { useLeftMenu };
