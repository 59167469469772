import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CompanyStockListing from "./components/CompanyStockListing";
import ExtractionListing from "./components/ExtractionListing";
import ExtractionDetails from "./components/ExtractionDetails";
import { LevelWrapper } from "./components/StyledElements";

const ScreeWrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    display: flex;
    flex-wrap: nowrap;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
`;

const CompanyStock = () => {
    const [state, setState] = useState({
        level: 0,
        cylinder: null,
        extraction: null,
    });

    useEffect(() => {
        document.querySelector(`#lvl${state.level + 1}`).scrollIntoView({
            behavior: "smooth",
        });
    }, [state.level]);

    return (
        <ScreeWrapper>
            <LevelWrapper id="lvl1">
                <CompanyStockListing
                    onSelect={(cyl) => {
                        setState({
                            ...state,
                            level: 1,
                            cylinder: cyl,
                            extraction: null,
                        });
                    }}
                />
            </LevelWrapper>
            <LevelWrapper id="lvl2">
                <ExtractionListing
                    onSelect={(ext) => {
                        setState((prevState) => ({
                            ...prevState,
                            level: 2,
                            extraction: ext,
                        }));
                    }}
                    onGoBack={(ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        setState((prevState) => ({
                            ...prevState,
                            level: 0,
                        }));
                    }}
                    cylinder={state.cylinder ? state.cylinder.skuId : undefined}
                />
            </LevelWrapper>
            <LevelWrapper id="lvl3">
                <ExtractionDetails
                    onGoBack={(ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        setState((state) => ({ ...state, level: 1 }));
                    }}
                    cylinder={state.cylinder}
                    details={state.extraction}
                    extraction={state.extraction}
                />
            </LevelWrapper>
        </ScreeWrapper>
    );
};

export default CompanyStock;
