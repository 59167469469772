import React from "react";
import { useTranslation } from "react-i18next";
import AccordionWrapper from "../../../components/toggle-view/AccordionWrapper";
import { SvgNoImage } from "../../pwa/components/StyledSvg";
import { Box16A9 } from "../../pwa/components/StyledElements";
import CylinderBlock from "../../../components/sidebar/CylinderBlock";
import useAccordionGroup from "../../../hooks/_shared/useAccordionGroup";

const CylinderDetailSideBar = React.forwardRef(
    ({ equipment, reportIssue }, ref) => {
        const { cylinderData } = equipment;
        const {
            id,
            size,
            tagId,
            refrigerantTypeCode,
            filledQuantity,
            imageUrl,
            ownerCompanyName,
            openAccordionId = null,
        } = cylinderData;
        const { t } = useTranslation("sidebar");
        const [openAccordion, setOpenAccordion] =
            useAccordionGroup(openAccordionId);

        React.useImperativeHandle(ref, () => ({
            updateAccordionId(accId) {
                setOpenAccordion(accId);
            },
        }));
        return (
            <>
                {cylinderData && (
                    <>
                        <CylinderBlock
                            cylinderId={id}
                            tagId={tagId}
                            refrigerantTypeCode={refrigerantTypeCode}
                            filledQuantity={filledQuantity}
                            size={size}
                            owner={ownerCompanyName}
                            openAccordion={openAccordion}
                            setOpenAccordion={setOpenAccordion}
                            reportIssue={reportIssue}
                        />

                        <AccordionWrapper
                            title={t("title.gallery")}
                            id="gallery"
                            isOpen={openAccordion}
                            onToggle={setOpenAccordion}
                        >
                            <Box16A9
                                style={{
                                    marginTop: "24px",
                                    marginLeft: "20px",
                                }}
                            >
                                {imageUrl !==
                                "/images/cylinder_no_image.svg" ? (
                                    <img
                                        className="thumb"
                                        alt="unit"
                                        src={imageUrl}
                                    />
                                ) : (
                                    <SvgNoImage />
                                )}
                            </Box16A9>
                        </AccordionWrapper>
                    </>
                )}
            </>
        );
    }
);

export default CylinderDetailSideBar;
