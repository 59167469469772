import { config } from "../Config/config.prod";
import api, { apiResult } from "../getAxios";

const PaymentService = {
    getMyBankAccounts: async () => {
        let res = (await api.get(config.apiUrl.getMyBankAccounts)).data.data;
        return res;
    },
    createBankAccount: async (values) => {
        return apiResult(api.post(config.apiUrl.createBankAccount, values));
    },
    deactivateBankAccount: async (bankAccountId) => {
        return await api.post(
            config.apiUrl.deactivateBankAccount,
            bankAccountId
        );
    },
    getMyKYCDocuments: async () => {
        return (await api.get(config.apiUrl.getMyKYCDocuments)).data.data;
    },

    getMyUbos: async () => {
        return (await api.get(config.apiUrl.getMyUbos)).data.data;
    },

    addMyUbo: async (values) => {
        return apiResult(api.post(config.apiUrl.addUbo, values));
    },
    updateUbo: async (values) => {
        return apiResult(api.put(config.apiUrl.updateUbo, values));
    },
    deleteUbo: async (values) => {
        return apiResult(api.put(config.apiUrl.deleteUbo, values));
    },
    submitUbo: async (values) => {
        return apiResult(api.put(config.apiUrl.submitUbo, values));
    },
    submitKYC: async (values) => {
        return apiResult(api.post(config.apiUrl.submitKYC, {KYCDocumentIds:values}));
    },
    
    addKYCDocument: async (values) => {
        return apiResult(api.post(config.apiUrl.addKYCDocument, values));
    },
    getMyWallets: async () => {
        return (await api.get(config.apiUrl.getMyWallets)).data.data;
    },
    getMyTransactions: async (values) => {
        return (await api.post(config.apiUrl.getMyTransactions, values)).data;
    },
    getMyGroupedTransactions: async (values) => {
        return (await api.post(config.apiUrl.getMyGroupedTransactions, values)).data;
    },
    getMyInvoices: async (values) => {
        return (await api.post(config.apiUrl.getMyInvoices, values)).data;
    },
    getMyDocuments: async (values) => {
        return (await api.post(config.apiUrl.getMyDocuments, values)).data;
    },
    downloadMyDocuments: async (documentIds) => {
        return apiResult(
            api.post(
                config.apiUrl.downloadMyDocuments,
                {
                    documentIds: documentIds
                },
                {
                    responseType: "arraybuffer",
                }
            )
        );
    },
    payout: async (values) => {
        return apiResult(api.post(config.apiUrl.payout, values));
    },
    getTransactionsFromMango: async () => {
        return apiResult(api.post(config.apiUrl.getTransactionsFromMango, {}));
    },
    downloadTransactionsFromMango: async (filter) => {
        return apiResult(
            api.post(
                config.apiUrl.downloadMyTransactions,
                {filter},
                {
                    responseType: "arraybuffer",
                }
            )
        );
    },
    getTransactionDetails: async (paidFor, bidId) => {
        return apiResult(
            api.post(config.apiUrl.getTransactionDetails, {
                paidFor: paidFor,
                bidId: bidId,
            })
        );
    },
    getTransactionDetailsById: async (id) => {
        return apiResult(
            api.post(config.apiUrl.getTransactionDetailsById, { id: id })
        );
    },
    topUp: async (values) => {
        const res = apiResult(api.post(config.apiUrl.topUp, values));
        return res;
    },
    transactionDetails: async (transactionId) => {
        return apiResult(
            api.post(config.apiUrl.transactionDetails, {
                transactionId,
            })
        );
    },
    getReclaimTransactionDetails: async (reclaimedOfferId) => {
        return apiResult(
            api.post(config.apiUrl.getReclaimTransactionDetails, {
                reclaimedOfferId
            })
        );
    },
};

export default PaymentService;
