import React from "react";
import styled from "styled-components";
import helpers from "../../core/helpers";
import ChartHeader from "./ChartHeader";
import { useTranslation } from "react-i18next";
import { ComposedChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';

const ChartWrapper = styled.div`
    display: flex;
    flex-direction:column;
    height: 100%;
    justify-content: space-between;
    flex-grow:0;

    &.admin {
        width: 100%;
    }
`;

const AmountContainer = styled.div`
  font-weight: 400;
  padding-top: 30px;
  font-size: 44px;
    font-weight:300;
    color:#333;
`;

const DatePeriod = styled.div`
font-size: 12px;

`;

const DateFrom = styled.div`
  float:left;

`;

const DateTo = styled.div`
  float:right;
`;

const CurrencyLabel = styled.span`
`;

const UnitLabel = styled.span`
    font-size: 20px;
`;

const LineChartWrapper = styled.div`
  margin-left:-40px;
  position: relative;
  
`;

export const FONT_COLOR = '#AAAEB3';

const ChartAveragePrice = (props) => {
    const { title, amount, currency, data, color, admin } = props;

    const { t } = useTranslation();

    const normalizedData = () => {
        let chartData = data ?? [];
        if (data && data.length == 1) {
            chartData = [];
            for (let step = 0; step < 10; step++) {
                chartData.push({ period: step, value: data[0].value });
            };
        }

        return chartData;
    }

    return (
        <ChartWrapper className={admin ? "admin" : ""} >
            <ChartHeader
                title={title}
            >

            </ChartHeader>
            <AmountContainer>
                <CurrencyLabel>
                    {currency} {amount?.toFixed(2)}
                </CurrencyLabel> <UnitLabel> /kg</UnitLabel>
            </AmountContainer>
            <LineChartWrapper>
                <ResponsiveContainer width="100%" height="100%" minHeight="100px">
                    <ComposedChart margin={{ top: 20, left: 0, right: 0, bottom: 20 }}
                        data={normalizedData()}
                        height={100}
                        width={350}
                    >
                        <defs>
                            <filter id="shadow" height="150%">
                                <feDropShadow dx="0" dy="40" stdDeviation="10" />
                            </filter>
                        </defs>
                        <XAxis dataKey="period" tick={false} axisLine={false} />
                        <YAxis tick={false} axisLine={false} width={40} />
                        <CartesianGrid strokeWidth={1} strokeOpacity="50%" horizontal={false} />
                        <Line type="monotone"
                            unit="M"
                            strokeLinecap="round"
                            dataKey="value"
                            fill={color}
                            filter="url(#shadow)"
                            stroke={color}
                            dot={false}
                            strokeWidth={3} />
                    </ComposedChart>
                </ResponsiveContainer>
            </LineChartWrapper>
            <DatePeriod>
                <DateFrom>
                    <span>
                        {data && data.length > 0 ? helpers.toLocalDateIgnoreTime(data[0].period) : null}
                    </span>
                </DateFrom>
                <DateTo>
                    {data && data.length > 0 ? helpers.toLocalDateIgnoreTime(data[data.length - 1].period) : null}
                </DateTo>
            </DatePeriod>
        </ChartWrapper>

    );
};

export default ChartAveragePrice;