import React, { useMemo } from "react";
import AccordionWrapper from "../../../../../components/toggle-view/AccordionWrapper";
import { useTranslation } from "react-i18next";
import QRCode from "qrcode.react";
import { TagTitle } from "../../../../Equipment/components/TagTitle";
import helpers from "../../../../../core/helpers";
import styled from "styled-components";
import SidebarField from "../../../../../components/elements/sidebar/SidebarField";
import DaikinMap from "../../../../../components/maps/DaikinMap";
import LocationMarker from "../../../../../components/maps/components/LocationMarker";
import { useLoadScript } from "@react-google-maps/api";
import MapBounds from "../../../../../components/maps/components/MapBounds";
import { Num, NumInfo, NumSection } from "../../../../../components/elements/sidebar/SidebarStyle";
import ImageGallery from "../../../../../components/gallery/ImageGallery";
import Slider from "../../../../../components/elements/general/Slider";
import useAccordionGroup from "../../../../../hooks/_shared/useAccordionGroup";
import { ReactComponent as InfoSvg } from "../../../../../assets/orange-info.svg";

const StyledInfo = styled(InfoSvg)`
    width: 30px;
    height: 30px;
    margin-right: 16px;
`;

const StyledQR = styled.div`
    text-align: center;
    margin: 24px 0 12px;
`;

const FlexDiv = styled.div`
    display: flex;
    font-size: 12px;
`;
const DownloadLink = styled.a`
    color: #2294d2;
    text-decoration: none;
    font-size: 12px;
    &.big {
        font-size: 14px;
    }
`;

const formatDateStr = (str) => {
    const fmt = Intl.DateTimeFormat("sk", {
        day: "numeric",
        month: "numeric",
        year: "numeric",
    });
    return fmt.format(new Date(str));
};

const splitTag = (tag) => {
    if (!tag || tag.length < 8) return tag;
    return tag.substring(0, 4) + " " + tag.substring(4, 8);
};

const gradeNames = ["A","B","C"]

const ExtractionDetailSideBar = ({ skuDetails, stockItem, extraction }) => {
    const { t } = useTranslation("sidebar");
    const [openAccordion, setOpenAccordion] = useAccordionGroup();
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyBQSju7sxgnuD4jrzA2cJLP2dVU3RDwHZY",
    });

    const extractionDetails = extraction ? extraction[0] : null;

    const boundsMarkers = useMemo(() => {
        let markers = [];
        if (extractionDetails) {
            if (extractionDetails.locationDetails.latitude) {
                markers.push(
                    new window.google.maps.Marker({
                        position: {
                            lat: extractionDetails.locationDetails.latitude,
                            lng: extractionDetails.locationDetails.longitude,
                        },
                    })
                );
            } else {
                markers.push(
                    new window.google.maps.Marker({
                        position: {
                            lat: 0,
                            lng: 0,
                        },
                    })
                );
            }
        }
        return markers;
    }, [stockItem, extraction]);

    if (!extraction) {
        return null;
    }
    const tagParts = helpers.splitQRTag(extractionDetails.unitDetails.tagId);

    let cnt = 0;
    return (
        isLoaded && (
            <>
                <AccordionWrapper
                    title={t("title.details")}
                    id="details"
                    isOpen={openAccordion}
                    onToggle={setOpenAccordion}
                >
                    <SidebarField
                        label={t("label.extraction.type")}
                        value={skuDetails.refrigerantGasTypeCode}
                    />

                    <SidebarField
                        label={t("label.extraction.quantity")}
                        value={extractionDetails.extractedQuantity.toFixed(2) + " kg"}
                    />

                    <SidebarField
                        label={t("label.extraction.qualityGrade")}
                        value={skuDetails.grade + " Grade"}
                    />

                    <SidebarField
                        label={t("label.extraction.type")}
                        value={
                            stockItem.hasAnalysis
                                ? t("filters:analysis.measurement")
                                : t("filters:analysis.selfAssessment")
                        }
                    />
                </AccordionWrapper>

                {stockItem && (
                    <AccordionWrapper
                        title={t(
                            `title.contaminated.${
                                extractionDetails.hasAnalysis ? "measured" : "selfAssessed"
                            }`
                        )}
                        id="contamination"
                        isOpen={openAccordion}
                        onToggle={setOpenAccordion}
                    >
                        <Slider
                            column
                            style={{ padding: "25px 20px 0px" }}
                            title={t("sidebar:title.extraction.extractionCylinder")}
                            captions={[splitTag(stockItem?.skuCylinderTagId)]}
                        >
                            {stockItem.hasAnalysis ? (
                                extractionDetails.isAnalysisReceived ? (
                                    <>
                                        <SidebarField
                                            label={t("label.analysisDate")}
                                            value={formatDateStr(
                                                extractionDetails.dataCollectionDateTime
                                            )}
                                        />

                                        <SidebarField
                                            dangerousHtml
                                            label={t("label.analysisResults")}
                                            value={`<a
                                                    href=${extractionDetails.dataFile}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    ${t("actions:download")}
                                                </a>`}
                                        />

                                        <SidebarField
                                            label={t("label.gasQuantity")}
                                            value={
                                                extractionDetails.extractedQuantity +" kg"
                                            }
                                        />

                                        <SidebarField
                                            label={t("label.gasGrade")}
                                            value={gradeNames[extractionDetails.grade]}
                                        />
                                    </>
                                ) : (
                                    <FlexDiv>
                                        <StyledInfo />
                                        <span>{t("label.waitingForResult")}</span>
                                    </FlexDiv>
                                )
                            ) : (
                                <div>
                                    {!stockItem.isContaminated &&
                                        !stockItem.isMixture &&
                                        !stockItem.hasR22 && (
                                            <NumSection>
                                                <Num className="tight">N/A</Num>
                                                <NumInfo>{t("actions:noContamination")}</NumInfo>
                                            </NumSection>
                                        )}

                                    {stockItem.isContaminated && (
                                        <NumSection>
                                            <Num>{++cnt}</Num>
                                            <NumInfo>{t("screens:stock.aGradeText")}</NumInfo>
                                        </NumSection>
                                    )}
                                    {stockItem.isMixture && (
                                        <NumSection>
                                            <Num>{++cnt}</Num>
                                            <NumInfo>{t("screens:stock.bGradeText")}</NumInfo>
                                        </NumSection>
                                    )}
                                    {stockItem.hasR22 && (
                                        <NumSection>
                                            <Num>{++cnt}</Num>
                                            <NumInfo>{t("screens:stock.cGradeText")}</NumInfo>
                                        </NumSection>
                                    )}
                                </div>
                            )}
                        </Slider>
                    </AccordionWrapper>
                )}

                <AccordionWrapper
                    title={t("title.unit.title")}
                    id="unit.title"
                    isOpen={openAccordion}
                    onToggle={setOpenAccordion}
                >
                    <StyledQR>
                        <QRCode value={extractionDetails.unitDetails.tagId} size={140} />
                        <TagTitle>{tagParts[0]}</TagTitle>
                        <span>{tagParts[1]}</span>
                    </StyledQR>

                    <br />

                    <SidebarField
                        label={t("label.unit.manufacturer")}
                        value={extractionDetails.unitDetails.manufacturerName}
                    />

                    <SidebarField
                        label={t("label.unit.applicationName")}
                        value={extractionDetails.unitDetails.applicationTypeName}
                    />

                    <SidebarField
                        label={t("label.unit.fGas")}
                        value={extractionDetails.unitDetails.refrigerantTypeCode}
                    />

                    <SidebarField
                        label={t("label.unit.capacity")}
                        value={extractionDetails.unitDetails.capacity}
                    />
                </AccordionWrapper>

                <AccordionWrapper
                    title={t("title.gallery")}
                    id="gallery"
                    isOpen={openAccordion}
                    onToggle={setOpenAccordion}
                >
                    <ImageGallery images={extractionDetails.unitDetails.images} showRows="2" />
                </AccordionWrapper>

                <AccordionWrapper
                    title="Notes"
                    id="Notes"
                    isOpen={openAccordion}
                    onToggle={setOpenAccordion}
                >
                    <p>
                        {extractionDetails.unitDetails.description
                            ? extractionDetails.unitDetails.description
                            : "-"}
                    </p>
                </AccordionWrapper>

                <AccordionWrapper
                    title={t("title.installationSite")}
                    id="installationSite"
                    isOpen={openAccordion}
                    onToggle={setOpenAccordion}
                >
                    <SidebarField
                        label={t("label.companyName")}
                        value={extractionDetails.clientName}
                    />

                    <SidebarField
                        label={t("label.companyStreet")}
                        value={extractionDetails.locationDetails.street}
                    />

                    <SidebarField
                        label={t("label.companyCity")}
                        value={extractionDetails.locationDetails.city}
                    />

                    <SidebarField
                        label={t("label.companyZIP")}
                        value={extractionDetails.locationDetails.postalCode}
                    />

                    <SidebarField
                        label={t("label.companyCountry")}
                        value={extractionDetails.locationDetails.countryName}
                    />

                    <DaikinMap
                        className="no-border"
                        style={{ marginTop: "24px", marginLeft: "20px" }}
                    >
                        <LocationMarker location={extractionDetails.locationDetails} />
                        <MapBounds markers={boundsMarkers} />
                    </DaikinMap>
                </AccordionWrapper>
            </>
        )
    );
};

export default ExtractionDetailSideBar;
