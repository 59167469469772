import React from "react";
import styled from "styled-components";
import { Select } from "../../../../components/elements/form-elements";
import InputElement from "../../../../components/elements/form-elements/InputElement";
import ValidationRule, {
    buildValidation,
} from "../../../../core/validations/ValidationRule";
import MoneyInputElement from "../../../../components/elements/form-elements/MoneyInputElement";

const validTest = (t, idx) => {
    return buildValidation({
        [`analysisImage_${idx}`]: ValidationRule.isStringRule().required(
            t("forms:qualityTest.errors.testRequired")
        ).rule,
        [`quality_${idx}`]: ValidationRule.isStringRule().required(
            t("forms:qualityTest.errors.gasTypeRequired")
        ).rule,
        [`quantity_${idx}`]: ValidationRule.isNumber(
            t("forms:qualityTest.errors.numberRequired")
        ).required(t("forms:qualityTest.errors.quantityRequired")).rule,
    });
};

const SellerInfo = styled.div`
    width: 100%;
    border-bottom: 1px solid #eee;
    margin-top: 10px;
    margin-bottom: 24px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 20px;

    & ul {
        margin: 0;
        list-style: none;
        padding: 0px 10px;
    }

    & ul.keys {
        font-weight: 600;
    }

    & .seller {
        padding: 0px 10px;
        width: 100%;
        font-size: 20px;
        font-weight: 600;
        color: ${(p) => p.theme.primaryColor};
    }
`;

const QualityTestWizard = (t, offerId, offerer, gasTypeCode, cylinders) => {
    const initialValues = {};
    const steps = [];

    cylinders.forEach((cyl) => {
        const idx = cyl.skuId;
        initialValues[`analysisImage_${idx}`] = "";
        initialValues[`analysisText_${idx}`] = null;
        initialValues[`analysisResult_${idx}`] = 9;
        initialValues[`quality_${idx}`] = cyl.gradeName;
        initialValues[`quantity_${idx}`] = cyl.quantity;
        initialValues[`skuId_${idx}`] = cyl.skuId;
        initialValues[`offerId_${idx}`] = offerId;

        steps.push({
            name: `${t("forms:qualityTest.cylinder")}: ${cyl.tagId}`,
            fieldNames: [
                `analysisImage_${idx}`,
                `quality_${idx}`,
                `quantity_${idx}`,
            ],
            validation: validTest(t, idx),
            fields: (formik) => (
                <>
                    <SellerInfo>
                        <div className="seller">{offerer}</div>
                        <ul className="keys">
                            <li>F-Gas:</li>
                            <li>Transaction ID:</li>
                        </ul>
                        <ul className="values">
                            <li>{gasTypeCode}</li>
                            <li>{offerId}</li>
                        </ul>
                    </SellerInfo>

                    <Select
                        id={`quality_${idx}`}
                        name={`quality_${idx}`}
                        labelText={t("forms:qualityTest.quality")}
                        wrapperVariant="flex-2"
                        defaultItem={{
                            key: `all_${idx}`,
                            value: "",
                            label: t("forms:qualityTest.quality"),
                        }}
                        defaultValue={initialValues[`quality_${idx}`]}
                        options={[
                            {
                                key: "A",
                                value: "A",
                                label: "A",
                            },
                            {
                                key: "B",
                                value: "B",
                                label: "B",
                            },
                            {
                                key: "C",
                                value: "C",
                                label: "C",
                            },
                        ]}
                        {...formik}
                    />

                    <MoneyInputElement
                        labelText={t("forms:qualityTest.quantity")}
                        currencyLabel="kg"
                        id={`quantity_${idx}`}
                        name={`quantity_${idx}`}
                        type="number"
                        step="0.01"
                        value={formik.values[`quantity_${idx}`]}
                        wrapperVariant="flex-2 standard"
                        formik={formik}
                    />

                    <InputElement
                        labelText=""
                        id={`analysisImage_${idx}`}
                        name={`analysisImage_${idx}`}
                        type="singlefile"
                        wrapperVariant="flex-1"
                        placeholder={t("forms:qualityTest.placeholder")}
                        title={t("actions:bidActions.qualityTest")}
                        {...formik}
                    />
                </>
            ),
        });
    });

    return {
        title: t("forms:qualityTest.title"),
        initialValues: initialValues,
        steps,
    };
};

export default QualityTestWizard;
