import styled, { css } from "styled-components";

const FlexRow = styled.div`
  flex-direction: row;
  ${props =>
    props.flexGrow &&
    css`
      flex-grow: ${props.flexGrow};
    `}
   ${props =>
    props.center &&
    css`
        text-align: center;
        justify-items: center;
    `}
   ${props =>
    props.flexBasis &&
    css`
        flex-basis: ${props.flexBasis};
    `}
   ${props => props.justifyContent && css`display:flex; justify-content: ${props.justifyContent};`}
`;

export default FlexRow;
