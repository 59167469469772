import { useEffect, useRef } from "react";
import useImmerReducer from "./useImmerReducer";

const initialDataState = {
    isLoading: false,
    data: null,
    error: null,
    isCompleted: false,
};

const useDataReducer = (dataMethod, preserveData = false) => {
    const renderCount = useRef(0);
    const [dataState, dispatch] = useImmerReducer(
        dataReducer,
        initialDataState
    );


    function dataReducer(state, action) {
        switch (action.type) {
            case "DATA_LOADING":
                state.isLoading = true;
                if(!preserveData) {
                    state.data = null;
                }
                state.error = null;
                state.isCompleted = false;
                break;
            case "DATA_SUCCESS":
                state.isLoading = false;
                state.data = action.payload;
                state.error = null;
                state.isCompleted = true;
                break;
            case "DATA_ERROR":
                state.isLoading = false;
                if(!preserveData) {
                    state.data = null;
                }
                state.error = action.payload;
                state.isCompleted = false;
                break;
            default:
                return state;
        }
    }

    const loading = () => {
        dispatch({ type: "DATA_LOADING" });
    };

    const error = (err) => {
        dispatch({ type: "DATA_ERROR", payload: err });
    };

    const success = (res) => {
        dispatch({ type: "DATA_SUCCESS", payload: res });
    };

    const executeAction = async (params) => {
        const currentRender = renderCount.current;
        try {
            loading();
            const res = await dataMethod(params);

            if (currentRender === renderCount.current) {
                success(res);
            }
        } catch (err) {
            if (currentRender === renderCount.current) {
                error(err);
            }
        }
    };
    useEffect(() => {
        renderCount.current++;
        return () => renderCount.current--;
    });
    return [dataState, executeAction];
};

export default useDataReducer;
