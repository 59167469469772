import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import UserService from "../../../../services/User/UserService";
import Roles from "../../../../core/enums/Roles";
import AuthorizeComponent from "../../../../core/AuthorizeComponent";
import RouteCreator from "../../../../core/RouteCreator";
import { NavLink } from "react-router-dom";
import { ReactComponent as KycValid } from "../../../../assets/kyc-valid.svg";
import { ReactComponent as KycInValid } from "../../../../assets/kyc-invalid.svg";
import Tooltip from "../../../../components/tooltip/Tooltip";
import useAuthorization from "../../../../core/useAuthorization";

const StyledKycValid = styled(KycValid)`
   opacity: 100%;
`;

const Link = styled(NavLink)`
    color: ${(p) => p.theme.primaryColorHover};
    text-decoration: none;
    &:hover {
        color: ${(p) => p.theme.primaryColorHover};
    }
  
    font-weight: bold;
    cursor: pointer;
    font-size: 14px;

`;

const KycWrapper = styled.div`
    @media ${(p) => p.theme.device.mobile} {
        margin: 0 15px 0 auto;
    }
    height: 32px;
`;

const StatusAction = styled.div`
    background: transparent;
    margin: 0;
    position: relative;

`;

const KycSemaphore = () => {
   
    const { t } = useTranslation("header");
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const canAccess = useAuthorization({
        allowedRoles: [Roles.InstallerCompanyAdmin, Roles.InstallerCompanyUser],
    });
    const {
        IsKYCValidated,
    } = UserService.getUserDetailsFromToken();

    const isValid = IsKYCValidated ==="True";

    const handleClick = (ev) => {
        if(!canAccess) ev.preventDefault();
     };

    const statusText = isValid ? t('screens:payments.kyc.headerStatus.valid'): t('screens:payments.kyc.headerStatus.nonValid');
    const toolTipText = t('screens:payments.kyc.headerStatus.text', {status :statusText });
    return (
        <KycWrapper>
          <StatusAction>
          <Link
                onClick={handleClick}
                to={canAccess ? RouteCreator.company.kyc() : ""}
                onMouseEnter={() => setTooltipVisible(true)}
                onMouseLeave={() => setTooltipVisible(false)}                               
          >
             { 
          
                isValid? <StyledKycValid/> : <KycInValid/>
             }
              <Tooltip  visible={tooltipVisible} tooltip={toolTipText} />
            </Link>
          </StatusAction>
         </KycWrapper>       
    );
};
export default KycSemaphore;
