import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { ReactComponent as NoDataSvg } from "../../../assets/no-file.svg";
import Button from "../../../components/elements/buttons/Button";
import useStateModule from "../../../core/state/useStateModule";
import userStateModule from "../../../core/state/user/userStateModule";

const InvalidCertWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    left: 0;
    top: 125px;
    width: 100%;
    height: calc(100% - 125px);
    z-index: 5;
    background-color: #f5f5f5;
    padding: 32px;
    font-size: 16px;
    font-weight: 600;
    color: #707070;

    & * {
        text-align: center;
    }

    & .title {
        color: #e94e4e;
        padding: 0px 32px;
    }

    & .subtitle {
        color: #000;
    }

    & .text {
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 20px;
    }
`;

const SvgNoData = styled(NoDataSvg)`
    width: 100%;
`;

const InvalidUserCert = () => {
    const { t } = useTranslation("screens");
    const { logout } = useStateModule(userStateModule);

    return (
        <InvalidCertWrapper>
            <div className="title">{t("certificates.userCert.missing")}</div>
            <SvgNoData />
            <div className="subtitle">
                {t("certificates.userCert.verification")}
            </div>
            <div>
                <div className="text">{t("certificates.userCert.allTech")}</div>
                <div className="text">
                    {t("certificates.userCert.requirement")}
                </div>
            </div>
            <Button onClick={()=>logout()} className="secondary--negative big centered">
                {t("header:header.logOut")}
            </Button>
            <div style={{ width: "100%", height: "30px" }} />
        </InvalidCertWrapper>
    );
};

export default InvalidUserCert;
