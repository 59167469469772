import styled, { css } from "styled-components";

const TextArea = styled.textarea`
    box-sizing: border-box;
    width: 100%;
    background: ${(props) => props.theme.inputBgColor};
    padding: 16px 16px;
    border: 1px solid ${(props) => props.theme.primaryInputBorderColor};
    border-radius: ${(props) => props.theme.inputBorderRadius};
    margin: 0 0 20px;
    resize: none;
    overflow-y: auto;
    ${(props) =>
        props.disabled &&
        css`
            background: #f5f5f5;
        `};
    height: ${(props) => `${props.theme.heightInputBigPx * (props.col=== undefined ?  3:props.col)}px`};
    font-size: ${(props) => props.theme.inputFontSize};

    ::placeholder {
        color: ${(props) => props.theme.inputPlaceholder};
    }

    ${(props) =>
        props.hasAction &&
        css`
            margin-bottom:5px;
        `};
`;

export default TextArea;
