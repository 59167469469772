import React, { useEffect, useState } from "react";
import RouteCreator from "../../core/RouteCreator";
import { useHistory } from "react-router-dom";

import styled from "styled-components";
import { useTranslation } from "react-i18next";
import useSharedState from "../../hooks/_shared/useSharedState";

const MenuBack = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    z-index: 1;
    transform: translateY(100%);

    &.open {
        transform: translateY(0);
    }
`;

const Wrapper = styled.div`
    box-sizing: border-box;
    padding: 15px 20px;
    background: #ffffff;
    color: #000000;
    position: fixed;
    top: 100%;
    left: 5%;
    width: 90%;
    height: 75%;
    display: flex;
    justify-content: space-between;
    align-content: center;
    transform: translateY(30px);
    transition: 0.3s ease all;
    z-index: 3;
    background: #f5f5f5;
    border-radius: 15px;
    box-shadow: 0 6px 40px rgba(0, 0, 0, 0.16);

    & * {
        user-select: none;
    }
    &.open {
        transform: translateY(-102%);
    }

    & .content {
        width: 100%;
        margin-top: 8px;
    }
`;

const MenuList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    user-select: none;
`;

export const Icon = styled.img`
    height: 29px;
    width: 28px;
`;

const CloseButton = styled.div`
    padding: 4px 16px;
    min-width: 150px;
    width: max-content;
    height: 32px;
    border-radius: 40px;
    background: #bc3535;
    color: #fff;
    position: absolute;
    z-index: 4;
    left: 50%;
    transform: translateX(-50%);
    top: -24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
    &:active {
        background: #961c1c;
    }
`;

const ListItem = styled.li`
    outline: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    list-style: none;
    transition: all 0.1s ease;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    margin: 16px 12px;
    padding: 0px 10px;
    background: #ededed;
    text-align: center;
    border-radius: 8px;
    font-size: 14px;
    font-weight: bold;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);

    &.disabled {
        background: #fefefe;
        box-shadow: none;
        color: #a0a0a0;
    }

    &:not(.disabled):active {
        background: ${(p) => p.theme.primaryColor};
        color: #fff;
    }

    & a {
        color: ${(props) => props.theme.menuItems};
        text-decoration: none;
        margin-left: 10px;
        &:hover {
            color: ${(props) => props.theme.textActive};
        }
    }
`;

const Space = styled.div`
    height: 4px;
`;

let listeners = [];
let state = { isOpen: false, active: 0 };

const setState = (newState) => {
    state = { ...state, ...newState };
    listeners.forEach((listener) => {
        listener(state);
    });
};

const useBottomMenu = () => {
    const newListener = useState()[1];
    useEffect(() => {
        // Called just after component mount
        listeners.push(newListener);
        return () => {
            // Called just before the component unmount
            listeners = listeners.filter(
                (listener) => listener !== newListener
            );
        };
    }, []);
    return [state, setState];
};

const menuItems = (t, globalState) => [
    {
        name: t("pwaMenu.newExtraction"),
        link: RouteCreator.pwa.extraction(),
        show: !globalState.isDistributor,
        disabled:!globalState.hasValidCompanyCertificate
    },
    { name: "_space_", link: "", show: !globalState.isDistributor },
    {
        name: t("pwaMenu.toCompanyStock"),
        link: RouteCreator.pwa.toCompanyStock(),
        show: true,
        disabled:!globalState.hasValidCompanyCertificate
    },
    {
        name: t("pwaMenu.toMyStock"),
        link: RouteCreator.pwa.toMyStock(),
        show: true,
        disabled:!globalState.hasValidCompanyCertificate
    },
    {
        name: t("pwaMenu.toBuyerStock"),
        link: RouteCreator.pwa.collect(),
        show: true,
        disabled:!globalState.hasValidCompanyCertificate
    },
    { name: "_space_", link: "", show: true },
    {
        name: t("pwaMenu.tagUnit"),
        link: RouteCreator.pwa.addUnit(),
        show: !globalState.isDistributor,
        disabled:!globalState.hasValidCompanyCertificate
    },
    {
        name: t("pwaMenu.tagCylinder"),
        link: RouteCreator.pwa.addCylinder(),
        show: true,
        disabled:!globalState.hasValidCompanyCertificate
    },
    {
        name: t("pwaMenu.tagAnalyzer"),
        link: RouteCreator.pwa.addAnalyzer(),
        show: true,
        disabled:!globalState.hasValidCompanyCertificate
    },
    { name: "_space_", link: "", show: !globalState.isDistributor },
    {
        name: t("pwaMenu.collectCylinder"),
        link: RouteCreator.pwa.collectEmptyCilinder(),
        show: true,
        disabled:!globalState.hasValidCompanyCertificate
    },
    {
        name: t("actions:startRecycling"),
        link: RouteCreator.pwa.recycleCylinder(),
        show: globalState.isDistributor,
        disabled:!globalState.hasValidCompanyCertificate
    },
    {
        name: t("pwaMenu.finishRecycling"),
        link: RouteCreator.pwa.finishRecycling(),
        show: globalState.isDistributor,
        disabled:!globalState.hasValidCompanyCertificate
    },
    // { name: t("pwaMenu.addQualityTest"), link: "", disabled: true },
    //Collect Extraction Cylinder collectCylinder
];

const BottomMenu = (props) => {
    const history = useHistory();
    const [menuState, setMenuState] = useBottomMenu();
    const { t } = useTranslation("screens");
    const [globalState] = useSharedState("global");

    const handleClick = (ev, item) => {
        if (item) {
            if (item.disabled) return;
            setTimeout(() => {
                item.link && history.push(item.link);
            }, 200);
        }
        setTimeout(() => {
            setMenuState({ isOpen: false });
        }, 200);
    };

    const shownItems = menuItems(t, globalState).filter(
        (item) => item.show === true
    );

    return (
        <>
            <MenuBack className={menuState.isOpen ? "open" : ""} />
            <Wrapper className={menuState.isOpen ? "open" : ""}>
                <CloseButton onClick={(ev) => handleClick(ev)}>
                    {t("pwaMenu.closeActions")}
                </CloseButton>
                <div className="content">
                    <MenuList>
                        {shownItems.map((item, index) => {
                            return item.name === "_space_" ? (
                                <Space key={index} />
                            ) : (
                                <ListItem
                                    className={item.disabled ? "disabled" : ""}
                                    key={index}
                                    onClick={(ev) => handleClick(ev, item)}
                                >
                                    {item.name}
                                </ListItem>
                            );
                        })}
                    </MenuList>
                </div>
            </Wrapper>
        </>
    );
};

export default BottomMenu;
export { useBottomMenu };
