import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
const Wrapper = styled.div``;

const Subtitle = styled.span`
    font-size: 16px;
    font-weight: 600;
    display: block;
`;

const Bullet = styled.span`
    display: list-item;

    &:after {
        content: ":";
    }

    &.no-content:after {
        content: "";
    }
    min-width: 140px;
`;

const FilterContainer = styled.div`
    margin: 10px 20px;
    font-size: 14px;
`;

const FilterRow = styled.div`
    display: flex;
    margin: 4px 0px;
`;

const FilterLabel = styled.div`
    padding: 0px 10px;
    font-weight: 500;
    color: #707070;
`;
const FilterValue = styled.div`
    font-weight: 600;
`;

const TotalContainer = styled.div`
    margin: 18px 0px;
`;
const FilterSummary = ({ filters, total }) => {
    const { t } = useTranslation();
    return (
        <Wrapper>
            {filters && filters.length > 0 && (
                <Subtitle>
                    {t("actions:dialogs.exportTransactions.subtitle")}
                </Subtitle>
            )}
            {filters && filters.length > 0 && (
                <FilterContainer>
                    {filters?.map((item) => (
                        <FilterRow>
                            <FilterLabel>
                                <Bullet>{item.label}</Bullet>
                            </FilterLabel>
                            <FilterValue>{item.value}</FilterValue>
                        </FilterRow>
                    ))}
                </FilterContainer>
            )}

            <TotalContainer>
                <Subtitle>
                    {t("actions:dialogs.exportTransactions.totalNumber")}
                </Subtitle>
                <FilterContainer>
                    <FilterLabel>
                        <Bullet className="no-content">
                            {`${total} ${t(
                                "actions:dialogs.exportTransactions.transactions"
                            )}`}
                        </Bullet>
                    </FilterLabel>
                </FilterContainer>
            </TotalContainer>
        </Wrapper>
    );
};

export default FilterSummary;
