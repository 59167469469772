import styled from "styled-components";

const Wrapper = styled.div`
        display: inline-block; /*needed because browsers are stupid */
        width: 100%;
        margin-bottom: 20px;

        &.buttons-wrapper {
            margin: 15px 0 0;
        }

        &.buttons-wrapper.mt-0{
            margin-top:0px;
        }

        &.form-links-wrapper {
            margin: 35px 0 0;
            /* padding-bottom: 100px; */
            position: relative;
            text-align: center;
        }

        &.edit-forms-wrapper {
            margin: 20px 0;
        }

        &.md-10 {
            margin-bottom:10px;
        }
    `;

export default Wrapper;
