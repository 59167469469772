import React from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import QrReader from "react-qr-reader";
import * as yup from "yup";
import InputElement from "../../../../components/elements/form-elements/InputElement";
import { Select } from "../../../../components/elements/form-elements";
import MoneyInputElement from "../../../../components/elements/form-elements/MoneyInputElement";

import {
    InfoText,
    InfoTitle,
    InfoWrapper,
    RoundInfo,
} from "../../../../components/modal/components/WizardStyling";
import { StyledQrReader } from "../../../../components/elements/qr/QrScanner";
import ValidationRule, {
    buildValidation,
} from "../../../../core/validations/ValidationRule";

import TagsService from "../../../../services/Tags/TagsService";

import { ReactComponent as WizardImage } from "../../../../assets/pwa/add-new-cylinder.svg";
import { ScanButton, KeyVal, HR } from "../../components/StyledElements";

import { SvgScan } from "../../components/StyledSvg";
import CylinderType from "../../../../core/enums/CylinderType";

const Content = styled.div`
    width: 100%;
    box-sizing: border-box;
    flex-grow: 1;
    position: relative;
    padding: 35px 60px 0px;
    display: flex;
    flex-direction: column;
    padding: 0px 0px 0px 0px;
    max-height: 500px;
    overflow: auto;
`;

const FlexCenter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const SvgWizardImage = styled(WizardImage)`
    width: auto;
    margin: 0 auto;
`;

const Section = styled.div`
    padding: 8px 20px;
    &.full-x {
        padding: 8px 0px;
    }

    &.flex {
        display: flex;
    }
`;

const ScanWrapper = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
`;

const Spacer = styled.div`
    height: 10px;
    width: 100%;
`;

const validUnusedTagId = (t) => {
    return buildValidation({
        unusedTagId: ValidationRule.isStringRule()
            .isUUID(t("forms:unit.uuid.invalid"))
            .required(t("forms:unit.unitTagId.required")).rule,
    });
};

const validUnusedTagAndSize = (t) => {
    return buildValidation({
        isValidUnusedTag: yup.boolean().required().oneOf([true], "Invalid Tag"),
        size: ValidationRule.min(
            0.0,
            t("forms:bid.wizard.minimumNetWeight"),
            t("forms:bid.wizard.minimumNetWeight")
        ).rule,
    });
};

const defaultInitialValues = {
    showTagScanner: false,
    isValidUnusedTag: false,
    unusedTagId: "",
    imageFile: "",
    size: "0.00",
    cylinderType: 0,
    ownerCompanyId: "",
};

const AddCylinderWizard = (
    t,
    cylOwners = [],
    unusedTagSearchFn,
) => {
    const ownerOptions = [
        { key: "me", value: "", label: t("forms:myCompany.text") },
        ...cylOwners.map((own) => ({
            key: own.companyId,
            value: own.companyId,
            label: own.companyName,
        })),
    ];

    const handleUnusedTagScan = (value, formik) => {
        if (value != null) {
            formik.setValues({
                ...formik.values,
                showTagScanner: false,
                unusedTagId: value.toLowerCase(),
            });
        }
    };

    return {
        title: t("forms:cylinder.wizard.pwaTitle"),
        initialValues: defaultInitialValues,
        steps: [
            {
                name: t("forms:cylinder.wizard.pwaStep1"),
                fieldNames: ["unusedTagId"],
                validation: validUnusedTagId(t),
                onStepSubmit: async ({ values }) => {
                    const tagId = values["unusedTagId"];

                    const tagResult =
                        await TagsService.lookupUnassignedTagsAsync(tagId);
                    if (tagResult.error || tagResult.data.data.length === 0) {
                        toast.error(t("forms:unit.wizard.invalidTag"));
                        return false;
                    }
                    return true;
                },
                fields: (formik) => {
                    return (
                        <>
                            <div
                                style={{
                                    display: formik.values.showTagScanner
                                        ? "none"
                                        : "block",
                                }}
                            >
                                <FlexCenter>
                                    <SvgWizardImage />
                                </FlexCenter>

                                <InfoTitle className="pwa">
                                    {t("forms:cylinder.wizard.infoTitle")}
                                </InfoTitle>
                                <InfoWrapper className="pwa">
                                    <RoundInfo
                                        alt=""
                                        src="/images/menu/green-info.svg"
                                    />
                                    <div>
                                        <InfoText className="pwa">
                                            {t(
                                                "forms:cylinder.newCylinderInfoText"
                                            )}
                                            <Spacer />
                                            {t(
                                                "forms:extraction.wizard.useCamera"
                                            )}
                                            <Spacer />
                                            {t(
                                                "forms:extraction.wizard.typeQrTag"
                                            )}
                                        </InfoText>
                                    </div>
                                </InfoWrapper>
                                <InputElement
                                    pwaBig
                                    noLabel
                                    labelText={t("forms:cylinderTagId.text")}
                                    id="unusedTagId"
                                    name="unusedTagId"
                                    type="search"
                                    direction="up"
                                    placeholder={t("screens:technician.qrTag")}
                                    searchFn={unusedTagSearchFn}
                                    wrapperVariant="flex-1"
                                    {...formik}
                                />
                            </div>

                            {formik.values.showTagScanner && (
                                <StyledQrReader className="wizard">
                                    <QrReader
                                        delay={300}
                                        onError={(error) => console.log(error)}
                                        onScan={(value) =>
                                            handleUnusedTagScan(value, formik)
                                        }
                                        style={{ width: "100%" }}
                                    />
                                </StyledQrReader>
                            )}

                            <ScanWrapper>
                                <ScanButton
                                    type="button"
                                    className={
                                        formik.values.showTagScanner
                                            ? "close"
                                            : ""
                                    }
                                    onClick={() =>
                                        formik.setFieldValue(
                                            "showTagScanner",
                                            !formik.values.showTagScanner
                                        )
                                    }
                                >
                                    <SvgScan className="white" />
                                </ScanButton>
                            </ScanWrapper>
                        </>
                    );
                },
            },
            {
                name: t("forms:extraction.wizard.title_step3"),
                fieldNames: [
                    "isValidUnusedTag",
                    "size",
                    "imageFile",
                    "cylinderType",
                    "ownerCompanyId",
                ],
                validation: validUnusedTagAndSize(t),
                onStepRender: async (props) => {
                    const tagId = props.value["unusedTagId"];
                    if (tagId !== props.propertyBag.tagId) {
                        const tagResult =
                            await TagsService.lookupUnassignedTagsAsync(tagId);
                        if (tagResult.error) {
                            toast.error(t(tagResult.error.message));
                            props.formik.setFieldValue(
                                "isValidUnusedTag",
                                false
                            );
                            return;
                        }
                        const tagData = tagResult.data.data;

                        if (tagData.length === 0) {
                            toast.error(t("forms:unit.wizard.invalidTag"));
                            props.formik.setFieldValue(
                                "isValidUnusedTag",
                                false
                            );
                            return;
                        }
                        props.formik.setFieldValue("isValidUnusedTag", true);
                        props.updatePropertyBag({ tagId: tagId });
                    } else {
                        props.formik.setFieldValue("isValidUnusedTag", true);
                    }
                },
                fields: (formik) => {
                    return (
                        <>
                            <Content>
                                {/* <HR className="content" /> */}
                                {/* <ListTitle className="wizard">
                                    {t("screens:technician.details")}
                                </ListTitle> */}
                                <Section>
                                    <KeyVal className="single">
                                        {t("forms:cylinder.cylinderGas")}
                                        <span>
                                            {t("forms:cylinder.noCylinderGas")}
                                        </span>
                                    </KeyVal>
                                    <KeyVal className="single">
                                        {t("screens:technician.Quantity")}
                                        <span>0.00 kg</span>
                                    </KeyVal>
                                </Section>
                                <HR className="content" />
                                {/* <ListTitle className="wizard">
                                    {t("forms:extraction.wizard.picture")}
                                </ListTitle> */}

                                <Select
                                    id="ownerCompanyId"
                                    name="ownerCompanyId"
                                    options={ownerOptions}
                                    labelText={t("sidebar:label.owner")}
                                    wrapperVariant="flex-1"
                                    {...formik}
                                />

                                <MoneyInputElement
                                    type="number"
                                    name="size"
                                    id="size"
                                    labelText={t("forms:cylinderSize.text")}
                                    wrapperVariant={"flex-1 standard"}
                                    values={2}
                                    min={0}
                                    step="0.01"
                                    positive={true}
                                    formik={formik}
                                    currencyLabel="kg"
                                    //grade={"A"}
                                />
                                {/* <Select
                                    id="size"
                                    name="size"
                                    options={[
                                        {
                                            key: "11.20",
                                            value: "11.20",
                                            label: "11.20 kg",
                                        },
                                        {
                                            key: "60.00",
                                            value: "60.00",
                                            label: "60.00 kg",
                                        },
                                    ]}
                                    defaultItem={{
                                        key: "all",
                                        value: "",
                                        label: t("forms:cylinderSize.text"),
                                    }}
                                    labelText={t("forms:cylinderSize.text")}
                                    wrapperVariant="flex-1"
                                    {...formik}
                                /> */}

                                <Select
                                    id="cylinderType"
                                    name="cylinderType"
                                    options={[
                                        {
                                            key: CylinderType.recovery,
                                            value: CylinderType.recovery,
                                            label: t(
                                                "filters:cylinders.recovery"
                                            ),
                                        },
                                        // {
                                        //     key: CylinderType.reclaimed,
                                        //     value: CylinderType.reclaimed,
                                        //     label: t(
                                        //         "filters:cylinders.reclaimed"
                                        //     ),
                                        // },
                                    ]}
                                    labelText={t("filters:cylinders.type")}
                                    wrapperVariant="flex-1"
                                    disabled
                                    {...formik}
                                />
                                <Spacer />
                                <InputElement
                                    labelText=""
                                    noLabel
                                    placeholder={t("forms:userPicture.text")}
                                    title={t(
                                        "forms:cylinder.wizard.cylinderPicture"
                                    )}
                                    name="imageFile"
                                    type="gallery"
                                    id="imageFile"
                                    single
                                    wrapperVariant="flex-1"
                                    autoHeight
                                    {...formik}
                                />
                            </Content>
                        </>
                    );
                },
            },
        ],
    };
};
export default AddCylinderWizard;
