import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import BidItem from "./BidItem";
import Globals from "../../globalVars";
import MoneyInputElement from "../elements/form-elements/MoneyInputElement";
import { Num } from "../elements/sidebar/SidebarStyle";

const BidsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 0 24px 0;
    flex-basis: 100%;
    width: 100%;
    margin: 0 2%;

    & > .handling {
        width: calc(50% - 16px);
    }
    & > .handling + .handling {
        margin-left: 32px;
    }
`;

const TotalBids = styled.div`
    width: 100%;
    margin: 0 2%;

    p {
        border-bottom: 1px solid #000;
        padding: 0 20px;
    }
`;

const Calculate = styled.div`
    display: flex;
    width: 100%;
`;

const CalcGrade = styled.div`
    flex: 1 1 0;
    width: 33%;
    font-size: 0.75rem;
`;

const GradeInfo = styled.div`
    padding: 4px 20px;
    //height: 52px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
        color: ${(props) => props.theme.textNeutral};
        line-height: 1rem;
    }
`;

const TotalPrice = styled.div`
    border-top: 1px solid ${(props) => props.theme.borderSeparator};
    font-size: 1rem;
    text-align: center;
    padding: 4px 0 0;
    font-weight: bold;

    & > div {
        color: #000;
    }

    &.net {
        border: 0;
        font-size: 12px;
        font-weight: normal;
    }

    &.total-a {
        color: ${(props) => props.theme.bidA};
    }
    &.total-b {
        color: ${(props) => props.theme.bidB};
    }
    &.total-c {
        color: ${(props) => props.theme.bidC};
    }
`;

const Bids = (props) => {
    const {
        offer,
        isLoading,
        formik,
        disabledGrades = [],
        gradeLabels = [],
        buyersDeposit = true,
        withVat = true,
        handlingStep = false,
        disableInputs = false,
    } = props;

    const [vatInfo, setVatInfo] = useState({
        percent: Globals.VATPercentage,
        percString: Globals.VATPercString,
    });

    const [prices, setPrices] = useState({
        A: 0,
        B: 0,
        C: 0,
        AVat: 0,
        BVat: 0,
        CVat: 0,
        handling: 0,
    });

    useEffect(() => {
        const vatPercentage =
            Globals.userCountryId === offer.offererCountryId
                ? Globals.VATPercentage
                : 1;
        const percString =
            Globals.userCountryId === offer.offererCountryId
                ? Globals.VATPercString
                : "0";

        setPrices({
            A: calculatePrice(formik.values.gradeA).toFixed(2),
            B: calculatePrice(formik.values.gradeB).toFixed(2),
            C: calculatePrice(formik.values.gradeC).toFixed(2),
            AVat: (
                calculatePrice(formik.values.gradeA) * vatPercentage
            ).toFixed(2),
            BVat: (
                calculatePrice(formik.values.gradeB) * vatPercentage
            ).toFixed(2),
            CVat: (
                calculatePrice(formik.values.gradeC) * vatPercentage
            ).toFixed(2),
            CVatHandling: (
                calculatePrice(formik.values.gradeC) * vatPercentage
            ).toFixed(2),
            handling: 0,
            transportationCosts: 0,
            administrationCosts: 0,
        });
        setVatInfo({ percent: vatPercentage, percString });
    }, [handlingStep]);

    const { t } = useTranslation();

    const handleAmount = (event) => {
        const amount = event.target.value;
        const grade = event.target.dataset.grade;
        setPrices((prevState) => ({
            ...prevState,
            [grade]: calculatePrice(amount).toFixed(2),
            [grade + "Vat"]: (calculatePrice(amount) * vatInfo.percent).toFixed(
                2
            ),
        }));

        return formik.handleChange(event);
    };

    const formikWithGradeHandling = Object.assign({}, formik, {
        handleChange: handleAmount,
    });

    const handleCosts = (event) => {
        const amount = Number(event.target.value);
        const cost = event.target.name;
        const otherCost =
            event.target.name === "transportationCosts"
                ? "administrationCosts"
                : "transportationCosts";
        const vatPercentage =
            Globals.userCountryId === offer.offererCountryId
                ? Globals.VATPercentage
                : 1;
        setPrices((prevState) => ({
            ...prevState,
            [cost]: amount,
            handling: (prevState[otherCost] + Number(amount)).toFixed(2),
            CVatHandling: (
                (calculatePrice(formik.values.gradeC) +
                    prevState[otherCost] +
                    Number(amount)) *
                vatPercentage
            ).toFixed(2),
        }));
        return formik.handleChange(event);
    };

    const formikWithCostsHandling = Object.assign({}, formik, {
        handleChange: handleCosts,
    });

    const calculatePrice = (amount) => {
        return Number(offer.maximumQuantity * Number(amount));
    };

    if (isLoading) {
        return null;
    }
    const bidItemsNum = 3 - disabledGrades.length;
    return (
        <>
            <BidsWrapper>
                {handlingStep && (
                    <>
                        <MoneyInputElement
                            type="number"
                            step="0.01"
                            name="transportationCosts"
                            id="transportationCosts"
                            labelText={t("forms:bid.wizard.transportCosts")}
                            wrapperVariant="handling bid-a"
                            // values={{ ["grade" + grade]: value }}
                            positive={true}
                            formik={formikWithCostsHandling}
                            grade="A"
                            currencyLabel="EUR"
                            disabled={disableInputs}
                        />

                        <MoneyInputElement
                            type="number"
                            step="0.01"
                            name="administrationCosts"
                            id="administrationCosts"
                            labelText={t("forms:bid.wizard.adminCosts")}
                            wrapperVariant="handling bid-c"
                            // values={{ ["grade" + grade]: value }}
                            positive={true}
                            formik={formikWithCostsHandling}
                            grade="B"
                            currencyLabel="EUR"
                            disabled={disableInputs}
                        />
                    </>
                )}
                {!handlingStep && !disabledGrades.includes("A") && (
                    <BidItem
                        t={t}
                        grade="A"
                        value={formik.values.gradeA}
                        minValue={0}
                        className={`bid-a size-${bidItemsNum}`}
                        positive
                        formik={formikWithGradeHandling}
                        labelText={
                            gradeLabels.length < 1 ? null : gradeLabels[0]
                        }
                    />
                )}
                {!handlingStep && !disabledGrades.includes("B") && (
                    <BidItem
                        t={t}
                        grade="B"
                        value={formik.values.gradeB}
                        minValue={0}
                        className={`bid-b size-${bidItemsNum}`}
                        positive
                        formik={formikWithGradeHandling}
                        labelText={
                            gradeLabels.length < 2 ? null : gradeLabels[1]
                        }
                    />
                )}
                {!handlingStep && !disabledGrades.includes("C") && (
                    <BidItem
                        t={t}
                        grade="C"
                        value={formik.values.gradeC}
                        className={`bid-c size-${bidItemsNum}`}
                        formik={formikWithGradeHandling}
                        labelText={
                            gradeLabels.length < 3 ? null : gradeLabels[2]
                        }
                    />
                )}
            </BidsWrapper>

            <TotalBids>
                <p>
                    {t("forms:bid.wizard.totalBids", {
                        gasType: offer.refrigerantGasTypeCode,
                    })}{" "}
                    :
                </p>

                <Calculate>
                    {!disabledGrades.includes("A") && (
                        <CalcGrade>
                            <GradeInfo>
                                <strong>
                                    {gradeLabels.length < 1
                                        ? `${t("forms:bid.wizard.grade", {
                                              grade: "A",
                                          })}  / ${offer.maximumQuantity.toFixed(
                                              2
                                          )} kg`
                                        : gradeLabels[0]}
                                </strong>
                                {buyersDeposit ? (
                                    <span>
                                        {t("forms:bid.wizard.buyersDeposit")}
                                    </span>
                                ) : (
                                    <span>&nbsp; </span>
                                )}
                                {withVat && (
                                    <TotalPrice className="total total-a net">
                                        {prices["A"]} EUR
                                        <div>
                                            + {t("filters:company.vat")} (
                                            {vatInfo.percString}%)
                                        </div>
                                    </TotalPrice>
                                )}
                            </GradeInfo>
                            <TotalPrice className="total total-a">
                                {prices[withVat ? "AVat" : "A"]} EUR
                            </TotalPrice>
                        </CalcGrade>
                    )}
                    {!disabledGrades.includes("B") && (
                        <CalcGrade>
                            <GradeInfo>
                                <strong>
                                    {gradeLabels.length < 2
                                        ? `${t("forms:bid.wizard.grade", {
                                              grade: "B",
                                          })}  / ${offer.maximumQuantity.toFixed(
                                              2
                                          )} kg`
                                        : gradeLabels[1]}
                                </strong>
                                <span>&nbsp; </span>
                                {withVat && (
                                    <TotalPrice className="total total-b net">
                                        {prices["B"]} EUR{" "}
                                        <div>
                                            + {t("filters:company.vat")} (
                                            {vatInfo.percString}%)
                                        </div>
                                    </TotalPrice>
                                )}
                            </GradeInfo>
                            <TotalPrice className="total total-b">
                                {prices[withVat ? "BVat" : "B"]} EUR
                            </TotalPrice>
                        </CalcGrade>
                    )}
                    {!disabledGrades.includes("C") && (
                        <CalcGrade>
                            <GradeInfo>
                                <strong>
                                    {gradeLabels.length < 3
                                        ? `${t("forms:bid.wizard.grade", {
                                              grade: "C",
                                          })}  / ${offer.maximumQuantity.toFixed(
                                              2
                                          )} kg`
                                        : gradeLabels[2]}
                                </strong>
                                <span>
                                    {t("forms:bid.wizard.sellersDeposit")}
                                </span>
                                {withVat && (
                                    <TotalPrice className="total total-c net">
                                        {prices["C"]} EUR
                                        {formik.values["chargeSeller"] &&
                                            ` + ${prices["handling"]} EUR`}
                                        <div>
                                            + {t("filters:company.vat")} (
                                            {vatInfo.percString}%)
                                        </div>
                                    </TotalPrice>
                                )}
                            </GradeInfo>
                            <TotalPrice className="total total-c">
                                {
                                    prices[
                                        withVat
                                            ? formik.values["chargeSeller"]
                                                ? "CVatHandling"
                                                : "CVat"
                                            : "C"
                                    ]
                                }{" "}
                                EUR
                            </TotalPrice>
                        </CalcGrade>
                    )}
                </Calculate>
            </TotalBids>
        </>
    );
};

export default Bids;
