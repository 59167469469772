import ValidationRule from './ValidationRule';

export default {
    email: (t) => {
        return ValidationRule
            .isStringRule()
            .emailRule(t('forms:email.invalid'))
            .required(t('forms:email.required'))
    },
    password: (t) => {
        return ValidationRule
            .isStringRule()
            .regexMatchRule(commonRegex.password, t('forms:password.regex'))
            .required(t('forms:password.required'))
    },
    passwordRequired: (t) => {
        return ValidationRule
            .isStringRule()
            .required(t('forms:password.required'))
    },
    vatNumber: (t) => {
        return ValidationRule
            .isStringRule()
            .regexMatchRule(commonRegex.VATRegExp, t('forms:VAT.invalid'))
    }
}


export const commonRegex = {
    usernameRegExp: /^([A-Za-z.]+)$/,
    firstNameRegExp: /^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
    lastNameRegExp: /^([A-Za-z0-9. -]+)$/i,
    VATRegExp: /^((AT)?U[0-9]{8}|(BE)?0[0-9]{9}|(BG)?[0-9]{9,10}|(CY)?[0-9]{8}L|(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)?[0-9]{8}|(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)?[0-9]{8}|(IE)?[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|(SE)?[0-9]{12}|(SI)?[0-9]{8}|(SK)?[0-9]{10}|(HR)?[0-9]{11})$/i,
    VATMatch: /^((AT)(U\d{8})|(BE)(0\d{9})|(BG)(\d{9,10})|(CY)(\d{8}[LX])|(CZ)(\d{8,10})|(DE)(\d{9})|(DK)(\d{8})|(EE)(\d{9})|(EL|GR)(\d{9})|(ES)([\dA-Z]\d{7}[\dA-Z])|(FI)(\d{8})|(FR)([\dA-Z]{2}\d{9})|(HU)(\d{8})|(IE)(\d{7}[A-Z]{2})|(IT)(\d{11})|(LT)(\d{9}|\d{12})|(LU)(\d{8})|(LV)(\d{11})|(MT)(\d{8})|(NL)(\d{9}(B\d{2}|BO2))|(PL)(\d{10})|(PT)(\d{9})|(RO)(\d{2,10})|(SE)(\d{12})|(SI)(\d{8})|(SK)(\d{10}))$/i,
    password: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d.*\d)(?!.*\s)(?=.*?[_#?!@$%^&*\-+()/[\]].*[#?!@$%^&*\-+()/[\]])(?!.*\s).{12,}$/i,
    UUID: /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
};


