import React, { useState } from "react";
import Tooltip from "../../components/tooltip/Tooltip";

const TooltipCell = ({ cell: { value }, tip, tooltipPosition="top right" }) => {
    const [tooltip, setTooltip] = useState({visible:false, tip:tip});

    const handleHover = (tooltipIsVisible) => {
        setTooltip((tooltip) => ({ ...tooltip, visible: tooltipIsVisible }));
    };

    return (
        <span style={{position:"relative"}}
            onMouseEnter={() => handleHover(true)}
            onMouseLeave={() => handleHover(false)}
        >
            {value}
            <Tooltip position={tooltipPosition} visible={tooltip.visible} tooltip={tooltip.tip} />
        </span>
    );
};

export default TooltipCell;
