const actionTypes = {
    SET_PAGING: "SET_PAGING",
    SET_SORT: "SET_SORT",
    SEARCH_EMAIL: "SEARCH_EMAIL",
    SEARCH_FULLNAME: "SEARCH_FULLNAME",
    SEARCH_COMPANYNAME: "SEARCH_COMPANYNAME",
    SEARCH_VAT: "SEARCH_VAT",
    SEARCH_LICENCENUMBER: "SEARCH_LICENCENUMBER",
    SEARCH_STATUS: "SEARCH_STATUS",
    SEARCH_STATUS_RESET: "SEARCH_STATUS_RESET",
    SEARCH_PARTNER_STATUS: "SEARCH_PARTNER_STATUS",
    SEARCH_PARTNER_STATUS_RESET: "SEARCH_PARTNER_STATUS_RESET",
    SET_COUNTRY:"SET_COUNTRY",
    RESET_COUNTRY:"RESET_COUNTRY",
};

export default actionTypes;
