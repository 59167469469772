import React, { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import useBreadcrumbs from "../../../core/state/navigation/useBreadcrumbs";
import { useTranslation } from "react-i18next";
import ListingPage from "../../../components/data/ListingPage";
import useImmerReducer from "../../../hooks/_shared/useImmerReducer";
import useDataReducer from "../../../hooks/_shared/useDataReducer";
import PaymentService from "../../../services/Payment/PaymentService";
import usePageChange from "../../../hooks/_shared/usePageChange";
import helpers from "../../../core/helpers";
import { Helmet } from "react-helmet";
import DocumentsFilter from "./components/DocumentsFilter";
import InvoiceDetailsSidebar from "./components/InvoiceDetailsSidebar";
import Flex from "../../../components/layout/flex/Flex";
import HamburgerMenu from "../../../components/menu/HamburgerMenu";
import HamburgerMenuAction from "../../../components/menu/HamburgerMenuAction";
import RoundTabs from "../../../components/elements/tabs/RoundTabs";
import ActionButton from "../../../components/elements/buttons/ActionButton";
import SellRefrigerantService from "../../../services/SellRefrigerant/SellRefrigerantService";
import DocumentType from "../../../core/enums/DocumentType";
import MangoTransferType from "../../../core/enums/MangoTransferType";
import MarketplaceService from "../../../services/BuyRefrigerant/MarketplaceService";
import useListingPageContext from "../../../core/state/useListingPage";
import {
    formatDateForExportDocument,
    ShortDateCell,
} from "../../../components/data/DateCell";
import { getApiUrl } from "../../../services/getAxios";

const actionTypes = {
    SET_SORT: "SET_SORT",
    RESET_SORT: "RESET_SORT",
    SET_MANGOTRANSACTION_ID: "SET_MANGOTRANSACTION_ID",
    RESET_MANGOTRANSACTION_ID: "RESET_MANGOTRANSACTION_ID",
    SET_PAGING: "SET_PAGING",
    SET_DOCUMENT_TYPE: "SET_DOCUMENT_TYPE",
    RESET_DOCUMENT_TYPE: "RESET_DOCUMENT_TYPE",
    SET_DATE_FROM: "SET_DATE_FROM",
    RESET_DATE_FROM: "RESET_DATE_FROM",
    SET_DATE_TO: "SET_DATE_TO",
    RESET_DATE_TO: "RESET_DATE_TO",
    SET_BUYER: "SET_BUYER",
    RESET_BUYER: "RESET_BUYER",
    SET_AMOUNT: "SET_AMOUNT",
    RESET_AMOUNT: "RESET_AMOUNT",
    SET_AMOUNT_TO: "SET_AMOUNT_TO",
    RESET_AMOUNT_TO: "RESET_AMOUNT_TO",
};

const documentsReducer = (state, action) => {
    if (action.type !== actionTypes.SET_PAGING) state.page = 1;
    switch (action.type) {
        case actionTypes.SET_PAGING:
            state.pageSize = action.payload.pageSize;
            state.page = action.payload.pageIndex + 1;
            break;
        case actionTypes.SET_SORT:
            state.orderColumn = action.payload[0].id;
            state.descending = action.payload[0].desc;
            break;
        case actionTypes.RESET_SORT:
            state.orderColumn = "executedDate";
            state.descending = true;
            break;
        case actionTypes.SET_AMOUNT_FROM:
            state.filter.amountFrom = Number(action.payload);
            break;
        case actionTypes.RESET_AMOUNT_FROM:
            delete state.filter.amountFrom;
            break;
        case actionTypes.SET_AMOUNT_TO:
            state.filter.amountTo = Number(action.payload);
            break;
        case actionTypes.RESET_AMOUNT_TO:
            delete state.filter.amountTo;
            break;
        case actionTypes.SET_DOCUMENT_TYPE:
            state.filter.documentType = action.payload;
            break;
        case actionTypes.RESET_DOCUMENT_TYPE:
            delete state.filter.documentType;
            break;
        case actionTypes.SET_DATE_FROM:
            state.filter.dateFrom = action.payload;
            break;
        case actionTypes.SET_DATE_TO:
            state.filter.dateTo = action.payload;
            break;
        case actionTypes.RESET_DATE_FROM:
            delete state.filter.dateFrom;
            break;
        case actionTypes.RESET_DATE_TO:
            delete state.filter.dateTo;
            break;
        case actionTypes.SET_MANGOTRANSACTION_ID:
            state.filter.mangoTransactionId = action.payload;
            break;
        case actionTypes.RESET_MANGOTRANSACTION_ID:
            delete state.filter.mangoTransactionId;
            break;
        case actionTypes.SET_BUYER:
            state.filter.buyer = action.payload;
            break;
        case actionTypes.RESET_BUYER:
            delete state.filter.buyer;
            break;
        default:
            return state;
    }
};

const EmptyGuid = "00000000-0000-0000-0000-000000000000";

const findAcceptedBid = (bids) => {
    return bids
        ? bids.find((b) => {
              if (b.bidStatus === 1) {
                  return true;
              }
              return null;
          })
        : null;
};

const DocumentListing = () => {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;
    const currency = "EUR";

    useBreadcrumbs([
        {
            text: t("navigation:finance"),
        },
        {
            text: t("navigation:documents"),
        },
    ]);

    const { state: listingPageState, actions: listingPageActions } =
        useListingPageContext();

    const [tabIndex, setTabIndex] = useState(0);

    const [filterState, dispatch] = useImmerReducer(documentsReducer, {
        filter: { documentType: DocumentType.payInOrder },
        page: 1,
        pageSize: 10,
        orderColumn: "executedDate",
        descending: true,
    });

    const [myDocuments, loadMyDocuments] = useDataReducer(async () => {
        var result = await PaymentService.getMyDocuments(filterState);
        return result;
    }, true);

    const [invoiceOffer, loadInvoiceOffer] = useDataReducer((offerId) => {
        if(offerId===EmptyGuid) return null;
        return SellRefrigerantService.getSellingItemById(offerId);
    }, true);

    const [detailViewIsOpened, setDetailViewIsOpened] = useState(false);
    const [bidData, setBidData] = useState({
        bid: null,
    });

    const [selectedDocument, setSelectedDocument] = useState(null);

    const pageChange = usePageChange(dispatch, filterState);

    useEffect(() => {
        return listingPageActions.resetState();
    }, []);

    useEffect(() => {
        loadMyDocuments(filterState);
    }, [filterState]);

    useEffect(() => {
        listingPageActions.resetState();
    }, [filterState.filter]);

    const setActiveTab = (tab) => {
        setTabIndex(tab);

        const activeDocumentType =
            tab === 0
                ? DocumentType.payInOrder
                : tab === 1
                ? DocumentType.receipt
                : DocumentType.invoice;
        dispatch({
            type: actionTypes.SET_DOCUMENT_TYPE,
            payload: activeDocumentType,
        });
    };

    const downloadSelected = async () => {
        const documentIds = listingPageState.selectedRows.map(
            (doc) => doc.original.documentId
        );
        const result = await PaymentService.downloadMyDocuments(documentIds);
        if (result && result.error) {
            toast.error(t(result.error.message));
        } else {
            const fileNamePrefix =
                tabIndex === 0
                    ? "PaymentOrders"
                    : tabIndex === 1
                    ? "TransactionReceipts"
                    : "SalesInformation";
            helpers.downloadFile(
                result.data,
                `${fileNamePrefix}_${formatDateForExportDocument()}.zip`,
                "application/zip"
            );

            await loadMyDocuments(filterState);
        }
    };

    const onSelect = useCallback(
        (row) => {
            const selectRow = async (row) => {
                if (row.original.offerId !== EmptyGuid) {
                    const bids = await MarketplaceService.getBids(
                        row.original.offerId
                    );

                    const acceptedBid = findAcceptedBid(bids);
                    setBidData({
                        bid: acceptedBid,
                    });
                } else {
                    setBidData({
                        bid: null,
                    });
                }
                await loadInvoiceOffer(row.original.offerId);
                setSelectedDocument(row.original);
                setDetailViewIsOpened(true);
            };
            selectRow(row);
        },
        [loadInvoiceOffer, setDetailViewIsOpened, setBidData]
    );

    const closeSideBar = () => {
        setDetailViewIsOpened(false);
    };

    const columns = useMemo(
        () => [
            {
                Header: t("table:headers.documents.transactionId"),
                accessor: "mangoPayTransactionId",
                disableSortBy: true,
            },
            {
                Header: t("table:headers.documents.executedDate"),
                accessor: "executedDate",

                Cell: ({ value }) => {
                    return value ? ShortDateCell({ cell: { value } }) : "-";
                },
            },
            {
                Header:
                    tabIndex === 2
                        ? t("table:headers.documents.buyer")
                        : t("table:headers.documents.beneficiary"),
                accessor: "buyer",
                disableSortBy: true,
            },
            {
                Header: t("table:headers.documents.amount"),
                accessor: "amount",
                Cell: ({ cell: { value } }) => {
                    return helpers.formatCurrency(
                        value,
                        currentLanguage,
                        currency
                    );
                },
            },
            {
                Header: t("table:headers.documents.description"),
                accessor: "transferType",
                Cell: ({ cell: { value } }) => {
                    let transferTypeTranslation;
                    if (MangoTransferType[value]) {
                        transferTypeTranslation = t(
                            "table:headers.transactions.transferType." +
                                MangoTransferType[value] +
                                ""
                        );
                    }

                    return transferTypeTranslation || value;
                },
                disableSortBy: true,
            },
            {
                Header: "",
                accessor: "documentId",
                Cell: ({ cell: { value } }) => (
                    <HamburgerMenu>
                        <HamburgerMenuAction
                            text={t("actions:view")}
                            onClick={() =>
                                window.open(
                                    `${getApiUrl}/Documents/${value}`,
                                    "_blank"
                                )
                            }
                        />
                    </HamburgerMenu>
                ),
                disableSortBy: true,
            },
        ],
        [t]
    );

    const offerAndBidData = useMemo(() => {
        return { offer: invoiceOffer?.data, bid: bidData.bid };
    }, [invoiceOffer, bidData]);

    const sortChange = (sortBy) => {
        if (sortBy.length > 0) {
            dispatch({ type: "SET_SORT", payload: sortBy });
        }
    };
    const currentSortBy = useMemo(() => {
        return [{ id: filterState.orderColumn, desc: filterState.descending }];
    }, [filterState.orderColumn, filterState.descending]);

    return (
        <>
            <Helmet>
                <title>{t("seo:documents.title")}</title>
            </Helmet>
            <ListingPage
                columns={columns}
                tableState={{
                    data: myDocuments.data ? myDocuments.data.data : [],
                    loading: myDocuments.isLoading,
                    error: myDocuments.error,
                }}
                paging={{
                    enablePaging: true,
                    pageCount: myDocuments.data
                        ? myDocuments.data.totalPages
                        : 1,
                    pageSize: myDocuments.data ? myDocuments.data.pageSize : 10,
                    totalRows: myDocuments.data
                        ? myDocuments.data.totalRows
                        : 1,
                    currentPageIndex: myDocuments.data
                        ? myDocuments.data.currentPage - 1
                        : 0,
                    onPageChange: pageChange,
                }}
                actions={{
                    headerTabs: () => {
                        return (
                            <RoundTabs
                                numTabs={3}
                                variant="wide"
                                tabIndex={tabIndex}
                                activeFn={setActiveTab}
                                tabs={[
                                    {
                                        label: t(
                                            "screens:documents.tabs.paymentOrder"
                                        ),
                                        activeValue: 0,
                                    },
                                    {
                                        label: t(
                                            "screens:documents.tabs.transactionReceipt"
                                        ),
                                        activeValue: 1,
                                    },
                                    {
                                        label: t(
                                            "screens:documents.tabs.proformaInvoice"
                                        ),
                                        activeValue: 2,
                                    },
                                ]}
                            />
                        );
                    },
                    headerActions: () => {
                        return (
                            <>
                                <Flex.Container
                                    justifyContent="flex-end"
                                    className="header-actions"
                                >
                                    <ActionButton
                                        className="primary"
                                        disabled={
                                            listingPageState.selectedRows
                                                .length < 1
                                        }
                                        onClick={downloadSelected}
                                        tooltip={t(
                                            "screens:documents.tooltip.downloadSelected"
                                        )}
                                    >
                                        {t(
                                            "screens:documents.actions.downloadSelected"
                                        )}
                                    </ActionButton>
                                </Flex.Container>
                            </>
                        );
                    },
                }}
                sorting={{
                    enableSorting: true,
                    onSortByChange: sortChange,
                    currentSortBy: currentSortBy,
                }}
                onSelect={onSelect}
                enableSelection={true}
                showSelectAll
                filterTitle={t("screens:documents.filterTitle")}
                filterComponent={() => {
                    return (
                        <DocumentsFilter
                            filterState={filterState.filter}
                            dispatch={dispatch}
                            actionTypes={actionTypes}
                        />
                    );
                }}
                variants={{
                    headerViewVariant: "right-action",
                }}
                getRowId={(row) => {
                    return row.documentId;
                }}
                closeSideBar={closeSideBar}
                detailViewIsOpened={detailViewIsOpened}
                detailView={
                    <InvoiceDetailsSidebar
                    key={detailViewIsOpened}    
                    selectedOffer={offerAndBidData}
                        offer={invoiceOffer.data}
                        isLoading={invoiceOffer.isLoading}
                        closeSideBar={closeSideBar}
                        document={selectedDocument}
                    />
                }
                detailSideBarTitle={t("sidebar:title.document.details")}
            />
        </>
    );
};

export default DocumentListing;
