import UserService from "../../../../services/User/UserService";
import createListStateModule from "../../createListStateModule";


const usersListStateModule = createListStateModule({
    stateNamespace: "users",
    itemsPropertyName: "users",
    loadItemsMethodName: "loadUsers",
    loadDetailMethodName: "loadUserById",
    detailsPropertyName: "userData",
    detailDataMethod: UserService.getUserById,
    itemsDataMethod: UserService.getNonAdministrators,
    deleteMethodName: "deleteUser",
    defaultItemsData: {data: []},
    deleteDataMethod: UserService.deleteUser
});

export default usersListStateModule;
