import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../components/elements/buttons/Button";
import SidebarField from "../../../../components/elements/sidebar/SidebarField";
import AccordionWrapper from "../../../../components/toggle-view/AccordionWrapper";
import { Num, NumInfo, NumSection } from "../../../../components/elements/sidebar/SidebarStyle";

import Slider from "../../../../components/elements/general/Slider";
import useSharedState from "../../../../hooks/_shared/useSharedState";
import useAccordionGroup from "../../../../hooks/_shared/useAccordionGroup";

const splitTag = (tag) => {
    if (!tag || tag.length < 8) return tag;
    return tag.substring(0, 4) + " " + tag.substring(4, 8);
};

const MarketplaceDetailSideBar = ({
    offer,
    details,
    item,
    isLoading,
    companyId,
    toggleBidModal,
    bidAlreadyPlaced,
}) => {
    const { t } = useTranslation(["sidebar"]);
    const shouldShow = item && !isLoading;
    const [globalState] = useSharedState("global");
    const [openAccordion, setOpenAccordion] = useAccordionGroup();
    const gradeNames = ["A", "B", "C"];

    const formatDateStr = (str) => {
        const fmt = Intl.DateTimeFormat("sk", {
            day: "numeric",
            month: "numeric",
            year: "numeric",
        });
        return fmt.format(new Date(str));
    };

    return (
        <>
            {shouldShow && (
                <>
                    <AccordionWrapper
                        title={t("title.details")}
                        id="details"
                        isOpen={openAccordion}
                        onToggle={setOpenAccordion}
                    >
                        {offer && (
                            <>
                                <SidebarField
                                    label={t("label.gasType")}
                                    value={offer.refrigerantGasTypeCode}
                                />

                                <SidebarField
                                    label={t("label.numberOfCylinders")}
                                    value={offer.numberOfCylinders}
                                />

                                <SidebarField
                                    label={t("label.netWeight")}
                                    value={`${offer.maximumQuantity.toFixed(2)} kg`}
                                />

                                <SidebarField
                                    label={t("label.totalExtractedQuantity")}
                                    value={`${offer.quantity.toFixed(2)} kg`}
                                />

                                <SidebarField
                                    label={t("label.extraction.qualityEvaluation")}
                                    value={
                                        item.hasAnalysis
                                            ? t("filters:analysis.measurement")
                                            : t("filters:analysis.selfAssessment")
                                    }
                                />
                            </>
                        )}
                    </AccordionWrapper>
                    {details.buyingItemData.skUs && (
                        <AccordionWrapper
                            id="contamination"
                            isOpen={openAccordion}
                            onToggle={setOpenAccordion}
                            title={t(
                                `title.contaminated.${
                                    details.buyingItemData.hasAnalysis ? "measured" : "selfAssessed"
                                }`
                            )}
                        >
                            <Slider
                                style={{ padding: "25px 20px 0px" }}
                                title={t("sidebar:title.extraction.extractionCylinder")}
                                captions={details.buyingItemData.skUs.map((sku) =>
                                    splitTag(sku.tagId)
                                )}
                            >
                                {details.buyingItemData.skUs.map((sku, idx) => {
                                    let cnt = 0;
                                    let exCnt = 0;
                                    return details.buyingItemData.hasAnalysis ? (
                                        <div>
                                            {sku.extractions.map((ex, idx) => (
                                                <NumSection>
                                                    <Num>{++exCnt}</Num>
                                                    <div style={{ flex: "1" }}>
                                                        <SidebarField
                                                            label={t("label.analysisDate")}
                                                            value={formatDateStr(
                                                                ex.dataCollectionDateTime
                                                            )}
                                                        />

                                                        <SidebarField
                                                            dangerousHtml
                                                            label={t("label.analysisResults")}
                                                            value={`<a
                                                    href=${ex.dataFile}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    ${t("actions:download")}
                                                </a>`}
                                                        />

                                                        <SidebarField
                                                            label={t("label.gasQuantity")}
                                                            value={ex.extractedQuantity + " kg"}
                                                        />

                                                        <SidebarField
                                                            label={t("label.gasGrade")}
                                                            value={gradeNames[ex.grade]}
                                                        />
                                                    </div>
                                                </NumSection>
                                            ))}
                                        </div>
                                    ) : (
                                        <div id={`slide-${idx + 1}`} key={idx}>
                                            {!sku.saIsContaminated &&
                                                !sku.saIsMixture &&
                                                !sku.saHasR22 && (
                                                    <NumSection>
                                                        <Num className="tight">N/A</Num>
                                                        <NumInfo>
                                                            {t("actions:noContamination")}
                                                        </NumInfo>
                                                    </NumSection>
                                                )}

                                            {sku.saIsContaminated && (
                                                <NumSection>
                                                    <Num>{++cnt}</Num>
                                                    <NumInfo>
                                                        {t("screens:stock.aGradeText")}
                                                    </NumInfo>
                                                </NumSection>
                                            )}
                                            {sku.saIsMixture && (
                                                <NumSection>
                                                    <Num>{++cnt}</Num>
                                                    <NumInfo>
                                                        {t("screens:stock.bGradeText")}
                                                    </NumInfo>
                                                </NumSection>
                                            )}
                                            {sku.saHasR22 && (
                                                <NumSection>
                                                    <Num>{++cnt}</Num>
                                                    <NumInfo>
                                                        {t("screens:stock.cGradeText")}
                                                    </NumInfo>
                                                </NumSection>
                                            )}
                                        </div>
                                    );
                                })}
                            </Slider>
                        </AccordionWrapper>
                    )}

                    <AccordionWrapper
                        id="sellerInfo"
                        isOpen={openAccordion}
                        onToggle={setOpenAccordion}
                        title={t("title.sellerInfo")}
                    >
                        <SidebarField label={t("title.company")} value={item.companyName} />
                        <SidebarField label={t("label.companyVAT")} value={item.vatNumber} />
                        <SidebarField label={t("label.companyStreet")} value={item.street} />
                        <SidebarField label={t("label.companyCity")} value={item.city} />
                        <SidebarField label={t("label.companyState")} value={item.region} />
                        <SidebarField label={t("label.companyZIP")} value={item.postalCode} />
                        <SidebarField label={t("label.companyCountry")} value={item.countryName} />
                        <SidebarField
                            label={t("label.companyContactPersonName")}
                            value={item.contactPerson}
                        />
                        <SidebarField label={t("label.companyEmail")} value={item.email} />
                        <SidebarField label={t("label.companyPhone")} value={item.phone} />
                    </AccordionWrapper>
                    {companyId && companyId !== item.companyId && !bidAlreadyPlaced && (
                        <div style={{ textAlign: "center" }}>
                            <Button
                                disabled={
                                    !globalState.hasValidCompanyCertificate |
                                    !globalState.hasCertificateRights
                                }
                                onClick={toggleBidModal}
                                className="primary"
                            >
                                {t("label.placeBid")}
                            </Button>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default MarketplaceDetailSideBar;
