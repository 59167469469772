import React, { Fragment } from "react";
import Bids from "../../../../components/bids/Bids";

const OfferBids = ({
    offers,
    isLoading,
    formik,
    disabledGrades = [],
    gradeLabels = [],
    buyersDeposit = true,
    withVat = true,
    handlingStep = false,
    disableInputs = false
}) => {

    return offers.map((offer) => {
        const key = `${offer.offerId}-${!handlingStep ? "first": "second"}`;
        return (
            <Fragment key={key}>
                <Bids
                    offer={offer}
                    isLoading={isLoading}
                    formik={formik}
                    disabledGrades={disabledGrades}
                    gradeLabels={gradeLabels}
                    buyersDeposit={buyersDeposit}
                    withVat={withVat}
                    handlingStep={handlingStep}
                    disableInputs = {disableInputs}
                />
            </Fragment>
        );
    });
};

export default OfferBids;
