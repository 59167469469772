import React, { useRef, useState } from "react";
import styled from "styled-components";
import useClickAway from "../../hooks/_shared/useClickAway";

export const Bar = styled.span`
    display: block;
    width: 100%;
    height: 2px;
    background: ${props => props.theme.secondaryColor};
    margin: 0 0 3px;
    transition: all 0.3s ease-in;
    border-radius: 1px;
`;

const Actions = styled.div`
    position: absolute;
    background: transparent;
    top: ${p=>p.up ? "auto":"-10px"};
    bottom: ${p=>p.up ? "-10px":"auto"};
    right: calc(100%);
    opacity: 0;
    pointer-events: none;
    min-width: 140px;
    z-index: 10;

    &.opened {
        pointer-events: auto;
        opacity: 1;
    }
    &::before {
        content: ' ';
        position: absolute;
        top: 0;
        left: 100%;
        width: 40px;
        height: 60%;
    }
`;

const ActionsWrapper = styled.div`
    background: white;
    border-radius: 8px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    padding: 16px 16px 0 16px;
    margin-right: 12px;
    min-height:32px;
`;

const Menu = styled.div`
    position: relative;
    width: 16px;
    text-align: right;
    height: 100%;
    float: right;

    &.opened {
        ${Bar} {
            &:nth-child(2) {
                width: calc(100% + 5px);
                margin: 0 0 3px -5px;
            }
        }
    }

    &.disabled {
        ${Bar} {
            background: #CCC;
        }
    }
`;

const HamburgerMenu = props => {
    const [menuIsOpened, setMenuIsOpened] = useState(false);
    const menuRef = useRef(null);
    useClickAway(menuRef, () => {
        setMenuIsOpened(false);
    });

    const {disabled} = props;

    const toggleMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setMenuIsOpened(!menuIsOpened);
    };
    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    return (
        <Menu
            ref={menuRef}
            onMouseEnter={!disabled ? toggleMenu : undefined}
            onMouseLeave={!disabled ? toggleMenu : undefined}
            onTouchStart={!disabled ? toggleMenu : undefined}
            onTouchEnd={!disabled ? toggleMenu : undefined}
            onClick={stopPropagation}
            className={[menuIsOpened && "opened", disabled && 'disabled']}
            data-test="user-action-wrapper"
        >
            <Bar/>
            <Bar/>
            <Bar/>
            {
                !disabled &&
                
                <Actions className={menuIsOpened && "opened"} up={props.up}>
                    <ActionsWrapper>{props.children}</ActionsWrapper>
                </Actions>
            }

        </Menu>
    );
};

export default HamburgerMenu;
