import React, { useEffect, useMemo, useCallback, useRef } from "react";
import globals from "../../../globalVars";
import ListingPage from "../../../components/data/ListingPage";
import useStateModule from "../../../core/state/useStateModule";
import { useTranslation } from "react-i18next";
import useImmerReducer from "../../../hooks/_shared/useImmerReducer";
import usePageChange from "../../../hooks/_shared/usePageChange";
import cylinderListStateModule from "../../../core/state/company/cylinderListStateModule";
import FilterMenu from "../FilterMenu";
import { Select } from "../../../components/elements/form-elements";
import InputElement from "../../../components/elements/form-elements/InputElement";
import CylinderType from "../../../core/enums/CylinderType";

import {
    StyledFilter,
    StyledArrow,
    StyledBack,
    StyledClose,
} from "./StyledSvg";

import {
    ScreenWrapper,
    ScreenHeader,
    FlexForm,
    FilterClear,
    FilterWrapper,
    HR,
    ListTitle,
} from "./StyledElements";

const actionTypes = {
    SET_PAGING: "SET_PAGING",
    SET_SORT: "SET_SORT",
    RESET_SORT: "RESET_SORT",
    SET_FILTERS: "SET_FILTERS",
    RESET_FILTERS: "RESET_FILTERS",
};

const filterReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.SET_PAGING:
            state.pageSize = action.payload.pageSize;
            state.page = action.payload.pageIndex + 1;
            break;
        case actionTypes.SET_SORT:
            state.orderColumn = action.payload[0].id;
            state.descending = action.payload[0].desc;
            break;
        case actionTypes.RESET_SORT:
            state.orderColumn = "extractionDateTime";
            state.descending = true;
            break;
        case actionTypes.SET_FILTERS:
            state.filter.refrigerantTypeCode =
                action.payload.refrigerantTypeCode;
            state.filter.quantityTo = action.payload.quantityTo;
            state.filter.quantityFrom = action.payload.quantityFrom;
            state.filter.size = action.payload.size;
            state.filter.cylinderType = action.payload.cylinderType;
            state.filter.cylinderSizeFrom = action.payload.cylinderSizeFrom;
            state.filter.cylinderSizeTo = action.payload.cylinderSizeTo;
            state.page = 1;
            break;
        case actionTypes.RESET_FILTERS:
            delete state.filter.refrigerantTypeCode;
            delete state.filter.quantityTo;
            delete state.filter.quantityFrom;
            delete state.filter.size;
            delete state.filter.cylinderType;
            delete state.filter.cylinderSizeFrom;
            delete state.filter.cylinderSizeTo;
            state.page = 1;
            break;
        default:
            return state;
    }
};

const CylinderStockListing = (props) => {
    const {
        cylinders,
        isLoading,
        error,
        loadCylinders,
        loadGasTypes,
        gasTypesData,
    } = useStateModule(cylinderListStateModule);

    const [filterState, dispatch] = useImmerReducer(filterReducer, {
        filter: {
            locationStatusName: "InstallerStock",
            marketplaceStatusName: "OnStock",
        },
        page: 1,
        pageSize: globals.maxListingItemsPerPage,
        orderColumn: "extractionDateTime",
        descending: true,
    });

    const { t } = useTranslation("screens");

    const filterMenuControl = {};

    useEffect(() => {
        loadCylinders(filterState);
    }, [loadCylinders, filterState, props.reset]);

    useEffect(() => {
        loadGasTypes();
    }, [loadGasTypes]);

    const columns = useMemo(() => {
        const columns = [
            {
                Header: t("table:headers.cylinders.gas"),
                accessor: "refrigerantTypeCode",
                Cell: ({ cell: { value } }) => (value ? value : "N/A"),
            },
            {
                Header: t("table:headers.cylinders.netWeight"),
                accessor: "size",
                className: "right",
                Cell: ({ cell: { value } }) => value.toFixed(2) + " kg",
            },

            {
                Header: t("table:headers.cylinders.quantity"),
                accessor: "filledQuantity",
                className: "right",
                Cell: ({ cell: { value } }) => value.toFixed(2) + " kg",
            },
            {
                Header: "",
                accessor: "skuId",
                className: "right-arrow",
                Cell: ({ cell: { value } }) => <StyledArrow />,
                disableSortBy: true,
            },
        ];
        return columns;
    }, [filterState.filter, t]);

    const filterData = useRef({
        quantityFrom: "",
        quantityTo: "",
        size: "",
        refrigerantTypeCode: "",
        cylinderType: "",
        cylinderSizeFrom: "",
        cylinderSizeTo: "",
    });

    const tempFilterData = useRef({});

    const hasFilters = () =>
        filterData.current.refrigerantTypeCode ||
        filterData.current.quantityFrom ||
        filterData.current.quantityTo ||
        filterData.current.size ||
        filterData.cylinderType ||
        filterData.cylinderSizeFrom ||
        filterData.cylinderSizeTo;

    const filterItems = () => {
        tempFilterData.current = {};
        return (
            <FlexForm autoComplete="off">
                {/* <Select
                    name="cylinderType"
                    key="cylinderType"
                    defaultItem={{
                        key: "all",
                        value: "",
                        label: t("filters:cylinders.type"),
                    }}
                    defaultValue={filterData.current.size}
                    options={[
                        {
                            key: CylinderType.recovery,
                            value: CylinderType.recovery,
                            label: t("filters:cylinders.recovery"),
                        },
                        {
                            key: CylinderType.reclaimed,
                            value: CylinderType.reclaimed,
                            label: t("filters:cylinders.reclaimed"),
                        },
                    ]}
                    handleChange={(evt) =>
                        (tempFilterData.current.cylinderType =
                            evt.currentTarget.value)
                    }
                    labelText={t("filters:cylinders.type")}
                    wrapperVariant="flex-1"
                />

                <Select
                    id="size"
                    name="size"
                    defaultItem={{
                        key: "",
                        value: "",
                        label: t("forms:cylinderSize.text"),
                    }}
                    options={[
                        {
                            key: "11.20",
                            value: "11.20",
                            label: "11.20 kg",
                        },
                        {
                            key: "60.00",
                            value: "60.00",
                            label: "60.00 kg",
                        },
                    ]}
                    labelText={t("forms:cylinderSize.text")}
                    wrapperVariant="flex-1"
                    defaultValue={filterData.current.size}
                    handleChange={(evt) =>
                        (tempFilterData.current.size = evt.currentTarget.value)
                    }
                /> */}

                <Select
                    className="filters big"
                    labelText={t("forms:gasType.text")}
                    name="refrigerantId"
                    id="refrigerantId"
                    wrapperVariant="flex-1"
                    defaultItem={{
                        key: "",
                        value: "",
                        label: t("forms:gasType.text"),
                    }}
                    options={
                        gasTypesData.gasTypes &&
                        gasTypesData.gasTypes.map((gasType) => {
                            return {
                                key: gasType.code,
                                value: gasType.code,
                                label: gasType.code,
                            };
                        })
                    }
                    defaultValue={filterData.current.refrigerantTypeCode}
                    handleChange={(evt) =>
                        (tempFilterData.current.refrigerantTypeCode =
                            evt.currentTarget.value)
                    }
                />

                <InputElement
                    labelText={t("filters:cylinders.sizeFrom")}
                    id="cylinderSizeFrom"
                    name="cylinderSizeFrom"
                    wrapperVariant="flex-2"
                    type="number"
                    className="filters big left"
                    defaultValue={filterData.current.cylinderSizeFrom}
                    handleChange={(evt) => {
                        tempFilterData.current.cylinderSizeFrom =
                            evt.currentTarget.value;
                    }}
                />

                <InputElement
                    labelText={t("filters:cylinders.sizeTo")}
                    id="cylinderSizeTo"
                    name="cylinderSizeTo"
                    wrapperVariant="flex-2"
                    type="number"
                    className="filters big right"
                    defaultValue={filterData.current.cylinderSizeTo}
                    handleChange={(evt) => {
                        tempFilterData.current.cylinderSizeTo =
                            evt.currentTarget.value;
                    }}
                />
            </FlexForm>
        );
    };

    const applyFilters = () => {
        Object.assign(filterData.current, tempFilterData.current);
        dispatch({
            type: actionTypes.SET_FILTERS,
            payload: filterData.current,
        });
    };

    const clearFilters = () => {
        filterData.current = {
            refrigerantTypeCode: "",
            quantityFrom: "",
            quantityTo: "",
            size: "",
            cylinderType: "",
            cylinderSizeFrom: "",
            cylinderSizeTo: "",
        };
        dispatch({
            type: actionTypes.RESET_FILTERS,
        });
    };

    const clearFilter = (name) => {
        switch (name) {
            case "cylinderType":
                filterData.current.cylinderType = "";
                break;
            case "weight":
                filterData.current.size = "";
                break;
            case "quantity":
                filterData.current.quantityFrom = "";
                filterData.current.quantityTo = "";
                break;
            case "gas":
                filterData.current.refrigerantTypeCode = "";
                break;
            case "size":
                filterData.current.cylinderSizeFrom = "";
                filterData.current.cylinderSizeTo = "";
                break;
            default:
                break;
        }
        dispatch({
            type: actionTypes.SET_FILTERS,
            payload: filterData.current,
        });
    };

    const showFilter = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        filterMenuControl.setState({
            isOpen: true,
            hasFilters: hasFilters(),
            filterItems: filterItems(),
            applyFn: applyFilters,
            clearFn: clearFilters,
            text: hasFilters()
                ? t("screens:stockListing.filters.clearFilters")
                : t("screens:cylinderListing.filterCylinders"),
        });
    };

    const onSelect = useCallback((row) => {
        props.onSelect && props.onSelect(row.original);
    }, []);

    const pageChange = usePageChange(dispatch, filterState);

    const sortChange = (sortBy) => {
        sortBy.length > 0
            ? dispatch({ type: actionTypes.SET_SORT, payload: sortBy })
            : dispatch({ type: actionTypes.RESET_SORT });
    };

    const currentSortBy = useMemo(() => {
        return [{ id: filterState.orderColumn, desc: filterState.descending }];
    }, [filterState.orderColumn, filterState.descending]);

    const curFilters = filterData.current;
    return (
        <ScreenWrapper>
            <FilterMenu control={filterMenuControl} />
            <ScreenHeader>
                <StyledBack className="disabled" />
                <FilterWrapper>
                    {curFilters.size && (
                        <FilterClear onClick={() => clearFilter("weight")}>
                            {t("screens:cylinderListing.weight")}
                            <StyledClose />
                        </FilterClear>
                    )}

                    {(curFilters.quantityFrom || curFilters.quantityTo) && (
                        <FilterClear onClick={() => clearFilter("quantity")}>
                            {t("screens:cylinderListing.quantity")}
                            <StyledClose />
                        </FilterClear>
                    )}

                    {(curFilters.cylinderSizeFrom ||
                        curFilters.cylinderSizeTo) && (
                        <FilterClear onClick={() => clearFilter("size")}>
                            {t("screens:cylinderListing.size")}
                            <StyledClose />
                        </FilterClear>
                    )}

                    {curFilters.refrigerantTypeCode && (
                        <FilterClear onClick={() => clearFilter("gas")}>
                            {t("screens:cylinderListing.gas")}
                            <StyledClose />
                        </FilterClear>
                    )}

                    {curFilters.cylinderType && (
                        <FilterClear
                            onClick={() => clearFilter("cylinderType")}
                        >
                            {t("filters:cylinders.type")}
                            <StyledClose />
                        </FilterClear>
                    )}

                    <StyledFilter onClick={showFilter} />
                </FilterWrapper>
            </ScreenHeader>
            <HR />
            <ListTitle>{t("screens:technician.cylinders")}</ListTitle>
            <ListingPage
                noHeader
                pwa
                columns={columns}
                tableState={{
                    data: cylinders.data,
                    loading: isLoading,
                    error: error,
                }}
                paging={{
                    enablePaging: true,
                    pageCount: cylinders.totalPages,
                    pageSize: cylinders.pageSize,
                    onPageChange: pageChange,
                    currentPageIndex: cylinders.currentPage - 1,
                }}
                sorting={{
                    enableSorting: true,
                    onSortByChange: sortChange,
                    currentSortBy: currentSortBy,
                }}
                onSelect={onSelect}
                noDataText={t("screens:stockListing.notification")}
            />
        </ScreenWrapper>
    );
};

export default CylinderStockListing;
