export default class ApiRouteError extends Error {
    constructor(msg) {
        super(msg);

        this.name = 'ApiRouteError';

        if ("captureStackTrace" in Error) {
            Error.captureStackTrace(this, ApiRouteError);
        } else {
            this.stack = (new Error()).stack;
        }
    }

    getMessages = () => {
        return [
            {
                fieldError: null,
                localizationKey: null,
                message: this.message,
                redirectUrl: this.redirectUrl
            }
        ];
    }

}
