import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { matchPath, NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";
import useActiveNavigationPath from "../../../core/state/navigation/useActiveNavigationPath";
import UserService from "../../../services/User/UserService";

const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    min-width: 30px;
`;

export const Icon = styled.img`
    width: 28px;
`;

export const SvgIcon = styled.svg`
    width: 28px;
`;

export const IconActive = styled.img`
    width: 28px;
    display: none;
`;

export const ListItem = styled.li`
    list-style: none;
    width: 100%;
    transition: all 0.5s ease-in;
    cursor: pointer;
    position: relative;

    &.top-item {
        &:hover:not(.active) {
            box-shadow: 0 0 8px 6px rgba(0, 0, 0, 0.1);
        }
    }

    a {
        color: ${(props) => props.theme.menuItems};
        text-decoration: none;

        &:hover {
            color: ${(props) => props.theme.primaryColorHover};
        }
    }

    .listitem-name {
        margin: 0 0 0 20px;
    }

    &.isClosed {
        .topitem-name {
            .active {
                padding-left: 0;
            }
        }

        .listitem-name {
            display: none;
        }

        &:hover {
            .popup {
                display: block;
            }
        }

        ${IconWrapper} {
            margin: 0;
        }
    }
`;

export const SubMenuItem = styled.ul`
    display: none;
    background: #E9F2ED;
    padding: 0 20px 0 50px;
    transition: all 0.5s ease-in;
    max-height: 1000px;
    overflow: hidden;
    box-shadow: inset 0px -15px 20px -23px rgba(0,0,0,0.35);

    &.hidden {
        max-height: 0;
        padding: 0;
    }

    &.popup {
        position: absolute;
        top: 0;
        left: calc(100% + 20px);
        z-index: 140;
        padding: 4px 16px;
        overflow: visible;
        background-color: #fff;
        min-width: 180px;
        border: 1px solid ${(props) => props.theme.primaryInputBorderColor || "#d4d4d4"};
        border-radius: ${(props) => props.theme.inputBorderRadius || "5px"};
        box-shadow: 4px 4px 16px 0 rgba(0, 0, 0, 0.16);

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: -24px;
            width: 24px;
            height: 100%;
        }

        .topitem-name a {
            color: ${props => props.theme.primaryColor};
            font-weight: 700;
            padding-left: 0;
        }

        > li {
            padding: 12px 0 12px 0;
            display: block;

            &:not(:last-child) {
                    border-bottom: 1px solid #e2e2e2;
            }

            a {
                padding: 0 0 0 20px;

                &.active {
                    padding: 0 0 0 20px;

                    &:before {
                        top: -2px !important;
                    }
                }
            }
        }
    }

    a {
        padding: 12px 0 12px 24px;
        display: inline-block;
        width: 100%;
    }

    li:first-child {
        padding-top: 12px;
    }

    li:last-child {
        padding-bottom: 12px;
    }

    ${ListItem} {
        &.active a {
            position: relative;
            color: ${(props) => props.theme.textActive};

            &:before {
                content: "";
                background: transparent url("/images/actions/arrow_right_primary.svg") no-repeat center;
                background-size: 10px auto;
                position: absolute;
                top: 9px;
                left: 0;
                width: 14px;
                height: 22px;
            }
        }
    }
`;

const TopItem = styled(NavLink)`
    color: ${(props) => props.theme.textColor};
    display: flex;
    align-items: center;
    padding: 16px;

    &.active {
        background-color: ${(props) => props.theme.bgMenuActive};
        color: ${(props) => props.theme.textWhite};
        position: relative;
        z-index: 50;
        &:hover {
            color: ${(props) => props.theme.textWhite};
        }

        ${Icon} {
            &.has-active {
                display: none;
            }
            &.invert {
                filter: brightness(0) invert(1);
            }
        }

        ${IconActive} {
            display: block;
        }

        ${ListItem}.isOpened & {
            ~ ${SubMenuItem} {
                display: block;
            }
        }
    }

    &.isOpened.active {
        box-shadow: 0 12px 20px 4px rgba(0, 0, 0, 0.24);
    }
`;

const DisabledItem = styled.span`
    padding: 12px 0 12px 24px;
    display: inline-block;
    width: 100%;
    cursor: auto;
    color: ${props => props.theme.textDisabled};

    &.topitem {
        display: flex;
        align-items: center;
        padding: 16px;

        span {
            flex-basis: 100%;
            display: flex;
            align-items: center;
        }
    }
`;

const LeftMenuListItem = (props) => {
    const {item, children, menuIsOpened, disabled = false} = props;
    const [isMouseOver, setMouseOver] = useState(false);
    const menuIsClosed = !menuIsOpened;
    const loc = useLocation();
    const userRoles = UserService.getUserRoles();
    const activePath = useActiveNavigationPath();
    const {t} = useTranslation();
    
    const isActive = (link) => {
        const currPath = activePath ? activePath : loc.pathname;
        if(link.activePaths && link.activePaths.includes(window.location.pathname)) return true;

        return matchPath(currPath, {
            path: link.url,
            exact: true,
            strict: false,
        })!==null;
    };

    const iconInlineStyle = item.icon.style || {};
    const iconClassName = item.icon.activeSrc ? 'has-active' : 'invert';

    return (
        <ListItem className={menuIsOpened ? "isOpened" : "isClosed"} onMouseEnter={() => setMouseOver(true)} onMouseLeave={() => setMouseOver(false)}>
            {
                !disabled ?
                    <TopItem
                        to={item.url}
                        activeClassName="active"
                        className={menuIsOpened ? "isOpened" : "isClosed"}
                    >
                        {
                            item.icon &&
                            <IconWrapper>
                                <Icon src={isMouseOver? item.icon.srcHover : item.icon.src}
                                      alt={item.icon.alt}
                                      className={iconClassName}
                                      style={iconInlineStyle}
                                />
                                {
                                    item.icon.activeSrc &&
                                    <IconActive src={item.icon.activeSrc}
                                                alt={item.icon.alt}
                                                style={iconInlineStyle}
                                    />
                                }
                            </IconWrapper>
                        }

                        <span className="listitem-name">{t(item.name)}</span>
                    </TopItem>
                    :
                    <DisabledItem className="topitem">
                        {item.icon &&
                        <span className={`active ${menuIsOpened ? "isOpened" : "isClosed"}`}>
                            <IconWrapper>
                                <Icon src={item.icon.src}
                                      alt={item.icon.alt}
                                      className={item.icon.activeSrc ? 'has-active' : 'invert'}
                                      style={iconInlineStyle}
                                />
                                {
                                    item.icon.activeSrc &&
                                    <IconActive src={item.icon.activeSrc}
                                                alt={item.icon.alt}
                                                style={iconInlineStyle}
                                    />
                                }
                            </IconWrapper>
                            <span className="listitem-name">{t(item.name)}</span>
                        </span>
                        }
                    </DisabledItem>
            }


            {children && (
                <SubMenuItem className={menuIsClosed ? "popup" : ""}>
                    {menuIsClosed && (
                        <li className="topitem-name">
                            {
                                item.url && !item.disabled ?
                                    (
                                        <NavLink to={item.url}>
                                            {t(item.name)}
                                        </NavLink>
                                    )
                                    :
                                    <DisabledItem>
                                        {t(item.name)}
                                    </DisabledItem>
                            }
                        </li>
                    )}

                    {children.map((subItem, index) => {
                        if (subItem.showForRoles) {
                            const shouldshow = userRoles.filter((role) =>
                                subItem.showForRoles.includes(role)
                            );
                            if (shouldshow && shouldshow.length > 0) {
                                return (
                                    <ListItem
                                        key={t(subItem.name) + index}
                                        className={
                                            isActive(subItem) ? "active" : ""
                                        }
                                    >
                                        {
                                            !subItem.disabled
                                                ?
                                                <NavLink
                                                    to={subItem.url}
                                                    activeClassName="active"
                                                    exact={true}
                                                    className={
                                                        menuIsClosed ? "isClosed" : ""
                                                    }
                                                >
                                                    {t(subItem.name)}
                                                </NavLink>
                                                :
                                                <DisabledItem>
                                                    {t(subItem.name)}
                                                </DisabledItem>

                                        }

                                    </ListItem>
                                );
                            } else {
                                return null;
                            }
                        } else {
                            return (
                                <ListItem
                                    key={subItem.name + index}
                                    className={
                                        isActive(subItem) ? "active" : ""
                                    }
                                >
                                    {
                                        !subItem.disabled
                                            ? <NavLink
                                                to={subItem.url}
                                                activeClassName="active"
                                                exact={true}
                                                className={
                                                    menuIsClosed ? "isClosed" : ""
                                                }
                                                disabled={subItem.disabled}
                                            >
                                                {t(subItem.name)}
                                            </NavLink>
                                            :
                                            <DisabledItem>
                                                {t(subItem.name)}
                                            </DisabledItem>
                                    }

                                </ListItem>
                            );
                        }
                    })}
                </SubMenuItem>
            )}
        </ListItem>
    );
};

export default LeftMenuListItem;
