import RouteCreator from "../../core/RouteCreator";
import CompanyType from "../../core/enums/CompanyType";

const globalAdminMenuItems = [
    {
        name: "navigation:users",
        icon: {
            src: "/images/menu/users.svg",
            srcHover: "/images/menu/users_hover.svg",
            activeSrc: "/images/menu/users_active.svg",
            alt: "Users",
        },
        isTop: true,
        url: RouteCreator.admin.users.root(),
        children: [
            {
                name: "navigation:f-cycleUsers",
                url: RouteCreator.admin.users.nonAdmins(),
            },
            {
                name: "navigation:f-cycleAdmins",
                url: RouteCreator.admin.users.admins(),
            },
        ],
    },
    {
        name: "navigation:companies",
        icon: {
            src: "/images/menu/companies.svg",
            srcHover: "/images/menu/companies_hover.svg",
            activeSrc: "/images/menu/companies_active.svg",
            alt: "Companies",
        },
        isTop: true,
        url: RouteCreator.admin.companies.root(),
        children: [
            {
                name: "navigation:installationPartners",
                url: RouteCreator.admin.companies.list(
                    CompanyType.InstallationCompany
                ),
            },
            {
                name: "navigation:distributors",
                url: RouteCreator.admin.companies.list(CompanyType.Distributor),
            },
        ],
    },
    {
        name: "navigation:salesCenter",
        icon: {
            src: "/images/menu/sales_center.svg",
            srcHover: "/images/menu/sales_center_hover.svg",
            activeSrc: "/images/menu/sales_center_active.svg",
            alt: "Marketplace",
        },
        isTop: true,
        url: RouteCreator.admin.marketplaceStatistics(),
        children: [
            {
                name: "navigation:recoveredRefrigerant",
                url: RouteCreator.admin.marketplace(),
            },
            {
                name: "navigation:reclaimedRefrigerant",
                url: RouteCreator.admin.reclaimedMarketplace(),
            },
        ]
    },
    {
        name: "navigation:services",
        url: RouteCreator.admin.services.root(),
        icon: {
            src: "/images/menu/services.svg",
            srcHover: "/images/menu/services_hover.svg",
            activeSrc: "/images/menu/services_active.svg",
            alt: "Services",
            style: {
                width: "32px",
            },
        },
        children: [
            {
                name: "navigation:disposalService",
                url: RouteCreator.admin.services.disposal(),
            },
            {
                name: "navigation:cleaningService",
                url: RouteCreator.admin.services.cleaning(),
            },
        ],
    },
    {
        name: "navigation:qrTags",
        icon: {
            src: "/images/menu/qr.svg",
            srcHover: "/images/menu/qr_hover.svg",
            activeSrc: "/images/menu/qr_active.svg",
            alt: "QR Tags",
        },
        isTop: true,
        url: RouteCreator.admin.qrTags(),
    },
];

export default globalAdminMenuItems;
