import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import RouteCreator from "../../../core/RouteCreator";
import { toast } from "react-toastify";
import ListingPage from "../../../components/data/ListingPage";
import GreenCell from "../../../components/data/GreenCell";
import useBreadcrumbs from "../../../core/state/navigation/useBreadcrumbs";
import TabLinks from "../../../components/elements/tabs/TabLinks";
import useSharedState from "../../../hooks/_shared/useSharedState";
import useReclaimedMarketFilterState from "./components/ReclaimedMarketFilterState";
import ReclaimedMarketplaceFilter from "./components/ReclaimedMarketFilter";
import AuthorizeComponent from "../../../core/AuthorizeComponent";
import Roles from "../../../core/enums/Roles";
import Flex from "../../../components/layout/flex/Flex";
import ActionButton from "../../../components/elements/buttons/ActionButton";
import ModalWizard from "../../../components/modal/ModalWizard";
import WizardAddReclaimOffer from "./components/wizard/WizardAddReclaimOffer";
import MarketplaceService from "../../../services/BuyRefrigerant/MarketplaceService";
import DateCell from "../../../components/data/DateCell";
import RoundTabs from "../../../components/elements/tabs/RoundTabs";
import HamburgerMenu from "../../../components/menu/HamburgerMenu";
import HamburgerMenuAction from "../../../components/menu/HamburgerMenuAction";
import { CustomDateCellWithExpiration } from "../../../components/data/DateCellWithExpiration";
import ReclaimedOfferStatus from "../../../core/enums/ReclaimedOfferStatus";
import { useModal } from "../../../components/modal/ModalDialog";
import ModalDialog from "../../../components/modal/ModalDialog";
import ReclaimedMarketSidebar from "./components/ReclaimedMarketSidebar";

const dealExpirationDays = 7;

const tabItems = (t) => [
    {
        name: t("navigation:myOffers"),
        path: RouteCreator.salesCenter.reclaimedMarketOffers(),
    },
];

const ReclaimedMarketOffers = () => {
    const { t, i18n } = useTranslation(["screens", "table"]);
    const [globalState] = useSharedState("global");
    const [tabIndex, setTabIndex] = useState(0);
    const [
        filterState,
        filterDispatch,
        filterActions,
        sortChange,
        currentSortBy,
        pageChange,
        gasTypes,
    ] = useReclaimedMarketFilterState();
    const [detailViewIsOpened, setDetailViewIsOpened] = useState(false);
    const [selected, setSelected] = useState({
        offer: null,
        details: null,
        showCancel: false,
    });

    const [tableState, setTableState] = useState({
        data: [],
        loading: false,
        error: null,
    });

    useBreadcrumbs([
        {
            text: t("navigation:salesCenter"),
        },
        {
            text: t("navigation:myOffers"),
        },
    ]);

    const getDistributorReclaimedOffers = async () => {
        const res = await MarketplaceService.getDistributorReclaimedOffers(
            filterState
        );
        setTableState({ data: res.data.data });
    };

    useEffect(() => {
        getDistributorReclaimedOffers();
    }, [filterState]);

    const setActiveTab = (tab) => {
        let offStatus = 0;
        switch (tab) {
            case 0:
                offStatus = ReclaimedOfferStatus.Offered;
                break;
            case 1:
                offStatus = ReclaimedOfferStatus.WaitingForBuyerFunds;
                break;
            case 2:
                offStatus = ReclaimedOfferStatus.Sold;
                break;
            default:
                break;
        }

        if (tab === 2) {
            filterDispatch({
                type: filterActions.SET_SORT,
                payload: [{ id: "lastChangeDateTime", desc: true }],
            });
        } else {
            filterDispatch({
                type: filterActions.SET_SORT,
                payload: [{ id: "offerDateTime", desc: false }],
            });
        }
        setTabIndex(tab);
        filterDispatch({
            type: filterActions.RESET_FILTERS,
            payload: null,
        });
        filterDispatch({
            type: filterActions.SET_OFFER_STATUS,
            payload: offStatus,
        });
    };

    //publish action
    const publishOffer = () => {
        return ModalWizard.show(WizardAddReclaimOffer(t, gasTypes), {
            onSubmit: async (values) => {
                values.quantity = Number(values.quantity);
                values.grade = Number(values.grade);
                values.price = Number(values.price);
                values.cylinderNetWeight = Number(values.cylinderNetWeight);
                const result = await MarketplaceService.addReclaimedOffer(
                    values
                );

                if (result && result.error)
                    ModalWizard.submitFailed(t(result.error.message));
                else {
                    getDistributorReclaimedOffers();
                    toast.success(t("marketplace.toastNotification.bidPlaced"));
                    //closeBidSidebar();
                    ModalWizard.hide();
                }
            },
            onCancel: async () => {
                ModalWizard.hidePrompt();
            },
        });
    };

    //Unpublish action
    const { modalProps: unpublishProps, toggleOpen: unpublishTogle } = useModal(
        {
            title: t("actions:dialogs.unPublishOffer.title"),
            type: "warning",
            onConfirm: async (model) => {
                const result = await MarketplaceService.unpublishReclaimedOffer(
                    {
                        offerIds: [model.data.reclaimedOfferId],
                    }
                );
                if (result && result.error) {
                    toast.error(t(result.error.message));
                } else {
                    getDistributorReclaimedOffers();
                    toast.success(
                        t("myOffers.toastNotification.offerUnpublished")
                    );
                }
                unpublishTogle();
            },
            onCancel: () => {
                unpublishTogle();
            },
            confirmText: t("actions:dialogs.unPublishOffer.confirmText"),
        }
    );

    //cancel action
    const { modalProps: cancelProps, toggleOpen: cancelTogle } = useModal({
        title: t("actions:dialogs.declineBid.title"),
        type: "warning",
        onConfirm: async (model) => {
            const result = await MarketplaceService.cancelReclaimedOffer(
                model.data.reclaimedOfferId
            );
            if (result && result.error) {
                toast.error(t(result.error.message));
            } else {
                getDistributorReclaimedOffers();
                toast.success(
                    t("actions:dialogs.declineBid.toastNotification.success")
                );
            }
            cancelTogle();
        },
        onCancel: () => {
            cancelTogle();
        },
        confirmText: t("actions:dialogs.declineBid.confirmText"),
    });

    const columns = useMemo(() => {
        const tabColumns = [
            {
                Header: t("table:headers.gasType"),
                accessor: "type",
                Cell: GreenCell,
            },
            {
                Header: t("table:headers.gasQuantity"),
                accessor: "quantity",
                Cell: ({ cell: { row, value } }) =>
                    GreenCell({
                        cell: {
                            value: value.toFixed(2) + ` kg`,
                        },
                    }),
            },
            {
                Header: t("table:headers.grade"),
                accessor: "grade",
                Cell: ({ cell: { value } }) => t("filters:cylinders.reclaimed"),
            },
            {
                Header: t("table:headers.buyer"),
                accessor: "buyer",
            },
            {
                Header: t("forms:cylinder.price"),
                accessor: "price",
                Cell: ({ cell: { row, value } }) => {
                    const ppk = (value / row.original.quantity).toFixed(2);
                    return (
                        <span
                            title={`${ppk} EUR ${t(
                                "forms:cylinder.perKiloReclaimed"
                            )}`}
                        >
                            {`${value.toFixed(2)} EUR`}
                        </span>
                    );
                },
            },
        ];

        if (tabIndex === 0)
            tabColumns.push({
                Header: t("table:headers.published"),
                accessor: "offerDateTime",
                Cell: (params) => {
                    return DateCell(params);
                },
            });

        if (tabIndex === 1)
            tabColumns.push({
                Header: t("stockListing.headers.dealExpiration"),
                accessor: "offerDateTime",
                Cell: ({ cell: { value }, row: { original } }) => {
                    let translation = "-";
                    const offerStatus = original.status;
                    let date = new Date(value);
                    date.setDate(date.getDate() + dealExpirationDays);
                    if (offerStatus === 2) {
                        translation = "screens:myOffers.offerStatus.2";
                    } else if (offerStatus === 3) {
                        translation = "screens:myOffers.offerStatus.3";
                    } else if (offerStatus === 4) {
                        translation = "screens:myOffers.offerStatus.4";
                    }
                    return CustomDateCellWithExpiration(date, translation);
                },
            });

        if (tabIndex === 2)
            tabColumns.push({
                Header: t("table:headers.soldDate"),
                accessor: "lastChangeDateTime",
                Cell: DateCell,
            });

        tabColumns.push({
            Header: "",
            accessor: "reclaimedOfferId",
            Cell: ({ cell: { value }, row: { original } }) => {
                let disableCancel = true;
                if (original.status === 2) {
                    let expDate = new Date(original.offerDateTime);
                    expDate.setDate(expDate.getDate() + dealExpirationDays);
                    disableCancel = expDate > new Date();
                }
                return (
                    <AuthorizeComponent
                        roles={[
                            Roles.InstallerCompanyAdmin,
                            Roles.InstallerCompanyUser,
                        ]}
                    >
                        <HamburgerMenu>
                            <HamburgerMenuAction
                                disabled={original.status !== 0}
                                text={t("actions:unpublishFromMarketplace")}
                                onClick={async () => {
                                    unpublishTogle({ data: original });
                                }}
                            />
                            <HamburgerMenuAction
                                disabled={disableCancel}
                                text={t("actions:cancel")}
                                onClick={async () => {
                                    cancelTogle({ data: original });
                                }}
                            />
                        </HamburgerMenu>
                    </AuthorizeComponent>
                );
            },
        });
        return tabColumns;
    }, [t, tabIndex]);

    const onSelect = useCallback(
        (row) => {
            const selectRow = async (offerId) => {
                const selectedOffer = tableState.data.filter(
                    (of) => of.reclaimedOfferId === offerId
                )[0];
                const res = await MarketplaceService.getReclaimedOfferById({
                    id: offerId,
                });
                let showCancel = false;
                if (selectedOffer.status === 2) {
                    let expDate = new Date(selectedOffer.offerDateTime);
                    expDate.setDate(expDate.getDate() + dealExpirationDays);
                    showCancel = expDate < new Date();
                }
                setSelected({
                    details: res.data.data[0],
                    offer: selectedOffer,
                    showCancel: showCancel,
                });
                setDetailViewIsOpened(true);
            };
            selectRow(row.original.reclaimedOfferId);
        },
        [tableState]
    );

    return (
        <>
            <Helmet>
                <title>{t("seo:marketplace.title")}</title>
            </Helmet>
            <TabLinks
                variant="shadowed"
                items={tabItems(t, globalState.isDistributor)}
                idx={0}
                style={{ marginTop: "0px", padding: "10px 0px 10px 40px" }}
            />
            <ListingPage
                key={tabIndex}
                columns={columns}
                tableState={tableState}
                sorting={{
                    enableSorting: true,
                    onSortByChange: sortChange,
                    currentSortBy: currentSortBy,
                }}
                paging={{
                    enablePaging: true,
                    pageCount: filterState.totalPages,
                    pageSize: filterState.pageSize,
                    onPageChange: pageChange,
                    currentPageIndex: filterState.page - 1,
                }}
                detailSideBarTitle={`${t("sidebar:title.offerId")}: ${
                    selected.offer?.offerNumber
                }`}
                detailView={
                    <ReclaimedMarketSidebar
                        details={selected.details}
                        offer={selected.offer}
                        isDistributor={globalState.isDistributor}
                        offerStatus={filterState.reclaimedOfferStatus}
                        showCancel={selected.showCancel}
                        cancelAction={cancelTogle}
                    />
                }
                onSelect={onSelect}
                closeSideBar={() => setDetailViewIsOpened(false)}
                detailViewIsOpened={detailViewIsOpened}
                filterComponent={() => {
                    return (
                        <ReclaimedMarketplaceFilter
                            filterState={filterState.filter}
                            dispatch={filterDispatch}
                            actionTypes={filterActions}
                            gasTypes={gasTypes}
                        />
                    );
                }}
                filterTitle={t("filters:reclaimedMarketPlace.filterTitle")}
                showTitle={true}
                variants={{
                    headerViewVariant: "right-action",
                }}
                actions={
                    globalState.isDistributor && {
                        headerTabs: () => {
                            return (
                                <RoundTabs
                                    numTabs={3}
                                    tabIndex={tabIndex}
                                    activeFn={setActiveTab}
                                    tabs={[
                                        {
                                            label: t(
                                                "screens:myOffers.tabs.pending"
                                            ),
                                            activeValue: 0,
                                        },
                                        {
                                            label: t(
                                                "screens:myOffers.tabs.reserved"
                                            ),
                                            activeValue: 1,
                                        },
                                        {
                                            label: t(
                                                "screens:myOffers.tabs.sold"
                                            ),
                                            activeValue: 2,
                                        },
                                    ]}
                                />
                            );
                        },

                        headerActions: () => {
                            return (
                                <>
                                    <AuthorizeComponent
                                        roles={[
                                            Roles.InstallerCompanyAdmin,
                                            Roles.InstallerCompanyUser,
                                            Roles.InstallerCompanyTechnician,
                                        ]}
                                    >
                                        <Flex.Container
                                            justifyContent="flex-end"
                                            className="header-actions"
                                        >
                                            <ActionButton
                                                disabled={
                                                    !globalState.hasValidCompanyCertificate ||
                                                    !globalState.hasCertificateRights
                                                }
                                                className="primary"
                                                onClick={publishOffer}
                                                tooltip={t(
                                                    "screens:reclaimed.tooltip.publish"
                                                )}
                                            >
                                                {t(
                                                    "screens:reclaimed.actions.sellRefrigerant"
                                                )}
                                            </ActionButton>
                                        </Flex.Container>
                                    </AuthorizeComponent>
                                </>
                            );
                        },
                    }
                }
            />
            <ModalDialog {...unpublishProps}>
                <p>{t("actions:dialogs.unPublishOffer.text")}</p>
                <p className="list">
                    {unpublishProps.modal &&
                        unpublishProps.modal.data &&
                        `${
                            unpublishProps.modal.data.type
                        } | ${unpublishProps.modal.data.quantity?.toFixed(
                            2
                        )} kg | ${unpublishProps.modal.data.price.toFixed(
                            2
                        )} EUR`}
                </p>
            </ModalDialog>
            <ModalDialog {...cancelProps}>
                <p>{t("actions:dialogs.declineBid.text")}</p>
                <p className="list">
                    {cancelProps.modal &&
                        cancelProps.modal.data &&
                        `${
                            cancelProps.modal.data.type
                        } | ${cancelProps.modal.data.quantity?.toFixed(
                            2
                        )} kg | ${cancelProps.modal.data.price.toFixed(2)} EUR`}
                </p>
            </ModalDialog>
        </>
    );
};

export default ReclaimedMarketOffers;
