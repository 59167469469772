import React from "react";
import styled from "styled-components";

import InputElement from "../../../../components/elements/form-elements/InputElement";
import { Select } from "../../../../components/elements/form-elements";

import ValidationRule, {
    buildValidation,
} from "../../../../core/validations/ValidationRule";

import { KeyVal, ListTitle, HR } from "../../components/StyledElements";
import MoneyInputElement from "../../../../components/elements/form-elements/MoneyInputElement";

const Content = styled.div`
    width: 100%;
    box-sizing: border-box;
    flex-grow: 1;
    position: relative;
    padding: 35px 60px 0px;
    display: flex;
    flex-direction: column;
    padding: 0px 0px 0px 0px;
    max-height: 500px;
    overflow: auto;
`;

const Section = styled.div`
    padding: 8px 20px;
    &.full-x {
        padding: 8px 0px;
    }

    &.flex {
        display: flex;
    }
`;

const SiteInfo = styled.div`
    font-size: 12px;
    &.title {
        font-weight: 700;
    }
`;

const validUnitInfo = (t) => {
    return buildValidation({
        manufacturerId: ValidationRule.isStringRule().required(
            t("forms:manufacturer.required")
        ).rule,
        applicationType: ValidationRule.isStringRule().required(
            t("forms:application.required")
        ).rule,
        refrigerantId: ValidationRule.isStringRule().required(
            t("forms:gasType.required")
        ).rule,
        capacity: ValidationRule.isNumber().required(
            t("forms:capacity.required")
        ).rule,
    });
};

const applicationTypes = ["Residential", "Commercial", "Industrial"];

const EditUnitWizard = (t, initialValues, manufacturers, refrigerants) => {
    return {
        title: t("forms:clients.editUnit"),
        initialValues: initialValues,
        steps: [
            {
                name: t("forms:unit.wizard.pwaStep3"),
                fieldNames: [
                    "manufacturerId",
                    "applicationType",
                    "refrigerantId",
                    "capacity",
                ],
                validation: validUnitInfo(t),
                fields: (formik, propertyBag) => {
                    return (
                        <>
                            <Select
                                id="manufacturerId"
                                name="manufacturerId"
                                labelText={t("forms:manufacturer.text")}
                                wrapperVariant="flex-1"
                                defaultItem={{
                                    key: "all",
                                    value: "",
                                    label: t("forms:manufacturer.text"),
                                }}
                                options={
                                    manufacturers && manufacturers.length > 0
                                        ? manufacturers.map((item) => {
                                              return {
                                                  key: item.id,
                                                  value: item.id,
                                                  label: item.name,
                                              };
                                          })
                                        : []
                                }
                                {...formik}
                            />

                            <Select
                                id="refrigerantId"
                                name="refrigerantId"
                                labelText={t("forms:gasType.text")}
                                wrapperVariant="flex-1"
                                defaultItem={{
                                    key: "all",
                                    value: "",
                                    label: t("forms:gasType.text"),
                                }}
                                options={
                                    refrigerants && refrigerants.length > 0
                                        ? refrigerants.map((item) => {
                                              return {
                                                  key: item.id,
                                                  value: item.id,
                                                  label: item.code,
                                              };
                                          })
                                        : []
                                }
                                {...formik}
                            />
                            <Select
                                id="applicationType"
                                name="applicationType"
                                labelText={t("forms:application.text")}
                                wrapperVariant="flex-1"
                                defaultItem={{
                                    key: "all",
                                    value: "",
                                    label: t("forms:application.text"),
                                }}
                                options={applicationTypes.map((item, idx) => {
                                    return {
                                        key: idx,
                                        value: idx.toString(),
                                        label: `${t(
                                            "common:applicationType." +
                                                item.toLowerCase()
                                        )}`,
                                    };
                                })}
                                {...formik}
                            />

                            <MoneyInputElement
                                type="number"
                                name="capacity"
                                id="capacity"
                                labelText={t("forms:capacity.text")}
                                wrapperVariant={"flex-1 standard"}
                                values={2}
                                min={0}
                                step="0.01"
                                positive={true}
                                formik={formik}
                                currencyLabel="kW"
                            />

                            {propertyBag.selectedLocation && (
                                <Content>
                                    <HR className="content" />
                                    <ListTitle className="wizard">
                                        {t(
                                            "screens:technician.installationSite"
                                        )}
                                    </ListTitle>
                                    <Section>
                                        <SiteInfo className="title">
                                            {
                                                propertyBag.selectedLocation
                                                    .clientName
                                            }
                                        </SiteInfo>
                                        <SiteInfo>{`${propertyBag.selectedLocation.address}, ${propertyBag.selectedLocation.postalCode}, ${propertyBag.selectedLocation.city}, ${propertyBag.selectedLocation.country}`}</SiteInfo>
                                    </Section>
                                </Content>
                            )}
                        </>
                    );
                },
            },
            {
                name: t("forms:unit.wizard.pwaStep4"),
                fieldNames: ["description"],
                fields: (formik, propertyBag) => {
                    return (
                        <>
                            {propertyBag.selectedLocation && (
                                <Content>
                                    <HR className="content" />
                                    <ListTitle className="wizard">
                                        {t(
                                            "screens:technician.installationSite"
                                        )}
                                    </ListTitle>
                                    <Section>
                                        <KeyVal className="single">
                                            {t("screens:technician.company")}
                                            <span>
                                                {
                                                    propertyBag.selectedLocation
                                                        .clientName
                                                }
                                            </span>
                                        </KeyVal>
                                        <KeyVal className="single">
                                            {t("screens:technician.street")}
                                            <span>
                                                {
                                                    propertyBag.selectedLocation
                                                        .address
                                                }
                                            </span>
                                        </KeyVal>
                                        <KeyVal className="single">
                                            {t("screens:technician.city")}
                                            <span>
                                                {
                                                    propertyBag.selectedLocation
                                                        .city
                                                }
                                            </span>
                                        </KeyVal>
                                        <KeyVal className="single">
                                            {t("screens:technician.zip")}
                                            <span>
                                                {
                                                    propertyBag.selectedLocation
                                                        .postalCode
                                                }
                                            </span>
                                        </KeyVal>
                                        <KeyVal className="single">
                                            {t("screens:technician.country")}
                                            <span>
                                                {
                                                    propertyBag.selectedLocation
                                                        .country
                                                }
                                            </span>
                                        </KeyVal>
                                    </Section>
                                    <HR className="content" />
                                </Content>
                            )}
                            <InputElement
                                labelText={t("forms:clients.notes")}
                                id="description"
                                autoComplete="new-description"
                                name="description"
                                type="textarea"
                                col={4}
                                wrapperVariant="flex-1"
                                {...formik}
                            />
                        </>
                    );
                },
            },
            {
                name: t("forms:unit.wizard.pwaStep5"),
                fieldNames: ["images"],
                fields: (formik, propertyBag) => {
                    return (
                        <>
                            {propertyBag.selectedLocation && (
                                <Content>
                                    <HR className="content" />
                                    <ListTitle className="wizard">
                                        {t(
                                            "screens:technician.installationSite"
                                        )}
                                    </ListTitle>
                                    <Section>
                                        <KeyVal className="single">
                                            {t("screens:technician.company")}
                                            <span>
                                                {
                                                    propertyBag.selectedLocation
                                                        .clientName
                                                }
                                            </span>
                                        </KeyVal>
                                        <KeyVal className="single">
                                            {t("screens:technician.street")}
                                            <span>
                                                {
                                                    propertyBag.selectedLocation
                                                        .address
                                                }
                                            </span>
                                        </KeyVal>
                                        <KeyVal className="single">
                                            {t("screens:technician.city")}
                                            <span>
                                                {
                                                    propertyBag.selectedLocation
                                                        .city
                                                }
                                            </span>
                                        </KeyVal>
                                        <KeyVal className="single">
                                            {t("screens:technician.zip")}
                                            <span>
                                                {
                                                    propertyBag.selectedLocation
                                                        .postalCode
                                                }
                                            </span>
                                        </KeyVal>
                                        <KeyVal className="single">
                                            {t("screens:technician.country")}
                                            <span>
                                                {
                                                    propertyBag.selectedLocation
                                                        .country
                                                }
                                            </span>
                                        </KeyVal>
                                    </Section>
                                    <HR className="content" />
                                </Content>
                            )}
                            <InputElement
                                labelText=""
                                placeholder={t("forms:userPicture.text")}
                                title={t("forms:clients.unitPictures")}
                                name="images"
                                type="gallery"
                                id="images"
                                single
                                wrapperVariant="flex-1"
                                {...formik}
                            />
                        </>
                    );
                },
            },
        ],
    };
};
export default EditUnitWizard;
