import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import Flex from "../../../../../components/layout/flex/Flex";
import {
    InfoText,
    InfoTitle,
    InfoWrapper,
} from "../../../../../components/modal/components/WizardStyling";

const WizardCompanyDetails = (props) => {
    const {t} = useTranslation('forms');
    const {companyData} = props;

    if (!companyData) {
        return null;
    }

    const currentCompanyData = companyData.data;

    return (
        <InfoWrapper className="column">
            <InfoTitle className="primary">
                {currentCompanyData && currentCompanyData.companyName}
            </InfoTitle>
            <InfoText className="larger">
                {
                    currentCompanyData.address &&
                    <>
                        <span><strong>{t('account.address')}:</strong></span>
                        &nbsp;
                        {currentCompanyData.address}
                    </>
                }
            </InfoText>
            <Flex.Container>
                <Flex.Row flexGrow={1}>
                    <InfoText className="larger">
                        {
                            currentCompanyData.city &&
                            <>
                                <span><strong>{t('account.city')}:</strong></span>
                                &nbsp;
                                {currentCompanyData.city}
                            </>
                        }
                    </InfoText>
                    <InfoText className="larger">
                        {
                            currentCompanyData.region &&
                            <>
                                <span><strong>{t('account.region')}:</strong></span>
                                &nbsp;
                                {currentCompanyData.region}
                            </>
                        }
                    </InfoText>
                </Flex.Row>
                <Flex.Row flexGrow={1}>
                    <InfoText className="larger">
                        {
                            currentCompanyData.postalCode &&
                            <>
                                <span><strong>{t('account.postal')}:</strong></span>
                                &nbsp;
                                {currentCompanyData.postalCode}
                            </>
                        }
                    </InfoText>
                    <InfoText className="larger">
                        {
                            currentCompanyData.countryName &&
                            <>
                                <span><strong>{t('account.country')}:</strong></span>
                                &nbsp;
                                {currentCompanyData.countryName}
                            </>
                        }
                    </InfoText>
                </Flex.Row>
            </Flex.Container>
        </InfoWrapper>
    )
};

export default WizardCompanyDetails;

WizardCompanyDetails.propTypes = {
    companyData: PropTypes.any.isRequired
}
