import React from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import QrReader from "react-qr-reader";
import * as yup from "yup";
import InputElement from "../../../../components/elements/form-elements/InputElement";
import { Select } from "../../../../components/elements/form-elements";
import MoneyInputElement from "../../../../components/elements/form-elements/MoneyInputElement";

import {
    InfoText,
    InfoTitle,
    InfoWrapper,
    RoundInfo,
} from "../../../../components/modal/components/WizardStyling";
import { StyledQrReader } from "../../../../components/elements/qr/QrScanner";
import ValidationRule, { buildValidation } from "../../../../core/validations/ValidationRule";

import TagsService from "../../../../services/Tags/TagsService";

import { ReactComponent as WizardImage } from "../../../../assets/pwa/add-new-analyzer.svg";
import { ScanButton, KeyVal, HR } from "../../components/StyledElements";

import { SvgScan } from "../../components/StyledSvg";
import CylinderType from "../../../../core/enums/CylinderType";

const Content = styled.div`
    width: 100%;
    box-sizing: border-box;
    flex-grow: 1;
    position: relative;
    padding: 35px 60px 0px;
    display: flex;
    flex-direction: column;
    padding: 0px 0px 0px 0px;
    max-height: 500px;
    overflow: auto;
`;

const FlexCenter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const SvgWizardImage = styled(WizardImage)`
    width: auto;
    margin: 0 auto;
`;

const Section = styled.div`
    padding: 8px 20px;
    &.full-x {
        padding: 8px 0px;
    }

    &.flex {
        display: flex;
    }
`;

const ScanWrapper = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
`;

const Spacer = styled.div`
    height: 10px;
    width: 100%;
`;

const validUnusedTagId = (t) => {
    return buildValidation({
        tagId: ValidationRule.isStringRule()
            .isUUID(t("forms:unit.uuid.invalid"))
            .required(t("forms:unit.unitTagId.required")).rule,
    });
};

const validAnalyzer = (t) => {
    return buildValidation({
        macAddress: ValidationRule.isStringRule()
            .regexMatchRule(
                /^([0-9a-fA-F]{2}[:-]){5}[0-9a-fA-F]{2}$/i,
                t("forms:analyzer.wizard.macInvalid")
            )
            .required(t("forms:analyzer.wizard.macRequired")).rule,
        name: ValidationRule.isStringRule().required(t("forms:analyzer.wizard.nameRequired")).rule,
    });
};

const defaultInitialValues = {
    tagId: "",
    name: "",
    description: "",
    macAddress: "",
    imageFile: "",
    isValidUnusedTag: false,
};

const AddAnalyzerWizard = (t,  unusedTagSearchFn) => {
   
    const handleUnusedTagScan = (value, formik) => {
        if (value != null) {
            formik.setValues({
                ...formik.values,
                showTagScanner: false,
                tagId: value.toLowerCase(),
            });
        }
    };

    return {
        title: t("forms:analyzer.addAnalyzer"),
        initialValues: defaultInitialValues,
        steps: [
            {
                name: t("forms:analyzer.wizard.step1Title"),
                fieldNames: ["tagId"],
                validation: validUnusedTagId(t),
                onStepSubmit: async ({ values }) => {
                    const tagId = values["tagId"];

                    const tagResult = await TagsService.lookupUnassignedTagsAsync(tagId);
                    if (tagResult.error || tagResult.data.data.length === 0) {
                        toast.error(t("forms:unit.wizard.invalidTag"));
                        return false;
                    }
                    return true;
                },
                fields: (formik) => {
                    return (
                        <>
                            <div
                                style={{
                                    display: formik.values.showTagScanner ? "none" : "block",
                                }}
                            >
                                <FlexCenter>
                                    <SvgWizardImage />
                                </FlexCenter>

                                <InfoTitle className="pwa">
                                    {t("forms:analyzer.wizard.infoTitlePWA")}
                                </InfoTitle>
                                <InfoWrapper className="pwa">
                                    <RoundInfo alt="" src="/images/menu/green-info.svg" />
                                    <div>
                                        <InfoText className="pwa">
                                            {t("forms:analyzer.wizard.infoTextPWA")}
                                            <Spacer />
                                            {t("forms:extraction.wizard.useCamera")}
                                            <Spacer />
                                            {t("forms:extraction.wizard.typeQrTag")}
                                        </InfoText>
                                    </div>
                                </InfoWrapper>
                                <InputElement
                                    pwaBig
                                    noLabel
                                    labelText={t("forms:cylinderTagId.text")}
                                    id="tagId"
                                    name="tagId"
                                    type="search"
                                    direction="up"
                                    placeholder={t("screens:technician.qrTag")}
                                    searchFn={unusedTagSearchFn}
                                    wrapperVariant="flex-1"
                                    {...formik}
                                />
                            </div>

                            {formik.values.showTagScanner && (
                                <StyledQrReader className="wizard">
                                    <QrReader
                                        delay={300}
                                        onError={(error) => console.log(error)}
                                        onScan={(value) => handleUnusedTagScan(value, formik)}
                                        style={{ width: "100%" }}
                                    />
                                </StyledQrReader>
                            )}

                            <ScanWrapper>
                                <ScanButton
                                    type="button"
                                    className={formik.values.showTagScanner ? "close" : ""}
                                    onClick={() =>
                                        formik.setFieldValue(
                                            "showTagScanner",
                                            !formik.values.showTagScanner
                                        )
                                    }
                                >
                                    <SvgScan className="white" />
                                </ScanButton>
                            </ScanWrapper>
                        </>
                    );
                },
            },
            {
                name: t("forms:analyzer.wizard.step2Title"),
                fieldNames: ["name", "description", "macAddress", "imageFile", "isValidUnusedTag"],
                validation: validAnalyzer(t),
                onStepRender: async (props) => {
                    const tagId = props.value["tagId"];
                    if (tagId !== props.propertyBag.tagId) {
                        const tagResult = await TagsService.lookupUnassignedTagsAsync(tagId);
                        if (tagResult.error) {
                            toast.error(t(tagResult.error.message));
                            props.formik.setFieldValue("isValidUnusedTag", false);
                            return;
                        }
                        const tagData = tagResult.data.data;
                        if (tagData.length === 0) {
                            toast.error(t("forms:unit.wizard.invalidTag"));
                            props.formik.setFieldValue("isValidUnusedTag", false);
                            return;
                        }
                        props.formik.setFieldValue("isValidUnusedTag", true);
                        props.updatePropertyBag({ tagId: tagId });
                    } else {
                        props.formik.setFieldValue("isValidUnusedTag", true);
                    }
                },
                fields: (formik) => {
                    return (
                        <>
                          
                                <InputElement
                                    labelText={t("forms:analyzer.wizard.macAddress")}
                                    mask="**:**:**:**:**:**"
                                    alwaysShowMask
                                    id="macAddress"
                                    autoComplete="new-description"
                                    name="macAddress"
                                    type="text"
                                    wrapperVariant="flex-1"
                                    {...formik}
                                />

                                <InputElement
                                    labelText={t("forms:analyzer.wizard.name")}
                                    id="name"
                                    autoComplete="new-description"
                                    name="name"
                                    type="text"
                                    wrapperVariant="flex-1"
                                    {...formik}
                                />

                                <InputElement
                                    labelText={t("forms:clients.notes")}
                                    id="description"
                                    name="description"
                                    autoComplete="new-description"
                                    type="textarea"
                                    wrapperVariant="flex-1"
                                    {...formik}
                                />

                                <InputElement
                                    labelText=""
                                    id="imageFile"
                                    name="imageFile"
                                    type="image"
                                    skipUrl="/images/GasAnalyzer_no_image.svg"
                                    placeholder={t("forms:analyzer.wizard.browse")}
                                    title={t("forms:analyzer.wizard.picture")}
                                    wrapperVariant="flex-1"
                                    className="doubleHeight"
                                    {...formik}
                                />
                           
                        </>
                    );
                },
            },
        ],
    };
};
export default AddAnalyzerWizard;
