import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Flex from "../../../../components/layout/flex/Flex";
import useImmerReducer from "../../../../hooks/_shared/useImmerReducer";
import StatisticsService from "../../../../services/Statistics/StatisticsService";
import DashboardWrapper from "../../../../components/dashboard/DashboardWrapper";
import DASHBOARD_CONSTANTS, { PRESUMED_GRADES } from "../../../../components/dashboard/DashboardConstants";
import dataFunctions from "../../../../components/dashboard/DataFunctions";
import ChartAveragePrice from "../../../../components/dashboard/ChartAveragePrice";
import ChartProgress from "../../../../components/dashboard/ChartProgress";
import ChartPieGasQuality from "../../../../components/dashboard/ChartPieGasQuality";
import { Helmet } from "react-helmet";
import GasMenu from "../../../pwa/GasMenu";
import useDataReducer from "../../../../hooks/_shared/useDataReducer";
import FilterableLineChart from "../../../../components/dashboard/FilterableLineChart";
import CompanyService from "../../../../services/Company/CompanyService";
import { toast } from "react-toastify";
import { ReactComponent as NoDataSvg } from "../../../../assets/no-chart-data.svg";
import styled from "styled-components";
import useBreadcrumbs from "../../../../core/state/navigation/useBreadcrumbs";
import DashboardStyles from "../../../../components/dashboard/DashboardStyles";

const SvgNoData = styled(NoDataSvg)`
    width: 100%;
`;

const MainContainer = styled(Flex.Container)`
   height: 100%;
    @media all and (max-width: ${props => props.theme.extraLargeDevices}) {
        flex-direction: column;
    }
`;

const LeftContainer = styled(DashboardStyles.Column)`
    width: 360px;

    @media all and (max-width: ${props => props.theme.extraLargeDevices}) {
        width: 100%;
        order: 2;
    }
`;

const MidContainer = styled(DashboardStyles.Column)`
    flex: 1;
    min-width: 500px;

    @media all and (max-width: ${props => props.theme.extraLargeDevices}) {
        width: 100%;
        order: 3;
    }
`;

const ExtractionsContainer = styled(Flex.Row)`
    height: 100%;
`;

const QualityContainer = styled(Flex.Container)`
    position: relative;

    @media all and (max-width: ${props => props.theme.largeDevices}) {
        flex-direction: column;
    }
`;

const QualityPieContainer = styled(Flex.Column)`
  
`;

const ProgressContainer = styled(Flex.Column)`
   flex: 1;
   @media all and (max-width: ${props => props.theme.largeDevices}) {
    order: 2;
    }
`;

const RightContainer = styled(Flex.Column)`
    min-width: 286px;
    width: 286px;

    @media all and (max-width: ${props => props.theme.extraLargeDevices}) {
        width: 100%;
    }
`;


const MarketplaceAdminDashboard = () => {

    const { t } = useTranslation();

    useBreadcrumbs([
        {
            text: t("navigation:salesCenter"),
        },
    ]);

    const [menuData, setMenuData] = useState();
    const [companyId, setCompanyId] = useState();
    const [qualityData, setQualityData] = useState();

    const [extractedFGasState, loadExtractedFGasState] = useDataReducer(async (id) => {
        return await StatisticsService.extractedFGasAdmin(id);
    });


    const [extractionsState, loadExtractions] = useDataReducer(async (filter) => {
        return await StatisticsService.extractionsAdmin(filter);
    });

    const [averagesState, loadAverages] = useDataReducer(async (filter) => {
        return await StatisticsService.tradedRefrigerantsAveragesAdmin(filter);
    });

    useEffect(() => {
        loadExtractedFGasState(companyId);

    }, [companyId]);

    const filterReducer = (state, action) => {
        switch (action.type) {
            case DASHBOARD_CONSTANTS.actionTypes.SET_FILTERS:
                state.from = action.payload.from;
                state.to = action.payload.to;
                break;
            case DASHBOARD_CONSTANTS.actionTypes.RESET_FILTERS:
                delete state.from
                delete state.to
                break;
            default:
                return state;
        }
    };

    const onChangeFilter = ((filters) => {
        dispatch({
            type: DASHBOARD_CONSTANTS.actionTypes.SET_FILTERS,
            payload: filters,
        });
    });

    let companyResult = [];
    const companyLookup = async (companyName) => {
        if (!companyName || companyName.length < 1) return [];

        const filter = {
            filter: {
                companyName: companyName
            }
        };

        const res = await CompanyService.getCompaniesWithAdmin(filter);
        if (res.error) {
            toast.error(res.error.message);
            return [];
        }
        companyResult = res.data;
        return companyResult.map((r) => r.companyName);
    };

    const companySelect = (selectedCompanyName) => {
        let id = getCompanyId(selectedCompanyName);
        setCompanyId(id);
    };

    const getCompanyId = (companyName) => {
        const match = companyResult.filter(
            (itm) => itm.companyName === companyName
        );
        return match.length > 0 ? match[0].companyId : "";
    };


    const [filterState, dispatch] = useImmerReducer(filterReducer, {

        from: "",
        to: "",

    });

    const [selectedGasType, setSelectedGasType] = useState({
        id: "",
        code: "",
        firstExtractionDate: "",
        lastExtractionDate: ""
    });


    useEffect(() => {

        if (extractedFGasState && extractedFGasState.isCompleted) {
            let menuData = dataFunctions.getExtractedFGasData(extractedFGasState);
            setMenuData(menuData);
            if (menuData && menuData.length > 0) {
                const firstMenu = menuData[0];

                setSelectedGasType({
                    id: firstMenu.refrigerantId,
                    code: firstMenu.refrigerantGasTypeCode,
                    firstExtractionDate: firstMenu.firstExtractionDate,
                    lastExtractionDate: firstMenu.lastExtractionDate,
                });

            }
            else {

                setSelectedGasType({
                    id: "",
                    code: "",
                    firstExtractionDate: "",
                    lastExtractionDate: ""
                });

            }
        }

    }, [extractedFGasState]);

    useEffect(() => {
        let data = [];
        if (extractedFGasState.isCompleted && selectedGasType && selectedGasType.id && selectedGasType.id !== "") {
            const filteredData = selectedGasType.id ? extractedFGasState.data.filter(x => x.refrigerantId === selectedGasType.id) : extractedFGasState.data;
            const totalQuantity = parseFloat(filteredData.reduce((sum, current) => sum + current.quantity, 0));

            data = PRESUMED_GRADES.map(grade => {

                const sumQuantity = filteredData.filter(x => x.presumedGrade === grade.id).reduce((sumQuantity, current) => sumQuantity + current.quantity, 0);
                return {
                    name: grade.name,
                    color: grade.color,
                    value: sumQuantity,
                    percent: sumQuantity / totalQuantity * 100
                }

            });

        }

        setQualityData(data);

    }, [selectedGasType]);

    useEffect(() => {
        const filter = getSelectedFilter();
        if (filter && filter.refrigerantId) {
            loadAverages({ filter: filter });
        }
    }, [filterState, selectedGasType]);

    const getSelectedFilter = () => {
        if (filterState && selectedGasType) {
            let isSelected = selectedGasType.id && selectedGasType.id !== "";
            const refrigerantId = { refrigerantId: isSelected ? selectedGasType.id : null };

            let filter = { ...filterState, ...refrigerantId };
            if (companyId) {
                const companyIdFilter = { companyId: companyId };
                filter = { ...filter, ...companyIdFilter };
            }
            return filter;
        }
        else return null;
    }

    useEffect(() => {
        let filter = getSelectedFilter();

        loadExtractions({ filter: filter });

    }, [filterState, selectedGasType.id]);


    useEffect(() => {
        if (selectedGasType && (selectedGasType.firstExtractionDate || selectedGasType.lastExtractionDate)) {
            onChangeFilter({ from: new Date(selectedGasType.firstExtractionDate), to: new Date(selectedGasType.lastExtractionDate) });
        }

    }, [selectedGasType]);


    const handleClick = (ev) => {
        const tgt = ev.currentTarget;
        if (tgt.dataset.value !== undefined && tgt.dataset.info !== undefined) {
            const refrigerantId = tgt.dataset.value;
            const refrigerantCode = tgt.dataset.info;
            const gasInfo = menuData.filter(x => x.refrigerantId === refrigerantId)[0];

            setSelectedGasType({
                id: refrigerantId,
                code: refrigerantCode,
                firstExtractionDate: gasInfo.firstExtractionDate,
                lastExtractionDate: gasInfo.lastExtractionDate,
            });
        }
    }

    const selectedGasLabel = () => {
        return selectedGasType.code ? "- " + selectedGasType.code : "";
    }

    const getAverageTitle = (grade) => {
        return selectedGasType ? `${t("screens:salesDashboard.labels.averagePrice")} - ${grade}  ${t("screens:salesDashboard.labels.grade")} ${selectedGasType.code} ` : "";
    }


    return (
        <DashboardWrapper>
            <Helmet>
                <title>{t("seo:admindashboard.title")}</title>
            </Helmet>

            <MainContainer>
                <LeftContainer className="between">

                    {averagesState?.data ? averagesState?.data.map((data, index) => (
                        <Flex.Row key={index}>

                            <ChartAveragePrice
                                admin
                                currency="€"
                                title={getAverageTitle(PRESUMED_GRADES[index].name)}
                                amount={data.averagePrice}
                                data={data.bidHistory}
                                color={DASHBOARD_CONSTANTS.COLORS[index]}
                            >
                            </ChartAveragePrice>

                        </Flex.Row>
                    )) : null
                    }

                </LeftContainer>
                <MidContainer>
                    <ExtractionsContainer>
                        <FilterableLineChart
                            filterState={filterState}
                            title={t("screens:stockDashboard.headers.extractionsChart") + selectedGasLabel()}
                            height={'90%'}
                            data={extractionsState?.data}
                            onChangeFilter={onChangeFilter}
                            column="Quantity"
                            color={DASHBOARD_CONSTANTS.lineChartColor}
                            unit="kg"
                            isCompleted={extractedFGasState.isCompleted}
                            style={{ marginTop: "-25px", maxHeight: "400px" }}
                            showDrillDown={true}
                            valueLabel={t("screens:dashboard.sidebar.extractedQuantity")}
                            countLabel={t("screens:dashboard.sidebar.numberOfExtractions")}
                        >

                        </FilterableLineChart>
                    </ExtractionsContainer>
                    <QualityContainer>
                        <DashboardStyles.NoDataWrapper
                            className={`${qualityData && qualityData.length === 0 ? "show" : ""}`}
                        >
                            <SvgNoData />
                            {t("table:headers.noItems")}
                        </DashboardStyles.NoDataWrapper>
                        <ProgressContainer>

                            {/* <ChartProgress
                                data={qualityData}
                                title={t("screens:stockDashboard.headers.qualityChart") + ' ' + selectedGasLabel()}
                                suffix={t("screens:salesDashboard.labels.grade")}
                                style={{ paddingLeft: "0px" }}
                            >
                            </ChartProgress> */}
                        </ProgressContainer>
                        <QualityPieContainer>

                            <ChartPieGasQuality width={200} height={200} style={{ paddingBottom: "0px" }}
                                data={qualityData}

                            ></ChartPieGasQuality>
                        </QualityPieContainer>

                    </QualityContainer>
                </MidContainer>
                <RightContainer>
                    {
                        menuData && (<GasMenu
                            items={menuData}
                            colors={DASHBOARD_CONSTANTS.COLORS}
                            handleClick={handleClick}
                            companySelect={companySelect}
                            title={t("screens:stockDashboard.headers.extractions")}
                            t={t}
                            searchFn={companyLookup}
                            selectedGasType={selectedGasType} >
                        </GasMenu>)
                    }


                </RightContainer>

            </MainContainer>
        </DashboardWrapper>
    );
};
export default MarketplaceAdminDashboard;
