import styled from "styled-components";
import Headings from "../../../../../components/elements/headings/Headings";

export const DetailsWrapper = styled.div`
    font-weight: bold;
    margin: 0 32px 44px 0;
    padding-bottom: 42px;
    line-height: 1.3125rem;
    width: 100%;
    display: flex;
    flex: 1;
    justify-content: flex-start;
    border-bottom: 1px solid ${props => props.theme.borderSeparator};
`;

export const Title = styled(Headings.H3)`
    margin-top: 0;
    color: ${props => props.theme.tertiaryColor};
    word-break: break-word;
    padding-left: 42px; // if you gonna change this, change Details.clients as well

    &.empty {
        min-height: 1.3125rem;
        visibility: hidden;
    }
`;

export const Details = styled.div`
    margin: 0 18px 0 0;
    font-size: 0.875rem;
    display: inline-block;
    vertical-align: top;
    width: 340px;

    &:last-of-type {
        margin-left: auto;
        margin-right: 0;
    }

    &.clients {
        padding-left: 42px;
    }

    &.map {
        width: auto;
        margin-right: 42px;
    }
`;

export const DetailSection = styled.div`
    margin: 0 0 12px;

    &:last-of-type {
        margin: 0;
    }
`;

export const SectionTitle = styled.span`
    display: block;
    color: ${props => props.theme.primaryColor};
`;

export const SectionContent = styled.p`
    margin: 0;
    line-height: 1.3;

    .normal {
        font-weight: normal;
    }
`;
