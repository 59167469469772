import api from "../getAxios";

const LoginService = {
    login: (username, password) => {
        return api.post("/Authentication/request", { username, password });
    },
    verifyTwoFactor: (username, code) => {
        return api.post("/Authentication/verify", { username, twoFactorAuthToken: code });
    },
    logout: () => {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        api.defaults.headers["Authorization"] = "";
        window.location.replace(window.location.origin);
    },
    refresh: async () => {
        api.defaults.headers["Authorization"] = "Bearer " + localStorage.getItem("refreshToken");

        return await api.post("/Authentication/refresh").then((tokenRefreshResponse) => {
            localStorage.setItem("token", tokenRefreshResponse.data.token);
            localStorage.setItem("refreshToken", tokenRefreshResponse.data.refreshToken);

            api.defaults.headers["Authorization"] = "Bearer " + tokenRefreshResponse.data.token;
            return { success: true };
        });
    },
    impersonate: async (username) => {
        const result = await api.post("/Authentication/impersonate", { username });
        return result.data || null;
    },
    stopImpersonation: async () => {
        const result = await api.post("/Authentication/stopImpersonation");
        return result.data || null;
    },
    getSSOTokens: async (id) => {
        const result = await api.get(`/Authentication/getSsoTokensById?id=${id}`);
        return result?.data;
    },
};

export default LoginService;
