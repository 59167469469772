import StockService from "../../../services/Stock/StockService";

const unpublishOfferPlugin = {
    types: ["UNPUBLISH_LOADING", "UNPUBLISH_ERROR", "UNPUBLISH_SUCCESS"],
    initialState: {
        unpublishData: {
            isLoading: false,
            error: null,
            data: null
        }
    },
    creators: types => ({
        unpublishLoading: () => ({ type: types.UNPUBLISH_LOADING }),
        unpublishError: err => ({ type: types.UNPUBLISH_ERROR, payload: err }),
        unpublishSuccess: data => ({
            type: types.UNPUBLISH_SUCCESS,
            payload: data
        })
    }),
    actions: creators => ({
        unpublishOffers: offerIds => {
            return async dispatch => {
                dispatch(creators.unpublishLoading());
                try {
                    const data = await StockService.unpublishFromMarketplace({
                        offerIds: offerIds
                    });
                    dispatch(creators.unpublishSuccess(data));
                } catch (err) {
                    dispatch(creators.unpublishError(err));
                }
            };
        }
    }),
    createReducer: types => {
        return (state, action) => {
            switch (action.type) {
                case types.UNPUBLISH_LOADING:
                    state.unpublishData.isLoading = true;
                    state.unpublishData.error = null;
                    state.unpublishData.data = null;
                    break;
                case types.UNPUBLISH_ERROR:
                    state.unpublishData.isLoading = false;
                    state.unpublishData.error = action.payload;
                    state.unpublishData.data = null;
                    break;
                case types.UNPUBLISH_SUCCESS:
                    state.unpublishData.isLoading = false;
                    state.unpublishData.error = null;
                    state.unpublishData.data = action.payload;
                    break;
                default:
                    break;
            }
        };
    }
};

export default unpublishOfferPlugin;
