import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Redirect, Switch, useHistory, useRouteMatch } from "react-router-dom";
import BackButton from "../../../../components/elements/buttons/BackButton";
import RoundTabs from "../../../../components/elements/tabs/RoundTabs";
import LayoutInnerContainer from "../../../../components/layout/split/one/LayoutInnerContainer";
import PrivateRoute from "../../../../core/PrivateRoute";
import RouteCreator from "../../../../core/RouteCreator";
import useQueryString from "../../../../hooks/_shared/useQueryString";
import EmployeeEditScreen from "../employees/EmployeeEditScreen";
import EmployeeList from "../employees/EmployeeList";
import CompanyCertificateScreen from "./CompanyCertificateScreen";
import CompanyEditScreen from "./CompanyEditScreen";
import PaymentsScreen from "./PaymentsScreen";
import KycScreen from "./KycScreen";
import { useTranslation } from "react-i18next";
import ProfileScreen from "../employees/ProfileScreen";
import useLeftMenu from "../../../../core/state/navigation/useLeftMenu";

const TabsWrapper = styled.div`
    position: fixed;
    top: 85px;
    width: 100%;
    z-index: 5;

    margin: 0px 0px 0px -95px;
    padding: 0px 60px 0px 78px;
    border-radius: 15px 15px 0px 0px;
    background: linear-gradient(180deg, rgba(245, 245, 245, 1) 0%, rgba(240, 240, 240, 1) 100%);
    box-shadow: -2px 9px 11px -16px rgba(0, 0, 0, 0.75);
    border-bottom: 1px solid #ffffff;

    &.pr-0 {
        padding-right: 0px;
    }
`;

const tabIndices = {
    CompanyDetails: 0,
    CompanyEmployees: 1,
    CompanyCertificates: 2,
    PaymentSettings: 3,
    Kyc: 4,
};

const CompanyScreen = () => {
    const history = useHistory();
    const returnUrl = useQueryString("returnUrl", "/");
    const returnTo = useRef("/");
    const { i18n, t } = useTranslation();

    const { setIsVisible } = useLeftMenu();

    if (returnUrl !== "/") returnTo.current = returnUrl;

    const onBackButtonClick = (pathName) => {
        if (pathName.indexOf("/employees/edit/") >= 0) return true;
    };

    const companyDetailsRouteMatch = useRouteMatch({
        path: RouteCreator.company.details(),
        strict: false,
    });

    const companyCertsRouteMatch = useRouteMatch({
        path: RouteCreator.company.certificates(),
        strict: false,
    });

    const companyPaymentsRouteMatch = useRouteMatch({
        path: RouteCreator.company.payments(),
        strict: false,
    });

    const companyQRRouteMatch = useRouteMatch({
        path: RouteCreator.company.requestQR(),
        strict: false,
    });

    const kycRouteMatch = useRouteMatch({
        path: RouteCreator.company.kyc(),
        strict: false,
    });

    const employeeEditRouteMatch = useRouteMatch({
        path: RouteCreator.employee.editEmployee(),
        strict: false,
    });

    const noTabsRouteMatch = useRouteMatch({
        path: "/company/employees/*",
        strict: false,
    });

    const [tabIndex, setTabIndexState] = useState(
        companyDetailsRouteMatch
            ? tabIndices.CompanyDetails
            : companyCertsRouteMatch
            ? tabIndices.CompanyCertificates
            : companyQRRouteMatch
            ? tabIndices.CompanyQR
            : companyPaymentsRouteMatch
            ? tabIndices.PaymentSettings
            : kycRouteMatch
            ? tabIndices.Kyc
            : tabIndices.CompanyEmployees
    );

    useEffect(() => {
        setIsVisible(false);
    }, []);

    const setTabIndex = useCallback(
        (index) => {
            if (index === tabIndices.CompanyDetails) {
                history.push(RouteCreator.company.details());
            } else if (index === tabIndices.CompanyEmployees) {
                history.push(RouteCreator.employee.list());
            } else if (index === tabIndices.CompanyCertificates) {
                history.push(RouteCreator.company.certificates());
            } else if (index === tabIndices.PaymentSettings) {
                history.push(RouteCreator.company.payments());
            } else if (index === tabIndices.Kyc) {
                history.push(RouteCreator.company.kyc());
            }
            setTabIndexState(index);
        },
        [history]
    );

    useEffect(() => {
        if (companyDetailsRouteMatch && tabIndex !== tabIndices.CompanyDetails)
            setTabIndex(tabIndices.CompanyDetails);
    }, [companyDetailsRouteMatch, setTabIndex, tabIndex]);

    useEffect(() => {
        if (
            !companyDetailsRouteMatch &&
            !companyCertsRouteMatch &&
            !companyQRRouteMatch &&
            !employeeEditRouteMatch &&
            !companyPaymentsRouteMatch &&
            !kycRouteMatch &&
            tabIndex !== tabIndices.CompanyEmployees
        )
            setTabIndex(tabIndices.CompanyEmployees);
    }, [
        companyDetailsRouteMatch,
        companyCertsRouteMatch,
        companyQRRouteMatch,
        employeeEditRouteMatch,
        companyPaymentsRouteMatch,
        kycRouteMatch,
        tabIndex,
        setTabIndex,
    ]);

    return (
        <div style={{ display: "inline-flex", height: "100%" }}>
            <BackButton
                variant="company"
                returnTo={returnTo.current}
                onGoBack={onBackButtonClick}
            />
            <LayoutInnerContainer
                className={tabIndex === tabIndices.PaymentSettings ? "company pr-0" : "company"}
            >
                {noTabsRouteMatch === null && (
                    <TabsWrapper
                        className={tabIndex === tabIndices.PaymentSettings ? "pr-0" : null}
                    >
                        <RoundTabs
                            language={i18n.language}
                            className={
                                tabIndex === tabIndices.PaymentSettings ? "minimal mr-0" : "minimal"
                            }
                            tabIndex={tabIndex}
                            activeFn={setTabIndex}
                            tabs={[
                                {
                                    label: t("screens:company.tabs.company").toUpperCase(),
                                    activeValue: tabIndices.CompanyDetails,
                                },
                                {
                                    label: t("screens:company.tabs.employees").toUpperCase(),
                                    activeValue: tabIndices.CompanyEmployees,
                                },
                                {
                                    label: t("screens:company.tabs.certificates").toUpperCase(),
                                    activeValue: tabIndices.CompanyCertificates,
                                },
                                {
                                    label: t("screens:company.tabs.paymentSettings").toUpperCase(),
                                    activeValue: tabIndices.PaymentSettings,
                                },
                                {
                                    label: t("screens:company.tabs.mangopay").toUpperCase(),
                                    activeValue: tabIndices.Kyc,
                                },
                            ]}
                        />
                    </TabsWrapper>
                )}

                <Switch>
                    <PrivateRoute
                        path={RouteCreator.company.details()}
                        component={CompanyEditScreen}
                    />
                    <PrivateRoute
                        path={RouteCreator.company.certificates()}
                        component={CompanyCertificateScreen}
                    />
                    <PrivateRoute
                        path={RouteCreator.company.payments()}
                        component={PaymentsScreen}
                    />

                    <PrivateRoute path={RouteCreator.company.kyc()} component={KycScreen} />

                    <PrivateRoute
                        path={RouteCreator.employee.edit(":employeeId")}
                        component={EmployeeEditScreen}
                    />

                    <PrivateRoute path={RouteCreator.user.profile()} component={ProfileScreen} />

                    <PrivateRoute path={RouteCreator.employee.list()} component={EmployeeList} />
                    <Redirect to={RouteCreator.company.details()} />
                </Switch>
            </LayoutInnerContainer>
        </div>
    );
};

export default CompanyScreen;
