import React, { useEffect, useState } from "react";
import styled from "styled-components";
import AnalyzerStockListing from "./components/AnalyzerStockListing";
import AnalyzerDetails from "./components/AnalyzerDetails";
import { LevelWrapper } from "./components/StyledElements";

const ScreeWrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    display: flex;
    flex-wrap: nowrap;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
`;

const AnalyzerStock = () => {


    const [state, setState] = useState({
        level: 0,
        analyzer: null,
        reset:false
    });

    useEffect(() => {
        document.querySelector(`#lvl${state.level + 1}`).scrollIntoView({
            behavior: "smooth",
        });
    }, [state.level]);

    return (

            <ScreeWrapper>
                <LevelWrapper id="lvl1">
                    <AnalyzerStockListing
                        onSelect={(row) => {
                            setState({
                                ...state,
                                level: 1,
                                analyzer: row,
                            });
                        }}
                        reset={state.reset}
                    />
                </LevelWrapper>
                <LevelWrapper id="lvl2">
                    <AnalyzerDetails
                        onGoBack={(ev) => {
                            ev.preventDefault();
                            ev.stopPropagation();
                            setState((state) => ({ ...state, level: 0 }));
                        }}
                        analyzer={state.analyzer}
                        onGotoStart={() => {
                            setState((prevState) => ({
                                level: 0,
                                analyzer: null,
                                reset:true
                            }));
                        }}
                    />
                </LevelWrapper>
            </ScreeWrapper>

    );
};

export default AnalyzerStock;
