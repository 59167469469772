import { config } from "../Config/config.prod";
import api, {apiResult} from '../getAxios';

const SellRefrigerantService = {
    getSellingItemById: async (id) => {
        return (await api.post(config.apiUrl.getOffers, {ids :[id]})).data.data[0][0];
    },
    getSellingItemByIdAsync: async (id) => {
        return apiResult(api.post(config.apiUrl.getOffers, {ids :[id]}));
    },
    getSellingItems: async (params) => {
        const res = (await api.post(config.apiUrl.getMySellingItems, params)).data;
        return res;
    }
};

export default SellRefrigerantService;
