import React from "react";
import useLeftMenu from "../../core/state/navigation/useLeftMenu";
import globalAdminMenuItems from "../../data/menu/globalAdminMenuItems";
import { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import AuthorizeComponent from "../../core/AuthorizeComponent";
import HamburgerMenu from "../../components/menu/HamburgerMenu";
import HamburgerMenuAction from "../../components/menu/HamburgerMenuAction";
import DateCell from "../../components/data/DateCell";
import Roles from "../../core/enums/Roles";
import useBreadcrumbs from "../../core/state/navigation/useBreadcrumbs";
import { Helmet } from "react-helmet";
import LayoutInnerContainer from "../../components/layout/split/one/LayoutInnerContainer";
import AccordionWrapper from "../../components/toggle-view/AccordionWrapper";
import CompanyService from "../../services/Company/CompanyService";
import useAccordionGroup from "../../hooks/_shared/useAccordionGroup";
import ListingPage from "../../components/data/ListingPage";
import AdminCertSidebar from "./AdminCertSidebar";

const ECertificateStatus = {
    Submitted: 0,
    Valid: 1,
    Rejected: 2,
    Expired: 99,
    NoAttestation: -1, //display only
};

const EmptyGuid = "00000000-0000-0000-0000-000000000000";

const ContentWrapper = styled.div`
    display: block;
    width: 100%;
    padding-right: 20px;
    @media ${(props) => props.theme.device.mobile} {
        padding-left: 16px;
        padding-right: 16px;
    }
`;

const InfoWrapper = styled.div`
    display: flex;
    width: 100%;
    color: #000;

    & .topSpace {
        margin-top: 14px;
    }

    & .info {
        font-size: 14px;
    }

    & li {
        font-size: 12px;
        line-height: 18px;
    }
`;

const GreenInfo = styled.img`
    width: 40px;
    height: 40px;
    margin-right: 24px;
`;

const ColoredText = styled.div`
    color: ${(p) => p.theme.primaryColor};

    &.error {
        color: ${(p) => p.theme.error};
    }
`;

const Separator = styled.div`
    width: 100%;
    height: 2px;
    background-color: #000;
    margin-top: 24px;
`;

const Spacer = styled.div`
    width: 100%;
    height: 30px;
    min-height: 30px;
`;

const HolderCell = ({ cell: { row } }) => {
    const holder = row.original.certificateHolder;
    const status = row.original.licenceStatus;

    return (
        <ColoredText
            className={status !== ECertificateStatus.Valid ? "error" : ""}
        >
            {holder}
        </ColoredText>
    );
};

const StatusCell = ({ cell: { row } }) => {
    const text = row.original.licenceStatusName;
    const status = row.original.licenceStatus;

    return (
        <ColoredText
            className={status !== ECertificateStatus.Valid ? "error" : ""}
        >
            {text}
        </ColoredText>
    );
};

const employeeBreadcrumbs = (t) => [
    {
        text: t("navigation:companies"),
    },
    {
        text: "",
    },
    {
        text: t("filters:certificate.certificates"),
    },
];

function groupBy(arr, prop) {
    const map = new Map(Array.from(arr, (obj) => [obj[prop], []]));
    arr.forEach((obj) => map.get(obj[prop]).push(obj));
    return Array.from(map.values());
}

const CompanyCertificateScreen = () => {
    useLeftMenu(globalAdminMenuItems);
    const { t } = useTranslation();
    const [openAccordion, setOpenAccordion] = useAccordionGroup(
        ["companyCert", "personalCert"],
        true
    );
    const [userCerts, setUserCerts] = useState([]);
    const [companyCerts, setCompanyCerts] = useState([]);
    const [userName, setUserName] = useState("");
    const { companyId } = useParams();

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [userSidebarOpen, setUserSidebarOpen] = useState(-1);
    const [selectedRow, setSelectedRow] = useState(null);
    const [detailViewKey, setDetailViewKey] = useState(null);
    const [userDetailViewKey, setUserDetailViewKey] = useState(null);

    const { update: updateBreadcrumbs } = useBreadcrumbs(
        employeeBreadcrumbs(t)
    );

    const getCertsAsync = async () => {
        const resUsers = await CompanyService.getEmployeesCertificates(
            "",
            companyId
        );
        const resCompany = await CompanyService.getCompanyCertificates(
            companyId
        );
        if (!resUsers.error && !resCompany.error) {
            const grp = groupBy(resUsers.data.data, "userId");
            setUserCerts(grp);
            setCompanyCerts(resCompany.data.data);
            if (resCompany.data.data && resCompany.data.data.length) {
                setUserName(resCompany.data.data[0].certificateHolder);
            }
        }
    };

    const updateCertificateStatus = async (certId, status) => {
        const res = await CompanyService.updateCertificateStatus(
            certId,
            status
        );
        if (res && res.error) {
            toast.error(t(res.error.message));
        } else {
            toast.success(t("actions:certificate.toastNotification.updated"));
            getCertsAsync();
        }
    };

    useEffect(() => {
        getCertsAsync();
    }, []);

    useEffect(() => {
        if (userName !== "") {
            const bc = employeeBreadcrumbs(t);
            bc[1].text = userName;
            updateBreadcrumbs(bc);
        }
    }, [userName]);

    const onSelectCompany = (row) => {
        const selectRow = async (row) => {
            setSelectedRow(row.original);
            setDetailViewKey(row.original);
            setSidebarOpen(true);
        };
        selectRow(row);
    };

    const onSelectUser = (row, idx) => {
        const selectRow = async (row) => {
            if (!row.original.approver) return;
            setSelectedRow(row.original);
            setUserSidebarOpen(idx);
        };
        selectRow(row);
    };

    const onCloseSidebar = () => {
        setSidebarOpen(false);
        setDetailViewKey(null);
    };

    const onCloseUserSidebar = (idx) => {
        if(userSidebarOpen === idx) setUserSidebarOpen(-1);
    };

    const certColumns = useMemo(
        () => [
            {
                Header: t("screens:certificates.tableHeaders.certHolder"),
                accessor: "certificateHolder",
                Cell: HolderCell,
            },
            {
                Header: t("screens:certificates.tableHeaders.certNumber"),
                accessor: "licenceNumber",
            },
            {
                Header: t("screens:certificates.tableHeaders.expiration"),
                accessor: "licenceExpiryDate",
                Cell: DateCell,
            },
            {
                Header: t("screens:certificates.tableHeaders.submitDate"),
                accessor: "licenceUploadDate",
                Cell: DateCell,
            },
            {
                Header: t("screens:certificates.tableHeaders.procDate"),
                accessor: "processedDate",
                Cell: DateCell,
            },
            {
                Header: t("sidebar:label.approver"),
                accessor: "approver",
            },
            {
                Header: t("screens:certificates.tableHeaders.status"),
                accessor: "licenceStatusName",
                Cell: StatusCell,
            },
            {
                Header: "",
                accessor: "id",
                Cell: ({ cell: { row } }) => {
                    return (
                        <AuthorizeComponent
                            roles={[Roles.Admin, Roles.MasterAdmin]}
                        >
                            {row.original.licenceId !== EmptyGuid && (
                                <HamburgerMenu up={true}>
                                    <HamburgerMenuAction
                                        text={t("actions:view")}
                                        onClick={() =>
                                            window.open(
                                                row.original
                                                    .licenceCertificateFile,
                                                "_blank"
                                            )
                                        }
                                    ></HamburgerMenuAction>
                                    {row.original.licenceStatus === 0 && (
                                        <>
                                            <HamburgerMenuAction
                                                text={t("actions:validate")}
                                                onClick={() =>
                                                    updateCertificateStatus(
                                                        row.original.licenceId,
                                                        1
                                                    )
                                                }
                                            />
                                            <HamburgerMenuAction
                                                text={t(
                                                    "actions:bidActions.reject"
                                                )}
                                                onClick={() =>
                                                    updateCertificateStatus(
                                                        row.original.licenceId,
                                                        2
                                                    )
                                                }
                                            />
                                        </>
                                    )}
                                </HamburgerMenu>
                            )}
                        </AuthorizeComponent>
                    );
                },
            },
        ],
        [t]
    );

    return (
        <ContentWrapper>
            <Helmet>
                <title>{t("screens:certificates.companyCert")}</title>
            </Helmet>
            <LayoutInnerContainer>
                <AuthorizeComponent roles={[Roles.MasterAdmin, Roles.Admin]}>
                    <AccordionWrapper
                        title={`${t("screens:certificates.companyCert")}`}
                        variant="overflow top-space inset"
                        style={{ marginTop: "56px" }}
                        noBorder
                        id="companyCert"
                        isOpen={openAccordion}
                        onToggle={setOpenAccordion}
                    >
                        <InfoWrapper>
                            <GreenInfo src="/images/menu/green-info.svg" />
                            <div>
                                <div className="info">
                                    {t("screens:certificates.companyInfo")}
                                </div>
                                <ul>
                                    <li>
                                        {t(
                                            "screens:certificates.companyList.installation"
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            "screens:certificates.companyList.maintenance"
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            "screens:certificates.companyList.recovery"
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            "screens:certificates.companyList.decommissioning"
                                        )}
                                    </li>
                                </ul>
                                <div className="info">
                                    {t(
                                        "screens:certificates.companyFullCert.info"
                                    )}
                                </div>
                                <ul>
                                    <li>
                                        {t(
                                            "screens:certificates.companyFullCert.personel"
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            "screens:certificates.companyFullCert.tools"
                                        )}
                                    </li>
                                </ul>
                            </div>
                        </InfoWrapper>

                        <Separator />
                        <ListingPage
                            key="company"
                            columns={certColumns}
                            tableState={{
                                data: companyCerts,
                            }}
                            onSelect={onSelectCompany}
                            closeSideBar={onCloseSidebar}
                            detailViewId="company"
                            detailView={
                                <AdminCertSidebar
                                    key="company"
                                    {...selectedRow}
                                    type="company"
                                />
                            }
                            detailViewIsOpened={sidebarOpen}
                        />
                    </AccordionWrapper>

                    <AccordionWrapper
                        title={`${t("screens:certificates.personalCert")}`}
                        id="personalCert"
                        isOpen={openAccordion}
                        onToggle={setOpenAccordion}
                        variant="overflow top-space inset"
                        style={{ marginTop: "56px" }}
                        noBorder
                    >
                        <InfoWrapper>
                            <GreenInfo src="/images/menu/green-info.svg" />
                            <div>
                                <div className="info">
                                    {t(
                                        "screens:certificates.personalCertInfo.allTechnicians"
                                    )}
                                </div>
                                <div className="info">
                                    {t(
                                        "screens:certificates.personalCertInfo.requirement"
                                    )}
                                </div>
                                <div className="info topSpace">
                                    {t(
                                        "screens:certificates.personalCertInfo.qualification"
                                    )}
                                </div>
                                <div className="info topSpace">
                                    {t(
                                        "screens:certificates.personalCertInfo.regulation"
                                    )}
                                </div>
                                <ul>
                                    <li>
                                        {t(
                                            "screens:certificates.personalList.regulations"
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            "screens:certificates.personalList.emission"
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            "screens:certificates.personalList.recovery"
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            "screens:certificates.personalList.handling"
                                        )}
                                    </li>
                                    <li>
                                        {t(
                                            "screens:certificates.personalList.information"
                                        )}
                                    </li>
                                </ul>
                            </div>
                        </InfoWrapper>

                        {userCerts.map((certs, idx) => {
                            return (
                                <React.Fragment key={idx}>
                                    <Separator />
                                    <ListingPage
                                        key={idx}
                                        columns={certColumns}
                                        tableState={{
                                            data: certs,
                                        }}
                                        onSelect={(row)=>onSelectUser(row, idx)}
                                        closeSideBar={()=>onCloseUserSidebar(idx)}
                                        detailViewId={`user-${idx}`}
                                        detailView={
                                            <AdminCertSidebar
                                                key={`user-${idx}`}
                                                {...selectedRow}
                                                type="user"
                                            />
                                        }
                                        detailViewIsOpened={userSidebarOpen === idx}
                                    />
                                </React.Fragment>
                            );
                        })}
                    </AccordionWrapper>
                    <Spacer className="spacer" />
                </AuthorizeComponent>
            </LayoutInnerContainer>
        </ContentWrapper>
    );
};

export default CompanyCertificateScreen;
