const validateConfig = (stateConfig, stateModule) => {
    const initialStateKeys = Object.keys(stateConfig.initialState);
    const creatorActionKeys = Object.keys({...stateModule.creators, ...stateModule.actions});

    for(let stateKey of initialStateKeys){
        for(let creatorActionKey of creatorActionKeys){
            if(stateKey === creatorActionKey){
                throw new Error(`There is a key named "${stateKey}" in your state and an action or creator named "${creatorActionKey}" in the state module "${stateConfig.namespace}". Please make sure that no keys in the state, creator, or actions have the same name`);
            }
        }
    }
}

const createStateModule = (stateConfig, plugins) => {
    const stateConfigWithPlugins = applyPlugins(stateConfig, plugins)

    const generateActionTypes = () => {
        const actionTypes = {};

        for (let key of stateConfigWithPlugins.types) {
            actionTypes[key.toUpperCase()] = `${
                stateConfigWithPlugins.stateNamespace
            }_${key.toLowerCase()}`;
        }

        return actionTypes;
    };

    const types = generateActionTypes();
    const creators = stateConfigWithPlugins.creators(types);
    const actions = stateConfigWithPlugins.actions ? stateConfigWithPlugins.actions(creators) : {};
    const reducer = stateConfigWithPlugins.createReducer(types);
    reducer.initialState = stateConfigWithPlugins.initialState;

    const stateModule = {
        types,
        creators,
        actions,
        reducer,
        stateNamespace: stateConfigWithPlugins.stateNamespace
    };
    validateConfig(stateConfigWithPlugins, stateModule);

    return {
        types,
        creators,
        actions,
        reducer,
        stateNamespace: stateConfigWithPlugins.stateNamespace
    };
};

const applyPlugins = (stateConfig, plugins) => {
    if (!plugins) {
        return stateConfig;
    }
    plugins.forEach(plugin => {
        stateConfig.initialState = {
            ...stateConfig.initialState,
            ...plugin.initialState
        };
        stateConfig.types = [...stateConfig.types, ...plugin.types];

        const initialCreateCreators = stateConfig.creators;
        stateConfig.creators = types => {
            const initialCreators = initialCreateCreators(types);
            const pluginCreators = plugin.creators(types);

            return {
                ...initialCreators,
                ...pluginCreators
            };
        };

        if (plugin.actions) {
            const initialCreateActions = stateConfig.actions;
            stateConfig.actions = creators => {
                const initialActions = initialCreateActions(creators);
                const pluginActions = plugin.actions(creators);

                return {
                    ...initialActions,
                    ...pluginActions
                };
            };
        }

        const initialReducerCreator = stateConfig.createReducer;
        stateConfig.createReducer = types => {
            const pluginReducer = plugin.createReducer(types);
            const initialReducer = initialReducerCreator(types);

            return (state, action) => {
                initialReducer(state, action);
                pluginReducer(state, action);
            };
        };
    });

    return stateConfig;
};

export default createStateModule;
