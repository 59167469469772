import React from "react";
import styled from "styled-components";
import AccordionMenu from "./AccordionMenu";

const ParentListWrapper = styled.ul`
    list-style: none;
    padding: 0px;
    border-top:2px solid #ECEDEF;
    margin: 0px;
  
`;

const GasTypeItemsWithGrades = ({ items, handleClick, selectedItem }) => {

    return (
        <ParentListWrapper>
            { items.map((item, index) =>
            (
                <AccordionMenu
                    item={item}
                    handleClick={handleClick}
                    selectedItem={selectedItem}
                    parentIndex={index}
                    isOpen={index === 0}
                    key={`${item.id}_${index}`}                
                    >

                </AccordionMenu>
            ))}
        </ParentListWrapper>
    )
};

export default GasTypeItemsWithGrades;
