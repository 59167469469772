import MarketplaceService from "../../../services/BuyRefrigerant/MarketplaceService";
import createListStateModule from "../createListStateModule";
import gasTypesStatePlugin from "../plugins/gasTypesStatePlugin";
import unpublishOfferPlugin from "../sell-refrigerant/sellRefrigerantListingPlugins";

const marketplaceListingStateModule = createListStateModule({
    stateNamespace: "marketplace",
    itemsPropertyName: "buyingItems",
    loadItemsMethodName: "loadBuyingItems",
    itemsDataMethod: MarketplaceService.getBuyingItems,
    loadDetailMethodName: "loadBuyingItemsById",
    detailDataMethod: MarketplaceService.getBuyingItemById,
    detailsPropertyName: "buyingItemData",
    updateMethodName: "updateBuyingItem",
    updateDataMethod: () => {},
    createMethodName: "createBuyingItem",
    createDataMethod: () => {},
    defaultItemsData: {data: []},
    plugins: [gasTypesStatePlugin, unpublishOfferPlugin]
});

export default marketplaceListingStateModule;
