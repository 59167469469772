import React, { useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as UploadSvg } from "../../../assets/fileupload.svg";
import useFileDrop from "./hooks/useFileDrop";
import Input from "./Input";
import { useTranslation } from "react-i18next";

const UploadInput = styled(Input)`
    background: #000;
`;

const StyledUpload = styled(UploadSvg)`
    position: absolute;
    left: 20px;
    top: 50%;
    width: 32px;
    height: 29px;
    transition: all 300ms ease;
    transform: translateY(-50%);

    &:hover .lines {
        stroke: #e94e4e;
    }
`;

export const UploadInputWrapper = styled.div`
    position: relative;
    height: 80px;
    border: 2px dashed #e2e2e2;
    font-size: 16px;
    border-radius: 18px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;

    &.white {
        background: #ffffff;
    }

    &.disabled {
        background: #f5f5f5;
    }

    &.disabled .placeholder {
        color: #888;
    }

    &.dragging {
        border: 2px dashed #2294d2;
        cursor: pointer;

        & .placeholder {
            opacity: 0;
        }

        & path {
            fill: #2294d2;
        }
    }

    &.has-file {
        & path {
            fill:#E2E2E2;
        }
    }

    & > .content {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-content: flex-start;
        align-items: flex-start;
        font-size: 12px;
        font-weight: 500;
        padding: 0px;
        color: #000;
        height: 100%;
        justify-content: center;
        box-sizing: border-box;
        padding-left: 86px;
        flex: 1;
    }

    & > .content .title {
        width: 100%;
        font-size: 20px;
        color: #000;
        font-weight: 700;
        line-height: 20px;
    }

    & > .placeholder {
        height: 100%;
        line-height: 80px;
        padding-right: 20px;
        color: #2294d2;
        font-weight: 500;
        cursor: pointer;
    }
    & > .placeholder.has-file {
        color: #e94e4e;
    }

    &.disabled > .content .title {
        color: #888;
        text-shadow: none;
    }

    & input {
        opacity: 0;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 99;
        height: 32px;
        margin: 0;
        padding: 0;
        display: block;
        width: 100%;
    }

    & input.hidden {
        display: none;
        z-index: -1;
        width: 0px;
        height: 0px;
    }

    & .back-image {
        position: absolute;
        left: 1px;
        top: 1px;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        z-index: -1;
        background-position: center;
        background-size: cover;
        border-radius: 15px;
    }
`;

const SingleFileUploader = (props) => {
    const { t } = useTranslation("actions");
    const [file, setFile] = useState(props.value);
    const inputRef = useRef();
    const onChange = (e) => {
        if (e && e.currentTarget.files.length > 0) {
            let newFile = Array.from(e.target.files)[0];
            if (newFile) {
                props.setFieldValue(props.name, newFile);
                setFile(newFile);
            }
        }
    };

    const { isDragging, getUploaderProps } = useFileDrop((files) => {
        inputRef.current.files = files;
        let event = document.createEvent("UIEvents");
        event.initUIEvent("change", true, true);
        inputRef.current.dispatchEvent(event);
    });

    const fileName = file?.name;
    let placeholder = file;

    if (!placeholder) placeholder = props.placeholder || "Drop or add files";
    const handleReset = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        if (props.disabled) return;
        props.setFieldValue(props.name, "");
        setFile("");
        inputRef.current.value = "";
    };

    return (
        <>
            <UploadInputWrapper
                {...getUploaderProps()}
                data-text={props.text || "Or Select"}
                disabled={props.disabled}
                className={[
                    props.className,
                    "inputElement",
                    isDragging && "dragging",
                    props.disabled && "disabled",
                    file && "has-file",
                ]}
            >
                <StyledUpload />

                <input
                    ref={inputRef}
                    type="file"
                    onChange={onChange}
                    multiple={false}
                    accept={props.accept}
                    className="hidden"
                />
                <UploadInput {...props} type="generic" className="hidden" />
                <div className={`content ${fileName ? "empty" : ""}`}>
                    {props.title && <div className="title">{props.title}</div>}
                    {fileName && <div className="fname">{fileName}</div>}
                </div>
                <div
                    className={`placeholder ${fileName ? "has-file" : ""}`}
                    onClick={(ev) => {
                        if (props.disabled) return;

                        if (fileName) handleReset(ev);
                        else {
                            inputRef.current &&
                                inputRef.current.dispatchEvent(
                                    new MouseEvent("click")
                                );
                        }
                    }}
                >
                    {fileName ? t("remove") : t("browse")}
                </div>
            </UploadInputWrapper>
        </>
    );
};

export default SingleFileUploader;
