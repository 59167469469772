import createStateModule from "./createStateModule";

/**
 * Creates a state module for a basic listing
 * @param {string} stateNamespace - the namespace of the state module
 * @param {string} itemsPropertyName - the name of the data property that will be exposed by the module
 * @param {string} detailsPropertyName - the name of the detail data property that will be exposed by the module in the "details" object
 * @param {string} loadItemsMethodName - the name of the method for loading data that will be exposed by useStateModule - default value "loadItems"
 * @param {string} loadDetailMethodName - the name of the method for loading item detail data - default value "loadDetail"
 * @param {function} itemsDataMethod - the method that will be called to load the data
 * @param {function} detailDataMethod - the method that will be called to load the item details data
 * @example
 * // creating the state module
 * const employeeListStateModule = createListStateModule({
 *   stateNamespace: "employees",
 *   itemsPropertyName: "employees",
 *   detailsPropertyName: "employeeData",
 *   loadItemsMethodName: "loadEmployees",
 *   itemsDataMethod: CompanyService.getCompanyEmployees,
 *   detailDataMethod: CompanyService.getCompanyEmployeeDetail,
 *   loadDetailMethodName: "loadEmployeeDetails"
 *  });
 * // Using the state module - note that  "employees" here is the itemsPropertyName parameter and "loadEmployees" is
 * // the loadItemsMethodName parameter
 * const {employees, isLoading, error, loadEmployees, loadEmployeeDetails, details} = useStateModule(employeeListStateModule);
 *
 * useEffect(() => {
 *    loadEmployees();
 * }, [loadEmployees]);
 *
 * // Later in code
 *
 * loadEmployeeDetails(employeeId) // loads employee data into details.employeeData
 */
const createListStateModule = ({
    stateNamespace,
    itemsPropertyName,
    detailsPropertyName = "data",
    loadItemsMethodName = "loadItems",
    loadDetailMethodName = "loadDetail",
    deleteMethodName = "deleteItem",
    updateMethodName = "updateItem",
    createMethodName = "createItem",
    itemsDataMethod,
    detailDataMethod,
    deleteDataMethod,
    updateDataMethod,
    createDataMethod,
    defaultItemsData = [],
    plugins
}) => {
    const initialState = {
        [itemsPropertyName]: defaultItemsData,
        isLoading: false,
        error: null,
        details: {
            isLoading: false,
            error: null,
            [detailsPropertyName]: {
                id: null
            }
        },
        deleteData: {
            isLoading: false,
            error: null,
            isCompleted: false
        },
        updateData: {
            isLoading: false,
            error: null,
            isCompleted: false
        },
        createData: {
            isLoading: false,
            error: null,
            isCompleted: false
        }
    };
    const listStateConfig = {
        stateNamespace: stateNamespace,
        types: [
            "LOADING",
            "ERROR",
            "SUCCESS",
            "DETAIL_LOADING",
            "DETAIL_ERROR",
            "DETAIL_SUCCESS",
            "RESET_STATE",
            "DELETE_LOADING",
            "DELETE_ERROR",
            "DELETE_SUCCESS",
            "UPDATE_ITEM_LOADING",
            "UPDATE_ITEM_ERROR",
            "UPDATE_ITEM_SUCCESS",
            "CREATE_ITEM_LOADING",
            "CREATE_ITEM_ERROR",
            "CREATE_ITEM_SUCCESS"
        ],
        initialState: initialState,
        createReducer: types => {
            return (state, action) => {
                switch (action.type) {
                    case types.RESET_STATE:
                        state.createData = initialState.createData;
                        state.updateData = initialState.updateData;
                        state.deleteData = initialState.deleteData;
                        state.details = initialState.details;
                        state[itemsPropertyName] = defaultItemsData;
                        state.isLoading = false;
                        state.error = null;
                        break;
                    case types.LOADING:
                        //state[itemsPropertyName] = defaultItemsData;
                        state.isLoading = true;
                        state.error = null;
                        break;
                    case types.ERROR:
                        state.isLoading = false;
                        state.error = action.payload;
                        break;
                    case types.SUCCESS:
                        state.isLoading = false;
                        state.error = null;
                        state[itemsPropertyName] = action.payload;
                        break;
                    case types.DETAIL_LOADING:
                        state.details.isLoading = true;
                        state.details.error = null;
                        state.details[detailsPropertyName] = {
                            [detailsPropertyName]: {
                                id: null
                            }
                        };
                        break;
                    case types.DETAIL_ERROR:
                        state.details.isLoading = false;
                        state.details.error = action.payload;
                        state.details[detailsPropertyName] = {
                            isLoading: false,
                            error: null,
                            [detailsPropertyName]: {
                                id: null
                            }
                        };
                        break;
                    case types.DETAIL_SUCCESS:
                        state.details.isLoading = false;
                        state.details.error = null;
                        state.details[detailsPropertyName] = action.payload;
                        break;
                    case types.DELETE_LOADING:
                        state.deleteData.isLoading = true;
                        state.deleteData.error = null;
                        state.deleteData.isCompleted = false;
                        break;
                    case types.DELETE_ERROR:
                        state.deleteData.isLoading = false;
                        state.deleteData.error = action.payload;
                        state.deleteData.isCompleted = false;
                        break;
                    case types.DELETE_SUCCESS:
                        state.deleteData.isLoading = false;
                        state.deleteData.error = null;
                        state.deleteData.isCompleted = true;
                        break;
                    case types.UPDATE_ITEM_LOADING:
                        state.updateData.isLoading = true;
                        state.updateData.error = null;
                        state.updateData.isCompleted = false;
                        break;
                    case types.UPDATE_ITEM_ERROR:
                        state.updateData.isLoading = false;
                        state.updateData.error = action.payload;
                        state.updateData.isCompleted = false;
                        break;
                    case types.UPDATE_ITEM_SUCCESS:
                        state.updateData.isLoading = false;
                        state.updateData.error = null;
                        state.updateData.isCompleted = true;
                        break;
                    case types.CREATE_ITEM_LOADING:
                        state.createData.isLoading = true;
                        state.createData.error = null;
                        state.createData.isCompleted = false;
                        break;
                    case types.CREATE_ITEM_ERROR:
                        state.createData.isLoading = false;
                        state.createData.error = action.payload;
                        state.createData.isCompleted = false;
                        break;
                    case types.CREATE_ITEM_SUCCESS:
                        state.createData.isLoading = false;
                        state.createData.error = null;
                        state.createData.isCompleted = true;
                        break;
                    default:
                        return state;
                }
            };
        },
        creators: types => ({
            loading: () => ({ type: types.LOADING }),
            errorCreator: error => ({ type: types.ERROR, payload: error }),
            success: items => ({ type: types.SUCCESS, payload: items }),
            detailsLoading: () => ({ type: types.DETAIL_LOADING }),
            detailsError: error => ({
                type: types.DETAIL_ERROR,
                payload: error
            }),
            detailsSuccess: item => ({
                type: types.DETAIL_SUCCESS,
                payload: item
            }),
            resetState: () => ({ type: types.RESET_STATE }),
            deleteLoading: () => ({ type: types.DELETE_LOADING }),
            deleteError: error => ({
                type: types.DELETE_ERROR,
                payload: error
            }),
            deleteSuccess: () => ({ type: types.DELETE_SUCCESS }),
            updateLoading: () => ({ type: types.UPDATE_ITEM_LOADING }),
            updateError: err => ({
                type: types.UPDATE_ITEM_ERROR,
                payload: err
            }),
            updateSuccess: res => ({
                type: types.UPDATE_ITEM_SUCCESS,
                payload: res
            }),
            createLoading: () => ({ type: types.CREATE_ITEM_LOADING }),
            createError: err => ({
                type: types.CREATE_ITEM_ERROR,
                payload: err
            }),
            createSuccess: res => ({
                type: types.CREATE_ITEM_SUCCESS,
                payload: res
            })
        }),
        actions: creators => ({
            [loadItemsMethodName]: parameters => {
                return async dispatch => {
                    dispatch(creators.loading());
                    try {
                        const dataItems = await itemsDataMethod(parameters);
                        dispatch(creators.success(dataItems));
                    } catch (e) {
                        dispatch(creators.errorCreator(e));
                    }
                };
            },
            [loadDetailMethodName]: params => {
                return async dispatch => {
                    dispatch(creators.detailsLoading());
                    try {
                        const item = await detailDataMethod(params);
                        dispatch(creators.detailsSuccess(item));
                    } catch (e) {
                        dispatch(creators.detailsError(e));
                    }
                };
            },
            [deleteMethodName]: params => {
                return async dispatch => {
                    dispatch(creators.deleteLoading());
                    try {
                        await deleteDataMethod(params);
                        dispatch(creators.deleteSuccess());
                    } catch (e) {
                        dispatch(creators.deleteError(e));
                    }
                };
            },
            [updateMethodName]: params => {
                return async dispatch => {
                    dispatch(creators.updateLoading());
                    try {
                        const response = await updateDataMethod(params);
                        dispatch(creators.updateSuccess(response));
                    } catch (e) {
                        dispatch(creators.updateError(e));
                    }
                };
            },
            [createMethodName]: params => {
                return async dispatch => {
                    dispatch(creators.createLoading());
                    try {
                        const response = await createDataMethod(params);
                        dispatch(creators.createSuccess(response));
                    } catch (e) {
                        dispatch(creators.createError(e));
                    }
                };
            }
        })
    };

    const listStateModule = createStateModule(listStateConfig, plugins);
    return listStateModule;
};



export default createListStateModule;
