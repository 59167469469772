import React, { useEffect, useMemo, useState, useCallback, useRef } from "react";
import useStateModule from "../../../core/state/useStateModule";
import marketplaceListingStateModule from "../../../core/state/buy-refrigerant/marketplaceListingStateModule";
import { useTranslation } from "react-i18next";
import useBreadcrumbs from "../../../core/state/navigation/useBreadcrumbs";
import DateCell from "../../../components/data/DateCell";
import HamburgerMenu from "../../../components/menu/HamburgerMenu";
import HamburgerMenuAction from "../../../components/menu/HamburgerMenuAction";
import AuthorizeComponent from "../../../core/AuthorizeComponent";
import ListingPage from "../../../components/data/ListingPage";
import UserService from "../../../services/User/UserService";
import DistDisposalDetailSideBar from "./components/DistDisposalDetailSideBar";
import ModalDialog, { useModal } from "../../../components/modal/ModalDialog";
import DetailSideBar from "../../../components/modal/DetailSideBar";
import OfferBids from "../../SalesCenter/views/components/OfferBids";
import MarketplaceService from "../../../services/BuyRefrigerant/MarketplaceService";
import Button from "../../../components/elements/buttons/Button";
import useClickAway from "../../../hooks/_shared/useClickAway";
import useImmerReducer from "../../../hooks/_shared/useImmerReducer";
import { toast } from "react-toastify";
import usePageChange from "../../../hooks/_shared/usePageChange";
import DisposalFilterDist from "./components/DisposalFilterDist";
import Roles from "../../../core/enums/Roles";
import ModalWizard from "../../../components/modal/ModalWizard";
import WizardPlaceDisposalBid from "./components/wizard/WizardPlaceDisposalBid";
import GradeCell from "../../../components/data/GradeCell";
import GreenCell from "../../../components/data/GreenCell";
import StockService from "../../../services/Stock/StockService";
import { Helmet } from "react-helmet";
import OfferStatus from "../../../core/enums/OfferStatus";
import TabLinks from "../../../components/elements/tabs/TabLinks";
import RouteCreator from "../../../core/RouteCreator";
import useSharedState from "../../../hooks/_shared/useSharedState";

const actionTypes = {
    SET_HIDEMYOFFERS: "SET_HIDEMYOFFERS",
    RESET_HIDEMYOFFERS: "RESET_HIDEMYOFFERS",
    SET_HIDEWITHBIDS: "SET_HIDEWITHBIDS",
    RESET_HIDEWITHBIDS: "RESET_HIDEWITHBIDS",
    SET_GAS_TYPE: "SET_GAS_TYPE",
    RESET_GAS_TYPE: "RESET_GAS_TYPE",
    SET_GAS_QUANTITY: "SET_GAS_QUANTITY",
    RESET_GAS_QUANTITY: "RESET_GAS_QUANTITY",
    SET_PRESUMED_QUALITY: "SET_PRESUMED_QUALITY",
    RESET_PRESUMED_QUALITY: "RESET_PRESUMED_QUALITY",
    SET_QUALITY_ASSURANCE: "SET_QUALITY_ASSURANCE",
    RESET_QUALITY_ASSURANCE: "RESET_QUALITY_ASSURANCE",
    SET_OFFER_CREATION__DATE_FROM: "SET_OFFER_CREATION__DATE_FROM",
    RESET_OFFER_CREATION__DATE_FROM: "RESET_OFFER_CREATION__DATE_FROM",
    SET_OFFER_CREATION__DATE_TO: "SET_OFFER_CREATION__DATE_TO",
    RESET_OFFER_CREATION__DATE_TO: "RESET_OFFER_CREATION__DATE_TO",
    SET_DEAL_EXPIRATION_FROM: "SET_DEAL_EXPIRATION_FROM",
    RESET_DEAL_EXPIRATION_FROM: "RESET_DEAL_EXPIRATION_FROM",
    SET_DEAL_EXPIRATION_TO: "SET_DEAL_EXPIRATION_TO",
    RESET_DEAL_EXPIRATION_TO: "RESET_DEAL_EXPIRATION_TO",
    SET_SORT: "SET_SORT",
    RESET_SORT: "RESET_SORT",
    SET_COUNTRY: "SET_COUNTRY",
    RESET_COUNTRY: "RESET_COUNTRY",
    SET_PAGING: "SET_PAGING",
};

const marketplaceReducer = (state, action) => {
    if (action.type !== actionTypes.SET_PAGING) state.page = 1;
    switch (action.type) {
        case "SET_ACTIVETAB":
            state.filter.offerStatuses = action.payload;
            state.filter.bidStatuses = [];
            delete state.filter.hasOffers;
            delete state.filter.gasType;
            delete state.filter.gasQuantityFrom;
            delete state.filter.gasQuantityTo;
            delete state.filter.presumedQuality;
            delete state.filter.hasAnalysis;
            delete state.filter.bidDealExpirationFrom;
            delete state.filter.bidDealExpirationTo;
            break;
        case "SET_ACTIVETAB_FAIL":
            state.filter.offerStatuses = [];
            state.filter.bidStatuses = action.payload;
            delete state.filter.hasOffers;
            delete state.filter.gasType;
            delete state.filter.gasQuantityFrom;
            delete state.filter.gasQuantityTo;
            delete state.filter.presumedQuality;
            delete state.filter.hasAnalysis;
            delete state.filter.bidDealExpirationFrom;
            delete state.filter.bidDealExpirationTo;
            break;
        case "SET_HIDEWITHBIDS":
            if (action.payload === "2") {
                state.filter.hideOffersWithMyBid = true;
                delete state.filter.showOnlyMyBids;
            } else {
                delete state.filter.hideOffersWithMyBid;
                state.filter.showOnlyMyBids = true;
            }
            break;
        case "RESET_HIDEWITHBIDS":
            delete state.filter.hideOffersWithMyBid;
            delete state.filter.showOnlyMyBids;
            break;
        case "SET_PAGING":
            state.page = action.payload.pageIndex + 1;
            state.pageSize = action.payload.pageSize;
            break;
        case "SET_SORT":
            state.orderColumn = action.payload[0].id;
            state.descending = action.payload[0].desc;
            break;
        case "RESET_SORT":
            state.orderColumn = "";
            state.descending = true;
            break;
        case "SET_GAS_TYPE":
            state.filter.gasType = action.payload;
            break;
        case "RESET_GAS_TYPE":
            delete state.filter.gasType;
            break;
        case "SET_GAS_QUANTITY":
            state.filter.gasQuantityTo = Number(action.payload);
            break;
        case "RESET_GAS_QUANTITY":
            delete state.filter.gasQuantityTo;
            break;
        case "SET_PRESUMED_QUALITY":
            state.filter.presumedQuality = Number(action.payload);
            break;
        case "RESET_PRESUMED_QUALITY":
            delete state.filter.presumedQuality;
            break;
        case "SET_QUALITY_ASSURANCE":
            state.filter.hasAnalysis = action.payload === "true" ? true : false;
            break;
        case "RESET_QUALITY_ASSURANCE":
            delete state.filter.hasAnalysis;
            break;

        case actionTypes.SET_OFFER_CREATION__DATE_FROM:
            state.filter.offerCreationDateTimeFrom = action.payload;
            break;
        case actionTypes.RESET_OFFER_CREATION__DATE_FROM:
            delete state.filter.offerCreationDateTimeFrom;
            break;
        case actionTypes.SET_OFFER_CREATION__DATE_TO:
            state.filter.offerCreationDateTimeTo = action.payload;
            break;
        case actionTypes.RESET_OFFER_CREATION__DATE_TO:
            delete state.filter.offerCreationDateTimeTo;
            break;

        case actionTypes.SET_DEAL_EXPIRATION_FROM:
            state.filter.dealExpirationFrom = action.payload;
            break;
        case actionTypes.RESET_DEAL_EXPIRATION_FROM:
            delete state.filter.dealExpirationFrom;
            break;
        case actionTypes.SET_DEAL_EXPIRATION_TO:
            state.filter.dealExpirationTo = action.payload;
            break;
        case actionTypes.RESET_DEAL_EXPIRATION_TO:
            delete state.filter.dealExpirationTo;
            break;
        case actionTypes.SET_COUNTRY:
            state.filter.countryId = action.payload;
            break;
        case actionTypes.RESET_COUNTRY:
            delete state.filter.countryId;
            break;
        default:
            return state;
    }
};

const tabItems = (t, isAdmin) => {
    let items = [
        {
            name: t("navigation:marketplace"),
            path: RouteCreator.services.disposal(),
        },
    ];
    if (!isAdmin) {
        items.push({
            name: t("navigation:myBids"),
            path: RouteCreator.services.disposalBids(),
        });
    }
    return items;
};

const initialValues = {
    gradeA: "0.00",
    gradeB: "0.00",
    gradeC: "0.00",
    transportationCosts: 0,
    administrationCosts: 0,
    chargeSeller: false,
};

const createSteps = (value, t, offerer = "", buyer = "") => {
    let result = Array.from([{ status: 0 }, { status: 0 }]);
    switch (value) {
        case OfferStatus.waitingForOffererFunds:
            result[0] = {
                status: 2,
                tip: t("screens:myOffers.waitingForOffererFunds"),
            };
            break;
        case OfferStatus.disposeAccepted:
            result[0] = {
                status: 1,
                tip: t("screens:myOffers.sellerEscrowed", {
                    company: offerer,
                }),
            };
            break;
        case OfferStatus.disposed:
            result[0] = {
                status: 1,
                tip: t("screens:myOffers.sellerEscrowed", {
                    company: offerer,
                }),
            };
            result[1] = {
                status: 1,
                tip: t("screens:myOffers.buyerClaimed", {
                    company: buyer,
                }),
            };
            break;
        default:
            break;
    }
    return result;
};

const disposalColumns = ({
    t,
    currentLanguage,
    offerStatuses,
    CompanyId,
    setCurrentOfferTranslations,
    toggleOpen,
    openPlaceBid,
    revokeBid,
    activeBidStatus,
    globalState,
}) => {
    const columns = [
        {
            Header: t("table:headers.gasType"),
            accessor: "refrigerantGasTypeCode",
            Cell: GreenCell,
        },
        {
            Header: t("table:headers.gasQuantity"),
            accessor: "quantity",
            Cell: ({ cell: { row, value } }) =>
                GreenCell({
                    cell: {
                        value:
                            value.toFixed(2) +
                            ` kg / ${row.original.numberOfCylinders || "-"} ${t("screens:technician.cylinders")}`,
                    },
                }),
        },
        {
            Header: t("table:headers.grade"),
            accessor: "pressumedGradeName",
            Cell: GradeCell,
        },
        {
            Header: t("table:headers.evaluation"),
            accessor: "analysisDone",
            Cell: ({ cell: { value } }) =>
                !value ? t("filters:analysis.measurement") : t("filters:analysis.selfAssessment"),
        },
        {
            Header: t("table:headers.owner"),
            accessor: "offerer",
        },
        {
            Header: t("table:headers.published"),
            accessor: "offerCreationDateTime",
            Cell: (params) => {
                return DateCell(params);
            },
        },
        {
            Header: "",
            accessor: "offerId",
            Cell: ({
                cell: {
                    row: { original },
                    value,
                },
            }) => {
                return (
                    <AuthorizeComponent roles={[Roles.InstallerCompanyAdmin, Roles.InstallerCompanyUser]}>
                        {original.offerStatus !== OfferStatus.disposed && (
                            <HamburgerMenu>
                                {!original.bidAlreadyPlaced &&
                                    original.offererId !== CompanyId &&
                                    activeBidStatus === 0 && (
                                        <HamburgerMenuAction
                                            disabled={!globalState.hasCertificateRights}
                                            text={t("actions:bidActions.bidForOffer")}
                                            onClick={async () => {
                                                setCurrentOfferTranslations(
                                                    `${original.refrigerantGasTypeCode} - ${original.quantity}kg`
                                                );
                                                openPlaceBid(original);
                                            }}
                                        />
                                    )}
                                {original.bidAlreadyPlaced && original.offererId !== CompanyId && (
                                    <HamburgerMenuAction
                                        disabled={original.offerStatus !== 0}
                                        text={t("actions:bidActions.revokeBid")}
                                        onClick={async () => {
                                            revokeBid(original);
                                        }}
                                    />
                                )}
                            </HamburgerMenu>
                        )}
                    </AuthorizeComponent>
                );
            },
            disableSortBy: true,
        },
    ];

    return columns;
};

const DistributorDisposalMarket = () => {
    const {
        buyingItems,
        isLoading,
        error,
        loadBuyingItems,
        loadBuyingItemsById,
        details,
        unpublishData,
        loadGasTypes,
        gasTypesData,
    } = useStateModule(marketplaceListingStateModule);
    const { t, i18n } = useTranslation("screens");
    const [globalState] = useSharedState("global");

    const [tabIndex, setTabIndex] = useState(0);
    const [tabIndexFail, settabIndexFail] = useState(-1);

    const getActiveBidStatus = () => {
        if (tabIndex === 1 || tabIndex === 2) return 1;
        if (tabIndexFail === 0) return 3;
        if (tabIndexFail === 1) return 2;
        return 0;
    };

    useEffect(() => {
        loadGasTypes();
    }, [loadGasTypes]);

    useBreadcrumbs([
        {
            text: t("navigation:services"),
        },
        {
            text: t("navigation:disposalService"),
        },
    ]);

    const { CompanyId } = useMemo(() => UserService.getUserDetailsFromToken(), []);

    const [filterState, dispatch] = useImmerReducer(marketplaceReducer, {
        filter: {
            showDisposals: true,
            hideOffersWithMyBid: true,
        },
        page: 1,
        pageSize: 10,
        orderColumn: "offerCreationDateTime",
        descending: true,
    });

    const [myBids, setMyBids] = useState([]);

    useEffect(() => {
        loadBuyingItems(filterState);
    }, [loadBuyingItems, filterState]);

    const [detailViewIsOpened, setDetailViewIsOpened] = useState(false);
    const [selectedOffer, setSelectedOffer] = useState({
        offer: null,
        bid: null,
    });
    const onSelect = useCallback(
        (row) => {
            const selectRow = async (row) => {
                let currentBid = null;

                const bids = await MarketplaceService.getBids(row.original.offerId);
                //Filter ny activeBidStatus
                if (bids.length > 0) {
                    const activeBidStatus = getActiveBidStatus();
                    const activeBids = bids.filter((b) => b.bidStatus === activeBidStatus);
                    if (activeBids.length > 0) currentBid = activeBids[0];
                }

                await loadBuyingItemsById(row.original.offerId);
                setSelectedOffer({ offer: row.original, bid: currentBid });
                setDetailViewIsOpened(true);
            };
            selectRow(row);
        },
        [loadBuyingItemsById, tabIndex, tabIndexFail]
    );

    const closeSideBar = () => {
        setDetailViewIsOpened(false);
        setSelectedOffer({
            offer: null,
            bid: null,
        });
    };

    //unpublish action
    const { modalProps, toggleOpen } = useModal({
        type: "warning",
        title: t("actions:dialogs.unPublishOffer.title"),
        onConfirm: async (model) => {
            const result = await StockService.unpublishFromMarketplace({
                offerIds: [model.original.offerId],
            });
            if (result && result.error) {
                toast.error(t(result.error.message));
            } else {
                loadBuyingItems(filterState);
                toast.success(t("myOffers.toastNotification.offerUnpublished"));
                toggleOpen();
            }
        },
        onCancel: () => {
            toggleOpen();
        },
        confirmText: t("actions:dialogs.unPublishOffer.confirmText"),
    });

    // revoke bid action
    const revokeBid = async (offer) => {
        const bids = await MarketplaceService.getBids(offer.offerId);
        const currentBid = bids.filter((b) => b.bidId === offer.bidId)[0];
        setSelectedOffer({ offer: offer, bid: currentBid });
        toggleOpenRevoke();
    };

    const { modalProps: revokeModalProps, toggleOpen: toggleOpenRevoke } = useModal({
        title: t("actions:dialogs.revokeBid.title"),
        onConfirm: async () => {
            const result = await MarketplaceService.revokeBid(selectedOffer.bid.bidId);
            if (result.error) {
                toast.error(result.error.message);
            } else {
                loadBuyingItems(filterState);
                setDetailViewIsOpened(false);
                toast.success(t("screens:myBids.successfullyRevoked"));
            }
            toggleOpenRevoke();
        },
        onCancel: () => {
            toggleOpenRevoke();
        },
        confirmText: t("actions:dialogs.revokeBid.confirmText"),
        type: "warning",
    });

    const [currentOfferTranslations, setCurrentOfferTranslations] = useState("");
    const getRowClassName = useCallback((row) => {
        if (row.original.offererId === CompanyId) {
            return "my-company";
        }
    }, []);

    const [bidViewIsOpened, setBidViewIsOpened] = useState(false);
    const [offers, setOffers] = useState([]);
    const bidSideBarRef = useRef(null);

    const closeBidSidebar = () => {
        setBidViewIsOpened(!bidViewIsOpened);
    };

    const [selectedRows, setSelectedRows] = useState([]);
    const toggleMultipleBids = (rows) => {
        setBidViewIsOpened(!bidViewIsOpened);
        if (rows) {
            const offers = rows.reduce((acc, currentRow) => {
                let offer = currentRow.original;
                acc.push(offer);
                return acc;
            }, []);

            setOffers(offers);
        }
    };

    useClickAway(bidSideBarRef, () => {
        setBidViewIsOpened(false);
    });

    const shouldShowCheckbox = useCallback(
        (row) => {
            if (row.original.bidAlreadyPlaced) {
                return false;
            }
            if (row.original.offererId === CompanyId) {
                return false;
            }
            return true;
        },
        [CompanyId]
    );

    const pageChange = usePageChange(dispatch, filterState);

    const sortChange = (sortBy) => {
        sortBy.length > 0
            ? dispatch({ type: actionTypes.SET_SORT, payload: sortBy })
            : dispatch({ type: actionTypes.RESET_SORT });
    };

    const currentSortBy = useMemo(() => {
        return [{ id: filterState.orderColumn, desc: filterState.descending }];
    }, [filterState.orderColumn, filterState.descending]);

    const openPlaceBid = (offer) => {
        return ModalWizard.show(
            WizardPlaceDisposalBid(t, initialValues, [offer], buyingItems.isLoading, globalState.enableHandlingCosts),
            {
                onSubmit: async (values) => {
                    if (!values.chargeSeller) {
                        values.administrationCosts = 0;
                        values.transportationCosts = 0;
                    }
                    const result = await MarketplaceService.addBid({
                        gradeA: values.gradeA,
                        gradeB: values.gradeB,
                        gradeC: values.gradeC,
                        offerIds: [offer.offerId],
                        disposalBid: true,
                        administrationCosts: values.administrationCosts,
                        transportationCosts: values.transportationCosts,
                    });

                    if (result && result.error) toast.error(t(result.error.message));
                    else {
                        loadBuyingItems(filterState);
                        toast.success(t("marketplace.toastNotification.bidPlaced"));
                        closeBidSidebar();
                        ModalWizard.hide();
                    }
                },
                onCancel: async () => {
                    ModalWizard.hidePrompt(setDetailViewIsOpened(false));
                },
            }
        );
    };

    return (
        <>
            <Helmet>
                <title>{t("seo:disposalService.title")}</title>
            </Helmet>
            <TabLinks
                variant="shadowed"
                items={tabItems(t, globalState.isAdmin)}
                idx={0}
                style={{ marginTop: "0px", padding: "10px 0px 10px 40px" }}
            />
            <ListingPage
                columns={disposalColumns({
                    t,
                    currentLanguage: i18n.language,
                    offerStatuses: filterState.filter.offerStatuses,
                    CompanyId,
                    setCurrentOfferTranslations,
                    toggleOpen,
                    openPlaceBid,
                    revokeBid,
                    activeBidStatus: getActiveBidStatus(),
                    globalState,
                })}
                tableState={{
                    data: buyingItems.data,
                    loading: isLoading || unpublishData.isLoading,
                    error: error,
                }}
                filterTitle={t("filters:disposalMarketPlace.filterTitle")}
                showTitle={true}
                filterComponent={() => {
                    return (
                        <DisposalFilterDist
                            filterState={filterState.filter}
                            dispatch={dispatch}
                            actionTypes={actionTypes}
                            gasTypes={gasTypesData.gasTypes}
                            tabIndex={tabIndex}
                            screen="rfps"
                        />
                    );
                }}
                //enableSelection
                paging={{
                    enablePaging: true,
                    pageCount: buyingItems.totalPages,
                    pageSize: buyingItems.pageSize,
                    onPageChange: pageChange,
                    currentPageIndex: buyingItems.currentPage - 1,
                }}
                sorting={{
                    enableSorting: true,
                    onSortByChange: sortChange,
                    currentSortBy: currentSortBy,
                }}
                setSelectedRows={setSelectedRows}
                noDataText={t("marketplace.notification")}
                getRowClassName={getRowClassName}
                onSelect={onSelect}
                closeSideBar={closeSideBar}
                shouldShowSelection={shouldShowCheckbox}
                detailViewIsOpened={detailViewIsOpened}
                detailView={
                    <DistDisposalDetailSideBar
                        key={selectedOffer.offer?.offerId}
                        offer={selectedOffer.offer}
                        bid={selectedOffer.bid}
                        details={details}
                        item={details.buyingItemData}
                        companyId={CompanyId}
                        isLoading={isLoading}
                        bidAlreadyPlaced={selectedOffer.offer && selectedOffer.offer.bidAlreadyPlaced}
                        getActiveBidStatus={getActiveBidStatus}
                        toggleBidModal={() => openPlaceBid(selectedOffer.offer)}
                        toggleRevokeBid={() => revokeBid(selectedOffer.offer)}
                    />
                }
            />

            {offers && offers.length > 0 && (
                <DetailSideBar detailIsOpened={bidViewIsOpened} closeSideBar={closeBidSidebar} ref={bidSideBarRef}>
                    <OfferBids offers={offers} showTotalOffers={false} isLoading={buyingItems.isLoading} />
                </DetailSideBar>
            )}
            <ModalDialog {...modalProps}>
                <p>{t("actions:dialogs.unPublishOffer.text")}</p>
                <p className="list">
                    {modalProps.modal &&
                        modalProps.modal.original &&
                        `${
                            modalProps.modal.original.refrigerantGasTypeCode
                        } | ${modalProps.modal.original.quantity?.toFixed(2)} kg | ${
                            modalProps.modal.original.pressumedGradeName
                        } | ${
                            modalProps.modal.original.analysisDone === "False"
                                ? t("filters:analysis.selfAssessment")
                                : t("filters:analysis.measurement")
                        }`}
                </p>
            </ModalDialog>

            <ModalDialog {...revokeModalProps}>
                <p>{t("actions:dialogs.revokeBid.text")}</p>
                <p className="list">
                    {selectedOffer.bid && `•${selectedOffer.bid.offeredPriceGradeC.toFixed(2)} EUR/kg`}
                </p>
                <p className="bold">{t("actions:dialogs.revokeBid.offeredItem")}</p>
                <p className="list">
                    {selectedOffer.offer &&
                        `• ${selectedOffer.offer.refrigerantGasTypeCode} | ${selectedOffer.offer.quantity.toFixed(
                            2
                        )} kg | ${selectedOffer.offer.pressumedGradeName} | ${
                            selectedOffer.offer.analysisDone === "False"
                                ? t("filters:analysis.selfAssessment")
                                : t("filters:analysis.measurement")
                        } `}
                </p>
            </ModalDialog>

            <div style={{ padding: "0px 60px" }}>
                <Button
                    style={{
                        display: selectedRows.length > 0 ? "block" : "none",
                    }}
                    onClick={() => toggleMultipleBids(selectedRows)}
                    className="primary"
                >
                    {t("marketplace.button.placeMultipleBids")}
                </Button>
            </div>
        </>
    );
};

export default DistributorDisposalMarket;
