import React from "react";
import InputElement from "../../../../components/elements/form-elements/InputElement";
import Select from "../../../../components/elements/form-elements/Select";
import FileDisplayWithAction from "../../../../components/file-processing/FileDisplayWithAction";
import Flex from "../../../../components/layout/flex/Flex";
import {
    InfoTitle,
    InfoWrapper,
    RoundInfo,
} from "../../../../components/modal/components/WizardStyling";
import MoneyInputElement from "../../../../components/elements/form-elements/MoneyInputElement";

const defaultInitialValues = {
    size: 0.0,
    refrigerantTypeCode: "",
    filledQuantity: 0,
    deleteImage: false,
    imageFile: "",
};

const CylinderWizardEdit = (t, searchFn, initialValues = undefined) => {
    const handleImageDelete = (shouldDelete, formik) => {
        formik.setFieldValue("deleteImage", shouldDelete);
    };

    return {
        title: t("forms:cylinder.edit"),
        initialValues: initialValues || defaultInitialValues,
        steps: [
            {
                name: t("forms:cylinder.newCylinderInfo"),
                fieldNames: [
                    "size",
                    "refrigerantTypeCode",
                    "filledQuantity",
                    "deleteImage",
                    "imageFile",
                ],
                onStepRender: (props) => {
                    if (!props.formik.values.size) {
                        props.formik.setFieldValue("size", 0.0);
                    }
                },
                fields: (formik) => {
                    return (
                        <>
                            <InfoWrapper>
                                <RoundInfo
                                    alt=""
                                    src="/images/menu/green-info.svg"
                                />
                                <div>
                                    <InfoTitle>
                                        {t(
                                            "forms:cylinder.editCylinderInfoTitle"
                                        )}
                                    </InfoTitle>
                                </div>
                            </InfoWrapper>

                            <Flex.Container
                                justifyContent="space-between"
                                style={{ padding: "0 15px" }}
                            >
                                <Flex.Column style={{ maxWidth: "50%" }}>
                                    <MoneyInputElement
                                        type="number"
                                        name="size"
                                        id="size"
                                        step="0.01"
                                        labelText={t("forms:cylinderSize.text")}
                                        wrapperVariant={"flex-1"}
                                        values={2}
                                        min={0}
                                        positive={true}
                                        formik={formik}
                                        currencyLabel="kg"
                                        //grade={"A"}
                                    />
                                    <Flex.Container>
                                        <InputElement
                                            labelText={t(
                                                "forms:cylinder.cylinderGas"
                                            )}
                                            id="refrigerantTypeCode"
                                            name="refrigerantTypeCode"
                                            placeholder={
                                                formik.values
                                                    .refrigerantTypeCode
                                                    ? ""
                                                    : t(
                                                          "exceptions:validation.NotAssigned"
                                                      )
                                            }
                                            type="text"
                                            wrapperVariant="flex-2"
                                            disabled
                                            {...formik}
                                        />
                                        <InputElement
                                            labelText={t(
                                                "forms:cylinder.quantity"
                                            )}
                                            id="filledQuantity"
                                            name="filledQuantity"
                                            type="text"
                                            disabled
                                            wrapperVariant="flex-2"
                                            {...formik}
                                        />
                                    </Flex.Container>
                                </Flex.Column>

                                {formik.values.imageUrl ===
                                "/images/cylinder_no_image.svg" ? (
                                    <InputElement
                                        labelText=""
                                        id="imageFile"
                                        name="imageFile"
                                        type="image"
                                        placeholder={t(
                                            "forms:cylinder.addPicture"
                                        )}
                                        title={t(
                                            "forms:cylinder.cylinderPicture"
                                        )}
                                        wrapperVariant="flex-2 double-height"
                                        {...formik}
                                    />
                                ) : (
                                    <FileDisplayWithAction
                                        fileType="image"
                                        filePath={formik.values.imageUrl}
                                        title={t(
                                            "forms:cylinderFileUpload.title"
                                        )}
                                        labelText=""
                                        wrapperVariant="double-height flex-2"
                                        handleFileDelete={(ev) =>
                                            handleImageDelete(ev, formik)
                                        }
                                    />
                                )}
                            </Flex.Container>
                        </>
                    );
                },
            },
        ],
    };
};

export default CylinderWizardEdit;
