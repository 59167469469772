import React from "react";
import Menu from "../../../../../components/dashboard/Menu";
import { NavLink } from "react-router-dom";

const ChildrenItems = ({ data }) => {
    return ( 
        <Menu.ListWrapper>

    { 
        data.map((item) =>
            (
                <Menu.Item
                    key={item.id}
                    data-info={item.name}
                    data-value={item.id}>
                    <div className= "content">         
                        <Menu.Detail>
                        {
                                item.url ? 
                                    <NavLink to={item.url}>
                                        {item.name}
                                    </NavLink> :
                                     <span>  {item.name}</span>
                        }
                        <Menu.Metric>
                                {item.count}
                        </Menu.Metric>
                        </Menu.Detail>
                    </div>
                </Menu.Item>
            ))
    }
    
    </Menu.ListWrapper>
)}

const MenuItems = ({ data, hasChildren }) => {

    return (
        <Menu.ListWrapper>
            { data.map((item) =>
            (
                <Menu.Item
                    key={item.id}
                    data-info={item.name}
                    data-value={item.id}>
                    <div className= { hasChildren ? "parent" : "content"}>                  
                        <Menu.Detail>
                            {
                                item.url ? 
                                    <NavLink to={item.url}>
                                        {item.name}
                                    </NavLink> 
                                    :
                                    <span>  {item.name}</span>
                            }
                           <Menu.Metric>
                                {item.count}
                           </Menu.Metric>
                        </Menu.Detail>
                    </div>

                    {
                        item.items && hasChildren && (
                          <ChildrenItems data={item.items}>

                          </ChildrenItems>
                        )
                    }

                   </Menu.Item>
            ))}
        </Menu.ListWrapper>
    )
};

const AdminDashboardMenu = ({ items, title, style, hasChildren, className }) => {
    const count = items? items.reduce((count, current) => count + current.count, 0): 0;
    return (

        <Menu.ColumnContainer style={style} className={className}>
            <Menu.TitleContainer>
                <Menu.Title>
                    {title}
                </Menu.Title>

                <Menu.CountWrapper>
                    {count}
                </Menu.CountWrapper>

            </Menu.TitleContainer>
            <MenuItems data={items} hasChildren={hasChildren} />
        </Menu.ColumnContainer >

    )
};

export default AdminDashboardMenu;
