import React from "react";
import helpers from "../../core/helpers";
import TooltipCell from "./TooltipCell";

const PurchasePrice = ({ row, t, currentLanguage, globalState, prices }) => {

    if (row.original.status === 99 || row.original.offerStatus === 99) {
        const currency = "EUR";
        const withVat =
            row.original.offererCountryId 
                ? row.original.offererCountryId === globalState?.userCountryId
                : row.original.acceptedBidInfo?.bidCompanyCountryId === globalState?.userCountryId;

        let totalPrice = 0;

        let analysis =
            row.original.buyerCylindersAnalysis &&
            row.original.buyerCylindersAnalysis.length > 0
                ? row.original.buyerCylindersAnalysis
                : row.original.cylindersAnalysis;

        analysis.map(
            (c) =>
                (totalPrice += helpers.calculatePurchasedPrice(
                    prices[c.gradeName],
                    c.quantity,
                    withVat
                ))
        );
        return TooltipCell({
            cell: {
                value: helpers.formatCurrency(
                    totalPrice,
                    currentLanguage,
                    currency
                ),
            },
            tip: withVat
                ? t("table:tooltips.withVat")
                : t("table:tooltips.withoutVat"),
        });
    }

    return "-";
};

export default PurchasePrice;
