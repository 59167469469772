import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Button from "../../components/elements/buttons/Button";
import { InputSubmit } from "../../components/elements/form-elements";
import FormTitle from "../../components/elements/form-elements/FormTitle";
import InputElement from "../../components/elements/form-elements/InputElement";
import Flex from "../../components/layout/flex/Flex";
import Wrappers from "../../components/wrappers/Wrappers";

const CreateAdministratorForm = ({ formik, isLoading }) => {
    const { t } = useTranslation("screens");
    const history = useHistory();
    return (
        <Wrappers.FormWrapper>
            <Flex.Container>
                <Flex.Column className="half">
                    <FormTitle title={t("adminCreate.title")} />
                    <Flex.Container flexWrap="wrap">
                        <InputElement
                            labelText={t("forms:firstName.text")}
                            id="firstName"
                            name="firstName"
                            autoComplete={false}
                            wrapperVariant="flex-half"
                            type="text"
                            {...formik}
                        />
                        <InputElement
                            labelText={t("forms:lastName.text")}
                            id="lastName"
                            name="lastName"
                            autoComplete={false}
                            wrapperVariant="flex-half"
                            type="text"
                            {...formik}
                        />
                        <InputElement
                            labelText={t("forms:phoneNumber.text")}
                            id="phoneNumber"
                            name="phoneNumber"
                            autoComplete={false}
                            type="text"
                            wrapperVariant="flex-half"
                            {...formik}
                        />
                        <InputElement
                            labelText={t("forms:email.text")}
                            id="email"
                            name="email"
                            autoComplete="email"
                            type="text"
                            wrapperVariant="flex-half"
                            {...formik}
                        />
                    </Flex.Container>
                </Flex.Column>
            </Flex.Container>

            <Wrappers.Wrapper>
                <Flex.Row
                    justifyContent="space-between"
                    style={{ width: "455px" }}
                >
                    <InputSubmit
                        disabled={isLoading}
                        variant="primary"
                        value={t("screens:adminCreate.submitText")}
                    />
                    <Button
                        className="secondary"
                        onClick={(ev) => {
                            ev.preventDefault();
                            history.goBack();
                        }}
                    >
                        {t("cancel")}
                    </Button>
                </Flex.Row>
            </Wrappers.Wrapper>
        </Wrappers.FormWrapper>
    );
};

export default CreateAdministratorForm;
