import React, { useState } from "react";
import styled from "styled-components";
import Headings from "../../../components/elements/headings/Headings";

import { InputSubmit } from "../../../components/elements/form-elements";
import { useTranslation } from "react-i18next";
import { Radio } from "../../../components/elements/form-elements";
import NavigationLink from "../../../components/elements/links/NavigationLink";
import RouteCreator from "../../../core/RouteCreator";
import Flex from "../../../components/layout/flex/Flex";
import userStateModule from "../../../core/state/user/userStateModule";
import useStateModule from "../../../core/state/useStateModule";
import UserService from "../../../services/User/UserService";

const HSpace = styled.div`
    width: 100%;
    height: ${(p) => p.size};
`;

const Title = styled.div`
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
`;

const Desc = styled.div`
    font-size: 12px;
    font-weight: normal;
    line-height: 12px;
`;

const FormWrapper = styled.div`
    width: 90%;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 25px;
`;

const FlexCol = styled.div`
    display: flex;
    flex-direction: column;
`;

const OrangeInfo = styled.img`
    width: 44px;
    height: 44px;
    margin-right: 24px;
`;

const BigTitle = styled.div`
    font-size: 20px;
    font-weight: 700;

    &.regular {
        font-weight: 400;
    }

    &.vspace {
        margin-top: 24px;
        margin-bottom: 60px;
    }
`;

const SelectCompanyForm = ({ companies }) => {
    const { t } = useTranslation("common");
    const [company, setCompany] = useState(null);
    const { logout } = useStateModule(userStateModule);

    const handleBack = (ev) => {
        ev.preventDefault();
        logout();
        window.location.reload();
    };

    const handleSubmit = async (ev) => {
        ev.preventDefault();
        const result = await UserService.updateCompanyForCurrentUser(company);
        localStorage.setItem("token", result.data.token);
        localStorage.setItem("refreshToken", result.data.refreshToken);
        window.location.reload();
    };

    return (
        <>
            <HSpace size="60px" />
            <FormWrapper>
                <div style={{ flexGrow: "1" }}>
                    <Headings.H2 className="center title bold">
                        {t("common:impersonate.chooseCompany")}
                    </Headings.H2>
                    <Flex.Row
                        style={{
                            display: "flex",
                            alignItems: "center",
                            margin: "60px 0px 24px",
                        }}
                    >
                        <OrangeInfo src="/images/menu/orange-info.svg" />
                        <BigTitle>
                            {t("screens:login.multipleCompanies")}
                        </BigTitle>
                    </Flex.Row>
                    <BigTitle className="regular vspace">
                        {t("screens:login.chooseSession")}
                    </BigTitle>
                    <div>
                        {companies.map((c, idx) => {
                            return (
                                <Radio
                                    // labelText="puši kitu"
                                    wrapperVariant="space"
                                    secondary
                                    name="radio"
                                    checked={company === c.companyId}
                                    onChange={() => setCompany(c.companyId)}
                                    key={idx}
                                >
                                    <div>
                                        <Title>{c.companyName}</Title>
                                        <Desc>{c.address}</Desc>
                                    </div>
                                </Radio>
                            );
                        })}
                    </div>
                </div>
                <FlexCol>
                    <InputSubmit
                        style={{ width: "50%", margin: "0 auto" }}
                        disabled={company === null}
                        type="submit"
                        value={t("screens:login.title")}
                        variant="primary"
                        onClick={(ev) => handleSubmit(ev)}
                    />
                    <NavigationLink
                        to={RouteCreator.user.login()}
                        className="forgotten-password"
                        onClick={(ev) => handleBack(ev)}
                    >
                        {t("screens:forgottenPassword.backToLoginLink")}
                    </NavigationLink>
                </FlexCol>
            </FormWrapper>
        </>
    );
};

export default SelectCompanyForm;
