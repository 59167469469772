import { config } from "../Config/config.prod";
import api, { apiResult } from "../getAxios";

const TagsService = {
    lookupEmptyCylinderTags: async (searchText) => {
        return (
            await api.post(config.apiUrl.tagsLookup, {
                searchText: searchText,
                IsForCollectEmpty: true,
            })
        ).data.data;
    },
    //Returns both free tags and tags that are already attached to a cylinder
    lookupCylinderTags: async (searchText) => {
        return (
            await api.post(config.apiUrl.tagsLookup, {
                searchText: searchText,
                isForEquipmentScreen: true,
            })
        ).data.data;
    },
    //Returns ONLY tags that are already assigned to a cylinder
    lookupExistingCylinderTags: async (searchText) => {
        return (
            await api.post(config.apiUrl.tagsLookup, {
                searchText: searchText,
                isForNewExtraction: true,
            })
        ).data.data;
    },
    lookupUnassignedTags: async (searchText) => {
        return (
            await api.post(config.apiUrl.tagsLookup, {
                searchText: searchText,
                isUnassigned: true,
            })
        ).data.data;
    },
    lookupUnassignedTagsAsync: async (searchText) => {
        return apiResult(
            api.post(config.apiUrl.tagsLookup, {
                searchText: searchText,
                isUnassigned: true,
            })
        );
    },
    lookupUnitTags: async (searchText, existing = true) => {
        return (
            await api.post(config.apiUrl.tagsLookup, {
                isExistingUnitTag: existing,
                searchText: searchText,
            })
        ).data.data;
    },
    lookupTagForEquipmentScreen: async (searchText) => {
        return (
            await api.post(config.apiUrl.tagsLookup, {
                searchText: searchText,
                isForEquipmentScreen: true,
            })
        ).data.data;
    },
    lookupTagForEquipmentScreenAsync: async (searchText) => {
        return apiResult(
            api.post(config.apiUrl.tagsLookup, {
                searchText: searchText,
                isForEquipmentScreen: true,
            })
        );
    },
    lookupTagForAnalyzerAsync: async (searchText) => {
        return apiResult(
            api.post(config.apiUrl.tagsLookup, {
                searchText: searchText,
                isAnalyzer: true,
            })
        );
    },
    lookupInstallerStockTag: async (searchText, userId) => {
        return (
            await api.post(config.apiUrl.tagsLookup, {
                searchText: searchText,
                isInInstallerStock: true,
                userId: userId,
            })
        ).data.data;
    },
    lookupCompanyStockTag: async (searchText, moveType) => {
        return (
            await api.post(config.apiUrl.tagsLookup, {
                searchText: searchText,
                isInCompanyStock: moveType === 0,
                isInOtherCompany: moveType === 1,
            })
        ).data.data;
    },
    identifyTag: async (id, skipCompanyCheck = false) => {
        return (await api.get(config.apiUrl.tagsIdentify + "/" + id + `?skipCompanyCheck=${skipCompanyCheck}`)).data
            .data;
    },
    identifyTagAsync: async (id, skipCompanyCheck = false) => {
        return apiResult(api.get(config.apiUrl.tagsIdentify + "/" + id + `?skipCompanyCheck=${skipCompanyCheck}`));
    },
    addCylinderIssueAsync: async (data) => {
        return apiResult(api.post(config.apiUrl.addCylinderIssue, data));
    },
    addSupportMessageAsync: async (data) => {
        return apiResult(api.post(config.apiUrl.addSupportMessage, data));
    },
    getSpocDetailsAsync: async () => {
        return apiResult(api.post(config.apiUrl.getSpocDetails, {}));
    },
    getDaikinSerial: async (serial) => {
        return await api.get(`${config.apiUrl.values}/testGetUnitInfoFromSap?serial=${serial}`);
    },
};

export default TagsService;
