import { config } from "../Config/config.prod";
import api, { apiResult } from "../getAxios";

const ForgottenPasswordService = {
    sendRequest: (username) => {
        return api.post(config.apiUrl.forgottenPassword, { username });
    },
    sendRequestAsync: (username) => {
        return apiResult(api.post(config.apiUrl.forgottenPassword, { username }));
    },
};

export default ForgottenPasswordService;
