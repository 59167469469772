import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const Title = styled.p`
    /* font-family: 'Open Sans', sans-serif; */
    color: ${props => props.theme.primaryColor};
    margin: 20px 0;
    font-size: 1.125rem;
    width: 100%;
`;

const FormTitle = (props) => {
    const {title} = props;

    return (
        <>
            {
                title &&
                (
                    <Title>{title}</Title>
                )
            }
        </>
    )
};

export default FormTitle;

FormTitle.propTypes = {
    title: PropTypes.any.isRequired
};
