import React from "react";
import styled from "styled-components";
import * as yup from "yup";
import {
    Checkbox,
    Select,
} from "../../../../../components/elements/form-elements";
import DatePicker from "../../../../../components/elements/form-elements/DatePicker";
import InputElement from "../../../../../components/elements/form-elements/InputElement";
import commonValidation from "../../../../../core/validations/common";
import ValidationRule, {
    buildValidation,
    skipValidation,
} from "../../../../../core/validations/ValidationRule";
import {
    InfoText,
    InfoTitle,
    InfoWrapper,
    RoundInfo,
} from "../../../../../components/modal/components/WizardStyling";
import CompanyService from "../../../../../services/Company/CompanyService";
import { toast } from "react-toastify";
import CountryService from "../../../../../services/User/CountryService";

const validTerms = (t) => {
    return buildValidation({
        acceptTerms: yup
            .boolean()
            .required()
            .oneOf([true], "'Must Accept Terms and Conditions'"),
        acceptMangoPay: yup
            .boolean()
            .required()
            .oneOf([true], "'Must Accept Terms and Conditions'"),
        acceptData: yup
            .boolean()
            .required()
            .oneOf([true], "'Must Accept Terms and Conditions'"),
    });
};

const validCompany = (t) => {
    return buildValidation({
        address: ValidationRule.isStringRule().required(
            t("forms:address.required")
        ).rule,
        city: ValidationRule.isStringRule().required(t("forms:city.required"))
            .rule,
        postalCode: ValidationRule.isStringRule().required(
            t("forms:postalCode.required")
        ).rule,
        countryId: ValidationRule.isStringRule().required(
            t("forms:country.required")
        ).rule,
        iban: ValidationRule.isStringRule()
            .isIBAN(t("forms:iban.invalid"))
            .required(t("forms:iban.required")).rule,
        companyNumber: ValidationRule.isStringRule().required(
            t("forms:companyNumber.required")
        ).rule,
    });
};

const validRepresentative = (t) => {
    return yup.lazy((values) => {
        return buildValidation({
            legalRepresentativeRegion:ValidationRule.isStringRule().required(
                t("forms:region.required")
            ).rule,
            legalRepresentativePostalCode:ValidationRule.isStringRule().required(
                t("forms:postalCode.required")
            ).rule,
            legalRepresentativeCity: ValidationRule.isStringRule().required(
                t("forms:city.required")
            ).rule,
            legalRepresentativeAddress: ValidationRule.isStringRule().required(
                t("forms:address.required")
            ).rule,
            legalRepresentativeFirstName:
                ValidationRule.isStringRule().required(
                    t("forms:firstName.required")
                ).rule,
            legalRepresentativeLastName: ValidationRule.isStringRule().required(
                t("forms:lastName.required")
            ).rule,
            legalRepresentativeBirthDate:
                ValidationRule.isStringRule().required(
                    t("forms:birthDate.required")
                ).rule,
            legalRepresentativeCountryOfResidenceId:
                ValidationRule.isStringRule().required(
                    t("forms:country.required")
                ).rule,
            legalRepresentativeCountryOfResidence: ValidationRule.shouldBeEqual(
                "legalRepresentativeCountryOfResidenceSelected",
                t("forms:countryOfResidence.invalid"),
                t("forms:countryOfResidence.required")
            ).rule,
            legalRepresentativeNationality: ValidationRule.shouldBeEqual(
                "legalRepresentativeNationalitySelected",
                t("forms:nationality.invalid"),
                t("forms:nationality.required")
            ).rule,
            legalRepresentativeEmail: commonValidation.email(t).rule,
        });
    });
};

const validPerson = (t) => {
    return buildValidation({
        userEmail: commonValidation.email(t).rule,
        userLastName: ValidationRule.isStringRule().required(
            t("forms:contactLastName.required")
        ).rule,
        userFirstName: ValidationRule.isStringRule().required(
            t("forms:contactLastName.required")
        ).rule,
    });
};

const validCert = (t) =>
    yup.lazy((values) => {
        if (!values.licenceCheck) return skipValidation();
        return buildValidation({
            userlicenceNumber: ValidationRule.isStringRule().required(
                t("forms:equipmentName.required")
            ).rule,
            userlicenceExpiryDate: ValidationRule.isStringRule().required(
                t("forms:equipmentSerialNumber.required")
            ).rule,
            userlicenceCertificateFile: ValidationRule.isStringRule().required(
                t("forms:equipmentManufacturer.required")
            ).rule,
        });
    });

let nationalityLookupResult = [];

const nationalityLookup = async (filter) => {
    if (!filter || filter.length < 3) return [];
    const res = await CompanyService.getNationalities(filter);
    if (res.error) {
        toast.error(res.error.message);
        return [];
    }
    nationalityLookupResult = res.data.data;
    return res.data.data.map((r) => r.nationality);
};

let countryLookupResult = [];
const countryLookup = async (getAllCountries) => {
    if (!getAllCountries || getAllCountries.length < 3) return [];
    const res = await CountryService.getCountryNames(getAllCountries);

    if (res.error) {
        toast.error(res.error.message);
        return [];
    }
    countryLookupResult = res.data.data;
    return res.data.data.map((r) => r.countryName);
};

const getNationalId = (nationality) => {
    const match = nationalityLookupResult.filter(
        (itm) => itm.nationality === nationality
    );
    return match.length > 0 ? match[0].id : "";
};

const getCountryId = (country) => {
    const match = countryLookupResult.filter((c) => c.countryName === country);
    return match.length > 0 ? match[0].id : "";
};

const CertificateWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;

    &.non-selectable {
        opacity: 0.45;
    }
`;

const FlexRowBetween = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 2%;

    & > * {
        flex-grow: 1 !important;
        margin: 0 !important;
        max-width: 100% !important;
    }

    & > * + * {
        margin-left: 3% !important;
    }
`;

const StepInfo = styled.div`
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    color: #000;
    padding: 15px 0px;
    & a {
        text-decoration: none;
        color: ${(p) => p.theme.primaryColor};
    }
    & a:hover {
        text-decoration: underline;
    }
    & div {
        padding-bottom: 25px;
        margin-left: -30px;
    }
    & span {
        color: ${(p) => p.theme.primaryColor};
    }
`;

const Separator = styled.div`
    width: 100%;
    height: 1px;
    background-color: #ddd;
`;

const TermsWrapper = styled.div`
    padding: 0px 15px;
    & label {
        font-weight: normal;
    }
`;

const defaultInitialValues = {};

const AccountWizard = (t, i18n, initialValues, countries, isEdit) => {
    let validateCert = initialValues ? initialValues.licenceCheck : false;

    const getLocalizedPdfLink = (docType) => {
        const lng = i18n.language;
        switch (docType) {
            case "terms":
                switch (lng) {
                    case "hu":
                        return "https://retradeables.com/hu/altalanos-felhasznalasi-feltetelek/";
                    case "sk":
                        return "https://retradeables.com/sk/zmluvne-podmienky/";
                    case "cz":
                        return "https://retradeables.com/cs/podminkami-pouzivani-a-prohlasenim/";
                    default:
                        return "https://retradeables.com/terms-and-conditions/";
                }
            case "data":
                switch (lng) {
                    case "hu":
                        return "https://retradeables.com/hu/adatvedelmi-szabalyzat/";
                    case "sk":
                        return "https://retradeables.com/sk/zasady-ochrany-udajov/ ";
                    case "cz":
                        return " https://retradeables.com/cs/smernice-o-ochrane-udaju/";
                    default:
                        return "https://retradeables.com/data-protection-policy/";
                }
            case "mango":
                switch (lng) {
                    default:
                        return "https://www.mangopay.com/terms/PSP/PSP_MANGOPAY_EN.pdf";
                }
            default:
                return "";
        }
    };

    const handleLicenceCheck = (ev, formik) => {
        validateCert = ev.target.checked;
        formik.setFieldValue("licenceCheck", ev.target.checked);
    };

    const handleTermsCheck = (ev, formik) => {
        formik.setFieldValue("acceptTerms", ev.target.checked);
    };

    const handleDataCheck = (ev, formik) => {
        formik.setFieldValue("acceptData", ev.target.checked);
    };

    const handleMangoCheck = (ev, formik) => {
        formik.setFieldValue("acceptMangoPay", ev.target.checked);
    };

    return {
        title: t("forms:account.title"),
        initialValues: initialValues || defaultInitialValues,
        steps: [
            {
                name: t("forms:account.step0Name"),
                fieldNames: ["acceptTerms", "acceptData", "acceptMangoPay"],
                validation: validTerms(t),
                fields: (formik) => (
                    <>
                        <TermsWrapper>
                            <StepInfo>
                                <div>{`${t(
                                    "forms:account.giveConsent"
                                )}:`}</div>
                                <a
                                    href={getLocalizedPdfLink("terms")}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <span>{`${t(
                                        "forms:account.terms"
                                    )} `}</span>
                                </a>
                                {`${t("forms:account.ofPlatform")} `}
                            </StepInfo>

                            <Checkbox
                                labelText={t("forms:account.confirmTerms")}
                                id="acceptTerms"
                                name="acceptTerms"
                                className="flex form"
                                wrapperVariant="flex-1"
                                onChange={(ev) => handleTermsCheck(ev, formik)}
                                {...formik}
                            />
                            <Separator />
                            <StepInfo>
                                <a
                                    href={getLocalizedPdfLink("data")}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <span>{`${t(
                                        "forms:account.dataAgreement"
                                    )} `}</span>
                                </a>
                                {`${t("forms:account.ofDaikinEurope")} `}
                            </StepInfo>

                            <Checkbox
                                labelText={t("forms:account.confirmDaikin")}
                                id="acceptData"
                                name="acceptData"
                                className="flex form"
                                wrapperVariant="flex-1"
                                onChange={(ev) => handleDataCheck(ev, formik)}
                                {...formik}
                            />
                            <Separator />
                            <StepInfo>
                                <a
                                    href={getLocalizedPdfLink("mango")}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <span>{`${t(
                                        "forms:account.agreement"
                                    )} `}</span>
                                </a>
                                {`${t("forms:account.ofMangoPay")} `}
                            </StepInfo>

                            <Checkbox
                                labelText={t("forms:account.confirmMangoPay")}
                                id="acceptMangoPay"
                                name="acceptMangoPay"
                                className="flex form"
                                wrapperVariant="flex-1"
                                onChange={(ev) => handleMangoCheck(ev, formik)}
                                {...formik}
                            />
                        </TermsWrapper>
                        <InfoWrapper className="left">
                            <RoundInfo
                                alt=""
                                src="/images/menu/green-info.svg"
                            />
                            <div>
                                <InfoTitle>
                                    {t("forms:account.didYouKnow")}
                                </InfoTitle>
                                <InfoText>
                                    {t("forms:account.step1Info")}
                                </InfoText>
                            </div>
                        </InfoWrapper>
                    </>
                ),
            },
            {
                name: t("forms:account.step1Name"),
                fieldNames: [
                    "address",
                    "city",
                    "postalCode",
                    "region",
                    "countryId",
                    "iban",
                    "companyNumber",
                ],
                validation: validCompany(t),
                fields: (formik) => (
                    <>
                        <InputElement
                            labelText={t("forms:account.companyNumber")}
                            id="companyNumber"
                            name="companyNumber"
                            type="companyNumber"
                            autoComplete="new-companyNumber"
                            wrapperVariant="flex-2"
                            {...formik}
                        />

                        <InputElement
                            labelText={t("forms:account.iban")}
                            id="iban"
                            name="iban"
                            type="text"
                            wrapperVariant="flex-2"
                            {...formik}
                        />

                        <InputElement
                            labelText={t("forms:account.address")}
                            id="address"
                            name="address"
                            type="text"
                            wrapperVariant="flex-2"
                            {...formik}
                        />

                        <InputElement
                            labelText={t("forms:account.city")}
                            id="city"
                            name="city"
                            type="text"
                            wrapperVariant="flex-2"
                            {...formik}
                        />

                        <InputElement
                            labelText={t("forms:account.postal")}
                            id="postalCode"
                            name="postalCode"
                            type="text"
                            wrapperVariant="flex-3"
                            {...formik}
                        />

                        <InputElement
                            labelText={t("forms:account.region")}
                            id="region"
                            name="region"
                            type="text"
                            wrapperVariant="flex-3"
                            {...formik}
                        />

                        <Select
                            id="countryId"
                            name="countryId"
                            labelText={t("forms:country.text")}
                            wrapperVariant="flex-3"
                            options={
                                countries && countries.length > 0
                                    ? countries.map((country) => {
                                          return {
                                              key: country.id,
                                              value: country.id,
                                              label: country.countryName,
                                          };
                                      })
                                    : []
                            }
                            {...formik}
                        />

                        <InfoWrapper>
                            <RoundInfo
                                alt=""
                                src="/images/menu/green-info.svg"
                            />
                            <div>
                                <InfoTitle>
                                    {t("forms:account.didYouKnow")}
                                </InfoTitle>
                                <InfoText>
                                    {t("forms:account.step1Info")}
                                </InfoText>
                            </div>
                        </InfoWrapper>
                    </>
                ),
            },
            {
                name: t("forms:account.step2Name"),
                fieldNames: [
                    "legalRepresentativeFirstName",
                    "legalRepresentativeLastName",
                    "legalRepresentativeBirthDate",
                    "legalRepresentativeNationalityId",
                    "legalRepresentativeNationality",
                    "legalRepresentativeNationalitySelected",
                    "legalRepresentativeCountryOfResidenceId",
                    "legalRepresentativeCountryOfResidence",
                    "legalRepresentativeCountryOfResidenceSelected",
                    "legalRepresentativeEmail",
                    "legalRepresentativeAddress",
                    "legalRepresentativeCity",
                    "legalRepresentativePostalCode",
                    "legalRepresentativeRegion"
                ],
                validation: validRepresentative(t),
                fields: (formik) => (
                    <>
                        <FlexRowBetween>
                            <InputElement
                                labelText={t("forms:account.firstName")}
                                id="legalRepresentativeFirstName"
                                name="legalRepresentativeFirstName"
                                type="text"
                                wrapperVariant="flex-4 "
                                {...formik}
                            />

                            <InputElement
                                labelText={t("forms:account.lastName")}
                                id="legalRepresentativeLastName"
                                name="legalRepresentativeLastName"
                                type="text"
                                wrapperVariant="flex-4 "
                                {...formik}
                            />

                            <DatePicker
                                labelText={t("forms:account.birthday")}
                                id="legalRepresentativeBirthDate"
                                name="legalRepresentativeBirthDate"
                                filter="past"
                                wrapperVariant="flex-4 "
                                formik={formik}
                                {...formik}
                            />
                            <InputElement
                                labelText={t("forms:account.email")}
                                id="legalRepresentativeEmail"
                                name="legalRepresentativeEmail"
                                type="text"
                                wrapperVariant="flex-4 "
                                {...formik}
                            />
                        </FlexRowBetween>

                        <FlexRowBetween>
                            <InputElement
                                labelText={t("forms:address.text")}
                                id="legalRepresentativeAddress"
                                name="legalRepresentativeAddress"
                                type="text"
                                wrapperVariant="flex-3 "
                                {...formik}
                            />
                            <InputElement
                                labelText={t("forms:city.text")}
                                id="legalRepresentativeCity "
                                name="legalRepresentativeCity"
                                type="text"
                                wrapperVariant="flex-3 "
                                {...formik}
                            />
                            <InputElement
                                labelText={t("forms:postalCode.text")}
                                id="legalRepresentativePostalCode"
                                name="legalRepresentativePostalCode"
                                type="text"
                                wrapperVariant="flex-3 "
                                {...formik}
                            />
                        </FlexRowBetween>

                        {/* <Select
                            id="legalRepresentativeCountryOfResidenceId"
                            name="legalRepresentativeCountryOfResidenceId"
                            labelText={t("forms:account.countryOfResidence")}
                            wrapperVariant="flex-2"
                            defaultItem={{
                                key: "all",
                                value: "",
                                label: t("forms:account.countryOfResidence"),
                            }}
                            options={
                                countries && countries.length > 0
                                    ? countries.map((country) => {
                                          return {
                                              key: country.id,
                                              value: country.id,
                                              label: country.countryName,
                                          };
                                      })
                                    : []
                            }
                            {...formik}
                        /> */}
                        <FlexRowBetween>
                            <InputElement
                                labelText={t("forms:region.text")}
                                id="legalRepresentativeRegion"
                                name="legalRepresentativeRegion"
                                type="text"
                                wrapperVariant="flex-3 "
                                {...formik}
                            />
                            <InputElement
                                labelText={t("forms:account.nationality")}
                                id="legalRepresentativeNationality"
                                name="legalRepresentativeNationality"
                                type="search"
                                searchFn={nationalityLookup}
                                itemSelected={(sel) => {
                                    setTimeout(() => {
                                        formik.setFieldValue(
                                            "legalRepresentativeNationalitySelected",
                                            sel
                                        );
                                        formik.setFieldValue(
                                            "legalRepresentativeNationalityId",
                                            getNationalId(sel)
                                        );
                                    }, 250);
                                }}
                                wrapperVariant="flex-3"
                                {...formik}
                            />
                            <InputElement
                                labelText={t(
                                    "forms:account.countryOfResidence"
                                )}
                                id="legalRepresentativeCountryOfResidenceId"
                                name="legalRepresentativeCountryOfResidence"
                                type="search"
                                searchFn={countryLookup}
                                itemSelected={(sel) => {
                                    setTimeout(() => {
                                        formik.setFieldValue(
                                            "legalRepresentativeCountryOfResidenceSelected",
                                            sel
                                        );
                                        formik.setFieldValue(
                                            "legalRepresentativeCountryOfResidenceId",
                                            getCountryId(sel)
                                        );
                                    }, 250);
                                }}
                                wrapperVariant="flex-3"
                                {...formik}
                            />
                        </FlexRowBetween>
                        {/* <Select
                            id="legalRepresentativeNationalityId"
                            name="legalRepresentativeNationalityId"
                            labelText={t("forms:account.nationality")}
                            wrapperVariant="flex-2"
                            defaultItem={{
                                key: "all",
                                value: "",
                                label: t("forms:account.nationality"),
                            }}
                            options={
                                countries && countries.length > 0
                                    ? countries.map((country) => {
                                          return {
                                              key: country.id,
                                              value: country.id,
                                              label: country.countryName,
                                          };
                                      })
                                    : []
                            }
                            {...formik}
                        /> */}

                        <InfoWrapper>
                            <RoundInfo
                                alt=""
                                src="/images/menu/green-info.svg"
                            />
                            <div>
                                <InfoTitle>
                                    {t("forms:account.didYouKnow")}
                                </InfoTitle>
                                <InfoText>
                                    {t("forms:account.step2Info")}
                                </InfoText>
                            </div>
                        </InfoWrapper>
                    </>
                ),
            },
            {
                name: t("forms:account.step4Name"),
                fieldNames: [
                    "userEmail",
                    "userPhoneNumber",
                    "userFirstName",
                    "userLastName",
                ],
                validation: validPerson(t),
                fields: (formik) => (
                    <>
                        <InputElement
                            labelText={t("forms:account.firstName")}
                            id="userFirstName"
                            name="userFirstName"
                            autocomplete="new-userFirstName"
                            type="text"
                            wrapperVariant="flex-2"
                            {...formik}
                        />

                        <InputElement
                            labelText={t("forms:account.lastName")}
                            id="userLastName"
                            name="userLastName"
                            autocomplete="new-userLastName"
                            type="text"
                            wrapperVariant="flex-2"
                            {...formik}
                        />

                        <InputElement
                            labelText={t("forms:account.email")}
                            id="userEmail"
                            name="userEmail"
                            autocomplete="new-userEmail"
                            type="text"
                            disabled
                            wrapperVariant="flex-2"
                            {...formik}
                        />

                        <InputElement
                            labelText={t("forms:account.phone")}
                            id="userPhoneNumber"
                            name="userPhoneNumber"
                            autocomplete="new-userPhoneNumber"
                            type="text"
                            wrapperVariant="flex-2"
                            {...formik}
                        />

                        <InfoWrapper>
                            <RoundInfo
                                alt=""
                                src="/images/menu/green-info.svg"
                            />
                            <div>
                                <InfoTitle>
                                    {t("forms:account.didYouKnow")}
                                </InfoTitle>
                                <InfoText>
                                    {t("forms:account.step4Info")}
                                </InfoText>
                            </div>
                        </InfoWrapper>
                    </>
                ),
            },
            {
                name: t("forms:account.step5Name"),
                fieldNames: [
                    "licenceCheck",
                    "userlicenceNumber",
                    "userlicenceExpiryDate",
                    "userlicenceCertificateFile",
                ],
                validation: validCert(t),
                fields: (formik) => (
                    <>
                        <Checkbox
                            labelText={t("forms:fGasCertificate.checkbox")}
                            id="licenceCheck"
                            name="licenceCheck"
                            className="flex form"
                            wrapperVariant="flex-1"
                            onChange={(ev) => handleLicenceCheck(ev, formik)}
                            {...formik}
                        />
                        <CertificateWrapper
                            className={validateCert ? "" : "non-selectable"}
                        >
                            <InputElement
                                labelText={t("forms:account.certNumber")}
                                id="userlicenceNumber"
                                name="userlicenceNumber"
                                type="text"
                                disabled={!validateCert}
                                wrapperVariant="flex-2"
                                {...formik}
                            />

                            <DatePicker
                                labelText={t("forms:account.expirationDate")}
                                id="userlicenceExpiryDate"
                                name="userlicenceExpiryDate"
                                disabled={!validateCert}
                                wrapperVariant={"flex-2"}
                                formik={formik}
                                {...formik}
                            />

                            <InputElement
                                labelText=""
                                name="userlicenceCertificateFile"
                                placeholder={t(
                                    "forms:fGasCertificateFileUpload.text"
                                )}
                                title={t(
                                    "forms:fGasCertificateFileUpload.header"
                                )}
                                type="file"
                                id="userlicenceCertificateFile"
                                accept="application/pdf"
                                wrapperVariant="flex-1"
                                disabled={!validateCert}
                                {...formik}
                            />
                        </CertificateWrapper>
                        <InfoWrapper>
                            <RoundInfo
                                alt=""
                                src="/images/menu/green-info.svg"
                            />
                            <div>
                                <InfoTitle>
                                    {t("forms:account.didYouKnow")}
                                </InfoTitle>
                                <InfoText>
                                    {t("forms:account.step5Info")}
                                </InfoText>
                            </div>
                        </InfoWrapper>
                    </>
                ),
            },
        ],
    };
};

export default AccountWizard;
