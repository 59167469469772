import PropTypes from "prop-types";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled, { css } from "styled-components";
import { Label, ValidationError } from "../elements/form-elements";
import { InputWrapper } from "../elements/Stylings";
import { ReactComponent as CalendarSvg } from "../../assets/calendar.svg";

const SvgCalendar = styled(CalendarSvg)`
    position: absolute;
    top: 50%;
    right: 4px;
    width: auto;
    height: 22px;
    transform: translateY(calc(-50% + 3px));
    z-index: 2;
    pointer-events: none;
`;

const StyledDatePicker = styled(DatePicker)`
    box-sizing: border-box;
    width: 100%;
    background: ${(props) => props.theme.inputBgColor};
    padding: 15px;
    border: 1px solid ${(props) => props.theme.primaryInputBorderColor || "#d4d4d4"};
    border-radius: ${(props) => props.theme.inputBorderRadius || "5px"};
    margin: 0 0 15px;
    height: 46px;
    ${(props) =>
        props.disabled &&
        css`
            background: transparent;
        `}

    &.flex-half {
        flex-basis: 45%;
        margin: 0 5% 0 0;
    }
`;

const SingleDatePicker = (props) => {
    let {
        currentDate,
        formik,
        labelText,
        name,
        errors,
        touched,
        wrapperVariant,
        style,
        filter = "future",
        disabled,
        minDate,
        maxDate,
        mode = "date",
    } = props;
    let error = errors[name];
    let isTouched = touched[name];

    const date = new Date();
    let today = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    const [licenceDate, setLicenceDate] = useState(formik.licenceExpirationDate);
    const isFutureDate = (date) => date >= today;
    const isPasteDate = (date) => date <= today;
    let filterDate = () => true;
    if (filter === "future") filterDate = isFutureDate;
    else if (filter === "past") filterDate = isPasteDate;
    return (
        <InputWrapper style={style} className={`${wrapperVariant} ${error ? "error" : ""}`}>
            <Label htmlFor={name} className={error && "error"}>
                {labelText}
            </Label>
            <div>
                <StyledDatePicker
                    disabled={disabled}
                    id={name}
                    selected={licenceDate || currentDate}
                    name={name}
                    onChange={(val) => {
                        setLicenceDate(val || "");
                        formik.setFieldValue(name, val || "");
                    }}
                    mode={mode}
                    dateFormat={mode === "date" ? "dd.MM.yyyy" : "dd.MM.yyyy HH:mm"}
                    timeIntervals={5}
                    timeFormat="HH:mm"
                    showTimeSelect={mode === "date" ? false : true}
                    placeholderText="DD/MM/YYYY"
                    filterDate={filterDate}
                    minDate={minDate ?? new Date("1/1/1930")}
                    maxDate={maxDate ?? new Date("1/1/2100")}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                />
                <ValidationError error={error} touched={isTouched} isFieldError={true} />
                <SvgCalendar />
            </div>
        </InputWrapper>
    );
};

export default SingleDatePicker;

SingleDatePicker.propTypes = {
    name: PropTypes.any.isRequired,
    labelText: PropTypes.any,
    currentDate: PropTypes.any,
    formik: PropTypes.any.isRequired,
};
