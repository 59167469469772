import React from "react";
import Label from "../../../../../components/elements/form-elements/Label";
import {SearchInput} from "../../../../../components/elements/form-elements";
import ValidationError from "../../../../../components/elements/form-elements/ValidationError";
import {InputWrapper} from "../../../../../components/elements/Stylings";
import {useTranslation} from "react-i18next";

const TagInput = (props) => {
    const {t} = useTranslation();
    const {id, visible, filterTagFromList, filterAvailableTags, error} = props;

    return (
        <InputWrapper className={`${visible ? 'flex-1' : 'hide'}`}>
            <Label htmlFor={id} className={error && "error"}>
                {t("forms:cylinderTagId.text")}
            </Label>

            <SearchInput id={id}
                         name={id}
                         type="search"
                         searchFn={filterAvailableTags}
                         onChange={filterTagFromList}
            />

            {error && (
                <ValidationError
                    touched={!!error}
                    error={error}
                    isFieldError={true}
                />
            )}
        </InputWrapper>
    )
};

export default TagInput;
