import React from "react";
import Select from "../../../../components/elements/form-elements/Select";
import ValidationRule, {buildValidation} from "../../../../core/validations/ValidationRule";
import * as yup from "yup";
import {InfoText, InfoTitle, InfoWrapper, RoundInfo} from "../../../../components/modal/components/WizardStyling";
import MoneyInputElement from "../../../../components/elements/form-elements/MoneyInputElement";

const validatePayout = (t, inputAmount) => (
    yup.lazy(() => {
        return buildValidation({
            bankAccountId: ValidationRule.isStringRule().required(t('forms:wallet.wizard.payout.iban.required')).rule,
            amount: ValidationRule
                .numberRange(0, inputAmount, t("forms:wallet.wizard.payout.amount.invalid"), t("forms:wallet.wizard.payout.amount.exceeded") )
                .rule
        })
    })
)

const PayoutWizard = (t, initialValues, myBankAccounts) => {
    let amount = initialValues ? initialValues.amount : 0;
    return {
        title: t("forms:wallet.wizard.payout.title"),
        initialValues: initialValues,
        steps: [
            {
                name: t("forms:wallet.wizard.payout.step1"),
                fieldNames: ["bankAccountId", "amount"],
                validation: validatePayout(t, amount),
                fields: (formik) => (
                    <>
                        <InfoWrapper>
                            <RoundInfo
                                alt=""
                                src="/images/menu/green-info.svg"
                            />
                            <div>
                                <InfoTitle>
                                    {t("forms:wallet.wizard.payout.infoTitle")}
                                </InfoTitle>
                                <InfoText>
                                    {t("forms:wallet.wizard.payout.infoText")}
                                    <br />
                                    {t("forms:wallet.wizard.payout.infoText2")}
                                </InfoText>
                            </div>
                        </InfoWrapper>

                        <MoneyInputElement
                            labelText={t(
                                "forms:wallet.wizard.payout.amount.text"
                            )}
                            name="amount"
                            id="amount"
                            type="number"
                            step="0.01"
                            value={formik.values["amount"]}
                            wrapperVariant="flex-2 wizard"
                            autoComplete="new-amount"
                            formik={formik}
                            currencyLabel="EUR"
                        />

                        <Select
                            key="bankAccountId"
                            id="bankAccountId"
                            name="bankAccountId"
                            labelText={t(
                                "forms:wallet.wizard.payout.iban.text"
                            )}
                            defaultItem={{
                                key: "-1",
                                value: "-1",
                                label: t(
                                    "forms:wallet.wizard.payout.iban.selectIBAN"
                                ),
                            }}
                            options={
                                myBankAccounts && myBankAccounts.length > 0
                                    ? myBankAccounts.map((account) => {
                                          return {
                                              key: account.id,
                                              value: account.id,
                                              label: account.iban,
                                          };
                                      })
                                    : []
                            }
                            wrapperVariant="flex-2"
                            {...formik}
                        />
                    </>
                ),
            },
        ],
    };
};

export default PayoutWizard;
