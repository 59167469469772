import React, { useState, useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Flex from "../../../../components/layout/flex/Flex";
import useBreadcrumbs from "../../../../core/state/navigation/useBreadcrumbs";
import useImmerReducer from "../../../../hooks/_shared/useImmerReducer";
import useDataReducerImmediate from "../../../../hooks/_shared/useDataReducerImmediate";
import useDataReducer from "../../../../hooks/_shared/useDataReducer";
import StatisticsService from "../../../../services/Statistics/StatisticsService";
import helpers from "../../../../core/helpers";
import ChartHeader from "../../../../components/dashboard/ChartHeader";
import CustomTooltip from "../../../../components/dashboard/CustomTooltip";
import ProgressBar from "../../../../components/dashboard/ProgressBar";
import ChartDates from "../../../../components/dashboard/ChartDates";
import GasMenu from "../../../pwa/GasMenu";
import DashboardStyles from "../../../../components/dashboard/DashboardStyles";

import {
    Surface,
    Symbols,
    ComposedChart,
    Area,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    PieChart,
    Label,
    Pie,
    Cell,
    BarChart,
    Bar,
    Legend,
    CartesianGrid,
} from "recharts";

export const COLORS = ["#2B736B", "#94C21C", "#F0C40D", "#FF8042"];

export const FONT_COLOR = "#AAAEB3";

const WEEK = "week";
const MONTH = "month";
const DAY = "day";

const DashboardWrapper = styled.div`
    padding: 25px 60px 25px;
`;

const TechnicianChartWrapper = styled.div`
    padding: 20px 30px 0px 0px;
    min-height: 10px;
    flex-grow: 1;
`;

const filterReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.SET_FILTERS:
            state.from = action.payload.from;
            state.to = action.payload.to;
            break;
        case actionTypes.RESET_FILTERS:
            delete state.from;
            delete state.to;
            break;
        default:
            return state;
    }
};

const MainContainer = styled(Flex.Container)`
    height: 100%;

    @media all and (max-width: ${(props) => props.theme.extraLargeDevices}) {
        flex-direction: column;
        height: auto;
    }
`;

const LeftContainer = styled(Flex.Container)`
    width: 80%;

    @media all and (max-width: ${(props) => props.theme.extraLargeDevices}) {
        order: 2;
        width: 100%;
        margin: 40px 0px 0px 0px;
    }
`;

const RightContainer = styled(Flex.Column)`
    min-width: 260px;
    width: 20%;
    min-height: 800px;

    @media all and (max-width: ${(props) => props.theme.extraLargeDevices}) {
        width: 100%;
        min-height: 100%;
    }
`;

const actionTypes = {
    SET_FILTERS: "SET_FILTERS",
    RESET_FILTERS: "RESET_FILTERS",
};

const ChartHeaderContainer = styled.div`
    padding: 25px 30px 0px 30px;
    display: flex;
    flex-direction: column;

    &.grow {
        flex-grow: 1;
    }

    &.responsive {
        padding-right: 0px;

        @media all and (max-width: ${(props) => props.theme.extraLargeDevices}) {
            padding: 0px;
        }
    }
`;

const TechnicianStockChartHeaderContainer = styled(ChartHeaderContainer)`
    padding: 0px 30px 0px 30px;
    flex-grow: 1;
    min-height: 350px;

    @media all and (max-width: ${(props) => props.theme.extraLargeDevices}) {
        padding: 0px;
    }
`;

const ListItem = styled.li`
    height: 40px;
    color: #aaaeb3;
    display: flex;
`;

const ListText = styled.div`
    float: left;
    margin: auto;

    & span {
        padding-left: 6px;
        font-size: 14px;
    }
`;

const ProgressWrapper = styled.div`
    float: right;
    width: 137px;
    font-size: 12px;
`;

const ProgressLabel = styled.span`
    float: right;
`;

const ProgressList = styled.ul`
    list-style: none;
    padding: 0;
    margin-top: 30px;
`;

const LegendList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`;

const LegendItem = styled.li`
    color: #aaaeb3;
    font-size: 14px;
    display: inline-block;
    padding-right: 10px;

    & span {
        padding-left: 6px;
    }
`;

const QualityContainer = styled(Flex.Column)`
    width: 320px;
    display: flex;
    flex-direction: column;
`;

const ExtractionsContainer = styled(Flex.Column)`
    padding-top: 25px;
    min-width: 200px;
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const RelativeDiv = styled.div`
    position: relative;
    height: 100%;
`;

const ExtractionsWrapper = styled.div`
    height: 441px;
    padding-right: 30px;
    padding-left: 25px;

    @media all and (max-width: ${(props) => props.theme.extraLargeDevices}) {
        padding-right: 0px;
    }
`;

const ExtractionChartContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

const StockDashboard = () => {
    const PRESUMED_GRADES = [
        { name: "A", type: "circle", id: 0, color: COLORS[0] },
        { name: "B", type: "circle", id: 1, color: COLORS[1] },
        { name: "C", type: "circle", id: 2, color: COLORS[2] },
    ];

    const [selectedGasType, setSelectedGasType] = useState({
        id: "",
        code: "",
        firstExtractionDate: "",
        lastExtractionDate: "",
    });

    const [selectedPeriodGrouping, setSelectedPeriodGrouping] = useState();

    const [menuData, setMenuData] = useState();

    const [extractedFGasState] = useDataReducerImmediate(StatisticsService.extractedFGas);

    const [extractionsState, loadExtractions] = useDataReducer(async (filter) => {
        return await StatisticsService.extractions(filter);
    });

    const [companyStockState, loadCompanyStock] = useDataReducer(async (filter) => {
        return await StatisticsService.companyStock(filter);
    });

    const [technicianStockState, loadTechnicianStock] = useDataReducer(async (filter) => {
        return await StatisticsService.technicianStock(filter);
    });

    const [filterState, dispatch] = useImmerReducer(filterReducer, {
        from: null,
        to: null,
    });

    useEffect(() => {
        if (filterState && selectedGasType && selectedGasType.id) {
            const refrigerantId = { refrigerantId: selectedGasType.id };
            const filter = { ...filterState, ...refrigerantId };
            loadExtractions({ filter: filter });
        }
    }, [filterState]);

    const selectedGasFilter = {
        filter: {
            refrigerantId: selectedGasType.id,
        },
    };

    useEffect(() => {
        tempFilterData.current.from = selectedGasType.firstExtractionDate;
        tempFilterData.current.to = selectedGasType.lastExtractionDate;
        applyFilters();
    }, [selectedGasType]);

    useEffect(() => {
        loadCompanyStock(selectedGasFilter);
    }, [selectedGasType]);

    useEffect(() => {
        const menuData = getExtractedFGasData();
        setMenuData(menuData);

        if (menuData && menuData.length > 0) {
            const firstMenu = menuData[0];

            setSelectedGasType({
                id: firstMenu.refrigerantId,
                code: firstMenu.refrigerantGasTypeCode,
                firstExtractionDate: firstMenu.firstExtractionDate,
                lastExtractionDate: firstMenu.lastExtractionDate,
            });
        }
    }, [extractedFGasState]);

    useEffect(() => {
        loadTechnicianStock(selectedGasFilter);
    }, [selectedGasType]);

    const { t } = useTranslation("screens");

    useBreadcrumbs([
        {
            text: t("navigation:stock"),
        },
        {
            text: t("navigation:dashboard"),
        },
    ]);

    const filterData = useRef({
        from: "",
        to: "",
    });

    const applyFilters = () => {
        Object.assign(filterData.current, tempFilterData.current);
        dispatch({
            type: actionTypes.SET_FILTERS,
            payload: filterData.current,
        });
    };

    const tempFilterData = useRef({});

    const handleClick = (ev) => {
        const tgt = ev.currentTarget;
        if (tgt.dataset.value !== undefined && tgt.dataset.info !== undefined) {
            const refrigerantId = tgt.dataset.value;
            const refrigerantCode = tgt.dataset.info;
            const gasInfo = menuData.filter((x) => x.refrigerantId == refrigerantId)[0];

            setSelectedGasType({
                id: refrigerantId,
                code: refrigerantCode,
                firstExtractionDate: gasInfo.firstExtractionDate,
                lastExtractionDate: gasInfo.lastExtractionDate,
            });
        }
    };

    const getExtractionsPerWeek = (rawData) => {
        return _(rawData)
            .groupBy((x) => helpers.getWeekName(x.period))
            .map((dates, key) => ({
                period: `Week ${key}`,
                value: _.sumBy(dates, "value"),
                dates: dates,
            }))
            .value();
    };

    const getExtractionsPerMonth = (rawData) => {
        return _(rawData)
            .groupBy((x) => x.period.substring(0, 7))
            .map((dates, key) => ({
                period: t(`screens:months.${parseInt(key.slice(-2))}`).substring(0, 3),
                value: _.sumBy(dates, "value"),
                dates: dates,
            }))
            .value();
    };

    const getExtractionsPerDay = (rawData) => {
        return rawData.map((x) => ({
            period: helpers.toLocalDateIgnoreTime(x.period),
            value: x.value,
        }));
    };

    const RenderExtractionsLineChart = () => {
        var rawData = extractionsState && extractionsState.data ? extractionsState.data : [];

        let data = [];

        const dataPerDay = getExtractionsPerDay(rawData);
        const dataPerMonth = getExtractionsPerMonth(rawData);
        const dataPerWeek = getExtractionsPerWeek(rawData);

        const threshold = 10;

        if (!selectedPeriodGrouping) {
            if (dataPerDay.length < threshold) setSelectedPeriodGrouping(DAY);
            else if (dataPerDay.length < threshold) setSelectedPeriodGrouping(WEEK);
            else setSelectedPeriodGrouping(MONTH);
        }

        if (selectedPeriodGrouping === DAY) data = dataPerDay;
        else if (selectedPeriodGrouping === WEEK) data = dataPerWeek;
        else if (selectedPeriodGrouping === MONTH) data = dataPerMonth;

        return (
            <ExtractionChartContainer>
                <DashboardStyles.NoDataWrapper
                    className={`${
                        (extractionsState.isCompleted && data.length === 0) || !(menuData && menuData.length > 0)
                            ? "show"
                            : ""
                    }`}
                >
                    <DashboardStyles.SvgNoData />
                    {t("table:headers.noItems")}
                </DashboardStyles.NoDataWrapper>
                <ResponsiveContainer width="100%" minHeight="320px">
                    <ComposedChart
                        data={data}
                        margin={{
                            top: 0,
                            right: 20,
                            left: -40,
                            bottom: 5,
                        }}
                    >
                        <defs>
                            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#6980BF" stopOpacity={0.4} />
                                <stop offset="95%" stopColor="#6980BF" stopOpacity={0} />
                            </linearGradient>
                        </defs>
                        <CartesianGrid strokeWidth={1} strokeOpacity="50%" vertical={false} />
                        <XAxis
                            dataKey="period"
                            axisLine={false}
                            tickLine={false}
                            stroke={FONT_COLOR}
                            style={{
                                fontSize: 10,
                            }}
                        />
                        <YAxis
                            axisLine={false}
                            tickLine={false}
                            stroke={FONT_COLOR}
                            style={{
                                fontSize: 12,
                            }}
                        />

                        <Tooltip content={<CustomTooltip unit="kg" />} />
                        <Area
                            type="linear"
                            dataKey="value"
                            fillOpacity={0.4}
                            fill="url(#colorUv)"
                            activeDot={{ r: 8 }}
                        />
                    </ComposedChart>
                </ResponsiveContainer>
            </ExtractionChartContainer>
        );
    };

    const RenderQualityofSelectedGasPieChart = () => {
        const filteredData = selectedGasType.id
            ? extractedFGasState.data.filter((x) => x.refrigerantId === selectedGasType.id)
            : extractedFGasState.data;
        const totalQuantity = parseFloat(filteredData.reduce((sum, current) => sum + current.quantity, 0));

        let data = PRESUMED_GRADES.map((grade) => {
            const sumQuantity = filteredData
                .filter((x) => x.presumedGrade === grade.id)
                .reduce((sumQuantity, current) => sumQuantity + current.quantity, 0);
            return {
                name: grade.name,
                color: grade.color,
                value: sumQuantity,
                percent: (sumQuantity / totalQuantity) * 100,
            };
        });

        return (
            <div>
                <div style={{ paddingTop: 30, position: "relative" }}>
                    <DashboardStyles.NoDataWrapper className={`${totalQuantity === 0 ? "show" : ""}`}>
                        <DashboardStyles.SvgNoData />
                        {t("table:headers.noItems")}
                    </DashboardStyles.NoDataWrapper>

                    <PieChart
                        width={200}
                        height={200}
                        style={{
                            margin: "auto",
                            padding: "0px",
                            fontWeight: "bold",
                        }}
                    >
                        <Pie
                            data={data}
                            cx="50%"
                            cy="50%"
                            innerRadius="90%"
                            outerRadius="100%"
                            fill="#8884d8"
                            paddingAngle={1}
                            dataKey="value"
                        >
                            <Label
                                fill="#AAAEB3"
                                value={totalQuantity.toFixed(2) + " kg"}
                                position="center"
                                style={{ fontSize: 20, fontWeight: 600 }}
                            />
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                    </PieChart>
                </div>
                <ProgressList>
                    {data.map((item, idx) => (
                        <ListItem key={idx}>
                            <ListText>
                                <svg height="12" width="12">
                                    <circle cx="6" cy="6" r="5" fill={item.color}></circle>
                                </svg>
                                <span>
                                    {item.name} {t("screens:stockDashboard.labels.grade")}
                                </span>
                            </ListText>
                            <ProgressWrapper>
                                <ProgressLabel>{parseFloat(item.value).toFixed(2)} kg</ProgressLabel>
                                <ProgressBar key={idx} bgcolor={item.color} completed={item.percent} />
                            </ProgressWrapper>
                        </ListItem>
                    ))}
                </ProgressList>
            </div>
        );
    };

    const RenderCustomGasGradeLegend = () => {
        return (
            <LegendList>
                {PRESUMED_GRADES.map((grade, index) => (
                    <LegendItem key={index}>
                        <Surface width={12} height={12} viewBox="0 0 10 10">
                            <Symbols cx={6} cy={6} type="circle" size={50} fill={grade.color} />
                        </Surface>
                        <span>{`${grade.name} ${t("screens:stockDashboard.labels.grade")}`}</span>
                    </LegendItem>
                ))}
            </LegendList>
        );
    };

    const barChartMargin = {
        top: 40,
        bottom: 5,
        left: -30,
    };

    const legendWrapperStyle = {
        top: 0,
        right: -30,
    };

    const RenderCompanyStockForSelectedGasBarChart = () => {
        const rawData = companyStockState && companyStockState.data ? companyStockState.data : [];

        let data = PRESUMED_GRADES.map((grade) => {
            const quantity = parseFloat(
                rawData
                    .filter((x) => x.presumedGrade === grade.id)
                    .reduce((quantity, current) => quantity + current.quantity, 0)
            );
            return {
                name: grade.name,
                color: grade.color,
                quantity: quantity,
            };
        }).filter((x) => x.quantity > 0);

        return (
            <RelativeDiv>
                <DashboardStyles.NoDataWrapper
                    className={`${companyStockState.isCompleted && data.length === 0 ? "show" : ""}`}
                >
                    <DashboardStyles.SvgNoData />
                    {t("table:headers.noItems")}
                </DashboardStyles.NoDataWrapper>

                <ResponsiveContainer width="100%" minHeight="20px">
                    <BarChart data={data} margin={barChartMargin}>
                        <Bar dataKey="quantity" legendType="circle" barSize={10} barGap={2} radius={[10, 10, 10, 10]}>
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index]} />
                            ))}
                        </Bar>
                        <YAxis
                            axisLine={false}
                            tickLine={false}
                            stroke={FONT_COLOR}
                            style={{
                                fontSize: 12,
                            }}
                        />

                        <XAxis
                            axisLine={false}
                            tickLine={false}
                            tick={false}
                            dataKey="refrigerantGradeName"
                            stroke={FONT_COLOR}
                        />
                        <Tooltip labelFormatter={() => ""} cursor={false} content={<CustomTooltip unit="kg" />} />
                        <CartesianGrid vertical={false} strokeWidth={1} strokeOpacity="50%" />
                        <Legend
                            iconType="circle"
                            verticalAlign="top"
                            align="left"
                            content={RenderCustomGasGradeLegend()}
                            wrapperStyle={legendWrapperStyle}
                        />
                    </BarChart>
                </ResponsiveContainer>
            </RelativeDiv>
        );
    };

    const RenderTechnicianStockForSelectedGasBarChart = () => {
        const rawData = technicianStockState && technicianStockState.data ? technicianStockState.data : [];
        const data = _(rawData)
            .groupBy((x) => x.technicianId)
            .map((values, key) => ({
                technicianId: key,
                technicianName: values[0].technicianName,
                quantityA: _.sumBy(
                    values.filter((x) => x.refrigerantGradeName === "A"),
                    "quantity"
                ),
                quantityB: _.sumBy(
                    values.filter((x) => x.refrigerantGradeName === "B"),
                    "quantity"
                ),
                quantityC: _.sumBy(
                    values.filter((x) => x.refrigerantGradeName === "C"),
                    "quantity"
                ),
                values: values,
            }))
            .value();

        return (
            <RelativeDiv>
                <DashboardStyles.NoDataWrapper
                    className={`${technicianStockState.isCompleted && data.length === 0 ? "show" : ""}`}
                >
                    <DashboardStyles.SvgNoData />
                    {t("table:headers.noItems")}
                </DashboardStyles.NoDataWrapper>

                <ResponsiveContainer width="100%" minHeight="20px" style={{ marginLeft: "-40px" }}>
                    <BarChart data={data} margin={barChartMargin}>
                        <YAxis
                            axisLine={false}
                            tickLine={false}
                            stroke={FONT_COLOR}
                            style={{
                                fontSize: 12,
                            }}
                        />

                        <Tooltip cursor={false} content={<CustomTooltip unit="kg" colors={COLORS} />} />
                        <XAxis
                            dataKey="technicianName"
                            axisLine={false}
                            tickLine={false}
                            stroke={FONT_COLOR}
                            style={{
                                fontSize: 12,
                            }}
                        />
                        <Bar
                            dataKey="quantityA"
                            key="quantityA"
                            name={`A ${t("screens:stockDashboard.labels.grade")}`}
                            fill={COLORS[0]}
                            barSize={10}
                            barGap={2}
                            radius={[10, 10, 10, 10]}
                        />
                        <Bar
                            dataKey="quantityB"
                            key="quantityB"
                            name={`B ${t("screens:stockDashboard.labels.grade")}`}
                            fill={COLORS[1]}
                            barSize={10}
                            barGap={2}
                            radius={[10, 10, 10, 10]}
                        />
                        <Bar
                            dataKey="quantityC"
                            key="quantityC"
                            name={`C ${t("screens:stockDashboard.labels.grade")}`}
                            fill={COLORS[2]}
                            barSize={10}
                            barGap={2}
                            radius={[10, 10, 10, 10]}
                        />

                        <CartesianGrid strokeWidth={1} strokeOpacity="50%" vertical={false} />
                        <Legend
                            iconType="circle"
                            verticalAlign="top"
                            align="left"
                            content={RenderCustomGasGradeLegend()}
                            wrapperStyle={legendWrapperStyle}
                        />
                    </BarChart>
                </ResponsiveContainer>
            </RelativeDiv>
        );
    };

    const selectedGasLabel = () => {
        return selectedGasType.code ? "- " + selectedGasType.code : "";
    };

    const getExtractedFGasData = () => {
        const rawData = extractedFGasState && extractedFGasState.data ? extractedFGasState.data : [];

        const data = _(rawData)
            .groupBy((x) => x.refrigerantId)
            .map((values, key) => ({
                refrigerantId: key,
                refrigerantGasTypeCode: values[0].refrigerantGasTypeCode,
                quantity: _.sumBy(values, "quantity"),
                values: values,
                firstExtractionDate: helpers.minDate(values.map((a) => a.firstExtractionDate)),
                lastExtractionDate: helpers.maxDate(values.map((a) => a.lastExtractionDate)),
            }))
            .value();

        const sortedData = _.orderBy(data, ["quantity"], ["desc"]);

        return sortedData;
    };

    const handleDateFromFilterChange = (date) => {
        tempFilterData.current.from = date;
        applyFilters();
    };

    const handleDateToFilterChange = (date) => {
        tempFilterData.current.to = date;
        applyFilters();
    };

    return (
        <DashboardWrapper>
            <Helmet>
                <title>{t("seo:stockDashboard.title")}</title>
            </Helmet>
            {extractedFGasState.isCompleted && extractedFGasState.data && (
                <MainContainer>
                    <LeftContainer>
                        <QualityContainer>
                            <ChartHeaderContainer style={{ paddingLeft: "0px" }}>
                                <ChartHeader
                                    title={t("screens:stockDashboard.headers.qualityChart")}
                                    subtitle={selectedGasLabel()}
                                />
                                {RenderQualityofSelectedGasPieChart()}
                            </ChartHeaderContainer>
                            <ChartHeaderContainer
                                style={{ marginTop: 12, minHeight: 350, paddingLeft: "0px" }}
                                className="grow"
                            >
                                <ChartHeader
                                    title={t("screens:stockDashboard.headers.companyStockChart")}
                                    subtitle={selectedGasLabel()}
                                />
                                <div
                                    style={{
                                        paddingTop: 20,
                                        minHeight: "10px",
                                        flexGrow: 1,
                                    }}
                                >
                                    {RenderCompanyStockForSelectedGasBarChart()}
                                </div>
                            </ChartHeaderContainer>
                        </QualityContainer>
                        <ExtractionsContainer>
                            <ExtractionsWrapper>
                                <ChartDates
                                    setSelectedPeriodGrouping={setSelectedPeriodGrouping}
                                    selectedPeriodGrouping={selectedPeriodGrouping}
                                    filterState={filterState}
                                    title={t("screens:stockDashboard.headers.extractionsChart") + selectedGasLabel()}
                                    handleDateFromFilterChange={handleDateFromFilterChange}
                                    handleDateToFilterChange={handleDateToFilterChange}
                                />

                                <ChartHeaderContainer className="responsive">
                                    {RenderExtractionsLineChart()}
                                </ChartHeaderContainer>
                            </ExtractionsWrapper>
                            <TechnicianStockChartHeaderContainer className="grow">
                                <ChartHeader
                                    marginTop="10px"
                                    title={t("screens:stockDashboard.headers.technicianStockChart")}
                                    subtitle={selectedGasLabel()}
                                />
                                <TechnicianChartWrapper>
                                    {RenderTechnicianStockForSelectedGasBarChart()}
                                </TechnicianChartWrapper>
                            </TechnicianStockChartHeaderContainer>
                        </ExtractionsContainer>
                    </LeftContainer>
                    <RightContainer>
                        <GasMenu
                            items={menuData}
                            colors={COLORS}
                            handleClick={handleClick}
                            title={t("screens:stockDashboard.headers.extractions")}
                            selectedGasType={selectedGasType}
                        ></GasMenu>
                    </RightContainer>
                </MainContainer>
            )}
        </DashboardWrapper>
    );
};

export default StockDashboard;
