import React from "react";
import styled from "styled-components";

const Heading2 = styled.h2`
    /* font-family: 'Open Sans', sans-serif; */
    color: ${props => props.theme.primaryColor};
    font-size: ${props => props.theme.h2FontSize};
    font-weight: 500;
    &.center {
        text-align: center;
    }

    &.title {
        font-size: ${(props) => props.theme.h2TitleFontSize};
        margin: 0 0 25px;
    }

    &.small-title {
        font-size: 24px;
    }

    &.bold {
        font-weight: 700;
    }

`;

const H2 = ({children, ...rest}) => {
    return (
        <Heading2 {...rest}>{children}</Heading2>
    )
};

export default H2;
