import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as BackSvg } from "../../../assets/goback.svg";

const StyledBackButton = styled.div`
    width: 160px;
    height: 100%;
    position: relative;

    &.company {
        height: 40px;
        position: fixed;
        top: 60px;
        z-index: 101;
    }

    &.icon {
        width: 32px;
        height: 32px;
    }

    & [data-ctrl="back"] {
        pointer-events: none;
    }
`;

export const StyledBackIcon = styled(BackSvg)`
    cursor: pointer;
    position: absolute;
    right: 80px;
    top: 8px;
    width: 18px;
    height: auto;
    transition: all 300ms ease;

    &.company {
        right: 32px;
        top: 12px;
    }

    &.clients {
        position: absolute;
        left: 0;
        top: 4px;
    }

    /* &:hover {
        cursor: pointer;
    }

    &:hover .arrow {
        stroke: ${(p) => p.theme.primaryColorHover};
    } */
`;

const Background = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 78px;
    border-radius: 15px 15px 0px 0px;
    background: linear-gradient(
        180deg,
        rgba(245, 245, 245, 1) 0%,
        rgba(240, 240, 240, 1) 100%
    );
    box-shadow: -2px 9px 11px -16px rgba(0, 0, 0, 0.75);
    border-bottom: 1px solid #ffffff;

    &.company {
        height: 40px;
        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 1) 0%,
            rgba(220, 220, 220, 1) 100%
        );
        box-shadow: -2px 9px 11px -16px rgb(0 0 0 / 75%);
        border-bottom: 1px solid #ffffff;
        margin-top: 0px;
    }

    &:after {
        content: "";
        position: absolute;
        right: 22px;
        bottom: 1px;
        width: 36px;
        height: 4px;
        background-color: transparent;
        border-radius: 8px 8px 0px 0px;
    }

    &:hover {
        cursor: pointer;
        ${StyledBackButton} ${StyledBackIcon} {
            background-color: ${(p) => p.theme.primaryColorHover};
        }
    }

    &:hover:after {
        background-color: ${(p) => p.theme.primaryColorHover};
    }

    &:hover .arrow {
        background-color: ${(p) => p.theme.primaryColorHover};
    }
`;

const BackButton = (props) => {
    const history = useHistory();
    return (
        <StyledBackButton
            className={props.variant}
            onClick={() => {
                if (!props.dummy) {
                    if (
                        props.onGoBack &&
                        props.onGoBack(window.location.pathname) === true
                    ) {
                        history.goBack();
                    } else {
                        if (props.returnTo) history.push(props.returnTo);
                        else history.goBack();
                    }
                }
            }}
        >
            <Background className={props.variant} />
            <StyledBackIcon data-ctrl="back" className={props.variant} />
        </StyledBackButton>
    );
};

export default BackButton;
