import React, { useRef } from "react";
import { Select } from "../../../../components/elements/form-elements";
import InputElement from "../../../../components/elements/form-elements/InputElement";
import Flex from "../../../../components/layout/flex/Flex";
import {
    InfoText,
    InfoTitle,
    InfoWrapper,
    RoundInfo,
} from "../../../../components/modal/components/WizardStyling";
import ValidationRule, {
    buildValidation,
} from "../../../../core/validations/ValidationRule";
import CylinderType from "../../../../core/enums/CylinderType";
import MoneyInputElement from "../../../../components/elements/form-elements/MoneyInputElement";

const validTagId = (t) => {
    return buildValidation({
        tagId:ValidationRule.isStringRule()
            .isUUID(t("forms:unit.uuid.invalid"))
            .required(t("forms:unit.unitTagId.required")).rule,
    });
};

const validNetWeightNumber = (t) => {
    return buildValidation({
        size: ValidationRule.min(
            0.0,
            t("forms:bid.wizard.minimumNetWeight"),
            t("forms:bid.wizard.minimumNetWeight")
        ).rule,
    });
};

const defaultInitialValues = {
    isNewTag: true,
    tagId: "",
    imageFile: "",
    size: "0.00",
    refrigerantId: "",
    quantity: "",
    cylinderType: 0,
    ownerCompanyId: "",
};

const CylinderWizardAdd = (
    t,
    searchFn,
    cylOwners = [],
    initialValues = undefined
) => {
    const ownerOptions = [
        { key: "me", value: "", label: t("forms:myCompany.text") },
        ...cylOwners.map((own) => ({
            key: own.companyId,
            value: own.companyId,
            label: own.companyName,
        })),
    ];
    return {
        title: t("forms:cylinder.create"),
        initialValues: initialValues || defaultInitialValues,
        steps: [
            {
                name: t("forms:cylinder.newCylinderTag"),
                fieldNames: ["tagId"],
                validation: validTagId(t),
                fields: (formik) => (
                    <>
                        <InfoWrapper>
                            <RoundInfo
                                alt=""
                                src="/images/menu/green-info.svg"
                            />
                            <div>
                                <InfoTitle>
                                    {t("forms:cylinder.newCylinderInfoTitle")}
                                </InfoTitle>
                                <InfoText>
                                    {t("forms:cylinder.newCylinderInfoText")}
                                    <br />
                                    {t("forms:cylinder.newCylinderInfoText2")}
                                </InfoText>
                            </div>
                        </InfoWrapper>
                        <InputElement
                            labelText={t("forms:cylinderTagId.text")}
                            id="tagId"
                            name="tagId"
                            type="search"
                            searchFn={searchFn}
                            wrapperVariant="flex-1"
                            {...formik}
                        />
                    </>
                ),
            },
            {
                name: t("forms:cylinder.newCylinderInfo"),
                fieldNames: [
                    "size",
                    "imageFile",
                    "refrigerantId",
                    "quantity",
                    "cylinderType",
                    "ownerCompanyId",
                ],
                validation: validNetWeightNumber(t),
                fields: (formik) => {
                    return (
                        <>
                            <InfoWrapper>
                                <RoundInfo
                                    alt=""
                                    src="/images/menu/green-info.svg"
                                />
                                <div>
                                    <InfoTitle>
                                        {t(
                                            "forms:cylinder.newCylinderInfoTitle"
                                        )}
                                    </InfoTitle>
                                    <InfoText>
                                        {t(
                                            "forms:cylinder.newCylinderInfoText3"
                                        )}
                                    </InfoText>
                                </div>
                            </InfoWrapper>

                            <Flex.Container>
                                <Flex.Column className="wizard-column">
                                    <Select
                                        id="ownerCompanyId"
                                        name="ownerCompanyId"
                                        options={ownerOptions}
                                        labelText={t("sidebar:label.owner")}
                                        wrapperVariant="flex-1"
                                        {...formik}
                                    />
                                    <MoneyInputElement
                                        type="number"
                                        name="size"
                                        id="size"
                                        step="0.01"
                                        labelText={t("forms:cylinderSize.text")}
                                        wrapperVariant={"flex-1 standard"}
                                        values={2}
                                        min={0}
                                        positive={true}
                                        formik={formik}
                                        currencyLabel="kg"
                                        //grade={"A"}
                                    />
                                    {/* <Select
                                        id="size"
                                        name="size"
                                        options={[
                                            {
                                                key: "11.20",
                                                value: "11.20",
                                                label: "11.20 kg",
                                            },
                                            {
                                                key: "60",
                                                value: "60.00",
                                                label: "60.00 kg",
                                            },
                                        ]}
                                        labelText={t("forms:cylinderSize.text")}
                                        wrapperVariant="flex-1"
                                        {...formik}
                                    /> */}

                                    <Select
                                        id="cylinderType"
                                        name="cylinderType"
                                        options={[
                                            {
                                                key: CylinderType.recovery,
                                                value: CylinderType.recovery,
                                                label: t(
                                                    "filters:cylinders.recovery"
                                                ),
                                            },
                                            // {
                                            //     key: CylinderType.reclaimed,
                                            //     value: CylinderType.reclaimed,
                                            //     label: t(
                                            //         "filters:cylinders.reclaimed"
                                            //     ),
                                            // },
                                        ]}
                                        labelText={t("filters:cylinders.type")}
                                        wrapperVariant="flex-1"
                                        disabled
                                        {...formik}
                                    />
                                </Flex.Column>

                                <Flex.Column className="wizard-column">
                                    <InputElement
                                        labelText=""
                                        id="imageFile"
                                        name="imageFile"
                                        type="image"
                                        placeholder={t(
                                            "forms:cylinder.addPicture"
                                        )}
                                        title={t(
                                            "forms:cylinder.cylinderPicture"
                                        )}
                                        wrapperVariant="double-height"
                                        {...formik}
                                    />
                                </Flex.Column>
                            </Flex.Container>
                        </>
                    );
                },
            },
        ],
    };
};

export default CylinderWizardAdd;
