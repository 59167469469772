import React from "react";
import {
    Surface,
    Symbols,
} from "recharts";

import styled from "styled-components";
const LegendList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`;

const LegendItem = styled.li`
    color: #aaaeb3;
    font-size: 14px;
    display: inline-block;
    padding-right: 10px;

    & span {
        padding-left: 6px;
    }
`;

const CustomLegend = ({
    data,
    suffix,
}) => {
    return (
        <LegendList>
            {data?.map((item, index) => (
                <LegendItem key={index}>
                    <Surface width={12} height={12} viewBox="0 0 10 10">
                        <Symbols
                            cx={6}
                            cy={6}
                            type="circle"
                            size={50}
                            fill={item.color}
                        />
                    </Surface>
                    <span>{`${item.name}${suffix? " " + suffix: ""}`}</span>
                </LegendItem>
            ))}
        </LegendList>
    );
};
export default CustomLegend;