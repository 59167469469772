import styled from "styled-components";
import FlexRow from "../../layout/flex/FlexRow";

export const FieldWrapper = styled(FlexRow)`
    margin: 4px 0;
    font-size: 0.875rem;
    display: flex;
    // justify-content: space-between;

    &:last-child {
        margin-bottom: 0;
    }
`;

export const FieldLabel = styled.label`
    display: inline-block;
    margin: 0 20px 0 0;
    padding: 0 0 0 20px;
    font-weight: bold;
    min-width: 150px;

    &:after {
        content: ":";      
    }
`;

export const FieldValue = styled.span`
    padding: 0;
    color: ${props => props.theme.primaryColor};
    text-align: right;

    a {
        color: ${props => props.theme.primaryColor};
    }
`;

export const Title = styled.p`
    color: ${props => props.theme.primaryColor};
    margin: 0 0 40px;
    font-size: 1.125rem;
`;

export const NumSection = styled.div`
    padding: 4px 0 4px 0px;
    display: flex;
`;

export const Num = styled.div`
    display:flex;
    align-items:center;
    /* width: 30px; */
    min-width: 30px;
    font-weight: 700;
    font-size: 24px;
    text-align: left;
    background: #fff;
    color: ${(p) => p.theme.primaryColor};
    border-right: 3px solid ${(p) => p.theme.primaryColor};
    margin: 0 12px 0 0;
    

    &.tight {
        font-size:18px;
        /* letter-spacing: -4px; */
        padding-right: 15px;
    }
`;

export const NumInfo = styled.div`
    font-size: 0.875rem;
    line-height: 1.1875rem;
`;
