import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ChartHeader from "../../../../components/dashboard/ChartHeader";
import styled from "styled-components";
import DASHBOARD_CONSTANTS from "../../../../components/dashboard/DashboardConstants";
import useImmerReducer from "../../../../hooks/_shared/useImmerReducer";
import DashboardStyles from "../../../../components/dashboard/DashboardStyles";
import DashboardTheme from "../../../../components/dashboard/DashboardTheme";
import ProgressStyles from "../../../../components/dashboard/ProgressStyles";
import CustomLegend from "../../../../components/dashboard/CustomLegend";
import ChartPieGasQuality from "../../../../components/dashboard/ChartPieGasQuality";
import Flex from "../../../../components/layout/flex/Flex";
import ProgressBar from "../../../../components/dashboard/ProgressBar";
import useDataReducerImmediate from "../../../../hooks/_shared/useDataReducerImmediate";
import AdminDashboardMenu from "./components/AdminDashboardMenu";
import RecentUserListing from "./RecentUserListing";
import StatisticsService from "../../../../services/Statistics/StatisticsService";
import useDataReducer from "../../../../hooks/_shared/useDataReducer";
import { Helmet } from "react-helmet";
import FilterableLineChart from "../../../../components/dashboard/FilterableLineChart";
import RouteCreator from "../../../../core/RouteCreator";
import dataFunctions from "../../../../components/dashboard/DataFunctions";
import useBreadcrumbs from "../../../../core/state/navigation/useBreadcrumbs";
import CustomTooltip from "../../../../components/dashboard/CustomTooltip";
import {
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    Cell,
    BarChart,
    Bar,
    Legend,
    CartesianGrid,
} from "recharts";

const MainContainer = styled(Flex.Container)`
    height: 100%;
    @media all and (max-width: ${props => props.theme.extraLargeDevices}) {
        flex-direction: column;
    }
`;

const LeftContainer = styled(Flex.Container)`
    width: 80%;

    @media all and (max-width: ${props => props.theme.extraLargeDevices}) {
        order: 2;
        width: 100%;
        margin: 40px 0px 0px 0px;
      }
`;

const RightContainer = styled(Flex.Column)`
    min-width: 260px;
    width: 20%;

    @media all and (max-width: ${props => props.theme.extraLargeDevices}) {
        width: 100%;
    }

`;

const DashboardWrapper = styled.div`
    padding: 25px 60px 25px;
    height: 100%;
    width: 100%
`;


const UserContainer = styled(Flex.Column)`
    padding-top: 25px;
    min-width: 200px;
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const CertificateContainer = styled(Flex.Column)`
    width: 260px;
    display: flex;
    flex-direction: column;
`;

const UserOnboardingContainer = styled(Flex.Row)`
    min-height: 441px;
    height: 412px;
    @media all and (max-width: ${props => props.theme.extraLargeDevices}) {
        height: 412px;
    }
    padding-right: 30px;
    padding-left: 25px;
`;

const RecentUsersContainer = styled(Flex.Row)`

    flex-grow: 1;

    @media all and (max-width: ${props => props.theme.extraLargeDevices}) {
        padding: 0px;
    }
`;

const UserStatisticsDashboard = () => {
    const { t } = useTranslation();

    useBreadcrumbs([
        {
            text: t("navigation:users"),
        },
    ]);

    const userUnit = t(
        "screens:statistics.usersTitle"
    );


    const [userCountsDataState] = useDataReducerImmediate(
        StatisticsService.getUsersCounts
    );

    const [onboardedUserState, loadOnboardedUsers] = useDataReducer(async (filter) => {
        return await StatisticsService.getUserOnboarding(filter);
    });

    const filterReducer = (state, action) => {
        switch (action.type) {
            case DASHBOARD_CONSTANTS.actionTypes.SET_FILTERS:
                state.from = action.payload.from;
                state.to = action.payload.to;
                break;
            case DASHBOARD_CONSTANTS.actionTypes.RESET_FILTERS:
                delete state.from
                delete state.to
                break;
            default:
                return state;
        }
    };

    const onChangeFilter = ((filters) => {
        dispatch({
            type: DASHBOARD_CONSTANTS.actionTypes.SET_FILTERS,
            payload: filters,
        });
    });


    const [filterState, dispatch] = useImmerReducer(filterReducer, {

        from: "",
        to: "",

    });

    useEffect(() => {
        loadOnboardedUsers({ filter: filterState });

    }, [filterState]);

    const ActiveUsers = 'ActiveUsers';
    const DisabledUsers = 'DisabledUsers';
    const DeletedUsers = 'DeletedUsers';


    const buildUrl = dataFunctions.buildUrl;

    const getUserMenu = () => {
        const rawData =
            userCountsDataState && userCountsDataState.data
                ? userCountsDataState.data
                : null;
        let items = [];
        if (rawData !== null) {
            items.push({
                id: ActiveUsers,
                name: t(
                    "screens:statistics.activeUsers"
                ),
                url: buildUrl(RouteCreator.admin.users.nonAdmins(), ActiveUsers),
                count: rawData.numberOfActiveUsers
            });

            items.push({
                id: DisabledUsers,
                name: t(
                    "screens:statistics.disabledUsers"
                ),
                url: buildUrl(RouteCreator.admin.users.nonAdmins(), DisabledUsers),
                count: rawData.numberOfInactiveUsers
            });

            items.push({
                id: DeletedUsers,
                name: t(
                    "screens:statistics.deletedUsers"
                ),
                url: buildUrl(RouteCreator.admin.users.nonAdmins(), DeletedUsers),
                count: rawData.numberOfDeletedUsers
            });
        }

        return items;

    };


    const renderUserRoles = () => {
        const rawData =
            userCountsDataState && userCountsDataState.data
                ? userCountsDataState.data
                : null;
        let items = [];
        let colors = [];

        if (rawData !== null) {
            items.push({
                name: t(
                    "common:roles.MasterAdmin"
                ),
                value: rawData.numberOfMasterAdministrators,
                color: DashboardTheme.PrimaryBarColor
            });

            items.push({
                name: t(
                    "common:roles.Admin"
                ),
                value: rawData.numberOfAdministrators,
                color: DashboardTheme.SecondaryBarColor
            });

            items.push({
                name: t(
                    "common:roles.InstallerCompanyTechnician"
                ),
                value: rawData.numberOfTechnicians,
                color: DashboardTheme.ThirdBarColor
            });

            items.map(item => {
                colors.push(item.color);
            });

        }

        return (
            <DashboardStyles.RelativeDiv>
                <DashboardStyles.NoDataWrapper
                    className={`${items.length === 0
                        ? "show bar"
                        : ""
                        }`}
                >
                    <DashboardStyles.SvgNoData />
                    {t("table:headers.noItems")}
                </DashboardStyles.NoDataWrapper>

                <ResponsiveContainer width="100%" minHeight="20px">
                    <BarChart data={items} margin={DashboardStyles.BarChart.Margin}>
                        <Bar
                            dataKey="value"
                            legendType="circle"
                            barSize={10}
                            barGap={2}
                            radius={[10, 10, 10, 10]}
                        >
                            {items.map((item, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={item.color}
                                />
                            ))}
                        </Bar>
                        <YAxis
                            axisLine={false}
                            tickLine={false}
                            stroke={DashboardTheme.FontColor}
                            style={{
                                fontSize: 12,
                            }}
                        />

                        <XAxis
                            axisLine={false}
                            tickLine={false}
                            tick={false}
                            dataKey="name"
                            stroke={DashboardTheme.FontColor}
                        />
                        <Tooltip
                            labelFormatter={() => ""}
                            cursor={false}
                            content={<CustomTooltip isInt={true} colors={colors} unit={userUnit} />}
                        />
                        <CartesianGrid
                            vertical={false}
                            strokeWidth={1}
                            strokeOpacity="50%"
                        />
                        <Legend
                            iconType="circle"
                            verticalAlign="top"
                            align="left"
                            content={<CustomLegend data={items} />}
                            wrapperStyle={DashboardStyles.Legend.Wrapper}
                        />
                    </BarChart>
                </ResponsiveContainer>
            </DashboardStyles.RelativeDiv>
        );
    };

    const renderFGasCertificates = () => {

        const rawData =
            userCountsDataState && userCountsDataState.data
                ? userCountsDataState.data
                : null;
        let items = [];
        if (rawData !== null) {
            items.push({
                name: t("common:Yes"),
                value: rawData.numberOfUsersWithFGasCertificate,
                percent: rawData.numberOfUsersWithFGasCertificate / rawData.numberOfUsers * 100,
                color: DashboardTheme.SecondaryBarColor
            });

            items.push({
                name: t("common:No"),
                percent: rawData.numberOfUsersWithoutFGasCertificate / rawData.numberOfUsers * 100,
                value: rawData.numberOfUsersWithoutFGasCertificate,
                color: DashboardTheme.ThirdBarColor
            });

        }

        return (
            <>
                <ChartPieGasQuality width={200} height={200} style={{ paddingBottom: "0px" }}
                    data={items}
                    isInt={true}
                    unit={t(
                        "screens:statistics.usersTitle"
                    )}

                >

                </ChartPieGasQuality>

                <ProgressStyles.List>
                    {items.map((item, idx) => (
                        <ProgressStyles.Item key={idx}>
                            <ProgressStyles.Text>
                                <svg height="12" width="12">
                                    <circle
                                        cx="6"
                                        cy="6"
                                        r="5"
                                        fill={item.color}
                                    ></circle>
                                </svg>
                                <span>
                                    {item.name}
                                </span>
                            </ProgressStyles.Text>
                            <ProgressStyles.Wrapper>
                                <ProgressStyles.Label>
                                    {item.value}
                                </ProgressStyles.Label>
                                <ProgressBar
                                    key={idx}
                                    bgcolor={item.color}
                                    completed={item.percent}
                                />
                            </ProgressStyles.Wrapper>
                        </ProgressStyles.Item>
                    ))}
                </ProgressStyles.List>

            </>
        );
    };

    return (
        <DashboardWrapper>
            <Helmet>
                <title>{t("seo:userStatistics.title")}</title>
            </Helmet>
            {userCountsDataState.isCompleted && userCountsDataState.data && (
                <MainContainer>
                    <LeftContainer>
                        <CertificateContainer>
                            <DashboardStyles.ChartHeaderContainer style={{ paddingLeft: "0px", minHeight: 461}} >
                                <ChartHeader
                                    title={t(
                                        "screens:statistics.fGasCertificate"
                                    )}

                                />
                                {renderFGasCertificates()}
                            </DashboardStyles.ChartHeaderContainer>
                            <DashboardStyles.ChartHeaderContainer
                                style={{ minHeight: "100px", paddingLeft: "0px", paddingTop: "4px" }}
                                className="grow"
                            >

                                <ChartHeader
                                    title={t(
                                        "screens:stockDashboard.headers.companyStockChart"
                                    )}
                                />
                                <div
                                    style={{
                                        paddingTop: 20,
                                        minHeight: "10px",
                                        flexGrow: 1,
                                    }}
                                >
                                    {renderUserRoles()}
                                </div>
                            </DashboardStyles.ChartHeaderContainer>

                        </CertificateContainer>
                        <UserContainer>
                            <UserOnboardingContainer>
                                <FilterableLineChart
                                    filterState={filterState}
                                    title={t(
                                        "screens:statistics.userOnboarding"
                                    )}
                                    color={DASHBOARD_CONSTANTS.lineChartColor}
                                    data={onboardedUserState?.data}
                                    isCompleted={onboardedUserState?.isCompleted}
                                    onChangeFilter={onChangeFilter}
                                    column="value"
                                    minHeight="320px"
                                    unit={userUnit}
                                    isInt={true}
                                    height={'90%'}
                                    style={{ marginTop: "-25px" }}
                                >

                                </FilterableLineChart>
                            </UserOnboardingContainer>
                            <RecentUsersContainer>
                                <ChartHeader
                                    title={t(
                                        "screens:statistics.recentUsers"
                                    )}
                                    tooltip={<DashboardStyles.Link
                                        to={RouteCreator.admin.users.nonAdmins()}
                                    >
                                        {t(
                                            "screens:statistics.viewAll"
                                        )}
                                    </DashboardStyles.Link>}

                                />
                                <RecentUserListing />
                            </RecentUsersContainer>
                        </UserContainer>
                    </LeftContainer>

                    <RightContainer>
                        <AdminDashboardMenu
                            items={getUserMenu()}
                            style={{ height: '100%' }}
                            title={t(
                                "screens:statistics.usersTitle"
                            )}

                        ></AdminDashboardMenu>
                    </RightContainer>
                </MainContainer>
            )}
        </DashboardWrapper>
    );
};

export default UserStatisticsDashboard;
