import Tippy from "@tippyjs/react";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Card = styled.div`
    font-size: 1.125rem;
    position: relative;
    background: #fff;
    display: flex;
    justify-content:space-between;
    width: 500px;
    height: 235px;
    min-height: 235px;
    padding: 25px 15px;
    text-align: center;
    border-radius: 8px;
    margin: 40px 20px;
    box-shadow: 6px 6px 11px 0px rgba(0, 0, 0, 0.16),
        0px 0px 20px 0px rgba(255, 255, 255, 1);
    transition: all 0.3s ease-in;
    box-sizing: border-box;
    border: 2px solid ${(props) => props.theme.bgModuleCards};
    text-decoration: none;
    @media ${(p) => p.theme.device.mobile} {
        height: 190px;
        min-height: 190px;
    }

    &.small {
        margin: 20px 20px;
    }

    &.disabled {
        opacity:0.5;
        pointer-events:none;
    }
`;

const CardPopup = styled(Tippy)`
    background-color: ${(props) => props.theme.primaryColor};

    .tippy-content {
        border-radius: 4px;
        background-color: ${(props) => props.theme.primaryColor};
    }

    &[data-placement^="bottom"] {
        > .tippy-arrow {
            border-bottom-color: ${(props) =>
                props.theme.primaryColor} !important;
        }
    }
    font-family: ${(props) => props.theme.fontFamily};
`;

const CardLink = styled(Link)`
    color: white;
    cursor: pointer;
    background: ${(p) => p.theme.primaryColor};
    width: 158px;
    height: 40px;
    line-height: 40px;
    border-radius: 40px;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;

    &:hover {
        background: ${(p) => p.theme.primaryColorHover};
    }

    &.disabled {
        background: ${(p) => p.theme.textDisabled};
    }
`;

const CardTitle = styled.div`
    width:100%;
    font-size: 22px;
    font-weight: bold;
    color: ${(p) => p.theme.primaryColor};
    text-align:left;
`;

const CardDesc= styled.div`
    font-size: 12px;
    font-weight: bold;
    color: #000;
    text-align:left;
`;

const CardImage = styled.img`
    width: 100px;
    height: 100px;
`;

const CardSplitter = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    &.left {
        width: 50%;
        margin-right:32px;
    }
    &.right {
        max-width:158px;
    }
`;

const Module = (props) => {
    return (
        <CardPopup
            delay={0}
            content={props.name}
            placement="bottom"
            animation="scale"
            trigger="mouseenter"
            duration={[100, 0]}
            disabled={!props.popupEnabled}
        >
            {props.link && (
                <Card className={`${props.variant} ${props.disabled ? "disabled":""}`}>
                    <CardSplitter className="left">
                        <CardTitle>{props.title}</CardTitle>
                        <CardDesc>{props.desc}</CardDesc>
                    </CardSplitter>
                    <CardSplitter className="right">
                        <CardImage src={props.image} />
                        <CardLink className={`primary ${props.disabled ? "disabled":""}`} to={props.link}>
                            {props.name}{" "}
                        </CardLink>
                    </CardSplitter>
                </Card>
            )}
        </CardPopup>
    );
};

export default Module;
