import helpers from "../../core/helpers";
import _ from 'lodash'
const dataFunctions = {

     getExtractedFGasData (extractedFGasState) {
        const rawData = extractedFGasState && extractedFGasState.data ? extractedFGasState.data : [];

        const data = _(rawData)
            .groupBy(x => x.refrigerantId)
            .map((values, key) =>
            ({
                refrigerantId: key,
                refrigerantGasTypeCode: values[0].refrigerantGasTypeCode,
                quantity: _.sumBy(values, 'quantity'),
                values: values,
                firstExtractionDate: helpers.minDate(values.map(a=> a.firstExtractionDate)),
                lastExtractionDate: helpers.maxDate(values.map(a=> a.lastExtractionDate)),
            })).value();

        const sortedData = _.orderBy(data, ['quantity'], ['desc']);

        return sortedData;
    },
    
    buildUrl(link, filter)  {

        return link + "?Filter=" + filter;
    },


}

export default dataFunctions;