import StockService from "../../../services/Stock/StockService";
import createListStateModule from "../createListStateModule";
import countriesStatePlugin from "../plugins/countriesStatePlugin";
import gasTypesStatePlugin from "../plugins/gasTypesStatePlugin";

const stockListingStateModule = createListStateModule({
    stateNamespace: "stock",
    itemsPropertyName: "stockItems",
    loadItemsMethodName: "loadStockItems",
    itemsDataMethod: StockService.getStockItems,
    loadDetailMethodName: "loadStockById",
    detailDataMethod: StockService.getStockItem,
    detailsPropertyName: "stockData",
    updateMethodName: "updateRefrigerant",
    updateDataMethod: () => {},
    createMethodName: 'createStockItem',
    defaultItemsData: {data: []},
    createDataMethod: StockService.addToMyStock,

    plugins: [countriesStatePlugin, gasTypesStatePlugin]
});

export default stockListingStateModule;
