import QRCode from "qrcode.react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ReactComponent as AddSvg } from "../../assets/ios_add.svg";
import { ReactComponent as ShareSvg } from "../../assets/ios_share.svg";
import { Radio } from "../../components/elements/form-elements";
import Headings from "../../components/elements/headings/Headings";

const StyledQR = styled.div`
    width: 200px;
    height: 200px;
`;

const SubTitle = styled.div`
    font-size: 20px;
    font-weight: 500;
    margin-top: -10px;
    white-space: nowrap;
`;

const Section = styled.div`
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    /* border-bottom: 1px solid #dddddd; */
    padding: 10px 0px;
    margin-bottom: 30px;

    &.h-80 {
        min-height: 80px;
    }

    &.bottom-space {
        padding-bottom: 40px;
        min-height: 280px;
    }

    & label + label {
        margin-left: 20px;
    }

    &:before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        height: 1px;
        width: 90%;
        transform: translateX(6%);
        border-bottom: 1px solid #dddddd;
    }

`;

const Instructions = styled.div`
    width: 60%;
`;

const Num = styled.div`
    width: 24px;
    height: 24px;
    line-height: 20px;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    background: #fff;
    color: ${(p) => p.theme.primaryColor};
    border: 3px solid ${(p) => p.theme.primaryColor};
    border-radius: 50%;
    float: left;
`;

const StepWrapper = styled.div`
    text-align: left;
    display: flex;
    align-items: flex-start;
    padding-left: 25px;
    flex-wrap: wrap;
    margin-bottom: 20px;

    > * {
        flex: 0;
        flex-basis: auto;
    }
`;

const Step = styled.div`
    width: 100%;
    height: 24px;
    line-height: 24px;
    font-size: 14px;
    font-weight: normal;
    color: #898989;
    &.title {
        color: #000;
        font-weight: 600;
        font-size: 16px;
    }
`;

const TechnicianTools = () => {
    const { t } = new useTranslation();
    const [isIos, setIsIos] = useState(true);
    const appLocation =
        window.location.protocol +
        "//" +
        window.location.hostname +
        (window.location.port ? ":" + window.location.port : "");

    return (
        <div style={{ marginBottom: "-50px", width: "100%", textAlign:"center" }}>
            <Headings.H3 style={{ fontWeight: "700" }}>
                {t("actions:dialogs.qrLink.title")}
            </Headings.H3>
            <SubTitle>{t("actions:dialogs.qrLink.scan")}</SubTitle>
            <Section className="h-80">
                <Radio
                    labelText={t("actions:dialogs.qrLink.usingIOS")}
                    id="ios"
                    name="radio"
                    checked={isIos}
                    onChange={() => setIsIos(true)}
                />
                <Radio
                    labelText={t("actions:dialogs.qrLink.usingAndroid")}
                    id="android"
                    name="radio"
                    checked={!isIos}
                    onChange={() => setIsIos(false)}
                />
            </Section>
            <Section className="bottom-space">
                {isIos && (
                    <Instructions style={{minHeight:"330px"}}>
                        <Num>1</Num>
                        <StepWrapper>
                            <Step className="title">
                                {t("actions:dialogs.qrLink.scanQr")}
                            </Step>
                            <Step>
                                {t("actions:dialogs.qrLink.accessCamera")}
                            </Step>
                        </StepWrapper>
                        <Num>2</Num>
                        <StepWrapper>
                            <Step className="title">
                                {t("actions:dialogs.qrLink.openWebsite")}
                            </Step>
                            <Step>
                                {t("actions:dialogs.qrLink.bannerBrowser")}
                            </Step>
                        </StepWrapper>
                        <Num>3</Num>
                        <StepWrapper>
                            <Step className="title">
                                {t("actions:dialogs.qrLink.browserOptions")}
                            </Step>
                            <Step>
                                {t("actions:dialogs.qrLink.shareButton")}
                                ( <ShareSvg /> )
                                {t("actions:dialogs.qrLink.screenBottom")}
                            </Step>
                        </StepWrapper>
                        <Num>4</Num>
                        <StepWrapper>
                            <Step className="title">
                                {t("actions:dialogs.qrLink.saveApp")}
                            </Step>
                            <Step>
                                {t("actions:dialogs.qrLink.optionsA2HS")}
                                ( <AddSvg /> ). <br />
                                {t("actions:dialogs.qrLink.added2HS")}
                            </Step>
                        </StepWrapper>
                    </Instructions>
                )}
                {!isIos && (
                    <Instructions style={{minHeight:"330px"}}>
                        <Num>1</Num>
                        <StepWrapper>
                            <Step className="title">
                                {t("actions:dialogs.qrLink.scanQr")}
                            </Step>
                            <Step>
                                {t("actions:dialogs.qrLink.accessCamera")}
                            </Step>
                        </StepWrapper>
                        <Num>2</Num>
                        <StepWrapper>
                            <Step className="title">
                                {t("actions:dialogs.qrLink.openWebsite")}
                            </Step>
                            <Step>
                                {t("actions:dialogs.qrLink.bannerBrowser")}
                            </Step>
                        </StepWrapper>
                        <Num>3</Num>
                        <StepWrapper>
                            <Step className="title">
                                {t("actions:dialogs.qrLink.saveApp")}
                            </Step>
                            <Step>
                                {t("actions:dialogs.qrLink.tapBanner")}
                                <br />
                                {t("actions:dialogs.qrLink.saveAndroid")}
                            </Step>
                        </StepWrapper>
                    </Instructions>
                )}
                <div style={{ marginTop: "30px", marginLeft:"10px" }}>
                    <StyledQR
                    >
                        <QRCode value={appLocation} size={200} />
                    </StyledQR>
                </div>
            </Section>
        </div>
    );
};

export default TechnicianTools;
