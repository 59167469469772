import React from "react";
import { useTranslation } from "react-i18next";
import ClearableInput from "../../../../components/elements/form-elements/ClearableInput";
import Label from "../../../../components/elements/form-elements/Label";
import Select from "../../../../components/elements/form-elements/Select";
import Filters from "../../../../components/filter/Filters";
import AnalysisStatus from "../../../../core/enums/AnalysisStatus";
import useDebouncedCallback from "../../../../hooks/_shared/useDebouncedCallback";
import { DateTimePicker } from "../../../../components/elements/form-elements/DatePicker";
import helpers from "../../../../core/helpers";
import Globals from "../../../../globalVars";

const MarketplaceFilter = ({
    filterState,
    dispatch,
    actionTypes,
    gasTypes,
    screen,
    tabIndex,
}) => {
    const { t } = useTranslation("filters");
    const [quantityToChanged] = useDebouncedCallback((text) => {
        text
            ? dispatch({ type: actionTypes.SET_GAS_QUANTITY_TO, payload: text.trim() })
            : dispatch({
                  type: actionTypes.RESET_GAS_QUANTITY_TO,
                  payload: text,
              });
    });

    const [quantityFromChanged] = useDebouncedCallback((text) => {
        text
            ? dispatch({
                  type: actionTypes.SET_GAS_QUANTITY_FROM,
                  payload: text.trim(),
              })
            : dispatch({
                  type: actionTypes.RESET_GAS_QUANTITY_FROM,
                  payload: text,
              });
    });
    const showHideOffers = [
        { key: t("offers.hideMyOffers"), value: true },
        { key: t("offers.showMyOffers"), value: false },
    ];

    const hideWithBids = [
        { key: t("offers.hideItemsWithBids"), value: true },
        { key: t("offers.showItemsWithBids"), value: false },
    ];

    const showWithBids = [
        { key: t("offers.allOffers"), value: false },
        { key: t("offers.offersWithBids"), value: true },
    ];

    return (
        <Filters.Wrapper className="filters-wrapper">
            {screen === "market" && (
                <>
                    {!Globals.isAdmin && (
                        <>
                            <Filters.Item>
                                <Select
                                    key="hideMyOffers"
                                    name="hideMyOffers"
                                    errors={[]}
                                    touched={[]}
                                    options={showHideOffers.map((item) => ({
                                        key: item.key,
                                        value: item.value,
                                        label: item.key,
                                    }))}
                                    defaultValue={
                                        filterState.hideMyOffers !== undefined
                                            ? filterState.hideMyOffers
                                            : false
                                    }
                                    handleChange={(evt) =>
                                        evt.currentTarget.value === "true"
                                            ? dispatch({
                                                  type: actionTypes.SET_HIDEMYOFFERS,
                                                  payload: true,
                                              })
                                            : dispatch({
                                                  type: actionTypes.RESET_HIDEMYOFFERS,
                                              })
                                    }
                                    className="filters"
                                    labelText={t("offers.hideMyOffers")}
                                    labelClassName="sr-only"
                                />
                            </Filters.Item>
                            <Filters.Item>
                                <Select
                                    key="hideWithBids"
                                    name="hideWithBids"
                                    errors={[]}
                                    touched={[]}
                                    options={hideWithBids.map((item) => ({
                                        key: item.key,
                                        value: item.value,
                                        label: item.key,
                                    }))}
                                    defaultValue={
                                        filterState.hideOffersWithMyBid !==
                                        undefined
                                            ? filterState.hideOffersWithMyBid
                                            : false
                                    }
                                    handleChange={(evt) =>
                                        evt.currentTarget.value === "true"
                                            ? dispatch({
                                                  type: actionTypes.SET_HIDEWITHBIDS,
                                                  payload: true,
                                              })
                                            : dispatch({
                                                  type: actionTypes.RESET_HIDEWITHBIDS,
                                              })
                                    }
                                    className="filters"
                                    labelText={t("offers.hideItemsWithBids")}
                                    labelClassName="sr-only"
                                />
                            </Filters.Item>
                        </>
                    )}
                    <Filters.Group>
                        <Filters.Item>
                            <DateTimePicker
                                labelText={t("forms:extractionDate.from")}
                                noLabel
                                placeholder={t("forms:extractionDate.from")}
                                id="offerCreationDateTimeFrom"
                                name="offerCreationDateTimeFrom"
                                className="filters big left group"
                                value={
                                    filterState ? filterState.extractFrom : ""
                                }
                                maxDate={filterState?.bidDealExpirationTo}
                                handleChange={(date) =>
                                    dispatch({
                                        type:
                                            date === ""
                                                ? actionTypes.RESET_OFFER_CREATION__DATE_FROM
                                                : actionTypes.SET_OFFER_CREATION__DATE_FROM,
                                        payload:
                                            date === ""
                                                ? date
                                                : helpers.dateToIsoDateString(
                                                      new Date(date)
                                                  ),
                                    })
                                }
                            />
                        </Filters.Item>
                        <Filters.Item>
                            <DateTimePicker
                                labelText={t("forms:extractionDate.to")}
                                noLabel
                                placeholder={t("forms:extractionDate.to")}
                                id="offerCreationDateTimeTo"
                                name="offerCreationDateTimeTo"
                                className="filters big right group"
                                value={filterState ? filterState.extractTo : ""}
                                minDate={filterState?.bidDealExpirationFrom}
                                handleChange={(date) =>
                                    dispatch({
                                        type:
                                            date === ""
                                                ? actionTypes.RESET_OFFER_CREATION__DATE_TO
                                                : actionTypes.SET_OFFER_CREATION__DATE_TO,
                                        payload:
                                            date === ""
                                                ? date
                                                : helpers.dateToIsoDateString(
                                                      date
                                                  ),
                                    })
                                }
                            />
                        </Filters.Item>
                    </Filters.Group>
                </>
            )}
            {screen === "offers" && tabIndex === 0 && (
                <>
                    <Filters.Item>
                        <Select
                            key="showWithBids"
                            name="showWithBids"
                            errors={[]}
                            touched={[]}
                            options={showWithBids.map((item) => ({
                                key: item.key,
                                value: item.value,
                                label: item.key,
                            }))}
                            defaultValue={
                                filterState.hasOffers !== undefined
                                    ? filterState.hasOffers
                                    : false
                            }
                            handleChange={(evt) =>
                                evt.currentTarget.value === "true"
                                    ? dispatch({
                                          type: actionTypes.SET_HASOFFERS,
                                          payload: true,
                                      })
                                    : dispatch({
                                          type: actionTypes.RESET_HASOFFERS,
                                      })
                            }
                            className="filters"
                            labelText={t("offers.showItemsWithBids")}
                            labelClassName="sr-only"
                        />
                    </Filters.Item>
                </>
            )}

            {(screen === "offers" || screen === "bids") &&
                (tabIndex === 1 || tabIndex === 2) && (
                    <Filters.Group>
                        <Filters.Item>
                            <DateTimePicker
                                labelText={t("forms:extractionDate.from")}
                                noLabel
                                placeholder={t("forms:extractionDate.from")}
                                id="bidDealExpirationFrom"
                                name="bidDealExpirationFrom"
                                className="filters big left group"
                                value={
                                    filterState ? filterState.bidDealExpirationFrom : ""
                                }
                                maxDate={filterState?.bidDealExpirationTo}
                                handleChange={(date) =>
                                    dispatch({
                                        type:
                                            date === ""
                                                ? actionTypes.RESET_EXPIRATION_DATE_FROM
                                                : actionTypes.SET_EXPIRATION_DATE_FROM,
                                        payload:
                                            date === ""
                                                ? date
                                                : helpers.dateToIsoDateString(
                                                      date
                                                  ),
                                    })
                                }
                            />
                        </Filters.Item>
                        <Filters.Item>
                            <DateTimePicker
                                labelText={t("forms:extractionDate.to")}
                                noLabel
                                placeholder={t("forms:extractionDate.to")}
                                id="bidDealExpirationTo"
                                name="bidDealExpirationTo"
                                className="filters big right group"
                                value={filterState ? filterState.bidDealExpirationTo : ""}
                                minDate={filterState?.bidDealExpirationFrom}
                                handleChange={(date) =>
                                    dispatch({
                                        type:
                                            date === ""
                                                ? actionTypes.RESET_EXPIRATION_DATE_TO
                                                : actionTypes.SET_EXPIRATION_DATE_TO,
                                        payload:
                                            date === ""
                                                ? date
                                                : helpers.dateToIsoDateString(
                                                      date
                                                  ),
                                    })
                                }
                            />
                        </Filters.Item>
                    </Filters.Group>
                )}

            <Filters.Item>
                <Select
                    key="gasType"
                    name="gasType"
                    errors={[]}
                    touched={[]}
                    defaultItem={{
                        key: "all",
                        value: "",
                        label: t("gas.gasType"),
                    }}
                    defaultValue={
                        filterState.gasType !== undefined
                            ? filterState.gasType
                            : ""
                    }
                    options={
                        gasTypes
                            ? gasTypes.map((gas) => ({
                                  key: gas.code,
                                  value: gas.code,
                                  label: gas.code,
                              }))
                            : []
                    }
                    handleChange={(evt) =>
                        evt.currentTarget.value
                            ? dispatch({
                                  type: actionTypes.SET_GAS_TYPE,
                                  payload: evt.currentTarget.value,
                              })
                            : dispatch({
                                  type: actionTypes.RESET_GAS_TYPE,
                                  payload: evt.currentTarget.value,
                              })
                    }
                    className="filters"
                    labelText={t("gas.gasType")}
                    labelClassName="sr-only"
                />
            </Filters.Item>
            <Filters.Group>
                <Filters.Item>
                    <Label htmlFor="quantityFrom" className="sr-only">
                        {t("gas.quantityFrom")}
                    </Label>

                    <ClearableInput
                        key="quantityFrom"
                        name="quantityFrom"
                        type="number"
                        defaultValue={
                            filterState.gasQuantityFrom !== undefined
                                ? filterState.gasQuantityFrom
                                : ""
                        }
                        onChange={(evt) => {
                            quantityFromChanged(evt.currentTarget.value);
                        }}
                        placeholder={t("gas.quantityFrom")}
                        title={t("gas.quantityFrom")}
                        className="filters left group"
                    />
                </Filters.Item>

                <Filters.Item>
                    <Label htmlFor="quantityTo" className="sr-only">
                        {t("gas.quantityTo")}
                    </Label>
                    <ClearableInput
                        key="quantityTo"
                        name="quantityTo"
                        type="number"
                        defaultValue={
                            filterState.gasQuantityTo !== undefined
                                ? filterState.gasQuantityTo
                                : ""
                        }
                        onChange={(evt) => {
                            quantityToChanged(evt.currentTarget.value);
                        }}
                        placeholder={t("gas.quantityTo")}
                        title={t("gas.quantityTo")}
                        className="filters right group"
                    />
                </Filters.Item>
            </Filters.Group>

            <Filters.Item>
                <Select
                    key="qualityAssurance"
                    name="qualityAssurance"
                    errors={[]}
                    touched={[]}
                    defaultItem={{
                        key: "all",
                        value: "",
                        label: t("gas.qualityAssurance"),
                    }}
                    defaultValue={
                        filterState.hasAnalysis !== undefined
                            ? filterState.hasAnalysis
                            : ""
                    }
                    options={Object.keys(AnalysisStatus).map((key) => ({
                        key: key,
                        value: key,
                        label: t("analysis." + AnalysisStatus[key]),
                    }))}
                    handleChange={(evt) => {
                        dispatch({
                            type:
                                evt.currentTarget.value === ""
                                    ? actionTypes.RESET_QUALITY_ASSURANCE
                                    : actionTypes.SET_QUALITY_ASSURANCE,
                            payload: evt.currentTarget.value,
                        });
                    }}
                    className="filters"
                    labelText={t("gas.qualityAssurance")}
                    labelClassName="sr-only"
                />
            </Filters.Item>
            {Globals.isAdmin && (
                <Filters.Item>
                    <Select
                        key="country"
                        name="countryId"
                        errors={[]}
                        touched={[]}
                        defaultItem={{
                            key: "all",
                            value: "",
                            label: t("filters:clients.country"),
                        }}
                        defaultValue={filterState.userStatus}
                        options={
                            Globals.countries
                                ? Globals.countries.map((country, index) => ({
                                      key: country.id,
                                      value: country.id,
                                      label: country.localName,
                                  }))
                                : []
                        }
                        handleChange={(evt) => {
                            if (evt.currentTarget.value === "") {
                                dispatch({
                                    type: actionTypes.RESET_COUNTRY,
                                    payload: evt.currentTarget.value,
                                });
                            } else {
                                dispatch({
                                    type: actionTypes.SET_COUNTRY,
                                    payload: evt.currentTarget.value,
                                });
                            }
                        }}
                        className="filters"
                        labelText={t("filters:clients.country")}
                        labelClassName="sr-only"
                    />
                </Filters.Item>
            )}
        </Filters.Wrapper>
    );
};

export default React.memo(MarketplaceFilter);
