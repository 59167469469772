import React from "react";
import InputElement from "../../../../components/elements/form-elements/InputElement";
import commonValidation from "../../../../core/validations/common";
import ValidationRule, {
    buildValidation,
} from "../../../../core/validations/ValidationRule";

const getAdminValidation = (t) => {
    const validation = buildValidation({
        email: commonValidation.email(t).rule,
        firstName: ValidationRule.isStringRule().required(
            t("forms:firstName.required")
        ).rule,
        lastName: ValidationRule.isStringRule().required(
            t("forms:lastName.required")
        ).rule,
    });

    return validation;
};

const defaultInitialValues = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
};

const CreateAdministratorWizard = (t) => {
    return {
        title: t("forms:administrator.create"),
        initialValues: defaultInitialValues,
        steps: [
            {
                name: t("forms:administrator.information"),
                fieldNames: ["firstName", "lastName", "phoneNumber", "email"],
                validation: getAdminValidation(t),
                fields: (formik) => (
                    <>
                        <InputElement
                            labelText={t("forms:firstName.text")}
                            id="firstName"
                            name="firstName"
                            autoComplete={false}
                            wrapperVariant="flex-2"
                            type="text"
                            {...formik}
                        />
                        <InputElement
                            labelText={t("forms:lastName.text")}
                            id="lastName"
                            name="lastName"
                            autoComplete={false}
                            wrapperVariant="flex-2"
                            type="text"
                            {...formik}
                        />
                        <InputElement
                            labelText={t("forms:phoneNumber.text")}
                            id="phoneNumber"
                            name="phoneNumber"
                            autoComplete={false}
                            type="text"
                            wrapperVariant="flex-2"
                            {...formik}
                        />
                        <InputElement
                            labelText={t("forms:email.text")}
                            id="email"
                            name="email"
                            autoComplete="email"
                            type="text"
                            wrapperVariant="flex-2"
                            {...formik}
                        />
                    </>
                ),
            },
        ],
    };
};

export default CreateAdministratorWizard;
