import api, {apiResult} from "../getAxios";
import {config} from "../Config/config.prod";

const ClientsService = {
    getClients: async (filters = {}) => {
        let res = await api.post(config.apiUrl.getClients, filters);
        return res.data;
    },
    getClientById: async (clientId) => {
        let res = await api.get(config.apiUrl.getClientById + "/" + clientId);
        return res.data.data[0];
    },
    getClientByIdAsync: async (clientId) => {
        return apiResult(api.get(config.apiUrl.getClientById + "/" + clientId));
    },
    deleteClient: async (clientId) => {
        let res = (await api.post(config.apiUrl.deleteClient, {clientId: clientId}));
        return res;
    },
    updateClient: (values) => {
        return api.post(config.apiUrl.updateClient, values);
    },
    updateClientAsync: async (values) => {
        return apiResult(api.post(config.apiUrl.updateClient, values));
    },
    createClient: (values) => {
        let res = api.post(config.apiUrl.setClient, values);
        return res;
    },
    createClientAsync: async (values) => {
        return apiResult(api.post(config.apiUrl.setClient, values));
    },
    getClientsLocations: async (filterState = {}) => {
        const data = (
            await api.post(config.apiUrl.getClientLocations, filterState)
        ).data;

        return data;
    },
    createClientLocationAsync: async (values) => {
        return apiResult(api.post(config.apiUrl.addClientLocation, values));
    },
    updateClientLocationAsync: async (values) => {
        return apiResult(api.post(config.apiUrl.updateClientLocation, values));
    },
    getClientLocationByIdAsync: async (locationId) => {
        return apiResult(
            api.get(config.apiUrl.getClientLocationById + "/" + locationId)
        );
    },
    getLocationById: async (locationId) => {
        let res = await api.get(config.apiUrl.getClientLocationById + "/" + locationId)
        return res.data.data[0];
    },
    getUnitManufacturersAsync: async (locationId) => {
        return apiResult(
            api.get(config.apiUrl.getUnitManufacturers)
        );
    },
    deleteClientLocation: async (locationId) => {
        let res = (await api.post(config.apiUrl.deleteClientLocation, {locationId: locationId}))
        return res;
    },
    getUnitById: async (unitId) => {
        let res = await api.get(config.apiUrl.getUnitById + "/" + unitId);
        return res.data.data[0];
    },
    deleteClientLocationUnit: async (unitId) => {
        let res = (await api.post(config.apiUrl.deleteUnit, {unitId: unitId}));
        return res;
    },
    deleteClientLocationUnitAsync: async (unitId) => {
        return apiResult ( api.post(config.apiUrl.deleteUnit, {unitId: unitId}));
    },
    createClientLocationUnit: async (values) => {
        return apiResult(api.post(config.apiUrl.addUnit, values));
    },
    getUnits: async (filterState = {}) => {
        return (await api.post(config.apiUrl.getUnits, filterState)).data;
    },
    getUnitsAsync: async (filterState = {}) => {
        return apiResult(api.post(config.apiUrl.getUnits, filterState));
    },
    addUnit: async (values) => {
        return apiResult(api.post(config.apiUrl.addUnit, values));
    },
    updateUnitAsync: async (values) => {
        return apiResult(api.post(config.apiUrl.updateUnit, values));
    },
    getUnitByIdAsync: async (unitId) => {
        return apiResult(api.get(config.apiUrl.getUnitById + "/" + unitId));
    },
};

export default ClientsService;
