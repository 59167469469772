import Roles from "./core/enums/Roles";
import sk from "date-fns/locale/sk";
import hu from "date-fns/locale/hu";
import cz from "date-fns/locale/cs";
import i18n from "i18next";
import React, { useEffect } from "react";
import LoginService from "./services/Login/LoginService";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { Helmet } from "react-helmet";
import { initReactI18next, useTranslation } from "react-i18next";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import useSharedState from "./hooks/_shared/useSharedState";
import { ThemeProvider } from "styled-components";
import { Normalize } from "styled-normalize";
import ListingPageProvider from "./components/data/ListingPageProvider";
import GlobalStyles from "./components/elements/GlobalStyles";
import AppHeader from "./components/elements/header/AppHeader.js";
import { Main } from "./components/elements/Main";
import MainLayout from "./components/layout/split/one-two/MainLayout";
import Loader from "./components/loaders/Loader";
import ModalWizard from "./components/modal/ModalWizard";
import Notifier from "./components/notifications/Notifier";
import PrivateRoute from "./core/PrivateRoute";
import companyStateModule from "./core/state/company/companyStateModule";
import userStateModule from "./core/state/user/userStateModule.js";
import useStateModule from "./core/state/useStateModule.js";
import globals from "./globalVars";
import useMatchMedia from "./hooks/_shared/useMatchMedia";
import routes from "./routes";
import SetupAccountScreen from "./screens/Company/views/company/SetupAccountScreen.js";
import UserService from "./services/User/UserService";
import CountryService from "./services/User/CountryService";
import CompanyService from "./services/Company/CompanyService";
import defaultTheme from "./themes/default";
import actions_en from "./translations/en/actions";
import common_en from "./translations/en/common.json";
import exceptions_en from "./translations/en/exceptions.json";
import filters_en from "./translations/en/filters";
import forms_en from "./translations/en/forms.json";
import header_en from "./translations/en/header";
import moduleCards_en from "./translations/en/moduleCards.json";
import navigation_en from "./translations/en/navigation";
import screens_en from "./translations/en/screens.json";
import sidebar_en from "./translations/en/sidebar";
import table_en from "./translations/en/table";
import seo_en from "./translations/en/seo";
import actions_sk from "./translations/sk/actions";
import common_sk from "./translations/sk/common.json";
import exceptions_sk from "./translations/sk/exceptions.json";
import filters_sk from "./translations/sk/filters";
import forms_sk from "./translations/sk/forms";
import header_sk from "./translations/sk/header";
import moduleCards_sk from "./translations/sk/moduleCards.json";
import navigation_sk from "./translations/sk/navigation";
import screens_sk from "./translations/sk/screens";
import sidebar_sk from "./translations/sk/sidebar";
import table_sk from "./translations/sk/table";
import seo_sk from "./translations/sk/seo";
import actions_hu from "./translations/hu/actions";
import common_hu from "./translations/hu/common.json";
import exceptions_hu from "./translations/hu/exceptions.json";
import filters_hu from "./translations/hu/filters";
import forms_hu from "./translations/hu/forms";
import header_hu from "./translations/hu/header";
import moduleCards_hu from "./translations/hu/moduleCards.json";
import navigation_hu from "./translations/hu/navigation";
import screens_hu from "./translations/hu/screens";
import sidebar_hu from "./translations/hu/sidebar";
import table_hu from "./translations/hu/table";
import seo_hu from "./translations/hu/seo";
import actions_cz from "./translations/cz/actions";
import common_cz from "./translations/cz/common.json";
import exceptions_cz from "./translations/cz/exceptions.json";
import filters_cz from "./translations/cz/filters";
import forms_cz from "./translations/cz/forms";
import header_cz from "./translations/cz/header";
import moduleCards_cz from "./translations/cz/moduleCards.json";
import navigation_cz from "./translations/cz/navigation";
import screens_cz from "./translations/cz/screens";
import sidebar_cz from "./translations/cz/sidebar";
import table_cz from "./translations/cz/table";
import seo_cz from "./translations/cz/seo";

registerLocale("sk", sk);
registerLocale("cz", cz);
registerLocale("hu", hu);

if (!localStorage.getItem("lang")) {
    localStorage.setItem("lang", "en");
}

const lang = localStorage.getItem("lang");

i18n.on("languageChanged", (lng) => {
    localStorage.setItem("lang", lng);
    setDefaultLocale(lng);
});

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            en: {
                common: common_en,
                forms: forms_en,
                screens: screens_en,
                exceptions: exceptions_en,
                moduleCards: moduleCards_en,
                sidebar: sidebar_en,
                header: header_en,
                actions: actions_en,
                navigation: navigation_en,
                filters: filters_en,
                table: table_en,
                seo: seo_en,
            },
            sk: {
                header: header_sk,
                navigation: navigation_sk,
                screens: screens_sk,
                actions: actions_sk,
                common: common_sk,
                exceptions: exceptions_sk,
                filters: filters_sk,
                forms: forms_sk,
                moduleCards: moduleCards_sk,
                sidebar: sidebar_sk,
                table: table_sk,
                seo: seo_sk,
            },
            hu: {
                header: header_hu,
                navigation: navigation_hu,
                screens: screens_hu,
                actions: actions_hu,
                common: common_hu,
                exceptions: exceptions_hu,
                filters: filters_hu,
                forms: forms_hu,
                moduleCards: moduleCards_hu,
                sidebar: sidebar_hu,
                table: table_hu,
                seo: seo_hu,
            },
            cz: {
                header: header_cz,
                navigation: navigation_cz,
                screens: screens_cz,
                actions: actions_cz,
                common: common_cz,
                exceptions: exceptions_cz,
                filters: filters_cz,
                forms: forms_cz,
                moduleCards: moduleCards_cz,
                sidebar: sidebar_cz,
                table: table_cz,
                seo: seo_cz,
            },
        },
        lng: lang,
        fallbackLng: "en",
        interpolation: {
            escapeValue: false,
        },
        transSupportBasicHtmlNodes: true,
    });

const ECertificateStatus = {
    Submitted: 0,
    Valid: 1,
    Rejected: 2,
    Expired: 99,
    NoAttestation: -1, //display only
};

function App() {
    let params = new URLSearchParams(window.location.search);
    const ssoLoginId = params.get("ssoId");

    useEffect(() => {
        const getSsoData = async () => {
            const data = await LoginService.getSSOTokens(ssoLoginId);
            if (data.token && data.refreshToken) {
                localStorage.setItem("token", data.token);
                localStorage.setItem(
                    "refreshToken",
                    data.refreshToken
                );
                window.location.href = window.location.origin;
            }
        };
        if (ssoLoginId) getSsoData();
    }, []);

    const { isAuthenticated, isFullyRegistered, loadUserData, userData } =
        useStateModule(userStateModule);

    const [globalState, setGlobalState] = useSharedState("global", {
        userLoaded: false,
        showDisposalSection: false,
        showGasAnalyzers: false,
    });

    const { loadCompany } = useStateModule(companyStateModule);
    const userDetails = UserService.getUserDetailsFromToken();
    const userCompanyId = userDetails ? userDetails.CompanyId : null;
    const userCountryId = userDetails ? userDetails.CountryId : null;
    const userRoles = UserService.getUserRoles();

    const getCompanyCertificateAsync = async () => {
        const resCompany = await CompanyService.getMyCompanyCertificates();
        if (!resCompany.error) {
            const certs = resCompany.data.data;
            const validCerts = certs.filter((c) => c.licenceStatus === ECertificateStatus.Valid);
            setGlobalState({
                hasValidCompanyCertificate: validCerts.length > 0,
                userLoaded: true,
            });
        }
    };

    const isUserCertValid = (userData) => {
        return (
            userData.licences.length > 0 &&
            userData.licences.filter((l) => l.licenceStatus === 1).length > 0
        );
    };

    useEffect(() => {
        if (isAuthenticated && !userData.isLoaded && !userData.isLoading) {
            loadUserData();
        }
        if (
            isAuthenticated &&
            isFullyRegistered &&
            !userData.isLoaded &&
            !userData.isLoading &&
            userCompanyId
        ) {
            loadCompany(userCompanyId);
        }
        globals.userCountryId = userCountryId;
        globals.companyId = userCompanyId;
        globals.userRoles = userRoles;
        globals.isAdmin = globals.userRoles
            ? globals.userRoles.includes(Roles.MasterAdmin) ||
              globals.userRoles.includes(Roles.Admin)
            : false;
        globals.VATPercentage = userData.vatPercentage ? userData.vatPercentage / 100 + 1.0 : 1;
        globals.VATPercString = userData.vatPercentage ? userData.vatPercentage : 0;

        if (userData.isLoaded && !globalState.userLoaded) {
            globals.isDistributor = userData.companyType === 1;
            globals.showDisposalSection =
                userData.showDisposalSection !== undefined ? userData.showDisposalSection : true;
            setGlobalState({
                enableHandlingCosts:
                    userData.enableHandlingCosts !== undefined
                        ? userData.enableHandlingCosts
                        : false,
                hasCertificateRights: userData.hasCertificateRights,
                showCertificateWarning: userData.showCertificateWarning,
                hasValidUserCertificate: isUserCertValid(userData),
                isDistributor: userData.companyType === 1,
                isMasterAdmin: (globals.userRoles = userData.roleNames.includes(Roles.MasterAdmin)),
                isAdmin:
                    userData.roleNames.includes(Roles.MasterAdmin) ||
                    userData.roleNames.includes(Roles.Admin),
                userCountryId: userDetails.CountryId,
                showDisposalSection:
                    userData.showDisposalSection !== undefined
                        ? userData.showDisposalSection
                        : true,
                showGasAnalyzers:
                    userData.showGasAnalyzers !== undefined ? userData.showGasAnalyzers : true,
                showServicesSection:
                    userData.showServicesSection !== undefined
                        ? userData.showServicesSection
                        : true,
                userCompanyId: userCompanyId,
            });
            getCompanyCertificateAsync();
        }
    }, [
        isAuthenticated,
        userData.isLoaded,
        userData.isLoading,
        loadUserData,
        loadCompany,
        userCompanyId,
        userRoles,
        isFullyRegistered,
    ]);

    useEffect(() => {
        (async () => {
            const result = await CountryService.getCountriesAsync();
            globals.countries = result?.data?.data || [];
        })();
    }, []);

    const { t } = useTranslation();
    globals.isMobile = useMatchMedia("(max-width: 768px)");

    return (
        <Router>
            <ThemeProvider theme={defaultTheme}>
                <Helmet titleTemplate="Retradeables - %s" defaultTitle="Retradeables">
                    <link rel="preconnect" href="https://fonts.gstatic.com" />
                    <link
                        href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;600&display=swap"
                        rel="stylesheet"
                    />
                </Helmet>
                <Normalize />
                <GlobalStyles />
                <ListingPageProvider>
                    <MainLayout>
                        <Main className={isAuthenticated && "authenticated"}>
                            {!globals.isMobile && <AppHeader />}

                            {!isFullyRegistered && isAuthenticated ? (
                                <Switch>
                                    <PrivateRoute
                                        component={SetupAccountScreen}
                                        path={"/company/edit"}
                                    />
                                    <Redirect to="/company/edit" />
                                </Switch>
                            ) : (
                                <Switch>
                                    {routes.map((r) => {
                                        return r.unprotected ? (
                                            <Route
                                                component={r.component}
                                                key={r.path}
                                                path={r.path}
                                            />
                                        ) : (
                                            <PrivateRoute
                                                roles={r.roles}
                                                exact={r.exact}
                                                component={r.component}
                                                key={r.path}
                                                path={r.path}
                                            />
                                        );
                                    })}
                                </Switch>
                            )}
                        </Main>
                    </MainLayout>
                </ListingPageProvider>
                <footer />
                <ModalWizard t={t} />
                <Notifier />
                <Loader />
            </ThemeProvider>
        </Router>
    );
}

export default App;
