import React from "react";
import styled from "styled-components";

const Container = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    flex-grow: 1;
    position: relative;
    padding: 25px 60px 0px;
    display: flex;
    flex-direction: column;

    &.company {
        padding: 25px 60px 0px 225px;
        @media ${(p) => p.theme.device.mobile} {
        padding: 10px 24px 0px 24px;
        }

        &.pr-0 {
            padding-right: 0px;
        }
    }

    &.wide {
        padding: 0px 0px 0px;
    }

    &.wide-list {
        padding: 20px 0px 0px;
    }

    &.pwa {
        height: auto;
        padding-left:15px;
        padding-right:20px;
    }

    & .right-action + .filter-wrapper {
        float: left;
        width: calc(100% - 250px);
    }

   
`;

const LayoutInnerContainer = ({ children, ...rest }) => {
    return <Container {...rest}>{children}</Container>;
};

export default LayoutInnerContainer;
