import styled from 'styled-components';

const Form = styled.form`
&.login {
    flex-grow:1;
    display:flex;
    align-items:center;
    padding:0px 30px;
}
`;

export default Form;
