import React, { useRef } from "react";
import { useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import useLeftMenu from "../../../core/state/navigation/useLeftMenu";
import userStateModule from "../../../core/state/user/userStateModule";
import useStateModule from "../../../core/state/useStateModule";
import globals from "../../../globalVars";
import Breadcrumbs from "../../breadcrumbs/Breadcrumbs";
import RouteCreator from "../../../core/RouteCreator";
import HeaderLogo from "./components/HeaderLogo";
import HeaderUserArea from "./components/HeaderUserArea";
import LanguageSwitch from "./components/LanguageSwitch";
import { ReactComponent as ImperSvg } from "../../../assets/impersonation.svg";
import { ReactComponent as SupportSvg } from "../../../assets/support.svg";
import { useTranslation } from "react-i18next";
import UserService from "../../../services/User/UserService";
import Button from "../../../components/elements/buttons/Button";
import LoginService from "../../../services/Login/LoginService";
import KycSemaphore from "./components/KycSemaphore";
import CertSemaphore from "./components/CertSemaphore";
import Roles from "../../../core/enums/Roles";
import RequestSupportForm from "./components/RequestSupportForm";
import { toast } from "react-toastify";
import ModalDialog, { useModal } from "../../modal/ModalDialog";
import useSharedState from "../../../hooks/_shared/useSharedState";

const Header = styled.header`
    &.is-authenticated {
        background-color: ${(props) => props.theme.bgHeader};
        border-bottom: 1px solid ${(props) => props.theme.headerBorderColor};
        box-sizing: border-box;
        display: flex;
        align-items: center;
        position: sticky;
        width: 100%;
        height: ${(props) => props.theme.heightHeader};
        min-height: ${(props) => props.theme.heightHeader};
        top: 0;
        z-index: 10;
        background-color: #fafafa;
        justify-content: space-between;
    }
`;

const SvgSupport = styled(SupportSvg)`
    width: 24px;
    height: 24px;
    margin-right: 12px;
`;

const SupportBlock = styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    color: #2294d2;
    margin-right: 60px;
    cursor: pointer;

    & svg {
        margin-left: 10px;
    }
`;

const SvgImper = styled(ImperSvg)`
    width: 20px;
    height: 20px;
    margin-right: 12px;
`;

const Separator = styled.div`
    width: 1px;
    height: 20px;
    background-color: #e94e4e;
    margin-right: 12px;
`;

const ImperText = styled.div`
    margin-right: 24px;
    font-size: 16px;
    & > .bold {
        font-weight: 700;
    }
`;

const AreaWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const AppHeader = () => {
    const [globalState] = useSharedState("global");
    const supportRef = useRef();
    const { isAuthenticated, userData, menuState, impersonatedByUsername } =
        useStateModule(userStateModule);
    let { isVisible } = useLeftMenu();
    const companyDetailsRouteMatch = useRouteMatch({
        path: RouteCreator.company.root(),
        strict: false,
    });

    const isAdminEdit = useRouteMatch({
        path: RouteCreator.admin.edit(""),
        strict: false,
    });

    const { t } = useTranslation("common");

    const CompanyName = UserService.getUserDetailsFromToken()?.CompanyName;

    const { modalProps, toggleOpen: toggleModalOpen } = useModal({
        title: t("actions:dialogs.supportRequest.title"),
        type: "info",
        onConfirm: async () => {
            toggleModalOpen();
        },
        confirmText: t("actions:dialogs.supportRequest.confirmText"),
    });

    let showHeaderLogo = false;
    if (isVisible === false) {
        showHeaderLogo = true;
    }

    if (!isAuthenticated) {
        return false;
    }

    const stopImpersonation = async () => {
        const result = await LoginService.stopImpersonation();
        if (result) {
            localStorage.setItem("token", result.token);
            localStorage.setItem("refreshToken", result.refreshToken);
            window.location.replace("/admin/users/users");
        }
    };

    const toggleSupportForm = () => {
        supportRef.current.classList.toggle("open");
    };

    const supportFormCb = (status, spockName) => {
        if (status === 0 || status === 200) {
            toggleSupportForm();
            if (status === 200) toggleModalOpen({ spockName });
        } else toast.error(t("screens:userPictureEdit.toastNotification.fail"));
    };

    return (
        <Header
            className={[
                isAuthenticated ? "is-authenticated" : "",
                menuState.isOpened ? "menu-is-opened" : "",
            ]}
        >
            {globalState.isAdmin === false && (
                <RequestSupportForm
                    ref={supportRef}
                    closeCb={supportFormCb}
                    isAdmin={globalState.isAdmin}
                />
            )}
            <ModalDialog {...modalProps}>
                <p>{t("actions:dialogs.supportRequest.message")}</p>
                <ul style={{ marginLeft: "10px" }}>
                    <li>{modalProps.modal?.spockName}</li>
                </ul>
            </ModalDialog>
            <AreaWrapper>
                {showHeaderLogo && <HeaderLogo />}
                {!globals.isMobile && (
                    <Breadcrumbs
                        className={companyDetailsRouteMatch || isAdminEdit ? "company" : ""}
                    />
                )}
            </AreaWrapper>
            {impersonatedByUsername && (
                <AreaWrapper>
                    <SvgImper />
                    <Separator />
                    <ImperText>
                        {t("impersonate.imperonating")}{" "}
                        <span className="bold">{userData.firstName + " " + userData.lastName}</span>{" "}
                        {t("impersonate.from")} <span className="bold">{CompanyName}</span>
                    </ImperText>
                    <Button
                        className="secondary--negative inline"
                        onClick={() => {
                            stopImpersonation();
                        }}
                    >
                        {t("impersonate.stopImpersonation")}
                    </Button>
                </AreaWrapper>
            )}
            <AreaWrapper>
                {globalState.isAdmin === false && (
                    <SupportBlock onClick={() => toggleSupportForm()}>
                        {t("actions:requestSupport")} <SupportSvg />
                    </SupportBlock>
                )}
                {isAuthenticated &&
                    !UserService.isInRole(Roles.Admin) &&
                    !UserService.isInRole(Roles.MasterAdmin) && (
                        <>
                            <CertSemaphore /> <KycSemaphore />
                        </>
                    )}
                <LanguageSwitch isInHeader></LanguageSwitch>
                {isAuthenticated ? (
                    <>
                        <HeaderUserArea
                            avatarUrl={userData.profilePictureUrl}
                            name={userData.firstName}
                            userFullName={userData.firstName + " " + userData.lastName}
                            email={userData.email}
                        />
                    </>
                ) : (
                    <div style={{ flex: 1 }} />
                )}
            </AreaWrapper>
        </Header>
    );
};

export default AppHeader;
