import i18next from "i18next";
import React, { useRef, useState, useEffect } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled, { css } from "styled-components";
import { ReactComponent as ResetSvg } from "../../assets/close.svg";
import { Label } from "../elements/form-elements";

const StyledReset = styled(ResetSvg)`
    position: absolute;
    right: 12px;
    width: 12px;
    height: 12px;
    transition: all 300ms ease;
    z-index: 2;

    &:hover .lines {
        stroke: #e94e4e;
    }
`;

const StyledDatePickerWrapper = styled.div`
    position: relative;
    display: inline-block;

    & .react-datepicker-wrapper {
        width: 100%;
        float: left;
    }

    & .react-datepicker-popper {
        z-index: 10000;
    }

    & input[value=""],
    & input[value="-"],
    & input:not([value]) {
        padding-left: 8px !important;
    }
`;

const InputWrapper = styled.div`
    & input {
        margin: 10px;
        width: 100px !important;
        color: #2294d2;
        font-weight: 600;
    }
    & label {
        color: #aaaeb3;
        float: left;
    }
`;

const StyledDatePicker = styled(ReactDatePicker)`
    background: none;
    float: left;
    border: none;
    ${(props) =>
        props.disabled &&
        css`
            background: #f5f5f5;
        `};
`;

const ChartDatePicker = (props) => {
    let {
        labelText,
        name,
        style,
        selected,
        disabled,
        filter = "",
        className,
        mode,
        value,
        values,
        placeholder,
        maxDate,
        minDate,
    } = props;

    useEffect(() => {
        setCurrentDate(selected);
    }, [selected]);

    const inputEl = useRef();
    const iniValue = value || (values ? values[name] : "");
    const [currentDate, setCurrentDate] = useState(selected || iniValue);
    const date = new Date();
    let today = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    const isFutureDate = (date) => date >= today;
    const isPasteDate = (date) => date <= today;
    let filterDate = () => true;
    if (filter === "future") filterDate = isFutureDate;
    else if (filter === "past") filterDate = isPasteDate;
    return (
        <InputWrapper style={style}>
            <Label htmlFor={name}>{labelText}</Label>
            <StyledDatePickerWrapper>
                <StyledDatePicker
                    dateFormat="dd.MM.yyyy"
                    minDate={minDate?? new Date("1/1/1930")}
                    maxDate={maxDate?? new Date("1/1/2100")}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    locale={i18next.language}
                    ref={inputEl}
                    id={name}
                    selected={currentDate}
                    name={name}
                    mode={mode ? mode : "date"}
                    className={className}
                    placeholderText={placeholder || "-"}
                    showTimeSelect={false}
                    filterDate={filterDate}
                    disabled={disabled}
                    onChange={(date) => {
                        props.handleChange && props.handleChange(date);
                        setCurrentDate(date);
                    }}
                    popperPlacement="bottom-start"
                    popperModifiers={{
                        preventOverflow: {
                            enabled: true,
                            escapeWithReference: false,
                            boundariesElement: "viewport",
                        },
                    }}
                />

                {currentDate && (
                    <StyledReset
                        className={
                            !props.className ? "clearable" : props.className
                        }
                        onClick={() => {
                            props.handleChange && props.handleChange("");
                            setCurrentDate("");
                        }}
                    />
                )}
            </StyledDatePickerWrapper>
        </InputWrapper>
    );
};

export default ChartDatePicker;
