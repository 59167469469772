import React from "react";
import { Switch } from "react-router-dom";
import PrivateRoute from "../../core/PrivateRoute";
import RouteCreator from "../../core/RouteCreator";
import useLeftMenu from "../../core/state/navigation/useLeftMenu";
import companyAdminMenuItems from "../../data/menu/companyAdminMenuItems";
import useSharedState from "../../hooks/_shared/useSharedState";
import ClientsSection from "./views/ClientsSection";

const ClientsScreen = () => {
    const [globalState] = useSharedState("global");
    useLeftMenu(companyAdminMenuItems(globalState));

    return (
        <Switch>
            <PrivateRoute
                path={RouteCreator.clients.root()}
                component={ClientsSection}
            />
        </Switch>
    );
};

export default ClientsScreen;
