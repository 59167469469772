import CompanyType from "./enums/CompanyType";

const RouteCreator = {
    home: () => {
        return "/";
    },
    admin: {
        edit: (userId) => {
            return `${RouteCreator.admin.users.root()}/edit/${userId}`;
        },
        users: {
            root: () => {
                return "/admin/users";
            },
            nonAdmins: () => {
                return `${RouteCreator.admin.users.root()}/users`;
            },
            admins: () => {
                return `${RouteCreator.admin.users.root()}/administrators`;
            },
            createAdmin: () => {
                return `${RouteCreator.admin.users.admins()}/create`;
            },
            statistics: () => {
                return `${RouteCreator.admin.users.nonAdmins()}/statistics`;
            },
            certificates: (userId) => {
                return `${RouteCreator.admin.users.root()}/info/${userId}`;
            },
        },
        companies: {
            root: () => {
                return "/admin/companies";
            },
            list: (companyType) => {
                if (companyType === CompanyType.InstallationCompany) {
                    return `${RouteCreator.admin.companies.root()}/list/installers`;
                } else if (companyType === CompanyType.Distributor) {
                    return `${RouteCreator.admin.companies.root()}/list/distributors`;
                }
                return `${RouteCreator.admin.companies.root()}/list`;
            },
            statistics: () => {
                return `${RouteCreator.admin.companies.root()}/statistics`;
            },
            certificates: () => {
                return `${RouteCreator.admin.companies.root()}/certificates/:companyId`;
            },
        },
        marketplaceStatistics: () => {
            return "/admin/sales-center";
        },
        marketplace: () => {
            return "/admin/sales-center/marketplace";
        },
        reclaimedMarketplace: () => {
            return "/admin/sales-center/reclaimedMarketplace";
        },
        services: {
            root: () => {
                return "/admin/services";
            },
            disposal: () => {
                return `${RouteCreator.admin.services.root()}/disposal`;
            },
            cleaning: () => {
                return `${RouteCreator.admin.services.root()}/cleaning`;
            },
        },
        hangfire: () => {
            return "/admin/hangfire";
        },
        qrTags: () => {
            return "/admin/qr";
        },
    },
    equipment: {
        root: () => {
            return "/equipment";
        },
        cylinders: () => {
            return `${RouteCreator.equipment.root()}/cylinders`;
        },
        analyzers: () => {
            return `${RouteCreator.equipment.root()}/analyzers`;
        },
        edit: (id) => {
            return `${RouteCreator.equipment.root()}/edit/${id}`;
        },
        create: () => {
            return `${RouteCreator.equipment.root()}/create`;
        },
    },
    user: {
        login: () => {
            return "/login";
        },
        register: () => {
            return "/register";
        },
        forgotPassword: () => {
            return "/forgot";
        },
        resetPassword: () => {
            return "/reset";
        },
        activate: () => {
            return "/activate";
        },
        verifyLogin: () => {
            return "/verify-login";
        },
        changeEmail: () => {
            return "/changeEmail";
        },
        profile: () => {
            return "/profile";
        },
    },
    company: {
        root: () => {
            return "/company";
        },
        details: () => {
            return `${RouteCreator.company.root()}/details`;
        },
        certificates: () => {
            return `${RouteCreator.company.root()}/certificates`;
        },
        requestQR: () => {
            return `${RouteCreator.company.root()}/qr`;
        },
        payments: () => {
            return `${RouteCreator.company.root()}/payments`;
        },
        kyc: () => {
            return `${RouteCreator.company.root()}/kyc`;
        },
    },
    employee: {
        list: () => {
            return `${RouteCreator.company.root()}/employees`;
        },
        edit: (userId) => {
            return `${RouteCreator.company.root()}/employees/edit/${userId}`;
        },
        editEmployee: () => {
            return `${RouteCreator.company.root()}/employees/edit/:id`;
        },
    },
    salesCenter: {
        root: () => {
            return "/sales-center";
        },
        marketplace: () => {
            return `${RouteCreator.salesCenter.root()}/marketplace`;
        },
        myBids: () => {
            return `${RouteCreator.salesCenter.root()}/my-bids`;
        },
        myOffers: () => {
            return `${RouteCreator.salesCenter.root()}/my-offers`;
        },
        transactions: () => {
            return `${RouteCreator.finance.root()}/transactions`;
        },
        myWallets: () => {
            return `${RouteCreator.salesCenter.root()}/transactions/my-wallets`;
        },
        myTransactions: () => {
            return `${RouteCreator.salesCenter.root()}/transactions/my-transactions`;
        },
        disputes: () => {
            return `${RouteCreator.salesCenter.root()}/disputes`;
        },
        reclaimedMarketOffers: () => {
            return `${RouteCreator.salesCenter.root()}/reclaimedMarketOffers`;
        },
        reclaimedMarketplace: () => {
            return `${RouteCreator.salesCenter.root()}/reclaimedMarketplace`;
        },
        reclaimedMarketPurchases: () => {
            return `${RouteCreator.salesCenter.root()}/reclaimedMarketPurchases`;
        },
    },
    disputes: {
        root: () => "/disputes",
    },
    stock: {
        root: () => {
            return "/stock";
        },
        company: () => {
            return `${RouteCreator.stock.root()}/company`;
        },
        installer: () => {
            return `${RouteCreator.stock.root()}/technician`;
        },
    },
    confirm: {
        company: () => {
            return `/confirm`;
        },
    },
    clients: {
        root: () => {
            return "/clients";
        },
        locations: (clientId) => {
            return `${RouteCreator.clients.root()}/${clientId}/locations`;
        },
        units: (clientId, locationId) => {
            return `${RouteCreator.clients.root()}/${clientId}/locations/${locationId}/units`;
        },
    },
    pwa: {
        root: () => {
            return "/pwa";
        },
        stock: () => {
            return `${RouteCreator.pwa.root()}/stock`;
        },
        equipment: () => {
            return `${RouteCreator.pwa.root()}/equipment`;
        },
        analyzers: () => {
            return `${RouteCreator.pwa.root()}/analyzers`;
        },
        cylinders: () => {
            return `${RouteCreator.pwa.root()}/cylinders`;
        },
        editUser: (userId) => {
            return `${RouteCreator.pwa.root()}/edit/${userId}`;
        },
        extraction: () => {
            return `${RouteCreator.pwa.root()}/extraction`;
        },
        toCompanyStock: () => {
            return `${RouteCreator.pwa.root()}/moveToCompanyStock`;
        },
        toMyStock: () => {
            return `${RouteCreator.pwa.root()}/moveToMyStock`;
        },
        addUnit: () => {
            return `${RouteCreator.pwa.root()}/addUnit`;
        },
        addCylinder: () => {
            return `${RouteCreator.pwa.root()}/addCylinder`;
        },
        addAnalyzer: () => {
            return `${RouteCreator.pwa.root()}/addAnalyzer`;
        },
        collect: () => {
            return `${RouteCreator.pwa.root()}/collect`;
        },
        recycleCylinder: () => {
            return `${RouteCreator.pwa.root()}/recycleCylinder`;
        },
        finishRecycling: () => {
            return `${RouteCreator.pwa.root()}/finishRecycling`;
        },
        collectEmptyCilinder: () => {
            return `${RouteCreator.pwa.root()}/collectEmptyCilinder`;
        },
    },
    finance: {
        root: () => {
            return "/finance";
        },
        transactions: () => {
            return `${RouteCreator.finance.root()}/transactions`;
        },
        hierarchy: () => {
            return `${RouteCreator.finance.root()}/hierarchy`;
        },
        invoices: () => {
            return `${RouteCreator.finance.root()}/invoices`;
        },
        documents: () => {
            return `${RouteCreator.finance.root()}/documents`;
        },
    },
    services: {
        root: () => {
            return "/services";
        },
        disposal: () => {
            return `${RouteCreator.services.root()}/disposal`;
        },
        disposalBids: () => {
            return `${RouteCreator.services.root()}/disposal/bids`;
        },
        cleaning: () => {
            return `${RouteCreator.services.root()}/cleaning`;
        },
        cleaningBids: () => {
            return `${RouteCreator.services.root()}/cleaning/bids`;
        },
        reclaimed: () => {
            return `${RouteCreator.services.root()}/reclaimed`;
        },
    },
};

export default RouteCreator;
