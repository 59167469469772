import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import QRCode from "qrcode.react";
import CompanyService from "../../../services/Company/CompanyService";
import { StyledBack, SvgNoImage, SvgEdit, SvgDelete } from "./StyledSvg";
import ModalDialog, { useModal } from "../../../components/modal/ModalDialog";
import ModalWizard from "../../../components/modal/ModalWizard";
import EditCylinderWizard from "../actions/components/EditCylinderWizard";
import {
    ScreenWrapper,
    ScreenHeader,
    ListTitle,
    HR,
    Box16A9,
    VSpace,
} from "./StyledElements";

const StyledQR = styled.div`
    width: 100%;
    text-align: center;
    span {
        display: inline-block;
        margin-top: 5px;
        font-size: 10px;
    }
`;
const TagTitle = styled.div`
    width: 100%;
    font-size: 16px;
    font-weight: 700;
    margin-top: 5px;
`;

const Content = styled.div`
    width: 100%;
    /* height: 100%; */
    box-sizing: border-box;
    flex-grow: 1;
    position: relative;
    padding: 35px 60px 0px;
    display: flex;
    flex-direction: column;
    padding: 10px 24px 0px 24px;
`;

const Section = styled.div`
    padding: 12px 20px;
`;

const KeyVal = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    color: #000;
    font-weight: 700;
    margin-bottom: 6px;
    & span {
        font-weight: 500;
        color: ${(p) => p.theme.primaryColor};
    }
`;

const HeaderActions = styled.div`
    & svg + svg {
        margin-left: 18px;
    }
`;

const splitAt = (index) => (x) => [x.slice(0, index), x.slice(index)];

const CylinderDetails = ({ cylinder, ...restProps }) => {
    const { t } = useTranslation("screens");

    const {
        modalProps: modalPropsDelete,
        toggleOpen: toggleOpenDelete,
    } = useModal({
        title: t("actions:dialogs.deleteCylinder.title"),
        onConfirm: async (id) => {
            const res = await CompanyService.deleteCylinderAsync(id);
            if (res && res.error) {
                toast.error(t(res.error.message));
            } else {
                toast.success(t("cylinderListing.toastNotification.success"));
                toggleOpenDelete();
                restProps.onGotoStart();
            }
        },
        onCancel: () => {
            toggleOpenDelete();
        },
        confirmText: t("actions:dialogs.deleteEquipment.confirmText"),
    });


    if (!cylinder) return <ScreenWrapper />;
    const tagParts = cylinder.tagId ? splitAt(4)(cylinder.tagId) : [];

    const showCylinderEdit = () => {
        const initialValues = {
            id: cylinder.id,
            tagId: cylinder.tagId,
            imageFile: cylinder.imageUrl && (cylinder.imageUrl.indexOf("no_image") < 0)
                ? [
                      {
                          id: "1",
                          imageUrl: cylinder.imageUrl,
                          imageThumbnailUrl: cylinder.imageUrl,
                      },
                  ]
                : [],
            size: cylinder.size.toString(),
            refrigerantTypeCode: cylinder.refrigerantTypeCode,
            filledQuantity: cylinder.filledQuantity,
            deleteImage: false,
        };

        ModalWizard.show(
            EditCylinderWizard(t, initialValues),
            {
                onSubmit: async (values) => {
                    if (values.imageFile.length === 0)
                        values.deleteImage = true;
                    else if (values.imageFile[0].id === "1")
                        values.imageFile = undefined;
                    const result = await CompanyService.updateCylinderAsync(
                        ModalWizard.asFormData(values)
                    );

                    if (result && result.error)
                        ModalWizard.submitFailed(t(result.error.message));
                    else {
                        toast.success(
                            t("actions:dialogs.editCylinder.success")
                        );
                        ModalWizard.hide();
                        restProps.onGotoStart();
                    }
                },
                onCancel: async () => {
                    ModalWizard.hidePrompt();
                },
            },
            { pwa: true }
        );
    };

    return (
        <ScreenWrapper className="ScreenWrapper">
            <ScreenHeader>
                <StyledBack
                    onClick={(ev) => {
                        restProps.onGoBack(ev);
                    }}
                />
                <HeaderActions>
                    <SvgDelete
                        className={
                            cylinder.filledQuantity > 0 ? "disabled" : ""
                        }
                        onClick={() => {
                            if (cylinder.filledQuantity === 0)
                                toggleOpenDelete(cylinder.id);
                        }}
                    />
                    <SvgEdit onClick={() => showCylinderEdit()} />
                </HeaderActions>
            </ScreenHeader>
            <HR />
            <ListTitle>{t("screens:technician.qrTag")}</ListTitle>
            <Content>
                <StyledQR>
                    <QRCode value={cylinder.tagId} size={106} />
                    <TagTitle>{tagParts[0]}</TagTitle>
                    <span>{tagParts[1]}</span>
                </StyledQR>
                <HR className="content" />
                <ListTitle className="content">
                    {t("screens:technician.details")}
                </ListTitle>
                <Section>
                    <KeyVal>
                        {t("table:headers.cylinders.netWeight")}
                        <span>{cylinder.size.toFixed(2) + " kg"}</span>
                    </KeyVal>

                    <KeyVal>
                        {t("table:headers.cylinders.gas")}
                        <span>{cylinder.refrigerantTypeCode ? cylinder.refrigerantTypeCode:"N/A"}</span>
                    </KeyVal>
                    <KeyVal>
                        {t("table:headers.cylinders.quantity")}
                        <span>{cylinder.filledQuantity.toFixed(2) +" kg"}</span>
                    </KeyVal>
                    <KeyVal>
                        {t("sidebar:label.owner")}
                        <span>{cylinder.ownerCompanyName}</span>
                    </KeyVal>
                    <VSpace size="20px" />
                    <Box16A9>
                        {cylinder.imageUrl && (cylinder.imageUrl.indexOf("no_image") < 0) ? (
                            <img
                                className="thumb"
                                alt="unit"
                                src={cylinder.imageUrl}
                            />
                        ) : (
                            <SvgNoImage />
                        )}
                    </Box16A9>
                </Section>
            </Content>
            <ModalDialog {...modalPropsDelete}>
                <p>{t("actions:dialogs.deleteCylinder.text")}</p>
                <StyledQR className="modal">
                    <TagTitle>{tagParts[0]}</TagTitle>
                    <span>{tagParts[1]}</span>
                </StyledQR>
            </ModalDialog>
        </ScreenWrapper>
    );
};

export default CylinderDetails;
