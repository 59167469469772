const DASHBOARD_CONSTANTS = {
    COLORS : ['#2B736B', '#94C21C', '#F0C40D', '#FF8042'],
    FONT_COLOR:  '#AAAEB3',
    PERIODS: {
        WEEK : 'week',
        MONTH : 'month',
        DAY : 'day',
    },
    actionTypes : {
        SET_FILTERS: "SET_FILTERS",
        RESET_FILTERS: "RESET_FILTERS",
        SET_COMPANY_ID:  "SET_COMPANY_ID"
    },
    lineChartColor : "#6980BF"
};


export default DASHBOARD_CONSTANTS;
export let PRESUMED_GRADES =  [
    { name: "A", type: 'circle', id: 0, color: DASHBOARD_CONSTANTS.COLORS[0] },
    { name: "B", type: 'circle', id: 1, color: DASHBOARD_CONSTANTS.COLORS[1] },
    { name: "C", type: 'circle', id: 2, color: DASHBOARD_CONSTANTS.COLORS[2] }
];
