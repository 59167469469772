import React, {useState} from "react";
import styled from 'styled-components';
import Tooltip from "../../tooltip/Tooltip";

const Wrapper = styled.div`
    position: relative;

    & + div {
        margin: 0 0 0 20px;
    }
`;

const Button = styled.button`
    cursor: pointer;
    min-width: ${(props) => props.theme.widthActionButtonMin};
    min-width: ${(props) => props.minWidth? props.minWidth: 192};
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${props => props.theme.primaryColor};
    height: ${props => props.theme.heightButtonSmall};
    line-height: ${props => props.theme.heightButtonSmall};
    box-shadow: ${props => props.theme.buttonShadow};
    font-size: 12px;
    text-align: left;
    padding: 0 20px;
    position: relative;
    border-radius: 16px;
    text-align: center;

    &.invisible {
        opacity:0;
        cursor: default;
    }

    &.primary {
        background: ${(props) => props.theme.primaryColor};
        color: ${props => props.theme.textWhite};

        &:enabled:hover {
            background: ${p => p.theme.primaryColorHover};
            border: 1px solid ${props => props.theme.primaryColorHover};
        }
    }

    &.secondary {
        background: ${(props) => props.theme.bgWhite};
        color: ${(props) => props.theme.tertiaryColor};
    }

    &:disabled {
        background: ${props => props.theme.bgDisabled};
        color: ${props => props.theme.textDisabled};
        border-color: ${props => props.theme.borderColorDisabled};
    }
`;

const Icon = styled.img`
    position: relative;
    max-height: 20px;
    margin: 0 0 0 7px;
    height: ${props => props.size || 'auto'};
    max-height: ${props => props.size || 'auto'};
`;

const ActionButton = ({className, dataTest, onClick, disabled, icon, iconSize, tooltip, children, as = "button", ...rest}) => {
    const [tooltipVisible, setTooltipVisible] = useState(false);
    return (
        <Wrapper>
            <Button as={as} onMouseEnter={() => setTooltipVisible(true)}
                    onMouseLeave={() => setTooltipVisible(false)}
                    className={className}
                    onClick={onClick}
                    disabled={disabled}
                    data-test={ dataTest ? dataTest : "" }
                    {...rest}
            >
                {children}

                {
                    icon &&
                    <Icon src={icon} size={iconSize} />
                }
            </Button>
            {
                tooltip &&
                <Tooltip
                    tooltip={tooltip}
                    visible={tooltipVisible}
                    position="right"
                />
            }

        </Wrapper>

    )
}

export default ActionButton;
