import React from "react";
import {
    InfoText,
    InfoTitle,
    InfoWrapper,
    RoundInfo,
} from "../../../../../components/modal/components/WizardStyling";
import WizardSummaryField from "../../../../../components/modal/components/WizardSummaryField";
import InputElement from "../../../../../components/elements/form-elements/InputElement";
import { toast } from "react-toastify";
import ValidationRule, {
    buildValidation,
} from "../../../../../core/validations/ValidationRule";
import CountryService from "../../../../../services/User/CountryService";
import CompanyService from "../../../../../services/Company/CompanyService";
import styled from "styled-components";
import DatePicker from "../../../../../components/elements/form-elements/DatePicker";
import helpers from "../../../../../core/helpers";

const StyledInfoWrapper = styled(InfoWrapper)`
    min-height: auto;
`;

const SectionSummaryTitleWrapper = styled.div`
    flex-basis: 100%;
    display: flex;
    flex: 0;
    position: relative;
    margin-bottom: 20px;
    padding: 0px 20px;
    
    &:before {
        content: "";
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 100%;
        height: 1px;
        border-top: 1px solid #dddddd;
    }
`;

const SectionSummaryFieldsWrapper = styled.div`
   flex: 1; 
`;

const SummaryInfoTitle = styled.div`
    font-size: 1rem;
    color: #000;
    flex-grow: 1;
    font-weight: bold;
    line-height: 16px;
    display: block;
    margin: 8px 0px 0px;
    padding-bottom: 10px;
    &.primary {
        color: ${props => props.theme.primaryColor};
        font-size: 1rem;
    }

`;

const Separator = styled.div`
    width: 100%;
    display: block;
`;

const SummaryInfoWrapper = styled.div`
    width: 100%;
    margin: 10px 0px;
    display: flex;
    justify-content: space-between;

    & > section {
        width: 48%;
        display: flex;
        flex-direction: column;
        font-size: 12px;
        font-weight: 600;

        & > div.wrong {
            color: #f0c40d !important;
        }
    }

    & > section.bold {
        font-size: 14px;
        font-weight: 700;
    }

    & > section.green {
        color: ${(p) => p.theme.primaryColor};
    }
`;


const validUboStep1 = (t) => {
    return buildValidation({
        firstName: ValidationRule.isStringRule().required(
            t("forms:firstName.required")
        ).rule,
        lastName: ValidationRule.isStringRule().required(
            t("forms:lastName.required")
        ).rule,
        addressLine1: ValidationRule.isStringRule().required(
            t("forms:address.required")
        ).rule,
        city: ValidationRule.isStringRule().required(t("forms:city.required"))
            .rule,
        postalCode: ValidationRule.isStringRule().required(
            t("forms:postalCode.required")
        ).rule,
        region: ValidationRule.isStringRule().required(
            t("forms:region.required")
        ).rule,
        addressCountry: ValidationRule.isStringRule().required(
            t("forms:country.required")
        ).rule,
    });
};

const validUboStep2 = (t) => {
    return buildValidation({
        nationality: ValidationRule.isStringRule().required(
            t("forms:nationalityId.required")
        ).rule,
        birthday: ValidationRule.isStringRule().required(
            t("forms:birthday.required")
        ).rule,
        birthplaceCity: ValidationRule.isStringRule().required(
            t("forms:birthPlaceCity.required")
        ).rule,
        birthplaceCountry: ValidationRule.isStringRule().required(
            t("forms:birthPlaceCountryId.required")
        ).rule,
    });
};

const defaultInitialValues = {};

let nationalityLookupResult = [];

const nationalityLookup = async (filter) => {
    if (!filter || filter.length < 3) return [];
    const res = await CompanyService.getNationalities(filter);
    if (res.error) {
        toast.error(res.error.message);
        return [];
    }
    nationalityLookupResult = res.data.data;
    return res.data.data.map((r) => r.nationality);
};

let countryLookupResult = [];
const countryLookup = async (getAllCountries) => {
    if (!getAllCountries || getAllCountries.length < 3) return [];
    const res = await CountryService.getCountryNames(getAllCountries);

    if (res.error) {
        toast.error(res.error.message);
        return [];
    }
    countryLookupResult = res.data.data;
    return res.data.data.map((r) => r.countryName);
};

const getCountryId = (country) => {
    const match = countryLookupResult.filter((c) => c.countryName === country);
    return match.length > 0 ? match[0].id : "";
};


const getNationalId = (nationality) => {
    const match = nationalityLookupResult.filter(
        (itm) => itm.nationality === nationality
    );
    return match.length > 0 ? match[0].id : "";
};

const UboDeclarationWizard = (t, initialValues, isEdit) => {
    return {
        title: isEdit
            ? t("forms:ubo.editUbo")
            : t("forms:ubo.addUbo"),
        dataTest: "add-ubo-modal",
        className: "wide",
        initialValues: initialValues || defaultInitialValues,
        steps: [
            {
                name: t("forms:ubo.firstStep"),
                fieldNames: [
                    "firstName",
                    "lastName",
                    "addressLine1",
                    "postalCode",
                    "city",
                    "id",
                    "region",
                    "addressCountryId",
                    "addressCountry",
                    "declarationId"
                ],
                validation: validUboStep1(t),
                fields: (formik) => (
                    <>
                        <StyledInfoWrapper>
                            <InfoTitle className="primary">
                                {t("forms:ubo.primarySection")}
                            </InfoTitle>
                        </StyledInfoWrapper>
                        <InputElement
                            labelText={t("forms:ubo.firstName")}
                            id="firstName"
                            autoComplete="new-firstName"
                            name="firstName"
                            type="text"
                            wrapperVariant="flex-2"
                            {...formik}
                        />

                        <InputElement
                            labelText={t("forms:ubo.lastName")}
                            id="lastName"
                            autoComplete="new-lastName"
                            name="lastName"
                            type="text"
                            wrapperVariant="flex-2"
                            {...formik}
                        />

                        <StyledInfoWrapper>
                            <InfoTitle className="primary">

                                {t("forms:ubo.addressSection")}

                            </InfoTitle>
                        </StyledInfoWrapper>

                        <InputElement
                            labelText={t("forms:ubo.address")}
                            id="addressLine1"
                            autoComplete="new-addressLine1"
                            name="addressLine1"
                            type="text"
                            wrapperVariant="flex-3"
                            {...formik}
                        />

                        <InputElement
                            labelText={t("forms:ubo.city")}
                            id="city"
                            autoComplete="new-city"
                            name="city"
                            type="text"
                            wrapperVariant="flex-3"
                            {...formik}
                        />

                        <InputElement
                            labelText={t("forms:ubo.postalCode")}
                            id="postalCode"
                            autoComplete="new-postalCode"
                            name="postalCode"
                            type="text"
                            wrapperVariant="flex-3"
                            {...formik}
                        />

                        <InputElement
                            labelText={t("forms:ubo.region")}
                            id="region"
                            autoComplete="new-region"
                            name="region"
                            type="text"
                            wrapperVariant="flex-2"
                            {...formik}
                        />

                        <InputElement
                            labelText={t(
                                "forms:ubo.addressCountryId"
                            )}
                            id="addressCountryId"
                            name="addressCountry"
                            type="search"
                            searchFn={countryLookup}
                            itemSelected={(sel) => {
                                setTimeout(() => {
                                    formik.setFieldValue(
                                        "addressCountry",
                                        sel
                                    );
                                    formik.setFieldValue(
                                        "addressCountryId",
                                        getCountryId(sel)
                                    );
                                }, 250);
                            }}
                            wrapperVariant="flex-2"
                            {...formik}
                        />

                    </>
                ),
            },
            {
                name: t("forms:ubo.secondStep"),
                fieldNames: [
                    "nationalityId",
                    "nationality",
                    "birthday",
                    "birthplaceCity",
                    "birthplaceCountryId",
                    "birthplaceCountry",
                ],
                validation: validUboStep2(t),
                fields: (formik) => (
                    <>
                        <StyledInfoWrapper>
                            <InfoTitle className="primary">
                                {t("forms:ubo.primarySection")}
                            </InfoTitle>
                        </StyledInfoWrapper>
                        <InputElement
                            labelText={t("forms:ubo.nationality")}
                            id="nationalityId"
                            name="nationality"
                            type="search"
                            searchFn={nationalityLookup}
                            itemSelected={(sel) => {
                                setTimeout(() => {
                                    formik.setFieldValue(
                                        "nationality",
                                        sel
                                    );
                                    formik.setFieldValue(
                                        "nationalityId",
                                        getNationalId(sel)
                                    );
                                }, 250);

                            }}
                            wrapperVariant="flex-2"
                            {...formik}
                        />


                        <StyledInfoWrapper>
                            <InfoTitle className="primary">
                                {t("forms:ubo.address")}

                            </InfoTitle>
                        </StyledInfoWrapper>
                        <DatePicker
                            labelText={t("forms:ubo.birthday")}
                            id="birthday"
                            name="birthday"
                            filter="past"
                            wrapperVariant={"flex-2"}
                            formik={formik}
                            {...formik}
                        />
                        <Separator />

                        <InputElement
                            labelText={t("forms:ubo.birthPlaceCity")}
                            id="birthplaceCity"
                            autoComplete="new-birthPlaceCity"
                            name="birthplaceCity"
                            type="text"
                            wrapperVariant="flex-2"
                            {...formik}
                        />
                        <InputElement
                            labelText={t(
                                "forms:ubo.birthPlaceCountryId"
                            )}
                            id="birthplaceCountryId"
                            name="birthplaceCountry"
                            type="search"
                            searchFn={countryLookup}
                            itemSelected={(sel) => {
                                setTimeout(() => {
                                    formik.setFieldValue(
                                        "birthplaceCountryId",
                                        getCountryId(sel)
                                    );

                                    formik.setFieldValue(
                                        "birthplaceCountry",
                                        sel
                                    );

                                }, 250);
                            }}
                            wrapperVariant="flex-2"
                            {...formik}
                        />
                    </>
                ),
            },

            {
                name: t("forms:ubo.summaryStep"),
                fields: (formik) => (
                    <>
                        <InfoWrapper>
                            <RoundInfo
                                alt=""
                                src="/images/menu/green-info.svg"
                            />
                            <div>
                                <InfoTitle>
                                    {t("forms:ubo.info.title")}

                                </InfoTitle>
                                <InfoText>
                                    {t("forms:ubo.info.text")}
                                </InfoText>
                            </div>

                        </InfoWrapper>

                        <SummaryInfoWrapper>
                            <section>
                                <SectionSummaryTitleWrapper>
                                    <SummaryInfoTitle className="primary">
                                        {t("forms:ubo.primarySection")}
                                    </SummaryInfoTitle>

                                </SectionSummaryTitleWrapper>
                                <SectionSummaryFieldsWrapper>
                                    <WizardSummaryField
                                        label={t("forms:ubo.firstName")}
                                        value={formik.values?.firstName}
                                    />
                                    <WizardSummaryField
                                        label={t("forms:ubo.lastName")}
                                        value={formik.values?.lastName}
                                    />
                                </SectionSummaryFieldsWrapper>
                            </section>
                            <section>
                                <SectionSummaryTitleWrapper>
                                    <SummaryInfoTitle className="primary">
                                        {t("forms:ubo.nationality")}
                                    </SummaryInfoTitle>
                                </SectionSummaryTitleWrapper>
                                <WizardSummaryField
                                    label={t("forms:ubo.nationality")}
                                    value={formik.values?.nationality}
                                />
                            </section>

                        </SummaryInfoWrapper>
                        <SummaryInfoWrapper>
                            <section>
                                <SectionSummaryTitleWrapper>
                                    <SummaryInfoTitle className="primary">
                                        {t("forms:ubo.address")}
                                    </SummaryInfoTitle>
                                </SectionSummaryTitleWrapper>
                                <SectionSummaryFieldsWrapper>
                                    <WizardSummaryField
                                        label={t("forms:ubo.address")}
                                        value={formik.values?.addressLine1}
                                    />
                                    <WizardSummaryField
                                        label={t("forms:ubo.city")}
                                        value={formik.values?.city}
                                    />
                                    <WizardSummaryField
                                        label={t("forms:ubo.postalCode")}
                                        value={formik.values?.postalCode}
                                    />
                                    <WizardSummaryField
                                        label={t("forms:ubo.region")}
                                        value={formik.values?.region}
                                    />
                                    <WizardSummaryField
                                        label={t("forms:ubo.addressCountryId")}
                                        value={formik.values?.addressCountry}
                                    />
                                </SectionSummaryFieldsWrapper>
                            </section>
                            <section>
                                <SectionSummaryTitleWrapper>
                                    <SummaryInfoTitle className="primary">
                                        {t("forms:ubo.additionalInformation")}

                                    </SummaryInfoTitle>
                                </SectionSummaryTitleWrapper>
                                <WizardSummaryField
                                    label={t("forms:ubo.birthday")}
                                    value={helpers.toLocalDateIgnoreTime(formik.values?.birthday)}
                                />
                                <WizardSummaryField
                                    label={t("forms:ubo.birthPlaceCity")}
                                    value={formik.values?.birthplaceCity}
                                />
                                <WizardSummaryField
                                    label={t("forms:ubo.birthPlaceCountryId")}
                                    value={formik.values?.birthplaceCountry}
                                />

                            </section>

                        </SummaryInfoWrapper>
                    </>
                ),
            },
        ],
    };
};

export default UboDeclarationWizard;
