import {config} from "../Config/config.prod";
import api, {apiResult} from "../getAxios";

const MarketplaceService = {
    getBuyingItemById: async (id) => {
        return (await api.post(config.apiUrl.getOffers, {ids: [id]})).data
            .data[0][0];
    },
    getBuyingItems: async (params) => {
        return (await api.post(config.apiUrl.getAllItemsForSale, params)).data;
    },
    addBid: async (params) => {
        return apiResult(
            api.post(config.apiUrl.addBid, {
                offeredPriceGradeA: params.gradeA,
                offeredPriceGradeB: params.gradeB,
                offeredPriceGradeC: params.gradeC,
                offerIds: params.offerIds,
                administrationCosts: params.administrationCosts || 0,
                transportationCosts: params.transportationCosts || 0,
                disposalBid: params.disposalBid ? params.disposalBid : false,
                cleaningBid: params.cleaningBid ? params.cleaningBid : false,
            })
        );
    },
    getBids: async (offerId) => {
        return (await api.post(config.apiUrl.getBids, {offerId: offerId}))
            .data.data;
    },
    acceptBid: async (bidId, paymentMethod, cleaningServiceType=null) => {
        return apiResult(api.post(config.apiUrl.acceptBid, {bidId: bidId, paymentMethod: paymentMethod, cleaningServiceType:cleaningServiceType}));
    },
    revokeBid: async (bidId) => {
        return apiResult(api.post(config.apiUrl.revokeBid, {bidId: bidId}));
    },
    rejectBid: async (bidId, rejectionReason) => {
        return apiResult(api.post(config.apiUrl.rejectBid, { bidId, rejectionReason}));
    },
    payForBid: async (bidId) => {
        return apiResult(api.post(config.apiUrl.payForBid, {bidId: bidId}));
    },
    getOffersWithMyBids: async (params) => {
        return (await api.post(config.apiUrl.getOffersWithMyBids, params)).data;
    },
    getMyBids: async (params) => {
        return (await api.post(config.apiUrl.getMyBids, params)).data.data;
    },
    doQualityTest: async (offerIds) => {
        return (
            await api.post(config.apiUrl.qualityTest, {offerIds: offerIds})
        ).data.data;
    },
    shipToBuyer: async (tagIds) => {
        return apiResult(api.post(config.apiUrl.shipToBuyer, {tagIds}));
    },
    offerSold: async (offerIds) => {
        return apiResult(api.post(config.apiUrl.offerSold, {offerIds}));
    },
    offerSoldByBuyer: async (offerIds) => {
        return apiResult(
            api.post(config.apiUrl.offerSoldByBuyer, {offerIds})
        );
    },
    addBuyerAnalysis: async (data) => {
        return apiResult(api.post(config.apiUrl.addBuyerAnalysis, data));
    },
    rejectBidDueToExpiration: async (bidId) => {
        return apiResult(
            api.post(config.apiUrl.rejectBidDueToExpiration, bidId)
        );
    },
    dispute: async (params) => {
        return apiResult(api.post(config.apiUrl.dispute, params));
    },
    getDisputes: async (params) => {
        return apiResult(api.post(config.apiUrl.getDisputes, params));
    },
    getDisputeDetails: async (id) => {
        return apiResult(api.post(config.apiUrl.getDisputeDetails, {id}));
    },
    addDisputeSettle: async (values) => {
        return apiResult(api.post(config.apiUrl.addDisputeDetails, values));
    },
    finishDispute: async (values) => {
        return apiResult(api.post(config.apiUrl.finishDispute, values));
    },
    getOffersForCollection: async () => {
        return (await api.post(config.apiUrl.getOffersForCollection, {})).data
            .data;
    },
    getOffersForCollectionAsync: async () => {
        return apiResult(api.post(config.apiUrl.getOffersForCollection, {}));
    },
    payAClass: async (bidId, paymentMethod, isCleaning=false) => {
        return apiResult(api.post(config.apiUrl.payAClass, {bidId: bidId, paymentMethod: paymentMethod, isCleaning:isCleaning}));
    },
    getAnalysesZip: async (offerId) => {
        return apiResult(api.get(config.apiUrl.getAnalysesZip+"/"+offerId));
    },
    addReclaimedOffer: async (props) => {
        return apiResult(
            api.post(config.apiUrl.addReclaimOffer, props)
        );
    },
    getDistributorReclaimedOffers: async (props) => {
        return apiResult(
            api.post(config.apiUrl.getDistributorReclaimedOffers, props)
        );
    },
    getInstallationCompanyReclaimedOffers: async (props) => {
        return apiResult(
            api.post(config.apiUrl.getInstallationCompanyReclaimedOffers, props)
        );
    },
    unpublishReclaimedOffer: async (offerIds) => {
        return apiResult(
            api.post(config.apiUrl.unpublishReclaimedOffer, offerIds)
        );
    },
    getReclaimedOfferById: async (offerId) => {
        return apiResult(
            api.post(config.apiUrl.getReclaimedOfferById, offerId)
        );
    },
    acceptReclaimOffer: async (offerId, paymentMethod) => {
        return apiResult(
            api.post(config.apiUrl.acceptReclaimOffer, {offerIds:[offerId], paymentMethod})
        );
    },
    cancelReclaimedOffer: async (offerId) => {
        return apiResult(
            api.post(config.apiUrl.cancelReclaimedOffer, {offerIds:[offerId]})
        );
    },
};

export default MarketplaceService;
