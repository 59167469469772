import React from "react";
import { useTranslation } from "react-i18next";
import SidebarField from "../../../../components/elements/sidebar/SidebarField";
import AccordionWrapper from "../../../../components/toggle-view/AccordionWrapper";
import helpers from "../../../../core/helpers";
import { HR } from "../../../pwa/components/StyledElements";
const UboDetailSideBar = ({ ubo }) => {
    const { t } = useTranslation("sidebar");

    return (
        <>
            {ubo && (
                <>
                    <AccordionWrapper title={`${t("label.ubo.uboOwner")} ${ubo.order}`} isOpen={true}>
                        <SidebarField
                            label={t("label.ubo.firstName")}
                            value={ubo.firstName}
                            valueVariant="pr-20"
                        />
                        <SidebarField
                            label={t("label.ubo.lastName")}
                            value={ubo.lastName}
                            valueVariant="pr-20"
                        />
                        <HR className="section" />
                        <SidebarField
                            label={t("label.ubo.address")}
                            value={ubo.addressLine1}
                            valueVariant="pr-20"
                        />
                        <SidebarField
                            label={t("label.ubo.city")}
                            value={ubo.city}
                            valueVariant="pr-20"
                        />
                        <SidebarField
                            label={t("label.ubo.postalCode")}
                            value={ubo.postalCode}
                            valueVariant="pr-20"
                        />
                        <SidebarField
                            label={t("label.ubo.region")}
                            value={ubo.region}
                            valueVariant="pr-20"
                        />
                        <SidebarField
                            label={t("label.ubo.addressCountryId")}
                            value={ubo.addressCountry}
                            valueVariant="pr-20"
                        />
                        <HR className="section" />
                        <SidebarField
                            label={t("label.ubo.nationality")}
                            value={ubo.nationality}
                            valueVariant="pr-20"
                        />
                        <HR className="section" />
                        <SidebarField
                            label={t("label.ubo.birthday")}
                            value={helpers.toLocalDateIgnoreTime(ubo.birthday)}
                            valueVariant="pr-20"
                        />
                        <SidebarField
                            label={t("label.ubo.birthPlaceCity")}
                            value={ubo.birthplaceCity}
                            valueVariant="pr-20"
                        />
                        <SidebarField
                            label={t("label.ubo.birthPlaceCountryId")}
                            value={ubo.birthplaceCountry}
                            valueVariant="pr-20"
                        />
                    </AccordionWrapper>
                </>
            )}
        </>
    );
};

export default UboDetailSideBar;
