import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
    useRef,
} from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import DataCellImage from "../../../../components/data/DataCellImage";
import ListingPage from "../../../../components/data/ListingPage";
import Flex from "../../../../components/layout/flex/Flex";
import HamburgerMenu from "../../../../components/menu/HamburgerMenu";
import HamburgerMenuAction from "../../../../components/menu/HamburgerMenuAction";
import ModalDialog, {
    useModal,
} from "../../../../components/modal/ModalDialog";
import ModalWizard from "../../../../components/modal/ModalWizard";
import AuthorizeComponent from "../../../../core/AuthorizeComponent";
import Roles from "../../../../core/enums/Roles";
import RouteCreator from "../../../../core/RouteCreator";
import employeeListStateModule from "../../../../core/state/company/employeeListStateModule";
import useBreadcrumbs from "../../../../core/state/navigation/useBreadcrumbs";
import useStateModule from "../../../../core/state/useStateModule";
import useDataReducer from "../../../../hooks/_shared/useDataReducer";
import useImmerReducer from "../../../../hooks/_shared/useImmerReducer";
import usePageChange from "../../../../hooks/_shared/usePageChange";
import CompanyService from "../../../../services/Company/CompanyService";
import UserService from "../../../../services/User/UserService";
import StatusCell from "../../../Administration/views/company/components/StatusCell";
import CertificateStatusCell from "../../../Administration/views/user/components/CertificateStatusCell";
import actionTypes from "../../../Administration/views/user/components/filterActionsTypes";
import RoleCell from "../../../Administration/views/user/components/RoleCell";
import UserFilter from "../../../Administration/views/user/components/UserFilter";
import EmployeeDetailSideBar from "./components/EmployeeDetailSideBar";
import NewEmployeeWizard from "./components/wizard/NewEmployeeWizard";
import VSpace from "../../../../components/elements/general/vspace";
import ActionButton from "../../../../components/elements/buttons/ActionButton";
import { Helmet } from "react-helmet";
import styled from "styled-components";

const LongTextCell = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

const filterReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.SEARCH_EMAIL:
            state.filter.email = action.payload;
            break;
        case actionTypes.SEARCH_FULLNAME:
            state.filter.fullName = action.payload;
            break;
        case actionTypes.SEARCH_ROLE:
            if (action.payload) {
                state.filter.roleNames = [action.payload];
            } else {
                state.filter.roleNames = [];
            }
            break;
        case actionTypes.SEARCH_STATUS:
            if (action.payload === "-1") {
                delete state.filter.userStatus;
            } else state.filter.userStatus = action.payload === "0";
            break;
        case actionTypes.SET_PAGING:
            state.page = action.payload.pageIndex + 1;
            state.pageSize = action.payload.pageSize;
            break;
        default:
            break;
    }
};

const EmployeeList = () => {
    const { t } = useTranslation("screens");
    const {
        employees,
        isLoading,
        error,
        loadEmployees,
        loadEmployeeById,
        details,
        deleteEmployee,
        loadRoles,
        rolesData,
    } = useStateModule(employeeListStateModule);
    const userDetails = UserService.getUserDetailsFromToken();
    const [filterState, dispatch] = useImmerReducer(filterReducer, {
        filter: {
            roleNames: [],
        },
        page: 1,
        pageSize: 10,
    });

    const [detailViewIsOpened, setDetailViewIsOpened] = useState(false);
    const deleteThisEmployee = useRef(null);
    const history = useHistory();

    useBreadcrumbs([
        {
            text: t("navigation:company"),
        },
        {
            text: t("navigation:employees"),
        },
    ]);
    const [, setEmployeeEnabled] = useDataReducer(
        UserService.setEmployeeEnabled
    );
    const columns = useMemo(
        () => [
            {
                Header: "Profile picture url",
                accessor: "profilePictureUrl",
                Cell: ({ cell: { value, row } }) => (
                    <DataCellImage
                        image={value}
                        fullName={row.original.fullName}
                        width="76px"
                    />
                ),
            },
            {
                Header: t("employeeList.headers.fullName"),
                accessor: "fullName",
                Cell: ({ cell: { value, row } }) => (
                    <LongTextCell>{value}</LongTextCell>
                ),
            },
            {
                Header: t("employeeList.headers.email"),
                accessor: "email",
                Cell: ({ cell: { value, row } }) => (
                    <LongTextCell>{value}</LongTextCell>
                ),
            },
            {
                Header: t("employeeList.headers.certificateStatus"),
                accessor: "certificationStatus",
                Cell: CertificateStatusCell,
            },
            {
                Header: t("employeeList.headers.roles"),
                accessor: "roleNames",
                Cell: RoleCell,
            },
            {
                Header: t("employeeList.headers.userStatus"),
                accessor: "userStatus",
                Cell: StatusCell,
            },
            {
                Header: "",
                accessor: "id",
                Cell: ({ cell: { value, row } }) => (
                    <AuthorizeComponent roles={[Roles.InstallerCompanyAdmin]}>
                        <HamburgerMenu>
                            {userDetails.UserId !== value && (
                                <HamburgerMenuAction
                                    text="Delete"
                                    onClick={() => {
                                        deleteThisEmployee.current = [
                                            row.original,
                                        ];
                                        toggleOpen(value);
                                    }}
                                />
                            )}

                            <HamburgerMenuAction
                                text={t("actions:edit")}
                                onClick={() => {
                                    history.push(
                                        RouteCreator.employee.edit(value)
                                    );
                                }}
                            />
                            {row.original.enabled && (
                                <HamburgerMenuAction
                                    text={t("actions:disable")}
                                    onClick={async () => {
                                        await setEmployeeEnabled({
                                            id: value,
                                            enabled: false,
                                        });
                                        loadEmployees(filterState);
                                    }}
                                />
                            )}
                            {!row.original.enabled && (
                                <HamburgerMenuAction
                                    text={t("actions:enable")}
                                    onClick={async () => {
                                        await setEmployeeEnabled({
                                            id: value,
                                            enabled: true,
                                        });
                                        loadEmployees(filterState);
                                    }}
                                />
                            )}
                        </HamburgerMenu>
                    </AuthorizeComponent>
                ),
            },
        ],
        [t]
    );
    useEffect(() => {
        loadEmployees(filterState);
    }, [loadEmployees, filterState]);

    useEffect(() => {
        loadRoles();
    }, [loadRoles]);

    const onSelect = useCallback((row) => {
        const selectRow = async (row) => {
            await loadEmployeeById(row.original.id);
            setDetailViewIsOpened(true);
        };
        selectRow(row);
    }, []);

    const closeSideBar = () => {
        setDetailViewIsOpened(false);
    };

    const cardColumns = [
        "profilePictureUrl",
        "fullName",
        "userStatus",
        "id",
        "email",
        "roleNames",
    ];

    const pageChange = usePageChange(dispatch, filterState);

    const { modalProps, toggleOpen } = useModal({
        title: t("actions:dialogs.deleteEmployee.title"),
        onConfirm: async (employee) => {
            await deleteEmployee(employee);
            loadEmployees(filterState);
            toggleOpen();
            toast.success(t("employeeList.toastNotification.success"));
        },
        onCancel: () => {
            toggleOpen();
        },
        confirmText: t("actions:dialogs.deleteEmployee.confirmText"),
        type: "danger",
    });

    return (
        <>
            <Helmet>
                <title>{t("seo:employeesList.title")}</title>
            </Helmet>
            <VSpace size={15} />
            <ListingPage
                wide
                employees
                columns={columns}
                tableState={{
                    data: employees.data,
                    loading: isLoading,
                    error: error,
                }}
                mode="cards"
                filterTitle={t("filters:employees.filterTitle")}
                filterComponent={() => (
                    <UserFilter
                        showStatusFilter={false}
                        filterState={filterState}
                        roles={[
                            {
                                key: Roles.InstallerCompanyAdmin,
                                value: Roles.InstallerCompanyAdmin,
                                label: t("common:roles.MasterAdmin"),
                            },
                            {
                                key: Roles.InstallerCompanyUser,
                                value: Roles.InstallerCompanyUser,
                                label: t("common:roles.InstallerCompanyUser"),
                            },
                            {
                                key: Roles.InstallerCompanyTechnician,
                                value: Roles.InstallerCompanyTechnician,
                                label: t("common:roles.InstallerCompanyTechnician"),
                            },
                        ]}
                        dispatch={dispatch}
                    />
                )}
                paging={{
                    enablePaging: true,
                    pageCount: employees.totalPages,
                    pageSize: employees.pageSize || 10,
                    onPageChange: pageChange,
                    totalRows: employees.totalRows,
                    currentPageIndex: employees.currentPage
                        ? employees.currentPage - 1
                        : 0,
                }}
                actions={{
                    headerActions: () => {
                        return (
                            <AuthorizeComponent
                                roles={[Roles.InstallerCompanyAdmin]}
                            >
                                <Flex.Container justifyContent="flex-end">
                                    <ActionButton
                                        className="primary"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            ModalWizard.show(
                                                NewEmployeeWizard(t, rolesData),
                                                {
                                                    onSubmit: async (
                                                        values
                                                    ) => {
                                                        const result = await CompanyService.createCompanyEmployeeAsync(
                                                            ModalWizard.asFormData(
                                                                values
                                                            )
                                                        );
                                                        if (
                                                            result &&
                                                            result.error
                                                        ) {
                                                            toast.error(
                                                                t(
                                                                    result.error
                                                                        .message
                                                                )
                                                            );
                                                        } else {
                                                            toast.success(
                                                                t(
                                                                    "actions:dialogs.createEmployee.toastNotification.success"
                                                                )
                                                            );
                                                            ModalWizard.hide();
                                                            loadEmployees(
                                                                filterState
                                                            );
                                                        }
                                                    },
                                                    onCancel: async () => {
                                                        ModalWizard.hidePrompt();
                                                    },
                                                },
                                                {
                                                    style: {
                                                        minHeight: "700px",
                                                    },
                                                }
                                            );
                                        }}
                                    >
                                        {t(
                                            "screens:employeeCreate.button_text"
                                        )}
                                    </ActionButton>
                                </Flex.Container>
                            </AuthorizeComponent>
                        );
                    },
                }}
                onSelect={onSelect}
                closeSideBar={closeSideBar}
                detailViewIsOpened={detailViewIsOpened}
                detailView={<EmployeeDetailSideBar employee={details} />}
                detailSideBarTitle={details && details.employeeData.fullName}
                cardColumns={cardColumns}
                variants={{
                    headerViewVariant: "right-action",
                }}
            />
            <ModalDialog {...modalProps}>
                <p>{t("actions:dialogs.deleteEmployee.text")}</p>
                <p style={{ marginLeft: "10px" }}>
                    •{" "}
                    {deleteThisEmployee.current &&
                    deleteThisEmployee.current.length > 0
                        ? `${deleteThisEmployee.current[0].fullName} | ${deleteThisEmployee.current[0].email}`
                        : null}
                </p>
            </ModalDialog>
        </>
    );
};

export default EmployeeList;
