import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as ResetSvg } from "../../../assets/close.svg";
import { Input } from "./index";

const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
    window.HTMLInputElement.prototype,
    "value"
).set;

const StyledReset = styled(ResetSvg)`
    position: absolute;
    left: 12px;
    top: 10px;
    width: 12px;
    height: 12px;
    transition: all 300ms ease;
    z-index: 2;

    &.clearable {
        top: 17px;
        width: 12px;
        height: 12px;
    }

    &.filters.big {
        top: 17px;
    }

    &:hover {
        cursor: pointer;
    }

    &:hover .lines {
        stroke: #e94e4e;
    }
`;

const ClearableInput = (props) => {
    let { enableSpinButton, value, defaultValue, ...rest } = props;
    if (defaultValue === undefined) defaultValue = "";
    const inputEl = useRef();
    const [inputVal, setInputVal] = useState(defaultValue);
    const [inputChanged, setInputChanged] = useState(0);

    useEffect(() => {
        setInputVal(defaultValue);
        nativeInputValueSetter.call(inputEl.current, defaultValue);
    }, [defaultValue]);

    const handleReset = (ev) => {
        nativeInputValueSetter.call(inputEl.current, "");
        setInputVal("");
        setInputChanged(inputChanged + 1);
        let inpEvt = new Event("input", { bubbles: true });
        inputEl.current.dispatchEvent(inpEvt);
    };

    const handleChange = (e) => {
        props.onChange && props.onChange(e);
        setInputVal(inputEl.current.value);
        //setInputChanged(inputChanged + 1);
    };

    const hasValue = inputEl.current && inputEl.current.value !== "";

    return (
        <div
            style={{
                position: "relative",
                padding: "0",
                width: props.width || "auto",
            }}
        >
            {hasValue && (
                <StyledReset
                    className={!props.className ? "clearable" : props.className}
                    onClick={handleReset}
                />
            )}
            <Input
                className="clearable"
                enableSpinButton={enableSpinButton}
                ref={inputEl}
                defaultValue={inputVal}
                {...rest}
                onChange={handleChange}
                type={props.type ? props.type : "text"}
            />
        </div>
    );
};

export default ClearableInput;
