import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";

export const getDateFormat = (currentLanguage) =>
    Intl.DateTimeFormat(currentLanguage, {
        day: "numeric",
        month: "numeric",
        year: "numeric",
    });

export const getTimeFormat = (currentLanguage) =>
    Intl.DateTimeFormat(currentLanguage, {
        hour: "numeric",
        minute: "numeric",
    });

export const getDateTimeFormat = (currentLanguage) =>
    Intl.DateTimeFormat(currentLanguage, {
        day: "numeric",
        month: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: false,
    });

export const formatDateForExportDocument = () => {
    const date = new Date();
    const day = date?.getDay();
    const formattedDay = day > 9 ? day.toString() : `0${day}`;
    return `${date?.getFullYear()}_${(
        date?.getMonth() + 1
    ).toString()}_${formattedDay}_${date?.getHours()}_${date?.getMinutes()}`;
};

const isValidDate = (dateValue) => {
    const year = dateValue.getFullYear();
    return (year > 1900) & (year < 2500);
};

const DateCell = ({ cell: { value } }) => {
    const { t } = useTranslation("common");
    const currentLanguage = "sk"; //i18next.language || "en";

    if (value) {
        const dateValue = new Date(value);

        let date = getDateFormat(currentLanguage).format(dateValue);
        const time = getTimeFormat(currentLanguage).format(dateValue);

        const title = isValidDate(dateValue)
            ? `${t("dateTime.at")} ${time}`
            : "";
        date = isValidDate(dateValue) ? date : "-";
        return <span title={title}>{date}</span>;
    }
    return null;
};

const ShortDateCell = ({ cell: { value } }) => {
    const currentLanguage = "sk"; //i18next.language || "en";

    if (value) {
        const dateValue = new Date(value);
        const date = getDateFormat(currentLanguage).format(dateValue);
        const time = getTimeFormat(currentLanguage).format(dateValue);
        return <span title={time}>{`${date}`}</span>;
    }
    return null;
};

const DateTimeCell = ({ cell: { value } }) => {
    const currentLanguage = "sk"; //i18next.language || "en";

    if (value) {
        const dateValue = new Date(value);
        const datetime = getDateTimeFormat(currentLanguage).format(dateValue);
        return <span>{datetime}</span>;
    }
    return null;
};

const GetShortDate = (dateValue) => {
    if (dateValue && typeof dateValue === "string")
        dateValue = new Date(dateValue);
    const currentLanguage = "sk"; //i18next.language || "en";
    return getDateFormat(currentLanguage).format(dateValue);
};

export default DateCell;
export { ShortDateCell };
export { DateTimeCell };
export { GetShortDate };
