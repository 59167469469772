import React from "react";
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import userStateModule from '../../../core/state/user/userStateModule';
import useStateModule from '../../../core/state/useStateModule';
import Button from "./Button";

const StyledLogoutButton = styled(Button)`
    font-size: 0.875rem;
    border-radius: ${props => props.theme.buttonsBorderRadius};
    border: 0;
    cursor: pointer;

    &.cancel {
        background-color: white;
        color: black;
        transition: background-color 0.3s ease-out;

        &:enabled:hover {
            background-color: ${props => props.theme.variantCancelBgHover};
        }
    }

    &.forms {
        background: ${props => props.theme.secondaryColor};
        border: 1px solid ${props => props.theme.secondaryColorBorder || 'yellow'};
        color: ${props => props.theme.secondaryColorText};
        font-size: 1rem;
        padding: 10px 15px;
        margin-right: 5px;
    }

    &.list {
        display: inline;
        padding: 0;
        background: transparent;
        font-size: initial;
        min-width: auto;
        box-shadow: none;
        height: auto;
        line-height: initial;
        width:100%;
        text-align:left;
    }

    &:hover {
        color: ${props => props.theme.errorColor}
    }

`;
const LogoutButton = (props) => {
    const {logout} = useStateModule(userStateModule);
    const {t} = useTranslation();

    return (
        <StyledLogoutButton onClick={() => logout()} className={props.variant}>
           {t("header:header.logOut")}
        </StyledLogoutButton>
    )
};


export default LogoutButton;
