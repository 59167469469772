import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ListingPage from "../../../components/data/ListingPage";
import useBreadcrumbs from "../../../core/state/navigation/useBreadcrumbs";
import stockListingStateModule from "../../../core/state/stock/stockListingStateModule";
import useListingPageContext from "../../../core/state/useListingPage";
import useStateModule from "../../../core/state/useStateModule";
import useImmerReducer from "../../../hooks/_shared/useImmerReducer";
import usePageChange from "../../../hooks/_shared/usePageChange";
import StockService from "../../../services/Stock/StockService";
import DateCell from "../../../components/data/DateCell";
import CleaningDetailsSidebar from "./components/CleaningDetailsSidebar";
import CleaningFilter from "./components/CleaningFilter";
import { Helmet } from "react-helmet";
import GreenCell from "../../../components/data/GreenCell";
import useSharedState from "../../../hooks/_shared/useSharedState";
import RouteCreator from "../../../core/RouteCreator";
import TabLinks from "../../../components/elements/tabs/TabLinks";
import HamburgerMenu from "../../../components/menu/HamburgerMenu";
import HamburgerMenuAction from "../../../components/menu/HamburgerMenuAction";
import AuthorizeComponent from "../../../core/AuthorizeComponent";
import Roles from "../../../core/enums/Roles";
import ModalWizard from "../../../components/modal/ModalWizard";
import WizardPlaceCleaningBid from "./components/wizard/WizardPlaceCleaningBid";
import MarketplaceService from "../../../services/BuyRefrigerant/MarketplaceService";
import { toast } from "react-toastify";
import UserService from "../../../services/User/UserService";

const actionTypes = {
    SET_GAS_TYPE: "SET_GAS_TYPE",
    RESET_GAS_TYPE: "RESET_GAS_TYPE",
    SET_GAS_QUANTITY_TO: "SET_GAS_QUANTITY",
    RESET_GAS_QUANTITY_TO: "RESET_GAS_QUANTITY",
    SET_GAS_QUANTITY_FROM: "SET_GAS_QUANTITY_FROM",
    RESET_GAS_QUANTITY_FROM: "RESET_GAS_QUANTITY_FROM",
    SET_QUALITY_ASSURANCE: "SET_QUALITY_ASSURANCE",
    RESET_QUALITY_ASSURANCE: "RESET_QUALITY_ASSURANCE",
    SET_OFFER_CREATION__DATE_FROM: "SET_OFFER_CREATION__DATE_FROM",
    RESET_OFFER_CREATION__DATE_FROM: "RESET_OFFER_CREATION__DATE_FROM",
    SET_OFFER_CREATION__DATE_TO: "SET_OFFER_CREATION__DATE_TO",
    RESET_OFFER_CREATION__DATE_TO: "RESET_OFFER_CREATION__DATE_TO",
    SET_PAGING: "SET_PAGING",
    SET_SORT: "SET_SORT",
    RESET_SORT: "RESET_SORT",
    SET_HIDEWITHBIDS: "SET_HIDEWITHBIDS",
    RESET_HIDEWITHBIDS: "RESET_HIDEWITHBIDS",
};

const tabItems = (t, isAdmin = false) => {
    let items = [
        {
            name: t("navigation:marketplace"),
            path: RouteCreator.services.cleaning(),
        },
    ];
    if (!isAdmin)
        items.push({
            name: t("navigation:myBids"),
            path: RouteCreator.services.cleaningBids(),
        });
    return items;
};

const filterReducer = (state, action) => {
    if (action.type !== actionTypes.SET_PAGING) state.page = 1;
    switch (action.type) {
        case actionTypes.SET_GAS_TYPE:
            state.filter.gasType = action.payload;
            break;
        case actionTypes.RESET_GAS_TYPE:
            delete state.filter.gasType;
            break;
        case actionTypes.SET_GAS_QUANTITY_FROM:
            state.filter.gasQuantityFrom = Number(action.payload);
            break;
        case actionTypes.RESET_GAS_QUANTITY_FROM:
            delete state.filter.gasQuantityFrom;
            break;
        case actionTypes.SET_GAS_QUANTITY_TO:
            state.filter.gasQuantityTo = Number(action.payload);
            break;
        case actionTypes.RESET_GAS_QUANTITY_TO:
            delete state.filter.gasQuantityTo;
            break;

        case actionTypes.SET_OFFER_CREATION__DATE_FROM:
            state.filter.offerCreationDateTimeFrom = action.payload;
            break;
        case actionTypes.RESET_OFFER_CREATION__DATE_FROM:
            delete state.filter.offerCreationDateTimeFrom;
            break;

        case actionTypes.SET_OFFER_CREATION__DATE_TO:
            state.filter.offerCreationDateTimeTo = action.payload;
            break;
        case actionTypes.RESET_OFFER_CREATION__DATE_TO:
            delete state.filter.offerCreationDateTimeTo;
            break;

        case actionTypes.SET_QUALITY_ASSURANCE:
            state.filter.hasAnalysis = action.payload === "true";
            break;
        case actionTypes.RESET_QUALITY_ASSURANCE:
            delete state.filter.hasAnalysis;
            break;
        case actionTypes.SET_PAGING:
            state.pageSize = action.payload.pageSize;
            state.page = action.payload.pageIndex + 1;
            break;
        case actionTypes.SET_SORT:
            state.orderColumn = action.payload[0].id;
            state.descending = action.payload[0].desc;
            break;
        case actionTypes.RESET_SORT:
            state.orderColumn = "";
            state.descending = true;
            break;
        case actionTypes.SET_HIDEWITHBIDS:
            state.filter.hideOffersWithMyBid = action.payload;
            break;
        case actionTypes.RESET_HIDEWITHBIDS:
            delete state.filter.hideOffersWithMyBid;
            break;
        default:
            return state;
    }
};

const initialValues = {
    gradeA: "0.00",
    gradeB: "0.00",
    gradeC: "0.00",
    transportationCosts: 0,
    administrationCosts: 0,
    chargeSeller: false,
};

const CleaningMarketplace = () => {
    const [cleaningItems, setCleaningItems] = useState({});
    const [globalState] = useSharedState("global");
    const isDistributor = globalState.isDistributor;
    const { isLoading, error, loadGasTypes, gasTypesData } = useStateModule(
        stockListingStateModule
    );

    const { CompanyId } = useMemo(
        () => UserService.getUserDetailsFromToken(),
        []
    );
    const [openAccordionId, setOpenAccordionId] = useState();
    const [detailViewKey, setDetailViewKey] = useState(null);
    const [currentOfferTranslations, setCurrentOfferTranslations] =
        useState("");

    const { state: listingPageState, actions: listingPageActions } =
        useListingPageContext();

    useEffect(() => {
        return listingPageActions.resetState();
    }, []);

    const [filterState, dispatch] = useImmerReducer(filterReducer, {
        filter: {
            hideOffersWithMyBid: true,
        },
        page: 1,
        pageSize: 10,
        orderColumn: "refrigerantGasTypeCode",
        descending: true,
    });
    const pageChange = usePageChange(dispatch, filterState);

    const { t } = useTranslation("screens");

    useBreadcrumbs([
        {
            text: t("navigation:services"),
        },
        {
            text: t("navigation:cleaningService"),
        },
    ]);

    useEffect(() => {
        loadGasTypes();
    }, [loadGasTypes]);

    const fetchCleaningItems = async () => {
        const res = await StockService.getCleaningListing({
            isInstaller: !isDistributor & !globalState.isAdmin,
            options: filterState,
        });
        setCleaningItems(res.data);
    };

    useEffect(() => {
        fetchCleaningItems();
    }, [filterState]);

    const [currentStockSkuId] = useState({});

    const openPlaceBid = (offer) => {
        return ModalWizard.show(
            WizardPlaceCleaningBid(t, initialValues, [offer], false, globalState.enableHandlingCosts),
            {
                onSubmit: async (values) => {
                    if (!values.chargeSeller) {
                        values.administrationCosts = 0;
                        values.transportationCosts = 0;
                    }
                    const result = await MarketplaceService.addBid({
                        gradeA: values.gradeA,
                        gradeB: values.gradeB,
                        gradeC: values.gradeC,
                        offerIds: [offer.offerId],
                        cleaningBid: true,
                        administrationCosts: values.administrationCosts,
                        transportationCosts: values.transportationCosts,
                    });

                    if (result && result.error)
                        toast.error(t(result.error.message));
                    else {
                        await fetchCleaningItems();
                        toast.success(
                            t("marketplace.toastNotification.bidPlaced")
                        );
                        closeSideBar();
                        ModalWizard.hide();
                    }
                },
                onCancel: async () => {
                    ModalWizard.hidePrompt(setDetailViewIsOpened(false));
                },
            }
        );
    };

    const columns = useMemo(
        () => [
            {
                Header: t("table:headers.gasType"),
                accessor: "refrigerantGasTypeCode",
                Cell: GreenCell,
            },
            {
                Header: t("table:headers.gasQuantity"),
                accessor: "quantity",
                Cell: ({ cell: { value } }) =>
                    GreenCell({ cell: { value: value.toFixed(2) + " kg" } }),
            },
            {
                Header: t("table:headers.grade"),
                accessor: "pressumedGradeName",
            },
            {
                Header: t("table:headers.evaluation"),
                accessor: "analysisDone",
                Cell: ({ cell: { value } }) => {
                    return value
                        ? t("filters:analysis.measurement")
                        : t("filters:analysis.selfAssessment");
                },
            },
            {
                Header: t("table:headers.owner"),
                accessor: "offerer",
            },
            {
                Header: t("table:headers.published"),

                accessor: "offerCreationDateTime",
                Cell: (params) => {
                    return DateCell(params);
                },
            },
            {
                Header: "",
                accessor: "offerId",
                Cell: ({
                    cell: {
                        row: { original },
                        value,
                    },
                }) => {
                    return (
                        !original.bidAlreadyPlaced && (
                            <AuthorizeComponent
                                roles={[
                                    Roles.InstallerCompanyAdmin,
                                    Roles.InstallerCompanyUser,
                                ]}
                            >
                                <HamburgerMenu>
                                    {!original.bidAlreadyPlaced && (
                                        <HamburgerMenuAction
                                            disabled={
                                                !globalState.hasValidCompanyCertificate ||
                                                !globalState.hasCertificateRights
                                            }
                                            text={t(
                                                "actions:bidActions.bidForOffer"
                                            )}
                                            onClick={async () => {
                                                setCurrentOfferTranslations(
                                                    `${original.refrigerantGasTypeCode} - ${original.quantity}kg`
                                                );
                                                openPlaceBid(original);
                                            }}
                                        />
                                    )}
                                </HamburgerMenu>
                            </AuthorizeComponent>
                        )
                    );
                },
                disableSortBy: true,
            },
        ],
        [currentStockSkuId, t, globalState]
    );

    const [detailViewIsOpened, setDetailViewIsOpened] = useState(false);

    const [selectedOffer, setSelectedOffer] = useState(null);
    const [details, setDetails] = useState(null);

    const onSelect = useCallback((row) => {
        const selectRow = async (row) => {
            //await loadBuyingItemsById(row.original.offerId);
            const res = await MarketplaceService.getBuyingItemById(
                row.original.offerId
            );
            setDetailViewKey(row.original.offerId);
            setDetails(res);
            setSelectedOffer(row.original);
            setDetailViewIsOpened(true);
        };
        selectRow(row);
    }, []);

    const closeSideBar = useCallback(() => {
        setOpenAccordionId(null);
        setDetailViewKey(null);
        setDetailViewIsOpened(false);
    }, []);

    const gasSelectionIsValid = (rows) => {
        if (rows.length > 0) {
            const firstRow = rows[0].original;

            const shouldAllow = rows.every((row) => {
                return (
                    row.original.refrigerantGasTypeCode ===
                        firstRow.refrigerantGasTypeCode &&
                    row.original.pressumedGradeValue ===
                        firstRow.pressumedGradeValue &&
                    row.original.analysisDone === firstRow.analysisDone
                );
            });

            return shouldAllow;
        }
        return true;
    };

    const sortChange = (sortBy) => {
        sortBy.length > 0
            ? dispatch({ type: actionTypes.SET_SORT, payload: sortBy })
            : dispatch({ type: actionTypes.RESET_SORT });
    };

    const currentSortBy = useMemo(() => {
        return [{ id: filterState.orderColumn, desc: filterState.descending }];
    }, [filterState.orderColumn, filterState.descending]);

    if (!cleaningItems) return null;

    return (
        <>
            <Helmet>
                <title>{t("seo:cleaningService.title")}</title>
            </Helmet>
            <TabLinks
                variant="shadowed"
                items={tabItems(t, globalState.isAdmin)}
                idx={0}
                style={{ marginTop: "0px", padding: "10px 0px 10px 40px" }}
            />
            <ListingPage
                columns={columns}
                tableState={{
                    data: cleaningItems.data || [],
                    loading: isLoading,
                    error: error,
                }}
                paging={{
                    enablePaging: true,
                    pageCount: cleaningItems.totalPages,
                    pageSize: cleaningItems.pageSize,
                    onPageChange: pageChange,
                    currentPageIndex: cleaningItems.currentPage - 1,
                }}
                sorting={{
                    enableSorting: true,
                    onSortByChange: sortChange,
                    currentSortBy: currentSortBy,
                }}
                onSelect={onSelect}
                closeSideBar={closeSideBar}
                getRowId={(row) => {
                    return row.skuId;
                }}
                detailViewKey={detailViewKey}
                detailView={
                    <CleaningDetailsSidebar
                        offer={selectedOffer}
                        details={details}
                        companyId={CompanyId}
                        bidAlreadyPlaced={
                            selectedOffer && selectedOffer.bidAlreadyPlaced
                        }
                        toggleBidModal={() => openPlaceBid(selectedOffer)}
                    />
                }
                filterTitle={t("filters:cleaning.filterTitle")}
                showTitle={true}
                filterComponent={() => (
                    <CleaningFilter
                        filterState={filterState.filter}
                        dispatch={dispatch}
                        actionTypes={actionTypes}
                        gasTypes={gasTypesData.gasTypes}
                    />
                )}
                detailViewIsOpened={detailViewIsOpened}
                variants={{
                    headerViewVariant: "right-action",
                }}
                noDataText={t("screens:stockListing.notification")}
                detailSideBarTitle={`${t("sidebar:title.offerId")}: ${
                    details?.offerNumber
                }`}
            />
        </>
    );
};

export default CleaningMarketplace;
