import React from "react";
import { useTranslation } from "react-i18next";
import ClearableInput from "../../../../../components/elements/form-elements/ClearableInput";
import Select from "../../../../../components/elements/form-elements/Select";
import Filters from "../../../../../components/filter/Filters";
import AuthorizeComponent from "../../../../../core/AuthorizeComponent";
import AnalysisStatus from "../../../../../core/enums/AnalysisStatus";
import GradeStatus from "../../../../../core/enums/GradeStatus";
import Roles from "../../../../../core/enums/Roles";
import useDebouncedCallback from "../../../../../hooks/_shared/useDebouncedCallback";
import { DateTimePicker } from "../../../../../components/elements/form-elements/DatePicker";
import helpers from "../../../../../core/helpers";


const InstallerStockFilter = ({
    filterState,
    dispatch,
    users,
    actionTypes,
    gasTypes,
}) => {
    const { t } = useTranslation("filters");

    const [quantityToChanged] = useDebouncedCallback((text) => {
        text === ""
            ? dispatch({
                  type: actionTypes.RESET_GAS_QUANTITY_TO,
                  payload: text,
              })
            : dispatch({
                  type: actionTypes.SET_GAS_QUANTITY_TO,
                  payload: text.trim(),
              });
    });

    const [quantityFromChanged] = useDebouncedCallback((text) => {
        text === ""
            ? dispatch({
                  type: actionTypes.RESET_GAS_QUANTITY_FROM,
                  payload: text,
              })
            : dispatch({
                  type: actionTypes.SET_GAS_QUANTITY_FROM,
                  payload: text.trim(),
              });
    });

    return (
        <>
            <Filters.Wrapper className="filter-wrapper">
                <AuthorizeComponent roles={[Roles.InstallerCompanyAdmin,  Roles.InstallerCompanyUser]}>
                    <Filters.Item>
                        <Select
                            key="user"
                            name="user"
                            errors={[]}
                            touched={[]}
                            defaultItem={{
                                key: "all",
                                value: "",
                                label: t("installer.selectInstaller"),
                            }}
                            defaultValue={filterState.userId}
                            options={
                                users.data
                                    ? users.data.map((user) => ({
                                          key: user.id,
                                          value: user.id,
                                          label: user.fullName,
                                      }))
                                    : []
                            }
                            handleChange={(evt) =>
                                dispatch({
                                    type: actionTypes.SEARCH_USER,
                                    payload: evt.currentTarget.value,
                                })
                            }
                            className="filters"
                            labelText={t("user.user")}
                            labelClassName="sr-only"
                        />
                    </Filters.Item>
                </AuthorizeComponent>
                <Filters.Item>
                    <Select
                        name="gastype"
                        key="gastype"
                        errors={[]}
                        touched={[]}
                        defaultItem={{
                            key: "all",
                            value: "",
                            label: t("gas.gasType"),
                        }}
                        defaultValue={filterState.refrigerantGasTypeCode}
                        options={
                            gasTypes
                                ? gasTypes.map((gas) => ({
                                      key: gas.code,
                                      value: gas.code,
                                      label: gas.code,
                                  }))
                                : []
                        }
                        handleChange={(evt) => {
                            dispatch({
                                type:
                                    evt.currentTarget.value === ""
                                        ? actionTypes.RESET_GAS_TYPE
                                        : actionTypes.SET_GAS_TYPE,
                                payload: evt.currentTarget.value,
                            });
                        }}
                        className="filters"
                        labelText={t("gas.gasType")}
                        labelClassName="sr-only"
                    />
                </Filters.Item>
                <Filters.Group>
                    <Filters.Item>
                        <ClearableInput
                            name="quantityFrom"
                            type="number"
                            defaultValue={
                                filterState.gasQuantityFrom !== undefined
                                    ? filterState.gasQuantityFrom
                                    : ""
                            }
                            onChange={(evt) =>
                                quantityFromChanged(evt.currentTarget.value)
                            }
                            placeholder={t("gas.quantityFrom")}
                            className="filters  left group"
                        />
                    </Filters.Item>
                    <Filters.Item>
                        <ClearableInput
                            name="quantityTo"
                            type="number"
                            defaultValue={
                                filterState.gasQuantityTo !== undefined
                                    ? filterState.gasQuantityTo
                                    : ""
                            }
                            onChange={(evt) =>
                                quantityToChanged(evt.currentTarget.value)
                            }
                            placeholder={t("gas.quantityTo")}
                            className="filters right group"
                        />
                    </Filters.Item>
                </Filters.Group>

                <Filters.Item>
                    <Select
                        name="presumedGrade"
                        key="presumedGrade"
                        errors={[]}
                        touched={[]}
                        defaultItem={{
                            key: "all",
                            value: "",
                            label: t("gas.presumedQuality"),
                        }}
                        defaultValue={filterState.presumedGrade}
                        options={Object.keys(GradeStatus).map((key) => ({
                            key: key,
                            value: key,
                            label: GradeStatus[key],
                        }))}
                        handleChange={(evt) => {
                            dispatch({
                                type:
                                    evt.currentTarget.value === ""
                                        ? actionTypes.RESET_PRESUMED_QUALITY
                                        : actionTypes.SET_PRESUMED_QUALITY,
                                payload: evt.currentTarget.value,
                            });
                        }}
                        className="filters"
                        labelText={t("gas.presumedGrade")}
                        labelClassName="sr-only"
                    />
                </Filters.Item>
                <Filters.Item>
                    <Select
                        name="qualityAssurance"
                        key="qualityAssurance"
                        errors={[]}
                        touched={[]}
                        defaultItem={{
                            key: "all",
                            value: "",
                            label: t("gas.qualityAssurance"),
                        }}
                        defaultValue={filterState.hasAnalysis}
                        options={Object.keys(AnalysisStatus).map((key) => ({
                            key: key,
                            value: key,
                            label: t("analysis." + AnalysisStatus[key]),
                        }))}
                        handleChange={(evt) => {
                            dispatch({
                                type:
                                    evt.currentTarget.value === ""
                                        ? actionTypes.RESET_QUALITY_ASSURANCE
                                        : actionTypes.SET_QUALITY_ASSURANCE,
                                payload: evt.currentTarget.value,
                            });
                        }}
                        className="filters"
                        labelText={t("gas.qualityAssurance")}
                        labelClassName="sr-only"
                    />
                </Filters.Item>
                <Filters.Group>
                    <Filters.Item>
                        <DateTimePicker
                            labelText={t("forms:extractionDate.from")}
                            noLabel
                            placeholder={t("forms:extractionDate.from")}
                            id="extractFrom"
                            name="extractFrom"
                            className="filters big left group"
                            value={filterState ? filterState.extractFrom : ""}
                            maxDate={filterState?.extractTo}
                            handleChange={(date) =>
                                dispatch({
                                    type:
                                        date === ""
                                            ? actionTypes.RESET_EXTRACTION_DATE_FROM
                                            : actionTypes.SET_EXTRACTION_DATE_FROM,
                                    payload:
                                        date === ""
                                            ? date
                                            : helpers.dateToIsoDateString(date),
                                })
                            }
                        />
                    </Filters.Item>
                    <Filters.Item>
                        <DateTimePicker
                            labelText={t("forms:extractionDate.to")}
                            noLabel
                            placeholder={t("forms:extractionDate.to")}
                            id="extractTo"
                            name="extractTo"
                            className="filters big right group"
                            value={filterState ? filterState.extractTo : ""}
                            maxDate={filterState?.extractFrom}
                            handleChange={(date) =>
                                dispatch({
                                    type:
                                        date === ""
                                            ? actionTypes.RESET_EXTRACTION_DATE_TO
                                            : actionTypes.SET_EXTRACTION_DATE_TO,
                                    payload:
                                        date === ""
                                            ? date
                                            : helpers.dateToIsoDateString(date),
                                })
                            }
                        />
                    </Filters.Item>
                </Filters.Group>
            </Filters.Wrapper>
        </>
    );
};

export default React.memo(InstallerStockFilter);
