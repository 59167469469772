import React from "react";
import { useTranslation } from "react-i18next";
import Wrappers from "../../components/wrappers/Wrappers";
import Flex from "../../components/layout/flex/Flex";
import InputElement from "../../components/elements/form-elements/InputElement";
import { InputSubmit } from "../../components/elements/form-elements";
import styled from "styled-components";

const Section = styled.div`
    font-size: 20px;
    padding: 0px 0px;
    margin-bottom: 35px;
    &.bold {
        font-weight: 600;
        margin-bottom: 24px;
    }
    & span {
        color: ${(p) => p.theme.primaryColor};
    }

    @media ${(p) => p.theme.device.mobile} {
        padding: 0;
    }

    &.flex {
        display: flex;
        align-items: center;
    }

    &.space {
        margin-top: 150px;
    }
`;
const Circle = styled.div`
    min-width: 44px;
    width: 44px;
    height: 44px;
    line-height: 42px;
    font-weight: 700;
    font-size: 26px;
    text-align: center;
    background: #fff;
    color: ${(p) => p.theme.primaryColor};
    border: 3px solid ${(p) => p.theme.primaryColor};
    border-radius: 50%;
    float: left;
    margin-right: 25px;

    & img {
        height: 20px;
        margin-top: 10px;
    }
`;

const InputWrap = styled.div`
    width: 380px;
    @media ${(p) => p.theme.device.mobile} {
        width: 100%;
    }
`;

const FlexNoWrap = styled.div`
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    position: relative;
    &.between {
        justify-content: space-between;
    }
`;

const PassInfo = styled.div`
    font-size: 14px;
    font-weight: 400;
    color: #898989;
    line-height: 18px;
    & ul {
        margin: 0;
        padding-inline-start: 15px;
    }
    & ul.dashed {
        list-style-type: none;
    }
    & ul.dashed > li {
        text-indent: -5px;
    }
    & ul.dashed > li:before {
        content: "-";
        text-indent: -5px;
    }
`;

export const ResetPasswordForm = ({ formik, loading, isCompleted }) => {
    const { t } = new useTranslation();

    if (isCompleted) {
        return (
            <Flex.Row style={{ marginT: "50px" }}>
                <Section className="bold flex">
                    <Circle>
                        <img
                            alt="ok"
                            src="/images/actions/icon-ok-primary.svg"
                        />
                    </Circle>
                    <div>{t("screens:resetPassword.passwordSaved")}</div>
                </Section>
                <Section className="space">
                    Your will be automatically re-directed to a login page.
                </Section>
            </Flex.Row>
        );
    }

    return (
        <Wrappers.FormWrapper
            className="kruno"
            style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
            }}
        >
            <InputWrap>
                <InputElement
                    labelText={t("screens:login.setPassword")}
                    name="password"
                    type="password"
                    style={{ width: "100%" }}
                    {...formik}
                />

                <InputElement
                    labelText={t("screens:login.confirmPassword")}
                    name="repeatPassword"
                    type="password"
                    {...formik}
                />
            </InputWrap>
            <PassInfo className="wide">
                {t("screens:login.passInfo")}
                <ul className="dashed">
                    <li>{t("screens:login.passLower")}</li>
                    <li>{t("screens:login.passUpper")}</li>
                    <li>{t("screens:login.passDigits")}</li>
                    <li>{t("screens:login.passSymbols")}</li>
                </ul>
            </PassInfo>
            <Flex.Container
                className="horizontal-center"
                style={{ margin: "24px 0" }}
            >
                <InputSubmit
                    value={t("screens:forgottenPassword.submit")}
                    variant="primary big"
                />
            </Flex.Container>
        </Wrappers.FormWrapper>
    );
};
