import Color from "color";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const SideBar = styled.div`
    transform: translate(100%);
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    bottom: auto;
    right: 11px;
    height: 100%;
    width: 480px;
    min-height: 100%;
    background: ${(props) => props.theme.bgSideBar};
    transition: all 0.25s ease-out 0.2s;
    box-sizing: border-box;
    z-index: 25;
    visibility: hidden;

    &.open {
        transform: translateX(41px);
        font-size: inherit;
        z-index: 200;
        visibility: visible;

        &.nested {
            transform: translateX(2%);
            z-index: 201;
        }
    }

    &.wide {
        width: 680px;
    }

    & .isOpened.accordion {
        /* border-bottom: 1px solid
            ${(props) => props.theme.sidebarWrapperBorderColor}; */
        padding: 0 0 30px 0;

        &:last-child,
        &:last-of-type {
            border: none;
        }
    }

    &.childOpen {
        > .close {
            pointer-events: none;
        }
        > .header {
            position: absolute;
        }
        > .sideContent {
            padding-top: 92px;
        }
    }
`;

const SidebarOverlay = styled.div`
    transition: all 0.25s ease-out;

    &.open {
        display: block;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: ${(props) =>
            Color(props.theme.bgOverlay).fade(0.17).string()};
        z-index: 100;
    }
`;

const CloseButton = styled.span`
    position: absolute;
    right: 60px;
    top: 22px;
    width: 14px;
    height: 14px;
    cursor: pointer;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 14px;
        height: 14px;
        background: url("/images/actions/close_button.svg") no-repeat;
        background-size: 14px;
    }
`;

const SideBarContent = styled.div`
    padding: 32px 40px 40px;
    box-shadow: 0 6px 40px 0 rgba(0, 0, 0, 0.16);
    flex-grow: 1;
    box-sizing: border-box;
    width: calc(100% - 30px);
    overflow-y: auto;

    mask-image: linear-gradient(to top, transparent, black),
        linear-gradient(to left, transparent 17px, black 17px);
    mask-size: 100% 20000px;
    mask-position: left bottom;
    transition: mask-position 0.5s;

    &:hover {
        mask-position: left top;
    }
`;

const SideBarHeader = styled.div`
    position: static;
    top: 0;
    left: 0;
    width: calc(100% - 30px);
    min-height: 60px;
    background: ${(p) => p.theme.primaryColor};
    color: #f9f9f9;
    padding: 18px 40px 0 60px;
    box-sizing: border-box;
    font-size: 1.25rem;
    box-shadow: -2px 9px 11px -16px rgba(0, 0, 0, 0.75);
    border-bottom: 1px solid #ffffff;

    &.nested {
        z-index: 300;
    }
`;

const DetailSideBar = (
    {
        children,
        detailIsOpened,
        closeSideBar,
        variant,
        title,
        shouldRender = true,
        sidebarId = null,
    },
    ref
) => {
    const { t } = useTranslation("sidebar");

    if (!title) {
        title = t("shared.title");
    }

    let parent = null;
    if (variant === "nested" && ref.current) {
        parent = document.querySelector(".detailview");
    }

    if (parent) {
        detailIsOpened
            ? parent.classList.add("childOpen")
            : parent.classList.remove("childOpen");
    }

    if (!shouldRender) {
        return null;
    }

    return (
        <>
            {variant !== "nested" && (
                <SidebarOverlay
                    data-id="sbOverlay"
                    className={detailIsOpened ? "open" : ""}
                />
            )}
            <SideBar
                ref={ref}
                className={[
                    detailIsOpened ? "open" : "",
                    variant,
                    "detailview",
                ]}
                id={sidebarId === null ? null : `sidebar-${sidebarId}`}
            >
                <>
                    {title && (
                        <SideBarHeader className="header">
                            {title}
                            <CloseButton
                                className="close"
                                onClick={(ev) => {
                                    closeSideBar(ev);
                                }}
                                id={
                                    sidebarId === null
                                        ? null
                                        : `close-sidebar-${sidebarId}`
                                }
                            />
                        </SideBarHeader>
                    )}
                </>

                <SideBarContent className="sideContent">
                    {children}
                </SideBarContent>
            </SideBar>
        </>
    );
};
export default React.forwardRef(DetailSideBar);
