import ClientsService from "../../../services/Clients/ClientsService";
import createListStateModule from "../createListStateModule";
import countriesStatePlugin from "../plugins/countriesStatePlugin";
import gasTypesStatePlugin from "../plugins/gasTypesStatePlugin";

const clientsLocationListStateModule = createListStateModule({
    stateNamespace: "clientsLocations",
    itemsPropertyName: "clientsLocations",
    loadItemsMethodName: "loadClientsLocations",
    itemsDataMethod: async (filterState) => {
        const locations = await ClientsService.getClientsLocations(filterState);
        return locations;
    },
    loadDetailMethodName: "loadClientsLocationsById",
    detailDataMethod: async (id) => {
        const clientsLocation = await ClientsService.getClientLocationByIdAsync(id);
        return clientsLocation
    },
    detailsPropertyName: "clientsLocationData",
    deleteMethodName: "deleteClientLocation",
    deleteDataMethod: ClientsService.deleteClientLocation,
    updateMethodName: "updateClientLocation",
    updateDataMethod: ClientsService.updateClientLocation,
    createDataMethod: "createClientLocationItem",
    createMethodName: ClientsService.createClientLocation,
    defaultItemsData: {data: []},
    plugins: [countriesStatePlugin, gasTypesStatePlugin]
});

export default clientsLocationListStateModule;
