import React, { useState } from "react";
import {
    Checkbox,
    InputSubmit,
    Select,
} from "../../components/elements/form-elements";
import InputElement from "../../components/elements/form-elements/InputElement";
import { useTranslation } from "react-i18next";
import Wrappers from "../../components/wrappers/Wrappers";
import Headings from "../../components/elements/headings/Headings";
import FlexContainer from "../../components/layout/flex/FlexContainer";
import CertificateUpload from "../../components/file-processing/CertificateUpload";
import RouteCreator from "../../core/RouteCreator";
import CompanyType from "../../core/enums/CompanyType";
import NavigationLink from "../../components/elements/links/NavigationLink";
import styled from "styled-components";

const Desc = styled.div`
    margin-top: 30px;
    width: 100%;
    font-size: 14px;
    color: #898989;
    text-align: center;
    @media ${(p) => p.theme.device.mobile} {
        font-size: 10px;
    }
`;

const RegisterForm = ({ formik, isLoading, isCertMandatory = true }) => {
    const { t } = new useTranslation(["common", "forms", "screens"]);

    return (
        <Wrappers.FormWrapper variant="nomargin">
            <Wrappers.Wrapper>
                <Headings.H1
                    className="center small-title bold"
                    style={{ marginBottom: "10px", marginTop: "30px" }}
                >
                    {t("screens:registration.title")}
                </Headings.H1>

                <InputElement
                    labelText={t("forms:companyName.text")}
                    id="companyName"
                    name="companyName"
                    autocomplete="new-companyName"
                    type="text"
                    {...formik}
                />

                <Select
                    id="companyType"
                    name="companyType"
                    labelText={t("forms:account.companyType")}
                    wrapperVariant="half"
                    options={Object.keys(CompanyType)
                        .filter((k) => k !== "All")
                        .map((key) => ({
                            key: CompanyType[key],
                            value: CompanyType[key],
                            label: t("forms:companyType." + key),
                        }))}
                    {...formik}
                />

                <InputElement
                    labelText={t("forms:VAT.text")}
                    id="vatNumber"
                    name="vatNumber"
                    autocomplete="new-vatNumber"
                    type="text"
                    wrapperVariant="half"
                    {...formik}
                />

                <InputElement
                    labelText={t("forms:email.text")}
                    id="email"
                    name="email"
                    autocomplete="new-email"
                    type="text"
                    {...formik}
                />
                <InputElement
                    labelText={t("forms:resetPassword.text")}
                    id="password"
                    name="password"
                    type="password"
                    autocomplete="new-password"
                    wrapperVariant="half"
                    {...formik}
                />

                <InputElement
                    labelText={t("forms:repeatPassword.text")}
                    name="repeatPassword"
                    type="password"
                    wrapperVariant="half"
                    {...formik}
                />

                <Checkbox
                    className="form mt-20"
                    labelText={t("forms:account.haveCertificate")}
                    id="fGasCertificateCheck"
                    name="fGasCertificateCheck"
                    checked={isCertMandatory}
                    disabled={isCertMandatory}
                    {...formik}
                />

                <CertificateUpload
                    style={{ marginTop: "-10px" }}
                    formik={formik}
                    isRegistration={true}
                />

                <Desc
                    dangerouslySetInnerHTML={{
                        __html: t("screens:login.signupDesc"),
                    }}
                />
            </Wrappers.Wrapper>

            <FlexContainer
                className="horizontal-center"
                style={{ marginTop: "0px" }}
            >
                <InputSubmit
                    value={t("screens:forgottenPassword.submit")}
                    variant="primary big"
                />
            </FlexContainer>
            <FlexContainer
                className="horizontal-center"
                style={{ marginTop: "0px" }}
            >
                <NavigationLink
                    to={RouteCreator.user.login()}
                    className="forgotten-password"
                >
                    {t("screens:forgottenPassword.backToLoginLink")}
                </NavigationLink>
            </FlexContainer>
        </Wrappers.FormWrapper>
    );
};

export default RegisterForm;
