import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import styled from "styled-components";
import DataCellImage from "../../components/data/DataCellImage";
import ListingPage from "../../components/data/ListingPage";
import Flex from "../../components/layout/flex/Flex";
import HamburgerMenu from "../../components/menu/HamburgerMenu";
import HamburgerMenuAction from "../../components/menu/HamburgerMenuAction";
import ModalDialog, { useModal } from "../../components/modal/ModalDialog";
import ModalWizard from "../../components/modal/ModalWizard";
import AuthorizeComponent from "../../core/AuthorizeComponent";
import Roles from "../../core/enums/Roles";
import cylinderListStateModule from "../../core/state/company/cylinderListStateModule";
import useBreadcrumbs from "../../core/state/navigation/useBreadcrumbs";
import useStateModule from "../../core/state/useStateModule";
import useImmerReducer from "../../hooks/_shared/useImmerReducer";
import usePageChange from "../../hooks/_shared/usePageChange";
import CompanyService from "../../services/Company/CompanyService";
import TagsService from "../../services/Tags/TagsService";
import CylinderDetailSideBar from "./components/CylinderDetailSideBar";
import CylinderFilter from "./components/CylinderFilter";
import CylinderWizardAdd from "./components/wizard/CylinderWizardAdd";
import CylinderWizardEdit from "./components/wizard/CylinderWizardEdit";
import helpers from "../../core/helpers";
import ActionButton from "../../components/elements/buttons/ActionButton";
import { Helmet } from "react-helmet";
import useSharedState from "../../hooks/_shared/useSharedState";
import WizardCylinderIssue from "./components/wizard/WizardCylinderIssue";

const TagTitle = styled.div`
    width: 100%;
    font-size: 20px;
    font-weight: 700;
    margin-top: 10px;
`;

const Qr = styled.span`
    font-size: 10px;
`;

const KeyValue = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    & .value {
        color: ${(p) => p.theme.primaryColor};
    }
`;

const CylTypeCell = styled.div`
    width: 100%;
    color: ${(p) => p.theme.primaryColor};
    font-size: 14px;
    margin-bottom: 5px;
`;

const KeyValueCell = (key, value) => {
    return (
        <KeyValue>
            <span>{key}</span>
            <span className="value">{value}</span>
        </KeyValue>
    );
};

const actionTypes = {
    SET_PAGING: "SET_PAGING",
    SET_GAS_TYPE: "SET_GAS_TYPE",
    RESET_GAS_TYPE: "RESET_GAS_TYPE",
    SET_SIZE: "SET_SIZE",
    RESET_SIZE: "RESET_SIZE",
    SET_CYL_TYPE: "SET_CYL_TYPE",
    RESET_CYL_TYPE: "RESET_CYL_TYPE",
    SET_CYLINDER_SIZE_FROM: "SET_CYLINDER_SIZE_FROM",
    SET_CYLINDER_SIZE_TO: "SET_CYLINDER_SIZE_TO",
    RESET_CYLINDER_SIZE_FROM: "RESET_CYLINDER_SIZE_FROM",
    RESET_CYLINDER_SIZE_TO: "RESET_CYLINDER_SIZE_TO",
};

const filterReducer = (state, action) => {
    if (action.type !== actionTypes.SET_PAGING) state.page = 1;
    switch (action.type) {
        case actionTypes.SET_GAS_TYPE:
            state.filter.refrigerantTypeCode = action.payload;
            break;
        case actionTypes.RESET_GAS_TYPE:
            delete state.filter.refrigerantTypeCode;
            break;
        case actionTypes.SET_SIZE:
            state.filter.size = action.payload;
            break;
        case actionTypes.RESET_SIZE:
            delete state.filter.size;
            break;
        case actionTypes.SET_CYL_TYPE:
            state.filter.cylinderType = action.payload;
            break;
        case actionTypes.RESET_CYL_TYPE:
            delete state.filter.cylinderType;
            break;
        case actionTypes.SET_CYLINDER_SIZE_FROM:
            state.filter.cylinderSizeFrom = Number(action.payload);
            break;
        case actionTypes.SET_CYLINDER_SIZE_TO:
            state.filter.cylinderSizeTo = Number(action.payload);
            break;
        case actionTypes.RESET_CYLINDER_SIZE_FROM:
            delete state.filter.cylinderSizeFrom;
            break;
        case actionTypes.RESET_CYLINDER_SIZE_TO:
            delete state.filter.cylinderSizeTo;
            break;
        case actionTypes.SET_PAGING:
            state.pageSize = action.payload.pageSize;
            state.page = action.payload.pageIndex + 1;
            break;
        default:
            break;
    }
};

const CylindersList = () => {
    const sidebarRef = React.useRef();
    const { t } = useTranslation("screens");
    const [globalState] = useSharedState("global");
    const {
        cylinders,
        isLoading,
        error,
        loadCylinders,
        loadCylinderById,
        details,
        deleteCylinder,
        loadGasTypes,
        gasTypesData,
    } = useStateModule(cylinderListStateModule);
    const [openAccordionId, setOpenAccordionId] = useState();
    const [detailViewIsOpened, setDetailViewIsOpened] = useState(false);
    const [selectedCylinder, setSelectedCylinder] = useState(null);
    const queryCylinderTags = async (tag) => {
        if (tag.length > 2) {
            const tags = await TagsService.lookupCylinderTags(tag);
            return tags.map((t) => t.id);
        }
        return [];
    };
    const [filterState, dispatch] = useImmerReducer(filterReducer, {
        filter: {},
        page: 1,
        pageSize: 10,
    });
    useEffect(() => {
        loadGasTypes();
    }, [loadGasTypes]);

    const pageChange = usePageChange(dispatch, filterState);
    const reportIssue = async (cylinderId) => {
        ModalWizard.show(
            WizardCylinderIssue(t),
            {
                onSubmit: async (values) => {
                    values.cylinderId = cylinderId;
                    values.issueType = Number(values.issueType);
                    const result = await TagsService.addCylinderIssueAsync(values);
                    if (result && result.error) ModalWizard.submitFailed(t(result.error.message));
                    //toast.error(t(result.error.message));
                    else {
                        toast.success(t("actions:dialogs.reportCylinder.success"));
                        ModalWizard.hide();
                    }
                },
                onCancel: async () => {
                    ModalWizard.hidePrompt();
                },
            },
            { style: { minHeight: "708px" } }
        );
    };

    const createNewCylinder = async (ev) => {
        ev.preventDefault();
        const res = await CompanyService.getDistributors();
        const cylOwners = res.data?.data || [];
        ModalWizard.show(
            CylinderWizardAdd(t, queryCylinderTags, cylOwners),
            {
                onSubmit: async (values) => {
                    if (values.ownerCompanyId === "") delete values.ownerCompanyId;
                    const result = await CompanyService.createCompanyCylinderAsync(
                        ModalWizard.asFormData(values)
                    );
                    if (result && result.error) ModalWizard.submitFailed(t(result.error.message));
                    //toast.error(t(result.error.message));
                    else {
                        loadCylinders(filterState);
                        toast.success(t("actions:dialogs.createCylinder.success"));
                        ModalWizard.hide();
                    }
                },
                onCancel: async () => {
                    ModalWizard.hidePrompt();
                },
            },
            { style: { minHeight: "708px" } }
        );
    };

    const editCylinder = async (cylinderId) => {
        const cylinder = await CompanyService.getCompanyCylinderByIdAsync(cylinderId);
        if (cylinder.error) {
            toast(cylinder.error.message);
            return;
        }
        ModalWizard.show(
            CylinderWizardEdit(t, queryCylinderTags, cylinder.data.data[0]),
            {
                onSubmit: async (values) => {
                    /* update cylinder */
                    const result = await CompanyService.updateCylinderAsync(
                        ModalWizard.asFormData(values)
                    );

                    if (result && result.error) toast.error(t(result.error.message));
                    else {
                        loadCylinders(filterState);
                        toast.success(t("actions:dialogs.editCylinder.success"));
                        ModalWizard.hide();
                    }
                },
                onCancel: async () => {
                    ModalWizard.hidePrompt();
                },
            },
            { style: { minHeight: "600px" } }
        );
    };

    const cardColumns = ["cylinderType", "imageUrl", "size", "refrigerantTypeCode", "tagId", "id"];
    const columns = useMemo(
        () => [
            {
                Header: "",
                accessor: "cylinderType",
                Cell: ({ cell: { value } }) => {
                    let typeName =
                        value === 0
                            ? t("filters:cylinders.recovery")
                            : t("filters:cylinders.reclaimed");
                    return <CylTypeCell>{typeName}</CylTypeCell>;
                },
            },
            {
                Header: () => null,
                accessor: "imageUrl",
                Cell: ({ cell }) => {
                    if (!cell.value) {
                        cell.value = "/images/cylinder_no_image.svg";
                    }
                    return <DataCellImage image={cell.value} width="76px" />;
                },
            },
            {
                Header: t("equipment.headers.size"),
                accessor: "size",
                Cell: ({ cell: { value } }) =>
                    KeyValueCell(t("forms:cylinderSize.text"), value.toFixed(2) + " kg"),
            },
            {
                Header: t("equipment.headers.refrigerantTypeCode"),
                accessor: "refrigerantTypeCode",
                Cell: ({ cell: { row, value } }) => {
                    const val = value
                        ? value + " - " + row.original.filledQuantity.toFixed(2) + " kg"
                        : t("equipment.emptyCylinder");

                    return KeyValueCell(t("forms:cylinder.cylinderGas"), val);
                },
            },
            {
                Header: t("equipment.headers.refrigerantTypeCode"),
                accessor: "tagId",
                Cell: ({ cell: { value } }) => {
                    const tagParts = value ? helpers.splitQRTag(value) : ["", ""];
                    return (
                        <>
                            <TagTitle>{tagParts[0]}</TagTitle>
                            <Qr>{tagParts[1]}</Qr>
                        </>
                    );
                },
            },
            {
                Header: "",
                accessor: "id",
                Cell: ({ cell: { value, row } }) => {
                    return (
                        <HamburgerMenu>
                            <AuthorizeComponent
                                roles={[
                                    Roles.InstallerCompanyAdmin,
                                    Roles.InstallerCompanyUser,
                                    Roles.InstallerCompanyTechnician,
                                ]}
                            >
                                <HamburgerMenuAction
                                    disabled={
                                        row.original.filledQuantity > 0 ||
                                        row.original.ownerCompanyId !== globalState.userCompanyId
                                    }
                                    text={t("actions:edit")}
                                    onClick={() => {
                                        editCylinder(value);
                                    }}
                                />
                                <HamburgerMenuAction
                                    disabled={
                                        row.original.filledQuantity > 0 ||
                                        row.original.ownerCompanyId !== globalState.userCompanyId
                                    }
                                    text={t("actions:delete")}
                                    onClick={() => {
                                        setSelectedCylinder(
                                            cylinders.data.filter((c) => c.id === value)
                                        );
                                        toggleOpen(value);
                                    }}
                                />
                                <HamburgerMenuAction
                                    text={t("actions:reportIssue")}
                                    onClick={() => {
                                        reportIssue(value);
                                    }}
                                />
                            </AuthorizeComponent>
                        </HamburgerMenu>
                    );
                },
            },
        ],
        createNewCylinder[
            // eslint-disable-next-line
            (cylinders, t)
        ]
    );

    useEffect(() => {
        loadCylinders(filterState);
    }, [loadCylinders, filterState]);

    const onSelect = useCallback(
        (row) => {
            const selectRow = async (row) => {
                await loadCylinderById(row.original.id);
                setDetailViewIsOpened(true);
            };
            selectRow(row);
        },
        [loadCylinderById]
    );

    const closeSideBar = () => {
        setOpenAccordionId(null);
        sidebarRef.current.updateAccordionId(null);
        setDetailViewIsOpened(false);
    };

    useBreadcrumbs([
        {
            text: t("navigation:company"),
        },
        {
            text: t("navigation:equipment"),
        },
        {
            text: t("navigation:cylinders"),
        },
    ]);

    const { modalProps, toggleOpen } = useModal({
        title: t("actions:dialogs.deleteCylinder.title"),
        onConfirm: async (equipment) => {
            await deleteCylinder(equipment).then(() => {
                loadCylinders(filterState);
            });
            toggleOpen();
            toast.success(t("cylinderListing.toastNotification.success"));
        },
        onCancel: () => {
            toggleOpen();
        },
        confirmText: t("actions:dialogs.deleteEquipment.confirmText"),
        cancelFirst: true,
    });

    return (
        <>
            <Helmet>
                <title>{t("seo:cylinderList.title")}</title>
            </Helmet>
            <ListingPage
                filterTitle={t("equipment.cylinderTitle")}
                filterComponent={() => (
                    <CylinderFilter
                        actionTypes={actionTypes}
                        gasTypes={gasTypesData.gasTypes}
                        dispatch={dispatch}
                        filterState={filterState.filter}
                    />
                )}
                columns={columns}
                mode="cards"
                tableState={{
                    data: cylinders.data,
                    loading: isLoading,
                    error: error,
                }}
                actions={{
                    headerActions: () => {
                        return (
                            <AuthorizeComponent
                                roles={[
                                    Roles.InstallerCompanyAdmin,
                                    Roles.InstallerCompanyUser,
                                    Roles.InstallerCompanyTechnician,
                                ]}
                            >
                                <Flex.Container justifyContent="flex-end">
                                    <ActionButton
                                        className="primary"
                                        // icon="/images/actions/icon-add.svg"
                                        iconSize="12px"
                                        onClick={createNewCylinder}
                                        tooltip={t("screens:cylinderListing.tooltip.add")}
                                    >
                                        {t("actions:addCylinder")}
                                    </ActionButton>
                                </Flex.Container>
                            </AuthorizeComponent>
                        );
                    },
                }}
                paging={{
                    numPageButtons: 5,
                    enablePaging: true,
                    pageCount: cylinders.totalPages,
                    pageSize: filterState.pageSize,
                    onPageChange: pageChange,
                    currentPageIndex: cylinders.currentPage ? cylinders.currentPage - 1 : 0,
                }}
                onSelect={onSelect}
                closeSideBar={closeSideBar}
                detailSideBarTitle={t("sidebar:title.extraction.extractionCylinderDetails")}
                detailView={
                    <CylinderDetailSideBar
                        ref={sidebarRef}
                        openAccordionId={openAccordionId}
                        equipment={details}
                        reportIssue={reportIssue}
                    />
                }
                detailViewIsOpened={detailViewIsOpened}
                cardColumns={cardColumns}
                variants={{
                    headerViewVariant: "right-action",
                }}
            />
            <ModalDialog {...modalProps}>
                <p>{t("actions:dialogs.deleteCylinder.text")}</p>
                <p style={{ marginLeft: "10px" }}>
                    {selectedCylinder &&
                        selectedCylinder.length > 0 &&
                        `• ${selectedCylinder[0].tagId} | ${selectedCylinder[0].size.toFixed(
                            2
                        )} kg`}
                </p>
            </ModalDialog>
        </>
    );
};

export default CylindersList;
