import React from "react";
import styled from "styled-components";

const Content = styled.div`
    --idx: ${(p) => (p.idx + 1) * 23}px;
    visibility: hidden;
    position: absolute;
    z-index: 100000;
    top: calc(100% + 12px);
    right: calc(100% - var(--idx));
    width: auto;
    padding: 4px 8px;
    border-radius: 4px;
    text-align: left;
    background: ${(props) => props.theme.bgTooltip};
    color: #fff;
    font-size: 12px;
    white-space: nowrap;

    &.visible {
        visibility: visible;
    }

    &.right {
        right: 0;
    }

    &.left {
        right: auto;
        left: 0;
    }

    &.top {
        top: -26px;
    }

    &.top-center {
        top: -26px;
        left: auto;
        right: auto;
        text-align: center;
        margin: 0 auto;
        transform: translate(-50%, 0);
    }

    &.top-left {
        top: -26px;
        right: auto;
        left: 0;
        text-align: center;
    }
`;

const Tooltip = ({ tooltip, visible, idx, position }) => {
    return (
        <Content
            idx={idx || 0}
            className={`${visible ? "visible" : ""} ${
                position ? position : ""
            }`}
        >
            {tooltip}
        </Content>
    );
};

export default Tooltip;
