import Color from "color";
import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

const StyledAnchor = styled(Link)`
    outline: none;
    -webkit-tap-highlight-color: transparent;
    border-radius: ${(props) => props.theme.buttonsBorderRadius};
    text-align: center;
    padding: 0 20px;
    text-decoration: none;
    color: ${(props) => props.theme.primaryColor};
    ${(props) =>
        props.margin &&
        css`
            margin: ${props.margin};
        `};
    box-shadow: ${(props) => props.theme.buttonShadow};
    box-sizing: border-box;
    transition: all 0.5s ease-in-out;
    display: inline-block;
    height: ${(props) => props.theme.heightButtonBig};
    line-height: ${(props) => props.theme.heightButtonBig};
    vertical-align: middle;
    font-size: 0.875rem;

    &.primary {
        background: ${(props) => props.theme.primaryColor};
        border: 1px solid ${(props) => props.theme.primaryColorBorder};
        color: ${(props) => props.theme.primaryColorText};
        min-width: ${(props) => props.theme.widthButtonMin};
    }

    &.secondary {
        background: ${(props) => props.theme.secondaryColor};
        border: 1px solid ${(props) => props.theme.secondaryColorBorder};
        color: ${(props) => props.theme.secondaryColorText};
        min-width: ${(props) => props.theme.widthButtonMin};

        &:hover {
            background: ${(props) =>
                Color(props.theme.secondaryColor).darken(0.09).string()};
        }
    }

    &.block {
        display: block;
    }

    &.horizontal-space {
        margin: 0 10px;
    }

    &.form-button {
    }

    &.filter {
        background: #675b34;
        border: 1px solid #675b34;
        height: 32px;
        line-height: 32px;
        padding: 0px 8px;
        margin: 0px;
    }

    &.pwa-filter {
        transition: all 0.1s;
        background: ${(p) => p.theme.primaryColor};
        color: #fff;
        border: 1px solid ${(p) => p.theme.primaryColor};
        height: 32px;
        line-height: 32px;
        padding: 0px 16px;
        margin: 0px;
        font-size: 12px;
        font-weight: 600;
        box-shadow: none;
        text-transform: uppercase;
        &:active {
            box-shadow: inset 0 0 6px #000000;
        }
    }

    &.pwa-filter.clear {
        background: #fff;
        border: 1px solid ${(p) => p.theme.secondaryNegativeColor};
        color: ${(p) => p.theme.secondaryNegativeColor};
    }

    &.pwa-filter.back {
        background: transparent;
        border: 1px transparent;
        color: ${(p) => p.theme.primaryColor};
        padding: 8px 0px;
        &:active {
            box-shadow: none;
        }
    }

    &.logout {
        background: transparent;
        border: 1px solid transparent;
        height: 26px;
        line-height: 26px;
        border-radius: 26px;
        padding: 0px 15px;
        margin: 0px;
        font-size: 12px;
        font-weight: 600;
        color: #bc3535;
        box-shadow: none;
        padding: 0px;
        display: flex;
        align-items: center;
        & img {
            margin-left:5px;
        }
    }
`;

const Anchor = (props) => {
    return <StyledAnchor className={props.variant} {...props} />;
};

export default Anchor;
