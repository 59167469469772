import React from "react";
import useAuthorization from "./useAuthorization";

const AuthorizeComponentByUserId = ({ children, userId, fullAccessRoles }) => {
    const canAccess = useAuthorization({
        allowedUserId: userId,
        overrideUserIdRoles: fullAccessRoles
    });
    if (canAccess) {
        return children;
    }
    return <></>;
};

export default AuthorizeComponentByUserId;
