import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import DateCell from "../../../../components/data/DateCell";
import ListingPage from "../../../../components/data/ListingPage";
import Flex from "../../../../components/layout/flex/Flex";
import ModalWizard from "../../../../components/modal/ModalWizard";
import AuthorizeComponent from "../../../../core/AuthorizeComponent";
import Roles from "../../../../core/enums/Roles";
import employeeListStateModule from "../../../../core/state/company/employeeListStateModule";
import useBreadcrumbs from "../../../../core/state/navigation/useBreadcrumbs";
import installerStockListingStateModule from "../../../../core/state/stock/installerStockListingStateModule";
import useStateModule from "../../../../core/state/useStateModule";
import useImmerReducer from "../../../../hooks/_shared/useImmerReducer";
import usePageChange from "../../../../hooks/_shared/usePageChange";
import usePromises from "../../../../hooks/_shared/usePromises";
import ClientsService from "../../../../services/Clients/ClientsService";
import StockService from "../../../../services/Stock/StockService";
import TagsService from "../../../../services/Tags/TagsService";
import UserService from "../../../../services/User/UserService";
import NewExtractionWizard from "../../../NewRefrigerant/views/new-refrigerant/components/NewExtractionWizard";
import InstallerStockFilter from "./components/InstallerStockFilter";
import MoveToStockWizard from "./components/MoveToStockWizard";
import StockDetailSideBar from "./components/StockDetailSideBar";
import Select from "../../../../components/elements/form-elements/Select";
import CollectCylinderWizard from "./components/CollectCylinderWizard";
import useDataReducer from "../../../../hooks/_shared/useDataReducer";
import MarketplaceService from "../../../../services/BuyRefrigerant/MarketplaceService";
import ModalDialog, { useModal } from "../../../../components/modal/ModalDialog";
import { Helmet } from "react-helmet";
import GreenCell from "../../../../components/data/GreenCell";
import styled from "styled-components";
import CompanyService from "../../../../services/Company/CompanyService";
import WizardCylinderIssue from "../../../Equipment/components/wizard/WizardCylinderIssue";

const P = styled.div`
    display: block;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 12px;
`;

const actionTypes = {
    SEARCH_USER: "SEARCH_USER",
    SET_GAS_TYPE: "SET_GAS_TYPE",
    RESET_GAS_TYPE: "RESET_GAS_TYPE",
    SET_GAS_QUANTITY_TO: "SET_GAS_QUANTITY_TO",
    RESET_GAS_QUANTITY_TO: "RESET_GAS_QUANTITY_TO",
    SET_GAS_QUANTITY_FROM: "SET_GAS_QUANTITY_FROM",
    RESET_GAS_QUANTITY_FROM: "RESET_GAS_QUANTITY_FROM",
    SET_PRESUMED_QUALITY: "SET_PRESUMED_QUALITY",
    RESET_PRESUMED_QUALITY: "RESET_PRESUMED_QUALITY",
    SET_QUALITY_ASSURANCE: "SET_QUALITY_ASSURANCE",
    RESET_QUALITY_ASSURANCE: "RESET_QUALITY_ASSURANCE",
    SET_EXTRACTION_DATE_FROM: "SET_EXTRACTION_DATE_FROM",
    RESET_EXTRACTION_DATE_FROM: "RESET_EXTRACTION_DATE_FROM",
    SET_EXTRACTION_DATE_TO: "SET_EXTRACTION_DATE_TO",
    RESET_EXTRACTION_DATE_TO: "RESET_EXTRACTION_DATE_TO",
    SET_PAGING: "SET_PAGING",
    SET_SORT: "SET_SORT",
    RESET_SORT: "RESET_SORT",
};

const StockActions = {
    NewExtraction: "0",
    ToCompanyStock: "1",
    ToInstallerStock: "2",
    Collect: "3",
};

const filterReducer = (state, action) => {
    if (action.type !== actionTypes.SET_PAGING) state.page = 1;
    switch (action.type) {
        case actionTypes.SEARCH_USER:
            state.filter.userId = action.payload;
            break;
        case actionTypes.RESET_GAS_TYPE:
            delete state.filter.refrigerantGasTypeCode;
            break;
        case actionTypes.SET_GAS_TYPE:
            state.filter.refrigerantGasTypeCode = action.payload;
            break;
        case actionTypes.SET_GAS_QUANTITY_TO:
            state.filter.quantityTo = Number(action.payload);
            break;
        case actionTypes.RESET_GAS_QUANTITY_TO:
            delete state.filter.quantityTo;
            break;
        case actionTypes.SET_GAS_QUANTITY_FROM:
            state.filter.quantityFrom = Number(action.payload);
            break;
        case actionTypes.RESET_GAS_QUANTITY_FROM:
            delete state.filter.quantityFrom;
            break;

        case actionTypes.SET_EXTRACTION_DATE_FROM:
            state.filter.extractFrom = action.payload;
            break;
        case actionTypes.RESET_EXTRACTION_DATE_FROM:
            delete state.filter.extractFrom;
            break;
        case actionTypes.SET_EXTRACTION_DATE_TO:
            state.filter.extractTo = action.payload;
            break;
        case actionTypes.RESET_EXTRACTION_DATE_TO:
            delete state.filter.extractTo;
            break;
        case actionTypes.SET_PRESUMED_QUALITY:
            state.filter.presumedGrade = Number(action.payload);
            break;
        case actionTypes.RESET_PRESUMED_QUALITY:
            delete state.filter.presumedGrade;
            break;
        case actionTypes.SET_QUALITY_ASSURANCE:
            state.filter.hasAnalysis = action.payload === "true";
            break;
        case actionTypes.RESET_QUALITY_ASSURANCE:
            delete state.filter.hasAnalysis;
            break;
        case actionTypes.SET_PAGING:
            state.pageSize = action.payload.pageSize;
            state.page = action.payload.pageIndex + 1;
            break;
        case actionTypes.SET_SORT:
            state.orderColumn = action.payload[0].id;
            state.descending = action.payload[0].desc;
            break;
        case actionTypes.RESET_SORT:
            state.orderColumn = "";
            state.descending = true;
            break;
        default:
            return state;
    }
};

const InstallerStockListing = () => {
    const {
        stockItems,
        isLoading,
        error,
        loadStockItems,
        loadStockById,
        details,
        loadGasTypes,
        gasTypesData,
    } = useStateModule(installerStockListingStateModule);
    const {
        employees,
        isLoading: employeesLoading,
        error: employeesError,
        loadEmployees,
    } = useStateModule(employeeListStateModule);

    const userId = UserService.getUserDetailsFromToken().UserId;

    const [filterState, dispatch] = useImmerReducer(filterReducer, {
        filter: {
            userId: UserService.isInRole(Roles.InstallerCompanyTechnician) ? userId : "",
            locationStatusName: "InstallerStock",
            marketplaceStatusName: "OnStock",
        },
        page: 1,
        pageSize: 10,
        orderColumn: "extractionDateTime",
        descending: true,
    });

    const { t } = useTranslation("screens");

    const stockActions = [
        {
            text: t("screens:pwaMenu.newExtraction"),
            value: StockActions.NewExtraction,
        },
        {
            text: t("screens:pwaMenu.toCompanyStock"),
            value: StockActions.ToCompanyStock,
        },
        {
            text: t("screens:pwaMenu.toMyStock"),
            value: StockActions.ToInstallerStock,
        },
        {
            text: t("screens:pwaMenu.toBuyerStock"),
            value: StockActions.Collect,
        },
    ];

    useBreadcrumbs([
        {
            text: t("navigation:stock"),
        },
        {
            text:
                UserService.isInRole(Roles.InstallerCompanyAdmin) ||
                UserService.isInRole(Roles.InstallerCompanyUser)
                    ? t("navigation:installerStock")
                    : t("navigation:myStock"),
        },
    ]);

    useEffect(() => {
        loadStockItems(filterState);
    }, [loadStockItems, filterState]);

    useEffect(() => {
        loadGasTypes();
    }, [loadGasTypes]);

    useEffect(() => {
        loadEmployees({});
    }, [loadEmployees]);

    const columns = useMemo(() => {
        const columns = [
            {
                Header: t("table:headers.gasType"),
                accessor: "refrigerantGasTypeCode",
                Cell: GreenCell,
            },
            {
                Header: t("table:headers.gasQuantity"),
                accessor: "quantity",
                Cell: ({ cell: { value } }) =>
                    GreenCell({ cell: { value: value.toFixed(2) + " kg" } }),
            },
            {
                Header: t("table:headers.grade"),
                accessor: "pressumedGradeName",
            },
            {
                Header: t("table:headers.evaluation"),
                accessor: "analysisDone",
                Cell: ({ cell: { row } }) => {
                    const item = row.original;
                    return !item.analysisDone
                        ? t("filters:analysis.selfAssessment")
                        : item.isAnalysisReceived
                        ? t("filters:analysis.measurement")
                        : t("filters:analysis.pendingMeasurement");
                },
            },
            {
                Header: t("stockListing.headers.lastExtraction"),
                accessor: "extractionDateTime",
                Cell: DateCell,
            },
            {
                Header: t("filters:cylinders.type"),
                accessor: "cylinderType",
                Cell: ({ cell: { value } }) => {
                    let typeName =
                        value === 0
                            ? t("filters:cylinders.recovery")
                            : t("filters:cylinders.reclaimed");
                    return typeName;
                },
            },
        ];
        if (
            UserService.isInRole(Roles.InstallerCompanyAdmin) ||
            UserService.isInRole(Roles.InstallerCompanyUser)
        ) {
            columns.splice(4, 0, {
                Header: t("stockListing.headers.onStockWith"),
                accessor: "fullName",
            });
        }
        return columns;
    }, [filterState.filter, t]);

    const [detailViewIsOpened, setDetailViewIsOpened] = useState(false);

    const onSelect = useCallback((row) => {
        const selectRow = async (row) => {
            await loadStockById(row.original.skuId);
            setDetailViewIsOpened(true);
        };
        selectRow(row);
    }, []);

    const closeSideBar = () => {
        setDetailViewIsOpened(false);
    };

    const pageChange = usePageChange(dispatch, filterState);

    /* Wizard */
    const queryUnitTags = async (tag) => {
        if (tag.length > 2) {
            const tags = await TagsService.lookupUnitTags(tag);
            return tags.map((t) => t.id);
        }
        return [];
    };

    const queryCylinderTags = async (tag) => {
        if (tag.length > 2) {
            const tags = await TagsService.lookupExistingCylinderTags(tag);
            return tags.map((t) => t.id);
        }
        return [];
    };

    const queryAnalyzerTags = async (tag) => {
        if (tag.length > 2) {
            const tags = await TagsService.lookupTagForAnalyzerAsync(tag);
            return tags.data.data.map((t) => t.id);
        }
        return [];
    };

    const queryInstallerTag = async (tag) => {
        if (tag.length > 2) {
            const tags = await TagsService.lookupInstallerStockTag(tag, userId);
            return tags.map((t) => t.id);
        }
        return [];
    };

    const queryCompanyTag = async (tag) => {
        if (tag.length > 2) {
            const tags = await TagsService.lookupCompanyStockTag(tag, 0);
            return tags.map((t) => t.id);
        }
        return [];
    };

    const wizardData = usePromises([() => ClientsService.getUnitManufacturersAsync()], (res) => ({
        manufacturers: res[0].data.data,
    }));

    const sortChange = (sortBy) => {
        sortBy.length > 0
            ? dispatch({ type: actionTypes.SET_SORT, payload: sortBy })
            : dispatch({ type: actionTypes.RESET_SORT });
    };

    const currentSortBy = useMemo(() => {
        return [{ id: filterState.orderColumn, desc: filterState.descending }];
    }, [filterState.orderColumn, filterState.descending]);

    const [currentStockItem, setCurrentStockItem] = useState({});
    useEffect(() => {
        setCurrentStockItem(
            stockItems.data.filter((item) => item.skuId === details.stockData.skuId)
        );
    }, [details]);

    const { modalProps: collectInfoModalProps, toggleOpen: toggleCollectInfoModal } = useModal({
        title: t("forms:stock.wizard.collectCylinder.title"),
        type: "warning",
        confirmText: t("actions:close"),
        onConfirm: () => {
            toggleCollectInfoModal();
        },
    });

    const { modalProps: extractionModalProps, toggleOpen: toggleExtractionModal } = useModal({
        title: t("forms:analyzer.wizard.measurementResults"),
        type: "warning",
        confirmText: t("forms:wizard.finish"),
        onConfirm: () => {
            toggleExtractionModal();
            toast.success(t("actions:dialogs.newExtraction.success"));
            loadStockItems(filterState);
            ModalWizard.hide();
        },
    });

    const showMoveToStockWizard = (toInstallerStock = false) => {
        ModalWizard.show(
            MoveToStockWizard(
                t,
                toInstallerStock ? queryCompanyTag : queryInstallerTag,
                toInstallerStock
            ),
            {
                onSubmit: async (id) => {
                    let res;
                    if (toInstallerStock) {
                        res = await StockService.moveToInstallerStock(id.tagId);
                    } else {
                        res = await StockService.moveToCompanyStock(id.tagId);
                    }

                    if (res && res.error) toast.error(t(res.error.message));
                    else {
                        loadStockItems(filterState);
                        toast.success(t("installerStockListing.toastNotification.success"));
                        ModalWizard.hide();
                    }
                },
                onCancel: async () => {
                    ModalWizard.hidePrompt();
                },
            },
            {
                style: {
                    minHeight: "610px",
                },
            }
        );
    };

    const showNewExtractionWizard = () => {
        ModalWizard.show(
            NewExtractionWizard(
                t,
                wizardData.manufacturers,
                gasTypesData,
                queryUnitTags,
                queryCylinderTags,
                queryAnalyzerTags
            ),
            {
                onSubmit: async (values) => {
                    if (values.gradeC) {
                        values.pressumedGrade = 2;
                    } else if (values.gradeB) {
                        values.pressumedGrade = 1;
                    } else {
                        values.pressumedGrade = 0;
                    }
                    values.isContaminated = values.gradeA;
                    values.isMixture = values.gradeB;
                    values.hasR22 = values.gradeC;
                    let analyzer = [];
                    if (values.analyzerTagId) {
                        try {
                            const analyzerData = await CompanyService.getMyGasAnalyzerByTagAsync(
                                values.analyzerTagId
                            );
                            analyzer = analyzerData?.data?.data?.[0];
                        } catch (ex) {
                            analyzer.name = t("screens:statistics.fGasAnalyzerTitle");
                        }
                    }
                    const result = await StockService.addToMyStock(ModalWizard.asFormData(values));

                    if (result && result.error) toast.error(t(result.error.message));
                    else {
                        toggleExtractionModal({ analyzer });
                    }
                },
                onCancel: async () => {
                    ModalWizard.hidePrompt();
                },
            },
            {
                style: {
                    minHeight: "680px",
                },
            }
        );
    };

    const [collectCompanies, setCollectCompanies] = useDataReducer(
        MarketplaceService.getOffersForCollection
    );
    useEffect(() => {
        setCollectCompanies();
    }, []);

    const showCollectWizard = () => {
        ModalWizard.show(
            CollectCylinderWizard(t, collectCompanies),
            {
                onSubmit: async (values) => {
                    // alert('uncomment BE action! Selected tags: ' + values.tagIds);

                    const result = await MarketplaceService.shipToBuyer(values.tagIds);
                    if (result.error) {
                        toast.error(t(result.error.message));
                    } else {
                        loadStockItems(filterState);
                        toast.success(t("actions:dialogs.shipOut.success"));
                        ModalWizard.hide();
                    }
                },
            },
            { style: { height: "700px" } }
        );
    };


    const reportCylinderIssue = async (cylinderId) => {
        ModalWizard.show(
            WizardCylinderIssue(t),
            {
                onSubmit: async (values) => {
                    values.cylinderId = cylinderId;
                    values.issueType = Number(values.issueType);
                    const result = await TagsService.addCylinderIssueAsync(values);
                    if (result && result.error) ModalWizard.submitFailed(t(result.error.message));
                    //toast.error(t(result.error.message));
                    else {
                        toast.success(t("actions:dialogs.reportCylinder.success"));
                        ModalWizard.hide();
                    }
                },
                onCancel: async () => {
                    ModalWizard.hidePrompt();
                },
            },
            { style: { minHeight: "708px" } }
        );
    };

    return (
        <>
            <Helmet>
                <title>{t("seo:installerStock.title")}</title>
            </Helmet>
            <ListingPage
                columns={columns}
                tableState={{
                    data: stockItems.data,
                    loading: isLoading || employeesLoading,
                    error: error || employeesError,
                }}
                paging={{
                    enablePaging: true,
                    pageCount: stockItems.totalPages,
                    pageSize: stockItems.pageSize,
                    onPageChange: pageChange,
                    currentPageIndex: stockItems.currentPage - 1,
                }}
                sorting={{
                    enableSorting: true,
                    onSortByChange: sortChange,
                    currentSortBy: currentSortBy,
                }}
                onSelect={onSelect}
                closeSideBar={closeSideBar}
                detailView={
                    <StockDetailSideBar
                        key={detailViewIsOpened}
                        selectedItem={currentStockItem[0]}
                        stockItem={details.stockData}
                        isLoading={details.isLoading}
                        reportCylinderIssue={reportCylinderIssue}
                    />
                }
                detailSideBarTitle={t("screens:stockListing.sidebarTitle")}
                actions={{
                    headerActions: () => {
                        return (
                            <AuthorizeComponent
                                roles={[
                                    Roles.InstallerCompanyAdmin,
                                    Roles.InstallerCompanyUser,
                                    Roles.InstallerCompanyTechnician,
                                ]}
                            >
                                <Flex.Container justifyContent="flex-end">
                                    <Select
                                        dropdown
                                        name="stockAction"
                                        key="stockAction"
                                        defaultItem={{
                                            key: "all",
                                            value: "",
                                            label: t("screens:companyListing.headers.actions"),
                                        }}
                                        defaultValue=""
                                        options={stockActions.map((action, idx) => ({
                                            key: action.value,
                                            value: action.value,
                                            label: action.text,
                                        }))}
                                        handleChange={(evt) => {
                                            switch (evt.currentTarget.value) {
                                                case StockActions.NewExtraction:
                                                    showNewExtractionWizard();
                                                    break;
                                                case StockActions.ToCompanyStock:
                                                    showMoveToStockWizard();
                                                    break;
                                                case StockActions.ToInstallerStock:
                                                    showMoveToStockWizard(true);
                                                    break;
                                                case StockActions.Collect:
                                                    collectCompanies.data.length === 0
                                                        ? toggleCollectInfoModal()
                                                        : showCollectWizard();
                                                    break;
                                                default:
                                                    break;
                                            }
                                        }}
                                        className="filters action"
                                        labelClassName="sr-only"
                                    />
                                </Flex.Container>
                            </AuthorizeComponent>
                        );
                    },
                }}
                filterTitle={
                    UserService.isInRole(Roles.InstallerCompanyAdmin)
                        ? t("filters:stock.installerFilterTitle")
                        : t("filters:stock.myStockFilterTitle")
                }
                filterComponent={() => (
                    <InstallerStockFilter
                        filterState={filterState.filter}
                        users={employees}
                        gasTypes={gasTypesData.gasTypes}
                        dispatch={dispatch}
                        actionTypes={actionTypes}
                    />
                )}
                detailViewIsOpened={detailViewIsOpened}
                noDataText={t("screens:stockListing.notification")}
                variants={{
                    headerViewVariant: "right-action",
                }}
            />

            <ModalDialog {...collectInfoModalProps}>
                <p>{t("forms:stock.wizard.collectCylinder.warning")}</p>
            </ModalDialog>
            <ModalDialog {...extractionModalProps}>
                <P>{t("forms:analyzer.wizard.meResultsLine1")}</P>
                <P>{extractionModalProps?.modal?.analyzer?.name}</P>
                <P>{t("forms:analyzer.wizard.meResultsLine2")}</P>
                <P>{t("forms:analyzer.wizard.meResultsLine3")}</P>
                <P>{t("forms:analyzer.wizard.meResultsLine4")}</P>
            </ModalDialog>
        </>
    );
};

export default InstallerStockListing;
