import React, { useState, useEffect } from "react";
import DataEntry from "../../components/data-entry/DataEntry";
import commonValidation from "../../core/validations/common";
import { useTranslation } from "react-i18next";
import { buildValidation } from "../../core/validations/ValidationRule";
import ResetPasswordService from "../../services/ResetPassword/ResetPasswordService";
import ValidationRule from "../../core/validations/ValidationRule";
import useQueryString from "../../hooks/_shared/useQueryString";
import { ResetPasswordForm } from "./ResetPasswordForm";

import RouteCreator from "../../core/RouteCreator";

import Headings from "../../components/elements/headings/Headings";
import Flex from "../../components/layout/flex/Flex";
import LayoutOneTwo from "../../components/layout/split/one-two/LayoutOneTwo";
import Wrappers from "../../components/wrappers/Wrappers";
import gasBottle from "../../assets/gasBottle.png";
import NavigationLink from "../../components/elements/links/NavigationLink";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { ReactComponent as WarningSvg } from "../../assets/warning-circle.svg";

const initialValues = {
    password: "",
    repeatPassword: "",
};

const WarningIcon = styled(WarningSvg)`
    min-width: 44px;
    width: 44px;
    height: 44px;
`;

const LinksWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const PasswordExpiredNote = styled.div`
    margin-bottom: 25px;
    /* color: ${(props) => props.theme.secondaryNegativeColor}; */
    font-weight: 800;
    font-size: 20px;
    display: flex;
    width: 100%;
    & > div {
        width: 100%;
        text-align: center;
    }
`;

const OrangeInfo = styled.img`
    width: 44px;
    height: 44px;
    margin-right: 24px;
`;

const BigTitle = styled.div`
    font-size: 20px;
    font-weight: 700;

    &.regular {
        font-weight: 400;
    }

    &.vspace {
        margin-top: 32px;
        margin-bottom: 32px;
    }
`;

const getFormValidation = (t) => {
    return buildValidation({
        password: commonValidation.password(t).rule,
        repeatPassword: ValidationRule.shouldBeEqual(
            "password",
            t("forms:repeatPassword.invalid"),
            t("forms:repeatPassword.required")
        ).rule,
    });
};

const ResetPasswordScreen = () => {
    const { t } = useTranslation();
    const yupValidation = getFormValidation(t);
    const [requestSent, setRequestSent] = useState(false);
    const params = useQueryString();
    const history = useHistory();

    const requestPassReset = async (values) => {
        const result = await ResetPasswordService.sendRequestAsync({
            username: params.username,
            password: values.password,
            token: params.token,
        });
        if (result.error) toast.error(t(result.error.message));
        else {
            setRequestSent(true);
        }
    };

    const resetType =  params.type;

    useEffect(() => {
        if (requestSent) {
            setTimeout(() => {
                window.location.replace(RouteCreator.user.login());
            }, 5000);
        }
    }, [requestSent, history]);

    return (
        <Flex.Container className="full-height">
            <Helmet>
                <title>{t("seo:resetPassword.title")}</title>
            </Helmet>
            <LayoutOneTwo image={gasBottle}>
                <Wrappers.FormWrapper
                    className="login"
                    style={{
                        marginTop: "50px",
                        height: "100%",
                        flexDirection: "column",
                    }}
                >
                    <Headings.H2
                        className="center title bold"
                        style={{ marginBottom: "70px" }}
                    >
                        {t("screens:resetPassword.introText.title")}
                    </Headings.H2>
                    {!requestSent && resetType !== "expired" && (
                        <Flex.Row
                            style={{ display: "flex", alignItems: "center" }}
                        >
                            <OrangeInfo src="/images/menu/orange-info.svg" />
                            <BigTitle className="vspace">
                                {t("screens:resetPassword.accWaiting")}
                            </BigTitle>
                        </Flex.Row>
                    )}
                    {resetType === "expired" && !requestSent && (
                        <>
                            <PasswordExpiredNote>
                                <WarningIcon />
                                <div>
                                    <div>
                                        {t(
                                            "screens:resetPassword.type.expired"
                                        )}
                                    </div>
                                    {t("screens:resetPassword.type.setNew")}
                                </div>
                            </PasswordExpiredNote>
                        </>
                    )}
                    <DataEntry
                        style={{ height: "100%" }}
                        isLoading={false}
                        renderForm={(formik) => (
                            <ResetPasswordForm
                                formik={formik}
                                loading={false}
                                isCompleted={requestSent}
                                type={resetType}
                            />
                        )}
                        initialValues={initialValues}
                        validationSchema={yupValidation}
                        onSubmit={requestPassReset}
                    />
                    <Wrappers.Wrapper
                        className="form-links-wrapper"
                        style={
                            requestSent
                                ? { marginTop: "0px" }
                                : { marginTop: "-30px" }
                        }
                    >
                        <LinksWrapper>
                            <NavigationLink
                                to={RouteCreator.user.login()}
                                className="forgotten-password"
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.location.replace(
                                        RouteCreator.user.login()
                                    );
                                }}
                            >
                                {t("screens:forgottenPassword.backToLoginLink")}
                            </NavigationLink>
                        </LinksWrapper>
                    </Wrappers.Wrapper>
                </Wrappers.FormWrapper>
            </LayoutOneTwo>
        </Flex.Container>
    );
};

export default ResetPasswordScreen;
