import React from 'react';
import { Checkbox } from '../elements/form-elements';

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, inTable, ...rest }, ref) => {
        const defaultRef = React.useRef();
        const resolvedRef = ref || defaultRef;

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate;
        }, [resolvedRef, indeterminate]);

        const clNames = indeterminate ? ["indeterminate"]:[];
        if(inTable) clNames.push("intable");

        return (
            <>
                <Checkbox
                    name="checkbox"
                    ref={resolvedRef}
                    className={clNames}
                    {...rest}
                />
            </>
        );
    }
);

export default IndeterminateCheckbox;
