import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import QRCode from "qrcode.react";
import CompanyService from "../../../services/Company/CompanyService";
import { StyledBack, SvgNoImage, SvgEdit, SvgDelete } from "./StyledSvg";
import ModalDialog, { useModal } from "../../../components/modal/ModalDialog";
import ModalWizard from "../../../components/modal/ModalWizard";
import EditCylinderWizard from "../actions/components/EditCylinderWizard";
import { ScreenWrapper, ScreenHeader, ListTitle, HR, Box16A9, VSpace } from "./StyledElements";

const StyledQR = styled.div`
    width: 100%;
    text-align: center;
    span {
        display: inline-block;
        margin-top: 5px;
        font-size: 10px;
    }
`;
const TagTitle = styled.div`
    width: 100%;
    font-size: 16px;
    font-weight: 700;
    margin-top: 5px;
`;

const Content = styled.div`
    width: 100%;
    /* height: 100%; */
    box-sizing: border-box;
    flex-grow: 1;
    position: relative;
    padding: 35px 60px 0px;
    display: flex;
    flex-direction: column;
    padding: 10px 24px 0px 24px;
`;

const Section = styled.div`
    padding: 12px 20px;
`;

const KeyVal = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    color: #000;
    font-weight: 700;
    margin-bottom: 6px;
    & span {
        font-weight: 500;
        color: ${(p) => p.theme.primaryColor};
    }
`;

const HeaderActions = styled.div`
    & svg + svg {
        margin-left: 18px;
    }
`;

const splitAt = (index) => (x) => [x.slice(0, index), x.slice(index)];

const AnalyzerDetails = ({ analyzer, ...restProps }) => {
    const { t } = useTranslation("screens");

    // const {
    //     modalProps: modalPropsDelete,
    //     toggleOpen: toggleOpenDelete,
    // } = useModal({
    //     title: t("actions:dialogs.deleteCylinder.title"),
    //     onConfirm: async (id) => {
    //         const res = await CompanyService.deleteCylinderAsync(id);
    //         if (res && res.error) {
    //             toast.error(t(res.error.message));
    //         } else {
    //             toast.success(t("cylinderListing.toastNotification.success"));
    //             toggleOpenDelete();
    //             restProps.onGotoStart();
    //         }
    //     },
    //     onCancel: () => {
    //         toggleOpenDelete();
    //     },
    //     confirmText: t("actions:dialogs.deleteEquipment.confirmText"),
    // });

    if (!analyzer) return <ScreenWrapper />;
    const tagParts = analyzer.tagId ? splitAt(4)(analyzer.tagId) : [];

    return (
        <ScreenWrapper className="ScreenWrapper">
            <ScreenHeader>
                <StyledBack
                    onClick={(ev) => {
                        restProps.onGoBack(ev);
                    }}
                />
            </ScreenHeader>
            <HR />
            <ListTitle>{t("screens:statistics.fGasAnalyzerTitle")}</ListTitle>
            <Content>
                <StyledQR>
                    <QRCode value={analyzer.tagId} size={106} />
                    <TagTitle>{tagParts[0]}</TagTitle>
                    <span>{tagParts[1]}</span>
                </StyledQR>

                {/* <ListTitle className="content">
                    {t("screens:technician.details")}
                </ListTitle> */}
                <Section>
                    <KeyVal>
                        {t("forms:analyzer.wizard.name")}
                        <span>{analyzer.name}</span>
                    </KeyVal>

                    <KeyVal>
                        {t("forms:analyzer.wizard.macAddress")}
                        <span>{analyzer.macAddress}</span>
                    </KeyVal>
                    <HR className="content" />

                    <ListTitle className="content outset">
                        {t("screens:technician.gallery")}
                    </ListTitle>
                    <VSpace size="20px" />
                    <Box16A9>
                        {analyzer.imageUrl && analyzer.imageUrl.indexOf("no_image") < 0 ? (
                            <img className="thumb" alt="unit" src={analyzer.imageUrl} />
                        ) : (
                            <SvgNoImage />
                        )}
                    </Box16A9>
                    <HR className="content" />
                    <ListTitle className="content outset">{t("forms:clients.notes")}</ListTitle>
                    <VSpace size="20px" />
                    <p>{analyzer.description ? analyzer.description : "-"}</p>
                </Section>
            </Content>
            {/* <ModalDialog {...modalPropsDelete}>
                <p>{t("actions:dialogs.deleteCylinder.text")}</p>
                <StyledQR className="modal">
                    <TagTitle>{tagParts[0]}</TagTitle>
                    <span>{tagParts[1]}</span>
                </StyledQR>
            </ModalDialog> */}
        </ScreenWrapper>
    );
};

export default AnalyzerDetails;
