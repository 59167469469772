import React from "react";
import styled from "styled-components";

const StyledGreenCell = styled.div`
    color: #48b05e;
    font-weight: 800;

    &.right {
        text-align:right;
    }
`;

const GreenCell = ({ cell: { value }, className }) => {
    return <StyledGreenCell className={className}>{value}</StyledGreenCell>;
};

export default GreenCell;
