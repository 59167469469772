import { config } from "../Config/config.prod";
import api, { apiResult } from "../getAxios";

const CountryService = {
    getCountries: async () => {
        const res = await api.get(config.apiUrl.getCountries);
        return res.data.data;
    },

    getCountriesAsync: async () => {
        return apiResult(api.get(config.apiUrl.getCountries));
    },

    getCountryNames: async (filter) => {
        if (!filter || filter.length < 3) return [];
        return apiResult(api.post(config.apiUrl.getCountryNames, { filter }));
    }
};

export default CountryService;
