import React from "react";
import { Select } from "../../../../../components/elements/form-elements";
import InputElement from "../../../../../components/elements/form-elements/InputElement";
import ValidationRule, {
    buildValidation,
} from "../../../../../core/validations/ValidationRule";
import WizardClientDetails from "../details/WizardClientDetails";

const validAddress = (t) => {
    return buildValidation({
        street: ValidationRule.isStringRule().required(
            t("forms:address.required")
        ).rule,
        locationName: ValidationRule.isStringRule().required(
            t("forms:locationName.required")
        ).rule,
        city: ValidationRule.isStringRule().required(t("forms:city.required"))
            .rule,
        postalCode: ValidationRule.isStringRule().required(
            t("forms:postalCode.required")
        ).rule,
        countryId: ValidationRule.isStringRule().required(
            t("forms:country.required")
        ).rule,
    });
};

const defaultInitialValues = {
    locationName: "",
    clientId: "",
    email: "",
    phoneNumber: "",
    street: "",
    contactPersonFirstName: "",
    contactPersonLastName: "",
    city: "",
    postalCode: "",
    countryId: "",
    region: "",
};

const ClientLocationWizard = (t, initialValues, countries, clientData, isEdit) => {
    return {
        title: isEdit
            ? t("forms:clients.editLocation")
            : t("forms:clients.addLocation"),
        dataTest: "add-client-location-modal",
        initialValues: initialValues || defaultInitialValues,
        steps: [
            {
                name: t("forms:clients.step2Name"),
                fieldNames: [
                    "locationName",
                    "street",
                    "postalCode",
                    "city",
                    "region",
                    "countryId",
                ],
                validation: validAddress(t),
                fields: (formik) => (
                    <>
                        <WizardClientDetails clientData={clientData} />

                        <InputElement
                            labelText={t("forms:locationName.text")}
                            id="locationName"
                            autoComplete="new-locationName"
                            name="locationName"
                            type="text"
                            wrapperVariant="flex-2"
                            {...formik}
                        />

                        <InputElement
                            labelText={t("forms:address.text")}
                            id="street"
                            autoComplete="new-address"
                            name="street"
                            type="text"
                            wrapperVariant="flex-2"
                            {...formik}
                        />

                        <InputElement
                            labelText={t("forms:city.text")}
                            id="city"
                            autoComplete="new-city"
                            name="city"
                            type="text"
                            wrapperVariant="flex-2"
                            {...formik}
                        />

                        <InputElement
                            labelText={t("forms:postalCode.text")}
                            id="postalCode"
                            autoComplete="new-postalCode"
                            name="postalCode"
                            type="text"
                            wrapperVariant="flex-2"
                            {...formik}
                        />

                        <InputElement
                            labelText={t("forms:region.text")}
                            id="region"
                            autoComplete="new-region"
                            name="region"
                            type="text"
                            wrapperVariant="flex-2"
                            {...formik}
                        />

                        <Select
                            id="countryId"
                            name="countryId"
                            labelText={t("forms:country.text")}
                            wrapperVariant="flex-2"
                            defaultItem={{
                                key: "all",
                                value: "",
                                label: t("forms:clients.country"),
                            }}
                            options={
                                countries && countries.length > 0
                                    ? countries.map((country) => {
                                          return {
                                              key: country.id,
                                              value: country.id,
                                              label: country.countryName,
                                          };
                                      })
                                    : []
                            }
                            {...formik}
                        />
                    </>
                ),
            },
            {
                name: t("forms:clients.step3Name"),
                fieldNames: [
                    "email",
                    "phoneNumber",
                    "contactPersonFirstName",
                    "contactPersonLastName",
                ],
                fields: (formik) => (
                    <>
                        <WizardClientDetails clientData={clientData} />

                        <InputElement
                            labelText={t("forms:contactFirstName.text")}
                            name="contactPersonFirstName"
                            autoComplete="new-contactPersonFirstName"
                            type="text"
                            id="contactPersonFirstName"
                            wrapperVariant="flex-2"
                            {...formik}
                        />

                        <InputElement
                            labelText={t("forms:contactLastName.text")}
                            name="contactPersonLastName"
                            type="text"
                            id="contactPersonLastName"
                            autoComplete="new-contactPersonLastName"
                            wrapperVariant="flex-2"
                            {...formik}
                        />

                        <InputElement
                            labelText={t("forms:email.text")}
                            id="email"
                            autoComplete="new-email"
                            name="email"
                            type="text"
                            wrapperVariant="flex-2"
                            {...formik}
                        />

                        <InputElement
                            labelText={t("forms:phoneNumber.text")}
                            id="phoneNumber"
                            autoComplete="new-phoneNumber"
                            name="phoneNumber"
                            type="text"
                            wrapperVariant="flex-2"
                            {...formik}
                        />
                    </>
                ),
            },
        ],
    };
};

export default ClientLocationWizard;
