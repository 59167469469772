import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import DataEntry from "../../../../components/data-entry/DataEntry";
import RoundTabs from "../../../../components/elements/tabs/RoundTabs";
import EffectError from "../../../../components/errors/EffectError";
import LayoutInnerContainer from "../../../../components/layout/split/one/LayoutInnerContainer";
import AccordionWrapper from "../../../../components/toggle-view/AccordionWrapper";
import AuthorizeComponentByUserId from "../../../../core/AuthorizeComponentByUserId";
import helpers from "../../../../core/helpers";
import employeeListStateModule from "../../../../core/state/company/employeeListStateModule";
import useBreadcrumbs from "../../../../core/state/navigation/useBreadcrumbs";
import userStateModule from "../../../../core/state/user/userStateModule.js";
import useStateModule from "../../../../core/state/useStateModule";
import useAuthorization from "../../../../core/useAuthorization";
import commonValidation from "../../../../core/validations/common";
import ValidationRule, {
    buildValidation,
} from "../../../../core/validations/ValidationRule";
import UserService from "../../../../services/User/UserService";
import Change2faSection from "../../../Company/views/employees/edit-employee/Change2faSection";
import ChangePasswordSection from "../../../Company/views/employees/edit-employee/ChangePasswordSection";
import ChangePictureSection from "../../../Company/views/employees/edit-employee/ChangePictureSection";
import AdministratorEditForm from "./AdministratorEditForm";
import { Helmet } from "react-helmet";
import useAccordionGroup from "../../../../hooks/_shared/useAccordionGroup";

const TabContent = styled.div`
    display: ${(props) => (props.show ? "block" : "none")};
`;

const TabsWrapper = styled.div`
    margin: 0px 0px 0px -95px;
    padding: 25px 60px 0px 78px;
    border-radius: 15px 15px 0px 0px;
    background: linear-gradient(
        180deg,
        rgba(245, 245, 245, 1) 0%,
        rgba(240, 240, 240, 1) 100%
    );
    box-shadow: -2px 9px 11px -16px rgba(0, 0, 0, 0.75);
    border-bottom: 1px solid #ffffff;
`;

const defaultEditEmployeeValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    username: "",
};

const tabIndices = {
    Profile: 0,
    Information: 1,
};

const validationEditEmployee = (t) => {
    return buildValidation({
        firstName: ValidationRule.isStringRule().required(
            t("forms:firstName.required")
        ).rule,
        lastName: ValidationRule.isStringRule().required(
            t("forms:lastName.required")
        ).rule,
        email: commonValidation.email(t).rule,
    });
};

const AdministratorEditScreen = () => {
    const { t } = useTranslation();
    const [openAccordion, setOpenAccordion] = useAccordionGroup();
    const { loadUserData } = useStateModule(userStateModule);

    const [tabIndex, setTabIndexState] = useState(tabIndices.Profile);

    /* Edit */
    let { employeeId } = useParams();

    const ownProfile = useAuthorization({
        allowedUserId: employeeId,
    });

    const { loadEmployeeById, details, updateData, resetState, rolesData } =
        useStateModule(employeeListStateModule);

    useEffect(() => {
        loadEmployeeById(employeeId);
    }, [employeeId, loadEmployeeById]);

    useEffect(() => {
        return () => {
            resetState();
        };
    }, [resetState]);

    let [submittedValues, setSubmittedValues] = React.useState({
        values: null,
        fullName: null,
    });

    const handleSubmit = async (
        values,
        { setSubmitting, setErrors, setStatus, resetForm }
    ) => {
        const updateValues = {
            ...values,
            id: employeeId,
        };
        if (ownProfile) {
            await UserService.setUserDetails(updateValues);
            loadUserData();

            toast.success(t("screens:adminEdit.completed.title"));
            setSubmittedValues({
                values: values,
                fullName: values.firstName + " " + values.lastName,
            });
            loadEmployeeById(employeeId);
        }
    };

    const handle2FASubmit = async (values) => {
        const updateValues = {
            ...details.employeeData,
            ...values,
            id: employeeId,
        };
        if (ownProfile) {
            await UserService.setUserDetails(updateValues);
            loadUserData();
            toast.success(t("screens:adminEdit.completed.title"));
            loadEmployeeById(employeeId);
        }
    };

    const twoFAValues = {
        passwordExpiration: details.employeeData.passwordExpiration || 0,
        twoFactorEnabled: details.employeeData.twoFactorEnabled || false,
    };

    let initializedValues = helpers.getInitialFormValues(
        defaultEditEmployeeValues,
        details.employeeData
    );

    if (employeeId) {
        initializedValues["id"] = employeeId;
    }

    const employeeBreadcrumbs = [
        {
            text: "Administrators",
        },
        {
            text: "Edit",
        },
    ];

    const { update: updateBreadcrumbs } = useBreadcrumbs(employeeBreadcrumbs);

    useEffect(() => {
        employeeBreadcrumbs[1].text = details.employeeData.fullName;
        updateBreadcrumbs(employeeBreadcrumbs);
    }, [details.employeeData.fullName]);

    return (
        <div
            style={{
                display: "inline-flex",
                paddingLeft: "96px",
                width: "100%",
            }}
        >
            <Helmet>
                <title>{t("seo:editAdministrator.title")}</title>
            </Helmet>
            <LayoutInnerContainer className="wide">
                <TabsWrapper>
                    <RoundTabs
                        style={{ width: "560px" }}
                        className="minimal"
                        tabIndex={tabIndex}
                        activeFn={setTabIndexState}
                        tabs={[
                            {
                                label: t(
                                    "screens:licenceEdit.profile"
                                ).toUpperCase(),
                                activeValue: tabIndices.Profile,
                            },
                        ]}
                    />
                </TabsWrapper>
                <TabContent show={tabIndex === tabIndices.Profile}>
                    <AccordionWrapper
                        title=""
                        id="unknown"
                        isOpen={openAccordion}
                        onToggle={setOpenAccordion}
                        variant="overflow top-space"
                        style={{ marginTop: "10px" }}
                        noBorder
                    >
                        <ChangePictureSection
                            fullName={submittedValues.fullName}
                            otherUserData={details.employeeData}
                        />
                    </AccordionWrapper>

                    <AccordionWrapper
                        title={t("forms:userAccount.text")}
                        id="userAccount.text"
                        isOpen={openAccordion}
                        onToggle={setOpenAccordion}
                        variant="overflow top-space inset"
                        style={{ marginTop: "10px" }}
                        noBorder
                    >
                        <EffectError error={details.error}>
                            <DataEntry
                                style={{ marginBottom: "20px" }}
                                initialValues={
                                    submittedValues.values || initializedValues
                                }
                                validationSchema={validationEditEmployee(t)}
                                renderForm={(formik) => {
                                    return (
                                        <AdministratorEditForm
                                            formik={formik}
                                            isLoading={
                                                details.isLoading ||
                                                updateData.isLoading
                                            }
                                            roles={rolesData.roles}
                                        />
                                    );
                                }}
                                onSubmit={handleSubmit}
                                isLoading={details.isLoading}
                            />
                        </EffectError>
                    </AccordionWrapper>

                    <AccordionWrapper
                        title={`${t("screens:employeeEdit.title_securitySettings")}`} 
                        id="securitySettings"
                        isOpen={openAccordion}
                        onToggle={setOpenAccordion}
                        variant="overflow top-space inset"
                        noBorder
                    >
                        <AuthorizeComponentByUserId userId={employeeId}>
                            <ChangePasswordSection></ChangePasswordSection>
                            <Change2faSection
                                onSubmit={handle2FASubmit}
                                defaultValues={twoFAValues}
                            ></Change2faSection>
                        </AuthorizeComponentByUserId>
                    </AccordionWrapper>
                </TabContent>
            </LayoutInnerContainer>
        </div>
    );
};

export default AdministratorEditScreen;
