export const config = {
    apiUrl: {
        login: "/Authentication/request",
        registration: "/Users/register",
        resendConfirmation: "/Users/resendConfirmation",
        confirmRegistration: "/Users/confirmRegistration",
        values: "/Values",
        forgottenPassword: "/Users/forgot",
        resetPassword: "/Users/reset",
        activateUser: "/Users/activate",
        getUserData: "/Users/currentUser",
        setUserData: "/Users/updateCurrentUser",
        getUserByUsername: "Users",
        getUserById: "/Users/getUserById",
        changeEmail: "/Users/changeEmail",
        confirmEmail: "/Users/confirmEmail",
        setUserCertificate: "/users/updateCurrentUserCertificate",
        setUserPicture: "/users/updateMyProfilePicture",
        deleteUserPicture: "/users/DeleteMyProfilePicture",
        setUserPassword: "/users/updateMyPassword",
        getCountries: "/Countries/countries",
        companyUpdate: "/Companies/update",
        updateCompanyProfile: "/Companies/updateProfile",
        getCompanyData: "/Companies",
        getCompanyDataByUserID: "/Companies/getCompanyByUserId",
        setCompanyCertificate: "/companies/updateMyCompanyCertificate",
        getCompaniesWithAdmin: "/companies/companiesWithAdmin",
        getDistributors: "/Companies/distributors",
        getCompanyEquipment: "/Companies/myCompanyEquipment",
        getCompanyCylinders: "/Companies/myCompanyCylinders",
        getCompanyAnalyzers: "/Companies/myCompanyGasAnalyzers",
        getCompanyEquipmentById: "/Equipment/getMyEquipmentById",
        createCompanyEquipment: "/Equipment/addMyEquipment",
        updateCompanyEquipment: "/Equipment/updateMyEquipment",
        getCompanyEmployees: "/Companies/myCompanyUsers",
        getCompanyEmployeeById: "/Users/getUserById",
        getAdministrators: "/Users/adminUsers",
        getNonAdministrators: "/Users/nonAdminUsers",
        createAdministrator: "/Users/addAdminUser",
        getAdminRoles: "/Roles/adminRoles",
        createCompanyEmployee: "/Users/addMyEmployee",
        updateCompanyEmployee: "/Users/updateMyEmployee",
        setCompanyEmployeeCertificate: "/Users/updateCertificateByUserId",
        getCompanyRoles: "/Roles/employeeRoles",
        deleteEquipment: "/Equipment/delete",
        deleteUser: "/Users/delete",
        deleteCompany: "/Companies/delete",
        deleteLicence: "/Licences/delete",
        getRefrigerantTypes: "/Stock/refrigerants",
        getMarketPlaceStatuses: "/Stock/marketplaceStatuses",
        getLocationStatuses: "/Stock/marketplaceStatuses",
        addToMyStock: "/Stock/addToMyStock",
        getMyCompanyStock: "/Stock/myCompanyStock",
        analyzeImage: "/Stock/getOCRData",
        updateLocationStatus: "/Stock/updateLocationStatus",
        unpublishFromMarketplace: "/Marketplace/unpublishOffer",
        publishSkus: "/Marketplace/publishSKUs",
        getMySellingItems: "/Marketplace/myOffers",
        getAllItemsForSale: "/Marketplace/allOffers",
        addBid: "/Marketplace/addBid",
        getBids: "/Marketplace/getBids",
        acceptBid: "/Marketplace/acceptBid",
        rejectBid: "/Marketplace/rejectBid",
        getOffersWithMyBids: "/Marketplace/getOffersWithMyBids",
        getMyBids: "/Marketplace/getMyBids",
        revokeBid: "/Marketplace/revokeBid",
        payForBid: "/Marketplace/payBid",
        getOffers: "/Marketplace/getoffers",
        enableDisableUser: "/Users/enableDisableUser",
        extractedFGas: "/StockDashBoard/extractedFGas",
        extractedFGasAdmin: "/StockDashBoard/extractedFGasAdmin",
        extractions: "/StockDashBoard/extractions",
        extractionsAdmin: "/StockDashBoard/extractionsAdmin",
        dashboardCompanyStock: "/StockDashBoard/companyStock",
        dashBoardTechnicianStock: "/StockDashBoard/technicianStock",
        dashBoardMarketPlaceTrades: "/MarketplaceDashboard/tradedRefrigerants",
        dashBoardMarketPlaceTradesPerDate: "/MarketplaceDashboard/tradedRefrigerantsPerDate",
        dashBoardMarketPlaceAverages: "/MarketplaceDashboard/refrigerantAverages",
        dashBoardMarketPlaceAveragesAdmin: "/MarketplaceDashboard/refrigerantAveragesAdmin",
        getMarketplaceCounts: "/Statistics/getMarketplaceCounts",
        getUserCounts: "/Statistics/getUsersCounts",
        getUserOnboarding: "/Statistics/getUserOnboardingPerDate",
        getCompanyOnboarding: "/Statistics/GetCompanyOnboardingPerDate",
        getCompaniesCounts: "/Statistics/getCompaniesCounts",
        enableDisableEmployee: "/Users/enableDisableEmployee",
        qualityTest: "/Marketplace/qualityTest",
        shipToBuyer: "/Marketplace/shipToBuyer",
        offerSold: "/Marketplace/offerSold",
        offerSoldByBuyer: "/Marketplace/offerSoldBuyer",
        addBuyerAnalysis: "/Marketplace/addBuyerAnalysis",
        rejectBidDueToExpiration: "/Marketplace/rejectBidDueToExpiration",
        dispute: "/Marketplace/addDispute",
        getDisputes: "/Marketplace/disputes",
        getDisputeDetails: "Marketplace/getDisputeDetails",
        addDisputeDetails: "Marketplace/addDisputeDetails",
        finishDispute: "Marketplace/finishDispute",
        getClients: "/Clients/clients",
        getClientById: "/Clients",
        setClient: "/Clients/add",
        updateClient: "/Clients/update",
        deleteClient: "/Clients/deleteClient",
        getClientLocations: "/Clients/locations",
        getClientLocationById: "/Clients/location",
        addClientLocation: "/Clients/addLocation",
        updateClientLocation: "/Clients/updateLocation",
        deleteClientLocation: "/Clients/deleteLocation",
        getUnits: "/Clients/units",
        getUnitById: "/Clients/unit",
        getUnitManufacturers: "/Clients/manufacturers",
        addUnit: "/Clients/addUnit",
        updateUnit: "/Clients/updateUnit",
        deleteUnit: "/Clients/deleteUnit",
        generateQr: "/tags/generate",
        requestQR: "/tags/request",
        tagsLookup: "/tags/lookup",
        tagsIdentify: "/tags/identify",
        getCompanyCylinderById: "/Equipment/getMyCylinderById",
        createCompanyCylinder: "/Equipment/addMyCylinder",
        updateCylinder: "/Equipment/updateMyCylinder",
        deleteCylinder: "/Equipment/deleteCylinder",
        getMyGasAnalyzerById: "/Equipment/getMyGasAnalyzerById",
        getMyGasAnalyzerByTag: "/Equipment/getMyGasAnalyzerByTag",
        createGasAnalyzer: "/Equipment/addMyGasAnalyzer",
        updateGasAnalyzer: "/Equipment/updateMyGasAnalyzer",
        deleteGasAnalyzer: "/Equipment/deleteGasAnalyzer",
        getMyBankAccounts: "/Mango/myBankAccounts",
        createBankAccount: "/Mango/createBankAccount",
        deactivateBankAccount: "/Mango/deactivateBankAccount",
        getMyKYCDocuments: "/Mango/myKYCDocuments",
        getMyUbos: "/Mango/myUbos",
        addUbo: "/Mango/createUBODeclaration",
        updateUbo: "/Mango/updateUBO",
        deleteUbo: "/Mango/deleteUBO",
        submitUbo: "/Mango/submitUBO",
        submitKYC: "/Mango/submitKYCDocument",
        addKYCDocument: "/Mango/addKYCDocument",
        getMyWallets: "/Mango/myWallets",
        getTransactionDetails: "/Mango/getTransactionDetails",
        getTransactionDetailsById: "/Mango/getTransactionDetailsById",
        getMyTransactions: "/Mango/myTransactions",
        getMyGroupedTransactions: "/Mango/myGroupedTransactions",
        payout: "/Mango/createPayout",
        getTransactionsFromMango: "/Mango/myMangoTransactions",
        downloadMyTransactions: "/Mango/download",
        getOffersForCollection: "/Marketplace/getOffersForCollection",
        payAClass: "/Marketplace/payAClass",
        topUp: "/Mango/topUp",
        getMyInvoices: "/Mango/myInvoices",
        getMyDocuments: "/Documents/myDocumentList",
        downloadMyDocuments: "/Documents/documentsZip",
        getNationalities: "/Countries/nationalities",
        getCompaniesByUserId: "/Users/getCompaniesByUserId",
        updateCompanyForCurrentUser: "/Users/updateCompanyForCurrentUser",
        getCountryNames: "/Countries/countryNames",
        getAnalysesZip: "/Marketplace/getAnalysesZip",
        transactionDetails: "/Mango/transactionDetails",
        sendToRecycling: "/Stock/sendToRecycling",
        emptyCylinder: "/Stock/emptyCylinder",
        collectEmptyCylinder: "/Stock/collectEmptyCylinder",
        isFGasLicenceMandatory: "Users/isFGasLicenceMandatory ",
        cleaningServiceListing: "/Stock/cleaningServiceListing",
        cleaningServiceListingInstaller: "/Stock/cleaningServiceListingInstaller",
        addReclaimOffer: "/Marketplace/addReclaimOffer",
        getDistributorReclaimedOffers: "/Marketplace/getDistributorReclaimedOffers",
        getInstallationCompanyReclaimedOffers: "/Marketplace/getInstallationCompanyReclaimedOffers",
        unpublishReclaimedOffer: "/Marketplace/unpublishReclaimedOffer",
        getReclaimedOfferById: "/Marketplace/getReclaimedOfferById",
        acceptReclaimOffer: "/Marketplace/acceptReclaimOffer",
        cancelReclaimedOffer: "/Marketplace/cancelReclaimedOffer",
        getReclaimTransactionDetails: "/Mango/getReclaimTransactionDetails",
        myEmployeesCertificates: "/Certificates/myEmployeesCertificates",
        myCompanyCertificates: "/Certificates/myCompanyCertificates",
        addCompanyCertificate: "/Certificates/addCompanyCertificate",
        addUserCertificate: "/Certificates/addUserCertificate",
        employeesCertificates: "/Certificates/employeesCertificates",
        companyCertificates: "/Certificates/companyCertificates",
        updateCertificateStatus: "/Certificates/updateCertificateStatus",
        updateShowWarningsSetting: "/Users/updateShowWarningsSetting",
        addCylinderIssue: "/Support/addCylinderIssue",
        addSupportMessage: "/Support/addMessage",
        getSpocDetails: "/Support/getSpocDetails",
        setPartnerStatus: "/Companies/updatePartnerStatus",
        getPartnerStatus: "/Companies/partnerStatus",
    },
    settings: {
        defaultDebounceDelay: 500,
    },
};
