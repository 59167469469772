import MarketplaceService from "../../../services/BuyRefrigerant/MarketplaceService";
import SellRefrigerantService from "../../../services/SellRefrigerant/SellRefrigerantService";
import createListStateModule from "../createListStateModule";
import gasTypesStatePlugin from "../plugins/gasTypesStatePlugin";

const myBidsListingStateModule = createListStateModule({
    stateNamespace: "mybids",
    itemsPropertyName: "myBids",
    loadItemsMethodName: "loadMyBids",
    itemsDataMethod: MarketplaceService.getOffersWithMyBids,
    loadDetailMethodName: "loadBidById",
    detailDataMethod: SellRefrigerantService.getSellingItemById,
    detailsPropertyName: "bidDetails",
    updateMethodName: "updateBid",
    updateDataMethod: () => {},
    createMethodName: "createBid",
    createDataMethod: () => {},
    defaultItemsData: {data: []},
    plugins: [gasTypesStatePlugin]
});

export default myBidsListingStateModule;
