import React from "react";
import {Select} from "../../../../../components/elements/form-elements";
import InputElement from "../../../../../components/elements/form-elements/InputElement";
import ValidationRule, {
    buildValidation,
} from "../../../../../core/validations/ValidationRule";
import WizardClientDetails from "../details/WizardClientDetails";
import MoneyInputElement from "../../../../../components/elements/form-elements/MoneyInputElement";

const validUnit = (t) => {
    return buildValidation({
        manufacturerId: ValidationRule.isStringRule().required(
            t("forms:manufacturer.required")
        ).rule,
        applicationType: ValidationRule.isStringRule().required(
            t("forms:application.required")
        ).rule,
        refrigerantId: ValidationRule.isStringRule().required(
            t("forms:gasType.required")
        ).rule,
        capacity: ValidationRule.isNumber().required(
            t("forms:capacity.required")
        ).rule,
    });
};

const defaultInitialValues = {
    manufacturerId: "",
    applicationType: "",
    refrigerantId: "",
    capacity: "0.00",
    description: "",
    images: "",
};
const applicationTypes = ["Residential", "Commercial", "Industrial"];

const UnitWizardEdit = (
    t,
    initialValues,
    clientData,
    manufacturers,
    refrigerants,
    searchFn = () => {}
) => {
    return {
        title: t("forms:clients.editUnit"),
        initialValues: initialValues || defaultInitialValues,
        steps: [
            {
                name: t("forms:clients.step1NameUnits"),
                fieldNames: [
                    "manufacturerId",
                    "applicationType",
                    "refrigerantId",
                    "capacity",
                ],
                validation: validUnit(t),
                fields: (formik) => (
                    <>
                        <WizardClientDetails clientData={clientData} />
                        <Select
                            id="manufacturerId"
                            name="manufacturerId"
                            labelText={t("forms:manufacturer.text")}
                            wrapperVariant="flex-2"
                            defaultItem={{
                                key: "all",
                                value: "",
                                label: t("forms:manufacturer.text"),
                            }}
                            options={
                                manufacturers && manufacturers.length > 0
                                    ? manufacturers.map((item) => {
                                          return {
                                              key: item.id,
                                              value: item.id,
                                              label: item.name,
                                          };
                                      })
                                    : []
                            }
                            {...formik}
                        />
                        <Select
                            id="applicationType"
                            name="applicationType"
                            labelText={t("forms:application.text")}
                            wrapperVariant="flex-2"
                            defaultItem={{
                                key: "all",
                                value: "",
                                label: t("forms:application.text"),
                            }}
                            options={applicationTypes.map((item, idx) => {
                                return {
                                    key: idx,
                                    value: idx.toString(),
                                    label: `${t(
                                        "common:applicationType." +
                                            item.toLowerCase()
                                    )}`,
                                };
                            })}
                            {...formik}
                        />

                        <Select
                            id="refrigerantId"
                            name="refrigerantId"
                            labelText={t("forms:gasType.text")}
                            wrapperVariant="flex-2"
                            defaultItem={{
                                key: "all",
                                value: "",
                                label: t("forms:gasType.text"),
                            }}
                            options={
                                refrigerants && refrigerants.length > 0
                                    ? refrigerants.map((item) => {
                                          return {
                                              key: item.id,
                                              value: item.id,
                                              label: item.code,
                                          };
                                      })
                                    : []
                            }
                            {...formik}
                        />

                        <MoneyInputElement
                            type="number"
                            name="capacity"
                            id="capacity"
                            labelText={t("forms:capacity.text")}
                            wrapperVariant={"flex-2 standard"}
                            values={2}
                            min={0}
                            step="0.01"
                            positive={true}
                            formik={formik}
                            currencyLabel="kW"
                        />
                    </>
                ),
            },
            {
                name: t("forms:clients.step4NameNotes"),
                fieldNames: ["description"],
                fields: (formik) => (
                    <>
                        <WizardClientDetails clientData={clientData} />

                        <InputElement
                            labelText={t("forms:clients.notes")}
                            id="description"
                            autoComplete="new-description"
                            name="description"
                            type="textarea"
                            wrapperVariant="flex-1"
                            {...formik}
                        />
                    </>
                ),
            },
            {
                name: t("forms:clients.step2NameUnits"),
                fieldNames: ["images"],
                fields: (formik) => (
                    <>
                        <InputElement
                            labelText=""
                            placeholder={t("forms:userPicture.text")}
                            title={t("forms:clients.unitPictures")}
                            name="images"
                            autoComplete="new-images"
                            type="gallery"
                            id="images"
                            wrapperVariant="flex-1"
                            {...formik}
                        />
                    </>
                ),
            },
        ],
    };
};

export default UnitWizardEdit;
