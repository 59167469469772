import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import Flex from "../../../../components/layout/flex/Flex";
import ModalDialog, {
    useModal,
} from "../../../../components/modal/ModalDialog";
import AuthorizeComponent from "../../../../core/AuthorizeComponent";
import Roles from "../../../../core/enums/Roles";
import ActionButton from "../../../../components/elements/buttons/ActionButton";

const StockListHeaderActions = ({
    publishMultipleToMarketplace,
    shouldAllowPublish,
    listingPageState,
    shouldShowDisposeButton,
}) => {
    const { t } = useTranslation("screens");

    const [currentTranslations, setCurrentTranslations] = useState([]);

    const getTranslations = useCallback(() => {
        setCurrentTranslations([]);
        let translationValues = "";
        listingPageState.selectedRows.map((r, index) => {
            translationValues +=
                "<br />" +
                (index + 1) +
                ": " +
                r.original.refrigerantGasTypeCode +
                " | " +
                r.original.quantity?.toFixed(2) +
                "kg" +
                " | " +
                r.original.pressumedGradeName +
                " | " +
                (r.original.analysisDone
                    ? t("filters:analysis.measurement")
                    : t("filters:analysis.selfAssessment"));
        });
        setCurrentTranslations(translationValues);
        toggleOpen();
    }, [listingPageState]);

    const { modalProps, toggleOpen } = useModal({
        title: t("sidebar:button.bid_publishOffer").toUpperCase(),
        type: "info",
        onConfirm: () => {
            publishMultipleToMarketplace();
            toggleOpen();
        },
        onCancel: () => {
            toggleOpen();
        },
        confirmText: t("actions:dialogs.publishStockItem.confirmText"),
    });

    const footerButton = (
        <>
            <AuthorizeComponent
                roles={[
                    Roles.InstallerCompanyAdmin,
                    Roles.InstallerCompanyUser,
                ]}
            >
                <Flex.Container justifyContent="flex-end">
                    {!shouldShowDisposeButton() && (
                        <ActionButton
                            className={ shouldAllowPublish() ? "primary": "invisible"}
                            onClick={getTranslations}
                            tooltip={t("screens:stockListing.tooltip.publish")}
                            disabled={!shouldAllowPublish()}
                        >
                            {t("actions:sellSelected")}
                        </ActionButton>
                    )}
                    {shouldShowDisposeButton() && (
                        <ActionButton
                            className="primary"
                            onClick={getTranslations}
                            tooltip={t("screens:stockListing.tooltip.dispose")}
                            disabled={false}
                        >
                            {t("actions:disposeSelected")}
                        </ActionButton>
                    )}
                </Flex.Container>
            </AuthorizeComponent>

            <ModalDialog {...modalProps}>
                <p>
                    <p>{t("actions:dialogs.publishStockItem.text")}</p>
                </p>
                <p
                    dangerouslySetInnerHTML={{
                        __html: t(
                            "actions:dialogs.publishMultipleStockItems.text",
                            { item: currentTranslations }
                        ),
                    }}
                />
            </ModalDialog>
        </>
    );

    return footerButton;
};
export default StockListHeaderActions;
