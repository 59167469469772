import React from "react";
import styled from "styled-components";

const Color = require("color");

const Element = styled.div`
    &.loader {
        display: flex;
        opacity: 0;
        pointer-events: none;
        position: fixed;
        width: 100%;
        margin: 0 auto;
        text-align: center;
        height: 100%;
        background: rgba(245, 245, 245, 0);
        top: 0;
        left: 0;
        z-index: 90000;
        justify-content: center;
        align-items: center;
        transition: all 0.3s ease-in 0.2s;
        & .loader__wrapper{
            display:none;
        }
    }
    &.loader.show {
        opacity: 1;
        background: rgba(245, 245, 245, 0.4);
        pointer-events: auto;
        & .loader__wrapper{
            display:block;
        }
    }
    .loader__wrapper {
        position: relative;
        width: 80px;
        height: 20px;
    }
    .loader__wrapper div {
        position: absolute;
        top: 33px;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }
    .loader__wrapper div:nth-child(1) {
        left: 8px;
        animation: lds-ellipsis1 0.6s infinite;
        background: ${(props) => props.theme.primaryColor};
    }
    .loader__wrapper div:nth-child(2) {
        left: 8px;
        animation: lds-ellipsis2 0.6s infinite;
        background: ${(props) =>
            Color(props.theme.primaryColor).lighten(0.2).string()};
    }
    .loader__wrapper div:nth-child(3) {
        left: 32px;
        animation: lds-ellipsis2 0.6s infinite;
        background: ${(props) =>
            Color(props.theme.primaryColor).lighten(0.3).string()};
    }
    .loader__wrapper div:nth-child(4) {
        left: 56px;
        animation: lds-ellipsis3 0.6s infinite;
        background: ${(props) =>
            Color(props.theme.primaryColor).lighten(0.4).string()};
    }
    @keyframes lds-ellipsis1 {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    }
    @keyframes lds-ellipsis3 {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(0);
        }
    }
    @keyframes lds-ellipsis2 {
        0% {
            transform: translate(0, 0);
        }
        100% {
            transform: translate(24px, 0);
        }
    }
`;

const Loader = () => {
    return (
        <Element className="loader" id="apiLoader">
            <div className="loader__wrapper">
                <div />
                <div />
                <div />
                <div />
            </div>
        </Element>
    );
};

export default Loader;
