import styled from "styled-components";
import Flex from "../../components/layout/flex/Flex";
import { ReactComponent as NoDataSvg } from "../../assets/no-chart-data.svg";
import { NavLink } from "react-router-dom";

const ChartHeaderContainer = styled.div`
    padding: 25px 30px 0px 30px;
    display: flex;
    flex-direction: column;

    &.grow {
        flex-grow: 1;
    }
`;

const RelativeDiv = styled.div`
    position: relative;
    height: 100%;
`;

const LeftContainer = styled(Flex.Container)`
    width: 80%;

    @media all and (max-width: ${props => props.theme.extraLargeDevices}) {
        order: 2;
        width: 100%;
        margin: 40px 0px 0px 0px;
    }
`;

const RightContainer = styled(Flex.Column)`
    min-width: 260px;
    width: 20%;

    
    @media all and (max-width: ${props => props.theme.extraLargeDevices}) {
        width: 100%;
    }

`;
const NoDataWrapper = styled.div`
    opacity: 0;
    width: 100%;
    height: calc(100% - 40px);
    position: absolute;
    top: 40px;
    left: 0;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #d5d5d5;
    transition: opacity 0s;

    &.show {
        opacity: 1;
        transition: opacity 1s;
    }

    &.bar {
        top: 0px;
    }
`;

const LineChartContainer = styled(Flex.Column)`
    padding-top: 25px;
    min-width: 200px;
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const SvgNoData = styled(NoDataSvg)`
    width: 100%;
`;

const BarChartMargin = {
    top: 40,
    bottom: 5,
    left: -30,
};

const BarChartMarginSides = {
    left: 40,
    right: 40,
};

const LegendWrapper = {
    top: 0,
    right: -30,
};

const MiddleContainer = styled(Flex.Column)`
    padding-top: 25px;
    min-width: 200px;
    flex: 1;
    display: flex;
    flex-direction: column;
`;


const Column = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 24px;
    min-height: 685px;

    &.between {
        justify-content: space-between;
    }

    &.border {
        border: 1px solid #979797;
        border-radius: 12px;
    }
`;

const Link = styled(NavLink)`
    cursor: pointer;
    min-width: 70px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    text-align: center;
    padding: 0 20px;
    position: relative;
    text-align: center;
    border: none;
    background: none;
    font-weight: 600;
    color:${p => p.theme.secondaryColor};
    text-decoration: none;
`;

const DashboardStyles = {
    ChartHeaderContainer: ChartHeaderContainer,
    RightContainer: RightContainer,
    RelativeDiv: RelativeDiv,
    NoDataWrapper: NoDataWrapper,
    LineChartContainer: LineChartContainer,
    LeftContainer: LeftContainer,
    SvgNoData: SvgNoData,
    Legend: {
        Wrapper: LegendWrapper,
    },
    BarChart: {
        MarginSides: BarChartMarginSides.left,
        Margin: BarChartMargin
    },
    MiddleContainer: MiddleContainer,
    Column: Column,
    Link: Link

};

export default DashboardStyles;