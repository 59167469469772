import React from "react";
import styled, { css } from "styled-components";

const StyledInputSubmit = styled.input.attrs({ type: "submit" })`
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border-radius: ${(props) => props.theme.buttonsBorderRadius};
    min-width: ${(props) => props.theme.widthButtonMin};
    padding: ${(props) => props.theme.paddingButton};
    box-shadow: ${(props) => props.theme.buttonShadow};
    height: ${(props) => props.theme.heightButtonBig};
    line-height: ${(props) => props.theme.heightButtonBig};
    vertical-align: middle;
    font-size: 0.875rem;

    &:hover {
        background: ${(props) => props.theme.primaryColorHover};
    }

    &.primary {
        background: ${(props) => props.theme.primaryColor};
        border: 1px solid ${(props) => props.theme.primaryColorBorder};
        color: ${(props) => props.theme.primaryColorText};
        min-width: ${(props) => props.theme.widthButtonMin};

        &:hover {
            background: ${(props) => props.theme.primaryColorHover};
        }
    }

    &.secondary {
        background: ${(props) => props.theme.secondaryColor};
        border: 1px solid ${(props) => props.theme.secondaryColorBorder};
        color: ${(props) => props.theme.secondaryColorText};
        min-width: ${(props) => props.theme.widthButtonMin};

        &:hover {
            background: ${(props) => props.theme.primaryColorHover};
        }
    }

    &.tertiary {
        background: ${(props) => props.theme.tertiaryColor};
        border: 1px solid ${(props) => props.theme.tertiaryColorBorder};
        color: ${(props) => props.theme.primaryColorText};
        min-width: ${(props) => props.theme.widthButtonMin};

        &:hover {
            background: ${(props) => props.theme.primaryColorHover};
        }
    }

    &.fill {
        width: 100% !important;
    }

    &.link {
        background: transparent;
        height: 25px;
        box-shadow: none;
        border: none;
        line-height: 25px;
        font-size: 16px;
        padding: 0;
        text-align: left;
        color: ${(p) => p.theme.primaryColor};
        font-weight: 500;
        transition: all 0s;
    }
    &.anchor {
        background: #675b34;
        border: 1px solid #675b34;
        height: 32px;
        line-height: 32px;
        color: #fff;
        box-shadow: 4px 4px 8px 0 rgba(34, 34, 34, 0.29);
        font-size: 0.875rem;
        text-align: left;
        padding: 0 20px;
        position: relative;
        border-radius: 32px;
        text-align: center;
    }

    &.big {
        height: ${(props) => props.theme.heightButtonBig};
        line-height: ${(props) => props.theme.heightButtonBig};
        border-radius: ${(props) => props.theme.heightButtonBig};
    }

    &.link:hover {
        font-weight: 600;
    }

    &.inline {
        display: inline;
        width: auto;
    }

    &.horizontal-space {
        margin: 0 10px;
    }

    ${(props) =>
        props.disabled &&
        css`
            cursor: disabled;
            filter: grayscale(1) opacity(0.3);
        `}
`;

const InputSubmit = React.forwardRef((props, ref) => {
    return <StyledInputSubmit ref={ref} className={props.variant} {...props} />;
});
export default InputSubmit;
