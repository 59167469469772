import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import ModalWizard from "../../../components/modal/ModalWizard";
import AddAnalyzerWizard from "./components/AddAnalyzerWizard";
import TagsService from "../../../services/Tags/TagsService";
import CompanyService from "../../../services/Company/CompanyService";
import UserService from "../../../services/User/UserService";
import { toast } from "react-toastify";
import RouteCreator from "../../../core/RouteCreator";

const ScreeWrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    display: flex;
    flex-wrap: nowrap;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
`;

const AddAnalyzer = () => {
    const { t } = useTranslation("screens");
    const history = useHistory();
    const { UserId } = UserService.getUserDetailsFromToken();

       const queryUnusedTags = async (tag) => {
        if (tag.length > 2) {
            const tags = await TagsService.lookupUnassignedTags(tag, UserId);
            return tags.map((t) => t.id);
        }
        return [];
    };

    useEffect(() => {
        const showWizard = async () => {
            ModalWizard.show(
                AddAnalyzerWizard(t, queryUnusedTags),
                {
                    onSubmit: async (values) => {
                        const result = await CompanyService.createGasAnalyzerAsync(
                            ModalWizard.asFormData(values)
                        );
                        if (result && result.error)
                            ModalWizard.submitFailed(t(result.error.message));
                        else {
                            toast.success(
                                t("actions:dialogs.createEquipment.toastNotification.success")
                            );
                            ModalWizard.hide();
                            history.push(RouteCreator.pwa.analyzers());
                        }
                    },
                    onCancel: async () => {
                        ModalWizard.hidePrompt(() => history.goBack());
                    },
                },
                { pwa: true }
            );
        };
        showWizard();
    }, []);

    return <ScreeWrapper></ScreeWrapper>;
};

export default AddAnalyzer;
