import React from "react";
import * as yup from "yup";
import { Checkbox } from "../../../../../components/elements/form-elements";
import DatePicker from "../../../../../components/elements/form-elements/DatePicker";
import InputElement from "../../../../../components/elements/form-elements/InputElement";
import Flex from "../../../../../components/layout/flex/Flex";
import {
    InfoTitle,
    InfoWrapper,
    RoundInfo,
    InfoText,
} from "../../../../../components/modal/components/WizardStyling";
import ValidationRule, {
    buildValidation,
} from "../../../../../core/validations/ValidationRule";

const licenceValidation = (t) =>
    yup.lazy((values) => {
        return buildValidation({
            fGasCertificate: ValidationRule.isStringRule().required(
                t("forms:fGasCertificate.required")
            ).rule,
            licenceNumber: ValidationRule.isStringRule().required(
                t("forms:licenceNumber.required")
            ).rule,
            licenceExpirationDate: values.licenceNeverExpires
                ? yup.string()
                : ValidationRule.isStringRule().required(
                      t("forms:licenceExpirationDate.required")
                  ).rule,
        });
    });

const defaultInitialValues = {
    licenceNeverExpires: false,
    fGasCertificate: "",
    licenceNumber: "",
    licenceExpirationDate: "",
};

const AddCertificateWizard = (t, id) => {
    let certType = id === null ? "Company" : "Personal";
    let certValidForever = defaultInitialValues.licenceNeverExpires;

    const handleLicenceCheck = (ev, formik) => {
        certValidForever = ev.target.checked;
        formik.setFieldValue("licenceNeverExpires", ev.target.checked);
        if (certValidForever) formik.setFieldValue("licenceExpirationDate", "");
    };

    return {
        title: t(`screens:certificates.newCertWizard.title${certType}`),
        initialValues: defaultInitialValues,
        steps: [
            {
                name: t(
                    `screens:certificates.newCertWizard.subtitle${certType}`
                ),
                fieldNames: [
                    "licenceNeverExpires",
                    "fGasCertificate",
                    "licenceNumber",
                    "licenceExpirationDate",
                ],
                validation: licenceValidation(t),
                fields: (formik) => (
                    <>
                        <InfoWrapper className="tight">
                            <RoundInfo
                                alt=""
                                src="/images/menu/green-info.svg"
                            />
                            <div>
                                <InfoTitle>
                                    {t(
                                        `screens:certificates.newCertWizard.${
                                            certType === "Company"
                                                ? "infoTitleCompany"
                                                : "infoTitlePersonal"
                                        }`
                                    )}
                                </InfoTitle>
                                <InfoText>
                                    {t(
                                        `screens:certificates.newCertWizard.${
                                            certType === "Company"
                                                ? "infoTextCompany"
                                                : "infoTextPersonal"
                                        }`
                                    )}
                                </InfoText>
                            </div>
                        </InfoWrapper>

                        <Flex.Row
                            style={{
                                width: "100%",
                                display: "flex",
                                flexWrap: "wrap",
                            }}
                        >
                            <InputElement
                                labelText={t("screens:certificates.tableHeaders.certNumber")}
                                id="licenceNumber"
                                name="licenceNumber"
                                type="text"
                                wrapperVariant="flex-2"
                                {...formik}
                            />

                            <DatePicker
                                labelText={t(
                                    "forms:licenceExpirationDate.text"
                                )}
                                id="licenceExpirationDate"
                                name="licenceExpirationDate"
                                wrapperVariant="flex-2"
                                disabled={certValidForever}
                                formik={formik}
                                filter=""
                                {...formik}
                            />
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "right",
                                    paddingRight: "12px",
                                }}
                            >
                                <Checkbox
                                    labelText={t(
                                        "screens:certificates.neverExpires"
                                    )}
                                    id="licenceNeverExpires"
                                    name="licenceNeverExpires"
                                    onChange={(ev) =>
                                        handleLicenceCheck(ev, formik)
                                    }
                                    {...formik}
                                />
                            </div>

                            <InputElement
                                labelText=""
                               
                                placeholder={t(`screens:certificates.newCertWizard.title${certType}`)}
                                title={t(`screens:certificates.newCertWizard.title${certType}`)}
                                id="fGasCertificate"
                                type="singlefile"
                                name="fGasCertificate"
                                className="wide-2"
                                style={{ width: "100%", padding:"0px 16px 0px 12px" }}
                                accept="application/pdf, image/*"
                                {...formik}
                            />

                        </Flex.Row>
                    </>
                ),
            },
        ],
    };
};

export default AddCertificateWizard;
