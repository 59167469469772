import React, {useState} from "react";
import styled from "styled-components";
import globals from "../../../globalVars";

const ErrorPlaceholder = styled.span`
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    text-align: right;
    &.pwaBig {
        top: -20px;
    }
`;

const FieldErrorSpan = styled.span`
    background: ${(props) => props.theme.errorColor};
    color: ${(props) => props.theme.errorTextColor};
    border-radius: 5px;
    padding: 8px 5px;
    position: absolute;
    bottom: 8px;
    width: max-content;
    max-width: 150%;
    right: 0px;
    display: none;
    font-size: 0.875rem;
    z-index: 100;
    text-align: center;
    
    &.open {
        display: block;
    }

    &.small {
        font-size: 0.75rem;
    }

    &:after {
        content: "";
        border-style: solid;
        border-width: 7px 5px 0 5px;
        border-color: ${(props) => props.theme.errorColor} transparent transparent transparent;
        position: absolute;
        bottom: -6px;
        right: 16px;
    }
`;

const MobileFiledError = styled.span`
    color: #fd6666;
    background: #fff;
    padding: 4px 4px;
    border-radius: 6px;
    position: absolute;
    bottom: -5px;
    width: auto;
    left: 0px;
    font-size: 0.775rem;
    z-index: 100;
    height: 19px;
    line-height: 10px;
`;

const StyledErrorSpan = styled.span`
    color: ${(props) => props.theme.errorColor};
    display: block;

    &.relative {
        position: relative;
    }

    &.small {
        font-size: 0.75rem;
    }
`;

const StyledIcon = styled.i`
    position: absolute;
    right: 12px;
    top: 0;
    width: 1.125rem;
    height: 1.125rem;
    cursor: pointer;
`;

const ValidationError = ({className, error, touched, isFieldError}) => {
    const shouldRender = error && touched;
    const [errorIsOpen, setErrorIsOpen] = useState(false);

    const toggleError = () => {
        setErrorIsOpen(!errorIsOpen);
    };

    if (shouldRender) {
        if (isFieldError) {
            if (globals.isMobile)
                return <MobileFiledError>{error}</MobileFiledError>;

            return (
                <ErrorPlaceholder className={className}>
                    <StyledIcon
                        onMouseEnter={toggleError}
                        onMouseLeave={toggleError}
                    >
                        <img
                            src="/images/actions/validation_question_mark.svg"
                            alt="Validation question mark"
                        />
                    </StyledIcon>
                    <FieldErrorSpan
                        className={[
                            className && className,
                            errorIsOpen && "open",
                        ]}
                        data-test="error-text"
                    >
                        {error}
                    </FieldErrorSpan>
                </ErrorPlaceholder>
            );
        }

        return <StyledErrorSpan className={className}>{error}</StyledErrorSpan>;
    }
    return null;
};

export default ValidationError;
