import Color from "color";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { ReactComponent as ResetSvg } from "../../../assets/close.svg";
import { ReactComponent as PlusSvg } from "../../../assets/plus-white.svg";
import { InputWrapper } from "../Stylings";
import { Label, ValidationError } from "./index";

const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
    window.HTMLInputElement.prototype,
    "value"
).set;

const StylizedDrop = styled.div`
    box-sizing: border-box;
    width: 100%;
    min-width: 190px;
    background: ${(props) => props.theme.inputBgColor};
    padding: 8px 16px;
    border: 1px solid
        ${(props) => props.theme.primaryInputBorderColor || "#d4d4d4"};
    border-radius: ${(props) => props.theme.inputBorderRadius || "5px"};
    position: absolute;
    top: 48px;
    z-index: 300;
    background: #fff;
    text-align: left;
    cursor: pointer;
    box-shadow: 4px 4px 16px 0 rgba(0, 0, 0, 0.16);
    transition: all 0.3s ease-in;
    opacity: 0;
    visibility: hidden;
    font-size: ${(props) => props.theme.inputFontSize};
    max-height: 360px;
    overflow-y: auto;

    & ul {
        list-style: none;
        margin: 0;
        padding: 0;
        overflow-y: auto;
    }

    @media ${(props) => props.theme.device.mobile} {
        max-height: 144px;
    }

    & ul li {
        list-style: none;
        padding: 10px 5px;
        display: flex;
        align-items: center;

        & img {
            height: 24px;
            margin-right: 12px;
        }
    }

    &.thin ul li {
        list-style: none;
        padding: 5px 5px;
    }

    & ul li:hover {
        color: ${(props) => props.theme.primaryColorHover} !important;
    }

    & ul li:not(:last-child) {
        border-bottom: 1px solid #e2e2e2;
    }

    ${(props) =>
        props.hasDefault &&
        css`
        & ul li:first-child {
        font-weight:600;
        display: none;
    `};
`;

const StyledPlus = styled(PlusSvg)`
    position: absolute;
    right: 12px;
    top: 6px;
    width: 20px;
    height: 20px;
`;

const StyledReset = styled(ResetSvg)`
    position: absolute;
    left: 7px;
    top: 50%;
    width: 16px;
    height: 16px;
    transition: all 300ms ease;
    transform: translateY(-50%);

    &.filters {
        left: 12px;
        top: 50%;
        width: 12px;
        height: 12px;
    }

    &.filters.action {
        display: none;
    }

    &.filters.big {
        left: 12px;
    }

    &:hover .lines {
        stroke: #e94e4e;
    }
`;

const StylizedSelect = styled.div`
    box-sizing: border-box;
    width: 100%;
    /* min-width: 190px; */
    background: ${(props) => props.theme.inputBgColor};
    padding: 0 32px 0 4px;
    border: 1px solid
        ${(props) => props.theme.primaryInputBorderColor || "#d4d4d4"};
    border-radius: ${(props) => props.theme.inputBorderRadius || "5px"};
    margin: 0 0 15px;
    cursor: pointer;
    position: relative;
    font-size: ${(props) => props.theme.inputFontSize};
    ${(props) =>
        props.disabled &&
        css`
            background: ${Color("#f8f9f9").darken(0.2).string()};
        `};
    color: ${(props) => (props.isDefault ? "#898989" : "#000")};
    height: ${(props) => props.theme.heightInputBig};
    line-height: ${(props) => props.theme.heightInputBig};

    & span {
        margin-left: 12px;
        display: flex;
        align-items: center;
        &.reset {
            margin-left: 26px !important;
        }

        & img {
            height: 24px;
            margin-right: 12px;
        }
    }

    &.error {
        border-color: #e94e4e;
    }

    &.filters {
        border-radius: ${(props) => props.theme.filtersBorderRadius};
        height: ${(props) => props.theme.heightInputSmall};
        line-height: ${(props) => props.theme.heightInputSmall};
        min-width: 210px;
        font-size: 14px;
        margin: 0 0 10px;
        padding: 0 30px 0 4px;
    }

    &.filters.big {
        height: 46px;
        margin: 0 0 20px;
        line-height: 46px;
        & span {
            margin-left: 20px;
            &.reset {
                margin-left: 24px !important;
            }
        }
    }

    &.focused {
        outline: none !important;
        border: 1px solid ${(p) => p.theme.secondaryColor};
    }

    &.focused.open {
        box-shadow: 4px 4px 16px 0px rgba(0, 0, 0, 0.16);
    }

    &.open ~ ${StylizedDrop} {
        opacity: 1;
        visibility: visible;
    }

    &.filters ~ ${StylizedDrop} {
        top: 36px;
    }

    &.filters.big ~ ${StylizedDrop} {
        top: 50px;
    }

    &:after {
        content: "";
        background: url("/images/menu/expand.svg") no-repeat center;
        background-size: 14px 12px;
        transition: all 0.5s ease;
        position: absolute;
        right: 8px;
        top: 15px;
        width: 15px;
        height: 15px;
        text-align: center;
    }

    &.filters {
        &:after {
            background: url("/images/menu/expand_thin.svg") no-repeat center;
            top: 8px;
            background-size: 12px 10px;
            right: 12px;
        }
        & span {
            margin-left: 26px;
        }
    }

    &.filters.action {
        background-color: ${(p) => p.theme.primaryColor};
        color: #fff;
        &:after {
            content: none;
        }
        & span {
            margin-left: 20px;
        }
    }

    &.filters.big {
        &:after {
            top: 15px;
            right: 12px;
        }
        & span {
            margin-left: 8px;
        }
    }

    /* &.open {
        &:after {
            transform: rotate(-180deg);
        }
    } */

    &.disabled {
        background: #f5f5f5;
    }
`;

function useDropdown(dropEl, actionEl) {
    dropEl = dropEl.current;
    actionEl = actionEl.current;

    const [drop, setDrop] = useState(false);

    const toggleDrop = useCallback(
        (toggleState) => {
            const newState =
                toggleState !== undefined ? Boolean(toggleState) : !drop;
            setDrop(newState);
        },
        [drop]
    );

    const onWindowClick = useCallback(
        (ev) => {
            const clickOnAction =
                actionEl &&
                (ev.target === actionEl || actionEl.contains(ev.target));
            const clickOnDrop =
                dropEl && (ev.target === dropEl || dropEl.contains(ev.target));

            if (!clickOnAction && !clickOnDrop) {
                if (drop === true) toggleDrop(false);
                actionEl &&
                    actionEl.classList.contains("focused") &&
                    actionEl.classList.remove("focused");
            }
        },
        [actionEl, drop, dropEl, toggleDrop]
    );

    const onEsc = useCallback(
        (ev) => {
            if (ev.keyCode === 27 && drop === true) {
                toggleDrop(false);
            }
        },
        [drop, toggleDrop]
    );

    useEffect(() => {
        window.addEventListener("click", onWindowClick);
        return () => window.removeEventListener("click", onWindowClick);
    });

    useEffect(() => {
        window.addEventListener("keyup", onEsc);
        return () => window.removeEventListener("keyup", onEsc);
    });

    return [drop, toggleDrop];
}

const CustomSelect = ({
    options = [],
    className,
    value,
    onChange,
    error,
    hasDefault,
    disabled,
    dropdown,
    handleChange,
    ...restProps
}) => {
    const inputEl = useRef(null);
    const selectEl = useRef(null);
    const selectDropEl = useRef(null);
    const [dropOpen, toggleDrop] = useDropdown(selectDropEl, selectEl);
    const [index, setIndex] = useState(0);

    useEffect(() => {
        if (options.length > 0) {
            for (var i = 0; i < options.length; i++) {
                if (options[i].value === value) {
                    setIndex(i);
                    break;
                }
            }
        }
    }, [options, value]);

    const handleInputChange = useCallback(
        (ev) => {
            onChange && onChange(ev);
        },
        [onChange]
    );

    const handleReset = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        setIndex(0);
        dispatchChangeEvent(0);
        toggleDrop(false);
    };

    const dispatchChangeEvent = (newIndex) => {
        !dropdown &&
            nativeInputValueSetter.call(
                inputEl.current,
                options[newIndex].value
            );
        let inpEvt = new Event("input", { bubbles: true });
        inputEl.current.dispatchEvent(inpEvt);
    };

    const onClick = (el) => {
        if (el.target.dataset.idx !== undefined) {
            const selectedIdx = Number(el.target.dataset.idx);
            if (selectedIdx !== index || dropdown) {
                if (!dropdown) {
                    setIndex(selectedIdx);
                    dispatchChangeEvent(selectedIdx);
                } else {
                    if (handleChange) {
                        const ev = {
                            currentTarget: {
                                value: options[selectedIdx].value,
                            },
                        };
                        handleChange(ev);
                    }
                }
            }
        }
        selectEl.current.classList.add("focused");
        toggleDrop();
    };
    selectEl.current &&
        (dropOpen
            ? selectEl.current.classList.add("open")
            : selectEl.current.classList.remove("open"));

    const label =
        index >= 0 && options.length > index ? options[index].label : "";
    error && (className = className + " error");

    return (
        <div style={{ position: "relative" }}>
            <input
                style={{ display: "none" }}
                ref={inputEl}
                type="text"
                {...restProps}
                onChange={handleInputChange}
            />
            <StylizedSelect
                ref={selectEl}
                data-test="dropdown-select-anchor"
                onClick={disabled ? undefined : onClick}
                className={`${className ? className : ""}${
                    disabled ? "disabled" : ""
                }`}
                isDefault={hasDefault && index === 0}
            >
                {hasDefault && index > 0 && (
                    <StyledReset className={className} onClick={handleReset} />
                )}
                <span
                    className={hasDefault && index > 0 ? "reset" : ""}
                    dangerouslySetInnerHTML={{ __html: label }}
                />
            </StylizedSelect>
            {dropdown && <StyledPlus />}
            {!disabled && (
                <StylizedDrop
                    ref={selectDropEl}
                    onClick={disabled ? undefined : onClick}
                    hasDefault={hasDefault}
                    className={className}
                    data-test="dropdown-options"
                >
                    <ul>
                        {options &&
                            options.map((option, idx) => (
                                <li
                                    key={option.key}
                                    data-value={option.value}
                                    data-idx={idx}
                                    dangerouslySetInnerHTML={{
                                        __html: option.label,
                                    }}
                                />
                            ))}
                    </ul>
                </StylizedDrop>
            )}
        </div>
    );
};

const Select = (props) => {
    let {
        labelText,
        labelClassName,
        name,
        className,
        errors,
        touched,
        values,
        onChange,
        handleChange,
        handleBlur,
        options,
        wrapperVariant,
        defaultItem,
        defaultValue,
        defaultFirstOption,
        disabled,
        dropdown,
    } = props;
    let value = values && values[name];
    let error = errors && errors[name];
    let isTouched = touched && touched[name];

    if (defaultValue !== undefined && value === undefined) value = defaultValue;

    const selectOptions = [...options];
    if (defaultItem && selectOptions) {
        selectOptions.unshift(defaultItem);
    }
    return (
        <InputWrapper
            className={`${wrapperVariant ? wrapperVariant : ""} ${
                error ? "error" : ""
            }`}
        >
            <Label htmlFor={name} className={labelClassName}>
                {labelText}
            </Label>
            <CustomSelect
                dropdown={dropdown}
                id={props.id}
                name={props.name}
                value={value}
                handleChange={handleChange}
                onChange={handleChange || onChange}
                onBlur={handleBlur}
                defaultValue={defaultValue}
                className={className}
                options={selectOptions}
                error={error}
                hasDefault={defaultItem !== undefined || defaultFirstOption}
                disabled={disabled}
            />
            <ValidationError
                touched={isTouched}
                error={error}
                isFieldError={true}
            />
        </InputWrapper>
    );
};

export default Select;
