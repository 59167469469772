import React from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import QrReader from "react-qr-reader";
import InputElement from "../../../../components/elements/form-elements/InputElement";
import { Select } from "../../../../components/elements/form-elements";
import * as yup from "yup";

import { InfoText, InfoTitle, InfoWrapper, RoundInfo } from "../../../../components/modal/components/WizardStyling";
import { StyledQrReader } from "../../../../components/elements/qr/QrScanner";
import ValidationRule, { buildValidation } from "../../../../core/validations/ValidationRule";

import TagsService from "../../../../services/Tags/TagsService";

import { ReactComponent as WizardImage } from "../../../../assets/pwa/add-new-unit.svg";
import { ScanButton, KeyVal, ListTitle, HR } from "../../components/StyledElements";

import { SvgScan } from "../../components/StyledSvg";
import MoneyInputElement from "../../../../components/elements/form-elements/MoneyInputElement";

const Content = styled.div`
    width: 100%;
    box-sizing: border-box;
    flex-grow: 1;
    position: relative;
    padding: 35px 60px 0px;
    display: flex;
    flex-direction: column;
    padding: 0px 0px 0px 0px;
    max-height: 500px;
    overflow: auto;
`;

const FlexCenter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const SvgWizardImage = styled(WizardImage)`
    width: auto;
    margin: 0 auto;
`;

const Section = styled.div`
    padding: 8px 20px;
    &.full-x {
        padding: 8px 0px;
    }

    &.flex {
        display: flex;
    }
`;

const ScanWrapper = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
`;

const Spacer = styled.div`
    height: 10px;
    width: 100%;
`;

const ClientsList = styled.ul`
    font-size: 12px;
    width: 100%;
    list-style: none;
    padding: 0px 8px;
    height: 300px;
    max-height: 300px;
    overflow: auto;

    & > li {
        border-bottom: 1px solid #ddd;
        padding: 5px 10px;
    }

    & > li:hover,
    & > li:active {
        background-color: ${(p) => p.theme.primaryColor};
        color: #fff;
    }
`;

const SiteInfo = styled.div`
    font-size: 12px;
    &.title {
        font-weight: 700;
    }
`;

const SiteTitle = styled.div`
    font-size: 20px;
    font-weight: 600;
    color: #48b05e;
    margin-bottom: 10px;
    margin-left: 10px;
`;

const SiteSubTitle = styled.div`
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    margin-left: 10px;
    color: #000;
    &.light {
        color: #8e8e8e;
    }
`;

const ClientTitle = styled.div`
    font-weight: 700;
`;

const Separator = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${(p) => p.theme.sidebarWrapperBorderColor};
    &.bottomSpace {
        margin-bottom: 14px;
    }
    &.topSpace {
        margin-top: 14px;
    }
`;

const SerialInfo = styled.div`
    font-size: 12px;
    font-weight: 600;
    color: #2294d2;
    margin: 0 2%;

    &.fail {
        color: #e94e4e;
    }

    &.info {
        font-weight: 400;
        color: #000;
        margin-bottom: 20px;
    }
`;

const defaultInitialValues = {
    locationId: "",
    showTagScanner: false,
    isValidUnusedTag: false,
    unusedTagId: "",
    clientName: "",
    clientId: "",
    manufacturerId: "",
    applicationType: "",
    refrigerantId: "",
    capacity: "0.00",
    images: "",
    description: "",
    serialNumber: "",
    serialFound: "",
};

const ClientListItem = ({ loc, formik, update }) => {
    return (
        <li
            data-id={loc.locationId}
            onClick={(ev) => {
                formik.setValues({
                    ...formik.values,
                    locationId: loc.locationId,
                    clientId: loc.clientId,
                });
                update({ selectedLocation: loc });
            }}
        >
            <ClientTitle>
                {loc.clientName} [ {loc.locationName} ]
            </ClientTitle>
            {`${loc.address}, ${loc.postalCode}, ${loc.city}`}
        </li>
    );
};

const validUnitInfo = (t) => {
    return buildValidation({
        manufacturerId: ValidationRule.isStringRule().required(t("forms:manufacturer.required")).rule,
        applicationType: ValidationRule.isStringRule().required(t("forms:application.required")).rule,
        refrigerantId: ValidationRule.isStringRule().required(t("forms:gasType.required")).rule,
        capacity: ValidationRule.isNumber().required(t("forms:capacity.required")).rule,
    });
};

const validUnusedTagId = (t) => {
    return buildValidation({
        unusedTagId: ValidationRule.isStringRule()
            .isUUID(t("forms:unit.uuid.invalid"))
            .required(t("forms:unit.unitTagId.required")).rule,
    });
};

const validLocationId = (t) => {
    return buildValidation({
        locationId: ValidationRule.isStringRule().required(t("forms:unit.unitTagId.required")).rule,
        isValidUnusedTag: yup.boolean().required().oneOf([true], t("forms:unit.wizard.invalidTag")),
    });
};
const applicationTypes = ["Residential", "Commercial", "Industrial"];

const AddUnitWizard = (t, unusedTagSearchFn, clientsSearchFn, manufacturers, refrigerants) => {
    const handleUnusedTagScan = (value, formik) => {
        if (value != null) {
            formik.setValues({
                ...formik.values,
                showTagScanner: false,
                unusedTagId: value.toLowerCase(),
            });
        }
    };

    return {
        title: t("forms:unit.wizard.pwaTitle"),
        initialValues: defaultInitialValues,
        steps: [
            {
                name: t("forms:unit.wizard.pwaStep1"),
                fieldNames: ["unusedTagId"],
                validation: validUnusedTagId(t),
                onStepSubmit: async ({ values }) => {
                    const tagId = values["unusedTagId"];

                    const tagResult = await TagsService.lookupUnassignedTagsAsync(tagId);
                    if (tagResult.error || tagResult.data.data.length === 0) {
                        toast.error(t("forms:unit.wizard.invalidTag"));
                        return false;
                    }
                    return true;
                },
                fields: (formik) => {
                    return (
                        <>
                            <div
                                style={{
                                    display: formik.values.showTagScanner ? "none" : "block",
                                }}
                            >
                                <FlexCenter>
                                    <SvgWizardImage />
                                </FlexCenter>

                                <InfoTitle className="pwa">{t("forms:unit.wizard.infoTitle")}</InfoTitle>
                                <InfoWrapper className="pwa">
                                    <RoundInfo alt="" src="/images/menu/green-info.svg" />
                                    <div>
                                        <InfoText className="pwa">
                                            {t("forms:unit.wizard.attachTag")}
                                            <Spacer />
                                            {t("forms:extraction.wizard.useCamera")}
                                            <Spacer />
                                            {t("forms:extraction.wizard.typeQrTag")}
                                        </InfoText>
                                    </div>
                                </InfoWrapper>
                                <InputElement
                                    pwaBig
                                    noLabel
                                    labelText={t("forms:cylinderTagId.text")}
                                    id="unusedTagId"
                                    name="unusedTagId"
                                    type="search"
                                    placeholder={t("screens:technician.qrTag")}
                                    searchFn={unusedTagSearchFn}
                                    wrapperVariant="flex-1"
                                    {...formik}
                                />
                            </div>

                            {formik.values.showTagScanner && (
                                <StyledQrReader className="wizard">
                                    <QrReader
                                        delay={300}
                                        onError={(error) => console.log(error)}
                                        onScan={(value) => handleUnusedTagScan(value, formik)}
                                        style={{ width: "100%" }}
                                    />
                                </StyledQrReader>
                            )}

                            <ScanWrapper>
                                <ScanButton
                                    type="button"
                                    className={formik.values.showTagScanner ? "close" : ""}
                                    onClick={() =>
                                        formik.setFieldValue("showTagScanner", !formik.values.showTagScanner)
                                    }
                                >
                                    <SvgScan className="white" />
                                </ScanButton>
                            </ScanWrapper>
                        </>
                    );
                },
            },
            {
                name: t("forms:unit.wizard.pwaStep2"),
                fieldNames: ["clientId", "locationId"],
                validation: validLocationId(t),
                onStepRender: async (props) => {
                    const tagId = props.value["unusedTagId"];
                    if (tagId !== props.propertyBag.tagId) {
                        const tagResult = await TagsService.lookupUnassignedTagsAsync(tagId);
                        if (tagResult.error) {
                            toast.error(t(tagResult.error.message));
                            props.formik.setFieldValue("isValidUnusedTag", false);
                            return;
                        }
                        const tagData = tagResult.data.data;

                        if (tagData.length === 0) {
                            toast.error(t("forms:unit.wizard.invalidTag"));
                            props.formik.setFieldValue("isValidUnusedTag", false);
                            return;
                        }
                        props.formik.setFieldValue("isValidUnusedTag", true);

                        props.updatePropertyBag({ tagId: tagId });
                    }
                    props.formik.setFieldValue("locationId", "");
                },
                fields: (formik, propertyBag, updatePropertyBag) => {
                    return (
                        <>
                            <InputElement
                                pwaBig
                                labelText={t("forms:unit.wizard.client")}
                                id="clientName"
                                name="clientName"
                                type="search"
                                placeholder={t("forms:unit.wizard.selectClient")}
                                searchFn={(name) => clientsSearchFn(name, updatePropertyBag)}
                                wrapperVariant="flex-1"
                                {...formik}
                            />
                            {propertyBag.locations && (
                                <ClientsList>
                                    {propertyBag.locations.map((loc, idx) => (
                                        <ClientListItem
                                            key={idx}
                                            loc={loc}
                                            formik={formik}
                                            update={updatePropertyBag}
                                        />
                                    ))}
                                </ClientsList>
                            )}
                        </>
                    );
                },
            },
            {
                name: t("forms:clients.daikinSerial"),
                fieldNames: ["serialNumber"],
                fields: (formik, propertyBag) => (
                    <>
                        <Separator className="bottomSpace" />
                        <SiteInfo>
                            <SiteTitle>{t("sidebar:title.installationSite")}</SiteTitle>
                            <SiteSubTitle>{propertyBag.selectedLocation?.locationName}</SiteSubTitle>
                            <SiteSubTitle className="light">
                                {`${propertyBag.selectedLocation?.address}, 
                                ${propertyBag.selectedLocation?.city}, 
                                ${propertyBag.selectedLocation?.country}`}
                            </SiteSubTitle>
                        </SiteInfo>
                        <Separator className="topSpace bottomSpace" />
                        <InfoWrapper>
                            <RoundInfo alt="" src="/images/menu/green-info.svg" />
                            <div>
                                <InfoText className="dark">{t("forms:clients.daikinSerialInfo")}</InfoText>
                            </div>
                        </InfoWrapper>
                        <InputElement
                            labelText={t("forms:clients.daikinSerial")}
                            id="serialNumber"
                            name="serialNumber"
                            type="text"
                            wrapperVariant="flex-1"
                            {...formik}
                        />
                    </>
                ),
            },
            {
                name: t("forms:unit.wizard.pwaStep3"),
                fieldNames: ["manufacturerId", "applicationType", "refrigerantId", "capacity"],
                validation: validUnitInfo(t),
                onStepRender: async ({ formik }) => {
                    const serial = formik.values["serialNumber"];
                    if (!serial) return;
                    const result = await TagsService.getDaikinSerial(serial);
                    if (result.status !== 200) return;
                    const response = result.data.response;
                    if (response.message || !result.data.success) {
                        formik.setFieldValue("serialFound", "N");
                    } else {
                        formik.setFieldValue("serialFound", "Y");
                        const rfg = refrigerants.find((r) => r.code === response.refrigerantType);
                        if (rfg) {
                            formik.setFieldValue("refrigerantId", rfg.id);
                        }
                        const mfc = manufacturers.find((r) => r.name === "Daikin");
                        if (mfc) {
                            formik.setFieldValue("manufacturerId", mfc.id);
                        }
                        if (response.kWh) {
                            formik.setFieldValue("capacity", Number(response.kWh));
                        }
                    }
                },
                fields: (formik, propertyBag) => {
                    return (
                        <>
                            <Separator className="bottomSpace" />
                            <SiteInfo>
                                <SiteTitle>{t("sidebar:title.installationSite")}</SiteTitle>
                                <SiteSubTitle>{propertyBag.selectedLocation?.locationName}</SiteSubTitle>
                                <SiteSubTitle className="light">
                                    {`${propertyBag.selectedLocation?.address}, 
                                ${propertyBag.selectedLocation?.city}, 
                                ${propertyBag.selectedLocation?.country}`}
                                </SiteSubTitle>
                            </SiteInfo>
                            <Separator className="topSpace bottomSpace" />
                            {formik.values["serialFound"] && (
                                <>
                                    <SerialInfo className={formik.values["serialFound"] === "N" ? "fail" : ""}>
                                        {formik.values["serialFound"] === "N"
                                            ? t("forms:clients.serialNotFound")
                                            : t("forms:clients.serialFound")}
                                    </SerialInfo>
                                    <SerialInfo className="info">{t("forms:clients.manualInput")}</SerialInfo>
                                </>
                            )}
                            <Select
                                id="manufacturerId"
                                name="manufacturerId"
                                labelText={t("forms:manufacturer.text")}
                                wrapperVariant="flex-1"
                                defaultItem={{
                                    key: "all",
                                    value: "",
                                    label: t("forms:manufacturer.text"),
                                }}
                                options={
                                    manufacturers && manufacturers.length > 0
                                        ? manufacturers.map((item) => {
                                              return {
                                                  key: item.id,
                                                  value: item.id,
                                                  label: item.name,
                                              };
                                          })
                                        : []
                                }
                                {...formik}
                            />
                            <Select
                                id="refrigerantId"
                                name="refrigerantId"
                                labelText={t("forms:gasType.text")}
                                wrapperVariant="flex-1"
                                defaultItem={{
                                    key: "all",
                                    value: "",
                                    label: t("forms:gasType.text"),
                                }}
                                options={
                                    refrigerants && refrigerants.length > 0
                                        ? refrigerants.map((item) => {
                                              return {
                                                  key: item.id,
                                                  value: item.id,
                                                  label: item.code,
                                              };
                                          })
                                        : []
                                }
                                {...formik}
                            />
                            <Select
                                id="applicationType"
                                name="applicationType"
                                labelText={t("forms:application.text")}
                                wrapperVariant="flex-1"
                                defaultItem={{
                                    key: "all",
                                    value: "",
                                    label: t("forms:application.text"),
                                }}
                                options={applicationTypes.map((item, idx) => {
                                    return {
                                        key: idx,
                                        value: idx.toString(),
                                        label: `${t("common:applicationType." + item.toLowerCase())}`,
                                    };
                                })}
                                {...formik}
                            />

                            <MoneyInputElement
                                type="number"
                                name="capacity"
                                id="capacity"
                                labelText={t("forms:capacity.text")}
                                wrapperVariant={"flex-1 standard"}
                                values={2}
                                min={0}
                                step="0.01"
                                positive={true}
                                formik={formik}
                                currencyLabel="kW"
                            />

                            {/* {propertyBag.selectedLocation && (
                                <Content>
                                    <HR className="content" />
                                    <ListTitle className="wizard">{t("screens:technician.installationSite")}</ListTitle>
                                    <Section>
                                        <SiteInfo className="title">{propertyBag.selectedLocation.clientName}</SiteInfo>
                                        <SiteInfo>{`${propertyBag.selectedLocation.address}, ${propertyBag.selectedLocation.postalCode}, ${propertyBag.selectedLocation.city}, ${propertyBag.selectedLocation.country}`}</SiteInfo>
                                    </Section>
                                </Content>
                            )} */}
                        </>
                    );
                },
            },
            {
                name: t("forms:unit.wizard.pwaStep4"),
                fieldNames: ["description"],
                fields: (formik, propertyBag) => {
                    return (
                        <>
                            {propertyBag.selectedLocation && (
                                <>
                                    <Separator className="bottomSpace" />
                                    <SiteInfo>
                                        <SiteTitle>{t("sidebar:title.installationSite")}</SiteTitle>
                                        <SiteSubTitle>{propertyBag.selectedLocation?.locationName}</SiteSubTitle>
                                        <SiteSubTitle className="light">
                                            {`${propertyBag.selectedLocation?.address}, 
                                ${propertyBag.selectedLocation?.city}, 
                                ${propertyBag.selectedLocation?.country}`}
                                        </SiteSubTitle>
                                    </SiteInfo>
                                    <Separator className="topSpace bottomSpace" />
                                </>
                                // <Content>
                                //     <HR className="content" />
                                //     <ListTitle className="wizard">{t("screens:technician.installationSite")}</ListTitle>
                                //     <Section>
                                //         <KeyVal className="single">
                                //             {t("screens:technician.company")}
                                //             <span>{propertyBag.selectedLocation.clientName}</span>
                                //         </KeyVal>
                                //         <KeyVal className="single">
                                //             {t("screens:technician.street")}
                                //             <span>{propertyBag.selectedLocation.address}</span>
                                //         </KeyVal>
                                //         <KeyVal className="single">
                                //             {t("screens:technician.city")}
                                //             <span>{propertyBag.selectedLocation.city}</span>
                                //         </KeyVal>
                                //         <KeyVal className="single">
                                //             {t("screens:technician.zip")}
                                //             <span>{propertyBag.selectedLocation.postalCode}</span>
                                //         </KeyVal>
                                //         <KeyVal className="single">
                                //             {t("screens:technician.country")}
                                //             <span>{propertyBag.selectedLocation.country}</span>
                                //         </KeyVal>
                                //     </Section>
                                //     <HR className="content" />
                                // </Content>
                            )}
                            <InputElement
                                labelText={t("forms:clients.notes")}
                                id="description"
                                autoComplete="new-description"
                                name="description"
                                type="textarea"
                                col={4}
                                wrapperVariant="flex-1"
                                {...formik}
                            />
                        </>
                    );
                },
            },
            {
                name: t("forms:unit.wizard.pwaStep5"),
                fieldNames: ["images"],
                fields: (formik, propertyBag) => {
                    return (
                        <>
                            {propertyBag.selectedLocation && (
                                <>
                                    <Separator className="bottomSpace" />
                                    <SiteInfo>
                                        <SiteTitle>{t("sidebar:title.installationSite")}</SiteTitle>
                                        <SiteSubTitle>{propertyBag.selectedLocation?.locationName}</SiteSubTitle>
                                        <SiteSubTitle className="light">
                                            {`${propertyBag.selectedLocation?.address}, 
                                                           ${propertyBag.selectedLocation?.city}, 
                                                           ${propertyBag.selectedLocation?.country}`}
                                        </SiteSubTitle>
                                    </SiteInfo>
                                    <Separator className="topSpace bottomSpace" />
                                </>
                                // <Content>
                                //     <HR className="content" />
                                //     <ListTitle className="wizard">{t("screens:technician.installationSite")}</ListTitle>
                                //     <Section>
                                //         <KeyVal className="single">
                                //             {t("screens:technician.company")}
                                //             <span>{propertyBag.selectedLocation.clientName}</span>
                                //         </KeyVal>
                                //         <KeyVal className="single">
                                //             {t("screens:technician.street")}
                                //             <span>{propertyBag.selectedLocation.address}</span>
                                //         </KeyVal>
                                //         <KeyVal className="single">
                                //             {t("screens:technician.city")}
                                //             <span>{propertyBag.selectedLocation.city}</span>
                                //         </KeyVal>
                                //         <KeyVal className="single">
                                //             {t("screens:technician.zip")}
                                //             <span>{propertyBag.selectedLocation.postalCode}</span>
                                //         </KeyVal>
                                //         <KeyVal className="single">
                                //             {t("screens:technician.country")}
                                //             <span>{propertyBag.selectedLocation.country}</span>
                                //         </KeyVal>
                                //     </Section>
                                //     <HR className="content" />
                                // </Content>
                            )}
                            <InputElement
                                labelText=""
                                placeholder={t("forms:userPicture.text")}
                                title={t("forms:clients.unitPictures")}
                                name="images"
                                type="gallery"
                                id="images"
                                single
                                wrapperVariant="flex-1"
                                {...formik}
                            />
                        </>
                    );
                },
            },
        ],
    };
};
export default AddUnitWizard;
