import React from "react";
import {
    CheckMark,
    RadioGroup,
    RadioLabel,
    RadioWrapper,
} from "../../../../../components/elements/form-elements/Radio";
import PaymentService from "../../../../../services/Payment/PaymentService";
import {
    InfoText,
    InfoTitle,
    InfoWrapper,
    RoundInfo,
    Separator,
    KeyText,
    ValueText,
    VSpace,
    PrimarySpan,
} from "../../../../../components/modal/components/WizardStyling";
import ValidationError from "../../../../../components/elements/form-elements/ValidationError";
import helpers from "../../../../../core/helpers";
import PaymentType from "../../../../../core/enums/PaymentType";
import Globals from "../../../../../globalVars";
import NavigationLink from "../../../../../components/elements/links/NavigationLink";
import ModalWizard from "../../../../../components/modal/ModalWizard";

const defaultInitialValues = {
    paymentMethod: PaymentType.MainWalletToTransaction,
};

const WizardPayForOffer = (
    t,
    currentLanguage,
    currency,
    price,
    offerInfo = "",
    offerer = true,
    customTitle = "",
    contentTitle = "",
    customInfo = "",
    bidCompanyCountryId
) => {
    price = Number(price);
    let vatPercentage = Globals.VATPercentage;
    let vatPercString = Globals.VATPercString;
    if (
        bidCompanyCountryId !== undefined &&
        Globals.userCountryId !== bidCompanyCountryId
    ) {
        vatPercentage = 1;
        vatPercString = "0";
    }

    const vatOnly = ((vatPercentage - 1) * price).toFixed(2);
    const withVat = (vatPercentage * price).toFixed(2);
    return {
        title: t("forms:payForOffer.wizard.payIn"),
        // title: customTitle
        //     ? customTitle
        //     : offerer
        //     ? t("forms:payForOffer.wizard.offerer.title")
        //     : t("forms:payForOffer.wizard.buyer.title"),
        initialValues: defaultInitialValues,
        steps: [
            {
                name: customTitle
                    ? customTitle
                    : offerer
                    ? t("forms:payForOffer.wizard.payInCgrade")
                    : t("forms:payForOffer.wizard.payInAgrade"),
                fieldNames: ["paymentMethod"],
                onStepRender: async (props) => {
                    // get wallet
                    let wallets = await PaymentService.getMyWallets();
                    let walletBalance = 0;

                    if (wallets && wallets.length > 0) {
                        let mainWallet = wallets.filter(
                            (w) => w.tag === "MAIN"
                        );
                        if (mainWallet) {
                            walletBalance = mainWallet[0].amount;
                        }
                    }
                    let remainingBalance =
                        walletBalance - price * vatPercentage;

                    props.updatePropertyBag({
                        walletBalanceExceeded:
                            walletBalance < price * vatPercentage,
                        walletBalance: helpers.formatCurrency(
                            walletBalance,
                            "",
                            "EUR"
                        ),
                        remainingBalance: helpers.formatCurrency(
                            remainingBalance,
                            "",
                            "EUR"
                        ),
                    });
                    return walletBalance < price * vatPercentage;
                },
                onStepSubmit: ({ propertyBag }) => {
                    return !propertyBag.walletBalanceExceeded;
                },
                fields: (formik, propertyBag) => {
                    return (
                        <>
                            {propertyBag.walletBalanceExceeded ? (
                                <InfoWrapper className="mv16">
                                    <RoundInfo
                                        alt=""
                                        src="/images/menu/green-info.svg"
                                    />
                                    <div>
                                        <InfoTitle>
                                            {t(
                                                "forms:payForOffer.wizard.noFunds"
                                            )}
                                        </InfoTitle>
                                        <InfoText className="justify">
                                            {customInfo
                                                ? customInfo
                                                : t(
                                                      "forms:payForOffer.wizard.noFundsInfo"
                                                  )}
                                        </InfoText>
                                        <NavigationLink
                                            className="secondaty"
                                            style={{ float: "right" }}
                                            to="/finance"
                                            onClick={() => {
                                                ModalWizard.hide();
                                            }}
                                        >
                                            {t(
                                                "forms:payForOffer.wizard.gotoMyWallets"
                                            )}
                                        </NavigationLink>
                                    </div>
                                </InfoWrapper>
                            ) : (
                                <InfoWrapper className="mv16 tight-border">
                                    <RoundInfo
                                        alt=""
                                        src="/images/menu/green-info.svg"
                                    />
                                    <div>
                                        <InfoTitle>
                                            {t(
                                                "forms:payForOffer.wizard.newPayment"
                                            )}
                                        </InfoTitle>
                                        <InfoText>
                                            {customInfo
                                                ? customInfo
                                                : t(
                                                      "forms:payForOffer.wizard.deductedAmount"
                                                  )}
                                        </InfoText>
                                    </div>
                                </InfoWrapper>
                            )}
                            <InfoWrapper className="column mv16 no-border">
                                <div className="content">
                                    <ValueText className="big">
                                        {contentTitle
                                            ? contentTitle
                                            : offerer
                                            ? t(
                                                  "forms:payForOffer.wizard.cGradeDeposit"
                                              )
                                            : t(
                                                  "forms:payForOffer.wizard.aGradeDeposit"
                                              )}
                                    </ValueText>
                                </div>
                                <Separator className="black" />
                                <div className="content keyval">
                                    <ValueText>
                                        {t(
                                            "forms:payForOffer.wizard.mainBallance"
                                        )}
                                    </ValueText>
                                    <ValueText>
                                        {propertyBag.walletBalance}
                                    </ValueText>
                                </div>
                                <div className="content keyval">
                                    <KeyText className="inset">
                                        {offerInfo}
                                    </KeyText>
                                    <ValueText className="light">
                                        -{price.toFixed(2)} EUR
                                    </ValueText>
                                </div>
                                <div className="content keyval">
                                    <KeyText className="inset">{`${t(
                                        "filters:company.vat"
                                    )} (${vatPercString}%)`}</KeyText>
                                    <ValueText className="light">
                                        -{vatOnly} EUR
                                    </ValueText>
                                </div>
                                <Separator className="indent" />

                                <div className="content keyval">
                                    <ValueText
                                        className={
                                            propertyBag.walletBalanceExceeded
                                                ? "danger"
                                                : ""
                                        }
                                    >
                                        {t("table:headers.total")}:
                                    </ValueText>
                                    <ValueText
                                        className={
                                            propertyBag.walletBalanceExceeded
                                                ? "danger"
                                                : ""
                                        }
                                    >
                                        -{withVat} EUR
                                    </ValueText>
                                </div>

                                {!propertyBag.walletBalanceExceeded && (
                                    <>
                                        <VSpace size="12" />
                                        <div className="content keyval">
                                            <ValueText className="warning">
                                                {t(
                                                    "forms:payForOffer.wizard.newMainBalance"
                                                )}
                                                :
                                            </ValueText>
                                            <ValueText className="warning">
                                                {propertyBag.remainingBalance}
                                            </ValueText>
                                        </div>{" "}
                                    </>
                                )}
                            </InfoWrapper>
                        </>
                    );
                },
            },
        ],
    };
};

export default WizardPayForOffer;
