import React from "react";
import styled from "styled-components";
import { ReactComponent as MenuBulletSvg } from "../../../assets/pwa/menu-bullet.svg";

const ListWrapper = styled.ul`
    list-style: none;
    padding: 0px;
    border-top:2px solid #ECEDEF;
    margin: 0px;
  
`;

const SvgBullet = styled(MenuBulletSvg)`
    vertical-align: middle;
    &.selected circle {
        fill: #04A9F5;
        stroke: #white;
    }
`;

const GasCode = styled.span`
    padding-left: 12px;
`;

const GasItem = styled.li`
    border-bottom: solid 1px #ECEDEF;
    font-size:12px; 
    cursor: pointer;
    
    &.selected {
        background-color: #E9F2ED;
        font-size: 16px;
        font-weight: bold;
    } 
`;

const GasContent = styled.div`
   padding: 14px 40px 14px 40px;
`;

const GasDetail = styled.div`
    display: inline;
    vertical-align: middle;
`;

const GasQuantity = styled.span`
    float: right;
`;

const GasTypeItems = ({ data, handleClick, selectedGasType }) => {

    const selectedClass = (item) => {
        return item.refrigerantId === selectedGasType.id ? 'selected' : null
    }

    return (
        <ListWrapper>
            { data.map((item) =>
            (
                <GasItem
                    onClick={handleClick}
                    className={selectedClass(item)}
                    key={item.refrigerantId}
                    data-info={item.refrigerantGasTypeCode}
                    data-value={item.refrigerantId}>
                    <GasContent>
                        <SvgBullet className={selectedClass(item)}></SvgBullet>
                        <GasDetail>
                            <GasCode>
                                {item.refrigerantGasTypeCode}
                            </GasCode>
                            <GasQuantity>{item.quantity.toFixed(2)} kg</GasQuantity>
                        </GasDetail>

                    </GasContent>

                </GasItem>
            ))}
        </ListWrapper>
    )
};

export default GasTypeItems;
