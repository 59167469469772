import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Tooltip from "../../../../components/tooltip/Tooltip";

const BidWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    position: relative;
    margin: -15px 0px;
`;

const BidItem = styled.div`
    padding: 7px 0px;
    text-align: center;
    display: flex;
    flex-direction: column;
    min-width: 70px;

    &.a {
        background: rgba(43, 115, 107, 0.1);
    }

    &.b {
        background: rgba(148, 194, 28, 0.1);
    }

    &.c {
        background: rgba(240, 196, 13, 0.1);
    }
`;

export const BidGradePriceCellInfo = ({ prices }) => {
    const { t } = useTranslation("screens");
    const [tooltip, setTooltip] = useState({ a: null, b: null, c: null });

    const handleHover = (tip) => {
        setTooltip((state) => {
            return { ...state, ...tip };
        });
    };

    return (
        <BidWrapper>
            <BidItem
                className="a"
                key="A"
                onMouseEnter={() => handleHover({ a: true })}
                onMouseLeave={() => handleHover({ a: null })}
            >
                {Number(prices.priceA) > 0
                    ? Number(prices.priceA).toFixed(2)
                    : "N/A"}
                {tooltip.a !== null && Number(prices.priceA) > 0 && (
                    <Tooltip
                        position="top right"
                        visible={true}
                        tooltip={t("table:tooltips.exVat", {
                            grade: "A",
                            price: Number(prices.priceA).toFixed(2),
                        })}
                    />
                )}
            </BidItem>

            <BidItem
                className="b"
                key="B"
                onMouseEnter={() => handleHover({ b: true })}
                onMouseLeave={() => handleHover({ b: null })}
            >
                {Number(prices.priceB) > 0
                    ? Number(prices.priceB).toFixed(2)
                    : "N/A"}
                {tooltip.b !== null && Number(prices.priceB) > 0 && (
                    <Tooltip
                        position="top right"
                        visible={true}
                        tooltip={t("table:tooltips.exVat", {
                            grade: "B",
                            price: Number(prices.priceB).toFixed(2),
                        })}
                    />
                )}
            </BidItem>

            <BidItem
                className="c"
                key="C"
                onMouseEnter={() => handleHover({ c: true })}
                onMouseLeave={() => handleHover({ c: null })}
            >
                {Number(prices.priceC) > 0
                    ? Number(prices.priceC).toFixed(2)
                    : "N/A"}
                {tooltip.c !== null && Number(prices.priceC) > 0 && (
                    <Tooltip
                        position="top right"
                        visible={true}
                        tooltip={t("table:tooltips.exVat", {
                            grade: "C",
                            price: Number(prices.priceC).toFixed(2),
                        })}
                    />
                )}
            </BidItem>
        </BidWrapper>
    );
};

const BidGradePriceCell = ({ cell: { row } }) => {
    const accepted = row.original.acceptedBid || row.original.acceptedBidInfo;
    const priceA = accepted
        ? accepted.offeredPriceGradeA
        : row.original.offeredPriceGradeA;
    const priceB = accepted
        ? accepted.offeredPriceGradeB
        : row.original.offeredPriceGradeB;
    const priceC = accepted
        ? accepted.offeredPriceGradeC
        : row.original.offeredPriceGradeC;
    return (
        <BidGradePriceCellInfo
            prices={{ priceA: priceA, priceB: priceB, priceC: priceC }}
        />
    );
};

export default BidGradePriceCell;
//"price EUR per kilo of grade grade refrigerant, VAT excluded"
