import React, {useCallback, useState} from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import Color from "color";
import Button from "../elements/buttons/Button";

const ModalOverlay = styled.div`
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: ${(props) =>
    Color(props.theme.bgOverlay).fade(0.17).string()};
    z-index: 700;
`;

const ModalContainer = styled.div`
    position: absolute;
    min-width: 1000px;
    max-width: 1000px;
    background: white;
    margin: 0 auto;
    padding: 64px 72px 40px;
    z-index: 701;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    transition: all 0.3s ease-in;
    font-family: ${(props) => props.theme.fontFamily};
    box-shadow: 0px 12px 20px 0px rgba(0, 0, 0, 0.16);
    border-radius: 14px;

    @media ${(p) => p.theme.device.mobile} {
        &,
        &.wide {
            min-width: 90%;
            width: 90%;
            max-width: 90%;
            padding: 30px 10px;
        }
    }
`;

const ButtonsWrapper = styled.div`
    text-align: center;
    border-top: 1px solid #000;
    padding-top: 32px;
`;

const DialogButton = styled(Button)`
    background: ${(props) => props.theme.primaryActionColor};
    border: 1px solid ${(props) => props.theme.primaryActionColorBorder};
    color: ${props => props.theme.textWhite};

    &:enabled:hover {
        background: ${props => Color(props.theme.primaryActionColor).lighten(0.15).string()};
    }
`;

export const usePlainModal = ({
                                  closeText,
                                  onClose
                              }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [modalModel, setModalModel] = useState({});

    const toggleOpen = useCallback(
        (modal) => {
            setIsOpen((open) => !open);
            setModalModel(modal);
        },
        [setIsOpen, setModalModel]
    );

    return {
        toggleOpen: toggleOpen,
        modalProps: {
            isOpen,
            closeText,
            modal: modalModel,
            onClose
        }
    }
};

const PlainModal = ({children, modalVariant, closeText, isOpen, onClose, modal}) => {

    if (!isOpen) {
        return null;
    }

    return ReactDOM.createPortal(
        <>
            <ModalOverlay/>
            <ModalContainer className={modalVariant}>
                {children}

                <ButtonsWrapper>
                    <DialogButton onClick={(ev) => {
                        ev.preventDefault();
                        onClose(modal);
                    }}>
                        {closeText}
                    </DialogButton>
                </ButtonsWrapper>
            </ModalContainer>
        </>
        ,
        document.body
    )
};

export default PlainModal;
