import React from "react";
import styled from "styled-components";
import InputElement from "../../../../components/elements/form-elements/InputElement";
import MoneyInputElement from "../../../../components/elements/form-elements/MoneyInputElement";

import ValidationRule, {
    buildValidation,
} from "../../../../core/validations/ValidationRule";

import { KeyVal, ListTitle, HR } from "../../components/StyledElements";

const Content = styled.div`
    width: 100%;
    box-sizing: border-box;
    flex-grow: 1;
    position: relative;
    padding: 35px 60px 0px;
    display: flex;
    flex-direction: column;
    padding: 0px 0px 0px 0px;
    max-height: 500px;
    overflow: auto;
`;

const Section = styled.div`
    padding: 8px 20px;
    &.full-x {
        padding: 8px 0px;
    }

    &.flex {
        display: flex;
    }
`;

const Spacer = styled.div`
    height: 10px;
    width: 100%;
`;

const validSize = (t) => {
    return buildValidation({
        size: ValidationRule.isStringRule().required(
            t("forms:cylinder.wizard.netWeightRequired")
        ).rule,
    });
};

const EditCylinderWizard = (t, initialValues) => {
    return {
        title: t("forms:cylinder.edit"),
        initialValues: initialValues,
        steps: [
            {
                name: t("forms:extraction.wizard.title_step3"),
                fieldNames: ["imageFile", "size"],
                validation: validSize(t),

                fields: (formik, propertyBag) => {
                    return (
                        <>
                            <Content>
                                <HR className="content" />
                                <ListTitle className="wizard">
                                    {t("screens:technician.details")}
                                </ListTitle>
                                <Section>
                                    <KeyVal className="single">
                                        {t("forms:cylinder.cylinderGas")}
                                        <span>
                                            {formik.values[
                                                "refrigerantTypeCode"
                                            ]
                                                ? formik.values[
                                                      "refrigerantTypeCode"
                                                  ]
                                                : t(
                                                      "forms:cylinder.noCylinderGas"
                                                  )}
                                        </span>
                                    </KeyVal>
                                    <KeyVal className="single">
                                        {t("screens:technician.Quantity")}
                                        <span>
                                            {formik.values["filledQuantity"]} kg
                                        </span>
                                    </KeyVal>
                                </Section>
                                <HR className="content" />

                                <MoneyInputElement
                                    type="number"
                                    name="size"
                                    id="size"
                                    labelText={t("forms:cylinderSize.text")}
                                    wrapperVariant={"flex-1 standard"}
                                    values={2}
                                    min={0}
                                    step="0.01"
                                    positive={true}
                                    formik={formik}
                                    currencyLabel="kg"
                                />
                                <Spacer />
                                <InputElement
                                    labelText=""
                                    noLabel
                                    placeholder={t("forms:userPicture.text")}
                                    title={t(
                                        "forms:cylinder.wizard.cylinderPicture"
                                    )}
                                    name="imageFile"
                                    type="gallery"
                                    id="imageFile"
                                    single
                                    wrapperVariant="flex-1"
                                    {...formik}
                                />
                            </Content>
                        </>
                    );
                },
            },
        ],
    };
};
export default EditCylinderWizard;
