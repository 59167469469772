import styled from "styled-components";

const DashboardWrapper = styled.div`

    padding: 0px 25px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow:1;
    @media ${(p) => p.theme.device.mobile} {
        margin-top: 90px;
    }
`;

export default DashboardWrapper;
