import {config} from '../Config/config.prod';
import api from '../getAxios';

const StatisticsService = {
    getMarketplaceCounts: async () => {
        return (await api.get(config.apiUrl.getMarketplaceCounts)).data.data[0];
    },
    getUsersCounts: async () => {
        return (await api.get(config.apiUrl.getUserCounts)).data.data[0];
    },
    getUserOnboarding: async (filter) => {
        return (await api.post(config.apiUrl.getUserOnboarding, filter)).data.data;
    },
    getCompanyOnboarding: async (filter) => {
        return (await api.post(config.apiUrl.getCompanyOnboarding, filter)).data.data;
    },
 
    getCompaniesCounts: async id => {
        return (await api.get(config.apiUrl.getCompaniesCounts)).data.data[0];
    },
    extractedFGas: async () => {
        return (await api.get(config.apiUrl.extractedFGas)).data.data;
    },
    extractedFGasAdmin: async (companyId) => {
        let url = config.apiUrl.extractedFGasAdmin;
        if(companyId) {
            url+= "?companyId=" + companyId;
        }
        return (await api.get(url)).data.data;
    },
    extractions: async (filter)  => {
        return (await api.post(config.apiUrl.extractions, filter)).data.data;
    },
    extractionsAdmin: async (filter)  => {
        return (await api.post(config.apiUrl.extractionsAdmin, filter)).data.data;
    },
    companyStock: async (filter)  => {
        return (await api.post(config.apiUrl.dashboardCompanyStock, filter)).data.data;
    },
    technicianStock: async (filter)  => {
        return (await api.post(config.apiUrl.dashBoardTechnicianStock, filter)).data.data;
    },
    tradedRefrigerants: async ()  => {
        return (await api.get(config.apiUrl.dashBoardMarketPlaceTrades)).data.data;
    },
    tradedRefrigerantsAverages: async (filter)  => {
        return (await api.post(config.apiUrl.dashBoardMarketPlaceAverages, filter)).data.data;
    },
    tradedRefrigerantsAveragesAdmin: async (filter)  => {
        return (await api.post(config.apiUrl.dashBoardMarketPlaceAveragesAdmin, filter)).data.data;
    },
    tradedQuantityPerDate: async (filter)  => {
        return (await api.post(config.apiUrl.dashBoardMarketPlaceTradesPerDate, filter)).data.data;
    },
};

export default StatisticsService;
