import React from "react";
import {Checkbox} from "../../../../../components/elements/form-elements";
import styled from "styled-components";

const GradeCheckboxes = ({t, formik, ...rest}) => {
    return (
        <div {...rest}>
            <Checkbox
                key="gradeA"
                name="gradeA"
                id="gradeA"
                labelText={t("screens:stock.aGradeText")}
                labelClassName="grade"
                className="mb-20"
                {...formik}
            />
            <Checkbox
                key="gradeB"
                name="gradeB"
                id="gradeB"
                labelText={t("screens:stock.bGradeText")}
                labelClassName="grade"
                className="mb-20"
                {...formik}
            />
            <Checkbox
                key="gradeC"
                name="gradeC"
                id="gradeC"
                labelText={t("screens:stock.cGradeText")}
                labelClassName="grade"
                className="mb-20"
                {...formik}
            />
        </div>
    );
};

export default styled(GradeCheckboxes)`
    margin-bottom: 20px;
`;
