import Color from "color";
import React, { useState } from "react";
import styled from "styled-components";

const PaginationWrapper = styled.div`
    margin: 0 auto;
    text-align: center;
    font-size: 0.875rem;
    height: 50px;
    width: 100%;

    @media ${(p) => p.theme.device.mobile} {
        height: 60px;
    }
`;

const PagePrevNext = styled.button`
    background: transparent;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 4px;
    transition: all 0.3s ease;
    min-width: 36px;
    max-width: 36px;
    /* height: 32px; */
    line-height: 32px;
    vertical-align: middle;
    color: ${(p) => p.theme.secondaryColor};
    padding: 8px 12px 6px;
    margin: 0 5px;
    /* &:hover {
        img {
            filter: brightness(0);
        }
    } */

    > img {
        height: 8px;
    }

    &.prev {
        & img {
            transform: rotateZ(90deg);
        }

        /* &:hover {
            transform: translateX(-2px);
        } */
    }

    &.next {
        & img {
            transform: rotateZ(-90deg);
        }
        /* &:hover {
            filter:grayscale();
        } */
    }
`;

const Page = styled.button`
    background: ${(props) => props.theme.bgPagination};
    border-radius: 4px;
    border: 1px solid ${(props) => props.theme.bgPagination};
    padding: 8px 12px 6px;
    margin: 0 5px;
    color: ${(props) =>
        Color(props.theme.textPagination).lighten(0.5).string()};
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.16);
    min-width: 36px;
    max-width: 36px;

    &.current {
        cursor: pointer;
        background: ${(p) => p.theme.secondaryColor};
        color: ${(props) => props.theme.textWhite};
        border-color: ${(p) => p.theme.secondaryColor};
    }

    &:hover {
        cursor: pointer;
        background: ${(p) => p.theme.primaryColorHover};
        color: ${(props) => props.theme.textWhite};
        border-color: ${(p) => p.theme.primaryColorHover};
    }
`;

const Pagination = ({
    numButtons,
    pageCount,
    currentPageIndex,
    canNextPage,
    nextPage,
    canPreviousPage,
    previousPage,
    gotoPage,
}) => {
        const [state, setState] = useState({ minIdx: currentPageIndex });
    //sort reset fix
    if (currentPageIndex === 0 && state.minIdx > 0) {
        setState({ minIdx: 0 });
    } else if (currentPageIndex === pageCount - 1 && state.minIdx < pageCount - numButtons) {
        setState({ minIdx: pageCount - numButtons });
    }

    numButtons = Math.min(numButtons || 3, pageCount);
    const showMore = numButtons < pageCount - 1;

     const visiblePages = () => {
        const pages = [];

        if (showMore && state.minIdx > numButtons - 1) {
            pages.push(
                <Page key="pageFirst" onClick={() => first()}>
                    1
                </Page>
            );
            pages.push(<Page key={"pagePrevSpace"}>...</Page>);
        }

        for (
            var i = state.minIdx;
            i < Math.min(pageCount, state.minIdx + numButtons);
            i++
        ) {
            let pageNum = i;
            pages.push(
                <Page
                    className={currentPageIndex === i ? "current" : ""}
                    key={"page" + pageNum}
                    onClick={
                        currentPageIndex === i
                            ? null
                            : () => {
                                  gotoPage(pageNum);
                              }
                    }
                >
                    {i + 1}
                </Page>
            );
        }
        if (showMore && state.minIdx + numButtons < pageCount - 1) {
            pages.push(<Page key={"pageNextSpace"}>...</Page>);
            pages.push(
                <Page key="pageLast" onClick={() => last()}>
                    {pageCount}
                </Page>
            );
        }
        return pages;
    };

    const fwd = () => {
        const cidx = currentPageIndex;
        nextPage();
        if (cidx >= state.minIdx + numButtons - 1) {
            let newMinIdx = Math.min(cidx + 1, pageCount - numButtons);
            setState({ minIdx: newMinIdx });
        }
    };

    const rew = () => {
        if (currentPageIndex - 1 < state.minIdx) {
            let newMinIdx = Math.max(currentPageIndex - numButtons, 0);
            setState({ minIdx: newMinIdx });
        }
        previousPage();
    };

    const first = () => {
        gotoPage(0);
        setState({ minIdx: 0 });
    };

    const last = () => {
        gotoPage(pageCount - 1);
        setState({ minIdx: pageCount - numButtons });
    };
    return (
        <PaginationWrapper>
            {/* {currentPageIndex > 0 && ( */}
            <PagePrevNext
                className="prev"
                onClick={() => rew()}
                disabled={!canPreviousPage}
            >
                {canPreviousPage && (
                    <img
                        src="/images/menu/expand_thin.svg"
                        alt="Previous page"
                    />
                )}
            </PagePrevNext>
            {/* )} */}
            {pageCount && visiblePages()}
            {/* {currentPageIndex < pageCount - 1 && ( */}
            <PagePrevNext
                className="next"
                onClick={() => fwd()}
                disabled={!canNextPage}
            >
                {canNextPage && (
                    <img src="/images/menu/expand_thin.svg" alt="Next page" />
                )}
            </PagePrevNext>
            {/* )} */}
        </PaginationWrapper>
    );
};

export default Pagination;
