import React from "react";
import styled from "styled-components";
import useLeftMenu from "../../../../core/state/navigation/useLeftMenu";
import FlexContainer from "../../flex/FlexContainer";
import LeftMenu from "../../sidebar/LeftMenu";

const MainContainer = styled(FlexContainer)`
    flex-direction: row;
    overflow: hidden;
    height: 100%;
`;

const LeftMainArea = styled.div`
    height:100%;
`;

const RightMainArea = styled.div`
    overflow-y: auto;
    width: 100%;
    height: 100%;
`;

const MainLayout = (props) => {
    let {menuItems, isVisible} = useLeftMenu();
    return (
        <MainContainer>
            {
                menuItems.length > 0 && isVisible &&
                <LeftMainArea>
                    <LeftMenu/>
                </LeftMainArea>
            }

            <RightMainArea>
                {props.children}
            </RightMainArea>
        </MainContainer>
    )
};

export default MainLayout;
