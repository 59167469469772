import React from "react";
import CollectableTags from "./CollectableTags";

const CollectStep = (props) => {
    return (
        <>
            {
                props.clientTags && props.clientTags.length > 0 &&
                <CollectableTags tags={props.clientTags} handleTags={props.handleTags}/>
            }
        </>
    )
};

export default CollectStep;
