import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ListingPage from "../../../../components/data/ListingPage";
import HamburgerMenu from "../../../../components/menu/HamburgerMenu";
import HamburgerMenuAction from "../../../../components/menu/HamburgerMenuAction";
import ModalDialog, { useModal } from "../../../../components/modal/ModalDialog";
import AuthorizeComponent from "../../../../core/AuthorizeComponent";
import Roles from "../../../../core/enums/Roles";
import useBreadcrumbs from "../../../../core/state/navigation/useBreadcrumbs";
import stockListingStateModule from "../../../../core/state/stock/stockListingStateModule";
import useListingPageContext from "../../../../core/state/useListingPage";
import useStateModule from "../../../../core/state/useStateModule";
import useDataReducer from "../../../../hooks/_shared/useDataReducer";
import useImmerReducer from "../../../../hooks/_shared/useImmerReducer";
import usePageChange from "../../../../hooks/_shared/usePageChange";
import StockService from "../../../../services/Stock/StockService";
import UserService from "../../../../services/User/UserService";
import StockListFooterActions from "../../../SalesCenter/views/components/StockListFooterActions";
import StockListHeaderActions from "../../../SalesCenter/views/components/StockListHeaderActions";
import StockDetailSideBar from "./components/StockDetailSideBar";
import StockFilter from "./components/StockFilter";
import { Helmet } from "react-helmet";
import GreenCell from "../../../../components/data/GreenCell";
import Globals from "../../../../globalVars";
import useSharedState from "../../../../hooks/_shared/useSharedState";
import ModalWizard from "../../../../components/modal/ModalWizard";
import WizardCylinderIssue from "../../../Equipment/components/wizard/WizardCylinderIssue";
import TagsService from "../../../../services/Tags/TagsService";

const actionTypes = {
    SET_GAS_TYPE: "SET_GAS_TYPE",
    RESET_GAS_TYPE: "RESET_GAS_TYPE",
    SET_GAS_QUANTITY_TO: "SET_GAS_QUANTITY",
    RESET_GAS_QUANTITY_TO: "RESET_GAS_QUANTITY",
    SET_GAS_QUANTITY_FROM: "SET_GAS_QUANTITY_FROM",
    RESET_GAS_QUANTITY_FROM: "RESET_GAS_QUANTITY_FROM",
    SET_PRESUMED_QUALITY: "SET_PRESUMED_QUALITY",
    RESET_PRESUMED_QUALITY: "RESET_PRESUMED_QUALITY",
    SET_QUALITY_ASSURANCE: "SET_QUALITY_ASSURANCE",
    RESET_QUALITY_ASSURANCE: "RESET_QUALITY_ASSURANCE",
    SET_MARKETPLACE_STATUS: "SET_MARKETPLACE_STATUS",
    RESET_MARKETPLACE_STATUS: "RESET_MARKETPLACE_STATUS",
    SET_PAGING: "SET_PAGING",
    SET_SORT: "SET_SORT",
    RESET_SORT: "RESET_SORT",
};

const filterReducer = (state, action) => {
    if (action.type !== actionTypes.SET_PAGING) state.page = 1;
    switch (action.type) {
        case actionTypes.SET_GAS_TYPE:
            state.filter.refrigerantGasTypeCode = action.payload;
            break;
        case actionTypes.RESET_GAS_TYPE:
            delete state.filter.refrigerantGasTypeCode;
            break;
        case actionTypes.SET_GAS_QUANTITY_TO:
            state.filter.quantityTo = Number(action.payload);
            break;
        case actionTypes.RESET_GAS_QUANTITY_TO:
            delete state.filter.quantityTo;
            break;
        case actionTypes.SET_GAS_QUANTITY_FROM:
            state.filter.quantityFrom = Number(action.payload);
            break;
        case actionTypes.RESET_GAS_QUANTITY_FROM:
            delete state.filter.quantityFrom;
            break;
        case actionTypes.SET_PRESUMED_QUALITY:
            state.filter.presumedGrade = Number(action.payload);
            break;
        case actionTypes.RESET_PRESUMED_QUALITY:
            delete state.filter.presumedGrade;
            break;
        case actionTypes.SET_QUALITY_ASSURANCE:
            state.filter.hasAnalysis = action.payload === "true";
            break;
        case actionTypes.RESET_QUALITY_ASSURANCE:
            delete state.filter.hasAnalysis;
            break;
        case actionTypes.SET_MARKETPLACE_STATUS:
            state.filter.marketplaceStatusName = action.payload;
            break;
        case actionTypes.RESET_MARKETPLACE_STATUS:
            delete state.filter.marketplaceStatusName;
            break;
        case actionTypes.SET_PAGING:
            state.pageSize = action.payload.pageSize;
            state.page = action.payload.pageIndex + 1;
            break;
        case actionTypes.SET_SORT:
            state.orderColumn = action.payload[0].id;
            state.descending = action.payload[0].desc;
            break;
        case actionTypes.RESET_SORT:
            state.orderColumn = "";
            state.descending = true;
            break;
        default:
            return state;
    }
};

const StockListing = () => {
    const {
        stockItems,
        isLoading,
        error,
        loadStockItems,
        loadStockById,
        details,
        loadGasTypes,
        gasTypesData,
    } = useStateModule(stockListingStateModule);

    const { state: listingPageState, actions: listingPageActions } = useListingPageContext();

    const [globalState] = useSharedState("global");

    useEffect(() => {
        return listingPageActions.resetState();
    }, []);

    const [filterState, dispatch] = useImmerReducer(filterReducer, {
        filter: {
            locationStatusName: "CompanyStock",
        },
        page: 1,
        pageSize: 10,
        orderColumn: "extractionDateTime",
        descending: true,
    });
    const pageChange = usePageChange(dispatch, filterState);

    const { t } = useTranslation("screens");

    useBreadcrumbs([
        {
            text: t("navigation:stock"),
        },
        {
            text: t("navigation:companyStock"),
        },
    ]);

    useEffect(() => {
        loadStockItems(filterState);
    }, [loadStockItems, filterState]);

    useEffect(() => {
        loadGasTypes();
    }, [loadGasTypes]);

    const publishToMarketplace = async (skuId, isDisposal = false, isCleaning = 0) => {
        if (skuId) {
            await StockService.publishToMarketplace([skuId], "", isCleaning === true ? 1 : 0);
        }

        listingPageState.tableInstance.toggleAllRowsSelected &&
            listingPageState.tableInstance.toggleAllRowsSelected(false);
        listingPageActions.resetState();
        await loadStockItems(filterState);
        toast.success(
            isDisposal
                ? t("actions:dialogs.disposal.success")
                : t("screens:stockListing.toastNotification.success")
        );
    };

    const sendToRecycling = async (tagId) => {
        if (tagId) {
            await StockService.sendToRecycling(tagId);
        }

        listingPageState.tableInstance.toggleAllRowsSelected &&
            listingPageState.tableInstance.toggleAllRowsSelected(false);
        listingPageActions.resetState();
        await loadStockItems(filterState);
        toast.success(t("actions:dialogs.recycle.success"));
    };

    const finishRecycling = async (tagId) => {
        if (tagId) {
            const res = await StockService.emptyCylinder(tagId);
        }

        listingPageState.tableInstance.toggleAllRowsSelected &&
            listingPageState.tableInstance.toggleAllRowsSelected(false);
        listingPageActions.resetState();
        await loadStockItems(filterState);
        toast.success(t("actions:dialogs.finishRecycling.success"));
    };

    const [currentStockSkuId, setCurrentStockSkuId] = useState({});

    useEffect(() => {
        if (listingPageState.selectedRows.length > 0) {
            setCurrentStockSkuId({
                gasType: listingPageState.selectedRows[0].original.refrigerantGasTypeCode,
                quantity: listingPageState.selectedRows[0].original.quantity,
                quality: listingPageState.selectedRows[0].original.pressumedGradeName,
                evaluation: listingPageState.selectedRows[0].original.analysisDone
                    ? t("filters:analysis.measurement")
                    : t("filters:analysis.selfAssessment"),
            });
        }
    }, [listingPageState]);

    const columns = useMemo(
        () => [
            {
                Header: t("table:headers.gasType"),
                accessor: "refrigerantGasTypeCode",
                Cell: GreenCell,
            },
            {
                Header: t("table:headers.gasQuantity"),
                accessor: "quantity",
                Cell: ({ cell: { value } }) =>
                    GreenCell({ cell: { value: value.toFixed(2) + " kg" } }),
            },
            {
                Header: t("table:headers.grade"),
                accessor: "pressumedGradeName",
            },
            {
                Header: t("table:headers.evaluation"),
                accessor: "analysisDone",
                Cell: ({ cell: { row } }) => {
                    const item = row.original;
                    return !item.analysisDone
                        ? t("filters:analysis.selfAssessment")
                        : item.isAnalysisReceived
                        ? t("filters:analysis.measurement")
                        : t("filters:analysis.pendingMeasurement");
                },
            },
            {
                Header: t("stockListing.headers.onStockWith"),
                accessor: "fullName",
            },
            {
                Header: t("stockListing.headers.marketplaceStatusName"),
                accessor: "marketplaceStatusName",
                Cell: ({ cell: { value, row } }) => {
                    if (
                        globalState.isDistributor &&
                        row.original.pressumedGradeName === "C" &&
                        value === "OnStock"
                    )
                        value = "RecyclePending";
                    return t("stockListing.marketplaceStatus." + value);
                },
            },
            {
                Header: t("filters:cylinders.type"),
                accessor: "cylinderType",
                Cell: ({ cell: { value } }) => {
                    let typeName =
                        value === 0
                            ? t("filters:cylinders.recovery")
                            : t("filters:cylinders.reclaimed");
                    return typeName;
                },
            },
            {
                Header: "",
                accessor: "skuId",
                Cell: ({ cell: { value, row } }) => {
                    return (
                        <AuthorizeComponent
                            roles={[Roles.InstallerCompanyAdmin, Roles.InstallerCompanyUser]}
                        >
                            <HamburgerMenu>
                                <AuthorizeComponent
                                    roles={[
                                        Roles.InstallerCompanyAdmin,
                                        Roles.InstallerCompanyUser,
                                    ]}
                                    disable={row.original.marketplaceStatusValue !== 0}
                                >
                                    <>
                                        {!Globals.isDistributor && (
                                            <HamburgerMenuAction
                                                text={t("actions:publishToMarketplace")}
                                                disabled={
                                                    row.original.pressumedGradeName === "C" ||
                                                    row.original.marketplaceStatusValue !== 0 ||
                                                    row.original.inUnfinishedOffer ||
                                                    row.original.analysisDone &
                                                        !row.original.isAnalysisReceived
                                                }
                                                onClick={() => {
                                                    setCurrentStockSkuId({
                                                        isCleaning: 0,
                                                        gasType:
                                                            row.original.refrigerantGasTypeCode,
                                                        quantity: row.original.quantity,
                                                        quality: row.original.pressumedGradeName,
                                                        evaluation: row.original.analysisDone
                                                            ? t("filters:analysis.measurement")
                                                            : t("filters:analysis.selfAssessment"),
                                                    });
                                                    toggleOpen(value);
                                                }}
                                            />
                                        )}
                                        {!Globals.isDistributor && (
                                            <HamburgerMenuAction
                                                text={t("actions:publishForCleaning")}
                                                disabled={
                                                    row.original.pressumedGradeName !== "A" ||
                                                    row.original.marketplaceStatusValue !== 0 ||
                                                    row.original.inUnfinishedOffer ||
                                                    row.original.analysisDone &
                                                        !row.original.isAnalysisReceived
                                                }
                                                onClick={() => {
                                                    setCurrentStockSkuId({
                                                        isCleaning: 1,
                                                        gasType:
                                                            row.original.refrigerantGasTypeCode,
                                                        quantity: row.original.quantity,
                                                        quality: row.original.pressumedGradeName,
                                                        evaluation: row.original.analysisDone
                                                            ? t("filters:analysis.measurement")
                                                            : t("filters:analysis.selfAssessment"),
                                                    });
                                                    toggleCleaning(value);
                                                }}
                                            />
                                        )}
                                        {!Globals.isDistributor && (
                                            <HamburgerMenuAction
                                                text={t("actions:publishForDisposal")}
                                                disabled={
                                                    row.original.pressumedGradeName !== "C" ||
                                                    row.original.marketplaceStatusValue !== 0
                                                }
                                                onClick={() => {
                                                    setCurrentStockSkuId({
                                                        gasType:
                                                            row.original.refrigerantGasTypeCode,
                                                        quantity: row.original.quantity,
                                                        quality: row.original.pressumedGradeName,
                                                        evaluation: row.original.analysisDone
                                                            ? t("filters:analysis.measurement")
                                                            : t("filters:analysis.selfAssessment"),
                                                    });
                                                    toggleDisposal(value);
                                                }}
                                            />
                                        )}

                                        {row.original.marketplaceStatusValue === 0 &&
                                            Globals.isDistributor && (
                                                <HamburgerMenuAction
                                                    text={t("actions:recycleCylinder")}
                                                    onClick={() => {
                                                        setCurrentStockSkuId({
                                                            tagId: row.original.tagId,
                                                            gasType:
                                                                row.original.refrigerantGasTypeCode,
                                                            quantity: row.original.quantity,
                                                            quality:
                                                                row.original.pressumedGradeName,
                                                            evaluation: row.original.analysisDone
                                                                ? t("filters:analysis.measurement")
                                                                : t(
                                                                      "filters:analysis.selfAssessment"
                                                                  ),
                                                        });
                                                        toggleRecycle(row.original.tagId);
                                                    }}
                                                    // text={t("actions:publishForDisposal")}
                                                    disabled={row.original.inUnfinishedOffer}
                                                />
                                            )}

                                        {row.original.marketplaceStatusValue === 10 && (
                                            <HamburgerMenuAction
                                                text={t(
                                                    "actions:dialogs.finishRecycling.confirmText"
                                                )}
                                                onClick={() => {
                                                    setCurrentStockSkuId({
                                                        tagId: row.original.tagId,
                                                        gasType:
                                                            row.original.refrigerantGasTypeCode,
                                                        quantity: row.original.quantity,
                                                        quality: row.original.pressumedGradeName,
                                                        evaluation: row.original.analysisDone
                                                            ? t("filters:analysis.measurement")
                                                            : t("filters:analysis.selfAssessment"),
                                                    });
                                                    toggleFinishRecycle(row.original.tagId);
                                                }}
                                                // text={t("actions:publishForDisposal")}
                                                disabled={row.original.inUnfinishedOffer}
                                            />
                                        )}
                                    </>
                                </AuthorizeComponent>
                            </HamburgerMenu>
                        </AuthorizeComponent>
                    );
                },
                disableSortBy: true,
            },
        ],
        [currentStockSkuId, t]
    );

    const [detailViewIsOpened, setDetailViewIsOpened] = useState(false);

    const onSelect = useCallback((row) => {
        const selectRow = async (row) => {
            await loadStockById(row.original.skuId);
            setDetailViewIsOpened(true);
        };
        selectRow(row);
    }, []);

    const closeSideBar = useCallback(() => {
        setDetailViewIsOpened(false);
    }, []);

    const [publishState, publishSelectedItems] = useDataReducer(async (skuIds) => {
        if (skuIds && skuIds.length > 0) {
            await StockService.publishToMarketplace(skuIds);
        }
        listingPageActions.resetState();
        loadStockItems(filterState);
    });

    const publishMultipleToMarketplace = useCallback(async () => {
        const skuIds = listingPageState.selectedRows.map((r) => r.original.skuId);
        await publishSelectedItems(skuIds);
        toast.success(t("screens:stockListing.toastNotification.successMultiple"));
    }, [publishSelectedItems, listingPageState]);

    const gasSelectionIsValid = (rows) => {
        if (rows.length > 0) {
            const firstRow = rows[0].original;

            const shouldAllow = rows.every((row) => {
                return (
                    row.original.refrigerantGasTypeCode === firstRow.refrigerantGasTypeCode &&
                    row.original.pressumedGradeValue === firstRow.pressumedGradeValue &&
                    row.original.analysisDone === firstRow.analysisDone
                );
            });

            return shouldAllow;
        }
        return true;
    };

    const shouldAllowPublish = useCallback(() => {
        if (listingPageState.selectedRows.length > 0) {
            return gasSelectionIsValid(listingPageState.selectedRows);
        }
        return listingPageState.selectedRows.length > 0;
    }, [listingPageState]);

    const shouldShowDisposeButton = useCallback(() => {
        if (!globalState.isDistributor && listingPageState.selectedRows.length > 0) {
            return listingPageState.selectedRows.every((row) => {
                return row.original.pressumedGradeValue === 2;
            });
        }
        return false;
    }, [listingPageState]);

    const shouldEnableSelectionForRow = (row, selectedRows) => {
        if (row.original.marketplaceStatusName !== "OnStock") {
            return false;
        }
        if (UserService.isInRole(Roles.InstallerCompanyTechnician)) {
            return false;
        }

        if (selectedRows.length > 0) {
            return gasSelectionIsValid([...selectedRows, row]);
        }

        return true;
    };

    const sortChange = (sortBy) => {
        sortBy.length > 0
            ? dispatch({ type: actionTypes.SET_SORT, payload: sortBy })
            : dispatch({ type: actionTypes.RESET_SORT });
    };

    const currentSortBy = useMemo(() => {
        return [{ id: filterState.orderColumn, desc: filterState.descending }];
    }, [filterState.orderColumn, filterState.descending]);

    const { modalProps, toggleOpen } = useModal({
        title: t("sidebar:button.bid_publishOffer").toUpperCase(),
        type: "info",
        onConfirm: async (offer) => {
            await publishToMarketplace(offer).then(toggleOpen());
        },
        onCancel: () => {
            toggleOpen();
        },
        confirmText: t("actions:dialogs.publishStockItem.confirmText"),
    });

    const { modalProps: disposalProps, toggleOpen: toggleDisposal } = useModal({
        title: t("actions:dialogs.disposal.title").toUpperCase(),
        type: "info",
        onConfirm: async (offer) => {
            await publishToMarketplace(offer, true).then(toggleDisposal());
        },
        onCancel: () => {
            toggleDisposal();
        },
        confirmText: t("actions:dialogs.publishStockItem.confirmText"),
    });

    const { modalProps: cleaninglProps, toggleOpen: toggleCleaning } = useModal({
        title: t("actions:dialogs.cleaning.title").toUpperCase(),
        type: "info",
        onConfirm: async (offer) => {
            await publishToMarketplace(offer, false, true).then(toggleCleaning());
        },
        onCancel: () => {
            toggleCleaning();
        },
        confirmText: t("actions:dialogs.publishStockItem.confirmText"),
    });

    const { modalProps: recycleProps, toggleOpen: toggleRecycle } = useModal({
        title: t("actions:dialogs.recycle.title").toUpperCase(),
        type: "info",
        onConfirm: async (tagId) => {
            await sendToRecycling(tagId);
            toggleRecycle();
        },
        onCancel: () => {
            toggleRecycle();
        },
        confirmText: t("actions:dialogs.recycle.confirmText"),
    });

    const { modalProps: finishRecyclingProps, toggleOpen: toggleFinishRecycle } = useModal({
        title: t("actions:dialogs.finishRecycling.title").toUpperCase(),
        type: "info",
        onConfirm: async (tagId) => {
            await finishRecycling(tagId);
            toggleFinishRecycle();
        },
        onCancel: () => {
            toggleFinishRecycle();
        },
        confirmText: t("actions:dialogs.finishRecycling.confirmText"),
    });

    const [currentStockItem, setCurrentStockItem] = useState({});
    useEffect(() => {
        setCurrentStockItem(
            stockItems.data.filter((item) => item.skuId === details.stockData.skuId)
        );
    }, [details]);

    const reportCylinderIssue = async (cylinderId) => {
        ModalWizard.show(
            WizardCylinderIssue(t),
            {
                onSubmit: async (values) => {
                    values.cylinderId = cylinderId;
                    values.issueType = Number(values.issueType);
                    const result = await TagsService.addCylinderIssueAsync(values);
                    if (result && result.error) ModalWizard.submitFailed(t(result.error.message));
                    //toast.error(t(result.error.message));
                    else {
                        toast.success(t("actions:dialogs.reportCylinder.success"));
                        ModalWizard.hide();
                    }
                },
                onCancel: async () => {
                    ModalWizard.hidePrompt();
                },
            },
            { style: { minHeight: "708px" } }
        );
    };

    return (
        <>
            <Helmet>
                <title>{t("seo:companyStock.title")}</title>
            </Helmet>
            <ListingPage
                columns={columns}
                tableState={{
                    data: stockItems.data,
                    loading: isLoading || publishState.isLoading,
                    error: error,
                }}
                paging={{
                    enablePaging: true,
                    pageCount: stockItems.totalPages,
                    pageSize: stockItems.pageSize,
                    onPageChange: pageChange,
                    currentPageIndex: stockItems.currentPage - 1,
                }}
                sorting={{
                    enableSorting: true,
                    onSortByChange: sortChange,
                    currentSortBy: currentSortBy,
                }}
                onSelect={onSelect}
                closeSideBar={closeSideBar}
                enableSelection={!globalState.isDistributor}
                getRowId={(row) => {
                    return row.skuId;
                }}
                shouldEnableSelectionForRow={shouldEnableSelectionForRow}
                showSelectAll={false}
                actions={{
                    headerActions: () => {
                        return (
                            <>
                                <StockListHeaderActions
                                    filteredStockItems={stockItems.data}
                                    publishMultipleToMarketplace={publishMultipleToMarketplace}
                                    shouldAllowPublish={shouldAllowPublish}
                                    shouldShowDisposeButton={shouldShowDisposeButton}
                                    listingPageState={listingPageState}
                                />
                            </>
                        );
                    },
                    footerActions: () => {
                        return (
                            <StockListFooterActions
                                gasSelectionIsValid={gasSelectionIsValid}
                                filteredStockItems={stockItems.data}
                                selectedRows={listingPageState.selectedRows}
                            />
                        );
                    },
                }}
                detailView={
                    <StockDetailSideBar
                        key={detailViewIsOpened}
                        selectedItem={currentStockItem[0]}
                        stockItem={details.stockData}
                        isLoading={details.isLoading}
                        isCompanyStock={true}
                        reportCylinderIssue={reportCylinderIssue}
                    />
                }
                filterTitle={t("filters:stock.companyFilterTitle")}
                filterComponent={() => (
                    <StockFilter
                        filterState={filterState}
                        dispatch={dispatch}
                        actionTypes={actionTypes}
                        gasTypes={gasTypesData.gasTypes}
                    />
                )}
                detailViewIsOpened={detailViewIsOpened}
                variants={{
                    headerViewVariant: "right-action",
                }}
                noDataText={t("screens:stockListing.notification")}
                detailSideBarTitle={t("screens:stockListing.sidebarTitle")}
            />
            <ModalDialog {...modalProps}>
                <p>{t("actions:dialogs.publishStockItem.text")}</p>
                <p className="list">
                    {`• ${currentStockSkuId.gasType} | ${currentStockSkuId.quantity?.toFixed(
                        2
                    )} kg | ${currentStockSkuId.quality} | ${currentStockSkuId.evaluation}`}
                </p>
            </ModalDialog>
            <ModalDialog {...disposalProps}>
                <p>{t("actions:dialogs.disposal.text")}</p>
                <p className="list">
                    {`• ${currentStockSkuId.gasType} | ${currentStockSkuId.quantity?.toFixed(
                        2
                    )} kg | ${currentStockSkuId.quality} | ${currentStockSkuId.evaluation}`}
                </p>
            </ModalDialog>
            <ModalDialog {...cleaninglProps}>
                <p>{t("actions:dialogs.cleaning.text")}</p>
                <p className="list">
                    {`• ${currentStockSkuId.gasType} | ${currentStockSkuId.quantity?.toFixed(
                        2
                    )} kg | ${currentStockSkuId.quality} | ${currentStockSkuId.evaluation}`}
                </p>
            </ModalDialog>
            <ModalDialog {...recycleProps}>
                <p>{t("actions:dialogs.recycle.text")}</p>
                <p className="list">{`• ${currentStockSkuId.tagId}`}</p>
                <p className="list">
                    {`• ${currentStockSkuId.gasType} | ${currentStockSkuId.quantity?.toFixed(
                        2
                    )} kg`}
                </p>
            </ModalDialog>
            <ModalDialog {...finishRecyclingProps}>
                <p>{t("actions:dialogs.finishRecycling.text")}</p>
                <p className="list">{`• ${currentStockSkuId.tagId}`}</p>
            </ModalDialog>
        </>
    );
};

export default StockListing;
