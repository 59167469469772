import Color from "color";
import { Formik } from "formik";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import produce from "immer";
import ModalDialog from "../../components/modal/ModalDialog";
import helpers from "../../core/helpers";
import Button from "../elements/buttons/Button";
import Logo from "../logo/Logo";

const StyledModalOverlay = styled.div`
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: ${(props) => Color(props.theme.bgOverlay).fade(0.1).string()};
    z-index: 500;
`;

const StyledModalContainer = styled.div`
    position: absolute;
    width: 650px;
    max-width: 650px;
    min-height: 580px;
    background: white;
    margin: 0 auto;
    padding: 24px 30px 30px;
    z-index: 505;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    transition: all 0.3s ease-in;
    font-family: ${(props) => props.theme.fontFamily};
    box-shadow: 0px 12px 20px 0px rgba(0, 0, 0, 0.16);
    border-radius: 8px;

    & * {
        outline: 0;
    }

    &.wide {
        width: 900px;
        max-width: 900px;
    }

    &.pwa {
        width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: 100%;
        padding: 24px 20px 20px;
        overflow-x: hidden;
    }

    &.submitting .currentCircle {
        background: ${(p) => p.theme.primaryColor} !important;
    }

    &.submitting .current {
        font-size: 0;
    }
`;

const GrayBack = styled.div`
    width: 100%;
    height: 68px;
    background: #ebebeb;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 8px 8px 0px 0px;
    z-index: -1;
`;

const ModalContentWrapper = styled.div`
    margin: 30px 0 30px;
    line-height: 1.6rem;
    max-width: 100%;
    padding: 0px 25px;
    flex: 1;
    display: flex;
    flex-grow: 1;

    &.pwa {
        margin: 10px 0 30px;
        padding: 0px 0px;
        height: calc(100% - 175px);
        max-height: calc(100% - 175px);
        overflow: hidden;
    }
`;

const ModalContent = styled.form`
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    align-items: flex-start;
    text-align: left;
    align-content: flex-start;
    position: relative;

    @media ${(p) => p.theme.device.mobile} {
        overflow-y: auto;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        color: ${(props) => props.theme.primaryColor};
        text-transform: uppercase;
        margin: 0 0 32px;
        font-size: 1.5rem;
        font-weight: 500;
    }

    p {
        margin: 0;
        font-size: 1.125rem;
    }

    & input[type="submit"] {
        visibility: hidden;
        display: none;
    }
`;

const FooterWrapper = styled.div`
    display: flex;
    flex-direction: row;

    max-width: 100%;
    padding: 30px 60px 0px 60px;

    p {
        margin: 0;
        font-size: 10px;

        width: 100%;
        text-align: center;

        span {
            font-weight: 700;
            color: ${(props) => props.theme.primaryColor};
            font-style: italic;
        }
    }
`;

const ModalButtonsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 100%;
    padding: 0px 35px;

    &.pwa {
        padding: 0;
        position: absolute;
        bottom: 30px;
        width: 90%;
        max-width: 90%;
    }

    & button {
        height: 32px;
        line-height: 32px;
        position: relative;
    }

    &.pwa button {
        min-width: 60px !important;

        &:before {
            left: calc(50% - 6px) !important;
            /* left: 4px !important; */
        }

        &:after {
            right: calc(50% - 6px) !important;
            /* right: 4px !important; */
        }

        &.finish:after {
            position: absolute;
            top: 8px;
            background-size: 14px 14px;
            left: 20px;
        }
    }

    & button.next:after,
    button.finish:after {
        content: "";
        background: url("/images/menu/expand_thin-white.svg") no-repeat center;
        background-size: 12px 12px;
        /* transition: all 0.5s ease; */
        position: absolute;
        right: 12px;
        top: 9px;
        width: 12px;
        height: 12px;
        text-align: center;
        transform: rotate(-90deg);
    }

    & button.finish:after {
        background: url("/images/actions/icon-ok-white.svg") no-repeat center;
        transform: rotate(0deg);
        width: 16px;
        height: 16px;
    }

    & button.prev:before {
        content: "";
        background: url("/images/menu/expand_thin-white.svg") no-repeat center;
        background-size: 12px 12px;
        /* transition: all 0.5s ease; */
        position: absolute;
        left: 12px;
        top: 9px;
        width: 12px;
        height: 12px;
        text-align: center;
        transform: rotate(90deg);
    }

    & button.prev-disabled:before {
        content: "";
        background: url("/images/menu/expand_thin-lightGray.svg") no-repeat center;
        background-size: 12px 12px;
        /* transition: all 0.5s ease; */
        position: absolute;
        left: 12px;
        top: 9px;
        width: 12px;
        height: 12px;
        text-align: center;
        transform: rotate(90deg);
    }
`;

const ModalCloseButton = styled.div`
    cursor: pointer;
    width: 24px;
    height: 24px;
    background: transparent;
    color: ${(props) => props.theme.textColor};
    display: flex;
    align-items: center;
    align-self: flex-end;
    justify-content: center;
    font-size: 42px;
    border-radius: ${(props) => props.theme.closeButtonBorderRadius};
    border: 2px solid transparent;
    /* box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.16); */
    transition: all 0.3s ease-in;
    position: absolute;
    top: 18px;
    right: 14px;

    &.pwa {
        background-color: #000;
        color: #fff;
        border-radius: 50%;
        font-size: 28px;
        width: 28px;
        font-weight: 500;
        height: 28px;
        right: 10px;
    }

    &:after {
        content: "+";
        transform: rotate(45deg);
    }

    &:hover {
        background: ${(props) => Color(props.theme.bgIvory).darken(0.05).string()};
        /* border-color: ${(props) => props.theme.bgActive}; */
    }
`;

const DialogButton = styled(Button)`
    min-width: 148px !important;

    &.primary.finish {
        background-color: #e69221 !important;
        border: 1px solid transparent;
        text-transform: uppercase;
        font-weight: 600;
    }

    &:disabled {
        opacity: 0.2;
    }
`;

const FormTitle = styled.h3`
    text-align: center;
    margin: 0;
    max-width: 100%;
`;

const CircleWrapper = styled.div`
    --numSteps: ${(props) => (props.numSteps !== undefined ? props.numSteps : 3)};
    display: flex;
    /* justify-content: ${(props) => (props.numSteps === 1 ? "space-around" : "space-between")}; */
    justify-content: center;
    min-width: calc(20px * var(--numSteps));
    align-items: center;
`;

const Circle = styled.div`
    width: 8px;
    height: 8px;
    border: 2px;
    background: ${(p) => p.theme.primaryColor};
    border-radius: 50%;
    border: 2px solid;
    border-color: ${(p) => p.theme.primaryColor};
    margin: 0 12px 0 0;

    &:last-of-type {
        margin: 0;
    }

    &.currentCircle {
        background: transparent;
    }

    &.nextCircle {
        background: #e0e0e0;
        border-color: #e0e0e0;
    }
`;

const Stepper = styled.ol`
    --numSteps: ${(props) => (props.numSteps !== undefined ? props.numSteps : 3)};
    --foreColor: #fff;
    --backColor: #fff;
    --activeColor: #fff;
    --activeBackColor: ${(p) => p.theme.primaryColor};
    --line-width: 4px;
    --bullet-size: 24px;

    background-color: transparent;
    position: relative;
    overflow: hidden;
    counter-reset: wizard -1;
    list-style: none;
    padding: 0;
    font-size: 12px;
    margin: 8px 0px;
    width: 130%;
    margin-left: -15%;

    & li {
        position: relative;
        float: left;
        width: calc(100% / var(--numSteps));
        text-align: center;
        /* color: var(--activeColor); */
    }

    & li.current {
        font-weight: 600;
        & span {
            margin: 0px -80px;
        }
    }

    & li:before {
        counter-increment: wizard;
        content: counter(wizard);
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--activeColor);
        background-color: var(--activeBackColor);
        border: var(--line-width) solid var(--activeBackColor);
        text-align: center;
        width: var(--bullet-size);
        height: var(--bullet-size);
        min-width: var(--bullet-size);
        min-height: var(--bullet-size);
        max-width: var(--bullet-size);
        max-height: var(--bullet-size);
        line-height: 26px;
        border-radius: var(--bullet-size);
        border-width: 3px;
        position: relative;
        left: 50%;
        margin-bottom: calc(var(--bullet-size) / 2 + 1px);
        margin-left: calc(var(--bullet-size) * -0.5);
        z-index: 1;
        font-size: 14px;
    }

    & li.current:before,
    & li.current ~ li:before {
        background-color: var(--backColor);
        color: var(--activeBackColor);
        border-color: var(--activeBackColor);
    }

    & li.current ~ li:before {
        border-color: #8e8e8e;
    }

    /* & li.submitting:before {
        background-color: var(--activeBackColor) !important;
        color: #fff !important;
    } */

    & li:first-child:before {
        content: "S";
    }

    & li:last-child:before {
        content: "E";
        color: #8e8e8e;
    }

    & li[data-start]:before {
        content: url("/images/actions/arrow_right.svg");
        line-height: 27px;
    }

    & li.last-step:before {
        color: #8e8e8e !important;
    }

    & li.final-step:before {
        content: "" !important;
        background-image: url("/images/actions/icon-ok.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 66%;
    }

    & li[data-step]:before {
        content: attr(data-step);
        /* line-height: 25px; */
    }

    & li + li {
        &:after {
            content: "";
            display: block;
            width: 100%;
            background-color: var(--activeBackColor);
            height: var(--line-width);
            position: absolute;
            left: -50%;
            top: calc(var(--bullet-size) / 2 + 2px);
            z-index: 0;
        }
    }

    li.current ~ li:after {
        background-color: transparent;
        border-bottom: 1px dashed #8e8e8e;
        border-width: 2px;
        content: "";
        top: calc((var(--bullet-size) / 2) - 1px);
    }

    &.submitting li.current:before {
        background: transparent;
        border: 3px solid transparent;
        content: "";
    }

    &.submitting li.current ~ li:after {
        border-bottom: 4px solid ${(p) => p.theme.primaryColor};
        top: calc((var(--bullet-size) / 2) - 2px);
    }

    &.submitting li.final-step:before {
        content: "";
        background-image: url("/images/actions/icon-ok-white.svg");
        background-color: var(--activeBackColor) !important;
        border-color: var(--activeBackColor);
    }

    & li.uppercase {
        text-transform: uppercase;
    }
`;

let __instance = null;

function Renderer(props) {
    useEffect(() => {
        while (props.updateFieldsCB.length > 0) {
            let cb = props.updateFieldsCB.pop();
            cb();
        }
    });

    return props.children();
}

const getModalProps = (title, confirm) => ({
    isOpen: false,
    onConfirm: null,
    onCancel: null,
    modalButtons: null,
    confirmText: confirm,
    cancelText: null,
    cancelFirst: false,
    isNegativeAction: false,
    modalVariant: "",
    title: title,
    type: "warning",
    logo: false,
});

class ModalWizard extends React.Component {
    constructor(props) {
        super(props);
        __instance = this;
        this.updateFieldsCB = [];
        this.values = {};
        this.formikInstances = [];
        this.onStateChange = null;
        this.state = {
            isOpen: false,
            isSubmitting: false,
            wizard: { title: "Form Title" },
            currentStep: 1,
            numSteps: 6,
            onSubmit: undefined,
            onCancel: undefined,
            wizardState: {},
            propertyBag: {},
            modalProps: getModalProps(
                props.t("actions:wizard.closeWizard"),
                props.t("actions:exit")
            ),
        };
        this.state.modalProps.onCancel = () => {
            this.setState({
                modalProps: { ...this.state.modalProps, isOpen: false },
            });
        };
        this.handlePrev = this.handlePrev.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.handleStepRender = this.handleStepRender.bind(this);
        this.updatePropertyBag = this.updatePropertyBag.bind(this);
        this.updateFields = this.updateFields.bind(this);
        this.__show = this.__show.bind(this);
        this.setState = this.setState.bind(this);
    }

    static show(forms, actions, options) {
        __instance.__show(forms, actions, options);
    }

    static hide(timeout = 1000) {
        setTimeout(() => {
            __instance.__hide();
        }, timeout);
    }

    static hidePrompt(onConfirm) {
        __instance.__hidePrompt(onConfirm);
    }

    static get state() {
        return __instance.state.wizardState;
    }

    static set state(wizardState) {
        __instance.setState({ wizardState });
    }

    static set onStateChange(callbackFn) {
        __instance.onStateChange = callbackFn;
    }

    static submitFailed(message) {
        __instance.__submitFailed(message);
    }

    static asFormData(values = null, mappings = {}) {
        return helpers.getFormData(values ? values : __instance.values, mappings);
    }

    static groupValues(values, separator, valuesOnly) {
        let temp = [];
        for (var prop in values) {
            const parts = prop.split(separator, 2);
            if (parts.length !== 2) throw Error("Error splitting values");
            if (temp[parts[1]] === undefined) {
                temp[parts[1]] = {
                    id: parts[1],
                    values: {},
                };
            }
            temp[parts[1]].values[parts[0]] = values[prop];
        }
        const result = [];
        for (var item in temp) result.push(valuesOnly ? temp[item].values : temp[item]);
        return result;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (__instance.onStateChange) {
            this.onStateChange(
                this.state.wizardState,
                this.formikInstances[this.state.currentStep - 1]
            );
        }
    }

    handleStepRender = async () => {
        if (this.state.wizard.steps[this.state.currentStep - 1].onStepRender) {
            let disableSubmit = await this.state.wizard.steps[
                this.state.currentStep - 1
            ].onStepRender({
                value: this.values,
                propertyBag: this.state.propertyBag,
                updatePropertyBag: this.updatePropertyBag,
                formik: this.formikInstances[this.state.currentStep - 1],
            });
            this.setState(
                produce((draft) => {
                    draft.wizard.steps[this.state.currentStep - 1].disableSubmit = disableSubmit;
                })
            );
        }
    };

    handleStepSubmit = async (values, formikBag) => {
        formikBag.setSubmitting(false);
        const fieldNames = this.state.wizard.steps[this.state.currentStep - 1].fieldNames;

        let mergedValues = { ...this.values };

        if (fieldNames) {
            fieldNames.forEach((field) => (mergedValues[field] = values[field]));
        }

        //call onStepSubmit
        if (this.state.wizard.steps[this.state.currentStep - 1].onStepSubmit) {
            if (
                (await this.state.wizard.steps[this.state.currentStep - 1].onStepSubmit({
                    values: mergedValues,
                    propertyBag: this.state.propertyBag,
                    updatePropertyBag: this.updatePropertyBag,
                    formik: this.formikInstances[this.state.currentStep - 1],
                })) === false
            )
                return;
        }

        if (fieldNames) {
            this.values = mergedValues;
            this.formikInstances.forEach((formik) => {
                formik.setValues({ ...this.values }, false);
            });
        }

        if (this.state.currentStep < this.state.numSteps)
            this.setState({ currentStep: this.state.currentStep + 1 }, this.handleStepRender);
        else if (!this.state.isSubmitting) {
            formikBag.setSubmitting(true);
            this.setState({ isSubmitting: true }, () => {
                this.state.onSubmit && this.state.onSubmit(this.values);
            });
        }
    };

    __submitFailed = (message) => {
        if (!this.state.isSubmitting) return;
        toast.error(message);
        this.setState({ isSubmitting: false });
    };

    __hidePrompt = (onConfirm) => {
        this.setState({
            modalProps: {
                ...this.state.modalProps,
                isOpen: true,
                title: this.props.t("actions:wizard.closeWizard"),
                confirmText: this.props.t("actions:exit"),
                onConfirm: () => {
                    this.__hide();
                    this.setState({
                        modalProps: { ...this.state.modalProps, isOpen: false },
                    });
                    onConfirm && onConfirm();
                },
            },
        });
    };

    modalContent() {
        this.formikInstances = [];
        if (this.state.wizard.steps) {
            return this.state.wizard.steps.map((step, idx) => {
                return (
                    <Formik
                        key={`frm-${idx}`}
                        initialValues={this.state.wizard.initialValues || {}}
                        // initialValues={this.values || {}}
                        validationSchema={
                            typeof this.state.wizard.steps[idx].validation === "function"
                                ? this.state.wizard.steps[idx].validation(this.state.wizardState)
                                : this.state.wizard.steps[idx].validation
                        }
                        onSubmit={this.handleStepSubmit}
                    >
                        {(formik, propertyBag) => {
                            this.formikInstances.push(formik);
                            return (
                                <ModalContent
                                    className={this.state.options.pwa ? "pwa" : ""}
                                    autoComplete="off"
                                    style={{
                                        display:
                                            idx === this.state.currentStep - 1 ? "flex" : "none",
                                    }}
                                    key={`step-${idx}`}
                                    onSubmit={formik.handleSubmit}
                                >
                                    <input
                                        autoComplete="false"
                                        name={`hidden${step}`}
                                        type="text"
                                        style={{ display: "none" }}
                                    />
                                    {this.state.wizard.steps[idx].children &&
                                        this.state.wizard.steps[idx].children}

                                    <Renderer
                                        propertyBag={this.state.propertyBag}
                                        updateFieldsCB={this.updateFieldsCB}
                                    >
                                        {() => {
                                            return this.state.wizard.steps[idx].fields(
                                                formik,
                                                this.state.propertyBag,
                                                this.updatePropertyBag,
                                                this.updateFields
                                            );
                                        }}
                                    </Renderer>

                                    <input type="submit" id={`wiz-step-submit-${idx + 1}`} />
                                </ModalContent>
                            );
                        }}
                    </Formik>
                );
            });
        } else return null;
    }

    updatePropertyBag(props) {
        this.setState({ propertyBag: { ...this.state.propertyBag, ...props } });
    }

    updateFields(cb) {
        this.updateFieldsCB.push(cb);
    }

    handlePrev = (ev) => {
        if (this.state.currentStep > 1)
            this.setState(
                {
                    currentStep: this.state.currentStep - 1,
                    isSubmitting: false,
                },
                this.handleStepRender
            );
    };

    handleNext = (ev) => {
        const submitButton = document.getElementById(`wiz-step-submit-${this.state.currentStep}`);
        submitButton.click();
    };

    circles = () => {
        const result = [];
        for (var i = 1; i <= this.state.numSteps; i++) {
            result.push(
                <Circle
                    key={i}
                    className={
                        i < this.state.currentStep
                            ? "prevCircle"
                            : i === this.state.currentStep
                            ? "currentCircle"
                            : "nextCircle"
                    }
                />
            );
        }
        return result;
    };

    stepName  ({wizStep, values}) {
        return typeof wizStep.name === "function"
            ? wizStep.name(values).toUpperCase()
            : wizStep.name.toUpperCase();
    };

    render() {
        if (!this.state.isOpen) {
            return null;
        }

        const wizard = this.state.wizard;
        const step = this.state.currentStep;
        const submitting = this.state.isSubmitting ? "submitting" : "";
        const wide = this.state.options.wide ? "wide" : "";
        const pwa = this.state.options.pwa ? "pwa" : "";
        const classNames = `${submitting} ${wide} ${pwa}`;
        const t = this.props.t;
        const logo = this.state.options.logo;
        const footerContent = this.state.options.footerContent;

        return ReactDOM.createPortal(
            <>
                <StyledModalOverlay id="modal-open" />
                <StyledModalContainer
                    className={classNames}
                    style={this.state.options.style}
                    data-test={wizard.dataTest ? wizard.dataTest : ""}
                >
                    <GrayBack />
                    {logo && (
                        <Logo
                            variant="big"
                            width="129"
                            height="28"
                            style={{
                                position: "absolute",
                                top: "18px",
                                left: "26px",
                            }}
                        />
                    )}

                    <FormTitle>{wizard.title}</FormTitle>
                    <Stepper className={submitting}>
                        <li data-start="1" className="uppercase">
                            {t("forms:wizard.start")}
                        </li>
                        <li data-step={step} className="current">
                            {wizard.steps && (
                                <span>
                                    {this.stepName({
                                        wizStep: wizard.steps[step - 1],
                                        values: this.values,
                                    })}
                                </span>
                            )}
                        </li>
                        <li
                            data-step={step < this.state.numSteps ? this.state.numSteps : "✔"}
                            className={`${
                                step === this.state.numSteps
                                    ? "final-step uppercase"
                                    : "last-step uppercase"
                            }`}
                        >
                            {t("forms:wizard.finish")}
                        </li>
                    </Stepper>
                    <ModalCloseButton
                        className={this.state.options.pwa ? "pwa" : ""}
                        onClick={() => {
                            if (this.state.onCancel === undefined || this.state.onCancel() === true)
                                this.setState({ isOpen: false });
                        }}
                    />
                    <ModalContentWrapper
                        className={this.state.options.pwa ? "pwa modal-content" : "modal-content"}
                    >
                        {this.modalContent(this.state.propertyBag)}
                    </ModalContentWrapper>
                    <ModalButtonsWrapper className={this.state.options.pwa ? "pwa" : ""}>
                        <DialogButton
                            onClick={this.handlePrev}
                            className={step > 1 ? "primary prev" : "primary prev-disabled"}
                            disabled={step === 1}
                        >
                            {!this.state.options.pwa && t("forms:wizard.previous")}
                        </DialogButton>
                        <CircleWrapper numSteps={this.state.numSteps}>
                            {this.circles()}
                        </CircleWrapper>

                        <DialogButton
                            type="submit"
                            disabled={
                                this.state.wizard.steps[this.state.currentStep - 1].disableSubmit
                            }
                            onClick={this.handleNext}
                            className={
                                this.state.currentStep < this.state.numSteps
                                    ? "primary next"
                                    : "primary finish"
                            }
                        >
                            {!this.state.options.pwa &&
                                (this.state.currentStep < this.state.numSteps
                                    ? t("forms:wizard.next")
                                    : this.state.options.finishButtonText
                                    ? this.state.options.finishButtonText
                                    : t("forms:wizard.finish"))}
                        </DialogButton>
                    </ModalButtonsWrapper>
                    <FooterWrapper>
                        {this.state.options.footerContent && (
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: this.state.options.footerContent,
                                }}
                            />
                        )}
                    </FooterWrapper>
                </StyledModalContainer>
                <ModalDialog {...this.state.modalProps}>
                    <p>{t("actions:wizard.areYouSure")}</p>
                    <p>{t("actions:wizard.unsavedChanges")}</p>
                </ModalDialog>
            </>,
            document.body
        );
    }

    __show(wizard, actions = {}, options = {}) {
        __instance.formikInstances = [];
        __instance.values = wizard.initialValues ? { ...wizard.initialValues } : {};
        __instance.setState(
            {
                isOpen: true,
                isSubmitting: false,
                options,
                wizard,
                currentStep: options.currentStep || 1,
                numSteps: wizard.steps.length,
                onSubmit: actions.onSubmit,
                onCancel: actions.onCancel,
                wizardState: {},
                propertyBag: {},
                renderCount: 0,
            },
            async () => {
                if (wizard.steps[0].onStepRender) {
                    let disableSubmit = await wizard.steps[0].onStepRender({
                        values: __instance.values,
                        updatePropertyBag: __instance.updatePropertyBag,
                        formik: __instance.formikInstances[0],
                    });
                    __instance.state.wizard.steps[0].disableSubmit = disableSubmit;
                }
            }
        );
    }

    __hide() {
        __instance.formikInstances = [];
        __instance.onStateChange = null;
        __instance.setState({
            isOpen: false,
            wizardState: {},
            propertyBag: {},
            renderCount: 0,
            wizard: null,
            options: undefined,
            actions: undefined,
        });
    }

    __toggle() {
        __instance.setState({ isOpen: !this.state.isOpen });
    }
}

export default ModalWizard;
