import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`

    @font-face {
        font-family: "pass";
        font-style: normal;
        font-weight: 400;
        src: url(data:application/font-woff;charset=utf-8;base64,d09GRgABAAAAAATsAA8AAAAAB2QAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABGRlRNAAABWAAAABwAAAAcg9+z70dERUYAAAF0AAAAHAAAAB4AJwANT1MvMgAAAZAAAAA/AAAAYH7AkBhjbWFwAAAB0AAAAFkAAAFqZowMx2N2dCAAAAIsAAAABAAAAAQAIgKIZ2FzcAAAAjAAAAAIAAAACAAAABBnbHlmAAACOAAAALkAAAE0MwNYJ2hlYWQAAAL0AAAAMAAAADYPA2KgaGhlYQAAAyQAAAAeAAAAJAU+ATJobXR4AAADRAAAABwAAAAcCPoA6mxvY2EAAANgAAAAEAAAABAA5gFMbWF4cAAAA3AAAAAaAAAAIAAKAE9uYW1lAAADjAAAARYAAAIgB4hZ03Bvc3QAAASkAAAAPgAAAE5Ojr8ld2ViZgAABOQAAAAGAAAABuK7WtIAAAABAAAAANXulPUAAAAA1viLwQAAAADW+JM4eNpjYGRgYOABYjEgZmJgBEI2IGYB8xgAA+AANXjaY2BifMg4gYGVgYVBAwOeYEAFjMgcp8yiFAYHBl7VP8wx/94wpDDHMIoo2DP8B8kx2TLHACkFBkYA8/IL3QB42mNgYGBmgGAZBkYGEEgB8hjBfBYGDyDNx8DBwMTABmTxMigoKKmeV/3z/z9YJTKf8f/X/4/vP7pldosLag4SYATqhgkyMgEJJnQFECcMOGChndEAfOwRuAAAAAAiAogAAQAB//8AD3jaY2BiUGJgYDRiWsXAzMDOoLeRkUHfZhM7C8Nbo41srHdsNjEzAZkMG5lBwqwg4U3sbIx/bDYxgsSNBRUF1Y0FlZUYBd6dOcO06m+YElMa0DiGJIZUxjuM9xjkGRhU2djZlJXU1UDQ1MTcDASNjcTFQFBUBGjYEkkVMJCU4gcCKRTeHCk+fn4+KSllsJiUJEhMUgrMUQbZk8bgz/iA8SRR9qzAY087FjEYD2QPDDAzMFgyAwC39TCRAAAAeNpjYGRgYADid/fqneL5bb4yyLMwgMC1H90HIfRkCxDN+IBpFZDiYGAC8QBbSwuceNpjYGRgYI7594aBgcmOAQgYHzAwMqACdgBbWQN0AAABdgAiAAAAAAAAAAABFAAAAj4AYgI+AGYB9AAAAAAAKgAqACoAKgBeAJIAmnjaY2BkYGBgZ1BgYGIAAUYGBNADEQAFQQBaAAB42o2PwUrDQBCGvzVV9GAQDx485exBY1CU3PQgVgIFI9prlVqDwcZNC/oSPoKP4HNUfQLfxYN/NytCe5GwO9/88+/MBAh5I8C0VoAtnYYNa8oaXpAn9RxIP/XcIqLreZENnjwvyfPieVVdXj2H7DHxPJH/2/M7sVn3/MGyOfb8SWjOGv4K2DRdctpkmtqhos+D6ISh4kiUUXDj1Fr3Bc/Oc0vPqec6A8aUyu1cdTaPZvyXyqz6Fm5axC7bxHOv/r/dnbSRXCk7+mpVrOqVtFqdp3NKxaHUgeod9cm40rtrzfrt2OyQa8fppCO9tk7d1x0rpiQcuDuRkjjtkHt16ctbuf/radZY52/PnEcphXpZOcofiEZNcQAAeNpjYGIAg///GBgZsAF2BgZGJkZmBmaGdkYWRla29JzKggxD9tK8TAMDAxc2D0MLU2NjENfI1M0ZACUXCrsAAAABWtLiugAA)
            format("woff");
    }

    font-family: ${(props) => props.theme.fontFamily};
    background-color: ${(props) => props.theme.bgIvory};
    font-size: ${(props) => props.theme.baseFontSize};
    display: flex;
    min-height: 100%;
    height: 100%;
    flex-direction: column;
    margin: 0;

    html, body {
        height: 100%;
        background-color: #F5F5F5;
    }
    #root {
        height: 100%;
        min-height: 100%;
    }
    input:focus, select:focus, textarea:focus {
        outline:none !important;
        border-color: ${(p) => p.theme.secondaryColor};
    }

    @media (min-width:769px) {
        ::-webkit-scrollbar-track
        {
            /* box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
            background-color: transparent;
            border-radius: 12px;
        }

        ::-webkit-scrollbar
        {
            width: 12px;
            height: 10px;
            background-color: transparent;
            border-radius: 10px;
            
        }

        ::-webkit-scrollbar-thumb
        {
            background-color: rgba(180,180,180,0.3);
              border-radius:6px;
              max-height:200px;
            /* box-shadow:  0 0 6px rgba(97,138,46,0.9); */
            border-bottom: 1px solid rgba(0,0,0,0.2);
            border-top: 1px solid rgba(0,0,0,0.2);

        }

        ::-webkit-scrollbar-button:single-button { 
            background-color: transparent;
            height: 0px; 
            width: 12px; 
        } 
    }
                            
    .hidden {
        display: none;
    }

    .react-datepicker {
        border: none;
        box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.25);
        display:flex;
    }
    .react-datepicker-wrapper {
        width: 100%;
    }

    .react-datepicker-popper {
        z-index: 10;
        top: -10px !important;
    }

    .react-datepicker__header {
        border-bottom: none;
        background-color: #f2f2f2;
        padding-bottom: 5px;
    }

    .react-datepicker-popper[data-placement^="bottom"]
        .react-datepicker__triangle,
    .react-datepicker-popper[data-placement^="bottom"]
        .react-datepicker__triangle::before {
        border-top: none;
        border-bottom-color: #e7f2d9;
    }

    .react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll {
        padding-top: 10px;
    }

    .react-datepicker__year-read-view--selected-year {
        font-weight: bold;
        font-size: 0.944rem;
    }

    .react-datepicker__navigation--previous {
        left: 10px;
        border-right-color: #48b05e;
    }

    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view--down-arrow {
        border-top-color: #48b05e;
        top: 5px;
    }

    .react-datepicker__navigation--next {
        right: 10px;
        border-left-color: #48b05e;
    }
    .react-datepicker__year-dropdown {
        background-color: #ffffff;
        padding: 10px 0px;
    }

    .react-datepicker__year-option {
        line-height: 25px;
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected {
        border-radius: 0.3rem;
        background-color: #ececec;
        color: #000;
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
        border-radius: 0.3rem;
        background-color: ${(props) => props.theme.primaryColor};
        color: #fff;
    }
    
    .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
    background-color: #ffffff;
}

    .react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover {
    background-color: #41a198;
    }

    .react-datepicker__month-year-dropdown {
    background-color: #fff;
    position: absolute;
    width: 80%;
    left: 10%;
    top: 30px;
    z-index: 1;
    text-align: center;
    border-radius: 0.3rem;
    border: 1px solid #aeaeae;
    max-height: 226px;
    overflow-y: scroll;
    }

    .react-datepicker__month-select, .react-datepicker__year-select {
        height: 24px;
        border: 1px solid #eee;
        border-radius: 5px;
        margin-top: 6px;
    }

    p {
        white-space: pre-line;
    }
`;

export default GlobalStyles;
