import React from "react";
import {KeyVal, ListTitle} from "../../../../pwa/components/StyledElements";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import Flex from "../../../../../components/layout/flex/Flex";

const Section = styled.div`
    padding: 8px 20px;
    &.full-x {
        padding: 8px 0px;
    }

    &.flex {
        display: flex;
    }
`;

const CollectWizardClientData = (props) => {
    const {t} = useTranslation();
    const {clientData} = props;

    if (!clientData) {
        return null;
    }

    return (
        <Flex.Container>
            <Flex.Row style={{width: '100%'}}>
                <ListTitle className="wizard">
                    {t("screens:technician.companyDetails")}
                </ListTitle>
                <Section>
                    <KeyVal className="single">
                        {t("screens:technician.address")}
                        <span>
                        {clientData.data.address}
                         </span>
                    </KeyVal>
                    <KeyVal className="single">
                        {t("screens:technician.city")}
                        <span>
                        {clientData.data.city}
                    </span>
                    </KeyVal>
                    <KeyVal className="single">
                        {t("screens:technician.postal")}
                        <span>
                        {clientData.data.postalCode}
                    </span>
                    </KeyVal>
                    <KeyVal className="single">
                        {t("screens:technician.country")}
                        <span>
                        {
                            clientData.data.countryName
                        }
                    </span>
                    </KeyVal>
                    <KeyVal className="single">
                        {t(
                            "sidebar:label.companyContactPersonName"
                        )}
                        <span>
                        {`${clientData.data.contactPersonFirstName
                            ? clientData.data.contactPersonFirstName
                            : ""
                        } ${clientData.data.contactPersonLastName
                            ? clientData.data.contactPersonLastName
                            : ""
                        } `}
                    </span>
                    </KeyVal>
                    <KeyVal className="single">
                        {t(
                            "screens:administratorListing.email"
                        )}
                        <span>
                        {clientData.data.email}
                    </span>
                    </KeyVal>
                    <KeyVal className="single">
                        {t(
                            "table:headers.client.contact.phone"
                        )}
                        <span>
                        {clientData.data.phoneNumber
                            ? clientData.data.phoneNumber
                            : ""}
                    </span>
                    </KeyVal>
                </Section>
            </Flex.Row>
        </Flex.Container>
    )
}

export default CollectWizardClientData;
