import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import QrReader from "react-qr-reader";
import { toast } from "react-toastify";
import styled from "styled-components";
import { ReactComponent as CloseSvg } from "../../../assets/pwa/dark-close.svg";
import { HR, ListTitle, ScreenHeader } from "../../../screens/pwa/components/StyledElements";
import { SvgNoData } from "../../../screens/pwa/components/StyledSvg";
import ClientsService from "../../../services/Clients/ClientsService";
import CompanyService from "../../../services/Company/CompanyService";
import TagService from "../../../services/Tags/TagsService";

const StyledQrReader = styled.div`
    &.wizard {
        width: 100%;
        margin: px auto;
        margin-bottom: 10px;
    }

    &.wizard.small {
        width: 78%;
        margin: 0 auto;
    }
    margin-top: 20px;
    & section {
        border-radius: 12px;
        transition: all 0.5s linear;
        & div {
            transition: all 0.5s linear;
            border: 40px solid rgba(0, 0, 0, 0.5) !important;
            box-shadow: ${(p) => p.theme.primaryColor} 0px 0px 0px 4px inset !important;
        }
    }

    &.search section {
        & div {
            box-shadow: #ff0000 0px 0px 0px 4px inset !important;
        }
    }
`;

const ImgNoData = styled(SvgNoData)`
    padding: 0;
    height: auto;
    margin: 10px auto;
`;

const InfoNoData = styled.div`
    font-size: 14px;
    text-align: center;
    color: #aaa;
`;

const Section = styled.div`
    padding: 8px 20px;
    &.full-x {
        padding: 8px 0px;
    }
`;

const Content = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    flex-grow: 1;
    position: relative;
    padding: 35px 60px 0px;
    display: flex;
    flex-direction: column;
    padding: 10px 0px 0px 0px;
`;

const FSWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    color: #fff;
    z-index: 2000;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0px 20px;
`;
const StyledClose = styled(CloseSvg)`
    width: 32px;
    height: 32px;

    & line,
    & path {
        stroke: #fff;
    }

    &.active line {
        stroke: #fff;
    }
`;

const HeaderTitle = styled.div`
    width: calc(100% - 32px);
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    color: black;
`;

const KeyVal = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    color: #000;
    font-weight: 700;
    margin-bottom: 10px;

    &.single {
        margin-bottom: 0;
    }
    & span {
        font-weight: 500;
        color: ${(p) => p.theme.primaryColor};
    }

    & b + span {
        font-size: 12px;
        margin-left: 10px;
    }

    &.swap {
        color: ${(p) => p.theme.primaryColor};
        span {
            color: #000;
        }
    }
`;

const splitTag = (x) => [`${x.slice(0, 4)} ${x.slice(4, 8)}`, x.slice(9)];

const QrScanner = (props) => {
    const { t } = useTranslation("screens");
    const [state, setState] = useState({});
    const [lookupResult, setLookupResult] = useState({
        cylinder: null,
        unit: null,
        analyzer: null,
    });

    const resetTimeout = 3000;

    useEffect(() => {
        async function lookup(tagId) {
            const tagData = await TagService.identifyTagAsync(tagId);
            if (tagData.error) {
                toast.error(t(tagData.error.message));
                setState({ ...state, search: false });
                setTimeout(() => {
                    setState({ search: false, tag: null });
                }, resetTimeout);
            } else {
                if (tagData.data.data.length === 0) {
                    setLookupResult({ unknown: true });
                    setState((prevState) => ({ ...prevState, search: false }));
                } else if (tagData.data.data[0].unitId) {
                    const unitData = await ClientsService.getUnitByIdAsync(
                        tagData.data.data[0].unitId
                    );
                    if (unitData.error) {
                        toast.error(unitData.error.message);
                        setState({ ...state, search: false });
                        setTimeout(() => {
                            setState({ search: false, tag: null });
                        }, resetTimeout);
                    } else {
                        setLookupResult({ unit: unitData.data.data[0] });
                        setState((prevState) => ({
                            ...prevState,
                            search: false,
                        }));
                    }
                } else if (tagData.data.data[0].cylinderId) {
                    const cylData = await CompanyService.getCompanyCylinderByIdAsync(
                        tagData.data.data[0].cylinderId
                    );
                    if (cylData.error) {
                        toast.error(cylData.error.message);
                        setState({ ...state, search: false });
                        setTimeout(() => {
                            setState({ search: false, tag: null });
                        }, resetTimeout);
                    } else {
                        setLookupResult({ cylinder: cylData.data.data[0] });
                        setState((prevState) => ({
                            ...prevState,
                            search: false,
                        }));
                    }
                } else if (tagData.data.data[0].analyzerId) {
                    const analData = await CompanyService.getMyGasAnalyzerByIdAsync(
                        tagData.data.data[0].analyzerId
                    );
                    if (analData.error) {
                        toast.error(analData.error.message);
                        setState({ ...state, search: false });
                        setTimeout(() => {
                            setState({ search: false, tag: null });
                        }, resetTimeout);
                    } else {
                        setLookupResult({ analyzer: analData.data.data[0] });
                        setState((prevState) => ({
                            ...prevState,
                            search: false,
                        }));
                    }
                } else {
                    setLookupResult({ unknown: true });
                    setState((prevState) => ({
                        ...prevState,
                        search: false,
                    }));
                }
            }
        }
        if (state.tag) lookup(state.tag);
    }, [state.tag]);

    if (!props.show) {
        return null;
    }

    const handleScan = (data) => {
        if (data) {
            if (data === state.tag || state.search) return;
            setState({ tag: data, search: true });
            if (props.onScan) props.onScan(data);
        }
    };
    const handleError = (err) => {
        console.error(err);
    };

    const handleClose = () => {
        setState({});
        setLookupResult({ cylinder: null, unit: null });
        if (props.onClose) props.onClose();
    };

    const tagParts = state.tag ? splitTag(state.tag) : [];
    return (
        <FSWrapper>
            <ScreenHeader className="big gray right">
                <HeaderTitle>{t("screens:pwaMenu.viewFinder")}</HeaderTitle>

                <StyledClose onClick={handleClose} />
            </ScreenHeader>
            <StyledQrReader className={state.search ? "search" : "nosearch"}>
                <QrReader
                    delay={300}
                    onError={handleError}
                    onScan={handleScan}
                    style={{ width: "100%" }}
                />
            </StyledQrReader>
            <Content>
                <HR className="content" />
                <Section className="full-x">
                    <KeyVal className="swap single">
                        {t("screens:technician.qrTag")}

                        {tagParts.length > 0 && (
                            <span>
                                <b>{tagParts[0]}</b>
                                <span>{tagParts[1]}</span>
                            </span>
                        )}
                    </KeyVal>
                </Section>
                <HR className="content" />

                {!state.search && lookupResult.unknown && (
                    <>
                        <ImgNoData />
                        <InfoNoData>{t("screens:technician.noTagData")}</InfoNoData>
                    </>
                )}

                {!state.search && lookupResult.analyzer && (
                    <>
                        <ListTitle className="scanner">{t("screens:technician.details")}</ListTitle>
                        <Section>
                            <KeyVal>
                                {t("filters:clients.name")}
                                <span>{lookupResult.analyzer.name}</span>
                            </KeyVal>
                            <KeyVal>
                                {t("forms:equipmentDescription.text")}
                                <span>{lookupResult.analyzer.description}</span>
                            </KeyVal>
                            
                            <KeyVal>
                                {t("forms:analyzer.wizard.macAddress")}
                                <span>
                                    {lookupResult.analyzer.macAddress}
                                </span>
                            </KeyVal>
                           
                        </Section>
                    </>
                )}
                {!state.search && lookupResult.cylinder && (
                    <>
                        <ListTitle className="scanner">{t("screens:technician.details")}</ListTitle>
                        <Section>
                            <KeyVal>
                                {t("table:headers.cylinders.netWeight")}
                                <span>{lookupResult.cylinder.size.toFixed(2)} kg</span>
                            </KeyVal>
                            <KeyVal>
                                {t("table:headers.cylinders.gas")}
                                <span>
                                    {lookupResult.cylinder.refrigerantTypeCode ||
                                        t("screens:equipment.emptyCylinder")}
                                </span>
                            </KeyVal>
                            <KeyVal>
                                {t("table:headers.cylinders.quantity")}
                                <span>{lookupResult.cylinder.filledQuantity?.toFixed(2)} kg</span>
                            </KeyVal>
                        </Section>
                    </>
                )}
                {!state.search && lookupResult.unit && (
                    <>
                        <ListTitle className="scanner">{t("screens:technician.details")}</ListTitle>
                        <Section>
                            <KeyVal>
                                {t("screens:technician.manufacturer")}
                                <span>{lookupResult.unit.manufacturerName}</span>
                            </KeyVal>
                            <KeyVal>
                                {t("screens:technician.application")}
                                <span>{lookupResult.unit.applicationTypeName}</span>
                            </KeyVal>
                            <KeyVal>
                                {t("screens:technician.installedGas")}
                                <span>{lookupResult.unit.refrigerantCode}</span>
                            </KeyVal>
                            <KeyVal>
                                {t("screens:technician.capacity")}
                                <span>{lookupResult.unit.capacity} kW</span>
                            </KeyVal>
                        </Section>
                    </>
                )}
            </Content>
        </FSWrapper>
    );
};

export default QrScanner;
export { StyledQrReader };
