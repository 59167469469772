import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import useDataReducer from "../../hooks/_shared/useDataReducer";
import MarketplaceService from "../../services/BuyRefrigerant/MarketplaceService";
import DataTable from "../data/DataTable";
import DateCell from "../data/DateCell";
import { useTranslation } from "react-i18next";

const BidCompanyName = styled.span`
    color: ${(props) => props.theme.primaryColor};
    font-weight: bold;
    &.invalid {
        color: #e94e4e;
    }
`;

const BidsListing = (props) => {
    const { offer, toggleBidView } = props;
    const { t } = useTranslation();

    const [bidsState, loadBids] = useDataReducer(async () => {
        return await MarketplaceService.getBids(offer.offerId);
    });

    useEffect(() => {
        if (offer.offerId) {
            loadBids(offer.offerId);
        }
    }, [offer.offerId]);
    
    const columns = useMemo(
        () => [
            {
                Header: t("forms:stock.wizard.collectCylinder.company"),
                accessor: "bidCompanyName",
                Cell: ({ row }) => {
                    return (
                        <BidCompanyName
                            className={
                                row.original.bidStatus > 1 ? "invalid" : ""
                            }
                        >
                            {row.original.bidCompanyName}
                        </BidCompanyName>
                    );
                },
            },
            {
                Header: t("screens:stockListing.filters.date"),
                accessor: "bidDateTime",
                Cell: DateCell,
            },
            {
                Header: `${t("forms:cylinder.price")} (EUR\\kg)`,
                accessor: "bidCompanyName2",
                Cell: ({ row }) => {
                    const item = row.original;
                    let prices = [];
                    if (item.offeredPriceGradeA > 0)
                        prices.push(item.offeredPriceGradeA.toFixed(2));
                    if (item.offeredPriceGradeB > 0)
                        prices.push(item.offeredPriceGradeB.toFixed(2));
                    if (item.offeredPriceGradeC > 0)
                        prices.push(item.offeredPriceGradeC.toFixed(2));
                    return (
                        <div>{prices.join(' / ')}</div>
                    );
                },
            },
        ],
        [t]
    );

    const onSelect = (row) =>{
        toggleBidView(row.original, row.original.bidId);
    }

    return (
        <>
            {bidsState.data && (
                <>
                    <DataTable loading={false} pwa columns={columns} data={bidsState.data} onSelect={onSelect} />
                </>
            )}
        </>
    );
};

export default BidsListing;
