import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ClearableInput from "../../../../components/elements/form-elements/ClearableInput";
import Label from "../../../../components/elements/form-elements/Label";
import Select from "../../../../components/elements/form-elements/Select";
import Filters from "../../../../components/filter/Filters";
import env from "../../../../core/env";
import useDebouncedCallback from "../../../../hooks/_shared/useDebouncedCallback";

const Section = styled.div`
    display: flex;
`;

const UnitsFilter = ({
    filterState,
    gasTypes,
    applicationTypes,
    dispatch,
    actionTypes,
    actions,
}) => {
    const { t } = useTranslation("filters");
    const [manufacturerChange] = useDebouncedCallback((text) => {
        text
            ? dispatch({
                  type: actionTypes.SET_MANUFACTURER_FILTER,
                  payload: text,
              })
            : dispatch({ type: actionTypes.RESET_MANUFACTURER_FILTER });
    });

    return (
        <Filters.Wrapper className="full spread">
            <Section>
                <Filters.Item>
                    <Label htmlFor="manufacturer" className="sr-only"></Label>
                    <ClearableInput
                        key="manufacturer"
                        name="manufacturer"
                        type="text"
                        defaultValue={
                            filterState.manufacturer !== undefined
                                ? filterState.manufacturer
                                : ""
                        }
                        onChange={(e) => {
                            manufacturerChange(e.currentTarget.value);
                        }}
                        placeholder={t("clients.manufacturerName")}
                        className="filters"
                    ></ClearableInput>
                </Filters.Item>

                <Filters.Item>
                    <Select
                        id="applicationType"
                        name="applicationType"
                        key="applicationType"
                        errors={[]}
                        touched={[]}
                        defaultItem={{
                            key: "allApplications",
                            value: "",
                            label: t("clients.applicationType"),
                        }}
                        defaultValue={filterState.applicationType}
                        options={
                            applicationTypes
                                ? // TODO Application types correctly
                                  Object.entries(applicationTypes).map(
                                      ([key, value], index) => ({
                                          key: key,
                                          value: key,
                                          label: value[index],
                                      })
                                  )
                                : []
                        }
                        handleChange={(evt) => {
                            dispatch({
                                type:
                                    evt.currentTarget.value === ""
                                        ? actionTypes.RESET_APPLICATION_TYPE_FILTER
                                        : actionTypes.SET_APPLICATION_TYPE_FILTER,
                                payload: evt.currentTarget.value,
                            });
                        }}
                        className="filters"
                        labelText={t("clients.applicationType")}
                        labelClassName="sr-only"
                    />
                </Filters.Item>

                <Filters.Item>
                    <Select
                        name="gastype"
                        key="gastype"
                        errors={[]}
                        touched={[]}
                        defaultItem={{
                            key: "all",
                            value: "",
                            label: t("gas.gasType"),
                        }}
                        defaultValue={filterState.refrigerantId}
                        options={
                            gasTypes
                                ? gasTypes.map((gas) => ({
                                      key: gas.id,
                                      value: gas.id,
                                      label: gas.code,
                                  }))
                                : []
                        }
                        handleChange={(evt) => {
                            dispatch({
                                type:
                                    evt.currentTarget.value === ""
                                        ? actionTypes.RESET_GAS_TYPE
                                        : actionTypes.SET_GAS_TYPE,
                                payload: evt.currentTarget.value,
                            });
                        }}
                        className="filters"
                        labelText={t("gas.gasType")}
                        labelClassName="sr-only"
                    />
                </Filters.Item>
            </Section>
            <Section>{actions}</Section>
        </Filters.Wrapper>
    );
};

export default UnitsFilter;
