import CompanyService from "../../../services/Company/CompanyService";
import RegistrationService from "../../../services/Registration/RegistrationService";
import createStateModule from "../createStateModule";
import countriesStatePlugin from "../plugins/countriesStatePlugin";

const companyStateModule = {
    stateNamespace: 'company',
    types: [
        "LOADING",
        "ERROR",
        "SUCCESS",
        "UPDATE_LOADING",
        "UPDATE_ERROR",
        "UPDATE_SUCCESS",
        "RESET_STATE",
        "RESEND_CONFIRMATION_LOADING",
        "RESEND_CONFIRMATION_SUCCESS",
        "RESEND_CONFIRMATION_ERROR"
    ],
    initialState: {
        companyData: {
            isLoading: false,
            error: null,
            data: {
                id: null
            }
        },
        updateCompanyData: {
            isLoading: false,
            error: null,
            isCompleted: false,
            response: null
        },
        resendConfirmation: {
            isLoading: false,
            data: null,
            error: null,
            isCompleted: false
        }
    },
    createReducer: types => {
        return (state, action) => {
            switch (action.type) {
                case types.LOADING:
                    state.companyData.isLoading = true;
                    state.companyData.error = false;
                    state.companyData.data = [];
                    break;
                case types.ERROR:
                    state.companyData.isLoading = false;
                    state.companyData.error = action.payload;
                    break;
                case types.SUCCESS:
                    state.companyData.isLoading = false;
                    state.companyData.error = false;
                    state.companyData.data = action.payload;
                    break;
                case types.UPDATE_LOADING:
                    state.updateCompanyData.isLoading = true;
                    state.updateCompanyData.error = null;
                    state.updateCompanyData.isCompleted = false;
                    state.updateCompanyData.response = null;
                    break;
                case types.UPDATE_ERROR:
                    state.updateCompanyData.isLoading = false;
                    state.updateCompanyData.error = action.payload;
                    state.updateCompanyData.isCompleted = false;
                    state.updateCompanyData.response = null;
                    break;
                case types.UPDATE_SUCCESS:
                    state.updateCompanyData.isLoading = false;
                    state.updateCompanyData.error = null;
                    state.updateCompanyData.isCompleted = true;
                    state.updateCompanyData.response = action.payload;
                    break;
                case types.RESET_STATE:
                    state.updateCompanyData = {
                        isLoading: false,
                        error: null,
                        isCompleted: false,
                        response: null
                    };
                    state.companyData = {
                        isLoading: false,
                        error: null,
                        data: {
                            id: null
                        }
                    };
                    break;
                case types.RESEND_CONFIRMATION_LOADING:
                    state.resendConfirmation.isLoading = true;
                    state.resendConfirmation.data = null;
                    state.resendConfirmation.error = null;
                    state.resendConfirmation.isCompleted = false;
                    break;
                case types.RESEND_CONFIRMATION_SUCCESS:
                    state.resendConfirmation.isLoading = false;
                    state.resendConfirmation.data = action.payload;
                    state.resendConfirmation.error = null;
                    state.resendConfirmation.isCompleted = true;
                    break;
                case types.RESEND_CONFIRMATION_ERROR:
                    state.resendConfirmation.isLoading = false;
                    state.resendConfirmation.data = null;
                    state.resendConfirmation.error = action.payload;
                    state.resendConfirmation.isCompleted = false;
                    break;
                default:
                    return state;
            }
        }
    },
    creators: types => ({
        loading: () => ({type: types.LOADING}),
        error: error => ({type: types.ERROR, payload: error}),
        success: company => ({type: types.SUCCESS, payload: company}),
        updateLoading: () => ({type: types.UPDATE_LOADING}),
        updateError: error => ({type: types.UPDATE_ERROR, payload: error}),
        updateSuccess: res => ({type: types.UPDATE_SUCCESS, payload: res}),
        resetState: () => ({type: types.RESET_STATE}),
        resendConfirmationLoading: () => ({type: types.RESEND_CONFIRMATION_LOADING}),
        resendConfirmationError: err => ({type: types.RESEND_CONFIRMATION_ERROR, payload: err}),
        resendConfirmationSuccess: res => ({type: types.RESEND_CONFIRMATION_SUCCESS, payload: res})
    }),
    actions: creators => ({
        loadCompany: companyId => {
            return async function (dispatch) {
                dispatch(creators.loading());
                try {
                    const res = await CompanyService.getCompanyData(companyId);
                    dispatch(creators.success(res));
                } catch (e) {
                    dispatch(creators.error())
                }
            }
        },
        updateCompany: params => {
            return async dispatch => {
                dispatch(creators.updateLoading());
                try {
                    const res = CompanyService.updateCompanyProfile(params);
                    dispatch(creators.updateSuccess(res));
                } catch (e) {
                    dispatch(creators.updateError(e));
                }
            }
        },
        resendValidationEmail: userEmail => {
            return async function (dispatch) {
                dispatch(creators.resendConfirmationLoading());
                try {
                    const res = await RegistrationService.resendConfirmation(userEmail);
                    dispatch(creators.resendConfirmationSuccess(res));
                } catch (err) {
                    dispatch(creators.resendConfirmationError(err));
                }
            }

        }
    })
};

export default createStateModule(companyStateModule, [countriesStatePlugin]);
