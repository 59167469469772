import styled from "styled-components";
import FlexRow from "../../layout/flex/FlexRow";

export const SidebarFieldWrapper = styled(FlexRow)`
    margin: 12px 0;
    font-size: 0.875rem;
    display: flex;
    justify-content: space-between;

    &:last-child {
        margin-bottom: 0;
    }

    &.mt-0 {
       margin-top: 0px;
    }

    &.big {
        font-size: 16px;
        margin-top: 20px;
    }

    &.num-section:last-child {
        margin-bottom: 12px;
    }
`;

export const SidebarLabel = styled.label`
    display: inline-block;
    margin: 0 20px 0 0;
    padding: 0 0 0 20px;
    font-weight: bold;
    min-width: 130px;

    &.pl-0 {
        padding-left: 0px;
    }
`;

export const SidebarValue = styled.span`
    padding: 0;
    color: ${props => props.theme.primaryColor};
    text-align: right;

    &.vat {
        color: #000;
    }

    &.bid-b {
        color: ${props => props.theme.bidB};
    }
    &.bid-c {
        color: ${props => props.theme.bidC};
    }

    a {
        color: ${props => props.theme.primaryColor};
    }

    &.wrong {
        color: #f0c40d;
    }

    &.pr-20 {
        padding-right: 20px;
    }
`;

export const Title = styled.p`
    color: ${props => props.theme.primaryColor};
    margin: 0 0 40px;
    font-size: 1.125rem;
`;

export const NumSection = styled.div`
    padding: 4px 0 4px 0px;
    display: flex;
    &.col {
        flex-direction:column;
    }
    &.pad {
        padding-top: 12px;
        padding-bottom: 12px;
    }
`;

export const Num = styled.div`
    display:flex;
    align-items:center;
    /* width: 30px; */
    min-width: 30px;
    font-weight: 700;
    font-size: 24px;
    text-align: left;
    background: #fff;
    color: ${(p) => p.theme.primaryColor};
    border-right: 3px solid ${(p) => p.theme.primaryColor};
    margin: 0 12px 0 0;
    

    &.tight {
        font-size:18px;
        /* letter-spacing: -4px; */
        padding-right: 15px;
    }
`;

export const NumInfo = styled.div`
    font-size: 0.875rem;
    line-height: 1.1875rem;
`;
