import React from "react";
import {useTranslation} from "react-i18next";
import Flex from "../../../../components/layout/flex/Flex";
import styled from "styled-components";
import {VSpace} from "../../../pwa/components/StyledElements";

const RowWrapper = styled.div`
    padding: 0 60px;

    &.separator {
        border-bottom: 1px solid ${props => props.theme.borderSeparator};
    }

    &.highlight {
        border-bottom: 1px solid #000;
    }
`;

const MainTitle = styled.span`
    padding: 0 0 10px;
    font-size: 1.5rem;
    color: ${props => props.theme.primaryColor};
    text-transform: uppercase;
    display: inline-block;
    width: 100%;
`;

const Title = styled.span`
    font-size: 1.25rem;
    font-weight: bold;
    display: inline-block;
    width: 100%;
    margin: 0 0 38px;
`;

const Content = styled.div`
    margin: 38px 0 48px;
`;

const ContentRow = styled.div`
    margin: 0 0 8px;

    span {
        color: ${props => props.theme.textNeutral};
        display: inline-block;
        vertical-align: top;
        font-weight: 400;
    }

    div {
        display: inline-block;
        vertical-align: top;
    }
`;

const FooterContent = styled.div`
    font-size: 1.25rem;
    padding: 30px 0 0;
`;

const FooterContentRow = styled.div`
    margin: 0 0 12px;

    &.flex {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    span {
        color: ${props => props.theme.textNeutral};
        font-weight: 400;
    }

    .highlight {
        color: ${props => props.theme.primaryColor};
        font-size: 2.25rem;
        font-weight: bold;
    }
`;

const Pipe = styled.div`
    position: relative;
    display: inline-block;
    margin: 0 12px;

    &:after {
        content: "";
        border-left: 1px solid #000;
        border-right: 1px solid #828181;
    }
`;

const TransactionDetails = ({transactionDetails, offer, paymentMethod, paymentType = 0}) => {
    const {t} = useTranslation();

    if (!transactionDetails) {
        return null;
    }

    return (
        <div style={{textAlign: 'left'}}>

            <RowWrapper className="highlight">
                <Flex.Row>
                    <MainTitle>
                        {t("actions:dialogs.transactionDetails.title")}
                    </MainTitle>
                </Flex.Row>
            </RowWrapper>


            {
                (paymentType !== 2 && paymentType !== 3) &&
                <RowWrapper className="separator">
                    <Flex.Row>
                        <Flex.Container>
                            <Flex.Column className="half">
                                <Content>
                                    <Title>{transactionDetails.payingCompany}&nbsp;{t('actions:dialogs.transactionDetails.beneficiaryInformationTitle')}</Title>
                                    <ContentRow>
                                        <span>{t('actions:dialogs.transactionDetails.bankAccountOwnerName')} :</span> &nbsp;
                                        {transactionDetails.bankAccountOwnerName}
                                    </ContentRow>

                                    <ContentRow>
                                        <span>{t('actions:dialogs.transactionDetails.bankAccountOwnerAddressLine1')}: </span> &nbsp;

                                        <div>
                                            {transactionDetails.bankAccountOwnerAddressLine1}
                                            {
                                                transactionDetails.bankAccountOwnerAddressLine2 && (
                                                    <> <br/>{transactionDetails.bankAccountOwnerAddressLine2}</>
                                                )
                                            }
                                        </div>
                                    </ContentRow>

                                    <ContentRow>
                                        <span>{t('actions:dialogs.transactionDetails.bankAccountOwnerCity')}: </span> &nbsp;
                                        {transactionDetails.bankAccountOwnerCity}
                                    </ContentRow>

                                    <ContentRow>
                                        <span>{t('actions:dialogs.transactionDetails.bankAccountOwnerPostalCode')}: </span> &nbsp;
                                        {transactionDetails.bankAccountOwnerPostalCode}
                                    </ContentRow>

                                    <ContentRow>
                                        <span>{t('actions:dialogs.transactionDetails.bankAccountOwnerCountryCode')}: </span> &nbsp;
                                        {transactionDetails.bankAccountOwnerCountryCode}
                                    </ContentRow>

                                </Content>
                            </Flex.Column>

                            <Flex.Column className="half">
                                <Content>
                                    <Title>Bank Details</Title>
                                    <ContentRow>
                                        <span>{t('actions:dialogs.transactionDetails.bankAccountOwnerIBAN')}: </span> &nbsp;
                                        {transactionDetails.bankAccountOwnerIBAN}<br/>
                                    </ContentRow>

                                    <ContentRow>
                                        <span>{t('actions:dialogs.transactionDetails.bankAccountOwnerBIC')}: </span> &nbsp;
                                        {transactionDetails.bankAccountOwnerBIC}<br/>
                                    </ContentRow>

                                    <ContentRow>
                                        <span>{t('actions:dialogs.transactionDetails.wireReference')}: </span> &nbsp;
                                        {transactionDetails.wireReference}<br/><br/>
                                    </ContentRow>
                                </Content>
                            </Flex.Column>
                        </Flex.Container>
                    </Flex.Row>
                </RowWrapper>
            }

            <RowWrapper>
                <Flex.Row>
                    <FooterContent>
                        {
                            offer && (paymentType === 0 || paymentType === 1) &&
                            <>
                                <FooterContentRow>
                                    <span>{t('actions:dialogs.transactionDetails.item')}:</span> &nbsp;
                                    {offer.refrigerantGasTypeCode}
                                    <Pipe/>

                                    {t('actions:dialogs.transactionDetails.grade', {grade: offer.pressumedGradeName})}
                                    <Pipe/>

                                    {
                                        offer.maximumQuantity &&
                                        offer.maximumQuantity.toFixed(2) + " kg"
                                    }
                                    {
                                        offer.quantitySum &&
                                        offer.quantitySum.toFixed(2) + " kg"
                                    }
                                </FooterContentRow>
                            </>
                        }
                        <FooterContentRow>
                            <span> {t('actions:dialogs.transactionDetails.paymentType.title')}:</span> &nbsp;
                            {
                                t(`table:headers.transactions.mangoPaymentType.${paymentMethod}`)
                            }
                        </FooterContentRow>

                        <VSpace size="10px"/>

                        <FooterContentRow className="flex">
                            <span> {t('actions:dialogs.transactionDetails.amount')}: </span> &nbsp;
                            <span className="highlight"> {transactionDetails.amount} EUR </span>
                        </FooterContentRow>
                    </FooterContent>
                </Flex.Row>
            </RowWrapper>
        </div>
    )
};

export default TransactionDetails;
