import React from "react";
import {Title} from "./SidebarStyle";

const SidebarTitle = (props) => {
    const {title} = props;

    return (
        <>
            {
                title &&
                (
                    <Title>{title}</Title>
                )
            }
        </>
    )
};

export default SidebarTitle;
