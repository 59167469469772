import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import useTimeoutFn from "../../hooks/_shared/useTimeoutFn";
import LoginService from "../../services/Login/LoginService";
import Headings from "../elements/headings/Headings";
import RoundedImage from "../elements/images/RoundedImage";
import Flex from "../layout/flex/Flex";
import Logo from "../logo/Logo";

const NotificationWrapper = styled.div`
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background: ${(props) => props.theme.bgWhite};
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease-in;
    z-index: 500;

    &.inline {
        position: absolute;
        left: 0;
        z-index: 5;
        background: ${(props) => props.theme.bgIvory};
    }
`;

const NotificationContainer = styled(Flex.Container)`
    /* max-width: 70%; */
    margin: 0 auto;
    align-items: center;
        width: auto;

    & .notificationContent {
        display: flex;
        flex-direction: column;
        padding-right: 10px;
        align-items: center;
        align-content: center;
        text-align: center;
        max-width: 800px;
        & a {
            max-width: 200px;
        }
    }

    @media all and (max-width: ${(props) => props.theme.smallDevices}) {
        max-width: 100%;
    }

    @media ${(p) => p.theme.device.mobile} {
        & h3.notification {
            font-size: 1.45rem;
            margin: 0 0 30px;
        }

        & .notificationContent {
            width: 100%;
        }
    }
`;

const NotificationImage = styled(RoundedImage)`
    width: 174px;
    min-width: 174px;
    height: 174px;
    margin: 0 80px 0 0;

    &.confirmed {
        position: relative;

        &:after {
            content: "";
            border-left: 8px solid ${(props) => props.theme.primaryColor};
            border-bottom: 8px solid ${(props) => props.theme.primaryColor};
            width: 60px;
            height: 30px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: rotate(-53deg) translate(0, -100%);
        }
    }

    @media ${(p) => p.theme.device.mobile} {
        width: 50px;
        min-width: 50px;
        height: 50px;
        margin: 0px 10px;

        &.confirmed {
            position: absolute;
            left: calc(50% - 40px);
            top: 40px;
        }
        &.confirmed:after {
            width: 16px;
            height: 7px;
            border-left: 4px solid ${p => p.theme.primaryColor};
            border-bottom: 4px solid ${p => p.theme.primaryColor};
        }
    }
`;

const NotificationText = styled.div`
    margin: 12px 0 36px 0;
    font-size: 1.125rem;

`;

const FullScreenNotification = (props) => {
    const {
        image,
        title,
        text,
        button,
        className,
        redirectTo,
        redirect = false,
        redirectTime = 5000,
        executeFn,
        refreshToken,
    } = props;
    const { t } = useTranslation();
    const [counter, setCounter] = useState(redirectTime / 1000);

    const history = useHistory();
    useTimeoutFn(async () => {
        if (redirectTo && redirect) {
            if (refreshToken) {
                await LoginService.refresh();
                window.location.replace(redirectTo);
            } else {
                history.push(redirectTo);
            }
        }
        if (executeFn) {
            executeFn();
        }
    }, redirectTime);

    useEffect(() => {
        const timer =
            counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);

    return (
        <NotificationWrapper className={className}>
            <Logo className="fullscreen-notification" />
            <NotificationContainer>
                <NotificationImage
                    className={image ? "" : "confirmed"}
                    image={image}
                />
                <div className="notificationContent">
                    <Headings.H3 className="notification">{title}</Headings.H3>

                    {text && <NotificationText>{text}</NotificationText>}
                    {button}

                    {redirect && (
                        <p>
                            {t(
                                "screens:userPassword.notifications.redirection"
                            )}{" "}
                            {counter}
                        </p>
                    )}
                </div>
            </NotificationContainer>
        </NotificationWrapper>
    );
};

export default FullScreenNotification;
