import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import useBreadcrumbs from "../../../core/state/navigation/useBreadcrumbs";
import { useTranslation } from "react-i18next";
import useImmerReducer from "../../../hooks/_shared/useImmerReducer";
import useDataReducer from "../../../hooks/_shared/useDataReducer";
import PaymentService from "../../../services/Payment/PaymentService";
import MarketplaceService from "../../../services/BuyRefrigerant/MarketplaceService";
import usePageChange from "../../../hooks/_shared/usePageChange";
import HamburgerMenu from "../../../components/menu/HamburgerMenu";
import HamburgerMenuAction from "../../../components/menu/HamburgerMenuAction";
import { getApiUrl } from "../../../services/getAxios";
import { DataListingContent } from "../../../components/data/styles/StyledDataContainer";
import styled from "styled-components";
import Flex from "../../../components/layout/flex/Flex";
import helpers from "../../../core/helpers";
import TransactionStatus from "../../../core/enums/TransactionStatus";
import DetailSideBar from "../../../components/modal/DetailSideBar";
import useClickAway from "../../../hooks/_shared/useClickAway";
import {
  ShortDateCell,
} from "../../../components/data/DateCell";
import TransactionDetails from "./components/TransactionDetails";
import PlainModal, {
  usePlainModal,
} from "../../../components/modal/PlainModal";
import { Helmet } from "react-helmet";
import TransactionGroupFilter from "./components/TransactionGroupFilter";
import TransactionDetailsSidebar from "./components/TransactionDetailsSidebar";
import MangoPaymentTypeForDisplay from "../../../core/enums/MangoPaymentTypeForDisplay";
import MangoTransferType from "../../../core/enums/MangoTransferType";
import Pagination from "../../../components/pagination/Pagination";
import LayoutInnerContainer from "../../../components/layout/split/one/LayoutInnerContainer";
import TransactionRow from "./components/TransactionRow";
import Filters from "../../../components/filter/Filters";
import useQueryString from "../../../hooks/_shared/useQueryString";

const HeaderWrapper = styled.div`
  display: flex;
  padding: 0px 60px 5px;
  margin: 0px -60px;
  border-radius: 15px 15px 0px 0px;
  background: linear-gradient(
      180deg,
      rgba(245, 245, 245, 1) 0%,
      rgba(240, 240, 240, 1) 100%
  );
  box-shadow: -2px 9px 11px -16px rgba(0, 0, 0, 0.75);
  border-bottom: 1px solid #ffffff;

  &.transparent {
      background: transparent;
      box-shadow: none;
      border-bottom: none;
  }

  &.hidden {
      display: none;
  }
`;

export const DataListingContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 20px 10px 20px 0;
`;

const TableTitleContainer = styled(Flex.Container)`
  justify-content: flex-end;
  margin: 20px 0px 0px 0px;
`;

const Highlight = styled.span`
  font-weight: 700;
  &.green {
      color: #48b05e;
  }
`;

const actionTypes = {
  SET_SORT: "SET_SORT",
  RESET_SORT: "RESET_SORT",
  SET_STATUS: "SET_STATUS",
  RESET_STATUS: "RESET_STATUS",
  SET_PAGING: "SET_PAGING",
  SET_PAYMENT_TYPE: "SET_PAYMENT_TYPE",
  RESET_PAYMENT_TYPE: "RESET_PAYMENT_TYPE",
  SET_CREATION_DATE_FROM: "SET_CREATION_DATE_FROM",
  RESET_CREATION_DATE_FROM: "RESET_CREATION_DATE_FROM",
  SET_CREATION_DATE_TO: "SET_CREATION_DATE_TO",
  RESET_CREATION_DATE_TO: "RESET_CREATION_DATE_TO",
  SET_EXECUTION_DATE_FROM: "SET_EXECUTION_DATE_FROM",
  RESET_EXECUTION_DATE_FROM: "RESET_EXECUTION_DATE_FROM",
  SET_EXECUTION_DATE_TO: "SET_EXECUTION_DATE_TO",
  RESET_EXECUTION_DATE_TO: "RESET_EXECUTION_DATE_TO",
  SET_PARENT_TYPE: "SET_PARENT_TYPE",
  RESET_PARENT_TYPE: "RESET_PARENT_TYPE",
  SET_PARENT_STATUS: "SET_PARENT_STATUS",
  RESET_PARENT_STATUS: "RESET_PARENT_STATUS",
  SET_FLOW_TYPE: "SET_FLOW_TYPE",
  RESET_FLOW_TYPE: "RESET_FLOW_TYPE",
  RESET_TRANSACTION_FILTER: "RESET_TRANSACTION_FILTER",
  RESET_GROUP_FILTER: "RESET_GROUP_FILTER",
  SET_AMOUNT_FROM: "SET_AMOUNT_FROM",
  RESET_AMOUNT_FROM: "RESET_AMOUNT_FROM",
  SET_AMOUNT_TO: "SET_AMOUNT_TO",
  RESET_AMOUNT_TO: "RESET_AMOUNT_TO"
};

const groupTransactionsReducer = (state, action) => {
  if (
    (action.type !== actionTypes.SET_PAGING)
  )
    state.page = 1;
  switch (action.type) {
    case actionTypes.SET_PAGING:
      state.pageSize = action.payload.pageSize;
      state.page = action.payload.pageIndex + 1;
      break;
    case actionTypes.SET_FLOW_TYPE:
      state.filter.flowType = action.payload;
      break;
    case actionTypes.RESET_FLOW_TYPE:
      delete state.filter.flowType;
      break;
    case actionTypes.SET_PARENT_TYPE:
      state.filter.parentType = action.payload;
      break;
    case actionTypes.RESET_PARENT_TYPE:
      delete state.filter.parentType;
      break;
    case actionTypes.SET_PARENT_STATUS:
      state.filter.isExecuted = action.payload;
      break;
    case actionTypes.RESET_PARENT_STATUS:
      delete state.filter.isExecuted;
      break;

    case actionTypes.RESET_GROUP_FILTER:
      state.filter = {};
      break;
    default:
      return state;
  }
};

const HierarchyView = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const currency = "EUR";
  const openDetailId = useQueryString("id", "");

  const sideBarRef = useRef(null);

  useClickAway(sideBarRef, () => {
    try {
      closeSideBar();
    } catch (ex) { }
  });

  // Group Transactions
  const [myGroupTransactionsState, myGroupTransactionsDispatch] =
    useImmerReducer(groupTransactionsReducer, {
      filter: {
        flowType: null,
        parentType: null,
        status: null

      },
      page: 1,
      pageSize: 10,
    });

  const columns = useMemo(
    () => [
      {
        Header: t("table:headers.transactions.transactionId"),
        accessor: "mangoTransactionId",
        Cell: ({ cell: { value } }) => {
          return <Highlight className="green">{value}</Highlight>;
        },
      },
      {
        Header: t("table:headers.transactions.paymentType"),
        accessor: "paymentTypeForDisplay",
        Cell: ({ cell: { value } }) => {
          let paymentTypeTranslation;
          if (MangoPaymentTypeForDisplay[value]) {
            paymentTypeTranslation = t(
              "table:headers.transactions.mangoPaymentTypeDisplay." +
              value +
              ""
            );
          }

          return (
            <Highlight className="green">
              {paymentTypeTranslation || value}
            </Highlight>
          );
        },
      },
      {
        Header: t("table:headers.transactions.creationDate"),
        accessor: "creationDate",
        Cell: ({ cell: { value } }) => {
          return value ? ShortDateCell({ cell: { value } }) : "-";
        },
      },
      {
        Header: t("table:headers.transactions.executionDate"),
        accessor: "executionDate",
        Cell: ({ cell: { value } }) => {
          return value ? ShortDateCell({ cell: { value } }) : "-";
        },
      },

      {
        Header: t("table:headers.transactions.status"),
        accessor: "transactionStatus",
        Cell: ({ cell: { value } }) => {
          let transactionTranslation;
          if (TransactionStatus[value]) {
            transactionTranslation = t(
              "table:headers.transactions.transactionStatus." +
              TransactionStatus[value] +
              ""
            );
          }

          return transactionTranslation || value;
        },
      },

      {
        Header: t("table:headers.transactions.amount"),
        accessor: "totalAmount",
        Cell: ({ cell: { value } }) => {
          return (
            <Highlight className="green">
              {helpers.formatCurrency(
                value,
                currentLanguage,
                currency
              )}
            </Highlight>
          );
        },
      },

      {
        Header: t("table:headers.transactions.transferTypeDescription"),
        accessor: "transferType",
        Cell: ({ cell: { value } }) => {
          let transferTypeTranslation;
          if (MangoTransferType[value]) {
            transferTypeTranslation = t(
              "table:headers.transactions.transferType." +
              MangoTransferType[value] +
              ""
            );
          }

          return transferTypeTranslation || value;
        },
      },
      {
        Header: "",
        accessor: "documentId",
        Cell: ({ cell: { value } }) =>
          value && (
            <HamburgerMenu>
              <HamburgerMenuAction
                text={t("actions:view")}
                onClick={() =>
                  window.open(
                    `${getApiUrl}/Documents/${value}`,
                    "_blank"
                  )
                }
              />
            </HamburgerMenu>
          ),
        disableSortBy: true,
      },
    ],
    [t]
  );

  useBreadcrumbs([
    {
      text: t("navigation:finance"),
    },
    {
      text: t("screens:transactions.myTransactions.hierarchyView"),
    },
  ]);

  const [myGroupedTransactions, loadMyGroupedTransactions] = useDataReducer(
    () => PaymentService.getMyGroupedTransactions(myGroupTransactionsState),
    true
  );

  useEffect(() => {
    loadMyGroupedTransactions(myGroupTransactionsState);
  }, [myGroupTransactionsState]);

  useEffect(() => {
    myGroupTransactionsDispatch({
      type: actionTypes.RESET_GROUP_FILTER,
    });

  }, []);

  const groupPageChange = usePageChange(
    myGroupTransactionsDispatch,
    myGroupTransactionsState
  );


  const [transactionDetails, setTransactionDetails] = useState({
    offer: "",
    details: "",
    paymentMethod: "0",
    paymentType: 0,
  });

  const {
    modalProps: transactionDetailsProps,
    toggleOpen: toggleTransactionDetails,
  } = usePlainModal({
    onClose: () => {
      toggleTransactionDetails();
    },
    closeText: t("actions:close"),
  });

  const [detailViewIsOpened, setDetailViewIsOpened] = useState(false);
  const [transaction, setTransaction] = useState({
    bid: null,
    offer: null,
    info: null,
  });

  const closeSideBar = () => {
    setDetailViewIsOpened(false);
  };

  const onSelect = useCallback((row) => {
    const getDetails = async () => {
      const info = await PaymentService.transactionDetails(
        row.original.id
      );
      if (info.data) {
        let offer = null;
        let acceptedBid = null;
        if (
          row.original.offerId !==
          "00000000-0000-0000-0000-000000000000"
        ) {
          offer = await MarketplaceService.getBuyingItemById(
            row.original.offerId
          );

          const bids = await MarketplaceService.getBids(
            row.original.offerId
          );
          const acceptedBid = bids.filter(
            (bid) => bid.bidStatus === 1
          )[0];
        }
        setTransaction({
          bid: acceptedBid,
          offer: offer,
          info: info.data,
        });
        setDetailViewIsOpened(true);
      }
    };
    getDetails();
  }, []);

  const gotoPage = (pageIndex) => {
    groupPageChange({
      pageIndex: pageIndex,
      pageSize: myGroupTransactionsState?.pageSize ?? 10,
    });
  };

  const nextPage = () => {
    groupPageChange({
      pageIndex: myGroupTransactionsState.page,
      pageSize: myGroupTransactionsState?.pageSize ?? 10,
    });
  };

  const previousPage = () => {
    groupPageChange({
      pageIndex: myGroupTransactionsState.page - 2,
      pageSize: myGroupTransactionsState?.pageSize ?? 10,
    });
  };

  const numPageButtons = 3;

  return (
    <>
      <Helmet>
        <title>{t("seo:transactions.title")}</title>
      </Helmet>
      <LayoutInnerContainer>
        <HeaderWrapper>
          <div>
            <Filters.Title>
              {t("screens:transactions.search")}
            </Filters.Title>
            {/* {isListView ? (
              <TransactionsFilter
                filterState={myTransactionsState.filter}
                dispatch={myTransactionsDispatch}
                actionTypes={actionTypes}
              />
            ) : ( */}
            <TransactionGroupFilter
              filterState={myGroupTransactionsState.filter}
              dispatch={myGroupTransactionsDispatch}
              actionTypes={actionTypes}
            />
            {/* )} */}
          </div>
        </HeaderWrapper>
        <TableTitleContainer>
        </TableTitleContainer>

        <DataListingContainer>
          <DataListingContent style={{ marginBottom: 10 }}>
            {myGroupedTransactions?.data?.data.map(
              (item, index) => {
                return (
                  <TransactionRow
                    key={`transaction-group}-${index} - ${item.id}`}
                    item={item}
                    columns={columns}
                    onSelect={onSelect}
                    isOpen={item.id === openDetailId}
                  ></TransactionRow>
                );
              }
            )}
          </DataListingContent>
          {
            <Pagination
              gotoPage={gotoPage}
              previousPage={previousPage}
              nextPage={nextPage}
              canPreviousPage={
                myGroupTransactionsState.page > 1
              }
              canNextPage={
                myGroupTransactionsState.page <
                myGroupedTransactions?.data?.totalPages
              }
              pageCount={
                myGroupedTransactions?.data?.totalPages
              }
              currentPageIndex={
                myGroupTransactionsState.page - 1
              }
              numButtons={numPageButtons}
            />
          }
        </DataListingContainer>

        <DetailSideBar
          detailIsOpened={detailViewIsOpened}
          closeSideBar={closeSideBar}
          ref={sideBarRef}
          title={t("actions:transactions.details")}
        >
          <TransactionDetailsSidebar
            key={detailViewIsOpened}
            selectedOffer={{
              offer: transaction.offer,
              bid: transaction.bid,
            }}
            offer={transaction.offer}
            bidDetails={transaction.bid}
            info={transaction.info}
            closeSideBar={closeSideBar}
          />
        </DetailSideBar>
      </LayoutInnerContainer>

      <PlainModal {...transactionDetailsProps}>
        <TransactionDetails
          transactionDetails={transactionDetails.details}
          offer={transactionDetails.offer}
          paymentMethod={transactionDetails.paymentMethod}
          paymentType={transactionDetails.paymentType}
        />
      </PlainModal>
    </>
  );
};

export default HierarchyView;
