import React, { useEffect, useMemo, useCallback, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import styled from "styled-components";
import DataTable from "../../../../components/data/DataTable";
import HamburgerMenu from "../../../../components/menu/HamburgerMenu";
import HamburgerMenuAction from "../../../../components/menu/HamburgerMenuAction";
import ModalWizard from "../../../../components/modal/ModalWizard";
import AuthorizeComponent from "../../../../core/AuthorizeComponent";
import Roles from "../../../../core/enums/Roles";
import DateTimeCell from "../../../../components/data/DateCell";
import useDataReducer from "../../../../hooks/_shared/useDataReducer";
import PaymentService from "../../../../services/Payment/PaymentService";
import UboDeclarationWizard from "./wizard/UboDeclarationWizard";
import Button from "../../../../components/elements/buttons/Button";
import UboDetailSideBar from "./UboDetailSideBar";
import DetailSideBar from "../../../../components/modal/DetailSideBar";
import ModalDialog, {
    useModal,
} from "../../../../components/modal/ModalDialog";
import useClickAway from "../../../../hooks/_shared/useClickAway";
import {
    DataListingContainer,
} from "../../../../components/data/styles/StyledDataContainer";
import SimpleTable from "../../../../components/elements/general/SimpleTable";
import UboOwnerCell from "./UboOwnerCell";
import UserService from "../../../../services/User/UserService";


const Container = styled.div`
    margin: 0px 0px 0px 20px;
`;

const HR = styled.div`
    border-bottom: 2px solid #d5d5d5;
    height: 2px;
    margin: 10px 0px;

    &.heavy {
        border-color: black;
    }

    &.small {
        border-color: black;
        height: 1px;
    }
`;

const CreateUboButton = styled(Button)`
    margin: 0 !important;
    font-size: 14px;
    font-weight: 500 !important;
    outline: none !important;
    color: #2294D2;
    padding: 0px;

    background: transparent !important;
    box-shadow: none;
    border: none !important;
    font-size: 16px;
    font-weight: 700;
    :hover {
        text-decoration: underline;
    }
`;

const LeftContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
`;

const LinerNotesDialog = styled.div`
    margin-top: 10px;
    font-size: 12px;
    font-weight: 300;
    line-height: 1.5;
`;

const RightContainer = styled.div`
    display: flex;
    justify-content: flex-end;

    margin-top: 20px;

`;

const SubmitList = styled.ul`
   font-style: italic;
`;

const ValidationStatus = styled.span`
   text-transform: uppercase;
`;

const UboTableContainer = styled.div`
    padding-left: 50px;

    tbody > tr td:first-child  {
       width: 250px;
    }
`;

const UboScreen = () => {

    const { t } = useTranslation();
    const [isMasterAdmin] = useState(
        UserService.isInRole(Roles.InstallerCompanyAdmin)
    );

    const [myUboDocuments, loadMyUboDocuments] = useDataReducer(async () => {
        return await PaymentService.getMyUbos();
    });

    const declaration = myUboDocuments?.data && myUboDocuments.data.length > 0 ? myUboDocuments.data[0] : null;
    const canSubmit = declaration && (declaration.status === "CREATED" || declaration.status === "INCOMPLETE");
    const canEdit = canSubmit;
    const canCreateNewDeclaration = declaration && (declaration.status === "VALIDATED" || declaration.status === "REFUSED");
    const canAddNew = declaration && declaration.ubos && declaration.ubos.length < 4 && canSubmit;
    const numberOfUbos = declaration && declaration.ubos ? declaration.ubos.length : 0;
    const [selectedUbo, setSelectedUbo] = useState(null);

    useEffect(() => {
        loadMyUboDocuments();
    }, []);


    useEffect(() => {
        setSelectedUbo(null);
    }, [myUboDocuments]);


    const handleCreateUbo = async (values) => {

        const body = { ...values, declarationId: declaration?.id };
        const result = await PaymentService.addMyUbo(
            body
        );
        if (result && result.error) {
            ModalWizard.submitFailed(t(result.error.message));
        } else {
            loadMyUboDocuments();
            toast.success(t("actions:dialogs.createUbo.success"));
            ModalWizard.hide();
        }

    };

    const handleCreateNewUboDeclaration = async (values) => {

        const body = { ...values };
        const result = await PaymentService.addMyUbo(
            body
        );
        if (result && result.error) {
            ModalWizard.submitFailed(t(result.error.message));
        } else {
            loadMyUboDocuments();
            toast.success(t("actions:dialogs.createNewUboDeclaration.success"));
            ModalWizard.hide();
        }

    };

    const handleEditUbo = async (values) => {

        const result = await PaymentService.updateUbo(
            values
        );
        if (result && result.error) {
            ModalWizard.submitFailed(t(result.error.message));
        } else {
            loadMyUboDocuments();
            toast.success(t("actions:dialogs.editUbo.success"));
            ModalWizard.hide();
        }
    };

    const { modalProps, toggleOpen } = useModal({
        title: t("actions:dialogs.deleteUbo.title"),
        onConfirm: async () => {
            if (selectedUbo) {
                const values = { uboId: selectedUbo.id, declarationId: declaration?.id };
                const result = await PaymentService.deleteUbo(values);
                if (result && result.error) toast.error(t(result.error.message));
                else {
                    toast.success(t("actions:dialogs.deleteUbo.success"));
                }
                loadMyUboDocuments();
                toggleOpen();
            }

        },
        onCancel: () => {
            toggleOpen();
        },
        confirmText: t("actions:dialogs.deleteUnit.confirmText"),
    });

    const submitModal = useModal({
        title: t("actions:dialogs.submitUbo.title").toUpperCase(),
        type: "info",
        onConfirm: async () => {
            const submitData = {
                declarationId: declaration?.id
            };

            var result = await PaymentService.submitUbo(submitData);
            if (result && result.error) {
                toast.error(t(result.error.message));

            } else {
                loadMyUboDocuments();
                toast.success(t("actions:dialogs.submitUbo.success"));
                submitModal.toggleOpen();
            }

        },
        onCancel: () => {
            submitModal.toggleOpen();
        },
        confirmText: t("actions:dialogs.submitUbo.confirmText"),
    });


    const createNewDeclarationModal = useModal({
        title: t("actions:dialogs.createNewUboDeclaration.title").toUpperCase(),
        confirmText: t("actions:dialogs.createNewUboDeclaration.confirmText"),
        onConfirm: async () => {
            createNewDeclarationModal.toggleOpen();
            ModalWizard.show(
                UboDeclarationWizard(
                    t,
                    null,
                    false
                ),
                {
                    onSubmit: handleCreateNewUboDeclaration,
                    onCancel: async () => {

                        ModalWizard.hidePrompt();
                    },
                },

                {
                    wide: true,
                    style: { minHeight: "720px" },
                    footerContent: t("forms:ubo.footerText"),
                    finishButtonText: t("forms:ubo.createUboButtonText")
                }
            );

        },
        onCancel: () => {
            createNewDeclarationModal.toggleOpen();
        },
    });


    const onDelete = async (row) => {
        setSelectedUbo(row.original);
        toggleOpen();
    };

    const uboDeclarationColumns = useMemo(
        () => [
            {
                header: t("table:headers.kyc.documentType"),
                accessor: "type",
                cell: (value, row) => {
                    let green = row.status === "VALIDATED";
                    let error = row.status !== "VALIDATION_ASKED" && !green;
                    let typeTranslation = t(
                        "table:headers.kyc.type." + value + ""
                    );
                    return (
                        <span
                              style={{ color: green ? "#48b05e" : error? "#E94E4E": null, fontWeight: 700, }}
                        >
                            {typeTranslation || value}
                        </span>
                    );
                },
            },
            {
                header: t("table:headers.kyc.creationDate"),
                accessor: "creationDate",
                cell: (value) => {
                    return value ? DateTimeCell({ cell: { value } }) : "-";
                },
            },
            {
                header: t("table:headers.kyc.processedDate"),
                accessor: "processedDate",
                cell: (value) => {
                    return value ? DateTimeCell({ cell: { value } }) : "-";
                },
            },
            {
                header: t("table:headers.kyc.status"),
                accessor: "status",
                cell: (value) => {
                    let green = value === "VALIDATED";
                    let error = value !== "VALIDATION_ASKED" && !green;
                    let translation = t(
                        "table:headers.ubo.statusEnum." + value + ""
                    );
                    return (
                        <ValidationStatus
                            style={{ color: green ? "#48b05e" : error? "#E94E4E": null, fontWeight: 700, }}
                        >
                            {translation || value}
                        </ValidationStatus>
                    );

                },
            },
            {
                header: t("table:headers.kyc.refusedReasonType"),
                accessor: "reason",
                cell: (value) => {
                    let translation = value
                        ? t(
                            "table:headers.ubo.refusedReasonTypeEnum." +
                            value +
                            ""
                        )
                        : null;
                    return translation || value || "-";;
                },
            },
            {
                header: t("table:headers.kyc.justification"),
                accessor: "message",
            },
        ],
        [t]
    );


    const uboColumns = useMemo(() => [
        {
            Header: t('table:headers.ubo.ubo'),
            accessor: 'lastName',
            Cell: UboOwnerCell
        },
        {
            Header: t('table:headers.ubo.ubo'),
            accessor: 'firstName',
            Cell: ({ cell: { row } }) => {
                return `${row.original.firstName} ${row.original.lastName}`;
            }
        },
        {
            Header: '',
            accessor: 'id',
            Cell: ({ cell: { row, value } }) => (

                <AuthorizeComponent
                    roles={[
                        Roles.InstallerCompanyAdmin,
                    ]}
                >
                    {canEdit && <HamburgerMenu
                        up={row.index === numberOfUbos - 1 && row.index > 0}
                    >

                        <HamburgerMenuAction
                            text={t('screens:payments.ubo.btnEdit')}
                            onClick={async () => {
                                ModalWizard.show(
                                    UboDeclarationWizard(
                                        t,
                                        row.original,
                                        true
                                    ),
                                    {
                                        onSubmit: handleEditUbo,
                                        onCancel: async () => {
                                            ModalWizard.hidePrompt();
                                        },
                                    },

                                    {
                                        wide: true,
                                        style: { minHeight: "720px" },
                                        footerContent: t("forms:ubo.footerText"),
                                        finishButtonText: t("forms:ubo.editUboButtonText")
                                    }
                                );
                            }}
                        />
                        <HamburgerMenuAction
                            text={t('screens:payments.ubo.btnDelete')}
                            onClick={async () => {
                                onDelete(row, value)

                            }}
                        />
                    </HamburgerMenu>
                    }
                </AuthorizeComponent>
            ),
        },

    ], [t, canEdit, numberOfUbos]);

    const onSelect = useCallback((row) => {
        const selectRow = async (row) => {
            setSelectedUbo({...row.original, order: row.index+1});
            setDetailViewIsOpened(true);
        };
        selectRow(row);
    }, []);

    const closeSideBar = () => {
        setDetailViewIsOpened(false);
    };
    const sideBarRef = useRef(null);

    useClickAway(sideBarRef, () => {
        try {
            closeSideBar();
        }
        catch (ex) { }
    });


    const [detailViewIsOpened, setDetailViewIsOpened] = useState(false);

    return myUboDocuments?.data ? (

        <Container>
            {
                myUboDocuments.data.length > 0 ?
                    <div>
                        <DataListingContainer>
                            <SimpleTable
                                columns={uboDeclarationColumns}
                                data={myUboDocuments?.data}
                                wrapperVariant="desktop-table"
                                shouldScrollInfoView={true}
                            />
                        </DataListingContainer>
                        <HR className="small" />
                        <UboTableContainer>
                            {declaration?.ubos && <DataTable
                                columns={uboColumns}
                                showHeaders={false}
                                data={declaration?.ubos}
                                onSelect={onSelect}
                            />
                            }
                            <DetailSideBar
                                detailIsOpened={detailViewIsOpened}
                                closeSideBar={closeSideBar}
                                title={t("sidebar:title.uboDetails")}
                                ref={sideBarRef}
                            >
                                <UboDetailSideBar ubo={selectedUbo} ownerLabel={""}>
                                </UboDetailSideBar>
                            </DetailSideBar>
                        </UboTableContainer>
                        {
                            canAddNew && isMasterAdmin && <RightContainer>
                                <CreateUboButton
                                    onClick={() => {
                                        ModalWizard.show(
                                            UboDeclarationWizard(
                                                t,
                                                null,
                                                false
                                            ),
                                            {
                                                onSubmit: handleCreateUbo,
                                                onCancel: async () => {

                                                    ModalWizard.hidePrompt();
                                                },
                                            },

                                            {
                                                wide: true,
                                                style: { minHeight: "720px" },
                                                footerContent: t("forms:ubo.footerText"),
                                                finishButtonText: t("forms:ubo.createUboButtonText")
                                            }
                                        );
                                    }
                                    }
                                >
                                    {t(
                                        "screens:payments.ubo.createAnotherUbo"
                                    )}
                                </CreateUboButton>
                            </RightContainer>
                        }
                        <HR className="small" />
                        <RightContainer>
                            {canCreateNewDeclaration && isMasterAdmin && <Button
                                onClick={() => {

                                    createNewDeclarationModal.toggleOpen();

                                }}
                                className="secondary--negative "
                            >
                                {t("screens:payments.ubo.create_new_declaration_button_text")}
                            </Button>
                            }

                            {declaration && !canCreateNewDeclaration && isMasterAdmin && <Button
                                disabled={!canSubmit}
                                className="primary--action"
                                onClick={() => {
                                    submitModal.toggleOpen();
                                }
                                }

                            >
                                {t(
                                    "screens:payments.ubo.submit_button_text"
                                )}
                            </Button>
                            }

                        </RightContainer>
                    </div>

                    :
                    <LeftContainer>
                        <Button
                            onClick={() => {
                                ModalWizard.show(
                                    UboDeclarationWizard(
                                        t,
                                        null,
                                        false
                                    ),
                                    {
                                        onSubmit: handleCreateUbo,
                                        onCancel: async () => {

                                            ModalWizard.hidePrompt();
                                        },
                                    },

                                    {
                                        wide: true,
                                        style: { minHeight: "720px" },
                                        footerContent: t("forms:ubo.footerText"),
                                        finishButtonText: t("forms:ubo.createUboButtonText")
                                    }
                                );
                            }}
                            className="secondary--action"
                        >
                            {t("screens:payments.ubo.create_new_button_text")}
                        </Button>
                    </LeftContainer>
            }

            <ModalDialog {...modalProps}>
                <p>{t("actions:dialogs.deleteUbo.text")}</p>
                <p style={{ marginLeft: "10px" }}>
                    {selectedUbo &&
                        `• ${selectedUbo.firstName} ${selectedUbo.lastName}`}
                </p>
            </ModalDialog>

            <ModalDialog {...submitModal.modalProps}>
                <p>{t("actions:dialogs.submitUbo.text")}</p>
                <p style={{ marginLeft: "10px" }}>

                    <SubmitList>
                        {
                            declaration?.ubos.map((item, index) =>
                                <li key={`UBO ${index + 1}`}>{`UBO ${index + 1} ${item.firstName} ${item.lastName}`}</li>
                            )
                        }
                    </SubmitList>
                </p>
            </ModalDialog>
            <ModalDialog {...createNewDeclarationModal.modalProps}>
                <p>{t("actions:dialogs.createNewUboDeclaration.text")}</p>
                <LinerNotesDialog
                    dangerouslySetInnerHTML={{
                        __html: t("actions:dialogs.createNewUboDeclaration.message"),
                    }}
                ></LinerNotesDialog>
            </ModalDialog>
        </Container>

    )
    : null;
};

export default UboScreen;
