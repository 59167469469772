import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import useBreadcrumbs from "../../core/state/navigation/useBreadcrumbs";

const BreadcrumbsWrapper = styled.div`
    display: flex;
    align-items: center;
    color: #675B34;
    font-size: 14px;
    /* font-family: 'Open Sans', sans-serif; */
    text-transform: uppercase;
    letter-spacing: 0.0625rem;
    padding: 4px 0px 0px 60px;

    &.company {
        padding-left: 198px;
    }
`;

const BreadcrumbsItem = styled.span`
    margin: 0 24px 0 0;

    &.separator {
        position: relative;

        &:before {
            content: '';
            position: absolute;
            border: 3px solid #675B34;
            border-radius: 50%;
            top: 50%;
            left: -14px;
            transform: translateY(-50%);
        }
    }

    &:last-child {
           color:${p => p.theme.secondaryColor} !important;
           font-weight: 600 !important;
        }

`;

const BreadcrumbsNavLink = styled(NavLink)`
    margin: 0 24px 0 0;
    color: inherit;

    text-decoration: none;

     &.separator {
         position: relative;

        &:before {
            content: '';
            position: absolute;
            border: 3px solid #675B34;
            border-radius: 50%;
            top: 50%;
            left: -14px;
            transform: translateY(-50%);
        }
    }

    &:last-child {
           color:${p => p.theme.primaryColor} !important;
           font-weight: 600 !important;
        }
`;

const Breadcrumbs = (props) => {
    const {items} = useBreadcrumbs();

    return (
        <BreadcrumbsWrapper {...props}>
            {
                items &&
                items.map((bc, i) => {
                    if (bc.url) {
                        return (
                            bc.text && <BreadcrumbsNavLink to={bc.url} key={bc.url + i} className={i !== 0 && 'separator'}>
                                {bc.text}
                            </BreadcrumbsNavLink>
                        )
                    } else {
                        return (
                            bc.text && <BreadcrumbsItem key={bc.text + i} className={i !== 0 && 'separator'}>
                                {bc.text}
                            </BreadcrumbsItem>
                        )
                    }
                })
            }
        </BreadcrumbsWrapper>
    )
};

export default Breadcrumbs;
