import React from "react";
import styled from "styled-components";
import { Checkbox } from "../../../../components/elements/form-elements";
import ValidationRule, {
    buildValidation,
} from "../../../../core/validations/ValidationRule";
import {
    InfoText,
    InfoTitle,
    InfoWrapper,
    RoundInfo,
    Separator,
    KeyText,
    ValueText,
    VSpace,
} from "../../../../components/modal/components/WizardStyling";
import {
    CheckMark,
    RadioGroup,
    RadioLabel,
    RadioWrapper,
} from "../../../../components/elements/form-elements/Radio";
import InputElement from "../../../../components/elements/form-elements/InputElement";

const validIssueType = (t) => {
    return buildValidation({
        issueType: ValidationRule.min(
            0,
            t("forms:bid.wizard.minimum"),
            t("forms:bid.wizard.minimum")
        ).rule,
    });
};

const validIssueDesc = (t) => {
    return buildValidation({
        issueDescription: ValidationRule.isStringRule().required(
            t("forms:cylinder.wizard.issueDescriptionRequired")
        ).rule,
    });
};

const WizardCylinderIssue = (t) => {
    return {
        title: t("forms:cylinder.wizard.reportIssue"),
        initialValues: { issueType: -1, issueDescription: "" },
        steps: [
            {
                name: t("forms:cylinder.wizard.issueType"),
                fieldNames: ["issueType"],
                validation: validIssueType(t),
                fields: (formik, propertyBag) => {
                    return (
                        <>
                            <InfoWrapper>
                                <RoundInfo
                                    alt=""
                                    src="/images/menu/green-info.svg"
                                />
                                <div>
                                    <InfoTitle>
                                        {t("forms:cylinder.wizard.issueTitle")}
                                    </InfoTitle>
                                    <InfoText>
                                        {t("forms:cylinder.wizard.issueTypeText")}
                                    </InfoText>
                                </div>
                            </InfoWrapper>

                            <RadioGroup className="flex-1 col">
                                <RadioWrapper>
                                    <input
                                        type="radio"
                                        name="issueType"
                                        id="radio-0"
                                        value="0"
                                        checked={
                                            formik.values["issueType"] === "0"
                                        }
                                        onChange={formik.handleChange}
                                    />
                                    <CheckMark className="secondary checkmark abs" />
                                    <RadioLabel className="tight" htmlFor="radio-0">
                                        {t(
                                            "forms:cylinder.wizard.issueType0"
                                        )}
                                    </RadioLabel>
                                </RadioWrapper>
                                <VSpace size="10" />
                                <RadioWrapper>
                                    <input
                                        type="radio"
                                        name="issueType"
                                        id="radio-1"
                                        value="1"
                                        checked={
                                            formik.values["issueType"] === "1"
                                        }
                                        onChange={formik.handleChange}
                                    />
                                    <CheckMark className="secondary checkmark abs" />
                                    <RadioLabel className="tight" htmlFor="radio-1">
                                        {t(
                                            "forms:cylinder.wizard.issueType1"
                                        )}
                                    </RadioLabel>
                                </RadioWrapper>
                                <VSpace size="10" />
                                <RadioWrapper>
                                    <input
                                        type="radio"
                                        name="issueType"
                                        id="radio-2"
                                        value="2"
                                        checked={
                                            formik.values["issueType"] === "2"
                                        }
                                        onChange={formik.handleChange}
                                    />
                                    <CheckMark className="secondary checkmark abs" />
                                    <RadioLabel className="tight" htmlFor="radio-2">
                                        {t(
                                            "forms:cylinder.wizard.issueType2"
                                        )}
                                    </RadioLabel>
                                </RadioWrapper>
                                <VSpace size="10" />
                                <RadioWrapper>
                                    <input
                                        type="radio"
                                        name="issueType"
                                        id="radio-3"
                                        value="3"
                                        checked={
                                            formik.values["issueType"] === "3"
                                        }
                                        onChange={formik.handleChange}
                                    />
                                    <CheckMark className="secondary checkmark abs" />
                                    <RadioLabel className="tight" htmlFor="radio-3">
                                        {t(
                                            "forms:cylinder.wizard.issueType3"
                                        )}
                                    </RadioLabel>
                                </RadioWrapper>
                                <VSpace size="10" />
                                <RadioWrapper>
                                    <input
                                        type="radio"
                                        name="issueType"
                                        id="radio-4"
                                        value="4"
                                        checked={
                                            formik.values["issueType"] === "4"
                                        }
                                        onChange={formik.handleChange}
                                    />
                                    <CheckMark className="secondary checkmark abs" />
                                    <RadioLabel className="tight" htmlFor="radio-4">
                                        {t(
                                            "forms:cylinder.wizard.issueType4"
                                        )}
                                    </RadioLabel>
                                </RadioWrapper>
                                <VSpace size="10" />
                            </RadioGroup>
                        </>
                    );
                },
            },{
                name: t("forms:cylinder.wizard.issueDescription"),
                fieldNames: ["issueDescription"],
                validation: validIssueDesc(t),
                fields: (formik, propertyBag) => {
                    return (
                        <>
                            <InfoWrapper>
                                <RoundInfo
                                    alt=""
                                    src="/images/menu/green-info.svg"
                                />
                                <div>
                                    <InfoTitle>
                                        {t("forms:cylinder.wizard.issueTitle")}
                                    </InfoTitle>
                                    <InfoText>
                                        {t("forms:cylinder.wizard.issueDescText")}
                                    </InfoText>
                                </div>
                            </InfoWrapper>
                            <InputElement
                                labelText={t("forms:cylinder.wizard.issueDescription")}
                                id="issueDescription"
                                autoComplete="new-description"
                                name="issueDescription"
                                type="textarea"
                                col={5}
                                wrapperVariant="flex-1"
                                maxLength="999"
                                {...formik}
                            />
                            
                        </>
                    );
                },
            },
        ],
    };
};

export default WizardCylinderIssue;
